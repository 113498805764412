import React, { Component, useState, useEffect } from "react"
import { getDecryptedItem } from '../../util';
import ReactDOM from "react-dom";
import { MDBDataTable } from "mdbreact"
import * as XLSX from "xlsx";
import {
  CardHeader,
  CardText,
  CardBody,
  Card,
  Col,
  Container,
  CardTitle,
  Button,
  Row,
  Modal,
  Title,
  Text,
  FormGroup,
  FormText,
  Label,
  Input,
  CustomInput,
  CheckBox
} from "reactstrap"
import MetaTags from "react-meta-tags"
import ReactHTMLTableToExcel from 'react-html-table-to-excel'
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faSearch,
  faTimes,
  faChevronLeft,
  faChevronRight,
  faPaperPlane,
  faBuilding,
  faUser,
  faCalendar,
  faCircle,
  faSync,
  faChessPawn,
  faBalanceScale,
  faGlobe,
  faDownload,
  faEye,
  faTrash,
} from "@fortawesome/free-solid-svg-icons"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
//Dependencia await instance para consumir apis
import instance from '../../config'
import Swal from "sweetalert2"
import { get } from "lodash";
import './style-cs.css';
import { array } from "prop-types";
//Url Principal
const baseUrl = process.env.REACT_APP_API_URL;
var today = new Date();
var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
const ReporteEstadoCuenta = props => {
  function formatFin() {
    var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  }
  function formatIni() {
    var d = new Date(),
      month = '' + 1,
      day = '' + 1,
      year = d.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  }
  const [dataCaso, setDataCaso] = useState([])
  const [dataCliente, setDataCliente] = useState([])
  const [dataCasoInfo, setDataCasoInfo] = useState([])
  const [dataMoney, setDataMoney] = useState({ money: "S/" })
  const [dataEstadoCuentaCostos, setDataEstadoCuentaCostos] = useState([])
  const [dataCasesExcel, setDataCasesExel] = useState([])
  const [dataTotalCosto, setDataTotalCosto] = useState([{ Total: 0.00 }])
  const [dataTotalHonorario, setDataTotalHonorario] = useState([{ Total: 0.00 }])
  const [dataSet, setDataSet] = useState([])
  const [items, setItems] = useState([]);
  const [dataCombo, setDataCombo] = useState([])
  const [dataComboLiq, setDataComboLiq] = useState([])
  const [dataEstadoCuentaHonorarios, setDataEstadoCuentaHonorarios] = useState([])
  const [dataConsulta, setDataConsulta] = useState({
    inicio: formatIni(),
    fin: formatFin(),
    mon: "MO01",
    CodigoLiq: "",
    cliente: '',
    caso: '',
    state: 2,
  })
  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
    promise.then((d) => {
      setItems(d);

    });
  };
  const sendDataSet = async () => {
    let styledMultiDataSet = [
      {
        columns: [
          {
            value: "Headings",
            widthPx: 160,
            style: { font: { sz: "24", bold: true } },
          },
          {
            value: "Text Style",
            widthPx: 180,
            style: { font: { sz: "24", bold: true } },
          },
          {
            value: "Colors",
            style: { font: { sz: "24", bold: true } }, // if no width set, default excel column width will be used ( 64px )
          },
        ],
        data: [
          [
            { value: "H1", style: { font: { sz: "24", bold: true } } },
            { value: "Bold", style: { font: { bold: true } } },
            {
              value: "Red",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
              },
            },
          ],
          [
            { value: "H2", style: { font: { sz: "18", bold: true } } },
            { value: "underline", style: { font: { underline: true } } },
            {
              value: "Blue",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "FF0000FF" } },
              },
            },
          ],
          [
            { value: "H3", style: { font: { sz: "14", bold: true } } },
            { value: "italic", style: { font: { italic: true } } },
            {
              value: "Green",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "FF00FF00" } },
              },
            },
          ],
          [
            { value: "H4", style: { font: { sz: "12", bold: true } } },
            { value: "strike", style: { font: { strike: true } } },
            {
              value: "Orange",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "FFF86B00" } },
              },
            },
          ],
          [
            { value: "H5", style: { font: { sz: "10.5", bold: true } } },
            { value: "outline", style: { font: { outline: true } } },
            {
              value: "Yellow",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "FFFFFF00" } },
              },
            },
          ],
          [
            { value: "H6", style: { font: { sz: "7.5", bold: true } } },
            { value: "shadow", style: { font: { shadow: true } } },
            {
              value: "Light Blue",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
              },
            },
          ],
        ],
      },
    ];
    setDataSet(styledMultiDataSet);
  }
  const FuncionListarCasos = async () => {


    await instance
      .post("/Casos/ListarCbo")
      .then(response => {
        setDataCaso(response.data)


      })
      .catch(error => {

      });

  }
  const FuncionListarCliente = async () => {
    let RolId=getDecryptedItem("RolID")
            
    var postData = {
        nUsu_ID:RolId == 6 || RolId == 1 ? null : getDecryptedItem("ID")


    }
    await instance
      .post("/Cliente/Listar",postData)
      .then(response => {
        setDataCliente(response.data)

      })
      .catch(error => {

      })
  }
  const fListarEstadoCuenta = async () => {
    var postData = {
      ini: dataConsulta.inicio,
      fin: dataConsulta.fin,
    }
    await instance
      .post("/Reporte/Ecuenta", postData)
      .then(response => {
        setDataEstadoCuenta(response.data)
        //
      })
      .catch(error => {

      })
  }
  const dataCasesExport = async () => {
    var postData = {
    }
    await instance
      .post("/Report/Cases/Excel")
      .then(response => {
        setDataCasesExel(response.data)

      })
      .catch(error => {

      })
  }
  const handleChangeBusqueda = async e => {
    const { name, value } = e.target
    setDataConsulta(prevState => ({
      ...prevState,
      [name]: value,
    }))


    if (name == "cliente") {
      var postData = {
        idClient: value,
        nUsu_Id: getDecryptedItem("ID"),
      }

      if (postData.idClient == '') {

        await instance
          .post("/Casos/ListarCbo")
          .then(response => {
            setDataCaso(response.data)

          })
          .catch(error => {

          });
      }
      else {

        await instance
          .post("/Casos/ListarCbo", postData)
          .then(response => {

            setDataCaso(response.data)

          })
          .catch(error => {

          });
      }
    }

  }
  const fListarCostos = async () => {
    fListarTotalCosto()

    var postData = {
      ini: dataConsulta.inicio,
      fin: dataConsulta.fin,
      mon: dataConsulta.mon,
      CodigoLiq: dataConsulta.CodigoLiq
    }
    await instance
      .post("/Reporte/Costos/Liq", postData)
      .then(response => {
        setDataEstadoCuentaCostos(response.data)
      })
      .catch(error => {

      })
    fListarCostosHonorarios()
  }
  const fListarCostosHonorarios = async () => {
    fListarTotalHonorario()
    var postData = {
      ini: dataConsulta.inicio,
      fin: dataConsulta.fin,
      mon: dataConsulta.mon,
      CodigoLiq: dataConsulta.CodigoLiq
    }
    await instance
      .post("/Reporte/Costos/H", postData)
      .then(response => {
        setDataEstadoCuentaHonorarios(response.data)
        if (dataConsulta.mon == "MO01") {
          setDataMoney({ money: "S/" })
        } else {
          setDataMoney({ money: "U$" })
        }
        //
      })
      .catch(error => {

      })
  }
  const fListarComboDocumento = async () => {
    var postData = {
      Prefijo: "MO",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataCombo(response.data)

      })
      .catch(error => {

      })
  }
  const fListarTotalCosto = async () => {
    var postData = {
      ini: dataConsulta.inicio,
      fin: dataConsulta.fin,
      mon: dataConsulta.mon,
      state: 2,
      cEgre_Liq_Numero: dataConsulta.CodigoLiq,
    }
    await instance
      .post("/Reporte/Costos/Total", postData)
      .then(response => {
        setDataTotalCosto(response.data)


      })
      .catch(error => {

      })
  }
  const fListarTotalHonorario = async () => {
    var postData = {
      ini: dataConsulta.inicio,
      fin: dataConsulta.fin,
      mon: dataConsulta.mon,
      state: 2,
      cLab_Liq_Numero: dataConsulta.CodigoLiq,
    }
    await instance
      .post("/Reporte/Honorarios/Total", postData)
      .then(response => {
        setDataTotalHonorario(response.data)

      })
      .catch(error => {

      })
  }
  const ListarComboCodigoLiq = async () => {
    await instance
      .post("/Combo/Codigo/Liq")
      .then(response => {
        setDataComboLiq(response.data)
      })
      .catch(error => {
      })
  }
  useEffect(() => {
    ListarComboCodigoLiq()
    fListarComboDocumento()
    FuncionListarCliente()
    FuncionListarCasos()
    fListarCostos()
    dataCasesExport()
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        {/* add meta title */}
        <MetaTags>
          <title> Documentos | GpsLegal</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="GpsLegal" breadcrumbItem="reporte Liquidación" />
          <Row>
            <div >
              <div className="" style={{ marginTop: '0.5em' }}>
                <div>
                  <div className="grillado-caso" >
                    <div className="">
                      <div>
                        <div className="combos-cliente">
                          <div>
                            <label className="texto-combex">Cliente</label>
                            <select style={{}}
                              className="form-select inputDown texto-combex"
                              name="cliente"
                              onChange={handleChangeBusqueda}
                            >
                              <option value="00">Seleccionar</option>
                              {dataCliente.map((e) =>
                                <option className="texto-combex" value={e.nCli_Id}>
                                  {e.cCli_NombreCompleto}
                                </option>
                              )
                              }
                            </select>
                          </div>
                          <div>
                            <label className="texto-combex">Caso</label>
                            <select style={{}}
                              className="form-select inputDown texto-combex"
                              name="caso"
                              onChange={handleChangeBusqueda}
                            >
                              <option className="texto-combex" value="00">Seleccionar</option>
                              {dataCaso.map((row, i) => (
                                <option className="texto-combex" value={row.nCas_Id}>
                                  {row.cCas_Titulo}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="options-ec-combo" style={{ paddingTop: '10px' }}>
                      <div >
                        <label className="texto-combex">Fecha Inicial</label>
                        <input style={{}} defaultValue={dataConsulta.inicio}
                          className="form-control texto-combex"
                          type="date"
                          name="inicio"
                          onChange={handleChangeBusqueda}
                        />
                      </div>
                      <div>
                        <label className="texto-combex">Fecha Final</label>
                        <input 
                          defaultValue={dataConsulta.fin}
                          className="form-control texto-combex"
                          type="date"
                          name="fin"
                          onChange={handleChangeBusqueda}
                        />
                      </div>
                      <div>
                        <label className="texto-combex">Ver Monto en</label>
                        <select style={{}}
                          className="form-select inputDown combodate texto-combex"
                          name="mon"
                          onChange={handleChangeBusqueda}
                        >
                          {dataCombo.slice(0).reverse().map((e) =>
                            <option className="texto-combex" value={e.cTabCodigo}>
                              {e.cTabNombre}
                            </option>
                          )
                          }
                        </select>
                      </div>
                      <div>
                        <label className="texto-combex">Código</label>
                        <select style={{}}
                          className="form-select inputDown combodate texto-combex"
                          name="CodigoLiq"
                          onChange={handleChangeBusqueda}
                        >
                          <option className="texto-combex" value="000">Seleccionar</option>
                          {dataComboLiq.map((e) =>
                            <option className="texto-combex" value={e.Numero}>
                              {e.Numero}
                            </option>
                          )
                          }
                        </select>
                      </div>
                      {/*Caso*/}
                      {/*Cliente*/}
                      <div className="botones-actions" style={{ marginTop: '2.1em' }}>
                        <div>
                          <button type="button" className="btn btn-danger" onClick={() => fListarCostos()}>{" "}<i className="fas fa-search"></i> </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br></br>

              <div className="alert titulo border-abajo" style={{ backgroundColor: 'white', borderBottom: 'groove #032D5E', borderTop: 'groove #032D5E', padding: '5px', height: '50px', marginTop: '0px' }} role="alert" >
                <div style={{ display: 'inline-block', float: 'left', marginTop: '7px', marginRight: '10px' }}>
                  GASTOS
                </div>

                <div style={{ display: 'inline-block', float: 'left' }}>
                  {<ExcelFile element={<button style={{ fontWeight: '600' }} className="btn btn-success">
                    <b>
                      <a><i className="icon-excel" ></i></a>
                    </b>
                  </button>} filename="Export_Data_Case">
                    <ExcelSheet data={dataCasesExcel} name="Data Casos" >
                      <ExcelColumn label="N°" value="N°" />
                      <ExcelColumn label="Código" value="Código" />
                      <ExcelColumn label="Cliente" value="Cliente" />
                      <ExcelColumn label="Asunto" value="Asunto" />
                      <ExcelColumn label="ÓrganoJurisdiccional" value="ÓrganoJurisdiccional" />
                      <ExcelColumn label="DistritoJudicial" value="DistritoJudicial" />
                      <ExcelColumn label="Juez" value="Juez" />
                      <ExcelColumn label="EspecialistaLegal" value="EspecialistaLegal" />
                      <ExcelColumn label="Materia" value="Materia" />
                      <ExcelColumn label="SubMateria" value="SubMateria" />
                      <ExcelColumn label="Ubicación" value="Ubicación" />
                      <ExcelColumn label="EtapaProcesal" value="EtapaProcesal" />
                      <ExcelColumn label="FechadeInicio" value="FechadeInicio" />
                      <ExcelColumn label="EstadoCEJ" value="EstadoCEJ" />
                    </ExcelSheet>
                  </ExcelFile>}
                </div>
                <div style={{ textAlign: 'right', marginTop: '8px', fontfamily: 'roboto' }}>
                  Total: &nbsp;
                  <b className="saldo-t" style={{ fontFamily: 'roboto' }}>
                    {dataTotalCosto.map((e) =>
                      <b>{
                        dataMoney.money + " " + e.Total
                      }</b>
                    )
                    }
                  </b>
                </div>
              </div>
              <div className="table-wrapper-scroll-y-costos my-custom-scrollbar-costos">
                <table id="datatableg" className="table table-bordered TablaHorasLaboradas">
                  <thead >
                    <tr className="">
                      <th style={{ fontFamily: 'roboto', fontWeight: '100' }} className="alignCenter space-text">Fecha Liquidación</th>
                      {/*<th style={{ fontFamily: 'roboto', fontWeight: '100' }} className="alignCenter space-text">Código</th>*/}
                      <th style={{ fontFamily: 'roboto', fontWeight: '100' }} className="alignCenter space-text">Concepto</th>
                      <th style={{ fontFamily: 'roboto', fontWeight: '100' }} className="alignCenter space-text">Monto</th>
                      <th style={{ fontFamily: 'roboto', fontWeight: '100' }} className="alignCenter space-text">Fec.
                        /Operación</th>
                      {/*<th style={{ fontFamily: 'roboto', fontWeight: '100' }} className="alignCenter space-text">Cod. Caso</th>*/}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      dataEstadoCuentaCostos.map((e) =>
                        <tr>
                          <td style={{ fontFamily: 'roboto', textAlign: "center", paddingTop: "5px", paddingBottom: "5px" }}>{e.fechaliq == null ? "Sin Liquidar" : e.fechaliq}</td>
                          {/*<td style={{ fontFamily: 'roboto', textAlign: "center", paddingTop: "5px", paddingBottom: "5px" }}>{e.num}</td>*/}
                          <td style={{ fontFamily: 'roboto', textAlign: "left", paddingTop: "5px", paddingBottom: "5px" }}>{e.Detalle}</td>
                          <td style={{ fontFamily: 'roboto', textAlign: "right", paddingTop: "5px", paddingBottom: "5px" }}>{e.Monto}</td>
                          <td style={{ fontFamily: 'roboto', textAlign: "center", paddingTop: "5px", paddingBottom: "5px" }}>{e.Fecha}</td>
                          {/*<td style={{ fontFamily: 'roboto', textAlign: "center", paddingTop: "5px", paddingBottom: "5px" }}>{"CASO-"+e.codInt}</td>*/}
                        </tr>)}
                  </tbody>
                </table>
              </div>
              <br></br>
              <br></br>
              <div className="alert titulo" style={{ backgroundColor: '#fff', color: '#454545', borderBottom: 'groove #032D5E', borderTop: 'groove #032D5E', padding: '5px' }} role="alert">
                HONORARIOS
                &nbsp;&nbsp;

                <ReactHTMLTableToExcel
                  type="button"
                  className="btn btn-success icon-excel"
                  table="tablehono"
                  filename={"HonorarioExport"}
                  sheet="Honorarios"
                  style={{ backgroundColor: '#00A94F !important' }}
                  buttonText=""
                />
                <div style={{ textAlign: 'right', marginTop: '-18px', fontFamily: 'roboto' }}>
                  Total: &nbsp;
                  <b className="saldo-t">  {dataTotalHonorario.map((e) =>
                    <b>{
                      dataMoney.money + " " + e.Total
                    }</b>
                  )
                  }</b>
                </div>
              </div>
              <div className="table-wrapper-scroll-y-costos my-custom-scrollbar-costos">
                <table id="tablehono" className="table table-bordered TablaHorasLaboradas">
                  <thead >
                    <tr className="">
                      <th style={{ fontFamily: 'roboto', fontWeight: '100' }} className="alignCenter space-text">Fecha Liquidación</th>
                      {/*<th style={{ fontFamily: 'roboto', fontWeight: '100' }} className="alignCenter space-text">Código</th>*/}
                      <th style={{ fontFamily: 'roboto', fontWeight: '100' }} className="alignCenter space-text">Concepto</th>
                      <th style={{ fontFamily: 'roboto', fontWeight: '100' }} className="alignCenter space-text">Monto</th>
                      <th style={{ fontFamily: 'roboto', fontWeight: '100' }} className="alignCenter space-text">Fec.
                        /Operación</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      dataEstadoCuentaHonorarios.map((e) =>
                        <tr>
                          <td style={{ fontFamily: 'roboto', textAlign: "center", paddingTop: "5px", paddingBottom: "5px" }}>{e.fechaliq == null ? "Sin Liquidar" : e.fechaliq}</td>
                          {/*<td style={{ fontFamily: 'roboto', textAlign: "center", paddingTop: "5px", paddingBottom: "5px" }}>{e.num}</td>*/}
                          <td style={{ fontFamily: 'roboto', textAlign: "left", paddingTop: "5px", paddingBottom: "5px" }}>{e.Detalle}</td>
                          <td style={{ fontFamily: 'roboto', textAlign: "right", paddingTop: "5px", paddingBottom: "5px" }}>{e.Monto}</td>
                          <td style={{ fontFamily: 'roboto', textAlign: "center", paddingTop: "5px", paddingBottom: "5px" }}>{e.Fecha}</td>
                        </tr>)}
                  </tbody>
                </table>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default ReporteEstadoCuenta