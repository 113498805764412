import React, { useReducer } from "react";
import instance from '../../../../config';
import ReportesContext from "./ReportesContext";
import ReportesReducer from "./ReportesReducer";
import { GET_REPORTE_LQP } from "../Type";
const baseUrl = process.env.REACT_APP_API_URL
var meses = new Array("Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre");
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var mesIni = Fecha.getMonth() - 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
if (mesIni < 10) mesIni = "0" + mesIni //agrega cero si el menor de 10
const ReportesState = (props) => {
    const initialState = {
        lqpArray: [],
    };
    const [state, dispatch] = useReducer(ReportesReducer, initialState);
    const FuncionListarLiquidacionProcesada = async (_nCli_Id,_nCas_Id) => {
        try {
            var postData = {
                nCli_Id: _nCli_Id,
                nCas_Id: _nCas_Id,
            }
            await instance
                .post("/Combo/Codigo/Liq", postData)
                .then(response => {

                    console.log(response.data)

                    dispatch({ type: GET_REPORTE_LQP, payload: response.data });
                })
        } catch (error) {
            
        }
    }
    return (
        <ReportesContext.Provider
            value={{
                lqpArray: state.lqpArray,
                FuncionListarLiquidacionProcesada,
            }}
        >
            {props.children}
        </ReportesContext.Provider>
    );
}
export default ReportesState;
