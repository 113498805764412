import React, { Component, useState, useEffect } from "react"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import {
    CardBody,
    Card,
    Col,
    Container,
    CardTitle,
    Button,
    Row,
    Modal,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/tablescss/FormatTable.scss"
import Swal from "sweetalert2"
import instance from '../../config'
import ReactApexChart from "react-apexcharts"
import i18n from "i18n"
import { compareObjs } from "@fullcalendar/react"
import ReactHTMLTableToExcel from 'react-html-table-to-excel'
import ReactExport from "react-export-excel";
import { If, Then, ElseIf, Else } from 'react-if-elseif-else-render';
import { set } from "lodash"
const baseUrl = process.env.REACT_APP_API_URL
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
var numClick=0;

const ReporteAvanzado = props => {
    const [getReporte1, setReporte1] = useState([])
    const [getReporte2, setReporte2] = useState([])
    const [getReporte3, setReporte3] = useState([])
    const [getReporte4, setReporte4] = useState([])
    var [getData, SetData] = useState([])
    const [getSeries, setSeries] = useState([])
    const [getLabel, setLabel] = useState([])

    const [getSReport1, setSReport1] = useState([{
        value: 0, label: ""
    }])
    const [getSReport2, setSReport2] = useState([{
        value: 0, label: ""
    }])

    const FuncionCargarDatosTabla = async () => {
        const DataReport = [{
            NProcesos: [{
                Materias: [],
                Juzgados: [],
                Clientes: [],
                SubMaterias: [],
                Asuntos: []
            }],
            Ingresos: [{
                Materias: [],
                Juzgados: [],
                Clientes: [],
                SubMaterias: [],
                Asuntos: []
            }],
            Egresos: [{
                Materias: [],
                Juzgados: [],
                Clientes: [],
                SubMaterias: [],
                Asuntos: []
            }],
            Contigencias: [{
                Materias: [],
                Juzgados: [],
                Clientes: [],
                SubMaterias: [],
                Asuntos: []
            }],
        }]
        const data = getSReport1.map((row, i) => {
            //N° Procesos
            if (getSReport2[0].value == 1) {
                if (row.value == "c.cCas_Tab_Materia") {
                    //Materias
                    const DataReporte = getReporte2.map((row, i1) => {
                        let Data = {
                            Materia: row.Materia,
                            SubMateria: row.SubMateria,
                            Cliente: row.Cliente,
                            Juzgado: row.Juzgado,
                            Cantidad: row.Cantidad,
                        }
                        DataReport[0].NProcesos[0].Materias.push(Data);
                    })
                } else if (row.value == "c.nCli_Id") {
                    //Clientes
                    const DataReporte = getReporte3.map((row, i3) => {
                        let Data = {
                            Materia: row.Materia,
                            SubMateria: row.SubMateria,
                            Cliente: row.Cliente,
                            Juzgado: row.Juzgado,
                            Cantidad: row.Cantidad,
                        }
                        DataReport[0].NProcesos[0].Clientes.push(Data);
                    })
                } else if (row.value == "c.nJuz_Id") {
                    //Juzgados
                    const DataReporte = getReporte1.map((row, i3) => {
                        let Data = {
                            Materia: row.Materia,
                            SubMateria: row.SubMateria,
                            Cliente: row.Cliente,
                            Juzgado: row.Juzgado,
                            Cantidad: row.Cantidad,
                        }
                        DataReport[0].NProcesos[0].Juzgados.push(Data);
                    })
                } else if (row.value == "c.cCas_Tab_SubMateria") {
                    //Submateria
                    const DataReporte = getReporte4.map((row, i3) => {
                        let Data = {
                            Materia: row.Materia,
                            SubMateria: row.SubMateria,
                            Cliente: row.Cliente,
                            Juzgado: row.Juzgado,
                            Cantidad: row.Cantidad,
                        }
                        DataReport[0].NProcesos[0].SubMaterias.push(Data);
                    })
                } else if (row.value == 5) {
                    //Asunto
                }
                //Ingresos
            } else if (getSReport2[0].value == 2) {
                if (row.value == "c.cCas_Tab_Materia") {
                    //Materias
                    const DataReporte = getReporte2.map((row, i) => {
                        let Data = {
                            Materia: row.Materia,
                            SubMateria: row.SubMateria,
                            Cliente: row.Cliente,
                            Juzgado: row.Juzgado,
                            Cantidad: row.Cantidad2,
                        }
                        DataReport[0].Ingresos[0].Materias.push(Data);
                    })
                    
                } else if (row.value == "c.nCli_Id") {
                    //Clientes
                    const DataReporte = getReporte3.map((row, i3) => {
                        let Data = {
                            Materia: row.Materia,
                            SubMateria: row.SubMateria,
                            Cliente: row.Cliente,
                            Juzgado: row.Juzgado,
                            Cantidad: row.Cantidad2,
                        }
                        DataReport[0].Ingresos[0].Clientes.push(Data);
                    })
                } else if (row.value == "c.nJuz_Id") {
                    //Juzgados
                    const DataReporte = getReporte1.map((row, i) => {
                        let Data = {
                            Materia: row.Materia,
                            SubMateria: row.SubMateria,
                            Cliente: row.Cliente,
                            Juzgado: row.Juzgado,
                            Cantidad: row.Cantidad2,
                        }
                        DataReport[0].Ingresos[0].Juzgados.push(Data);
                    })

                } else if (row.value == "c.cCas_Tab_SubMateria") {
                    //Submateria
                    const DataReporte = getReporte4.map((row, i3) => {
                        let Data = {
                            Materia: row.Materia,
                            SubMateria: row.SubMateria,
                            Cliente: row.Cliente,
                            Juzgado: row.Juzgado,
                            Cantidad: row.Cantidad2,
                        }
                        DataReport[0].Ingresos[0].SubMaterias.push(Data);
                    })
                } else if (row.value == 5) {
                    //Asunto
                }
                //Egresos
            } else if (getSReport2[0].value == 3) {
                if (row.value == "c.cCas_Tab_Materia") {
                    //Materias
                    const DataReporte = getReporte2.map((row, i1) => {
                        let Data = {
                            Materia: row.Materia,
                            SubMateria: row.SubMateria,
                            Cliente: row.Cliente,
                            Juzgado: row.Juzgado,
                            Cantidad: row.Cantidad2,
                        }
                        DataReport[0].Egresos[0].Materias.push(Data);
                    })
                    
                } else if (row.value == "c.nCli_Id") {
                    //Clientes
                    const DataReporte = getReporte3.map((row, i3) => {
                        let Data = {
                            Materia: row.Materia,
                            SubMateria: row.SubMateria,
                            Cliente: row.Cliente,
                            Juzgado: row.Juzgado,
                            Cantidad: row.Cantidad2,
                        }
                        DataReport[0].Egresos[0].Clientes.push(Data);
                    })
                } else if (row.value == "c.nJuz_Id") {
                    //Juzgados
                    const DataReporte = getReporte1.map((row, i3) => {
                        let Data = {
                            Materia: row.Materia,
                            SubMateria: row.SubMateria,
                            Cliente: row.Cliente,
                            Juzgado: row.Juzgado,
                            Cantidad: row.Cantidad2,
                        }
                        DataReport[0].Egresos[0].Juzgados.push(Data);
                    })
                    
                } else if (row.value == "c.cCas_Tab_SubMateria") {
                    //Submateria
                    const DataReporte = getReporte4.map((row, i3) => {
                        let Data = {
                            Materia: row.Materia,
                            SubMateria: row.SubMateria,
                            Cliente: row.Cliente,
                            Juzgado: row.Juzgado,
                            Cantidad: row.Cantidad2,
                        }
                        DataReport[0].Egresos[0].SubMaterias.push(Data);
                    })
                } else if (row.value == 5) {
                    //Asunto
                }
            }

            SetData(DataReport)
            
        })
    }
    const series = getSeries
    const options = {
        maintainAspectRatio: true,
        labels: getLabel,
        colors: ["#00A94F", "#AA1B0E", "#556ee6", "#9928ad", "#e0a226"],
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }],
        chart: {
            width: 380,
            type: 'pie',
        }
    }
    const series2 = [
        {
            data: getSeries,
        },
    ]
    const options2 = {
        chart: {
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: true,
            },
        },
        dataLabels: {
            enabled: false,
        },
        colors: ["#556ee6"],
        grid: {
            borderColor: "#262626",
        },
        xaxis: {
            categories: getLabel,
        },
    }
    const Opciones = [{
        value: "c.cCas_Tab_Materia",
        label: "Materia",
        color: "#556ee6",
    },
    {
        value: "c.nCli_Id",
        label: "Cliente",
        color: "#556ee6",
    },
    {
        value: "c.nJuz_Id",
        label: "Juzgado",
        color: "#556ee6",
    },
    {
        value: "c.cCas_Tab_SubMateria",
        label: "Sub Materia",
        color: "#556ee6",
    },
    /*{
        value: 5,
        label: "Asunto",
        color: "#556ee6",
    },
    {
        value: 6,
        label: "Activa",
        color: "#556ee6",
    }*/];
    const Valores = [
        {
            value: 1,
            label: "N° procesos",
        },
        {
            value: 2,
            label: "Ingresos",
        },
        {
            value: 3,
            label: "Egresos",
        },
        /*{
            value: 4,
            label: "Contigencias",
        },*/
    ];
    const FuncionListarReporteJuzgadoNProcesos = async (_ParmGroup) => {
        var postData = {
            ParmGroup: _ParmGroup,
        }
        await instance
            .post("/Reporte/Analitico/Juzgado/NProcesos", postData)
            .then(response => {
                setReporte1(response.data)
                
            })
            .catch(error => {
                
            })
    }
    const FuncionListarReporteMateriaNProcesos = async (_ParmGroup) => {
        var postData = {
            ParmGroup: _ParmGroup,
        }
        await instance
            .post("/Reporte/Analitico/Materia/NProcesos", postData)
            .then(response => {
                setReporte2(response.data)
                
            })
            .catch(error => {
                
            })
    }
    const FuncionListarReporteClienteNProcesos = async (_ParmGroup) => {
        var postData = {
            ParmGroup: _ParmGroup,
        }
        await instance
            .post("/Reporte/Analitico/Cliente/NProcesos", postData)
            .then(response => {
                setReporte3(response.data)
                
            })
            .catch(error => {
                
            })
    }
    const FuncionListarReporteSubMateriaNProcesos = async (_ParmGroup) => {
        var postData = {
            ParmGroup: _ParmGroup,
        }
        await instance
            .post("/Reporte/Analitico/SubMateria/NProcesos", postData)
            .then(response => {
                setReporte4(response.data)
                
            })
            .catch(error => {
                
            })
    }
    const FuncionListarReporteMateriaIngresos = async (_ParmGroup, _Moneda) => {
        var postData = {
            ParmGroup: _ParmGroup,
            Moneda: _Moneda,
        }
        await instance
            .post("/Reporte/Analitico/Materia/Ingresos", postData)
            .then(response => {
                setReporte2(response.data)
                
            })
            .catch(error => {
                
            })
    }

    const FuncionListarReporteJuzgadoIngresos = async (_ParmGroup, _Moneda) => {
        var postData = {
            ParmGroup: _ParmGroup,
            Moneda: _Moneda,
        }
        await instance
            .post("/Reporte/Analitico/Juzgado/Ingresos", postData)
            .then(response => {
                setReporte1(response.data)
                
            })
            .catch(error => {
                
            })
    }
    const FuncionListarReporteClienteIngresos = async (_ParmGroup, _Moneda) => {
        var postData = {
            ParmGroup: _ParmGroup,
            Moneda: _Moneda,
        }
        await instance
            .post("/Reporte/Analitico/Cliente/Ingresos", postData)
            .then(response => {
                setReporte3(response.data)
                
            })
            .catch(error => {
                
            })
    }
    const FuncionListarReporteSubMateriaIngresos = async (_ParmGroup, _Moneda) => {
        var postData = {
            ParmGroup: _ParmGroup,
            Moneda: _Moneda,
        }
        await instance
            .post("/Reporte/Analitico/SubMateria/Ingresos", postData)
            .then(response => {
                setReporte4(response.data)
                
            })
            .catch(error => {
                
            })
    }

    const FuncionListarReporteMateriaEgresos = async (_ParmGroup, _Moneda) => {
        var postData = {
            ParmGroup: _ParmGroup,
            Moneda: _Moneda,
        }
        await instance
            .post("/Reporte/Analitico/Materia/Egresos", postData)
            .then(response => {
                setReporte2(response.data)
                
            })
            .catch(error => {
                
            })
    }
    const FuncionListarReporteJuzgadoEgresos = async (_ParmGroup, _Moneda) => {
        var postData = {
            ParmGroup: _ParmGroup,
            Moneda: _Moneda,
        }
        await instance
            .post("/Reporte/Analitico/Juzgado/Egresos", postData)
            .then(response => {
                setReporte1(response.data)
                
            })
            .catch(error => {
                
            })
    }
    const FuncionListarReporteClienteEgresos = async (_ParmGroup, _Moneda) => {
        var postData = {
            ParmGroup: _ParmGroup,
            Moneda: _Moneda,
        }
        await instance
            .post("/Reporte/Analitico/Cliente/Egresos", postData)
            .then(response => {
                setReporte3(response.data)
                
            })
            .catch(error => {
                
            })
    }
    const FuncionListarReporteSubMateriaEgresos = async (_ParmGroup, _Moneda) => {
        var postData = {
            ParmGroup: _ParmGroup,
            Moneda: _Moneda,
        }
        await instance
            .post("/Reporte/Analitico/SubMateria/Egresos", postData)
            .then(response => {
                setReporte4(response.data)
                
            })
            .catch(error => {
                
            })
    }
    const [SelectPrueba, setSelectPrueba] = useState({
        value: 1,
    })
    const FuncionClick = async () => {
        //N° Procesos
        if (getSReport2[0].value == 1) {
            getSReport1.map((row, i) => {
                if (getSReport1[0].value == "c.cCas_Tab_Materia") {
                    
                    FuncionListarReporteMateriaNProcesos("c.cCas_Tab_Materia")
                    var seriesData = []
                    var labelData = []
                    const DATA1 = getReporte2.map((row, i) => (
                        seriesData.push(parseFloat(row.Cantidad)))
                    )
                    setSeries(seriesData)
                    const LABEL1 = getReporte2.map(
                        (row, i) =>
                            (labelData.push(row.Materia))
                    )
                    setLabel(labelData)
                    try {
                        if (getSReport1[1].value == "c.nJuz_Id") {
                            FuncionListarReporteJuzgadoNProcesos(getSReport1[0].value + ",c.nJuz_Id")
                        } else if (getSReport1[1].value == "c.nCli_Id") {
                            FuncionListarReporteClienteNProcesos(getSReport1[0].value + ",c.nCli_Id")
                        } else if (getSReport1[1].value == "c.cCas_Tab_SubMateria") {
                            FuncionListarReporteSubMateriaNProcesos(getSReport1[0].value + ",c.cCas_Tab_SubMateria")
                        } else if (getSReport1[1].value == "c.Asunto") {
                        } else if (getSReport1[1].value == "c.Activa") {
                        }
                        if (getSReport1[2].value == "c.nJuz_Id") {
                            FuncionListarReporteJuzgadoNProcesos(getSReport1[0].value + "," + getSReport1[1].value + ",c.nJuz_Id")
                        } else if (getSReport1[2].value == "c.nCli_Id") {
                            FuncionListarReporteClienteNProcesos(getSReport1[0].value + "," + getSReport1[1].value + ",c.nCli_Id")
                        } else if (getSReport1[2].value == "c.cCas_Tab_SubMateria") {
                            FuncionListarReporteSubMateriaNProcesos(getSReport1[0].value + "," + getSReport1[1].value + ",c.cCas_Tab_SubMateria")
                        } else if (getSReport1[2].value == "c.Asunto") {
                        } else if (getSReport1[2].value == "c.Activa") {
                        }
                        if (getSReport1[3].value == "c.nJuz_Id") {
                            FuncionListarReporteJuzgadoNProcesos(getSReport1[0].value + "," + getSReport1[1].value + "," + getSReport1[2].value + ",c.nJuz_Id")
                        } else if (getSReport1[3].value == "c.nCli_Id") {
                            FuncionListarReporteClienteNProcesos(getSReport1[0].value + "," + getSReport1[1].value + "," + getSReport1[2].value + ",c.nCli_Id")
                        } else if (getSReport1[3].value == "c.cCas_Tab_SubMateria") {
                            FuncionListarReporteSubMateriaNProcesos(getSReport1[0].value + "," + getSReport1[1].value + "," + getSReport1[2].value + ",c.cCas_Tab_SubMateria")
                        } else if (getSReport1[3].value == "c.Asunto") {
                        } else if (getSReport1[3].value == "c.Activa") {
                        }
                    } catch (error) {
                    }

                } else if (getSReport1[0].value == "c.nJuz_Id") {
                    
                    FuncionListarReporteJuzgadoNProcesos('c.nJuz_Id')
                    var seriesData = []
                    var labelData = []
                    const DATA1 = getReporte1.map((row, i) => (
                        seriesData.push(parseFloat(row.Cantidad)))
                    )
                    setSeries(seriesData)
                    const LABEL1 = getReporte1.map(
                        (row, i) =>
                            (labelData.push(row.Juzgado))
                    )
                    setLabel(labelData)
                    try {
                        if (getSReport1[1].value == "c.cCas_Tab_Materia") {
                            FuncionListarReporteMateriaNProcesos(getSReport1[0].value + ",c.cCas_Tab_Materia")
                        } else if (getSReport1[1].value == "c.nCli_Id") {
                            FuncionListarReporteClienteNProcesos(getSReport1[0].value + ",c.nCli_Id")
                        } else if (getSReport1[1].value == "c.cCas_Tab_SubMateria") {
                            FuncionListarReporteSubMateriaNProcesos(getSReport1[0].value + ",c.cCas_Tab_SubMateria")
                        } else if (getSReport1[1].value == "c.Asunto") {
                        } else if (getSReport1[1].value == "c.Activa") {
                        }
                        if (getSReport1[2].value == "c.cCas_Tab_Materia") {
                            FuncionListarReporteMateriaNProcesos(getSReport1[0].value + "," + getSReport1[1].value + ",c.cCas_Tab_Materia")
                        } else if (getSReport1[2].value == "c.nCli_Id") {
                            FuncionListarReporteClienteNProcesos(getSReport1[0].value + "," + getSReport1[1].value + ",c.nCli_Id")
                        } else if (getSReport1[2].value == "c.cCas_Tab_SubMateria") {
                            FuncionListarReporteSubMateriaNProcesos(getSReport1[0].value + "," + getSReport1[1].value + ",c.cCas_Tab_SubMateria")
                        } else if (getSReport1[2].value == "c.Asunto") {
                        } else if (getSReport1[2].value == "c.Activa") {
                        }
                        if (getSReport1[3].value == "c.cCas_Tab_Materia") {
                            FuncionListarReporteMateriaNProcesos(getSReport1[0].value + "," + getSReport1[1].value + "," + getSReport1[2].value + ",c.cCas_Tab_Materia")
                        } else if (getSReport1[3].value == "c.nCli_Id") {
                            FuncionListarReporteClienteNProcesos(getSReport1[0].value + "," + getSReport1[1].value + "," + getSReport1[2].value + ",c.nCli_Id")
                        } else if (getSReport1[3].value == "c.cCas_Tab_SubMateria") {
                            FuncionListarReporteSubMateriaNProcesos(getSReport1[0].value + "," + getSReport1[1].value + "," + getSReport1[2].value + ",c.cCas_Tab_SubMateria")
                        } else if (getSReport1[3].value == "c.Asunto") {
                        } else if (getSReport1[3].value == "c.Activa") {
                        }

                    } catch (error) {
                    }

                } else if (getSReport1[0].value == "c.cCas_Tab_SubMateria") {
                    FuncionListarReporteSubMateriaNProcesos('c.cCas_Tab_SubMateria')
                    var seriesData = []
                    var labelData = []
                    const DATA1 = getReporte4.map((row, i) => (
                        seriesData.push(parseFloat(row.Cantidad)))
                    )
                    setSeries(seriesData)
                    const LABEL1 = getReporte4.map(
                        (row, i) =>
                            (labelData.push(row.SubMateria))
                    )
                    setLabel(labelData)
                    try {
                        if (getSReport1[1].value == "c.cCas_Tab_Materia") {
                            FuncionListarReporteMateriaNProcesos(getSReport1[0].value + ",c.cCas_Tab_Materia")
                        } else if (getSReport1[1].value == "c.nCli_Id") {
                            FuncionListarReporteClienteNProcesos(getSReport1[0].value + ",c.nCli_Id")
                        } else if (getSReport1[1].value == "c.nJuz_Id") {
                            FuncionListarReporteJuzgadoNProcesos(getSReport1[0].value + ",c.nJuz_Id")
                        } else if (getSReport1[1].value == "c.Asunto") {
                        } else if (getSReport1[1].value == "c.Activa") {
                        }
                        if (getSReport1[2].value == "c.cCas_Tab_Materia") {
                            FuncionListarReporteMateriaNProcesos(getSReport1[0].value + "," + getSReport1[1].value + ",c.cCas_Tab_Materia")
                        } else if (getSReport1[2].value == "c.nCli_Id") {
                            FuncionListarReporteClienteNProcesos(getSReport1[0].value + "," + getSReport1[1].value + ",c.nCli_Id")
                        } else if (getSReport1[2].value == "c.nJuz_Id") {
                            FuncionListarReporteJuzgadoNProcesos(getSReport1[0].value + "," + getSReport1[1].value + ",c.nJuz_Id")
                        } else if (getSReport1[2].value == "c.Asunto") {
                        } else if (getSReport1[2].value == "c.Activa") {
                        }
                        if (getSReport1[3].value == "c.cCas_Tab_Materia") {
                            FuncionListarReporteMateriaNProcesos(getSReport1[0].value + "," + getSReport1[1].value + "," + getSReport1[2].value + ",c.cCas_Tab_Materia")
                        } else if (getSReport1[3].value == "c.nCli_Id") {
                            FuncionListarReporteClienteNProcesos(getSReport1[0].value + "," + getSReport1[1].value + "," + getSReport1[2].value + ",c.nCli_Id")
                        } else if (getSReport1[3].value == "c.nJuz_Id") {
                            FuncionListarReporteJuzgadoNProcesos(getSReport1[0].value + "," + getSReport1[1].value + "," + getSReport1[2].value + ",c.nJuz_Id")
                        } else if (getSReport1[3].value == "c.Asunto") {
                        } else if (getSReport1[3].value == "c.Activa") {
                        }
                    } catch (error) {
                    }

                } else if (getSReport1[0].value == "c.nCli_Id") {
                    FuncionListarReporteClienteNProcesos('c.nCli_Id')
                    var seriesData = []
                    var labelData = []
                    const DATA1 = getReporte3.map((row, i) => (
                        seriesData.push(parseFloat(row.Cantidad)))
                    )
                    setSeries(seriesData)
                    const LABEL1 = getReporte3.map(
                        (row, i) =>
                            (labelData.push(row.Cliente))
                    )
                    setLabel(labelData)
                    try {
                        if (getSReport1[1].value == "c.cCas_Tab_Materia") {
                            FuncionListarReporteMateriaNProcesos(getSReport1[0].value + ",c.cCas_Tab_Materia")
                        } else if (getSReport1[1].value == "c.nJuz_Id") {
                            FuncionListarReporteJuzgadoNProcesos(getSReport1[0].value + ",c.nJuz_Id")
                        } else if (getSReport1[1].value == "c.cCas_Tab_SubMateria") {
                            FuncionListarReporteSubMateriaNProcesos(getSReport1[0].value + ",c.cCas_Tab_SubMateria")
                        } else if (getSReport1[1].value == "c.Asunto") {
                        } else if (getSReport1[1].value == "c.Activa") {
                        }
                        if (getSReport1[2].value == "c.cCas_Tab_Materia") {
                            FuncionListarReporteMateriaNProcesos(getSReport1[0].value + "," + getSReport1[1].value + ",c.cCas_Tab_Materia")
                        } else if (getSReport1[2].value == "c.nJuz_Id") {
                            FuncionListarReporteJuzgadoNProcesos(getSReport1[0].value + "," + getSReport1[1].value + ",c.nJuz_Id")
                        } else if (getSReport1[2].value == "c.cCas_Tab_SubMateria") {
                            FuncionListarReporteSubMateriaNProcesos(getSReport1[0].value + "," + getSReport1[1].value + ",c.cCas_Tab_SubMateria")
                        } else if (getSReport1[2].value == "c.Asunto") {
                        } else if (getSReport1[2].value == "c.Activa") {
                        }
                        if (getSReport1[3].value == "c.cCas_Tab_Materia") {
                            FuncionListarReporteMateriaNProcesos(getSReport1[0].value + "," + getSReport1[1].value + "," + getSReport1[2].value + ",c.cCas_Tab_Materia")
                        } else if (getSReport1[3].value == "c.nJuz_Id") {
                            FuncionListarReporteJuzgadoNProcesos(getSReport1[0].value + "," + getSReport1[1].value + "," + getSReport1[2].value + ",c.nJuz_Id")
                        } else if (getSReport1[3].value == "c.cCas_Tab_SubMateria") {
                            FuncionListarReporteSubMateriaNProcesos(getSReport1[0].value + "," + getSReport1[1].value + "," + getSReport1[2].value + ",c.cCas_Tab_SubMateria")
                        } else if (getSReport1[3].value == "c.Asunto") {
                        } else if (getSReport1[3].value == "c.Activa") {
                        }

                    } catch (error) {
                    }
                }
            });
            setTimeout(() => {
                 FuncionCargarDatosTabla()
            }, 500);
           
        }
        //Ingresos
        else if (getSReport2[0].value == 2) {
            if (getSReport1[0].value == "c.cCas_Tab_Materia") {
                FuncionListarReporteMateriaIngresos("c.cCas_Tab_Materia", document.getElementById("cMoneda").value)
                var seriesData = []
                var labelData = []
                const DATA1 = getReporte2.map((row, i) => (
                    seriesData.push(parseFloat(row.Cantidad)))
                )
                setSeries(seriesData)
                const LABEL1 = getReporte2.map(
                    (row, i) =>
                        (labelData.push(row.Materia))
                )
                setLabel(labelData)
                try {
                    if (getSReport1[1].value == "c.nJuz_Id") {
                        FuncionListarReporteJuzgadoIngresos(getSReport1[0].value + ",c.nJuz_Id", document.getElementById("cMoneda").value)
                    } else if (getSReport1[1].value == "c.nCli_Id") {
                        FuncionListarReporteClienteIngresos(getSReport1[0].value + ",c.nCli_Id", document.getElementById("cMoneda").value)
                    } else if (getSReport1[1].value == "c.cCas_Tab_SubMateria") {
                        FuncionListarReporteSubMateriaIngresos(getSReport1[0].value + ",c.cCas_Tab_SubMateria", document.getElementById("cMoneda").value)
                    } else if (getSReport1[1].value == "c.Asunto") {
                    } else if (getSReport1[1].value == "c.Activa") {
                    }
                    if (getSReport1[2].value == "c.nJuz_Id") {
                        FuncionListarReporteJuzgadoIngresos(getSReport1[0].value + "," + getSReport1[1].value + ",c.nJuz_Id", document.getElementById("cMoneda").value)
                    } else if (getSReport1[2].value == "c.nCli_Id") {
                        FuncionListarReporteClienteIngresos(getSReport1[0].value + "," + getSReport1[1].value + ",c.nCli_Id", document.getElementById("cMoneda").value)
                    } else if (getSReport1[2].value == "c.cCas_Tab_SubMateria") {
                        FuncionListarReporteSubMateriaIngresos(getSReport1[0].value + "," + getSReport1[1].value + ",c.cCas_Tab_SubMateria", document.getElementById("cMoneda").value)
                    } else if (getSReport1[2].value == "c.Asunto") {
                    } else if (getSReport1[2].value == "c.Activa") {
                    }
                    if (getSReport1[3].value == "c.nJuz_Id") {
                        FuncionListarReporteJuzgadoIngresos(getSReport1[0].value + "," + getSReport1[1].value + "," + getSReport1[2].value + ",c.nJuz_Id", document.getElementById("cMoneda").value)
                    } else if (getSReport1[3].value == "c.nCli_Id") {
                        FuncionListarReporteClienteIngresos(getSReport1[0].value + "," + getSReport1[1].value + "," + getSReport1[2].value + ",c.nCli_Id", document.getElementById("cMoneda").value)
                    } else if (getSReport1[3].value == "c.cCas_Tab_SubMateria") {
                        FuncionListarReporteSubMateriaIngresos(getSReport1[0].value + "," + getSReport1[1].value + "," + getSReport1[2].value + ",c.cCas_Tab_SubMateria", document.getElementById("cMoneda").value)
                    } else if (getSReport1[3].value == "c.Asunto") {
                    } else if (getSReport1[3].value == "c.Activa") {
                    }
                } catch (error) {
                }
            } else if (getSReport1[0].value == "c.nJuz_Id") {
                
                FuncionListarReporteJuzgadoIngresos('c.nJuz_Id', document.getElementById("cMoneda").value)
                var seriesData = []
                var labelData = []
                const DATA1 = getReporte1.map((row, i) => (
                    seriesData.push(parseFloat(row.Cantidad)))
                )
                setSeries(seriesData)
                const LABEL1 = getReporte1.map(
                    (row, i) =>
                        (labelData.push(row.Juzgado))
                )
                setLabel(labelData)
                try {
                    if (getSReport1[1].value == "c.cCas_Tab_Materia") {
                        FuncionListarReporteMateriaIngresos(getSReport1[0].value + ",c.cCas_Tab_Materia", document.getElementById("cMoneda").value)
                    } else if (getSReport1[1].value == "c.nCli_Id") {
                        FuncionListarReporteClienteIngresos(getSReport1[0].value + ",c.nCli_Id", document.getElementById("cMoneda").value)
                    } else if (getSReport1[1].value == "c.cCas_Tab_SubMateria") {
                        FuncionListarReporteSubMateriaIngresos(getSReport1[0].value + ",c.cCas_Tab_SubMateria", document.getElementById("cMoneda").value)
                    } else if (getSReport1[1].value == "c.Asunto") {
                    } else if (getSReport1[1].value == "c.Activa") {
                    }
                    if (getSReport1[2].value == "c.cCas_Tab_Materia") {
                        FuncionListarReporteMateriaIngresos(getSReport1[0].value + "," + getSReport1[1].value + ",c.cCas_Tab_Materia", document.getElementById("cMoneda").value)
                    } else if (getSReport1[2].value == "c.nCli_Id") {
                        FuncionListarReporteClienteIngresos(getSReport1[0].value + "," + getSReport1[1].value + ",c.nCli_Id", document.getElementById("cMoneda").value)
                    } else if (getSReport1[2].value == "c.cCas_Tab_SubMateria") {
                        FuncionListarReporteSubMateriaIngresos(getSReport1[0].value + "," + getSReport1[1].value + ",c.cCas_Tab_SubMateria", document.getElementById("cMoneda").value)
                    } else if (getSReport1[2].value == "c.Asunto") {
                    } else if (getSReport1[2].value == "c.Activa") {
                    }
                    if (getSReport1[3].value == "c.cCas_Tab_Materia") {
                        FuncionListarReporteMateriaIngresos(getSReport1[0].value + "," + getSReport1[1].value + "," + getSReport1[2].value + ",c.cCas_Tab_Materia", document.getElementById("cMoneda").value)
                    } else if (getSReport1[3].value == "c.nCli_Id") {
                        FuncionListarReporteClienteIngresos(getSReport1[0].value + "," + getSReport1[1].value + "," + getSReport1[2].value + ",c.nCli_Id", document.getElementById("cMoneda").value)
                    } else if (getSReport1[3].value == "c.cCas_Tab_SubMateria") {
                        FuncionListarReporteSubMateriaIngresos(getSReport1[0].value + "," + getSReport1[1].value + "," + getSReport1[2].value + ",c.cCas_Tab_SubMateria", document.getElementById("cMoneda").value)
                    } else if (getSReport1[3].value == "c.Asunto") {
                    } else if (getSReport1[3].value == "c.Activa") {
                    }

                } catch (error) {
                }

            } else if (getSReport1[0].value == "c.cCas_Tab_SubMateria") {
                FuncionListarReporteSubMateriaIngresos('c.cCas_Tab_SubMateria', document.getElementById("cMoneda").value)
                var seriesData = []
                var labelData = []
                const DATA1 = getReporte4.map((row, i) => (
                    seriesData.push(parseFloat(row.Cantidad)))
                )
                setSeries(seriesData)
                const LABEL1 = getReporte4.map(
                    (row, i) =>
                        (labelData.push(row.SubMateria))
                )
                setLabel(labelData)
                try {
                    if (getSReport1[1].value == "c.cCas_Tab_Materia") {
                        FuncionListarReporteMateriaIngresos(getSReport1[0].value + ",c.cCas_Tab_Materia", document.getElementById("cMoneda").value)
                    } else if (getSReport1[1].value == "c.nCli_Id") {
                        FuncionListarReporteClienteIngresos(getSReport1[0].value + ",c.nCli_Id", document.getElementById("cMoneda").value)
                    } else if (getSReport1[1].value == "c.nJuz_Id") {
                        FuncionListarReporteJuzgadoIngresos(getSReport1[0].value + ",c.nJuz_Id", document.getElementById("cMoneda").value)
                    } else if (getSReport1[1].value == "c.Asunto") {
                    } else if (getSReport1[1].value == "c.Activa") {
                    }
                    if (getSReport1[2].value == "c.cCas_Tab_Materia") {
                        FuncionListarReporteMateriaIngresos(getSReport1[0].value + "," + getSReport1[1].value + ",c.cCas_Tab_Materia", document.getElementById("cMoneda").value)
                    } else if (getSReport1[2].value == "c.nCli_Id") {
                        FuncionListarReporteClienteIngresos(getSReport1[0].value + "," + getSReport1[1].value + ",c.nCli_Id", document.getElementById("cMoneda").value)
                    } else if (getSReport1[2].value == "c.nJuz_Id") {
                        FuncionListarReporteJuzgadoIngresos(getSReport1[0].value + "," + getSReport1[1].value + ",c.nJuz_Id", document.getElementById("cMoneda").value)
                    } else if (getSReport1[2].value == "c.Asunto") {
                    } else if (getSReport1[2].value == "c.Activa") {
                    }
                    if (getSReport1[3].value == "c.cCas_Tab_Materia") {
                        FuncionListarReporteMateriaIngresos(getSReport1[0].value + "," + getSReport1[1].value + "," + getSReport1[2].value + ",c.cCas_Tab_Materia", document.getElementById("cMoneda").value)
                    } else if (getSReport1[3].value == "c.nCli_Id") {
                        FuncionListarReporteClienteIngresos(getSReport1[0].value + "," + getSReport1[1].value + "," + getSReport1[2].value + ",c.nCli_Id", document.getElementById("cMoneda").value)
                    } else if (getSReport1[3].value == "c.nJuz_Id") {
                        FuncionListarReporteJuzgadoIngresos(getSReport1[0].value + "," + getSReport1[1].value + "," + getSReport1[2].value + ",c.nJuz_Id", document.getElementById("cMoneda").value)
                    } else if (getSReport1[3].value == "c.Asunto") {
                    } else if (getSReport1[3].value == "c.Activa") {
                    }
                } catch (error) {
                }

            } else if (getSReport1[0].value == "c.nCli_Id") {
                FuncionListarReporteClienteIngresos('c.nCli_Id', document.getElementById("cMoneda").value)
                var seriesData = []
                var labelData = []
                const DATA1 = getReporte3.map((row, i) => (
                    seriesData.push(parseFloat(row.Cantidad)))
                )
                setSeries(seriesData)
                const LABEL1 = getReporte3.map(
                    (row, i) =>
                        (labelData.push(row.Cliente))
                )
                setLabel(labelData)
                try {
                    if (getSReport1[1].value == "c.cCas_Tab_Materia") {
                        FuncionListarReporteMateriaIngresos(getSReport1[0].value + ",c.cCas_Tab_Materia", document.getElementById("cMoneda").value)
                    } else if (getSReport1[1].value == "c.nJuz_Id") {
                        FuncionListarReporteJuzgadoIngresos(getSReport1[0].value + ",c.nJuz_Id", document.getElementById("cMoneda").value)
                    } else if (getSReport1[1].value == "c.cCas_Tab_SubMateria") {
                        FuncionListarReporteSubMateriaIngresos(getSReport1[0].value + ",c.cCas_Tab_SubMateria", document.getElementById("cMoneda").value)
                    } else if (getSReport1[1].value == "c.Asunto") {
                    } else if (getSReport1[1].value == "c.Activa") {
                    }
                    if (getSReport1[2].value == "c.cCas_Tab_Materia") {
                        FuncionListarReporteMateriaIngresos(getSReport1[0].value + "," + getSReport1[1].value + ",c.cCas_Tab_Materia", document.getElementById("cMoneda").value)
                    } else if (getSReport1[2].value == "c.nJuz_Id") {
                        FuncionListarReporteJuzgadoIngresos(getSReport1[0].value + "," + getSReport1[1].value + ",c.nJuz_Id", document.getElementById("cMoneda").value)
                    } else if (getSReport1[2].value == "c.cCas_Tab_SubMateria") {
                        FuncionListarReporteSubMateriaIngresos(getSReport1[0].value + "," + getSReport1[1].value + ",c.cCas_Tab_SubMateria", document.getElementById("cMoneda").value)
                    } else if (getSReport1[2].value == "c.Asunto") {
                    } else if (getSReport1[2].value == "c.Activa") {
                    }
                    if (getSReport1[3].value == "c.cCas_Tab_Materia") {
                        FuncionListarReporteMateriaIngresos(getSReport1[0].value + "," + getSReport1[1].value + "," + getSReport1[2].value + ",c.cCas_Tab_Materia", document.getElementById("cMoneda").value)
                    } else if (getSReport1[3].value == "c.nJuz_Id") {
                        FuncionListarReporteJuzgadoIngresos(getSReport1[0].value + "," + getSReport1[1].value + "," + getSReport1[2].value + ",c.nJuz_Id", document.getElementById("cMoneda").value)
                    } else if (getSReport1[3].value == "c.cCas_Tab_SubMateria") {
                        FuncionListarReporteSubMateriaIngresos(getSReport1[0].value + "," + getSReport1[1].value + "," + getSReport1[2].value + ",c.cCas_Tab_SubMateria", document.getElementById("cMoneda").value)
                    } else if (getSReport1[3].value == "c.Asunto") {
                    } else if (getSReport1[3].value == "c.Activa") {
                    }

                } catch (error) {
                }
            }
            setTimeout(() => {
                FuncionCargarDatosTabla()
           }, 500);
        }
        //Egresos   
        else if (getSReport2[0].value == 3) {
            if (getSReport1[0].value == "c.cCas_Tab_Materia") {
                FuncionListarReporteMateriaEgresos("c.cCas_Tab_Materia", document.getElementById("cMoneda").value)
                var seriesData = []
                var labelData = []
                const DATA1 = getReporte2.map((row, i) => (
                    seriesData.push(parseFloat(row.Cantidad)))
                )
                setSeries(seriesData)
                const LABEL1 = getReporte2.map(
                    (row, i) =>
                        (labelData.push(row.Materia))
                )
                setLabel(labelData)
                try {
                    if (getSReport1[1].value == "c.nJuz_Id") {
                        FuncionListarReporteJuzgadoEgresos(getSReport1[0].value + ",c.nJuz_Id", document.getElementById("cMoneda").value)
                    } else if (getSReport1[1].value == "c.nCli_Id") {
                        FuncionListarReporteClienteEgresos(getSReport1[0].value + ",c.nCli_Id", document.getElementById("cMoneda").value)
                    } else if (getSReport1[1].value == "c.cCas_Tab_SubMateria") {
                        FuncionListarReporteSubMateriaEgresos(getSReport1[0].value + ",c.cCas_Tab_SubMateria", document.getElementById("cMoneda").value)
                    } else if (getSReport1[1].value == "c.Asunto") {
                    } else if (getSReport1[1].value == "c.Activa") {
                    }
                    if (getSReport1[2].value == "c.nJuz_Id") {
                        FuncionListarReporteJuzgadoEgresos(getSReport1[0].value + "," + getSReport1[1].value + ",c.nJuz_Id", document.getElementById("cMoneda").value)
                    } else if (getSReport1[2].value == "c.nCli_Id") {
                        FuncionListarReporteClienteEgresos(getSReport1[0].value + "," + getSReport1[1].value + ",c.nCli_Id", document.getElementById("cMoneda").value)
                    } else if (getSReport1[2].value == "c.cCas_Tab_SubMateria") {
                        FuncionListarReporteSubMateriaEgresos(getSReport1[0].value + "," + getSReport1[1].value + ",c.cCas_Tab_SubMateria", document.getElementById("cMoneda").value)
                    } else if (getSReport1[2].value == "c.Asunto") {
                    } else if (getSReport1[2].value == "c.Activa") {
                    }
                    if (getSReport1[3].value == "c.nJuz_Id") {
                        FuncionListarReporteJuzgadoEgresos(getSReport1[0].value + "," + getSReport1[1].value + "," + getSReport1[2].value + ",c.nJuz_Id", document.getElementById("cMoneda").value)
                    } else if (getSReport1[3].value == "c.nCli_Id") {
                        FuncionListarReporteClienteEgresos(getSReport1[0].value + "," + getSReport1[1].value + "," + getSReport1[2].value + ",c.nCli_Id", document.getElementById("cMoneda").value)
                    } else if (getSReport1[3].value == "c.cCas_Tab_SubMateria") {
                        FuncionListarReporteSubMateriaEgresos(getSReport1[0].value + "," + getSReport1[1].value + "," + getSReport1[2].value + ",c.cCas_Tab_SubMateria", document.getElementById("cMoneda").value)
                    } else if (getSReport1[3].value == "c.Asunto") {
                    } else if (getSReport1[3].value == "c.Activa") {
                    }
                } catch (error) {
                }
            } else if (getSReport1[0].value == "c.nJuz_Id") {
                
                FuncionListarReporteJuzgadoEgresos('c.nJuz_Id', document.getElementById("cMoneda").value)
                var seriesData = []
                var labelData = []
                const DATA1 = getReporte1.map((row, i) => (
                    seriesData.push(parseFloat(row.Cantidad)))
                )
                setSeries(seriesData)
                const LABEL1 = getReporte1.map(
                    (row, i) =>
                        (labelData.push(row.Juzgado))
                )
                setLabel(labelData)
                try {
                    if (getSReport1[1].value == "c.cCas_Tab_Materia") {
                        FuncionListarReporteMateriaEgresos(getSReport1[0].value + ",c.cCas_Tab_Materia", document.getElementById("cMoneda").value)
                    } else if (getSReport1[1].value == "c.nCli_Id") {
                        FuncionListarReporteClienteEgresos(getSReport1[0].value + ",c.nCli_Id", document.getElementById("cMoneda").value)
                    } else if (getSReport1[1].value == "c.cCas_Tab_SubMateria") {
                        FuncionListarReporteSubMateriaEgresos(getSReport1[0].value + ",c.cCas_Tab_SubMateria", document.getElementById("cMoneda").value)
                    } else if (getSReport1[1].value == "c.Asunto") {
                    } else if (getSReport1[1].value == "c.Activa") {
                    }
                    if (getSReport1[2].value == "c.cCas_Tab_Materia") {
                        FuncionListarReporteMateriaEgresos(getSReport1[0].value + "," + getSReport1[1].value + ",c.cCas_Tab_Materia", document.getElementById("cMoneda").value)
                    } else if (getSReport1[2].value == "c.nCli_Id") {
                        FuncionListarReporteClienteEgresos(getSReport1[0].value + "," + getSReport1[1].value + ",c.nCli_Id", document.getElementById("cMoneda").value)
                    } else if (getSReport1[2].value == "c.cCas_Tab_SubMateria") {
                        FuncionListarReporteSubMateriaEgresos(getSReport1[0].value + "," + getSReport1[1].value + ",c.cCas_Tab_SubMateria", document.getElementById("cMoneda").value)
                    } else if (getSReport1[2].value == "c.Asunto") {
                    } else if (getSReport1[2].value == "c.Activa") {
                    }
                    if (getSReport1[3].value == "c.cCas_Tab_Materia") {
                        FuncionListarReporteMateriaEgresos(getSReport1[0].value + "," + getSReport1[1].value + "," + getSReport1[2].value + ",c.cCas_Tab_Materia", document.getElementById("cMoneda").value)
                    } else if (getSReport1[3].value == "c.nCli_Id") {
                        FuncionListarReporteClienteEgresos(getSReport1[0].value + "," + getSReport1[1].value + "," + getSReport1[2].value + ",c.nCli_Id", document.getElementById("cMoneda").value)
                    } else if (getSReport1[3].value == "c.cCas_Tab_SubMateria") {
                        FuncionListarReporteSubMateriaEgresos(getSReport1[0].value + "," + getSReport1[1].value + "," + getSReport1[2].value + ",c.cCas_Tab_SubMateria", document.getElementById("cMoneda").value)
                    } else if (getSReport1[3].value == "c.Asunto") {
                    } else if (getSReport1[3].value == "c.Activa") {
                    }

                } catch (error) {
                }

            } else if (getSReport1[0].value == "c.cCas_Tab_SubMateria") {
                FuncionListarReporteSubMateriaEgresos('c.cCas_Tab_SubMateria', document.getElementById("cMoneda").value)
                var seriesData = []
                var labelData = []
                const DATA1 = getReporte4.map((row, i) => (
                    seriesData.push(parseFloat(row.Cantidad)))
                )
                setSeries(seriesData)
                const LABEL1 = getReporte4.map(
                    (row, i) =>
                        (labelData.push(row.SubMateria))
                )
                setLabel(labelData)
                try {
                    if (getSReport1[1].value == "c.cCas_Tab_Materia") {
                        FuncionListarReporteMateriaEgresos(getSReport1[0].value + ",c.cCas_Tab_Materia", document.getElementById("cMoneda").value)
                    } else if (getSReport1[1].value == "c.nCli_Id") {
                        FuncionListarReporteClienteEgresos(getSReport1[0].value + ",c.nCli_Id", document.getElementById("cMoneda").value)
                    } else if (getSReport1[1].value == "c.nJuz_Id") {
                        FuncionListarReporteJuzgadoEgresos(getSReport1[0].value + ",c.nJuz_Id", document.getElementById("cMoneda").value)
                    } else if (getSReport1[1].value == "c.Asunto") {
                    } else if (getSReport1[1].value == "c.Activa") {
                    }
                    if (getSReport1[2].value == "c.cCas_Tab_Materia") {
                        FuncionListarReporteMateriaEgresos(getSReport1[0].value + "," + getSReport1[1].value + ",c.cCas_Tab_Materia", document.getElementById("cMoneda").value)
                    } else if (getSReport1[2].value == "c.nCli_Id") {
                        FuncionListarReporteClienteEgresos(getSReport1[0].value + "," + getSReport1[1].value + ",c.nCli_Id", document.getElementById("cMoneda").value)
                    } else if (getSReport1[2].value == "c.nJuz_Id") {
                        FuncionListarReporteJuzgadoEgresos(getSReport1[0].value + "," + getSReport1[1].value + ",c.nJuz_Id", document.getElementById("cMoneda").value)
                    } else if (getSReport1[2].value == "c.Asunto") {
                    } else if (getSReport1[2].value == "c.Activa") {
                    }
                    if (getSReport1[3].value == "c.cCas_Tab_Materia") {
                        FuncionListarReporteMateriaEgresos(getSReport1[0].value + "," + getSReport1[1].value + "," + getSReport1[2].value + ",c.cCas_Tab_Materia", document.getElementById("cMoneda").value)
                    } else if (getSReport1[3].value == "c.nCli_Id") {
                        FuncionListarReporteClienteEgresos(getSReport1[0].value + "," + getSReport1[1].value + "," + getSReport1[2].value + ",c.nCli_Id", document.getElementById("cMoneda").value)
                    } else if (getSReport1[3].value == "c.nJuz_Id") {
                        FuncionListarReporteJuzgadoEgresos(getSReport1[0].value + "," + getSReport1[1].value + "," + getSReport1[2].value + ",c.nJuz_Id", document.getElementById("cMoneda").value)
                    } else if (getSReport1[3].value == "c.Asunto") {
                    } else if (getSReport1[3].value == "c.Activa") {
                    }
                } catch (error) {
                }

            } else if (getSReport1[0].value == "c.nCli_Id") {
                FuncionListarReporteClienteEgresos('c.nCli_Id', document.getElementById("cMoneda").value)
                var seriesData = []
                var labelData = []
                const DATA1 = getReporte3.map((row, i) => (
                    seriesData.push(parseFloat(row.Cantidad)))
                )
                setSeries(seriesData)
                const LABEL1 = getReporte3.map(
                    (row, i) =>
                        (labelData.push(row.Cliente))
                )
                setLabel(labelData)
                try {
                    if (getSReport1[1].value == "c.cCas_Tab_Materia") {
                        FuncionListarReporteMateriaEgresos(getSReport1[0].value + ",c.cCas_Tab_Materia", document.getElementById("cMoneda").value)
                    } else if (getSReport1[1].value == "c.nJuz_Id") {
                        FuncionListarReporteJuzgadoEgresos(getSReport1[0].value + ",c.nJuz_Id", document.getElementById("cMoneda").value)
                    } else if (getSReport1[1].value == "c.cCas_Tab_SubMateria") {
                        FuncionListarReporteSubMateriaEgresos(getSReport1[0].value + ",c.cCas_Tab_SubMateria", document.getElementById("cMoneda").value)
                    } else if (getSReport1[1].value == "c.Asunto") {
                    } else if (getSReport1[1].value == "c.Activa") {
                    }
                    if (getSReport1[2].value == "c.cCas_Tab_Materia") {
                        FuncionListarReporteMateriaEgresos(getSReport1[0].value + "," + getSReport1[1].value + ",c.cCas_Tab_Materia", document.getElementById("cMoneda").value)
                    } else if (getSReport1[2].value == "c.nJuz_Id") {
                        FuncionListarReporteJuzgadoEgresos(getSReport1[0].value + "," + getSReport1[1].value + ",c.nJuz_Id", document.getElementById("cMoneda").value)
                    } else if (getSReport1[2].value == "c.cCas_Tab_SubMateria") {
                        FuncionListarReporteSubMateriaEgresos(getSReport1[0].value + "," + getSReport1[1].value + ",c.cCas_Tab_SubMateria", document.getElementById("cMoneda").value)
                    } else if (getSReport1[2].value == "c.Asunto") {
                    } else if (getSReport1[2].value == "c.Activa") {
                    }
                    if (getSReport1[3].value == "c.cCas_Tab_Materia") {
                        FuncionListarReporteMateriaEgresos(getSReport1[0].value + "," + getSReport1[1].value + "," + getSReport1[2].value + ",c.cCas_Tab_Materia", document.getElementById("cMoneda").value)
                    } else if (getSReport1[3].value == "c.nJuz_Id") {
                        FuncionListarReporteJuzgadoEgresos(getSReport1[0].value + "," + getSReport1[1].value + "," + getSReport1[2].value + ",c.nJuz_Id", document.getElementById("cMoneda").value)
                    } else if (getSReport1[3].value == "c.cCas_Tab_SubMateria") {
                        FuncionListarReporteSubMateriaEgresos(getSReport1[0].value + "," + getSReport1[1].value + "," + getSReport1[2].value + ",c.cCas_Tab_SubMateria", document.getElementById("cMoneda").value)
                    } else if (getSReport1[3].value == "c.Asunto") {
                    } else if (getSReport1[3].value == "c.Activa") {
                    }

                } catch (error) {
                }
            }
            setTimeout(() => {
                FuncionCargarDatosTabla()
           }, 500);
        }
    }
    const handleChangeSelect1 = selectedOption => {
        
        if (selectedOption == null) {
            setSReport1([{
                value: 0, label: ""
            }]);
        } else {
            setSReport1(selectedOption);
        }
    };
    const handleChangeSelect2 = selectedOption => {
        
        if (selectedOption == null) {
            setSReport2([{
                value: 0, label: ""
            }]);
        } else {
            setSReport2(selectedOption);
        }
    };
    const ordenar = function (array, head) {
        

        if(head=='Materia'){
            if(numClick==0){
                numClick=1;
                //Filtrado de forma ascendente
                var newArray = array[0]["NProcesos"][0]["Materias"].sort((a,b)=> (a.Materia > b.Materia ? 1 : -1))
            }
            else if(numClick==1){
                numClick=0;
                 //Filtrado de forma descendente
                var newArray = array[0]["NProcesos"][0]["Materias"].sort((a,b)=> (a.Materia < b.Materia ? 1 : -1))
            }
            var data = {
                Materias: newArray
            };
            
    

            var array2 = delete array[0]["NProcesos"][0]["Materias"];
            
            
            var objetoarr = array[0]["NProcesos"][0];
            objetoarr.unshift(data);
            //array[0]["NProcesos"][0].push(data);
            //array[0]["NProcesos"][0]["Materias"].push(newArray);
            //
            //SetData(nuevoarreglo);
            
          
            
            
            //SetData(array);
        }
        else if(head=='Procesos'){
            
            
        }
       
     
        
    }

    useEffect(() => {
    }, [])
    return (
        <React.Fragment>
            <div className="page-content">
                {}
                <MetaTags>
                    <title>Reporte Avanzado | GpsLegal</title>
                </MetaTags>
                <Container fluid>
                 
                    <Breadcrumbs title="GpsLegal" breadcrumbItem="Reporte Avanzado" />
                    <Row>
                        <Col className="col-12">
                            <Card className="cardBorder">
                                <CardBody >
                                    <h5 className="modal-title mt-0 mb-3" id="myModalLabel">
                                        Filtros
                                    </h5>
                                    <div className="container">
                                        <div className="mb-3 row">
                                            <div className="col-md-2">Totalizar por</div>
                                            <div className="col-md-5">
                                                <Select
                                                    name="nReport"
                                                    isMulti
                                                    onChange={handleChangeSelect1}
                                                    options={Opciones}
                                                    placeholder="Agrupados"
                                                />
                                            </div>
                                            <div className="col-md-1"></div>
                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-md-2">Mostrar en</div>
                                            <div className="col-md-5">
                                                <Select
                                                    isMulti
                                                    onChange={handleChangeSelect2}
                                                    name="colors"
                                                    options={Valores}
                                                    placeholder="Valores"
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-md-2">Moneda</div>
                                            <div className="col-md-5">
                                                <select
                                                    className="form-select"
                                                    id="cMoneda"
                                                >
                                                    <option value="0">Seleccionar...</option>
                                                    <option value="MO01">Soles</option>
                                                    <option value="MO02">Dolares</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mb-3 row" style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <div className="col-md-1">
                                                <button
                                                    type="button"
                                                    onClick={() => FuncionClick()}
                                                    className="btn btn-primary waves-effect waves-light">
                                                    Consultar
                                                </button>
                                            </div>
                                            <div className="col-md-2">
                                                <i className="icon-excel excel" style={{marginLeft:'11px',marginTop:'12px'}}></i>
                                                <ReactHTMLTableToExcel
                                                    type="button"
                                                    className="btn btn-success"
                                                    table="idReporte"
                                                    filename="ReporteAvanzado"
                                                    sheet="ReporteAvanzado"
                                                    buttonText="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Excel"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <Row>
                                        <Col lg={6}>
                                            <Card>
                                                <CardBody>
                                                    <CardTitle className="mb-4">
                                                        {getSReport2[0].label} por {getSReport1[0].label}{" "}
                                                    </CardTitle>
                                                    <ReactApexChart
                                                        options={options}
                                                        series={series}
                                                        type="pie"
                                                        height={400}
                                                    />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg={6}>
                                            <Card>
                                                <CardBody>
                                                    <ReactApexChart
                                                        options={options2}
                                                        series={series2}
                                                        type="bar"
                                                        height="350"
                                                        className="apex-charts"
                                                    />
                                                    <div align='center'>
                                                        <p className="mb-4 center">{getSReport2[0].label}</p>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row> 
                                    <div className="">
                                        <table  id="idReporte" className="table table-bordered table-fixed table-hover dataTable">
                                            <tr style={{  fontSize:"18px",position: "sticky", top: "0", zIndex: "1",backgroundColor: "#032D5E",color: "white",border: "1px solid #dfd2d2" }}>
                                                {getSReport1.map((item, i) => (
                                                    <>
                                                        <th className="text-center" onClick={(e) => ordenar(getData, 'Materia')}>{item.label}</th>
                                                        <th className="text-center" onClick={(e) => ordenar(getData, 'Procesos')}>{getSReport2[0].label}</th>
                                                    </>
                                                ))}
                                            </tr>
                                            {getData.map((item, i) => (
                                                <tbody style={{border: "1px solid inherit"}}>
                                                    <If condition={getSReport2[0].value == 1}>
                                                        <Then>
                                                            <If condition={getSReport1[0].value == "c.cCas_Tab_Materia"}>
                                                                <Then>
                                                                    {item.NProcesos[0].Materias.map((item1, i) => {
                                                                        return (
                                                                            <tr key={i}>
                                                                                <td>
                                                                                    {item1.Materia}
                                                                                </td>
                                                                                <td style={{ textAlign: "right" }}>
                                                                                    {item1.Cantidad}
                                                                                </td>
                                                                                {/* Segunda Columna */}
                                                                                {(() => {
                                                                                    try {
                                                                                        if (getSReport1[1].value == "c.nCli_Id") {
                                                                                            return (<>
                                                                                                <td >{item.NProcesos[0].Clientes.map((item3, i) => {
                                                                                                    if (item1.Materia == item3.Materia) {
                                                                                                        
                                                                                                        return (
                                                                                                            <p>{item3.Cliente}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                                <td style={{ textAlign: "right" }}>{item.NProcesos[0].Clientes.map((item3, i) => {
                                                                                                    if (item1.Materia == item3.Materia) {
                                                                                                        return (
                                                                                                            <p>{item3.Cantidad}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                            </>)
                                                                                        } else if (getSReport1[1].value == "c.nJuz_Id") {
                                                                                            return (<>
                                                                                                <td>{item.NProcesos[0].Juzgados.map((item2, i) => {
                                                                                                    if (item2.Materia == item1.Materia) {
                                                                                                        return (
                                                                                                            <p>{item2.Juzgado}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                                <td style={{ textAlign: "right" }}>{item.NProcesos[0].Juzgados.map((item2, i) => {
                                                                                                    if (item2.Materia == item1.Materia) {
                                                                                                        return (
                                                                                                            <p>{item2.Cantidad}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                            </>)

                                                                                        } else if (getSReport1[1].value == "c.cCas_Tab_SubMateria") {
                                                                                            return (<>
                                                                                                <td>{item.NProcesos[0].SubMaterias.map((item2, i) => {
                                                                                                    if (item2.Materia == item1.Materia) {
                                                                                                        return (
                                                                                                            <p>{item2.SubMateria}ddsss</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                                <td style={{ textAlign: "right" }}>{item.NProcesos[0].SubMaterias.map((item2, i) => {
                                                                                                    if (item2.Materia == item1.Materia) {
                                                                                                        return (
                                                                                                            <p>{item2.Cantidad}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                            </>)
                                                                                        }
                                                                                    } catch (error) {
                                                                                    }
                                                                                })()}
                                                                                {/* ----------------------- */}
                                                                                {/* Tercera Columna */}
                                                                                {(() => {
                                                                                    try {
                                                                                        if (getSReport1[2].value == "c.nCli_Id") {
                                                                                            return (<>
                                                                                                <td >{item.NProcesos[0].Clientes.map((item3, i) => {
                                                                                                    if (item1.Materia == item3.Materia) {
                                                                                                        return (
                                                                                                            <p>{item3.Cliente}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                                <td style={{ textAlign: "right" }}>{item.NProcesos[0].Clientes.map((item3, i) => {
                                                                                                    if (item1.Materia == item3.Materia) {
                                                                                                        return (
                                                                                                            <p>{item3.Cantidad}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                            </>)
                                                                                        } else if (getSReport1[2].value == "c.nJuz_Id") {
                                                                                            return (<>
                                                                                                <td>{item.NProcesos[0].Juzgados.map((item2, i) => {
                                                                                                    if (item2.Materia == item1.Materia) {
                                                                                                        return (
                                                                                                            <p>{item2.Juzgado}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                                <td style={{ textAlign: "right" }}>{item.NProcesos[0].Juzgados.map((item2, i) => {
                                                                                                    if (item2.Materia == item1.Materia) {
                                                                                                        return (
                                                                                                            <p>{item2.Cantidad}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                            </>)
                                                                                            s
                                                                                        } else if (getSReport1[2].value == "c.cCas_Tab_SubMateria") {
                                                                                            return (<>
                                                                                                <td>{item.NProcesos[0].SubMaterias.map((item2, i) => {
                                                                                                    if (item2.Materia == item1.Materia) {
                                                                                                        return (
                                                                                                            <p>{item2.SubMateria}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                                <td style={{ textAlign: "right" }}>{item.NProcesos[0].SubMaterias.map((item2, i) => {
                                                                                                    if (item2.Materia == item1.Materia) {
                                                                                                        return (
                                                                                                            <p>{item2.Cantidad}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                            </>)
                                                                                        }
                                                                                    } catch (error) {
                                                                                    }
                                                                                })()}
                                                                                {/* ----------------------- */}
                                                                                {/* Cuarta Columna */}
                                                                                {(() => {
                                                                                    try {
                                                                                        if (getSReport1[3].value == "c.nCli_Id") {
                                                                                            return (<>
                                                                                                <td >{item.NProcesos[0].Clientes.map((item3, i) => {
                                                                                                    if (item1.Materia == item3.Materia) {
                                                                                                        return (
                                                                                                            <p>{item3.Cliente}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                                <td style={{ textAlign: "right" }}>{item.NProcesos[0].Clientes.map((item3, i) => {
                                                                                                    if (item1.Materia == item3.Materia) {
                                                                                                        return (
                                                                                                            <p>{item3.Cantidad}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                            </>)
                                                                                        } else if (getSReport1[3].value == "c.nJuz_Id") {
                                                                                            return (<>
                                                                                                <td>{item.NProcesos[0].Juzgados.map((item2, i) => {
                                                                                                    if (item2.Materia == item1.Materia) {
                                                                                                        return (
                                                                                                            <p>{item2.Juzgado}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                                <td style={{ textAlign: "right" }}>{item.NProcesos[0].Juzgados.map((item2, i) => {
                                                                                                    if (item2.Materia == item1.Materia) {
                                                                                                        return (
                                                                                                            <p>{item2.Cantidad}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                            </>)
                                                                                        } else if (getSReport1[3].value == "c.cCas_Tab_SubMateria") {
                                                                                            return (<>
                                                                                                <td>{item.NProcesos[0].SubMaterias.map((item2, i) => {
                                                                                                    if (item2.Materia == item1.Materia) {
                                                                                                        return (
                                                                                                            <p>{item2.SubMateria}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                                <td style={{ textAlign: "right" }}>{item.NProcesos[0].SubMaterias.map((item2, i) => {
                                                                                                    if (item2.Materia == item1.Materia) {
                                                                                                        return (
                                                                                                            <p>{item2.Cantidad}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                            </>)
                                                                                        }
                                                                                    } catch (error) {
                                                                                    }
                                                                                })()}
                                                                            </tr>)
                                                                    })
                                                                    }
                                                                </Then>
                                                                <ElseIf condition={getSReport1[0].value == "c.nJuz_Id"}>
                                                                    <Then>
                                                                        {item.NProcesos[0].Juzgados.map((item1, i) => {
                                                                            return (
                                                                                <tr key={i}>
                                                                                    <td>
                                                                                        {item1.Juzgado}
                                                                                    </td>
                                                                                    <td style={{ textAlign: "right" }}>
                                                                                        {item1.Cantidad}
                                                                                    </td>
                                                                                    {/* Segunda Columna */}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[1].value == "c.cCas_Tab_Materia") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.NProcesos[0].Materias.map((item2, i) => {
                                                                                                            if (item2.Juzgado == item1.Juzgado) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Materia}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.NProcesos[0].Materias.map((item2, i) => {
                                                                                                            if (item2.Juzgado == item1.Juzgado) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Cantidad}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[1].value == "c.nCli_Id") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.NProcesos[0].Clientes.map((item2, i) => {
                                                                                                            if (item2.Juzgado == item1.Juzgado) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Cliente}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.NProcesos[0].Clientes.map((item2, i) => {
                                                                                                            if (item2.Juzgado == item1.Juzgado) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Cantidad}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[1].value == "c.cCas_Tab_SubMateria") {
                                                                                                return (<>
                                                                                                    <td>{item.NProcesos[0].SubMaterias.map((item2, i) => {
                                                                                                        if (item2.Juzgado == item1.Juzgado) {
                                                                                                            return (
                                                                                                                <p>{item2.SubMateria}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    })}</td>
                                                                                                    <td style={{ textAlign: "right" }}>{item.NProcesos[0].SubMaterias.map((item2, i) => {
                                                                                                        if (item2.Juzgado == item1.Juzgado) {
                                                                                                            return (
                                                                                                                <p>{item2.Cantidad}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    })}</td>
                                                                                                </>)
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {/* ----------------------- */}
                                                                                    {/* Tercera Columna */}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[2].value == "c.nCli_Id") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.NProcesos[0].Clientes.map((item2, i) => {
                                                                                                            if (item2.Juzgado == item1.Juzgado) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Cliente}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.NProcesos[0].Clientes.map((item2, i) => {
                                                                                                            if (item2.Juzgado == item1.Juzgado) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Cantidad}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[2].value == "c.cCas_Tab_Materia") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.NProcesos[0].Materias.map((item2, i) => {
                                                                                                            if (item2.Juzgado == item1.Juzgado) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Materia}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.NProcesos[0].Materias.map((item2, i) => {
                                                                                                            if (item2.Juzgado == item1.Juzgado) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Cantidad}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[2].value == "c.cCas_Tab_SubMateria") {
                                                                                                return (<>
                                                                                                    <td>{item.NProcesos[0].SubMaterias.map((item2, i) => {
                                                                                                        if (item2.Juzgado == item1.Juzgado) {
                                                                                                            return (
                                                                                                                <p>{item2.SubMateria}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    })}</td>
                                                                                                    <td style={{ textAlign: "right" }}>{item.NProcesos[0].SubMaterias.map((item2, i) => {
                                                                                                        if (item2.Juzgado == item1.Juzgado) {
                                                                                                            return (
                                                                                                                <p>{item2.Cantidad}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    })}</td>
                                                                                                </>)
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {/* ----------------------- */}
                                                                                    {/* Cuarta Columna */}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[3].value == "c.nCli_Id") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.NProcesos[0].Clientes.map((item2, i) => {
                                                                                                            if (item2.Juzgado == item1.Juzgado) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Cliente}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.NProcesos[0].Clientes.map((item2, i) => {
                                                                                                            if (item2.Juzgado == item1.Juzgado) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Cantidad}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[3].value == "c.cCas_Tab_Materia") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.NProcesos[0].Materias.map((item2, i) => {
                                                                                                            if (item2.Juzgado == item1.Juzgado) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Materia}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.NProcesos[0].Materias.map((item2, i) => {
                                                                                                            if (item2.Juzgado == item1.Juzgado) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Cantidad}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[3].value == "c.cCas_Tab_SubMateria") {
                                                                                                return (<>
                                                                                                    <td>{item.NProcesos[0].SubMaterias.map((item2, i) => {
                                                                                                        if (item2.Juzgado == item1.Juzgado) {
                                                                                                            return (
                                                                                                                <p>{item2.SubMateria}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    })}</td>
                                                                                                    <td style={{ textAlign: "right" }}>{item.NProcesos[0].SubMaterias.map((item2, i) => {
                                                                                                        if (item2.Juzgado == item1.Juzgado) {
                                                                                                            return (
                                                                                                                <p>{item2.Cantidad}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    })}</td>
                                                                                                </>)
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                </tr>
                                                                            )
                                                                        })
                                                                        }
                                                                    </Then>
                                                                </ElseIf>
                                                                <ElseIf condition={getSReport1[0].value == "c.nCli_Id"}>
                                                                    <Then>
                                                                        {item.NProcesos[0].Clientes.map((item1, i) => {
                                                                            return (
                                                                                <tr key={i}>
                                                                                    <td>
                                                                                        {item1.Cliente}
                                                                                    </td>
                                                                                    <td style={{ textAlign: "right" }}>
                                                                                        {item1.Cantidad}
                                                                                    </td>
                                                                                    {/* Segunda Columna */}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[1].value == "c.cCas_Tab_Materia") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.NProcesos[0].Materias.map((item2, i) => {
                                                                                                            
                                                                                                            if (item2.Cliente == item1.Cliente) {
                                                                                                                return (
                                                                                                                    <p>{item2.Materia}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td>{item.NProcesos[0].Materias.map((item2, i) => {
                                                                                                            
                                                                                                            if (item2.Cliente == item1.Cliente) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cantidad}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[1].value == "c.nJuz_Id") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.NProcesos[0].Juzgados.map((item2, i) => {
                                                                                                            if (item2.Cliente == item1.Cliente) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Juzgado}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.NProcesos[0].Juzgados.map((item2, i) => {
                                                                                                            if (item2.Cliente == item1.Cliente) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Cantidad}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[1].value == "c.cCas_Tab_SubMateria") {
                                                                                                return (<>
                                                                                                    <td>{item.NProcesos[0].SubMaterias.map((item2, i) => {
                                                                                                        if (item2.Cliente == item1.Cliente) {
                                                                                                            return (
                                                                                                                <p>{item2.SubMateria}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    })}</td>
                                                                                                    <td style={{ textAlign: "right" }}>{item.NProcesos[0].SubMaterias.map((item2, i) => {
                                                                                                        if (item2.Cliente == item1.Cliente) {
                                                                                                            return (
                                                                                                                <p>{item2.Cantidad}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    })}</td>
                                                                                                </>)
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {/* ----------------------- */}
                                                                                    {/* Tercera Columna */}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[2].value == "c.cCas_Tab_Materia") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.NProcesos[0].Materias.map((item2, i) => {
                                                                                                            
                                                                                                            if (item2.Cliente == item1.Cliente) {
                                                                                                                return (
                                                                                                                    <p>{item2.Materia}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td>{item.NProcesos[0].Materias.map((item2, i) => {
                                                                                                            
                                                                                                            if (item2.Cliente == item1.Cliente) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cantidad}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[2].value == "c.nJuz_Id") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.NProcesos[0].Juzgados.map((item2, i) => {
                                                                                                            if (item2.Cliente == item1.Cliente) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Juzgado}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.NProcesos[0].Juzgados.map((item2, i) => {
                                                                                                            if (item2.Cliente == item1.Cliente) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Cantidad}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[2].value == "c.cCas_Tab_SubMateria") {
                                                                                                return (<>
                                                                                                    <td>{item.NProcesos[0].SubMaterias.map((item2, i) => {
                                                                                                        if (item2.Cliente == item1.Cliente) {
                                                                                                            return (
                                                                                                                <p>{item2.SubMateria}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    })}</td>
                                                                                                    <td style={{ textAlign: "right" }}>{item.NProcesos[0].SubMaterias.map((item2, i) => {
                                                                                                        if (item2.Cliente == item1.Cliente) {
                                                                                                            return (
                                                                                                                <p>{item2.Cantidad}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    })}</td>
                                                                                                </>)
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {/* ----------------------- */}
                                                                                    {/* Cuarta Columna */}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[3].value == "c.cCas_Tab_Materia") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.NProcesos[0].Materias.map((item2, i) => {
                                                                                                            
                                                                                                            if (item2.Cliente == item1.Cliente) {
                                                                                                                return (
                                                                                                                    <p>{item2.Materia}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td>{item.NProcesos[0].Materias.map((item2, i) => {
                                                                                                            
                                                                                                            if (item2.Cliente == item1.Cliente) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cantidad}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[3].value == "c.nJuz_Id") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.NProcesos[0].Juzgados.map((item2, i) => {
                                                                                                            if (item2.Cliente == item1.Cliente) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Juzgado}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.NProcesos[0].Juzgados.map((item2, i) => {
                                                                                                            if (item2.Cliente == item1.Cliente) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Cantidad}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[3].value == "c.cCas_Tab_SubMateria") {
                                                                                                return (<>
                                                                                                    <td>{item.NProcesos[0].SubMaterias.map((item2, i) => {
                                                                                                        if (item2.Cliente == item1.Cliente) {
                                                                                                            return (
                                                                                                                <p>{item2.SubMateria}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    })}</td>
                                                                                                    <td style={{ textAlign: "right" }}>{item.NProcesos[0].SubMaterias.map((item2, i) => {
                                                                                                        if (item2.Cliente == item1.Cliente) {
                                                                                                            return (
                                                                                                                <p>{item2.Cantidad}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    })}</td>
                                                                                                </>)
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {/* ----------------------- */}

                                                                                </tr>
                                                                            )
                                                                        })
                                                                        }
                                                                    </Then>
                                                                </ElseIf>
                                                                <ElseIf condition={getSReport1[0].value == "c.cCas_Tab_SubMateria"}>
                                                                    <Then>
                                                                        {item.NProcesos[0].SubMaterias.map((item1, i) => {
                                                                            return (
                                                                                <tr key={i}>
                                                                                    <td>
                                                                                        {item1.SubMateria}
                                                                                    </td>
                                                                                    <td style={{ textAlign: "right" }}>
                                                                                        {item1.Cantidad}
                                                                                    </td>
                                                                                    {/* Segunda Columna */}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[1].value == "c.cCas_Tab_Materia") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.NProcesos[0].Materias.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Materia}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.NProcesos[0].Materias.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cantidad}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[1].value == "c.nJuz_Id") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.NProcesos[0].Juzgados.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Juzgado}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.NProcesos[0].Juzgados.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cantidad}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[1].value == "c.nCli_Id") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.NProcesos[0].Clientes.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cliente}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.NProcesos[0].Clientes.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cantidad}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {/* ----------------------- */}
                                                                                    {/* Tercera Columna */}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[2].value == "c.cCas_Tab_Materia") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.NProcesos[0].Materias.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Materia}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.NProcesos[0].Materias.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cantidad}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[2].value == "c.nJuz_Id") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.NProcesos[0].Juzgados.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Juzgado}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td>{item.NProcesos[0].Juzgados.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cantidad}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[2].value == "c.nCli_Id") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.NProcesos[0].Clientes.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cliente}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td>{item.NProcesos[0].Clientes.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cantidad}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {/* ----------------------- */}
                                                                                    {/* Cuarta Columna */}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[3].value == "c.cCas_Tab_Materia") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.NProcesos[0].Materias.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Materia}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.NProcesos[0].Materias.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cantidad}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[3].value == "c.nJuz_Id") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.NProcesos[0].Juzgados.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Juzgado}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.NProcesos[0].Juzgados.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cantidad}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[3].value == "c.nCli_Id") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.NProcesos[0].Clientes.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cliente}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.NProcesos[0].Clientes.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cantidad}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {/* ----------------------- */}
                                                                                </tr>
                                                                            )
                                                                        })
                                                                        }
                                                                    </Then>
                                                                </ElseIf>
                                                            </If>
                                                        </Then>
                                                        <ElseIf condition={getSReport2[0].value == 2}>
                                                            <If condition={getSReport1[0].value == "c.cCas_Tab_Materia"}>
                                                                <Then>
                                                                    {item.Ingresos[0].Materias.map((item1, i) => {
                                                                        return (
                                                                            <tr key={i}>
                                                                                <td>
                                                                                    {item1.Materia}
                                                                                </td>
                                                                                <td style={{ textAlign: "right" }}>
                                                                                    {item1.Cantidad}
                                                                                </td>
                                                                                {/* Segunda Columna */}
                                                                                {(() => {
                                                                                    try {
                                                                                        if (getSReport1[1].value == "c.nCli_Id") {
                                                                                            return (<>
                                                                                                <td >{item.Ingresos[0].Clientes.map((item3, i) => {
                                                                                                    if (item1.Materia == item3.Materia) {
                                                                                                        
                                                                                                        return (
                                                                                                            <p>{item3.Cliente}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                                <td style={{ textAlign: "right" }}>{item.Ingresos[0].Clientes.map((item3, i) => {
                                                                                                    if (item1.Materia == item3.Materia) {
                                                                                                        return (
                                                                                                            <p>{item3.Cantidad}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                            </>)
                                                                                        } else if (getSReport1[1].value == "c.nJuz_Id") {
                                                                                            return (<>
                                                                                                <td>{item.Ingresos[0].Juzgados.map((item2, i) => {
                                                                                                    if (item2.Materia == item1.Materia) {
                                                                                                        return (
                                                                                                            <p>{item2.Juzgado}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                                <td style={{ textAlign: "right" }}>{item.Ingresos[0].Juzgados.map((item2, i) => {
                                                                                                    if (item2.Materia == item1.Materia) {
                                                                                                        return (
                                                                                                            <p>{item2.Cantidad}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                            </>)

                                                                                        } else if (getSReport1[1].value == "c.cCas_Tab_SubMateria") {
                                                                                            return (<>
                                                                                                <td>{item.Ingresos[0].SubMaterias.map((item2, i) => {
                                                                                                    if (item2.Materia == item1.Materia) {
                                                                                                        return (
                                                                                                            <p>{item2.SubMateria}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                                <td style={{ textAlign: "right" }}>{item.Ingresos[0].SubMaterias.map((item2, i) => {
                                                                                                    if (item2.Materia == item1.Materia) {
                                                                                                        return (
                                                                                                            <p>{item2.Cantidad}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                            </>)
                                                                                        }
                                                                                    } catch (error) {
                                                                                    }
                                                                                })()}
                                                                                {/* ----------------------- */}
                                                                                {/* Tercera Columna */}
                                                                                {(() => {
                                                                                    try {
                                                                                        if (getSReport1[2].value == "c.nCli_Id") {
                                                                                            return (<>
                                                                                                <td >{item.Ingresos[0].Clientes.map((item3, i) => {
                                                                                                    if (item1.Materia == item3.Materia) {
                                                                                                        return (
                                                                                                            <p>{item3.Cliente}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                                <td style={{ textAlign: "right" }}>{item.Ingresos[0].Clientes.map((item3, i) => {
                                                                                                    if (item1.Materia == item3.Materia) {
                                                                                                        return (
                                                                                                            <p>{item3.Cantidad}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                            </>)
                                                                                        } else if (getSReport1[2].value == "c.nJuz_Id") {
                                                                                            return (<>
                                                                                                <td>{item.Ingresos[0].Juzgados.map((item2, i) => {
                                                                                                    if (item2.Materia == item1.Materia) {
                                                                                                        return (
                                                                                                            <p>{item2.Juzgado}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                                <td style={{ textAlign: "right" }}>{item.Ingresos[0].Juzgados.map((item2, i) => {
                                                                                                    if (item2.Materia == item1.Materia) {
                                                                                                        return (
                                                                                                            <p>{item2.Cantidad}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                            </>)
                                                                                        } else if (getSReport1[2].value == "c.cCas_Tab_SubMateria") {
                                                                                            return (<>
                                                                                                <td>{item.Ingresos[0].SubMaterias.map((item2, i) => {
                                                                                                    if (item2.Materia == item1.Materia) {
                                                                                                        return (
                                                                                                            <p>{item2.SubMateria}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                                <td style={{ textAlign: "right" }}>{item.Ingresos[0].SubMaterias.map((item2, i) => {
                                                                                                    if (item2.Materia == item1.Materia) {
                                                                                                        return (
                                                                                                            <p>{item2.Cantidad}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                            </>)
                                                                                        }
                                                                                    } catch (error) {
                                                                                    }
                                                                                })()}
                                                                                {/* ----------------------- */}
                                                                                {/* Cuarta Columna */}
                                                                                {(() => {
                                                                                    try {
                                                                                        if (getSReport1[3].value == "c.nCli_Id") {
                                                                                            return (<>
                                                                                                <td >{item.Ingresos[0].Clientes.map((item3, i) => {
                                                                                                    if (item1.Materia == item3.Materia) {
                                                                                                        return (
                                                                                                            <p>{item3.Cliente}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                                <td style={{ textAlign: "right" }}>{item.Ingresos[0].Clientes.map((item3, i) => {
                                                                                                    if (item1.Materia == item3.Materia) {
                                                                                                        return (
                                                                                                            <p>{item3.Cantidad}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                            </>)
                                                                                        } else if (getSReport1[3].value == "c.nJuz_Id") {
                                                                                            return (<>
                                                                                                <td>{item.Ingresos[0].Juzgados.map((item2, i) => {
                                                                                                    if (item2.Materia == item1.Materia) {
                                                                                                        return (
                                                                                                            <p>{item2.Juzgado}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                                <td style={{ textAlign: "right" }}>{item.Ingresos[0].Juzgados.map((item2, i) => {
                                                                                                    if (item2.Materia == item1.Materia) {
                                                                                                        return (
                                                                                                            <p>{item2.Cantidad}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                            </>)
                                                                                        } else if (getSReport1[3].value == "c.cCas_Tab_SubMateria") {
                                                                                            return (<>
                                                                                                <td>{item.Ingresos[0].SubMaterias.map((item2, i) => {
                                                                                                    if (item2.Materia == item1.Materia) {
                                                                                                        return (
                                                                                                            <p>{item2.SubMateria}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                                <td style={{ textAlign: "right" }}>{item.Ingresos[0].SubMaterias.map((item2, i) => {
                                                                                                    if (item2.Materia == item1.Materia) {
                                                                                                        return (
                                                                                                            <p>{item2.Cantidad}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                            </>)
                                                                                        }
                                                                                    } catch (error) {
                                                                                    }
                                                                                })()}
                                                                            </tr>)
                                                                    })
                                                                    }
                                                                </Then>
                                                                <ElseIf condition={getSReport1[0].value == "c.nJuz_Id"}>
                                                                    <Then>
                                                                        {item.Ingresos[0].Juzgados.map((item1, i) => {
                                                                            return (
                                                                                <tr key={i}>
                                                                                    <td>
                                                                                        {item1.Juzgado}
                                                                                    </td>
                                                                                    <td style={{ textAlign: "right" }}>
                                                                                        {item1.Cantidad}
                                                                                    </td>
                                                                                    {/* Segunda Columna */}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[1].value == "c.cCas_Tab_Materia") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Ingresos[0].Materias.map((item2, i) => {
                                                                                                            if (item2.Juzgado == item1.Juzgado) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Materia}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.Ingresos[0].Materias.map((item2, i) => {
                                                                                                            if (item2.Juzgado == item1.Juzgado) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Cantidad}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[1].value == "c.nCli_Id") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Ingresos[0].Clientes.map((item2, i) => {
                                                                                                            if (item2.Juzgado == item1.Juzgado) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Cliente}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.Ingresos[0].Clientes.map((item2, i) => {
                                                                                                            if (item2.Juzgado == item1.Juzgado) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Cantidad}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[1].value == "c.cCas_Tab_SubMateria") {
                                                                                                return (<>
                                                                                                    <td>{item.Ingresos[0].SubMaterias.map((item2, i) => {
                                                                                                        if (item2.Juzgado == item1.Juzgado) {
                                                                                                            return (
                                                                                                                <p>{item2.SubMateria}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    })}</td>
                                                                                                    <td style={{ textAlign: "right" }}>{item.Ingresos[0].SubMaterias.map((item2, i) => {
                                                                                                        if (item2.Juzgado == item1.Juzgado) {
                                                                                                            return (
                                                                                                                <p>{item2.Cantidad}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    })}</td>
                                                                                                </>)
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {/* ----------------------- */}
                                                                                    {/* Tercera Columna */}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[2].value == "c.nCli_Id") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Ingresos[0].Clientes.map((item2, i) => {
                                                                                                            if (item2.Juzgado == item1.Juzgado) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Cliente}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.Ingresos[0].Clientes.map((item2, i) => {
                                                                                                            if (item2.Juzgado == item1.Juzgado) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Cantidad}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[2].value == "c.cCas_Tab_Materia") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Ingresos[0].Materias.map((item2, i) => {
                                                                                                            if (item2.Juzgado == item1.Juzgado) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Materia}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.Ingresos[0].Materias.map((item2, i) => {
                                                                                                            if (item2.Juzgado == item1.Juzgado) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Cantidad}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[2].value == "c.cCas_Tab_SubMateria") {
                                                                                                return (<>
                                                                                                    <td>{item.Ingresos[0].SubMaterias.map((item2, i) => {
                                                                                                        if (item2.Juzgado == item1.Juzgado) {
                                                                                                            return (
                                                                                                                <p>{item2.SubMateria}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    })}</td>
                                                                                                    <td style={{ textAlign: "right" }}>{item.Ingresos[0].SubMaterias.map((item2, i) => {
                                                                                                        if (item2.Juzgado == item1.Juzgado) {
                                                                                                            return (
                                                                                                                <p>{item2.Cantidad}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    })}</td>
                                                                                                </>)
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {/* ----------------------- */}
                                                                                    {/* Cuarta Columna */}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[3].value == "c.nCli_Id") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Ingresos[0].Clientes.map((item2, i) => {
                                                                                                            if (item2.Juzgado == item1.Juzgado) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Cliente}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.Ingresos[0].Clientes.map((item2, i) => {
                                                                                                            if (item2.Juzgado == item1.Juzgado) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Cantidad}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[3].value == "c.cCas_Tab_Materia") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Ingresos[0].Materias.map((item2, i) => {
                                                                                                            if (item2.Juzgado == item1.Juzgado) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Materia}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.Ingresos[0].Materias.map((item2, i) => {
                                                                                                            if (item2.Juzgado == item1.Juzgado) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Cantidad}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[3].value == "c.cCas_Tab_SubMateria") {
                                                                                                return (<>
                                                                                                    <td>{item.Ingresos[0].SubMaterias.map((item2, i) => {
                                                                                                        if (item2.Juzgado == item1.Juzgado) {
                                                                                                            return (
                                                                                                                <p>{item2.SubMateria}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    })}</td>
                                                                                                    <td style={{ textAlign: "right" }}>{item.Ingresos[0].SubMaterias.map((item2, i) => {
                                                                                                        if (item2.Juzgado == item1.Juzgado) {
                                                                                                            return (
                                                                                                                <p>{item2.Cantidad}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    })}</td>
                                                                                                </>)
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                </tr>
                                                                            )
                                                                        })
                                                                        }
                                                                    </Then>
                                                                </ElseIf>
                                                                <ElseIf condition={getSReport1[0].value == "c.cCas_Tab_SubMateria"}>
                                                                    <Then>
                                                                        {item.Ingresos[0].SubMaterias.map((item1, i) => {
                                                                            return (
                                                                                <tr key={i}>
                                                                                    <td>
                                                                                        {item1.SubMateria}
                                                                                    </td>
                                                                                    <td style={{ textAlign: "right" }}>
                                                                                        {item1.Cantidad}
                                                                                    </td>
                                                                                    {/* Segunda Columna */}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[1].value == "c.cCas_Tab_Materia") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Ingresos[0].Materias.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Materia}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.Ingresos[0].Materias.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cantidad}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[1].value == "c.nJuz_Id") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Ingresos[0].Juzgados.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Juzgado}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.Ingresos[0].Juzgados.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cantidad}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[1].value == "c.nCli_Id") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Ingresos[0].Clientes.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cliente}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td>{item.Ingresos[0].Clientes.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cantidad}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {/* ----------------------- */}
                                                                                    {/* Tercera Columna */}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[2].value == "c.cCas_Tab_Materia") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Ingresos[0].Materias.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Materia}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.Ingresos[0].Materias.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cantidad}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[2].value == "c.nJuz_Id") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Ingresos[0].Juzgados.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Juzgado}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.Ingresos[0].Juzgados.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cantidad}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[2].value == "c.nCli_Id") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Ingresos[0].Clientes.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cliente}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.Ingresos[0].Clientes.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cantidad}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {/* ----------------------- */}
                                                                                    {/* Cuarta Columna */}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[3].value == "c.cCas_Tab_Materia") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Ingresos[0].Materias.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Materia}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.Ingresos[0].Materias.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cantidad}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[3].value == "c.nJuz_Id") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Ingresos[0].Juzgados.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Juzgado}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.Ingresos[0].Juzgados.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cantidad}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[3].value == "c.nCli_Id") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Ingresos[0].Clientes.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cliente}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.Ingresos[0].Clientes.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cantidad}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {/* ----------------------- */}
                                                                                </tr>
                                                                            )
                                                                        })
                                                                        }
                                                                    </Then>
                                                                </ElseIf>
                                                                <ElseIf condition={getSReport1[0].value == "c.nCli_Id"}>
                                                                    <Then>
                                                                        {item.Ingresos[0].Clientes.map((item1, i) => {
                                                                            return (
                                                                                <tr key={i}>
                                                                                    <td>
                                                                                        {item1.Cliente}
                                                                                    </td>
                                                                                    <td style={{ textAlign: "right" }}>
                                                                                        {item1.Cantidad}
                                                                                    </td>
                                                                                    {/* Segunda Columna */}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[1].value == "c.cCas_Tab_Materia") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Ingresos[0].Materias.map((item2, i) => {
                                                                                                            
                                                                                                            if (item2.Cliente == item1.Cliente) {
                                                                                                                return (
                                                                                                                    <p>{item2.Materia}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.Ingresos[0].Materias.map((item2, i) => {
                                                                                                            
                                                                                                            if (item2.Cliente == item1.Cliente) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cantidad}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[1].value == "c.nJuz_Id") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Ingresos[0].Juzgados.map((item2, i) => {
                                                                                                            if (item2.Cliente == item1.Cliente) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Juzgado}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.Ingresos[0].Juzgados.map((item2, i) => {
                                                                                                            if (item2.Cliente == item1.Cliente) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Cantidad}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[1].value == "c.cCas_Tab_SubMateria") {
                                                                                                return (<>
                                                                                                    <td>{item.Ingresos[0].SubMaterias.map((item2, i) => {
                                                                                                        if (item2.Cliente == item1.Cliente) {
                                                                                                            return (
                                                                                                                <p>{item2.SubMateria}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    })}</td>
                                                                                                    <td style={{ textAlign: "right" }}>{item.Ingresos[0].SubMaterias.map((item2, i) => {
                                                                                                        if (item2.Cliente == item1.Cliente) {
                                                                                                            return (
                                                                                                                <p>{item2.Cantidad}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    })}</td>
                                                                                                </>)
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {/* ----------------------- */}
                                                                                    {/* Tercera Columna */}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[2].value == "c.cCas_Tab_Materia") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Ingresos[0].Materias.map((item2, i) => {
                                                                                                            
                                                                                                            if (item2.Cliente == item1.Cliente) {
                                                                                                                return (
                                                                                                                    <p>{item2.Materia}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td>{item.Ingresos[0].Materias.map((item2, i) => {
                                                                                                            
                                                                                                            if (item2.Cliente == item1.Cliente) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cantidad}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[2].value == "c.nJuz_Id") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Ingresos[0].Juzgados.map((item2, i) => {
                                                                                                            if (item2.Cliente == item1.Cliente) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Juzgado}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.Ingresos[0].Juzgados.map((item2, i) => {
                                                                                                            if (item2.Cliente == item1.Cliente) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Cantidad}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[2].value == "c.cCas_Tab_SubMateria") {
                                                                                                return (<>
                                                                                                    <td>{item.Ingresos[0].SubMaterias.map((item2, i) => {
                                                                                                        if (item2.Cliente == item1.Cliente) {
                                                                                                            return (
                                                                                                                <p>{item2.SubMateria}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    })}</td>
                                                                                                    <td style={{ textAlign: "right" }}>{item.Ingresos[0].SubMaterias.map((item2, i) => {
                                                                                                        if (item2.Cliente == item1.Cliente) {
                                                                                                            return (
                                                                                                                <p>{item2.Cantidad}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    })}</td>
                                                                                                </>)
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {/* ----------------------- */}
                                                                                    {/* Cuarta Columna */}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[3].value == "c.cCas_Tab_Materia") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Ingresos[0].Materias.map((item2, i) => {
                                                                                                            
                                                                                                            if (item2.Cliente == item1.Cliente) {
                                                                                                                return (
                                                                                                                    <p>{item2.Materia}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td>{item.Ingresos[0].Materias.map((item2, i) => {
                                                                                                            
                                                                                                            if (item2.Cliente == item1.Cliente) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cantidad}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[3].value == "c.nJuz_Id") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Ingresos[0].Juzgados.map((item2, i) => {
                                                                                                            if (item2.Cliente == item1.Cliente) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Juzgado}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.Ingresos[0].Juzgados.map((item2, i) => {
                                                                                                            if (item2.Cliente == item1.Cliente) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Cantidad}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[3].value == "c.cCas_Tab_SubMateria") {
                                                                                                return (<>
                                                                                                    <td>{item.Ingresos[0].SubMaterias.map((item2, i) => {
                                                                                                        if (item2.Cliente == item1.Cliente) {
                                                                                                            return (
                                                                                                                <p>{item2.SubMateria}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    })}</td>
                                                                                                    <td style={{ textAlign: "right" }}>{item.Ingresos[0].SubMaterias.map((item2, i) => {
                                                                                                        if (item2.Cliente == item1.Cliente) {
                                                                                                            return (
                                                                                                                <p>{item2.Cantidad}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    })}</td>
                                                                                                </>)
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {/* ----------------------- */}

                                                                                </tr>
                                                                            )
                                                                        })
                                                                        }
                                                                    </Then>
                                                                </ElseIf>
                                                            </If>
                                                        </ElseIf>
                                                        <ElseIf condition={getSReport2[0].value == 3}>
                                                            <If condition={getSReport1[0].value == "c.cCas_Tab_Materia"}>
                                                                <Then>
                                                                    {item.Egresos[0].Materias.map((item1, i) => {
                                                                        return (
                                                                            <tr key={i}>
                                                                                <td>
                                                                                    {item1.Materia}
                                                                                </td>
                                                                                <td style={{ textAlign: "right" }}>
                                                                                    {item1.Cantidad}
                                                                                </td>
                                                                                {/* Segunda Columna */}
                                                                                {(() => {
                                                                                    try {
                                                                                        if (getSReport1[1].value == "c.nCli_Id") {
                                                                                            return (<>
                                                                                                <td >{item.Egresos[0].Clientes.map((item3, i) => {
                                                                                                    if (item1.Materia == item3.Materia) {
                                                                                                        
                                                                                                        return (
                                                                                                            <p>{item3.Cliente}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                                <td style={{ textAlign: "right" }}>{item.Egresos[0].Clientes.map((item3, i) => {
                                                                                                    if (item1.Materia == item3.Materia) {
                                                                                                        return (
                                                                                                            <p>{item3.Cantidad}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                            </>)
                                                                                        } else if (getSReport1[1].value == "c.nJuz_Id") {
                                                                                            return (<>
                                                                                                <td>{item.Egresos[0].Juzgados.map((item2, i) => {
                                                                                                    if (item2.Materia == item1.Materia) {
                                                                                                        return (
                                                                                                            <p>{item2.Juzgado}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                                <td style={{ textAlign: "right" }}>{item.Egresos[0].Juzgados.map((item2, i) => {
                                                                                                    if (item2.Materia == item1.Materia) {
                                                                                                        return (
                                                                                                            <p>{item2.Cantidad}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                            </>)

                                                                                        } else if (getSReport1[1].value == "c.cCas_Tab_SubMateria") {
                                                                                            return (<>
                                                                                                <td>{item.Egresos[0].SubMaterias.map((item2, i) => {
                                                                                                    if (item2.Materia == item1.Materia) {
                                                                                                        return (
                                                                                                            <p>{item2.SubMateria}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                                <td style={{ textAlign: "right" }}>{item.Egresos[0].SubMaterias.map((item2, i) => {
                                                                                                    if (item2.Materia == item1.Materia) {
                                                                                                        return (
                                                                                                            <p>{item2.Cantidad}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                            </>)
                                                                                        }
                                                                                    } catch (error) {
                                                                                    }
                                                                                })()}
                                                                                {/* ----------------------- */}
                                                                                {/* Tercera Columna */}
                                                                                {(() => {
                                                                                    try {
                                                                                        if (getSReport1[2].value == "c.nCli_Id") {
                                                                                            return (<>
                                                                                                <td >{item.Egresos[0].Clientes.map((item3, i) => {
                                                                                                    if (item1.Materia == item3.Materia) {
                                                                                                        return (
                                                                                                            <p>{item3.Cliente}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                                <td style={{ textAlign: "right" }}>{item.Egresos[0].Clientes.map((item3, i) => {
                                                                                                    if (item1.Materia == item3.Materia) {
                                                                                                        return (
                                                                                                            <p>{item3.Cantidad}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                            </>)
                                                                                        } else if (getSReport1[2].value == "c.nJuz_Id") {
                                                                                            return (<>
                                                                                                <td>{item.Egresos[0].Juzgados.map((item2, i) => {
                                                                                                    if (item2.Materia == item1.Materia) {
                                                                                                        return (
                                                                                                            <p>{item2.Juzgado}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                                <td style={{ textAlign: "right" }}>{item.Egresos[0].Juzgados.map((item2, i) => {
                                                                                                    if (item2.Materia == item1.Materia) {
                                                                                                        return (
                                                                                                            <p>{item2.Cantidad}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                            </>)
                                                                                        } else if (getSReport1[2].value == "c.cCas_Tab_SubMateria") {
                                                                                            return (<>
                                                                                                <td>{item.Egresos[0].SubMaterias.map((item2, i) => {
                                                                                                    if (item2.Materia == item1.Materia) {
                                                                                                        return (
                                                                                                            <p>{item2.SubMateria}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                                <td style={{ textAlign: "right" }}>{item.Egresos[0].SubMaterias.map((item2, i) => {
                                                                                                    if (item2.Materia == item1.Materia) {
                                                                                                        return (
                                                                                                            <p>{item2.Cantidad}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                            </>)
                                                                                        }
                                                                                    } catch (error) {
                                                                                    }
                                                                                })()}
                                                                                {/* ----------------------- */}
                                                                                {/* Cuarta Columna */}
                                                                                {(() => {
                                                                                    try {
                                                                                        if (getSReport1[3].value == "c.nCli_Id") {
                                                                                            return (<>
                                                                                                <td >{item.Egresos[0].Clientes.map((item3, i) => {
                                                                                                    if (item1.Materia == item3.Materia) {
                                                                                                        return (
                                                                                                            <p>{item3.Cliente}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                                <td style={{ textAlign: "right" }}>{item.Egresos[0].Clientes.map((item3, i) => {
                                                                                                    if (item1.Materia == item3.Materia) {
                                                                                                        return (
                                                                                                            <p>{item3.Cantidad}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                            </>)
                                                                                        } else if (getSReport1[3].value == "c.nJuz_Id") {
                                                                                            return (<>
                                                                                                <td>{item.Egresos[0].Juzgados.map((item2, i) => {
                                                                                                    if (item2.Materia == item1.Materia) {
                                                                                                        return (
                                                                                                            <p>{item2.Juzgado}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                                <td style={{ textAlign: "right" }}>{item.Egresos[0].Juzgados.map((item2, i) => {
                                                                                                    if (item2.Materia == item1.Materia) {
                                                                                                        return (
                                                                                                            <p>{item2.Cantidad}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                            </>)
                                                                                        } else if (getSReport1[3].value == "c.cCas_Tab_SubMateria") {
                                                                                            return (<>
                                                                                                <td>{item.Egresos[0].SubMaterias.map((item2, i) => {
                                                                                                    if (item2.Materia == item1.Materia) {
                                                                                                        return (
                                                                                                            <p>{item2.SubMateria}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                                <td style={{ textAlign: "right" }}>{item.Egresos[0].SubMaterias.map((item2, i) => {
                                                                                                    if (item2.Materia == item1.Materia) {
                                                                                                        return (
                                                                                                            <p>{item2.Cantidad}</p>
                                                                                                        )
                                                                                                    }
                                                                                                })}</td>
                                                                                            </>)
                                                                                        }
                                                                                    } catch (error) {
                                                                                    }
                                                                                })()}
                                                                            </tr>)
                                                                    })
                                                                    }
                                                                </Then>
                                                                <ElseIf condition={getSReport1[0].value == "c.nJuz_Id"}>
                                                                    <Then>
                                                                        {item.Egresos[0].Juzgados.map((item1, i) => {
                                                                            return (
                                                                                <tr key={i}>
                                                                                    <td>
                                                                                        {item1.Juzgado}
                                                                                    </td>
                                                                                    <td style={{ textAlign: "right" }}>
                                                                                        {item1.Cantidad}
                                                                                    </td>
                                                                                    {/* Segunda Columna */}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[1].value == "c.cCas_Tab_Materia") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Egresos[0].Materias.map((item2, i) => {
                                                                                                            if (item2.Juzgado == item1.Juzgado) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Materia}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.Egresos[0].Materias.map((item2, i) => {
                                                                                                            if (item2.Juzgado == item1.Juzgado) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Cantidad}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[1].value == "c.nCli_Id") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Egresos[0].Clientes.map((item2, i) => {
                                                                                                            if (item2.Juzgado == item1.Juzgado) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Cliente}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.Egresos[0].Clientes.map((item2, i) => {
                                                                                                            if (item2.Juzgado == item1.Juzgado) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Cantidad}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[1].value == "c.cCas_Tab_SubMateria") {
                                                                                                return (<>
                                                                                                    <td>{item.Egresos[0].SubMaterias.map((item2, i) => {
                                                                                                        if (item2.Juzgado == item1.Juzgado) {
                                                                                                            return (
                                                                                                                <p>{item2.SubMateria}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    })}</td>
                                                                                                    <td style={{ textAlign: "right" }}>{item.Egresos[0].SubMaterias.map((item2, i) => {
                                                                                                        if (item2.Juzgado == item1.Juzgado) {
                                                                                                            return (
                                                                                                                <p>{item2.Cantidad}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    })}</td>
                                                                                                </>)
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {/* ----------------------- */}
                                                                                    {/* Tercera Columna */}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[2].value == "c.nCli_Id") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Egresos[0].Clientes.map((item2, i) => {
                                                                                                            if (item2.Juzgado == item1.Juzgado) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Cliente}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.Egresos[0].Clientes.map((item2, i) => {
                                                                                                            if (item2.Juzgado == item1.Juzgado) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Cantidad}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[2].value == "c.cCas_Tab_Materia") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Egresos[0].Materias.map((item2, i) => {
                                                                                                            if (item2.Juzgado == item1.Juzgado) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Materia}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.Egresos[0].Materias.map((item2, i) => {
                                                                                                            if (item2.Juzgado == item1.Juzgado) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Cantidad}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[2].value == "c.cCas_Tab_SubMateria") {
                                                                                                return (<>
                                                                                                    <td>{item.Egresos[0].SubMaterias.map((item2, i) => {
                                                                                                        if (item2.Juzgado == item1.Juzgado) {
                                                                                                            return (
                                                                                                                <p>{item2.SubMateria}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    })}</td>
                                                                                                    <td style={{ textAlign: "right" }}>{item.Egresos[0].SubMaterias.map((item2, i) => {
                                                                                                        if (item2.Juzgado == item1.Juzgado) {
                                                                                                            return (
                                                                                                                <p>{item2.Cantidad}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    })}</td>
                                                                                                </>)
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {/* ----------------------- */}
                                                                                    {/* Cuarta Columna */}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[3].value == "c.nCli_Id") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Egresos[0].Clientes.map((item2, i) => {
                                                                                                            if (item2.Juzgado == item1.Juzgado) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Cliente}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.Egresos[0].Clientes.map((item2, i) => {
                                                                                                            if (item2.Juzgado == item1.Juzgado) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Cantidad}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[3].value == "c.cCas_Tab_Materia") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Egresos[0].Materias.map((item2, i) => {
                                                                                                            if (item2.Juzgado == item1.Juzgado) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Materia}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.Egresos[0].Materias.map((item2, i) => {
                                                                                                            if (item2.Juzgado == item1.Juzgado) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Cantidad}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[3].value == "c.cCas_Tab_SubMateria") {
                                                                                                return (<>
                                                                                                    <td>{item.Egresos[0].SubMaterias.map((item2, i) => {
                                                                                                        if (item2.Juzgado == item1.Juzgado) {
                                                                                                            return (
                                                                                                                <p>{item2.SubMateria}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    })}</td>
                                                                                                    <td style={{ textAlign: "right" }}>{item.Egresos[0].SubMaterias.map((item2, i) => {
                                                                                                        if (item2.Juzgado == item1.Juzgado) {
                                                                                                            return (
                                                                                                                <p>{item2.Cantidad}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    })}</td>
                                                                                                </>)
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                </tr>
                                                                            )
                                                                        })
                                                                        }
                                                                    </Then>
                                                                </ElseIf>
                                                                <ElseIf condition={getSReport1[0].value == "c.cCas_Tab_SubMateria"}>
                                                                    <Then>
                                                                        {item.Egresos[0].SubMaterias.map((item1, i) => {
                                                                            return (
                                                                                <tr key={i}>
                                                                                    <td>
                                                                                        {item1.SubMateria}
                                                                                    </td>
                                                                                    <td style={{ textAlign: "right" }}>
                                                                                        {item1.Cantidad}
                                                                                    </td>
                                                                                    {/* Segunda Columna */}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[1].value == "c.cCas_Tab_Materia") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Egresos[0].Materias.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Materia}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.Egresos[0].Materias.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cantidad}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[1].value == "c.nJuz_Id") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Egresos[0].Juzgados.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Juzgado}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.Egresos[0].Juzgados.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cantidad}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[1].value == "c.nCli_Id") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Egresos[0].Clientes.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cliente}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td>{item.Egresos[0].Clientes.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cantidad}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {/* ----------------------- */}
                                                                                    {/* Tercera Columna */}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[2].value == "c.cCas_Tab_Materia") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Egresos[0].Materias.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Materia}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.Egresos[0].Materias.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cantidad}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[2].value == "c.nJuz_Id") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Egresos[0].Juzgados.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Juzgado}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.Egresos[0].Juzgados.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cantidad}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[2].value == "c.nCli_Id") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Egresos[0].Clientes.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cliente}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.Egresos[0].Clientes.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cantidad}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {/* ----------------------- */}
                                                                                    {/* Cuarta Columna */}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[3].value == "c.cCas_Tab_Materia") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Egresos[0].Materias.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Materia}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.Egresos[0].Materias.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cantidad}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[3].value == "c.nJuz_Id") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Egresos[0].Juzgados.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Juzgado}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.Egresos[0].Juzgados.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cantidad}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[3].value == "c.nCli_Id") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Egresos[0].Clientes.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cliente}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.Egresos[0].Clientes.map((item2, i) => {
                                                                                                            if (item2.SubMateria == item1.SubMateria) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cantidad}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {/* ----------------------- */}
                                                                                </tr>
                                                                            )
                                                                        })
                                                                        }
                                                                    </Then>
                                                                </ElseIf>
                                                                <ElseIf condition={getSReport1[0].value == "c.nCli_Id"}>
                                                                    <Then>
                                                                        {item.Egresos[0].Clientes.map((item1, i) => {
                                                                            return (
                                                                                <tr key={i}>
                                                                                    <td>
                                                                                        {item1.Cliente}
                                                                                    </td>
                                                                                    <td style={{ textAlign: "right" }}>
                                                                                        {item1.Cantidad}
                                                                                    </td>
                                                                                    {/* Segunda Columna */}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[1].value == "c.cCas_Tab_Materia") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Egresos[0].Materias.map((item2, i) => {
                                                                                                            
                                                                                                            if (item2.Cliente == item1.Cliente) {
                                                                                                                return (
                                                                                                                    <p>{item2.Materia}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.Egresos[0].Materias.map((item2, i) => {
                                                                                                            
                                                                                                            if (item2.Cliente == item1.Cliente) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cantidad}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[1].value == "c.nJuz_Id") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Egresos[0].Juzgados.map((item2, i) => {
                                                                                                            if (item2.Cliente == item1.Cliente) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Juzgado}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.Egresos[0].Juzgados.map((item2, i) => {
                                                                                                            if (item2.Cliente == item1.Cliente) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Cantidad}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[1].value == "c.cCas_Tab_SubMateria") {
                                                                                                return (<>
                                                                                                    <td>{item.Egresos[0].SubMaterias.map((item2, i) => {
                                                                                                        if (item2.Cliente == item1.Cliente) {
                                                                                                            return (
                                                                                                                <p>{item2.SubMateria}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    })}</td>
                                                                                                    <td style={{ textAlign: "right" }}>{item.Egresos[0].SubMaterias.map((item2, i) => {
                                                                                                        if (item2.Cliente == item1.Cliente) {
                                                                                                            return (
                                                                                                                <p>{item2.Cantidad}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    })}</td>
                                                                                                </>)
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {/* ----------------------- */}
                                                                                    {/* Tercera Columna */}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[2].value == "c.cCas_Tab_Materia") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Egresos[0].Materias.map((item2, i) => {
                                                                                                            
                                                                                                            if (item2.Cliente == item1.Cliente) {
                                                                                                                return (
                                                                                                                    <p>{item2.Materia}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td>{item.Egresos[0].Materias.map((item2, i) => {
                                                                                                            
                                                                                                            if (item2.Cliente == item1.Cliente) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cantidad}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[2].value == "c.nJuz_Id") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Egresos[0].Juzgados.map((item2, i) => {
                                                                                                            if (item2.Cliente == item1.Cliente) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Juzgado}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.Egresos[0].Juzgados.map((item2, i) => {
                                                                                                            if (item2.Cliente == item1.Cliente) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Cantidad}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[2].value == "c.cCas_Tab_SubMateria") {
                                                                                                return (<>
                                                                                                    <td>{item.Egresos[0].SubMaterias.map((item2, i) => {
                                                                                                        if (item2.Cliente == item1.Cliente) {
                                                                                                            return (
                                                                                                                <p>{item2.SubMateria}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    })}</td>
                                                                                                    <td style={{ textAlign: "right" }}>{item.Egresos[0].SubMaterias.map((item2, i) => {
                                                                                                        if (item2.Cliente == item1.Cliente) {
                                                                                                            return (
                                                                                                                <p>{item2.Cantidad}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    })}</td>
                                                                                                </>)
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {/* ----------------------- */}
                                                                                    {/* Cuarta Columna */}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[3].value == "c.cCas_Tab_Materia") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Egresos[0].Materias.map((item2, i) => {
                                                                                                            
                                                                                                            if (item2.Cliente == item1.Cliente) {
                                                                                                                return (
                                                                                                                    <p>{item2.Materia}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td>{item.Egresos[0].Materias.map((item2, i) => {
                                                                                                            
                                                                                                            if (item2.Cliente == item1.Cliente) {
                                                                                                                return (
                                                                                                                    <p>{item2.Cantidad}</p>
                                                                                                                )
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[3].value == "c.nJuz_Id") {
                                                                                                return (
                                                                                                    <>
                                                                                                        <td>{item.Egresos[0].Juzgados.map((item2, i) => {
                                                                                                            if (item2.Cliente == item1.Cliente) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Juzgado}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                        <td style={{ textAlign: "right" }}>{item.Egresos[0].Juzgados.map((item2, i) => {
                                                                                                            if (item2.Cliente == item1.Cliente) {
                                                                                                                return (
                                                                                                                    <p>
                                                                                                                        <a>{item2.Cantidad}</a>
                                                                                                                    </p>)
                                                                                                            }
                                                                                                        })}</td>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {(() => {
                                                                                        try {
                                                                                            if (getSReport1[3].value == "c.cCas_Tab_SubMateria") {
                                                                                                return (<>
                                                                                                    <td>{item.Egresos[0].SubMaterias.map((item2, i) => {
                                                                                                        if (item2.Cliente == item1.Cliente) {
                                                                                                            return (
                                                                                                                <p>{item2.SubMateria}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    })}</td>
                                                                                                    <td style={{ textAlign: "right" }}>{item.Egresos[0].SubMaterias.map((item2, i) => {
                                                                                                        if (item2.Cliente == item1.Cliente) {
                                                                                                            return (
                                                                                                                <p>{item2.Cantidad}</p>
                                                                                                            )
                                                                                                        }
                                                                                                    })}</td>
                                                                                                </>)
                                                                                            }
                                                                                        } catch (error) {
                                                                                        }
                                                                                    })()}
                                                                                    {/* ----------------------- */}

                                                                                </tr>
                                                                            )
                                                                        })
                                                                        }
                                                                    </Then>
                                                                </ElseIf>
                                                            </If>
                                                        </ElseIf>
                                                    </If>
                                                </tbody>
                                            ))}
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default ReporteAvanzado
