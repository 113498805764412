import React, { Component } from "react"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Col } from "reactstrap"
import profile from "../../../src/assets/images/bg-auth-overlay.png"
export default class CarouselPage extends Component {
  render() {
    return (
      <React.Fragment>
        <Col xl={9}>
          <div className="auth-full-bg pt-lg-5 p-4">
            <div className="w-100">
              <div className="bg-overlay"  style={{backgroundColor:'white'}}>
                <img src={profile}  style={{position:'fixed', width:'auto', height:'100%', zIndex:'0'}}>
                
                </img>

              </div>
              <div className="d-flex h-100 flex-column" >
                <div className="p-4 mt-auto" >
                  <div className="row justify-content-center">
                    <div className="col-lg-7">
                      <div className="text-center">
                    
                        <div dir="ltr">
                     
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </React.Fragment>
    )
  }
}
