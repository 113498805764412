import React, { Component, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTags from "react-meta-tags"
import { MDBDataTable } from "mdbreact"
import "../../assets/scss/tablescss/FormatTable.scss"
import Swal from "sweetalert2"
import Select from "react-select"
import {
    CardHeader,
    CardText,
    CardBody,
    Card,
    Col,
    Container,
    CardTitle,
    Button,
    Row,
    Modal,
    Title,
    Text,
    Form,
    FormGroup,
    TabContent,
    TabPane,
    FormText,
    Label,
    Input,
    CustomInput,
    CheckBox,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap"
import instance from '../../config'
import { post } from "helpers/api_helper"
import { setEncryptedItem } from "util"

const baseUrl = process.env.REACT_APP_API_URL
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
const Suscriptores = props => {
    const [modalInsertar, setModalInsertar] = useState(false)
    const [modalActualizar, setModalActualizar] = useState(false)
    const [modalFiltro, setModalFiltro] = useState(false)
    const [data, setData] = useState([
        { 'NombreFlujo': 'Proceso de Conocimiento', 'Descripcion': 'Proceso mas completo de larga duración y complejidad', 'Fases': '8', 'Eliminar': 'x' },
        { 'NombreFlujo': 'Proceso Abreviado ', 'Descripcion': 'Proceso mas corto', 'Fases': '6', 'Eliminar': 'x' },
        { 'NombreFlujo': 'Proceso Sumarísimo ', 'Descripcion': 'Proceso mas rápido', 'Fases': '3', 'Eliminar': 'x' },
        { 'NombreFlujo': 'Proceso Cautelar  ', 'Descripcion': 'Proceso para asegurar la sentencia', 'Fases': '1', 'Eliminar': 'x' },
        { 'NombreFlujo': 'Proceso de Ejecución', 'Descripcion': 'Proceso una vez se tiene sentencia firme', 'Fases': '2', 'Eliminar': 'x' },
    ])
    const [dataPlan, setDataPlan] = useState([])
    const [dataCategoria, setDataCategoria] = useState([])
    const [dataFormaPago, setDataFormaPago] = useState([])
    const [dataCanal, setDataCanal] = useState([])
    const [dataTipoPlan, setTipoPlan] = useState([])
    const [subTitulo, setSubTitulo] = useState([])

    const [getSus, setSus] = useState({
        nSus_Id: "",
        nPla_ID: "",
        nPro_ID: "",
        cSus_Codigo: "",
        cSus_Categoria: "",
        cSus_Nombres: "",
        dSus_FechaVencimiento: "",
        dSus_FechaVencimiento2: "",
        dSus_FechaCreacion: "",
        dSus_FechaCreacion2: "",
        dSus_UltimoPago: "",
        dSus_UltimoPago2: "",
        cSus_Correo: "",
        cSus_Pass: "",
        cSus_Telefono: "",
        cSus_Empresa: "",
        cSus_CadenaConexion: "",
        cSus_Cuenta: "",
        bSus_Estado: 1,
        bSus_Visto: 1,
        cSus_Fac_Pais: "",
        cSus_Fac_PrimerNombre: "",
        cSus_Fac_Apellido: "",
        cSus_Fac_Correo: "",
        cSus_Fac_Compania: "",
        cSus_Fac_Telefono: "",
        cSus_Fac_DireccionL1: "",
        cSus_Fac_DireccionL2: "",
        cSus_Fac_DireccionL3: "",
        cSus_Fac_Ciudad: "",
        cSus_Fac_Departamento: "",
        cSus_Fac_CodigoPostal: "",
        nSus_Fac_Plan: "",
        cSus_Fac_Canal: "",
        cSus_Fac_tab_FormaPago: "",
        cSus_Fac_Ruc: "",
        dSus_Fac_ProxPago: "",
        dSus_Fac_ProxPago2: ""
    })
    const [getFiltro, setFiltro] = useState({
        FechaInicial: ano + "-" + "01" + "-" + "01",
        FechaFin: ano + "-" + mes + "-" + dia,
    })

    const handleChangeFiltro = e => {
        const { name, value } = e.target
        setFiltro({
            ...getFiltro,
            [e.target.name]: e.target.value
        })

    }
    const abrirCerrarModal = Modal => {
        if (Modal == 1) {
            if (!modalInsertar == false) {
                Swal.fire({
                    html: "¿Seguro que desea salir sin guardar los datos?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si",
                    cancelButtonText: "No",
                }).then(result => {
                    if (result.isConfirmed) {
                        setModalInsertar(!modalInsertar)
                    }
                })
            } else {
                setModalInsertar(!modalInsertar)
            }
        } else if (Modal == 2) {
            setModalActualizar(!modalActualizar)
        } else if (Modal == 3) {
            setModalFiltro(!modalFiltro)
        }
    }
    const FuncionListarSuscriptores = async () => {
        await instance
            .post("/Suscriptores/Listar")
            .then(response => {
                setData(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionBuscarSuscriptores = async () => {
        var postData = {
            FechaInicial: getFiltro.FechaInicial,
            FechaFin: getFiltro.FechaFin,
        }
        await instance
            .post("/Suscriptores/Buscar", postData)
            .then(response => {
                setData(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionRegistrarSuscriptores = async () => {
        var postData = {
            cSus_Nombres: getSus.cSus_Nombres,
            cSus_Correo: getSus.cSus_Correo,
            cSus_Pass: getSus.cSus_Pass,
            cSus_Telefono: getSus.cSus_Telefono,
            cSus_Empresa: getSus.cSus_Empresa,
            cSus_CadenaConexion: getSus.cSus_CadenaConexion,
            cSus_Cuenta: getSus.cSus_Cuenta,
            cSus_CadenaConexion: getSus.cSus_CadenaConexion,
        }

        await instance
            .post("/Suscriptores/Registrar", postData)
            .then(response => {
                FuncionListarSuscriptores()
                abrirCerrarModal(1)

                Swal.fire({
                    title: "Mensaje",
                    text: "Agregado exitosamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })
            })
            .catch(error => {

            })
    }
    const FuncionBuscarPlan_PorTipoPlan = async (nPro_ID) => {
        var postData = {
            nPro_ID: nPro_ID
        }
        await instance
            .post("/Plan/PlanesBuscar_porTipoPlan", postData)
            .then(response => {

                setDataPlan(response.data)

            })
            .catch(error => {

            })
    }

    const handleChange = e => {
        const { name, value } = e.target
        setSus(prevState => ({
            ...prevState,
            [name]: value,
        }))
        if (name == "nPro_ID") {

            FuncionBuscarPlan_PorTipoPlan(value);
        }

    }
    const FuncionActualizarSuscriptores = async () => {
        var postData = {
            nSus_Id: getSus.nSus_Id,
            nPla_ID: getSus.nPla_ID,
            nPro_ID: getSus.nPro_ID,
            cSus_Categoria: getSus.cSus_Categoria,
            cSus_Nombres: getSus.cSus_Nombres,
            cSus_Correo: getSus.cSus_Correo,
            cSus_Pass: getSus.cSus_Pass,
            cSus_Telefono: getSus.cSus_Telefono,
            cSus_Empresa: getSus.cSus_Empresa,
            cSus_CadenaConexion: getSus.cSus_CadenaConexion,
            cSus_Cuenta: getSus.cSus_Cuenta,
            bSus_Estado: Number.parseInt(getSus.bSus_Estado),
            bSus_Visto: getSus.bSus_Visto,
            dSus_FechaVencimiento: getSus.dSus_FechaVencimiento,
            dSus_FechaCreacion: getSus.dSus_FechaCreacion,
            dSus_UltimoPago: getSus.dSus_UltimoPago,
            cSus_Fac_Pais: getSus.cSus_Fac_Pais,
            cSus_Fac_PrimerNombre: getSus.cSus_Fac_PrimerNombre,
            cSus_Fac_Apellido: getSus.cSus_Fac_Apellido,
            cSus_Fac_Correo: getSus.cSus_Fac_Correo,
            cSus_Fac_Compania: getSus.cSus_Fac_Compania,
            cSus_Fac_Telefono: getSus.cSus_Fac_Telefono,
            cSus_Fac_DireccionL1: getSus.cSus_Fac_DireccionL1,
            cSus_Fac_DireccionL2: getSus.cSus_Fac_DireccionL2,
            cSus_Fac_DireccionL3: getSus.cSus_Fac_DireccionL3,
            cSus_Fac_Ciudad: getSus.cSus_Fac_Ciudad,
            cSus_Fac_Departamento: getSus.cSus_Fac_Departamento,
            cSus_Fac_CodigoPostal: getSus.cSus_Fac_CodigoPostal,
            nSus_Fac_Plan: getSus.nSus_Fac_Plan,
            cSus_Fac_Canal: getSus.cSus_Fac_Canal,
            cSus_Fac_tab_FormaPago: getSus.cSus_Fac_tab_FormaPago,
            cSus_Fac_Ruc: getSus.cSus_Fac_Ruc,
            dSus_Fac_ProxPago: getSus.dSus_Fac_ProxPago,
        }

        await instance
            .post("/Suscriptores/Actualizar", postData)
            .then(response => {
                FuncionListarSuscriptores()
                abrirCerrarModal(2)

                Swal.fire({
                    title: "Mensaje",
                    text: "Actualizado exitosamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })
            })
            .catch(error => {

            })
    }
    const FuncionEliminarSuscriptores = async (_nSus_Id) => {
        var postData = {
            nSus_Id: Number.parseInt(_nSus_Id),
        }
        await instance
            .post("/Suscriptores/Eliminar", postData)
            .then(response => {
                FuncionListarSuscriptores()

                Swal.fire({
                    title: "Mensaje",
                    text: "Eliminado exitosamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })
            })
            .catch(error => {

            })
    }
    const Acciones = (Suscriptor) => {

        setEncryptedItem('nSus_Id', Suscriptor.nSus_Id);

    }
    const FuncionListarCategoria = async () => {
        var postData = {
            Prefijo: "ZD",
        }
        await instance
            .post("/Maestros/Data", postData)
            .then(response => {
                setDataCategoria(response.data)

            })
            .catch(error => {

            })
    }
    const datas = {
        columns: [

            {
                label: "Nombre del Flujo de Trabajo",
                field: "NombreFlujo",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "Descripcion",
                field: "Descripcion",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "Fases",
                field: "Fases",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "Eliminar",
                field: "Eliminar",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },


        ],
        rows: data,
    }
    const AbrirNv = (Url) => {
        window.open(Url, '_blank')
    }
    const badgesData = {
        columns: [
            ...datas.columns,
            {
                label: "Acciones",
                field: "acciones",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
        ],
        rows: [
            ...datas.rows.map((row, id) => ({
                ...row,
                cuenta: (
                    <div>
                        <Link onClick={() => AbrirNv(row.cSus_Cuenta)}>
                            {row.cSus_Cuenta}
                        </Link>
                    </div>
                ),
                acciones: (
                    <div>
                        <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="dropdown-toggle">
                                <i className="bx bx-dots-vertical-rounded" />
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem>
                                    <Link onClick={() => Acciones(row)} to="/NuevoAmbiente">
                                        Editar
                                    </Link>
                                </DropdownItem>

                                <DropdownItem
                                    onClick={() =>
                                        Swal.fire({
                                            title: "Mensaje",
                                            html:
                                                "Estas seguro de eliminar al cliente : <b>" +
                                                row.cSus_Nombres +
                                                "</b> ?",
                                            icon: "warning",
                                            showCancelButton: true,
                                            confirmButtonColor: "#3085d6",
                                            cancelButtonColor: "#d33",
                                            confirmButtonText: "Aceptar",
                                            cancelButtonText: "Cancelar",
                                        }).then(result => {
                                            if (result.isConfirmed) {

                                                FuncionEliminarSuscriptores(row.nSus_Id)
                                            }
                                        })
                                    }
                                >
                                    Eliminar
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                ),
            })),
        ],
    }
    const FuncionFormaDePago = async () => {
        var postData = {
            Prefijo: "BD",
        }
        await instance
            .post("/Maestros/Data", postData)
            .then(response => {
                setDataFormaPago(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionListarCanal = async () => {
        var postData = {
            Prefijo: "AD",
        }
        await instance
            .post("/Maestros/Data", postData)
            .then(response => {
                setDataCanal(response.data)

            })
            .catch(error => {

            })
    }

    const FuncionListarTipoPlan = async () => {
        await instance
            .post("/TipoPlanes/TipoPlaneslistar")
            .then(response => {
                setTipoPlan(response.data)

            })
            .catch(error => {

            })
    }
    useEffect(() => {
    }, [])
    return (
        <React.Fragment>
            <div className="page-content">
                {/* add meta title */}
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="" breadcrumbItem="" />
                    <Row>
                        <Col className="col-12 row center">
                            <Col className="col-4">
                                <CardTitle className="h6" style={{ fontSize: "30px", fontSize: '25px' }}>
                                    Flujos de Trabajo
                                </CardTitle>
                            </Col>
                            <Col className="col-7" style={{ textAlign: 'left' }}>
                                <CardTitle className="h6" style={{ fontSize: "30px", fontSize: '20px', textAlign: 'left' }}>
                                    Juicio de Conocimiento
                                </CardTitle>
                            </Col>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '25px' }}>
                        <Col className="col-12 row center">
                            <Col className="col-8">
                                <CardTitle className="h6" style={{ fontSize: "20px", }}>
                                    Fase: Presentación de la demanda
                                </CardTitle>
                            </Col>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '40px' }}>
                        <Col className="col-12 row" >
                            <Card className="col-12">
                                <CardBody>

                                    <Row>
                                        <Col className="col-12">
                                            <CardTitle className="h6" style={{ fontSize: "20px", }}>
                                                Redactar la demanda
                                            </CardTitle>
                                        </Col>
                                        <Col className="col-6 " style={{ marginTop: '20px' }}>
                                            <Col className="col-10 ">
                                                <div className="modal-body">
                                                    <div className="mb-2 row">
                                                        <div className="col-md-6">
                                                            <select className="form-select inputDown" name="nCli_Id">
                                                                <option value="0">Seleccionar...</option>
                                                            </select>
                                                            <label className="col-form-label labelDown">Cliente</label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <select
                                                                className="form-select inputDown"
                                                                name="nCas_Id"

                                                            >
                                                                <option value="0">Seleccionar...</option>

                                                            </select>
                                                            <label className="col-form-label labelDown">Caso</label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-1 row">
                                                        <div style={{ height: "20px;" }} className="col-md-5">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-12 col-form-label"
                                                                style={{ marginLeft: "5px" }}
                                                            >
                                                                Cód Interno: {"000000"}
                                                            </label>
                                                        </div>
                                                        <div className="col-md-7">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-12 col-form-label"
                                                                style={{ marginLeft: "2px" }}
                                                            >
                                                                Cód Externo:{"Ext 000000"}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3 row" style={{ marginTop: '15px' }}>
                                                        <div className="col-md-12">
                                                            <select
                                                                className="form-select"
                                                                name="cEve_Tab_Categoria"
                                                            >
                                                                <option value="0">Seleccionar...</option>
                                                            </select>
                                                            <label className="col-form-label labelDown">Categoría</label>
                                                        </div>
                                                        <div className="col-md-12" style={{ marginTop: '15px' }}>
                                                            <textarea

                                                                className="form-control"
                                                                placeholder="Ingrese el título"
                                                                name="cEve_Titulo"
                                                                maxLength="200"
                                                            />
                                                            <label className="col-form-label labelDown">Título</label>
                                                        </div>
                                                    </div>

                                                    <div className="mb-3 row">
                                                        <div className="col-md-12">
                                                            <Select

                                                                isMulti

                                                                name="colors"

                                                                className="basic-multi-select"
                                                                placeholder="Ingrese los participantes"

                                                            />
                                                            <label className="col-form-label labelDown">
                                                                Participantes
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3 row">
                                                        <div className="col-md-6">
                                                            <input
                                                                className="form-control"
                                                                type="date"
                                                                name="dEve_FechaIni"

                                                            />
                                                            <label className="col-form-label labelDown">
                                                                Fecha de vencimiento
                                                            </label>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <input
                                                                className="form-control"
                                                                type="time"
                                                                name="tEve_HoraIni"

                                                            />
                                                            <label className="col-form-label labelDown">
                                                                Hora de vencimiento
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3 row">
                                                        <div className="col-md-6">
                                                            <select
                                                                className="form-select"
                                                                name="cEve_tab_Recordar"

                                                            >
                                                                <option value="0">Seleccionar...</option>

                                                            </select>
                                                            <label className="col-form-label labelDown">Recordar</label>

                                                        </div>
                                                        <div className="col-md-6">
                                                            <select
                                                                className="form-select"
                                                                name="bEve_TodoDía"

                                                            >
                                                                <option value="3">Seleccionar...</option>
                                                                <option value="1">Si</option>
                                                                <option value="0">No</option>
                                                            </select>
                                                            <label className="col-form-label labelDown">Duración</label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3 row">
                                                        <div className="col-md-6">
                                                            <Input
                                                                style={{ float: 'left' }}
                                                                type="text"
                                                                className="form-control"
                                                                id="cSus_Nombres"
                                                                name="cSus_Nombres"
                                                                placeholder="Pagar Tasas"
                                                                value={"Alta"}
                                                            />
                                                            <label className="col-form-label labelDown">Prioridad</label>
                                                        </div>
                                                    </div>
                                                </div>

                                            </Col>
                                        </Col>
                                        <Col className="col-6 row" style={{ marginTop: '20px' }}>
                                            <Col className="col-12 row">
                                                <Col lg="12">
                                                    <FormGroup className="mb-2">
                                                        <Label htmlFor="cardnumberInput">
                                                            Descripción
                                                        </Label>
                                                        <Input
                                                            type="textarea"
                                                            className="form-control"
                                                            id="cSus_Nombres"
                                                            value={"Redactar la demanda con los anexos y documentos base de acción"}
                                                            name="cSus_Nombres"

                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col lg="12" style={{ marginTop: '-50px' }}>
                                                    <FormGroup className="mb-2">
                                                        <Label htmlFor="cardnumberInput">
                                                            Aprobar listas
                                                        </Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col className="col-12 row" style={{ marginBottom: '-25px', paddingBottom: '-25px', marginTop: '-80px' }}>
                                                    <Col lg="1" style={{ height: '20px', marginBottom: '0px' }}>
                                                        <FormGroup className="mb-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list-ul" viewBox="0 0 16 16">
                                                                <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                                            </svg>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="7" style={{ height: '20px', marginBottom: '0px' }}>
                                                        <FormGroup className="mb-2">
                                                            <Input
                                                                style={{ float: 'left' }}
                                                                type="text"
                                                                className="form-control"
                                                                id="cSus_Nombres"
                                                                name="cSus_Nombres"
                                                                placeholder="Copia de los Documentos de Identidad"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="1" style={{ height: '20px', marginBottom: '0px' }}>
                                                        <FormGroup className="mb-2">
                                                            <input type="checkbox" id="cbox2" value="second_checkbox"></input>
                                                        </FormGroup>
                                                    </Col>
                                                </Col>

                                                <Col className="col-12 row" style={{ marginBottom: '-20px', paddingBottom: '-20px', marginTop: '-100px' }}>
                                                    <Col lg="1" style={{ height: '20px' }}>
                                                        <FormGroup className="mb-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list-ul" viewBox="0 0 16 16">
                                                                <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                                            </svg>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="7" style={{ height: '20px' }}>
                                                        <FormGroup className="mb-2">
                                                            <Input
                                                                style={{ float: 'left' }}
                                                                type="text"
                                                                className="form-control"
                                                                id="cSus_Nombres"
                                                                name="cSus_Nombres"
                                                                placeholder="Copias Literales de los Inmubles"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="1" style={{ height: '20px' }}>
                                                        <FormGroup className="mb-2">
                                                            <input type="checkbox" id="cbox2" value="second_checkbox"></input>
                                                        </FormGroup>
                                                    </Col>
                                                </Col>
                                                <Col className="col-12 row" style={{ marginBottom: '-20px', paddingBottom: '-60px', marginTop: '-120px' }}>
                                                    <Col lg="1" style={{ height: '20px' }}>
                                                        <FormGroup className="mb-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list-ul" viewBox="0 0 16 16">
                                                                <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                                            </svg>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="7" style={{ height: '20px' }}>
                                                        <FormGroup className="mb-2">
                                                            <Input
                                                                style={{ float: 'left' }}
                                                                type="text"
                                                                className="form-control"
                                                                id="cSus_Nombres"
                                                                name="cSus_Nombres"
                                                                placeholder="Seleccionar Testigos"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="1" style={{ height: '20px' }}>
                                                        <FormGroup className="mb-2">
                                                            <input type="checkbox" id="cbox2" value="second_checkbox"></input>
                                                        </FormGroup>
                                                    </Col>
                                                </Col>

                                                <Col className="col-12 row" style={{ marginBottom: '-20px', paddingBottom: '-60px', marginTop: '-140px' }}>
                                                    <Col lg="1" style={{ height: '20px' }}>
                                                        <FormGroup className="mb-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list-ul" viewBox="0 0 16 16">
                                                                <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                                            </svg>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="7" style={{ height: '20px' }}>
                                                        <FormGroup className="mb-2">
                                                            <Input
                                                                style={{ float: 'left' }}
                                                                type="text"
                                                                className="form-control"
                                                                id="cSus_Nombres"
                                                                name="cSus_Nombres"
                                                                placeholder="Pagar Tasas"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="1" style={{ height: '20px' }}>
                                                        <FormGroup className="mb-2">
                                                            <input type="checkbox" id="cbox2" value="second_checkbox"></input>
                                                        </FormGroup>
                                                    </Col>
                                                </Col>
                                                <Col className="col-8" style={{ marginTop: "-150px" }}>
                                                    <button

                                                        type="button"

                                                        className="btn btn-danger waves-effect waves-light"
                                                        data-toggle="modal"
                                                        data-target="#myModal"
                                                    >
                                                        Aprobar
                                                    </button>
                                                    {" "}
                                                    <button

                                                        type="button"

                                                        className="btn btn-danger waves-effect waves-light"
                                                        data-toggle="modal"
                                                        data-target="#myModal"
                                                    >
                                                        Rechazar
                                                    </button>
                                                </Col>
                                                <Col className="col-12" style={{ marginTop: '-150px', textAlign: 'right' }}>
                                                    <Col lg="9">
                                                        <button

                                                            type="button"

                                                            className="btn btn-danger waves-effect waves-light"
                                                            data-toggle="modal"
                                                            data-target="#myModal"
                                                        >
                                                            Cerrar
                                                        </button>
                                                        {" "}
                                                        <button

                                                            type="button"

                                                            className="btn btn-danger waves-effect waves-light"
                                                            data-toggle="modal"
                                                            data-target="#myModal"
                                                        >
                                                            Guardar
                                                        </button>
                                                    </Col>
                                                </Col>

                                            </Col>

                                        </Col>

                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default Suscriptores