import React, { useState, useEffect, useContext } from "react"
import MetaTags from "react-meta-tags"
import {
    CardBody,
    Card,
    Col,
    Container,
    CardTitle,
    Row,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import instance from '../../../config'
import Swal from "sweetalert2"
import { getDecryptedItem } from '../../../util';
import ComboState from "../Context/Combos/ComboState"
import ModalCaso from "./Componentes/ModalCaso"
import ModalCasoU from "./Componentes/ModalCasoU"
import ModalCasoF from "./Componentes/ModalCasoF"
import ListaCaso from "./Componentes/ListaCaso"
import CasoState from "../Context/Casos/CasoState"
import CasoContext from "../Context/Casos/CasoContext"
var meses = new Array("Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre");
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var mesIni = Fecha.getMonth() - 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
if (mesIni < 10) mesIni = "0" + mesIni //agrega cero si el menor de 10
const baseUrl = process.env.REACT_APP_API_URL
const Caso = props => {
    const casoContext = useContext(CasoContext);
    var cantidadCasos = 0;
    const [modalInsertar, setModalInsertar] = useState(false)
    const [modalActualizar, setModalActualizar] = useState(false)
    const [modalFiltro, setModalFiltro] = useState(false)
    const [hiddenBtnEditar, setHiddenBtnEditar] = useState(true)
    const [hiddenBtnEliminar, setHiddenBtnEliminar] = useState(true)
    const abrirCerrarModal = (Modal, Row) => {
        if (Modal == 1) {
            if (!modalInsertar == false) {
                Swal.fire({
                    html: "¿Seguro que desea salir sin guardar los datos?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si",
                    cancelButtonText: "No",
                }).then(result => {
                    if (result.isConfirmed) {
                        setModalInsertar(!modalInsertar)
                    }
                })
            } else {
                setModalInsertar(!modalInsertar)
            }
        } else if (Modal == 4) {
            setModalFiltro(!modalFiltro)
        }
    }

    const FuncionListarPermisos = async () => {
        var postData = {
            nUsu_ID: parseInt(getDecryptedItem("RolID"))
        }
        await instance
            .post("/Usuario/Acceso", postData)
            .then(response => {
                let arrayPermisos = response.data;
                const permiso = arrayPermisos.find(element => element.cUsu_Modulo == "ME02");

                if (permiso.bUsu_Editar == 1) {
                    setHiddenBtnEditar(false);
                }
                else {
                    setHiddenBtnEditar(true);
                }
                if (permiso.bUsu_Eliminar == 1) {
                    setHiddenBtnEliminar(false);
                }
                else {
                    setHiddenBtnEliminar(true);
                }
            })
            .catch(error => {

            })
    }
    useEffect(async () => {
        FuncionListarPermisos();
    }, [])
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Casos | GpsLegal</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="GpsLegal" breadcrumbItem="Casos" className="colorTitulo" />
                </Container>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <div>
                                    <button
                                        type="button"
                                        onClick={() => abrirCerrarModal(1)}
                                        className="btn btn-danger waves-effect waves-light"
                                        data-toggle="modal"
                                        data-target="#myModal"
                                        hidden={hiddenBtnEditar}
                                    >
                                        <i style={{ fontSize: "15px" }} className="icon-nuevo"></i> Nuevo Caso
                                    </button>{" "}
                                    <button
                                        type="button"
                                        onClick={() => abrirCerrarModal(4)}
                                        className="btn btn-danger waves-effect waves-light"
                                        data-toggle="modal"
                                        data-target="#myModal"
                                    >
                                        <i style={{ fontSize: "15px" }} className="icon-filtro"></i> Filtros
                                    </button>{" "}

                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <CasoState>
                        <ComboState>
                            {(() => {
                                if (modalFiltro == true) {
                                    return <>
                                        <ModalCasoF
                                            show={modalFiltro}
                                            onCloseClick={() => abrirCerrarModal(3)}
                                            onCloseModal={() => { setModalFiltro(!modalFiltro) }} />
                                    </>
                                }
                            })()}
                            {(() => {
                                if (modalInsertar == true) {
                                    return <>
                                        <ModalCaso
                                            show={modalInsertar}
                                            onCloseClick={() => abrirCerrarModal(1)}
                                            onCloseModal={() => { setModalInsertar(!modalInsertar) }} />
                                    </>
                                }
                            })()}
                            <ListaCaso />
                        </ComboState>
                    </CasoState>
                </Row>
            </div>
        </React.Fragment>
    )
}
export default Caso