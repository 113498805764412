import React, { Component, useState, useEffect } from "react"
import {
    CardBody,
    Card,
    Col,
    Container,
    Row,
    Table,
    CardTitle,
} from "reactstrap"
import { MDBDataTable } from "mdbreact"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import ListaReporteProcesado from "./Componentes/ListaReporteProcesado"
import instance from '../../../config'
import ReportesState from "../Context/Reportes/ReportesState"

const baseUrl = process.env.REACT_APP_API_URL
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10

const ReportesProcesados = props => {

    useEffect(() => {
  
    }, [])
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Listado de liquidaciones procesadas | GpsLegal</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs
                        title="GpsLegal"
                        breadcrumbItem="Listado de liquidaciones procesadas"
                    />
                    
                    <Container fluid>
                        <Row>
                            <ReportesState>
                                <ListaReporteProcesado />
                            </ReportesState>
                        </Row>
                    </Container>
                </Container>
            </div>
        </React.Fragment >
    )
}
export default ReportesProcesados
