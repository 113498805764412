import React, { useRef, Component, useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import Swal from "sweetalert2"
import {
    CardBody,
    Card,
    Col,
    Container,
    Row,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import ListarRoles from "./Componentes/Lista"
import RolesState from "../Context/Roles/RolesState"
import ModalRoles from "./Componentes/Modal"

const Roles = props => {
    const [getModal, setModal] = useState(false)
    const abrirCerrarModal = Modal => {
        if (!getModal == false) {
            Swal.fire({
                html: "¿Seguro que desea salir sin guardar los datos?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Si",
                cancelButtonText: "No",
            }).then(result => {
                if (result.isConfirmed) {
                    setModal(!getModal)
                }
            })
        } else {
            setModal(!getModal)
        }
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Roles | GpsLegal</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="GpsLegal" breadcrumbItem="Roles" />
                </Container>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <button
                                    type="button"
                                    onClick={() => abrirCerrarModal(1)}
                                    className="btn btn-danger waves-effect waves-light"
                                    data-toggle="modal"
                                    data-target="#myModal"
                                >
                                    <i style={{ fontSize: "15px" }} className="icon-nuevo"></i> Nuevo Rol
                                </button>
                                <RolesState>
                                    {(() => {
                                        if (getModal == true) {
                                            return <>
                                                <ModalRoles show={getModal}
                                                    onCloseClick={() => abrirCerrarModal(1)}
                                                    Titulo="Nuevo Rol"
                                                    onCloseModal={() => setModal(!getModal)}
                                                    ReloadLista={() => setModal(false)}
                                                />
                                            </>
                                        }
                                    })()}
                                </RolesState>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <RolesState>
                                    <ListarRoles />
                                </RolesState>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )
}
export default Roles