import React, { Component, useState, useEffect } from "react"
import { MDBDataTable } from "mdbreact"
import MetaTags from "react-meta-tags"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap"
import Select from "react-select"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import listPlugin from "@fullcalendar/list"
import interactionPlugin, { Draggable } from "@fullcalendar/interaction"
import esLocale from '@fullcalendar/core/locales/es';
import "@fullcalendar/bootstrap/main.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import instance from '../../config'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCircle,
} from "@fortawesome/free-solid-svg-icons"
import Swal from "sweetalert2"
import ModalTarea from "pages/Componentes/ModalTarea"
import ModalEvento from "pages/Componentes/ModalEvento"
import { getDecryptedItem } from '../../util';
import { get } from "lodash"

const baseUrl = process.env.REACT_APP_API_URL
const Calendario = props => {
  const [data, setData] = useState([])
  const [data2, setData2] = useState([])
  const [dataCliente, setDataCliente] = useState([])
  const [dataCaso, setDataCaso] = useState([])
  const [dataComboUsuario, setDataComboUsuario] = useState([])
  const [dataRecordarEvento, setDataRecordarEvento] = useState([])
  const [dataTipoEvento, setDataTipoEvento] = useState([])
  const [dataEstadoEvento, setDataEstadoEvento] = useState([])
  const [hiddenBtnEditar, setHiddenBtnEditar] = useState(true)
  const [hiddenBtnEliminar, setHiddenBtnEliminar] = useState(true)
  const [dataTipoTarea, setDataTipoTarea] = useState([])
  const [dataEstadoTarea, setDataEstadoTarea] = useState([])
  const [dataCategoriaTarea, setDataCategoriaTarea] = useState([])
  const [dataRecordarTarea, setDataRecordarTarea] = useState([])
  const [modalInsertarTarea, setModalInsertarTarea] = useState(false)
  const [modalInsertarEvento, setModalInsertarEvento] = useState(false)
  const [dataCategoriaEvento, setDataCategoriaEvento] = useState([])
  const [GetParticipantes, setParticipantes] = useState([])
  const [dataLista, setDatalista] = useState([])
  

  var Fecha = new Date() //Fecha actual
  var mes = Fecha.getMonth() + 1 //obteniendo mes
  var dia = Fecha.getDate() //obteniendo dia
  var ano = Fecha.getFullYear() //obteniendo año
  if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
  if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
  const [getEvento, setEvento] = useState({
    nEve_Id: "",
    nCas_Id: "",
    cCas_Titulo: "",
    cEve_Tab_Tipo: "",
    cEve_Tab_Categoria: "",
    cEve_Titulo: "",
    cEve_Ubicacion: "",
    cEve_Descripcion: "",
    dEve_FechaIni: ano + "-" + mes + "-" + dia,
    dEve_FechaFin: ano + "-" + mes + "-" + dia,
    bEve_TodoDía: "",
    tEve_HoraIni: "",
    tEve_HoraFin: "",
    tEve_HoraIni2: data.tEve_HoraIni2,
    tEve_HoraFin2: data.tEve_HoraFin2,
    cEve_tab_Recordar: "",
    bEve_Estado: 1,
    cCas_Cod_Externo: "",
    cCas_Cod_Interno: "",
    nCli_Id: "",
    cEve_Estado: ""
  })
  const [getTareas, setTareas] = useState({
    nTar_Id: "",
    nCas_Id: "",
    cCas_Titulo: "",
    cTar_Tab_Tipo: "",
    cTar_Tab_Categoria: [],
    cTar_Titulo: "",
    nUsu_Asignado: 1,
    nUsu_Relacionado: 1,
    dTar_Fec_Vence: "",
    tTar_Hora_Vence: "",
    tTar_Hora_Vence2: data.tTar_Hora_Vence2,
    cTar_tab_recordar: "",
    bTar_Estado: "1",
    cEstadoTarea: "MT01",
    cCas_Cod_Externo: "",
    cCas_Cod_Interno: "",
    nCli_Id: "",
  })
  const [modalVerEve, setModalVerEve] = useState(false)
  const [modalVerTarea, setModalVerTarea] = useState(false)
  const FuncionListarEventos = async () => {
    let RolId = getDecryptedItem("RolID")
    var postData = {
      nUsu_ID: RolId == 6 || RolId == 1 ? null : getDecryptedItem("ID")
    }
    await instance
      .post("/Evento/Listar", postData)
      .then(response => {
        setData(response.data)

      })
      .catch(error => {

      })
  }
  const FuncionListarTareas = async () => {
    let RolId = getDecryptedItem("RolID")
    var postData = {
      nUsu_ID: RolId == 6 || RolId == 1 ? null : getDecryptedItem("ID")
    }
    await instance
      .post("/Tarea/Listar", postData)
      .then(response => {
        setData2(response.data)
      })
      .catch(error => {

      })
  }
  /*********** */
  const FuncionListarComboTipoTarea = async () => {
    var postData = {
      Prefijo: "TT",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataTipoTarea(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarComboCategoriaTarea = async () => {
    var postData = {
      Prefijo: "CT",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataCategoriaTarea(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarComboRecordarTarea = async () => {
    var postData = {
      Prefijo: "PE",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataRecordarTarea(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionBuscarTareasParticipantes = async (_nTar_Id) => {
    var postData = {
      nTar_Id: _nTar_Id,
    }
    await instance
      .post("/Tarea/Buscar/Participante", postData)
      .then(response => {
        setParticipantes(response.data);
      })
      .catch(error => {

      })
  }

  const FuncionlistarLista = async (_nTar_Id) => {
    var postData = {
      nTar_Id: _nTar_Id,
    }

    await instance
      .post("/Tareas/TareaListaListar", postData)
      .then(response => {
        setDatalista(response.data);

      })
      .catch(error => {

      })
  }

  const FuncionObtenerTarea = async (id) => {

    var postData = {
      _nTar_Id: id,
    }
    await instance
      .post("/Tarea/ObtenerTarea", postData)
      .then(response => {
        setTareas(response.data[0])

        setModalVerTarea(!modalVerTarea)
        FuncionBuscarTareasParticipantes(id)
      })
      .catch(error => {

      })

      FuncionlistarLista(id);
  }
  /********************** */
  const FuncionListarCliente = async () => {
    let RolId = getDecryptedItem("RolID")

    var postData = {
      bCli_Tipo: null,
      nUsu_ID: RolId == 6 || RolId == 1 ? null : getDecryptedItem("ID")
    }
    await instance
      .post("/Cliente/ListarCbo", postData)
      .then(response => {
        setDataCliente(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarCasos = async (_idClient) => {
    var postData = {
      idClient: _idClient,
      nUsu_Id: getDecryptedItem("ID"),
    }
    await instance
      .post("/Casos/ListarCbo", postData)
      .then(response => {
        setDataCaso(response.data)

      })
      .catch(error => {

      })
  }
  const FuncionListarComboCategoriaEvento = async () => {
    var postData = {
      Prefijo: "CE",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataCategoriaEvento(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarComboUsuario = async () => {
    await instance
      .get("/Usuario/Listar")
      .then(response => {
        setDataComboUsuario(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarEstadoEvento = async () => {
    var postData = {
      Prefijo: "TT",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataEstadoEvento(response.data)
      })
      .catch(error => {

      })
  }
  const UsuariosSelect = dataComboUsuario.map((row, i) => ({
    value: row.nUsu_ID,
    label: row.cUsu_Nombres,
    email: row.cUsu_email,
  }));
  const handleChangeParticipantes = value => {
    if (value == null) {
    } else {

      setParticipantes(value);
    }
  };
  const FuncionBuscarEventosParticipantes = async (_nEve_Id) => {   // Aqui se carga GetdefaultParticipantes
    var postData = {
      nEve_Id: _nEve_Id,
    }
    await instance
      .post("/Evento/Buscar/Participantes", postData)
      .then(response => {
        setParticipantes(response.data);
      })
      .catch(error => {

      })
  }
  const FuncionListarComboTipoEvento = async () => {
    var postData = {
      Prefijo: "TE",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataTipoEvento(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarComboRecordarEvento = async () => {
    var postData = {
      Prefijo: "PE",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataRecordarEvento(response.data)
      })
      .catch(error => {

      })
  }

  const FuncionObtenerEvento = async (id) => {

    var postData = {
      _nEve_Id: id,
    }
    await instance
      .post("/Evento/ObtenerEvento", postData)
      .then(response => {
        setEvento(response.data[0])
        FuncionBuscarEventosParticipantes(id)

        setModalVerEve(!modalVerEve)
      })
      .catch(error => {

      })
  }
  const EventoRow = data.map((row, i) => ({
    id: row.nEve_Id,
    title: row.cEve_Titulo,
    description: row.cEve_Descripcion,
    allDay: true,
    date: "",
    start: row.dEve_FechaIni,
    end: row.dEve_FechaFin,
    horaIni: row.tEve_HoraIni2,
    horaFin: row.tEve_HoraFin2,
  }))
  function ArregloTareas(
    id,
    title,
    description,
    allDay,
    date,
    start,
    end,
    horaIni,
    horaFin
  ) {
    let Item = {
      id: id,
      title: title,
      description: description,
      allDay: allDay,
      date: date,
      start: start,
      end: end,
      horaIni: horaIni,
      horaFin: horaFin,
    }
    EventoRow.push(Item)
  }
  const TareaRow = data2.map((row, i) =>
    ArregloTareas(
      row.nTar_Id,
      row.cTar_Titulo,
      row.cTar_Titulo,
      false,
      row.dTar_Fec_Vence,
      row.dTar_Fec_Vence + "T" + row.tTar_Hora_Vence,
      row.dTar_Fec_Vence,
      row.tTar_Hora_Vence2,
      row.tTar_Hora_Vence2
    )
  )
  const EventContent = ({ event }) => {

    if (event.allDay == true) {
      return (
        <div style={{ margin: "4px" }}>
          <a onClick={() => FuncionObtenerEvento(event.id)}>
            {event._def.extendedProps.horaIni} {" / "}{" "}
            {event._def.extendedProps.horaFin} {event.title}
          </a>
        </div>
      )
    } else {
      return (
        <div className="a">
          <a onClick={() => FuncionObtenerTarea(event.id)}>
            <FontAwesomeIcon style={{ color: "#1F60AB" }} icon={faCircle} />{" "}
            {event._def.extendedProps.horaIni} {event.title}
          </a>
        </div>
      )
    }
  }
  const TareaContent = ({ tarea }) => {

  }

  const abrirCerrarModal = (modal) => {
    if (modal == 1) {
      if (!modalInsertarTarea == false) {
        Swal.fire({
          html: "¿Seguro que desea salir sin guardar los datos?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        }).then(result => {
          if (result.isConfirmed) {
            setModalInsertarTarea(!modalInsertarTarea)
            FuncionListarTareas();
          }
        })
      } else {
        FuncionListarTareas();
        setModalInsertarTarea(!modalInsertarTarea)
      }
    } else if (modal == 2) {
      if (!modalInsertarEvento == false) {
        Swal.fire({
          html: "¿Seguro que desea salir sin guardar los datos?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        }).then(result => {
          if (result.isConfirmed) {
            setModalInsertarEvento(!modalInsertarEvento)
          }
        })
      } else {
        setModalInsertarEvento(!modalInsertarEvento)
      }
    }
    else if (modal == 3) {
      setModalVerEve(!modalVerEve)
    }
    else if (modal == 4) {
      setModalVerTarea(!modalVerTarea)
    }


  }
  const FuncionListarPermisos = async () => {
    var postData = {
      nUsu_ID: parseInt(getDecryptedItem('RolID')),
    }
    await instance
      .post("/Usuario/Acceso", postData)
      .then(response => {
        let arrayPermisos = response.data;
        const permiso = arrayPermisos.find(element => element.cUsu_Modulo == "ME08");

        if (permiso.bUsu_Editar == 1) {
          setHiddenBtnEditar(false);
        }
        else {
          setHiddenBtnEditar(true);
        }
        if (permiso.bUsu_Eliminar == 1) {
          setHiddenBtnEliminar(false);
        }
        else {
          setHiddenBtnEliminar(true);
        }
      })
      .catch(error => {

      })
  }

  const datas = {
    columns: [
      {
        label: "Listas ",
        field: "cLis_Nombre",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Estado ",
        field: "estado",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },

    ],
    rows: dataLista,
  }
  const badgesData = {
    columns: [
      ...datas.columns,
      {
        label: "Acciones",
        field: "acciones",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
    ],
    rows: [
      ...datas.rows.map((row, id) => ({
        ...row,
        acciones: (
          <div>
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="dropdown-toggle">
                <i className="bx bx-dots-vertical-rounded" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => FuncionEliminarLista(0, row.nLis_Id)}>
                  Realizado
                </DropdownItem>
                <DropdownItem
                  onClick={() => FuncionEliminarLista(1, row.nLis_Id)}
                >
                  Pendiente
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        ),
      })),
    ],
  }
  const badgesData2 = {
    columns: [
      ...datas.columns,
    ],
    rows: [
      ...datas.rows.map((row, id) => ({
        ...row,
      })),
    ],
  }

  useEffect(() => {
    FuncionListarPermisos();
    let rol = getDecryptedItem('RolID');
    if (rol !== '9') {
      localStorage.removeItem('CLIID');
      localStorage.removeItem('nCas_Id');
    }
    FuncionListarComboUsuario()
    FuncionListarEstadoEvento()
    FuncionListarEventos()
    FuncionListarComboTipoTarea()       //registrado
    FuncionListarComboCategoriaTarea()  //registrado
    FuncionListarComboRecordarTarea()   //registrado
    FuncionListarComboTipoEvento()        //Registrado
    FuncionListarComboCategoriaEvento()   //Registrado
    FuncionListarComboRecordarEvento()    //Registrado
    FuncionListarTareas()
    FuncionListarCliente()
    FuncionListarCasos(null)
    FuncionListarComboCategoriaEvento()
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Calendario | GpsLegal</title>
        </MetaTags>
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="GpsLegal" breadcrumbItem="Calendario" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col className="col-lg-4">
                      <Button
                        hidden={hiddenBtnEditar}
                        className="btn btn-danger waves-effect waves-light"
                        onClick={() => abrirCerrarModal(2)}
                      >
                        <i style={{ fontSize: "15px" }} className="icon-nuevo"></i>  Nuevo Evento
                      </Button>{" "}
                      <Button
                        hidden={hiddenBtnEditar}
                        className="btn btn-danger waves-effect waves-light"
                        onClick={() => abrirCerrarModal(1)}
                      >
                        <i style={{ fontSize: "15px" }} className="icon-nuevo"></i>  Nueva Tarea
                      </Button>
                    </Col>
                    <Col className="col-lg-8">
                      <b>Leyenda:   </b>{" "}<button className="btn  btn-sm btn-block waves-effect " style={{ background: "#48b071", color: "#FFF" }}>Evento  </button>{" "}<FontAwesomeIcon style={{ color: "#1F60AB" }} icon={faCircle} /> Tarea
                    </Col>
                    {(() => {
                      if (modalInsertarEvento == true) {
                        return <>
                          <ModalEvento show={modalInsertarEvento}
                            modo={0}
                            onCloseModal={() => { setModalInsertarEvento(false), FuncionListarEventos() }}
                            onCloseClick={() => abrirCerrarModal(2)}
                            ReloadLista={() => { setModalInsertarEvento(false), FuncionListarEventos() }}
                            Titulo="Nueva Evento"
                          />
                        </>
                      }
                    })()}
                    {(() => {
                      if (modalInsertarTarea == true) {
                        return <>
                          <ModalTarea show={modalInsertarTarea}
                            onCloseClick={() => abrirCerrarModal(1)}
                            ReloadLista={() => { setModalInsertarTarea(false), FuncionListarTareas() }}
                            Titulo="Nueva Tarea"
                          />
                        </>
                      }
                    })()}
                    <Modal isOpen={modalVerEve}>
                      <div
                        className="modal-header ModalHeaderColor"
                      >
                        <h5
                          style={{ color: "#fff" }}
                          className="modal-title mt-0"
                          id="myModalLabel"
                        >
                          Detalle del evento
                        </h5>
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(3)}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="mb-3 row">
                          <div className="col-md-6">
                            <select className="form-select inputDown" disabled="true"
                              name="nCli_Id"
                              value={getEvento && getEvento.nCli_Id}
                            >
                              <option value="0">Seleccionar...</option>
                              {dataCliente.map((row, i) => (
                                <option key={i} value={row.nCli_Id}>
                                  {row.cCli_NombreCompleto}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Cliente
                            </label>
                          </div>
                          <div className="col-md-6">
                            <select
                              disabled="true"
                              className="form-select inputDown"
                              name="nCas_Id"
                              value={getEvento && getEvento.nCas_Id}
                            >
                              <option value="0">Seleccionar...</option>
                              {dataCaso.map((row, i) => (
                                <option key={i} value={row.nCas_Id}>
                                  {row.cCas_Titulo}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Caso
                            </label>
                          </div>
                        </div>
                        <div className="mb-1 row">
                          <div className="col-md-5">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-12 col-form-label"
                              style={{ marginLeft: "5px" }}
                            >
                              Cod Interno: {getEvento.cCas_Cod_Interno}
                            </label>
                          </div>
                          <div className="col-md-7" style={{ paddingLeft: "0px", marginLeft: "0px" }}>
                            <label
                              htmlFor="example-text-input"
                              className="col-md-12 col-form-label"
                              style={{ paddingLeft: "0px", marginLeft: "0px" }}
                            >
                              Cod Externo: {getEvento.cCas_Cod_Externo}
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row" style={{ marginTop: '15px' }}>
                          <div className="col-md-6">
                            <select
                              disabled
                              className="form-select"
                              name="cEve_Tab_Categoria"
                              value={getEvento && getEvento.cEve_Tab_Categoria}
                            >
                              <option value="0">Seleccionar...</option>
                              {dataCategoriaEvento.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Categoría
                            </label>
                          </div>
                          <div className="col-md-6">
                            <input
                              disabled
                              className="form-control"
                              placeholder="Ingrese el título"
                              name="cEve_Titulo"
                              value={getEvento && getEvento.cEve_Titulo}
                            />
                            <label className="col-form-label labelDown">
                              Título
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <textarea
                              disabled
                              className="form-control"
                              placeholder="Ingrese la descripción"
                              name="cEve_Descripcion"
                              value={getEvento && getEvento.cEve_Descripcion}
                              maxLength="200"
                              style={{ resize: "none" }}
                            ></textarea>
                            <label className="col-form-label labelDown">
                              Descripción
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <Select
                              isDisabled={true}
                              readonly
                              isMulti
                              isClearable
                              onChange={handleChangeParticipantes}
                              className="basic-multi-select"
                              placeholder="Ingrese los participantes"
                              options={UsuariosSelect}
                              defaultValue={GetParticipantes}
                            />
                            <label className="col-form-label labelDown">
                              Participantes
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <input
                              disabled
                              className="form-control"
                              placeholder="Ingrese la ubicación"
                              name="cEve_Ubicacion"
                              value={getEvento && getEvento.cEve_Ubicacion}
                            />
                            <label className="col-form-label labelDown">
                              Ubicación
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-6">
                            <input
                              disabled
                              className="form-control"
                              type="date"
                              name="dEve_FechaIni"
                              value={getEvento && getEvento.dEve_FechaIni}
                            />
                            <label className="col-form-label labelDown">
                              Fecha de inicio
                            </label>
                          </div>
                          <div className="col-md-6">
                            <input
                              disabled
                              className="form-control"
                              type="time"
                              name="tEve_HoraIni"
                              value={getEvento && getEvento.tEve_HoraIni}
                            />
                            <label className="col-form-label labelDown">
                              Hora de inicio
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-6">
                            <input
                              disabled
                              className="form-control"
                              type="date"
                              name="dEve_FechaFin"
                              value={getEvento && getEvento.dEve_FechaFin}
                            />
                            <label className="col-form-label labelDown">
                              Fecha de fin
                            </label>
                          </div>
                          <div className="col-md-6">
                            <input
                              disabled
                              className="form-control"
                              type="time"
                              name="tEve_HoraFin"
                              value={getEvento && getEvento.tEve_HoraFin}
                            />
                            <label className="col-form-label labelDown">
                              Hora de fin
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-6">
                            <select
                              disabled
                              className="form-select"
                              name="cEve_tab_Recordar"
                              value={getEvento && getEvento.cEve_tab_Recordar}
                            >
                              <option value="0">Seleccionar...</option>
                              {dataRecordarEvento.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Recordar
                            </label>
                          </div>
                          <div className="col-md-6">
                            <select
                              disabled
                              className="form-select"
                              name="bEve_TodoDía"
                              value={getEvento && getEvento.bEve_TodoDía}
                            >
                              <option value="00">Seleccionar...</option>
                              <option value="1">Si</option>
                              <option value="0">No</option>
                            </select>
                            <label className="col-form-label labelDown">
                              Todo el día
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-6">
                            <select
                              disabled
                              className="form-select"
                              name="cEve_Tab_Tipo"
                              value={getEvento && getEvento.cEve_Tab_Tipo}
                            >
                              <option value="00">Seleccionar...</option>
                              {dataTipoEvento.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Tipo de evento
                            </label>
                          </div>
                          <div hidden className="col-md-6">
                            <select
                              disabled
                              className="form-select"
                              name="bEve_Estado"
                              value={getEvento && getEvento.bEve_Estado}
                            >
                              <option value="00">Seleccionar...</option>
                              <option value="1">Activo</option>
                              <option value="0">No activo</option>
                            </select>
                            <label className="col-form-label labelDown">
                              Estado
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(3)}
                          className="btn btn-secondary waves-effect"
                          data-dismiss="modal"
                        >
                          Cerrar
                        </button>
                      </div>
                    </Modal>

                    <Modal size="lg" isOpen={modalVerTarea}>
                      <div
                        className="modal-header ModalHeaderColor"
                      >
                        <h5
                          style={{ color: "#fff" }}
                          className="modal-title mt-0"
                          id="myModalLabel"
                        >
                          Detalle de la tarea
                        </h5>
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(4)}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="mb-3 row">
                          <div className="col-md-6">
                            <select className="form-select inputDown" disabled="true"
                              name="nCli_Id"
                              value={getTareas && getTareas.nCli_Id}>
                              <option value="0">Seleccionar...</option>
                              {dataCliente.map((row, i) => (
                                <option key={i} value={row.nCli_Id}>
                                  {row.cCli_NombreCompleto}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Cliente
                            </label>
                          </div>
                          <div className="col-md-6">
                            <select
                              className="form-select inputDown"
                              name="nCas_Id"
                              value={getTareas && getTareas.nCas_Id}
                              disabled="true"
                            >
                              <option value="0">Seleccionar...</option>
                              {dataCaso.map((row, i) => (
                                <option key={i} value={row.nCas_Id}>
                                  {row.cCas_Titulo}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Caso
                            </label>
                          </div>
                        </div>
                        <div className="mb-1 row">
                          <div className="col-md-5">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-12 col-form-label"
                              style={{ marginLeft: "5px" }}
                            >
                              Cod Interno: {getTareas.cCas_Cod_Interno}
                            </label>
                          </div>
                          <div className="col-md-7" style={{ paddingLeft: "0px", marginLeft: '0px' }}>
                            <label
                              htmlFor="example-text-input"
                              className="col-md-12 col-form-label"
                              style={{ marginLeft: "0px" }}
                            >
                              Cod Externo: {getTareas.cCas_Cod_Externo}
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row" style={{ marginTop: '15px' }}>
                          <div className="col-md-12">
                            <select
                              disabled="true"
                              className="form-select"
                              name="cTar_Tab_Categoria"
                              value={getTareas && getTareas.cTar_Tab_Categoria}
                            >
                              <option value="0">Seleccionar...</option>
                              {dataCategoriaTarea.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Categoría
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <textarea
                              disabled="true"
                              className="form-control"
                              placeholder="Ingrese el título"
                              name="cTar_Titulo"
                              value={getTareas && getTareas.cTar_Titulo}
                            ></textarea>
                            <label className="col-form-label labelDown">
                              Título
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <Select
                              isDisabled={true}
                              isMulti
                              onChange={handleChangeParticipantes}
                              name="colors"
                              options={UsuariosSelect}
                              defaultValue={GetParticipantes}
                              className="basic-multi-select"
                              placeholder="Ingrese los participantes"
                            />
                            <label className="col-form-label labelDown">
                              Participantes
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-6">
                            <input
                              disabled="true"
                              className="form-control"
                              type="date"
                              name="dTar_Fec_Vence"
                              value={getTareas && getTareas.dTar_Fec_Vence}
                            />
                            <label className="col-form-label labelDown">
                              Fecha de vencimiento
                            </label>
                          </div>
                          <div className="col-md-6">
                            <input
                              disabled="true"
                              className="form-control"
                              type="time"
                              name="tTar_Hora_Vence"
                              value={getTareas && getTareas.tTar_Hora_Vence}
                            />
                            <label className="col-form-label labelDown">
                              Hora de vencimiento
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-6">
                            <select
                              disabled="true"
                              className="form-select"
                              name="cTar_tab_recordar"
                              value={getTareas && getTareas.cTar_tab_recordar}
                            >
                              <option value="0">Seleccionar...</option>
                              {dataRecordarTarea.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Recordar
                            </label>
                          </div>
                          <div className="col-md-6">
                            <select
                              disabled="true"
                              className="form-select"
                              name="cTar_Tab_Tipo"
                              value={getTareas && getTareas.cTar_Tab_Tipo}
                            >
                              <option value="00">Seleccionar...</option>
                              {dataTipoTarea.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Estado
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-6" hidden>
                            <select
                              disabled="true"
                              className="form-select"
                              name="bTar_Estado"
                              value={getTareas && getTareas.bTar_Estado}
                            >
                              <option value="00">Seleccionar...</option>
                              <option value="1">Activo</option>
                              <option value="0">No activo</option>
                            </select>
                            <label className="col-form-label labelDown">
                              Estado
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="mb-3 row" style={{ paddingRight: '12px', paddingLeft: '12px' }}>
                        <div className="col-md-12">
                          <CardBody style={{ paddingLeft: '0px', paddingBottom: '0px' }}>

                            <MDBDataTable
                              data={badgesData2}
                              className="TablaListas"
                              responsiveXl
                              fixed
                              hover
                              entriesLabel="Mostrar"
                              entrylabel={"Mostrar entradas"}
                              infoLabel={["Mostrando ", "a", "de", "registros"]}
                              bordered
                              order={["age", "desc"]}
                              noRecordsFoundLabel={"No hay datos"}
                              searchLabel={"Buscar"}
                              noBottomColumns
                              paginationLabel={["Anterior", "Siguiente"]}
                              theadColor="HeadBlue"
                              searching={false}
                              paging={false}
                              info={false}
                            />
                          </CardBody>
                        </div>
                      </div>


                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(4)}
                          className="btn btn-secondary waves-effect"
                          data-dismiss="modal"
                        >
                          Cerrar
                        </button>
                      </div>
                    </Modal>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col className="col-lg-12">
                      {/* fullcalendar control */}
                      <FullCalendar
                        defaultView="dayGridMonth"
                        timeZone="GMT-5"
                        locale={esLocale}
                        headerToolbar={{
                          left: "prev,next today",
                          center: "title",
                          right: "dayGridMonth,dayGridWeek,dayGridDay",
                        }}
                        buttonText={{
                          today: "Hoy",
                          month: "Mes",
                          week: "Semana",
                          day: "Día",
                        }}
                        plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
                        selectable={true}
                        selectMirror={true}
                        eventColor="#48b071"
                        events={EventoRow}
                        eventContent={EventContent}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Calendario
/* dayMaxEventRows={3}
events={[
                            { title: 'Evento 1', description: 'Test data', date: '2021-05-04' },
                            { title: 'Evento 1', description: 'Test data', date: '2021-05-04' },
                            { title: 'Evento 1', description: 'Test data', date: '2021-05-04' },
                            { title: 'Evento 1', description: 'Test data', date: '2021-05-04' },
                            { title: 'Evento 1', description: 'Test data', date: '2021-05-04' },
                            { title: 'Evento 1', description: 'Test data', date: '2021-05-04' },
                            { title: 'Evento 1', description: 'Test data', date: '2021-05-04' },
                            { title: 'Evento 1', description: 'Test data', date: '2021-05-04' },
                            { title: 'Evento 1', description: 'Test data', date: '2021-05-04' },
                            { title: 'Evento 1', description: 'Test data', date: '2021-05-04' },
                            { title: 'Evento 1', description: 'Test data', date: '2021-05-04' },
                            { title: 'Tarea 1', date: '2021-05-05' },
                            { title: 'Evento 2', start: '2021-05-05T12:30:00Z',end:'2021-05-09T12:30:00Z' },
                            { title: 'Evento 3', start: '2021-05-10T12:30:00Z',allDay:false }
                          ]}*/
