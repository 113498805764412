import React, { Component, useState, useEffect } from "react"
import {
  CardBody,
  Card,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"

import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import { getDecryptedItem } from '../../util';
//Import Breadcrumb
import ModalFlujo from "pages/Componentes/ModalFlujo"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/tablescss/FormatTable.scss"
import instance from '../../config'
import ReactApexChart from "react-apexcharts"
import ModalEvento from "pages/Menus/Evento/Componentes/ModalEvento"
import ModalTarea from "pages/Menus/Tarea/Componentes/ModalTarea"
import ComboState from "pages/Menus/Context/Combos/ComboState"
import { arrayInsert } from "redux-form"
const baseUrl = process.env.REACT_APP_API_URL
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
var meses = new Array("Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre");

const DashboardAbogado = props => {

  const [modalFlujo, setModalFlujo] = useState(false)
  const [getModalEvento, setModalEvento] = useState(false)
  const [getModalTarea, setModalTarea] = useState(false)
  const [GetParticipantes, setParticipantes] = useState([])
  const [getCantidadTarea, setCantidadTarea] = useState(4)
  const [getCantidadEventos, setCantidadEventos] = useState(4)
  const [getCantidadNovedad, setCantidadNovedad] = useState(4)
  const [getSeriesGraficoLineaU, setSeriesGraficoLineaU] = useState([])
  const [getSeriesGraficoLineaS, setSeriesGraficoLineaS] = useState([])
  const [getFiltroGrafico, setFiltroGrafico] = useState({
    M: parseInt(Fecha.getMonth() + 1),
    Y: parseInt(Fecha.getFullYear()),
  })
  const options = {
    maintainAspectRatio: false,
    labels: [" Horas laboradas", "No Facturable"],
    colors: ["#00A94F", "#AA1B0E"],
    legend: { show: !1 },
    plotOptions: {
      pie: {
        donut: {
          size: "50%",
        },
      },
    },
  }
  const series2 = [
    {
      name: ["Facturado S/"],
      data: getSeriesGraficoLineaS,
      color: "#AA1B0E",
    },
    {
      name: ["Facturado U$"],
      data: getSeriesGraficoLineaU,
      color: "#00A94F",
    },
  ]
  const options2 = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    title: {
      text: "Facturado",
      align: "left",
      style: {
        fontSize: '14px',
        color: '#263238',
        fontFamily: 'texto-combex',
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic",
      ],
    },
  }
  const options3 = {
    maintainAspectRatio: false,
    labels: ["Pendiente", "Realizadas", "Rechazadas", "Vencidos"],
    colors: ["#1F60AB", "#00A94F", "#AA1B0E", "#ad8e93"],
    legend: { show: !1 },
    plotOptions: {
      pie: {
        donut: {
          size: "50%",
        },
      },
    },
  }
  const [data, setData] = useState([])
  const [dataCaso, setDataCaso] = useState([])
  const [dataAuditoria, setDataAuditoria] = useState([])
  const [dataNov, setDataNov] = useState([])
  const [dataTareasEstadisticas, setDataTareasEstadisticas] = useState([])
  const [dataHorasLaboradas, setHorasLaboradas] = useState([])
  const [getAnioData, setAnioData] = useState([])
  const [HorasLabHL, setHorasHorasLabHL] = useState("")
  const [HorasLabHF, setHorasHorasLabHF] = useState("")
  const [HorasLabHNF, setHorasHorasLabHNF] = useState("")
  const [dataEvento, setDataEvento] = useState([])
  const [dataTarea, setDataTarea] = useState([])
  const [getRow, setRow] = useState({})
  var Listar = []

  //ear
  const SendAnios = () => {
    const anio = ano
    const anios = []
    for (var i = 2021; i <= anio + 3; i++) {
      anios.push({ anio: i })
    }
    setAnioData(anios)
    console.table(anios)
  }
  const SerieTarea = dataTareasEstadisticas.map(
    (row, i) =>
    (
      Listar = [row.pendientes, row.realizados, row.rechazados, row.vencidas]
    )
  )
  var Listar2 = [];
  var Listar2Obj = {
    HorasLaboradas: "",
    HorasFacturables: "",
    HorasNoFacturables: "",
  };

  //Listado de la base de datos
  const SerieHorasLaboradas = dataHorasLaboradas.map(
    (row, i) =>
      (Listar2 = [(parseInt(row.tLab_HoraFacturable) + parseFloat(row.tLab_MinutoFacturable / 100)), (parseInt(row.tLab_HoraNoFacturable) + parseFloat(row.tLab_MinutoNoFacturable / 100))])
  )

  const FuncionListarTareas = async () => {
    var postData = {
      nUsu_ID: getDecryptedItem("ID"),
    }
    await instance
      .post("/Tareas/DH", postData)
      .then(response => {

        setDataTarea(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarEventos = async () => {

    console.log(getDecryptedItem("ID"));
    console.log(getDecryptedItem("ID"));

    var postData = {
      nCas_Id: null,
      nCli_Id: null,
      nUsu_ID: getDecryptedItem("ID"),
    }

    console.log(postData);

    await instance
      .post("/Eventos/DH", postData)
      .then(response => {

        console.log(response);
        setDataEvento(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarCasos = async () => {
    var postData = {
      nCli_Id: null,
      nUsu_Id: getDecryptedItem("ID"),
    }
    await instance
      .post("/Casos/Buscar/Cliente", postData)
      .then(response => {
        setDataCaso(response.data)
      })
      .catch(error => {

      })
  }
  const FormatoFecha = (fecha) => {
    try {
      if (fecha == 'No Proveido') {
        return fecha;
      } else {
        var d = fecha.substring(0, 2);
        var m = fecha.substring(4, 2);
        var y = fecha.substring(4);
        var fch = d + "-" + m + "-" + y;
        return fch;
      }
    } catch (error) {
      return ''
    }
   
  }
  const FuncionListarCasosNov = async () => {
    var postData = {
      nUsu_ID: parseInt(getDecryptedItem('ID')),
      nNov_Estado: 1,
    }

    console.log(postData);
    await instance
      .post("/Dashboard/Casos/Novedades/Listar", postData)
      .then(response => {
        console.log(response);
        setDataNov(response.data)
      })
      .catch(error => {

      })
  }

  const FuncionBuscarTareasParticipantes = async (_nTar_Id) => {
    var postData = {
      nTar_Id: _nTar_Id,
    }
    await instance
      .post("/Tarea/Buscar/Participante", postData)
      .then(response => {

        setParticipantes(response.data);
        setModalTarea(!getModalTarea)

      })
      .catch(error => {

      })
  }

  const FuncionBuscarEventosParticipantes = async (_nEve_Id) => {   // Aqui se carga GetdefaultParticipantes
    var postData = {
      nEve_Id: _nEve_Id,
    }
    await instance
      .post("/Evento/Buscar/Participantes", postData)
      .then(response => {

        setParticipantes(response.data);
      })
      .catch(error => {

      })
  }
  const abrirCerrarModal = (Modal, Row) => {
    if (Modal == 1) {

      setRow(Row)
      FuncionBuscarEventosParticipantes(Row.nEve_Id)
      setTimeout(() => {
        setModalEvento(!getModalEvento)
      }, 500);
    }
    else if (Modal == 2) {
      setModalFlujo(!modalFlujo)
    }
    else if (Modal == 3) {



      FuncionBuscarTareasParticipantes(Row.nTar_Id)
      setRow(Row)
      setTimeout(() => {
        setModalTarea(!getModalTarea)
      }, 500);
    }
  }
  const FuncionListarTareasEstadisticas = async () => {
    var postData = {
      nUsu_ID: getDecryptedItem("ID"),
    }
    await instance
      .post("/Dashboard/Tareas", postData)
      .then(response => {
        setDataTareasEstadisticas(response.data)
      })
      .catch(error => {

      })
  }

  const SelectDetalle = async (nCas_Id, nCli_Id, nEqun_Id) => {
    setEncryptedItem('nCas_Id', nCas_Id);
    setEncryptedItem('CASOIDCli', nCli_Id);

    let ID =  getDecryptedItem("ID");

    console.log("ID : ", ID);
    CambiarEstadoNovedadMasivo(ID, nCas_Id);
  
  }

  const CambiarEstadoNovedadMasivo = async (ID, _nCas_Id) => {

    var postData = {
        ID: parseInt(ID),
        nCas_Id: _nCas_Id
    }


    console.log(postData);

    await instance
    .post("/Dashboard/Casos/Novedades/CambiarEstadoMasivo", postData)
    .then(response => {
    console.log(response.data)
    })
    .catch(error => {

    })
}

  const FuncionListarHorasLaboradas = async () => {
    var postData = {
      nUsu_ID: parseInt(getDecryptedItem("ID")),
      M: getFiltroGrafico.M,
      Y: getFiltroGrafico.Y
    }

    await instance
      .post("/Dashboard/HorasLaboradas", postData)
      .then(response => {

        setHorasLaboradas(response.data)
        var HL = response.data[0].nLab_Hora
        var ML = response.data[0].nLab_Minuto
        var HF = response.data[0].tLab_HoraFacturable
        var MF = response.data[0].tLab_MinutoFacturable
        var HnF = response.data[0].tLab_HoraNoFacturable
        var MnF = response.data[0].tLab_MinutoNoFacturable
        if (response.data[0].nLab_Hora.length == 1) {
          HL = "0" + response.data[0].nLab_Hora
        }
        if (response.data[0].nLab_Minuto.length == 1) {
          ML = "0" + response.data[0].nLab_Minuto
        }
        if (response.data[0].tLab_HoraFacturable.length == 1) {
          HF = "0" + response.data[0].tLab_HoraFacturable
        }
        if (response.data[0].tLab_MinutoFacturable.length == 1) {
          MF = "0" + response.data[0].tLab_MinutoFacturable
        }
        if (response.data[0].tLab_HoraNoFacturable.length == 1) {
          HnF = "0" + response.data[0].tLab_HoraNoFacturable
        }
        if (response.data[0].tLab_MinutoNoFacturable.length == 1) {
          MnF = "0" + response.data[0].tLab_MinutoNoFacturable
        }

        setHorasHorasLabHL(HL + ":" + ML)
        setHorasHorasLabHF(HF + ":" + MF)
        setHorasHorasLabHNF(HnF + ":" + MnF)
      })
      .catch(error => {

      })
  }
  const Meses = [
    "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"
  ]
  const FuncionListarMesesLaborados = async () => {
    var postData = {
      nUsu_ID: parseInt(getDecryptedItem("ID")),
      nCas_Id: null,
      nCli_Id: null,
      M: getFiltroGrafico.M,
      Y: getFiltroGrafico.Y
    }
    await instance
      .post("/Dashboard/MesesLaborados", postData)
      .then(response => {


        let Array = [
          { "Mes": "01", "Cantidad": "0" },
          { "Mes": "02", "Cantidad": "0" },
          { "Mes": "03", "Cantidad": "0" },
          { "Mes": "04", "Cantidad": "0" },
          { "Mes": "05", "Cantidad": "0" },
          { "Mes": "06", "Cantidad": "0" },
          { "Mes": "07", "Cantidad": "0" },
          { "Mes": "08", "Cantidad": "0" },
          { "Mes": "09", "Cantidad": "0" },
          { "Mes": "10", "Cantidad": "0" },
          { "Mes": "11", "Cantidad": "0" },
          { "Mes": "12", "Cantidad": "0" },
        ];
        let Array2 = [
          { "Mes": "01", "Cantidad": "0" },
          { "Mes": "02", "Cantidad": "0" },
          { "Mes": "03", "Cantidad": "0" },
          { "Mes": "04", "Cantidad": "0" },
          { "Mes": "05", "Cantidad": "0" },
          { "Mes": "06", "Cantidad": "0" },
          { "Mes": "07", "Cantidad": "0" },
          { "Mes": "08", "Cantidad": "0" },
          { "Mes": "09", "Cantidad": "0" },
          { "Mes": "10", "Cantidad": "0" },
          { "Mes": "11", "Cantidad": "0" },
          { "Mes": "12", "Cantidad": "0" },
        ];
        let ArrayU = []
        let ArrayS = []
        for (var i = 0; i < response.data.length; i++) {
          for (var j = 0; j < Meses.length; j++) {
            if (response.data[i].Mes == Meses[j]) {
              if (response.data[i].Moneda == "Dólares") {
                Array[j].Cantidad = response.data[i].Total;
              } else {
                Array2[j].Cantidad = response.data[i].Total;
              }
            }
          }
        }
        for (var i = 0; i < Array.length; i++) {
          ArrayU.push(Array[i].Cantidad)
        }
        for (var i = 0; i < Array2.length; i++) {
          ArrayS.push(Array2[i].Cantidad)
        }


        setSeriesGraficoLineaU(ArrayU)
        setSeriesGraficoLineaS(ArrayS)
      })
      .catch(error => {

      })
  }
  const ListarAuditoria = async () => { // Guardar Lectura del caso

    await instance
      .post("/Auditoria/listarCasos")
      .then(response => {

        setDataAuditoria(response.data)
      });
  }
  const handleChangeFiltro = e => {
    setFiltroGrafico({
      ...getFiltroGrafico,
      [e.target.name]: e.target.value
    })
  }
  const BuscarFiltro = () => {
    FuncionListarMesesLaborados()
    FuncionListarHorasLaboradas();
  }

  const validateAbogadoDashboardAccess = () => {
    return new Promise((resolve, reject) => {
        const userRole = getDecryptedItem("RolID");
        console.log(userRole);
        if (userRole == '9') {
            window.location.href = '/AccessDenied';
            reject(true);
        } else {
            resolve(false);
        }
    });
  };

  useEffect(async () => {

    await validateAbogadoDashboardAccess();

    ListarAuditoria();
    SendAnios();
    FuncionListarMesesLaborados()
    FuncionListarHorasLaboradas();
    FuncionListarEventos()
    FuncionListarTareas()
    FuncionListarCasos()
    FuncionListarTareasEstadisticas()
    FuncionListarCasosNov()
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard del Usuario | GpsLegal</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="GpsLegal"
            breadcrumbItem="Dashboard del Usuario"
          />
          <Row className="mb-3">
            <Col xl="1">
              <select
                className="form-select inputDown"
                name="M"
                onChange={handleChangeFiltro}
                value={getFiltroGrafico && getFiltroGrafico.M}
              >
                <option value="1">Enero</option>
                <option value="2">Febrero</option>
                <option value="3">Marzo</option>
                <option value="4">Abril</option>
                <option value="5">Mayo</option>
                <option value="6">Junio</option>
                <option value="7">Julio</option>
                <option value="8">Agosto</option>
                <option value="9">Setiembre</option>
                <option value="10">Octubre</option>
                <option value="11">Noviembre</option>
                <option value="12">Diciembre</option>
              </select>
            </Col>
            <Col xl="1">
              <select
                className="form-select inputDown"
                name="Y"
                onChange={handleChangeFiltro}
                value={getFiltroGrafico && getFiltroGrafico.Y}
              >
                {getAnioData.map((row, i) => (
                  <option key={i} value={row.anio}>
                    {row.anio}
                  </option>
                ))}
              </select>
            </Col>
            <Col xl="2">
              <button
                type="button"
                className="btn btn-danger waves-effect waves-light"
                onClick={() => BuscarFiltro()}
              >
                Filtrar
              </button>
            </Col>
          </Row>
          <Row>
            <Col xl="3">
              <Card className="cardBorder">
                <CardBody>
                  <h4 className="card-title mb-4">Estadística de horas (hh:mm)</h4>
                  <div>
                    <div id="donut-chart" className="apex-charts">
                      <ReactApexChart
                        options={options}
                        series={Listar2}
                        type="donut"
                        height={200}
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="row" style={{ paddingLeft: "5%" }}>
                      <div className="col-md-8 col-6">
                        <p className="text-truncate font-size-14 mb-1">
                          <i className="icon-esfera-2d text-success" />{" "}
                          Facturable
                        </p>
                      </div>
                      <div className="col-md-2 col-6" style={{ paddingRight: "10px", textAlign: 'right' }}><a style={{ float: "right" }}>{HorasLabHF}</a></div>
                    </div>
                    <div className="row " style={{ paddingLeft: "5%" }}>
                      <div className="col-md-8 col-6">
                        <p className="text-truncate font-size-14 mb-1">
                          <i className="col-md-12 icon-esfera-2d text-danger" />{" "}
                          No Facturable
                        </p>
                      </div>
                      <div className="col-md-2" style={{ paddingRight: "10px", textAlign: 'right' }}> <a style={{ float: "right" }}>{HorasLabHNF}</a></div>
                    </div>
                    <div className="row" style={{ paddingLeft: "5%" }}>
                      <div className="col-md-8 col-6">
                        <p className="text-truncate font-size-14 mb-1">{" "}
                          &nbsp;&nbsp;&nbsp;
                          Horas laboradas
                        </p>
                      </div>
                      <div className="col-md-2" style={{ paddingRight: "10px", textAlign: 'right' }}><a style={{ float: "right" }}>{HorasLabHL}</a></div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card className="cardBorder">
                <CardBody>
                  <div className="clearfix">
                    <h4 className="card-title mb-4"></h4>
                  </div>
                  <Row>
                    <Col lg="12">
                      <div id="line-chart" className="apex-charts" dir="ltr">
                        <ReactApexChart
                          series={series2}
                          options={options2}
                          type="line"
                          height={200}
                          className="apex-charts"
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="cardBorder">
                <CardBody>
                  <h4 className="card-title mb-4">Mis tareas</h4>
                  <div>
                    <div id="donut-chart" className="apex-charts">
                      <ReactApexChart
                        options={options3}
                        series={Listar}
                        type="donut"
                        height={200}
                      />
                    </div>
                  </div>
                  <div className="text-muted">
                    {dataTareasEstadisticas.map((row, i) => (
                      <div key={i} style={{ textAlign: 'center' }}>
                        <div className="row" style={{ paddingLeft: "5%", textAlign: 'left' }}>
                          <div className="col-md-7">
                            <p className="text-truncate font-size-14 mb-2" >
                              <i className="icon-esfera-2d text-success" />{" "}
                              Realizadas
                            </p>
                          </div>
                          <div className="col-md-2">
                            <a style={{ float: "right" }}>{row.realizados}</a>
                          </div>
                        </div>
                        <div className="row" style={{ paddingLeft: "5%", textAlign: 'left' }}>
                          <div className="col-md-7">
                            <p className="text-truncate font-size-14 mb-1">
                              <i className="icon-esfera-2d text-primary" />{" "}
                              Pendientes
                            </p>
                          </div>
                          <div className="col-md-2">
                            <a style={{ float: "right" }}>{row.pendientes}</a>
                          </div>
                        </div>
                        <div className="row" style={{ paddingLeft: "5%", textAlign: 'left' }}>
                          <div className="col-md-7">
                            <p className="text-truncate font-size-14 mb-1">
                              <i className="icon-esfera-2d text-danger" />{" "}
                              Rechazadas
                            </p>
                          </div>
                          <div className="col-md-2">
                            <a style={{ float: "right" }}>{row.rechazados}</a>
                          </div>
                        </div>
                        <div className="row center" style={{ paddingLeft: "5%", textAlign: 'left' }}>
                          <div className="col-md-7">
                            <p className="text-truncate font-size-14 mb-1">
                              <i className="icon-esfera-2d text-text" />{" "}
                              Vencidas
                            </p>
                          </div>
                          <div className="col-md-2">
                            <a style={{ float: "right" }}>{row.vencidas}</a>
                          </div>
                        </div>
                        <div className="row" style={{ paddingLeft: "5%", textAlign: 'left' }}>
                          <div className="col-md-7">
                            <p className="text-truncate font-size-14 mb-1">
                              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                              Total
                            </p>
                          </div>
                          <div className="col-md-2">
                            <a style={{ float: "right" }}>{row.total}</a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="5">
              <Card className="cardBorder">
                <CardBody>
                  <h4 className="card-title mb-3">
                    Novedades Judiciales{" "}
                    <span className="badge bg-danger rounded-pill">{dataNov.length}</span>
                    <Link to="/HistorialNovedades" style={{ float: "right" }} className="badge bg-danger ">Ver historial</Link>
                  </h4>
                  <div >
                    {dataNov.slice(0, getCantidadNovedad).map((row, i2) => (
                      <Card className="cardBorder " key={i2} >
                        <CardBody>
                          <Link to="/dashboard-Casos" onClick={() => SelectDetalle(row.nCas_Id, row.nCli_Id, row.nEqun_Id)}>
                            <div className="row mb-1">
                              <div className="col-md-4 container">
                                <b className="text-truncate font-size-14 mb-1">
                                  Proceso
                                </b>
                              </div>
                              <div className="col-md-8 container" style={{ paddingLeft: '4.5px' }}>
                                <b className="text-truncate font-size-14 mb-1">
                                  {row.cCas_Cod_Externo} <a style={{ float: "right", fontSize: 12 }} >{row.nNov_Fecha2}</a>
                                </b>
                              </div>
                              <hr
                                style={{ color: "#e01646", height: "2px" }}
                              ></hr>
                            </div>
                            <div className="row mb-1">
                              <div className="col-md-4">
                                <p className="mb-0">Juzgado</p>
                              </div>
                              <div className="col-md-8" style={{ paddingLeft: '4.5px' }}>
                                <p className="mb-0">
                                  {row.nJuz_Id2}
                                </p>
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-md-4">
                                <p className="mb-0">Cliente</p>
                              </div>
                              <div className="col-md-8" style={{ paddingLeft: '4.5px' }}>
                                <p className="mb-0">
                                  {row.cCli_NombreCompleto}
                                </p>
                              </div>
                            </div>
                            <div className="row mb-4">
                              {row.cNov_Movimiento?.map((row2, i) => (
                                <div key={i} style={{ marginBottom: "30px" }}>
                                  <div className="row">
                                    <div className="col-md-4 container">
                                      <b className="text-truncate font-size-14 mb-1">
                                        Acto / Resolución
                                      </b>
                                    </div>
                                    <div className="col-md-8 container">
                                      <b className="text-truncate font-size-14 mb-1">
                                        {row2?.Acto} {" / "} {row2?.Resolucion}
                                      </b>
                                    </div>
                                    <hr
                                      style={{ color: "#e01646", height: "2px" }}
                                    ></hr>
                                  </div>
                                  <div className="row mb-1">
                                    <div className="col-md-4">
                                      <p className="mb-0">Sumilla</p>
                                    </div>
                                    <div className="col-md-8">
                                      <p className="mb-0">
                                        {row2?.Sumilla}
                                      </p>
                                    </div>
                                    <div className="col-md-4">
                                      <b className="mb-0">Fecha Resolucion</b>
                                    </div>
                                    <div className="col-md-8">
                                      <p className="mb-0">
                                        {FormatoFecha(row2?.FechaResolucion)}
                                      </p>
                                    </div>
                                    <div className="col-md-4">
                                      <b className="mb-0">Proveido</b>
                                    </div>
                                    <div className="col-md-8">
                                      <p className="mb-0">
                                        {FormatoFecha(row2?.Proveido)}
                                      </p>
                                    </div>

                                  </div>
                                </div>
                              ))}
                            </div>
                            <div className="row mb-4">
                              {row.cNov_Notificacion?.map((row, i) => (
                                <div key={i} style={{ marginBottom: "30px" }}>
                                  <div className="row">
                                    <div className="col-md-4 container">
                                      <b className="text-truncate font-size-14">
                                        Titulo / Anexo
                                      </b>
                                    </div>
                                    <div className="col-md-8 container">
                                      <b className="text-truncate font-size-14 ">
                                        {row?.titulo} {" / "} {row?.anexos}
                                      </b>
                                    </div>
                                    <hr
                                      style={{ color: "#e01646", height: "2px" }}
                                    ></hr>
                                  </div>
                                  <div className="row ">
                                    <div className="col-md-4">
                                      <b className="mb-0">Fecha envio</b>
                                    </div>
                                    <div className="col-md-8">
                                      <p className="mb-0">
                                        {FormatoFecha(row?.fechaEnvio)}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </Link>
                        </CardBody>
                      </Card>
                    ))}
                  </div>
                  <div className="card-footer bg-transparent border-top">
                    <div className="text-center">
                      <button
                        onClick={() => setCantidadNovedad(getCantidadNovedad + 4)}
                        className="btn btn-secondary waves-effect waves-light"
                      >
                        {" "}
                        Cargar más
                      </button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="4">
              <Card className="cardBorder">
                <CardBody>
                  <h4 className="card-title mb-4">Casos recientes{" "}
                    <span className="badge bg-danger rounded-pill">{dataAuditoria.length}</span>
                  </h4>
                  <div>
                    {dataAuditoria.slice(0, 5).map((row, i) => (
                      <Link key={i} style={{ color: "black" }} to="/dashboard-Casos">
                        <div className="row" key={i} onClick={() => setEncryptedItem('nCas_Id', row.nAud_IdReg)}>
                          <div className="col-md-2" style={{ width: "38px" }}>
                            <i className="icon-Documentos" style={{ color: "#1F60AB", fontSize: "25px", paddingTop: "5px" }} />
                            {" "}</div>
                          <div className="col-md-10">
                            <p className="text-truncate font-size-14 mb-1">
                              <b>CASO-{row.cCas_Cod_Externo}</b>
                            </p>
                            <p className="text-truncate font-size-11 mb-1">
                              {row.cCas_Titulo}
                            </p>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </CardBody>
              </Card>
              <Card className="cardBorder">

                <CardBody style={{ paddingLeft: '9px', paddingRight: '4px' }}>
                  <div className="clearfix" style={{ paddingLeft: '10.5px' }}>
                    <h4 className="card-title mb-4">
                      Próximos Eventos /
                    </h4>
                  </div>
                  <div className="table-responsive mt-4">
                    <h4 className="card-title mb-4" style={{ paddingLeft: '10.5px' }}>Tareas</h4>
                    <Table className="table align-middle mb-0 borderColorTable">
                      <tbody>
                        {dataTarea.slice(0, getCantidadTarea).map((row, i) => (
                          <tr key={i} onClick={() => abrirCerrarModal(3, row)} style={{ cursor: "pointer" }}>
                            <td style={{ maxWidth: '20px' }}>
                              {(() => {
                                if (row.cTar_Tab_Tipo == "TT01") {
                                  return (
                                    <i className="icon-tarea" style={{ color: "#1F60AB", fontSize: "25px", paddingTop: "5px" }} />
                                  )
                                } else if (row.cTar_Tab_Tipo == "TT02") {
                                  return (
                                    <i className="icon-tarea" style={{ color: "#00A94F", fontSize: "25px", paddingTop: "5px" }} />
                                  )
                                } else if (row.cTar_Tab_Tipo == "TT03") {
                                  return (
                                    <i className="icon-tarea" style={{ color: "#AA1B0E", fontSize: "25px", paddingTop: "5px" }} />
                                  )
                                } else if (row.cTar_Tab_Tipo == "TT04") {
                                  return (
                                    <i className="icon-tarea" style={{ color: "#AA1B0E", fontSize: "25px", paddingTop: "5px" }} />
                                  )
                                }
                              })()}{" "}

                            </td>
                            <td colspan="1" style={{ maxWidth: '170px' }} className="Texto-Limit" >
                              <h5
                                style={{ color: "#032D5E" }}
                                className="font-size-14 mb-1"
                              >
                                {row.cTar_Titulo}
                              </h5>
                              <p className="mb-0">
                                CASO-{row.cCas_Cod_Interno}
                              </p>
                              <p className="mb-0">
                                {row.cCas_Cod_Externo}
                              </p>
                              <p className="mb-0" style={{ width: "30px" }}>
                                {row.cCli_NombreCompleto}
                              </p>
                            </td>
                            <td className="Texto-Limit" style={{ maxWidth: '65px', textAlign: 'right', paddingRight: '10px' }}>
                              <p className="mb-1 ">
                                {row.dTar_Fec_Vence2}
                              </p>
                              <p className="mb-1" style={{ textAlign: 'right', paddingRight: '2px' }}>
                                {row.tTar_Hora_Vence2}
                              </p>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div className="card-footer bg-transparent border-top">
                      <div className="text-center">
                        <button
                          onClick={() => setCantidadTarea(getCantidadTarea + 4)}
                          className="btn btn-secondary waves-effect waves-light"
                        >
                          Cargar más
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive mt-4">
                    <h4 className="card-title mb-4" style={{ paddingLeft: '10.5px' }}>Eventos</h4>
                    <Table className="table align-middle mb-0 borderColorTable">
                      <tbody>
                        {dataEvento.slice(0, getCantidadEventos).map((row, i) => (
                          <tr key={i} onClick={() => abrirCerrarModal(1, row)} style={{ cursor: "pointer" }}>
                            <td style={{ maxWidth: '20px' }}>

                              {(() => {
                                if (row.cEve_Estado == "TT01") {
                                  return (
                                    <i className="icon-eventos2" style={{ color: "#1F60AB", fontSize: "25px", paddingTop: "5px" }} />
                                  )
                                } else if (row.cEve_Estado == "TT02") {
                                  return (
                                    <i className="icon-eventos2" style={{ color: "#00A94F", fontSize: "25px", paddingTop: "5px" }} />
                                  )
                                } else if (row.cEve_Estado == "TT03") {
                                  return (
                                    <i className="icon-eventos2" style={{ color: "#AA1B0E", fontSize: "25px", paddingTop: "5px" }} />
                                  )
                                } else if (row.cEve_Estado == "TT04") {
                                  return (
                                    <i className="icon-eventos2" style={{ color: "#AA1B0E", fontSize: "25px", paddingTop: "5px" }} />
                                  )
                                }
                              })()}



                            </td>
                            <td colspan="2" style={{ maxWidth: '170px' }} className="Texto-Limit">
                              <h5
                                style={{ color: "#032D5E" }}
                                className="font-size-14 mb-1"
                              >
                                {row.cEve_Titulo}
                              </h5>
                              <p className="mb-0">
                                CASO-{row.cCas_Cod_Interno}
                              </p>
                              <p className="mb-0">
                                {row.cCas_Cod_Externo}
                              </p>
                              <p className="mb-0">
                                {row.cCli_NombreCompleto}
                              </p>
                            </td>
                            <td className="Texto-Limit" style={{ maxWidth: '65px', textAlign: 'right', paddingRight: '10px' }}>
                              <p className="mb-1">
                                {row.dEve_FechaFin2}
                              </p>
                              <p className="mb-1" style={{ textAlign: 'right', paddingRight: '2px' }}>
                                {row.tEve_HoraFin2}
                              </p>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <ComboState>
                      {(() => {
                        if (getModalEvento == true) {
                          return <>
                            <ModalEvento show={getModalEvento}
                              onCloseClick={() => { setModalEvento(!getModalEvento), FuncionListarEventos() }}
                              Condicion="1"
                              Row={getRow}
                              arrayParticipantesEventos={GetParticipantes}
                              Id="2"
                              onCloseModal={() => { setModalEvento(!getModalEvento), FuncionListarEventos() }}
                            />
                          </>
                        }
                      })()}
                      {(() => {
                        if (getModalTarea == true) {
                          return <>
                            <ModalTarea show={getModalTarea}
                              onCloseClick={() => { setModalTarea(!getModalTarea) }}
                              Condicion="1"
                              Row={getRow}
                              arrayParticipantes={GetParticipantes}
                              Id="2"
                              onCloseModal={() => { setModalTarea(!getModalTarea), FuncionListarTareas() }}
                            />
                          </>
                        }
                      })()}
                    </ComboState>
                    <div className="card-footer bg-transparent border-top">
                      <div className="text-center">
                        <button
                          onClick={() => setCantidadEventos(getCantidadEventos + 4)}
                          className="btn btn-secondary waves-effect waves-light"
                        >
                          {" "}
                          Cargar más
                        </button>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {(() => {
            if (modalFlujo == true) {
              return <>
                <ModalFlujo show={modalFlujo}
                  onCloseClick={() => abrirCerrarModal(2)}
                  //onCloseModal={() => setModalInsertar(false)}
                  //ReloadLista={() => ReloadModalListar()}
                  Titulo="Nuevo Flujo"
                />
              </>
            }
          })()}
        </Container>
      </div>
    </React.Fragment >
  )
}
export default DashboardAbogado
