import React, { Component, useState, useEffect } from "react"
import { Alert, Row, Col, CardBody, Card, Container } from "reactstrap"
import MetaTags from "react-meta-tags"
import { Link, useParams } from "react-router-dom"
import Swal from "sweetalert2"
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
// import images
import profile from "../../assets/images/fda9a84e-df37-473f-ad02-1f39be316056.jpg"
import instance from '../../config'
import { setEncryptedItem } from "util"

const jsonwebtoken = require('jsonwebtoken');

var idCliente = null;
var token = null;
const RecuperarContraseña = props => {
  const baseUrl = process.env.REACT_APP_API_URL
  const [formulario, setFormulario] = useState(false)
  const [getVisible1, setVisible1] = useState("")
  const [getVisible2, setVisible2] = useState("none")
  const [getVisible3, setVisible3] = useState("")
  const [getVisible4, setVisible4] = useState("none")
  const [getUsuario, setUsuario] = useState({})
  var param = useParams();

  var FuncionGetToken = async () => {
    token = param.idCliente;

    console.log(token);
    const SECRET_KEY_JWT = "secretkey5353";
    const payload = await jsonwebtoken.verify(token, SECRET_KEY_JWT);
    if (!payload) {
      return res.status(401).send('Unauhtorized Request');
    }
    else {
      console.log(payload);
      var nUsu_ID = payload.payload.id;
      let objeto = {
        _nUsu_Id: nUsu_ID
      }
      await instance
        .post("/Validar/LoginEstado", objeto)
        .then(res => {
          console.log(res)
          setUsuario(res.data[0]);
          setEncryptedItem('Correo', res.data[0].cUsu_email);


          if (res.data.length == 0) {

            location.href = "/IniciarSesion";
          }
          else if (res.data.length > 0) {

          }
        })
        .catch(error => {

        })
    }
  }
  var FuncionChangePass = async () => {
    FuncionGetToken();
    let pass = document.getElementById("pass").value;
    let passConfirm = document.getElementById("pass2").value;
    if (pass == undefined || pass == null || pass.trim() == "") {

      Swal.fire({
        title: "Mensaje",
        text: "Ingrese una contraseña",
        icon: "error",
        confirmButtonText: "Aceptar",
      })
    }
    else if (passConfirm == undefined || passConfirm == null || passConfirm.trim() == "") {

      Swal.fire({
        title: "Mensaje",
        text: "Confirme la contraseña",
        icon: "error",
        confirmButtonText: "Aceptar",
      })
    }
    else if (pass != passConfirm) {

      Swal.fire({
        title: "Mensaje",
        text: "Las contraseñas no coinciden",
        icon: "error",
        confirmButtonText: "Aceptar",
      })
    }
    else {
      token = param.idCliente;
      const SECRET_KEY_JWT = "secretkey5353";

      const payload = await jsonwebtoken.verify(token, SECRET_KEY_JWT);

      const postData = {
        idCliente: payload.payload.id,
        pass: pass
      }

      console.log(postData);


      await instance
        .post("/Usuario/changepass", postData)
        .then(response => {

          let res = response["data"];

          if (res == "success") {
            Swal.fire({
              title: "Mensaje",
              text: "Se guardo la nueva contraseña",
              icon: "success",
              confirmButtonText: "Aceptar",
            })
            location.href = "/IniciarSesion";
          }
          else {
            Swal.fire({
              title: "Mensaje",
              text: "Ocurrio un error",
              icon: "error",
              confirmButtonText: "Aceptar",
            })
          }

        })
        .catch(error => {

        })


    }
  }
  const changeVisibility = async () => {
    var tipo = document.getElementById("pass");
    if (tipo.type == "password") {
      tipo.type = "text";
      setVisible1("none")
      setVisible2("")
    } else {
      tipo.type = "password";
      setVisible1("")
      setVisible2("none")
    }
  }
  const changeVisibilityConfirm = async () => {
    var tipo = document.getElementById("pass2");

    if (tipo.type == "password") {
      tipo.type = "text";
      setVisible3("none")
      setVisible4("")
    } else {
      tipo.type = "password";
      setVisible3("")
      setVisible4("none")
    }
  }

  useEffect(() => {
    FuncionGetToken();
  }, [])
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <MetaTags>
          <title>Cambiar contraseña | GpsLegal</title>
        </MetaTags>
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={3} lg={3} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0" isOpen={formulario}>
                  <div style={{ paddingTop: "10px" }} className="mb-3 row" isOpen={formulario}>
                    <Link to="/" className="auth-logo-dark">
                      <img
                        src={profile}
                        alt=""
                        style={{
                          width: "100%",
                          height: "auto",
                          marginLeft: "auto",
                          marginRight: "auto",
                          display: "block",
                        }}
                      />
                    </Link>
                  </div>
                  <div className="p-2" isOpen={formulario}>
                    <AvForm className="mb-3">
                      <div className="row mb-12">
                        <div className="col-md-10" style={{ width: '90%', paddingRight: '0px', marginRight: '0px' }}>
                          <label className="col-form-label labelDown" style={{ paddingLeft: '0px', marginLeft: '0px', fontSize: '13px' }}>
                            Correo electronico
                          </label>
                          <div className="form-group">
                            <input
                              readOnly
                              className="form-control"
                              type="text"
                              name="cUsu_email"
                              value={getUsuario && getUsuario.cUsu_email}
                            />

                          </div>
                        </div>
                        <div className="col-md-10" style={{ width: '90%', paddingRight: '0px', marginRight: '0px', marginTop: '10px' }}>
                          <div className="form-group">
                            <AvField
                              name="password"
                              id="pass"
                              label="Nueva contraseña"
                              className="form-control"
                              placeholder="Ingrese la nueva contraseña"
                              type="password"
                            />
                          </div>
                        </div>
                        <div className="col-md-2 d-flex justify-content-center align-items-center col-conten"
                          style={{
                            borderRadius: '5px',
                            cursor: 'pointer',
                            width: '8%',
                            height: '30px',
                            marginTop: '42px',
                            paddingLeft: '6px', marginLeft: '0px'
                          }}
                          onClick={() => changeVisibility()}
                        >
                          <i className="icon-ver " style={{ display: getVisible1 }}> </i>
                          <i className="icon-no-ver t" style={{ display: getVisible2 }}> </i>
                        </div>
                      </div>

                      <div className="row mb-12">
                        <div className="col-md-10 " style={{ width: '90%', paddingRight: '0px', marginRight: '0px' }}>
                          <div className="form-group" style={{ marginTop: '15px' }}>
                            <AvField
                              name="passwordConfirm"
                              id="pass2"
                              label="Confirmar contraseña"
                              className="form-control"
                              placeholder="Confirme su nueva contraseña"
                              type="password"
                            />
                          </div>
                        </div>
                        <div className="col-md-2 d-flex justify-content-center align-items-center col-conten"
                          style={{
                            borderRadius: '5px',
                            cursor: 'pointer',
                            width: '8%',
                            height: '30px',
                            marginTop: '45px',
                            paddingLeft: '6px', marginLeft: '0px'
                          }}
                          onClick={() => changeVisibilityConfirm()}
                        >
                          <i className="icon-ver" style={{ display: getVisible3 }}> </i>
                          <i className="icon-no-ver" style={{ display: getVisible4 }}> </i>
                        </div>
                      </div>
                      <div className="mt-3 d-grid">
                        <a
                          className="btn btn-block waves-effect waves-light"
                          style={{
                            backgroundColor: "#4b5d81",
                            color: "white",
                          }}
                          href="#"
                          onClick={() => FuncionChangePass()}
                        >
                          Confirmar
                        </a>
                      </div>
                      <div className="mt-4 text-center">
                        <p>
                          Regresar a{" "}
                          <Link
                            to="/IniciarSesion"
                            className="fw-medium text-primary"
                          >
                            Iniciar Sesión
                          </Link>{" "}
                        </p>
                        <p>© {new Date().getFullYear()} GPS Legal </p>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default RecuperarContraseña
