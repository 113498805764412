import React, { Component, useState, useEffect } from "react"
import CasoState from "pages/Menus/Context/Casos/CasoState"
import { MDBDataTable } from "mdbreact"
import { getDecryptedItem, setEncryptedItem } from '../../util';
import {
  CardBody,
  Card,
  Col,
  Container,
  CardTitle,
  Button,
  Row,
  Modal,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Table
} from "reactstrap"

import MetaTags from "react-meta-tags"
import { useParams } from 'react-router-dom';
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/tablescss/FormatTable.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCalendar,
  faEdit,
  faPen,
  faPaperclip,
  faComment,
  faTrash,
  faTimes,
} from "@fortawesome/free-solid-svg-icons"
import Swal from "sweetalert2"
import instance from '../../config'
import ReactApexChart from "react-apexcharts"
import logo from "../../assets/images/logo-dark.png"
import ModalEvento from "pages/Componentes/ModalEvento"
import ModalTarea from "pages/Menus/Tarea/Componentes/ModalTarea"
import ModalCasoU from "pages/Menus/Casos/Componentes/ModalCasoU"
import ModalMovimiento from "pages/Componentes/ModalMovimiento"
import ComboState from "pages/Menus/Context/Combos/ComboState"
const jsonwebtoken = require('jsonwebtoken');
const baseUrl = process.env.REACT_APP_API_URL
import LetteredAvatar from 'react-lettered-avatar';
import ReactTooltip from "react-tooltip"
import uuid from "react-uuid"
import moment from "moment"
import ModalRelacion from "pages/Componentes/ModalRelacion"
import { useHistory } from "react-router-dom"
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10 if (mes < 10) mes="0" + mes //agrega cero si el menor de 10
const DashboardCasos = props => {
  const { token } = useParams();
  let history = useHistory()
  const [GetParticipantesALL, setParticipantesALL] = useState([])
  const [GetParticipantesCaso, setParticipantesCaso] = useState([])
  const [getTipoCaso, setTipoCaso] = useState(0)
  const [getCantidadTarea, setCantidadTarea] = useState(4)
  const [getCantidadTareaFase, setCantidadTareaFase] = useState(4)
  const [getCantidadEventos, setCantidadEventos] = useState(4)
  const [getCantidadNovedad, setCantidadNovedad] = useState(4)
  const [getDataNotas, setDataNotas] = useState([])
  const [getRelacionData, setRelacionData] = useState([])
  const [nCas_Id, setnCas_Id] = useState(0)
  const [getRelacion, setRelacion] = useState({})
  const [getBoolBtnMotivo, setBoolBtnMotivo] = useState(true)
  const [getFlagTotal, setFlagTotal] = useState(false)
  const [getFiltroGrafico, setFiltroGrafico] = useState({
    M: parseInt(Fecha.getMonth() + 1),
    Y: parseInt(Fecha.getFullYear()),
  })
  const [Loading, setLoading] = useState(true)

  var FuncionGetToken = async () => {
    if (token) {
      const SECRET_KEY_JWT = "secretkey5353";
      const payload = await jsonwebtoken.verify(token, SECRET_KEY_JWT);

      if (!payload) {
        return res.status(401).send('Unauthorized Request');
      } else {
        let nCli_Id = payload["payload"]["nCli_Id"];
        let _nCas_Id = payload["payload"]["nCas_Id"];
        setnCas_Id(parseInt(_nCas_Id));

        setEncryptedItem('nCas_Id', _nCas_Id);
        setEncryptedItem('CASOIDCli', nCli_Id);

        await Promise.all([
          FuncionListarEquipo(parseInt(_nCas_Id)),
          FuncionListarEventos(parseInt(_nCas_Id)),
          FuncionListarTareas(parseInt(_nCas_Id)),
          FuncionListarHorasEstadisticas(),
          FuncionBuscarCasos(parseInt(_nCas_Id)),
          FuncionListarMesesLaborados(),
          fListarCuentaTotal(_nCas_Id)
        ]);
      }
    } else if (getDecryptedItem("nCas_Id")) {
      let caso = parseInt(getDecryptedItem("nCas_Id"));
      setnCas_Id(caso);

      await Promise.all([
        FuncionListarTareas(caso),
        FuncionListarEquipo(caso),
        FuncionListarEventos(caso),
        FuncionListarHorasEstadisticas(),
        FuncionBuscarCasos(caso),
        FuncionListarMesesLaborados(),
        fListarCuentaTotal(caso),
        FlujoCasoListar(),
        FnRelacionListar(caso)
      ]);
    } else {
      history.push("/Casos");
    }

    FuncionListarPermisos();
  };

  const arrayWithColors = ['#ff7f50', '#87cefa', '#da70d6', '#32cd32', '#6495ed',
    '#ff69b4', '#ba55d3', '#cd5c5c', '#ffa500', '#40e0d0'];

  const [getModalEvento, setModalEvento] = useState(false)
  const [getModalTarea, setModalTarea] = useState(false)
  const [getModalRelacion, setModalRelacion] = useState(false)
  const [getModalCaso, setModalCaso] = useState(false)
  const [getDataCasoflujo, setDataCasoflujo] = useState({
    cfFas_Nombre: "",
    cfFlu_Nombre: "",
    dfCaf_FecInicio: "",
    nCas_Id: 0,
    nfCaf_Id: 0,
    nfFas_Id: 0
  })
  const [boolFlujo, setBoolFlujo] = useState(true)

  const [getSeriesGraficoLineaU, setSeriesGraficoLineaU] = useState([])
  const [getSeriesGraficoLineaS, setSeriesGraficoLineaS] = useState([])
  const [GetParticipantesA, setParticipantesA] = useState([])
  const [GetParticipantesAR, setParticipantesAR] = useState([])
  const [GetParticipantesP, setParticipantesP] = useState([])
  const [GetParticipantesUAD, setParticipantesUAD] = useState([])
  const [modalActualizar, setModalActualizar] = useState(false)
  const [modalMovimiento, setModalMovimiento] = useState(false)
  const [GetParticipantes, setParticipantes] = useState([])
  const [GetParticipantesEvento, setParticipantesEvento] = useState([])
  const [fileName, setFileName] = useState('');
  const [getJsonCavMovs, setJsonCavMovs] = useState([
    {
      cCav_Archivo: [],
      cCav_Cuadernos: [],
      cCav_General: [],
      cCav_Movimientos: [],
      cCav_Notas: [],
      cCav_Notificacion: [],
      cCav_UltimaCEstado: "",
      nCas_Id: 0,
      nCas_Origen: 0,
      nCas_Origen2: "",
      cCav_FileName: "",
      ImgBinary: "",
    }
  ])
  const [getRow, setRow] = useState({})
  //const series = [150.2, 50.5]
  const abrirCerrarModalTable = (Modal, Row) => {
    if (Modal == 1) {
      FuncionBuscarEventosParticipantes(Row.nEve_Id)
      setRow(Row)
    }
    else if (Modal == 3) {
      setModalCaso(!getModalCaso)
    }
    else {
      FuncionBuscarTareasParticipantes(Row.nTar_Id)
      FlujoCasoListar();
      setRow(Row)
    }
  }
  const options = {
    maintainAspectRatio: false,
    labels: ["Facturable", "No Facturable"],
    colors: ["#00A94F", "#AA1B0E"],
    legend: { show: !1 },
    plotOptions: {
      pie: {
        donut: {
          size: "55%",
        },
      },
    },
  }
  const series2 = [
    {
      name: ["Facturado S/"],
      data: getSeriesGraficoLineaS,
      color: "#AA1B0E",
    },
    {
      name: ["Facturado U$"],
      data: getSeriesGraficoLineaU,
      color: "#00A94F",
    },
  ]
  const options2 = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    title: {
      text: "Facturado",
      align: "left",
      style: {
        fontSize: '14px',
        color: '#263238',
        fontFamily: 'texto-combex',
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic",
      ],
    },
  }
  const [data, setData] = useState([])
  const [dataCaso, setDataCaso] = useState([])
  const [dataCasoInfo, setDataCasoInfo] = useState([])
  const [dataTareasEstadisticas, setDataTareasEstadisticas] = useState([])
  const [dataHorasEstadisticas, setDataHorasEstadisticas] = useState([])
  const [dataEvento, setDataEvento] = useState([])
  const [dataTarea, setDataTarea] = useState([])
  const [dataTareaFlujo, setDataTareaFlujo] = useState([])
  const [HorasLabHL, setHorasHorasLabHL] = useState("")
  const [HorasLabHF, setHorasHorasLabHF] = useState("")
  const [HorasLabHNF, setHorasHorasLabHNF] = useState("")
  const [getUltimoCEstado, setUltimoCEstado] = useState("")
  const [getdataAdicionalTable, setDataAdicionalTable] = useState([])
  const [getdataMovimientos, setDataMovimientos] = useState([])
  const [getdataNotificacion, setDataNotificacion] = useState([])
  const [getdataArchivo, setDataArchivo] = useState([])
  const [getdataCuadernos, setDataCuadernos] = useState([])
  const [getFechaUltMovimiento, setFechaUltMovimiento] = useState("")
  const [getAnioData, setAnioData] = useState([])
  const [getDefaultCliente, setDefaultCliente] = useState({})
  const [getDefaultOrgano, setDefaultOrgano] = useState({})
  const [dataTotal, setDataTotal] = useState([{ Total: 0.00 }])

  const [hiddenBtnEditar, setHiddenBtnEditar] = useState(true)
  const [hiddenBtnEliminar, setHiddenBtnEliminar] = useState(true)


  /** Accesos */
  const FuncionListarPermisos = async () => {
    var postData = {
      nUsu_ID: parseInt(getDecryptedItem("RolID"))
    }
    await instance
      .post("/Usuario/Acceso", postData)
      .then(response => {
        let arrayPermisos = response.data;
        const permiso = arrayPermisos.find(element => element.cUsu_Modulo == "ME01");
        console.log(permiso)
        if (permiso.bUsu_Editar == 1) {
          setHiddenBtnEditar(false);
        }
        else {
          setHiddenBtnEditar(true);
        }
        if (permiso.bUsu_Eliminar == 1) {
          setHiddenBtnEliminar(false);
        }
        else {
          setHiddenBtnEliminar(true);
        }
      })

      .catch(error => {

      })
  }

  /****************************************** */

  const FuncionListarEventos = async (_nCas_Id) => {
    var postData = {
      nCas_Id: _nCas_Id,
      nCli_Id: null,
      nUsu_ID: getDecryptedItem("ID"),
    }


    await instance
      .post("/Eventos/DH", postData)
      .then(response => {
        setDataEvento(response.data)

      })
      .catch(error => {

      })
  }
  const FuncionListarTareas = async (_nCas_Id) => {

    var postData = {
      nCas_Id: _nCas_Id,
      nCli_Id: null,
      nUsu_ID: parseInt(getDecryptedItem("ID")),
    }

    await instance
      .post("/Tareas/DH", postData)
      .then(response => {

        setDataTarea(response.data)
      })
      .catch(error => {

      })
  }
  const HiddenItemsDataMovimientos = async () => {
    if (getJsonCavMovs[0].nCas_Origen2 === "Otros") {
      return true;
    } else {
      return false;
    }
  }
  const FuncionListarAdicional = async (_ID, _CodExt) => {
    var postData = {
      id: _ID,
    }
    await instance
      .post("/Dashboard/Casos/DataCEJ", postData)
      .then(response => {
        if (response.data.length > 0) {
          setJsonCavMovs(response.data);
          setUltimoCEstado(response.data[0].cCav_UltimaCEstado);
          setTipoCaso(response.data[0].nCas_Origen)
          if (response.data[0].nCas_Origen == 0) {
            const Listar3 = response.data.map(
              (row, i) =>
                row.cCav_Cuadernos
            )
            var DatosAdicioneles = response.data[0].cCav_General.find(DatosAdd => DatosAdd.CodigoExterno === _CodExt)
            var Movimientos = response.data[0].cCav_Movimientos.filter(DatosAdd => DatosAdd.CodigoExterno === _CodExt)
            var Notificacion = response.data[0].cCav_Notificacion.filter(DatosAdd => DatosAdd.CodigoExterno === _CodExt)
            var Archivos = response.data[0].cCav_Archivo.filter(DatosAdd => DatosAdd.CodigoExterno === _CodExt)
            var Notas = response.data[0].cCav_Notas

            setDataAdicionalTable(DatosAdicioneles.General)
            setDataMovimientos(Movimientos)
            try {
              setFechaUltMovimiento(FormatoFecha(Movimientos[0].FechaResolucion))
              setParticipantesCaso(response.data[0].cCav_General[0].General[0].Participantes.replace("(Parte)", "").split('|') || [])
            } catch (error) {
            }
            setDataArchivo(Archivos)
            setDataNotificacion(Notificacion)
            setDataCuadernos(response.data[0].cCav_Cuadernos)
            setDataNotas(JSON.parse(Notas))
          } else {
            var Notas = response.data[0].cCav_Notas
            setDataAdicionalTable(response.data[0].cCav_General)
            setDataMovimientos(response.data[0].cCav_Movimientos)
            setDataNotificacion(response.data[0].cCav_Notificacion)
            setDataCuadernos(response.data[0].cCav_Cuadernos)
            setDataNotas(JSON.parse(Notas))
          }
        } else {
          setTipoCaso(2)
        }
        Swal.close();
      })
      .catch(error => {

      })
  }
  const FuncionListarTareasEstadisticas = async () => {
    await instance
      .post("/Dashboard/Tareas")
      .then(response => {
        setDataTareasEstadisticas(response.data)

      })
      .catch(error => {

      })
  }
  const FuncionListarHorasEstadisticas = async () => {
    var postData = {
      id: getDecryptedItem('nCas_Id'),
      M: getFiltroGrafico.M,
      Y: getFiltroGrafico.Y
    }
    await instance
      .post("/Dashboard/Casos/Laborado", postData)
      .then(response => {
        setDataHorasEstadisticas(response.data)

        var HL = response.data[0].nLab_Hora
        var ML = response.data[0].nLab_Minuto
        var HF = response.data[0].tLab_HoraFacturable
        var MF = response.data[0].tLab_MinutoFacturable
        var HnF = response.data[0].tLab_HoraNoFacturable
        var MnF = response.data[0].tLab_MinutoNoFacturable
        if (response.data[0].nLab_Hora.length == 1) {
          HL = "0" + response.data[0].nLab_Hora
        }
        if (response.data[0].nLab_Minuto.length == 1) {
          ML = "0" + response.data[0].nLab_Minuto
        }
        if (response.data[0].tLab_HoraFacturable.length == 1) {
          HF = "0" + response.data[0].tLab_HoraFacturable
        }
        if (response.data[0].tLab_MinutoFacturable.length == 1) {
          MF = "0" + response.data[0].tLab_MinutoFacturable
        }
        if (response.data[0].tLab_HoraNoFacturable.length == 1) {
          HnF = "0" + response.data[0].tLab_HoraNoFacturable
        }
        if (response.data[0].tLab_MinutoNoFacturable.length == 1) {
          MnF = "0" + response.data[0].tLab_MinutoNoFacturable
        }

        setHorasHorasLabHL(HL + ":" + ML)
        setHorasHorasLabHF(HF + ":" + MF)
        setHorasHorasLabHNF(HnF + ":" + MnF)
      })
      .catch(error => {

      })
  }
  const FuncionSeleccionarCuaderno = async (_cCas_Cod_Externo) => {
    Swal.fire({
      html:
        '<img id="imgr1" className="imgr" src="/static/media/logo-dark.7a9779c2.png" style="width:50px;height:auto;-webkit-animation: 3s rotate linear infinite;' +
        'animation: 3s rotate linear infinite;' +
        '-webkit-transform-origin: 50% 50%;' +
        'transform-origin: 50% 50%;" /> ' +
        '<br/><br/> Cargando...',
      showConfirmButton: false,
      allowOutsideClick: false
    });
    var postData = {
      cCas_Cod_Externo: _cCas_Cod_Externo,
    }
    await instance
      .post("/Casos/IDCASO", postData)
      .then(response => {
        FuncionListarAdicional(response.data[0].nCas_Id, _cCas_Cod_Externo)
        FuncionListarEventos(response.data[0].nCas_Id)
        FuncionListarTareas(response.data[0].nCas_Id)
        FuncionListarHorasEstadisticas()
        FuncionListarTareasEstadisticas()
        setEncryptedItem('nCas_Id', response.data[0].nCas_Id);
        FuncionBuscarCasos(response.data[0].nCas_Id)
      })
      .catch(error => {

      })
  }
  const FuncionBuscarCasos = async (_nCas_Id) => {

    var postData = {
      nCas_Id: _nCas_Id,
    }
    await instance
      .post("/Casos/Buscar", postData)
      .then(response => {

        if (response.data[0].nCas_Origen2 == "Otros") {
          setBoolBtnMotivo(false);
        }
        else {
          setBoolBtnMotivo(true);
        }
        setDataCasoInfo(response.data)
        setDataCaso(response.data[0])
        FuncionListarAdicional(_nCas_Id, response.data[0].cCas_Cod_Externo)
        window.scrollTo(0, 0);
      })
      .catch(error => {

      })
  }

  const FuncionListarEquipo = async (_CASOID) => {
    var postData = {
      nCas_Id: _CASOID,
      Tipo: null,
    }
    await instance
      .post("/Casos/Equipo", postData)
      .then(response => {
        setParticipantesALL(response.data)
      })
      .catch(error => {

      })
  }
  const Meses = [
    "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"
  ]
  const FuncionListarMesesLaborados = async () => {
    var postData = {
      nUsu_ID: null,
      nCas_Id: parseInt(getDecryptedItem('nCas_Id')),
      nCli_Id: null,
      M: getFiltroGrafico.M,
      Y: getFiltroGrafico.Y
    }
    await instance
      .post("/Dashboard/MesesLaborados", postData)
      .then(response => {


        let Array = [
          { "Mes": "01", "Cantidad": "0" },
          { "Mes": "02", "Cantidad": "0" },
          { "Mes": "03", "Cantidad": "0" },
          { "Mes": "04", "Cantidad": "0" },
          { "Mes": "05", "Cantidad": "0" },
          { "Mes": "06", "Cantidad": "0" },
          { "Mes": "07", "Cantidad": "0" },
          { "Mes": "08", "Cantidad": "0" },
          { "Mes": "09", "Cantidad": "0" },
          { "Mes": "10", "Cantidad": "0" },
          { "Mes": "11", "Cantidad": "0" },
          { "Mes": "12", "Cantidad": "0" },
        ];
        let Array2 = [
          { "Mes": "01", "Cantidad": "0" },
          { "Mes": "02", "Cantidad": "0" },
          { "Mes": "03", "Cantidad": "0" },
          { "Mes": "04", "Cantidad": "0" },
          { "Mes": "05", "Cantidad": "0" },
          { "Mes": "06", "Cantidad": "0" },
          { "Mes": "07", "Cantidad": "0" },
          { "Mes": "08", "Cantidad": "0" },
          { "Mes": "09", "Cantidad": "0" },
          { "Mes": "10", "Cantidad": "0" },
          { "Mes": "11", "Cantidad": "0" },
          { "Mes": "12", "Cantidad": "0" },
        ];
        let ArrayU = []
        let ArrayS = []
        for (var i = 0; i < response.data.length; i++) {
          for (var j = 0; j < Meses.length; j++) {
            if (response.data[i].Mes == Meses[j]) {
              if (response.data[i].Moneda == "Dólares") {
                Array[j].Cantidad = response.data[i].Total;
              } else {
                Array2[j].Cantidad = response.data[i].Total;
              }
            }
          }
        }
        for (var i = 0; i < Array.length; i++) {
          ArrayU.push(Array[i].Cantidad)
        }
        for (var i = 0; i < Array2.length; i++) {
          ArrayS.push(Array2[i].Cantidad)
        }


        setSeriesGraficoLineaU(ArrayU)
        setSeriesGraficoLineaS(ArrayS)
      })
      .catch(error => {

      })
  }
  var ListarHoras = []
  //Listado de la base de datos
  const SerieHoras = dataHorasEstadisticas.map(
    (row, i) =>
      (ListarHoras = [(parseInt(row.tLab_HoraFacturable) + parseFloat(row.tLab_MinutoFacturable / 100)), (parseInt(row.tLab_HoraNoFacturable) + parseFloat(row.tLab_MinutoNoFacturable / 100))])
  )
  const GuardarAuditoriaVer = async () => { // Guardar Lectura del caso
    let postData2 = {
      cAud_nombre: "Casos",
      nAud_IdReg: parseInt(getDecryptedItem('nCas_Id')),
      nUsu_ID: getDecryptedItem("ID"),
      dAud_Fecha: null,
      nAud_Accion: "L",
      cTabCodigo: 'ME02'
    }
    await instance
      .post("/Auditoria/Insertar", postData2)
      .then(response => {
      });
  }
  const handleChangeFiltro = e => {
    setFiltroGrafico({
      ...getFiltroGrafico,
      [e.target.name]: e.target.value
    })
  }
  const SendAnios = () => {
    const anio = ano
    const anios = []
    for (var i = 2021; i <= anio + 3; i++) {
      anios.push({ anio: i })
    }
    setAnioData(anios)
    console.table(anios)
  }
  const BuscarFiltro = () => {
    FuncionListarHorasEstadisticas();
    FuncionListarMesesLaborados();
  }
  const FormatoFecha = (fecha) => {
    if (fecha == undefined || fecha == '') {
      return "";
    }
    else if (fecha == "No Proveido") {
      return "No Proveido";
    }

    else {
      var d = fecha.substring(0, 2);
      var m = fecha.substring(4, 2);
      var y = fecha.substring(4);
      var fch = d + "-" + m + "-" + y;
      return fch;
    }
  }
  const FuncionListarEquipoAbogadoResponsable = async (_nCas_Id) => {
    var postData = {
      nCas_Id: _nCas_Id,
      Tipo: "UC01",
    }
    await instance
      .post("/Casos/Equipo", postData)
      .then(response => {
        setParticipantesAR(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarEquipoAbogado = async (_nCas_Id) => {
    var postData = {
      nCas_Id: _nCas_Id,
      Tipo: "UC02"
    }
    await instance
      .post("/Casos/Equipo", postData)
      .then(response => {

        setParticipantesA(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarEquipoPracticante = async (_nCas_Id) => {
    var postData = {
      nCas_Id: _nCas_Id,
      Tipo: "UC03"
    }
    await instance
      .post("/Casos/Equipo", postData)
      .then(response => {
        setParticipantesP(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarEquipoUsuarioAdd = async (_nCas_Id) => {
    var postData = {
      nCas_Id: _nCas_Id,
      Tipo: "UC04"
    }
    await instance
      .post("/Casos/Equipo", postData)
      .then(response => {
        setParticipantesUAD(response.data)
      })
      .catch(error => {

      })
  }
  const OpenModalCaso = () => { /************************************************************ */
    if (hiddenBtnEditar) {
      Swal.fire({
        title: "Mensaje",
        text: "Acceso denegado, no tiene permisos para editar el caso",
        icon: "error",
        confirmButtonText: "Aceptar",
      })
      return;
    }

    Swal.fire({
      html:
        '<img id="imgr1" className="imgr" src="/static/media/logo-dark.7a9779c2.png" style="width:50px;height:auto;-webkit-animation: 3s rotate linear infinite;' +
        'animation: 3s rotate linear infinite;' +
        '-webkit-transform-origin: 50% 50%;' +
        'transform-origin: 50% 50%;" /> ' +
        '<br/><br/> Cargando...',
      showConfirmButton: false,
      allowOutsideClick: false
    });
    let nCas_Id = getDecryptedItem("nCas_Id")

    setDefaultCliente({ label: dataCasoInfo[0].cCli_NombreCompleto, value: dataCasoInfo[0].nCli_Id })
    setDefaultOrgano({ label: dataCasoInfo[0].cCas_Tab_Distrito_Jud2, value: dataCasoInfo[0].cCas_Tab_Distrito_Jud })
    FuncionListarEquipoAbogado(nCas_Id)
    FuncionListarEquipoAbogadoResponsable(nCas_Id)
    FuncionListarEquipoPracticante(nCas_Id)
    FuncionListarEquipoUsuarioAdd(nCas_Id)
    setTimeout(() => {
      setModalActualizar(!modalActualizar)
      Swal.close();
    }, 1000);
  }

  const FuncionBuscarTareasParticipantes = async (_nTar_Id) => {
    var postData = {
      nTar_Id: _nTar_Id,
    }
    await instance
      .post("/Tarea/Buscar/Participante", postData)
      .then(response => {
        setParticipantes(response.data);
        setModalTarea(!getModalTarea)
      })
      .catch(error => {

      })
  }
  const FuncionBuscarEventosParticipantes = async (_nEve_Id) => {   // Aqui se carga GetdefaultParticipantes
    var postData = {
      nEve_Id: _nEve_Id,
    }
    await instance
      .post("/Evento/Buscar/Participantes", postData)
      .then(response => {
        setParticipantesEvento(response.data);
        setModalEvento(!getModalEvento)
      })
      .catch(error => {

      })
  }
  const FlujoCasoListar = async () => {
    let _nCas_Id = getDecryptedItem("nCas_Id");
    var postData = {
      nCas_Id: _nCas_Id,
    }
    await instance
      .post("/Flujo/FlujoCasoListar", postData)
      .then(response => {

        let flujoCaso = response.data[0];

        if (flujoCaso) {
          setBoolFlujo(false);
          setDataCasoflujo(response.data[0])
          FunciojTareaListarFase(response.data[0].nfFas_Id, _nCas_Id);
        }

      })
      .catch(error => {

      })
  }
  const verFlujo = async () => {
    setEncryptedItem('nfCaf_Id', getDataCasoflujo.nfCaf_Id);
    location.href = "FlujoSeguimiento";
  }
  const FunciojTareaListarFase = async (_nfFas_Id, _nCas_Id) => {
    if (_nfFas_Id != null || _nCas_Id != null) {
      var postData = {
        nfFas_Id: _nfFas_Id,
        nCas_Id: _nCas_Id,
      }

      await instance
        .post("/flujoTarea/FlujoTareaListarFase", postData)
        .then(response => {

          setDataTareaFlujo(response.data);
        })
        .catch(error => {

        })
    }
  }
  const FnRegistrarComentarios = async (_nCas_Id, _cCav_Notas, image) => {

    const formdata = new FormData()
    if (image !== null) {
      formdata.append("image", image);
    }
    formdata.append("nUsu_ID", parseInt(getDecryptedItem("ID")))
    formdata.append("nCas_Id", Number.parseInt(_nCas_Id))
    formdata.append("cCav_Notas", _cCav_Notas)

    await instance
      .post("/CasMovs/Notas/Update", formdata, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(async response => {
        FuncionListarAdicional(nCas_Id, dataCasoInfo[0].cCas_Cod_Externo)
      });
  }
  const FnRelacionListar = async (_nCas_Id) => {
    var postData = {
      nCas_Id: _nCas_Id,
    }
    await instance
      .post("/relacion/RelacionListar", postData)
      .then(response => {
        Swal.close()
        setRelacionData(response.data)
      })
      .catch(error => {

      })
  }
  const FnRelacionEliminar = async (row) => {
    var postData = {
      nRela_Id: row.nRela_Id,
    }
    await instance
      .post("/relacion/RelacionDelete", postData)
      .then(response => {
        FnRelacionListar(row.nCas_Id)
      })
      .catch(error => {

      })
  }
  const handleChangeNotas = e => {
    const ID = uuid();
    const date = new Date()
    const data = getDataNotas;
    if (e.target.value.length > 0) {
      const boddy = {
        IdMovimiento: e.target.name,
        Comentario: {
          id: ID,
          description: e.target.value,
          idUser: getDecryptedItem("ID"),
          nameUser: getDecryptedItem("NombreC"),
          date: moment(date).format('MM/DD/YYYY'),
          type: 1
        },
      }
      data.push(boddy)
    }
    FnRegistrarComentarios(getDecryptedItem("nCas_Id"), JSON.stringify(data))
    setDataNotas([...data])
  }
  const FnEliminarNota = async (row) => {
    const data = getDataNotas;
    const dataF = data.filter((item) => item.Comentario.id !== row.Comentario.id);
    FnRegistrarComentarios(getDecryptedItem("nCas_Id"), JSON.stringify(dataF))
    setDataNotas([...dataF])
  }
  const FuncionEliminarMovimiento = async (row) => {
    const movimiento = getdataMovimientos.find(objeto => objeto.Moviento === row.Moviento);
    const newArray = [...getdataMovimientos];
    const index = newArray.indexOf(movimiento);
    if (index !== -1) {
      newArray.splice(index, 1);
      setDataMovimientos(newArray);




      let _json =
      {
        Cav_Id: getJsonCavMovs[0].Cav_Id,
        nCas_Id: getJsonCavMovs[0].nCas_Id,
        cCav_Cuadernos: getJsonCavMovs[0].cCav_Cuadernos,
        cCav_General: getJsonCavMovs[0].cCav_General,
        cCav_Movimientos: newArray,
        cCav_Notificacion: getJsonCavMovs[0].cCav_Notificacion,
        cCav_Archivo: getJsonCavMovs[0].cCav_Archivo,
      }


      var _arrayMovimientos = JSON.parse(JSON.stringify(_json).replace(/\//g, '').toString());


      await instance
        .post("/Scraping/Insertar", _arrayMovimientos)
        .then(response => {

          Swal.fire({
            title: "Mensaje",
            text: "Eliminado exitosamente",
            icon: "success",
            confirmButtonText: "Aceptar",
          })
        })
        .catch(error => {

        })



    }

  }
  function getFirstWord(str) {
    const words = str.split(' ');
    return {
      first: words[0],
      rest: words.slice(1).join('  ')
    };
  }
  const validateFileType = async (e, rowIdx, obj) => {
    Swal.fire({
      html:
        '<img id="imgr1" className="imgr" src="/static/media/logo-dark.7a9779c2.png" style="width:50px;height:auto;-webkit-animation: 3s rotate linear infinite;' +
        'animation: 3s rotate linear infinite;' +
        '-webkit-transform-origin: 50% 50%;' +
        'transform-origin: 50% 50%;" /> ' +
        '<br/><br/> Cargando...',
      showConfirmButton: false,
      allowOutsideClick: false
    });

    var fileName = e.target.value;
    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();

    if (
      extFile == "jpg" ||
      extFile == "jpeg" ||
      extFile == "png" ||
      extFile == "pdf" ||
      extFile == "rar" ||
      extFile == "zip"
    ) {
      var Valor = e.target.files[0].name;
      const ID = uuid();
      const date = new Date()
      const data = getDataNotas;

      const boddy = {
        IdMovimiento: parseInt(obj),
        Comentario: {
          id: ID,
          description: Valor,
          idUser: getDecryptedItem("ID"),
          nameUser: getDecryptedItem("NombreC"),
          date: moment(date).format('MM/DD/YYYY'),
          type: 0
        },
      }
      data.push(boddy)

      FnRegistrarComentarios(getDecryptedItem("nCas_Id"), JSON.stringify(data), e.target.files[0])
      setDataNotas([...data])

      let arr = []
      arr.push(getJsonCavMovs[0])
      const JsonCavMovs = JSON.stringify(arr[0]);

      const formdata = new FormData();
      formdata.append("image", e.target.files[0]);
      formdata.append("JsonCavMovs", JsonCavMovs);


      setFileName(Valor);
      setTimeout(() => {
        fileName = "";
        e.target.value = null;
      }, 500);

    } else {


      e.target.value = "";
      setDataMovimientos(prevState => {
        const newData = [...prevState];
        newData[rowIdx] = { ...newData[rowIdx], fileName: "" };
        return newData;
      });

      setFileName("");
      Swal.fire({
        title: "Mensaje",
        text: "El archivo debe ser formado .pdf o .jpg",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }

    setJsonCavMovs(prevState => ({
      ...prevState,
      ImgBinary: e.target.files[0],
    }));


  };
  const fListarCuentaTotal = async (_nCas_Id) => {
    var postData = {
      nCli_Id: null,
      nCas_Id: _nCas_Id,
    }

    await instance
      .post("/Liquidacion/SaldoCajaGasto", postData)
      .then(response => {

        console.log(response);

        if (response.data[0].Total == null) {
          let array = [
            { Total: 0.00 }
          ]
          setDataTotal(array)
        }
        else {
          setDataTotal(response.data)
          setFlagTotal(response.data[0].Total.includes('-'))
        }
      })
      .catch(error => {

      })
  }

  const generateUrl = async (row) => {
    var postData = {
      nCas_Id: nCas_Id,
      Mov: row.CodigoMoviento,
      FileName: row.NombreArchivo,
    }
    await instance
      .post("/GenerateUrl", postData)
      .then(response => {

        console.log(response);
        const pdfUrl = response.data.data
        console.log(pdfUrl);


        var win = window.open(pdfUrl, '_blank');
        win.focus();
      })
      .catch(error => {

      })
  }
  const DownloadFile = async (row) => {
    var postData = {
      FileName: row.Comentario.filename,
    }
    await instance
      .post("/Download/Notas/File", postData)
      .then(response => {
        const Url = response.data.data
        var win = window.open(Url, '_blank');
        win.focus();
      })
      .catch(error => {

      })
  }
  const GenerarHtml = async () => {
    setLoading(!Loading)
    var postData = {
      CodigoExterno: dataCasoInfo[0].cCas_Cod_Externo,
    }
    await instance
      .post("/GenerateHtml", postData)
      .then(response => {
        const res = response.data.data
        console.log(res)
        console.log(encodeURIComponent(res))
        var win = window.open(res, '_blank');
        win.focus();
        setLoading(true)
      })
      .catch(error => {
        setLoading(true)
      })
  }
  useEffect(async () => {
    await FuncionGetToken();
    SendAnios()

  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Casos | GpsLegal</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="GpsLegal" breadcrumbItem="Dashboard del Caso" Condicion="1"
            ReloadTareas={() => { FuncionListarTareas(nCas_Id) }}
            ReloadEventos={() => { FuncionListarEventos(nCas_Id) }}
            ReloadHL={() => { FuncionListarHorasEstadisticas() }}
            ReloadIng={() => { FuncionListarMesesLaborados() }}
            ReloadEgreso={() => { fListarCuentaTotal(nCas_Id) }}
            ReloadFlujo={() => { FlujoCasoListar() }}
          />
          <Row className="mb-3">
            <Col xl="6">
            </Col>
            <Col xl="2" >
              <select
                className="form-select inputDown"
                name="M"
                onChange={handleChangeFiltro}
                value={getFiltroGrafico && getFiltroGrafico.M}
              >
                <option value="1">Enero</option>
                <option value="2">Febrero</option>
                <option value="3">Marzo</option>
                <option value="4">Abril</option>
                <option value="5">Mayo</option>
                <option value="6">Junio</option>
                <option value="7">Julio</option>
                <option value="8">Agosto</option>
                <option value="9">Setiembre</option>
                <option value="10">Octubre</option>
                <option value="11">Noviembre</option>
                <option value="12">Diciembre</option>
              </select>
            </Col>
            <Col xl="2">
              <select
                className="form-select inputDown"
                name="Y"
                onChange={handleChangeFiltro}
                value={getFiltroGrafico && getFiltroGrafico.Y}
              >
                {getAnioData.map((row, i) => (
                  <option key={i} value={row.anio}>
                    {row.anio}
                  </option>
                ))}
              </select>
            </Col>
            <Col xl="1">
              <button
                type="button"
                className="btn btn-danger waves-effect waves-light"
                onClick={() => BuscarFiltro()}
              >
                Filtrar
              </button>
            </Col>
            <Col xl="1">
              <a
                style={{
                  color: "black",
                  fontSize: "20px",
                }}
                onClick={() =>
                  GenerarHtml()
                }
              >
                {" "}
                <i className="icon-Mundo" style={{ cursor: 'pointer' }} hidden={!Loading}></i>
              </a>
              <img
                id="imgr2"
                className="imgr"
                src={logo}
                style={{ width: '40px', height: "auto" }}
                hidden={Loading}
              />
            </Col>
          </Row>
          <Row>
            <Col xl="4">
              <Card className="cardBorder">
                <CardBody>
                  <div>
                    <div className="mb-4">
                      <a className="row" onClick={() => OpenModalCaso()}>
                        <div className="col-md-12"
                          style={{ paddingTop: "5px" }}>
                          <i style={{ fontSize: "40px", float: "left", cursor: 'pointer' }} className="icon-Editar"></i>
                          <label className="" style={{ paddingLeft: "6px" }}>
                            Caso: <b style={{ fontSize: "15px" }}>{dataCasoInfo.map((row, i) => (
                              row.cCas_Titulo
                            ))}</b>
                          </label>
                        </div>
                      </a>
                    </div>
                    <div className="row">
                      <div className="col-md-5 Texto-Limit" style={{ width: "40%" }}><p className=" mb-0">Código Interno</p></div>
                      <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>:</div>
                      <div className="col-md-5">{dataCasoInfo.map((row, i) => (
                        row.cCas_Cod_Interno
                      ))}</div>
                    </div>
                    <div className="row">
                      <div className="col-md-5 Texto-Limit" style={{ width: "40%" }}><p className=" mb-0">Código Externo</p></div>
                      <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>:</div>
                      <div className="col-md-5" style={{ width: "59%" }}>{dataCasoInfo.map((row, i) => (
                        row.cCas_Cod_Externo
                      ))}</div>
                    </div>
                    <div className="row">
                      <div className="col-md-5 Texto-Limit" style={{ width: "40%" }}><p className=" mb-0">Estado interno</p></div>
                      <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>:</div>
                      <div className="col-md-5" style={{ width: "59%" }}>{dataCasoInfo.map((row, i) => (
                        row.bUsu_Estado
                      ))}</div>
                    </div>
                    <div className="row">
                      <div className="col-md-5 Texto-Limit" style={{ width: "40%" }}><p className=" mb-0">Cliente</p></div>
                      <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>:</div>
                      <div className="col-md-5" style={{ width: "59%" }}>{dataCasoInfo.map((row, i) => (
                        row.cCli_NombreCompleto
                      ))}</div>
                    </div>
                  </div>
                </CardBody>
                <CardBody className="border-top">
                  <Row>
                    <div className="col-sm-12">
                      <h5 style={{ fontWeight: "600" }}>Equipo:</h5>
                      {GetParticipantesALL.map((row2, i) => {
                        if (row2.cCas_Tab_TipoEquipo == "UC01" || row2.cCas_Tab_TipoEquipo == "UC02") {
                          return (
                            <div className="col-md-1" style={{ width: "38px", bottom: "12px", marginTop: '0px', paddingTop: "0px", float: 'left' }}>
                              <div>
                                <ReactTooltip place="right" delayShow="50" id={"tooltip" + i + row2.label}>
                                  {row2.label}{" "}{"-"}{" "}
                                  {row2.cCas_Tab_TipoEquipo2}
                                </ReactTooltip>
                              </div>
                              <div data-tip data-for={"tooltip" + i + row2.label}>
                                <LetteredAvatar
                                  name={row2.label}
                                  size={35}
                                  radius={50}
                                  color="#fff"
                                  backgroundColors={arrayWithColors}
                                  style={{ paddingTop: '0px', marginTop: '0px' }}
                                />
                              </div>
                            </div>)
                        }
                      })}
                    </div>
                  </Row>
                </CardBody>
              </Card>
              <Card className="cardBorder">
                <CardBody>
                  {(() => {
                    if (getTipoCaso == 0) {
                      return (
                        <div>
                          <div className="mb-4 me-3">
                            <p className="mb-3" style={{ fontSize: "15px", color: "#000" }}>
                              Datos adicionales de la página oficial
                            </p>
                          </div>
                          <div className="">
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }}>
                                <label className="tituloDatoAdd">
                                  Expediente N°
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-5">
                                {dataCasoInfo.map((row, i) => (
                                  row.cCas_Cod_Externo
                                ))}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }}>
                                <label className="tituloDatoAdd">
                                  Organo Jurisdiccional
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6">
                                {getdataAdicionalTable.map((row, i) => (
                                  row.OrganoJurisdiccional
                                ))}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }}>
                                <label className="tituloDatoAdd">
                                  Distrito Judicial
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6 Texto-Limit">
                                {getdataAdicionalTable.map((row, i) => (
                                  row.DistritoJudicial
                                ))}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }}>
                                <label className="tituloDatoAdd">Juez:</label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6">
                                {getdataAdicionalTable.map((row, i) => (
                                  row.Juez
                                ))}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }}>
                                <label className="tituloDatoAdd">
                                  Especialista Legal
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6">
                                {getdataAdicionalTable.map((row, i) => (
                                  row.EspecialistaLegal
                                ))}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }}>
                                <label className="tituloDatoAdd">
                                  Fecha de Inicio
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6">
                                {getdataAdicionalTable.map((row, i) => (
                                  FormatoFecha(row.FechadeInicio)
                                ))}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }}>
                                <label className="tituloDatoAdd">Proceso</label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6">
                                {getdataAdicionalTable.map((row, i) => (
                                  row.Proceso
                                ))}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }}>
                                <label className="tituloDatoAdd">Observación</label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6">
                                {getdataAdicionalTable.map((row, i) => (
                                  row.Observacion
                                ))}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }}>
                                <label className="tituloDatoAdd">Especialidad</label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6">
                                {getdataAdicionalTable.map((row, i) => (
                                  row.Especialidad
                                ))}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }}>
                                <label className="tituloDatoAdd">Materia</label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6">
                                {getdataAdicionalTable.map((row, i) => (
                                  row.Materia
                                ))}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }}>
                                <label className="tituloDatoAdd">Estado</label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6">
                                {getdataAdicionalTable.map((row, i) => (
                                  row.Estado
                                ))}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }}>
                                <label className="tituloDatoAdd">
                                  Etapa Procesal
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6">
                                {getdataAdicionalTable.map((row, i) => (
                                  row.EtapaProcesal
                                ))}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }}>
                                <label className="tituloDatoAdd">Ubicación del expediente</label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6">
                                {getdataAdicionalTable.map((row, i) => (
                                  row.Ubicación
                                ))}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }}>
                                <label className="tituloDatoAdd">Sumilla</label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6">
                                {getdataAdicionalTable.map((row, i) => (
                                  row.Sumilla
                                ))}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }}>
                                <label className="tituloDatoAdd">Juzgado</label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6">
                                {getdataAdicionalTable.map((row, i) => (
                                  row.OrganoJurisdiccional
                                ))}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }}>
                                <label className="tituloDatoAdd">Materia</label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6">
                                {getdataAdicionalTable.map((row, i) => (
                                  row.Especialidad
                                ))}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }}>
                                <label className="tituloDatoAdd ">SubMateria</label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6">
                                {getdataAdicionalTable.map((row, i) => (
                                  row.Materia
                                ))}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }}>
                                <label className="tituloDatoAdd">
                                  Detalle SubMateria
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6"></div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }}>
                                <label className="tituloDatoAdd">
                                  Ult Movimiento
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6">
                                {getFechaUltMovimiento}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }}>
                                <label className="tituloDatoAdd">
                                  Ult cambio Estado
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6">{getUltimoCEstado}</div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }}>
                                <label className="tituloDatoAdd">Fecha Inicio</label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6">
                                {getdataAdicionalTable.map((row, i) => (
                                  FormatoFecha(row.FechadeInicio)
                                ))}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }}>
                                <label className="tituloDatoAdd">
                                  Fecha término:
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6">
                                {getdataAdicionalTable.map((row, i) => (
                                  row.FechaConclusión
                                ))}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="mb-4 me-3">
                              <p style={{ fontSize: "15px", color: "#000" }}>
                                Datos Económicos
                              </p>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5" style={{ width: "49%" }}>
                                <label className="tituloDatoAdd">
                                  Moneda
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6">  {dataCasoInfo.map((row, i) => (
                                row.cTab_Moneda2
                              ))}</div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5" style={{ width: "49%" }}>
                                <label className="tituloDatoAdd">
                                  Monto
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6">{dataCasoInfo.map((row, i) => (
                                row.nCas_Monto2
                              ))}</div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5" style={{ width: "49%" }}>
                                <label className="tituloDatoAdd">
                                  Tipo
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6">{dataCasoInfo.map((row, i) => (
                                row.cTab_Tipo_Monto2
                              ))}</div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }}>
                                <label className="tituloDatoAdd">Ubicación del file</label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6">
                                {dataCaso.cName_Resolucion}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}></hr>
                            <div className="row">
                              <div className="col-md-5" style={{ width: "100%" }}>
                                <label className="tituloDatoAdd">
                                  Partes :
                                </label>
                              </div>
                              <div className="col-md-12">
                                {GetParticipantesCaso.map((row, i) => {
                                  const { first, rest } = getFirstWord(row);
                                  const isFirst = i === 0;

                                  const [parte1, ...parte2] = row.split(' ');
                                  const nombre = parte1 + ' ' + parte2.join(' ');
                                  return (
                                    <div key={i} className="row mb-1">
                                      <div className={`col-md-4 text-right${isFirst ? ' pr-1' : ''}`} style={{ width: '110px' }}>
                                        {parte1.padEnd(10)}
                                      </div>
                                      :
                                      <div className="col-md-8">{nombre}</div>
                                    </div>

                                  );

                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    } else if (getTipoCaso == 1) {
                      return (
                        <div>
                          <div className="mb-4 me-3">
                            <p className="mb-3" style={{ fontSize: "15px", color: "#000" }}>
                              Datos adicionales de la página oficial
                            </p>
                          </div>
                          <div className="">
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }} >
                                <label className="tituloDatoAdd">
                                  Expediente N°
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6 Texto-Limit">
                                {dataCasoInfo.map((row, i) => (
                                  row.cCas_Cod_Externo
                                ))}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }}>
                                <label className="tituloDatoAdd">
                                  Tipo de expediente
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6">
                                {getdataAdicionalTable.map((row, i) => (
                                  row.TipoExpediente
                                ))}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }}>
                                <label className="tituloDatoAdd">
                                  Fecha de presentación
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6">
                                {getdataAdicionalTable.map((row, i) => (
                                  row.FechaPresentacion
                                ))}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }}>
                                <label className="tituloDatoAdd">
                                  Tipo de presentación
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6">
                                {getdataAdicionalTable.map((row, i) => (
                                  row.Tpresentación
                                ))}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }}>
                                <label className="tituloDatoAdd">
                                  SAC
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6">
                                {getdataAdicionalTable.map((row, i) => (
                                  row.SAC
                                ))}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }}>
                                <label className="tituloDatoAdd">
                                  Lugar de trámite
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6">
                                {getdataAdicionalTable.map((row, i) => (
                                  row.LugarT
                                ))}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }}>
                                <label className="tituloDatoAdd">
                                  Lugar de presentación
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6">
                                {getdataAdicionalTable.map((row, i) => (
                                  row.LugarP
                                ))}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }}>
                                <label className="tituloDatoAdd">
                                  Acumulado a
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6">
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }}>
                                <label className="tituloDatoAdd">
                                  Denunciantes
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6">
                                {getdataCuadernos.map((row, i) => (
                                  <p>{row.denunciante}</p>
                                ))}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }}>
                                <label className="tituloDatoAdd">
                                  Denunciando
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6">
                                {getdataMovimientos.map((row, i) => (
                                  <p>{row.denunciado}</p>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    } else if (getTipoCaso == 2) {
                      return (
                        <div>
                          <div className="mb-4 me-3">
                            <p className="mb-3" style={{ fontSize: "15px", color: "#000" }}>
                              Datos adicionales de la página oficial
                            </p>
                          </div>
                          <div className="">
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }} >
                                <label className="tituloDatoAdd">
                                  Especialidad
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6 Texto-Limit">
                                {dataCaso.cCas_Tab_Materia2}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }} >
                                <label className="tituloDatoAdd">
                                  Materia
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6 Texto-Limit">
                                {dataCaso.cCas_Tab_SubMateria2}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }} >
                                <label className="tituloDatoAdd">
                                  Órgano competente
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6 Texto-Limit">
                                {dataCaso.cCas_Tab_Distrito_Jud2}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }} >
                                <label className="tituloDatoAdd">
                                  Denominación
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6 Texto-Limit">
                                {dataCaso.cCas_Denominacion}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }} >
                                <label className="tituloDatoAdd">
                                  Ubicación del expediente
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6 Texto-Limit">
                                {dataCaso.cUbi_Org}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }} >
                                <label className="tituloDatoAdd">
                                  Fecha de inicio
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6 Texto-Limit">
                                {dataCaso.dCas_Fec_Ini_Interno2}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }} >
                                <label className="tituloDatoAdd">
                                  Estado externo
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6 Texto-Limit">
                                {dataCaso.cCas_Tab_Estado_Externo2}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }} >
                                <label className="tituloDatoAdd">
                                  Estado Interno
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6 Texto-Limit">
                                {dataCaso.cTab_Estado_Interno2}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }} >
                                <label className="tituloDatoAdd">
                                  Ubicación del file
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6 Texto-Limit">
                                {dataCaso.cName_Resolucion}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                          </div>
                        </div>
                      )
                    } else if (getTipoCaso == 3) {
                      return (
                        <div>
                          <div className="mb-4 me-3">
                            <p className="mb-3" style={{ fontSize: "15px", color: "#000" }}>
                              Datos adicionales de la página oficial
                            </p>
                          </div>
                          <div className="">
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }} >
                                <label className="tituloDatoAdd">
                                  Especialidad
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6 Texto-Limit">
                                {dataCaso.cCas_Tab_Materia2}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }} >
                                <label className="tituloDatoAdd">
                                  Materia
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6 Texto-Limit">
                                {dataCaso.cCas_Tab_SubMateria2}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }} >
                                <label className="tituloDatoAdd">
                                  Órgano competente
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6 Texto-Limit">
                                {dataCaso.cCas_Tab_Distrito_Jud2}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }} >
                                <label className="tituloDatoAdd">
                                  Denominación
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6 Texto-Limit">
                                {dataCaso.cCas_Denominacion}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }} >
                                <label className="tituloDatoAdd">
                                  Ubicación del expediente
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6 Texto-Limit">
                                {dataCaso.cUbi_Org}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }} >
                                <label className="tituloDatoAdd">
                                  Fecha de inicio
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6 Texto-Limit">
                                {dataCaso.dCas_Fec_Ini_Interno2}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }} >
                                <label className="tituloDatoAdd">
                                  Estado externo
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6 Texto-Limit">
                                {dataCaso.cCas_Tab_Estado_Externo2}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }} >
                                <label className="tituloDatoAdd">
                                  Estado Interno
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6 Texto-Limit">
                                {dataCaso.cTab_Estado_Interno2}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }} >
                                <label className="tituloDatoAdd">
                                  Ubicación del file
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6 Texto-Limit">
                                {dataCaso.cName_Resolucion}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}>
                            </hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }} >
                                <label className="tituloDatoAdd">
                                  Relator
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6 Texto-Limit">
                                {getdataCuadernos.map((row, i) => (
                                  <p>{row.Relator}</p>
                                ))}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}></hr>
                            <div className="row">
                              <div className="col-md-5 Texto-Limit" style={{ width: "49%" }} >
                                <label className="tituloDatoAdd">
                                  Ubicacion
                                </label>
                              </div>
                              <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                <label className="tituloDatoAdd">
                                  :
                                </label>
                              </div>
                              <div className="col-md-6 Texto-Limit">
                                {getdataCuadernos.map((row, i) => (
                                  <p>{row.Ubicacion}</p>
                                ))}
                              </div>
                            </div>
                            <hr style={{
                              paddingTop: "0px",
                              marginTop: "1px",
                              marginBottom: "5px",
                            }}></hr>

                          </div>
                          <div>
                            <div className="mb-4 me-3" style={{ marginTop: "20px" }}>
                              <p className="mb-3" style={{ fontSize: "15px", color: "#000" }}>
                                Partes Procesales
                              </p>
                            </div>
                            {getdataAdicionalTable.map((row, i) => (
                              <>
                                <div className="row">
                                  <div className="col-md-5 Texto-Limit" style={{ width: "49%" }} >
                                    <label className="tituloDatoAdd">
                                      {row.Tipo.replace(":", '')}
                                    </label>
                                  </div>
                                  <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                    <label className="tituloDatoAdd">
                                      :
                                    </label>
                                  </div>
                                  <div className="col-md-6 Texto-Limit">
                                    {row.Nombre}
                                  </div>
                                </div>
                                <hr style={{
                                  paddingTop: "0px",
                                  marginTop: "1px",
                                  marginBottom: "5px",
                                }}></hr>
                              </>
                            ))}
                          </div>
                        </div>
                      )
                    }
                  })()}
                </CardBody>
              </Card>
              {(() => {
                if (getTipoCaso == 0) {
                  return (
                    <Card className="cardBorder">
                      <CardBody>
                        <h5 className="card-title mb-4">Cuadernos</h5>
                        <div id="divDetalles" className="">
                          {getdataCuadernos.map((row, i) => (
                            <div className="divGLRE0 divResolImpar row" style={{
                              justifyContentt: "center",
                              alignItems: "center",
                            }}>
                              <div style={{ textAlign: "center" }} className="col-md-2">
                                {row.CodigoExterno.split("-")[2]}
                              </div>
                              <div className="col-md-8 container">
                                <div style={{
                                  borderBottom: "1px dashed #CCC",
                                  paddingRight: "5px",
                                  width: "100%;",
                                }}>
                                  <div style={{ display: "inline-table" }}>
                                    <b style={{ fontSize: "12px" }}>{row.CodigoExterno}</b>
                                  </div>
                                  <div style={{ paddingTop: "5px" }}>
                                    <p style={{ fontSize: "10px" }}>{row.Detalle}</p>
                                  </div>
                                </div>
                              </div>
                              <div style={{ textAlignalign: "center" }} className="celdCentro col-md-2">
                                <a className="icon-ver" onClick={() => FuncionSeleccionarCuaderno(row.CodigoExterno)} ></a>
                              </div>
                            </div>
                          ))}
                        </div>
                      </CardBody>
                    </Card>
                  )
                } else if (getTipoCaso == 3) {
                  return (
                    <Card className="cardBorder">
                      <CardBody>
                        <div className="mb-4 me-3" style={{ marginTop: "20px" }}>
                          <p className="mb-3" style={{ fontSize: "15px", color: "#000" }}>
                            Vistas de Causas
                          </p>
                        </div>
                        <div id="divDetalles" className="">
                          {getdataNotificacion.map((row, i) => (
                            <>
                              <div className="row">
                                <div className="col-md-5 Texto-Limit" style={{ width: "49%" }} >
                                  <label className="tituloDatoAdd">
                                    Fecha Programación
                                  </label>
                                </div>
                                <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                  <label className="tituloDatoAdd">
                                    :
                                  </label>
                                </div>
                                <div className="col-md-6 Texto-Limit">
                                  {row.FechaProgramacion}
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-5 Texto-Limit" style={{ width: "49%" }} >
                                  <label className="tituloDatoAdd">
                                    Fecha Vista
                                  </label>
                                </div>
                                <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                  <label className="tituloDatoAdd">
                                    :
                                  </label>
                                </div>
                                <div className="col-md-6 Texto-Limit">
                                  {row.FechaVista}
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-5 Texto-Limit" style={{ width: "49%" }} >
                                  <label className="tituloDatoAdd">
                                    Observación
                                  </label>
                                </div>
                                <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                  <label className="tituloDatoAdd">
                                    :
                                  </label>
                                </div>
                                <div className="col-md-6 Texto-Limit">
                                  {row.Observacion}
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-5 Texto-Limit" style={{ width: "49%" }} >
                                  <label className="tituloDatoAdd">
                                    Parte
                                  </label>
                                </div>
                                <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                  <label className="tituloDatoAdd">
                                    :
                                  </label>
                                </div>
                                <div className="col-md-6 Texto-Limit">
                                  {row.Parte}
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-5 Texto-Limit" style={{ width: "49%" }} >
                                  <label className="tituloDatoAdd">
                                    Sentido Resultado
                                  </label>
                                </div>
                                <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                  <label className="tituloDatoAdd">
                                    :
                                  </label>
                                </div>
                                <div className="col-md-6 Texto-Limit">
                                  {row.SentidoResultado}
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-5 Texto-Limit" style={{ width: "49%" }} >
                                  <label className="tituloDatoAdd">
                                    Tipo Vista
                                  </label>
                                </div>
                                <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                                  <label className="tituloDatoAdd">
                                    :
                                  </label>
                                </div>
                                <div className="col-md-6 Texto-Limit">
                                  {row.TipoVista}
                                </div>
                              </div>
                              <hr style={{
                                paddingTop: "0px",
                                marginTop: "1px",
                                marginBottom: "5px",
                              }}></hr>
                            </>
                          ))}
                        </div>
                      </CardBody>
                    </Card>
                  )
                }
              })()}
            </Col>
            <Col xl="5">
              <Card className="cardBorder">
                <CardBody>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
                    <h5>Movimientos</h5>
                    <button
                      hidden={getBoolBtnMotivo}
                      type="button"
                      className="btn btn-danger waves-effect waves-light"
                      onClick={() => { setModalMovimiento(true) }}
                    >
                      Nuevo
                    </button>
                  </div>

                  <div id="pnlSeguimiento10" className="pnl-seg cpnlSeguimiento cpnlSeguimiento2">
                    <div className="panel panel-default divResolImpar" id="divResol">
                      <div className="panel-body">
                        <div className="row">
                          {(() => {
                            if (getTipoCaso == 0) {
                              return (
                                <div className="col-sm-12">
                                  {getdataMovimientos.map((row, i1) => (
                                    <>
                                      <div className="borderinf" key={i1} style={{
                                        background: "#fff",
                                        padding: "10px",
                                        borderRadius: "5px",
                                      }}>
                                        {" "}
                                        <div className="mb-4">
                                          <div
                                            hidden={hiddenBtnEliminar}
                                            className="float-right" onClick={() => {
                                              Swal.fire({
                                                html: "¿Seguro que desea eliminar el registro?",
                                                icon: "warning",
                                                showCancelButton: true,
                                                confirmButtonColor: "#3085d6",
                                                cancelButtonColor: "#d33",
                                                confirmButtonText: "Si",
                                                cancelButtonText: "No",
                                              }).then(result => {
                                                if (result.isConfirmed) {
                                                  FuncionEliminarMovimiento(row)
                                                }
                                              })

                                            }}
                                            style={{ float: 'right', cursor: 'pointer' }}>

                                            <i style={{ fontSize: "15px" }} className="icon-papelera"></i>

                                          </div>
                                          <i className="icon-Martillo" style={{ fontSize: "25px" }}></i>
                                          &nbsp; <b style={{ color: "#1F60AB" }}>{row.Moviento}-{row.Acto} - {row.Sumilla}</b>
                                          {row.Resolucion !== "" && (
                                            <span>
                                              / Resolución: {row.Resolucion}
                                            </span>
                                          )}

                                          / Tipo de Notificación:
                                          {row.TNotificacion}
                                          {row.Fojas !== "" && (
                                            <span>
                                              / Fojas: {row.Fojas}
                                            </span>
                                          )}
                                          {row.Proveido !== "" && (
                                            <span>
                                              / Proveido: {FormatoFecha(row.Proveido)}
                                            </span>
                                          )}
                                          <b>
                                            / Fecha de Ingreso:  {FormatoFecha(row.FechaResolucion)} / </b>
                                          Descripción de Usuario: {row.ComentarioUsuario}
                                          {getJsonCavMovs[0].nCas_Origen2 !== "Otros" && (
                                            <span>
                                              / Descargado desde el
                                              Poder Judicial
                                            </span>
                                          )}
                                          {getJsonCavMovs[0].nCas_Origen2 !== "Otros" && (
                                            <b style={{ color: "#1F60AB" }}>( {row.cFechaDescarga} )</b>
                                          )}
                                        </div>
                                        <div>
                                          {getdataArchivo.filter(e => e.CodigoMoviento == row.Moviento).map((rowFile, iFile) => {
                                            return (
                                              <div className=" row">
                                                <div className="col-md-2 mb-2" >
                                                  <i style={{ fontSize: "25px", paddingTop: "5px", paddingLeft: "40px", color: "#AA1B0E" }} className="icon-PDF"></i>
                                                </div>
                                                <a className="col-md-10" onClick={() => generateUrl(rowFile)} target="_blank">
                                                  <b className="text-truncate font-size-15 mb-1">
                                                    {rowFile.NombreArchivo}
                                                  </b>
                                                </a>
                                              </div>
                                            )
                                          })}
                                          <div style={{ paddingLeft: "10px", paddingRight: "10px", paddingTop: "20px" }}>
                                            {getDataNotas?.map((rowNotas, iNotas) => {
                                              if (row.Moviento == rowNotas.IdMovimiento) {
                                                return (
                                                  <div className="col-md-12 row" style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                    <div>

                                                      {rowNotas.Comentario.type == 1 ? (
                                                        <ReactTooltip place="right" delayShow="50" id={"tooltip" + rowNotas.Comentario.id}>
                                                          {rowNotas.Comentario.nameUser}
                                                        </ReactTooltip>
                                                      ) : null}
                                                      {rowNotas.Comentario.type == 0 ? (
                                                        <ReactTooltip place="right" delayShow="50" id={"tooltip" + rowNotas.Comentario.id}>
                                                          {rowNotas.Comentario.description}
                                                        </ReactTooltip>
                                                      ) : null}

                                                    </div>
                                                    <div className="col-1" style={{ width: "13%", height: '15px' }} data-tip data-for={"tooltip" + rowNotas.Comentario.id}>
                                                      {rowNotas.Comentario.type == 1 ? (
                                                        <LetteredAvatar
                                                          name={rowNotas.Comentario.nameUser}
                                                          size={35}
                                                          radius={50}
                                                          color="#fff"
                                                          backgroundColors={arrayWithColors}
                                                          style={{ paddingTop: '0px', marginTop: '0px' }}
                                                        />
                                                      ) : null}
                                                      {rowNotas.Comentario.type == 0 ? (
                                                        <FontAwesomeIcon
                                                          style={{ fontSize: '20px', cursor: 'pointer', marginTop: '10px', marginLeft: '8px' }}
                                                          icon={faPaperclip}
                                                        />
                                                      ) : null}

                                                    </div>
                                                    {rowNotas.Comentario.type == 1 ? (
                                                      <div className="col-7 Texto-Limit" style={{ width: '52%' }} >
                                                        <p className="Texto-Limit" style={{ paddingTop: "10px" }}>{rowNotas.Comentario.description}</p>
                                                      </div>
                                                    ) : null}
                                                    {rowNotas.Comentario.type == 0 ? (
                                                      <div className="col-7 Texto-Limit" style={{ width: '52%' }} onClick={() => DownloadFile(rowNotas)}>
                                                        <p className="Texto-Limit" style={{ paddingTop: "10px" }}><a>{rowNotas.Comentario.description}</a></p>
                                                      </div>
                                                    ) : null}

                                                    <div className="col-3 Texto-Limit" style={{ width: '25%' }}>
                                                      <p style={{ paddingTop: "10px" }}>{rowNotas.Comentario.date}</p>
                                                    </div>
                                                    {rowNotas.Comentario.idUser == getDecryptedItem("ID") && (

                                                      <p className="col-1" style={{ width: '7%', paddingTop: "6px", fontSize: "17px", cursor: "pointer" }}
                                                        onClick={() => {
                                                          Swal.fire({
                                                            html: "¿Seguro que desea eliminar el registro?",
                                                            icon: "warning",
                                                            showCancelButton: true,
                                                            confirmButtonColor: "#3085d6",
                                                            cancelButtonColor: "#d33",
                                                            confirmButtonText: "Si",
                                                            cancelButtonText: "No",
                                                          }).then(result => {
                                                            if (result.isConfirmed) {
                                                              FnEliminarNota(rowNotas)
                                                            }
                                                          })
                                                        }}>

                                                        <i style={{ fontSize: "15px" }} className="icon-papelera"></i>
                                                      </p>
                                                    )}
                                                  </div>
                                                )
                                              }
                                            })}
                                          </div>
                                          <div className="row">
                                            <div className="col-md-10">
                                              <input type="text" name={row.Moviento} onKeyPress={event => {
                                                if (event.key === 'Enter') {
                                                  handleChangeNotas(event)
                                                  event.target.value = ""
                                                }
                                              }} className="form-control" placeholder="Escribe un comentario" />
                                              {row.fileName}
                                            </div>
                                            <div hidden={hiddenBtnEditar}
                                              className="col-md-2" style={{ display: "flex", alignItems: 'center' }}>
                                              <input
                                                className="form-control"
                                                type="file"
                                                placeholder="Archivo"
                                                name="cCav_FileName"
                                                id={`cCav_FileName-${i1}`}
                                                style={{ display: 'none' }}
                                                onChange={e => validateFileType(e, i1, row.Moviento)}
                                              />
                                              <FontAwesomeIcon
                                                onClick={() => document.getElementById(`cCav_FileName-${i1}`).click()}
                                                style={{ fontSize: '20px', cursor: 'pointer' }}
                                                icon={faPaperclip}
                                              />
                                            </div>
                                          </div>
                                          <div style={{ height: "15px" }}>
                                            <hr style={{ color: "black", opacity: "1" }} />
                                          </div>
                                        </div>
                                        {(() => {
                                          return (
                                            getdataNotificacion.map((row2, i) => {
                                              if (row2.CodigoMoviento == row.Moviento) {
                                                return (
                                                  <div className="borderinf" key={i} style={{
                                                    background: "#ececec",
                                                    padding: "10px",
                                                    borderRadius: "5px",
                                                  }}>
                                                    {" "}
                                                    <div className="">
                                                      <i className="icon-campana" style={{ fontSize: "25px" }}></i>
                                                      &nbsp; <b style={{ color: "#1F60AB" }}>{row2.titulo}</b> /
                                                      <b style={{ color: "#1F60AB" }}>Anexo(s): {row2.anexos}</b> / Destinatario:
                                                      {row2.destinatario} / Fecha Envio: {FormatoFecha(row2.fechaEnvio)} / Forma Entrega:{FormatoFecha(row2.formaEntrega) || ""}
                                                    </div>
                                                  </div>
                                                )
                                              }
                                            })
                                          )
                                        })()}
                                      </div>
                                      <div style={{ height: "15px" }}>
                                        <hr style={{ color: "black", opacity: "1" }} />
                                      </div>
                                    </>
                                  ))}
                                </div>
                              )
                            } else if (getTipoCaso == 1) {
                              return (
                                <div className="col-sm-12">
                                  {getdataNotificacion.map((row, i1) => (
                                    <>
                                      <div className="borderinf" key={i1} style={{
                                        background: "#fff",
                                        padding: "10px",
                                        borderRadius: "5px",
                                      }}>
                                        {" "}
                                        <div className="mb-4">
                                          <p><i className="icon-campana" style={{ fontSize: "25px" }}></i>&nbsp;&nbsp;&nbsp;Detalle: <b style={{ color: "#1F60AB" }}>{row.detalle}</b> Fecha: <b style={{ color: "#1F60AB" }}>{row.fecha}</b></p>
                                        </div>
                                        <div>
                                          <div style={{ paddingLeft: "10px", paddingRight: "10px", paddingTop: "20px" }}>
                                            {getDataNotas?.map((rowNotas, iNotas) => {
                                              if (row.Moviento == rowNotas.IdMovimiento) {
                                                return (
                                                  <div className="col-md-12 row" style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                    <div>

                                                      {rowNotas.Comentario.type == 1 ? (
                                                        <ReactTooltip place="right" delayShow="50" id={"tooltip" + rowNotas.Comentario.id}>
                                                          {rowNotas.Comentario.nameUser}
                                                        </ReactTooltip>
                                                      ) : null}
                                                      {rowNotas.Comentario.type == 0 ? (
                                                        <ReactTooltip place="right" delayShow="50" id={"tooltip" + rowNotas.Comentario.id}>
                                                          {rowNotas.Comentario.description}
                                                        </ReactTooltip>
                                                      ) : null}

                                                    </div>
                                                    <div className="col-1" style={{ width: "13%", height: '15px' }} data-tip data-for={"tooltip" + rowNotas.Comentario.id}>
                                                      {rowNotas.Comentario.type == 1 ? (
                                                        <LetteredAvatar
                                                          name={rowNotas.Comentario.nameUser}
                                                          size={35}
                                                          radius={50}
                                                          color="#fff"
                                                          backgroundColors={arrayWithColors}
                                                          style={{ paddingTop: '0px', marginTop: '0px' }}
                                                        />
                                                      ) : null}
                                                      {rowNotas.Comentario.type == 0 ? (
                                                        <FontAwesomeIcon
                                                          style={{ fontSize: '20px', cursor: 'pointer', marginTop: '10px', marginLeft: '8px' }}
                                                          icon={faPaperclip}
                                                        />
                                                      ) : null}

                                                    </div>
                                                    {rowNotas.Comentario.type == 1 ? (
                                                      <div className="col-7 Texto-Limit" style={{ width: '52%' }} >
                                                        <p className="Texto-Limit" style={{ paddingTop: "10px" }}>{rowNotas.Comentario.description}</p>
                                                      </div>
                                                    ) : null}
                                                    {rowNotas.Comentario.type == 0 ? (
                                                      <div className="col-7 Texto-Limit" style={{ width: '52%' }} onClick={() => DownloadFile(rowNotas)}>
                                                        <p className="Texto-Limit" style={{ paddingTop: "10px" }}><a>{rowNotas.Comentario.description}</a></p>
                                                      </div>
                                                    ) : null}

                                                    <div className="col-3 Texto-Limit" style={{ width: '25%' }}>
                                                      <p style={{ paddingTop: "10px" }}>{rowNotas.Comentario.date}</p>
                                                    </div>
                                                    {rowNotas.Comentario.idUser == getDecryptedItem("ID") && (

                                                      <p className="col-1" style={{ width: '7%', paddingTop: "6px", fontSize: "17px", cursor: "pointer" }}
                                                        onClick={() => {
                                                          Swal.fire({
                                                            html: "¿Seguro que desea eliminar el registro?",
                                                            icon: "warning",
                                                            showCancelButton: true,
                                                            confirmButtonColor: "#3085d6",
                                                            cancelButtonColor: "#d33",
                                                            confirmButtonText: "Si",
                                                            cancelButtonText: "No",
                                                          }).then(result => {
                                                            if (result.isConfirmed) {
                                                              FnEliminarNota(rowNotas)
                                                            }
                                                          })
                                                        }}>

                                                        <i style={{ fontSize: "15px" }} className="icon-papelera"></i>
                                                      </p>
                                                    )}
                                                  </div>
                                                )
                                              }
                                            })}
                                          </div>
                                          <div className="row">
                                            <div className="col-md-10">
                                              <input type="text" name={row.Moviento} onKeyPress={event => {
                                                if (event.key === 'Enter') {
                                                  handleChangeNotas(event)
                                                  event.target.value = ""
                                                }
                                              }} className="form-control" placeholder="Escribe un comentario" />
                                              {row.fileName}
                                            </div>
                                            <div className="col-md-2" style={{ display: "flex", alignItems: 'center' }}>
                                              <input
                                                className="form-control"
                                                type="file"
                                                placeholder="Archivo"
                                                name="cCav_FileName"
                                                id={`cCav_FileName-${i1}`}
                                                style={{ display: 'none' }}
                                                onChange={e => validateFileType(e, i1, row)}
                                              />
                                              <FontAwesomeIcon
                                                onClick={() => document.getElementById(`cCav_FileName-${i1}`).click()}
                                                style={{ fontSize: '20px', cursor: 'pointer' }}
                                                icon={faPaperclip}
                                              />
                                            </div>
                                          </div>
                                          <div style={{ height: "15px" }}>
                                            <hr style={{ color: "black", opacity: "1" }} />
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ))}
                                </div>
                              )
                            } else if (getTipoCaso == 3) {
                              return (
                                <div className="col-sm-12">
                                  {getdataMovimientos.map((row, i1) => (
                                    <>
                                      <div className="borderinf" key={i1} style={{
                                        background: "#fff",
                                        padding: "10px",
                                        borderRadius: "5px",
                                      }}>
                                        {" "}
                                        <div className="mb-4">
                                          <p><i className="icon-campana" style={{ fontSize: "25px" }}></i>&nbsp;&nbsp;&nbsp;Acto: <b style={{ color: "#1F60AB" }}>{row.Acto}</b> Fojas: <b style={{ color: "#1F60AB" }}>{row.Fojas}</b> Sumilla: <b style={{ color: "#1F60AB" }}>{row.Sumilla}</b></p>
                                          <p>INGRESADO POR : <b >{row.Presentante}</b></p>
                                        </div>
                                        <div>
                                          <div style={{ paddingLeft: "10px", paddingRight: "10px", paddingTop: "20px" }}>
                                            {getDataNotas?.map((rowNotas, iNotas) => {
                                              if (row.IdSeguimientoSeguimiento == rowNotas.IdMovimiento) {
                                                return (
                                                  <div className="col-md-12 row" style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                    <div>
                                                      {rowNotas.Comentario.type == 1 ? (
                                                        <ReactTooltip place="right" delayShow="50" id={"tooltip" + rowNotas.Comentario.id}>
                                                          {rowNotas.Comentario.nameUser}
                                                        </ReactTooltip>
                                                      ) : null}
                                                      {rowNotas.Comentario.type == 0 ? (
                                                        <ReactTooltip place="right" delayShow="50" id={"tooltip" + rowNotas.Comentario.id}>
                                                          {rowNotas.Comentario.description}
                                                        </ReactTooltip>
                                                      ) : null}

                                                    </div>
                                                    <div className="col-1" style={{ width: "13%", height: '15px' }} data-tip data-for={"tooltip" + rowNotas.Comentario.id}>
                                                      {rowNotas.Comentario.type == 1 ? (
                                                        <LetteredAvatar
                                                          name={rowNotas.Comentario.nameUser}
                                                          size={35}
                                                          radius={50}
                                                          color="#fff"
                                                          backgroundColors={arrayWithColors}
                                                          style={{ paddingTop: '0px', marginTop: '0px' }}
                                                        />
                                                      ) : null}
                                                      {rowNotas.Comentario.type == 0 ? (
                                                        <FontAwesomeIcon
                                                          style={{ fontSize: '20px', cursor: 'pointer', marginTop: '10px', marginLeft: '8px' }}
                                                          icon={faPaperclip}
                                                        />
                                                      ) : null}

                                                    </div>
                                                    {rowNotas.Comentario.type == 1 ? (
                                                      <div className="col-7 Texto-Limit" style={{ width: '52%' }} >
                                                        <p className="Texto-Limit" style={{ paddingTop: "10px" }}>{rowNotas.Comentario.description}</p>
                                                      </div>
                                                    ) : null}
                                                    {rowNotas.Comentario.type == 0 ? (
                                                      <div className="col-7 Texto-Limit" style={{ width: '52%' }} onClick={() => DownloadFile(rowNotas)}>
                                                        <p className="Texto-Limit" style={{ paddingTop: "10px" }}><a>{rowNotas.Comentario.description}</a></p>
                                                      </div>
                                                    ) : null}

                                                    <div className="col-3 Texto-Limit" style={{ width: '25%' }}>
                                                      <p style={{ paddingTop: "10px" }}>{rowNotas.Comentario.date}</p>
                                                    </div>
                                                    {rowNotas.Comentario.idUser == getDecryptedItem("ID") && (

                                                      <p className="col-1" style={{ width: '7%', paddingTop: "6px", fontSize: "17px", cursor: "pointer" }}
                                                        onClick={() => {
                                                          Swal.fire({
                                                            html: "¿Seguro que desea eliminar el registro?",
                                                            icon: "warning",
                                                            showCancelButton: true,
                                                            confirmButtonColor: "#3085d6",
                                                            cancelButtonColor: "#d33",
                                                            confirmButtonText: "Si",
                                                            cancelButtonText: "No",
                                                          }).then(result => {
                                                            if (result.isConfirmed) {
                                                              FnEliminarNota(rowNotas)
                                                            }
                                                          })
                                                        }}>

                                                        <i style={{ fontSize: "15px" }} className="icon-papelera"></i>
                                                      </p>
                                                    )}
                                                  </div>
                                                )
                                              }
                                            })}
                                          </div>
                                          <div className="row">
                                            <div className="col-md-10">
                                              <input type="text" name={row.IdSeguimientoSeguimiento} onKeyPress={event => {
                                                if (event.key === 'Enter') {
                                                  handleChangeNotas(event)
                                                  event.target.value = ""
                                                }
                                              }} className="form-control" placeholder="Escribe un comentario" />
                                              {row.fileName}
                                            </div>
                                            <div className="col-md-2" style={{ display: "flex", alignItems: 'center' }}>
                                              <input
                                                className="form-control"
                                                type="file"
                                                placeholder="Archivo"
                                                name="cCav_FileName"
                                                id={`cCav_FileName-${i1}`}
                                                style={{ display: 'none' }}
                                                onChange={e => validateFileType(e, i1, row.IdSeguimientoSeguimiento)}
                                              />
                                              <FontAwesomeIcon
                                                onClick={() => document.getElementById(`cCav_FileName-${i1}`).click()}
                                                style={{ fontSize: '20px', cursor: 'pointer' }}
                                                icon={faPaperclip}
                                              />
                                            </div>
                                          </div>
                                          <div style={{ height: "15px" }}>
                                            <hr style={{ color: "black", opacity: "1" }} />
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ))}
                                </div>
                              )
                            }
                          })()}
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                </CardBody>
                <ComboState>
                  {(() => {
                    if (getModalEvento == true) {
                      return <>
                        <ModalEvento show={getModalEvento}
                          onCloseClick={() => abrirCerrarModalTable(1)}
                          Condicion="1"
                          modo={1}
                          evento={getRow}
                          Row={getRow}
                          Id="1"
                          onCloseModal={() => { setModalEvento(!getModalEvento), FuncionListarEventos(nCas_Id) }}
                          arrayParticipantesEventos={GetParticipantesEvento}
                        />
                      </>
                    }
                  })()}
                  {(() => {
                    if (getModalTarea == true) {
                      return <>
                        <ModalTarea show={getModalTarea}
                          onCloseClick={() => abrirCerrarModalTable(2)}
                          Row={getRow}
                          onCloseModal={() => { setModalTarea(!getModalTarea), FuncionListarTareas(nCas_Id), FlujoCasoListar(); }}
                          arrayParticipantes={GetParticipantes}
                        />
                      </>
                    }
                  })()}
                  {(() => {
                    if (getModalRelacion == true) {
                      return <>
                        <ModalRelacion show={getModalRelacion}
                          onCloseClick={() => setModalRelacion(!getModalRelacion)}
                          ReloadLista={() => { FnRelacionListar(parseInt(getDecryptedItem("nCas_Id"))), setModalRelacion(!getModalRelacion) }}
                          relacion={getRelacion}
                          Contition={Object.values(getRelacion).length > 0 ? 1 : 0}
                        />
                      </>
                    }
                  })()}
                </ComboState>
              </Card>
            </Col>
            <Col xl="3">
              <Card className="cardBorder">
                <CardBody>
                  <h4 className="card-title mb-4">Estadística de horas (hh:mm)</h4>
                  <div>
                    <div id="donut-chart" className="apex-charts">
                      <ReactApexChart options={options} series={ListarHoras} type="donut" height={220} />
                    </div>
                  </div>
                  <div className="text-muted">
                    <div className="row" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                      <div className="col-md-8">
                        <i className="mdi mdi-circle text-success" /> Facturable
                      </div>
                      <div className="col-md-3" style={{ float: "right" }}><a style={{ float: "right" }}>{HorasLabHF}</a></div>
                    </div>
                    <div className="row" style={{ paddingLeft: "0px" }}>
                      <div className="col-md-8">
                        <i className="mdi mdi-circle text-danger" /> No Facturable
                      </div>
                      <div className="col-md-3" style={{ float: "right" }}><a style={{ float: "right" }}>{HorasLabHNF}</a></div>
                    </div>
                    <div className="row" style={{ paddingLeft: "0px" }}>
                      <div className="col-md-8 ">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Horas laboradas
                      </div>
                      <div className="col-md-3" ><a style={{ float: "right" }}>{HorasLabHL}</a></div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card className="cardBorder">
                <CardBody>
                  <h4 className="card-title mb-4">Saldo en caja para gastos</h4>
                  <div className="row" style={{ paddingLeft: "4%" }}>
                    <div className="col-md-6 col-6">
                      <p className="text-truncate font-size-13 mb-1">{" "}
                        &nbsp;&nbsp;&nbsp;

                      </p>
                    </div>
                    <div className="col-md-6" style={{ paddingRight: "10px", textAlign: 'left' }}><a style={{ float: "right", color: getFlagTotal ? '#AA1B0E' : 'black' }}>{dataTotal[0].Total}</a></div>
                  </div>

                </CardBody>
              </Card>
              <Card className="cardBorder">
                <CardBody>
                  <div className="clearfix">
                    <h4 className="card-title mb-4"></h4>
                  </div>
                  <Row>
                    <Col lg="12">
                      <div id="line-chart" className="apex-charts" dir="ltr">
                        <ReactApexChart series={series2} options={options2} type="line" height={250}
                          className="apex-charts" />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="cardBorder">
                <CardBody style={{ paddingLeft: '9px', paddingRight: '4px' }}>
                  <div className="clearfix">
                    <h4 className="card-title mb-4">
                      Próximos Eventos / Tareas
                    </h4>
                  </div>
                  <div className="table-responsive mt-4">

                    <div className="mb-12 Texto-Limit" hidden={boolFlujo}>
                      <b>Flujo:</b> {" "}{getDataCasoflujo.cfFlu_Nombre}
                    </div>
                    <div className="mb-12 Texto-Limit" hidden={boolFlujo}>
                      <b>Fase:</b> {" "} {getDataCasoflujo.cfFas_Nombre}
                    </div>
                    <div className="table-responsive mt-4">
                      <h4 className="card-title mb-4">Tareas de la fase</h4>
                      <Table className="table align-middle mb-0 borderColorTable">
                        <tbody>
                          {dataTareaFlujo.slice(0, getCantidadTareaFase).map((row, i) => (
                            <tr key={i} onClick={() => abrirCerrarModalTable(2, row)} style={{ cursor: "pointer" }}>
                              <td style={{ maxWidth: '20px' }}>
                                {(() => {
                                  if (row.cTar_Tab_Tipo == "TT01") {
                                    return (
                                      <td>
                                        <i className="icon-tareas" style={{ color: "#1F60AB", fontSize: "25px", paddingTop: "5px" }} />
                                      </td>
                                    )
                                  } else if (row.cTar_Tab_Tipo == "TT02") {
                                    return (
                                      <td>
                                        <i className="icon-tareas" style={{ color: "#00A94F", fontSize: "25px", paddingTop: "5px" }} />
                                      </td>
                                    )
                                  } else if (row.cTar_Tab_Tipo == "TT03") {
                                    return (
                                      <td>
                                        <i className="icon-tareas" style={{ color: "#AA1B0E", fontSize: "25px", paddingTop: "5px" }} />
                                      </td>
                                    )
                                  } else if (row.cTar_Tab_Tipo == "TT04") {
                                    return (
                                      <td>
                                        <i className="icon-tareas" style={{ color: "#AA1B0E", fontSize: "25px", paddingTop: "5px" }} />
                                      </td>
                                    )
                                  }
                                })()}
                              </td>

                              <td style={{ maxWidth: '170px' }} className="Texto-Limit">
                                <h5
                                  style={{ color: "#1F60AB", width: '10px' }}
                                  className="font-size-14 mb-1"
                                >
                                  {row.cTar_Titulo}
                                </h5>
                                <p className="text-muted mb-0">
                                  {row.cTar_Tab_Categoria2}
                                </p>
                                <p className="text-muted mb-0">
                                </p>
                              </td>
                              <td className="Texto-Limit" style={{ maxWidth: '65px', textAlign: 'right', paddingRight: '10px' }}>
                                <p className="mb-1 ">
                                  {row.dTar_Fec_Vence2}
                                </p>
                                <p className="mb-1" style={{ textAlign: 'right', paddingRight: '2px' }}>
                                  {row.tTar_Hora_Vence2}
                                </p>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <div className="card-footer bg-transparent border-top">
                        <div className="text-center">
                          <button
                            onClick={() => setCantidadTareaFase(getCantidadTareaFase + 4)}
                            className="btn btn-secondary waves-effect waves-light"
                          >
                            {" "}
                            Cargar más
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive mt-4">
                    <h4 className="card-title mb-4">Otras tareas</h4>
                    <Table className="table align-middle mb-0 borderColorTable">
                      <tbody>
                        {dataTarea.slice(0, getCantidadTarea).map((row, i) => (
                          <tr key={i} onClick={() => abrirCerrarModalTable(2, row)} style={{ cursor: "pointer" }}>
                            <td style={{ maxWidth: '20px' }}>
                              {(() => {
                                if (row.cTar_Tab_Tipo == "TT01") {
                                  return (
                                    <td>
                                      <i className="icon-tareas" style={{ color: "#1F60AB", fontSize: "25px", paddingTop: "5px" }} />
                                    </td>
                                  )
                                } else if (row.cTar_Tab_Tipo == "TT02") {
                                  return (
                                    <td>
                                      <i className="icon-tareas" style={{ color: "#00A94F", fontSize: "25px", paddingTop: "5px" }} />
                                    </td>
                                  )
                                } else if (row.cTar_Tab_Tipo == "TT03") {
                                  return (
                                    <td>
                                      <i className="icon-tareas" style={{ color: "#AA1B0E", fontSize: "25px", paddingTop: "5px" }} />
                                    </td>
                                  )
                                } else if (row.cTar_Tab_Tipo == "TT04") {
                                  return (
                                    <td>
                                      <i className="icon-tareas" style={{ color: "#AA1B0E", fontSize: "25px", paddingTop: "5px" }} />
                                    </td>
                                  )
                                }
                              })()}
                            </td>

                            <td style={{ maxWidth: '170px' }} className="Texto-Limit">
                              <h5
                                style={{ color: "#1F60AB", width: '10px' }}
                                className="font-size-14 mb-1"
                              >
                                {row.cTar_Titulo}
                              </h5>
                              <p className="text-muted mb-0">
                                {row.cTar_Tab_Categoria2}
                              </p>
                              <p className="text-muted mb-0">
                              </p>
                            </td>
                            <td className="Texto-Limit" style={{ maxWidth: '65px', textAlign: 'right', paddingRight: '10px' }}>
                              <p className="mb-1 ">
                                {row.dTar_Fec_Vence2}
                              </p>
                              <p className="mb-1" style={{ textAlign: 'right', paddingRight: '2px' }}>
                                {row.tTar_Hora_Vence2}
                              </p>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div className="card-footer bg-transparent border-top">
                      <div className="text-center">
                        <button
                          onClick={() => setCantidadTarea(getCantidadTarea + 4)}
                          className="btn btn-secondary waves-effect waves-light"
                        >
                          {" "}
                          Cargar más
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive mt-4">
                    <h4 className="card-title mb-4">Eventos</h4>
                    <Table className="table align-middle mb-0 borderColorTable">
                      <tbody>
                        {dataEvento.slice(0, getCantidadEventos).map((row, i) => (
                          <tr key={i} onClick={() => abrirCerrarModalTable(1, row)} style={{ cursor: "pointer" }}>
                            <td style={{ maxWidth: '20px' }}>
                              {(() => {
                                if (row.cEve_Estado == "TT01") {
                                  return (
                                    <td>
                                      <i className="icon-eventos2" style={{ color: "#032D5E", fontSize: "25px", paddingTop: "5px" }} />
                                    </td>
                                  )
                                } else if (row.cEve_Estado == "TT02") {
                                  return (
                                    <td>
                                      <i className="icon-eventos2" style={{ color: "#00A94F", fontSize: "25px", paddingTop: "5px" }} />
                                    </td>
                                  )
                                } else if (row.cEve_Estado == "TT03") {
                                  return (
                                    <td>
                                      <i className="icon-eventos2" style={{ color: "#AA1B0E", fontSize: "25px", paddingTop: "5px" }} />
                                    </td>
                                  )
                                } else if (row.cEve_Estado == "TT04") {
                                  return (
                                    <td>
                                      <i className="icon-eventos2" style={{ color: "#AA1B0E", fontSize: "25px", paddingTop: "5px" }} />
                                    </td>
                                  )
                                }
                              })()}
                            </td>

                            <td style={{ maxWidth: '170px' }} className="Texto-Limit">
                              <h5
                                style={{ color: "#1F60AB", width: '140px' }}
                                className="font-size-14 mb-1"
                              >
                                {row.cEve_Titulo}
                              </h5>
                              <p className="font-size-14 mb-1" style={{ width: '140px' }}>
                                {row.cEve_Descripcion}
                              </p>
                              <p className="text-muted mb-0">
                              </p>
                            </td>
                            <td className="Texto-Limit" style={{ maxWidth: '65px', textAlign: 'right', paddingRight: '10px' }}>
                              <p className="mb-1 ">
                                {row.dEve_FechaIni2}
                              </p>
                              <p className="mb-1" style={{ textAlign: 'right', paddingRight: '2px' }}>
                                {row.tEve_HoraIni2}
                              </p>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div className="card-footer bg-transparent border-top">
                      <div className="text-center">
                        <button
                          onClick={() => setCantidadEventos(getCantidadEventos + 4)}
                          className="btn btn-secondary waves-effect waves-light"
                        >
                          {" "}
                          Cargar más
                        </button>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card className="cardBorder">
                <CardBody hidden={hiddenBtnEditar}>
                  <div className="clearfix">
                    <h4 className="card-title mb-4">
                      Contactos Relacionados
                    </h4>
                    <button
                      onClick={() => { setRelacion({}), setModalRelacion(true) }}
                      className="btn btn-danger waves-effect waves-light"
                    >
                      {" "}
                      Añadir Contacto al caso
                    </button>
                    <div style={{ marginTop: 30 }}>
                      {getRelacionData.map((row, i) =>
                        <div className="row">
                          <div className="col-md-5 Texto-Limit" style={{ width: "52%" }}>
                            <label >
                              {row.cCli_NombreCompleto}
                            </label>
                          </div>
                          <div className="col-md-1" style={{ width: "1px", paddingLeft: "1px", paddingRight: "1px" }}>
                            <label className="tituloDatoAdd">
                              :
                            </label>
                          </div>
                          <div className="col-md-3 Texto-Limit">
                            {row.cCas_Tab_Relacion2}
                          </div>
                          <div className="col-md-2">
                            <div className="row">
                              <div className="col-md-1"><FontAwesomeIcon icon={faEdit} onClick={() => { setRelacion(row), setModalRelacion(true) }} color="green" style={{ fontSize: '15px' }} /></div>
                              <div className="col-md-1"><FontAwesomeIcon icon={faTimes} onClick={() => { FnRelacionEliminar(row) }} color="red" style={{ fontSize: '15px' }} /></div>
                            </div>
                          </div>
                        </div>
                      )}
                      <hr style={{
                        paddingTop: "0px",
                        marginTop: "1px",
                        marginBottom: "5px",
                      }}>
                      </hr>

                    </div>
                  </div>

                </CardBody>
              </Card>
            </Col>
          </Row>
          <CasoState>
            <ComboState>
              {(() => {
                if (modalActualizar == true) {
                  return <>
                    <ModalCasoU
                      show={modalActualizar}
                      Rows={dataCaso}
                      RowAR={GetParticipantesAR}
                      RowA={GetParticipantesA}
                      RowP={GetParticipantesP}
                      RowU={GetParticipantesUAD}
                      RowC={getDefaultCliente}
                      RowO={getDefaultOrgano}
                      onCloseClick={() => setModalActualizar(false)}
                      onCloseModal={() => { setModalActualizar(false), FuncionBuscarCasos(nCas_Id) }} />
                  </>
                }
              })()}
            </ComboState>
            <ComboState>
              {(() => {
                if (modalMovimiento == true) {
                  return <>
                    <ModalMovimiento
                      show={modalMovimiento}
                      CodigoExterno={dataCaso.cCas_Cod_Externo}
                      onCloseClick={() => { FuncionListarAdicional(getDecryptedItem("nCas_Id"), 0), setModalMovimiento(false) }} />
                  </>
                }
              })()}
            </ComboState>
          </CasoState>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default DashboardCasos