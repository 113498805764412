import { GET_CASOS, FLT_CASOS, FLT_CASOS_EXPEDIENTE, GET_ASUNTOS } from "../Type";
export default (state, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_CASOS:
      return {
        ...state,
        casos: payload,
        bkcasos: payload,
      };
    case FLT_CASOS:
      return {
        ...state,
        casos: payload,
      };
    case FLT_CASOS_EXPEDIENTE:
      return {
        ...state,
        casos: payload,
      };
    case GET_ASUNTOS:
      return {
        ...state,
        asunto: payload,
      };
    default:
      return state;
  }
};
