import React, { Component, useState, useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import MetaTags from "react-meta-tags"
import moment from 'moment'
import { MDBDataTable } from "mdbreact"
import {
  CardBody,
  Card,
  Col,
  Container,
  CardTitle,
  Button,
  Row,
  Modal,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap"

import { getDecryptedItem, setEncryptedItem } from '../../util';
import Breadcrumbs from "../../components/Common/Breadcrumb"
import LetteredAvatar from 'react-lettered-avatar';
import instance from '../../config'
import Select from "react-select"
import Swal from "sweetalert2"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCircle,
  faMapMarkedAlt,
} from "@fortawesome/free-solid-svg-icons"
import ModalEvento from "pages/Componentes/ModalEvento"
import ReactTooltip from "react-tooltip";
import { valuesIn } from "lodash"
const jsonwebtoken = require('jsonwebtoken');

const baseUrl = process.env.REACT_APP_API_URL
var arrayEventos = [];
var participantesEvento = [];
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
const Evento = props => {
  const param = useParams();
  let token = param.token;

  const [checked, setStateChecked] = useState(false)

  var FuncionGetToken = async () => {
    if (token) {

      const SECRET_KEY_JWT = "secretkey5353";
      const payload = await jsonwebtoken.verify(token, SECRET_KEY_JWT);
      if (!payload) {
        return res.status(401).send('Unauhtorized Request');
      }
      else {


        let RolId = getDecryptedItem("RolID")
        var postData = {
          nUsu_ID: RolId == 6 || RolId == 1 ? null : getDecryptedItem("ID")
        }
        await instance
          .post("/Evento/Listar", postData)
          .then(response => {
            setData(response.data)


            for (let i = 0; i < response.data.length; i++) {

              if (response.data[i]["nEve_Id"] == evento["nEve_Id"]) {

                Acciones(response.data[i], "VerDetalle");
              }
            }
          })
          .catch(error => {

          })

      }
    }
    else {

    }
  }
  const [modalInsertar, setModalInsertar] = useState(false)
  const [modalActualizar, setModalActualizar] = useState(false)
  const [modalVer, setModalVer] = useState(false)
  const [modalFiltro, setModalFiltro] = useState(false)
  const [modalActualizarFake, setModalActualizarFake] = useState(false)
  const [modalActualizarMasivo, setModalActualizarMasivo] = useState(false)
  const [modalCambiarEstado, setModalCambiarEstado] = useState(false)
  const [modalCambiarTipo, setModalCambiarTipo] = useState(false)
  const [modalDesasociarParticipantes, setModalDesasociarParticipantes] = useState(false)
  const [dataEstadoET, setDataEstadoET] = useState([])
  const [data, setData] = useState([])
  const [dataTipoEvento, setDataTipoEvento] = useState([])
  const [dataCategoriaEvento, setDataCategoriaEvento] = useState([])
  const [dataRecordarEvento, setDataRecordarEvento] = useState([])
  const [dataCliente, setDataCliente] = useState([])
  const [dataCaso, setDataCaso] = useState([])
  const [dataComboUsuario, setDataComboUsuario] = useState([])
  const [dataEstadoEvento, setDataEstadoEvento] = useState([])
  const [dataParticipantes, setDataParticipantes] = useState([])
  const [getDefaultParticipantes, setDefaultParticipantes] = useState()
  const [GetParticipantes, setParticipantes] = useState([])
  const [estadoInterno, SetEstadoInterno] = useState([])
  const [tipoInterno, SetTipoInterno] = useState([])  //tipo de evento 
  const [hiddenBtnEditar, setHiddenBtnEditar] = useState(true)
  const [hiddenBtnEliminar, setHiddenBtnEliminar] = useState(true)

  const [modo, setModo] = useState(0)

  const [getEvento, setEvento] = useState({
    nEve_Id: "",
    nCas_Id: "",
    cEve_Tab_Tipo: "",
    cEve_Tab_Categoria: "",
    cEve_Titulo: "",
    cEve_Ubicacion: "",
    cEve_Descripcion: "",
    dEve_FechaIni: ano + "-" + mes + "-" + dia,
    dEve_FechaFin: ano + "-" + mes + "-" + dia,
    bEve_TodoDía: "",
    tEve_HoraIni: "",
    tEve_HoraFin: "",
    tEve_HoraIni2: data.tEve_HoraIni2,
    tEve_HoraFin2: data.tEve_HoraFin2,
    cEve_tab_Recordar: "",
    bEve_Estado: 1,
    cCas_Cod_Externo: "",
    cCas_Cod_Interno: "",
    nCli_Id: "",
    cEve_Estado: ""
  })

  const [chekedValor1, setchekedValor1] = useState(false)
  const [chekedValor2, setchekedValor2] = useState(false)
  const [chekedValor3, setchekedValor3] = useState(false)
  const [chekedValor4, setchekedValor4] = useState(false)
  const [chekedValor5, setchekedValor5] = useState(false)
  const [Valor1, setValor1] = useState(true)
  const [Valor2, setValor2] = useState(true)
  const [Valor3, setValor3] = useState(true)
  const [Valor4, setValor4] = useState(true)
  const [Valor5, setValor5] = useState(true)
  const [hiddenCliente, setHiddenCliente] = useState(false)
  const [validData, setValidData] = useState(false)

  const [blockBtnUPD, setBlockBtnUPD] = useState(false)

  const [getFiltro, setFiltro] = useState({
    nCas_Id: null,
    cEve_Tab_Tipo: null,
    cEve_Tab_Categoria: null,
    FechaInicial: ano + "-" + "01" + "-" + "01",
    FechaFin: ano + "-" + mes + "-" + dia,
    nCli_Id: null,
    nUsu_ID: null
  })
  const [getErrores, setErrores] = useState({});
  const Validacion = (form) => {
    let errors = {};
    let regexLetras = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    let regexCorreo = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    let regexNumero = /^[0-9]+$/;
    if (form.nCli_Id == 0) {
      errors.nCli_Id = "(*)"
    }
    if (form.nCas_Id == 0) {
      errors.nCas_Id = "(*)"
    }
    if (form.cEve_Tab_Categoria == 0) {
      errors.cEve_Tab_Categoria = "(*)"
    }
    if (!form.cEve_Titulo.trim()) {
      errors.cEve_Titulo = "(*)"
    }
    if (form.cEve_tab_Recordar == 0) {
      errors.cEve_tab_Recordar = "(*)"
    }
    if (form.bEve_TodoDía == 3) {
      errors.bEve_TodoDía = "(*)"
    }
    if (form.cEve_Tab_Tipo == 0) {
      errors.cEve_Tab_Tipo = "(*)"
    }
    if (form.cEve_Estado == 0) {
      errors.cEve_Estado = "(*)"
    }
    return errors;
  }

  const handleBlur = e => {

    handleChange(e)
    // Obtener el nombre del campo y el valor actualizado
    const { name, value } = e.target;


    if (name == "tEve_HoraIni" || name == "tEve_HoraFin") {
      if (value.length == 0) {
        const now = new Date(); // Obtener la fecha y hora actual
        let hours = now.getHours(); // Obtener solo la hora actual

        if (hours < 10) {
          hours = "0" + hours
        }
        const newValue = hours + ":00";

        setEvento((prevState) => ({
          ...prevState,
          [name]: newValue,
        }));
      }
    }
    setErrores(Validacion(getEvento))

  }

  const FuncionListarComboUsuarioAll = async () => {
    await instance
      .get("/Usuario/ListarUsuarioAll")
      .then(response => {
        console.log(response.data)
        setDataComboUsuario(response.data)

      })
      .catch(error => {
        console.log(error)
      })
  }

  const arrayWithColors = ['#ff7f50', '#87cefa', '#da70d6', '#32cd32', '#6495ed',
    '#ff69b4', '#ba55d3', '#cd5c5c', '#ffa500', '#40e0d0'];

  const handleChangeFiltro = e => {
    if (e.target.name == "FechaInicial" || e.target.name == "FechaFin") {
      setFiltro({
        ...getFiltro,
        [e.target.name]: e.target.value
      })
    }
    else {
      if (chekedValor1 == true || chekedValor2 == true || chekedValor3 == true || chekedValor4 == true || chekedValor5 == true) {


        setFiltro({
          ...getFiltro,
          [e.target.name]: e.target.value
        })

        if (e.target.name == "nCli_Id") {
          FuncionListarCasos(e.target.value)
        }
      } else {


        setFiltro({
          ...getFiltro,
          [e.target.name]: null
        })

      }
      if (e.target.name == "cEstado") {
        if (e.target.value == "Todos") {
          FuncionListarEventos()
        } else {

          FuncionFiltroEveLista(document.getElementById("cEstado").value)
        }
      }
    }


  }
  const handleChangeValor1 = e => {
    setchekedValor1(!chekedValor1)
    if (chekedValor1 == true) {
      setFiltro({
        ...getFiltro,
        nCli_Id: null
      })
      setValor1(true)
    } else {
      var Valor = getFiltro.nCli_Id

      setFiltro({
        ...getFiltro,
        nCli_Id: Valor
      })
      setValor1(false)
    }
  }
  const handleChangeValor2 = e => {
    setchekedValor2(!chekedValor2)
    if (chekedValor2 == true) {
      setFiltro({
        ...getFiltro,
        nCas_Id: null
      })
      setValor2(true)
    } else {
      var Valor = getFiltro.nCas_Id

      setFiltro({
        ...getFiltro,
        nCas_Id: Valor
      })
      setValor2(false)
    }
  }
  const handleChangeValor3 = e => {
    setchekedValor3(!chekedValor3)
    if (chekedValor3 == true) {
      setFiltro({
        ...getFiltro,
        cEve_Tab_Tipo: null
      })
      setValor3(true)
    } else {
      var Valor = getFiltro.cEve_Tab_Tipo

      setFiltro({
        ...getFiltro,
        cEve_Tab_Tipo: Valor
      })
      setValor3(false)
    }
  }
  const handleChangeValor4 = e => {
    setchekedValor4(!chekedValor4)
    if (chekedValor4 == true) {
      setFiltro({
        ...getFiltro,
        cEve_Tab_Categoria: null
      })
      setValor4(true)
    } else {
      var Valor = getFiltro.cEve_Tab_Categoria

      setFiltro({
        ...getFiltro,
        cEve_Tab_Categoria: Valor
      })
      setValor4(false)
    }
  }
  const handleChangeValor5 = e => {
    setchekedValor5(!chekedValor5)
    if (chekedValor5 == true) {
      setFiltro({
        ...getFiltro,
        nUsu_ID: null
      })
      setValor5(true)
    } else {
      var Valor = getFiltro.nUsu_ID

      setFiltro({
        ...getFiltro,
        nUsu_ID: Valor
      })
      setValor5(false)
    }
  }
  const handleChange = e => {
    const { name, value } = e.target
    setEvento(prevState => ({
      ...prevState,
      [name]: value,
    }))
    if (name == "dEve_FechaIni") {
      setEvento(prevState => ({
        ...prevState,
        dEve_FechaFin: value,
      }))
    }

  }

  const abrirCerrarModal = Modal => {
    if (Modal == 1) {
      if (!modalInsertar == false) {
        Swal.fire({
          html: "¿Seguro que desea salir sin guardar los datos?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        }).then(result => {
          if (result.isConfirmed) {
            setModalInsertar(!modalInsertar)
          }
        })
      } else {
        setModo(0)
        setModalInsertar(!modalInsertar)

      }
    } else if (Modal == 2) {
      setModo(1)
      setModalInsertar(!modalInsertar)

      console.log("se abrio actualizar");
    } else if (Modal == 3) {
      //setModalVer(!modalVer) /////////// AQUI SE LLAMA AL MODAL VER EVENTO !
      setModo(2)
      setModalInsertar(!modalInsertar)
    }
    else if (Modal == 4) {
      setModalFiltro(!modalFiltro)
    }
    else if (Modal == 5) {
      setModalActualizarMasivo(!modalActualizarMasivo)
      setParticipantes([]);
    }
    else if (Modal == 6) {
      setModalCambiarEstado(!modalCambiarEstado)
    }
    else if (Modal == 7) {
      setModalCambiarTipo(!modalCambiarTipo)
    }
    else if (Modal == 8) {
      setModalDesasociarParticipantes(!modalDesasociarParticipantes)
      setParticipantes([]);
      FuncionListarComboUsuarioAll();
    }
  }
  const ReloadModalListar = async () => {
    setModalInsertar(!modalInsertar)
    FuncionListarEventos()
    FuncionListarEventosParticipantes()
  }
  const FuncionListarEstadoET = async () => {
    var postData = {
      Prefijo: "TT",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataEstadoET(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarEventos = async () => {
    let RolId = getDecryptedItem("RolID")
    var postData = {
      nUsu_ID: RolId == 6 || RolId == 1 ? null : getDecryptedItem("ID")
    }
    await instance
      .post("/Evento/Listar", postData)
      .then(response => {
        console.log("Listando...");
        setData(response.data)

      })
      .catch(error => {

      })
  }
  const FuncionListarEventosParticipantes = async () => {
    await instance
      .post("/Eventos/Participante")
      .then(response => {
        console.log("Listando EventosParticipantes...");
        setDataParticipantes(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionBuscarEventosParticipantes = async (_nEve_Id) => {   // Aqui se carga GetdefaultParticipantes
    var postData = {
      nEve_Id: _nEve_Id,
    }
    await instance
      .post("/Evento/Buscar/Participantes", postData)
      .then(response => {

        setParticipantes(response.data);
      })
      .catch(error => {

      })
  }
  function sortJSON(data, key, orden) {
    return data.sort(function (a, b) {
      var x = a[key],
        y = b[key];
      if (orden === 'asc') {
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
      }
      if (orden === 'desc') {
        return ((x > y) ? -1 : ((x < y) ? 1 : 0));
      }
    });
  }
  const FuncionValidarFormulario = async () => {
    if (Object.keys(getErrores).length === 0) {
      FuncionActualizarEvento()
    } else {
      setValidData(true);
      Swal.fire({
        title: "Mensaje",
        text: "Complete los campos requeridos",
        icon: "error",
        confirmButtonText: "Aceptar",
      })
    }
  }

  function obtenerFechaRecordatorio(fechaInicio, diasAntes) {
    const fechaActual = new Date();
    const fechaRecordatorio = new Date(fechaInicio);



    // Restamos los días de antelación a la fecha de inicio de la tarea
    fechaRecordatorio.setDate(fechaRecordatorio.getDate() - diasAntes);

    // Si la fecha del recordatorio es menor o igual a la fecha actual, retornamos null
    if (fechaRecordatorio <= fechaActual) {
      return null;
    }

    return fechaRecordatorio;
  }
  const FuncionActualizarEvento = async () => {

    var postData = {
      nEve_Id: getEvento.nEve_Id,
      nCas_Id: Number.parseInt(getEvento.nCas_Id),
      cEve_Tab_Tipo: getEvento.cEve_Tab_Tipo,
      cEve_Tab_Categoria: getEvento.cEve_Tab_Categoria,
      cEve_Titulo: getEvento.cEve_Titulo,
      cEve_Ubicacion: getEvento.cEve_Ubicacion,
      cEve_Descripcion: getEvento.cEve_Descripcion,
      dEve_FechaIni: getEvento.dEve_FechaIni,
      dEve_FechaFin: getEvento.dEve_FechaFin,
      bEve_TodoDía: Number.parseInt(getEvento.bEve_TodoDía),
      tEve_HoraIni: getEvento.tEve_HoraIni,
      tEve_HoraFin: getEvento.tEve_HoraFin,
      tEve_HoraIni2: getEvento.tEve_HoraIni2,
      tEve_HoraFin2: getEvento.tEve_HoraFin2,
      cEve_tab_Recordar: getEvento.cEve_tab_Recordar,
      bEve_Estado: Number.parseInt(getEvento.bEve_Estado),
      cEve_Estado: getEvento.cEve_Estado,
    }

    var nomCat = null;
    for (const categoriaEvento of dataCategoriaEvento) {
      if (categoriaEvento.cTabCodigo === getEvento.cEve_Tab_Categoria) {
        nomCat = categoriaEvento.cTabNombre;
        break; // detener la búsqueda una vez que se encuentra el objeto correspondiente
      }
    }

    const { cEve_Tab_Tipo } = dataTipoEvento.find(element => element.cTabCodigo === postData.cEve_Tab_Tipo);



    var now = new Date()
    var fechaSys = moment(now).format('yyyy-MM-DD');
    var fft = moment(getEvento.dEve_FechaIni).format('YYYY-MM-DD'); //Fecha de fin de tarea
    var fecha1 = moment(fechaSys); //Fecha del servidor
    var fecha2 = moment(fft); //Fecha del fin
    const found = dataRecordarEvento.find(element => element.cTabCodigo == getEvento.cEve_tab_Recordar);
    const diasRecordatorio = found.cTabSimbolo;
    var diferencia = fecha2 - fecha1;
    diferencia = diferencia / (1000 * 60 * 60 * 24);

    if (diferencia < 0) {

      Swal.fire({
        title: "Mensaje",
        text: "La fecha de inicio es menor que la fecha del sistema",// El periodo de recordatorio es mayor que la fecha de vencimiento
        icon: "error",
        confirmButtonText: "Aceptar",
      })
    }
    else {
      if (diferencia >= diasRecordatorio) {
        let horaIniFormat = postData.dEve_FechaIni + " " + postData.tEve_HoraIni;
        let fechaI = new Date(horaIniFormat);
        let horaFinFormat = postData.dEve_FechaFin + " " + postData.tEve_HoraFin;
        let fechaFin = new Date(horaFinFormat);
        let horaSistema = new Date();
        if (fechaI >= fechaFin) {
          Swal.fire({
            title: "Mensaje",
            text: "La fecha de inicio no puede ser mayor a la fecha final",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        }
        else if (fechaI <= horaSistema && postData.cEve_Estado == "TT01") {
          Swal.fire({
            title: "Mensaje",
            text: "La fecha de inicio no puede ser menor a la fecha actual",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        }
        else if (fechaFin <= horaSistema && postData.cEve_Estado == "TT01") {
          Swal.fire({
            title: "Mensaje",
            text: "La fecha final no puede ser menor a la fecha actual",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        }
        else {
          setBlockBtnUPD(true);



          // await instance
          //   .post("/Evento/Actualizar", sendEvento)
          //   .then(async response => {
          //     FuncionListarEventos();
          //     abrirCerrarModal(2)
          // let postData2 = {
          //   cAud_nombre: "Eventos",
          //   nAud_IdReg: postData.nEve_Id,
          //   nUsu_ID: getDecryptedItem("ID"),
          //   dAud_Fecha: null,
          //   nAud_Accion: "U",
          //   cTabCodigo: 'ME04'
          // }
          // await instance
          //   .post("/Auditoria/Insertar", postData2)
          //   .then(response => {
          //   });
          // FuncionEliminarParticipantes(getEvento.nEve_Id);
          // setTimeout(() => {
          //   const Participantes = GetParticipantes.map((row, i) => {
          //     FuncionActualizarParticipantes(getEvento.nEve_Id, row.value)
          //   })

          //   Swal.fire({
          //     title: "Mensaje",
          //     text: "Actualizado exitosamente",
          //     icon: "success",
          //     confirmButtonText: "Aceptar",
          //   })
          //   setBlockBtnUPD(false);
          // }, 800);
          // })
          // .catch(error => {
          //   console.log(error)
          // })

          // console.log(dataCategoriaEvento);

          // for (let i = 0; i < dataCategoriaEvento.length; i++) {
          //   console.log("test", dataCategoriaEvento[i].cTabCodigo, " - ", getEvento.cEve_Tab_Categoria);
          //   if (dataCategoriaEvento[i].cTabCodigo == getEvento.cEve_Tab_Categoria) {
          //     nomCat = dataCategoriaEvento[i].cTabNombre;
          //   }
          // }

          // const cEve_Tab_Tipo = dataTipoEvento.find(element => element.cTabCodigo == postData.cEve_Tab_Tipo);

          // console.log(cEve_Tab_Tipo);


          // let objMails = {
          //   categoria: nomCat,
          //   cEve_Tab_Tipo:cEve_Tab_Tipo.cTabNombre,
          //   idCaso: getEvento.nCas_Id,
          //   cliente: getEvento.nCli_Id,
          //   parametro: {
          //     tpoTbl: 'evento',   // tipo de tabla
          //     tpoAct: 'actualizar'  // insertar o actualizar
          //   },
          //   dataEvent: postData,
          //   arrayParticipantes: GetParticipantes
          // }
          // console.log(objMails);

          // await instance
          //   .post("/mail/event/evento", objMails)
          //   .then(res => {

          //     console.log(res)

          //   })
          //   .catch(error => {
          //     console.log(error)
          //   })
        }
      }
      else {
        Swal.fire({
          title: "Mensaje",
          text: "El periodo de recordatorio es mayor que la fecha de inicio",// El periodo de recordatorio es mayor que la fecha de vencimiento
          icon: "error",
          confirmButtonText: "Aceptar",
        })
      }
    }
  }


  const handleChangeParticipantes = value => {
    if (value == null) {
    } else {
      console.log(value)
      console.log(dataComboUsuario);
      setParticipantes(value);
      console.log(UsuariosSelect);
    }
  };
  const FuncionActualizarParticipantes = async (_nEve_Id, _nUsu_ID) => {
    var postData = {
      nEve_Id: Number.parseInt(_nEve_Id),
      nUsu_ID: Number.parseInt(_nUsu_ID),
    }
    await instance
      .post("/Evento/Actualizar/Participante", postData)
      .then(response => {
        FuncionListarEventos()
        FuncionListarEventosParticipantes()
      })
      .catch(error => {

      })
  }
  const FuncionEliminarParticipantes = async (_nEve_Id) => {
    var postData = {
      nEve_Id: Number.parseInt(_nEve_Id),
    }
    await instance
      .post("/Evento/Eliminar/Participante", postData)
      .then(response => {
      })
      .catch(error => {

      })
  }

  const FuncionEliminarEvento = async (_nEve_Id) => {
    var postData = {
      nEve_Id: _nEve_Id,
    }
    await instance
      .post("/Evento/Eliminar", postData)
      .then(async response => {
        let postData2 = {
          cAud_nombre: "Eventos",
          nAud_IdReg: postData.nEve_Id,
          nUsu_ID: getDecryptedItem("ID"),
          dAud_Fecha: null,
          nAud_Accion: "D",
          cTabCodigo: 'ME04'
        }
        await instance
          .post("/Auditoria/Insertar", postData2)
          .then(response => {
          });
        FuncionListarEventos()
        Swal.fire({
          title: "Mensaje",
          text: "Evento eliminado exitosamente!",
          icon: "success",
          confirmButtonText: "Aceptar",
        })
      })
      .catch(error => {

      })
  }
  const FuncionListarComboTipoEvento = async () => {
    var postData = {
      Prefijo: "TE",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataTipoEvento(response.data)

      })
      .catch(error => {

      })
  }
  const FuncionListarComboCategoriaEvento = async () => {
    var postData = {
      Prefijo: "CE",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataCategoriaEvento(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarComboRecordarEvento = async () => {
    var postData = {
      Prefijo: "PE",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataRecordarEvento(response.data)

      })
      .catch(error => {

      })
  }
  const FuncionListarCliente = async () => {
    let RolId = getDecryptedItem("RolID")

    var postData = {
      bCli_Tipo: null,
      nUsu_ID: RolId == 6 || RolId == 1 ? null : getDecryptedItem("ID")
    }
    await instance
      .post("/Cliente/ListarCbo", postData)
      .then(response => {
        setDataCliente(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarCasos = async (_idClient) => {
    var postData = {
      idClient: _idClient,
      nUsu_Id: getDecryptedItem("ID"),
    }
    await instance
      .post("/Casos/ListarCbo", postData)
      .then(response => {
        setDataCaso(response.data)
      })
      .catch(error => {

      })
  }

  const FuncionListarComboUsuario = async (nCas_Id) => {
    var postData = {
      nCas_Id: nCas_Id,
    }

    await instance
      .post("/Casos/FiltroUsuarioCaso", postData)
      .then(response => {

        setDataComboUsuario(response.data)


      })
      .catch(error => {

      })
  }
  const FuncionListarEstadoEvento = async () => {
    var postData = {
      Prefijo: "TT",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataEstadoEvento(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionCorreoEvento = async (_nCas_Id, _nUsu_Id, _cCor_Tab_Tipo, _cCor_Asunto) => {
    var postData = {
      nCas_Id: _nCas_Id,
      nUsu_Id: _nUsu_Id,
      cCor_Tab_Tipo: _cCor_Tab_Tipo,
      cCor_Asunto: _cCor_Asunto,
    }
    await instance
      .post("/Correo/Insertar", postData)
      .then(response => {
      })
      .catch(error => {

      })
  }
  const FuncionFiltroEvento = async (_Estado) => {
    var postData = {
      nCli_Id: getDecryptedItem("CLIID") || getFiltro.nCli_Id,
      nCas_Id: getFiltro.nCas_Id,
      cEve_Tab_Tipo: getFiltro.cEve_Tab_Tipo,
      cEve_Tab_Categoria: getFiltro.cEve_Tab_Categoria,
      FechaInicial: getFiltro.FechaInicial,
      FechaFin: getFiltro.FechaFin,
      nUsu_ID: getFiltro.nUsu_ID,
      Estado: _Estado,
    }

    console.log(postData);

    if (postData.Estado == "Todos") {
      postData.Estado = null;
    }

    await instance
      .post("/Evento/Buscar", postData)
      .then(response => {
        console.log(response.data);
        setData(response.data)
        setModalFiltro(!modalFiltro);
      })
      .catch(error => {

      })
  }
  const FuncionFiltroEveLista = async (_Estado) => {
    var postData = {
      nCli_Id: getFiltro.nCli_Id,
      nCas_Id: getFiltro.nCas_Id,
      cEve_Tab_Tipo: getFiltro.cEve_Tab_Tipo,
      cEve_Tab_Categoria: getFiltro.cEve_Tab_Categoria,
      FechaInicial: getFiltro.FechaInicial,
      FechaFin: getFiltro.FechaFin,
      nUsu_ID: getFiltro.nUsu_ID,
      Estado: _Estado,
    }
    if (postData.Estado == "Todos") {
      postData.Estado = null;
    }

    await instance
      .post("/Evento/Buscar", postData)
      .then(response => {

        setData(response.data)
      })
      .catch(error => {

      })
  }
  const SelectCheckbox = async (evento) => {

    var _idEvento = evento.nEve_Id;
    participantesEvento = [];
    var objetoEvento = {
      evento: evento,
      participantes: []
    }

    participantesEvento = dataParticipantes.filter(row => row.nEve_Id == _idEvento)
    objetoEvento.participantes = participantesEvento;

    if (arrayEventos.length == 0) {
      arrayEventos.push(objetoEvento);
      setStateChecked(true)
    }
    else {
      let trueFalse = false;
      for (var i = 0; i < arrayEventos.length; i++) {
        if (evento.nEve_Id == arrayEventos[i].evento.nEve_Id) {
          arrayEventos.splice(i, 1)
          trueFalse = true;
        }
      }
      if (i == arrayEventos.length) {
        if (trueFalse == true) {
        }
        else {
          arrayEventos.push(objetoEvento);
        }
      }
    }
    if (arrayEventos.length == 0) {
      setStateChecked(false)
    }

  }

  const Acciones = (Evento, caso) => {
    setEvento(Evento)
    if (caso == "Editar") {
      Swal.fire({
        html:
          '<img id="imgr1" className="imgr" src="/static/media/logo-dark.7a9779c2.png" style="width:50px;height:auto;-webkit-animation: 3s rotate linear infinite;' +
          'animation: 3s rotate linear infinite;' +
          '-webkit-transform-origin: 50% 50%;' +
          'transform-origin: 50% 50%;" /> ' +
          '<br/><br/> Cargando...',
        showConfirmButton: false,
        allowOutsideClick: false
      });
      FuncionBuscarEventosParticipantes(Evento.nEve_Id)

      setTimeout(() => {
        let caso = Evento.nCas_Id;


        FuncionListarComboUsuario(caso)
        setModo(1);

        abrirCerrarModal(2)
        Swal.close();
      }, 2000);
    }
    else if (caso == "VerDetalle") {
      Swal.fire({
        html:
          '<img id="imgr1" className="imgr" src="/static/media/logo-dark.7a9779c2.png" style="width:50px;height:auto;-webkit-animation: 3s rotate linear infinite;' +
          'animation: 3s rotate linear infinite;' +
          '-webkit-transform-origin: 50% 50%;' +
          'transform-origin: 50% 50%;" /> ' +
          '<br/><br/> Cargando...',
        showConfirmButton: false,
        allowOutsideClick: false
      });
      FuncionBuscarEventosParticipantes(Evento.nEve_Id)
      setTimeout(() => {
        abrirCerrarModal(3)
        setModo(2);
        Swal.close();
      }, 2000);
    }
    else if (caso == "modalActualizarMasivo") {
      Swal.fire({
        html:
          '<img id="imgr1" className="imgr" src="/static/media/logo-dark.7a9779c2.png" style="width:50px;height:auto;-webkit-animation: 3s rotate linear infinite;' +
          'animation: 3s rotate linear infinite;' +
          '-webkit-transform-origin: 50% 50%;' +
          'transform-origin: 50% 50%;" /> ' +
          '<br/><br/> Cargando...',
        showConfirmButton: false,
        allowOutsideClick: false
      });
      setTimeout(() => {
        setParticipantes([]);
        abrirCerrarModal(5)
        Swal.close();
      }, 2000);
    }
    else {
      abrirCerrarModal(3)
    }
  }
  const SelectDetalle = async (nCas_Id, nCli_Id) => {
    setEncryptedItem('nCas_Id', nCas_Id);
    setEncryptedItem('CLIID', nCli_Id);
  }

  const UsuariosSelect = dataComboUsuario.map((row, i) => {
    let value;
    if (row.hasOwnProperty('nUsu_Id')) {
      value = row.nUsu_Id;
    } else if (row.hasOwnProperty('nUsu_ID')) {
      value = row.nUsu_ID;
    }
    return {
      value: value,
      label: row.cUsu_Nombres,
      color: "#556ee6",
      email: row.cUsu_email,
    };
  });

  const stlSpanPruebaPer = {
    marginLeft: '10px',
  };
  const onChangeHandler = (e) => {

    if (e.target.value == "") {
      FuncionListarEventos()
    }
    else {
      var newArray = data.filter((d) => {
        return d.cEve_Titulo.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
      });

      setData(newArray)
    }
  }

  const FuncionActualizarEventoMasivo = async () => {  // añadir participantes
    Swal.fire({
      html:
        '<img id="imgr1" className="imgr" src="/static/media/logo-dark.7a9779c2.png" style="width:50px;height:auto;-webkit-animation: 3s rotate linear infinite;' +
        'animation: 3s rotate linear infinite;' +
        '-webkit-transform-origin: 50% 50%;' +
        'transform-origin: 50% 50%;" /> ' +
        '<br/><br/> Cargando...',
      showConfirmButton: false,
      allowOutsideClick: false
    });
    var i = 0;
    if (i == arrayEventos.length) {
    }
    for (i = 0; i < arrayEventos.length; i++) {
      var allParticipantes = [];
      if (GetParticipantes.length > 0) {
        for (var a = 0; a < GetParticipantes.length; a++) {
          allParticipantes.push(GetParticipantes[a]);
        }
        if (a == GetParticipantes.length) {
          let arrayEventoParticipantes2 = arrayEventos[i].participantes;
          for (var h = 0; h < arrayEventoParticipantes2.length; h++) {
            let objParticipantes = {
              email: arrayEventoParticipantes2[h].email,
              label: arrayEventoParticipantes2[h].label,
              value: arrayEventoParticipantes2[h].value
            }
            allParticipantes.push(objParticipantes);
          }
          let hash = {};
          allParticipantes = allParticipantes.filter(o => hash[o.value] ? false : hash[o.value] = true);

        }

        let getEvento = arrayEventos[i].evento;
        var postData2 = {
          nEve_Id: getEvento.nEve_Id,
        }

        await instance
          .post("/Evento/Eliminar/Participante", postData2)
          .then(async response => {

          })
          .catch(error => {

          })
        var postData = {
          nEve_Id: getEvento.nEve_Id,
          participantes: allParticipantes,
        }

        await instance
          .post("/Evento/Actualizar/ParticipanteMasivo", postData)
          .then(async response => {

          })
          .catch(error => {

          })
        var nomCat = undefined;
        for (let i = 0; i < dataCategoriaEvento.length; i++) {
          if (dataCategoriaEvento[i].cTabCodigo == getEvento.cEve_Tab_Categoria) {
            nomCat = dataCategoriaEvento[i].cTabNombre;
          }
        }

        /*
        let objMails = {
          categoria: nomCat,
          ttlCaso: getEvento.cCas_Titulo,
          idEvent: postData.nEve_Id,
          cliente: getEvento.nCli_Id,
          parametro: {
            tpoTbl: 'evento',   // tipo de tabla
            tpoAct: 'insertar'  // insertar o actualizar
          },
          dataEvent: getEvento,
          arrayParticipantes: allParticipantes
        }
        await instance
          .post("/mail/event/evento", objMails)
          .then(res => {
            
          })
          .catch(error => {
            
          })
          */
      }
      else {
        abrirCerrarModal(5)
      }
    }

    FuncionListarEventos()
    FuncionListarEventosParticipantes();
    abrirCerrarModal(5)
    setParticipantes([]);
    if (i == arrayEventos.length) {
      Swal.fire({
        title: "Mensaje",
        text: "Actualizado exitosamente",
        icon: "success",
        confirmButtonText: "Aceptar",
      })
    }
  }

  /** Eliminar participantes masivo */
  const methodEliminarParticipantes = async () => {
    console.log(arrayEventos);
    console.log(GetParticipantes);

    let postData = {
      _arrayEventos: arrayEventos,
      _getParticipantes: GetParticipantes,
    }

    console.log(postData);

    await instance
      .post("/Evento/Participantes/EliminarParticipantes", postData)
      .then(response => {

        console.log(response);
        FuncionListarEventos();
        FuncionListarEventosParticipantes();
        Swal.fire({
          title: "Mensaje",
          text: "Se eliminaron los participantes",
          //text: ` ${countstatusText} participantes eliminados de ${arrayEventos.length} registros`,
          icon: "success",
          confirmButtonText: "Aceptar",
        });
        abrirCerrarModal(8);

      });


    // var countstatusText = 0;
    // for (let i = 0; i < arrayEventos.length; i++) {

    //   let evento = arrayEventos[i].evento;
    //   let nEve_Id = evento.nEve_Id;

    //   for (let u = 0; u < GetParticipantes.length; u++) {

    //     var postData = {
    //       nEve_Id: nEve_Id,
    //       nUsu_Id: GetParticipantes[u].value
    //     }
    //     console.log(postData);

    //     await instance
    //       .post("/Evento/Participantes/EliminarParticipantes", postData)
    //       .then(response => {
    //         console.log(response.data.affectedRows);
    //         if (response.data.affectedRows == 1) {
    //           countstatusText++
    //         }
    //       })
    //       .catch(error => {
    //         console.log(error)
    //       });
    //   }
    // }

    FuncionListarEventos();
    FuncionListarEventosParticipantes();
    abrirCerrarModal(8);
  }

  /**  Funcion de interactividad del boton "acciones masivas" */
  function myFunction() {
    //Añade una clase al elemento que tenga el id myDropdown
    document.getElementById("myDropdown").classList.toggle("show");
  }
  window.onclick = function (event) {
    if (!event.target.matches('.drop-button')) {
      var dropdowns = document.getElementsByClassName("dropdown-content");
      var i;
      for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        //Busca dentro de drop-content los elementos con la clase show
        if (openDropdown.classList.contains('show')) {
          //elimina la clase show de los elementos dentro de drop-content
          openDropdown.classList.remove('show');
        }
      }
    }
  }
  /****** Metodo para cambiar el estado del evento */
  const handleChangeEstadointerno = e => {

    SetEstadoInterno(e.target.value);
  }
  const methodCambiarEstado = async () => {

    let postdata = {
      arrayEventos: arrayEventos,
      estadoInterno: estadoInterno
    }

    console.log(postdata);

    await instance
      .post("/Evento/MasivoActualizar", postdata)
      .then(response => {
        console.log(response.data);
        Swal.fire({
          title: "Mensaje",
          text: "Actualizado exitosamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        })
        DeseleccionarTodo();
        SetEstadoInterno("NN00");
        abrirCerrarModal(6);

        FuncionListarEventos();
      })
      .catch(error => {
        console.log(error)
      })

  }
  /****** Metodo para cambiar el tipo del evento */
  const handleChangeTipo = e => {

    SetTipoInterno(e.target.value);
  }
  const methodCambiarTipoEvento = async () => {

    let postdata = {
      arrayEventos: arrayEventos,
      tipoInterno: tipoInterno
    }

    await instance
      .post("/Evento/MasivoActualizar", postdata)
      .then(response => {
        console.log(response.data);
        Swal.fire({
          title: "Mensaje",
          text: "Actualizado exitosamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        })

        SetEstadoInterno("NN00");
        abrirCerrarModal(7);

        FuncionListarEventos();
      })
      .catch(error => {
        console.log(error)
      })
  }
  /***** Metodo para eliminar evento logicamente */
  const methodEliminarEventosLogicamente = async () => {
    var countstatusText = 0;
    Swal.fire({
      html: "¿ Seguro que desea eliminar ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then(result => {
      if (result.isConfirmed) {
        setTimeout(() => {
          Swal.fire({
            html: "¿ Realmente seguro de eliminar ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si",
            cancelButtonText: "No",
          }).then(async result => {
            if (result.isConfirmed) {

              var postData = {
                arrayEventos: arrayEventos
              }

              await instance
                .post("/Evento/MasivoEliminar", postData)
                .then(response => {

                  FuncionListarEventos()
                  DeseleccionarTodo();
                  Swal.fire({
                    title: "Mensaje",
                    text: `Eventos eliminados exitosamente`,
                    icon: "success",
                    confirmButtonText: "Aceptar",
                  });

                })
                .catch(error => {
                  console.log(error)
                })
            }

          })
        }, 200);
      }
      else {
        //DeseleccionarTodo();
      }
    })
  }
  function validarFecha(fecha, recordatorio, tipo) {
    var label = "";
    if (tipo == "TT01") {
      var numRecordatorio = 0;
      var now = moment().format('YYYY-MM-DD');
      if (recordatorio == 'PE01') { numRecordatorio = 1 }
      if (recordatorio == 'PE02') { numRecordatorio = 2 }
      if (recordatorio == 'PE03') { numRecordatorio = 3 }
      if (recordatorio == 'PE04') { numRecordatorio = 7 }
      if (recordatorio == 'PE05') { numRecordatorio = 15 }
      if (recordatorio == 'PE06') { numRecordatorio = 30 }
      //if(recordatorio=='PE06'){numRecordatorio=1}
      var fecha1 = moment(now); //Fecha del servidor
      var fecha2 = moment(fecha); //Fecha del fin
      var diferencia = fecha2 - fecha1;
      diferencia = diferencia / (1000 * 60 * 60 * 24);
      if (diferencia < 0) {
      }
      else {
        if (diferencia <= numRecordatorio) {
          label = " - " + diferencia + " dias restantes"
          return label;
        }
        else {
          return label;
        }
      }
    }
  }
  function validarSucediento(fechaInicio, HoraInicio, fechaFin, HoraFin) {
    let stringInicio = fechaInicio + " " + HoraInicio;
    let stringFin = fechaFin + " " + HoraFin;
    var f_inicio = new Date(stringInicio);
    var f_fin = new Date(stringFin);
    var f_System = new Date();

    if (f_inicio <= f_System && f_fin >= f_System) {
      return true;
    }
    else {
      return false;
    }
  }
  const FuncionListarPermisos = async () => {
    var postData = {
      nUsu_ID: parseInt(getDecryptedItem("RolID"))
    }
    await instance
      .post("/Usuario/Acceso", postData)
      .then(response => {
        let arrayPermisos = response.data;
        const permiso = arrayPermisos.find(element => element.cUsu_Modulo == "ME04");

        if (permiso.bUsu_Editar == 1) {
          setHiddenBtnEditar(false);
        }
        else {
          setHiddenBtnEditar(true);
        }
        if (permiso.bUsu_Eliminar == 1) {
          setHiddenBtnEliminar(false);
        }
        else {
          setHiddenBtnEliminar(true);
        }
      })
      .catch(error => {

      })
  }

  const SeleccionarTodo = async () => {
    var elem = document.getElementById("selecionarMasivo")
    var i
    if (elem.checked) {
      for (i = 0; i < document.f1.elements.length; i++)
        if (document.f1.elements[i].type == "checkbox") {
          document.f1.elements[i].checked = 1
          SelectCheckbox(JSON.parse(document.f1.elements[i].name))
        }
    } else {
      for (i = 0; i < document.f1.elements.length; i++)
        if (document.f1.elements[i].type == "checkbox")
          document.f1.elements[i].checked = 0
      arrayEventos = []
    }
  }

  const DeseleccionarTodo = async () => {
    var i
    for (i = 0; i < document.f1.elements.length; i++)
      if (document.f1.elements[i].type == "checkbox")
        document.f1.elements[i].checked = 0
    arrayEventos = []
  }


  useEffect(async () => {
    if (getDecryptedItem("RolID") == 9) {
      setHiddenCliente(true);
      FuncionListarCasos(null)
    }
    await FuncionGetToken();
    FuncionListarPermisos();
    FuncionListarComboTipoEvento()        //Registrado
    FuncionListarComboCategoriaEvento()   //Registrado
    FuncionListarComboRecordarEvento()    //Registrado
    FuncionListarCliente()

    FuncionListarEstadoEvento()
    FuncionListarEventosParticipantes()
    FuncionListarEventos()
    FuncionListarEstadoET()
    let rol = getDecryptedItem("RolID");
    if (rol !== '9') {
      localStorage.removeItem('CLIID');
      localStorage.removeItem('nCas_Id');
    }
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Eventos | GpsLegal</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="GpsLegal" breadcrumbItem="Eventos" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div>
                    <button
                      type="button"
                      onClick={() => abrirCerrarModal(1)}
                      className="btn btn-danger waves-effect waves-light"
                      data-toggle="modal"
                      data-target="#myModal"
                      hidden={hiddenBtnEditar}
                    >
                      <i style={{ fontSize: "15px" }} className="icon-nuevo"></i>  Nuevo Evento
                    </button>{" "}
                    <button
                      type="button"
                      onClick={() => abrirCerrarModal(4)}
                      className="btn btn-danger waves-effect waves-light"
                      data-toggle="modal"
                      data-target="#myModal"
                    >
                      <i style={{ fontSize: "15px" }} className="icon-filtro"></i>  Filtros
                    </button>{" "}
                    <Link
                      type="button"
                      className="btn btn-danger waves-effect waves-light"
                      to="/calendar"
                    >
                      <i style={{ fontSize: "15px" }} className="icon-calendario"></i>  Calendario
                    </Link>
                    {(() => {
                      if (modalInsertar == true) {
                        return <>
                          <ModalEvento show={modalInsertar}
                            modo={modo}
                            evento={getEvento}

                            onCloseClick={() => abrirCerrarModal(1)}
                            onCloseModal={() => { setModalInsertar(false), ReloadModalListar() }}
                            ReloadLista={() => ReloadModalListar()}
                            Titulo="Nuevo Evento"
                          />
                        </>
                      }
                    })()}
                    <Modal isOpen={modalCambiarTipo}>
                      <div className="modal-header ModalHeaderColor" >
                        <h5
                          style={{ color: "#fff" }}
                          className="modal-title mt-0"
                          id="myModalLabel"
                        >
                          Editar evento
                        </h5>
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(7)}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <label> Eventos seleccionados : {arrayEventos.length}</label>
                        <div className="col-md-12">
                          <div className="col-md-3"></div>
                          <div className="col-md-6" style={{ marginTop: '30px' }}>
                            <select
                              className="form-select"
                              name="cEve_Tab_Tipo"
                              onChange={handleChangeTipo}
                              value={tipoInterno}
                            >
                              <option value="00">Seleccionar...</option>
                              {dataTipoEvento.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Tipo de evento
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(7)}
                          className="btn btn-secondary waves-effect"
                          data-dismiss="modal"
                        >
                          Cerrar
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger waves-effect waves-light"
                          onClick={() => methodCambiarTipoEvento()}
                        >
                          Guardar
                        </button>
                      </div>
                    </Modal>
                    <Modal isOpen={modalCambiarEstado}>
                      <div className="modal-header ModalHeaderColor" >
                        <h5
                          style={{ color: "#fff" }}
                          className="modal-title mt-0"
                          id="myModalLabel"
                        >
                          Editar evento
                        </h5>
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(6)}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <label> Eventos seleccionados : {arrayEventos.length}</label>
                        <div className="col-md-12">
                          <div className="col-md-3"></div>
                          <div className="col-md-6">
                            <select
                              className="form-select"
                              name="cEve_Estado"
                              onChange={handleChangeEstadointerno}
                              value={estadoInterno}>
                              <option value="NN00">Seleccionar...</option>
                              {dataEstadoEvento.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Estado
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(6)}
                          className="btn btn-secondary waves-effect"
                          data-dismiss="modal"
                        >
                          Cerrar
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger waves-effect waves-light"
                          onClick={() => methodCambiarEstado()}
                        >
                          Guardar
                        </button>
                      </div>
                    </Modal>
                    <Modal isOpen={modalActualizarMasivo}>
                      <div className="modal-header ModalHeaderColor">
                        <h5
                          style={{ color: "#fff" }}
                          className="modal-title mt-0"
                          id="myModalLabel"
                        >
                          Editar eventos
                        </h5>
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(5)}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <label> Eventos seleccionados : {arrayEventos.length}</label>
                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <Select
                              isMulti
                              onChange={handleChangeParticipantes}
                              name="colors"
                              options={UsuariosSelect}
                              className="basic-multi-select"
                              placeholder="Ingrese los participantes"
                              value={GetParticipantes}
                            />
                            <label className="col-form-label labelDown">
                              Participantes
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(5)}
                          className="btn btn-secondary waves-effect"
                          data-dismiss="modal"
                        >
                          Cerrar
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger waves-effect waves-light"
                          onClick={() => FuncionActualizarEventoMasivo()}
                        >
                          Guardar
                        </button>
                      </div>
                    </Modal>
                    <Modal isOpen={modalDesasociarParticipantes}>
                      <div className="modal-header ModalHeaderColor">
                        <h5
                          style={{ color: "#fff" }}
                          className="modal-title mt-0"
                          id="myModalLabel"
                        >
                          Desasociar participantes
                        </h5>
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(8)}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <label> Eventos seleccionados : {arrayEventos.length}</label>
                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <Select
                              isMulti
                              isClearable
                              onChange={handleChangeParticipantes}
                              className="basic-multi-select"
                              placeholder="Ingrese los participantes"
                              options={UsuariosSelect}
                              defaultValue={GetParticipantes}
                            />
                            <label className="col-form-label labelDown">
                              Participantes
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(8)}
                          className="btn btn-secondary waves-effect"
                          data-dismiss="modal"
                        >
                          Cerrar
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger waves-effect waves-light"
                          onClick={() => methodEliminarParticipantes()}
                        >
                          Guardar
                        </button>
                      </div>
                    </Modal>
                    <Modal isOpen={modalActualizar}>
                      <div
                        className="modal-header ModalHeaderColor"
                      >
                        <h5
                          style={{ color: "#fff" }}
                          className="modal-title mt-0"
                          id="myModalLabel"
                        >
                          Editar evento
                        </h5>
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(2)}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="mb-3 row">
                          <div className="col-md-6">
                            <select className="form-select inputDown" disabled="true"
                              name="nCli_Id"
                              value={getEvento && getEvento.nCli_Id}
                            >
                              <option value="0">Seleccionar...</option>
                              {dataCliente.map((row, i) => (
                                <option key={i} value={row.nCli_Id}>
                                  {row.cCli_NombreCompleto}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Cliente
                            </label>
                          </div>
                          <div className="col-md-6">
                            <select
                              className="form-select inputDown" disabled="true"
                              name="nCas_Id"
                              onChange={handleChange}
                              value={getEvento && getEvento.nCas_Id}
                            >
                              <option value="0">Seleccionar...</option>
                              {dataCaso.map((row, i) => (
                                <option key={i} value={row.nCas_Id}>
                                  {row.cCas_Titulo}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Caso
                            </label>
                          </div>
                        </div>
                        <div className="mb-1 row">
                          <div className="col-md-5">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-12 col-form-label"
                              style={{ marginLeft: "5px" }}
                            >
                              Cod Interno: {getEvento.cCas_Cod_Interno}
                            </label>
                          </div>
                          <div className="col-md-7" >
                            <label
                              htmlFor="example-text-input"
                              className="col-md-12 col-form-label"
                              style={{ marginLeft: "2px" }}
                            >
                              Cod Externo: {getEvento.cCas_Cod_Externo}
                            </label>
                          </div>
                        </div>

                        <div className="mb-3 row" style={{ marginTop: '15px' }}>
                          <div className="col-md-6">
                            <select
                              className={getErrores.cEve_Tab_Categoria && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                              name="cEve_Tab_Categoria"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={getEvento && getEvento.cEve_Tab_Categoria}
                            >
                              <option value="0">Seleccionar...</option>
                              {dataCategoriaEvento.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">Categoría</label>{getErrores.cEve_Tab_Categoria && <label className="col-form-label labelDown labelRed">{getErrores.cEve_Tab_Categoria}</label>}
                          </div>
                          <div className="col-md-6">
                            <input
                              className={getErrores.cEve_Titulo && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                              placeholder="Ingrese el título"
                              name="cEve_Titulo"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={getEvento && getEvento.cEve_Titulo}
                              maxLength="200"
                            />
                            <label className="col-form-label labelDown">Título</label>{getErrores.cEve_Titulo && <label className="col-form-label labelDown labelRed">{getErrores.cEve_Titulo}</label>}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <textarea
                              className="form-control"
                              placeholder="Ingrese la descripción"
                              name="cEve_Descripcion"
                              value={getEvento && getEvento.cEve_Descripcion}
                              onChange={handleChange}
                              maxLength="200"
                            ></textarea>
                            <label className="col-form-label labelDown">
                              Descripción
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <Select
                              isMulti
                              isClearable
                              onChange={handleChangeParticipantes}
                              className="basic-multi-select"
                              placeholder="Ingrese los participantes"
                              options={UsuariosSelect}
                              defaultValue={GetParticipantes}
                            />
                            <label className="col-form-label labelDown">
                              Participantes
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <input
                              className="form-control"
                              placeholder="Ingrese la ubicación"
                              name="cEve_Ubicacion"
                              value={getEvento && getEvento.cEve_Ubicacion}
                              onChange={handleChange}
                              maxLength="200"
                            />
                            <label className="col-form-label labelDown">
                              Ubicación
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-6">
                            <input
                              className="form-control"
                              type="date"
                              name="dEve_FechaIni"
                              value={getEvento && getEvento.dEve_FechaIni}
                              onChange={handleChange}
                            />
                            <label className="col-form-label labelDown">
                              Fecha de inicio
                            </label>
                          </div>
                          <div className="col-md-6">
                            <input
                              className="form-control"
                              type="time"
                              name="tEve_HoraIni"
                              value={getEvento && getEvento.tEve_HoraIni}
                              id="time-input"
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                            <label className="col-form-label labelDown">
                              Hora de inicio
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-6">
                            <input
                              className="form-control"
                              type="date"
                              name="dEve_FechaFin"
                              value={getEvento && getEvento.dEve_FechaFin}
                              onChange={handleChange}
                            />
                            <label className="col-form-label labelDown">
                              Fecha de fin
                            </label>
                          </div>
                          <div className="col-md-6">
                            <input
                              className="form-control"
                              type="time"
                              name="tEve_HoraFin"
                              value={getEvento && getEvento.tEve_HoraFin}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                            <label className="col-form-label labelDown">
                              Hora de fin
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-6">
                            <select
                              className="form-select"
                              name="cEve_tab_Recordar"
                              value={getEvento && getEvento.cEve_tab_Recordar}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value="0">Seleccionar...</option>
                              {dataRecordarEvento.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">Recordatorio</label>{getErrores.cEve_tab_Recordar && <label className="col-form-label labelDown labelRed">{getErrores.cEve_tab_Recordar}</label>}
                          </div>
                          <div className="col-md-6">
                            <select
                              className="form-select"
                              name="bEve_TodoDía"
                              value={getEvento && getEvento.bEve_TodoDía}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value="00">Seleccionar...</option>
                              <option value="1">Si</option>
                              <option value="0">No</option>
                            </select>
                            <label className="col-form-label labelDown">Todo el día</label>{getErrores.bEve_TodoDía && <label className="col-form-label labelDown labelRed">{getErrores.bEve_TodoDía}</label>}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-6">
                            <select
                              className={getErrores.cEve_Tab_Tipo && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                              name="cEve_Tab_Tipo"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={getEvento && getEvento.cEve_Tab_Tipo}
                            >
                              <option value="00">Seleccionar...</option>
                              {dataTipoEvento.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">Tipo de Evento</label>{getErrores.cEve_Tab_Tipo && <label className="col-form-label labelDown labelRed">{getErrores.cEve_Tab_Tipo}</label>}
                          </div>
                          <div hidden className="col-md-6">
                            <select
                              className="form-select"
                              name="bEve_Estado"
                              value={getEvento && getEvento.bEve_Estado}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value="00">Seleccionar...</option>
                              <option value="1">Activo</option>
                              <option value="0">No activo</option>
                            </select>
                            <label className="col-form-label labelDown">Estado</label>{getErrores.cEve_Estado && <label className="col-form-label labelDown labelRed">{getErrores.cEve_Estado}</label>}
                          </div>
                        </div>
                        <div className="mb-3 row" style={{ marginTop: '20px', marginBottom: '0px' }}>
                          <div className="col-md-12">
                            <label className="col-form-label labelDown labelRed">(*) Campos obligatorios</label>
                            <label className="col-form-label labelDown"></label>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(2)}
                          className="btn btn-secondary waves-effect"
                          data-dismiss="modal"
                        >
                          Cerrar
                        </button>
                        <button
                          disabled={blockBtnUPD}
                          type="button"
                          className="btn btn-danger waves-effect waves-light"
                          onClick={() => FuncionValidarFormulario()}
                        >
                          Guardar
                        </button>
                      </div>
                    </Modal>
                    <Modal isOpen={modalVer}>
                      <div
                        className="modal-header ModalHeaderColor"
                      >
                        <h5
                          style={{ color: "#fff" }}
                          className="modal-title mt-0"
                          id="myModalLabel"
                        >
                          Detalle del evento
                        </h5>
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(3)}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="mb-3 row">
                          <div className="col-md-6">
                            <select className="form-select inputDown" disabled="true"
                              name="nCli_Id"
                              value={getEvento && getEvento.nCli_Id}
                            >
                              <option value="0">Seleccionar...</option>
                              {dataCliente.map((row, i) => (
                                <option key={i} value={row.nCli_Id}>
                                  {row.cCli_NombreCompleto}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Cliente
                            </label>
                          </div>
                          <div className="col-md-6">
                            <select
                              className="form-select inputDown" disabled="true"
                              name="nCas_Id"
                              onChange={handleChange}
                              value={getEvento && getEvento.nCas_Id}
                            >
                              <option value="0">Seleccionar...</option>
                              {dataCaso.map((row, i) => (
                                <option key={i} value={row.nCas_Id}>
                                  {row.cCas_Titulo}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Caso
                            </label>
                          </div>
                        </div>
                        <div className="mb-1 row">
                          <div className="col-md-5">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-12 col-form-label"
                              style={{ marginLeft: "5px" }}
                            >
                              Cod Interno: {getEvento.cCas_Cod_Interno}
                            </label>
                          </div>
                          <div className="col-md-7" style={{ paddingLeft: "0px", marginLeft: "0px" }}>
                            <label
                              htmlFor="example-text-input"
                              className="col-md-12 col-form-label"
                              style={{ paddingLeft: "0px", marginLeft: "0px" }}
                            >
                              Cod Externo: {getEvento.cCas_Cod_Externo}
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row" style={{ marginTop: '15px' }}>
                          <div className="col-md-6">
                            <select
                              disabled
                              className="form-select"
                              name="cEve_Tab_Categoria"
                              onChange={handleChange}
                              value={getEvento && getEvento.cEve_Tab_Categoria}
                            >
                              <option value="0">Seleccionar...</option>
                              {dataCategoriaEvento.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Categoría
                            </label>
                          </div>
                          <div className="col-md-6">
                            <input
                              disabled
                              className="form-control"
                              placeholder="Ingrese el título"
                              name="cEve_Titulo"
                              onChange={handleChange}
                              value={getEvento && getEvento.cEve_Titulo}
                            />
                            <label className="col-form-label labelDown">
                              Título
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <textarea
                              disabled
                              className="form-control"
                              placeholder="Ingrese la descripción"
                              name="cEve_Descripcion"
                              value={getEvento && getEvento.cEve_Descripcion}
                              onChange={handleChange}
                              maxLength="200"
                              style={{ resize: "none" }}
                            ></textarea>
                            <label className="col-form-label labelDown">
                              Descripción
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <Select
                              isDisabled={true}
                              readonly
                              isMulti
                              isClearable
                              onChange={handleChangeParticipantes}
                              className="basic-multi-select"
                              placeholder="Ingrese los participantes"
                              options={UsuariosSelect}
                              defaultValue={GetParticipantes}
                            />
                            <label className="col-form-label labelDown">
                              Participantes
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <input
                              disabled
                              className="form-control"
                              placeholder="Ingrese la ubicación"
                              name="cEve_Ubicacion"
                              value={getEvento && getEvento.cEve_Ubicacion}
                              onChange={handleChange}
                            />
                            <label className="col-form-label labelDown">
                              Ubicación
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-6">
                            <input
                              disabled
                              className="form-control"
                              type="date"
                              name="dEve_FechaIni"
                              value={getEvento && getEvento.dEve_FechaIni}
                              onChange={handleChange}
                            />
                            <label className="col-form-label labelDown">
                              Fecha de inicio
                            </label>
                          </div>
                          <div className="col-md-6">
                            <input
                              disabled
                              className="form-control"
                              type="time"
                              name="tEve_HoraIni"
                              value={getEvento && getEvento.tEve_HoraIni}
                              onChange={handleChange}
                            />
                            <label className="col-form-label labelDown">
                              Hora de inicio
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-6">
                            <input
                              disabled
                              className="form-control"
                              type="date"
                              name="dEve_FechaFin"
                              value={getEvento && getEvento.dEve_FechaFin}
                              onChange={handleChange}
                            />
                            <label className="col-form-label labelDown">
                              Fecha de fin
                            </label>
                          </div>
                          <div className="col-md-6">
                            <input
                              disabled
                              className="form-control"
                              type="time"
                              name="tEve_HoraFin"
                              value={getEvento && getEvento.tEve_HoraFin}
                              onChange={handleChange}
                            />
                            <label className="col-form-label labelDown">
                              Hora de fin
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-6">
                            <select
                              disabled
                              className="form-select"
                              name="cEve_tab_Recordar"
                              value={getEvento && getEvento.cEve_tab_Recordar}
                              onChange={handleChange}
                            >
                              <option value="0">Seleccionar...</option>
                              {sortJSON(dataRecordarEvento, 'cTabCodigo', 'asc').map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Recordatorio
                            </label>
                          </div>
                          <div className="col-md-6">
                            <select
                              disabled
                              className="form-select"
                              name="bEve_TodoDía"
                              value={getEvento && getEvento.bEve_TodoDía}
                              onChange={handleChange}
                            >
                              <option value="00">Seleccionar...</option>
                              <option value="1">Si</option>
                              <option value="0">No</option>
                            </select>
                            <label className="col-form-label labelDown">
                              Todo el día
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-6">
                            <select
                              disabled
                              className="form-select"
                              name="cEve_Tab_Tipo"
                              onChange={handleChange}
                              value={getEvento && getEvento.cEve_Tab_Tipo}
                            >
                              <option value="00">Seleccionar...</option>
                              {dataTipoEvento.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Tipo de evento
                            </label>
                          </div>
                          <div hidden className="col-md-6">
                            <select
                              disabled
                              className="form-select"
                              name="bEve_Estado"
                              value={getEvento && getEvento.bEve_Estado}
                              onChange={handleChange}
                            >
                              <option value="00">Seleccionar...</option>
                              <option value="1">Activo</option>
                              <option value="0">No activo</option>
                            </select>
                            <label className="col-form-label labelDown">
                              Estado
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(3)}
                          className="btn btn-secondary waves-effect"
                          data-dismiss="modal"
                        >
                          Cerrar
                        </button>
                      </div>
                    </Modal>
                    <Modal size="lg" isOpen={modalFiltro}>
                      <div
                        className="modal-header ModalHeaderColor"
                      >
                        <h5
                          style={{ color: "#fff" }}
                          className="modal-title mt-0"
                          id="myModalLabel"
                        >
                          Seleccione criterio(s) de búsqueda
                        </h5>
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(4)}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <strong>Fechas (rango)</strong>
                        <div className="mb-3 row">
                          <div className="col-md-1" style={{ width: "20px" }}></div>
                          <div className="col-md-5">
                            <input
                              className="form-control inputDown"
                              type="date" name="FechaInicial" value={getFiltro && getFiltro.FechaInicial} onChange={handleChangeFiltro}
                            />
                            <label className="col-form-label labelDown">
                              Inicio*
                            </label>
                          </div>
                          <div className="col-md-1" style={{ width: "20px" }}></div>
                          <div className="col-md-5">
                            <input
                              className="form-control inputDown"
                              type="date" name="FechaFin" value={getFiltro && getFiltro.FechaFin} onChange={handleChangeFiltro}
                            />
                            <label className="col-form-label labelDown">
                              Fin*
                            </label>
                          </div>
                        </div>
                        <hr style={{ color: "#000", marginTop: "10px" }} />
                        <div className="mb-3 row">
                          <div className="col-md-1" hidden={hiddenCliente} style={{ width: "20px" }}>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheckcolor1"
                              checked={chekedValor1}
                              value={chekedValor1}
                              onChange={handleChangeValor1}
                              style={{ transform: "scale(1.5)", marginTop: "10px" }}
                            />
                          </div>
                          <div className="col-md-5" hidden={hiddenCliente}>
                            <select className="form-select inputDown" name="nCli_Id" id="nCli_Id" disabled={Valor1} onChange={handleChangeFiltro}>
                              <option value="00">Seleccionar...</option>
                              {dataCliente.map((row, i) => (
                                <option key={i} value={row.nCli_Id}>
                                  {row.cCli_NombreCompleto}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Cliente
                            </label>
                          </div>
                          <div className="col-md-1" style={{ width: "20px" }}>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheckcolor1"
                              checked={chekedValor2}
                              value={chekedValor2}
                              onChange={handleChangeValor2}
                              style={{ transform: "scale(1.5)", marginTop: "10px" }}
                            />
                          </div>
                          <div className="col-md-5">
                            <select
                              className="form-select inputDown"
                              id="nCas_Id" name="nCas_Id" disabled={Valor2} onChange={handleChangeFiltro}
                            >
                              <option value="0">Seleccionar...</option>
                              {dataCaso.map((row, i) => (
                                <option key={i} value={row.nCas_Id}>
                                  {row.cCas_Titulo}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Caso
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-1" style={{ width: "20px" }}>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheckcolor1"
                              checked={chekedValor3}
                              value={chekedValor3}
                              onChange={handleChangeValor3}
                              style={{ transform: "scale(1.5)", marginTop: "10px" }}
                            />
                          </div>
                          <div className="col-md-5">
                            <select
                              className="form-select"
                              id="cEve_Tab_Tipo" name="cEve_Tab_Tipo" disabled={Valor3} onChange={handleChangeFiltro}
                            >
                              <option value="00">Seleccionar...</option>
                              {dataTipoEvento.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Tipo de Evento
                            </label>
                          </div>
                          <div className="col-md-1" style={{ width: "20px" }}>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheckcolor1"
                              checked={chekedValor4}
                              value={chekedValor4}
                              onChange={handleChangeValor4}
                              style={{ transform: "scale(1.5)", marginTop: "10px" }}
                            />
                          </div>
                          <div className="col-md-5">
                            <select
                              className="form-select"
                              id="cEve_Tab_Categoria"
                              name="cEve_Tab_Categoria"
                              disabled={Valor4}
                              onChange={handleChangeFiltro}
                            >
                              <option value="0">Seleccionar...</option>
                              {dataCategoriaEvento.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Categoria
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-1" style={{ width: "20px" }}>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheckcolor1"
                              checked={chekedValor5}
                              value={chekedValor5}
                              onChange={handleChangeValor5}
                              style={{ transform: "scale(1.5)", marginTop: "10px" }}
                            />
                          </div>
                          <div className="col-md-5">
                            <select
                              className="form-select"
                              id="nUsu_ID" name="nUsu_ID" disabled={Valor5} onChange={handleChangeFiltro}
                            >
                              <option value="00">Seleccionar...</option>
                              {dataComboUsuario.map((row, i) => (
                                <option key={i} value={row.nUsu_ID}>
                                  {row.cUsu_Nombres}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Usuario
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(4)}
                          className="btn btn-secondary waves-effect"
                          data-dismiss="modal"
                        >
                          Cerrar
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger waves-effect waves-light"
                          onClick={() => FuncionFiltroEvento(document.getElementById("cEstado").value)}
                        >
                          Filtrar
                        </button>
                      </div>
                    </Modal>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card >
                <CardBody style={{ paddingBottom: "0px", }}>
                  <CardTitle style={{ fontSize: "20px", color: "#aa1b0E", fontWeight: "normal", }}>
                    <span style={{ color: "#aa1b0E" }}>Listado de Eventos</span>{" "}
                  </CardTitle>

                  <div className="mb-3 row">
                    <div className="col-md-2" >
                      <input
                        type="checkbox"
                        className="checkbox"
                        id="selecionarMasivo"
                        style={{ marginTop: "8px", paddingLeft: '0px', marginLeft: '0px', left: '5px' }}
                        onClick={() => SeleccionarTodo()}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customCheckcolor1"
                        style={{ marginTop: "8px", paddingLeft: "67px" }}

                      >
                        Marcar todos
                      </label>
                    </div>
                    <div className="col-md-3">

                    </div>
                    <div className="col-md-3" style={{ textAlign: 'right', float: 'right', marginTop: '-20px' }}>
                      <label style={{ textAlign: 'center', paddingTop: '0px' }}>Cantidad de eventos : {data.length}</label>
                      <input className="form-control" placeholder="🔎 Ingrese el titulo del evento" style={{ textAlign: "right", width: "100%" }} type="text" onChange={onChangeHandler.bind(this)} />
                    </div>
                    <div className="col-md-2" style={{ textAlign: 'right', float: 'right', marginTop: '-30px' }}>
                      <div className="mb-12" style={{ display: "flex", justifyContent: "flex-end" }}>
                        <div className="col-md-12" >
                          <label className="col-form-label">
                            Estado
                          </label>
                          <select
                            className="form-select"
                            name="cEstado" id="cEstado" onChange={handleChangeFiltro}
                          >
                            <option value={"Todos"}>
                              Todos
                            </option>
                            {dataEstadoET.map((row, i) => (
                              <option key={i} value={row.cTabCodigo}>
                                {row.cTabNombre}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-1" style={{ textAlign: 'right', float: 'right', marginTop: '6px' }}>
                      <div className="dropdown" style={{ marginBottom: '10px', marginLeft: '0px' }}>
                        <button hidden={hiddenBtnEditar} onClick={() => myFunction()} className="drop-button btn btn-danger" style={{ width: '180px' }}>Acciones masivas</button>
                        <div id="myDropdown" className="dropdown-content">
                          <a href="#" onClick={() => abrirCerrarModal(8)}> Desasociar participantes</a>
                          <a href="#" onClick={() => abrirCerrarModal(6)}>Cambiar estado</a>
                          <a href="#" onClick={() => abrirCerrarModal(7)}>Cambiar tipo de evento</a>
                          <a href="#" onClick={() => methodEliminarEventosLogicamente()}>Eliminar eventos</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>

                <form name="f1" style={{ minHeight: '700px' }}>
                  {data.map((row, i) => (
                    <div key={i} style={{ borderBottom: "1px solid #bbb" }}>

                      <div className="container-fluid" style={{ height: '100px', padding: '0px' }}>
                        <div className="row col-md-12" style={{ padding: '0px', marginLeft: '2px' }}>

                          <div className="col-md-1" style={{ width: '5%', height: '110px', padding: '0px' }}>
                            <input
                              type="checkbox"
                              className="checkbox"
                              name={JSON.stringify(row)}
                              style={{ paddingLeft: '13px', marginLeft: '23%' }}
                              id={"id" + row.nEve_Id}
                              onClick={() => SelectCheckbox(row)}
                            />
                          </div>

                          <div className="col-md-9" style={{ width: '71%', paddingLeft: '6px' }}>
                            <div className="row col-md-12 titulo Texto-Limit" style={{ marginTop: '10px', paddingTop: '0px', paddingBottom: '0px' }}>

                              <Link className="Texto-Limit col-md-1" data-tip data-for={"tooltip" + row.cCas_Titulo} style={{ width: 'auto', maxWidth: '40%' }} to="/dashboard-Casos" onClick={() => SelectDetalle(row.nCas_Id, row.nCli_Id)} >
                                <a className="Texto-Limit" style={{ color: "#6B6B6B" }} >
                                  {row.cCas_Titulo}
                                </a>

                                <ReactTooltip id={"tooltip" + row.cCas_Titulo}>
                                  <a style={{ fontSize: '13px', display: 'inline-block', fontWeight: 'normal' }}>Ir al caso</a>
                                </ReactTooltip>


                              </Link>
                              {" "}/{" "}

                              <Link className="Texto-Limit text-detalle col-md-5" data-tip data-for={"tooltip1" + row.cEve_Titulo} onClick={() => Acciones(row, "VerDetalle")} >
                                <a className="Texto-Limit text-detalle" style={{ color: "#032D5E" }} >
                                  {row.cEve_Titulo}
                                </a>

                                <ReactTooltip id={"tooltip1" + row.cEve_Titulo} >
                                  <a style={{ fontSize: '13px', display: 'inline-block', fontWeight: 'normal' }}>Ver el detalle del evento</a>
                                </ReactTooltip>
                              </Link>

                            </div>

                            <div className="titulo Texto-Limit" style={{ marginTop: '0px', height: '30px' }}>
                              <label htmlFor="" className="text-detalle Texto-Limit" style={{ width: 'auto', maxWidth: '40%', marginRight: '7px', marginTop: '10px' }}>
                                {row.cEve_Descripcion}
                              </label>
                              <FontAwesomeIcon icon={faMapMarkedAlt} hidden={row.cEve_Ubicacion == ""} style={{ color: "#1F60AB", marginRight: '1.5px', marginTop: '0px', marginBottom: '14px' }} />
                              <label className="text-detalle Texto-Limit" style={{ marginRight: '0px', marginTop: '10px' }}>
                                {row.cEve_Ubicacion}
                              </label>
                            </div>

                            <div className="titulo Texto-Limit" style={{ marginTop: '5px', float: 'left' }}>
                              <label htmlFor="" className="text-detalle">
                                {row.cEve_Tab_Categoria2}
                              </label>{" "}

                              <span style={{ marginLeft: '4px' }}></span>

                              <i style={{ fontSize: "15px", color: "black", marginRight: '2px' }} className="icon-edificio"></i>

                              <label className="text-detalle">{row.cCli_NombreCompleto}</label>
                            </div>

                            <div className="Texto-Limit" style={{ marginTop: '0px', float: 'left', marginLeft: '20px' }}>
                              {dataParticipantes.map((row2, i) => {
                                if (row.nEve_Id == row2.nEve_Id) {
                                  return (
                                    <div className="col-md-1"
                                      style={{
                                        width: "33px",
                                        bottom: "12px",
                                        paddingTop: "-4px",
                                        float: 'left'
                                      }}>
                                      <div>
                                        <ReactTooltip id={"tooltip" + row2.cUsu_Nombres}>
                                          {row2.cUsu_Nombres}
                                        </ReactTooltip>
                                      </div>
                                      <div data-tip data-for={"tooltip" + row2.cUsu_Nombres} style={{ paddingLeft: '2px' }}>
                                        <LetteredAvatar
                                          name={row2.cUsu_Nombres}
                                          size={30}
                                          radius={50}
                                          color="#fff"
                                          backgroundColors={arrayWithColors}
                                        />
                                      </div>


                                    </div>)
                                }
                              })}
                            </div>

                          </div>

                          <div className="col-md-2" style={{ width: '24%', padding: '0px' }}>
                            <div className="col-md-10 Texto-Limit" style={{ float: 'left', marginTop: '5px' }}>
                              <label className="" style={{ fontSize: '12px' }}>
                                CASO-{row.cCas_Cod_Externo}
                              </label>
                            </div>

                            <div className="" style={{ float: 'left', marginTop: '5px', }}>

                              <UncontrolledDropdown >
                                <DropdownToggle
                                  tag="a"
                                  className="dropdown-toggle"
                                >
                                  <i className="bx bx-dots-vertical-rounded" />
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <DropdownItem
                                    onClick={() =>
                                      Acciones(row, "VerDetalle")}
                                  >
                                    Ver detalle
                                  </DropdownItem>


                                  <DropdownItem hidden={hiddenBtnEditar} onClick={() => Acciones(row, "Editar")}>
                                    Editar
                                  </DropdownItem>

                                  <DropdownItem hidden={hiddenBtnEliminar}
                                    onClick={() =>
                                      Swal.fire({
                                        title: "Mensaje",
                                        html:
                                          "Estas seguro de eliminar el evento: <b>" +
                                          row.cEve_Titulo +
                                          "</b>",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonColor: "#3085d6",
                                        cancelButtonColor: "#d33",
                                        confirmButtonText: "Aceptar",
                                        cancelButtonText: "Cancelar",
                                      }).then(result => {
                                        if (result.isConfirmed) {
                                          setEvento(row)
                                          FuncionEliminarEvento(row.nEve_Id)
                                        }
                                      })
                                    }
                                  >
                                    Eliminar
                                  </DropdownItem>

                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>

                            <div className="col-md-12 titulo Texto-Limit" style={{ marginTop: '10px' }}>
                              <span className="time">
                                {(() => {
                                  if (row.cEve_Estado == "TT01") {

                                    if (validarSucediento(row.dEve_FechaIni, row.tEve_HoraIni, row.dEve_FechaFin, row.tEve_HoraFin) == true) {
                                      return (
                                        <FontAwesomeIcon
                                          style={{ color: "#FFCD00" }}
                                          icon={faCircle}
                                        />
                                      )
                                    }
                                    else {
                                      return (
                                        <FontAwesomeIcon
                                          style={{ color: "#1F60AB" }}
                                          icon={faCircle}
                                        />
                                      )
                                    }


                                  } else if (row.cEve_Estado == "TT02") {


                                    return (
                                      <FontAwesomeIcon
                                        style={{ color: "#00A94F" }}
                                        icon={faCircle}
                                      />
                                    )


                                  } else if (row.cEve_Estado == "TT03") {
                                    return (
                                      <FontAwesomeIcon
                                        style={{ color: "#AA1B0E" }}
                                        icon={faCircle}
                                      />
                                    )
                                  } else if (row.cEve_Estado == "TT04") {
                                    return (
                                      <FontAwesomeIcon
                                        style={{ color: "#AA1B0E" }}
                                        icon={faCircle}
                                      />
                                    )
                                  }
                                })()}
                                {" "}{row.cEve_Estado2} {(() => {

                                  return (
                                    <label>{validarFecha(row.dEve_FechaIni, row.cEve_tab_Recordar, row.cEve_Estado)}


                                    </label>
                                  )
                                })()}
                              </span>
                            </div>

                            <div className="col-md-12  Texto-Limit" style={{ marginTop: '10px' }}>

                              <span className="time" style={{ color: "#AA1B0E" }}>Inicio: {row.dEve_FechaIni2} - Fin: {row.dEve_FechaFin2}</span>

                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}
export default Evento
