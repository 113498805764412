import React, { useState, useEffect } from "react"
import { useContext } from "react"
import { MDBDataTable } from "mdbreact"
import { Link } from "react-router-dom"
import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Modal
} from "reactstrap"
import { getDecryptedItem } from '../../../../util';
const baseUrl = process.env.REACT_APP_API_URL
import instance from '../../../../config'
import Swal from "sweetalert2"
import ClienteContext from "../../Context/Cliente/ClienteContext";
import ModalCliente from "./ModalCliente"
import { setEncryptedItem } from '../../../../util';


const ListarCliente = () => {
    const clienteContext = useContext(ClienteContext);
    const [getmodalP, setModalP] = useState(false)
    const [getmodalE, setModalE] = useState(false)
    const [modalLogin, setModalLogin] = useState(false)
    const [getIdCliente, setIdCliente] = useState(0)
    const [getSelectCliente, setSelectCliente] = useState()
    const [getUsuario, setUsuario] = useState({})
    const [hiddenBtnEditar, setHiddenBtnEditar] = useState(true)
    const [hiddenBtnEliminar, setHiddenBtnEliminar] = useState(true)


    const abrirCerrarModal = Modal => {
        if (Modal == 1) {
            if (!getmodalP == false) {
                Swal.fire({
                    html: "¿Seguro que desea salir sin guardar los datos?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si",
                    cancelButtonText: "No",
                }).then(result => {
                    if (result.isConfirmed) {
                        setModalP(!getmodalP)
                    }
                })
            } else {
                setModalP(!getmodalP)
            }
        } else if (Modal == 2) {
            if (!getmodalE == false) {
                Swal.fire({
                    html: "¿Seguro que desea salir sin guardar los datos?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si",
                    cancelButtonText: "No",
                }).then(result => {
                    if (result.isConfirmed) {
                        setModalE(!getmodalE)
                    }
                })
            } else {
                setModalE(!getmodalE)
            }
        }
        else if (Modal == 3) {

            if (!modalLogin == false) {
                Swal.fire({
                    html: "¿Seguro que desea salir sin guardar los datos?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si",
                    cancelButtonText: "No",
                }).then(result => {
                    if (result.isConfirmed) {
                        setModalLogin(!modalLogin)
                    }
                })
            } else {
                setModalLogin(!modalLogin)
            }
        }
    }
    const EditarCliente = (Tipo, IdCliente, Row) => {
        setIdCliente(IdCliente);
        setSelectCliente(Row)
        if (Tipo == 'Persona') {
            abrirCerrarModal(1)
        } else {
            abrirCerrarModal(2)
        }
    }
    const BuscarUsuario = async (usuario) => {
        let postData = {
            nUsu_ID: parseInt(getDecryptedItem("ID")),
        }
        await instance
            .post("/Usuario/Perfil", postData)
            .then(response => {

                setUsuario(response.data[0])
            })
            .catch(error => {

            })
    }
    const FuncionAgregarUsuario = async (objeto) => {

        var postData = {
            nUsu_ID_Creador: parseInt(getDecryptedItem("ID")),
            nRol_ID: 9,
            cUsu_Nombres: objeto.cCli_NombreCompleto,
            cUsu_email: objeto.cCli_Email1,
            cUsu_Fono: objeto.cCli_Fono1,
            cUsu_Login: objeto.cCli_Email1,
            cUsu_Pass: " ",
            bUsu_Activo: 1,
            nCli_Id: parseInt(objeto.nCli_Id),
            usuario: getUsuario.cUsu_Nombres
        }

        console.log(postData)


        if (objeto.cCli_Email1 == null || objeto.cCli_Email1 == undefined || objeto.cCli_Email1.toString() == "") {
            Swal.fire({
                title: "Mensaje",
                text: "El cliente debe tener un correo registrado",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        }
        else {
            await instance
                .post("/Usuario/RegistrarUsuarioxCliente", postData)
                .then(async response => {

                    console.log(response.data)  

                    if (response.data["status"]) {
                        Swal.fire({
                            title: "Mensaje",
                            text: "Ya existe un usuario con el mismo login",
                            icon: "error",
                            confirmButtonText: "Aceptar",
                        })
                    }
                    else {
                        let nUsu_ID = getDecryptedItem("ID")
                        let idUsu = response.data[0].nUsu_Id; // este es el id del usuario creado
                        //FuncionCorreoUsuario(Number.parseInt(idUsu),nUsu_ID,'CR02','Usted ha sido invitado a participar en GPS LEGAL');
                        Swal.fire({
                            title: "Mensaje",
                            text: "Agregado exitosamente",
                            icon: "success",
                            confirmButtonText: "Aceptar",
                        })
                        //    
                        //    const Modulos = dataModulos.map((row, i) => {
                        //        FuncionAgregarUsuarioAcceso(response.data[0].nUsu_Id, row.cTabCodigo, 1, 1, 1, 1)
                        //    })
                    }
                })
                .catch(error => {

                })
        }


    }

    const FuncionCorreoUsuario = async (_nCas_Id, _nUsu_Id, _cCor_Tab_Tipo, _cCor_Asunto) => {
        var postData = {
            nCas_Id: _nCas_Id,
            nUsu_Id: _nUsu_Id,
            cCor_Tab_Tipo: _cCor_Tab_Tipo,
            cCor_Asunto: _cCor_Asunto,
        }
        await instance
            .post("/Correo/Insertar", postData)
            .then(response => {
            })
            .catch(error => {

            })
    }

    const FuncionListarPermisos = async () => {

        var postData = {
          nUsu_ID: parseInt(getDecryptedItem("RolID"))
        }

        await instance
        .post("/Usuario/Acceso", postData)
        .then(response => {
            let arrayPermisos = response.data;
            const permiso = arrayPermisos.find(element => element.cUsu_Modulo == "ME03");

            if (permiso.bUsu_Editar == 1) {
                setHiddenBtnEditar(false);
            }
            else {
                setHiddenBtnEditar(true);
            }
            if (permiso.bUsu_Eliminar == 1) {
                setHiddenBtnEliminar(false);
            }
            else {
                setHiddenBtnEliminar(true);
            }
        })
        .catch(error => {

        })
    }

    useEffect(() => {
        BuscarUsuario();
        FuncionListarPermisos();
        clienteContext.FuncionListarCliente()
    }, []);
    const datas = {
        columns: [
            {
                label: "Tipo",
                field: "cCli_Tipo",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "RUC/DNI",
                field: "cCli_Num_Doc",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
        ],
        rows: clienteContext.clientes,
    }

    const Acciones = {
        columns: [
            ...datas.columns,
            {
                label: "Nombres",
                field: "NombreCompleto",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "Grupo",
                field: "cCli_Profesion2",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "Teléfono",
                field: "cCli_Fono1",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "Correo",
                field: "cCli_Email1",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "Encargado",
                field: "cCli_ConCargo",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "Acciones",
                field: "acciones",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
        ],
        rows: [
            ...datas.rows.map((row, id) => ({
                ...row,
                NombreCompleto: (
                    row.cCli_Profesion == 'ZA01' ?
                        <Link style={{ color: "black" }} onClick={() =>
                            setEncryptedItem('CLIID', row.nCli_Id)
                        } to="/dashboard-Cliente">
                            {row.cCli_NombreCompleto}
                        </Link> : <Link style={{ color: "black" }} to="#" >
                            {row.cCli_NombreCompleto}
                        </Link>
                ),
                acciones: (
                    <div>
                        <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="dropdown-toggle">
                                <i className="bx bx-dots-vertical-rounded" />
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem
                                    hidden={hiddenBtnEditar}
                                    onClick={() =>
                                        EditarCliente(row.cCli_Tipo, row.nCli_Id, row)
                                    }>
                                    Editar
                                </DropdownItem>
                                <DropdownItem
                                    hidden={hiddenBtnEliminar}
                                    onClick={() =>
                                        clienteContext.FuncionEliminarCliente(row.cCli_NombreCompleto, row)
                                    }
                                >
                                    Eliminar
                                </DropdownItem>
                                {row.cCli_Profesion == 'ZA01' ?
                                    <DropdownItem onClick={() =>
                                        setEncryptedItem('CLIID', row.nCli_Id)
                                    }>
                                        <Link to="/dashboard-Cliente">Detalle</Link>
                                    </DropdownItem>
                                    : false}

                                {/* <DropdownItem >
                                    <Link to="/dashboard-prueba">Dashboard</Link>
                                </DropdownItem> */}
                                <DropdownItem
                                    hidden={hiddenBtnEditar}
                                    onClick={() =>
                                        Swal.fire({
                                            title: "Mensaje",
                                            html:
                                                "¿ Desea crear un usuario para el cliente <b> </br>" +
                                                row.cCli_NombreCompleto +
                                                "</b> ?",
                                            icon: "warning",
                                            showCancelButton: true,
                                            confirmButtonColor: "#3085d6",
                                            cancelButtonColor: "#d33",
                                            confirmButtonText: "Aceptar",
                                            cancelButtonText: "Cancelar",
                                        }).then(result => {
                                            if (result.isConfirmed) {
                                                FuncionAgregarUsuario(row)
                                            }
                                        })
                                    }
                                >
                                    Crear usuario
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                ),
            })),
        ],
    }
    return (<>
        {(() => {
            if (modalLogin == true) {
                return <>
                    <Modal show={modalLogin}>
                        <div
                            className="modal-header ModalHeaderColor"
                        >
                            <h5
                                style={{ color: "#fff" }}
                                className="modal-title mt-0"
                                id="myModalLabel"
                            >
                                Editar tarea
                            </h5>
                            <button
                                type="button"
                                onClick={() => onCloseModal()}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                    </Modal>
                </>
            }
        })()}
        {(() => {
            if (getmodalP == true) {
                return <>
                    <ModalCliente show={getmodalP}
                        onCloseClick={() => abrirCerrarModal(1)}
                        Condicion="2"
                        Id={getIdCliente}
                        Row={getSelectCliente}
                        onCloseModal={() => setModalP(!getmodalP)}
                    />
                </>
            }
        })()}
        {(() => {
            if (getmodalE == true) {
                return <>
                    <ModalCliente show={getmodalE}
                        onCloseClick={() => abrirCerrarModal(2)}
                        Condicion="3"
                        Id={getIdCliente}
                        Row={getSelectCliente}
                        onCloseModal={() => setModalE(!getmodalE)}
                    />
                </>
            }
        })()}
        <MDBDataTable
            data={Acciones}
            className="TablaClientes"
            responsiveXl
            fixed
            hover
            entriesLabel="Mostrar"
            entrylabel={"Mostrar entradas"}
            infoLabel={["Mostrando ", "a", "de", "registros"]}
            bordered
            order={["age", "desc"]}
            noRecordsFoundLabel={"No hay datos"}
            searchLabel={"Buscar"}
            noBottomColumns
            paginationLabel={["Anterior", "Siguiente"]}
            theadColor="HeadBlue"
        />
    </>)
}
export default ListarCliente