import React, { Component, useState, useEffect } from "react"
import {
  CardBody,
  CardFooter,
  Card,
  Col,
  Container,
  CardTitle,
  Button,
  Row,
  Modal,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Media,
  Nav,
  NavItem,
  NavLink,
  Table,
} from "reactstrap"
import { getDecryptedItem } from '../../util';
import SimpleBar from "simplebar-react"
import avatar from "../../assets/images/users/avatar-1.jpg"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/tablescss/FormatTable.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBook,
} from "@fortawesome/free-solid-svg-icons"
import Swal from "sweetalert2"
import instance from '../../config'
import ReactApexChart from "react-apexcharts"
import ModalEvento from "pages/Componentes/ModalEvento"
import ModalTarea from "pages/Menus/Tarea/Componentes/ModalTarea"



import ComboState from "pages/Menus/Context/Combos/ComboState"
import ModalCliente from "../../pages/Menus/Cliente/Componentes/ModalCliente"
import { toLower, wrap } from "lodash"
import moment from "moment"
import ComboContext from "pages/Menus/Context/Combos/ComboContext"
import ClienteState from "pages/Menus/Context/Cliente/ClienteState"

const baseUrl = process.env.REACT_APP_API_URL
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10 if (mes < 10) mes="0" + mes //agrega cero si el menor de 10
const Meses = [
  "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"
]
const DashboardCliente = props => {

  const [getSelectCliente, setSelectCliente] = useState()
  const [getmodalP, setModalP] = useState(false)
  const [getmodalE, setModalE] = useState(false)
  const [getIdCliente, setIdCliente] = useState(0)
  const [getCantidadTarea, setCantidadTarea] = useState(4)
  const [getCantidadEventos, setCantidadEventos] = useState(4)
  const [getCantidadNovedad, setCantidadNovedad] = useState(4)
  const [getSeriesGraficoLineaU, setSeriesGraficoLineaU] = useState([])
  const [GetParticipantesEvento, setParticipantesEvento] = useState([])
  const [GetParticipantesTarea, setParticipantesTarea] = useState([])
  const [getSeriesGraficoLineaS, setSeriesGraficoLineaS] = useState([])
  const [dataTotal, setDataTotal] = useState([{ Total: 0.00 }])
  const [getFlagTotal, setFlagTotal] = useState(false)
  const options = {
    maintainAspectRatio: false,
    labels: ["Facturable", " No Facturable"],
    colors: ["#00A94F", "#AA1B0E"],
    legend: { show: !1 },
    plotOptions: {
      pie: {
        donut: {
          size: "55%",
        },
      },
    },
  }
  const series2 = [
    {
      name: ["Facturado S/"],
      data: getSeriesGraficoLineaS,
      color: "#AA1B0E",
    },
    {
      name: ["Facturado U$"],
      data: getSeriesGraficoLineaU,
      color: "#00A94F",
    },
  ]
  const options2 = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    title: {
      text: "Facturado",
      align: "left",
      style: {
        fontSize: '14px',
        color: '#263238',
        fontFamily: 'texto-combex',
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic",
      ],
    },
  }

  const [hiddenBtnEditar, setHiddenBtnEditar] = useState(true)
  const [hiddenBtnEliminar, setHiddenBtnEliminar] = useState(true)
  const [dataCaso, setDataCaso] = useState([])
  const [dataTareasEstadisticas, setDataTareasEstadisticas] = useState([])
  const [dataCliente, setdataCliente] = useState([])
  const [dataEvento, setDataEvento] = useState([])
  const [dataTarea, setDataTarea] = useState([])
  const [getModalEvento, setModalEvento] = useState(false)
  const [getModalTarea, setModalTarea] = useState(false)
  const [getModalTareaReadOnly, setModalTareaReadOnly] = useState(false)
  const [getRow, setRow] = useState({})
  const [HorasLabHL, setHorasHorasLabHL] = useState("")
  const [HorasLabHF, setHorasHorasLabHF] = useState("")
  const [HorasLabHNF, setHorasHorasLabHNF] = useState("")
  const [getNotas, setNotas] = useState([])
  const [getAnioData, setAnioData] = useState([])
  const [modo, setModo] = useState(1)
  const [getFiltroGrafico, setFiltroGrafico] = useState({
    M: parseInt(Fecha.getMonth() + 1),
    Y: parseInt(Fecha.getFullYear()),
  })
  const abrirCerrarModalTable = (Modal, Row) => {
    if (Modal == 1) {
      FuncionBuscarEventosParticipantes(Row.nEve_Id)

      setRow(Row)
    } else {
      FuncionBuscarTareasParticipantes(Row.nTar_Id)
      setRow(Row)
    }
  }
  var Listar = [];
  const FuncionBuscarEventosParticipantes = async (_nEve_Id) => {   // Aqui se carga GetdefaultParticipantes
    var postData = {
      nEve_Id: _nEve_Id,
    }
    await instance
      .post("/Evento/Buscar/Participantes", postData)
      .then(response => {
        setParticipantesEvento(response.data);
        setModalEvento(!getModalEvento)
      })
      .catch(error => {
      })
  }
  const FuncionBuscarTareasParticipantes = async (_nTar_Id) => {
    var postData = {
      nTar_Id: _nTar_Id,
    }
    await instance
      .post("/Tarea/Buscar/Participante", postData)
      .then(response => {

        setParticipantesTarea(response.data);

        setModalTarea(true)
     
 
      })
      .catch(error => {
      })
  }

  //Listado de la base de datos
  const SerieHoras = dataTareasEstadisticas.map(
    (row, i) =>
      (Listar = [(parseInt(row.tLab_HoraFacturable) + parseFloat(row.tLab_MinutoFacturable / 100)), (parseInt(row.tLab_HoraNoFacturable) + parseFloat(row.tLab_MinutoNoFacturable / 100))])
  )
  const FuncionListarCasos = async (_nClid_Id) => {
    var postData = {
      nCli_Id: _nClid_Id,
    }
    await instance
      .post("/Casos/Buscar/Cliente", postData)
      .then(response => {
        setDataCaso(response.data)
      })
      .catch(error => {
      })
  }
  const FuncionListarCliente = async (_nClid_Id) => {
    var postData = {
      nClid_Id: _nClid_Id,
    }

    console.log(postData);
    await instance
      .post("/Cliente/Buscar", postData)
      .then(response => {
        console.log(response.data);
        setdataCliente(response.data)
      })
      .catch(error => {
      })
  }
  const FuncionListarTareas = async (_nClid_Id) => {
    var postData = {
      nCas_Id: null,
      nCli_Id: _nClid_Id,
      nUsu_ID: getDecryptedItem("ID"),
    }
    await instance
      .post("/Tareas/DH", postData)
      .then(response => {
        setDataTarea(response.data)
      })
      .catch(error => {
      })
  }
  const FuncionListarEventos = async (_nClid_Id) => {
    var postData = {
      nCas_Id: null,
      nCli_Id: _nClid_Id,
      nUsu_ID: getDecryptedItem("ID"),
    }
    await instance
      .post("/Eventos/DH", postData)
      .then(response => {
        setDataEvento(response.data)
      })
      .catch(error => {
      })
  }
  const FuncionListarEstadisticas1 = async (_nClid_Id) => {
    var postData = {
      id: _nClid_Id,
      M: getFiltroGrafico.M,
      Y: getFiltroGrafico.Y
    }
    await instance
      .post("/Dashboard/Cliente/Laborado", postData)
      .then(response => {
        setDataTareasEstadisticas(response.data)
        var HL = response.data[0].nLab_Hora
        var ML = response.data[0].nLab_Minuto
        var HF = response.data[0].tLab_HoraFacturable
        var MF = response.data[0].tLab_MinutoFacturable
        var HnF = response.data[0].tLab_HoraNoFacturable
        var MnF = response.data[0].tLab_MinutoNoFacturable
        if (response.data[0].nLab_Hora.length == 1) {
          HL = "0" + response.data[0].nLab_Hora
        }
        if (response.data[0].nLab_Minuto.length == 1) {
          ML = "0" + response.data[0].nLab_Minuto
        }
        if (response.data[0].tLab_HoraFacturable.length == 1) {
          HF = "0" + response.data[0].tLab_HoraFacturable
        }
        if (response.data[0].tLab_MinutoFacturable.length == 1) {
          MF = "0" + response.data[0].tLab_MinutoFacturable
        }
        if (response.data[0].tLab_HoraNoFacturable.length == 1) {
          HnF = "0" + response.data[0].tLab_HoraNoFacturable
        }
        if (response.data[0].tLab_MinutoNoFacturable.length == 1) {
          MnF = "0" + response.data[0].tLab_MinutoNoFacturable
        }

        setHorasHorasLabHL(HL + ":" + ML)
        setHorasHorasLabHF(HF + ":" + MF)
        setHorasHorasLabHNF(HnF + ":" + MnF)
      })
      .catch(error => {
      })
  }
  const FuncionListarMesesLaborados = async () => {
    var postData = {
      nUsu_ID: null,
      nCas_Id: null,
      nCli_Id: parseInt(getDecryptedItem("CLIID")),
      M: getFiltroGrafico.M,
      Y: getFiltroGrafico.Y
    }
    await instance
      .post("/Dashboard/MesesLaborados", postData)
      .then(response => {


        let Array = [
          { "Mes": "01", "Cantidad": "0" },
          { "Mes": "02", "Cantidad": "0" },
          { "Mes": "03", "Cantidad": "0" },
          { "Mes": "04", "Cantidad": "0" },
          { "Mes": "05", "Cantidad": "0" },
          { "Mes": "06", "Cantidad": "0" },
          { "Mes": "07", "Cantidad": "0" },
          { "Mes": "08", "Cantidad": "0" },
          { "Mes": "09", "Cantidad": "0" },
          { "Mes": "10", "Cantidad": "0" },
          { "Mes": "11", "Cantidad": "0" },
          { "Mes": "12", "Cantidad": "0" },
        ];
        let Array2 = [
          { "Mes": "01", "Cantidad": "0" },
          { "Mes": "02", "Cantidad": "0" },
          { "Mes": "03", "Cantidad": "0" },
          { "Mes": "04", "Cantidad": "0" },
          { "Mes": "05", "Cantidad": "0" },
          { "Mes": "06", "Cantidad": "0" },
          { "Mes": "07", "Cantidad": "0" },
          { "Mes": "08", "Cantidad": "0" },
          { "Mes": "09", "Cantidad": "0" },
          { "Mes": "10", "Cantidad": "0" },
          { "Mes": "11", "Cantidad": "0" },
          { "Mes": "12", "Cantidad": "0" },
        ];
        let ArrayU = []
        let ArrayS = []
        for (var i = 0; i < response.data.length; i++) {
          for (var j = 0; j < Meses.length; j++) {
            if (response.data[i].Mes == Meses[j]) {
              if (response.data[i].Moneda == "Dólares") {
                Array[j].Cantidad = response.data[i].Total;
              } else {
                Array2[j].Cantidad = response.data[i].Total;
              }
            }
          }
        }
        for (var i = 0; i < Array.length; i++) {
          ArrayU.push(Array[i].Cantidad)
        }
        for (var i = 0; i < Array2.length; i++) {
          ArrayS.push(Array2[i].Cantidad)
        }


        setSeriesGraficoLineaU(ArrayU)
        setSeriesGraficoLineaS(ArrayS)
      })
      .catch(error => {
      })
  }
  const handleChangeFiltro = e => {
    setFiltroGrafico({
      ...getFiltroGrafico,
      [e.target.name]: e.target.value
    })
  }
  const SendAnios = () => {
    const anio = ano
    const anios = []
    for (var i = 2021; i <= anio + 3; i++) {
      anios.push({ anio: i })
    }
    setAnioData(anios)
    console.table(anios)
  }
  const BuscarFiltro = () => {
    var IDCLIENTE = parseInt(getDecryptedItem("CLIID"));
    FuncionListarEstadisticas1(IDCLIENTE)
    FuncionListarMesesLaborados();
  }
  const handleChangeNotas = e => {
    FuncionInsertNotas(e.target.value)
  }
  const FuncionListarNotas = async (_nClid_Id) => {
    var postData = {
      nNta_Cli_Id: _nClid_Id,
    }
    await instance
      .post("/Nota/Listar", postData)
      .then(response => {
        setNotas(response.data)
      })
      .catch(error => {
      })
  }
  const FuncionInsertNotas = async (_nNta_Nota) => {
    var postData = {
      nNta_Nota: _nNta_Nota,
      nNta_Cli_Id: parseInt(getDecryptedItem("CLIID")),
      nNta_Usu_Id: parseInt(getDecryptedItem("ID")),
    }
    await instance
      .post("/Nota/Insertar", postData)
      .then(response => {
        FuncionListarNotas(parseInt(getDecryptedItem("CLIID")))
      })
      .catch(error => {
      })
  }
  const FuncionEliminarNotas = async () => {
    var postData = {
      nNta_Id: nNta_Id,
    }
    await instance
      .post("/Nota/Eliminar", postData)
      .then(response => {
      })
      .catch(error => {
      })
  }
  const fListarCuentaTotal = async (_nCli_Id) => {
    var postData = {
      nCli_Id: _nCli_Id,
      nCas_Id: null,
    }

    await instance
      .post("/Liquidacion/SaldoCajaGasto", postData)
      .then(response => {

        if (response.data[0].Total == null) {
          let array = [
            { Total: 0.00 }
          ]
          setDataTotal(array)
        }
        else {
          setDataTotal(response.data)
          setFlagTotal(response.data[0].Total.includes('-'))

        }
      })
      .catch(error => {

      })
  }

  const abrirCerrarModal = Modal => {
    if (Modal == 1) {
      if (!getmodalP == false) {
        Swal.fire({
          html: "¿Seguro que desea salir sin guardar los datos?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        }).then(result => {
          if (result.isConfirmed) {
            setModalP(!getmodalP)
          }
        })
      } else {
        setModalP(!getmodalP)
      }
    } else if (Modal == 2) {
      if (!getmodalE == false) {
        Swal.fire({
          html: "¿Seguro que desea salir sin guardar los datos?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        }).then(result => {
          if (result.isConfirmed) {
            setModalE(!getmodalE)
          }
        })
      } else {
        setModalE(!getmodalE)
      }
    }

  }

  const FuncionListarPermisos = async () => {
    var postData = {
      nUsu_ID: parseInt(getDecryptedItem("RolID"))
    }
    await instance
      .post("/Usuario/Acceso", postData)
      .then(response => {
        let arrayPermisos = response.data;
        const permiso = arrayPermisos.find(element => element.cUsu_Modulo == "ME03");

        if (permiso.bUsu_Editar == 1) {
          setHiddenBtnEditar(false);
          setModo(1);
        }
        else {
          setHiddenBtnEditar(true);
          setModo(2);
        }
        if (permiso.bUsu_Eliminar == 1) {
          setHiddenBtnEliminar(false);
        }
        else {
          setHiddenBtnEliminar(true);
        }
      })
      .catch(error => {

      })
  }

  const EditarCliente = (Row) => {

    if(hiddenBtnEditar == true){
      Swal.fire({
        title: "Mensaje",
        text: "Acceso denegado, no tiene permisos para editar el cliente",
        icon: "error",
        confirmButtonText: "Aceptar",
      })
      return;
    }

    let Tipo = Row[0].cCli_Tipo;
    let IdCliente = Row[0].nCli_Id;
    let Cliente = Row[0]

    console.log("Tipo : ", Tipo);
    console.log("IdCliente : ", IdCliente);
    console.log("Cliente : ", Cliente);

    setIdCliente(IdCliente);
    setSelectCliente(Cliente)
    if (Tipo == 'Persona') {
      abrirCerrarModal(1)
    } else {
      abrirCerrarModal(2)
    }
  }

  useEffect(() => {
    FuncionListarPermisos();
    var IDCLIENTE = parseInt(getDecryptedItem("CLIID"));
    console.log("IDCLIENTE : ", IDCLIENTE);
    fListarCuentaTotal(IDCLIENTE);

    FuncionListarEventos(IDCLIENTE)
    FuncionListarTareas(IDCLIENTE)
    FuncionListarCliente(IDCLIENTE)
    FuncionListarCasos(IDCLIENTE)
    FuncionListarEstadisticas1(IDCLIENTE)
    FuncionListarNotas(IDCLIENTE)
    FuncionListarMesesLaborados()
    SendAnios()
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Cliente | GpsLegal</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="GpsLegal"
            breadcrumbItem="Dashboard del Cliente"
          />


          <Row className="mb-3">
            <Col xl="9">
            </Col>
            <Col xl="1">
              <select
                className="form-select inputDown"
                name="M"
                onChange={handleChangeFiltro}
                value={getFiltroGrafico && getFiltroGrafico.M}
              >
                <option value="1">Enero</option>
                <option value="2">Febrero</option>
                <option value="3">Marzo</option>
                <option value="4">Abril</option>
                <option value="5">Mayo</option>
                <option value="6">Junio</option>
                <option value="7">Julio</option>
                <option value="8">Agosto</option>
                <option value="9">Setiembre</option>
                <option value="10">Octubre</option>
                <option value="11">Noviembre</option>
                <option value="12">Diciembre</option>
              </select>
            </Col>
            <Col xl="1">
              <select
                className="form-select inputDown"
                name="Y"
                onChange={handleChangeFiltro}
                value={getFiltroGrafico && getFiltroGrafico.Y}
              >
                {getAnioData.map((row, i) => (
                  <option key={i} value={row.anio}>
                    {row.anio}
                  </option>
                ))}
              </select>
            </Col>
            <Col xl="1">
              <button
                type="button"
                className="btn btn-danger waves-effect waves-light"
                onClick={() => BuscarFiltro()}
              >
                Filtrar
              </button>
            </Col>
          </Row>
          <Row>
            <Col xl="4">
              <Card className="cardBorder">
                <CardBody>
                  <div className="media">
                    <img
                      src={avatar}
                      alt=""
                      className="avatar-sm rounded-circle img-thumbnail"
                    />
                    <div className="media-body">
                      <div className="media mb-3 Texto-Limit">
                        <div className="media-body">
                          <div className="text-muted">

                            <p className="mb-0" style={{ fontSize: "15px", top: '-50px' }}>
                              {dataCliente.map((row, i) => (
                                row.cCli_NombreCompleto
                              ))}
                              <span style={{ float: "right" }} onClick={() =>
                                EditarCliente(dataCliente)
                              }>
                                <i style={{ fontSize: "40px", float: "left", cursor: 'pointer' }} className="icon-Editar"></i>
                              </span>
                            </p>
                            <h5 className="mb-1">
                              {dataCliente.map((row, i) => (
                                row.cCli_Tipo
                              ))}
                            </h5>


                          </div>

                        </div>
                      </div>
                      <hr />
                      <Row className="mb-3 Texto-Limit">
                        <Col xl={4}>
                          <div>
                            <p className="text-muted text-truncate mb-2">
                              Estado
                            </p>
                            <h5 className="mb-0">
                              {dataCliente.map((row, i) => (
                                row.nCli_Estado
                              ))}</h5>
                          </div>
                        </Col>
                        <div className="col-4">
                          <div>
                            <p className="text-muted text-truncate mb-2">
                              Relación
                            </p>
                            <h5 className="mb-0">Cliente</h5>
                          </div>
                        </div>
                      </Row>
                      <Row >
                        <Col xl={12} >
                          <div className="">
                            <p className="text-muted text-truncate mb-2">
                              <div className="row col-12">
                                <div className="col-4" style={{ width: '60px' }}>
                                  Telefono
                                </div>
                                <div className="col-4" style={{ width: '5px' }}>
                                  :
                                </div>
                                <div className="col-4" style={{ width: '50px' }}>
                                  {dataCliente.map((row, i) => (
                                    row.cCli_Fono1
                                  ))}
                                </div>
                              </div>

                            </p>
                            <p className="text-muted text-truncate mb-2">
                              <div className="row col-12">
                                <div className="col-4" style={{ width: '60px' }}>
                                  Correo
                                </div>
                                <div className="col-4" style={{ width: '5px' }}>
                                  :
                                </div>
                                <div className="col-4" style={{ width: '50px' }}>
                                  {dataCliente.map((row, i) => (
                                    row.cCli_Email1
                                  ))}
                                </div>
                              </div>
                            </p>
                            <p className="text-muted text-truncate mb-2">
                              <div className="row col-12">
                                <div className="col-4" style={{ width: '60px' }}>
                                  Dirección
                                </div>
                                <div className="col-4" style={{ width: '5px' }}>
                                  :
                                </div>
                                <div className="col-4" style={{ width: '50px' }}>
                                  {dataCliente.map((row, i) => (
                                    row.cCli_Dirección
                                  ))}
                                </div>
                              </div>

                            </p>
                            { }
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card className="cardBorder">
                <CardBody>
                  <h4 className="card-title mb-4">Casos activos</h4>
                  <div>
                    {dataCaso.slice(0, 10).map((row, i) => (
                      <Link style={{ color: "black" }} to="/dashboard-Casos">
                        <div className="row" key={i} onClick={() => setEncryptedItem('nCas_Id', row.nCas_Id)}>
                          <div className="col-md-2" style={{ width: "38px" }}>
                            <i className="icon-Documentos" style={{ color: "#1F60AB", fontSize: "25px", paddingTop: "5px" }} />
                            {" "}</div>
                          <div className="col-md-10">
                            <p className="text-truncate font-size-15 mb-1">
                              <b>CASO-{row.cCas_Cod_Externo}</b>
                            </p>
                            <p className="text-truncate font-size-11 mb-1">
                              Codigo interno: {row.cCas_Cod_Interno}
                            </p>
                            <p className="text-truncate font-size-11 mb-1">
                              {row.cCas_Titulo}
                            </p>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </CardBody>
              </Card>
              <Card className="cardBorder">
                <CardBody hidden>
                  <h4 className="card-title mb-4">Actividades</h4>
                  <div className="table-responsive mt-4">
                    <Table className="table align-middle mb-0">
                      <tbody>
                        <tr>
                          <td>
                            <i className="icon-libro" style={{ color: "#AA1B0E", fontSize: "25px" }} />
                          </td>
                          <td>
                            <p className="text-muted mb-1">
                              Alberto Rodriguez dejo una nota para Joel Aguilar
                              y Antonio Luis
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <FontAwesomeIcon
                              style={{ color: "#AA1B0E", fontSize: "25px" }}
                              icon={faBook}
                            />
                          </td>
                          <td>
                            <p className="text-muted mb-1">
                              Alberto Rodriguez completó la tarea de Escribir
                              carta notarial
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <FontAwesomeIcon
                              style={{ color: "#AA1B0E", fontSize: "25px" }}
                              icon={faBook}
                            />
                          </td>
                          <td>
                            <p className="text-muted mb-1">
                              Alberto Rodriguez subió el archivo 2020-01-01
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="5">
              <Card className="cardBorder">
                <CardBody>
                  <div className="clearfix">
                    <h4 className="card-title mb-4">
                      Próximos Eventos / Tareas
                    </h4>
                  </div>
                  <div className="table-responsive mt-4">
                    <h4 className="card-title mb-4">Tareas</h4>
                    <Table className="table align-middle mb-0 borderColorTable">
                      <tbody>
                        {dataTarea.slice(0, getCantidadTarea).map((row, i) => (
                          <tr key={i} onClick={() => abrirCerrarModalTable(2, row)} style={{ cursor: "pointer" }}>
                            <td>
                              {(() => {
                                if (row.cTar_Tab_Tipo == "TT01") {
                                  return (
                                    <i className="icon-tareas" style={{ color: "#1F60AB", fontSize: "25px", paddingTop: "5px" }} />
                                  )
                                } else if (row.cTar_Tab_Tipo == "TT02") {
                                  return (
                                    <i className="icon-tareas" style={{ color: "#00A94F", fontSize: "25px", paddingTop: "5px" }} />
                                  )
                                } else if (row.cTar_Tab_Tipo == "TT03") {
                                  return (
                                    <i className="icon-tareas" style={{ color: "#AA1B0E", fontSize: "25px", paddingTop: "5px" }} />
                                  )
                                } else if (row.cTar_Tab_Tipo == "TT04") {
                                  return (
                                    <i className="icon-tareas" style={{ color: "#AA1B0E", fontSize: "25px", paddingTop: "5px" }} />
                                  )
                                }
                              })()}
                            </td>
                            <td className="Texto-Limit">
                              <h5
                                style={{ color: "#1F60AB", width: '10px' }}
                                className="font-size-14 mb-1"
                              >
                                {row.cTar_Titulo}
                              </h5>
                              <p className="text-muted mb-0">
                                {row.cTar_Tab_Categoria2}
                              </p>
                              <p className="text-muted mb-0">
                              </p>
                            </td>
                            <td className="Texto-Limit" style={{ textAlign: 'right', paddingRight: '10px' }}>
                              <p className="mb-1 ">
                                {row.dTar_Fec_Vence2}
                              </p>
                              <p className="mb-1" style={{ textAlign: 'right', paddingRight: '2px' }}>
                                {row.tTar_Hora_Vence2}
                              </p>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div className="card-footer bg-transparent border-top">
                      <div className="text-center">
                        <button
                          onClick={() => setCantidadTarea(getCantidadTarea + 4)}
                          className="btn btn-secondary waves-effect waves-light"
                        >
                          {" "}
                          Cargar más
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive mt-4">
                    <h4 className="card-title mb-4">Eventos</h4>
                    <Table className="table align-middle mb-0 borderColorTable">
                      <tbody>
                        {dataEvento.slice(0, 4).map((row, i) => (

                          <tr key={i} onClick={() => abrirCerrarModalTable(1, row)} style={{ cursor: "pointer" }}>
                            <td>
                              {(() => {
                                if (row.cEve_Estado == "TT01") {
                                  return (
                                    <i className="icon-eventos2" style={{ color: "#032D5E", fontSize: "25px", paddingTop: "5px" }} />
                                  )
                                } else if (row.cEve_Estado == "TT02") {
                                  return (
                                    <i className="icon-eventos2" style={{ color: "#00A94F", fontSize: "25px", paddingTop: "5px" }} />
                                  )
                                } else if (row.cEve_Estado == "TT03") {
                                  return (
                                    <i className="icon-eventos2" style={{ color: "#AA1B0E", fontSize: "25px", paddingTop: "5px" }} />
                                  )
                                } else if (row.cEve_Estado == "TT04") {
                                  return (
                                    <i className="icon-eventos2" style={{ color: "#AA1B0E", fontSize: "25px", paddingTop: "5px" }} />
                                  )
                                }
                              })()}
                            </td>
                            <td className="Texto-Limit">
                              <h5
                                style={{ color: "#1F60AB", width: '140px' }}
                                className="font-size-14 mb-1"
                              >
                                {row.cEve_Titulo}
                              </h5>
                              <p className="font-size-14 mb-1" style={{ width: '140px' }}>
                                {row.cEve_Descripcion}
                              </p>
                              <p className="text-muted mb-0">
                              </p>
                            </td>
                            <td className="Texto-Limit" style={{ textAlign: 'right', paddingRight: '10px' }}>
                              <p className="mb-1 ">
                                {row.dEve_FechaIni2}
                              </p>
                              <p className="mb-1" style={{ textAlign: 'right', paddingRight: '2px' }}>
                                {row.tEve_HoraIni2}
                              </p>
                            </td>
                          </tr>

                        ))}
                      </tbody>
                    </Table>
                    <ComboState>
                      {(() => {
                        if (getModalEvento == true) {
                          return <>
                            {/* <ModalEvento show={getModalEvento}
                              onCloseClick={() => abrirCerrarModalTable(1)}    // Aqui se carga GetdefaultParticipantes
                              Condicion="1"
                              Row={getRow}
                              arrayParticipantesEventos={GetParticipantesEvento}
                              Id="1"
                              onCloseModal={() => { setModalEvento(!getModalEvento), FuncionListarEventos() }}
                            /> */}

                            
                             <ModalEvento show={getModalEvento}
                              onCloseClick={() => abrirCerrarModalTable(1)}
                              Condicion={modo}
                              modo={modo}
                              evento={getRow}
                              Row={getRow}
                              Id="1"
                              onCloseModal={() => { setModalEvento(!getModalEvento), FuncionListarEventos() }}
                              arrayParticipantesEventos={GetParticipantesEvento}
                        />
                          </>
                        }
                      })()}
                      {(() => {
                        if (getModalTarea == true) {
                          return <>
                            <ModalTarea show={getModalTarea}
                              onCloseClick={() => abrirCerrarModalTable(2)}
                              Condicion="1"
                              mode={modo}
                              Row={getRow}
                              arrayParticipantes={GetParticipantesTarea}
                              Id="1"
                              onCloseModal={() => { setModalTarea(!getModalTarea), FuncionListarTareas() }}
                            />
                          </>
                        }




                      })()}
                    </ComboState>
                    <div className="card-footer bg-transparent border-top">
                      <div className="text-center">
                        <button
                          onClick={() => setCantidadEventos(getCantidadEventos + 4)}
                          className="btn btn-secondary waves-effect waves-light"
                        >
                          {" "}
                          Cargar más
                        </button>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card className="cardBorder">
                <CardBody>
                  <div className="d-flex flex-wrap">
                    <div className="me-2">
                      <h5 className="card-title mb-3">Notas</h5>
                    </div>
                  </div>
                  <Col style={{ maxHeight: "310px" }}>
                    <div>
                      <input
                        type="text"
                        className="form-control" placeholder="Escribe un comentario"
                        onKeyPress={event => {
                          if (event.key === 'Enter') {
                            handleChangeNotas(event)
                            event.target.value = ""
                          }
                        }}
                      />
                      <ul className="list-group list-group-flush">
                        {getNotas.map((row, i) =>
                          <li className="list-group-item py-3">
                            <div className="media">
                              <div className="avatar-xs me-3">
                                <div className="avatar-title rounded-circle bg-light text-primary">
                                  <i className="icon-Persona"></i>
                                </div>
                              </div>
                              <div className="media-body">
                                <h5 style={{ fontWeight: "600" }} className="font-size-14 mb-1">
                                  {row.cUsu_Nombres}{" "}
                                  <small className="text-muted float-end">
                                    {moment(row.nNta_Fecha).format('MM/DD/YYYY')}
                                  </small>
                                </h5>
                                <p className="text-muted">
                                  {row.nNta_Nota}
                                </p>
                                <div>
                                  {
                                  /* <Link to="#" className="text-success">
                                     <i className="mdi mdi-reply me-1"></i>{" "}
                                     Responder
                                   </Link>*/}
                                </div>
                              </div>
                            </div>
                          </li>
                        )}
                      </ul>
                    </div>
                  </Col>
                </CardBody>
              </Card>
            </Col>
            <Col xl="3">
              <Card className="cardBorder">
                <CardBody>
                  <h4 className="card-title mb-4">Estadística de horas (hh:mm)</h4>
                  <div>
                    <div id="donut-chart" className="apex-charts">
                      <ReactApexChart
                        options={options}
                        series={Listar}
                        type="donut"
                        height={200}
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="row" style={{ paddingLeft: "4%" }}>
                      <div className="col-md-8 col-8">
                        <p className="text-truncate font-size-13 mb-1">
                          <i className="icon-esfera-2d text-success" />{" "}
                          Facturable
                        </p>
                      </div>
                      <div className="col-md-4 col-4" style={{ paddingRight: "10px", textAlign: 'right' }}><a style={{ float: "right" }}>{HorasLabHF}</a></div>
                    </div>
                    <div className="row " style={{ paddingLeft: "4%" }}>
                      <div className="col-md-8 col-8">
                        <p className="text-truncate font-size-13 mb-1">
                          <i className="col-md-12 icon-esfera-2d text-danger" />{" "}
                          No Facturable
                        </p>
                      </div>
                      <div className="col-md-4" style={{ paddingRight: "10px", textAlign: 'right' }}> <a style={{ float: "right" }}>{HorasLabHNF}</a></div>
                    </div>
                    <div className="row" style={{ paddingLeft: "4%" }}>
                      <div className="col-md-8 col-8">
                        <p className="text-truncate font-size-13 mb-1">{" "}
                          &nbsp;&nbsp;&nbsp;
                          Horas laboradas
                        </p>
                      </div>
                      <div className="col-md-4" style={{ paddingRight: "10px", textAlign: 'left' }}><a style={{ float: "right" }}>{HorasLabHL}</a></div>
                    </div>

                  </div>
                </CardBody>
              </Card>
              <Card className="cardBorder">
                <CardBody>
                  <h4 className="card-title mb-4">Saldo en caja para gastos</h4>
                  <div className="row" style={{ paddingLeft: "4%" }}>
                    <div className="col-md-6 col-6">
                      <p className="text-truncate font-size-13 mb-1">{" "}
                        &nbsp;&nbsp;&nbsp;
                      </p>
                    </div>
                    <div className="col-md-6" style={{ paddingRight: "10px", textAlign: 'left' }}><a style={{ float: "right", color: getFlagTotal ? '#AA1B0E' : '#00A94F' }}>{dataTotal[0].Total}</a></div>
                  </div>

                </CardBody>
              </Card>
              <Card className="cardBorder">
                <CardBody>
                  <div className="clearfix">
                    <h4 className="card-title mb-4"></h4>
                  </div>
                  <Row>
                    <Col lg="12">
                      <div id="line-chart" className="apex-charts" dir="ltr">
                        <ReactApexChart
                          series={series2}
                          options={options2}
                          type="line"
                          height={200}
                          className="apex-charts"
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="cardBorder">
                <CardBody hidden>
                  <div className="d-flex flex-wrap">
                    <h5 className="card-title mb-3 me-2">
                      Empleado / contacto relacionado
                    </h5>
                    <UncontrolledDropdown className="dropdown ms-auto dropdown-menu-end dropdown-menu">
                      <DropdownToggle
                        className="text-muted font-size-16"
                        color="white"
                        type="button"
                        tag="a"
                      >
                        <i className="mdi mdi-dots-horizontal"></i>
                      </DropdownToggle>
                      <DropdownMenu>
                        <Link className="dropdown-item" to="#">
                          Action
                        </Link>
                        <Link className="dropdown-item" to="#">
                          Another action
                        </Link>
                        <Link className="dropdown-item" to="#">
                          Something else
                        </Link>
                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" to="#">
                          Separated link
                        </Link>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                  <div className="d-flex flex-wrap">
                    <div>
                      <p className="text-muted mb-1">Juan Perez</p>
                      <p className="text-success mb-0">
                        <span>Senior response assistant</span>
                      </p>
                    </div>
                    <div className="ms-auto align-self-end">
                      <i className="bx bx-group display-4 text-light"></i>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ComboState>
            <ClienteState>
              {(() => {
                if (getmodalP == true) {
                  return <>
                    <ModalCliente show={getmodalP}
                      onCloseClick={() => abrirCerrarModal(1)}
                      Condicion="2"
                      Id={getIdCliente}
                      Row={getSelectCliente}
                      onCloseModal={() => { setModalP(!getmodalP), FuncionListarCliente(parseInt(getDecryptedItem("CLIID"))) }}
                    />
                  </>
                }
              })()}
            </ClienteState>
          </ComboState>

        </Container>
      </div>
    </React.Fragment>
  )
}
export default DashboardCliente
