import React, { Component, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTags from "react-meta-tags"
import { MDBDataTable } from "mdbreact"
import "../../assets/scss/tablescss/FormatTable.scss"
import Swal from "sweetalert2"
import Select from "react-select"
import {
    CardHeader,
    CardText,
    CardBody,
    Card,
    Col,
    Container,
    CardTitle,
    Button,
    Row,
    Modal,
    Title,
    Text,
    Form,
    FormGroup,
    TabContent,
    TabPane,
    FormText,
    Label,
    Input,
    CustomInput,
    CheckBox,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap"
import instance from '../../config'
import { post } from "helpers/api_helper"
import { getDecryptedItem } from "util"

const baseUrl = process.env.REACT_APP_API_URL
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
const Suscriptores = props => {
    const [GetParticipantes, setParticipantes] = useState([])
    const [GetUsuarios, setUsuarios] = useState([])
    const [modalInsertar, setModalInsertar] = useState(false)
    const [modalActualizar, setModalActualizar] = useState(false)
    const [modalFiltro, setModalFiltro] = useState(false)
    const [data, setData] = useState([
        { 'NombreFlujo': 'Proceso de Conocimiento', 'Descripcion': 'Proceso mas completo de larga duración y complejidad', 'Fases': '8', 'Eliminar': 'x' },
        { 'NombreFlujo': 'Proceso Abreviado ', 'Descripcion': 'Proceso mas corto', 'Fases': '6', 'Eliminar': 'x' },
        { 'NombreFlujo': 'Proceso Sumarísimo ', 'Descripcion': 'Proceso mas rápido', 'Fases': '3', 'Eliminar': 'x' },
        { 'NombreFlujo': 'Proceso Cautelar  ', 'Descripcion': 'Proceso para asegurar la sentencia', 'Fases': '1', 'Eliminar': 'x' },
        { 'NombreFlujo': 'Proceso de Ejecución', 'Descripcion': 'Proceso una vez se tiene sentencia firme', 'Fases': '2', 'Eliminar': 'x' },
    ])
    const [dataCaso, setDataCaso] = useState({})
    const [dataCategoria, setDataCategoria] = useState([])
    const [dataFormaPago, setDataFormaPago] = useState([])
    const [dataCanal, setDataCanal] = useState([])
    const [dataTipoPlan, setTipoPlan] = useState([])
    const [dataCategoriaTarea, setDataCategoriaTarea] = useState([])
    const [HiddenLista, setHiddenLista] = useState(true)
    const [dataLista, setDataLista] = useState([])
    const [subTitulo, setSubTitulo] = useState([])
    const [getFlujo, setFlujo] = useState({
        nfFas_Id: "",
        nfFlu_Id: "",
        cfFlu_Nombre: "",
        cfFlu_Descripcion: "",
        cfFas_Nombre: ""
    })
    const [getListas, setListas] = useState({
        nfList_Id: "",
        nfTar_Id: "",
        CfList_Nombre: "",
    })
    const [getTarea, setTarea] = useState({
        nfFas_Id: '',
        cfTar_Nombre: '',
        cfTar_Descripcion: '',
        cfTar_Tab_Categoria: '',
        nfTar_Duracion: '1',
        nfTar_TipoDias: '1',
        cfTar_Tab_Prioridad: '1',
        nfTar_Contado: '3',
        nfTar_Id: '',
    })
    const [getFiltro, setFiltro] = useState({
        FechaInicial: ano + "-" + "01" + "-" + "01",
        FechaFin: ano + "-" + mes + "-" + dia,
    })
    const FuncionListarSuscriptores = async () => {
        await instance
            .post("/Suscriptores/Listar")
            .then(response => {
                setData(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionBuscarPlan_PorTipoPlan = async (nPro_ID) => {
        var postData = {
            nPro_ID: nPro_ID
        }
        await instance
            .post("/Plan/PlanesBuscar_porTipoPlan", postData)
            .then(response => {

                setDataPlan(response.data)
            })
            .catch(error => {

            })
    }

    const handleChange = e => {
        const { name, value } = e.target
        setTarea(prevState => ({
            ...prevState,
            [name]: value,
        }))
        setListas(prevState => ({
            ...prevState,
            [name]: value,
        }))
    }

    const FuncionEliminarSuscriptores = async (_nSus_Id) => {
        var postData = {
            nSus_Id: Number.parseInt(_nSus_Id),
        }
        await instance
            .post("/Suscriptores/Eliminar", postData)
            .then(response => {
                FuncionListarSuscriptores()

                Swal.fire({
                    title: "Mensaje",
                    text: "Eliminado exitosamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })
            })
            .catch(error => {

            })
    }
    const Acciones = (opcion, lista) => {

        setListas(lista)
        if (opcion == 1) {
            setModalInsertar(!modalInsertar)
        }
        else if (opcion == 2) {
            //Procedimiento para aprobar la lista
        }

    }
    const datas = {
        columns: [
            {
                label: "Nombre ",
                field: "CfList_Nombre",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },

        ],
        rows: dataLista,
    }

    const badgesData = {
        columns: [
            ...datas.columns,
            {
                label: "Acciones",
                field: "acciones",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
        ],
        rows: [
            ...datas.rows.map((row, id) => ({
                ...row,
                acciones: (
                    <div>
                        <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="dropdown-toggle">
                                <i className="bx bx-dots-vertical-rounded" />
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem>
                                    <Link onClick={() => Acciones(1, row)}>
                                        Editar
                                    </Link>
                                </DropdownItem>

                                <DropdownItem
                                    onClick={() =>
                                        Swal.fire({
                                            title: "Mensaje",
                                            html:
                                                "¿Esta seguro de eliminar?",

                                            icon: "warning",
                                            showCancelButton: true,
                                            confirmButtonColor: "#3085d6",
                                            cancelButtonColor: "#d33",
                                            confirmButtonText: "Aceptar",
                                            cancelButtonText: "Cancelar",
                                        }).then(result => {
                                            if (result.isConfirmed) {

                                                FuncionEliminarLista(row.nfList_Id)
                                            }
                                        })
                                    }
                                >
                                    Rechazar
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                ),
            })),
        ],
    }

    const FuncionBuscarFlujo_nfFlu_Id = async () => {
        let _nfFas_Id = getDecryptedItem("nfFas_Id");
        var postData = {
            nfFas_Id: parseInt(_nfFas_Id)
        }

        await instance
            .post("/Fase/Obtener_nfFas_Id", postData)
            .then(response => {
                setFlujo(response.data[0])

            })
            .catch(error => {

            })
    }
    const FuncionListarComboCategoriaTarea = async () => {
        var postData = {
            Prefijo: "CT",
        }
        await instance
            .post("/Maestros/Data", postData)
            .then(response => {
                setDataCategoriaTarea(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionActualizarParticipantes = async (_nfTar_Id) => {
        var postData = {
            nfTar_Id: parseInt(_nfTar_Id)
        }
        await instance
            .post("/flujoTarea/FlujoEliminarTareasParticipantes", postData)
            .then(response => {
                const Participantes = GetParticipantes.map((row, i) => {
                    FuncionAgregarParticipantes(_nfTar_Id, row.value)
                })
            })
            .catch(error => {

            })
    }

    const FuncionAgregarTarea = async () => {
        var postData = {
            nfFas_Id: parseInt(getFlujo.nfFas_Id),
            cfTar_Nombre: getTarea.cfTar_Nombre,
            cfTar_Descripcion: getTarea.cfTar_Descripcion,
            cfTar_Tab_Categoria: getTarea.cfTar_Tab_Categoria,
            nfTar_Duracion: parseInt(getTarea.nfTar_Duracion),
            nfTar_TipoDias: parseInt(getTarea.nfTar_TipoDias),
            cfTar_Tab_Prioridad: getTarea.cfTar_Tab_Prioridad,
            nfTar_Contado: parseInt(getTarea.nfTar_Contado),
            nfTar_Id: ""
        }

        if (postData.nfFas_Id == "") {
            Swal.fire({
                title: "Mensaje",
                text: "Complete los registros",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        }
        else if (postData.cfTar_Nombre == undefined || postData.cfTar_Nombre == null || postData.cfTar_Nombre == "") {
            Swal.fire({
                title: "Mensaje",
                text: "Complete los registros",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        }
        else if (postData.cfTar_Descripcion == undefined || postData.cfTar_Descripcion == null || postData.cfTar_Descripcion == "") {
            Swal.fire({
                title: "Mensaje",
                text: "Complete los registros",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        }
        else if (postData.cfTar_Tab_Categoria == undefined || postData.cfTar_Tab_Categoria == null || postData.cfTar_Tab_Categoria == "") {
            Swal.fire({
                title: "Mensaje",
                text: "Complete los registros",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        }
        else if (postData.nfTar_Duracion == undefined || postData.nfTar_Duracion == null || postData.nfTar_Duracion == "") {
            Swal.fire({
                title: "Mensaje",
                text: "Complete los registros",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        }
        else if (postData.nfTar_TipoDias == undefined || postData.nfTar_TipoDias == null || postData.nfTar_TipoDias == "") {
            Swal.fire({
                title: "Mensaje",
                text: "Complete los registros",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        }
        else if (postData.cfTar_Tab_Prioridad == undefined || postData.cfTar_Tab_Prioridad == null || postData.cfTar_Tab_Prioridad == "") {
            Swal.fire({
                title: "Mensaje",
                text: "Complete los registros",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        }
        else if (postData.nfTar_Contado == undefined || postData.nfTar_Contado == null || postData.nfTar_Contado == "") {
            Swal.fire({
                title: "Mensaje",
                text: "Complete los registros",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        }
        else {

            let _nfTar_Id = getDecryptedItem("nfTar_Id");
            if (_nfTar_Id) {
                postData.nfTar_Id = parseInt(_nfTar_Id)
                await instance
                    .post("/flujoTarea/Actualizar", postData)
                    .then(response => {
                        FuncionActualizarParticipantes(_nfTar_Id);
                        Swal.fire({
                            title: "Mensaje",
                            text: "Agregado exitosamente",
                            icon: "success",
                            confirmButtonText: "Aceptar",
                        })

                        setTimeout(() => {
                            location.href = "/FlujosTarea";
                        }, 1000);

                    })
                    .catch(error => {

                    })
            }
            else {

                await instance
                    .post("/flujoTarea/Insertar", postData)
                    .then(response => {

                        let nfTar_Id = response.data[0].nfTar_Id;




                        const Participantes = GetParticipantes.map((row, i) => {
                            FuncionAgregarParticipantes(nfTar_Id, row.value)
                        })


                        Swal.fire({
                            title: "Mensaje",
                            text: "Agregado exitosamente",
                            icon: "success",
                            confirmButtonText: "Aceptar",
                        })

                        setTimeout(() => {
                            location.href = "/FlujosTarea";
                        }, 1000);

                    })
                    .catch(error => {

                    })
            }

        }
    }
    const FuncionAgregarParticipantes = async (_nTar_Id, _nUsu_ID) => {
        var postData = {
            nfTar_Id: Number.parseInt(_nTar_Id),
            nUsu_Id: Number.parseInt(_nUsu_ID),
        }

        await instance
            .post("/flujoTarea/FlujoTareaInsertarParticipantes", postData)
            .then(response => {

            })
            .catch(error => {

            })
    }

    const abrirCerrarModal = Modal => {
        if (Modal == 1) {

            if (!modalInsertar == false) {
                Swal.fire({
                    html: "¿Seguro que desea salir sin guardar los datos?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si",
                    cancelButtonText: "No",
                }).then(result => {
                    if (result.isConfirmed) {
                        setModalInsertar(!modalInsertar)
                        setListas({})
                    }
                })
            } else {
                setModalInsertar(!modalInsertar)
            }
        } else if (Modal == 2) {
            setModalActualizar(!modalActualizar)
        } else if (Modal == 3) {
            setModalFiltro(!modalFiltro)
        }
    }
    const FuncionBuscarFlujoTarea = async (_nfTar_Id) => {
        var postData = {
            nfTar_Id: parseInt(_nfTar_Id)
        }

        await instance
            .post("/flujoTarea/Obtener", postData)
            .then(response => {
                setTarea(response.data[0])

            })
            .catch(error => {

            })
    }
    const FuncionListarLista = async (_nfTar_Id) => {
        var postData = {
            nfTar_Id: parseInt(_nfTar_Id)
        }

        await instance
            .post("/flujoLista/Listar", postData)
            .then(response => {
                setDataLista(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionListarParticipantes = async (_nfTar_Id) => {
        var postData = {
            nfTar_Id: parseInt(_nfTar_Id)
        }

        await instance
            .post("/flujoTarea/FlujoTareaListarParticipantes", postData)
            .then(response => {

                setParticipantes(response.data)

            })
            .catch(error => {

            })
    }


    const FuncioninsertarLista = async () => {
        let _nfTar_Id = getDecryptedItem("nfTar_Id")
        var postData = {
            nfList_Id: parseInt(getListas.nfList_Id),
            CfList_Nombre: getListas.CfList_Nombre,
            nfTar_Id: _nfTar_Id
        }
        if (postData.CfList_Nombre == undefined || postData.CfList_Nombre == null || postData.CfList_Nombre == "") {
            Swal.fire({
                title: "Mensaje",
                text: "Ingrese un nombre",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        }
        else {
            if (postData.nfList_Id) {
                await instance
                    .post("/flujoLista/Actualizar", postData)
                    .then(response => {
                        FuncionListarLista(_nfTar_Id);

                        setModalInsertar(!modalInsertar)
                        setListas({})
                    })
                    .catch(error => {

                    })
            }
            else {
                await instance
                    .post("/flujoLista/Insertar", postData)
                    .then(response => {
                        FuncionListarLista(_nfTar_Id);

                        setModalInsertar(!modalInsertar)
                        setListas({})
                    })
                    .catch(error => {

                    })
            }
        }
    }
    const FuncionListarComboUsuario = async (nCas_Id) => {
        var postData = {

        }
        await instance
            .get("/Usuario/Listar", postData)
            .then(response => {

                setUsuarios(response.data)

            })
            .catch(error => {

            })
    }
    const UsuariosSelect = GetUsuarios.map((row, i) => ({
        value: row.nUsu_ID,
        label: row.cUsu_Nombres,
        email: row.cUsu_email,
        color: "#556ee6",
    }))
    const handleChangeParticipantes = value => {
        if (value == null) {
        } else {
            setParticipantes(value);

        }
    };

    const FuncionEliminarLista = async (_nfList_Id) => {
        var postData = {
            nfList_Id: parseInt(_nfList_Id)
        }

        await instance
            .post("/flujoLista/FlujoEliminarLista", postData)
            .then(response => {

                let _nfTar_Id = getDecryptedItem("nfTar_Id");
                FuncionListarLista(_nfTar_Id);

            })
            .catch(error => {

            })
    }
    const FuncionBuscarCasos = async () => {

        let _nCas_Id = getDecryptedItem("nCas_Id");

        var postData = {
            nCas_Id: _nCas_Id,
        }
        await instance
            .post("/Casos/Buscar", postData)
            .then(response => {
                setDataCaso(response.data[0]);


            })
            .catch(error => {

            })
    }

    useEffect(() => {
        FuncionBuscarCasos();
        let _nfTar_Id = 1;
        if (_nfTar_Id) {
            FuncionListarParticipantes(_nfTar_Id);
            FuncionBuscarFlujoTarea(_nfTar_Id);
            setHiddenLista(false)
            FuncionListarLista(_nfTar_Id);
        }
        FuncionListarComboUsuario();
        FuncionListarComboCategoriaTarea();
        FuncionBuscarFlujo_nfFlu_Id();



    }, [])
    return (
        <React.Fragment>
            <div className="page-content">
                {/* add meta title */}
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Row>
                        <Col className="col-12 row center">
                            <Col className="col-4">
                                <CardTitle className="h6" style={{ fontSize: "30px", fontSize: '25px' }}>
                                    Flujo de trabajo
                                </CardTitle>
                            </Col>
                            <Col className="col-7" style={{ textAlign: 'left' }}>
                                <CardTitle className="h6" style={{ fontSize: "30px", fontSize: '20px', textAlign: 'left' }}>
                                    {getFlujo.cfFlu_Nombre}
                                </CardTitle>
                            </Col>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '25px' }}>
                        <Col className="col-12 row center">
                            <Col className="col-8">
                                <CardTitle className="h6" style={{ fontSize: "20px", }}>
                                    Fase: {getFlujo.cfFas_Nombre}
                                </CardTitle>
                            </Col>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '25px' }}>
                        <Col className="col-12 row center">
                            <Col className="col-8">
                                <CardTitle className="h6" style={{ fontSize: "20px", }}>
                                    Caso: {dataCaso.cCas_Titulo1}
                                </CardTitle>
                            </Col>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '40px' }}>
                        <Col className="col-12 row" >
                            <Card className="col-12">
                                <CardBody>

                                    <Row>
                                        <Col className="col-12">
                                            <CardTitle className="h6" style={{ fontSize: "20px", }}>
                                                Nueva Tarea
                                            </CardTitle>
                                        </Col>
                                        <Col className="col-6 " style={{ marginTop: '20px' }}>
                                            <Col className="col-12 ">
                                                <Col lg="8">
                                                    <FormGroup className="mb-2">
                                                        <Label htmlFor="cardnumberInput">
                                                            Nombre de la Tarea
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="cfTar_Nombre"
                                                            placeholder="Ingrese el nombre"
                                                            name="cfTar_Nombre"
                                                            value={getTarea && getTarea.cfTar_Nombre}
                                                            onChange={handleChange}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="8">
                                                    <FormGroup className="mb-2">
                                                        <Label htmlFor="cardnumberInput">
                                                            Categorías
                                                        </Label>
                                                        <select
                                                            className="form-select"
                                                            name="cfTar_Tab_Categoria"
                                                            onChange={handleChange}
                                                            value={getTarea && getTarea.cfTar_Tab_Categoria}
                                                        >
                                                            <option value="0">Seleccionar...</option>
                                                            {dataCategoriaTarea.map((row, i) => (
                                                                <option key={i} value={row.cTabCodigo}>
                                                                    {row.cTabNombre}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </FormGroup>
                                                </Col>
                                                <Col className="mb-12 row">
                                                    <Col lg="2">
                                                        <FormGroup className="mb-2">
                                                            <Label htmlFor="cardnumberInput">
                                                                Duración
                                                            </Label>
                                                            <Input
                                                                type="text"
                                                                className="form-control"
                                                                id="nfTar_Duracion"
                                                                name="nfTar_Duracion"
                                                                value={getTarea && getTarea.nfTar_Duracion}
                                                                onChange={handleChange}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="4">
                                                        <FormGroup className="mb-2">
                                                            <Label htmlFor="cardnumberInput">
                                                                Dias
                                                            </Label>
                                                            <select
                                                                className="form-select"
                                                                name="nfTar_TipoDias"
                                                                onChange={handleChange}
                                                                value={getTarea && getTarea.nfTar_TipoDias}
                                                            >
                                                                <option value="0">Seleccionar...</option>
                                                                <option value="1">Dias calendario</option>
                                                                <option value="2">Dias utiles</option>
                                                            </select>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="4">
                                                        <FormGroup className="mb-2">
                                                            <Label htmlFor="cardnumberInput">
                                                                Prioridad
                                                            </Label>
                                                            <select
                                                                className="form-select"
                                                                name="cfTar_Tab_Prioridad"
                                                                onChange={handleChange}
                                                                value={getTarea && getTarea.cfTar_Tab_Prioridad}
                                                            >
                                                                <option value="0">Seleccionar...</option>
                                                                <option value="1">Alta</option>
                                                                <option value="2">Mediana</option>
                                                                <option value="3">Baja</option>
                                                                <option value="4">Sin Prioridad</option>
                                                            </select>
                                                        </FormGroup>
                                                    </Col>
                                                </Col>
                                                <Col className="mb-12 row">
                                                    <Col lg="5" style={{ marginTop: '10px' }}>
                                                        <FormGroup className="mb-2">
                                                            <Label htmlFor="cardnumberInput">
                                                                Contados desde:
                                                            </Label>
                                                            <select
                                                                className="form-select"
                                                                name="nfTar_Contado"
                                                                onChange={handleChange}
                                                                value={getTarea && getTarea.nfTar_Contado}
                                                            >
                                                                <option value="0">Seleccionar...</option>
                                                                <option value="1">Evento</option>
                                                                <option value="2">Terminar otra tarea</option>
                                                                <option value="3">Sin fecha</option>
                                                            </select>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="5" style={{ marginTop: '10px' }}>
                                                        <FormGroup className="mb-2">
                                                            <Label htmlFor="cardnumberInput">
                                                                Por determinar:
                                                            </Label>
                                                            <select
                                                                className="form-select"
                                                                name="cTar_Tab_Categoria"
                                                                onChange={handleChange}
                                                            >
                                                                <option value="0">Seleccionar...</option>
                                                                <option value="1">Inicio</option>
                                                                <option value="2">Finalizado</option>
                                                            </select>
                                                        </FormGroup>
                                                    </Col>
                                                </Col>

                                                <Col lg="4" style={{ marginTop: '10px' }}>
                                                    <FormGroup className="mb-2">
                                                        <Label htmlFor="cardnumberInput">
                                                            Alerta antes del vencimiento:
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="cSus_Nombres"
                                                            placeholder="Consultoria"
                                                            name="cSus_Nombres"
                                                            value={"1"}
                                                            style={{ textAlign: 'right' }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col className="col-12" style={{ marginTop: '20px' }}>
                                                    <Col lg="9">
                                                        <FormGroup className="mb-2">
                                                            <Label htmlFor="cardnumberInput">
                                                                Responsable Roles
                                                            </Label>
                                                            {" "}
                                                            <Select
                                                                isClearable
                                                                noOptionsMessage
                                                                name="nCli_Id"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Col>
                                                <Col className="mb-12 row" style={{ marginTop: '20px' }}>
                                                    <Col lg="6">
                                                        <FormGroup className="mb-2">
                                                            <Label htmlFor="cardnumberInput">
                                                                Transiciones
                                                            </Label>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup className="mb-2">
                                                            <button
                                                                type="button"
                                                                className="btn btn-danger waves-effect waves-light"
                                                                data-toggle="modal"
                                                                data-target="#myModal"
                                                            >
                                                                Añadir transición
                                                            </button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="9">
                                                        <FormGroup className="mb-2">
                                                            <Label htmlFor="cardnumberInput">
                                                                Fase
                                                            </Label>
                                                            <Input
                                                                type="text"
                                                                className="form-control"
                                                                id="cSus_Nombres"
                                                                placeholder="Consultoria"
                                                                name="cSus_Nombres"
                                                                value={"Admisión de la Demanda"}

                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col className="col-12">
                                                        <div style={{ paddingLeft: '0px' }}>
                                                            <button
                                                                type="button"
                                                                onClick={() => { FuncionAgregarTarea(), localStorage.removeItem('nfTar_Id') }}
                                                                className="btn btn-danger waves-effect waves-light"
                                                                data-toggle="modal"
                                                                data-target="#myModal"
                                                            >
                                                                Guardar
                                                            </button>
                                                            {" "}
                                                            <button
                                                                type="button"
                                                                onClick={() => { location.href = "FlujosTarea", localStorage.removeItem('nfTar_Id') }}
                                                                className="btn btn-danger waves-effect waves-light"
                                                                data-toggle="modal"
                                                                data-target="#myModal"
                                                            >
                                                                Retornar
                                                            </button>
                                                        </div>
                                                    </Col>

                                                </Col>
                                            </Col>
                                        </Col>
                                        <Col className="col-6" style={{ marginTop: '20px' }}>


                                            <FormGroup className="mb-2">
                                                <Label htmlFor="cardnumberInput">
                                                    Descripción
                                                </Label>
                                                <Input
                                                    type="textarea"
                                                    className="form-control"
                                                    id="cfTar_Descripcion"
                                                    name="cfTar_Descripcion"
                                                    placeholder="Ingrese la descripción"
                                                    onChange={handleChange}
                                                    value={getTarea && getTarea.cfTar_Descripcion}
                                                />
                                            </FormGroup>



                                            <Col className="col-12 row"
                                                style={{ marginTop: '20px', paddingRight: '0px' }}>
                                                <Col className="col-12 row" style={{ paddingRight: '0px' }}>
                                                    <FormGroup className="col-6" >
                                                        <Label htmlFor="cardnumberInput">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list-check" viewBox="0 0 16 16">
                                                                <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z" />
                                                            </svg> Listas
                                                        </Label>
                                                    </FormGroup>
                                                    <FormGroup className="col-6 " style={{ textAlign: 'right', paddingRight: '0px' }}>
                                                        <button
                                                            style={{ height: '30px', textAlign: 'right' }}
                                                            type="button"
                                                            onClick={() => abrirCerrarModal(1)}
                                                            className="btn btn-danger waves-effect waves-light"
                                                            data-toggle="modal"
                                                            data-target="#myModal"
                                                        >
                                                            Nueva
                                                        </button>
                                                    </FormGroup>
                                                </Col>
                                            </Col>
                                            <CardBody style={{ paddingLeft: '0px', paddingBottom: '0px' }} >

                                                <MDBDataTable
                                                    data={badgesData}
                                                    className="TablaListas"
                                                    responsiveXl
                                                    fixed
                                                    hover
                                                    entriesLabel="Mostrar"
                                                    entrylabel={"Mostrar entradas"}
                                                    infoLabel={["Mostrando ", "a", "de", "registros"]}
                                                    bordered
                                                    order={["age", "desc"]}
                                                    noRecordsFoundLabel={"No hay datos"}
                                                    searchLabel={"Buscar"}
                                                    noBottomColumns
                                                    paginationLabel={["Anterior", "Siguiente"]}
                                                    theadColor="HeadBlue"
                                                />
                                            </CardBody>
                                            <Col lg="9">
                                                <FormGroup className="mb-2">
                                                    <Label htmlFor="cardnumberInput">
                                                        Responsables Usuarios
                                                    </Label>
                                                    <Select
                                                        isMulti
                                                        onChange={handleChangeParticipantes}
                                                        name="colors"
                                                        options={UsuariosSelect}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        placeholder="Usuarios responsables..."
                                                        value={GetParticipantes}
                                                    />
                                                </FormGroup>
                                            </Col>


                                            <Col lg="9">
                                                <FormGroup className="mb-2">
                                                    <Label htmlFor="cardnumberInput">
                                                        Trigger
                                                    </Label>
                                                    {" "}
                                                    <Select
                                                        isClearable
                                                        noOptionsMessage
                                                        name="nCli_Id"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Modal size="l" isOpen={modalInsertar}>
                        <div className="modal-header ModalHeaderColor">
                            <h5
                                style={{ color: "#fff" }}
                                className="modal-title mt-0"
                                id="myModalLabel"
                            >
                                Nueva Lista
                            </h5>
                            <button
                                type="button"
                                onClick={() => abrirCerrarModal(1)}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3 row">
                                <div className="col-md-12">
                                    <input
                                        disabled
                                        className="form-control inputDown"
                                        type="text"
                                        placeholder="Nombre de la tarea"
                                        name="cfFas_Nombre"
                                        value={getTarea && getTarea.cfTar_Nombre}
                                    />
                                    <label className="col-form-label labelDown">
                                        Tarea
                                    </label>
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <div className="col-md-12">
                                    <input
                                        className="form-control inputDown"
                                        type="text"
                                        placeholder="Ingrese el nombre de la lista"
                                        name="CfList_Nombre"
                                        value={getListas && getListas.CfList_Nombre}
                                        onChange={handleChange}
                                    />
                                    <label className="col-form-label labelDown">
                                        Nombre
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={() => abrirCerrarModal(1)}
                                className="btn btn-secondary waves-effect"
                                data-dismiss="modal"
                            >
                                Cerrar
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger waves-effect waves-light"
                                onClick={() => FuncioninsertarLista()}
                            >
                                Guardar
                            </button>
                        </div>

                    </Modal>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default Suscriptores