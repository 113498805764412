import React, { useState, useEffect, useContext } from "react"
import {
    Col,
    Row,
    Card,
    CardBody,
    DropdownItem,
    DropdownMenu,
    UncontrolledDropdown,
    CardTitle,
    DropdownToggle,
    Modal,
} from "reactstrap"
import { getDecryptedItem, setEncryptedItem } from '../../../../util';
import moment from "moment"
import ComboContext from "pages/Menus/Context/Combos/ComboContext"
import instance from '../../../../config'
import CasoContext from "pages/Menus/Context/Casos/CasoContext"
import { Link } from "react-router-dom";
import Swal from "sweetalert2"
import ModalCasoU from "./ModalCasoU";
import Select from "react-select"
import authSaga from "store/auth/login/saga";
import { method } from "lodash"
import ReactTooltip from "react-tooltip"
import LetteredAvatar from 'react-lettered-avatar';
import CasoState from "pages/Menus/Context/Casos/CasoState"
import ModalCaso from "./ModalCaso"
import ComboState from "pages/Menus/Context/Combos/ComboState"
import ModalCasoF from "./ModalCasoF"
import { useHistory } from "react-router-dom"
import ReactHtmlTableToExcel from "react-html-table-to-excel"
import ReactExport from "react-export-excel";
import { MDBDataTable } from "mdbreact"
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var meses = new Array("Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre");
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var mesIni = Fecha.getMonth() - 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
if (mesIni < 10) mesIni = "0" + mesIni //agrega cero si el menor de 10
const baseUrl = process.env.REACT_APP_API_URL
var arrayParticipantes = [];
var tipoParticipante = "";
var horaFin = Fecha.getHours() + 1 + ':' + '00';
const ListaCaso = props => {
    let history = useHistory()
    var cantidadCasos = 0;
    const casoContext = useContext(CasoContext);
    const [estadoInterno, SetEstadoInterno] = useState([])
    const [idEQParticipantes, SetidEQParticipantes] = useState(0)
    const [comboTipoParticipante, SetcomboTipoParticipante] = useState("")

    const [GetParticipantes, setParticipantes] = useState([])
    const [GetParticipantesExist, setParticipantesExist] = useState([])
    const [arrayCasos, setDataArrayCaso] = useState([])
    const [GetParticipantesA, setParticipantesA] = useState([])
    const [GetParticipantesAR, setParticipantesAR] = useState([])
    const [GetParticipantesP, setParticipantesP] = useState([])
    const [GetParticipantesUAD, setParticipantesUAD] = useState([])
    const [GetParticipantesALL, setParticipantesALL] = useState([])
    const [UsuariosEquipo, setUsuariosEquipo] = useState([])
    const arrayWithColors = ['#ff7f50', '#87cefa', '#da70d6', '#32cd32', '#6495ed',
        '#ff69b4', '#ba55d3', '#cd5c5c', '#ffa500', '#40e0d0'];
    const [getCasos, setCasos] = useState({
        nCas_Id: "",
        nCli_Id: "",
        nEqu_ID: 1,
        cCas_Tab_Tipo: "CA01",
        cCas_Titulo: "",
        cCas_Cod_Externo: "",
        cCas_Cod_Interno: "2021-0008",
        cCas_Recurso_Num: "0203",
        cCas_Detalle: "",
        cCas_Expediente: "",
        nCas_Exp_Year: ano,
        cCas_Tab_Materia: "NN00",
        cCas_Tab_SubMateria: "NN00",
        nJuz_Id: "NN00",
        cCas_Tab_Distrito_Jud: "NN00",

        cCas_Tab_Jurisdiccion: "JU01",//Falta
        cCas_Tab_Sala: "SA01",//Falta
        cCas_Tab_Recurso_Tipo: "HA01",//Falta
        cCas_Tab_Comision: "CP01", //Falta
        cTab_Lugar_Tramite: "WE01",   //Falta
        cTab_Tipo_Acto: "0000", //Falta
        nPer_Id: 1,
        cCas_Referencia: "",
        cCas_Denominacion: "",
        cUbi_Org: "",
        dCas_Fec_Ini_Interno: ano + "-" + mes + "-" + dia,
        dCas_Fec_Ini_Procesal: ano + "-" + mes + "-" + dia,
        cCas_Tab_Estado_Externo: "",
        dCas_Fec_Estima_Cierre: moment(Fecha).format('yyyy-MM-DD'),
        cTab_Estado_Interno: "",
        dFas_Fec_Cierre: "",
        nUsu_ID: getDecryptedItem("ID"),
        dUsu_Fecha: ano + "-" + mes + "-" + dia,
        bCas_Estado: 1,
        cTab_Moneda: "",
        nCas_Monto: "",
        cTab_Tipo_Monto: "",
        nCas_Origen: 0,
        TEXPEDIENTE: 0,
        LTRAMITE: 0,
        nUsu_ID2: "",
    })
    const comboContext = useContext(ComboContext);
    const [modalInsertar, setModalInsertar] = useState(false)
    const [modalActualizar, setModalActualizar] = useState(false)
    const [modalFiltro, setModalFiltro] = useState(false)
    const [modalCambiarEstado, setModalCambiarEstado] = useState(false)
    const [modalAddParticipantes, setModalAddParticipantes] = useState(false)
    const [modalRmParticipantes, setModalRmParticipantes] = useState(false)
    const [modalAddEquipo, setModalAddEquipo] = useState(false)
    const [modalTarea, setModalTarea] = useState(false)
    const [dataCodInterno, setdataCodInterno] = useState("")
    const [dataCodExterno, setdataCodExterno] = useState("")
    const [dataEstadoTarea, setDataEstadoTarea] = useState([])
    const [dataCategoriaTarea, setDataCategoriaTarea] = useState([])
    const [dataRecordarTarea, setDataRecordarTarea] = useState([])
    const [dataTipoTarea, setDataTipoTarea] = useState([])
    const [getDefaultCliente, setDefaultCliente] = useState({})
    const [getDefaultOrgano, setDefaultOrgano] = useState({})
    const [hiddenBtnEditar, setHiddenBtnEditar] = useState(true)
    const [hiddenBtnEliminar, setHiddenBtnEliminar] = useState(true)
    const [dataLista, setDatalista] = useState([])
    const [dataCaso, setDataCaso] = useState([])
    const [getListas, setListas] = useState({
        nLis_Id: null,
        nTar_Id: "",
        cLis_Nombre: "",
        nLis_Estado: ""
    })
    const [modalInsertarlista, setModalInsertarlista] = useState(false)
    const [ttlModalLista, setModalLista] = useState("Lista :")

    const [getTareas, setTareas] = useState({
        nTar_Id: "",
        nCli_Id: getDecryptedItem("CLIID"),
        nCas_Id: getDecryptedItem('nCas_Id'),
        cTar_Tab_Tipo: "TT01",
        cTar_Tab_Categoria: "NN00",
        cTar_Titulo: "",
        nUsu_Asignado: getDecryptedItem("ID"),
        nUsu_Relacionado: getDecryptedItem("ID"),
        dTar_Fec_Vence: ano + "-" + mes + "-" + dia,
        tTar_Hora_Vence: horaFin,
        cTar_tab_recordar: "PE07",
        bTar_Estado: "1",
        cEstadoTarea: "MT01",
        nTar_Prioridad: 4,
        nTar_Duracion: '1',
        nTar_TipoDias: '1',
        nfFas_Id: 0,
    })

    const [dataComboUsuario, setDataComboUsuario] = useState([])
    const [checked, setStateChecked] = useState(false)
    const [getErrores, setErrores] = useState({});
    const [validData, setValidData] = useState(false)

    const [getFiltro, setFiltro] = useState({
        nCli_Id: null,
        cCas_Tab_Materia: null,
        cCas_Tab_SubMateria: null,
        bCas_Estado: null,
        nUsu_ID: getDecryptedItem("ID"),
        FechaInicial: ano + "-" + "01" + "-" + "01",
        FechaFin: ano + "-" + mes + "-" + dia,
    })

    const handleChangeFiltroNExpediente = e => {
        e.target.onkeydown = function (event) {
            if (event.keyCode == 13) { // 13 es el código de tecla para Enter
                console.log(e.target.value)
                if (e.target.value.length > 0) {
                    casoContext.FuncionFiltroCasosExpediente(casoContext.bkcasos.filter(DatosAdd =>
                        DatosAdd.cCas_Cod_Externo.includes(e.target.value) || DatosAdd.cCli_NombreCompleto.toLowerCase().includes(e.target.value.toLowerCase()) ||
                        DatosAdd.cCas_Titulo.toLowerCase().includes(e.target.value.toLowerCase()) ||
                        DatosAdd.cCas_Titulo1.toLowerCase().includes(e.target.value.toLowerCase())
                    ))
                } else {
                    casoContext.FuncionFiltroCasos(getFiltro)
                }
            }
        };
    }

    /** Validacion  */
    const Validacion = (form) => {
        let errors = {};
        let regexLetras = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
        let regexCorreo = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
        let regexNumero = /^[0-9]+$/;



        if (form.nCas_Id == 0) {
            errors.nCas_Id = "(*)"
        }

        if (form.cTar_Tab_Categoria == 0 || form.cTar_Tab_Categoria == "NN00") {
            errors.cTar_Tab_Categoria = "(*)"
        }

        if (!form.cTar_Titulo.trim()) {
            errors.cTar_Titulo = "(*)"
        }

        if (form.cTar_tab_recordar == 0) {
            errors.cTar_tab_recordar = "(*)"
        }

        if (form.cTar_Tab_Tipo == 0) {
            errors.cTar_Tab_Tipo = "(*)"
        }

        return errors;
    }
    const handleBlur = e => {
        handleChange(e)
        const { name, value } = e.target;
        if (name == "tTar_Hora_Vence") {
            if (value.length == 0) {
                const now = new Date(); // Obtener la fecha y hora actual
                let hours = now.getHours(); // Obtener solo la hora actual


                if (hours < 10) {
                    hours = "0" + hours
                }
                const newValue = hours + ":00";

                setTareas((prevState) => ({
                    ...prevState,
                    [name]: newValue,
                }));
            }
        }
        setErrores(Validacion(getTareas))

    }
    /************************************* */
    const FuncionListarEquipoAbogadoResponsable = async (_nCas_Id) => {
        var postData = {
            nCas_Id: _nCas_Id,
            Tipo: "UC01",
        }
        await instance
            .post("/Casos/Equipo", postData)
            .then(response => {
                setParticipantesAR(response.data)
            })
            .catch(error => {

            })
    }
    const FuncionListarEquipoAbogado = async (_nCas_Id) => {
        var postData = {
            nCas_Id: _nCas_Id,
            Tipo: "UC02"
        }
        await instance
            .post("/Casos/Equipo", postData)
            .then(response => {
                setParticipantesA(response.data)
            })
            .catch(error => {

            })
    }
    const FuncionListarEquipoPracticante = async (_nCas_Id) => {
        var postData = {
            nCas_Id: _nCas_Id,
            Tipo: "UC03"
        }
        await instance
            .post("/Casos/Equipo", postData)
            .then(response => {
                setParticipantesP(response.data)
            })
            .catch(error => {

            })
    }
    const FuncionListarEquipoUsuarioAdd = async (_nCas_Id) => {
        var postData = {
            nCas_Id: _nCas_Id,
            Tipo: "UC04"
        }
        await instance
            .post("/Casos/Equipo", postData)
            .then(response => {
                setParticipantesUAD(response.data)
            })
            .catch(error => {

            })
    }
    const abrirCerrarModal = (Modal, Row) => {
        if (Modal == 1) {
            if (!modalInsertar == false) {
                Swal.fire({
                    html: "¿Seguro que desea salir sin guardar los datos?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si",
                    cancelButtonText: "No",
                }).then(result => {
                    if (result.isConfirmed) {
                        setModalInsertar(!modalInsertar)
                    }
                })
            } else {
                setModalInsertar(!modalInsertar)
                setEncryptedItem('temp_nCas_Id', Row);
            }
        } else if (Modal == 2) {
            if (!modalActualizar == false) {
                setModalActualizar(!modalActualizar)
                FuncionListarEquipo()
            } else {
                Swal.fire({
                    html:
                        '<img id="imgr1" className="imgr" src="/static/media/logo-dark.7a9779c2.png" style="width:50px;height:auto;-webkit-animation: 3s rotate linear infinite;' +
                        'animation: 3s rotate linear infinite;' +
                        '-webkit-transform-origin: 50% 50%;' +
                        'transform-origin: 50% 50%;" /> ' +
                        '<br/><br/> Cargando...',
                    showConfirmButton: false,
                    allowOutsideClick: false
                });
                setCasos(Row)
                FuncionListarEquipoAbogado(Row.nCas_Id)
                FuncionListarEquipoAbogadoResponsable(Row.nCas_Id)
                FuncionListarEquipoPracticante(Row.nCas_Id)
                FuncionListarEquipoUsuarioAdd(Row.nCas_Id)
                setDefaultCliente({ label: Row.cCli_NombreCompleto, value: Row.nCli_Id })
                setDefaultOrgano({ label: Row.cCas_Tab_Distrito_Jud2, value: Row.cCas_Tab_Distrito_Jud })
                setTimeout(() => {
                    setModalActualizar(!modalActualizar)
                    Swal.close();
                }, 2000);
            }
        } else if (Modal == 4) {
            setModalFiltro(!modalFiltro)
        } else if (Modal == 5) {

            if (modalCambiarEstado == true) {
                DeseleccionarTodo();
            }
            setModalCambiarEstado(!modalCambiarEstado)
        }
        else if (Modal == 6) {

            if (modalAddParticipantes == true) {
                DeseleccionarTodo();
            }
            setModalAddParticipantes(!modalAddParticipantes)
        }
        else if (Modal == 7) {

            if (modalAddParticipantes == true) {
                DeseleccionarTodo();
            }
            setModalRmParticipantes(!modalRmParticipantes)
        }
        else if (Modal == 8) {

            if (modalAddEquipo == true) {
                setModalAddEquipo(!modalAddEquipo)
                DeseleccionarTodo();
            }
            else {
                Swal.fire({
                    html: "La asignación de un equipo reemplazara las personas asignadas ¿ Continuar ?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si",
                    cancelButtonText: "No",
                }).then(async result => {
                    if (result.isConfirmed) {
                        setModalAddEquipo(!modalAddEquipo)
                    }
                    else {
                    }
                })
            }

        }
        else if (Modal == 9) {
        }
        else if (Modal == 10) {
            setErrores(Validacion(getTareas))

            if (modalTarea == true) {
                DeseleccionarTodo();
            }
            setModalTarea(!modalTarea)
        }
    }
    const FuncionEliminarCaso = async (_nCas_Id) => {
        var postData = {
            nCas_Id: _nCas_Id,
        }
        await instance
            .post("/Casos/Eliminar", postData)
            .then(response => {
                casoContext.FuncionListarCasos()

                Swal.fire({
                    title: "Mensaje",
                    text: "Eliminado exitosamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })
            })
            .catch(error => {

            })
    }
    let ArregloConsultaMasiva = []
    const ConsultasMasivas = async param1 => {
        let locationData = {
            nCas_Id: param1,
        }
        var elem = document.getElementById("id" + param1)
        if (elem.checked) {
            ArregloConsultaMasiva.push(locationData)

        } else {
            var indice = ArregloConsultaMasiva.findIndex(
                nCas_Id => nCas_Id.nCas_Id == param1
            )

            ArregloConsultaMasiva.splice(indice, 1)

        }
    }
    const FuncionActivarMasiva = async () => {
        ArregloConsultaMasiva.map((row, id) => FuncionFuncionMasiva(row.nCas_Id))
    }
    const SeleccionarTodo = async () => {
        var elem = document.getElementById("selecionarMasivo");
        var i;
        if (elem.checked) {

            for (i = 0; i < document.f1.elements.length; i++)
                if (document.f1.elements[i].type == "checkbox")
                    document.f1.elements[i].checked = 1;
        } else {

            for (i = 0; i < document.f1.elements.length; i++)
                if (document.f1.elements[i].type == "checkbox")
                    document.f1.elements[i].checked = 0;
            arrayCasos = []; // <-- Agregar esta línea para establecer arrayCasos como vacío
        }
        setDataArrayCaso(casoContext.casos)
    };
    const DeseleccionarTodo = async () => {
        setDataArrayCaso([])
        var elem = document.getElementsByName("dinamico")
        var i
        for (i = 0; i < document.f1.elements.length; i++)
            if (document.f1.elements[i].type == "checkbox")
                document.f1.elements[i].checked = 0
    }
    const SelectDetalle = async (nCas_Id, nCli_Id, nCas_Origen) => {
        Swal.fire({
            html:
                '<img id="imgr1" className="imgr" src="/static/media/logo-dark.7a9779c2.png" style="width:50px;height:auto;-webkit-animation: 3s rotate linear infinite;' +
                'animation: 3s rotate linear infinite;' +
                '-webkit-transform-origin: 50% 50%;' +
                'transform-origin: 50% 50%;" /> ' +
                '<br/><br/> Cargando...',
            showConfirmButton: false,
            allowOutsideClick: false
        });
        setEncryptedItem('nCas_Id', nCas_Id);
        setEncryptedItem('CLIID', nCli_Id);
        history.push("/dashboard-Casos")
    }
    const FuncionListarAdicional = async (_ID, _CodExt, _Array, _Array2) => {
        var postData = {
            id: _ID,
        }
        await instance
            .post("/Dashboard/Casos/DataCEJ", postData)
            .then(async response => {

                if (response.data[0].DataMovimienntos.length > 0) {
                    var Movimientos = JSON.parse(response.data[0].DataMovimienntos).filter(DatosAdd => DatosAdd.CodigoExterno === _CodExt)
                    var Notificaciones = JSON.parse(response.data[0].DataNotificacion).filter(DatosAdd => DatosAdd.CodigoExterno === _CodExt)
                    var Json1 = Movimientos.splice(0, 4)
                    var Json2 = JSON.parse(JSON.stringify(_Array).replace(/\//g, '').toString())
                    var Json3 = Notificaciones
                    var Json4 = JSON.parse(JSON.stringify(_Array2).replace(/\//g, '').toString()).splice(0, 4)


                    var JsonNovedad = []
                    var JsonNotif = []
                    var encontrado = false;
                    var i = 0;
                    for (i = 0; i < 4; i++) {
                        encontrado = false;
                        if (Json1[i].Acto != Json2[i].Acto) {
                            if (Json1[i].Resolucion != Json2[i].Resolucion) {
                                if (Json1[i].Proveido != Json2[i].Proveido) {

                                    JsonNovedad.push(Json2[i])
                                }
                            } else {
                                if (Json1[i].Proveido != Json2[i].Proveido) {

                                    JsonNovedad.push(Json2[i])
                                }
                            }
                        } else {
                            if (Json1[i].Resolucion != Json2[i].Resolucion) {
                                if (Json1[i].Proveido != Json2[i].Proveido) {

                                    JsonNovedad.push(Json2[i])
                                }
                            } else {
                                if (Json1[i].Proveido != Json2[i].Proveido) {

                                    JsonNovedad.push(Json2[i])
                                }
                            }
                        }
                    }
                    /*** Json 1 itera primero */
                    var i2 = 0
                    for (i2 = 0; i2 < 4; i2++) {
                        if (Json3.filter(DatosAdd => DatosAdd.titulo === Json4[i2].titulo).length == 0) {
                            JsonNotif.push(Json4[i2])
                        }
                    }


                    if (i == Json1.length || i2 == Json3.length) {
                        let objJsonNovedad = {
                            nCas_Id: _ID,
                            JsonNovedad: JsonNovedad,
                            JsonNotif: JsonNotif,
                            CodExt: _CodExt
                        }
                        await instance
                            .post("/Dashboard/Casos/NovedadesMail", objJsonNovedad)
                            .then(response => {
                                let objJsonNovedadI = {
                                    nCas_Id: _ID,
                                    cNov_Movimiento: JsonNovedad,
                                    cNov_Notificacion: JsonNotif,
                                }
                                instance
                                    .post("/Dashboard/Casos/Novedades/Insertar", objJsonNovedadI)
                                    .then(response => {
                                    })
                            })
                    }
                }
            })
            .catch(error => {

            })


    }
    const FuncionRefrescarCEJ = async (_nCas_Id, _cCas_Cod_Externo) => {
        var postData = {
            CodigoExterno: _cCas_Cod_Externo,
            cFecha: Fecha.getDate() + " de " + meses[Fecha.getMonth()] + " de " + Fecha.getFullYear(),
        }
        await instance
            .post("/Vincular/CEJ", postData)
            .then(response => {
                //   FuncionListarAdicional(_nCas_Id, _cCas_Cod_Externo, response.data.DataMovimienntos.splice(0, 4), response.data.DataNotificacion.splice(0, 16))
                FuncionInsertarScraping(_nCas_Id, response.data.DataCuadernos, response.data.DataGneral, response.data.DataMovimienntos, response.data.DataNotificacion, response.data.DataResoluciones)
            })
            .catch(error => {
                Swal.fire({
                    title: "Mensaje",
                    text: error,
                    icon: "error",
                    confirmButtonText: "Aceptar",
                })
            })
    }
    /**  Funcion de interactividad del boton "acciones masivas" */
    function myFunction() {
        //Añade una clase al elemento que tenga el id myDropdown
        document.getElementById("myDropdown").classList.toggle("show");
    }
    window.onclick = function (event) {
        if (!event.target.matches('.drop-button')) {
            var dropdowns = document.getElementsByClassName("dropdown-content");
            var i;
            for (i = 0; i < dropdowns.length; i++) {
                var openDropdown = dropdowns[i];
                //Busca dentro de drop-content los elementos con la clase show
                if (openDropdown.classList.contains('show')) {
                    //elimina la clase show de los elementos dentro de drop-content
                    openDropdown.classList.remove('show');
                }
            }
        }
    }
    /** Funciones para cambiar el estado interno del caso *************************** */
    const SelectCheckbox = async (caso, e) => {
        if (e.target.checked) {
            let data = arrayCasos.concat(caso)
            setDataArrayCaso(data)
        } else {
            let data = arrayCasos.filter(e => e.nCas_Id !== caso.nCas_Id)
            setDataArrayCaso(data)
        }
    }
    const handleChangeEstadointerno = e => {

        SetEstadoInterno(e.target.value);
    }
    const handleChangeTipoParticipante = e => {
        SetcomboTipoParticipante(e.target.value);

    }
    const handleChangeParticipantes = e => {

        setParticipantes(e)
        if (comboTipoParticipante == "UC01") {
            setParticipantes(e)
        }
        else if (comboTipoParticipante == "UC02") {
            setParticipantes(e)
        }
        else if (comboTipoParticipante == "UC03") {
            setParticipantes(e)
        }
        else if (comboTipoParticipante == "UC04") {
            setParticipantes(e)
        }
    }
    const methodCambiarEstado = async () => {
        let postData = {
            _arrayCasos: arrayCasos,
            _estadoInterno: estadoInterno,
            _nUsu_ID: getDecryptedItem("ID"),
        }


        // if(estadoInterno=='EP05'){
        //     let dataFilter=arrayCasos.filter(e=>e.cTab_Estado_Interno!=='EP03')
        //     let dataFilter2=arrayCasos.filter(e=>e.cTab_Estado_Interno=='EP03')
        //     let cantidadCasosSelect=arrayCasos.length
        //     let cantidadNoEliminados=dataFilter.length
        //     let cantidadEliminados=cantidadCasosSelect-cantidadNoEliminados
        //     console.log(dataFilter)
        //     console.log(cantidadCasosSelect)
        //     console.log(cantidadNoEliminados)
        //     console.log(cantidadEliminados)
        //     postData._arrayCasos=dataFilter2
        // }

        console.log(postData)

        await instance
            .post("/Casos/MasivoEditarCaso", postData)
            .then(response => {

                console.log(response.data);
                let count = response.data.count

                let texto = "";
                let registro = "registro";
                let nota = "";
                if (arrayCasos.length > 1) { registro = "registros" }
                if (count == 1) {
                    texto = `${count} caso editado de ${arrayCasos.length} seleccionado(s)`
                }
                else {
                    texto = `${count} casos editados de ${arrayCasos.length} seleccionado(s)`
                }
                if (arrayCasos.length != count) {
                    nota = "Nota : Los casos que no fueron editados contienen una liquidación pendiente o no se puede archivar un caso que no se encuentra cerrado"
                }

                casoContext.FuncionListarCasos();
                FuncionListarEquipo();
                Swal.fire({
                    title: "Mensaje",
                    html: `${texto}<br><br> <b>${nota}</b>`,
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })
                DeseleccionarTodo();
                setModalCambiarEstado(!modalCambiarEstado);
            })
            .catch(error => {

            })

    }

    /** Funciones para cambiar participantes del caso ***** */
    const FuncionAgregarCasoEquipo = async (_nCas_Id, _nUsu_Id, _cCas_Tab_TipoEquipo) => {
        var postData = {
            nCas_Id: _nCas_Id,
            nUsu_Id: _nUsu_Id,
            cCas_Tab_TipoEquipo: _cCas_Tab_TipoEquipo,
        }
        await instance
            .post("/EqCasos/Insertar", postData)
            .then(response => {

            })
            .catch(error => {

            })
    }
    const methodAñadirParticipantes = async () => { // ========>> Funcion agregar nuevos participantes
        let postData = {
            _arrayCasos: arrayCasos,
            _comboTipoParticipante: comboTipoParticipante,
            _getParticipantes: GetParticipantes
        }
        await instance
            .post("/EqCasos/Masivo/Insertar", postData)
            .then(response => {

                if (response.data == "success") {
                    casoContext.FuncionListarCasos();
                    FuncionListarEquipo();
                    Swal.fire({
                        title: "Mensaje",
                        text: "Actualizado exitosamente",
                        icon: "success",
                        confirmButtonText: "Aceptar",
                    })
                    DeseleccionarTodo();
                    abrirCerrarModal(6)
                }
            })
            .catch(error => {

            })

    }
    /** Funcion para eliminar participantes del caso ***** */
    const methodEliminarParticipantes = async () => {



        let postData = {
            _arrayCasos: arrayCasos,
            _comboTipoParticipante: comboTipoParticipante,
            _getParticipantes: GetParticipantes
        }
        await instance
            .post("/EqCasos/UsuarioCaso_EliminarParticipantes", postData)
            .then(response => {

                if (response.data == "success") {
                    casoContext.FuncionListarCasos();
                    FuncionListarEquipo();
                    Swal.fire({
                        title: "Mensaje",
                        text: "Actualizado exitosamente",
                        icon: "success",
                        confirmButtonText: "Aceptar",
                    })
                    DeseleccionarTodo();
                    abrirCerrarModal(7)
                }
            })
            .catch(error => {

            })
    }
    /** Funcion para agregar equipo casos ***** */

    const handleChangeEQParticipantes = e => {

        SetidEQParticipantes(e.target.value)

        FuncionBuscarUsuXEquipo(e.target.value);
    }
    const methodAgregarEquipoCasos = async () => {



        for (let i = 0; i < arrayCasos.length; i++) {
            let postData = arrayCasos[i];
            postData.nEqu_ID = idEQParticipantes;
            await instance
                .post("/Casos/Actualizar", postData)
                .then(response => {


                    FuncionInsParticipantesFromEC(postData.nEqu_ID, postData.nCas_Id);
                })
                .catch(error => {

                })
        }
        Swal.fire({
            title: "Mensaje",
            text: "Actualizado exitosamente",
            icon: "success",
            confirmButtonText: "Aceptar",
        })
        abrirCerrarModal(8);
    }
    /** Funcion para eliminar casos logicamente ***** */
    const methodEliminarCasosLogicamente = async () => {
        var countstatusText = 0;
        if (arrayCasos.length == 0) {
            Swal.fire({
                title: "Mensaje",
                text: `No a seleccionado ningun caso`,
                icon: "success",
                confirmButtonText: "Aceptar",
            })

            const checkbox = document.getElementById("selecionarMasivo");
            checkbox.checked = false;

        }
        else {

            Swal.fire({
                html: ` ${arrayCasos.length} casos seleccionados. ¿Desea eliminar permanentemente los casos ?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Si",
                cancelButtonText: "No",
            }).then(result => {
                if (result.isConfirmed) {
                    setTimeout(() => {
                        Swal.fire({
                            html: " Esta acción no podra ser revertida. <br> ¿ Desea continuar ? ",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Si",
                            cancelButtonText: "No",
                        }).then(async result => {
                            if (result.isConfirmed) {
                                let postData1 = {
                                    _arrayCasos: arrayCasos
                                }
                                await instance
                                    .post("/Casos/MasivoEliminarCaso", postData1)
                                    .then(async response => {
                                        let count = response.data.count

                                        let texto = "";
                                        let registro = "registro";
                                        let nota = "";
                                        if (arrayCasos.length > 1) { registro = "registros" }
                                        if (count == 1) {
                                            texto = `${count} caso eliminado de ${arrayCasos.length} seleccionado(s)`
                                        }
                                        else {
                                            texto = `${count} casos eliminados de ${arrayCasos.length} seleccionado(s)`
                                        }
                                        if (arrayCasos.length != count) {
                                            nota = "Nota : Los casos que no fueron eliminados contienen una liquidación pendiente"
                                        }

                                        casoContext.FuncionListarCasos();
                                        Swal.fire({
                                            title: "Mensaje",
                                            html: `${texto}<br><br> <b>${nota}</b>`,
                                            icon: "success",
                                            confirmButtonText: "Aceptar",
                                        })
                                        DeseleccionarTodo();
                                        const checkbox = document.getElementById("selecionarMasivo");
                                        checkbox.checked = false;
                                    });

                                DeseleccionarTodo();
                                const checkbox = document.getElementById("selecionarMasivo");
                                checkbox.checked = false;

                            }
                            else {
                                DeseleccionarTodo();
                                const checkbox = document.getElementById("selecionarMasivo");
                                checkbox.checked = false;
                            }
                        })
                    }, 200);
                }
                else {
                    DeseleccionarTodo();
                    const checkbox = document.getElementById("selecionarMasivo");
                    checkbox.checked = false;
                }
            })
        }

    }
    const VincularProcesos = async () => {
        let DataCuadernos = []
        let data = arrayCasos.filter(e => e.nCas_Origen == 0)

        for (let i = 0; i < data.length; i++) {
            let result = await RetornarCuadernos(arrayCasos[i].nCas_Id)
            console.log("result", result)
            if (result.code == 'Success') {
                let book = result.res[0].cCav_Cuadernos.find(e => e.CodigoExterno == arrayCasos[i].cCas_Cod_Externo)
                book.CodigoC = i + 1;
                DataCuadernos.push(book)
            }
        }
        for (let i = 0; i < arrayCasos.length; i++) {
            let result = await UpdateCuadernos(arrayCasos[i].nCas_Id, JSON.stringify(DataCuadernos).replace(/\//g, '').toString())

        }
        setDataArrayCaso([])
        Swal.fire({
            title: "Mensaje",
            text: "Registros vinculados exitosamente",
            icon: "success",
            confirmButtonText: "Aceptar",
        }).then(result => {
            window.location.reload();
        })
    }
    const DesvincularCaso = async (row) => {
        Swal.fire({
            html: "Esta accion no se podra revertir ¿ Continuar ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si",
            cancelButtonText: "No",
        }).then(async result => {
            if (result.isConfirmed) {
                let result = await RetornarCuadernos(row.nCas_Id)
                if (result.code == 'Success') {
                    let data = result.res[0].cCav_Cuadernos

                    data = data.filter(item => item.CodigoExterno == row.cCas_Cod_Externo)
                    await UpdateCuadernos(row.nCas_Id, JSON.stringify(data).replace(/\//g, '').toString())
                }
                Swal.fire({
                    title: "Mensaje",
                    text: "Registro desvinculado exitosamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })
            }
        })
    }
    const RetornarCuadernos = async (_ID) => {
        var postData = {
            id: _ID,
        }
        return new Promise(async (resolve) => {
            await instance
                .post("/Dashboard/Casos/DataCEJ", postData)
                .then(response => {
                    resolve({
                        code: 'Success',
                        res: response.data
                    })
                }).catch(function (error) {
                    resolve({
                        code: 'Error',
                        res: error.response.data.message
                    })
                });
        });
    }
    const UpdateCuadernos = async (_ID, _cCav_Cuadernos) => {
        var postData = {
            nCas_Id: _ID,
            cCav_Cuadernos: _cCav_Cuadernos,
        }
        return new Promise(async (resolve) => {
            await instance
                .post("/Cuaderno/Actualizar", postData)
                .then(response => {
                    resolve({
                        code: 'Success',
                        res: response.data
                    })
                }).catch(function (error) {
                    resolve({
                        code: 'Error',
                        res: error.response.data.message
                    })
                });
        });
    }

    function sortJSON(data, key, orden) {
        return data.sort(function (a, b) {
            var x = a[key],
                y = b[key];
            if (orden === 'asc') {
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            }
            if (orden === 'desc') {
                return ((x > y) ? -1 : ((x < y) ? 1 : 0));
            }
        });
    }
    /** Funcion para eliminar casos fisicamente ***** */
    const methodEliminarCasosFisicamente = async () => {
        Swal.fire({
            html: "¿ Desea eliminar los casos ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si",
            cancelButtonText: "No",
        }).then(result => {
            if (result.isConfirmed) {
                setTimeout(() => {
                    Swal.fire({
                        html: "Esta accion no se podra revertir ¿ Continuar ?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Si",
                        cancelButtonText: "No",
                    }).then(async result => {
                        if (result.isConfirmed) {
                            for (let i = 0; i < arrayCasos.length; i++) {
                                let caso = arrayCasos[i];
                                var postData = {
                                    nCas_Id: caso.nCas_Id,
                                }
                                await instance
                                    .post("/Casos/Eliminar_Fisicamente", postData)
                                    .then(response => {
                                    })
                                    .catch(error => {

                                    })
                            }
                            casoContext.FuncionListarCasos();
                            Swal.fire({
                                title: "Mensaje",
                                text: "Registros eliminados exitosamente",
                                icon: "success",
                                confirmButtonText: "Aceptar",
                            })
                            DeseleccionarTodo();
                        }
                        else {
                            DeseleccionarTodo();
                        }
                    })
                }, 200);
            }
            else {
                DeseleccionarTodo();
            }
        })
    }
    const FuncionListarEquipo = async () => {
        var postData = {
            nCas_Id: null,
            Tipo: null,
        }
        await instance
            .post("/Casos/Equipo", postData)
            .then(response => {
                setParticipantesALL(response.data)

            })
            .catch(error => {

            })
    }
    /************************************************************************ */
    const FuncionRefrescarProcesosMasivo = async () => {

        const UsuariosSelect = arrayCasos.map((row, i) => {
            if (row.nCas_Origen == "Indecopi") {
                FuncionSincronizarIndecopi(row)
            } else {
                (function (index) {
                    setTimeout(function () { FuncionRefrescarCEJ(row.nCas_Id, row.cCas_Cod_Externo); }, i * 40000);
                })(i);
            }
        })
    }
    const FuncionSincronizarIndecopi = async (getCasos) => {
        Swal.fire({
            html:
                '<img id="imgr1" className="imgr" src="/static/media/logo-dark.7a9779c2.png" style="width:50px;height:auto;-webkit-animation: 3s rotate linear infinite;' +
                'animation: 3s rotate linear infinite;' +
                '-webkit-transform-origin: 50% 50%;' +
                'transform-origin: 50% 50%;" /> ' +
                '<br/><br/> Cargando...',
            showConfirmButton: false,
            allowOutsideClick: false
        });
        var Cod = "";
        var AREA = "";
        var TEXPEDIENTE = "";
        var LTRAMITE = "";
        var NEXPEDIENTE = "";
        var AÑO = "";
        var postData1 = {
            Prefijo: getCasos.nJuz_Id,
        }
        await instance
            .post("/Maestros/Listar", postData1)
            .then(response => {
                AREA = response.data[0].cTabSimbolo
            })
            .catch(error => {

            })
        var postData2 = {
            Prefijo: getCasos.TEXPEDIENTE,
        }
        await instance
            .post("/Maestros/Listar", postData2)
            .then(response => {
                TEXPEDIENTE = response.data[0].cTabSimbolo
            })
            .catch(error => {

            })
        var postData3 = {
            Prefijo: getCasos.LTRAMITE,
        }
        await instance
            .post("/Maestros/Listar", postData3)
            .then(response => {
                LTRAMITE = response.data[0].cTabSimbolo
            })
            .catch(error => {

            })
        AÑO = getCasos.nCas_Exp_Year;
        NEXPEDIENTE = getCasos.cCas_Expediente;
        Cod = NEXPEDIENTE + "-" + AÑO + "-" + AREA + "-" + TEXPEDIENTE + "-" + LTRAMITE


        var postData4 = {
            cod: Cod,
        }
        await instance
            .post("/Indecopi/Listar", postData4)
            .then(response => {




                FuncionInsertarScraping(getCasos.nCas_Id, response.data.denunciantes, response.data.NExpediente, response.data.denunciados, response.data.Notications)
            })
            .catch(error => {

            })
    }
    const FuncionInsertarScraping = async (_nCas_Id, _cCav_Cuadernos, _cCav_General, _cCav_Movimientos, _cCav_Notificacion, _cCav_Archivo) => {
        var postData = {
            nCas_Id: _nCas_Id,
            cCav_Cuadernos: _cCav_Cuadernos,
            cCav_General: _cCav_General,
            cCav_Movimientos: _cCav_Movimientos,
            cCav_Notificacion: _cCav_Notificacion,
            cCav_Archivo: _cCav_Archivo,
            cCav_Notas: "",
        }

        await instance
            .post("/Scraping/Insertar", postData)
            .then(response => {
                Swal.close();
            })
            .catch(error => {

            })
    }
    const FuncionListarComboUsuario = async () => {
        await instance
            .get("/Usuario/Listar")
            .then(response => {
                setDataComboUsuario(response.data)

            })
            .catch(error => {

            })
    }
    /** Funcion para agregar tarea masivamente */
    const FuncionListarComboCategoriaTarea = async () => {
        var postData = {
            Prefijo: "CT",
        }
        await instance
            .post("/Maestros/Data", postData)
            .then(response => {
                setDataCategoriaTarea(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionListarComboRecordarTarea = async () => {
        var postData = {
            Prefijo: "PE",
        }
        await instance
            .post("/Maestros/Data", postData)
            .then(response => {
                setDataRecordarTarea(response.data)

            })
            .catch(error => {

            })
    }
    const handleChange = e => {
        const { name, value } = e.target
        setTareas(prevState => ({
            ...prevState,
            [name]: value,
        }))
        setListas(prevState => ({
            ...prevState,
            [name]: value,
        }))


        if (e.target.name == "nCas_Id") {
            FuncionInformacionCaso(e.target.value)
        }
    }
    const FuncionListarComboTipoTarea = async () => {
        var postData = {
            Prefijo: "TT",
        }
        await instance
            .post("/Maestros/Data", postData)
            .then(response => {
                setDataTipoTarea(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionValidarFormulario = async () => {
        if (!getTareas.cTar_Tab_Categoria || !getTareas.cTar_Titulo || !getTareas.cTar_tab_recordar || !getTareas.cTar_Tab_Tipo) {
            setValidData(true);
            Swal.fire({
                title: "Mensaje",
                text: "Faltan completar datos",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        } else {
            FuncionAgregarTarea()
        }
    }
    const FuncionAgregarParticipantes = async (_nTar_Id, _nUsu_ID) => {
        var postData = {
            nTar_Id: Number.parseInt(_nTar_Id),
            nUsu_ID: Number.parseInt(_nUsu_ID),
        }
        await instance
            .post("/Tarea/Insertar/Participante", postData)
            .then(response => {

            })
            .catch(error => {

            })
    }
    const FuncionCorreoTarea = async (_nCas_Id, _nUsu_Id, _cCor_Tab_Tipo, _cCor_Asunto) => {
        var postData = {
            nCas_Id: _nCas_Id,
            nUsu_Id: _nUsu_Id,
            cCor_Tab_Tipo: _cCor_Tab_Tipo,
            cCor_Asunto: _cCor_Asunto,
        }
        await instance
            .post("/Correo/Insertar", postData)
            .then(response => {
            })
            .catch(error => {

            })
    }
    async function InsertarLista(_nTar_Id, _cLis_Nombre) {
        var postData = {
            nTar_Id: parseInt(_nTar_Id),
            cLis_Nombre: _cLis_Nombre,
        }

        return new Promise(async (resolve) => {
            await instance
                .post("/Tareas/ListaInsertar", postData)
                .then(response => {
                    resolve({
                        code: 'Success',
                        res: response.data
                    })
                }).catch(function (error) {
                    resolve({
                        code: 'Error',
                        res: error.response.data.message
                    })
                });
        });
    }
    const FuncionEliminarLista = async (lista, index) => {
        let arrayLista = dataLista;
        arrayLista.splice(index, 1);
        setDatalista(arrayLista);
        setModalLista("Nueva Lista")
        setListas(prevState => ({
            ...prevState,
            cLis_Nombre: "",
            nLis_Id: null
        }))
    }
    const FuncionAddLista = async () => {
        if (getTareas.cTar_Titulo == "" || getTareas.cTar_Titulo == undefined || getTareas.cTar_Titulo.trim() == "") {
            Swal.fire({
                title: "Mensaje",
                text: "Primero defina el titulo de la tarea",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        }
        else if (getListas.cLis_Nombre == "" || getListas.cLis_Nombre == undefined || getListas.cLis_Nombre.trim() == "") {
            Swal.fire({
                title: "Mensaje",
                text: "Ingrese un nombre para la lista",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        }
        else {
            if (getListas.nLis_Id != null) {

                FuncionEditarLista(getListas);
            }
            else {
                var arrayLista = dataLista;
                const found = arrayLista.find(element => element.cLis_Nombre == getListas.cLis_Nombre);
                if (found) {
                    Swal.fire({
                        title: "Mensaje",
                        text: "Ya existe una lista con este nombre",
                        icon: "error",
                        confirmButtonText: "Aceptar",
                    })
                }
                else {

                    let obj = {
                        cLis_Nombre: getListas.cLis_Nombre,
                        nLis_Id: arrayLista.length + 1
                    }
                    arrayLista.push(obj);
                    setDatalista(arrayLista);
                    setListas(prevState => ({
                        ...prevState,
                        cLis_Nombre: "",
                    }))
                    setModalInsertarlista(!modalInsertarlista)
                }
            }
        }
    }
    const FuncionAgregarTarea = async () => {

        let tarea = {
            nCas_Id: getTareas.nCas_Id,
            cTar_Tab_Tipo: getTareas.cTar_Tab_Tipo,
            cTar_Tab_Categoria: getTareas.cTar_Tab_Categoria,
            cTar_Titulo: getTareas.cTar_Titulo,
            nUsu_Asignado: getTareas.nUsu_Asignado,
            nUsu_Relacionado: getTareas.nUsu_Relacionado,
            dTar_Fec_Vence: getTareas.dTar_Fec_Vence,
            tTar_Hora_Vence: getTareas.tTar_Hora_Vence,
            cTar_tab_recordar: getTareas.cTar_tab_recordar,
            bTar_Estado: Number.parseInt(getTareas.bTar_Estado),
            nTar_Prioridad: parseInt(getTareas.nTar_Prioridad),
            nTar_Duracion: parseInt(getTareas.nTar_Duracion),
            nTar_TipoDias: parseInt(getTareas.nTar_TipoDias),
            nfFas_Id: parseInt(getTareas.nfFas_Id)
        }


        let postData = {
            arrayCasos: arrayCasos,
            getParticipantes: GetParticipantes,
            dataLista: dataLista,
            tarea: tarea
        }

        await instance
            .post("/Tarea/InsertarMasivo", postData)
            .then(async response => {

                console.log(response);
                Swal.fire({
                    title: "Mensaje",
                    text: "Agregado exitosamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                });

                abrirCerrarModal(10);


            })


        // var idTar = 0;
        // for (let i = 0; i < arrayCasos.length; i++) {
        //     postData.nCas_Id = arrayCasos[i].nCas_Id;
        //     await instance
        //         .post("/Tarea/Insertar", postData)
        //         .then(async response => {
        //             GetParticipantes.map((row, i) => {
        //                 FuncionAgregarParticipantes(response.data[0].nTar_Id, row.value)
        //             });
        //             idTar = response.data[0].nTar_Id; // este es el id de la tarea creada
        //             for (let i = 0; i < dataLista.length; i++) {
        //                 await InsertarLista(idTar, dataLista[i].cLis_Nombre);
        //             }
        //             let objMails = {
        //                 ttlCaso: arrayCasos[i].cCas_Titulo,
        //                 idTar: idTar,
        //                 cliente: arrayCasos[i].nCli_Id,
        //                 parametro: {
        //                     tpoTbl: 'tarea',   // tipo de tabla
        //                     tpoAct: 'insertar'  // insertar o actualizar
        //                 },
        //                 dataTarea: postData,
        //                 arrayParticipantes: GetParticipantes
        //             }
        //             await instance
        //                 .post("/mail/event/tarea", objMails)
        //                 .then(res => {
        //                 })
        //                 .catch(error => {
        //                     
        //                 })
        //         })
        //         .catch(error => {
        //             
        //         });
        // }
        // Swal.fire({
        //     title: "Mensaje",
        //     text: "Agregado exitosamente",
        //     icon: "success",
        //     confirmButtonText: "Aceptar",
        // });
        // casoContext.FuncionListarCasos();
        // abrirCerrarModal(10);

    }
    /** Funcion para buscar usuarios del equipo */
    const FuncionBuscarUsuXEquipo = async (_nEqu_ID) => {
        var postData = {
            nEqu_ID: _nEqu_ID
        }
        await instance
            .post("/EQU/BuscarUsuarios", postData)
            .then(response => {
                setUsuariosEquipo(response.data[0])

            });
    }

    /** Funcion para Eliminar participantes del caso y reemplazar por el equipo */
    const FuncionInsParticipantesFromEC = async (_nEqu_ID, _nCas_Id) => {
        var postData = {
            nEqu_ID: _nEqu_ID,
            nCas_Id: _nCas_Id
        }

        await instance
            .post("/EQU/InsParticipantesFromEC", postData)
            .then(response => {
            });
    }
    /** Funcion para eliminar participantes del caso equipo */
    const FuncionEliminarCasoEquipo = async () => {



        var countSucces = 0;
        Swal.fire({
            html: "Los casos que no cuenten con un abogado responsable no serán afectados por este proceso. ¿ Continuar ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si",
            cancelButtonText: "No",
        }).then(async result => {
            if (result.isConfirmed) {
                if (arrayCasos.length == 0) {
                }
                else {
                    for (let i = 0; i < arrayCasos.length; i++) {
                        var postData = {
                            nCas_Id: arrayCasos[i].nCas_Id,
                            Tipo: "UC01",
                        }
                        await instance
                            .post("/Casos/Equipo", postData)
                            .then(async response => {
                                setParticipantesAR(response.data)

                                if (response.data.length == 0) {

                                }
                                else {
                                    countSucces++
                                    var postData2 = {
                                        nCas_Id: arrayCasos[i].nCas_Id,
                                    }
                                    await instance
                                        .post("/EQU/DeleteParticipantesExceptoAR", postData2)
                                        .then(response => {

                                        })
                                        .catch(error => {

                                        })
                                    /** Eliminar los participantes menos al abogado responsable */
                                }

                            })
                            .catch(error => {

                            })
                        /*
                                                var postData2 = {
                                                    nCas_Id: arrayCasos[i].nCas_Id,
                                                }
                                            
                                                await instance
                                                .post("/EqCasos/Eliminar", postData2)
                                                .then(response => {
                                                    
                                                })
                                                .catch(error => {
                                                    
                                                })
                                                */
                    }
                    Swal.fire({
                        title: "Mensaje",
                        text: ` ${countSucces} registros afectados de ${arrayCasos.length} seleccionados`,
                        icon: "success",
                        confirmButtonText: "Aceptar",
                    });
                    casoContext.FuncionListarCasos();
                }
            }
            else {
                DeseleccionarTodo();
            }
        })

    }
    const UsuariosSelect = dataComboUsuario.map((row, i) => ({
        value: row.nUsu_ID,
        label: row.cUsu_Nombres,
        color: "#556ee6",
        email: row.cUsu_email,
    }))
    /**  Validar caso antes de eliminar */
    const FuncionValidarHorasCaso = async (_nCas_Id) => {
        let postData = {
            _nCas_Id: _nCas_Id,
        }

        await instance
            .post("/Caso/validarHoras", postData)
            .then(response => {

                if (response.data[0][0].numregistros > 0) {

                    Swal.fire({
                        title: "Mensaje",
                        text: `Este caso tiene horas laboradas pendientes, no se puede eliminar`,
                        icon: "warning",
                        confirmButtonText: "Aceptar",
                    });
                }
                else {

                    Swal.fire({
                        html: "¿ Desea eliminar permanentemente los casos ?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Si",
                        cancelButtonText: "No",
                    }).then(result => {
                        if (result.isConfirmed) {
                            setTimeout(() => {
                                Swal.fire({
                                    html: " Esta acción no podra ser revertida. <br> ¿ Desea continuar ? ",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Si",
                                    cancelButtonText: "No",
                                }).then(async result => {
                                    if (result.isConfirmed) {
                                        var postData2 = {
                                            nCas_Id: _nCas_Id,
                                        }
                                        await instance
                                            .post("/Casos/Eliminar", postData2)
                                            .then(response => {
                                            })
                                            .catch(error => {

                                            })
                                        casoContext.FuncionListarCasos();
                                        Swal.fire({
                                            title: "Mensaje",
                                            text: `El caso fue eliminado correctamente`,
                                            icon: "success",
                                            confirmButtonText: "Aceptar",
                                        })
                                    }
                                    else {
                                    }
                                })
                            }, 200);
                        }
                        else {
                        }
                    })
                }
            });
    }
    const FuncionListarPermisos = async () => {
        var postData = {
            nUsu_ID: parseInt(getDecryptedItem("RolID"))
        }
        await instance
            .post("/Usuario/Acceso", postData)
            .then(response => {
                let arrayPermisos = response.data;
                const permiso = arrayPermisos.find(element => element.cUsu_Modulo == "ME02");

                if (permiso.bUsu_Editar == 1) {
                    setHiddenBtnEditar(false);
                }
                else {
                    setHiddenBtnEditar(true);
                }
                if (permiso.bUsu_Eliminar == 1) {
                    setHiddenBtnEliminar(false);
                }
                else {
                    setHiddenBtnEliminar(true);
                }
            })
            .catch(error => {

            })
    }
    const datas = {
        columns: [
            {
                label: "Listas",
                field: "cLis_Nombre",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            }
        ],
        rows: dataLista,
    }
    const badgesData = {
        columns: [
            ...datas.columns,
            {
                label: "Acciones",
                field: "acciones",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
        ],
        rows: [
            ...datas.rows.map((row, id) => ({
                ...row,
                acciones: (
                    <div>
                        <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="dropdown-toggle">
                                <i className="bx bx-dots-vertical-rounded" />
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => FuncionEliminarLista(row, id)}>
                                    <Link >
                                        Eliminar
                                    </Link>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                ),
            })),
        ],
    }

    const onChangeHandler = (e) => {

        if (e.target.value == "") {
            FuncionListarCasos()
        }
        else {
            var newArray = data.filter((d) => {
                return d.cEve_Titulo.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
            });

            setData(newArray)
        }
    }

    const FnExportExcel = async () => {
        var postData = {
            type: "Casos",
            data: casoContext.casos
        }
        await instance
            .post("/Generate/Excel", postData, { responseType: 'arraybuffer' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                // the filename you want
                a.download = 'Casos.xlsx';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(error => {

            })
    }


    useEffect(() => {
        FuncionListarPermisos();
        FuncionListarComboUsuario()
        casoContext.FuncionListarCasos()
        comboContext.FuncionListarEstadoInterno()
        FuncionListarEquipo()
        FuncionListarComboTipoTarea()
        FuncionListarComboCategoriaTarea()
        FuncionListarComboRecordarTarea()
        cantidadCasos = casoContext.casos.length;
        comboContext.FuncionListarEquipo()
    }, [])
    return (
        <Col className="col-12">
            <Card>
                <CardBody>
                    <CardTitle style={{ fontSize: "20px", color: "#aa1b0E", fontWeight: "normal" }}>
                        <span style={{ color: "#aa1b0E" }}>  Listado de Casos{" "}</span>
                    </CardTitle>
                    <CasoState>
                        {(() => {
                            if (modalActualizar == true) {
                                return <>
                                    <ModalCasoU
                                        show={modalActualizar}
                                        Rows={getCasos}
                                        RowAR={GetParticipantesAR}
                                        RowA={GetParticipantesA}
                                        RowP={GetParticipantesP}
                                        RowU={GetParticipantesUAD}
                                        RowC={getDefaultCliente}
                                        RowO={getDefaultOrgano}
                                        onCloseClick={() => abrirCerrarModal(2)}
                                        onCloseModal={() => { abrirCerrarModal(2), casoContext.FuncionListarCasos() }} />
                                </>
                            }
                        })()}
                    </CasoState>
                    <CasoState>
                        <ComboState>
                            {(() => {
                                if (modalInsertar == true) {
                                    return <>
                                        <ModalCaso
                                            show={modalInsertar}
                                            onCloseClick={() => abrirCerrarModal(1)}
                                            onCloseModal={() => { setModalInsertar(!modalInsertar) }} />
                                    </>
                                }
                            })()}
                        </ComboState>
                    </CasoState>
                    <Modal size="lg" isOpen={modalTarea}>
                        <div className="modal-header ModalHeaderColor">
                            <h5
                                style={{ color: "#fff" }}
                                className="modal-title mt-0"
                                id="myModalLabel"
                            >
                                Agregar Tarea
                            </h5>
                            <button
                                type="button"
                                onClick={() => abrirCerrarModal(10)}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3 row">
                            </div>
                            <div className="mb-3 row" style={{ marginTop: "15px" }}>
                                <div className="col-md-6">
                                    <select
                                        className={getErrores.cTar_Tab_Categoria && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                                        name="cTar_Tab_Categoria"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    >
                                        <option value="0">Seleccionar...</option>
                                        {dataCategoriaTarea.map((row, i) => (
                                            <option key={i} value={row.cTabCodigo}>
                                                {row.cTabNombre}
                                            </option>
                                        ))}
                                    </select>
                                    <label className="col-form-label labelDown">Categoria</label> {getErrores.cTar_Tab_Categoria && <label className="col-form-label labelDown labelRed">{getErrores.cTar_Tab_Categoria}</label>}
                                </div>
                                <div className="col-md-6">
                                    <textarea
                                        className={getErrores.cTar_Titulo && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                                        placeholder="Ingrese el título"
                                        name="cTar_Titulo"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        maxLength="200"
                                    ></textarea>
                                    <label className="col-form-label labelDown">Titulo</label>{getErrores.cTar_Titulo && <label className="col-form-label labelDown labelRed">{getErrores.cTar_Titulo}</label>}
                                </div>
                            </div>
                            <div className="mb-3 row">
                            </div>
                            <div className="mb-3 row">
                                <div className="col-md-6">
                                    <Select
                                        isMulti
                                        onChange={handleChangeParticipantes}
                                        name="colors"
                                        options={UsuariosSelect}
                                        className="basic-multi-select"
                                        placeholder="Ingrese los participantes"
                                        value={GetParticipantes}
                                    />
                                    <label className="col-form-label labelDown">
                                        Participantes
                                    </label>
                                </div>
                                <div className="col-md-2" style={{ width: '90px' }}>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="nTar_Duracion"
                                        name="nTar_Duracion"
                                        value={getTareas && getTareas.nTar_Duracion}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        style={{ textAlign: 'right' }}
                                    />
                                    <label className="col-form-label labelDown">
                                        Duración
                                    </label>
                                </div>
                                <div className="col-md-2" style={{ width: '150px' }}>
                                    <select
                                        className="form-select"
                                        name="nTar_TipoDias"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={getTareas && getTareas.nTar_TipoDias}
                                    >
                                        <option value="0">Seleccionar...</option>
                                        <option value="1">Dias calendario</option>
                                        <option value="2">Dias utiles</option>
                                    </select>
                                    <label className="col-form-label labelDown">
                                        Dias
                                    </label>
                                </div>

                                <div className="col-md-2" style={{ width: '150px' }}>
                                    <select
                                        className="form-select"
                                        name="nTar_Prioridad"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={getTareas && getTareas.nTar_Prioridad}
                                    >
                                        <option value="0">Seleccionar...</option>
                                        <option value="1">Alta</option>
                                        <option value="2">Mediana</option>
                                        <option value="3">Baja</option>
                                        <option value="4">Sin Prioridad</option>
                                    </select>
                                    <label className="col-form-label labelDown">
                                        Prioridad
                                    </label>
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <div className="col-md-3">
                                    <input
                                        className="form-control"
                                        type="date"
                                        name="dTar_Fec_Vence"
                                        value={getTareas && getTareas.dTar_Fec_Vence}
                                        onChange={handleChange}
                                    />
                                    <label className="col-form-label labelDown">
                                        Fecha de vencimiento
                                    </label>
                                </div>
                                <div className="col-md-3">
                                    <input
                                        className="form-control"
                                        type="time"
                                        name="tTar_Hora_Vence"
                                        value={getTareas && getTareas.tTar_Hora_Vence}
                                        onChange={handleChange}
                                    />
                                    <label className="col-form-label labelDown">
                                        Hora de vencimiento
                                    </label>
                                </div>
                                <div className="col-md-3">
                                    <select
                                        className={getErrores.cTar_tab_recordar && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                                        name="cTar_tab_recordar"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={getTareas && getTareas.cTar_tab_recordar}
                                    >
                                        <option value="0">Seleccionar...</option>
                                        {sortJSON(dataRecordarTarea, 'cTabCodigo', 'asc').map((row, i) => (
                                            <option key={i} value={row.cTabCodigo}>
                                                {row.cTabNombre}
                                            </option>
                                        ))}
                                    </select>
                                    <label className="col-form-label labelDown">Recordar</label>{getErrores.cTar_tab_recordar && <label className="col-form-label labelDown labelRed">{getErrores.cTar_tab_recordar}</label>}
                                </div>
                                <div className="col-md-3">
                                    <select
                                        className={getErrores.cTar_Tab_Tipo && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                                        name="cTar_Tab_Tipo"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={getTareas && getTareas.cTar_Tab_Tipo}
                                    >
                                        <option value="0">Seleccionar...</option>
                                        {dataTipoTarea.map((row, i) => (
                                            <option key={i} value={row.cTabCodigo}>
                                                {row.cTabNombre}
                                            </option>
                                        ))}
                                    </select>
                                    <label className="col-form-label labelDown">Estado</label> {getErrores.cTar_Tab_Tipo && <label className="col-form-label labelDown labelRed">{getErrores.cTar_Tab_Tipo}</label>}
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <div className="col-md-6" hidden>
                                    <select
                                        className="form-select"
                                        name="bTar_Estado"
                                        value={getTareas && getTareas.bTar_Estado}
                                        onChange={handleChange}
                                    >
                                        <option value="00">Seleccionar...</option>
                                        <option value="1">Activo</option>
                                        <option value="NN00">No activo</option>
                                    </select>
                                    <label className="col-form-label labelDown">
                                        Estado
                                    </label>
                                </div>
                            </div>
                            <div className="mb-3 row" >
                                <hr style={{ border: 'none', height: '5px', backgroundColor: '#333' }}></hr>
                                <CardBody style={{ paddingLeft: '12px', paddingBottom: '0px' }}>
                                    <CardTitle>
                                        {ttlModalLista}
                                    </CardTitle>
                                    <div className="mb-3 row">
                                        {/* <div className="col-md-6">
                                <input
                                disabled
                                className="form-control inputDown"
                                type="text"
                                placeholder="Nombre de la tarea"
                                name="cTar_Titulo"
                                value={getTareas && getTareas.cTar_Titulo}
                                />
                                <label className="col-form-label labelDown">
                                Tarea
                                </label>
                            </div> */}
                                        <div className="col-md-6">
                                            <input
                                                className="form-control inputDown"
                                                type="text"
                                                placeholder="Ingrese el nombre de la lista"
                                                name="cLis_Nombre"
                                                value={getListas && getListas.cLis_Nombre}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <button
                                            style={{ height: '30px', marginTop: '5px', width: '150px' }}
                                            type="button"
                                            onClick={() => FuncionAddLista()}
                                            className="btn btn-danger waves-effect waves-light"
                                            data-toggle="modal"
                                            data-target="#myModal"
                                        >
                                            Agregar item
                                        </button>
                                    </div>
                                </CardBody>
                            </div>
                            <div className="mb-3 row" style={{ marginTop: '0px' }}>
                                <div className="col-md-12">
                                    <CardBody style={{ paddingLeft: '0px', paddingBottom: '0px', paddingRight: '0px' }}>
                                        <Col className="col-12 row" style={{ paddingRight: '0px' }}>

                                        </Col>
                                        <MDBDataTable
                                            data={badgesData}
                                            className="TablaListasNuevo"
                                            responsiveXl
                                            fixed
                                            hover
                                            entriesLabel="Mostrar"
                                            entrylabel={"Mostrar entradas"}
                                            infoLabel={["Mostrando ", "a", "de", "registros"]}
                                            bordered
                                            order={["age", "desc"]}
                                            noRecordsFoundLabel={"No hay datos"}
                                            searchLabel={"Buscar"}
                                            noBottomColumns
                                            paginationLabel={["Anterior", "Siguiente"]}
                                            theadColor="HeadBlue"
                                            searching={false}
                                            paging={false}
                                            info={false}
                                        />
                                    </CardBody>
                                </div>
                            </div>
                            <div className="mb-3 row" hidden>
                                <div className="col-md-6">
                                    <select
                                        className="form-select"
                                        name="bTar_Estado"
                                        value={getTareas && getTareas.bTar_Estado}
                                        onChange={handleChange}
                                    >
                                        <option value="00">Seleccionar...</option>
                                        <option value="1">Activo</option>
                                        <option value="0">No activo</option>
                                    </select>
                                    <label className="col-form-label labelDown">
                                        Estado
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={() => abrirCerrarModal(10)}
                                className="btn btn-secondary waves-effect"
                                data-dismiss="modal"
                            >
                                Cerrar
                            </button>
                            <button
                                type="button"
                                onClick={() => FuncionValidarFormulario()}
                                className="btn btn-danger waves-effect waves-light"
                            >
                                Guardar
                            </button>
                        </div>
                    </Modal>
                    <Modal isOpen={modalAddEquipo}>
                        <div className="modal-header ModalHeaderColor" >
                            <h5
                                style={{ color: "#fff" }}
                                className="modal-title mt-0"
                                id="myModalLabel"
                            >
                                Agregar Equipo
                            </h5>
                            <button
                                type="button"
                                onClick={() => abrirCerrarModal(8)}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <label> Casos seleccionados : {arrayCasos.length}</label>
                            <div className="col-md-12">
                                <div className="col-md-3"></div>
                                <div className="col-md-12" style={{ marginTop: '30px' }}>
                                    <select
                                        className="form-select inputDown"
                                        name="nCas_Id"
                                        onChange={handleChangeEQParticipantes}
                                    >
                                        <option value="0">
                                            Seleccionar...
                                        </option>
                                        {comboContext.cequipo.map((row, i) => (
                                            <option key={i} value={row.nEqu_ID}>
                                                {row.cEqu_Nombre}
                                            </option>
                                        ))}
                                    </select>
                                    <label className="col-form-label labelDown">
                                        Seleccione equipo
                                    </label>
                                </div>
                                <div className="col-md-12" style={{ marginTop: '30px' }}>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={() => abrirCerrarModal(8)}
                                className="btn btn-secondary waves-effect"
                                data-dismiss="modal"
                            >
                                Cerrar
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger waves-effect waves-light"
                                onClick={() => methodAgregarEquipoCasos()}
                            >
                                Guardar
                            </button>
                        </div>
                    </Modal>
                    <Modal isOpen={modalCambiarEstado}>
                        <div className="modal-header ModalHeaderColor" >
                            <h5
                                style={{ color: "#fff" }}
                                className="modal-title mt-0"
                                id="myModalLabel"
                            >
                                Editar estado interno
                            </h5>
                            <button
                                type="button"
                                onClick={() => abrirCerrarModal(5)}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <label> Casos seleccionados : {arrayCasos.length}</label>
                            <div className="col-md-12">
                                <div className="col-md-3"></div>
                                <div className="col-md-6" style={{ marginTop: '30px' }}>
                                    <select
                                        className="form-select inputDown"
                                        name="cTab_Estado_Interno"
                                        onChange={handleChangeEstadointerno}
                                        value={estadoInterno}
                                        id="00000"
                                    >
                                        <option value="NN00">
                                            Seleccionar...
                                        </option>
                                        {comboContext.estadointerno.map(
                                            (row, i) => (
                                                <option
                                                    key={i}
                                                    value={row.cTabCodigo}
                                                >
                                                    {row.cTabNombre}
                                                </option>
                                            )
                                        )}
                                    </select>
                                    <label className="col-form-label labelDown">
                                        Estado Interno
                                    </label>
                                </div>
                            </div>


                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={() => abrirCerrarModal(5)}
                                className="btn btn-secondary waves-effect"
                                data-dismiss="modal"
                            >
                                Cerrar
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger waves-effect waves-light"
                                onClick={() => methodCambiarEstado()}
                            >
                                Guardar
                            </button>
                        </div>
                    </Modal>
                    <Modal isOpen={modalAddParticipantes}>
                        <div className="modal-header ModalHeaderColor" >
                            <h5
                                style={{ color: "#fff" }}
                                className="modal-title mt-0"
                                id="myModalLabel"
                            >
                                Añadir participantes
                            </h5>
                            <button
                                type="button"
                                onClick={() => abrirCerrarModal(6)}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <label> Casos seleccionados : {arrayCasos.length}</label>
                            <div className="col-md-12">
                                <div className="col-md-3"></div>
                                <div className="col-md-12" style={{ marginTop: '30px' }}>
                                    <select
                                        className="form-select inputDown"
                                        value={comboTipoParticipante}
                                        onChange={handleChangeTipoParticipante}
                                    >
                                        <option value="NN00">Seleccionar...</option>
                                        <option value="UC01">Abogado(s) responsable</option>
                                        <option value="UC02">Abogado(s)</option>
                                        <option value="UC03">Practicante(s)</option>
                                        <option value="UC04">Usuario Adicional</option>
                                    </select>
                                    <div className="col-mb-12 row" style={{ marginTop: '20px' }}>
                                        <div className="col-md-12">
                                            <Select
                                                style={{ width: '300px' }}
                                                isMulti
                                                onChange={handleChangeParticipantes}
                                                name="colors"
                                                options={UsuariosSelect}
                                                className="basic-multi-select"
                                                placeholder="Ingrese los participantes"
                                            />
                                            <label className="col-form-label labelDown">
                                                Tipo de participantes
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={() => abrirCerrarModal(6)}
                                className="btn btn-secondary waves-effect"
                                data-dismiss="modal"
                            >
                                Cerrar
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger waves-effect waves-light"
                                onClick={() => methodAñadirParticipantes()}
                            >
                                Guardar
                            </button>
                        </div>
                    </Modal>
                    <Modal isOpen={modalRmParticipantes}>
                        <div className="modal-header ModalHeaderColor" >
                            <h5
                                style={{ color: "#fff" }}
                                className="modal-title mt-0"
                                id="myModalLabel"
                            >
                                Desasociar participantes
                            </h5>
                            <button
                                type="button"
                                onClick={() => abrirCerrarModal(7)}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <label> Casos seleccionados : {arrayCasos.length}</label>
                            <div className="col-md-12">
                                <div className="col-md-3"></div>
                                <div className="col-md-12" style={{ marginTop: '30px' }}>
                                    <select
                                        className="form-select inputDown"
                                        value={comboTipoParticipante}
                                        onChange={handleChangeTipoParticipante}
                                    >
                                        <option value="NN00">Seleccionar...</option>
                                        <option value="UC01">Abogado(s) responsable</option>
                                        <option value="UC02">Abogado(s)</option>
                                        <option value="UC03">Practicante(s)</option>
                                        <option value="UC04">Usuario Adicional</option>
                                    </select>
                                    <div className="col-mb-12 row" style={{ marginTop: '20px' }}>
                                        <div className="col-md-12">
                                            <Select
                                                style={{ width: '300px' }}
                                                isMulti
                                                onChange={handleChangeParticipantes}
                                                name="colors"
                                                options={UsuariosSelect}
                                                className="basic-multi-select"
                                                placeholder="Ingrese los participantes"
                                            />
                                            <label className="col-form-label labelDown">
                                                Tipo de participantes
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={() => abrirCerrarModal(7)}
                                className="btn btn-secondary waves-effect"
                                data-dismiss="modal"
                            >
                                Cerrar
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger waves-effect waves-light"
                                onClick={() => methodEliminarParticipantes()}
                            >
                                Guardar
                            </button>
                        </div>
                    </Modal>
                </CardBody>
                <div className="mb-3 row">
                    <div className="col-md-2">
                        <input
                            type="checkbox"
                            className="checkbox"
                            id="selecionarMasivo"
                            style={{ marginTop: "8px" }}
                            onClick={() => SeleccionarTodo()}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="customCheckcolor1"
                            style={{ marginTop: "8px", paddingLeft: "67px" }}
                        >
                            Marcar todos
                        </label>
                    </div>
                    <div className="col-md-6" >
                        <button
                            hidden
                            type="button"
                            onClick={() => FuncionActivarMasiva()}
                            className="btn btn-danger waves-effect waves-light"
                            data-toggle="modal"
                            data-target="#myModal"
                        >
                            <i style={{ fontSize: "13px" }} className="icon-eliminar"> </i>Desactivar
                        </button>
                    </div>
                    <div className="col-md-2" style={{ textAlign: 'left', float: 'left', paddingLeft: '15px' }}>
                        <div className="dropdown">
                            <button hidden={hiddenBtnEditar} onClick={() => myFunction()} className="drop-button btn btn-danger" style={{ width: '150px', paddingLeft: '8px', paddingRight: '8px' }}>Acciones masivas</button>
                            <div id="myDropdown" className="dropdown-content">
                                <a href="#" onClick={() => abrirCerrarModal(5, casoContext.casos)}>Cambiar el estado interno</a>
                                <a href="#" onClick={() => abrirCerrarModal(6, casoContext.casos)}>Asociar participantes</a>
                                <a href="#" onClick={() => abrirCerrarModal(7, casoContext.casos)}>Desasociar participantes</a>
                                <a href="#" hidden onClick={() => abrirCerrarModal(8, casoContext.casos)}>Asociar equipo</a>
                                <a href="#" hidden onClick={() => FuncionEliminarCasoEquipo()}>Desasociar equipo</a>
                                <a href="#" onClick={() => abrirCerrarModal(10)}>Agregar tarea</a>
                                <a href="#" onClick={() => methodEliminarCasosLogicamente()}>Eliminar casos</a>
                                <a href="#" onClick={() => FuncionRefrescarProcesosMasivo(casoContext.casos)}>Refescar procesos</a>
                                <a href="#" onClick={() => VincularProcesos()}>Vincular Procesos</a>
                            </div>
                        </div>

                        <div className="col-md-12" style={{ textAlign: 'right', float: 'right', paddingLeft: '0px', marginTop: '40px', width: "100%" }}>
                            <input
                                className="form-control"
                                placeholder="🔎 Expediente / Cliente / Titulo"
                                name="cCas_Expediente" onChange={handleChangeFiltroNExpediente.bind(this)}
                                style={{ textAlign: "left", width: "300px" }}
                                type="text" />
                        </div>

                        <div>
                            <label style={{ textAlign: 'center', paddingTop: '20px', paddingRight: '0px', marginRight: '0px' }}>Cantidad de casos: {casoContext.casos.length}</label>
                        </div>

                    </div>
                    <div className="col-md-2">
                        <button
                            type="button"
                            onClick={() => FnExportExcel()}
                            className="btn btn-success waves-effect waves-light"
                            data-toggle="modal"
                            data-target="#myModal"
                        >
                            <i style={{ fontSize: "15px" }} className="icon-excel"></i>&nbsp;
                            Excel
                        </button>{" "}
                    </div>
                </div>
                <form name="f1" style={{ minHeight: '700px' }}>
                    {casoContext.casos.map((row, i) => (
                        <div id="idReporte" key={i} style={{ borderBottom: "1px solid #bbb" }}>
                            <input
                                type="checkbox"
                                className="checkbox"
                                name="dinamico"
                                id={"id" + row.nCas_Id}
                                onClick={(e) => SelectCheckbox(row, e)}
                            />
                            <div className="listado">
                                <div className="titulo Texto-Limit">
                                    <Link to='#' onClick={() => SelectDetalle(row.nCas_Id, row.nCli_Id, row.nCas_Origen)} >
                                        <a className="" style={{ color: "black" }} >{row.cCas_Titulo} - {row.cCas_Titulo1}</a></Link>
                                </div>
                                <div className="descripcion">
                                    <div className="case Texto-Limit">
                                        <label className="text-truncate">
                                            {" "}
                                            {row.nCas_Origen2}: {row.cCas_Cod_Interno}
                                        </label>
                                    </div>
                                    <div className="">
                                        <UncontrolledDropdown>
                                            <DropdownToggle tag="a" className="dropdown-toggle">
                                                <i className="bx bx-dots-vertical-rounded" />
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                                <DropdownItem onClick={() => SelectDetalle(row.nCas_Id, row.nCli_Id, row.nCas_Origen)
                                                }>
                                                    <Link to='#' style={{ color: "black" }} >Ver Detalle</Link>
                                                </DropdownItem>
                                                <DropdownItem hidden={hiddenBtnEditar} onClick={() => abrirCerrarModal(2, row)}>
                                                    <Link style={{ color: "black" }} to="#" >Editar</Link>
                                                </DropdownItem>
                                                <DropdownItem hidden={hiddenBtnEliminar}
                                                    onClick={() => FuncionValidarHorasCaso(row.nCas_Id)}
                                                >
                                                    Eliminar
                                                </DropdownItem>
                                                <DropdownItem onClick={() => {
                                                    if (row.nCas_Origen == 1) {
                                                        FuncionSincronizarIndecopi()
                                                    } else if (row.nCas_Origen == 0) {
                                                        FuncionRefrescarCEJ(row.nCas_Id, row.cCas_Cod_Externo)
                                                    } else {
                                                        abrirCerrarModal(1, row.nCas_Id)
                                                    }
                                                }}>
                                                    {(() => {
                                                        if (row.nCas_Origen == 1) {
                                                            return (<>Refrescar INDECOPI</>)
                                                        } else if (row.nCas_Origen == 0) {
                                                            return (<>Refrescar CEJ</>)
                                                        } else if (row.nCas_Origen == 3) {
                                                            return (<>Vincular Corte Suprema</>)
                                                        } else {
                                                            return (<>Vincular Expediente</>)
                                                        }
                                                    })()}
                                                </DropdownItem>
                                                <DropdownItem hidden={hiddenBtnEditar} onClick={() => DesvincularCaso(row)}>
                                                    <Link style={{ color: "black" }} to="#" >Desvincular Caso</Link>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </div>
                                </div>
                                <div className="detalle Texto-Limit">
                                    <i style={{ fontSize: "16px" }} className="icon-balanza" />{" "}
                                    <label htmlFor="" className="text-detalle">
                                        Juzgado: {row.cCas_Tab_Distrito_Jud2}
                                    </label>{" "}
                                    <i style={{ fontSize: "16px", color: "#c92c48" }} className="icon-Flor-de-Liz" />{" "}
                                    <label className="text-detalle" style={{ color: "#c92c48" }} >
                                        Cod Externo: {row.cCas_Cod_Externo}
                                    </label>
                                </div>
                                <div className="users">
                                    {(() => {
                                        if (row.cTab_Estado_Interno == "EP03") {
                                            return (
                                                <i style={{ fontSize: "16px", color: "#AA1B0E" }} className="icon-esfera-2d" />
                                            )
                                        } else if (row.cTab_Estado_Interno == "EP02") {
                                            return (
                                                <i style={{ fontSize: "16px", color: "#1F60AB" }} className="icon-esfera-2d" />
                                            )
                                        } else if (row.cTab_Estado_Interno == "EP01") {
                                            return (
                                                <i style={{ fontSize: "16px", color: "#032D5E" }} className="icon-esfera-2d" />
                                            )
                                        } else if (row.cTab_Estado_Interno == "EP04") {
                                            return (
                                                <i style={{ fontSize: "16px", color: "#00A94F" }} className="icon-esfera-2d" />
                                            )
                                        } else {
                                            return (
                                                <i style={{ fontSize: "16px", color: "#000" }} className="icon-esfera-2d" />
                                            )
                                        }
                                    })()}{" "}
                                    <label className="text-case">
                                        {row.cTab_Estado_Interno2}
                                    </label>
                                </div>
                                <div className="row">
                                    <Col md="auto">
                                        {(() => {
                                            if (row.bCli_Tipo == "1") {
                                                return (
                                                    <i style={{ fontSize: "16px", color: "#1F60AB" }} className="icon-edificio" />
                                                )
                                            } else if (row.bCli_Tipo == "0") {
                                                return (
                                                    <i style={{ fontSize: "16px", color: "#1F60AB" }} className="icon-Persona" />
                                                )
                                            }
                                        })()}{" "}
                                        {" "}
                                        <label style={{ color: "#1F60AB" }} className="text-detalle">
                                            {row.cCli_NombreCompleto}
                                        </label>{" "}
                                    </Col >
                                    <div className="col-md-6">
                                        {GetParticipantesALL.map((row2, i) => {
                                            if (row.nCas_Id == row2.nCas_Id) {
                                                if (row2.cCas_Tab_TipoEquipo == "UC01" || row2.cCas_Tab_TipoEquipo == "UC02") {
                                                    return (
                                                        <div className="col-md-1" style={{ width: "25px", bottom: "12px", marginTop: '0px', paddingTop: "0px", float: 'left' }}>
                                                            <div>
                                                                <ReactTooltip id={"tooltip" + i + row2.label}>
                                                                    {row2.label}{" "}{"-"}{" "}
                                                                    {row2.cCas_Tab_TipoEquipo2}
                                                                </ReactTooltip>
                                                            </div>
                                                            <div data-tip data-for={"tooltip" + i + row2.label}>
                                                                <LetteredAvatar
                                                                    name={row2.label}
                                                                    size={23}
                                                                    radius={50}
                                                                    color="#fff"
                                                                    backgroundColors={arrayWithColors}
                                                                    style={{ paddingTop: '0px', marginTop: '0px' }}
                                                                />
                                                            </div>
                                                        </div>)
                                                }
                                            }
                                        })}
                                    </div>
                                </div>
                                <div className="active">
                                    <i style={{ fontSize: "16px" }} className="icon-calendario" />
                                    <label className="text-case">
                                        Apertura {row.dCas_Fec_Ini_Interno2}
                                    </label>
                                </div>
                            </div>
                        </div>
                    ))}
                </form>
            </Card>
        </Col>
    )
}
export default ListaCaso