import React, { Component, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTags from "react-meta-tags"
import { MDBDataTable } from "mdbreact"
import "../../assets/scss/tablescss/FormatTable.scss"
import Swal from "sweetalert2"
import { getDecryptedItem, setEncryptedItem } from '../../util';
import {
    CardHeader,
    CardText,
    CardBody,
    Card,
    Col,
    Container,
    CardTitle,
    Button,
    Row,
    Modal,
    Title,
    Text,
    Form,
    FormGroup,
    TabContent,
    TabPane,
    FormText,
    Label,
    Input,
    CustomInput,
    CheckBox,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap"
import instance from '../../config'
import { post } from "helpers/api_helper"

const baseUrl = process.env.REACT_APP_API_URL
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
const Suscriptores = props => {
    const [modalInsertar, setModalInsertar] = useState(false)
    const [tituloModal, setTituloModal] = useState("Nueva Fase")
    const [hiddenBtnEditar, setHiddenBtnEditar] = useState(true)
    const [hiddenBtnEliminar, setHiddenBtnEliminar] = useState(true)
    const [modalActualizar, setModalActualizar] = useState(false)
    const [modalFiltro, setModalFiltro] = useState(false)
    const [data, setData] = useState([
        { 'FASE': 'Presentacion de demanda', 'TAREAS': '4', 'LISTAS': '5', 'DURACION': '11' },
        { 'FASE': 'Diligencia de Emplazamiento', 'TAREAS': '4', 'LISTAS': '5', 'DURACION': '11' },
        { 'FASE': 'Ejecución de sentencia', 'TAREAS': '4', 'LISTAS': '5', 'DURACION': '11' },
        { 'FASE': 'Diligencia de Emplazamiento', 'TAREAS': '4', 'LISTAS': '5', 'DURACION': '11' },

    ])
    const [dataPlan, setDataPlan] = useState([])
    const [dataCategoria, setDataCategoria] = useState([])
    const [dataFormaPago, setDataFormaPago] = useState([])
    const [dataCanal, setDataCanal] = useState([])
    const [dataTipoPlan, setTipoPlan] = useState([])
    const [subTitulo, setSubTitulo] = useState([])
    const [getSus, setSus] = useState({
        nSus_Id: "",
        nPla_ID: "",
        nPro_ID: "",
        cSus_Codigo: "",
        cSus_Categoria: "",
        cSus_Nombres: "",
        dSus_FechaVencimiento: "",
        dSus_FechaVencimiento2: "",
        dSus_FechaCreacion: "",
        dSus_FechaCreacion2: "",
        dSus_UltimoPago: "",
        dSus_UltimoPago2: "",
        cSus_Correo: "",
        cSus_Pass: "",
        cSus_Telefono: "",
        cSus_Empresa: "",
        cSus_CadenaConexion: "",
        cSus_Cuenta: "",
        bSus_Estado: 1,
        bSus_Visto: 1,
        cSus_Fac_Pais: "",
        cSus_Fac_PrimerNombre: "",
        cSus_Fac_Apellido: "",
        cSus_Fac_Correo: "",
        cSus_Fac_Compania: "",
        cSus_Fac_Telefono: "",
        cSus_Fac_DireccionL1: "",
        cSus_Fac_DireccionL2: "",
        cSus_Fac_DireccionL3: "",
        cSus_Fac_Ciudad: "",
        cSus_Fac_Departamento: "",
        cSus_Fac_CodigoPostal: "",
        nSus_Fac_Plan: "",
        cSus_Fac_Canal: "",
        cSus_Fac_tab_FormaPago: "",
        cSus_Fac_Ruc: "",
        dSus_Fac_ProxPago: "",
        dSus_Fac_ProxPago2: ""
    })
    const [validData, setValidData] = useState(false)
    const [getErrores, setErrores] = useState({});
    const [dataFlujos, setDataFlujo] = useState([])
    const [getFase, setFase] = useState({
        nfFas_Id: "",
        nfFlu_Id: "",
        cfFas_Nombre: "",
        cfFas_Estado: ""
    })
    const [getFlujo, setFlujo] = useState({
        nfFlu_Id: "",
        cfFlu_Nombre: "",
        cfFlu_Descripcion: "",
    })
    const [dataFases, setDataFases] = useState([])
    const [getFiltro, setFiltro] = useState({
        FechaInicial: ano + "-" + "01" + "-" + "01",
        FechaFin: ano + "-" + mes + "-" + dia,
    })
    const Validacion = (form) => {
        let errors = {};
        let regexLetras = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
        let regexCorreo = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
        let regexNumero = /^[0-9]+$/;

        if (form.cfFas_Nombre == null || form.cfFas_Nombre == "" || form.cfFas_Nombre.trim() == "") {
            errors.cfFas_Nombre = "(*)"
        }

        return errors;
    }
    const abrirCerrarModal = Modal => {
        if (Modal == 1) {
            setValidData(false);
            setFase({
                nfFas_Id: "",
                nfFlu_Id: "",
                cfFas_Nombre: "",
                cfFas_Estado: ""
            })
            if (!modalInsertar == false) {
                Swal.fire({
                    html: "¿Seguro que desea salir sin guardar los datos?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si",
                    cancelButtonText: "No",
                }).then(result => {
                    if (result.isConfirmed) {
                        setModalInsertar(!modalInsertar)
                    }
                })
            } else {
                setModalInsertar(!modalInsertar)
                setTituloModal("Nueva fase");
            }
        } else if (Modal == 2) {
            setModalActualizar(!modalActualizar)
        } else if (Modal == 3) {
            setModalFiltro(!modalFiltro)
        }
    }
    const FuncionBuscarPlan_PorTipoPlan = async (nPro_ID) => {
        var postData = {
            nPro_ID: nPro_ID
        }
        await instance
            .post("/Plan/PlanesBuscar_porTipoPlan", postData)
            .then(response => {

                setDataPlan(response.data)

            })
            .catch(error => {

            })
    }

    const handleChange = e => {
        const { name, value } = e.target
        setFase(prevState => ({
            ...prevState,
            [name]: value,
        }))


    }
    const handleBlur = e => {
        handleChange(e)

        setErrores(Validacion(getFase))

    }
    const Acciones = (fase, opcion) => {

        setFase(fase)
        if (opcion == 1) {
            setModalInsertar(!modalInsertar)
            setTituloModal("Editar fase");
        }
        else {
            setValidData(false);
            setErrores(Validacion(getFase))
            setEncryptedItem('nfFas_Id', fase.nfFas_Id);
            location.href = "FlujosTarea";
        }


    }
    const datas = {
        columns: [
            {
                label: "Fase",
                field: "cfFas_Nombre",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "Tareas",
                field: "CantidadTarea",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "Listas",
                field: "CantidadLista",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },


        ],
        rows: dataFases,
    }
    const AbrirNv = (Url) => {
        window.open(Url, '_blank')
    }
    const badgesData = {
        columns: [
            ...datas.columns,
            {
                label: "Acciones",
                field: "acciones",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
        ],
        rows: [
            ...datas.rows.map((row, id) => ({
                ...row,
                cuenta: (
                    <div>
                        <Link onClick={() => AbrirNv(row.cSus_Cuenta)}>
                            {row.cSus_Cuenta}
                        </Link>
                    </div>
                ),
                acciones: (
                    <div>
                        <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="dropdown-toggle">
                                <i className="bx bx-dots-vertical-rounded" />
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => Acciones(row, 2)} >
                                    <Link>
                                        Ver tareas
                                    </Link>
                                </DropdownItem>

                                <DropdownItem onClick={() => Acciones(row, 1)} hidden= {hiddenBtnEditar}>
                                    <Link>
                                        Editar
                                    </Link>
                                </DropdownItem>

                                <DropdownItem hidden= {hiddenBtnEliminar}
                                    onClick={() =>
                                        Swal.fire({
                                            title: "Mensaje",
                                            html:
                                                "¿Esta seguro de eliminar la fase?",
                                            icon: "warning",
                                            showCancelButton: true,
                                            confirmButtonColor: "#3085d6",
                                            cancelButtonColor: "#d33",
                                            confirmButtonText: "Aceptar",
                                            cancelButtonText: "Cancelar",
                                        }).then(result => {
                                            if (result.isConfirmed) {
                                                FuncionEliminarFase(row.nfFas_Id)
                                            }
                                        })
                                    }
                                >
                                    Eliminar
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                ),
            })),
        ],
    }
    const FuncionListarFlujos = async () => {
        await instance
            .post("/Flujo/Listar")
            .then(response => {
                setDataFlujo(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionAgregarFase = async () => {

        if (getFase.cfFas_Nombre == undefined || getFase.cfFas_Nombre == null || getFase.cfFas_Nombre.trim() == "") {
            Swal.fire({
                title: "Mensaje",
                text: "Ingrese un nombre para la fase",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
            setValidData(true);
        }
        else {
            if (getFase.nfFas_Id) {
                FuncionActualizarFase();

            }
            else {
                var postData = {
                    nfFlu_Id: parseInt(getFlujo.nfFlu_Id),
                    cfFas_Nombre: getFase.cfFas_Nombre,
                }


                await instance
                    .post("/Fase/Insertar", postData)
                    .then(response => {

                        FuncionListarFases();

                        Swal.fire({
                            title: "Mensaje",
                            text: "Agregado exitosamente",
                            icon: "success",
                            confirmButtonText: "Aceptar",
                        })

                        setFase(prevState => ({
                            ...prevState,
                            cfFas_Nombre: "",
                        }))

                        //location.href = "/FlujosTarea";
                        setModalInsertar(!modalInsertar)

                    });
            }
        }
    }
    const FuncionListarFases = async () => {
        let _nfFlu_Id = getDecryptedItem("nfFlu_Id");
        var postData = {
            nfFlu_Id: _nfFlu_Id
        }

        await instance
            .post("/Fase/Listar", postData)
            .then(response => {
                setDataFases(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionBuscarFlujo = async () => {
        let _nfFlu_Id = getDecryptedItem("nfFlu_Id");
        var postData = {
            nfFlu_Id: _nfFlu_Id
        }
        await instance
            .post("/Flujo/Obtener", postData)
            .then(response => {
                setFlujo(response.data[0])

            })
            .catch(error => {

            })
    }

    const FuncionActualizarFase = async () => {
        var postData = {
            nfFas_Id: parseInt(getFase.nfFas_Id),
            cfFas_Nombre: getFase.cfFas_Nombre,
        }

        await instance
            .post("/Fase/Actualizar", postData)
            .then(response => {
                FuncionListarFases();
                Swal.fire({
                    title: "Mensaje",
                    text: "Agregado exitosamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })
                setFase(prevState => ({
                    ...prevState,
                    cfFas_Nombre: "",
                }))
                //location.href = "/FlujosTarea";
                setModalInsertar(!modalInsertar)
            });


    }
    const FuncionEliminarFase = async (_nfFas_Id) => {
        var postData = {
            nfFas_Id: parseInt(_nfFas_Id)
        }

        await instance
            .post("/Fase/FaseEliminar", postData)
            .then(response => {

                FuncionListarFases();

            })
            .catch(error => {

            })
    }

    const FuncionListarPermisos = async () => {
        var postData = {
          nUsu_ID: parseInt(getDecryptedItem("RolID"))
        }
        await instance
        .post("/Usuario/Acceso", postData)
        .then(response => {
        let arrayPermisos = response.data;
        const permiso = arrayPermisos.find(element => element.cUsu_Modulo == "ME03");

        if (permiso.bUsu_Editar == 1) {
            setHiddenBtnEditar(false);
            setModo(1);
        }
        else {
            setHiddenBtnEditar(true);
            setModo(2);
        }
        if (permiso.bUsu_Eliminar == 1) {
            setHiddenBtnEliminar(false);
        }
        else {
            setHiddenBtnEliminar(true);
        }
        })
        .catch(error => {

        })
    }

    useEffect(() => {
        FuncionListarPermisos();
        FuncionBuscarFlujo();
        FuncionListarFases();
        FuncionListarFlujos();
    }, [])
    return (
        <React.Fragment>
            <div className="page-content">
                {/* add meta title */}
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="" breadcrumbItem="" />
                    <Row>
                        <Col className="col-12 row center" style={{ paddingLeft: '33px' }}>
                            <Col className="col-12">
                                <CardTitle className="h6" style={{ fontSize: '25px', fontWeight: 'normal' }}>
                                    Flujo de trabajo : <b style={{ fontSize: "25px", textAlign: 'left' }}>{getFlujo.cfFlu_Nombre}</b>
                                </CardTitle>
                            </Col>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col className="col-12 row" >
                            <Card className="col-12">
                                <CardBody>
                                    <CardTitle className="h6" style={{ fontSize: '23px', fontWeight: 'normal' }}>
                                        Fases
                                    </CardTitle>
                                    <CardTitle className="h6" style={{ fontSize: "30px", fontSize: '15px' }}>
                                        <div style={{ textAlign: 'left' }}>
                                            <Link
                                                hidden={hiddenBtnEditar}
                                                className="btn btn-danger waves-effect waves-light "
                                                onClick={() => abrirCerrarModal(1)}>
                                                + Nueva
                                            </Link>
                                            {" "}
                                            <Link

                                                className="btn btn-danger waves-effect waves-light "
                                                to="/Flujo">
                                                Retornar
                                            </Link>
                                        </div>
                                    </CardTitle>

                                    <Row style={{ marginTop: '40px' }}>
                                        <Row>
                                            <Col className="col-10">
                                                <Card>
                                                    <CardBody>

                                                        <MDBDataTable
                                                            data={badgesData}
                                                            className="TablaFases"
                                                            responsiveXl
                                                            fixed
                                                            hover
                                                            entriesLabel="Mostrar"
                                                            entrylabel={"Mostrar entradas"}
                                                            infoLabel={["Mostrando ", "a", "de", "registros"]}
                                                            bordered
                                                            order={["age", "desc"]}
                                                            noRecordsFoundLabel={"No hay datos"}
                                                            searchLabel={"Buscar"}
                                                            noBottomColumns
                                                            paginationLabel={["Anterior", "Siguiente"]}
                                                            theadColor="HeadBlue"
                                                        />
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Modal size="l" isOpen={modalInsertar}>
                        <div className="modal-header ModalHeaderColor">
                            <h5
                                style={{ color: "#fff" }}
                                className="modal-title mt-0"
                                id="myModalLabel"
                            >
                                {tituloModal}
                            </h5>
                            <button
                                type="button"
                                onClick={() => abrirCerrarModal(1)}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3 row">
                                <div className="col-md-12">
                                    <input
                                        disabled
                                        className="form-control inputDown"
                                        type="text"
                                        placeholder="Nombre de la fase"
                                        name="cfFas_Nombre"
                                        value={getFlujo.cfFlu_Nombre}
                                    />
                                    <label className="col-form-label labelDown" style={{ marginLeft: '0px' }}>
                                        Flujo
                                    </label>
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <div className="col-md-12">
                                    <input
                                        className={getErrores.cfFas_Nombre && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                                        type="text"
                                        placeholder="Nombre de la fase"
                                        name="cfFas_Nombre"
                                        value={getFase && getFase.cfFas_Nombre}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        maxLength="200"
                                    />
                                    <label className="col-form-label labelDown" style={{ marginLeft: '0px' }}>Nombre</label>{getErrores.cfFas_Nombre && <label className="col-form-label labelDown labelRed">{getErrores.cfFas_Nombre}</label>}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={() => abrirCerrarModal(1)}
                                className="btn btn-secondary waves-effect"
                                data-dismiss="modal"
                            >
                                Cerrar
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger waves-effect waves-light"
                                onClick={() => FuncionAgregarFase()}
                            >
                                Guardar
                            </button>
                        </div>
                    </Modal>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default Suscriptores