import axios from 'axios';
const urlApi = process.env.REACT_APP_API_URL
const token =  localStorage.getItem('token');

const instance = axios.create({
  baseURL: urlApi
});

instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
export default instance;
