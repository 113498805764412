import React, { Component, useState, useEffect } from "react"
import Select from "react-select"
import Swal from "sweetalert2"
import { getDecryptedItem } from '../../util';
import {
    CardBody,
    Card,
    Col,
    Container,
    CardTitle,
    Button,
    Row,
    Modal,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
} from "reactstrap"
import instance from '../../config'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faDownload,
    faEye,
    faTrash,
} from "@fortawesome/free-solid-svg-icons"
const baseUrl = process.env.REACT_APP_API_URL
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
var idClient = undefined;
var ttlCaso = undefined;
var codExt = undefined;


const ModalEgreso = ({ show, onCloseClick, ReloadLista, Titulo, Row, mode, modo, egreso }) => {
    const [modalInsertar, setModalInsertar] = useState(false)
    const [dataCliente, setDataCliente] = useState([])
    const [dataComboMoneda, setDataComboMoneda] = useState([])
    const [dataComboTEgreso, setDataComboTEgreso] = useState([])
    const [dataComboMPago, setDataComboMPago] = useState([])

    const [data, setData] = useState([])
    const [dataCaso, setDataCaso] = useState([])
    const [dataComboUsuario, setDataComboUsuario] = useState([])
    const [dataComboCuenta, setDataComboCuenta] = useState([])
    const [dataCodInterno, setdataCodInterno] = useState("")
    const [dataCodExterno, setdataCodExterno] = useState("")
    const [disable, setDisable] = useState(false)
    const [disableTpoCambio, setDisableTpoCambio] = useState(true)
    const [blockBtn, setBlockBtn] = useState(false)
    const [getErrores, setErrores] = useState({});
    const [modalImg, setModalImg] = useState(false)
    const [dataTitulo, setdataTitulo] = useState("")
    const [getEgreso, setEgreso] = useState({
        nEge_Id: "",
        nCas_Id: getDecryptedItem("nCas_Id"),
        nCli_Id: getDecryptedItem("CLIID"),
        cCas_Cod_Externo: "",
        cCas_Cod_Interno: "",
        nCue_Id: "",
        cEge_Tab_Tipo: "",
        cEge_Tab_FormaPago: "",
        cIng_Tab_Moneda: "MO01",
        dEge_TCambio: 0,
        dEge_TCambioFormat: 0,
        dEge_TCambioOld: 0,
        nEge_Monto: 0,
        nEge_MontoFormat: 0,
        nEge_MontoOld: 0,
        cEge_Detalle: "",
        nUsu_ID: "",
        cEge_Archivo: "",
        bEge_Estado: "1",
        dEge_Fecha: ano + "-" + mes + "-" + dia,
        ImgBinary: "",
    })
    const [hiddenBtnGuardar, setHiddenBtnGuardar] = useState(false)
    const [hiddenBtnEditar, setHiddenBtnEditar] = useState(true)
    const [hiddenBtnEliminar, setHiddenBtnEliminar] = useState(true)
    const [validData, setValidData] = useState(false)
    const [getUrlFile, setUrlFile] = useState("")
    const Validacion = (form) => {
        let errors = {};
        let regexLetras = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
        let regexCorreo = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
        let regexNumero = /^[0-9]+$/;

        if (form.nCas_Id == 0 || form.nCas_Id == null) {
            errors.nCas_Id = "*"
        }
        if (form.nCli_Id == 0 || form.nCli_Id == null) {
            errors.nCli_Id = "*"
        }
        if (form.nCue_Id == 0) {
            errors.nCue_Id = "*"
        }
        if (form.cEge_Tab_FormaPago == 0) {
            errors.cEge_Tab_FormaPago = "*"
        }
        if (form.cIng_Tab_Moneda == 0) {
            errors.cIng_Tab_Moneda = "*"
        }
        if (form.cIng_Tab_Moneda == "MO02") {
            if (form.dEge_TCambio == 0) {
                errors.dEge_TCambio = "*"

            }
        }
        if (form.nEge_Monto == 0) {
            errors.nEge_Monto = "*"
        }
        if (form.cEge_Num_Ope == "") {
            errors.cEge_Num_Ope = "*"
        }
        if (form.cEge_Tab_Tipo == 0) {
            errors.cEge_Tab_Tipo = "*"
        }

        if (form.nUsu_ID == 0) {
            errors.nUsu_ID = "*"
        }

        return errors;
    }
    const handleBlur = e => {
        handleChange(e)
        setErrores(Validacion(getEgreso))


    }
    const BluerChange = e => {
        const { name, value } = e.target
        if (name == "nEge_Monto") {
            setEgreso(prevState => ({
                ...prevState,
                [name]: getEgreso.nEge_MontoFormat,
            }))
        } else if (name == "dEge_TCambio") {
            setEgreso(prevState => ({
                ...prevState,
                dEge_TCambio: getEgreso.dEge_TCambioFormat,
            }))
        }
        setErrores(Validacion(getEgreso))


    }
    const FocusChange = e => {
        const { name, value } = e.target
        if (name == "nEge_Monto") {
            setEgreso(prevState => ({
                ...prevState,
                [name]: getEgreso.nEge_MontoOld,
            }))
        } else if (name == "dEge_TCambio") {
            setEgreso(prevState => ({
                ...prevState,
                [name]: getEgreso.dEge_TCambioOld,
            }))
        }
    }
    const handleChange = e => {
        const { name, value } = e.target
        if (name == "nEge_Monto") {
            setEgreso(prevState => ({
                ...prevState,
                nEge_Monto: value,
            }))
            setEgreso(prevState => ({
                ...prevState,
                nEge_MontoOld: value,
            }))
            setEgreso(prevState => ({
                ...prevState,
                nEge_MontoFormat: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(value),
            }))
        } else if (name == "dEge_TCambio") {
            setEgreso(prevState => ({
                ...prevState,
                dEge_TCambio: value,
            }))
            setEgreso(prevState => ({
                ...prevState,
                dEge_TCambioOld: value,
            }))
            setEgreso(prevState => ({
                ...prevState,
                dEge_TCambioFormat: new Intl.NumberFormat("en-US", { key: "en-US", value: "English (US)", minimumFractionDigits: 3, }).format(value),
            }))
        } else {
            setEgreso(prevState => ({
                ...prevState,
                [name]: value,
            }))
            if (name == "cIng_Tab_Moneda") {

                if (value == "MO01") {
                    setDisableTpoCambio(true);
                }
                else if (value == "0") {
                    setDisableTpoCambio(true);
                }
                else {
                    setDisableTpoCambio(false);
                }
            }
        }

        if (e.target.name == "nCas_Id") {
            FuncionInformacionCaso(e.target.value)
            FuncionListarComboUsuario(e.target.value)
        }
        if (e.target.name == "nCli_Id") {
            idClient = e.target.value;
            if (e.target.value !== 0) {
                FuncionListarCasos(e.target.value)
            }
        }
        if (e.target.name == "nUsu_ID") {

            setEgreso(prevState => ({
                ...prevState,
                nUsu_ID: value,
            }))
        }

    }



    const FuncionCargarImg = async (nameFile) => {
        await instance
            .post("/getfile/" + getDecryptedItem("ID") + '/' + nameFile, {}, { responseType: "blob" })
            .then(response => {
                const theImage = URL.createObjectURL(response.data);
                var frame = document.createElement("iframe")
                frame.src = theImage;
                frame.setAttribute("style", "width:100%;height:600px")
                document.getElementById("dase64_img").innerHTML = frame.outerHTML
            })
            .catch(error => {

            })
    }

    const validateFileType = async () => {
        var fileName = document.getElementById("cEge_Archivo").value
        var idxDot = fileName.lastIndexOf(".") + 1
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase()
        if (
            extFile == "jpg" ||
            extFile == "jpeg" ||
            extFile == "png" ||
            extFile == "pdf" ||
            extFile == "rar" ||
            extFile == "zip"
        ) {
            var Valor = document.getElementById("cEge_Archivo").files[0].name
            console.log(Valor);
            console.log(document.getElementById("cEge_Archivo").files[0]);
            setEgreso(prevState => ({
                ...prevState,
                cEge_Archivo: Valor,
                ImgBinary: document.getElementById("cEge_Archivo").files[0]
            }))

            console.log(getEgreso);
        } else {
            document.getElementById("cEge_Archivo").value = ""
            setEgreso(prevState => ({
                ...prevState,
                cEge_Archivo: "",
            }))
            Swal.fire({
                title: "Mensaje",
                text: "El archivo debe ser formado .pdf o .jpg",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        }
    }

    function encodeImageFileAsURL() {
        var filesSelected = document.getElementById("cEge_Archivo").files
        if (filesSelected.length > 0) {
            var fileToLoad = filesSelected[0]
            var fileReader = new FileReader()
            fileReader.onload = function (fileLoadedEvent) {
                var srcData = fileLoadedEvent.target.result


                var newImage = document.createElement("iframe")
                newImage.src = srcData
                newImage.setAttribute("style", "width:100%;height:600px")
                // Create Base64 Object
                var array = srcData.split(",")


                var fileName = document.getElementById("cEge_Archivo").value
                var idxDot = fileName.lastIndexOf(".") + 1
                var extFile = fileName.substr(idxDot, fileName.length).toLowerCase()
                if (extFile == "rar" || extFile == "zip") {
                    const name = "<a>Este archivo no tiene vista previa</a>"
                    document.getElementById("dase64_img").innerHTML = name
                } else {
                    document.getElementById("dase64_img").innerHTML = newImage.outerHTML
                }
            }
            fileReader.readAsDataURL(fileToLoad)
        }
    }
    const FuncionListarCliente = async () => {
        let RolId = getDecryptedItem("RolID")

        var postData = {
            bCli_Tipo: null,
            nUsu_ID: RolId == 6 || RolId == 1 ? null : getDecryptedItem("ID")
        }
        await instance
            .post("/Cliente/ListarCbo", postData)
            .then(response => {
                console.log(response);
                setDataCliente(response.data)

            })
            .catch(error => {

            })
    }
    const handleChangeFiltroCaso = e => {


        idClient = e.target.value;
        if (e.target.value) {
            FuncionListarCasos(null)
        } else {
            FuncionListarCasos(e.target.value)
        }
    }
    const FuncionListarCasos = async (_idClient) => {
        var postData = {
            idClient: _idClient,
            nUsu_Id: getDecryptedItem("ID"),
        }

        console.log("Listando casos :", postData);

        await instance
            .post("/Casos/ListarCbo", postData)
            .then(response => {
                console.log("Listando casos");
                console.log(response.data);
                setDataCaso(response.data)

                if (modo == 0) {

                    console.log("es modo 0")
                    console.log(getDecryptedItem("nCas_Id"))

                    let caso = response.data.find((caso) => caso.nCas_Id == getDecryptedItem("nCas_Id"));
                    console.log("caso : ", caso)
                    ttlCaso = caso.cCas_Titulo;
                    codExt = caso.cCas_Cod_Externo;

                    setEgreso(prevState => ({
                        ...prevState,
                        nCas_Id: caso.nCas_Id,
                        cCas_Cod_Externo: caso.cCas_Cod_Externo,
                        cCas_Cod_Interno: caso.cCas_Cod_Interno,
                    }))


                    console.log(ttlCaso);
                    console.log(codExt);
                }


            })
            .catch(error => {

            })
    }
    const FuncionInformacionCaso = async (_nCas_Id) => {
        var postData = {
            nCas_Id: _nCas_Id,
        }
        await instance
            .post("/Casos/Buscar", postData)
            .then(response => {
                ttlCaso = response.data[0]["cCas_Titulo"];

                codExt = response.data[0].cCas_Cod_Externo;
                setdataCodInterno(response.data[0].cCas_Cod_Interno)
                setdataCodExterno(response.data[0].cCas_Cod_Externo)
            })
            .catch(error => {

            })
    }
    const FuncionListarEgresos = async () => {
        await instance
            .get("/Egresos/Listar")
            .then(response => {
                setData(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionListarComboMoneda = async () => {
        var postData = {
            Prefijo: "MO",
        }
        await instance
            .post("/Maestros/Data", postData)
            .then(response => {
                setDataComboMoneda(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionListarComboTEgreso = async () => {
        var postData = {
            Prefijo: "NA",
        }
        await instance
            .post("/Maestros/Data", postData)
            .then(response => {
                setDataComboTEgreso(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionListarComboMetodoPago = async () => {
        var postData = {
            Prefijo: "FP",
        }
        await instance
            .post("/Maestros/Data", postData)
            .then(response => {
                setDataComboMPago(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionListarComboUsuario = async (nCas_Id) => {

        var postData = {
            nCas_Id: nCas_Id,
        }
        await instance
            .post("/Casos/FiltroUsuarioCaso", postData)
            .then(response => {

                setDataComboUsuario(response.data)

            })
            .catch(error => {

            })
    }

    const FuncionCorreoEvento = async (_nCas_Id, _nUsu_Id, _cCor_Tab_Tipo, _cCor_Asunto) => {
        var postData = {
            nCas_Id: _nCas_Id,
            nUsu_Id: _nUsu_Id,
            cCor_Tab_Tipo: _cCor_Tab_Tipo,
            cCor_Asunto: _cCor_Asunto,
        }

        await instance
            .post("/Correo/Insertar", postData)
            .then(response => {
            })
            .catch(error => {

            })
    }

    const FuncionAgregarEgreso = async () => {
        setBlockBtn(true)
        var postData = {
            nCas_Id: Number.parseInt(getEgreso.nCas_Id),
            nCue_Id: Number.parseInt(getEgreso.nCue_Id),
            cEge_Tab_Tipo: getEgreso.cEge_Tab_Tipo,
            cIng_Tab_Moneda: getEgreso.cIng_Tab_Moneda,
            dEge_Fecha: getEgreso.dEge_Fecha,
            cEge_Tab_FormaPago: getEgreso.cEge_Tab_FormaPago,
            dEge_TCambio: parseFloat(getEgreso.dEge_TCambioOld),
            nEge_Monto: parseFloat(getEgreso.nEge_MontoOld),
            cEge_Detalle: getEgreso.cEge_Detalle,
            nUsu_ID: getDecryptedItem("ID"),
            cEge_Archivo: getEgreso.cEge_Archivo,
            cEge_Num_Ope: getEgreso.cEge_Num_Ope,
            bEge_Estado: Number.parseInt(getEgreso.bEge_Estado),
        }

        const formdata = new FormData()
        formdata.append("image", getEgreso.ImgBinary)
        formdata.append("nCas_Id", Number.parseInt(getEgreso.nCas_Id))
        formdata.append("nCue_Id", Number.parseInt(getEgreso.nCue_Id))
        formdata.append("cEge_Tab_Tipo", getEgreso.cEge_Tab_Tipo)
        formdata.append("cIng_Tab_Moneda", getEgreso.cIng_Tab_Moneda)
        formdata.append("dEge_Fecha", getEgreso.dEge_Fecha)
        formdata.append("cEge_Tab_FormaPago", getEgreso.cEge_Tab_FormaPago)
        formdata.append("dEge_TCambio", parseFloat(getEgreso.dEge_TCambioOld))
        formdata.append("nEge_Monto", parseFloat(getEgreso.nEge_MontoOld))
        formdata.append("cEge_Detalle", getEgreso.cEge_Detalle)
        formdata.append("nUsu_ID", getDecryptedItem("ID"))
        formdata.append("cEge_Archivo", getEgreso.cEge_Archivo)
        formdata.append("cEge_Num_Ope", getEgreso.cEge_Num_Ope)
        formdata.append("bEge_Estado", 1)

        var idEge;

        await instance
            .post("/Egresos/Insertar", formdata, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(async response => {


                idEge = response.data[0].nEge_Id; // este es el id del evento creado.
                let nUsu_ID = getDecryptedItem("ID")
                let postData2 = {
                    cAud_nombre: "Egresos",
                    nAud_IdReg: idEge,
                    nUsu_ID: getDecryptedItem("ID"),
                    dAud_Fecha: null,
                    nAud_Accion: "I",
                    cTabCodigo: 'ME07'
                }
                await instance
                    .post("/Auditoria/Insertar", postData2)
                    .then(response => {
                    });



                Swal.fire({
                    title: "Mensaje",
                    text: "Agregado exitosamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })
                setBlockBtn(false)
                onCloseClick();
                ReloadLista();
            })
            .catch(error => {
                setBlockBtn(false)
            })

        if (idClient == undefined || idClient == null) {
            idClient = getDecryptedItem('CASOIDCli') || getDecryptedItem("CLIID")
        }

        let objMails = {
            idEge: parseInt(idEge),
            idClient: parseInt(idClient),
            ttlCaso: ttlCaso,
            codExt: codExt,
            parametro: {
                tpoTbl: 'egreso',   // tipo de tabla
                tpoAct: 'insertar'  // insertar o actualizar
            },
            dataEgreso: postData,
        }

        console.log(objMails);

        await instance
            .post("/mail/event/egreso", objMails)
            .then(res => {
            })
            .catch(error => {

            })


    }

    const FuncionActualizarEgreso = async () => {

        const formdata = new FormData()
        formdata.append("image", getEgreso.ImgBinary)
        formdata.append("nEge_Id", Number.parseInt(getEgreso.nEge_Id))
        formdata.append("nCas_Id", Number.parseInt(getEgreso.nCas_Id))
        formdata.append("nCue_Id", Number.parseInt(getEgreso.nCue_Id))
        formdata.append("cEge_Tab_Tipo", getEgreso.cEge_Tab_Tipo)
        formdata.append("cIng_Tab_Moneda", getEgreso.cIng_Tab_Moneda)
        formdata.append("dEge_Fecha", getEgreso.dEge_Fecha)
        formdata.append("cEge_Tab_FormaPago", getEgreso.cEge_Tab_FormaPago)
        formdata.append("dEge_TCambio", parseFloat(getEgreso.dEge_TCambioOld))
        formdata.append("nEge_Monto", parseFloat(getEgreso.nEge_MontoOld))
        formdata.append("cEge_Detalle", getEgreso.cEge_Detalle)
        formdata.append("nUsu_ID", getDecryptedItem("ID"))
        formdata.append("cEge_Archivo", getEgreso.cEge_Archivo)
        formdata.append("cEge_Num_Ope", getEgreso.cEge_Num_Ope)
        formdata.append("bEge_Estado", 1)

        await instance
            .post("/Egresos/Actualizar", formdata, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(async response => {

                console.log(response);
                let postData2 = {
                    cAud_nombre: "Egresos",
                    nAud_IdReg: getEgreso.nEge_Id,
                    nUsu_ID: getDecryptedItem("ID"),
                    dAud_Fecha: null,
                    nAud_Accion: "U",
                    cTabCodigo: 'ME07'
                }
                await instance
                    .post("/Auditoria/Insertar", postData2)
                    .then(response => {
                    });

                Swal.fire({
                    title: "Mensaje",
                    text: "Egreso actualizado exitosamente!",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })

                onCloseClick();
                ReloadLista();
            })
            .catch(error => {

            })
    }

    const FuncionValidarFormulario = async () => {
        if (Object.keys(getErrores).length === 0) {
            //setBlockBtn(true);


            if (modo == 0) {
                FuncionAgregarEgreso();
            }
            else if (modo == 1) {
                FuncionActualizarEgreso();
            }


        } else {
            setValidData(true);
            Swal.fire({
                title: "Mensaje",
                text: "Complete los campos requeridos",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        }
    }

    const FuncionListarComboCuenta = async () => {
        await instance
            .get("/CBanco/Listar/Combo")
            .then(response => {
                setDataComboCuenta(response.data)

            })
            .catch(error => {

            })
    }

    const abrirCerrarModal = Modal => {
        if (Modal == 1) {
            if (!modalInsertar == false) {
                Swal.fire({
                    html: "¿Seguro que desea salir sin guardar los datos?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si",
                    cancelButtonText: "No",
                }).then(result => {
                    if (result.isConfirmed) {
                        setModalInsertar(!modalInsertar)
                    }
                })
            } else {
                setModalInsertar(!modalInsertar)
            }
        } else if (Modal == 2) {
            setModalActualizar(!modalActualizar)
        } else if (Modal == 3) {
            setModalVer(!modalVer) /////////// AQUI SE LLAMA AL MODAL VER TAREA !
        }
        else if (Modal == 4) {
            setModalFiltro(!modalFiltro)
        } else if (Modal == 5) {
            VerFile(getEgreso.cEge_Archivo)
        }
    }

    const FuncionModCall = async () => {

        console.log("modo");
        console.log(modo);


        if (modo !== 0 && egreso.cIng_Tab_Moneda !== "MO01") {
            setDisableTpoCambio(false);
        }
        if (modo !== 0) {
            setErrores(Validacion(egreso))
            FuncionListarCasos(null)
        }

        setEgreso(egreso);
        FuncionListarComboUsuario(egreso.nCas_Id);
        FuncionListarCliente()
        FuncionListarComboCuenta()
        FuncionListarComboMoneda()
        FuncionListarComboTEgreso()
        FuncionListarComboMetodoPago()

        switch (modo) {
            case 0:
                console.log("opcion : ", modo);
        

                if (getDecryptedItem("nCas_Id")) {
                    console.log("dashboard");
                    setDisable(true);
                    FuncionListarComboUsuario(getDecryptedItem("nCas_Id"));
                    FuncionListarCasos(getDecryptedItem("CLIID"))
                    setEgreso(prevState => ({
                        ...prevState,
                        nCli_Id: getDecryptedItem("CLIID") || getDecryptedItem('CASOIDCli'),
                    }))
                }

                setEgreso({
                    nEge_Id: "",
                    nCas_Id: "",
                    nCli_Id: "",
                    nCas_Id: getDecryptedItem("nCas_Id") || "",
                    nCli_Id: getDecryptedItem("CLIID") || "",
                    cCas_Cod_Externo: "",
                    cCas_Cod_Interno: "",
                    nCue_Id: "",
                    cEge_Tab_Tipo: "",
                    cEge_Tab_FormaPago: "",
                    cIng_Tab_Moneda: "MO01",
                    dEge_TCambio: 0,
                    dEge_TCambioFormat: 0,
                    dEge_TCambioOld: 0,
                    nEge_Monto: 0,
                    nEge_MontoFormat: 0,
                    nEge_MontoOld: 0,
                    cEge_Detalle: "",
                    nUsu_ID: "",
                    cEge_Archivo: "",
                    bEge_Estado: "1",
                    dEge_Fecha: ano + "-" + mes + "-" + dia,
                    ImgBinary: "",
                })



                return setdataTitulo("Nuevo egreso");
            case 1:
                return setdataTitulo("Editar egreso");
            case 2:
                return setdataTitulo("Ver detalle");

        }
    }

    const FuncionDeleteFile = async (nameFile) => {

        var postData = {
            nEge_Id: Number.parseInt(getEgreso.nEge_Id),
            nCas_Id: Number.parseInt(getEgreso.nCas_Id),
            nCue_Id: Number.parseInt(getEgreso.nCue_Id),
            cEge_Tab_Tipo: getEgreso.cEge_Tab_Tipo,
            cIng_Tab_Moneda: getEgreso.cIng_Tab_Moneda,
            dEge_Fecha: getEgreso.dEge_Fecha,
            cEge_Tab_FormaPago: getEgreso.cEge_Tab_FormaPago,
            dEge_TCambio: parseFloat(getEgreso.dEge_TCambioOld),
            nEge_Monto: parseFloat(getEgreso.nEge_MontoOld),
            cEge_Detalle: getEgreso.cEge_Detalle,
            nUsu_ID: getEgreso.nUsu_ID,
            cEge_Archivo: getEgreso.cEge_Archivo,
            cEge_Num_Ope: '0001',
            bEge_Estado: getEgreso.bEge_Estado,
        }

        await instance
            .post("/deleteFileEgresos", postData)
            .then(async response => {

                Swal.fire({
                    title: "Mensaje",
                    text: "Se elimino el archivo",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })
                ReloadLista();
            });
    }

    const FuncionDescargarFile = async (nameFile) => {
        var postData = {
            FileName: nameFile,
        }
        console.log(postData);
        await instance
            .post("/Egreso/DownloadFile", postData)
            .then(response => {
                const Url = response.data.data
                var win = window.open(Url, '_blank');
                win.focus();
            })
            .catch(error => {
            })
    }
    const VerFile = async (nameFile) => {
        var postData = {
            FileName: nameFile,
        }
        console.log(postData);
        await instance
            .post("/Egreso/DownloadFile", postData)
            .then(response => {
                const Url = response.data.data
                setUrlFile(Url)
                setModalImg(!modalImg)
            })
            .catch(error => {
            })
    }

    const FuncionListarPermisos = async () => {

        let rol = getDecryptedItem("RolID");
        console.log(rol);
        
        var postData = {
          nUsu_ID: parseInt(getDecryptedItem("RolID")),
        }
        await instance
          .post("/Usuario/Acceso", postData)
          .then(response => {
            let arrayPermisos = response.data;
            const permiso = arrayPermisos.find(element => element.cUsu_Modulo == "ME06");
    
            if (permiso.bUsu_Editar == 1) {
                console.log("editar");
              setHiddenBtnEditar(false);
            }
            else {
              setHiddenBtnEditar(true);
            }
            if (permiso.bUsu_Eliminar == 1) {
              setHiddenBtnEliminar(false);
            }
            else {
              setHiddenBtnEliminar(true);
            }
          })
          .catch(error => {
    
          })
    }

    useEffect(() => {

        let nCas_Id = getDecryptedItem("nCas_Id");
        console.log(nCas_Id);
        FuncionListarPermisos();
        FuncionModCall();
        let _mode = mode;
        console.log(_mode);
        if (_mode == "Readonly") {
            
            setEgreso(Row);
            setHiddenBtnGuardar(true);
            FuncionInformacionCaso(Row.nCas_Id)
            FuncionListarComboUsuario(Row.nCas_Id)
            setdataTitulo("Ver egreso");
        }
        else{
            console.log(Row);
        }

     
        
    }, [])
    return (
        <Modal isOpen={show}>
            <div
                className="modal-header ModalHeaderColor"
            >
                <h5
                    style={{ color: "#fff" }}
                    className="modal-title mt-0"
                    id="myModalLabel"
                >
                    {dataTitulo}
                </h5>
                <button
                    type="button"
                    onClick={() => onCloseClick()}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="mb-3 row">
                    <div className="col-md-12">
                        <select
                            disabled={modo == 1 || modo == 2 || disable == true}
                            className={getErrores.nCli_Id && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                            name="nCli_Id"
                            onChange={handleChange}
                            value={getEgreso && getEgreso.nCli_Id}
                            onBlur={handleBlur}
                        >
                            <option value="0">Seleccionar...</option>
                            {dataCliente.map((row, i) => (
                                <option key={i} value={row.nCli_Id}>
                                    {row.cCli_NombreCompleto}
                                </option>
                            ))}
                        </select>
                        <label className="col-form-label labelDown">Cliente</label> {getErrores.nCli_Id && <label className="col-form-label labelDown labelRed">({getErrores.nCli_Id})</label>}
                    </div>
                </div>
                <div className="mb-3 row">
                    <div className="col-md-12">
                        <select
                            disabled={modo == 1 || modo == 2 || disable == true}
                            className={getErrores.nCas_Id && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                            name="nCas_Id"
                            onChange={handleChange}
                            value={getEgreso && getEgreso.nCas_Id}
                            onBlur={handleBlur}
                        >
                            <option value="0">Seleccionar..</option>
                            {dataCaso.map((row, i) => (
                                <option key={i} value={row.nCas_Id}>
                                    {row.cCas_Titulo}
                                </option>
                            ))}
                        </select>
                        <label className="col-form-label labelDown">Caso</label> {getErrores.nCas_Id && <label className="col-form-label labelDown labelRed">({getErrores.nCas_Id})</label>}
                    </div>
                </div>
                <div className="mb-1 row">
                    <div className="col-md-5" style={{ width: '40%' }}>
                        <label
                            htmlFor="example-text-input"
                            className="col-md-12 col-form-label"
                            style={{ paddingLeft: "5px" }}
                        >
                            Cód Interno: {dataCodInterno || getEgreso.cCas_Cod_Interno}
                        </label>
                    </div>
                    <div className="col-md-7" style={{ width: '60%' }}>
                        <label
                            htmlFor="example-text-input"
                            className="col-md-12 col-form-label"
                            style={{ paddingLeft: "0px", marginLeft: '0px' }}
                        >
                            Cód Externo: {dataCodExterno || getEgreso.cCas_Cod_Externo}
                        </label>
                    </div>
                </div>
                <div className="mb-3 row" style={{ marginTop: '15px' }}>
                    <div className="col-md-6">
                        <input
                            disabled={modo == 2}
                            className="form-control"
                            type="date"
                            value={getEgreso.dEge_Fecha}
                            name="dEge_Fecha"
                            onChange={handleChange}
                        />
                        <label className="col-form-label labelDown">
                            Fecha dd/mm/aaaa
                        </label>
                    </div>
                    <div className="col-md-6">
                        <select
                            disabled={modo == 2}
                            className={getErrores.cEge_Tab_Tipo && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                            name="cEge_Tab_Tipo"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={getEgreso && getEgreso.cEge_Tab_Tipo}
                        >
                            <option value="0">Seleccionar...</option>
                            {dataComboTEgreso.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                    {row.cTabNombre}
                                </option>
                            ))}
                        </select>
                        <label className="col-form-label labelDown">Motivo</label>{getErrores.cEge_Tab_Tipo && <label className="col-form-label labelDown labelRed">({getErrores.cEge_Tab_Tipo})</label>}
                    </div>
                </div>
                <div className="mb-3 row">
                    <div className="col-md-6">
                        <select
                            disabled={modo == 2}
                            className={getErrores.cEge_Tab_FormaPago && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                            name="cEge_Tab_FormaPago"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={getEgreso && getEgreso.cEge_Tab_FormaPago}
                        >
                            <option value="0">Seleccionar...</option>
                            {dataComboMPago.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                    {row.cTabNombre}
                                </option>
                            ))}
                        </select>
                        <label className="col-form-label labelDown">Método de pago</label>{getErrores.cEge_Tab_FormaPago && <label className="col-form-label labelDown labelRed">({getErrores.cEge_Tab_FormaPago})</label>}
                    </div>
                    <div className="col-md-6">
                        <select
                            disabled={modo == 2}
                            className={getErrores.nCue_Id && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                            name="nCue_Id"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={getEgreso && getEgreso.nCue_Id}
                        >
                            <option value="0">Seleccionar...</option>
                            {dataComboCuenta.map((row, i) => (
                                <option key={i} value={row.nCue_Id}>
                                    {row.Cuentas}
                                </option>
                            ))}
                        </select>
                        <label className="col-form-label labelDown">Cuenta</label> {getErrores.nCue_Id && <label className="col-form-label labelDown labelRed">({getErrores.nCue_Id})</label>}
                    </div>
                </div>
                <div className="mb-3 row">
                    <div className="col-md-12">
                        <textarea
                            disabled={modo == 2}
                            placeholder="Ingrese la descripción"
                            className="form-control"
                            type="text"
                            name="cEge_Detalle"
                            value={getEgreso && getEgreso.cEge_Detalle}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            maxLength="200"
                        ></textarea>
                        <label className="col-form-label labelDown">
                            Descripción
                        </label>
                    </div>
                </div>
                <div className="mb-3 row">
                    <div className="col-md-4">
                        <select
                            disabled={modo == 2}
                            className={getErrores.cIng_Tab_Moneda && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                            name="cIng_Tab_Moneda"
                            onChange={handleChange}
                            value={getEgreso && getEgreso.cIng_Tab_Moneda}
                            onBlur={handleBlur}
                        >
                            <option value="0">Seleccionar</option>
                            {dataComboMoneda.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                    {row.cTabNombre}
                                </option>
                            ))}
                        </select>
                        <label className="col-form-label labelDown">Moneda</label>{getErrores.cIng_Tab_Moneda && <label className="col-form-label labelDown labelRed">({getErrores.cIng_Tab_Moneda})</label>}
                    </div>
                    <div className="col-md-4">
                        <input
                            disabled={modo == 2}
                            className={getErrores.nEge_Monto && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                            type="text"
                            placeholder="Ingrese el monto"
                            name="nEge_Monto"
                            value={getEgreso && getEgreso.nEge_Monto}
                            onChange={handleChange}
                            onBlur={BluerChange}
                            onFocus={FocusChange}
                            style={{ textAlign: "right" }}
                        />
                        <label className="col-form-label labelDown">Monto</label>{getErrores.nEge_Monto && <label className="col-form-label labelDown labelRed">({getErrores.nEge_Monto})</label>}
                    </div>
                    <div className="col-md-4" hidden={disableTpoCambio}>
                        <input
                            disabled={modo == 2}
                            className={getErrores.dEge_TCambio && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                            type="text"
                            placeholder="Tipo de cambio"
                            name="dEge_TCambio"
                            value={getEgreso && getEgreso.dEge_TCambio}
                            onChange={handleChange}
                            onBlur={BluerChange}
                            onFocus={FocusChange}
                            style={{ textAlign: "right" }}
                        />
                        <label className="col-form-label labelDown">Tipo de cambio</label>{getErrores.dEge_TCambio && <label className="col-form-label labelDown labelRed">({getErrores.dEge_TCambio})</label>}
                    </div>
                </div>
                <div className="mb-3 row">
                    <div className="col-md-12">
                        <select
                            disabled={modo == 2}
                            className={getErrores.nUsu_ID && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                            name="nUsu_ID"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={getEgreso && getEgreso.nUsu_ID}
                        >
                            <option value="0">Seleccionar...</option>
                            {dataComboUsuario.map((row, i) => (
                                <option key={i} value={row.nUsu_Id}>
                                    {row.cUsu_Nombres}
                                </option>
                            ))}
                        </select>
                        <label className="col-form-label labelDown">Usuario(responsable)</label>{getErrores.nUsu_ID && <label className="col-form-label labelDown labelRed">({getErrores.nUsu_ID})</label>}
                    </div>
                </div>
                <div className="mb-3 row">
                    <div className="col-md-9">
                        <input
                            disabled={modo == 2}
                            className="form-control"
                            type="file"
                            placeholder="Usuario"
                            name="cEge_Archivo"
                            id="cEge_Archivo"
                            onChange={validateFileType}
                        />


                        <label className="col-form-label labelDown">


                            {getEgreso.cEge_Archivo && (
                                <span>
                                    <b>Archivo : </b>
                                    <b style={{ color: 'black' }}> {getEgreso.cEge_Archivo}</b>
                                </span>
                            )}


                        </label>

                        <br></br>

                        <label className="col-form-label labelDown">
                            <b>Adjuntar máximo 5 Mb  (PDF , JPG)</b>
                        </label>
                    </div>

                    {getEgreso.nEge_Id && (
                        <>
                            <div className="col-md-1" style={{ paddingTop: '3px', cursor: 'pointer' }}
                                onClick={() => {

                                    Swal.fire({
                                        html: "¿Seguro que desea eliminar el archivo?",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonColor: "#3085d6",
                                        cancelButtonColor: "#d33",
                                        confirmButtonText: "Si",
                                        cancelButtonText: "No",
                                    }).then(result => {
                                        if (result.isConfirmed) {
                                            FuncionDeleteFile()
                                        }
                                    })
                                }}
                            >
                                <i className="icon-papelera" style={{ fontSize: '23px' }}></i>

                            </div>
                            <div className="col-md-1" onClick={() => { FuncionDescargarFile(getEgreso.cEge_Archivo) }}>
                                <FontAwesomeIcon
                                    style={{
                                        color: "black",
                                        fontSize: "20px",
                                        marginTop: "5px",
                                        cursor: 'pointer'
                                    }}
                                    icon={faDownload}
                                />
                            </div>
                            <div className="col-md-1" onClick={() => abrirCerrarModal(5)}>
                                <FontAwesomeIcon
                                    style={{
                                        color: "black",
                                        fontSize: "20px",
                                        marginTop: "5px",
                                        cursor: 'pointer'
                                    }}
                                    icon={faEye}
                                />
                            </div>
                        </>
                    )}
                </div>
                <div className="mb-3 row" style={{ marginTop: '20px', marginBottom: '0px' }}>
                    <div className="col-md-12">
                        <label className="col-form-label labelDown labelRed">(*) Campos obligatorios</label>
                        <label className="col-form-label labelDown"></label>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => onCloseClick()}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                >
                    Cerrar
                </button>
                <button
                    disabled={modo == 2 || blockBtn}
                    type="button"
                    hidden={hiddenBtnGuardar || hiddenBtnEditar || modo == 2 } 
                    className="btn btn-danger waves-effect waves-light"
                    onClick={() => FuncionValidarFormulario()}
                >
                    Guardar
                </button>
            </div>
            <Modal size="lg" isOpen={modalImg}>
                <div
                    className="modal-header"
                    style={{
                        background:
                            "linear-gradient( 180deg , #E5163A, #AF2240)",
                    }}
                >
                    <h5
                        style={{ color: "#fff" }}
                        className="modal-title mt-0"
                        id="myModalLabel"
                    >
                        Vista Previa
                    </h5>
                    <button
                        type="button"
                        onClick={() => abrirCerrarModal(5)}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <iframe title="PDF Viewer" src={getUrlFile} width="100%" height="500px" />
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => abrirCerrarModal(5)}
                        className="btn btn-secondary waves-effect"
                        data-dismiss="modal"
                    >
                        Cerrar
                    </button>
                </div>
            </Modal>
        </Modal>
    )

}

export default ModalEgreso
