import React, { Component, useState, useEffect, useContext } from "react"
import {
    CardBody,
    Card,
    Col,
    Container,
    Row,
    Modal,
    Table,
} from "reactstrap"


import ReportesContext from "pages/Menus/Context/Reportes/ReportesContext";
import FileDownload from "js-file-download";
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/tablescss/FormatTable.scss"
import instance from '../../config'
import ReactApexChart from "react-apexcharts"

import { getDecryptedItem, setEncryptedItem } from '../../util';

import ModalEvento from "pages/Componentes/ModalEvento"
import ModalTarea from "pages/Menus/Tarea/Componentes/ModalTarea"
import ComboState from "pages/Menus/Context/Combos/ComboState"
import { arrayInsert } from "redux-form"
import Swal from "sweetalert2"
const baseUrl = process.env.REACT_APP_API_URL
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
var meses = new Array("Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre");

const DashboardSuscriptor = props => {
    const reportesContext = useContext(ReportesContext);
    const [getModalEvento, setModalEvento] = useState(false)
    const [getModalTarea, setModalTarea] = useState(false)
    const [GetParticipantes, setParticipantes] = useState([])
    const [getCantidadTarea, setCantidadTarea] = useState(4)
    const [getCantidadEventos, setCantidadEventos] = useState(4)
    const [getCantidadNovedad, setCantidadNovedad] = useState(4)
    const [GetParticipantesEvento, setParticipantesEvento] = useState([])
    const [getSeriesGraficoLineaU, setSeriesGraficoLineaU] = useState([])
    const [getSeriesGraficoLineaS, setSeriesGraficoLineaS] = useState([])
    const [modalInsertar, setModalInsertar] = useState(false)
    const [getCliente_Hliquidadas, setCliente_Hliquidadas] = useState(0.00)
    const [getCliente_GXPagar, Cliente_GXPagar] = useState(0.00)
    const [getCliente_SCXGasto, Cliente_SCXGasto] = useState(0.00)
    const [docsCount, setDocsCount] = useState("0")
    const [modo, setModo] = useState(0)



    const [getNumeroFactura, setNumeroFactura] = useState({
        Factura: "",
        Codigo: "",
    })
    const [getUsuario, setUsuario] = useState([])

    const [getFiltroGrafico, setFiltroGrafico] = useState({
        M: parseInt(Fecha.getMonth() + 1),
        Y: parseInt(Fecha.getFullYear()),
    })
    const options = {
        maintainAspectRatio: false,
        labels: [" Horas laboradas", "No Facturable"],
        colors: ["#00A94F", "#AA1B0E"],
        legend: { show: !1 },
        plotOptions: {
            pie: {
                donut: {
                    size: "50%",
                },
            },
        },
    }
    const series2 = [
        {
            name: ["Facturado S/"],
            data: getSeriesGraficoLineaS,
            color: "#AA1B0E",
        },
        {
            name: ["Facturado U$"],
            data: getSeriesGraficoLineaU,
            color: "#00A94F",
        },
    ]
    const options2 = {
        chart: {
            height: 350,
            type: "line",
            zoom: {
                enabled: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "straight",
        },
        title: {
            text: "Facturado",
            align: "left",
            style: {
                fontSize: '14px',
                color: '#263238',
                fontFamily: 'texto-combex',
            },
        },
        grid: {
            row: {
                colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                opacity: 0.5,
            },
        },
        xaxis: {
            categories: [
                "Ene",
                "Feb",
                "Mar",
                "Abr",
                "May",
                "Jun",
                "Jul",
                "Ago",
                "Sep",
                "Oct",
                "Nov",
                "Dic",
            ],
        },
    }
    const options3 = {
        maintainAspectRatio: false,
        labels: ["Pendiente", "Realizadas", "Rechazadas", "Vencidos"],
        colors: ["#1F60AB", "#00A94F", "#AA1B0E", "#ad8e93"],
        legend: { show: !1 },
        plotOptions: {
            pie: {
                donut: {
                    size: "50%",
                },
            },
        },
    }

    const [data, setData] = useState([])
    const [dataCaso, setDataCaso] = useState([])
    const [dataAuditoria, setDataAuditoria] = useState([])
    const [dataNov, setDataNov] = useState([])
    const [dataTareasEstadisticas, setDataTareasEstadisticas] = useState([])
    const [dataHorasLaboradas, setHorasLaboradas] = useState([])
    const [dataLiquidacion, setDataLiquidacion] = useState([])
    const [dataLiqPendiente, setDataLiqPendiente] = useState([])
    const [gastosNoLiquidados, setGastosNoLiquidados] = useState([])
    const [dataHorasNoLiquidadas, setDataHorasNoLiquidadas] = useState([])
    const [getInfoSuscriptor, setInfoSuscriptor] = useState([])
    const [nombreUsuario, setNombreUsuario] = useState("")
    const [HorasLabHL, setHorasHorasLabHL] = useState("")
    const [HorasLabHF, setHorasHorasLabHF] = useState("")
    const [HorasLabHNF, setHorasHorasLabHNF] = useState("")
    const [dataEvento, setDataEvento] = useState([])
    const [dataTarea, setDataTarea] = useState([
        {}, {}, {}
    ])
    const [dataCasoInfo, setDataCasoInfo] = useState([])
    const [getRow, setRow] = useState({})
    var Listar = []
    var Listar2 = [];
    //Listado de la base de datos
    const SerieHorasLaboradas = dataHorasLaboradas.map(
        (row, i) =>
            (Listar2 = [(parseInt(row.tLab_HoraFacturable) + parseFloat(row.tLab_MinutoFacturable / 100)), (parseInt(row.tLab_HoraNoFacturable) + parseFloat(row.tLab_MinutoNoFacturable / 100))])
    )
    const FuncionListarEventos = async () => {
        let RolId = getDecryptedItem("RolID")
        var postData = {
            nUsu_ID: RolId == 6 || RolId == 1 ? null : getDecryptedItem("ID")
        }
        await instance
            .post("/Evento/Listar", postData)
            .then(response => {

                setDataEvento(response.data)
            })
            .catch(error => {

            })
    }
    const FuncionBuscarCasos = async (cliente, caso) => {
        reportesContext.FuncionListarLiquidacionProcesada(cliente, caso)
        var postData = {
            nCas_Id: caso,
        }
        await instance
            .post("/Casos/Buscar", postData)
            .then(response => {
                setDataCasoInfo(response.data)
            })
            .catch(error => {

            })
    }

    const FuncionListarLiquidacionProcesada = async () => {
        try {
            var postData = {
                nCli_Id: null,
                nCas_Id: null,
            }
            await instance
                .post("/Combo/Codigo/Liq", postData)
                .then(response => {
                    setDataLiquidacion(response.data)


                })
        } catch (error) {

        }
    }
    const FuncionEgresoGastosNoLiquidados = async () => {
        try {
            var postData = {
                nUsu_ID: getDecryptedItem("ID"),
            }
            await instance
                .post("/Egreso/EgresoGastosNoLiquidados", postData)
                .then(response => {

                    setGastosNoLiquidados(response.data)

                })
        } catch (error) {

        }
    }
    const FuncionListarHorasNoLiquidadas = async () => {
        try {
            var postData = {
                nCli_Id: getDecryptedItem("ID"),
            }

            await instance
                .post("/Laborado/LabHorasNoLiquidadas", postData)
                .then(response => {

                    setDataHorasNoLiquidadas(response.data[0])

                })
        } catch (error) {

        }
    }
    const FormatoMoneda = (moneda) => {
        return moneda = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(moneda)
    }
    const FuncionGenerarPDFLiQ = async (_CodigoLiq, _ArrayDatos, _Tipo) => {
        Swal.fire({
            html:
                '<img id="imgr1" className="imgr" src="/static/media/logo-dark.7a9779c2.png" style="width:50px;height:auto;-webkit-animation: 3s rotate linear infinite;' +
                'animation: 3s rotate linear infinite;' +
                '-webkit-transform-origin: 50% 50%;' +
                'transform-origin: 50% 50%;" /> ' +
                '<br/><br/> Cargando...',
            showConfirmButton: false,
            allowOutsideClick: false
        });
        try {
            var postData = {
                CodigoLiq: _CodigoLiq,
                ArrayDatos: _ArrayDatos,
                TGastos: _ArrayDatos.MontoG,
                THonorarios: _ArrayDatos.MontoH,
                Tipo: _Tipo,
            }

            console.log(postData);

            if (_Tipo == "R") {
                await instance
                    .post("/Descargar/ReportePDF", postData, { responseType: 'blob' })
                    .then(response => {
                        Swal.close();
                        FileDownload(response.data, _Tipo + "-" + _CodigoLiq + ".pdf")
                    })
            } else {
                await instance
                    .post("/Generar/ReportePDF", postData, { responseType: 'blob' })
                    .then(response => {
                        Swal.close();
                        FileDownload(response.data, _Tipo + "-" + _CodigoLiq + ".pdf")
                    })
            }

        } catch (error) {

            Swal.close();
        }

    }
    const FuncionInfoSuscriptor = async () => {
        try {

            await instance
                .post("/Suscriptores/InfoSuscriptor")
                .then(response => {

                    setInfoSuscriptor(response.data[0])

                })
        } catch (error) {

        }
    }
    const abrirCerrarModal = Modal => {
        if (Modal == 2) {

            setNumeroFactura(prevState => ({
                ...prevState,
                Codigo: "",
                Factura: "",
            }))
            if (!modalInsertar == false) {
                Swal.fire({
                    html: "¿Seguro que desea salir sin guardar los datos?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si",
                    cancelButtonText: "No",
                }).then(result => {
                    if (result.isConfirmed) {
                        setModalInsertar(!modalInsertar)
                        FuncionListarLiquidacionProcesada();
                    }
                })
            }
            else {
                setModalInsertar(!modalInsertar);
            }
        }
    }
    const handleChange = e => {
        const { name, value } = e.target
        setNumeroFactura(prevState => ({
            ...prevState,
            Factura: value,
        }))

    }
    const FuncionGuardarFactura = async () => {
        var postData = {
            Factura: getNumeroFactura.Factura,
            Codigo: getNumeroFactura.Codigo,
            Estado: 3,
            NumeroLiq: getNumeroFactura.Codigo,
            Estado2: 3
        }
        await instance
            .post("/Liquidacion/ActualizarFactura", postData)
            .then(response => {

                setModalInsertar(!modalInsertar)
                Swal.fire({
                    title: "Mensaje",
                    text: "Agregado exitosamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })
                FuncionListarLiquidacionProcesada();
                FuncionListarHorasNoLiquidadas();
                FuncionEgresoGastosNoLiquidados();
            })
            .catch(error => {

            })
    }

    const HideCampoFactura = async (liquidacion) => {

        if (liquidacion.Factura == "" || liquidacion.Factura == null) {

            return true
        }
        else {

            return false
        }
    }
    const abrirCerrarModalTable = (Modal, Row) => {
        if (Modal == 1) {
            setModo(2);
            FuncionBuscarEventosParticipantes(Row.nEve_Id)
            setRow(Row)
        }
    }
    const FuncionBuscarEventosParticipantes = async (_nEve_Id) => {   // Aqui se carga GetdefaultParticipantes
        var postData = {
            nEve_Id: _nEve_Id,
        }
        await instance
            .post("/Evento/Buscar/Participantes", postData)
            .then(response => {
                setParticipantesEvento(response.data);
                setModalEvento(!getModalEvento)
            })
            .catch(error => {

            })
    }
    const FuncionListarUsuario = async (_nUsu_ID) => {
        var postData = {
            nUsu_ID: _nUsu_ID,
        }
        await instance
            .post("/Usuario/Perfil", postData)
            .then(response => {

                setUsuario(response.data[0])
            })
            .catch(error => {

            })
    }
    const FuncionListarCasosNov = async () => {
        var postData = {
            nUsu_ID: getDecryptedItem("ID"),
            nNov_Estado: 1,
        }
        await instance
            .post("/Dashboard/Casos/Novedades/Listar", postData)
            .then(response => {
                console.log(data)
                setDataNov(response.data)
            })
            .catch(error => {

            })
    }
    const FuncionListarContacto = async () => {
        var postData = {
            nCli_Id: getDecryptedItem("CLIID"),
        }
        await instance
            .post("/Casos/ListarContactoCaso", postData)
            .then(response => {

                setUsuario(response.data)
            })
            .catch(error => {

            })
    }
    const FormatoFecha = (fecha) => {

        var d = fecha.substring(0, 2);
        var m = fecha.substring(4, 2);
        var y = fecha.substring(4);
        var fch = d + "-" + m + "-" + y;
        return fch;

    }
    const FuncionListarHorasLaboradas = async () => {
        var postData = {
            id: parseInt(getDecryptedItem("CLIID")),
            M: getFiltroGrafico.M,
            Y: getFiltroGrafico.Y
        }

        await instance
            .post("/Dashboard/Cliente/Laborado", postData)
            .then(response => {

                setHorasLaboradas(response.data)
                var HL = response.data[0].nLab_Hora
                var ML = response.data[0].nLab_Minuto
                var HF = response.data[0].tLab_HoraFacturable
                var MF = response.data[0].tLab_MinutoFacturable
                var HnF = response.data[0].tLab_HoraNoFacturable
                var MnF = response.data[0].tLab_MinutoNoFacturable
                if (response.data[0].nLab_Hora.length == 1) {
                    HL = "0" + response.data[0].nLab_Hora
                }
                if (response.data[0].nLab_Minuto.length == 1) {
                    ML = "0" + response.data[0].nLab_Minuto
                }
                if (response.data[0].tLab_HoraFacturable.length == 1) {
                    HF = "0" + response.data[0].tLab_HoraFacturable
                }
                if (response.data[0].tLab_MinutoFacturable.length == 1) {
                    MF = "0" + response.data[0].tLab_MinutoFacturable
                }
                if (response.data[0].tLab_HoraNoFacturable.length == 1) {
                    HnF = "0" + response.data[0].tLab_HoraNoFacturable
                }
                if (response.data[0].tLab_MinutoNoFacturable.length == 1) {
                    MnF = "0" + response.data[0].tLab_MinutoNoFacturable
                }

                setHorasHorasLabHL(HL + ":" + ML)
                setHorasHorasLabHF(HF + ":" + MF)
                setHorasHorasLabHNF(HnF + ":" + MnF)
            })
            .catch(error => {

            })
    }
    const FuncionCliente_Hliquidadas = async (_nUsu_ID) => {
        var postData = {
            nUsu_ID: _nUsu_ID
        }
        await instance
            .post("/Liquidacion/Cliente_Hliquidadas", postData)
            .then(response => {
                setCliente_Hliquidadas(response.data[0].nLab_Total ? response.data[0].nLab_Total : 0.00);
            })
            .catch(error => {

            })
    }
    const FuncionCliente_GXPagar = async (_nUsu_ID) => {
        var postData = {
            nUsu_ID: _nUsu_ID
        }
        await instance
            .post("/Liquidacion/Cliente_GXPagar", postData)
            .then(response => {

                Cliente_GXPagar(response.data[0].nEge_Monto)
            })
            .catch(error => {

            })
    }


    const FuncionCliente_SCXGasto = async (_nUsu_ID) => {
        var postData = {
            nUsu_ID: _nUsu_ID
        }
        await instance
            .post("/Liquidacion/Cliente_SCXGasto", postData)
            .then(response => {

                Cliente_SCXGasto(response.data[0].Total);
            })
            .catch(error => {

            })
    }

    const FuncionDocsCount = async (nCli_Id) => {
        var postData = {
            nCli_Id: nCli_Id
        }
        await instance
            .post("/Docs/Count", postData)
            .then(response => {
                let total = response.data[0].Pendientes + response.data[0].Rechazados

                if (total < 10) {
                    setDocsCount("0" + total);
                } else {
                    setDocsCount("" + total);
                }


            })
            .catch(error => {

            })
    }


    const FuncionLiquidacionPendiente = async (nCli_Id) => {
        var postData = {
            nCli_Id: nCli_Id
        }
        console.log("postData : ", postData);
        await instance
            .post("/Liquidacion/LiquidacionPendiente", postData)
            .then(response => {
                console.log(response.data);
                setDataLiqPendiente(response.data);

            })
            .catch(error => {

            })
    }

    const SelectDetalle = async (nCas_Id, nCli_Id, _nEqun_Id) => {
        setEncryptedItem('nCas_Id', nCas_Id);
        setEncryptedItem('CASOIDCli', nCli_Id);

        let ID =  getDecryptedItem("ID");

        console.log("ID : ", ID);
        CambiarEstadoNovedadMasivo(ID, nCas_Id);
    }

    const CambiarEstadoNovedadMasivo = async (ID, _nCas_Id) => {

        var postData = {
            ID: parseInt(ID),
            nCas_Id: _nCas_Id
        }
    
        console.log(postData);
    
        await instance
        .post("/Dashboard/Casos/Novedades/CambiarEstadoMasivo", postData)
        .then(response => {
        console.log(response.data)
        })
        .catch(error => {

        })
    }

    const validateClientDashboardAccess = () => {
        return new Promise((resolve, reject) => {

            const userRole = getDecryptedItem('RolID');
            console.log("userRole : ", userRole);

            if (userRole.toString() !== '9') {
                window.location.href = '/AccessDenied';
                reject(true);
            } else {
                resolve(false);
            }
        });
    };    

    useEffect(async () => {

        await validateClientDashboardAccess();
      

        const CLIID = getDecryptedItem("CLIID");
        console.log("CLIID : ", CLIID); 


        FuncionListarContacto()
        FuncionLiquidacionPendiente(CLIID);
        FuncionDocsCount(CLIID);
        FuncionCliente_Hliquidadas(CLIID);
        FuncionCliente_GXPagar(CLIID)
        FuncionCliente_SCXGasto(CLIID)
        FuncionListarHorasLaboradas();
        FuncionListarCasosNov();
        FuncionListarEventos();
        FuncionListarLiquidacionProcesada();
        FuncionListarHorasNoLiquidadas();
        FuncionEgresoGastosNoLiquidados();
        FuncionInfoSuscriptor();
        let nombre = "Bienvenido(a), " + getDecryptedItem("NombreC");
        setNombreUsuario(nombre)
    }, [])
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Bienvenido | GpsLegal</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs
                        title="GpsLegal"
                        breadcrumbItem={nombreUsuario}
                    />
                    <Modal isOpen={modalInsertar}>
                        <div
                            className="modal-header ModalHeaderColor"
                        >
                            <h5
                                style={{ color: "#fff" }}
                                className="modal-title mt-0"
                                id="myModalLabel"
                            >
                                Ingrese número de factura
                            </h5>
                            <button
                                type="button"
                                onClick={() => abrirCerrarModal(2)}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3 row">
                                <div className="col-md-12">
                                    <input
                                        type="text"
                                        className="form-control inputDown"
                                        placeholder="Factura"
                                        name="Factura"
                                        value={getNumeroFactura && getNumeroFactura.Factura}
                                        onChange={handleChange}
                                    />
                                    <label className="col-form-label labelDown">
                                        Número de factura
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={() => abrirCerrarModal(2)}
                                className="btn btn-secondary waves-effect"
                                data-dismiss="modal"
                            >
                                Cerrar
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger waves-effect waves-light"
                                onClick={() => FuncionGuardarFactura()}
                            >
                                Guardar
                            </button>
                        </div>
                    </Modal>

                    <Row className="mb-3">
                        <Col xl="12">
                            <h6 className="card-title mb-12">
                                Aquí  puede encontrar el resumen principal de su cuenta en la empresa <b style={{ fontSize: '16px', color: 'black' }}>{getInfoSuscriptor.cSsi_Fac_Compania}</b>
                            </h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl="3">
                            <Col className="row">
                                <Card className="cardBorder" style={{ paddingLeft: '0px' }}>
                                    <CardBody style={{ textAlign: 'center' }}>
                                        <h4 className="card-title mb-4">
                                            Documentos por cargar
                                        </h4>


                                        <Link to="/Documentos" className="waves-effect">
                                            <h1>
                                                {docsCount}
                                            </h1>
                                        </Link>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="row">

                                <Card className="cardBorder" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                    <div className="row container-fluid">


                                        <div className="col-xl-0 col-4 " style={{ height: '10px' }}></div>
                                        <div className="col-xl-12 col-4" style={{ height: 'auto' }}>
                                            <CardBody style={{ textAlign: 'center', paddingLeft: '20px', paddingRight: '20px' }}>
                                                <h4 className="card-title mb-4">
                                                    Liquidaciones pendientes { }
                                                </h4>
                                                <Table className="table align-middle mb-0 borderColorTable"
                                                    style={{ padding: '0px', textAlign: 'left' }}>
                                                    <tbody style={{ padding: '0px' }}>
                                                        {dataLiqPendiente.slice(0, getCantidadTarea).map((row, i) => (
                                                            <tr key={i} onClick={() => abrirCerrarModal(3, row)}>
                                                                <td colSpan="1" style={{ maxWidth: '200px', padding: '0px', paddingBottom: '10px', paddingTop: '10px' }} className="Texto-Limit">
                                                                    <p className="mb-0" style={{ padding: '0px' }}>
                                                                        <label style={{ width: '70px' }}> Código </label>
                                                                        <label style={{ width: '20px' }}> : </label>
                                                                        <label style={{ textAlign: 'right !important', cursor: 'pointer', float:'right' }}
                                                                            onClick={() => {
                                                                                FuncionGenerarPDFLiQ(row.Numero, row, row.Estado)
                                                                            }}> <b>{row.Numero}</b>  </label>
                                                                    </p>
                                                                    <p className="mb-0" style={{ padding: '0px' }}>
                                                                        <label style={{ width: '70px' }}> Fecha </label>
                                                                        <label style={{ width: '20px' }}> : </label>
                                                                        <label style={{ textAlign: 'right !important', float:'right' }}> {row.Fecha} </label>

                                                                    </p>
                                                                    <p className="mb-0" style={{ padding: '0px' }}>
                                                                        <label style={{ width: '70px' }}> Gastos</label>
                                                                        <label style={{ width: '20px' }}> : </label>
                                                                        <label style={{ textAlign: 'right !important', float:'right' }}> {row.cTabSimbolo} {row.MontoG} </label>

                                                                    </p>

                                                                    <p className="mb-0" style={{ padding: '0px' }}>
                                                                        <label style={{ width: '70px' }}> Honorarios</label>
                                                                        <label style={{ width: '20px' }}> : </label>
                                                                        <label style={{ textAlign: 'right !important', float:'right' }}> {row.cTabSimbolo} {row.MontoH} </label>

                                                                    </p>

                                                                    <p className="mb-0" style={{ padding: '0px' }}>
                                                                        <label style={{ width: '70px' }}> Estado</label>
                                                                        <label style={{ width: '20px' }}> : </label>
                                                                        <label style={{ textAlign: 'right !important', float:'right' }}> {row.Estado} </label>

                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                                <style>
                                                    { }
                                                </style>
                                                <div className="card-footer bg-transparent border-top">
                                                    <div className="text-center">
                                                        <button
                                                            // onClick={() => setCantidadTarea(getCantidadTarea + 4)}
                                                            className="btn btn-secondary waves-effect waves-light"
                                                        >
                                                            Cargar más
                                                        </button>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </div>
                                        <div className="col-xl-0 col-4" style={{ height: '10px' }}></div>





                                    </div>
                                </Card>

                            </Col>
                            <Col className="row">
                                <Card className="cardBorder" style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                    <CardBody style={{ textAlign: 'center', paddingBottom: '10px' }}>
                                        <h4 className="card-title mb-4">
                                            Persona de contacto
                                        </h4>
                                    </CardBody>
                                    <div className="center " style={{ textAlign: 'center' }}>
                                        {getUsuario.map((row, i) => (
                                            <div key={i}>
                                                <h5 style={{ textAlign: 'center' }}>
                                                    {row.cUsu_Nombres}
                                                </h5>
                                                <h5 style={{ textAlign: 'center', fontSize: '10.5px' }}>
                                                    {row.cUsu_email}
                                                </h5>
                                                <h5 style={{ textAlign: 'center', fontSize: '10.5px' }}>
                                                    {row.cUsu_Fono}
                                                </h5>
                                            </div>
                                        ))}
                                    </div>
                                </Card>
                            </Col>

                        </Col>
                        <Col xl="5">
                            <Card className="cardBorder" hidden>
                                <CardBody>
                                    <h4 className="card-title mb-4">Ultimas liquidaciones</h4>
                                    <div className="card-title mb-4" style={{ fontSize: '17px', height: '1px', backgroundColor: '#5E5E5E' }}></div>
                                    {dataLiquidacion.map((row, i) => (
                                        <>
                                            <Row className="mb-12">
                                                <Col xl="12">
                                                    <Col xl="8">
                                                        <label>
                                                            Codigo de liquidación: {row.Numero}
                                                        </label>
                                                    </Col>
                                                    <Col xl="4">
                                                        <label>
                                                            Monto: {row.cTabSimbolo} {FormatoMoneda(parseFloat(row.MontoG) + parseFloat(row.MontoH))}
                                                        </label>
                                                    </Col>
                                                </Col>
                                            </Row>
                                            <Row className="mb-12" hidden={HideCampoFactura(row)}>
                                                <Col xl="12">
                                                    <Col xl="8">
                                                        <label>
                                                            Factura: {row.Factura}
                                                        </label>
                                                    </Col>
                                                </Col>
                                            </Row>
                                            <div className="row mb-12">
                                                <div className="mb-8">
                                                    <label>
                                                        Fecha : {row.Fecha}
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="row mb-12">
                                                <div className="row mb-8">
                                                    <label>
                                                        Cliente : {row.cCli_NombreCompleto}
                                                    </label>
                                                </div>

                                                <div className="row mb-4" style={{ width: '200px' }} hidden={row.hidden}>
                                                    <button type="button" className="btn btn-danger waves-effect waves-light"
                                                        onClick={() => {
                                                            abrirCerrarModal(2)
                                                            setNumeroFactura(prevState => ({
                                                                ...prevState,
                                                                Codigo: row.Numero,
                                                            }))
                                                        }}>
                                                        Aprobar liquidación
                                                    </button>

                                                </div>
                                                <div className="row mb-4" onClick={() => {
                                                    FuncionGenerarPDFLiQ(row.Numero, row, row.Estado.substring(0, 1))
                                                }}>
                                                    <label style={{ color: '#246BD3', cursor: 'pointer' }}
                                                    >
                                                        <u>
                                                            Ver liquidación
                                                        </u>
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="card-title mb-4" style={{ fontSize: '17px', height: '1px', backgroundColor: '#a52019' }}></div>
                                        </>
                                    ))}
                                </CardBody>
                            </Card>
                            <Card className="cardBorder">
                                <CardBody>
                                    <h4 className="card-title mb-3">
                                        Novedades Judiciales{" "}
                                        <span className="badge bg-danger rounded-pill">{dataNov.length}</span>
                                        <Link to="/HistorialNovedades" style={{ float: "right" }} className="badge bg-danger ">Ver historial</Link>
                                    </h4>
                                    <div >
                                        {dataNov.slice(0, getCantidadNovedad).map((row, i2) => (
                                            <Card className="cardBorder " key={i2} >
                                                <CardBody>
                                                    <Link to="/dashboard-Casos" onClick={() => SelectDetalle(row.nCas_Id, row.nCli_Id, row.nEqun_Id)}>
                                                        <div className="row mb-1">
                                                            <div className="col-md-4 container">
                                                                <b className="text-truncate font-size-14 mb-1">
                                                                    Proceso
                                                                </b>
                                                            </div>
                                                            <div className="col-md-8 container" style={{ paddingLeft: '4.5px' }}>
                                                                <b className="text-truncate font-size-14 mb-1">
                                                                    {row.cCas_Cod_Externo} <a style={{ float: "right", fontSize: 12 }} >{row.nNov_Fecha2}</a>
                                                                </b>
                                                            </div>
                                                            <hr
                                                                style={{ color: "#e01646", height: "2px" }}
                                                            ></hr>
                                                        </div>
                                                        <div className="row mb-1">
                                                            <div className="col-md-4">
                                                                <p className="mb-0">Juzgado</p>
                                                            </div>
                                                            <div className="col-md-8" style={{ paddingLeft: '4.5px' }}>
                                                                <p className="mb-0">
                                                                    {row.nJuz_Id2}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-3">
                                                            <div className="col-md-4">
                                                                <p className="mb-0">Cliente</p>
                                                            </div>
                                                            <div className="col-md-8" style={{ paddingLeft: '4.5px' }}>
                                                                <p className="mb-0">
                                                                    {row.cCli_NombreCompleto}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-4">
                                                            {row.cNov_Movimiento.map((row2, i) => (
                                                                <>
                                                                    <div className="row mb-1">
                                                                        <div className="col-md-4 container">
                                                                            <b className="text-truncate font-size-14 mb-1">
                                                                                Acto / Resolución
                                                                            </b>
                                                                        </div>
                                                                        <div className="col-md-8 container">
                                                                            <b className="text-truncate font-size-14 mb-1">
                                                                                {row2?.Acto} {" / "} {row2?.Resolucion}
                                                                            </b>
                                                                        </div>
                                                                        <hr
                                                                            style={{ color: "#e01646", height: "2px" }}
                                                                        ></hr>
                                                                    </div>
                                                                    <div className="row mb-1">
                                                                        <div className="col-md-4">
                                                                            <p className="mb-0">Sumilla</p>
                                                                        </div>
                                                                        <div className="col-md-8">
                                                                            <p className="mb-0">
                                                                                {row2?.Sumilla}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ))}
                                                        </div>
                                                        <div className="row mb-4">
                                                            {row.cNov_Notificacion.map((row, i) => (
                                                                <>
                                                                    <div className="row mb-1">
                                                                        <div className="col-md-4 container">
                                                                            <b className="text-truncate font-size-14 mb-1">
                                                                                Titulo / Anexo
                                                                            </b>
                                                                        </div>
                                                                        <div className="col-md-8 container">
                                                                            <b className="text-truncate font-size-14 mb-1">
                                                                                {row.titulo} {" / "} {row.anexos}
                                                                            </b>
                                                                        </div>
                                                                        <hr
                                                                            style={{ color: "#e01646", height: "2px" }}
                                                                        ></hr>
                                                                    </div>
                                                                    <div className="row mb-1">
                                                                        <div className="col-md-4">
                                                                            <p className="mb-0">Fecha envio</p>
                                                                        </div>
                                                                        <div className="col-md-8">
                                                                            <p className="mb-0">
                                                                                {FormatoFecha(row.fechaEnvio)}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ))}
                                                        </div>
                                                    </Link>
                                                </CardBody>
                                            </Card>
                                        ))}
                                    </div>
                                    <div className="card-footer bg-transparent border-top">
                                        <div className="text-center">
                                            <button
                                                onClick={() => setCantidadNovedad(getCantidadNovedad + 4)}
                                                className="btn btn-secondary waves-effect waves-light"
                                            >
                                                {" "}
                                                Cargar más
                                            </button>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl="4">

                            <Card className="cardBorder">
                                <CardBody>
                                    <h4 className="card-title mb-4">Horas laboradas (aún no liquidadas)</h4>

                                    <div className="">
                                        <div className="row" style={{ textAlign: 'center' }}>

                                            <h1>
                                                {HorasLabHF} {" "}h
                                            </h1>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>

                            <Card className="cardBorder">
                                <CardBody> 
                                    <Col xl="12"><h4 className="card-title mb-4" >
                                        Honorarios liquidados por pagar{" "}
                                    </h4>

                                        <Col xl="12">
                                            <h4
                                                style={{ textAlign: 'right', fontSize: '50px !important' }}>
                                                {getCliente_Hliquidadas}
                                            </h4>
                                        </Col>
                                        <div className="card-title mb-4" style={{ fontSize: '17px', height: '1px', backgroundColor: '#a52019' }}>
                                        </div></Col>
                                    <Col xl="12">
                                        <Col xl="12">
                                            <h4 className="card-title mb-4">
                                                Gastos por pagar{" "}
                                            </h4>
                                            <Col xl="12">
                                                <h4
                                                    style={{ textAlign: 'right', fontSize: '20px !important' }}>
                                                    {getCliente_GXPagar}
                                                </h4>
                                            </Col>

                                            <div className="card-title mb-4" style={{ fontSize: '17px', height: '1px', backgroundColor: '#a52019' }}>
                                            </div>
                                        </Col>
                                    </Col>
                                    <Col xl="12">
                                        <Col xl="12"><h4 className="card-title mb-4">
                                            Saldo en cajas para gastos{" "}
                                        </h4>
                                            <Col xl="12">
                                                <h4
                                                    style={{ textAlign: 'right', fontSize: "20px !important" }}>
                                                    {getCliente_SCXGasto}
                                                </h4>
                                            </Col>
                                            <div className="card-title mb-4" style={{ fontSize: '17px', height: '1px', backgroundColor: '#a52019' }}>
                                            </div>
                                        </Col>
                                    </Col>
                                </CardBody>
                                <CardBody>
                                </CardBody>
                            </Card>
                            <Card className="cardBorder">
                                <CardBody>
                                    <div className="table-responsive mt-4">
                                        <h4 className="card-title mb-4">Eventos</h4>
                                        <Table className="table align-middle mb-0 borderColorTable">
                                            <tbody>
                                                {dataEvento.slice(0, getCantidadEventos).map((row, i) => (
                                                    <tr key={i} onClick={() => abrirCerrarModalTable(1, row)} style={{ cursor: "pointer" }}>
                                                        {(() => {
                                                            if (row.cEve_Estado == "TT01") {
                                                                return (
                                                                    <td>
                                                                        <i className="icon-eventos2" style={{ color: "#032D5E", fontSize: "25px", paddingTop: "5px" }} />
                                                                    </td>
                                                                )
                                                            } else if (row.cEve_Estado == "TT02") {
                                                                return (
                                                                    <td>
                                                                        <i className="icon-eventos2" style={{ color: "#00A94F", fontSize: "25px", paddingTop: "5px" }} />
                                                                    </td>
                                                                )
                                                            } else if (row.cEve_Estado == "TT03") {
                                                                return (
                                                                    <td>
                                                                        <i className="icon-eventos2" style={{ color: "#AA1B0E", fontSize: "25px", paddingTop: "5px" }} />
                                                                    </td>
                                                                )
                                                            } else if (row.cEve_Estado == "TT04") {
                                                                return (
                                                                    <td>
                                                                        <i className="icon-eventos2" style={{ color: "#AA1B0E", fontSize: "25px", paddingTop: "5px" }} />
                                                                    </td>
                                                                )
                                                            }
                                                        })()}
                                                        <td className="Texto-Limit">
                                                            <h5
                                                                style={{ color: "#1F60AB", width: '140px' }}
                                                                className="font-size-14 mb-1"
                                                            >
                                                                {row.cEve_Titulo}
                                                            </h5>
                                                            <p className="font-size-14 mb-1" style={{ width: '140px' }}>
                                                                {row.cEve_Descripcion}
                                                            </p>
                                                            <p className="text-muted mb-0">
                                                            </p>
                                                        </td>
                                                        <td className="Texto-Limit" style={{ textAlign: 'right', paddingRight: '10px' }}>
                                                            <p className="mb-1 ">
                                                                {row.dEve_FechaIni2}
                                                            </p>
                                                            <p className="mb-1" style={{ textAlign: 'right', paddingRight: '2px' }}>
                                                                {row.tEve_HoraIni2}
                                                            </p>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                        <div className="card-footer bg-transparent border-top">
                                            <div className="text-center">
                                                <button
                                                    onClick={() => setCantidadEventos(getCantidadEventos + 4)}
                                                    className="btn btn-secondary waves-effect waves-light"
                                                >
                                                    {" "}
                                                    Cargar más
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>

                            <ComboState>
                                {(() => {
                                    if (getModalEvento == true) {
                                        return <>
                                            <ModalEvento show={getModalEvento}
                                                onCloseClick={() => abrirCerrarModalTable(1)}
                                                modo={modo}
                                                evento={getRow}
                                                Condicion="1"
                                                Row={getRow}
                                                Id="1"
                                                onCloseModal={() => { setModalEvento(!getModalEvento), FuncionListarEventos() }}
                                                arrayParticipantesEventos={GetParticipantesEvento}
                                            />
                                        </>
                                    }
                                })()}
                            </ComboState>


                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    )
}
export default DashboardSuscriptor
