import instance from '../../config'
import React, { Component, useState, useEffect } from "react"
import { Link, useParams } from "react-router-dom"
const jsonwebtoken = require('jsonwebtoken');
var token = null;
const VerificarCorreo = props => {
    var param = useParams();
    const baseUrl = process.env.REACT_APP_API_URL
    var FuncionGetToken = async () => {
        token = param.datos;
        const SECRET_KEY_JWT = "secretkey5353";
        const datos = await jsonwebtoken.verify(token, SECRET_KEY_JWT);
        ValidarEstado_bSusVisto(datos.payload.cuenta, datos.payload.db);
    }
    const ValidarEstado_bSusVisto = async (cSus_Cuenta, dataBase) => {
        var postData = {
            _cSus_Cuenta: cSus_Cuenta,
        }
        
        await instance
            .post("/Suscriptores/ValidarEstado/bSusVisto", postData)
            .then(async response => {
                
                if (response.data.length > 0) {
                    FuncionCrearDB(dataBase)
                    FuncionCrearDominio(cSus_Cuenta)
                   
                }
                else {
                    location.href = 'http://' + cSus_Cuenta + '.gpslegal.pe';
                }
            })
            .catch(error => {
                
            })
    }
    const CambiarEstado_bSusVisto = async (cSus_Cuenta) => {
        var postData = {
            _cSus_Cuenta: cSus_Cuenta,
        }
        
        await instance
            .post("/Suscriptores/CambiarEstado/SusVisto", postData)
            .then(async response => {
                
            })
            .catch(error => {
                
            })
    }

    const FuncionCrearDB = async (_DB) => {
        var postData = {
            DB: _DB,
        }
        await instance
            .post("/Suscriptores/CrearDB", postData)
            .then(async response => {
            })
            .catch(error => {
                
            })
    }
    const FuncionCrearDominio = async (_cuenta) => {
        var postData = {
            Subdominio: _cuenta,
        }

        await instance
            .post("/AWS/Router53", postData)
            .then(async response => {
                setTimeout(() => {
                    location.href = "http://" + _cuenta + '.gpslegal.pe/CrearCuenta/' + param.datos
                    CambiarEstado_bSusVisto(postData.Subdominio);
                }, 5000);
             
            })
            .catch(error => {
                
            })
    }
    useEffect(() => {
        FuncionGetToken();
    }, [])
    return (
        <React.Fragment>
            <center>
                <h1>Cargando...</h1>
            </center>
        </React.Fragment>
    )
}
export default VerificarCorreo