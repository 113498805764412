import React, { Component, useState, useEffect } from "react"
import Select from "react-select"
import Swal from "sweetalert2"
import { getDecryptedItem } from '../../util'
import {
    CardBody,
    Card,
    Col,
    Container,
    CardTitle,
    Button,
    Row,
    Modal,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
} from "reactstrap"
import instance from '../../config'
import desconectado from '../../assets/images/desconectado.png'
const baseUrl = process.env.REACT_APP_API_URL
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10

export const ModalNoticia = ({ show, onCloseClick }) => {
    const fetchData = async () => {
        var postdata = {
            nUsu_ID: getDecryptedItem("ID")
        }
        const response = await instance.post("http://localhost:3010/UpdateVerifictCej", postdata);

    };
    useEffect(() => {
    }, [])
    return (
        <Modal isOpen={show}>
            <div
                className="modal-header ModalHeaderColor"
            >
                <h5
                    style={{ color: "#fff" }}
                    className="modal-title mt-0"
                    id="myModalLabel"
                >
                    Aviso.
                </h5>
                <button
                    type="button"
                    onClick={() => { onCloseClick(), fetchData() }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="container">
                    <center>
                        <img style={{ marginTop: 25 }} src={desconectado} width="150px" height="150px"></img>
                    </center>
                    <p style={{ marginTop: 55 }}>Estimado <b>Usuario</b>:<br /><br />
                        Queremos informarle que nuestro sistema sigue presentando intermitencias debido a los cambios en seguridad ocurridos en el sitio web del <b>Consejo Ejecutivo del Poder Judicial (CEJ)</b> durante las últimas semanas. Esto impide tanto la actualización como la creación de nuevos procesos.
                        Nuestro equipo está trabajando arduamente y mantiene contacto con el Poder Judicial del Perú para resolver esta situación lo más pronto posible.
                        Sentimos cualquier molestia que esto pueda causarle.
                        Si tiene alguna pregunta, no dude en contactarnos a través de <b>soporte@gpslegal.pe</b>.
                    </p>
                </div>
            </div>
            <div className="modal-footer">
            </div>
        </Modal>
    );
}
export default ModalNoticia
