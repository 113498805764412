import React, { Component, useState, useEffect } from "react"
import Select from "react-select"
import Swal from "sweetalert2"
import { useLocation } from 'react-router-dom';
import {
    CardBody,
    Card,
    Col,
    Container,
    CardTitle,
    Button,
    Row,
    Modal,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
} from "reactstrap"
import instance from '../../config'
import { getDecryptedItem } from '../../util';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faPlus,
    faSearch,
    faTimes,
    faChevronLeft,
    faChevronRight,
    faPaperPlane,
    faBuilding,
    faUser,
    faCalendar,
    faCircle,
    faClipboard,
    faBell,
} from "@fortawesome/free-solid-svg-icons"
import Cliente from "pages/Consultas/Cliente";
import { set } from "lodash";
const baseUrl = process.env.REACT_APP_API_URL
var idClientSlt = undefined;
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10


export const ModalIngreso = ({ show, onCloseClick, ReloadLista, Titulo, Row, mode, modo, ingreso }) => {
    const [modalInsertar, setModalInsertar] = useState(false)
    const [dataCliente, setDataCliente] = useState([])
    const [getCliente, setCliente] = useState({})
    const [dataCaso, setDataCaso] = useState([])
    const [dataComboMPago, setDataComboMPago] = useState([])
    const [dataComboMoneda, setDataComboMoneda] = useState([])
    const [dataComboTIngreso, setDataComboTIngreso] = useState([])
    const [dataComboCuenta, setDataComboCuenta] = useState([])
    const [disable, setDisable] = useState(false)
    const [dataCodInterno, setdataCodInterno] = useState("")
    const [dataCodExterno, setdataCodExterno] = useState("")
    const [hiddenBtnEditar, setHiddenBtnEditar] = useState(true)
    const [hiddenBtnEliminar, setHiddenBtnEliminar] = useState(true)


    const [getcasoSlt, setcasoSlt] = useState({})
    const [blockBtn, setBlockBtn] = useState(false)
    const [blockComboMoneda, setBlockComboMoneda] = useState(false)
    const [disableTpoCambio, setDisableTpoCambio] = useState(true)
    const [hiddenBtnGuardar, setHiddenBtnGuardar] = useState(false)
    const [validData, setValidData] = useState(false)
    const [dataTitulo, setdataTitulo] = useState("")

    const [getErrores, setErrores] = useState({
        nIng_Id: "",
        nCas_Id: "",
        nCue_Id: "",
        cIng_Tab_Tipo: "",
        cIng_Tab_FormaPago: "",
        cIng_Tab_Moneda: "MO01",
        nIng_TCambio: 0,
        dIng_TCambio: 0,
        dIng_TCambioFormat: 0,
        dIng_TCambioOld: 0,
        nIng_Monto: 0,
        nIng_MontoFormat: 0,
        nIng_MontoOld: 0,
        cIng_Detalle: "",
        nUsu_ID: "",
        cIng_Archivo: "",
        bIng_Estado: "1",
        dIng_Fecha: ano + "-" + mes + "-" + dia,
    });
    const [getIngreso, setIngreso] = useState({
        p_nIng_Id: "",
        nCas_Id: "",
        nCli_Id: "",
        nCue_Id: "",
        cCas_Cod_Externo: "",
        cCas_Cod_Interno: "",
        cIng_Tab_Tipo: "KP01",
        cIng_Tab_FormaPago: "",
        cIng_Tab_Moneda: "MO01",
        nIng_Monto: 0,
        nIng_MontoFormat: 0,
        nIng_MontoOld: 0,
        cIng_Detalle: "",
        dIng_Fecha: ano + "-" + mes + "-" + dia,
        cIng_Num_Ope: "",
        bIng_Estado: "1",
        nIng_TCambio: 0
    })

    const Validacion = (form) => {

        console.log(form);
        let errors = {};
        let regexLetras = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
        let regexCorreo = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
        let regexNumero = /^[0-9]+$/;



        if (form.nCas_Id == 0 || form.nCas_Id == null) {
            errors.nCas_Id = "(*)"
        }
        if (form.nCli_Id == 0 || form.nCli_Id == null) {
            errors.nCli_Id = "(*)"
        }

        if (form.nCue_Id == 0) {
            errors.nCue_Id = "(*)"
        }

        if (form.cIng_Tab_FormaPago == 0) {
            errors.cIng_Tab_FormaPago = "(*)"
        }

        if (form.cIng_Tab_Moneda == 0) {
            errors.cIng_Tab_Moneda = "(*)"
        }
        if (disableTpoCambio == false) {
            if (form.nIng_TCambio == 0 || form.nIng_TCambio == null || form.nIng_TCambio.toString() == "") {
                errors.nIng_TCambio = "(*)"
            }

        }

        if (form.nIng_Monto == 0) {
            errors.nIng_Monto = "(*)"
        }

        if (form.cIng_Num_Ope.trim() == "") {
            errors.cIng_Num_Ope = "(*)"
        }

        if (form.cIng_Tab_Tipo == 0) {
            errors.cIng_Tab_Tipo = "(*)"
        }

        if (form.cTabCodigo == 0) {
            errors.cTabCodigo = "(*)"
        }



        return errors;
    }
    const handleBlur = e => {
        handleChange(e)
        setErrores(Validacion(getIngreso))

    }
    const handleChange = e => {
        const { name, value } = e.target
        if (name == "cIng_Tab_Tipo") {
            if (value == "KP02") {
                setBlockComboMoneda(true);
                setIngreso(prevState => ({
                    ...prevState,
                    cIng_Tab_Moneda: "MO01",
                }))
                setDisableTpoCambio(true);
            }
            else {
                setBlockComboMoneda(false);
            }
        }
        if (name == "cIng_Tab_Moneda") {
            if (value == "MO01" || value == "MO07") {
                setDisableTpoCambio(true);

            }
            else {
                setDisableTpoCambio(false);
            }
        }

        if (name == "nIng_Monto") {
            setIngreso(prevState => ({
                ...prevState,
                nIng_Monto: value,
            }))
            setIngreso(prevState => ({
                ...prevState,
                nIng_MontoOld: value,
            }))
            setIngreso(prevState => ({
                ...prevState,
                nIng_MontoFormat: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(value),
            }))

        }
        else if (e.target.name == "nCas_Id") {
            FuncionInformacionCaso(e.target.value)
            setIngreso(prevState => ({
                ...prevState,
                [name]: value,
            }))
        }
        else {
            setIngreso(prevState => ({
                ...prevState,
                [name]: value,
            }))
        }
    }
    const FuncionListarCliente = async () => {
        let RolId = getDecryptedItem("RolID")

        var postData = {
            bCli_Tipo: null,
            nUsu_ID: RolId == 6 || RolId == 1 ? null : getDecryptedItem("ID")
        }
        await instance
            .post("/Cliente/ListarCbo", postData)
            .then(response => {
                setDataCliente(response.data)

            })
            .catch(error => {

            })
    }
    const handleChangeFiltroCaso = e => {

        idClientSlt = e.target.value;

        setIngreso(prevState => ({
            ...prevState,
            nCli_Id: e.target.value
        }))

        if (e.target.value == 0) {
            FuncionListarCasos(null)
        } else {
            FuncionListarCasos(e.target.value)
        }
    }
    const FuncionListarCasos = async (_idClient) => {
        var postData = {
            idClient: _idClient,
            nUsu_Id: getDecryptedItem("ID"),
        }
        await instance
            .post("/Casos/ListarCbo", postData)
            .then(response => {
                console.log(response.data);
                setDataCaso(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionInformacionCaso = async (_nCas_Id) => {
        var postData = {
            nCas_Id: _nCas_Id,
        }

        await instance
            .post("/Casos/Buscar", postData)
            .then(response => {

                setcasoSlt(response.data)
                setdataCodInterno(response.data[0].cCas_Cod_Interno)
                setdataCodExterno(response.data[0].cCas_Cod_Externo)
            })
            .catch(error => {

            })
    }
    const FuncionListarComboMetodoPago = async () => {
        var postData = {
            Prefijo: "FP",
        }
        await instance
            .post("/Maestros/Data", postData)
            .then(response => {
                setDataComboMPago(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionListarComboMoneda = async () => {
        var postData = {
            Prefijo: "MO",
        }
        await instance
            .post("/Maestros/Data", postData)
            .then(response => {
                setDataComboMoneda(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionListarComboTIngreso = async () => {
        var postData = {
            Prefijo: "KP",
        }
        await instance
            .post("/Maestros/Data", postData)
            .then(response => {
                setDataComboTIngreso(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionListarComboCuenta = async () => {
        await instance
            .get("/CBanco/Listar/Combo")
            .then(response => {
                setDataComboCuenta(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionCorreoEvento = async (_nCas_Id, _nUsu_Id, _cCor_Tab_Tipo, _cCor_Asunto) => {
        var postData = {
            nCas_Id: _nCas_Id,
            nUsu_Id: _nUsu_Id,
            cCor_Tab_Tipo: _cCor_Tab_Tipo,
            cCor_Asunto: _cCor_Asunto,
        }

        await instance
            .post("/Correo/Insertar", postData)
            .then(response => {
            })
            .catch(error => {

            })
    }
    const FuncionAgregarIngreso = async () => {
        var postData = {
            nCas_Id: Number.parseInt(getIngreso.nCas_Id),
            nCue_Id: Number.parseInt(getIngreso.nCue_Id),
            cIng_Tab_Tipo: getIngreso.cIng_Tab_Tipo,
            cIng_Tab_FormaPago: getIngreso.cIng_Tab_FormaPago,
            cIng_Tab_Moneda: getIngreso.cIng_Tab_Moneda,
            nIng_Monto: parseFloat(getIngreso.nIng_MontoOld),
            cIng_Detalle: getIngreso.cIng_Detalle,
            dIng_Fecha: getIngreso.dIng_Fecha,
            cIng_Num_Ope: getIngreso.cIng_Num_Ope,
            bIng_Estado: Number.parseInt(getIngreso.bIng_Estado),
            nIng_TCambio: parseFloat(getIngreso.nIng_TCambio)
        }

        var nUsu_ID = getDecryptedItem("ID")

        await instance
            .post("/Ingresos/Insertar", postData)
            .then(async response => {


                let idIng = response.data[0].nIng_Id; // este es el id del evento creado

                let postData2 = {
                    cAud_nombre: "Ingresos",
                    nAud_IdReg: idIng,
                    nUsu_ID: getDecryptedItem("ID"),
                    dAud_Fecha: null,
                    nAud_Accion: "I",
                    cTabCodigo: 'ME06'
                }

                await instance
                    .post("/Auditoria/Insertar", postData2)
                    .then(response => {
                    });

                setModalInsertar(!modalInsertar)

                if (idClientSlt == undefined || idClientSlt == null) {
                    idClientSlt = getDecryptedItem('CASOIDCli') || getDecryptedItem("CLIID")
                }

                let objMails = {
                    idIng: parseInt(idIng),
                    casoSlt: getcasoSlt,
                    idClient: parseInt(idClientSlt),
                    parametro: {
                        tpoTbl: 'ingreso',   // tipo de tabla
                        tpoAct: 'insertar'  // insertar o actualizar
                    },
                    dataIngreso: postData,
                }

                await instance
                    .post("/mail/event/ingreso", objMails)
                    .then(res => {
                        ReloadLista()
                    })
                    .catch(error => {

                    })

                Swal.fire({
                    title: "Mensaje",
                    text: "Ingreso registrado exitosamente!",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })

            })
            .catch(error => {
            })
    }

    const FuncionActualizarIngreso = async () => {

        var postData = {
            nIng_Id: Number.parseInt(getIngreso.p_nIng_Id),
            nCas_Id: Number.parseInt(getIngreso.nCas_Id),
            nCue_Id: Number.parseInt(getIngreso.nCue_Id),
            cIng_Tab_Tipo: getIngreso.cIng_Tab_Tipo,
            cIng_Tab_Banco: getIngreso.cIng_Tab_Banco,
            cIng_Tab_Moneda: getIngreso.cIng_Tab_Moneda,
            cIng_Tab_FormaPago: getIngreso.cIng_Tab_FormaPago,
            nIng_Monto: parseFloat(getIngreso.nIng_MontoOld),
            dIng_Fecha: getIngreso.dIng_Fecha,
            cIng_Detalle: getIngreso.cIng_Detalle,
            cIng_Num_Ope: getIngreso.cIng_Num_Ope,
            bIng_Estado: getIngreso.bIng_Estado,
            nIng_TCambio: parseFloat(getIngreso.nIng_TCambio)
        }

        console.log(postData);
        console.log(getIngreso.nCli_Id);

        await instance
            .post("/Ingreso/Actualizar", postData)
            .then(async response => {
                let postData2 = {
                    cAud_nombre: "Ingresos",
                    nAud_IdReg: postData.nIng_Id,
                    nUsu_ID: getDecryptedItem("ID"),
                    dAud_Fecha: null,
                    nAud_Accion: "U",
                    cTabCodigo: 'ME06'
                }

                await instance
                    .post("/Auditoria/Insertar", postData2)
                    .then(response => {
                    });
                let objMails = {
                    casoSlt: postData.nCas_Id,
                    idClient: getIngreso.nCli_Id,
                    nUsu_ID: getDecryptedItem("ID"),
                    parametro: {
                        tpoTbl: 'ingreso',   // tipo de tabla
                        tpoAct: 'actualizar'  // insertar o actualizar
                    },
                    dataIngreso: postData,
                }


                await instance
                    .post("/mail/event/ingreso", objMails)
                    .then(res => {

                    })
                    .catch(error => {

                    })

                ReloadLista();
                onCloseClick();

                Swal.fire({
                    title: "Mensaje",
                    text: "Ingreso actualizado exitosamente!",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })

            })
            .catch(error => {

            })

    }



    const FuncionValidarFormulario = async () => {
        if (Object.keys(getErrores).length === 0) {
            setBlockBtn(true);

            if (modo == 0) {
                FuncionAgregarIngreso();
            }
            else if (modo == 1) {
                FuncionActualizarIngreso();
            }



        } else {
            setValidData(true);
            Swal.fire({
                title: "Mensaje",
                text: "Complete los campos requeridos",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        }

    }
    const BluerChange = e => {
        const { name, value } = e.target
        if (name == "nIng_Monto") {
            setIngreso(prevState => ({
                ...prevState,
                [name]: getIngreso.nIng_MontoFormat,
            }))
        }
        setErrores(Validacion(getIngreso))
    }
    const FocusChange = e => {
        const { name, value } = e.target
        if (name == "nIng_Monto") {
            setIngreso(prevState => ({
                ...prevState,
                [name]: getIngreso.nIng_MontoOld,
            }))
        }
    }

    const FuncionModCall = async () => {

        console.log("modo");
        console.log(modo);

        switch (modo) {
            case 0:

                console.log("nuevo ");
                setErrores(Validacion(getIngreso))
                return setdataTitulo("Nuevo ingreso");
            case 1:
                console.log(ingreso);
                setIngreso(ingreso);
                setErrores(Validacion(ingreso))
                if (ingreso.cIng_Tab_Moneda == "MO02") {
                    setDisableTpoCambio(false);
                }
                FuncionListarCasos(null)
                setDisable(true);

                if (mode == "Editar") {
                    console.log("editar");
                    mode=3
                }

                return setdataTitulo("Editar ingreso");
            case 2:

                console.log(ingreso);
                setIngreso(ingreso);
                setErrores(Validacion(ingreso))
                FuncionListarCasos(null)
                if (ingreso.cIng_Tab_Moneda == "MO02") {
                    setDisableTpoCambio(false);
                }
                return setdataTitulo("Ver detalle");

        }
    }

    const FuncionObtenerCliente = async () => {

        let postData = {
            nClid_Id: getDecryptedItem("CLIID") || getDecryptedItem('CASOIDCli')
        }

        console.log(postData);

        await instance
            .post("/Cliente/Buscar", postData)
            .then(response => {
                setCliente(response.data[0]);
                console.log(response.data[0]);


                setIngreso(prevState => ({
                    ...prevState,
                    cIng_Tab_Moneda: response.data[0]["cCli_Moneda"]
                }))
            });
    }

    const FuncionListarPermisos = async () => {
        var postData = {
          nUsu_ID: parseInt(getDecryptedItem("RolID"))
        }
        await instance
          .post("/Usuario/Acceso", postData)
          .then(response => {
            let arrayPermisos = response.data;
            const permiso = arrayPermisos.find(element => element.cUsu_Modulo == "ME06");
    
            if (permiso.bUsu_Editar == 1) {
                console.log("editar");
              setHiddenBtnEditar(false);
            }
            else {
              setHiddenBtnEditar(true);
            }
            if (permiso.bUsu_Eliminar == 1) {
              setHiddenBtnEliminar(false);
            }
            else {
              setHiddenBtnEliminar(true);
            }
          })
          .catch(error => {
    
          })
    }

    useEffect(() => {

        console.log("MODAL ABIERTO INGRESO");
        console.log(Titulo);

        FuncionListarPermisos();
        FuncionModCall();

        let _mode = mode;
        FuncionListarCliente()

        FuncionListarComboMetodoPago()
        FuncionListarComboMoneda()
        FuncionListarComboTIngreso()
        FuncionListarComboCuenta()
        if (getDecryptedItem("nCas_Id") === null) {
            setDisable(false)
        } else {
            setDisable(true)
            FuncionInformacionCaso(getDecryptedItem("nCas_Id"))
            FuncionListarCasos(getDecryptedItem("CLIID"))
            FuncionObtenerCliente();
            setIngreso(prevState => ({
                ...prevState,
                nCli_Id: getDecryptedItem("CLIID") || getDecryptedItem('CASOIDCli'),
                nCas_Id: getDecryptedItem('nCas_Id'),
                cIng_Tab_Moneda: getCliente.cCli_Moneda
            }))
        }

        // if (_mode == "Readonly") {
        //     setIngreso(Row);
        //     setHiddenBtnGuardar(true);
        //     FuncionInformacionCaso(Row.nCas_Id)
        //     setdataTitulo("Ver ingreso");
        //     FuncionListarCasos(null)
        //     setDisable(true);
        //     modo = 2;
        //     console.log("modo: ", modo);
        // }
        
        
    }, [])
    return (
        <Modal size="lg" isOpen={show}>
            <div
                className="modal-header ModalHeaderColor"
            >
                <h5
                    style={{ color: "#fff" }}
                    className="modal-title mt-0"
                    id="myModalLabel"
                >
                    {dataTitulo}
                </h5>
                <button
                    type="button"
                    onClick={() => onCloseClick()}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="mb-3 row">
                    <div className="col-md-6">                        
                        <select
                            disabled={modo == 1 || modo == 2 || disable == true}
                            className={getErrores.nCli_Id && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                            name="nCli_Id"
                            value={getIngreso && getIngreso.nCli_Id}
                            onBlur={handleBlur}
                            onChange={handleChangeFiltroCaso}>
                            <option value="0">Seleccionar...</option>
                            {dataCliente.map((row, i) => (
                                <option key={i} value={row.nCli_Id}>
                                    {row.cCli_NombreCompleto}
                                </option>
                            ))}
                        </select>
                        <label className="col-form-label labelDown">Cliente</label>{getErrores.nCli_Id && <label className="col-form-label labelDown labelRed">{getErrores.nCli_Id}</label>}
                    </div>
                    <div className="col-md-6">
                        <select
                            disabled={modo == 1 || modo == 2 || disable == true}
                            className={getErrores.nCas_Id && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                            name="nCas_Id"
                            onChange={handleChange} onBlur={handleBlur}
                            value={getIngreso && getIngreso.nCas_Id}
                            onFocus={FocusChange}
                        >
                            <option value="0">Seleccionar..</option>
                            {dataCaso.map((row, i) => (
                                <option key={i} value={row.nCas_Id}>
                                    {row.cCas_Titulo}
                                </option>
                            ))}
                        </select>
                        <label className="col-form-label labelDown">Caso</label>{getErrores.nCas_Id && <label className="col-form-label labelDown labelRed">{getErrores.nCas_Id}</label>}
                    </div>
                </div>
                <div className="mb-3 row">
                    <div className="col-md-6">
                        <label
                            htmlFor="example-text-input"
                            style={{ paddingLeft: "10px" }}
                            className="col-md-10 col-form-label"
                        >
                            Cód Interno: {dataCodInterno || getIngreso.cCas_Cod_Interno}
                        </label>
                    </div>
                    <div className="col-md-6">
                        <label
                            htmlFor="example-text-input"
                            className="col-md-10 col-form-label"
                            style={{ paddingLeft: "10px" }}
                        >
                            Cód Externo:{dataCodExterno || getIngreso.cCas_Cod_Externo}
                        </label>
                    </div>
                </div>
                <div className="mb-3 row">
                    <div className="col-md-4">
                        <select
                            disabled={  modo == 2}
                            className={getErrores.nCue_Id && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                            name="nCue_Id"
                            onChange={handleChange}
                            value={getIngreso && getIngreso.nCue_Id}
                            onBlur={handleBlur}
                        >
                            <option value="0">Seleccionar...</option>
                            {dataComboCuenta.map((row, i) => (
                                <option key={i} value={row.nCue_Id}>
                                    {row.Cuentas}
                                </option>
                            ))}
                        </select>
                        <label className="col-form-label labelDown">Tipo de cuenta</label>{getErrores.nCue_Id && <label className="col-form-label labelDown labelRed">{getErrores.nCue_Id}</label>}
                    </div>
                    <div className="col-md-4">
                        <select
                            disabled={ modo == 2 }
                            className={getErrores.cIng_Tab_FormaPago && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                            name="cIng_Tab_FormaPago"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onFocus={FocusChange}
                            value={getIngreso && getIngreso.cIng_Tab_FormaPago}
                        >
                            <option value="0">Selecionar...</option>
                            {dataComboMPago.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                    {row.cTabNombre}
                                </option>
                            ))}
                        </select>
                        <label className="col-form-label labelDown">Forma de pago</label>{getErrores.cIng_Tab_FormaPago && <label className="col-form-label labelDown labelRed">{getErrores.cIng_Tab_FormaPago}</label>}
                    </div>
                    <div className="col-md-4">
                        <input
                            disabled={ modo == 2 }
                            name="cIng_Num_Ope"
                            className={getErrores.cIng_Num_Ope && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                            type="text"
                            placeholder="Ingrese el número de la operación"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onFocus={FocusChange}
                            maxLength="50"
                            value={getIngreso && getIngreso.cIng_Num_Ope}
                            autocomplete="off"
                        />
                        <label className="col-form-label labelDown">Número de operación</label>{getErrores.cIng_Num_Ope && <label className="col-form-label labelDown labelRed">{getErrores.cIng_Num_Ope}</label>}

                    </div>
                </div>
                <div className="mb-3 row">
                    <div className="col-md-4">
                        <select
                            disabled={ modo == 2}
                            className="form-select inputDown"
                            name="cIng_Tab_Tipo"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={getIngreso && getIngreso.cIng_Tab_Tipo}
                            onFocus={FocusChange}
                        >
                            {dataComboTIngreso.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                    {row.cTabNombre}
                                </option>
                            ))}
                        </select>
                        {getErrores.cTabCodigo && <label className="col-form-label labelDown labelRed">{getErrores.cTabCodigo}</label> || <label className="col-form-label labelDown">Tipo de ingreso</label>}
                    </div>
                    <div className="col-md-4">
                        <select
                            disabled={ modo == 2 || blockComboMoneda == true}
                            className="form-select inputDown"
                            name="cIng_Tab_Moneda"
                            onChange={handleChange}
                            value={getIngreso && getIngreso.cIng_Tab_Moneda}
                            onBlur={handleBlur}
                            onFocus={FocusChange}
                        >
                            <option value="0">Seleccionar</option>
                            {dataComboMoneda.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                    {row.cTabNombre}
                                </option>
                            ))}
                        </select>
                        <label className="col-form-label labelDown">Moneda</label>{getErrores.cIng_Tab_Moneda && <label className="col-form-label labelDown labelRed">{getErrores.cIng_Tab_Moneda}</label>}
                    </div>
                    <div className="col-md-4">
                        <input
                            disabled={ modo == 2 }
                            name="nIng_Monto"
                            className={getErrores.nIng_Monto && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                            type="text"
                            placeholder="Ingrese el monto"
                            value={getIngreso && getIngreso.nIng_Monto}
                            onChange={handleChange}
                            onBlur={BluerChange}
                            onFocus={FocusChange}
                            style={{ textAlign: "right" }}
                        />
                        <label className="col-form-label labelDown">Monto</label>{getErrores.nIng_Monto && <label className="col-form-label labelDown labelRed">{getErrores.nIng_Monto}</label>}
                    </div>

                </div>
                <div className="mb-3 row">
                    <div className="col-md-4">
                        <input
                            disabled={ modo == 2 }
                            name="dIng_Fecha"
                            className="form-control"
                            type="date"
                            value={getIngreso.dIng_Fecha}
                            onChange={handleChange}
                            onFocus={FocusChange}
                        />
                        <label className="col-form-label labelDown">
                            Fecha de abono
                        </label>
                    </div>
                    <div className="col-md-8">
                        <textarea
                            disabled={ modo == 2 }
                            name="cIng_Detalle"
                            className="form-control"
                            type="text"
                            placeholder="Ingrese la descripción"
                            onChange={handleChange}
                            maxLength="400"
                            value={getIngreso && getIngreso.cIng_Detalle}
                        ></textarea>
                        <label className="col-form-label labelDown">
                            Descripción
                        </label>
                    </div>


                </div>
                <div className="mb-3 row">

                    <div className="col-md-4" hidden={disableTpoCambio}>
                        <input
                            disabled={ modo == 2 || disable == true || disableTpoCambio}
                            className={getErrores.nIng_TCambio && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                            type="text"
                            placeholder="Tipo de cambio"
                            name="nIng_TCambio"
                            value={getIngreso && getIngreso.nIng_TCambio}
                            onChange={handleChange}
                            onBlur={BluerChange}
                            onFocus={FocusChange}
                            style={{ textAlign: "right" }}
                        />
                        <label className="col-form-label labelDown">Tipo de cambio</label>{getErrores.nIng_TCambio && <label className="col-form-label labelDown labelRed">{getErrores.nIng_TCambio}</label>}
                    </div>

                </div>


                <div className="mb-3 row" style={{ marginTop: '20px', marginBottom: '0px' }}>
                    <div className="col-md-12">
                        <label className="col-form-label labelDown labelRed">(*) Campos obligatorios</label>
                        <label className="col-form-label labelDown"></label>
                    </div>
                </div>

            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => onCloseClick()}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                >
                    Cerrar
                </button>
                <button
                    
                    disabled={modo == 2 || blockBtn}
                    type="button"
                    className="btn btn-danger waves-effect waves-light"
                    hidden={hiddenBtnGuardar || hiddenBtnEditar || modo == 2}
                    onClick={() => FuncionValidarFormulario()}
                >
                    Guardar
                </button>
            </div>
        </Modal>
    )
}
export default ModalIngreso