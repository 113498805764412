import React, { Component, useState, useEffect } from "react"
import moment from 'moment'
import { Link } from "react-router-dom"
import MetaTags from "react-meta-tags"
import { MDBDataTable } from "mdbreact"
import { getDecryptedItem } from '../../util';

import {
    CardBody,
    Card,
    Col,
    Container,
    CardTitle,
    Button,
    Row,
    Modal,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"

import instance from '../../config'
import Select from "react-select"
import Swal from "sweetalert2"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faPlus,
    faSearch,
    faTimes,
    faChevronLeft,
    faChevronRight,
    faPaperPlane,
    faBuilding,
    faUser,
    faCalendar,
    faCircle,
    faClipboard,
    faMapMarkedAlt,
} from "@fortawesome/free-solid-svg-icons"
import { get } from "lodash"

const baseUrl = process.env.REACT_APP_API_URL

var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año

var clienteSelected = ""
var ttlCaso = "";

if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10

var horaInicio = Fecha.getHours() + ':' + '00';
var horaFin = Fecha.getHours() + 1 + ':' + '00';

const Validacion = (form) => {


    let errors = {};
    let regexLetras = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    let regexCorreo = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    let regexNumero = /^[0-9]+$/;

    console.log("Evento");
    console.log(form);

    if (form.nCli_Id == 0) {
        errors.nCli_Id = "(*)"
    }

    if (form.nCas_Id == 0) {
        errors.nCas_Id = "(*)"
    }

    if (form.cEve_Tab_Categoria == 0) {
        errors.cEve_Tab_Categoria = "(*)"
    }

    if (!form.cEve_Titulo.trim()) {
        errors.cEve_Titulo = "(*)"
    }

    if (form.cEve_tab_Recordar == 0) {
        errors.cEve_tab_Recordar = "(*)"
    }

    if (form.bEve_TodoDía == 3) {
        errors.bEve_TodoDía = "(*)"
    }

    if (form.cEve_Tab_Tipo == 0) {
        errors.cEve_Tab_Tipo = "(*)"
    }

    if (form.cEve_Estado == 0) {
        errors.cEve_Estado = "(*)"
    }

    return errors;
}


const ModalEvento = ({ show, onCloseClick, ReloadLista, Titulo, onCloseModal, modo, evento }) => {
    const [dataTipoEvento, setDataTipoEvento] = useState([])
    const [dataCategoriaEvento, setDataCategoriaEvento] = useState([])
    const [dataRecordarEvento, setDataRecordarEvento] = useState([])
    const [dataCliente, setDataCliente] = useState([])
    const [dataCaso, setDataCaso] = useState([])
    const [dataComboUsuario, setDataComboUsuario] = useState([])
    const [dataEstadoEvento, setDataEstadoEvento] = useState([])
    const [getErrores, setErrores] = useState({});
    const [dataCodInterno, setdataCodInterno] = useState("")
    const [dataCodExterno, setdataCodExterno] = useState("")
    const [dataTitulo, setdataTitulo] = useState("")
    const [blockInputs, setBlockInputs] = useState(false)
    const [GetParticipantes, setParticipantes] = useState([])
    const [validData, setValidData] = useState(false)

    const [disable, setDisable] = useState(false)
    const [blockBtn, setBlockBtn] = useState(false)
    const [getEvento, setEvento] = useState({
        nEve_Id: "",
        nCli_Id: "", // encriptar (pendiente)
        nCas_Id: "",    // encriptar (pendiente)
        cEve_Tab_Tipo: "",
        cEve_Tab_Categoria: "",
        cEve_Titulo: "",
        cEve_Ubicacion: "",
        cEve_Descripcion: "",
        dEve_FechaIni: ano + "-" + mes + "-" + dia,
        dEve_FechaFin: ano + "-" + mes + "-" + dia,
        bEve_TodoDía: "0",
        tEve_HoraIni: horaInicio,
        tEve_HoraFin: horaFin,
        cEve_tab_Recordar: "PE07",
        cEve_Estado: "TT01",
        bEve_Estado: "",
        cCas_Cod_Interno: "",
        cCas_Cod_Externo: "",

    })
    const [_nCor_Id, set_nCor_Id] = useState(0)
    const [blockBtnUPD, setBlockBtnUPD] = useState(false)
    const [hiddenBtnEditar, setHiddenBtnEditar] = useState(true)
    const [hiddenBtnEliminar, setHiddenBtnEliminar] = useState(true)

    const FuncionListarPermisos = async () => {
        var postData = {
          nUsu_ID: parseInt(getDecryptedItem("RolID"))
        }
        await instance
          .post("/Usuario/Acceso", postData)
          .then(response => {
            let arrayPermisos = response.data;
            const permiso = arrayPermisos.find(element => element.cUsu_Modulo == "ME04");
    
            if (permiso.bUsu_Editar == 1) {
              setHiddenBtnEditar(false);
            }
            else {
              setHiddenBtnEditar(true);
            }
            if (permiso.bUsu_Eliminar == 1) {
              setHiddenBtnEliminar(false);
            }
            else {
              setHiddenBtnEliminar(true);
            }
          })
          .catch(error => {
    
          })
    }

    const handleChange = e => {

        const { name, value } = e.target
        setEvento(prevState => ({
            ...prevState,
            [name]: value,
        }))
        console.log(getEvento)

        if (name == "dEve_FechaIni") {
            setEvento(prevState => ({
                ...prevState,
                dEve_FechaFin: value,
            }))
        }

        if (e.target.name == "nCas_Id") {
            FuncionInformacionCaso(e.target.value)
            FuncionListarComboUsuario(e.target.value)
            setParticipantes([{ value: parseInt(getDecryptedItem("ID")), label: getDecryptedItem('NombreC'), email: getDecryptedItem('Correo') }]);

        }

    }

    const handleBlur = e => {
        handleChange(e)

        // Obtener el nombre del campo y el valor actualizado
        const { name, value } = e.target;

        if (name == "tEve_HoraIni" || name == "tEve_HoraFin") {

            console.log("valor de la hora :", value); //valor de la hora : 20:00
            var [hour, minute] = value.split(":").map(Number);

            // Sumamos 1 minuto
            minute += 1;

            // Verificamos si se pasa de 59 minutos, en ese caso incrementamos la hora y reiniciamos los minutos a 0
            if (minute >= 60) {
                minute = 0;
                hour += 1;
            }

            // Formateamos nuevamente la hora y minutos a una cadena en formato HH:MM
            var horaFin = `${hour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`;

            // Mostramos el nuevo valor en consola
            console.log("Nuevo valor de la hora:", horaFin);
            setEvento((prevState) => ({
                ...prevState,
                tEve_HoraFin: horaFin
            }));

            if (value.length == 0) {
                const now = new Date(); // Obtener la fecha y hora actual
                let hours = now.getHours(); // Obtener solo la hora actual
                console.log(hours);

                if (hours < 10) {
                    hours = "0" + hours
                }

                const newValue = hours + ":00";
                console.log(newValue);

                setEvento((prevState) => ({
                    ...prevState,
                    [name]: newValue
                }));
            }
        }

        setErrores(Validacion(getEvento))
        console.log(getErrores)
    }

    const handleChangeFiltroCaso = e => {
        console.log("este seria el cliente");

        clienteSelected = e.target.value;
        console.log(clienteSelected);

        setEvento((prevState) => ({
            ...prevState,
            nCli_Id: clienteSelected,
        }));



        if (e.target.value == 0) {
            FuncionListarCasos(null)
        } else {
            FuncionListarCasos(e.target.value)

        }
    }

    const handleChangeParticipantes = value => {
        console.log("vvvv :", value);
        if (value == null) {

        } else {
            setParticipantes(value);
            console.log("participantes :", GetParticipantes);
        }

    };

    const FuncionEnviarCorreo = async (_email) => {
        var postData = {
            id: Number.parseInt(0),
            email: _email,
        }

        await instance
            .post("/mail/event/evento", postData)
            .then(response => {

            })
            .catch(error => {
                console.log(error)
            })
    }

    /** Configuracion de prueba */

    const EnvioCorreo = async () => {

        await instance
            .post("/mail/event/evento", datos)
            .then(res => {

                console.log(res)
                Swal.fire({
                    title: "Mensaje",
                    text: "Agregado exitosamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })
            })
            .catch(error => {
                console.log(error)
            })

    }

    /********************** */
    const FuncionGuardarEvento = async () => {

        var postData = {
            nEve_Id: getEvento.nEve_Id,
            nCas_Id: Number.parseInt(getEvento.nCas_Id),
            cEve_Tab_Tipo: getEvento.cEve_Tab_Tipo,
            cEve_Tab_Categoria: getEvento.cEve_Tab_Categoria,
            cEve_Titulo: getEvento.cEve_Titulo,
            cEve_Ubicacion: getEvento.cEve_Ubicacion,
            cEve_Descripcion: getEvento.cEve_Descripcion,
            dEve_FechaIni: getEvento.dEve_FechaIni,
            dEve_FechaFin: getEvento.dEve_FechaFin,
            bEve_TodoDía: Number.parseInt(getEvento.bEve_TodoDía),
            tEve_HoraIni: getEvento.tEve_HoraIni,
            tEve_HoraFin: getEvento.tEve_HoraFin,
            cEve_tab_Recordar: getEvento.cEve_tab_Recordar,
            bEve_Estado: getEvento.bEve_Estado,
            cEve_Estado: getEvento.cEve_Estado
        }

        var now = new Date()
        var fechaSys = moment(now).format('yyyy-MM-DD');
        var fft = moment(getEvento.dEve_FechaIni).format('YYYY-MM-DD'); //Fecha de fin de tarea   
        var fecha1 = moment(fechaSys); //Fecha del servidor
        var fecha2 = moment(fft); //Fecha del fin
        const found = dataRecordarEvento.find(element => element.cTabCodigo == getEvento.cEve_tab_Recordar);
        const diasRecordatorio = found.cTabSimbolo;
        var diferencia = fecha2 - fecha1;
        diferencia = diferencia / (1000 * 60 * 60 * 24);

        if (diferencia < 0) {

            Swal.fire({
                title: "Mensaje",
                text: "La fecha de inicio es menor que la fecha del sistema",// El periodo de recordatorio es mayor que la fecha de vencimiento
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        }
        else {

            if (diferencia >= diasRecordatorio) {
                let horaIniFormat = postData.dEve_FechaIni + " " + postData.tEve_HoraIni;
                let fechaI = new Date(horaIniFormat);

                let horaFinFormat = postData.dEve_FechaFin + " " + postData.tEve_HoraFin;
                let fechaFin = new Date(horaFinFormat);

                let horaSistema = new Date();

                if (fechaI >= fechaFin) {
                    Swal.fire({
                        title: "Mensaje",
                        text: "La fecha de inicio no puede ser mayor a la fecha final",
                        icon: "error",
                        confirmButtonText: "Aceptar",
                    });
                }
                else if (fechaI <= horaSistema && postData.cEve_Estado == "TT01") {
                    Swal.fire({
                        title: "Mensaje",
                        text: "La fecha de inicio no puede ser menor a la fecha actual",
                        icon: "error",
                        confirmButtonText: "Aceptar",
                    });
                }
                else if (fechaFin <= horaSistema && postData.cEve_Estado == "TT01") {
                    Swal.fire({
                        title: "Mensaje",
                        text: "La fecha final no puede ser menor a la fecha actual",
                        icon: "error",
                        confirmButtonText: "Aceptar",
                    });
                }
                else {

                    setBlockBtnUPD(true);

                    if (modo == 0) {
                        FuncionInsEvento(postData)
                    }
                    else if (modo == 1) {
                        FuncionUpdEvento(postData);
                    }

                    // await instance
                    //     .post("/Evento/Insertar", postData)
                    //     .then(async response => {

                    //         idEvent = response.data[0].nEve_Id; // este es el id del evento creado

                    //         let postData2 = {
                    //             cAud_nombre: "Eventos",
                    //             nAud_IdReg: idEvent,
                    //             nUsu_ID: getDecryptedItem("ID"),
                    //             dAud_Fecha: null,
                    //             nAud_Accion: "I",
                    //             cTabCodigo:'ME04'
                    //         }

                    //         await instance
                    //         .post("/Auditoria/Insertar", postData2)
                    //         .then(response => {
                    //         });

                    //         const Participantes = GetParticipantes.map((row, i) => {
                    //             FuncionAgregarParticipantes(response.data[0].nEve_Id,row.value)
                    //         })

                    //         Swal.fire({
                    //             title: "Mensaje",
                    //             text: "Agregado exitosamente",
                    //             icon: "success",
                    //             confirmButtonText: "Aceptar",
                    //         })



                    //     })
                    //     .catch(error => {
                    //         console.log(error)
                    //     });
                    //     ReloadLista()





                }
            }
            else {
                Swal.fire({
                    title: "Mensaje",
                    text: "El periodo de recordatorio es mayor que la fecha de inicio",// El periodo de recordatorio es mayor que la fecha de vencimiento
                    icon: "error",
                    confirmButtonText: "Aceptar",
                })
            }
        }
    }

    const FuncionUpdEvento = async (getEvento) => {

        var nomCat = null;
        for (const categoriaEvento of dataCategoriaEvento) {
            if (categoriaEvento.cTabCodigo === getEvento.cEve_Tab_Categoria) {
                nomCat = categoriaEvento.cTabNombre;
                break; // detener la búsqueda una vez que se encuentra el objeto correspondiente
            }
        }

        const cEve_Tab_Tipo = dataTipoEvento.find(element => element.cTabCodigo === getEvento.cEve_Tab_Tipo);

        let postData = {
            getParticipantes: GetParticipantes,
            evento: getEvento,
            categoria: nomCat,
            cEve_Tab_Tipo: cEve_Tab_Tipo.cTabNombre,
            nUsu_ID: getDecryptedItem("ID"),
            parametro: {
                tpoTbl: 'evento',   // tipo de tabla
                tpoAct: 'actualizar'  // insertar o actualizar
            }
        }

        console.log(postData);

        await instance
            .post("/Evento/Actualizar", postData)
            .then(async response => {

                console.log(response);

                Swal.fire({
                    title: "Mensaje",
                    text: "Actualizado exitosamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })
                setBlockBtnUPD(false);
                onCloseModal()
            })
            .catch(error => {
                console.log(error)
            })

    }

    const FuncionInsEvento = async (getEvento) => {

        var nomCat = null;
        for (const categoriaEvento of dataCategoriaEvento) {
            if (categoriaEvento.cTabCodigo === getEvento.cEve_Tab_Categoria) {
                nomCat = categoriaEvento.cTabNombre;
                break; // detener la búsqueda una vez que se encuentra el objeto correspondiente
            }
        }

        var cEve_Tab_Tipo = dataTipoEvento.find(element => element.cTabCodigo === getEvento.cEve_Tab_Tipo);

        let postData = {
            getParticipantes: GetParticipantes,
            evento: getEvento,
            categoria: nomCat,
            cEve_Tab_Tipo: cEve_Tab_Tipo.cTabNombre,
            nUsu_ID: getDecryptedItem("ID"),
            parametro: {
                tpoTbl: 'evento',   // tipo de tabla
                tpoAct: 'insertar'  // insertar o actualizar
            }
        }

        console.log(postData);

        await instance
            .post("/Evento/Insertar", postData)
            .then(async response => {

                console.log(response);
                let idEvent = response.data[0]["nEve_Id"];

                Swal.fire({
                    title: "Mensaje",
                    text: "Agregado exitosamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })
                setBlockBtnUPD(false);
                onCloseModal()

                /************** Envio de correo *******/
                var nomCat = undefined;
                for (let i = 0; i < dataCategoriaEvento.length; i++) {
                    console.log(dataCategoriaEvento[i].cTabCodigo, " - ", getEvento.cEve_Tab_Categoria);
                    if (dataCategoriaEvento[i].cTabCodigo == getEvento.cEve_Tab_Categoria) {
                        nomCat = dataCategoriaEvento[i].cTabNombre;
                    }
                }

                console.log(dataTipoEvento);

                let objMails = {
                    categoria: nomCat,
                    cEve_Tab_Tipo: cEve_Tab_Tipo.cTabNombre,
                    ttlCaso: ttlCaso,
                    idEvent: idEvent,
                    cliente: clienteSelected,
                    parametro: {
                        tpoTbl: 'evento',   // tipo de tabla
                        tpoAct: 'insertar'  // insertar o actualizar
                    },
                    dataEvent: postData,
                    arrayParticipantes: GetParticipantes,
                }

                console.log(objMails);

                await instance
                    .post("/mail/event/evento", objMails)
                    .then(res => {
                    })
                    .catch(error => {
                        console.log(error)
                    });


            })
            .catch(error => {
                console.log(error)
            })

    }


    const FuncionCorreoEvento = async (_nCas_Id, _nUsu_Id, _cCor_Tab_Tipo, _cCor_Asunto) => {
        var postData = {
            nCas_Id: _nCas_Id,
            nUsu_Id: _nUsu_Id,
            cCor_Tab_Tipo: _cCor_Tab_Tipo,
            cCor_Asunto: _cCor_Asunto,
        }

        await instance
            .post("/Correo/Insertar", postData)
            .then(response => {

                console.log(response.data[0].nCor_Id);
                set_nCor_Id(response.data[0].nCor_Id);

            })
            .catch(error => {
                console.log(error)
            })
    }

    const FuncionValidarFormulario = async () => {
        if (Object.keys(getErrores).length === 0) {

            setBlockBtn(true);
            FuncionGuardarEvento();

        } else {

            setValidData(true);
            Swal.fire({
                title: "Mensaje",
                text: "Complete los campos requeridos",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        }
    }

    const FuncionAgregarParticipantes = async (_nEve_Id, _nUsu_ID) => {

        var postData = {
            nEve_Id: Number.parseInt(_nEve_Id),
            nUsu_ID: Number.parseInt(_nUsu_ID),
        }

        await instance
            .post("/Evento/Insertar/Participante", postData)
            .then(response => {

            })
            .catch(error => {
                console.log(error)
            })
    }

    const FuncionInformacionCaso = async (_nCas_Id) => {
        console.log(_nCas_Id);
        var postData = {
            nCas_Id: _nCas_Id,
        }
        await instance
            .post("/Casos/Buscar", postData)
            .then(response => {

                console.log(response.data[0])
                ttlCaso = response.data[0]["cCas_Titulo"]
                setdataCodInterno(response.data[0].cCas_Cod_Interno)
                setdataCodExterno(response.data[0].cCas_Cod_Externo)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const FuncionListarComboTipoEvento = async () => {
        var postData = {
            Prefijo: "TE",
        }
        await instance
            .post("/Maestros/Data", postData)
            .then(response => {
                setDataTipoEvento(response.data)
                console.log(response.data)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const FuncionListarComboCategoriaEvento = async () => {
        var postData = {
            Prefijo: "CE",
        }
        await instance
            .post("/Maestros/Data", postData)
            .then(response => {
                setDataCategoriaEvento(response.data)
                console.log(response.data)
            })
            .catch(error => {
                console.log(error)
            })
    }

    function sortJSON(data, key, orden) {
        return data.sort(function (a, b) {
            var x = a[key],
                y = b[key];

            if (orden === 'asc') {
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            }

            if (orden === 'desc') {
                return ((x > y) ? -1 : ((x < y) ? 1 : 0));
            }
        });
    }

    const FuncionListarComboRecordarEvento = async () => {
        var postData = {
            Prefijo: "PE",
        }
        await instance
            .post("/Maestros/Data", postData)
            .then(response => {
                setDataRecordarEvento(response.data)
                console.log(response.data)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const FuncionListarCliente = async () => {
        let RolId = getDecryptedItem("RolID")

        var postData = {
            bCli_Tipo: null,
            nUsu_ID: RolId == 6 || RolId == 1 ? null : getDecryptedItem("ID")
        }
        await instance
            .post("/Cliente/ListarCbo", postData)
            .then(response => {
                setDataCliente(response.data)
                console.log(response.data)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const FuncionListarCasos = async (_idClient) => {
        var postData = {
            idClient: _idClient,
            nUsu_Id: getDecryptedItem("ID"),
        }
        console.log(postData)
        await instance
            .post("/Casos/ListarCbo", postData)
            .then(response => {
                setDataCaso(response.data)
                console.log(response.data)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const FuncionListarComboUsuario = async (nCas_Id) => {

        var postData = {
            nCas_Id: nCas_Id,
        }

        await instance
            .post("/Casos/FiltroUsuarioCaso", postData)
            .then(response => {
                console.log(response.data)
                setDataComboUsuario(response.data)

            })
            .catch(error => {
                console.log(error)
            })
    }

    const FuncionListarEstadoEvento = async () => {
        var postData = {
            Prefijo: "TT",
        }
        await instance
            .post("/Maestros/Data", postData)
            .then(response => {
                setDataEstadoEvento(response.data)
                console.log(response.data)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const UsuariosSelect = dataComboUsuario.map((row, i) => ({
        value: row.nUsu_Id,
        label: row.cUsu_Nombres,
        color: "#556ee6",
        email: row.cUsu_email,
    }))
    console.log(UsuariosSelect);



    const FuncionBuscarEventosParticipantes = async (_nEve_Id) => {   // Aqui se carga GetdefaultParticipantes
        var postData = {
            nEve_Id: _nEve_Id,
        }
        await instance
            .post("/Evento/Buscar/Participantes", postData)
            .then(response => {
                console.log(response.data);
                setParticipantes(response.data);

            })
            .catch(error => {
                console.log(error)
            })
    }

    const FuncionModCall = async () => {

        console.log(modo);
        FuncionListarCliente()


        switch (modo) {
            case 0:

                setErrores(Validacion(getEvento))
                return setdataTitulo("Nuevo evento");
            case 1:

                setEvento(evento);
                setErrores(Validacion(evento))
                FuncionBuscarEventosParticipantes(evento.nEve_Id);
                FuncionListarComboUsuario(evento.nCas_Id);
                FuncionListarCasos(null)
                return setdataTitulo("Editar evento");
            case 2:
                setBlockInputs(true);
                setEvento(evento);
                FuncionBuscarEventosParticipantes(evento.nEve_Id);
                FuncionListarCasos(null)
                return setdataTitulo("Ver detalle");
        }
    }

    useEffect(() => {

        FuncionListarPermisos();
        FuncionModCall();

        FuncionListarComboTipoEvento()
        FuncionListarComboCategoriaEvento()
        FuncionListarComboRecordarEvento()


        FuncionListarEstadoEvento()
        if (getDecryptedItem("nCas_Id") === null) {
            setDisable(false)

        } else {
            setDisable(true)
            FuncionListarCasos(null)
            FuncionInformacionCaso(getDecryptedItem("nCas_Id"));
            FuncionListarComboUsuario(getDecryptedItem("nCas_Id"));
            setParticipantes([{ value: getDecryptedItem("ID"), label: getDecryptedItem('NombreC'), email: getDecryptedItem('Correo') }]);
            clienteSelected = getDecryptedItem("CLIID")

            setEvento(prevState => ({
                ...prevState,
                nCas_Id: getDecryptedItem("nCas_Id"),
                nCli_Id: getDecryptedItem("CLIID"),
            }))
        }
    }, [])

    return (
        <Modal isOpen={show}>
            <div
                className="modal-header ModalHeaderColor"
            >
                <h5
                    style={{ color: "#fff" }}
                    className="modal-title mt-0"
                    id="myModalLabel"
                >
                    {dataTitulo}
                </h5>
                <button
                    type="button"
                    onClick={() => onCloseModal()}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div className="modal-body">
                <div className="mb-2 row">

                    <div className="col-md-6">
                        <select
                            disabled={modo == 1 || modo == 2 || disable == true}
                            className={getErrores.nCli_Id && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                            name="nCli_Id"
                            onChange={handleChangeFiltroCaso}
                            onBlur={handleBlur}
                            value={getEvento && getEvento.nCli_Id} >
                            <option value="0">Seleccionar...</option>
                            {dataCliente.map((row, i) => (
                                <option key={i} value={row.nCli_Id}>
                                    {row.cCli_NombreCompleto}
                                </option>
                            ))}
                        </select>
                        <label className="col-form-label labelDown">Cliente</label>{getErrores.nCli_Id && <label className="col-form-label labelDown labelRed">{getErrores.nCli_Id}</label>}
                    </div>
                    <div className="col-md-6">
                        <select
                            disabled={modo == 1 || modo == 2 || disable == true}
                            className={getErrores.nCas_Id && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                            name="nCas_Id"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={getEvento && getEvento.nCas_Id}
                        >
                            <option value="0">Seleccionar...</option>
                            {dataCaso.map((row, i) => (
                                <option key={i} value={row.nCas_Id}>
                                    {row.cCas_Titulo}
                                </option>
                            ))}
                        </select>
                        <label className="col-form-label labelDown">Caso</label>{getErrores.nCas_Id && <label className="col-form-label labelDown labelRed">{getErrores.nCas_Id}</label>}
                    </div>
                </div>
                <div className="mb-1 row">
                    <div style={{ height: "20px;" }} className="col-md-5">
                        <label
                            htmlFor="example-text-input"
                            className="col-md-12 col-form-label"
                            style={{ marginLeft: "5px" }}
                        >
                            Cód Interno: {dataCodInterno === "" ? getEvento.cCas_Cod_Interno : dataCodInterno}
                        </label>
                    </div>
                    <div className="col-md-7">
                        <label
                            htmlFor="example-text-input"
                            className="col-md-12 col-form-label"
                            style={{ marginLeft: "2px" }}
                        >
                            Cód Externo: {dataCodInterno === "" ? getEvento.cCas_Cod_Externo : dataCodExterno}
                        </label>
                    </div>
                </div>
                <div className="mb-3 row" style={{ marginTop: '15px' }}>
                    <div className="col-md-6">
                        <select
                            disabled={modo == 2}
                            className={getErrores.cEve_Tab_Categoria && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                            value={getEvento && getEvento.cEve_Tab_Categoria}
                            name="cEve_Tab_Categoria"
                            onChange={handleChange}
                            onBlur={handleBlur}
                        >
                            <option value="0">Seleccionar...</option>
                            {dataCategoriaEvento.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                    {row.cTabNombre}
                                </option>
                            ))}
                        </select>
                        <label className="col-form-label labelDown">Categoría</label>{getErrores.cEve_Tab_Categoria && <label className="col-form-label labelDown labelRed">{getErrores.cEve_Tab_Categoria}</label>}
                    </div>
                    <div className="col-md-6">
                        <input
                            disabled={modo == 2}
                            className={getErrores.cEve_Titulo && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                            value={getEvento && getEvento.cEve_Titulo}
                            placeholder="Ingrese el título"
                            name="cEve_Titulo"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            maxLength="200"
                        />
                        <label className="col-form-label labelDown">Título</label>{getErrores.cEve_Titulo && <label className="col-form-label labelDown labelRed">{getErrores.cEve_Titulo}</label>}
                    </div>
                </div>
                <div className="mb-3 row">
                    <div className="col-md-12">
                        <input
                            disabled={modo == 2}
                            className="form-control"
                            placeholder="Ingrese la ubicación"
                            name="cEve_Ubicacion"
                            value={getEvento && getEvento.cEve_Ubicacion}
                            onChange={handleChange}
                            maxLength="200"
                        />
                        <label className="col-form-label labelDown">
                            Ubicación / enlace de entrada
                        </label>
                    </div>
                </div>
                <div className="mb-3 row">
                    <div className="col-md-12">
                        <textarea
                            disabled={modo == 2}
                            className="form-control"
                            placeholder="Ingrese la descripción"
                            name="cEve_Descripcion"
                            maxLength="200"
                            onChange={handleChange}
                            value={getEvento && getEvento.cEve_Descripcion}
                        ></textarea>
                        <label className="col-form-label labelDown">
                            Descripción
                        </label>
                    </div>
                </div>
                <div className="mb-3 row">
                    <div className="col-md-12">
                        <Select
                            isDisabled={modo == 2}
                            readonl
                            isMulti
                            onChange={handleChangeParticipantes}
                            name="colors"
                            options={UsuariosSelect}
                            className="basic-multi-select"
                            placeholder="Ingrese los participantes"
                            value={GetParticipantes}
                        />
                        <label className="col-form-label labelDown">
                            Participantes
                        </label>
                    </div>
                </div>
                <div className="mb-3 row">
                    <div className="col-md-6">
                        <input
                            disabled={modo == 2}
                            className="form-control"
                            type="date"
                            name="dEve_FechaIni"
                            value={getEvento && getEvento.dEve_FechaIni}
                            onChange={handleChange}
                        />
                        <label className="col-form-label labelDown">
                            Fecha de inicio
                        </label>
                    </div>
                    <div className="col-md-6">
                        <input
                            disabled={modo == 2}
                            className="form-control"
                            type="time"
                            name="tEve_HoraIni"
                            value={getEvento && getEvento.tEve_HoraIni}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <label className="col-form-label labelDown">
                            Hora de inicio
                        </label>
                    </div>
                </div>
                <div className="mb-3 row">
                    <div className="col-md-6">
                        <input
                            disabled={modo == 2}
                            className="form-control"
                            type="date"
                            value={getEvento && getEvento.dEve_FechaFin}
                            name="dEve_FechaFin"
                            onChange={handleChange}
                        />
                        <label className="col-form-label labelDown">
                            Fecha de fin
                        </label>
                    </div>
                    <div className="col-md-6">
                        <input
                            disabled={modo == 2}
                            className="form-control"
                            type="time"
                            name="tEve_HoraFin"
                            value={getEvento && getEvento.tEve_HoraFin}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <label className="col-form-label labelDown">
                            Hora de fin
                        </label>
                    </div>
                </div>
                <div className="mb-3 row">
                    <div className="col-md-6">
                        <select
                            disabled={modo == 2}
                            className="form-select"
                            name="cEve_tab_Recordar"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={getEvento && getEvento.cEve_tab_Recordar}
                        >
                            <option value="0">Seleccionar...</option>
                            {sortJSON(dataRecordarEvento, 'cTabCodigo', 'asc').map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                    {row.cTabNombre}
                                </option>
                            ))}
                        </select>
                        <label className="col-form-label labelDown">Recordar</label>{getErrores.cEve_tab_Recordar && <label className="col-form-label labelDown labelRed">{getErrores.cEve_tab_Recordar}</label>}

                    </div>
                    <div className="col-md-6">
                        <select
                            disabled={modo == 2}
                            className="form-select"
                            name="bEve_TodoDía"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={getEvento && getEvento.bEve_TodoDía}
                        >
                            <option value="3">Seleccionar...</option>
                            <option value="1">Si</option>
                            <option value="0">No</option>
                        </select>
                        <label className="col-form-label labelDown">Todo el día</label>{getErrores.bEve_TodoDía && <label className="col-form-label labelDown labelRed">{getErrores.bEve_TodoDía}</label>}

                    </div>
                </div>
                <div className="mb-3 row">
                    <div className="col-md-6">
                        <select
                            disabled={modo == 2}
                            className={getErrores.cEve_Tab_Tipo && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                            name="cEve_Tab_Tipo"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={getEvento && getEvento.cEve_Tab_Tipo}
                        >
                            <option value="00">Seleccionar...</option>
                            {dataTipoEvento.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                    {row.cTabNombre}
                                </option>
                            ))}
                        </select>
                        <label className="col-form-label labelDown">Tipo de Evento</label>{getErrores.cEve_Tab_Tipo && <label className="col-form-label labelDown labelRed">{getErrores.cEve_Tab_Tipo}</label>}
                    </div>
                    <div className="col-md-6">
                        <select className="form-select" name="cEve_Estado"
                            disabled={modo == 2}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={getEvento && getEvento.cEve_Estado}
                        >
                            <option value="0">Seleccionar...</option>
                            {dataEstadoEvento.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                    {row.cTabNombre}
                                </option>
                            ))}
                        </select>
                        <label className="col-form-label labelDown">Estado</label>{getErrores.cEve_Estado && <label className="col-form-label labelDown labelRed">{getErrores.cEve_Estado}</label>}

                    </div>
                </div>

                <div className="mb-3 row" style={{ marginTop: '20px', marginBottom: '0px' }}>
                    <div className="col-md-12">
                        <label className="col-form-label labelDown labelRed">(*) Campos obligatorios</label>
                        <label className="col-form-label labelDown"></label>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => onCloseModal()}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                >
                    Cerrar
                </button>
                <button
                    hidden={hiddenBtnEditar}
                    disabled={blockBtnUPD || modo == 2}
                    type="button"
                    className="btn btn-danger waves-effect waves-light"
                    //disabled={blockBtn}
                    onClick={() => FuncionValidarFormulario()}
                >
                    Guardar
                </button>
            </div>
        </Modal>
    )
}
export default ModalEvento