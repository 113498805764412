import React, { Component, useState, useEffect } from "react"
import Select from "react-select"
import Swal from "sweetalert2"
import { getDecryptedItem } from '../../util';
import { MDBDataTable } from "mdbreact"
import { Link, useParams } from "react-router-dom"
import {
    FormGroup,
    Label,
    Input,
    CardBody,
    Card,
    Col,
    Container,
    CardTitle,
    Button,
    Row,
    Modal,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
} from "reactstrap"
import instance from '../../config'
import moment from 'moment'
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
var clienteSelected = ""
var ttlCaso = "";
var horaInicio = Fecha.getHours() + ':' + Fecha.getMinutes();
var horaFin = Fecha.getHours() + 1 + ':' + '00';
const Validacion = (form) => {
    let errors = {};
    let regexLetras = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    let regexCorreo = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    let regexNumero = /^[0-9]+$/;

    if (form.nCas_Id == 0) {
        errors.nCas_Id = "(*)"
    }
    if (form.cTar_Tab_Categoria == 0 || form.cTar_Tab_Categoria == "NN00") {
        errors.cTar_Tab_Categoria = "(*)"
    }
    if (!form.cTar_Titulo.trim()) {
        errors.cTar_Titulo = "(*)"
    }
    if (form.cTar_tab_recordar == 0) {
        errors.cTar_tab_recordar = "(*)"
    }
    if (form.cTar_Tab_Tipo == 0) {
        errors.cTar_Tab_Tipo = "(*)"
    }
    return errors;
}

export const ModalTarea = ({ show, onCloseClick, ReloadLista, Titulo, arrayParticipantes }) => {
    const [modalInsertar, setModalInsertar] = useState(false)
    const [modalActualizar, setModalActualizar] = useState(false)
    const [modalFiltro, setModalFiltro] = useState(false)
    const [dataParticipantes, setDataParticipantes] = useState([])
    const [dataSelect, setDataSelect] = useState([])
    const [dataTipoTarea, setDataTipoTarea] = useState([])
    const [dataEstadoTarea, setDataEstadoTarea] = useState([])
    const [dataCategoriaTarea, setDataCategoriaTarea] = useState([])
    const [dataRecordarTarea, setDataRecordarTarea] = useState([])
    const [dataCliente, setDataCliente] = useState([])
    const [dataCaso, setDataCaso] = useState([])
    const [dataComboUsuario, setDataComboUsuario] = useState([])
    const [disable, setDisable] = useState(false)
    const [dataLista, setDatalista] = useState([])
    const [validData, setValidData] = useState(false)
    const [getErrores, setErrores] = useState({});
    const [modalInsertarlista, setModalInsertarlista] = useState(false)
    const [ttlModalLista, setModalLista] = useState("Lista :")
    const [indexLista, setIndexLista] = useState(0)
    const [dataCodInterno, setdataCodInterno] = useState("")
    const [dataCodExterno, setdataCodExterno] = useState("")
    const [GetParticipantes, setParticipantes] = useState([])
    const [blockBtn, setBlockBtn] = useState(false)
    const [getTareas, setTareas] = useState({
        nTar_Id: "",
        nCli_Id: getDecryptedItem("CLIID"),
        nCas_Id: getDecryptedItem('nCas_Id'),
        cTar_Tab_Tipo: "TT01",
        cTar_Tab_Categoria: "NN00",
        cTar_Titulo: "",
        nUsu_Asignado: getDecryptedItem("ID"),
        nUsu_Relacionado: getDecryptedItem("ID"),
        dTar_Fec_Vence: ano + "-" + mes + "-" + dia,
        tTar_Hora_Vence: horaFin,
        cTar_tab_recordar: "PE07",
        bTar_Estado: "1",
        cEstadoTarea: "MT01",
        nTar_Prioridad: 4,
        nTar_Duracion: '1',
        nTar_TipoDias: '1',
        nfFas_Id: 0,

    })
    const [getUsuario, setUsuario] = useState({
        bUsu_Activo: 1,
        cUsu_Fono: "",
        cUsu_Login: "",
        cUsu_Nombres: "",
        cUsu_Pass: "",
        cUsu_email: "",
        nRol_ID: 1,
        nRol_ID2: "",
        ImgBinary: "",
        nUsu_ID: ""
    })
    const [getListas, setListas] = useState({
        nLis_Id: null,
        nTar_Id: "",
        cLis_Nombre: "",
        nLis_Estado: ""
    })
    const UsuariosSelect = dataComboUsuario.map((row, i) => ({
        value: row.nUsu_Id,
        label: row.cUsu_Nombres,
        color: "#556ee6",
        email: row.cUsu_email,
    }))
    async function InsertarLista(_nTar_Id, _cLis_Nombre) {
        var postData = {
            nTar_Id: parseInt(_nTar_Id),
            cLis_Nombre: _cLis_Nombre,
        }

        return new Promise(async (resolve) => {
            await instance
                .post("/Tareas/ListaInsertar", postData)
                .then(response => {
                    resolve({
                        code: 'Success',
                        res: response.data
                    })
                }).catch(function (error) {
                    resolve({
                        code: 'Error',
                        res: error.response.data.message
                    })
                });
        });
    }

    const FuncionAgregarTarea = async () => {
        var postData = {
            nCas_Id: getTareas.nCas_Id,
            cTar_Tab_Tipo: getTareas.cTar_Tab_Tipo,
            cTar_Tab_Categoria: getTareas.cTar_Tab_Categoria,
            cTar_Titulo: getTareas.cTar_Titulo,
            nUsu_Asignado: getTareas.nUsu_Asignado,
            nUsu_Relacionado: getTareas.nUsu_Relacionado,
            dTar_Fec_Vence: getTareas.dTar_Fec_Vence,
            tTar_Hora_Vence: getTareas.tTar_Hora_Vence,
            cTar_tab_recordar: getTareas.cTar_tab_recordar,
            bTar_Estado: Number.parseInt(getTareas.bTar_Estado),
            nTar_Prioridad: parseInt(getTareas.nTar_Prioridad),
            nTar_Duracion: parseInt(getTareas.nTar_Duracion),
            nTar_TipoDias: parseInt(getTareas.nTar_TipoDias),
            nfFas_Id: parseInt(getTareas.nfFas_Id)
        }

        var now = new Date()
        var fechaSys = moment(now).format('yyyy-MM-DD');
        var fft = moment(getTareas.dTar_Fec_Vence).format('YYYY-MM-DD'); //Fecha de fin de tarea




        var fecha1 = moment(fechaSys); //Fecha del servidor
        var fecha2 = moment(fft); //Fecha del fin


        const found = dataRecordarTarea.find(element => element.cTabCodigo == getTareas.cTar_tab_recordar);


        const diasRecordatorio = found.cTabSimbolo;






        var diferencia = fecha2 - fecha1;
        diferencia = diferencia / (1000 * 60 * 60 * 24);



        if (diferencia < 0) {

        }
        else {
            if (diferencia >= diasRecordatorio) {

                let horaFin = postData.dTar_Fec_Vence + " " + postData.tTar_Hora_Vence;
                let fechaf = new Date(horaFin);

                let horaSistema = new Date();

                if (fechaf <= horaSistema && postData.cTar_Tab_Tipo == "TT01") {

                    Swal.fire({
                        title: "Mensaje",
                        text: "La fecha final no puede ser menor que la fecha actual",
                        icon: "error",
                        confirmButtonText: "Aceptar",
                    })

                }
                else {

                    var idTar = 0;


                    setBlockBtn(true);
                    await instance
                        .post("/Tarea/Insertar", postData)
                        .then(async response => {

                            idTar = response.data[0].nTar_Id; // este es el id de la tarea creada

                            const Participantes = GetParticipantes.map((row, i) => {
                                FuncionAgregarParticipantes(response.data[0].nTar_Id, row.value)
                            })



                            for (let i = 0; i < dataLista.length; i++) {
                                await InsertarLista(idTar, dataLista[i].cLis_Nombre);
                            }

                            let postData2 = {
                                cAud_nombre: "Tareas",
                                nAud_IdReg: idTar,
                                nUsu_ID: getDecryptedItem("ID"),
                                dAud_Fecha: null,
                                nAud_Accion: "I",
                                cTabCodigo: 'ME05'
                            }

                            await instance
                                .post("/Auditoria/Insertar", postData2)
                                .then(response => {
                                });
                            ReloadLista()
                            Swal.fire({
                                title: "Mensaje",
                                text: "Agregado exitosamente",
                                icon: "success",
                                confirmButtonText: "Aceptar",
                            })
                        })
                        .catch(error => {

                        });

                    const categoria = dataCategoriaTarea.find(element => element.cTabCodigo == postData.cTar_Tab_Categoria);

                    let objMails = {
                        categoria: categoria.cTabNombre,
                        usuario: getUsuario.cUsu_Nombres,
                        nUsu_ID: getUsuario.nUsu_ID,
                        ttlCaso: ttlCaso,
                        idTar: idTar,
                        cliente: dataCliente.find(element => element.nCli_Id == clienteSelected),
                        parametro: {
                            tpoTbl: 'tarea',   // tipo de tabla
                            tpoAct: 'insertar'  // insertar o actualizar
                        },
                        dataTarea: postData,
                        arrayParticipantes: GetParticipantes
                    }




                    await instance
                        .post("/mail/event/tarea", objMails)
                        .then(res => {

                        })
                        .catch(error => {

                        })

                    setBlockBtn(false);

                }
            }
            else {
                Swal.fire({
                    title: "Mensaje",
                    text: "El periodo de recordatorio es mayor que la fecha de vencimiento",// El periodo de recordatorio es mayor que la fecha de vencimiento
                    icon: "error",
                    confirmButtonText: "Aceptar",
                })
            }
        }

    }
    const FuncionAgregarParticipantes = async (_nTar_Id, _nUsu_ID) => {
        var postData = {
            nTar_Id: Number.parseInt(_nTar_Id),
            nUsu_ID: Number.parseInt(_nUsu_ID),
        }
        await instance
            .post("/Tarea/Insertar/Participante", postData)
            .then(response => {

            })
            .catch(error => {

            })
    }
    const FuncionEnviarCorreo = async () => {
        var postData = {
            id: Number.parseInt(0),
            email: getDecryptedItem('Correo'),
        }
        await instance
            .post("/mail/event/tarea", postData)
            .then(response => {
            })
            .catch(error => {

            })
    }
    const FuncionCorreoTarea = async (_nCas_Id, _nUsu_Id, _cCor_Tab_Tipo, _cCor_Asunto) => {
        var postData = {
            nCas_Id: _nCas_Id,
            nUsu_Id: _nUsu_Id,
            cCor_Tab_Tipo: _cCor_Tab_Tipo,
            cCor_Asunto: _cCor_Asunto,
        }

        await instance
            .post("/Correo/Insertar", postData)
            .then(response => {
            })
            .catch(error => {

            })
    }

    const handleChangeParticipantes = value => {
        if (value == null) {
        } else {
            setParticipantes(value);
        }
    };
    const FuncionValidarFormulario = async () => {
        if (!getTareas.nCas_Id || !getTareas.cTar_Tab_Categoria || !getTareas.cTar_Titulo || !getTareas.cTar_tab_recordar || !getTareas.cTar_Tab_Tipo) {
            setValidData(true);
            Swal.fire({
                title: "Mensaje",
                text: "Faltan completar datos",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        } else {

            FuncionAgregarTarea()

        }
    }
    const FuncionListarComboTipoTarea = async () => {
        var postData = {
            Prefijo: "TT",
        }
        await instance
            .post("/Maestros/Data", postData)
            .then(response => {
                setDataTipoTarea(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionListarComboCategoriaTarea = async () => {
        var postData = {
            Prefijo: "CT",
        }
        await instance
            .post("/Maestros/Data", postData)
            .then(response => {
                setDataCategoriaTarea(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionListarComboRecordarTarea = async () => {
        var postData = {
            Prefijo: "PE",
        }
        await instance
            .post("/Maestros/Data", postData)
            .then(response => {
                setDataRecordarTarea(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionListarCliente = async () => {
        let RolId = getDecryptedItem("RolID")

        var postData = {
            bCli_Tipo: null,
            nUsu_ID: RolId == 6 || RolId == 1 ? null : getDecryptedItem("ID")
        }
        await instance
            .post("/Cliente/ListarCbo", postData)
            .then(response => {
                setDataCliente(response.data)

            })
            .catch(error => {

            })
    }
    const handleChangeFiltroCaso = e => {




        clienteSelected = e.target.value;

        if (e.target.value == 0) {
            FuncionListarCasos(null)
        } else {
            FuncionListarCasos(e.target.value)
        }
    }
    const FuncionListarCasos = async (_idClient) => {
        var postData = {
            idClient: _idClient,
            nUsu_Id: getDecryptedItem("ID"),
        }
        await instance
            .post("/Casos/ListarCbo", postData)
            .then(response => {
                setDataCaso(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionInformacionCaso = async (_nCas_Id) => {
        var postData = {
            nCas_Id: _nCas_Id,
        }
        await instance
            .post("/Casos/Buscar", postData)
            .then(response => {
                ttlCaso = response.data[0]["cCas_Titulo"];


                setdataCodInterno(response.data[0].cCas_Cod_Interno)
                setdataCodExterno(response.data[0].cCas_Cod_Externo)
            })
            .catch(error => {

            })
    }
    const FuncionListarComboUsuario = async (nCas_Id) => {
        var postData = {
            nCas_Id: nCas_Id,
        }
        await instance
            .post("/Casos/FiltroUsuarioCaso", postData)
            .then(response => {

                setDataComboUsuario(response.data)

            })
            .catch(error => {

            })
    }

    const FuncionListarEstadoTarea = async () => {
        var postData = {
            Prefijo: "MT",
        }
        await instance
            .post("/Maestros/Data", postData)
            .then(response => {
                setDataEstadoTarea(response.data)

            })
            .catch(error => {

            })
    }
    const handleChange = e => {
        const { name, value } = e.target
        setTareas(prevState => ({
            ...prevState,
            [name]: value,
        }))
        setListas(prevState => ({
            ...prevState,
            [name]: value,
        }))


        if (e.target.name == "nCas_Id") {
            FuncionInformacionCaso(e.target.value)
            FuncionListarComboUsuario(e.target.value)
            setParticipantes([{ value: getDecryptedItem("ID"), label: getDecryptedItem('NombreC'), email: getDecryptedItem('Correo') }]);
        }
    }
    const handleBlur = e => {
        handleChange(e)
        const { name, value } = e.target;
        if (name == "tTar_Hora_Vence") {
            if (value.length == 0) {
                const now = new Date(); // Obtener la fecha y hora actual
                let hours = now.getHours(); // Obtener solo la hora actual


                if (hours < 10) {
                    hours = "0" + hours
                }
                const newValue = hours + ":00";

                setTareas((prevState) => ({
                    ...prevState,
                    [name]: newValue,
                }));
            }
        }
        setErrores(Validacion(getTareas))

    }
    function sortJSON(data, key, orden) {
        return data.sort(function (a, b) {
            var x = a[key],
                y = b[key];
            if (orden === 'asc') {
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            }
            if (orden === 'desc') {
                return ((x > y) ? -1 : ((x < y) ? 1 : 0));
            }
        });
    }
    const datas = {
        columns: [

            {
                label: "Listas",
                field: "cLis_Nombre",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            }

        ],
        rows: dataLista,
    }

    const badgesData = {
        columns: [

            ...datas.columns,
            {
                label: "Acciones",
                field: "acciones",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
        ],
        rows: [
            ...datas.rows.map((row, id) => ({
                ...row,
                acciones: (
                    <div>
                        <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="dropdown-toggle">
                                <i className="bx bx-dots-vertical-rounded" />
                            </DropdownToggle>
                            <DropdownMenu>

                                <DropdownItem onClick={() => FuncionEliminarLista(row, id)}>
                                    <Link >
                                        Eliminar
                                    </Link>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                ),
            })),
        ],
    }
    const abrirCerrarModal = Modal => {
        if (Modal == 8) {

            setListas({})
            setModalLista("Nueva Lista");
            setModalInsertarlista(!modalInsertarlista)
        }
    }
    const FuncioninsertarLista = async (cLis_Nombre, nfTar_Id) => {
        var postData = {
            cLis_Nombre: cLis_Nombre,
            nTar_Id: nfTar_Id
        }


        await instance
            .post("/Tareas/ListaInsertar", postData)
            .then(response => {

            })
            .catch(error => {

            })

    }
    const FuncionPreEditarLista = async (lista, index) => {



        setListas(prevState => ({
            ...prevState,
            cLis_Nombre: lista.cLis_Nombre,
            nLis_Id: lista.nLis_Id,
        }))
        setModalLista("Editar Lista")
        setModalInsertarlista(true)
    }
    const FuncionEditarLista = async (lista) => {


        let arrayLista = dataLista;

        const found = arrayLista.find(element => element.cLis_Nombre == getListas.cLis_Nombre);
        if (getTareas.cTar_Titulo == "" || getTareas.cTar_Titulo == undefined || getTareas.cTar_Titulo.trim() == "") {
            Swal.fire({
                title: "Mensaje",
                text: "Primero defina el titulo de la tarea",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        }
        else if (found.nLis_Id != lista.nLis_Id) {
            Swal.fire({
                title: "Mensaje",
                text: "Ya existe una lista con este nombre",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        }
        else {
            for (let i = 0; i < arrayLista.length; i++) {
                if (arrayLista[i].nLis_Id == lista.nLis_Id) {

                    arrayLista[i].cLis_Nombre = getListas.cLis_Nombre;

                    setDatalista(arrayLista);
                    setListas(prevState => ({
                        ...prevState,
                        cLis_Nombre: "",
                        nLis_Id: null
                    }))
                    setModalLista("Nueva Lista")
                }

            }
        }
    }

    const FuncionEliminarLista = async (lista, index) => {

        let arrayLista = dataLista;
        arrayLista.splice(index, 1);
        setDatalista(arrayLista);
        setModalLista("Nueva Lista")
        setListas(prevState => ({
            ...prevState,
            cLis_Nombre: "",
            nLis_Id: null
        }))


    }
    const FuncionCancelarLista = async () => {
        setListas(prevState => ({
            ...prevState,
            cLis_Nombre: "",
            nLis_Id: null
        }))
        setModalLista("Nueva Lista")
        setModalInsertarlista(!modalInsertarlista)
    }
    const FuncionAddLista = async () => {
        if (getTareas.cTar_Titulo == "" || getTareas.cTar_Titulo == undefined || getTareas.cTar_Titulo.trim() == "") {
            Swal.fire({
                title: "Mensaje",
                text: "Primero defina el titulo de la tarea",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        }
        else if (getListas.cLis_Nombre == "" || getListas.cLis_Nombre == undefined || getListas.cLis_Nombre.trim() == "") {
            Swal.fire({
                title: "Mensaje",
                text: "Ingrese un nombre para la lista",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        }
        else {
            if (getListas.nLis_Id != null) {

                FuncionEditarLista(getListas);
            }
            else {

                var arrayLista = dataLista;

                const found = arrayLista.find(element => element.cLis_Nombre == getListas.cLis_Nombre);

                if (found) {
                    Swal.fire({
                        title: "Mensaje",
                        text: "Ya existe una lista con este nombre",
                        icon: "error",
                        confirmButtonText: "Aceptar",
                    })
                }
                else {


                    let obj = {
                        cLis_Nombre: getListas.cLis_Nombre,
                        nLis_Id: arrayLista.length + 1
                    }

                    arrayLista.push(obj);
                    setDatalista(arrayLista);
                    setListas(prevState => ({
                        ...prevState,
                        cLis_Nombre: "",
                    }))
                    setModalInsertarlista(!modalInsertarlista)
                }
            }
        }
    }
    const FuncionListarUsuario = async (_nUsu_ID) => {
        var postData = {
            nUsu_ID: _nUsu_ID,
        }
        await instance
            .post("/Usuario/Perfil", postData)
            .then(response => {

                setUsuario(response.data[0])

            })
            .catch(error => {

            })
    }
    useEffect(() => {

        let nUsu_ID = getDecryptedItem("ID")
        FuncionListarUsuario(nUsu_ID);

        FuncionListarComboTipoTarea()
        FuncionListarComboCategoriaTarea()
        FuncionListarComboRecordarTarea()
        FuncionListarCliente()
        FuncionListarCasos(null)
        FuncionListarEstadoTarea()
        if (getDecryptedItem("nCas_Id") === null) {

            setDisable(false)

        } else {
            setDisable(true)
            clienteSelected = getDecryptedItem("CLIID");
            FuncionInformacionCaso(getDecryptedItem("nCas_Id"))
            FuncionListarComboUsuario(getDecryptedItem("nCas_Id"))
            setParticipantes([{ value: getDecryptedItem("ID"), label: getDecryptedItem('NombreC'), email: getDecryptedItem('Correo') }]);

        }
        setErrores(Validacion(getTareas))
    }, [])

    return (
        <Modal size="lg" isOpen={show}  >
            <div
                className="modal-header ModalHeaderColor"
            >
                <h5
                    style={{ color: "#fff" }}
                    className="modal-title mt-0"
                    id="myModalLabel"
                >
                    {Titulo}
                </h5>
                <button
                    type="button"
                    onClick={() => onCloseClick()}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="mb-3 row">
                    <div className="col-md-6">
                        <select className="form-select inputDown" name="nCli_Id" onChange={handleChangeFiltroCaso}
                            value={getTareas && getTareas.nCli_Id} disabled={disable}>
                            <option value="0">Seleccionar...</option>
                            {dataCliente.map((row, i) => (
                                <option key={i} value={row.nCli_Id}>
                                    {row.cCli_NombreCompleto}
                                </option>
                            ))}
                        </select>
                        <label className="col-form-label labelDown">
                            Cliente
                        </label>
                    </div>
                    <div className="col-md-6">
                        <select
                            className="form-select inputDown"
                            name="nCas_Id"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={disable}
                            value={getTareas && getTareas.nCas_Id}
                        >
                            <option value="0">Seleccionar...</option>
                            {dataCaso.map((row, i) => (
                                <option key={i} value={row.nCas_Id}>
                                    {row.cCas_Titulo}
                                </option>
                            ))}
                        </select>
                        {getErrores.nCas_Id && <label className="col-form-label labelDown labelRed">{getErrores.nCas_Id}</label> || <label className="col-form-label labelDown">Caso</label>}
                    </div>
                </div>
                <div className="mb-1 row">
                    <div className="col-md-6">
                        <label
                            htmlFor="example-text-input"
                            className="col-md-12 col-form-label"
                            style={{ marginLeft: "5px" }}
                        >
                            Cód Interno: {dataCodInterno}
                        </label>
                    </div>
                    <div className="col-md-6">
                        <label
                            htmlFor="example-text-input"
                            className="col-md-12 col-form-label"
                            style={{ marginLeft: "2px" }}
                        >
                            Cód Externo:{dataCodExterno}
                        </label>
                    </div>
                </div>
                <div className="mb-3 row" style={{ marginTop: "15px" }}>
                    <div className="col-md-6">
                        <select
                            className={getErrores.cTar_Tab_Categoria && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                            name="cTar_Tab_Categoria"
                            onChange={handleChange}
                            onBlur={handleBlur}
                        >
                            <option value="0">Seleccionar...</option>
                            {dataCategoriaTarea.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                    {row.cTabNombre}
                                </option>
                            ))}
                        </select>
                        <label className="col-form-label labelDown">Categoria</label> {getErrores.cTar_Tab_Categoria && <label className="col-form-label labelDown labelRed">{getErrores.cTar_Tab_Categoria}</label>}

                    </div>
                    <div className="col-md-6">
                        <textarea
                            className={getErrores.cTar_Titulo && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                            placeholder="Ingrese el título"
                            name="cTar_Titulo"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            maxLength="200"
                        ></textarea>
                        <label className="col-form-label labelDown">Titulo</label>{getErrores.cTar_Titulo && <label className="col-form-label labelDown labelRed">{getErrores.cTar_Titulo}</label>}
                    </div>
                </div>
                <div className="mb-3 row">

                </div>
                <div className="mb-3 row">
                    <div className="col-md-6">
                        <Select
                            isMulti
                            onChange={handleChangeParticipantes}
                            name="colors"
                            options={UsuariosSelect}
                            className="basic-multi-select"
                            placeholder="Ingrese los participantes"
                            value={GetParticipantes}
                        />
                        <label className="col-form-label labelDown">
                            Participantes
                        </label>
                    </div>
                    <div className="col-md-2" style={{ width: '90px' }}>
                        <input
                            type="text"
                            className="form-control"
                            id="nTar_Duracion"
                            name="nTar_Duracion"
                            value={getTareas && getTareas.nTar_Duracion}
                            onChange={handleChange}
                            style={{ textAlign: 'right' }}
                        />
                        <label className="col-form-label labelDown">
                            Duración
                        </label>
                    </div>
                    <div className="col-md-2" style={{ width: '150px' }}>
                        <select
                            className="form-select"
                            name="nTar_TipoDias"
                            onChange={handleChange}
                            value={getTareas && getTareas.nTar_TipoDias}
                        >
                            <option value="0">Seleccionar...</option>
                            <option value="1">Dias calendario</option>
                            <option value="2">Dias utiles</option>
                        </select>
                        <label className="col-form-label labelDown">
                            Dias
                        </label>
                    </div>

                    <div className="col-md-2" style={{ width: '150px' }}>
                        <select
                            className="form-select"
                            name="nTar_Prioridad"
                            onChange={handleChange}
                            value={getTareas && getTareas.nTar_Prioridad}
                        >
                            <option value="0">Seleccionar...</option>
                            <option value="1">Alta</option>
                            <option value="2">Mediana</option>
                            <option value="3">Baja</option>
                            <option value="4">Sin Prioridad</option>
                        </select>
                        <label className="col-form-label labelDown">
                            Prioridad
                        </label>
                    </div>
                </div>
                <div className="mb-3 row">
                    <div className="col-md-3">
                        <input
                            className="form-control"
                            type="date"
                            name="dTar_Fec_Vence"
                            value={getTareas && getTareas.dTar_Fec_Vence}
                            onChange={handleChange}
                        />
                        <label className="col-form-label labelDown">
                            Fecha de vencimiento
                        </label>
                    </div>
                    <div className="col-md-3">
                        <input
                            className="form-control"
                            type="time"
                            name="tTar_Hora_Vence"
                            value={getTareas && getTareas.tTar_Hora_Vence}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <label className="col-form-label labelDown">
                            Hora de vencimiento
                        </label>
                    </div>
                    <div className="col-md-3">
                        <select
                            className="form-select"
                            name="cTar_tab_recordar"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={getTareas && getTareas.cTar_tab_recordar}
                        >
                            <option value="0">Seleccionar...</option>
                            {sortJSON(dataRecordarTarea, 'cTabCodigo', 'asc').map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                    {row.cTabNombre}
                                </option>
                            ))}
                        </select>
                        <label className="col-form-label labelDown">Recordar</label>{getErrores.cTar_tab_recordar && <label className="col-form-label labelDown labelRed">{getErrores.cTar_tab_recordar}</label>}
                    </div>
                    <div className="col-md-3">
                        <select
                            className="form-select"
                            name="cTar_Tab_Tipo"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={getTareas && getTareas.cTar_Tab_Tipo}
                        >
                            <option value="3">Seleccionar...</option>
                            {dataTipoTarea.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                    {row.cTabNombre}
                                </option>
                            ))}
                        </select>
                        <label className="col-form-label labelDown">Estado</label> {getErrores.cTar_Tab_Tipo && <label className="col-form-label labelDown labelRed">{getErrores.cTar_Tab_Tipo}</label>}
                    </div>
                </div>

                <div className="mb-3 row">
                    <div className="col-md-6" hidden>
                        <select
                            className="form-select"
                            name="bTar_Estado"
                            value={getTareas && getTareas.bTar_Estado}
                            onChange={handleChange}
                        >
                            <option value="00">Seleccionar...</option>
                            <option value="1">Activo</option>
                            <option value="NN00">No activo</option>
                        </select>
                        <label className="col-form-label labelDown">
                            Estado
                        </label>
                    </div>
                </div>


                <div className="mb-3 row" >
                    <hr style={{ border: 'none', height: '5px', backgroundColor: '#333' }}></hr>

                    <CardBody style={{ paddingLeft: '12px', paddingBottom: '0px' }}>
                        <CardTitle>
                            {ttlModalLista}
                        </CardTitle>
                        <div className="mb-3 row">
                            {/* <div className="col-md-6">
                                <input
                                disabled
                                className="form-control inputDown"
                                type="text"
                                placeholder="Nombre de la tarea"
                                name="cTar_Titulo"
                                value={getTareas && getTareas.cTar_Titulo}
                                />
                                <label className="col-form-label labelDown">
                                Tarea
                                </label>
                            </div> */}
                            <div className="col-md-6">
                                <input
                                    className="form-control inputDown"
                                    type="text"
                                    placeholder="Ingrese el nombre de la lista"
                                    name="cLis_Nombre"
                                    value={getListas && getListas.cLis_Nombre}
                                    onChange={handleChange}
                                />

                            </div>
                            <button
                                style={{ height: '30px', marginTop: '5px', width: '150px' }}
                                type="button"
                                onClick={() => FuncionAddLista()}
                                className="btn btn-danger waves-effect waves-light"
                                data-toggle="modal"
                                data-target="#myModal"
                            >
                                Agregar item
                            </button>
                        </div>

                    </CardBody>
                </div>
                <div className="mb-3 row" style={{ marginTop: '0px' }}>
                    <div className="col-md-12">
                        <CardBody style={{ paddingLeft: '0px', paddingBottom: '0px', paddingRight: '0px' }}>
                            <Col className="col-12 row" style={{ paddingRight: '0px' }}>

                            </Col>
                            <MDBDataTable
                                data={badgesData}
                                className="TablaListasNuevo"
                                responsiveXl
                                fixed
                                hover
                                entriesLabel="Mostrar"
                                entrylabel={"Mostrar entradas"}
                                infoLabel={["Mostrando ", "a", "de", "registros"]}
                                bordered
                                order={["age", "desc"]}
                                noRecordsFoundLabel={"No hay datos"}
                                searchLabel={"Buscar"}
                                noBottomColumns
                                paginationLabel={["Anterior", "Siguiente"]}
                                theadColor="HeadBlue"
                                searching={false}
                                paging={false}
                                info={false}
                            />
                        </CardBody>
                    </div>
                </div>

            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => onCloseClick()}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                >
                    Cerrar
                </button>
                <button
                    disable={blockBtn}
                    type="button"
                    onClick={() => FuncionValidarFormulario()}
                    disabled={blockBtn}
                    className="btn btn-danger waves-effect waves-light"
                >
                    Guardar
                </button>
            </div>
        </Modal>
    );

}
export default ModalTarea
