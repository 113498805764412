import React, { Component, useState, useEffect } from "react"
import { getDecryptedItem, setEncryptedItem } from '../../../util';
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown
} from "reactstrap"
import { withRouter, Link } from "react-router-dom"
 
//i18n
import { withTranslation } from "react-i18next"
import instance from '../../../config'
const baseUrl = process.env.REACT_APP_API_URL
const ProfileMenu = props => {
  const [name, setname] = useState("")
  const [MenuUsur, setMenuUsur] = useState(true)
  const [getImagenes, setImagenes] = useState("/static/media/avatar-1.de73c074.jpg")

  const nombreusuario = async () => {

    if (getDecryptedItem('NombreC') == null && getDecryptedItem('NombreC') == undefined) {
      setEncryptedItem('HistoryUrl', window.location.href);
      location.href = "/IniciarSesion";
    } else {
      setname(getDecryptedItem('NombreC'))
    }
  }

  function checkImage(src, good, bad) {
    var img = new Image();
    img.onload = good;
    img.onerror = bad;
    img.src = src;
  }

  const FuncionListarUsuario = async (_nUsu_ID) => {
    var postData = {
      nUsu_ID: parseInt(_nUsu_ID),
    }

    await instance
      .post("/Usuario/Perfil", postData)
      .then(response => {
        console.log(response)
        FuncionCargarImg(response.data[0].cUsu_Avatar)
      })
      .catch(error => {
      })
  }

  const FuncionCargarImg = async (cUsu_Avatar) => {
    var postData = {
      FileName: cUsu_Avatar,
    }
    await instance
      .post("/Usuario/DownloadFile", postData)
      .then(response => {
        const Url = response.data.data
        console.log(Url)
        checkImage(Url, function () {
          setImagenes(Url)
        }, function () { setImagenes('/static/media/avatar-1.de73c074.jpg') });
      })
      .catch(error => {
      })
  }

  const abrirMenu = () => {
    setMenuUsur(!MenuUsur)
  }


  const CerrarSesion = async () => {
    localStorage.clear();
    location.href = "/IniciarSesion";
  }

  useEffect(() => {
    nombreusuario()
    FuncionListarUsuario(getDecryptedItem("ID"))
  }, [])
  return (
    <React.Fragment>
      <UncontrolledDropdown>
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={getImagenes}
            alt="Header Avatar"
          />{" "}
          <span className="d-none d-xl-inline-block ms-1">
            {name}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>

        <DropdownMenu>
          <DropdownItem tag="a" href="/MiCuenta">
            <span className="badge badge-success float-end mt-1">5</span>
            <i className="bx bx-wrench font-size-17 align-middle me-1" />
            <span>Mi Cuenta</span>
          </DropdownItem>
          <div className="dropdown-divider" />
          <Link to="#" onClick={() => CerrarSesion()} className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>Cerrar sesión</span>
          </Link>
        </DropdownMenu>
      </UncontrolledDropdown>
    </React.Fragment>
  )
}


export default withRouter(withTranslation()(ProfileMenu))
