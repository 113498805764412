import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import { Row, Col, CardBody, Card, Container } from "reactstrap"
import Swal from "sweetalert2"
import { AvForm, AvField } from "availity-reactstrap-validation"
import profile from "../../assets/images/profile-imgI.png"
import instance from '../../config'
import axios from "axios"

import { setEncryptedItem, getDecryptedItem } from '../../util';


const baseUrl = process.env.REACT_APP_API_URL
const jsonwebtoken = require('jsonwebtoken');
const SECRET_KEY_JWT = "secretkey5353";

const Login = props => {
  const [getVisible1, setVisible1] = useState("")
  const [getVisible2, setVisible2] = useState("none")
  const [getDisabled, setDisabled] = useState(false)
  const [getChecked, setChecked] = useState(false)
  const [getUsuario, setUsuario] = useState({
    cUsu_email: "",
    cUsu_Pass: "",
  })

  const FuncionLoginUsuario = async () => {
    try {
      setDisabled(true);
      // Realiza la solicitud de inicio de sesión
      const response = await axios.post(baseUrl + "/Usuario/Login", {
        cUsu_email: getUsuario.cUsu_email,
        cUsu_Pass: getUsuario.cUsu_Pass,
      });

      if (response.status == 200) {
        // Verifica y decodifica el token JWT
        const token = response.data.token;
        const responseToken = await jsonwebtoken.verify(token, SECRET_KEY_JWT);
        // Si no se puede verificar el token, responde con un error 401
        if (!responseToken) {
          throw new Error('Unauthorized Request');
        }
        // Extrae los datos del usuario del token
        const { ID, NombreC, Correo, Rol, CliId, CONDICION } = responseToken.dataUser;

        // Realiza la solicitud para obtener permisos
        const responsePermisos = await instance.post("/Usuario/Acceso", {
          nUsu_ID: Rol,
        });
        const arrayPermisos = responsePermisos.data;

        if (arrayPermisos.length === 0) {
          throw new Error('Usted no cuenta con permisos habilitados');
        } else if (CONDICION === 1) {
          setEncryptedItem('token', token);
          setEncryptedItem('ID', ID);
          setEncryptedItem('NombreC', NombreC);
          setEncryptedItem('Correo', Correo);
          //setEncryptedItem('ID', ID);
          //setEncryptedItem('RolID', Rol);
          //setEncryptedItem('CLIID', CliId);

          setEncryptedItem('ID', ID);
          setEncryptedItem('RolID', Rol);
          setEncryptedItem('CLIID', CliId);
          
          if (Rol !== 9) {
            const historyUrl = getDecryptedItem("HistoryUrl")
            if (historyUrl) {
              location.href = historyUrl;
              localStorage.removeItem("HistoryUrl")
            } else {
              location.href = "/dashboard-Abogado";
            }
          } else {
            location.href = "/dashboard-Resumen";
          }
        }
      }
    } catch (error) {
      console.log(error)
      setDisabled(false);
      Swal.fire({
        title: "Mensaje",
        text: "Usuario o contraseña incorrecta",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    } finally {
      setDisabled(false);
    }
  };
  const changeVisibility = async () => {
    var tipo = document.getElementById("cUsu_Pass");
    if (tipo.type == "password") {
      tipo.type = "text";
      setVisible1("none")
      setVisible2("")
    } else {
      tipo.type = "password";
      setVisible1("")
      setVisible2("none")
    }
  }
  const handleChange = e => {
    const { name, value } = e.target
    setUsuario(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }
  const FnRecordar = e => {
    const { checked } = e.target
    setChecked(checked)
    if (!checked) {
      localStorage.removeItem("Correo")
      return
    }
    setEncryptedItem("Correo", getUsuario.cUsu_email);

  }

  useEffect(() => {
    const historyUrl = getDecryptedItem("HistoryUrl")
    localStorage.clear();
    if (historyUrl) {
      setEncryptedItem("HistoryUrl", historyUrl)
    }
    let value = getDecryptedItem("Correo")
    if (value) {
      setUsuario(prevState => ({
        ...prevState,
        cUsu_email: value,
      }))
      setChecked(true)
    }

  }, [])
  return (
    <React.Fragment>
      <MetaTags>
        <title>Iniciar Sesión | GpsLegal</title>
      </MetaTags>
      <div className="account-pages my-2 pt-sm-2">
        <Container>
          <Row className="justify-content-center">
            <Col md={7} lg={5} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <Link to="/" className="">
                    <img
                      src={profile}
                      alt=""
                      style={{
                        width: "100%",
                        height: "auto",
                      }}
                    />
                  </Link>
                  <div className="p-2">
                    <AvForm className="form-horizontal">
                      <div className="mb-2">
                        <label className="col-form-label labelDown" style={{ paddingLeft: '0px', marginLeft: '0px', fontSize: '13px' }}>
                          Correo electrónico o usuario
                        </label>
                        <input
                          disabled={getChecked}
                          label="Nombre de usuario o email"
                          value={getUsuario && getUsuario.cUsu_email}
                          onChange={handleChange}
                          className="form-control"
                          placeholder="Ingresa nombre de usuario o email"
                          type="text"
                          name="cUsu_email"
                          id="cUsu_email"
                        />
                      </div>
                      <div className="mb-2">
                        <div className="row mb-12">
                          <div className="col-md-10" style={{ width: '90%', paddingRight: '0px', marginRight: '0px' }}>
                            <AvField
                              label="Contraseña"
                              onChange={handleChange}
                              type="password"
                              placeholder="Ingresa Contraseña"
                              name="cUsu_Pass"
                              id="cUsu_Pass"
                            />
                          </div>
                          <div className="col-md-2"
                            style={{
                              borderRadius: '5px',
                              cursor: 'pointer',
                              width: '8%',
                              height: '30px',
                              marginTop: '35px',
                              paddingLeft: '6px', marginLeft: '0px'
                            }}
                            onClick={() => changeVisibility()}
                          >
                            <i className="icon-ver" style={{ display: getVisible1 }}> </i>
                            <i className="icon-no-ver" style={{ display: getVisible2 }}> </i>
                          </div>
                        </div>
                      </div>
                      <div className="form-check" >
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="chkboxRecordar"
                          checked={getChecked}
                          onClick={(e) => FnRecordar(e)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="chkboxRecordar"
                        >
                          Recordar
                        </label>
                      </div>
                      <div className="mt-3 d-grid">
                        <button
                          type="button"
                          className="btn btn-block waves-effect waves-light"
                          onClick={() => FuncionLoginUsuario()}
                          style={{
                            backgroundColor: "#4b5d81",
                            color: "white",
                          }}
                          disabled={getDisabled}
                        >
                          Iniciar Sesión
                        </button>
                      </div>
                      <div className="mt-4 text-center">
                        <Link to="/RecuperarContraseña" className="text-muted">
                          <i className="mdi mdi-lock me-1" /> ¿Olvidaste tu
                          contraseña?
                        </Link>
                        <p style={{ paddingTop: "10px" }}>
                          © {new Date().getFullYear()} GPS Legal{" "} ⓥ Versión. 231030
                        </p>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Login