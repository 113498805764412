import React, { useReducer } from "react";
import instance from '../../../../config';
import RolesContext from "./RolesContext";
import RolesReducer from "./RolesReducer";
import { ADD_ROLES, UPD_ROLES,GET_ROLES } from "../Type";
const baseUrl = process.env.REACT_APP_API_URL
var meses = new Array("Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre");
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var mesIni = Fecha.getMonth() - 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
if (mesIni < 10) mesIni = "0" + mesIni //agrega cero si el menor de 10
const RolesState = (props) => {
    const initialState = {
        roles: [],
    };
    const [state, dispatch] = useReducer(RolesReducer, initialState);
    const FuncionListarRoles = async () => {
        try {
            await instance
                .post("/Roles/Listar")
                .then(response => {
                    dispatch({ type: GET_ROLES, payload: response.data });
                    
                })
        } catch (error) {
            
        }
    }
    const FuncionInsertarRoles = async (postData) => {
        try {
            await instance
                .post("/Roles/Insertar", postData)
                .then(response => {
                    FuncionListarRoles()
                    
                    
                })
        } catch (error) {
            
        }
    }
    const FuncionActualizarRoles = async (postData) => {
        try {
            await instance
                .post("/Roles/Actualizar", postData)
                .then(response => {
                    FuncionListarRoles()
                    
                    
                })
        } catch (error) {
            
        }
    }
    return (
        <RolesContext.Provider
            value={{
                roles: state.roles,
                FuncionListarRoles,
                FuncionInsertarRoles,
                FuncionActualizarRoles
            }}
        >
            {props.children}
        </RolesContext.Provider>
    );
}
export default RolesState;
