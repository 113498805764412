import React, { Component, useState, useEffect } from "react"
import { MDBDataTable } from "mdbreact"
import {
  CardBody,
  Card,
  Col,
  Container,
  CardTitle,
  Button,
  Row,
  Modal,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap"
import MetaTags from "react-meta-tags"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
//Dependencia axios para consumir apis
import instance from '../../config'

import Swal from "sweetalert2"
import { set } from "lodash"
import { faWeight } from "@fortawesome/free-solid-svg-icons"
//Url Principal
const baseUrl = process.env.REACT_APP_API_URL
function nExpediente(){
  return <div className="col-4">
        <label
        htmlFor="example-input"
        className="col-md-12 col-form-label"
        >
        N° Expediente
        </label>
        <div className="col-md-12">
              <input
                className="form-control"
                type="email"
                defaultValue=""
                placeholder="Ejemplo: 1234"
              />
        </div>         
  </div>
}
function comboArea(){
  return  <div className="col-4">
  <label
  htmlFor="example-email-input"
  className="col-md-2 col-form-label"
  >
  Area
  </label>
  <select
      className="form-select inputDown"
      name="area"
      
    >
<option value="0"  selected="">Seleccione</option>
<option value="128">CC1</option>
                                                
<option value="130">CC2</option>
                                                
<option value="138">CC3</option>
                                                
<option value="8">CPC</option>
</select>         
</div>
}
function comboAnio(){
  return <div className="col-4">
                                <label
                                htmlFor="example-input"
                                className="col-md-2 col-form-label"
                                >
                                Año
                                </label>
                                <div className="col-md-12">
                                      <input
                                        className="form-control"
                                        type="input"
                                        defaultValue=""
                                        placeholder="Ejemplo: 2023"
                                      />
                                </div>         
                          </div>
}
function comboTipoExpediente(){
  return <div className="col-4">
                                <label
                                htmlFor="example-email-input"
                                className="col-md-12 col-form-label"
                                >
                                Tip. Expediente
                                </label>
                                <select
                                    className="form-select inputDown"
                                    name="texpediente"
                                    
                                  >
                               <option value="0">Seleccione</option>
                                                                             
                               <option value="1" selected="">DENUNCIA</option>
                                                                              
                                <option value="2">APELACION</option>
                                                                              
                                <option value="3">INCUMPL. DE ACUERDO CONCILIAT.</option>
                                                                              
                                <option value="4">INCUMPL. DE MEDIDAS CORRECTIVAS</option>
                                                                              
                                <option value="10">QUEJA</option>
                                                                              
                                <option value="12">LIQUIDACION DE COSTAS Y COSTOS</option>
                                                                              
                                <option value="13">INCUM.LIQUID.DE COSTAS Y COSTOS</option>
                                                                              
                                <option value="14">SANCIONADOR POR EL ART. 5</option>
                                                                              
                                <option value="17">INCUMPL. DE ACUERDO CONCILIATORIO SAC</option>
                              
                            </select>        
                          </div>
}
function comboLugarTramite(){
  return <div className="col-4">
  <label
  htmlFor="example-email-input"
  className="col-md-12 col-form-label"
  >
  Lugar de trámite
  </label>
  <select
      className="form-select inputDown"
      name="cCli_Tab_TipoDoc"
      
    >
<option value="0" selected="">Seleccionar </option>
                                               
                                               <option value="43">ANCASH SEDE CHIMBOTE</option>
                                                
                                               <option value="37">ANCASH SEDE HUARAZ</option>
                                                
                                               <option value="6">AREQUIPA</option>
                                                
                                               <option value="12">CAJAMARCA</option>
                                                
                                               <option value="7">CUSCO</option>
                                                
                                               <option value="38">ICA</option>
                                                
                                               <option value="13">JUNIN</option>
                                                
                                               <option value="8">LA LIBERTAD</option>
                                                
                                               <option value="9">LAMBAYEQUE</option>
                                                
                                               <option value="10">LORETO</option>
                                                
                                               <option value="54">MADRE DE DIOS</option>
                                                
                                               <option value="11">PIURA</option>
                                                
                                               <option value="14">PUNO</option>
                                                
                                               <option value="16">SAN MARTIN</option>
                                                
                                               <option value="1" >SEDE CENTRAL</option>
</select>      
</div>
}
function botonBucar(){
  return <div className="col-4">
                          <button
                      type="button"
                      
                      className="btn btn-danger waves-effect waves-light"
                      style={{marginTop:'2.7em'}}
                    >
                      <i className="bx bx-search-alt" style={{color: "white", fontSize:"15px",position:"absolute",textAlign:"right",paddingTop:"0.1em"}}></i>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Buscar
                    </button>
                          </div>
}

const AbogadoVT = props => {
  const [modalInsertarBusqueda, setModalInsertarBusqueda] = useState(false)
  const [data, setData] = useState([])
  const [getAbogadoVirtual, setAbogadoVirtual] = useState({
    expediente : '',
    anio : '',
    area : '',
    texpediente : '',
    tramite:''
  })
  const handleChange = e => {
    const { name, value } = e.target
    setPersona(prevState => ({
      ...prevState,
      [name]: value,
    }))
    
  }
  const listAbogadoVirtual = async () => {
    await instance
      .post("/Av/Listar")
      .then(response => {
        setData(response.data)
        
      })
      .catch(error => {
        
      })
  }
  useEffect(() => {
    listAbogadoVirtual()
  }, [])

  const datas = {
    columns: [
      {
        label: "#",
        field: "nDet_Id",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Desc.Caso",
        field: "nCas_Id",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Tipo Caso",
        field: "cCas_Tab_Tipo",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Detalle",
        field: "cDet_Detalle",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Notas",
        field: "cDet_Notas",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      }
    ],
    rows: data,
  }

  const badgesData = {
    columns: [
      ...datas.columns,
      {
        label: "Acciones",
        field: "acciones",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
    ],
    rows: [
      ...datas.rows.map((row, id) => ({
        ...row,
        acciones: (
          <div>
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="dropdown-toggle">
                <i className="bx bx-dots-vertical-rounded" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => ""}>
                  Editar
                </DropdownItem>
                <DropdownItem
                  onClick={() =>
                    confirm(
                      "Press a button!\nEither OK or Cancel.\nThe button you pressed will be displayed in the result window."
                    )
                  }
                >
                  Eliminar
                </DropdownItem>
                <DropdownItem href="#">Detalle</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        ),
      })),
    ],
  }
  const abrirCerrarModalBuscarInsertar = Parametro => {
    if (Parametro == 1) {
      setModalInsertarBusqueda(!modalInsertarBusqueda)
    } /*else {
      setModalInsertarEmpresa(!modalInsertarEmpresa)
    }*/
  }
  const spacio ='Too bad.<br />Try again';
    return (
        <React.Fragment>
        <div className="page-content">
          {/* add meta title */}
          <MetaTags>
            <title>Abogado Virtual | GpsLegal</title>
          </MetaTags>
          <Container fluid >
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="GpsLegal" breadcrumbItem="Abogado Virtual" />
            <Row >
            <Col className="col-12"  >
              <button
                      type="button"
                      onClick={() => abrirCerrarModalBuscarInsertar(1)}
                      className="btn btn-danger waves-effect waves-light"
                      data-toggle="modal"
                      data-target="#myModal"
                    >
                      <i className="bx bx-search-alt" style={{color: "white", fontSize:"15px",position:"absolute",textAlign:"right",paddingTop:"0.1em"}}></i>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Buscar
                    </button>{" "}  
              <Modal size="lg" isOpen={modalInsertarBusqueda}>
                      <div
                        className="modal-header"
                        style={{
                          background:
                             "linear-gradient( 90deg , #AA1B0E, #661A13)",
                        }}
                      >
                        <h5
                          style={{ color: "#fff" }}
                          className="modal-title mt-0"
                          id="myModalLabel"
                        >
                          Nueva Busqueda en <span style={{fontWeight:'600'}}> INDECOPI</span>
                        </h5>
                        <button
                          type="button"
                          onClick={() => abrirCerrarModalBuscarInsertar(1)}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                      <div className="mb-3 row"> 
                        { /* numero expediente*/ 
                          nExpediente()
                          /* fin numero expediente*/ }      
                        {/* año */ 
                        comboAnio()
                        /* fin año*/}
                        {/*combo área*/
                          comboArea()
                        /*fin combo área*/}
                        {/*combo tipo de expediente*/ 
                        comboTipoExpediente()
                        /*fin combo tipo de expediente*/ }     
                        { /* combo lugar de tramite*/ 
                        comboLugarTramite()
                         /* fin combo lugar de tramite*/}
                        { /* boton buscar*/ 
                              botonBucar()
                            /* fin boton buscar */}
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => abrirCerrarModalBuscarInsertar(1)}
                          className="btn btn-secondary waves-effect"
                          data-dismiss="modal"
                        >
                          Cerrar
                        </button>
                        
                      </div>
                    </Modal>
              </Col> 
            <Col className="col-12" style={{paddingTop:'10px'}}>
              <Card>
                <CardBody>  <CardTitle className="h4" style={{ fontSize: '30px'}}>
                    Listado de Notificaciones{" "}
                  </CardTitle>
               
                        <div /*style={{border: '#ce3b3b 2px dashed',padding:'0em 1em',borderRadius:'5px'}}*/>
                  <MDBDataTable style={{borderRadius:'10px'}}  
                    data={badgesData}
                    responsiveXl
                    fixed
                    hover
                    entriesLabel="Mostrar"
                    entrylabel={"Mostrar entradas"}
                    infoLabel={[
                      "Mostrando ",
                      "a",
                      "de",
                      "registros",
                    ]}
                    bordered
                    order={["age", "desc"]}
                    noRecordsFoundLabel={"No hay datos"}
                    searchLabel={"Buscar"}
                    noBottomColumns
                    paginationLabel={["Anterior", "Siguiente"]} /> 
                    </div>
                </CardBody>
              </Card>
            </Col>
            </Row>
        </Container>
      </div>
    </React.Fragment>
    )
}
export default AbogadoVT
