import React, { Component, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTags from "react-meta-tags"
import { MDBDataTable } from "mdbreact"
import "../../assets/scss/tablescss/FormatTable.scss"
import Swal from "sweetalert2"
import { getDecryptedItem, setEncryptedItem } from '../../util';
import {
    CardHeader,
    CardText,
    CardBody,
    Card,
    Col,
    Container,
    CardTitle,
    Button,
    Row,
    Modal,
    Title,
    Text,
    Form,
    FormGroup,
    TabContent,
    TabPane,
    FormText,
    Label,
    Input,
    CustomInput,
    CheckBox,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap"
import instance from '../../config'
import { post } from "helpers/api_helper"

const baseUrl = process.env.REACT_APP_API_URL
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
const Suscriptores = props => {
    const [modalInsertar, setModalInsertar] = useState(false)
    const [modalActualizar, setModalActualizar] = useState(false)
    const [modalFiltro, setModalFiltro] = useState(false)
    const [hiddenBtnEditar, setHiddenBtnEditar] = useState(true)
    const [hiddenBtnEliminar, setHiddenBtnEliminar] = useState(true)
    const [data, setData] = useState([])
    const [dataPlan, setDataPlan] = useState([])
    const [dataCategoria, setDataCategoria] = useState([])
    const [dataFormaPago, setDataFormaPago] = useState([])
    const [dataCanal, setDataCanal] = useState([])
    const [dataTipoPlan, setTipoPlan] = useState([])
    const [subTitulo, setSubTitulo] = useState([])
    const [dataTransiciones, setDataTransiciones] = useState([])
    const [dataFases, setDataFases] = useState([])
    const [tituloModal, setTituloModal] = useState('Nuevo')
    const [tituloTransicion, setTituloTransicion] = useState('Nuevo')
    const [getSus, setSus] = useState({
        nSus_Id: "",
        nPla_ID: "",
        nPro_ID: "",
        cSus_Codigo: "",
        cSus_Categoria: "",
        cSus_Nombres: "",
        dSus_FechaVencimiento: "",
        dSus_FechaVencimiento2: "",
        dSus_FechaCreacion: "",
        dSus_FechaCreacion2: "",
        dSus_UltimoPago: "",
        dSus_UltimoPago2: "",
        cSus_Correo: "",
        cSus_Pass: "",
        cSus_Telefono: "",
        cSus_Empresa: "",
        cSus_CadenaConexion: "",
        cSus_Cuenta: "",
        bSus_Estado: 1,
        bSus_Visto: 1,
        cSus_Fac_Pais: "",
        cSus_Fac_PrimerNombre: "",
        cSus_Fac_Apellido: "",
        cSus_Fac_Correo: "",
        cSus_Fac_Compania: "",
        cSus_Fac_Telefono: "",
        cSus_Fac_DireccionL1: "",
        cSus_Fac_DireccionL2: "",
        cSus_Fac_DireccionL3: "",
        cSus_Fac_Ciudad: "",
        cSus_Fac_Departamento: "",
        cSus_Fac_CodigoPostal: "",
        nSus_Fac_Plan: "",
        cSus_Fac_Canal: "",
        cSus_Fac_tab_FormaPago: "",
        cSus_Fac_Ruc: "",
        dSus_Fac_ProxPago: "",
        dSus_Fac_ProxPago2: ""
    })
    const [getFlujo, setFlujo] = useState({
        nfFas_Id: "",
        nfFlu_Id: "",
        cfFlu_Nombre: "",
        cfFlu_Descripcion: "",
        cfFas_Nombre: ""
    })
    const [getTransicion, setTransicion] = useState({
        nTra_Id: "",
        nfFas_Id: "",
        nTra_Fase: "0",
        nTra_Trigger: "0",
        nTra_Estado: ""
    })
    const [getFiltro, setFiltro] = useState({
        FechaInicial: ano + "-" + "01" + "-" + "01",
        FechaFin: ano + "-" + mes + "-" + dia,
    })

    const handleChangeFiltro = e => {
        const { name, value } = e.target
        setFiltro({
            ...getFiltro,
            [e.target.name]: e.target.value
        })

    }
    const abrirCerrarModal = Modal => {
        if (Modal == 1) {
            if (!modalInsertar == false) {
                Swal.fire({
                    html: "¿Seguro que desea salir sin guardar los datos?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si",
                    cancelButtonText: "No",
                }).then(result => {
                    if (result.isConfirmed) {
                        setTituloModal("Nueva Transición")
                        setModalInsertar(!modalInsertar)
                    }
                })
            } else {

                setModalInsertar(!modalInsertar)
            }
        } else if (Modal == 2) {
            setModalActualizar(!modalActualizar)
        } else if (Modal == 3) {
            setModalFiltro(!modalFiltro)
        }
    }
    const FuncionListarSuscriptores = async () => {
        await instance
            .post("/Suscriptores/Listar")
            .then(response => {
                setData(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionBuscarSuscriptores = async () => {
        var postData = {
            FechaInicial: getFiltro.FechaInicial,
            FechaFin: getFiltro.FechaFin,
        }
        await instance
            .post("/Suscriptores/Buscar", postData)
            .then(response => {
                setData(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionRegistrarSuscriptores = async () => {
        var postData = {
            cSus_Nombres: getSus.cSus_Nombres,
            cSus_Correo: getSus.cSus_Correo,
            cSus_Pass: getSus.cSus_Pass,
            cSus_Telefono: getSus.cSus_Telefono,
            cSus_Empresa: getSus.cSus_Empresa,
            cSus_CadenaConexion: getSus.cSus_CadenaConexion,
            cSus_Cuenta: getSus.cSus_Cuenta,
            cSus_CadenaConexion: getSus.cSus_CadenaConexion,
        }

        await instance
            .post("/Suscriptores/Registrar", postData)
            .then(response => {
                FuncionListarSuscriptores()
                abrirCerrarModal(1)

                Swal.fire({
                    title: "Mensaje",
                    text: "Agregado exitosamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })
            })
            .catch(error => {

            })
    }
    const FuncionBuscarPlan_PorTipoPlan = async (nPro_ID) => {
        var postData = {
            nPro_ID: nPro_ID
        }
        await instance
            .post("/Plan/PlanesBuscar_porTipoPlan", postData)
            .then(response => {

                setDataPlan(response.data)
            })
            .catch(error => {

            })
    }

    const handleChange = e => {
        const { name, value } = e.target
        setSus(prevState => ({
            ...prevState,
            [name]: value,
        }))
        setTransicion(prevState => ({
            ...prevState,
            [name]: value,
        }))
        if (name == "nPro_ID") {

            FuncionBuscarPlan_PorTipoPlan(value);
        }

    }
    const FuncionActualizarSuscriptores = async () => {
        var postData = {
            nSus_Id: getSus.nSus_Id,
            nPla_ID: getSus.nPla_ID,
            nPro_ID: getSus.nPro_ID,
            cSus_Categoria: getSus.cSus_Categoria,
            cSus_Nombres: getSus.cSus_Nombres,
            cSus_Correo: getSus.cSus_Correo,
            cSus_Pass: getSus.cSus_Pass,
            cSus_Telefono: getSus.cSus_Telefono,
            cSus_Empresa: getSus.cSus_Empresa,
            cSus_CadenaConexion: getSus.cSus_CadenaConexion,
            cSus_Cuenta: getSus.cSus_Cuenta,
            bSus_Estado: Number.parseInt(getSus.bSus_Estado),
            bSus_Visto: getSus.bSus_Visto,
            dSus_FechaVencimiento: getSus.dSus_FechaVencimiento,
            dSus_FechaCreacion: getSus.dSus_FechaCreacion,
            dSus_UltimoPago: getSus.dSus_UltimoPago,
            cSus_Fac_Pais: getSus.cSus_Fac_Pais,
            cSus_Fac_PrimerNombre: getSus.cSus_Fac_PrimerNombre,
            cSus_Fac_Apellido: getSus.cSus_Fac_Apellido,
            cSus_Fac_Correo: getSus.cSus_Fac_Correo,
            cSus_Fac_Compania: getSus.cSus_Fac_Compania,
            cSus_Fac_Telefono: getSus.cSus_Fac_Telefono,
            cSus_Fac_DireccionL1: getSus.cSus_Fac_DireccionL1,
            cSus_Fac_DireccionL2: getSus.cSus_Fac_DireccionL2,
            cSus_Fac_DireccionL3: getSus.cSus_Fac_DireccionL3,
            cSus_Fac_Ciudad: getSus.cSus_Fac_Ciudad,
            cSus_Fac_Departamento: getSus.cSus_Fac_Departamento,
            cSus_Fac_CodigoPostal: getSus.cSus_Fac_CodigoPostal,
            nSus_Fac_Plan: getSus.nSus_Fac_Plan,
            cSus_Fac_Canal: getSus.cSus_Fac_Canal,
            cSus_Fac_tab_FormaPago: getSus.cSus_Fac_tab_FormaPago,
            cSus_Fac_Ruc: getSus.cSus_Fac_Ruc,
            dSus_Fac_ProxPago: getSus.dSus_Fac_ProxPago,
        }

        await instance
            .post("/Suscriptores/Actualizar", postData)
            .then(response => {
                FuncionListarSuscriptores()
                abrirCerrarModal(2)

                Swal.fire({
                    title: "Mensaje",
                    text: "Actualizado exitosamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })
            })
            .catch(error => {

            })
    }
    const FuncionEliminarSuscriptores = async (_nSus_Id) => {
        var postData = {
            nSus_Id: Number.parseInt(_nSus_Id),
        }
        await instance
            .post("/Suscriptores/Eliminar", postData)
            .then(response => {
                FuncionListarSuscriptores()

                Swal.fire({
                    title: "Mensaje",
                    text: "Eliminado exitosamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })
            })
            .catch(error => {

            })
    }
    const Acciones = (opcion, transicion) => {


        if (opcion == 1) {
            setTituloModal("Editar Transición")
            setTransicion(transicion);
            abrirCerrarModal(1);
        }
        else {
            let _nfFas_Id = getDecryptedItem("nfFas_Id");
        }

    }
    const FuncionListarCategoria = async () => {
        var postData = {
            Prefijo: "ZD",
        }
        await instance
            .post("/Maestros/Data", postData)
            .then(response => {
                setDataCategoria(response.data)

            })
            .catch(error => {

            })
    }
    const verDetalle = async (tarea) => {

        setEncryptedItem('nfTar_Id', tarea.nfTar_Id);
        location.href = "FlujosNuevaTarea";


    }

    const datas = {
        columns: [
            {
                label: "TAREA",
                field: "cfTar_Nombre",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "LISTAS",
                field: "Cantidad",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "DURACIÓN",
                field: "nfTar_Duracion",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "PRIORIDAD",
                field: "cfTar_Tab_Prioridad2",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },

        ],
        rows: data,
    }
    const badgesData = {
        columns: [
            ...datas.columns,
            {
                label: "Acciones",
                field: "acciones",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
        ],
        rows: [
            ...datas.rows.map((row, id) => ({
                ...row,
                cuenta: (
                    <div>
                        <Link onClick={() => AbrirNv(row.cSus_Cuenta)}>
                            {row.cSus_Cuenta}
                        </Link>
                    </div>
                ),
                acciones: (
                    <div>
                        <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="dropdown-toggle">
                                <i className="bx bx-dots-vertical-rounded" />
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => verDetalle(row)}>
                                    <Link>
                                        Ver detalle
                                    </Link>
                                </DropdownItem>
                                <DropdownItem
                                    hidden={hiddenBtnEditar}
                                    onClick={() =>
                                        Swal.fire({
                                            title: "Mensaje",
                                            html:
                                                "¿Esta seguro de eliminar la tarea?",
                                            icon: "warning",
                                            showCancelButton: true,
                                            confirmButtonColor: "#3085d6",
                                            cancelButtonColor: "#d33",
                                            confirmButtonText: "Aceptar",
                                            cancelButtonText: "Cancelar",
                                        }).then(result => {
                                            if (result.isConfirmed) {
                                                FuncionEliminarTarea(row.nfTar_Id)
                                            }
                                        })
                                    }
                                >
                                    Eliminar
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                ),
            })),
        ],
    }
    const datas2 = {
        columns: [
            {
                label: "Fase",
                field: "cfFas_Nombre",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "Trigger",
                field: "nTra_Trigger2",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            }
        ],
        rows: dataTransiciones,
    }
    const badgesData2 = {
        columns: [
            ...datas2.columns,
            {
                label: "Acciones",
                field: "acciones",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
        ],
        rows: [
            ...datas2.rows.map((row, id) => ({
                ...row,
                cuenta: (
                    <div>
                        <Link onClick={() => AbrirNv(row.cSus_Cuenta)}>
                            {row.cSus_Cuenta}
                        </Link>
                    </div>
                ),
                acciones: (
                    <div>
                        <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="dropdown-toggle">
                                <i className="bx bx-dots-vertical-rounded" />
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => Acciones(1, row)}>
                                    <Link>
                                        Editar
                                    </Link>
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() =>
                                        Swal.fire({
                                            title: "Mensaje",
                                            html:
                                                "¿Esta seguro de eliminar la tarea?",
                                            icon: "warning",
                                            showCancelButton: true,
                                            confirmButtonColor: "#3085d6",
                                            cancelButtonColor: "#d33",
                                            confirmButtonText: "Aceptar",
                                            cancelButtonText: "Cancelar",
                                        }).then(result => {
                                            if (result.isConfirmed) {
                                                FuncionEliminarTrigger(row.nTra_Id)
                                            }
                                        })
                                    }
                                >
                                    Eliminar
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                ),
            })),
        ],
    }

    const AbrirNv = (Url) => {
        window.open(Url, '_blank')
    }
    const FuncionFormaDePago = async () => {
        var postData = {
            Prefijo: "BD",
        }
        await instance
            .post("/Maestros/Data", postData)
            .then(response => {
                setDataFormaPago(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionListarCanal = async () => {
        var postData = {
            Prefijo: "AD",
        }
        await instance
            .post("/Maestros/Data", postData)
            .then(response => {
                setDataCanal(response.data)

            })
            .catch(error => {

            })
    }

    const FuncionListarTarea = async () => {
        let _nfFas_Id = getDecryptedItem("nfFas_Id");
        var postData = {
            nfFas_Id: parseInt(_nfFas_Id)
        }
        await instance
            .post("/flujoTarea/Listar", postData)
            .then(response => {
                setData(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionBuscarFlujo_nfFlu_Id = async () => {
        let _nfFas_Id = getDecryptedItem("nfFas_Id");
        var postData = {
            nfFas_Id: parseInt(_nfFas_Id)
        }

        await instance
            .post("/Fase/Obtener_nfFas_Id", postData)
            .then(response => {
                setFlujo(response.data[0])

            })
            .catch(error => {

            })
    }
    const FuncionEliminarTarea = async (_nfTar_Id) => {
        var postData = {
            nfTar_Id: parseInt(_nfTar_Id)
        }

        await instance
            .post("/flujoTarea/FlujoEliminarTarea", postData)
            .then(response => {

                FuncionListarTarea();

            })
            .catch(error => {

            })
    }
    const FuncionListarFases = async () => {
        let _nfFlu_Id = getDecryptedItem("nfFlu_Id");
        var postData = {
            nfFlu_Id: _nfFlu_Id
        }

        await instance
            .post("/Fase/Listar", postData)
            .then(response => {
                setDataFases(response.data)

            })
            .catch(error => {

            })
    }
    /*** Trigger */
    const FuncionInsertarTrigger = async () => {
        let _nfFas_Id = getDecryptedItem("nfFas_Id");
        if (getTransicion.nTra_Fase == "0" || getTransicion.nTra_Fase == null || getTransicion.nTra_Fase == undefined) {
            Swal.fire({
                title: "Mensaje",
                text: "Seleccione una fase",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        }
        else if (getTransicion.nTra_Trigger == "0" || getTransicion.nTra_Trigger == null || getTransicion.nTra_Trigger == undefined) {
            Swal.fire({
                title: "Mensaje",
                text: "Seleccione un trigger",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        }
        else {

            var postData = {
                nfFas_Id: parseInt(_nfFas_Id),
                nTra_Fase: parseInt(getTransicion.nTra_Fase),
                nTra_Trigger: parseInt(getTransicion.nTra_Trigger),
                nTra_Id: null
            }


            if (postData.nfFas_Id == postData.nTra_Fase) {
                Swal.fire({
                    title: "Mensaje",
                    text: "No se puede transicionar a la misma fase",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                })
                return
            }
            else {
                if (getTransicion.nTra_Id) {

                    postData.nTra_Id = getTransicion.nTra_Id;
                    await instance
                        .post("/Transicion/TransicionBuscar", postData)
                        .then(async response => {


                            let array = response.data;

                            if (array.length > 0) {

                                if (array[0].nTra_Id == postData.nTra_Id) {
                                    await instance
                                        .post("/Transicion/TransicionActualizar", postData)
                                        .then(response => {

                                            Swal.fire({
                                                title: "Mensaje",
                                                text: "Agregado exitosamente",
                                                icon: "success",
                                                confirmButtonText: "Aceptar",
                                            })

                                            setTituloModal("Nueva Transición")
                                            FuncionListarTrigger();
                                            setModalInsertar(!modalInsertar);
                                        })
                                        .catch(error => {

                                        })
                                }
                                else {
                                    Swal.fire({
                                        title: "Mensaje",
                                        text: "Este registro ya existe",
                                        icon: "error",
                                        confirmButtonText: "Aceptar",
                                    })
                                }
                            }
                            else {

                                await instance
                                    .post("/Transicion/TransicionActualizar", postData)
                                    .then(response => {

                                        Swal.fire({
                                            title: "Mensaje",
                                            text: "Agregado exitosamente",
                                            icon: "success",
                                            confirmButtonText: "Aceptar",
                                        })

                                        setTituloModal("Nueva Transición")
                                        FuncionListarTrigger();
                                        setModalInsertar(!modalInsertar);
                                    })
                                    .catch(error => {

                                    })
                            }
                        })
                        .catch(error => {

                        })
                }
                else {
                    await instance
                        .post("/Transicion/TransicionBuscar", postData)
                        .then(async response => {


                            let array = response.data;

                            if (array.length > 0) {
                                Swal.fire({
                                    title: "Mensaje",
                                    text: "Este registro ya existe",
                                    icon: "error",
                                    confirmButtonText: "Aceptar",
                                })
                            }
                            else {

                                await instance
                                    .post("/Transicion/TransicionInsertar", postData)
                                    .then(response => {

                                        Swal.fire({
                                            title: "Mensaje",
                                            text: "Agregado exitosamente",
                                            icon: "success",
                                            confirmButtonText: "Aceptar",
                                        })

                                        FuncionListarTrigger();
                                        setModalInsertar(!modalInsertar);
                                    })
                                    .catch(error => {

                                    })
                            }

                        })
                        .catch(error => {

                        })
                }
            }
        }
    }
    const FuncionListarTrigger = async () => {
        let _nfFas_Id = getDecryptedItem("nfFas_Id");
        var postData = {
            nfFas_Id: parseInt(_nfFas_Id),
        }

        await instance
            .post("/Transicion/Transicionlistar", postData)
            .then(response => {
                setDataTransiciones(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionEliminarTrigger = async (_nTra_Id) => {
        var postData = {
            nTra_Id: parseInt(_nTra_Id),
        }

        await instance
            .post("/Transicion/TransicionDelete", postData)
            .then(response => {

                Swal.fire({
                    title: "Mensaje",
                    text: "Eliminado exitosamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })

                FuncionListarTrigger();

            })
            .catch(error => {

            })
    }

    const FuncionListarPermisos = async () => {
        var postData = {
          nUsu_ID: parseInt(getDecryptedItem("RolID"))
        }
        await instance
        .post("/Usuario/Acceso", postData)
        .then(response => {
        let arrayPermisos = response.data;
        const permiso = arrayPermisos.find(element => element.cUsu_Modulo == "ME03");

        if (permiso.bUsu_Editar == 1) {
            setHiddenBtnEditar(false);
            setModo(1);
        }
        else {
            setHiddenBtnEditar(true);
            setModo(2);
        }
        if (permiso.bUsu_Eliminar == 1) {
            setHiddenBtnEliminar(false);
        }
        else {
            setHiddenBtnEliminar(true);
        }
        })
        .catch(error => {

        })
    }


    useEffect(() => {
        FuncionListarPermisos();
        FuncionListarFases();
        FuncionBuscarFlujo_nfFlu_Id();
        FuncionListarTarea();
        FuncionListarTrigger();
        FuncionListarCanal();
        FuncionFormaDePago();
        //FuncionListarSuscriptores()
        FuncionListarCategoria()
    }, [])
    return (
        <React.Fragment>
            <div className="page-content">
                {/* add meta title */}
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="" breadcrumbItem="" />
                    <Row>
                        <Col className="col-12 row center" style={{ paddingLeft: '33px' }}>
                            <Col className="col-12">
                                <CardTitle className="h6" style={{ fontSize: "25px", fontWeight: 'normal' }}>
                                    Flujo de trabajo : <b style={{ fontSize: "25px", textAlign: 'left' }}>{getFlujo.cfFlu_Nombre}</b>
                                </CardTitle>
                            </Col>

                        </Col>
                    </Row>
                    <Row style={{ marginTop: '10px', paddingLeft: '20px' }}>
                        <Col className="col-12 row center">
                            <Col className="col-8">
                                <CardTitle className="h6" style={{ fontSize: "25px", fontWeight: 'normal' }}>
                                    Fase: <b style={{ fontSize: "25px", textAlign: 'left' }}>{getFlujo.cfFas_Nombre}</b>
                                </CardTitle>
                            </Col>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col className="col-12 row" >
                            <Card className="col-12">
                                <CardBody>
                                    <CardTitle className="h6" style={{ fontSize: '23px', fontWeight: 'normal' }}>
                                        Tareas
                                    </CardTitle>
                                    <CardTitle className="h6" style={{ fontSize: "30px", fontSize: '15px' }}>
                                        <div style={{ textAlign: 'left' }}>
                                            <Link
                                                hidden={hiddenBtnEditar}
                                                className="btn btn-danger waves-effect waves-light "
                                                to="/FlujosNuevaTarea" onClick={() => { localStorage.removeItem('nSus_Id') }}>
                                                Nueva
                                            </Link>
                                            {" "}
                                            <Link
                                                className="btn btn-danger waves-effect waves-light "
                                                to="/FlujosDetalle">
                                                Retornar
                                            </Link>
                                        </div>
                                    </CardTitle>

                                    <Row style={{ marginTop: '0px', paddingBottom: '0px', marginBottom: '0px' }}>
                                        <Row>
                                            <Col className="col-10" style={{ paddingBottom: '0px', marginBottom: '0px' }}>
                                                <Card style={{ paddingBottom: '0px', marginBottom: '0px' }}>
                                                    <CardBody style={{ paddingBottom: '0px', marginBottom: '0px' }}>

                                                        <MDBDataTable
                                                            data={badgesData}
                                                            className="TablaTareas"
                                                            responsiveXl
                                                            fixed
                                                            hover
                                                            entriesLabel="Mostrar"
                                                            entrylabel={"Mostrar entradas"}
                                                            infoLabel={["Mostrando ", "a", "de", "registros"]}
                                                            bordered
                                                            order={["age", "desc"]}
                                                            noRecordsFoundLabel={"No hay datos"}
                                                            searchLabel={"Buscar"}
                                                            noBottomColumns
                                                            paginationLabel={["Anterior", "Siguiente"]}
                                                            theadColor="HeadBlue"
                                                        />
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Row>
                                    <Row style={{ marginTop: '0px' }}>


                                        <Row>
                                            <Col className="col-8">
                                                <Card>

                                                    <CardBody>

                                                        <CardTitle className="h6" style={{ fontSize: "25px", fontWeight: 'normal' }}>
                                                            Transiciones
                                                        </CardTitle>

                                                        <Col className="col-12" style={{ textAlign: 'right' }}>
                                                            <button
                                                                onClick={() => { abrirCerrarModal(1), setTransicion({}) }}
                                                                className="btn btn-danger waves-effect waves-light">
                                                                Añadir transición
                                                            </button>
                                                        </Col>
                                                        <MDBDataTable
                                                            data={badgesData2}
                                                            className="TablaTransiciones"
                                                            responsiveXl
                                                            fixed
                                                            hover
                                                            entriesLabel="Mostrar"
                                                            entrylabel={"Mostrar entradas"}
                                                            infoLabel={["Mostrando ", "a", "de", "registros"]}
                                                            bordered
                                                            order={["age", "desc"]}
                                                            noRecordsFoundLabel={"No hay datos"}
                                                            searchLabel={"Buscar"}
                                                            noBottomColumns
                                                            paginationLabel={["Anterior", "Siguiente"]}
                                                            theadColor="HeadBlue"
                                                            searching={false}
                                                            paging={false}
                                                            info={false}
                                                        />
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Row>


                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Modal size="l" isOpen={modalInsertar}>
                        <div className="modal-header ModalHeaderColor">
                            <h5
                                style={{ color: "#fff" }}
                                className="modal-title mt-0"
                                id="myModalLabel"
                            >
                                {tituloModal}
                            </h5>
                            <button
                                type="button"
                                onClick={() => abrirCerrarModal(1)}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3 row">
                                <div className="col-md-12">
                                    <select
                                        className="form-select"
                                        name="nTra_Fase"
                                        onChange={handleChange}
                                        value={getTransicion && getTransicion.nTra_Fase}
                                    >
                                        <option value="0">Seleccionar...</option>
                                        {dataFases.map((row, i) => (
                                            <option key={i} value={row.nfFas_Id}>
                                                {row.cfFas_Nombre}
                                            </option>
                                        ))}
                                    </select>
                                    <label className="col-form-label labelDown">
                                        Fase
                                    </label>
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <div className="col-md-12">
                                    <select
                                        className="form-select"
                                        name="nTra_Trigger"
                                        onChange={handleChange}
                                        value={getTransicion && getTransicion.nTra_Trigger}
                                    >
                                        <option value="0">Seleccionar...</option>
                                        <option value="1">Todas finalizadas</option>
                                        <option value="2">Todas aprobadas</option>
                                        <option value="5">Todas rechazadas</option>
                                        <option value="3">Todas finalizadas con al menos una aprobada</option>
                                        <option value="4">Todas finalizadas con al menos una rechazada</option>
                                    </select>
                                    <label className="col-form-label labelDown">
                                        Trigger
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={() => abrirCerrarModal(1)}
                                className="btn btn-secondary waves-effect"
                                data-dismiss="modal"
                            >
                                Cerrar
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger waves-effect waves-light"
                                onClick={() => FuncionInsertarTrigger()}
                            >
                                Guardar
                            </button>
                        </div>

                    </Modal>

                </Container>
            </div>
        </React.Fragment>
    )
}
export default Suscriptores