import React, { Component, useState, useEffect } from "react"
import Select from "react-select"
import Swal from "sweetalert2"
import {
    CardBody,
    Card,
    Col,
    Container,
    CardTitle,
    Button,
    Row,
    Modal,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
} from "reactstrap"
import instance from '../../config'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faPlus,
    faSearch,
    faTimes,
    faChevronLeft,
    faChevronRight,
    faPaperPlane,
    faBuilding,
    faUser,
    faCalendar,
    faCircle,
    faClipboard,
    faBell,
} from "@fortawesome/free-solid-svg-icons"
import { getDecryptedItem } from "util"
const baseUrl = process.env.REACT_APP_API_URL
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
var horaInicio = Fecha.getHours() + ':' + Fecha.getMinutes();
var horaFin = Fecha.getHours() + 1 + ':' + '00';
export const ModalMovimiento = ({ show, onCloseClick, CodigoExterno }) => {
    const [getErrores, setErrores] = useState({});
    const [dataTipoNotificacion, setDataTipoNotificacion] = useState([])
    const [validData, setValidData] = useState(false);
    const [blockBtn, setBlockBtn] = useState(false);
    const [getMovimiento, setMovimiento] = useState({
        Acto: "",
        CodigoC: "",
        CodigoExterno: "",
        ComentarioUsuario: "",
        FechaResolucion: "",
        Fojas: "",
        Moviento: "",
        Proveido: "",
        Resolucion: "",
        Sumilla: "",
        TNotificacion: "",
        cFechaDescarga: "",
    })

    const Validacion = (form) => {
        let errors = {};
        let regexLetras = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
        let regexCorreo = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
        let regexNumero = /^[0-9]+$/;

        if (!form.Acto.trim()) {
            errors.Acto = "(*)";
        }


        if (!form.ComentarioUsuario.trim()) {
            errors.ComentarioUsuario = "(*)";
        }

        if (!form.TNotificacion.trim()) {
            errors.TNotificacion = "(*)";
        }
        if (!form.FechaResolucion.trim()) {
            errors.FechaResolucion = "(*)";
        }

        return errors;
    }
    const FuncionListarTipoNotificacion = async () => {
        var postData = {
            Prefijo: "NT",
        }
        await instance
            .post("/Maestros/Data", postData)
            .then(response => {
                setDataTipoNotificacion(response.data)

            })
            .catch(error => {

            })
    }
    async function ListarMovimientos(_nCas_Id) {
        var postData = {
            nCas_Id: parseInt(_nCas_Id),
        }

        return new Promise(async (resolve) => {
            await instance
                .post("/CasMovs/ListaXCaso", postData)
                .then(response => {
                    resolve({
                        code: 'Success',
                        res: response.data
                    })
                }).catch(function (error) {
                    resolve({
                        code: 'Error',
                        res: error.response.data.message
                    })
                });
        });
    }
    const FuncionAgregarMovimiento = async () => {
        let arrayMovimientos = []
        let _nCas_Id = getDecryptedItem("nCas_Id")
        var respuesta = await ListarMovimientos(_nCas_Id);

        const fechaPrueba = new Date(getMovimiento.FechaResolucion);
        var year = fechaPrueba.getFullYear();


        var month = fechaPrueba.getMonth() + 1; // Se suma 1 porque los meses empiezan en 0 (enero)


        var day = fechaPrueba.getDate() + 1;

        if (day < 9) {
            day = "0" + day
        }
        if (month < 9) {
            month = "0" + month
        }
        let _correlativo = 0;

        var postData = {
            Acto: getMovimiento.Acto,
            CodigoC: getMovimiento.CodigoC,
            CodigoExterno: CodigoExterno,
            ComentarioUsuario: getMovimiento.ComentarioUsuario,
            FechaResolucion: day + "" + month + "" + year,
            Fojas: getMovimiento.Fojas,
            Moviento: _correlativo,
            Proveido: getMovimiento.Proveido,
            Resolucion: getMovimiento.Resolucion,
            Sumilla: getMovimiento.Sumilla,
            TNotificacion: getMovimiento.TNotificacion,
            cFechaDescarga: getMovimiento.cFechaDescarga,
        }

        let _json = {
            nCas_Id: _nCas_Id,
            cCav_Cuadernos: [],
            cCav_General: [],
            cCav_Movimientos: [],
            cCav_Notificacion: [],
            cCav_Archivo: []
        }

        if (respuesta.res.length == 0) {

            postData.Moviento = 1
            _json.cCav_Movimientos.push(postData)

            await instance
                .post("/Scraping/Insertar", _json)
                .then(response => {

                    Swal.fire({
                        title: "Mensaje",
                        text: "Agregado exitosamente",
                        icon: "success",
                        confirmButtonText: "Aceptar",
                    })
                    onCloseClick()
                })
                .catch(error => {

                })
        }
        else {

            arrayMovimientos = respuesta.res[0]

            _correlativo = arrayMovimientos.cCav_Movimientos.length + 1;

            postData.Moviento = _correlativo
            arrayMovimientos.cCav_Movimientos.unshift(postData);

            var _arrayMovimientos = JSON.parse(JSON.stringify(arrayMovimientos).replace(/\//g, '').toString());



            await instance
                .post("/Scraping/Insertar", _arrayMovimientos)
                .then(response => {

                    Swal.fire({
                        title: "Mensaje",
                        text: "Agregado exitosamente",
                        icon: "success",
                        confirmButtonText: "Aceptar",
                    })
                    onCloseClick()
                })
                .catch(error => {

                })
        }


    }

    const handleChange = e => {
        const { name, value } = e.target
        setMovimiento((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }
    const handleBlur = e => {
        handleChange(e)
        // Obtener el nombre del campo y el valor actualizado
        const { name, value } = e.target;

        setErrores(Validacion(getMovimiento))

    }
    const FuncionValidarFormulario = async () => {
        if (Object.keys(getErrores).length === 0) {
            setBlockBtn(true);
            FuncionAgregarMovimiento();

        } else {
            setValidData(true);
            Swal.fire({
                title: "Mensaje",
                text: "Complete los campos requeridos",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        }
    }

    useEffect(() => {
        FuncionListarTipoNotificacion();
        setErrores(Validacion(getMovimiento))
    }, [])
    return (
        <Modal size="lg" isOpen={show}  >
            <div
                className="modal-header ModalHeaderColor"
            >
                <h5
                    style={{ color: "#fff" }}
                    className="modal-title mt-0"
                    id="myModalLabel"
                >
                    Nuevo movimiento
                </h5>
                <button
                    type="button"
                    onClick={() => onCloseClick()}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="mb-3 row">
                    <div className="col-md-6">
                        <input
                            className={getErrores.Acto && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                            placeholder="Ingrese el acto"
                            name="Acto"
                            value={getMovimiento && getMovimiento.Acto}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            maxLength="200"
                        />
                        <label className="col-form-label labelDown">Acto</label>{getErrores.Acto && <label className="col-form-label labelDown labelRed">{getErrores.Acto}</label>}
                    </div>
                    <div className="col-md-6">
                        <select
                            className={getErrores.TNotificacion && validData ? "invalid form-select inputDown" : "form-select inputDown"}

                            name="TNotificacion"
                            onChange={handleChange}
                            onBlur={handleBlur}
                        >
                            <option value="0">Seleccionar...</option>
                            {dataTipoNotificacion.map((row, i) => (
                                <option key={i} value={row.cTabNombre}>
                                    {row.cTabNombre}
                                </option>
                            ))}
                        </select>
                        <label className="col-form-label labelDown">Tipo de notificación</label>{getErrores.TNotificacion && <label className="col-form-label labelDown labelRed">{getErrores.TNotificacion}</label>}
                    </div>

                </div>
                <div className="mb-3 row">

                    <div className="col-md-6">
                        <input
                            className="form-control"
                            type="date"
                            name="FechaResolucion"
                            value={getMovimiento && getMovimiento.FechaResolucion}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <label className="col-form-label labelDown">Fecha de movimiento</label>{getErrores.FechaResolucion && <label className="col-form-label labelDown labelRed">{getErrores.FechaResolucion}</label>}

                    </div>

                    <div className="col-md-6">
                        <textarea
                            className={getErrores.ComentarioUsuario && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                            placeholder="Ingrese un comentario"
                            name="ComentarioUsuario"
                            value={getMovimiento && getMovimiento.ComentarioUsuario}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            maxLength="200"
                        />
                        <label className="col-form-label labelDown">Comentario</label>{getErrores.ComentarioUsuario && <label className="col-form-label labelDown labelRed">{getErrores.ComentarioUsuario}</label>}
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => onCloseClick()}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                >
                    Cerrar
                </button>
                <button
                    type="button"
                    className="btn btn-danger waves-effect waves-light"
                    onClick={() => FuncionValidarFormulario()}
                >
                    Guardar
                </button>
            </div>
        </Modal>
    );
}
export default ModalMovimiento
