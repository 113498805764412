
import React, { Component, useState, useEffect } from "react"
import { MDBDataTable } from "mdbreact"
import {
  CardBody,
  Card,
  Col,
  Container,
  CardTitle,
  Button,
  Row,
  Modal,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap"
import MetaTags from "react-meta-tags"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/tablescss/FormatTable.scss"
import Swal from "sweetalert2"
import { getDecryptedItem } from '../../util';
import instance from '../../config'
import ModalIngreso from "pages/Componentes/ModalIngreso"
import ReactHTMLTableToExcel from 'react-html-table-to-excel'
// Declaramos las variables para jQuery
var jQuery;
var $;
const baseUrl = process.env.REACT_APP_API_URL
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
const Ingresos = props => {
  const [modalVer, setModalVer] = useState(false)
  const [modalInsertar, setModalInsertar] = useState(false)
  const [modalActualizar, setModalActualizar] = useState(false)
  const [modalEliminar, setModalEliminar] = useState(false)
  const [dataCasoInfo, setDataCasoInfo] = useState([])
  const [modalFiltro, setModalFiltro] = useState(false)
  const [mode, setMode] = useState(false)
  const [data, setData] = useState([])
  const [dataPermiso, setDataPermiso] = useState({
    bUsu_Consultar: 0,
    bUsu_Editar: 0,
    bUsu_Eliminar: 0,
    cRol_Nombre: "",
    cUsu_Modulo: "",
    cUsu_Opcion: "0",
    nRol_Id: 0,
  })
  const [blockComboMoneda, setBlockComboMoneda] = useState(false)
  const [disableTpoCambio, setDisableTpoCambio] = useState(true)
  const [dataCliente, setDataCliente] = useState([])
  const [dataCaso, setDataCaso] = useState([])
  const [dataComboMPago, setDataComboMPago] = useState([])
  const [dataComboMoneda, setDataComboMoneda] = useState([])
  const [dataComboTIngreso, setDataComboTIngreso] = useState([])
  const [dataComboCuenta, setDataComboCuenta] = useState([])
  const [getErrores, setErrores] = useState({});
  const [getIngreso, setIngreso] = useState({
    p_nIng_Id: "",
    nCas_Id: "",
    nCue_Id: "",
    cIng_Tab_Tipo: "KP01",
    cIng_Tab_FormaPago: "",
    cIng_Tab_Moneda: "MO01",
    cCas_Cod_Interno: "",
    cCas_Cod_Externo: "",
    nIng_Monto: 0,
    nIng_MontoFormat: 0,
    nIng_MontoOld: 0,
    nIng_Monto2: 0,
    cIng_Detalle: "",
    dIng_Fecha: ano + "-" + mes + "-" + dia,
    cIng_Num_Ope: "",
    bIng_Estado: "1",
    nIng_TCambio: 0,
    nCli_Id: "",
  })
  const [validData, setValidData] = useState(false)
  const [getFiltro, setFiltro] = useState({
    nCas_Id: null,
    cIng_Tab_Moneda: null,
    cIng_Tab_Tipo: null,
    FechaInicial: ano + "-" + "01" + "-" + "01",
    FechaFin: ano + "-" + mes + "-" + dia,
    nCli_Id: null,
  })
  const [chekedValor1, setchekedValor1] = useState(false)
  const [chekedValor2, setchekedValor2] = useState(false)
  const [chekedValor3, setchekedValor3] = useState(false)
  const [chekedValor4, setchekedValor4] = useState(false)
  const [Valor1, setValor1] = useState(true)
  const [Valor2, setValor2] = useState(true)
  const [Valor3, setValor3] = useState(true)
  const [Valor4, setValor4] = useState(true)
  const [hiddenBtnEditar, setHiddenBtnEditar] = useState(true)
  const [hiddenBtnEliminar, setHiddenBtnEliminar] = useState(true)
  const [hiddenCliente, setHiddenCliente] = useState(false)
  const [modo, setModo] = useState(0)

  const handleChangeFiltro = e => {
    if (chekedValor1 == true || chekedValor2 == true || chekedValor3 == true || chekedValor4 == true) {


      setFiltro({
        ...getFiltro,
        [e.target.name]: e.target.value
      })

      if (e.target.name == "nCli_Id") {
        FuncionListarCasos(e.target.value)
      }

    } else {


      setFiltro({
        ...getFiltro,
        [e.target.name]: e.target.value
      })

    }

    console.log(getFiltro);
  }
  const handleChangeValor1 = e => {
    setchekedValor1(!chekedValor1)
    if (chekedValor1 == true) {
      setFiltro({
        ...getFiltro,
        nCli_Id: null
      })
      setValor1(true)
    } else {
      var Valor = getFiltro.nCli_Id
      setFiltro({
        ...getFiltro,
        nCli_Id: Valor
      })
      setValor1(false)
    }
  }
  const handleChangeValor2 = e => {
    setchekedValor2(!chekedValor2)
    if (chekedValor2 == true) {
      setFiltro({
        ...getFiltro,
        nCas_Id: null
      })
      setValor2(true)
    } else {
      var Valor = getFiltro.nCas_Id
      setFiltro({
        ...getFiltro,
        nCas_Id: Valor
      })
      setValor2(false)
    }
  }
  const handleChangeValor3 = e => {
    setchekedValor3(!chekedValor3)
    if (chekedValor3 == true) {
      setFiltro({
        ...getFiltro,
        cIng_Tab_Moneda: null
      })
      setValor3(true)
    } else {
      var Valor = getFiltro.cIng_Tab_Moneda
      setFiltro({
        ...getFiltro,
        cIng_Tab_Moneda: Valor
      })
      setValor3(false)
    }
  }
  const handleChangeValor4 = e => {
    setchekedValor4(!chekedValor4)
    if (chekedValor4 == true) {
      setFiltro({
        ...getFiltro,
        cIng_Tab_Tipo: null
      })
      setValor4(true)
    } else {
      var Valor = getFiltro.cIng_Tab_Tipo
      setFiltro({
        ...getFiltro,
        cIng_Tab_Tipo: Valor
      })
      setValor4(false)
    }
  }

  const ReloadModalListar = async () => {
    setModalInsertar(!modalInsertar)
    FuncionListarIngresos()
  }

  const FuncionListarCliente = async () => {
    let RolId = getDecryptedItem("RolID")

    var postData = {
      bCli_Tipo: null,
      nUsu_ID: RolId == 6 || RolId == 1 ? null : getDecryptedItem("ID")
    }
    await instance
      .post("/Cliente/ListarCbo", postData)
      .then(response => {
        setDataCliente(response.data)
      })
      .catch(error => {

      })
  }


  const FuncionListarCasos = async (_idClient) => {
    var postData = {
      idClient: _idClient,
      nUsu_Id: getDecryptedItem("ID"),
    }
    await instance
      .post("/Casos/ListarCbo", postData)
      .then(response => {
        console.log(response.data);
        setDataCaso(response.data)
      })
      .catch(error => {

      })
  }

  const FuncionListarComboMetodoPago = async () => {
    var postData = {
      Prefijo: "FP",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataComboMPago(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarComboMoneda = async () => {
    var postData = {
      Prefijo: "MO",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataComboMoneda(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarComboTIngreso = async () => {
    var postData = {
      Prefijo: "KP",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataComboTIngreso(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarComboCuenta = async () => {
    await instance
      .get("/CBanco/Listar/Combo")
      .then(response => {
        setDataComboCuenta(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarIngresos = async () => {
    let RolId = getDecryptedItem("RolID")
    var postData = {
      nUsu_ID: RolId == 6 || RolId == 1 ? null : getDecryptedItem("ID")
    }
    await instance
      .post("/Ingresos/Listar", postData)
      .then(response => {
        console.log(response.data);
        setData(response.data)

      })
      .catch(error => {

      })
  }
  const FuncionBuscarIngresos = async () => {
    var postData = {
      nCli_Id: getDecryptedItem("CLIID") || parseInt(getFiltro.nCli_Id),
      nCas_Id: getFiltro.nCas_Id !== null && getFiltro.nCas_Id !== undefined ? parseInt(getFiltro.nCas_Id, 10) : getFiltro.nCas_Id,
      cIng_Tab_Moneda: getFiltro.cIng_Tab_Moneda,
      cIng_Tab_Tipo: getFiltro.cIng_Tab_Tipo,
      FechaInicial: getFiltro.FechaInicial,
      FechaFin: getFiltro.FechaFin,
      nUsu_ID: parseInt(getDecryptedItem("ID"))
    }

    console.log(postData);

    await instance
      .post("/Ingreso/Buscar", postData)
      .then(response => {
        console.log(response.data);
        setData(response.data)
        setModalFiltro(!modalFiltro)
      })
      .catch(error => {

      })
  }

  const FuncionAgregarIngreso = async () => {
    var postData = {
      nCas_Id: Number.parseInt(getIngreso.nCas_Id),
      nCue_Id: Number.parseInt(getIngreso.nCue_Id),
      cIng_Tab_Tipo: getIngreso.cIng_Tab_Tipo,
      cIng_Tab_FormaPago: getIngreso.cIng_Tab_FormaPago,
      cIng_Tab_Moneda: getIngreso.cIng_Tab_Moneda,
      nIng_Monto: parseFloat(getIngreso.nIng_Monto),
      cIng_Detalle: getIngreso.cIng_Detalle,
      dIng_Fecha: getIngreso.dIng_Fecha,
      cIng_Num_Ope: getIngreso.cIng_Num_Ope,
      bIng_Estado: Number.parseInt(getIngreso.bIng_Estado),
    }
    await instance
      .post("/Ingresos/Insertar", postData)
      .then(response => {

        setModalInsertar(!modalInsertar)
        Swal.fire({
          title: "Mensaje",
          text: "Ingreso registrado exitosamente!",
          icon: "success",
          confirmButtonText: "Aceptar",
        })
        FuncionListarIngresos()
      })
      .catch(error => {

      })
  }
  const FuncionCorreoEvento = async (_nCas_Id, _nUsu_Id, _cCor_Tab_Tipo, _cCor_Asunto) => {
    var postData = {
      nCas_Id: _nCas_Id,
      nUsu_Id: _nUsu_Id,
      cCor_Tab_Tipo: _cCor_Tab_Tipo,
      cCor_Asunto: _cCor_Asunto,
    }
    await instance
      .post("/Correo/Insertar", postData)
      .then(response => {
      })
      .catch(error => {

      })
  }
  const FuncionActualizarIngreso = async () => {

    var postData = {
      nIng_Id: Number.parseInt(getIngreso.p_nIng_Id),
      nCas_Id: Number.parseInt(getIngreso.nCas_Id),
      nCue_Id: Number.parseInt(getIngreso.nCue_Id),
      cIng_Tab_Tipo: getIngreso.cIng_Tab_Tipo,
      cIng_Tab_Banco: getIngreso.cIng_Tab_Banco,
      cIng_Tab_Moneda: getIngreso.cIng_Tab_Moneda,
      cIng_Tab_FormaPago: getIngreso.cIng_Tab_FormaPago,
      nIng_Monto: parseFloat(getIngreso.nIng_MontoOld),
      dIng_Fecha: getIngreso.dIng_Fecha,
      cIng_Detalle: getIngreso.cIng_Detalle,
      cIng_Num_Ope: getIngreso.cIng_Num_Ope,
      bIng_Estado: getIngreso.bIng_Estado,
      nIng_TCambio: parseFloat(getIngreso.nIng_TCambio)
    }

    await instance
      .post("/Ingreso/Actualizar", postData)
      .then(async response => {
        let postData2 = {
          cAud_nombre: "Ingresos",
          nAud_IdReg: postData.nIng_Id,
          nUsu_ID: getDecryptedItem("ID"),
          dAud_Fecha: null,
          nAud_Accion: "U",
          cTabCodigo: 'ME06'
        }

        await instance
          .post("/Auditoria/Insertar", postData2)
          .then(response => {
          });
        let objMails = {
          casoSlt: postData.nCas_Id,
          idClient: getIngreso.nCli_Id,
          nUsu_ID: getDecryptedItem("ID"),
          parametro: {
            tpoTbl: 'ingreso',   // tipo de tabla
            tpoAct: 'actualizar'  // insertar o actualizar
          },
          dataIngreso: postData,
        }


        await instance
          .post("/mail/event/ingreso", objMails)
          .then(res => {
            FuncionListarIngresos();
          })
          .catch(error => {

          })

        abrirCerrarModal(2)
        Swal.fire({
          title: "Mensaje",
          text: "Ingreso actualizado exitosamente!",
          icon: "success",
          confirmButtonText: "Aceptar",
        })
        FuncionListarIngresos()
      })
      .catch(error => {

      })

  }
  const FuncionValidarFormulario = async () => {

    if (Object.keys(getErrores).length === 0) {
      FuncionActualizarIngreso()

    } else {
      setValidData(true);
      Swal.fire({
        title: "Mensaje",
        text: "Complete los campos requeridos",
        icon: "error",
        confirmButtonText: "Aceptar",
      })
    }
  }
  const FuncionEliminarIngreso = async nIng_Id => {
    var postData = {
      nIng_Id: Number.parseInt(nIng_Id),
    }
    await instance
      .post("/Ingreso/Eliminar", postData)
      .then(async response => {
        let postData2 = {
          cAud_nombre: "Ingresos",
          nAud_IdReg: postData.nIng_Id,
          nUsu_ID: getDecryptedItem("ID"),
          dAud_Fecha: null,
          nAud_Accion: "D",
          cTabCodigo: 'ME06'
        }
        await instance
          .post("/Auditoria/Insertar", postData2)
          .then(response => {
          });


        FuncionListarIngresos()
        Swal.fire({
          title: "Mensaje",
          text: "Ingreso eliminado exitosamente!",
          icon: "success",
          confirmButtonText: "Aceptar",
        })
      })
      .catch(error => {

      })
  }
  const datas = {
    columns: [
      {
        label: "Cliente",
        field: "cCli_NombreCompleto",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Caso",
        field: "cCas_Titulo",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Fecha",
        field: "dIng_Fecha2",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Tipo",
        field: "cIng_Tab_Tipo2",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Cuenta Banco",
        field: "entBan",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Descripción",
        field: "cIng_Detalle",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
          width: "28%"
        },
      },
      {
        label: "Monto",
        field: "nIng_Monto2",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
    ],
    rows: data,
  }
  const Acciones = (Ingreso, caso) => {

    setIngreso(prevState => ({
      ...prevState,
      p_nIng_Id: Ingreso.nIng_Id,
      nCas_Id: Ingreso.nCas_Id,
      nCue_Id: Ingreso.nCue_Id,
      cCas_Cod_Externo: Ingreso.cCas_Cod_Externo,
      cCas_Cod_Interno: Ingreso.cCas_Cod_Interno,
      cIng_Tab_Tipo: Ingreso.cIng_Tab_Tipo,
      cIng_Tab_FormaPago: Ingreso.cIng_Tab_FormaPago,
      cIng_Tab_Moneda: Ingreso.cIng_Tab_Moneda,
      nIng_Monto: Ingreso.nIng_Monto,
      nIng_MontoFormat: Ingreso.nIng_Monto,
      nIng_MontoOld: Ingreso.nIng_Monto,
      nIng_Monto2: Ingreso.nIng_Monto2,
      cIng_Detalle: Ingreso.cIng_Detalle,
      dIng_Fecha: Ingreso.dIng_Fecha,
      cIng_Num_Ope: Ingreso.cIng_Num_Ope,
      bIng_Estado: Ingreso.bIng_Estado,
      nIng_TCambio: Ingreso.nIng_TCambio,
      nCli_Id: Ingreso.nCli_Id,
    }))
    if (caso == "Editar") {
      abrirCerrarModal(2)

      if (Ingreso.cIng_Tab_Moneda != "MO01") {
        setDisableTpoCambio(false);
      }
      else {
        setDisableTpoCambio(true);
      }
    }
    else if (caso == "Ver") {
      abrirCerrarModal(3)
    }
    else {
      abrirCerrarModal(4)
    }
  }
  const badgesData = {
    columns: [
      ...datas.columns,
      {
        label: "Acciones",
        field: "acciones",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
    ],
    rows: [
      ...datas.rows.map((row, id) => ({
        ...row,
        acciones: (
          <div>
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="dropdown-toggle">
                <i className="bx bx-dots-vertical-rounded" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => Acciones(row, "Ver")}>
                  Ver detalle
                </DropdownItem>
                <DropdownItem hidden={hiddenBtnEditar} onClick={() => Acciones(row, "Editar")}>
                  Editar
                </DropdownItem>
                <DropdownItem hidden={hiddenBtnEliminar}
                  onClick={() =>
                    Swal.fire({
                      title: "Mensaje",
                      html: "Estas seguro de eliminar este registro",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Aceptar",
                      cancelButtonText: "Cancelar",
                    }).then(result => {
                      if (result.isConfirmed) {
                        FuncionEliminarIngreso(row.nIng_Id)
                      }
                    })
                  }
                >
                  Eliminar
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        ),
      })),
    ],
  }
  const abrirCerrarModal = Modal => {
    if (Modal == 1) {
      if (!modalInsertar == false) {
        Swal.fire({
          html: "¿Seguro que desea salir sin guardar los datos?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        }).then(result => {
          if (result.isConfirmed) {
            setModalInsertar(!modalInsertar)
          }
        })
      } else {
        setModo(0);
        setModalInsertar(!modalInsertar)
      }
    } else if (Modal == 2) {
      setMode("Editar")
      setModo(1);
      setModalInsertar(!modalInsertar)
    }
    else if (Modal == 3) {
      setModo(2);
      setModalInsertar(!modalInsertar)
    }
    else if (Modal == 4) {
      setModalFiltro(!modalFiltro)
    }
  }

  const FormatoMoneda = (moneda) => {

    return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(moneda);
  }
  const handleBlur = e => {
    handleChange(e)
    setErrores(Validacion(getIngreso))

  }
  const handleChange = e => {
    const { name, value } = e.target
    setIngreso(prevState => ({
      ...prevState,
      [name]: value,
    }))
    if (name == "cIng_Tab_Tipo") {
      if (value == "KP02") {
        setBlockComboMoneda(true);
        setIngreso(prevState => ({
          ...prevState,
          cIng_Tab_Moneda: "MO01",
        }))
        setDisableTpoCambio(true);
      }
      else {
        setBlockComboMoneda(false);
      }
    }
    if (name == "cIng_Tab_Moneda") {
      if (value == "MO01" || value == "MO07") {
        setDisableTpoCambio(true);

      }
      else {
        setDisableTpoCambio(false);
      }
    }
    if (name == "nIng_Monto") {
      setIngreso(prevState => ({
        ...prevState,
        nIng_Monto: value,
      }))
      setIngreso(prevState => ({
        ...prevState,
        nIng_MontoOld: value,
      }))
      setIngreso(prevState => ({
        ...prevState,
        nIng_MontoFormat: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(value),
      }))

    }
  }

  const BluerChange = e => {
    const { name, value } = e.target
    if (name == "nIng_Monto") {
      setIngreso(prevState => ({
        ...prevState,
        [name]: getIngreso.nIng_MontoFormat,
      }))
    }
  }
  const FocusChange = e => {
    const { name, value } = e.target
    if (name == "nIng_Monto") {
      setIngreso(prevState => ({
        ...prevState,
        [name]: getIngreso.nIng_MontoOld,
      }))
    }
  }
  const Validacion = (form) => {
    let errors = {};
    let regexLetras = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    let regexCorreo = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    let regexNumero = /^[0-9]+$/;

    if (form.nCas_Id == 0) {
      errors.nCas_Id = "(*)"
    }
    if (form.nCue_Id == 0 || form.nCue_Id == "" || form.nCue_Id == "0") {
      errors.nCue_Id = "(*)"
    }
    if (form.cIng_Tab_FormaPago == 0) {
      errors.cIng_Tab_FormaPago = "(*)"
    }
    if (form.cIng_Tab_Moneda == 0) {
      errors.cIng_Tab_Moneda = "(*)"
    }
    if (disableTpoCambio == false) {

      if (form.nIng_TCambio == 0 || form.nIng_TCambio == null || form.nIng_TCambio.toString() == "") {

        errors.nIng_TCambio = "(*)"
      }
    }
    if (form.nIng_Monto == 0) {
      errors.nIng_Monto = "(*)"
    }
    if (form.cIng_Num_Ope.trim() == "") {
      errors.cIng_Num_Ope = "(*)"
    }
    if (form.cIng_Tab_Tipo == 0) {
      errors.cIng_Tab_Tipo = "(*)"
    }
    if (form.cTabCodigo == 0) {
      errors.cTabCodigo = "(*)"
    }

    return errors;
  }
  const FuncionListarPermisos = async () => {
    var postData = {
      nUsu_ID: parseInt(getDecryptedItem("RolID"))
    }
    await instance
      .post("/Usuario/Acceso", postData)
      .then(response => {
        let arrayPermisos = response.data;
        const permiso = arrayPermisos.find(element => element.cUsu_Modulo == "ME06");

        if (permiso.bUsu_Editar == 1) {
          setHiddenBtnEditar(false);
        }
        else {
          setHiddenBtnEditar(true);
        }
        if (permiso.bUsu_Eliminar == 1) {
          setHiddenBtnEliminar(false);
        }
        else {
          setHiddenBtnEliminar(true);
        }
      })
      .catch(error => {

      })
  }
  const FnExportExcel = async () => {
    var postData = {
      type: "Ingresos",
      data: data
    }
    await instance
      .post("/Generate/Excel", postData, { responseType: 'arraybuffer' })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = 'Ingresos.xlsx';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {

      })
  }

  useEffect(() => {
    if (getDecryptedItem("RolID") == 9) {
      setHiddenCliente(true);
      FuncionListarCasos()
    }

    //setErrores(Validacion(getIngreso));
    FuncionListarPermisos();
    FormatoMoneda();
    FuncionListarIngresos()
    FuncionListarCliente()

    FuncionListarComboMetodoPago()  //registrado
    FuncionListarComboMoneda()      //registrado
    FuncionListarComboTIngreso()    //registrado
    FuncionListarComboCuenta()      //registrado
    let rol = getDecryptedItem("RolID");
    if (rol !== '9') {
      localStorage.removeItem('CLIID');
      localStorage.removeItem('nCas_Id');
    }
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        { }
        <MetaTags>
          <title>Ingresos | GpsLegal</title>
        </MetaTags>
        <Container fluid>
          { }
          <Breadcrumbs title="GpsLegal" breadcrumbItem="Ingresos" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div>
                    <button
                      type="button"
                      onClick={() => abrirCerrarModal(1)}
                      className="btn btn-danger waves-effect waves-light"
                      data-toggle="modal"
                      data-target="#myModal"
                      hidden={hiddenBtnEditar}
                    >
                      <i style={{ fontSize: "15px" }} className="icon-nuevo"></i>&nbsp;
                      Nuevo
                    </button>{" "}
                    <button
                      type="button"
                      onClick={() => abrirCerrarModal(4)}
                      className="btn btn-danger waves-effect waves-light"
                      data-toggle="modal"
                      data-target="#myModal"
                    >
                      <i style={{ fontSize: "15px" }} className="icon-filtro"></i>&nbsp;
                      Filtros
                    </button>{" "}
                    <button
                      type="button"
                      onClick={() => FnExportExcel()}
                      className="btn btn-success waves-effect waves-light"
                      data-toggle="modal"
                      data-target="#myModal"
                    >
                      <i style={{ fontSize: "15px" }} className="icon-excel"></i>&nbsp;
                      Excel
                    </button>{" "}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="">
              <div>
                <div>
                  <div>
                    {(() => {
                      if (modalInsertar == true) {
                        return <>
                          <ModalIngreso show={modalInsertar}
                            modo={modo}
                            ingreso={getIngreso}
                            mode={mode}
                            onCloseClick={() => setModalInsertar(false)}
                            ReloadLista={() => ReloadModalListar()}
                            Titulo="Nuevo Ingreso"
                          />
                        </>
                      }
                    })()}
                    <Modal size="lg" isOpen={modalVer}>
                      <div
                        className="modal-header"
                        style={{
                          background:
                            "linear-gradient( 180deg , #E5163A, #AF2240)",
                        }}
                      >
                        <h5
                          style={{ color: "#fff" }}
                          className="modal-title mt-0"
                          id="myModalLabel"
                        >
                          Detalle del ingreso
                        </h5>
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(3)}
                          className="close"
                          data-dismiss="modal"
                          style={{ color: "white" }}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="mb-3 row">
                          <div className="col-md-6">
                            <select
                              className="form-select inputDown"
                              name="nCli_Id"
                              onChange={handleChange}
                              disabled
                              value={getIngreso && getIngreso.nCli_Id}
                            >
                              <option value="0">Seleccionar...</option>
                              {dataCliente.map((row, i) => (
                                <option key={i} value={row.nCli_Id}>
                                  {row.cCli_NombreCompleto}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Cliente
                            </label>
                          </div>
                          <div className="col-md-6">
                            <select
                              className="form-select inputDown"
                              name="nCas_Id"
                              onChange={handleChange}
                              disabled
                              value={getIngreso && getIngreso.nCas_Id}
                            >
                              <option value="0">Seleccionar..</option>
                              {dataCaso.map((row, i) => (
                                <option key={i} value={row.nCas_Id}>
                                  {row.cCas_Titulo}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Caso
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-6">
                            <label
                              htmlFor="example-text-input"
                              style={{ paddingLeft: "10px" }}
                              className="col-md-10 col-form-label"
                            >
                              Código Interno : {getIngreso.cCas_Cod_Interno}
                            </label>
                          </div>
                          <div className="col-md-6">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-10 col-form-label"
                              style={{ paddingLeft: "10px" }}
                            >
                              Código Externo : {getIngreso.cCas_Cod_Externo}
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-4">
                            <select
                              disabled
                              className={getErrores.nCue_Id && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                              name="nCue_Id"
                              onChange={handleChange}
                              value={getIngreso && getIngreso.nCue_Id}
                            >
                              <option value="0">Seleccionar...</option>
                              {dataComboCuenta.map((row, i) => (
                                <option key={i} value={row.nCue_Id}>
                                  {row.Cuentas}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Cuenta
                            </label>
                          </div>
                          <div className="col-md-4">
                            <select
                              disabled
                              className="form-select inputDown"
                              name="cIng_Tab_FormaPago"
                              onChange={handleChange}
                              value={
                                getIngreso && getIngreso.cIng_Tab_FormaPago
                              }
                            >
                              <option value="0">Selecionar...</option>
                              {dataComboMPago.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Método de pago
                            </label>
                          </div>
                          <div className="col-md-4">
                            <input
                              disabled
                              name="cIng_Num_Ope"
                              className="form-control"
                              type="text"
                              placeholder="Ingrese número de operación"
                              onChange={handleChange}
                              value={getIngreso && getIngreso.cIng_Num_Ope}
                            />
                            <label className="col-form-label labelDown">
                              Número de operación
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-4">
                            <select
                              className="form-select inputDown"
                              name="cIng_Tab_Tipo"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={getIngreso && getIngreso.cIng_Tab_Tipo}
                              onFocus={FocusChange}
                            >
                              {dataComboTIngreso.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            {getErrores.cTabCodigo && <label className="col-form-label labelDown labelRed">{getErrores.cTabCodigo}</label> || <label className="col-form-label labelDown">Tipo de ingreso</label>}
                          </div>
                          <div className="col-md-4">
                            <select
                              className="form-select inputDown"
                              name="cIng_Tab_Moneda"
                              onChange={handleChange}
                              value={getIngreso && getIngreso.cIng_Tab_Moneda}
                              onBlur={handleBlur}
                              onFocus={FocusChange}
                            >
                              <option value="0">Seleccionar</option>
                              {dataComboMoneda.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">Moneda</label>{getErrores.cIng_Tab_Moneda && <label className="col-form-label labelDown labelRed">{getErrores.cIng_Tab_Moneda}</label>}
                          </div>
                          <div className="col-md-4">
                            <input
                              name="nIng_Monto"
                              className="form-control"
                              type="text"
                              placeholder="Ingrese el monto"
                              value={getIngreso && getIngreso.nIng_Monto}
                              onChange={handleChange}
                              onBlur={BluerChange}
                              onFocus={FocusChange}
                              style={{ textAlign: "right" }}
                            />
                            <label className="col-form-label labelDown">Monto</label>{getErrores.nIng_Monto && <label className="col-form-label labelDown labelRed">{getErrores.nIng_Monto}</label>}
                          </div>

                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-4">
                            <input
                              name="dIng_Fecha"
                              className="form-control"
                              type="date"
                              value={getIngreso.dIng_Fecha}
                              onChange={handleChange}
                              onFocus={FocusChange}
                            />
                            <label className="col-form-label labelDown">
                              Fecha de abono
                            </label>
                          </div>
                          <div className="col-md-8">
                            <textarea
                              name="cIng_Detalle"
                              className="form-control"
                              type="text"
                              placeholder="Ingrese la descripción"
                              onChange={handleChange}
                              maxLength="400"
                            ></textarea>
                            <label className="col-form-label labelDown">
                              Descripción
                            </label>
                          </div>


                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(3)}
                          className="btn btn-secondary waves-effect"
                          data-dismiss="modal"
                        >
                          Cerrar
                        </button>
                      </div>
                    </Modal>
                    <Modal size="lg" isOpen={modalActualizar}>
                      <div
                        className="modal-header"
                        style={{
                          background:
                            "linear-gradient( 180deg , #E5163A, #AF2240)",
                        }}
                      >
                        <h5
                          style={{ color: "#fff" }}
                          className="modal-title mt-0"
                          id="myModalLabel"
                        >
                          Editar Ingreso
                        </h5>
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(2)}
                          className="close"
                          data-dismiss="modal"
                          style={{ color: "white" }}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="mb-3 row">
                          <div className="col-md-6">
                            <select
                              className="form-select inputDown"
                              name="nCli_Id"
                              onChange={handleChange}
                              disabled
                              value={getIngreso && getIngreso.nCli_Id}
                              onBlur={handleBlur}
                            >
                              <option value="0">Seleccionar...</option>
                              {dataCliente.map((row, i) => (
                                <option key={i} value={row.nCli_Id}>
                                  {row.cCli_NombreCompleto}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Cliente
                            </label>
                          </div>
                          <div className="col-md-6">
                            <select
                              className="form-select inputDown"
                              name="nCas_Id"
                              onChange={handleChange}
                              disabled
                              value={getIngreso && getIngreso.nCas_Id}
                              onBlur={handleBlur}
                            >
                              <option value="0">Seleccionar..</option>
                              {dataCaso.map((row, i) => (
                                <option key={i} value={row.nCas_Id}>
                                  {row.cCas_Titulo}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Caso
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-6">
                            <label
                              htmlFor="example-text-input"
                              style={{ paddingLeft: "10px" }}
                              className="col-md-10 col-form-label"
                            >
                              Código Interno : {getIngreso.cCas_Cod_Interno}
                            </label>
                          </div>
                          <div className="col-md-6">
                            <label
                              htmlFor="example-text-input"
                              className="col-md-10 col-form-label"
                              style={{ paddingLeft: "10px" }}
                            >
                              Código Externo : {getIngreso.cCas_Cod_Externo}
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-4">
                            <select
                              className={getErrores.nCue_Id && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                              name="nCue_Id"
                              onChange={handleChange}
                              value={getIngreso && getIngreso.nCue_Id}
                              onBlur={handleBlur}
                            >
                              <option value="0">Seleccionar...</option>
                              {dataComboCuenta.map((row, i) => (
                                <option key={i} value={row.nCue_Id}>
                                  {row.Cuentas}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">Cuenta</label>{getErrores.nCue_Id && <label className="col-form-label labelDown labelRed">{getErrores.nCue_Id}</label>}
                          </div>
                          <div className="col-md-4">
                            <select
                              className={getErrores.cIng_Tab_FormaPago && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                              name="cIng_Tab_FormaPago"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              onFocus={FocusChange}
                              value={
                                getIngreso && getIngreso.cIng_Tab_FormaPago
                              }

                            >
                              <option value="0">Selecionar...</option>
                              {dataComboMPago.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">Metodo de pago</label>{getErrores.cIng_Tab_FormaPago && <label className="col-form-label labelDown labelRed">{getErrores.cIng_Tab_FormaPago}</label>}
                          </div>
                          <div className="col-md-4">
                            <input
                              name="cIng_Num_Ope"
                              className={getErrores.cIng_Num_Ope && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                              type="text"
                              placeholder="Ingrese el número de la operación"
                              onChange={handleChange}
                              value={getIngreso && getIngreso.cIng_Num_Ope}
                              onBlur={handleBlur}
                              onFocus={FocusChange}
                              maxLength="50"
                            />
                            <label className="col-form-label labelDown">Número de operación</label>{getErrores.cIng_Num_Ope && <label className="col-form-label labelDown labelRed">{getErrores.cIng_Num_Ope}</label>}

                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-4">
                            <select
                              className="form-select inputDown"
                              name="cIng_Tab_Tipo"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={getIngreso && getIngreso.cIng_Tab_Tipo}
                              onFocus={FocusChange}
                            >
                              {dataComboTIngreso.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            {getErrores.cTabCodigo && <label className="col-form-label labelDown labelRed">{getErrores.cTabCodigo}</label> || <label className="col-form-label labelDown">Tipo de ingreso</label>}
                          </div>
                          <div className="col-md-4">
                            <select
                              disabled={blockComboMoneda}
                              className="form-select inputDown"
                              name="cIng_Tab_Moneda"
                              onChange={handleChange}
                              value={getIngreso && getIngreso.cIng_Tab_Moneda}
                              onBlur={handleBlur}
                              onFocus={FocusChange}
                            >
                              <option value="0">Seleccionar</option>
                              {dataComboMoneda.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>

                            <label className="col-form-label labelDown">Moneda</label>{getErrores.cIng_Tab_Moneda && <label className="col-form-label labelDown labelRed">{getErrores.cIng_Tab_Moneda}</label>}
                          </div>
                          <div className="col-md-4">
                            <input
                              name="nIng_Monto"
                              className={getErrores.nIng_Monto && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                              type="text"
                              placeholder="Ingrese el monto"
                              value={getIngreso && getIngreso.nIng_Monto}
                              onChange={handleChange}
                              onBlur={BluerChange}
                              onFocus={FocusChange}
                              style={{ textAlign: "right" }}
                            />
                            <label className="col-form-label labelDown">Monto</label>{getErrores.nIng_Monto && <label className="col-form-label labelDown labelRed">{getErrores.nIng_Monto}</label>}
                          </div>

                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-4">
                            <input
                              name="dIng_Fecha"
                              className="form-control"
                              type="date"
                              value={getIngreso && getIngreso.dIng_Fecha}
                              onChange={handleChange}
                              onFocus={FocusChange}
                            />
                            <label className="col-form-label labelDown">
                              Fecha de abono
                            </label>
                          </div>
                          <div className="col-md-8">
                            <textarea
                              name="cIng_Detalle"
                              className="form-control"
                              type="text"
                              placeholder="Ingrese la descripción"
                              onChange={handleChange}
                              onBlur={BluerChange}
                              onFocus={FocusChange}
                              value={getIngreso && getIngreso.cIng_Detalle}
                              maxLength="400"
                            ></textarea>
                            <label className="col-form-label labelDown">
                              Descripción
                            </label>
                          </div>
                          <div className="mb-3 row">

                            <div className="col-md-4" hidden={disableTpoCambio}>
                              <input
                                className={getErrores.nIng_TCambio && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                                type="text"
                                placeholder="Tipo de cambio"
                                name="nIng_TCambio"
                                value={getIngreso && getIngreso.nIng_TCambio}
                                onChange={handleChange}
                                onBlur={BluerChange}
                                onFocus={FocusChange}
                                style={{ textAlign: "right" }}
                              />
                              <label className="col-form-label labelDown">Tipo de cambio</label>{getErrores.nIng_TCambio && <label className="col-form-label labelDown labelRed">{getErrores.nIng_TCambio}</label>}
                            </div>

                          </div>


                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(2)}
                          className="btn btn-secondary waves-effect"
                          data-dismiss="modal"
                        >
                          Cerrar
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger waves-effect waves-light"
                          onClick={() => FuncionValidarFormulario()}
                        >
                          Guardar
                        </button>
                      </div>
                    </Modal>
                    <Modal size="lg" isOpen={modalFiltro}>
                      <div
                        className="modal-header"
                        style={{
                          background:
                            "linear-gradient( 180deg , #E5163A, #AF2240)",
                        }}
                      >
                        <h5
                          style={{ color: "#fff" }}
                          className="modal-title mt-0"
                          id="myModalLabel"
                        >
                          Seleccione criterio(s) de búsqueda
                        </h5>
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(4)}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <strong>Fechas (rango)</strong>
                        <hr style={{ color: "#000", marginTop: "0px" }} />
                        <div className="mb-3 row">
                          <div className="col-md-1" style={{ width: "20px" }}></div>
                          <div className="col-md-5">
                            <input
                              className="form-control inputDown"
                              name="FechaInicial"
                              type="date"
                              value={getFiltro && getFiltro.FechaInicial}
                              onChange={handleChangeFiltro}
                            />
                            <label className="col-form-label labelDown">
                              Inicio*
                            </label>
                          </div>
                          <div className="col-md-1" style={{ width: "20px" }}></div>
                          <div className="col-md-5">
                            <input
                              className="form-control inputDown"
                              name="FechaFin"
                              type="date"
                              value={getFiltro && getFiltro.FechaFin}
                              onChange={handleChangeFiltro}
                            />
                            <label className="col-form-label labelDown">
                              Fin*
                            </label>
                          </div>
                        </div>
                        <hr style={{ color: "#000", marginTop: "0px" }} />
                        <div className="mb-3 row">
                          <div className="col-md-1" hidden={hiddenCliente} style={{ width: "20px" }}>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheckcolor1"
                              checked={chekedValor1}
                              value={chekedValor1}
                              onChange={handleChangeValor1}
                              style={{ transform: "scale(1.5)", marginTop: "10px" }}
                            />
                          </div>
                          <div className="col-md-5" hidden={hiddenCliente}>
                            <select className="form-select inputDown" disabled={Valor1} name="nCli_Id" id="nCli_Id" onChange={handleChangeFiltro}>
                              <option value="00">Seleccionar...</option>
                              {dataCliente.map((row, i) => (
                                <option key={i} value={row.nCli_Id}>
                                  {row.cCli_NombreCompleto}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Cliente
                            </label>
                          </div>
                          <div className="col-md-1" style={{ width: "20px" }}>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheckcolor1"
                              checked={chekedValor2}
                              value={chekedValor2}
                              onChange={handleChangeValor2}
                              style={{ transform: "scale(1.5)", marginTop: "10px" }}
                            />
                          </div>
                          <div className="col-md-5">
                            <select
                              className="form-select inputDown"
                              id="nCas_Id" name="nCas_Id" disabled={Valor2} onChange={handleChangeFiltro}
                            >
                              <option value="0">Seleccionar...</option>
                              {dataCaso.map((row, i) => (
                                <option key={i} value={row.nCas_Id}>
                                  {row.cCas_Titulo}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Caso
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-1" style={{ width: "20px" }}>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheckcolor1"
                              checked={chekedValor3}
                              value={chekedValor3}
                              onChange={handleChangeValor3}
                              style={{ transform: "scale(1.5)", marginTop: "10px" }}
                            />
                          </div>
                          <div className="col-md-5">
                            <select
                              className="form-select inputDown"
                              id="cIng_Tab_Moneda" name="cIng_Tab_Moneda" disabled={Valor3} onChange={handleChangeFiltro}
                            >
                              <option value="0">Seleccionar</option>
                              {dataComboMoneda.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Tipo de moneda
                            </label>
                          </div>
                          <div className="col-md-1" style={{ width: "20px" }}>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheckcolor1"
                              checked={chekedValor4}
                              value={chekedValor4}
                              onChange={handleChangeValor4}
                              style={{ transform: "scale(1.5)", marginTop: "10px" }}
                            />
                          </div>
                          <div className="col-md-5">
                            <select
                              className="form-select inputDown"
                              id="cIng_Tab_Tipo" name="cIng_Tab_Tipo" disabled={Valor4} onChange={handleChangeFiltro}
                            >
                              <option value="0">Seleccionar...</option>
                              {dataComboTIngreso.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Tipo de ingreso
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(4)}
                          className="btn btn-secondary waves-effect"
                          data-dismiss="modal"
                        >
                          Cerrar
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger waves-effect waves-light"
                          onClick={() => FuncionBuscarIngresos()}
                        >
                          Filtrar
                        </button>
                      </div>
                    </Modal>
                    <div className="col-md-3" style={{ textAlign: 'right', float: 'right', paddingLeft: '0px', paddingTop: '0px' }}>
                      <label style={{ textAlign: 'center', paddingTop: '20px', paddingRight: '56px' }}>Cantidad de ingresos : {data.length}</label>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <div>
                <div className="col-md-12">
                  <MDBDataTable
                    data={badgesData}
                    responsiveXl
                    fixed
                    hover
                    entriesLabel="Mostrar"
                    infoLabel={["Mostrando ", "a", "de", "registros"]}
                    bordered
                    order={["age", "desc"]}
                    noRecordsFoundLabel={"No hay datos"}
                    searchLabel={"Buscar"}
                    noBottomColumns
                    paginationLabel={["Anterior", "Siguiente"]}
                    className="TablaIngreso"
                    id="tableIngreso"
                    theadColor="HeadBlue"
                  />
                </div>

              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Ingresos
