import React, { Component, useState, useEffect } from "react"
import { Alert, Row, Col, CardBody, Card, Container } from "reactstrap"
import MetaTags from "react-meta-tags"
import { Link, useParams } from "react-router-dom"
import Swal from "sweetalert2"
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
// import images
import profile from "../../assets/images/fda9a84e-df37-473f-ad02-1f39be316056.jpg"
import logo from "../../assets/images/Logo.png"
import instance from '../../config'

const jsonwebtoken = require('jsonwebtoken');

var token = null;
const Validacion = (form) => {
    let errors = {};
    let regexLetras = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    let regexCorreo = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    let regexNumero = /^[0-9]+$/;
    if (form.cUsu_Nombres == null || form.cUsu_Nombres == undefined) {
        errors.cUsu_Nombres = "El campo es obligatorio"
    }
    else if (!form.cUsu_Nombres.trim()) {
        errors.cUsu_Nombres = "El campo es obligatorio"
    }
    else if (form.cUsu_Nombres.length > 50) {
        errors.cUsu_Nombres = "El campo solo puede tener 50 caracteres"
    }
    if (form.cUsu_email == null || form.cUsu_email == undefined) {
        errors.cUsu_email = "El campo es obligatorio"
    }
    else if (!form.cUsu_email.trim()) {
        errors.cUsu_email = "El campo es obligatorio"
    }
    else if (!regexCorreo.test(form.cUsu_email.trim())) {
        errors.cUsu_email = "El correo no es valido"
    }
    else if (form.cUsu_email.length > 50) {
        errors.cUsu_email = "El correo solo puede tener 50 caracteres"
    }

    if (form.cUsu_Login == undefined || form.cUsu_Login == null) {
        errors.cUsu_Login = "El campo es obligatorio"
    }
    else if (!form.cUsu_Login.trim()) {
        errors.cUsu_Login = "El campo es obligatorio"
    }
    else if (form.cUsu_Login.length > 50) {
        errors.cUsu_Login = "El campo solo puede tener 50 caracteres"
    }
    if (form.cUsu_Pass == undefined || form.cUsu_Pass == null) {
        errors.cUsu_Pass = "El campo es obligatorio"
    }
    else if (!form.cUsu_Pass.trim()) {
        errors.cUsu_Pass = "El campo es obligatorio"
    }
    else if (!form.cUsu_Pass.length > 15) {
        errors.cUsu_Pass = "El campo solo puede tener 15 caracteres"
    }
    return errors;
}

const RecuperarContraseña = props => {
    const baseUrl = process.env.REACT_APP_API_URL
    var param = useParams();
    const [getVisible1, setVisible1] = useState("")
    const [getVisible2, setVisible2] = useState("none")
    const [getVisible3, setVisible3] = useState("")
    const [getVisible4, setVisible4] = useState("none")
    const [getErrores, setErrores] = useState({});
    const [dataModulos, setDataModulos] = useState([])
    const [getUsuario, setUsuario] = useState({
        nUsu_ID: "",
        nRol_ID: 1,
        cUsu_Nombres: "",
        cUsu_email: "",
        cUsu_Fono: "",
        cUsu_Login: "",
        cUsu_Pass: "",
        bUsu_Activo: "1",
    })
    const handleChange = e => {
        const { name, value } = e.target
        setUsuario(prevState => ({
            ...prevState,
            [name]: value,
        }))
    }
    const handleBlur = e => {
        handleChange(e)
        setErrores(Validacion(getUsuario))

    }
    const FuncionListarModulos = async () => {
        var postData = {
            Prefijo: "ME",
        }
        await instance
            .post("/Maestros/Data", postData)
            .then(response => {
                setDataModulos(response.data)

            })
            .catch(error => {

            })
    }
    var FuncionGetToken = async () => {
        token = param.datos;
        const SECRET_KEY_JWT = "secretkey5353";
        const datos = await jsonwebtoken.verify(token, SECRET_KEY_JWT);

        setUsuario(prevState => ({
            ...prevState,
            cUsu_email: datos.payload.email,
            cUsu_Nombres: datos.payload.nombre,
            cUsu_Fono: datos.payload.telefono,
        }))
        setErrores(Validacion(getUsuario))
    }
    const FuncionAgregarUsuario = async () => {
        if (Object.keys(getErrores).length === 0) {
            var postData = {
                nRol_ID: Number.parseInt(getUsuario.nRol_ID),
                cUsu_Nombres: getUsuario.cUsu_Nombres,
                cUsu_email: getUsuario.cUsu_email,
                cUsu_Fono: getUsuario.cUsu_Fono,
                cUsu_Login: getUsuario.cUsu_Login,
                cUsu_Pass: getUsuario.cUsu_Pass,
                bUsu_Activo: Number.parseInt(getUsuario.bUsu_Activo),
            }

            await instance
                .post("/Usuario/Insertar", postData)
                .then(async response => {
                    token = param.datos;
                    const SECRET_KEY_JWT = "secretkey5353";
                    const datos = await jsonwebtoken.verify(token, SECRET_KEY_JWT);
                    Swal.fire({
                        title: "Mensaje",
                        text: "Usuario registrado exitosamente",
                        icon: "success",
                        confirmButtonText: "Aceptar",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            setTimeout(() => {
                                location.href = 'http://' + datos.payload.cuenta + '.gpslegal.pe/';
                            }, 3000);
                        }
                    })
                })
                .catch(error => {
                    Swal.fire({
                        title: "Mensaje",
                        text: "Usuario ya existe",
                        icon: "error",
                        confirmButtonText: "Aceptar",
                    })
                })
        } else {
            Swal.fire({
                title: "Mensaje",
                text: "Complete los campos requeridos",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        }
    }
    const changeVisibility = async () => {
        var tipo = document.getElementById("pass");

        if (tipo.type == "password") {
            tipo.type = "text";
            setVisible1("none")
            setVisible2("")
        } else {
            tipo.type = "password";
            setVisible1("")
            setVisible2("none")
        }

    }

    const changeVisibilityConfirm = async () => {

        var tipo = document.getElementById("cUsu_Pass");

        if (tipo.type == "password") {
            tipo.type = "text";
            setVisible3("none")
            setVisible4("")
        } else {
            tipo.type = "password";
            setVisible3("")
            setVisible4("none")
        }

    }
    useEffect(() => {
        FuncionGetToken();
    }, [])
    return (
        <React.Fragment>
            <div className="home-btn d-none d-sm-block">
                <MetaTags>
                    <title>Crear cuenta | GpsLegal</title>
                </MetaTags>
                <Link to="/" className="text-dark">
                    <i className="fas fa-home h2" />
                </Link>
            </div>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={3} lg={3} xl={5}>
                            <Card className="overflow-hidden">
                                <CardBody className="pt-0" isOpen={true}>
                                    <div style={{ paddingTop: "10px" }} className="mb-3 row" isOpen={true}>
                                        <Link to="/" className="auth-logo-dark">
                                            <img
                                                src={profile}
                                                alt=""
                                                style={{
                                                    width: "100%",
                                                    height: "auto",
                                                    marginLeft: "auto",
                                                    marginRight: "auto",
                                                    display: "block",
                                                }}
                                            />
                                        </Link>
                                    </div>
                                    <div className="p-4" isOpen={true}>
                                        <AvForm className="mb-3">
                                            <div className="form-group">
                                                <AvField
                                                    name="cUsu_Login"
                                                    id="cUsu_Login"
                                                    label="Ingrese el nuevo login para su cuenta"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    placeholder="Cree un login para su cuenta"
                                                    type="text"
                                                    maxLength="50"
                                                />
                                            </div>

                                            <div className="row mb-12">
                                                <div className="col-md-10" style={{ width: '90%', paddingRight: '0px', marginRight: '0px' }}>
                                                    <div className="form-group" style={{ marginTop: '15px' }}>
                                                        <AvField
                                                            name="password"
                                                            id="pass"
                                                            label="Nueva contraseña"
                                                            className="form-control"
                                                            placeholder="Ingrese la nueva contraseña"
                                                            type="password"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2"
                                                    style={{
                                                        borderRadius: '5px',
                                                        cursor: 'pointer',
                                                        width: '8%',
                                                        height: '30px',
                                                        marginTop: '55px',
                                                        paddingLeft: '6px', marginLeft: '0px'
                                                    }}
                                                    onClick={() => changeVisibility()}
                                                >
                                                    <i className="icon-ver" style={{ display: getVisible1 }}> </i>
                                                    <i className="icon-no-ver" style={{ display: getVisible2 }}> </i>
                                                </div>
                                            </div>
                                            <div className="row mb-12" style={{ marginTop: '15px' }}>
                                                <div className="col-md-10" style={{ width: '90%', paddingRight: '0px', marginRight: '0px' }}>
                                                    <AvField
                                                        name="cUsu_Pass"
                                                        id="cUsu_Pass"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        label="Confirmar contraseña"
                                                        className="form-control"
                                                        placeholder="Confirme su nueva contraseña"
                                                        type="password"
                                                    />
                                                </div>
                                                <div className="col-md-2"
                                                    style={{
                                                        borderRadius: '5px',
                                                        cursor: 'pointer',
                                                        width: '8%',
                                                        height: '30px',
                                                        marginTop: '40px',
                                                        paddingLeft: '6px', marginLeft: '0px'
                                                    }}
                                                    onClick={() => changeVisibilityConfirm()}
                                                >
                                                    <i className="icon-ver" style={{ display: getVisible3 }}> </i>
                                                    <i className="icon-no-ver" style={{ display: getVisible4 }}> </i>
                                                </div>
                                            </div>

                                            <div className="mt-3 d-grid">
                                                <a
                                                    className="btn btn-block waves-effect waves-light"
                                                    style={{
                                                        backgroundColor: "#4b5d81",
                                                        color: "white",
                                                    }}
                                                    href="#"
                                                    onClick={() => FuncionAgregarUsuario()}
                                                >
                                                    Confirmar
                                                </a>
                                            </div>
                                            <div className="mt-4 text-center">
                                                <p>
                                                    Regresar a{" "}
                                                    <Link
                                                        to="/IniciarSesion"
                                                        className="fw-medium text-primary"
                                                    >
                                                        Iniciar Sesión
                                                    </Link>{" "}
                                                </p>
                                                <p>© {new Date().getFullYear()} GPS Legal </p>
                                            </div>
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default RecuperarContraseña
