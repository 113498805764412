import React, { Component, useState, useEffect } from "react"
import {
  CardBody,
  Card,
  Col,
  Container,
  Row,
  Table,
  CardTitle,
} from "reactstrap"
import { MDBDataTable } from "mdbreact"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import instance from '../../../config';
import { getDecryptedItem, setEncryptedItem } from '../../../util';

const baseUrl = process.env.REACT_APP_API_URL
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10

const HistorialNovedades = props => {
  const [dataNov, setDataNov] = useState([])
  const [dataNovF, setDataNovF] = useState([])

  const FuncionListarCasosNov = async () => {
    var postData = {
      nUsu_ID: getDecryptedItem("ID"),
      nNov_Estado: null,
    }

    console.log(postData);
    await instance
      .post("/Dashboard/Casos/Novedades/Listar", postData)
      .then(response => {
        console.log(response.data)
        setDataNov(response.data)
        setDataNovF(response.data)
      })
      .catch(error => {

      })
  }

  const handleChangeFiltroNExpediente = e => {
    if (e.target.value.length > 0) {
      setDataNov(dataNovF.filter(DatosAdd => {
        return DatosAdd.cCas_Cod_Externo.includes(e.target.value) ||
          DatosAdd.cCli_NombreCompleto.toLowerCase().includes(e.target.value.toLowerCase())
      }))
    } else {
      setDataNov(dataNovF)
    }
  }

  const SelectDetalle = async (nCas_Id, nCli_Id, nNov_Id) => {
    setEncryptedItem('nCas_Id', nCas_Id);
    setEncryptedItem('CASOIDCli', nCli_Id);

    CambiarEstadoNovedadMasivo(nCas_Id)    
  }

  const CambiarEstadoNovedadMasivo = async (_nCas_Id) => {

    var postData = {
      nCas_Id: _nCas_Id
    }

    console.log(postData);

    await instance
      .post("/Dashboard/Casos/Novedades/CambiarEstadoMasivo", postData)
      .then(response => {
        console.log(response.data)
      })
      .catch(error => {

      })
  }


  useEffect(() => {
    FuncionListarCasosNov()
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Historial de novedades | GpsLegal</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="GpsLegal"
            breadcrumbItem="Historial de novedades"
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4" style={{ fontSize: "30px" }}>
                    Listado de novedades{" "}
                  </CardTitle>
                  <p className="card-title-desc">Descripción.</p>
                  <div className="mb-3 row">
                    <div className="col-md-10">
                      <input className="form-control" placeholder="🔎" type="text" onChange={handleChangeFiltroNExpediente} />
                    </div>
                    <div className="col-md-2">
                      <b style={{ textAlign: 'center', paddingTop: '35px' }}>Cantidad : {dataNov.length}</b>
                    </div>
                  </div>
                  {dataNov.map((row, i2) => (
                    <Card className="cardBorder " key={i2} >
                      <CardBody>
                        <Link to="/dashboard-Casos" onClick={() => SelectDetalle(row.nCas_Id, row.nCli_Id, row.nNov_Id)}>
                          <div className="row mb-1">
                            <div className="col-md-4 container">
                              <b className="text-truncate font-size-14 mb-1">
                                Proceso
                              </b>
                            </div>
                            <div className="col-md-8 container">
                              <b className="text-truncate font-size-14 mb-1">
                                {row.cCas_Cod_Externo} / {row.cCas_Titulo} <a style={{ float: "right", fontSize: 12 }} >{row.nNov_Fecha2}</a>
                              </b>
                            </div>
                            <hr
                              style={{ color: "#e01646", height: "2px" }}
                            ></hr>
                          </div>
                          <div className="row mb-1">
                            <div className="col-md-4">
                              <p className="mb-0">Juzgado</p>
                            </div>
                            <div className="col-md-8">
                              <p className="mb-0">
                                {row.nJuz_Id2}
                              </p>
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-md-4">
                              <p className="mb-0">Cliente</p>
                            </div>
                            <div className="col-md-8">
                              <p className="mb-0">
                                {row.cCli_NombreCompleto}
                              </p>
                            </div>
                          </div>
                          <div className="row mb-4">
                            {row.cNov_Movimiento?.map((row2, i) => (
                              <>
                                <div className="row mb-1">
                                  <div className="col-md-4 container">
                                    <b className="text-truncate font-size-14 mb-1">
                                      Acto / Resolución
                                    </b>
                                  </div>
                                  <div className="col-md-8 container">
                                    <b className="text-truncate font-size-14 mb-1">
                                      {row2?.Acto} {" / "} {row2?.Resolucion}
                                    </b>
                                  </div>
                                  <hr
                                    style={{ color: "#e01646", height: "2px" }}
                                  ></hr>
                                </div>
                                <div className="row mb-1">
                                  <div className="col-md-4">
                                    <p className="mb-0">Sumilla</p>
                                  </div>
                                  <div className="col-md-8">
                                    <p className="mb-0">
                                      {row2?.Sumilla}
                                    </p>
                                  </div>
                                </div>
                              </>
                            ))}
                          </div>
                          <div className="row mb-4">
                            {row.cNov_Notificacion?.map((row, i) => (
                              <>
                                <div className="row mb-1">
                                  <div className="col-md-4 container">
                                    <b className="text-truncate font-size-14 mb-1">
                                      Titulo / Anexo
                                    </b>
                                  </div>
                                  <div className="col-md-8 container">
                                    <b className="text-truncate font-size-14 mb-1">
                                      {row.titulo} {" / "} {row.anexos}
                                    </b>
                                  </div>
                                  <hr
                                    style={{ color: "#e01646", height: "2px" }}
                                  ></hr>
                                </div>
                                <div className="row mb-1">
                                  <div className="col-md-4">
                                    <p className="mb-0">Fecha envio</p>
                                  </div>
                                  <div className="col-md-8">
                                    <p className="mb-0">
                                      {row.fechaEnvio}
                                    </p>
                                  </div>
                                </div>
                              </>
                            ))}
                          </div>
                        </Link>
                      </CardBody>
                    </Card>
                  ))}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}
export default HistorialNovedades
