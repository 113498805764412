import React, { Component, useState, useEffect } from "react"
import ReactDOM from "react-dom";
import { MDBDataTable } from "mdbreact"
import { getDecryptedItem } from '../../util';

import {
  CardHeader,
  CardText,
  CardBody,
  Card,
  Col,
  Container,
  CardTitle,
  Button,
  Row,
  Modal,
  Title,
  Text,
  FormGroup,
  FormText,
  Label,
  Input,
  CustomInput,
  CheckBox
} from "reactstrap"
import MetaTags from "react-meta-tags"
import ReactHTMLTableToExcel from 'react-html-table-to-excel'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faSearch,
  faTimes,
  faChevronLeft,
  faChevronRight,
  faPaperPlane,
  faBuilding,
  faUser,
  faCalendar,
  faCircle,
  faSync,
  faChessPawn,
  faBalanceScale,
  faGlobe,
  faDownload,
  faEye,
  faTrash,
} from "@fortawesome/free-solid-svg-icons"

import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/tablescss/FormatTable.scss"
//Dependencia await instance para consumir apis
import instance from '../../config'
import './style-ec.css';
import FileDownload from "js-file-download";
//Url Principal
const baseUrl = process.env.REACT_APP_API_URL;
var today = new Date();
var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
const ReporteEstadoCuenta = props => {
  function formatFin() {
    var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  }

  function formatIni() {
    var d = new Date(),
      month = '' + 1,
      day = '' + 1,
      year = d.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  }

  const [dataCaso, setDataCaso] = useState([])
  const [dataMoney, setDataMoney] = useState({ money: "S/" })
  const [dataCliente, setDataCliente] = useState([])
  const [dataCasoInfo, setDataCasoInfo] = useState([])
  const [dataCombo, setDataCombo] = useState([])
  const [dataEstadoCuenta, setDataEstadoCuenta] = useState([])
  const [dataTotal, setDataTotal] = useState([{ Total: "0.00" }])
  const [hiddenBtnEditar, setHiddenBtnEditar] = useState(true)
  const [hiddenBtnEliminar, setHiddenBtnEliminar] = useState(true)
  const [hiddenBtnConsultar, setHiddenBtnConsultar] = useState(true)
  const [dataComboTIngreso, setDataComboTIngreso] = useState([])
  const [hiddenCliente, setHiddenCliente] = useState(false)

  var clienteSelected = ""
  const [dataConsulta, setDataConsulta] = useState({
    inicio: formatIni(),
    fin: formatFin(),
    mon: 'MO01',
    caso: 0,
    cliente: 0,
    tipo: '0'
  })

  const [data, setData] = useState([])
  let ArregloConsultaMasiva = []
  const fListarEstadoCuenta = async () => {
    let _tipo = ""
    if (dataConsulta.tipo == "0") {
      _tipo = null;
    }
    else {
      _tipo = dataConsulta.tipo;
    }
    let _nCas_Id = dataConsulta.caso == "0" ? null : parseInt(dataConsulta.caso);
    var postData = {
      ini: dataConsulta.inicio,
      fin: dataConsulta.fin,
      mon: dataConsulta.mon,
      nCas_Id: _nCas_Id,
      tipo: _tipo,
      nCli_Id: parseInt(dataConsulta.cliente),
    }

    await instance
      .post("/Reporte/Ecuenta", postData)
      .then(response => {
        setDataEstadoCuenta(response.data)
        fRellenarDataList(response.data)
        fListarCuentaTotal()

      })
      .catch(error => {

      })


  }

  const FuncionGenerarPDF = async (_ArrayDatos) => {
    try {
      var postData = {
        mtotal: dataTotal,
        caso: dataConsulta.caso,
        ArrayDatos: data,
      }
      await instance
        .post("/Generar/EstadoCuentaPDF", postData, { responseType: 'blob' })
        .then(response => {
          FileDownload(response.data, "file.pdf")
        })
    } catch (error) {

    }

  }

  const FuncionListarCliente = async () => {
    let RolId = getDecryptedItem("RolID")
    var postData = {
      nUsu_ID: RolId == 6 || RolId == 1 ? null : getDecryptedItem("ID")
    }
    await instance
      .post("/Cliente/Listar", postData)
      .then(response => {
        setDataCliente(response.data)

      })
      .catch(error => {

      })
  }
  function fRellenarDataList(arrayEstadoCuenta) {
    var Saldo = 0;
    var SaldoSg;
    var Resultado = 0;
    ArregloConsultaMasiva = [];
    for (let i of arrayEstadoCuenta) {
      Resultado = i.Egreso - i.Ingreso;
      Saldo = Saldo - Resultado; // Actualiza el saldo antes de calcular SaldoSg

      let locationData = {
        Fecha: i.Fecha,
        mIng: i.mIng,
        mEg: i.mEg,
        Detalle: i.Detalle,
        Ingresos: i.Ingreso,
        Egresos: i.Egreso,
        cTabSimbolo: i.cTabSimbolo,
        Saldo: Saldo, // Usa el saldo actualizado
        tipo: i.cTabNombre
      }
      if (dataConsulta.mon == "MO01") { // Formatea el saldo con 2 decimales
        setDataMoney({ money: "S/" });
      } else { // Formatea el saldo con 2 decimales
        setDataMoney({ money: "U$" });
      }
      ArregloConsultaMasiva.push(locationData);
    }

    setData(ArregloConsultaMasiva)
  }
  const handleChangeBusqueda = e => {
    const { name, value } = e.target
    setDataConsulta(prevState => ({
      ...prevState,
      [name]: value,
    }))
    if (name == "caso") {
      if (value == "00") {
        setDataConsulta(prevState => ({
          ...prevState,
          caso: null,
        }))
      }
    }

  }
  const fListarComboDocumento = async () => {
    var postData = {
      Prefijo: "MO",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataCombo(response.data)

      })
      .catch(error => {

      })
  }
  const fListarCuentaTotal = async () => {
    let _tipo = ""
    if (dataConsulta.tipo == "0") {
      _tipo = null;
    }
    else {
      _tipo = dataConsulta.tipo;
    }
    let _nCas_Id = dataConsulta.caso == "0" ? null : parseInt(dataConsulta.caso);
    var postData = {
      ini: dataConsulta.inicio,
      fin: dataConsulta.fin,
      mon: dataConsulta.mon,
      nCas_Id: _nCas_Id,
      tipo: _tipo,
      nCli_Id: parseInt(dataConsulta.cliente),
    }

    await instance
      .post("/Reporte/Ecuenta/Total", postData)
      .then(response => {
        if (response.data[0].Total == null) {
          let array = [
            { Total: 0.00 }
          ]
          setDataTotal(array)
        }
        else {
          setDataTotal(response.data)


        }
      })
      .catch(error => {

      })
  }
  const FuncionListarPermisos = async () => {
    var postData = {
      nUsu_ID: parseInt(getDecryptedItem("RolID"))
    }
    await instance
      .post("/Usuario/Acceso", postData)
      .then(response => {
        let arrayPermisos = response.data;
        const permiso = arrayPermisos.find(element => element.cUsu_Modulo == "ME13");

        if (permiso.bUsu_Editar == 1) {
          setHiddenBtnEditar(false);
        }
        else {
          setHiddenBtnEditar(true);
        }
        if (permiso.bUsu_Eliminar == 1) {
          setHiddenBtnEliminar(false);
        }
        else {
          setHiddenBtnEliminar(true);
        }
        if (permiso.bUsu_Consultar == 1) {
          setHiddenBtnConsultar(false);
        }
        else {
          setHiddenBtnConsultar(true);
        }

      })
      .catch(error => {

      })
  }
  const handleChangeFiltroCaso = e => {


    clienteSelected = e.target.value;

    if (e.target.value == "0") {
      FuncionListarCasos(null)
    } else {
      FuncionListarCasos(e.target.value)
    }

    setDataConsulta(prevState => ({
      ...prevState,
      cliente: clienteSelected,
    }))

  }
  const FuncionListarCasos = async (_idClient) => {
    if (_idClient == null) {
      setDataCaso([]);
    }
    else {
      var postData = {
        idClient: _idClient,
        nUsu_Id: getDecryptedItem("ID"),
      }

      await instance
        .post("/Casos/ListarCbo", postData)
        .then(response => {
          setDataCaso(response.data)

        })
        .catch(error => {

        })
    }

  }
  const FuncionListarComboTIngreso = async () => {
    var postData = {
      Prefijo: "KP",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataComboTIngreso(response.data)

      })
      .catch(error => {

      })
  }
  const FnExportExcel = async () => {
    var postData = {
      type: "EstadoCuenta",
      data: data
    }

    console.log(postData);
    await instance
      .post("/Generate/Excel", postData, { responseType: 'arraybuffer' })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = 'Egresos.xlsx';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {

      })
  }

  useEffect(() => {
    if (getDecryptedItem("RolID") == 9) {
      setHiddenCliente(true);
      FuncionListarCasos(parseInt(getDecryptedItem("CLIID")))
      setDataConsulta(prevState => ({
        ...prevState,
        cliente: parseInt(getDecryptedItem("CLIID")),
      }))
    }
    FuncionListarComboTIngreso()
    FuncionListarCasos(null)
    FuncionListarPermisos();
    fListarComboDocumento()
    FuncionListarCliente()

  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        {/* add meta title */}
        <MetaTags>
          <title> Estado de cuenta | GpsLegal</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="GpsLegal" breadcrumbItem="Estado de cuenta" />
          <div>


            <div className="">
              <div>
                <div className="grillado-caso" >
                  <div>
                    <div className="combos-cliente">
                      <div hidden={hiddenCliente}>
                        <label className="texto-combex">Cliente</label>
                        <select style={{ textAlignLast: "left" }}
                          className="text-center form-select inputDown texto-combex"
                          name="cliente"
                          onChange={handleChangeFiltroCaso}
                          value={dataConsulta && dataConsulta.cliente}>
                          <option style={{ textAlign: "left" }} value="null">Seleccionar</option>
                          {dataCliente.map((e) =>
                            <option style={{ textAlign: "left" }} className="" value={e.nCli_Id}>
                              {e.cCli_NombreCompleto}
                            </option>
                          )
                          }
                        </select>
                      </div>
                      <div>
                        <label className="texto-combex">Caso</label>
                        <select style={{}}
                          className="form-select inputDown texto-combex"
                          name="caso"
                          onChange={handleChangeBusqueda}
                          value={dataConsulta && dataConsulta.caso}
                        >
                          <option className="texto-combex" value="0">Seleccionar</option>
                          {dataCaso.map((row, i) => (
                            <option className="texto-combex" value={row.nCas_Id}>
                              {row.cCas_Titulo}
                            </option>
                          ))}
                        </select>
                      </div>

                    </div>
                  </div>


                  <div className="options-ec" style={{ padding: '0', paddingTop: '10px' }}>
                    <div>
                      <label className="texto-combex">Fecha Inicial</label>
                      <input style={{}} defaultValue={dataConsulta.inicio}
                        className="form-control texto-combex"
                        type="date"
                        name="inicio"
                        onChange={handleChangeBusqueda}
                      />
                    </div>
                    <div>
                      <label className="texto-combex">Fecha Final</label>
                      <input n style={{}}
                        defaultValue={dataConsulta.fin}
                        className="form-control texto-combex"
                        type="date"
                        name="fin"
                        onChange={handleChangeBusqueda}
                      />
                    </div>
                    <div>
                      <label className="texto-combex">Ver Monto en</label>
                      <select style={{}}
                        className="form-select inputDown texto-combex"
                        name="mon"
                        onChange={handleChangeBusqueda}
                      >
                        {dataCombo.slice(0).reverse().map((e) =>
                          <option className="texto-combex" value={e.cTabCodigo}>
                            {e.cTabNombre}
                          </option>
                        )
                        }
                      </select>
                    </div>
                    {/*CASO */}


                    {/*CLIENTE*/}



                    <div className="botones-actions" style={{ marginTop: '2.1em' }}>
                      <div>
                        <button hidden={hiddenBtnConsultar} type="button" className="btn btn-danger" onClick={() => fListarEstadoCuenta()}>{" "}<i className="fas fa-search"></i> </button>
                      </div>
                      <div>
                        <button type="button" className="btn btn-danger" onClick={() => FuncionGenerarPDF()}>{" "}PDF </button>
                      </div>
                      <div style={{}}>

                        {/*<button type="button" class="btn btn-success" onClick={() => fListarEstadoCuenta()}>{" "} <i class="icon-excel"></i> </button>*/}
                      </div>
                    </div>


                    <div style={{ marginTop: '0px', width: '100px !important' }}>

                      <div className="col-md-4">
                        <label className="texto-combex">Tipo de ingreso</label>
                        <select
                          className="form-select inputDown"
                          name="tipo"
                          onChange={handleChangeBusqueda}
                        >
                          <option value="0">Seleccionar</option>
                          {dataComboTIngreso.map((row, i) => (
                            <option key={i} value={row.cTabCodigo}>
                              {row.cTabNombre}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="total" style={{}}>
                        <label>Saldo total:</label>
                        <br></br>
                        <b className="saldo-t">{dataTotal.map((e) =>
                          <b>{e.Total}</b>
                        )}</b>
                      </div>


                    </div>

                    <button
                      type="button"
                      onClick={() => FnExportExcel()}
                      className="btn btn-success waves-effect waves-light"
                      data-toggle="modal"
                      data-target="#myModal"
                      style={{ width: '150px' }}
                    >
                      <i style={{ fontSize: "15px" }} class="icon-excel"></i>&nbsp;
                      Excel
                    </button>{" "}
                  </div>
                </div>
              </div>



              <div className="">

              </div>

              <br></br>

              <div className="table-wrapper-scroll-y my-custom-scrollbar">
                <table id="datatable" className="table table-bordered TablaHorasLaboradas ">
                  <thead >
                    <tr style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#032D5E", color: "white", border: "1px solid #dfd2d2" }}>
                      <th style={{ fontWeight: '100' }} className="alignCenter texto-combex space-text">Fecha</th>
                      <th style={{ fontWeight: '100' }} className="alignCenter texto-combex space-text">Descripción</th>
                      <th style={{ fontWeight: '100' }} className="alignCenter texto-combex space-text">Tipo</th>
                      <th style={{ fontWeight: '100' }} className="alignCenter texto-combex space-text">Ingreso</th>
                      <th style={{ fontWeight: '100' }} className="alignCenter texto-combex space-text">Egreso  </th>
                      <th style={{ fontWeight: '100' }} className="alignCenter texto-combex space-text">Saldo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.map((e) =>
                        <tr>
                          <td className="texto-combex" style={{ textAlign: "center", paddingTop: "5px", paddingBottom: "5px" }}>{e.Fecha}</td>
                          <td className="texto-combex" style={{ textAlign: "left", paddingTop: "5px", paddingBottom: "5px" }}>{e.Detalle}</td>
                          <td className="texto-combex" style={{ textAlign: "left", paddingTop: "5px", paddingBottom: "5px" }}>{e.tipo}</td>
                          <td className="texto-combex" style={{ textAlign: "right", paddingTop: "5px", paddingBottom: "5px" }}>{e.mIng}</td>
                          <td className="texto-combex" style={{ textAlign: "right", paddingTop: "5px", paddingBottom: "5px" }}>{e.mEg}</td>
                          <td className="texto-combex" style={{ textAlign: "right", paddingTop: "5px", paddingBottom: "5px" }}>{e.cTabSimbolo + " " + e.Saldo.toFixed(2)}</td>
                        </tr>)}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default ReporteEstadoCuenta