import React, { useReducer } from "react";
import instance from '../../../../config';
import ComboContext from "./ComboContext";
import ComboReducer from "./ComboReducer";
import { getDecryptedItem } from '../../../../util';
import {
    GET_COMBO_CLIENTE, GET_COMBO_CASO,
    GET_COMBO_TDOCUMENTO, GET_COMBO_DEPARTAMENTO,
    GET_COMBO_PROVINCIA, GET_COMBO_DISTRITO,
    GET_COMBO_ESTADO, GET_COMBO_CATEVENTO, GET_COMBO_GRUPO,
    GET_COMBO_TIPEVENTO, GET_COMBO_RECORDAR,
    GET_COMBO_CATTAREA,
    GET_COMBO_JURISDICCION,
    GET_COMBO_JUZGADO,
    GET_COMBO_MATERIA,
    GET_COMBO_SUBMATERIA,
    GET_COMBO_ESTADOINTERNO,
    GET_COMBO_ESTADOEXTERNO,
    GET_COMBO_USUARIO,
    GET_COMBO_EQUIPO,
    GET_COMBO_MONEDA,
    GET_COMBO_LUGARTRAMITE,
    GET_COMBO_AREA,
    GET_COMBO_TEXPEDIENTE,
    GET_COMBO_PARTICIPANTESEVENTO,
    GET_COMBO_METODOPAGO,
    //GET_COMBO_MONEDA, -->Se encuentra registrado lineas arriba
    GET_COMBO_TINGRESO,
    GET_COMBO_CUENTA,
    GET_COMBO_TEGRESO,
    GET_COMBO_TIPOTAREA,
    GET_COMBO_RECORDARTAREA,
    GET_COMBO_RECORDAREVENTO,
    GET_COMBO_TCORREO,
    GET_COMBO_THORASLABORADAS,
    GET_COMBO_ABOGADORESPONSABLE,
    GET_COMBO_ABOGADO,
    GET_COMBO_PRACTICANTE,
    GET_COMBO_USUARIOADICIONAL,
    GET_CLIENTEULT
} from "../Type";
const baseUrl = process.env.REACT_APP_API_URL
const ComboState = (props) => {
    const initialState = {
        clientes: [],
        casos: [],
        tdocumentos: [],
        departamentos: [],
        provincias: [],
        distritos: [],
        categoriasEvento: [],
        categoriasTarea: [],
        tiposEvento: [],
        recordar: [],
        estados: [],
        jurisdiccion: [],
        juzgado: [],
        materia: [],
        submateria: [],
        estadointerno: [],
        estadoexterno: [],
        cusuario: [],
        cequipo: [],
        moneda: [],
        lugartramite: [],
        area: [],
        texpediente: [],
        participantesevento: [],
        metodopago: [],
        //moneda:[], -->Se encuentra registrado lineas arriba
        tingreso: [],
        cuenta: [],
        tegreso: [],
        tipoTarea: [],
        recordarTarea: [],
        recordarEvento: [],
        tCorreo: [],
        thLaboradas: [],
        abogadoresponsable: [],
        abogado: [],
        practicante: [],
        usuarioadicional: [],
        clientesult: [],
        idClienteUlt: 0,
        grupo: []
    };
    const [state, dispatch] = useReducer(ComboReducer, initialState);
    const FuncionListarCliente = async (_bCli_Tipo) => {
        try {
            let RolId = getDecryptedItem("RolID")

            var postData = {
                bCli_Tipo: _bCli_Tipo,
                nUsu_ID: RolId == 6 || RolId == 1 ? null : getDecryptedItem("ID")
            }
            await instance
                .post("/Cliente/ListarCbo", postData)
                .then(response => {
                    dispatch({ type: GET_COMBO_CLIENTE, payload: response.data, id: response.data[0].value });
                })

        } catch (error) {
            console.error(error);
        }
    };
    const FuncionListarCliente2 = async (_bCli_Tipo, setCliente, setCasos) => {
        try {
            let RolId = getDecryptedItem("RolID")

            var postData = {
                bCli_Tipo: _bCli_Tipo,
                nUsu_ID: RolId == 6 || RolId == 1 ? null : getDecryptedItem("ID")
            }
            await instance
                .post("/Cliente/ListarCbo", postData)
                .then(response => {
                    FuncionListarClienteUltimo(setCliente, setCasos)
                    dispatch({ type: GET_COMBO_CLIENTE, payload: response.data });
                })

        } catch (error) {
            console.error(error);
        }
    };
    const FuncionResetClienteUltimo = async () => {
        dispatch({ type: GET_CLIENTEULT, payload: [] });
    };
    const FuncionListarClienteUltimo = async (setCliente, setCasos) => {
        try {
            await instance
                .post("/Cliente/UltimoRegistrado")
                .then(response => {
                    setCliente(response.data[0])
                    setCasos(prevState => ({
                        ...prevState,
                        nCli_Id: response.data[0].value,
                    }))
                })
                .catch(error => {

                })
        } catch (error) {
            console.error(error);
        }
    };
    const FuncionListarCaso = async (_Idcliente) => {
        var postData = {
            idClient: _Idcliente,
            nUsu_Id: getDecryptedItem("ID"),
        }
        try {
            await instance
                .post("/Casos/ListarCbo", postData)
                .then(response => {
                    dispatch({ type: GET_COMBO_CASO, payload: response.data });
                })

        } catch (error) {
            console.error(error);
        }
    };
    const FuncionListarTDocumento = async () => {
        try {
            var postData = {
                Prefijo: "DO",
            }
            await instance
                .post("/Maestros/Data", postData)
                .then(response => {
                    dispatch({ type: GET_COMBO_TDOCUMENTO, payload: response.data });
                })

        } catch (error) {
            console.error(error);
        }
    };
    const FuncionListarCatEvento = async () => {
        try {
            var postData = {
                Prefijo: "CE",
            }
            await instance
                .post("/Maestros/Data", postData)
                .then(response => {
                    dispatch({ type: GET_COMBO_CATEVENTO, payload: response.data });
                })

        } catch (error) {
            console.error(error);
        }
    };
    const FuncionListarGrupo = async () => {
        try {
            var postData = {
                Prefijo: "ZA",
            }
            await instance
                .post("/Maestros/Data", postData)
                .then(response => {
                    dispatch({ type: GET_COMBO_GRUPO, payload: response.data });
                })

        } catch (error) {
            console.error(error);
        }
    };
    const FuncionListarCatTarea = async () => {
        try {
            var postData = {
                Prefijo: "CT",
            }
            await instance
                .post("/Maestros/Data", postData)
                .then(response => {
                    dispatch({ type: GET_COMBO_CATTAREA, payload: response.data });
                })

        } catch (error) {
            console.error(error);
        }
    };
    const FuncionListarTipEvento = async () => {
        try {
            var postData = {
                Prefijo: "TE",
            }
            await instance
                .post("/Maestros/Data", postData)
                .then(response => {
                    dispatch({ type: GET_COMBO_TIPEVENTO, payload: response.data });
                })

        } catch (error) {
            console.error(error);
        }
    };
    const FuncionListarRecordar = async () => {
        try {
            var postData = {
                Prefijo: "PE",
            }
            await instance
                .post("/Maestros/Data", postData)
                .then(response => {
                    dispatch({ type: GET_COMBO_RECORDAR, payload: response.data });
                })

        } catch (error) {
            console.error(error);
        }
    };
    const FuncionListarEstado = async () => {
        try {
            var postData = {
                Prefijo: "TT",
            }
            await instance
                .post("/Maestros/Data", postData)
                .then(response => {
                    dispatch({ type: GET_COMBO_ESTADO, payload: response.data });
                })

        } catch (error) {
            console.error(error);
        }
    };
    const FuncionListarJurisdiccion = async () => {
        try {
            var postData = {
                Prefijo: "JU",
            }
            await instance
                .post("/Maestros/Data", postData)
                .then(response => {
                    dispatch({ type: GET_COMBO_JURISDICCION, payload: response.data });
                })

        } catch (error) {
            console.error(error);
        }
    };
    const FuncionListarJuzgado = async () => {
        try {
            var postData = {
                Prefijo: "GA",
            }
            await instance
                .post("/Maestros/Data", postData)
                .then(response => {
                    dispatch({ type: GET_COMBO_JUZGADO, payload: response.data });
                })

        } catch (error) {
            console.error(error);
        }
    };
    const FuncionListarMateria = async () => {
        try {
            var postData = {
                Prefijo: "MA",
            }
            await instance
                .post("/Maestros/Data", postData)
                .then(response => {
                    dispatch({ type: GET_COMBO_MATERIA, payload: response.data });
                })

        } catch (error) {
            console.error(error);
        }
    };
    const FuncionListarSubMateria = async () => {
        try {
            var postData = {
                Prefijo: "MC",
            }
            await instance
                .post("/Maestros/Data", postData)
                .then(response => {
                    dispatch({ type: GET_COMBO_SUBMATERIA, payload: response.data });
                })

        } catch (error) {
            console.error(error);
        }
    };
    const FuncionListarEstadoInterno = async () => {
        try {
            var postData = {
                Prefijo: "EP",
            }
            await instance
                .post("/Maestros/Data", postData)
                .then(response => {
                    dispatch({ type: GET_COMBO_ESTADOINTERNO, payload: response.data });
                })

        } catch (error) {
            console.error(error);
        }
    };
    const FuncionListarEstadoExterno = async () => {
        try {
            var postData = {
                Prefijo: "EG",
            }
            await instance
                .post("/Maestros/Data", postData)
                .then(response => {
                    dispatch({ type: GET_COMBO_ESTADOEXTERNO, payload: response.data });
                })

        } catch (error) {
            console.error(error);
        }
    };
    const FuncionListarUsuario = async () => {
        try {
            await instance
                .get("/Usuario/Listar")
                .then(response => {

                    dispatch({ type: GET_COMBO_USUARIO, payload: response.data });
                })

        } catch (error) {
            console.error(error);
        }
    };
    const FuncionListarEquipo = async () => {
        try {
            await instance
                .get("/Equipo/Listar")
                .then(response => {
                    dispatch({ type: GET_COMBO_EQUIPO, payload: response.data });
                })
        } catch (error) {
            console.error(error);
        }
    };
    const FuncionListarMoneda = async () => {
        try {
            var postData = {
                Prefijo: "MO",
            }
            await instance
                .post("/Maestros/Data", postData)
                .then(response => {
                    dispatch({ type: GET_COMBO_MONEDA, payload: response.data });
                })

        } catch (error) {
            console.error(error);
        }
    };
    const FuncionListarLugarTramite = async () => {
        try {
            var postData = {
                Prefijo: "SI",
            }
            await instance
                .post("/Maestros/Data", postData)
                .then(response => {
                    dispatch({ type: GET_COMBO_LUGARTRAMITE, payload: response.data });
                })

        } catch (error) {
            console.error(error);
        }
    };
    const FuncionListarArea = async () => {
        try {
            var postData = {
                Prefijo: "CC",
            }
            await instance
                .post("/Maestros/Data", postData)
                .then(response => {
                    dispatch({ type: GET_COMBO_AREA, payload: response.data });
                })

        } catch (error) {
            console.error(error);
        }
    };
    const FuncionListarTipoExpediente = async () => {
        try {
            var postData = {
                Prefijo: "CX",
            }
            await instance
                .post("/Maestros/Data", postData)
                .then(response => {
                    dispatch({ type: GET_COMBO_TEXPEDIENTE, payload: response.data });
                })

        } catch (error) {
            console.error(error);
        }
    };
    const FuncionBuscarParticipantesEvento = async (_nEve_Id) => {
        try {
            var postData = {
                nEve_Id: _nEve_Id,
            }
            await instance
                .post("/Evento/Buscar/Participantes", postData)
                .then(response => {
                    dispatch({ type: GET_COMBO_PARTICIPANTESEVENTO, payload: response.data });

                })

        } catch (error) {
            console.error(error);
        }
    };
    const FuncionListarMetodoPago = async () => {
        try {
            var postData = {
                Prefijo: "FP",
            }
            await instance
                .post("/Maestros/Data", postData)
                .then(response => {
                    dispatch({ type: GET_COMBO_METODOPAGO, payload: response.data });
                })
        } catch (error) {
            console.error(error);
        }
    }
    const FuncionListarTIngreso = async () => {
        try {
            var postData = {
                Prefijo: "KP",
            }
            await instance
                .post("/Maestros/Data", postData)
                .then(response => {
                    dispatch({ type: GET_COMBO_TINGRESO, payload: response.data });
                })
                .catch(error => {

                })
        } catch (error) {

        }
    }
    const FuncionListarCuenta = async () => {
        try {
            await instance
                .get("/CBanco/Listar/Combo")
                .then(response => {
                    dispatch({ type: GET_COMBO_CUENTA, payload: response.data });
                })
        } catch (error) {

        }
    }
    const FuncionListarTEgreso = async () => {
        try {
            var postData = {
                Prefijo: "NA",
            }
            await instance
                .post("/Maestros/Data", postData)
                .then(response => {
                    dispatch({ type: GET_COMBO_TEGRESO, payload: response.data });
                })

        } catch (error) {

        }
    }
    const FuncionListarTipoTarea = async () => {
        try {
            var postData = {
                Prefijo: "TT",
            }
            await instance
                .post("/Maestros/Data", postData)
                .then(response => {
                    dispatch({ type: GET_COMBO_TIPOTAREA, payload: response.data });
                })
        } catch (error) {

        }
    }
    const FuncionListarCategoriaTarea = async () => {
        try {
            var postData = {
                Prefijo: "CT",
            }
            await instance
                .post("/Maestros/Data", postData)
                .then(response => {
                    dispatch({ type: GET_COMBO_CATTAREA, payload: response.data });
                })
        } catch (error) {

        }
    }
    const FuncionListarRecordarTarea = async () => {
        try {
            var postData = {
                Prefijo: "PE",
            }
            await instance
                .post("/Maestros/Data", postData)
                .then(response => {
                    dispatch({ type: GET_COMBO_RECORDARTAREA, payload: response.data });
                })
        } catch (error) {

        }
    }
    const FuncionListarTipoEvento = async () => {
        try {
            var postData = {
                Prefijo: "TE",
            }
            await instance
                .post("/Maestros/Data", postData)
                .then(response => {
                    dispatch({ type: GET_COMBO_TIPEVENTO, payload: response.data });
                })
        } catch (error) {

        }
    }
    const FuncionListarCategoriaEvento = async () => {
        try {
            var postData = {
                Prefijo: "CE",
            }
            await instance
                .post("/Maestros/Data", postData)
                .then(response => {
                    dispatch({ type: GET_COMBO_CATEVENTO, payload: response.data });
                })
        } catch (error) {

        }
    }
    const FuncionListarRecordarEvento = async () => {
        try {
            var postData = {
                Prefijo: "PE",
            }
            await instance
                .post("/Maestros/Data", postData)
                .then(response => {
                    dispatch({ type: GET_COMBO_RECORDAREVENTO, payload: response.data });
                })
        } catch (error) {

        }
    }
    const FuncionListarDocumento = async () => {
        try {
            var postData = {
                Prefijo: "DO",
            }
            await instance
                .post("/Maestros/Data", postData)
                .then(response => {
                    dispatch({ type: GET_COMBO_TDOCUMENTO, payload: response.data });
                })

        } catch (error) {

        }
    }
    const FuncionListarTipoCorreo = async () => {
        try {
            var postData = {
                Prefijo: "CR",
            }
            await instance
                .post("/Maestros/Data", postData)
                .then(response => {
                    dispatch({ type: GET_COMBO_TCORREO, payload: response.data });
                })
        } catch (error) {

        }
    }
    const FuncionListarTipoHL = async () => {
        try {
            var postData = {
                Prefijo: "XA",
            }
            await instance
                .post("/Maestros/Data", postData)
                .then(response => {
                    dispatch({ type: GET_COMBO_THORASLABORADAS, payload: response.data });
                })
        } catch (error) {

        }
    }
    const FuncionListarEquipoAbogadoResponsable = async (_nCas_Id) => {
        try {
            var postData = {
                nCas_Id: _nCas_Id,
                Tipo: "UC01",
            }
            await instance
                .post("/Casos/Equipo", postData)
                .then(response => {
                    dispatch({ type: GET_COMBO_ABOGADORESPONSABLE, payload: response.data });
                })
        } catch (error) {

        }
    }
    const FuncionListarEquipoAbogado = async (_nCas_Id) => {
        try {
            var postData = {
                nCas_Id: _nCas_Id,
                Tipo: "UC02",
            }
            await instance
                .post("/Casos/Equipo", postData)
                .then(response => {
                    dispatch({ type: GET_COMBO_ABOGADO, payload: response.data });
                })
        } catch (error) {

        }
    }
    const FuncionListarEquipoPracticante = async (_nCas_Id) => {
        try {
            var postData = {
                nCas_Id: _nCas_Id,
                Tipo: "UC03",
            }
            await instance
                .post("/Casos/Equipo", postData)
                .then(response => {
                    dispatch({ type: GET_COMBO_PRACTICANTE, payload: response.data });
                })
        } catch (error) {

        }
    }
    const FuncionListarEquipoUsuarioAdd = async (_nCas_Id) => {
        try {
            var postData = {
                nCas_Id: _nCas_Id,
                Tipo: "UC04",
            }
            await instance
                .post("/Casos/Equipo", postData)
                .then(response => {
                    dispatch({ type: GET_COMBO_USUARIOADICIONAL, payload: response.data });
                })
        } catch (error) {

        }
    }
    return (
        <ComboContext.Provider
            value={{
                clientes: state.clientes,
                casos: state.casos,
                tdocumentos: state.tdocumentos,
                departamentos: state.departamentos,
                provincias: state.provincias,
                distritos: state.distritos,
                categoriasEvento: state.categoriasEvento,
                categoriasTarea: state.categoriasTarea,
                tiposEvento: state.tiposEvento,
                recordar: state.recordar,
                estados: state.estados,
                jurisdiccion: state.jurisdiccion,
                juzgado: state.juzgado,
                materia: state.materia,
                submateria: state.submateria,
                estadointerno: state.estadointerno,
                estadoexterno: state.estadoexterno,
                cusuario: state.cusuario,
                cequipo: state.cequipo,
                moneda: state.moneda,
                lugartramite: state.lugartramite,
                area: state.area,
                texpediente: state.texpediente,
                participantesevento: state.participantesevento,
                metodopago: state.metodopago,
                tingreso: state.tingreso,
                cuenta: state.cuenta,
                tegreso: state.tegreso,
                tipoTarea: state.tipoTarea,
                recordarTarea: state.recordarTarea,
                recordarEvento: state.recordarEvento,
                tipoCorreo: state.tipoCorreo,
                thLaboradas: state.thLaboradas,
                abogadoresponsable: state.abogadoresponsable,
                abogado: state.abogado,
                practicante: state.practicante,
                usuarioadicional: state.usuarioadicional,
                clientesult: state.clientesult,
                idClienteUlt: state.idClienteUlt,
                grupo: state.grupo,
                FuncionListarCliente,
                FuncionListarCliente2,
                FuncionListarCaso,
                FuncionListarTDocumento,
                FuncionListarCatEvento,
                FuncionListarCatTarea,
                FuncionListarTipEvento,
                FuncionListarRecordar,
                FuncionListarEstado,
                FuncionListarJurisdiccion,
                FuncionListarJuzgado,
                FuncionListarMateria,
                FuncionListarSubMateria,
                FuncionListarEstadoInterno,
                FuncionListarEstadoExterno,
                FuncionListarUsuario,
                FuncionListarEquipo,
                FuncionListarMoneda,
                FuncionListarLugarTramite,
                FuncionListarArea,
                FuncionListarTipoExpediente,
                FuncionBuscarParticipantesEvento,
                FuncionListarMetodoPago,
                FuncionListarTIngreso,
                FuncionListarCuenta,
                FuncionListarTEgreso,
                FuncionListarTipoTarea,
                FuncionListarCategoriaTarea,
                FuncionListarRecordarTarea,
                FuncionListarTipoEvento,
                FuncionListarCategoriaEvento,
                FuncionListarRecordarEvento,
                FuncionListarDocumento,
                FuncionListarTipoCorreo,
                FuncionListarTipoHL,
                FuncionListarEquipoAbogado,
                FuncionListarEquipoAbogadoResponsable,
                FuncionListarEquipoPracticante,
                FuncionListarEquipoUsuarioAdd,
                FuncionResetClienteUltimo,
                FuncionListarGrupo
            }}
        >
            {props.children}
        </ComboContext.Provider>
    );
}
export default ComboState;