import React, { Component, useState, useEffect } from "react"
import Select from "react-select"
import Swal from "sweetalert2"
import { getDecryptedItem } from '../../util'
import {
    CardBody,
    Card,
    Col,
    Container,
    CardTitle,
    Button,
    Row,
    Modal,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
} from "reactstrap"
import instance from '../../config'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faPlus,
    faSearch,
    faTimes,
    faChevronLeft,
    faChevronRight,
    faPaperPlane,
    faBuilding,
    faUser,
    faCalendar,
    faCircle,
    faClipboard,
    faBell,
} from "@fortawesome/free-solid-svg-icons"
const baseUrl = process.env.REACT_APP_API_URL
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10

var clienteSelected = ""
var ttlCaso = "";
var horaInicio = Fecha.getHours() + ':' + Fecha.getMinutes();
var horaFin = Fecha.getHours() + 1 + ':' + '00';
const Validacion = (form) => {
    let errors = {};
    let regexLetras = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    let regexCorreo = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    let regexNumero = /^[0-9]+$/;
    if (form.nCas_Id == 0) {
        errors.nCas_Id = "(*)"
    }
    if (form.cTar_Tab_Categoria == 0) {
        errors.cTar_Tab_Categoria = "(*)"
    }
    if (!form.cTar_Titulo.trim()) {
        errors.cTar_Titulo = "(*)"
    }
    if (form.cTar_tab_recordar == 0) {
        errors.cTar_tab_recordar = "(*)"
    }
    if (form.cTar_Tab_Tipo == 0) {
        errors.cTar_Tab_Tipo = "(*)"
    }
    return errors;
}

export const ModalFlujo = ({ show, onCloseClick, ReloadLista, Titulo, arrayParticipantes }) => {
    const [modalInsertar, setModalInsertar] = useState(false)
    const [modalActualizar, setModalActualizar] = useState(false)
    const [modalFiltro, setModalFiltro] = useState(false)
    const [dataParticipantes, setDataParticipantes] = useState([])
    const [dataSelect, setDataSelect] = useState([])
    const [dataTipoTarea, setDataTipoTarea] = useState([])
    const [dataEstadoTarea, setDataEstadoTarea] = useState([])
    const [dataCategoriaTarea, setDataCategoriaTarea] = useState([])
    const [dataRecordarTarea, setDataRecordarTarea] = useState([])
    const [dataCliente, setDataCliente] = useState([])
    const [dataCaso, setDataCaso] = useState([])
    const [dataComboUsuario, setDataComboUsuario] = useState([])
    const [disable, setDisable] = useState(false)
    const [dataComboFlujo, setDataComboFlujo] = useState([])
    const [getErrores, setErrores] = useState({});
    const [dataCodInterno, setdataCodInterno] = useState("")
    const [dataCodExterno, setdataCodExterno] = useState("")
    const [GetParticipantes, setParticipantes] = useState([])
    const [blockBtn, setBlockBtn] = useState(false)
    const [dataFases, setDataFases] = useState([])
    const [dataLabelFlujo, setDataLabelFlujo] = useState("")
    const [getFlujo, setFlujo] = useState({
        nfFlu_Id: "1",
        cfFlu_Nombre: "...",
        cfFlu_Descripcion: "Seleccione un flujo",
    })
    const [getFlujoCaso, setFlujoCaso] = useState({
        nfFlu_Id: "",
        nCas_Id: "",
        nfFas_Id: "",
        dfCaf_FecInicio: ano + "-" + mes + "-" + dia,
    })


    const FuncionAgregarParticipantes = async (_nTar_Id, _nUsu_ID) => {
        var postData = {
            nTar_Id: Number.parseInt(_nTar_Id),
            nUsu_ID: Number.parseInt(_nUsu_ID),
        }
        await instance
            .post("/Tarea/Insertar/Participante", postData)
            .then(response => {
            })
            .catch(error => {

            })
    }
    const FuncionInformacionCaso = async (_nCas_Id) => {
        var postData = {
            nCas_Id: _nCas_Id,
        }
        await instance
            .post("/Casos/Buscar", postData)
            .then(response => {
                ttlCaso = response.data[0]["cCas_Titulo"];


                setdataCodInterno(response.data[0].cCas_Cod_Interno)
                setdataCodExterno(response.data[0].cCas_Cod_Externo)
            })
            .catch(error => {

            })
    }
    const FuncionListarComboUsuario = async (nCas_Id) => {
        var postData = {
            nCas_Id: nCas_Id,
        }
        await instance
            .post("/Casos/FiltroUsuarioCaso", postData)
            .then(response => {

                setDataComboUsuario(response.data)
            })
            .catch(error => {

            })
    }
    const handleChange = e => {
        const { name, value } = e.target
        setFlujoCaso(prevState => ({
            ...prevState,
            [name]: value,
        }))
        if (name == "nfFlu_Id") {
            if (value != "0") {
                const found = dataComboFlujo.find(element => element.nfFlu_Id == value);
                setFlujo(found)

                FuncionListarFases(value)
            }
            else {
                setFlujo(prevState => ({
                    ...prevState,
                    nfFlu_Id: "",
                    cfFlu_Nombre: "...",
                    cfFlu_Descripcion: "Seleccione un flujo",
                }))
                setDataFases([]);
            }
        }
    }
    const FuncionListarFlujos = async () => {
        await instance
            .post("/Flujo/Listar")
            .then(response => {
                setDataComboFlujo(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionListarFases = async (_nfFlu_Id) => {
        var postData = {
            nfFlu_Id: _nfFlu_Id
        }

        await instance
            .post("/Fase/Listar", postData)
            .then(response => {
                setDataFases(response.data)

            })
            .catch(error => {

            })
    }
    function convertStringSplit(arr) {
        let arrConvert = "";
        arr.map(async (row, i) => {
            if (arrConvert.length == 0) {
                arrConvert = arrConvert.concat(arr[i])
            } else {
                arrConvert = arrConvert.concat(',', arr[i])
            }
        })
        return arrConvert;
    }
    const FuncionListarTarea = async (_nfFas_Id) => {
        var postData = {
            nfFas_Id: parseInt(_nfFas_Id)
        }
        await instance
            .post("/flujoTarea/Listar", postData)
            .then(async response => {
                let result = response.data;
                let Condition = []

                result.map(async (row, i) => {
                    let RolesFlujo = await ListarRolesFlujoTarea(row.nfTar_Id)
                    let resultRolFlujo = RolesFlujo;
                    resultRolFlujo.res.map(async (row) => {
                        Condition.push(row.value)
                    })
                    let param = convertStringSplit(Condition)
                    let ParticipantesCaso = await ListarParticipanteRoles(parseInt(getDecryptedItem("nCas_Id")), param)
                    let ResultPartCaso = ParticipantesCaso.res
                    if (ResultPartCaso.length == 0) {
                        onCloseClick()
                        Swal.fire({
                            title: "Mensaje",
                            text: "Los roles del caso no coinciden con los roles del flujo",
                            icon: "error",
                            confirmButtonText: "Aceptar",
                        })
                        return
                    }

                    let FetchListaFlujo = await ListarListaTarea(row.nfTar_Id)
                    let date = new Date(getFlujoCaso.dfCaf_FecInicio)
                    date.setDate(date.getDate() + row.nfTar_Duracion);
                    date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
                    let Fetchres1 = await InsertTareaSaas(row, date)

                    FetchListaFlujo.res.map(async (row) => {
                        let FetchListaFlujoInsert = await InsertListaSaas(Fetchres1.res.nTar_Id, row)

                    })
                    ResultPartCaso.map(async (row) => {
                        let Fetchres2 = await InsertTareaPartSaas(Fetchres1.res.nTar_Id, row.value)

                    })
                    ResultPartCaso = []
                })
                let postData2 = {
                    nfFlu_Id: parseInt(getFlujoCaso.nfFlu_Id),
                    nCas_Id: parseInt(getDecryptedItem("nCas_Id")),
                    nfFas_Id: parseInt(getFlujoCaso.nfFas_Id),
                    dfCaf_FecInicio: getFlujoCaso.dfCaf_FecInicio
                }
                let resultInsertFlujoCaso = await FlujoInsertarCaso(postData2)
                ReloadLista()

            })
            .catch(error => {

            })
    }

    const FuncionAgregarFlujoCaso = async () => {
        if (getFlujoCaso.nfFlu_Id == 0) {
            Swal.fire({
                title: "Mensaje",
                text: "Seleccione un flujo",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        }
        else if (getFlujoCaso.nfFas_Id == 0) {
            Swal.fire({
                title: "Mensaje",
                text: "Seleccione una fase",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        }
        else {
            var postData = {
                nfFlu_Id: parseInt(getFlujoCaso.nfFlu_Id),
                nCas_Id: parseInt(getDecryptedItem("nCas_Id")),
                nfFas_Id: parseInt(getFlujoCaso.nfFas_Id),
                dfCaf_FecInicio: getFlujoCaso.dfCaf_FecInicio
            }

            await instance
                .post("/Flujo/FlujoCasoListar", postData)
                .then(async response => {
                    if (response.data.length > 0) {
                        Swal.fire({
                            title: "Mensaje",
                            text: "Este caso se encuentra asigando a un flujo actualmente",
                            icon: "error",
                            confirmButtonText: "Aceptar",
                        })
                        onCloseClick()
                        return
                    }
                    FuncionListarTarea(postData.nfFas_Id);
                })
                .catch(error => {

                })
        }
    }
    async function ListarListaTarea(nfTar_Id) {
        var postData = {
            nfTar_Id: parseInt(nfTar_Id)
        }
        return new Promise(async (resolve) => {
            await instance
                .post("/flujoLista/Listar", postData)
                .then(response => {
                    resolve({
                        code: 'Success',
                        res: response.data
                    })
                }).catch(function (error) {
                    resolve({
                        code: 'Error',
                        res: error.response.data.message
                    })
                });
        });
    }
    async function InsertTareaSaas(data, date) {
        var postData = {
            nCas_Id: parseInt(getDecryptedItem('nCas_Id')),
            cTar_Tab_Tipo: 'TT01',
            cTar_Tab_Categoria: data.cfTar_Tab_Categoria,
            cTar_Titulo: data.cfTar_Nombre,
            nUsu_Asignado: parseInt(getDecryptedItem("ID")),
            nUsu_Relacionado: parseInt(getDecryptedItem("ID")),
            dTar_Fec_Vence: date,
            tTar_Hora_Vence: '12:00',
            cTar_tab_recordar: 'PE03',
            bTar_Estado: parseInt(1),
            nTar_Prioridad: parseInt(data.cfTar_Tab_Prioridad),
            nTar_Duracion: parseInt(data.nfTar_Duracion),
            nTar_TipoDias: parseInt(data.nfTar_TipoDias),
            nfFas_Id: parseInt(data.nfFas_Id)
        }


        return new Promise(async (resolve) => {
            await instance
                .post("/Tarea/Insertar", postData)
                .then(response => {
                    resolve({
                        code: 'Success',
                        res: response.data[0]
                    })
                }).catch(function (error) {
                    resolve({
                        code: 'Error',
                        res: error.response.data.message
                    })
                });
        });
    }
    async function InsertTareaPartSaas(_nTar_Id, nUsu_ID) {
        var postData = {
            nTar_Id: parseInt(_nTar_Id),
            nUsu_ID: nUsu_ID,
        }
        return new Promise(async (resolve) => {
            await instance
                .post("/Tarea/Insertar/Participante", postData)
                .then(response => {
                    resolve({
                        code: 'Success',
                        res: response.data
                    })
                }).catch(function (error) {
                    resolve({
                        code: 'Error',
                        res: error.response.data.message
                    })
                });
        });
    }
    async function InsertListaSaas(nTar_Id, row) {
        var postData = {
            nTar_Id: parseInt(nTar_Id),
            cLis_Nombre: row.CfList_Nombre,
        }
        return new Promise(async (resolve) => {
            await instance
                .post("/Tareas/ListaInsertar", postData)
                .then(response => {
                    resolve({
                        code: 'Success',
                        res: response.data[0]
                    })
                }).catch(function (error) {
                    resolve({
                        code: 'Error',
                        res: error.response.data.message
                    })
                });
        });
    }
    async function ListarRolesFlujoTarea(nfTar_Id) {
        var postData = {
            nfTar_Id: parseInt(nfTar_Id),
        }
        return new Promise(async (resolve) => {
            await instance
                .post("/flujoTarea/FlujoTareaListarParticipantes", postData)
                .then(response => {
                    resolve({
                        code: 'Success',
                        res: response.data
                    })
                }).catch(function (error) {
                    resolve({
                        code: 'Error',
                        res: error.response.data.message
                    })
                });
        });
    }
    async function ListarParticipanteRoles(_nCas_Id, Tipo) {
        var postData = {
            nCas_Id: _nCas_Id,
            Tipo: Tipo,
        }
        return new Promise(async (resolve) => {
            await instance
                .post("/Casos/Equipo", postData)
                .then(response => {
                    resolve({
                        code: 'Success',
                        res: response.data
                    })
                }).catch(function (error) {
                    resolve({
                        code: 'Error',
                        res: error.response.data.message
                    })
                });
        });
    }
    async function FlujoInsertarCaso(postData) {
        return new Promise(async (resolve) => {
            await instance
                .post("/Flujo/FlujoCasoInsertar", postData)
                .then(response => {
                    Swal.fire({
                        title: "Mensaje",
                        text: "Agregado exitosamente",
                        icon: "success",
                        confirmButtonText: "Aceptar",
                    })
                    resolve({
                        code: 'Success',
                        res: ''
                    })
                }).catch(function (error) {
                    resolve({
                        code: 'Error',
                        res: error.response.data.message
                    })
                });
        });
    }
    useEffect(() => {
        FuncionListarFlujos();
    }, [])
    return (
        <Modal isOpen={show}  >
            <div
                className="modal-header ModalHeaderColor"
            >
                <h5
                    style={{ color: "#fff" }}
                    className="modal-title mt-0"
                    id="myModalLabel"
                >
                    Asociar un Flujo de Trabajo
                </h5>
                <button
                    type="button"
                    onClick={() => onCloseClick()}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="mb-3 row">
                    <div className="col-md-12">
                        <select
                            className="form-select"
                            name="nfFlu_Id"
                            onChange={handleChange}
                            value={getFlujoCaso && getFlujoCaso.nfFlu_Id}
                        >
                            <option value="0">Seleccionar...</option>
                            {dataComboFlujo.map((row, i) => (
                                <option key={i} value={row.nfFlu_Id}>
                                    {row.cfFlu_Nombre}
                                </option>
                            ))}
                        </select>
                        <label className="col-form-label labelDown">
                            Flujo de trabajo
                        </label>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <select
                            className="form-select"
                            name="nfFas_Id"
                            onChange={handleChange}
                            value={getFlujoCaso && getFlujoCaso.nfFas_Id}
                        >
                            <option value="0">Seleccionar...</option>
                            {dataFases.map((row, i) => (
                                <option key={i} value={row.nfFas_Id}>
                                    {row.cfFas_Nombre}
                                </option>
                            ))}
                        </select>
                        <label className="col-form-label labelDown">Fase de Inicio</label>
                    </div>
                    <div className="col-md-12" style={{ marginTop: '10px' }}>
                        <input
                            type="date"
                            className="form-control"
                            id="dfCaf_FecInicio"
                            name="dfCaf_FecInicio"
                            value={getFlujoCaso && getFlujoCaso.dfCaf_FecInicio}
                            onChange={handleChange}
                        />
                        <label className="col-form-label labelDown">Evento: {getFlujo.cfFlu_Descripcion}</label>
                    </div>

                </div>
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => onCloseClick()}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                >
                    Cerrar
                </button>
                <button
                    type="button"
                    className="btn btn-danger waves-effect waves-light"
                    onClick={() => FuncionAgregarFlujoCaso()}
                >
                    Guardar
                </button>
            </div>
        </Modal>
    );
}
export default ModalFlujo
