import React, { useState, useEffect, useContext } from "react"
import { Button, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { getDecryptedItem } from '../../../../util';
import { v4 as uuidv4 } from 'uuid';
import './PlanPago.css';
import instance from '../../../../config'
const baseUrl = process.env.REACT_APP_API_URL
import Swal from "sweetalert2"

const PlanPago = ({ DisableTabs, InfoCabecera, InfoCaso, nCasId, nCb_Monto, dCb_Fecha, cTab_Moneda, onCloseClick, arrayMoneda }) => {
    const [Active1, setActive1] = useState("active")
    const [getDisableTabs, setDisableTabs] = useState(DisableTabs)
    const [Active2, setActive2] = useState("")
    const [Active3, setActive3] = useState("")
    const [Tab, setTab] = useState("1")
    const [valoresFormulariosCabecera, setValoresFormulariosCabecera] = useState([]);
    const [numFormularios, setNumFormularios] = useState(1);
    const [getCabeceraPago, setCabeceraPago] = useState({
        nCb_Pago: 0,
        nCas_Id: nCasId,
        nCb_Tipo: 1,
        dCb_Fecha: null,
        nCb_Cuotas: 0,
        nCb_TEA: 0,
        nCb_Monto: nCb_Monto,
        nCb_Estado: 1
    })
    const [valoresFormularios, setValoresFormularios] = useState([]);
    const [getDataDetallePago, setDataDetallePago] = useState([]);
    const [getSubTotal, setSubTotal] = useState('0.00');
    const [getTotal, setTotal] = useState('0.00');
    const [dataCliente, setDataCliente] = useState([])
    const [dataCaso, setDataCaso] = useState([])
    const [dataCopyPlanDePago, setdataCopyPlanDePago] = useState([])
    const [getConditionDetallePago, setConditionDetallePago] = useState(true)
    const [getDisabled, setDisabled] = useState(true)

    const EventoCabeceraTab = async param1 => {
        if (param1 == "1") {
            setActive1("active")
            setActive2("")
            setActive3("")
            setTab("1")
            setCabeceraPago(prevState => ({
                ...prevState,
                nCb_Tipo: 1,
                nCb_Cuotas: 0,
                nCb_TEA: 0,
                dCb_Fecha: null
            }))
            setValoresFormulariosCabecera([])
            setValoresFormularios([])
        } else if (param1 == "2") {
            setActive1("")
            setActive2("active")
            setActive3("")
            setTab("2")
            setCabeceraPago(prevState => ({
                ...prevState,
                nCb_Tipo: 2,
                nCb_Cuotas: 0,
                nCb_TEA: 0,
                dCb_Fecha: dCb_Fecha
            }))
            setValoresFormulariosCabecera([])
            setValoresFormularios([])
        } else if (param1 == "3") {
            setActive1("")
            setActive2("")
            setActive3("active")
            setTab("3")
            setCabeceraPago(prevState => ({
                ...prevState,
                nCb_Tipo: 3,
                nCb_Cuotas: 0,
                nCb_TEA: 0,
                dCb_Fecha: dCb_Fecha
            }))
            setValoresFormulariosCabecera([])
            setValoresFormularios([])
        }
    }
    const handleInputChangeCabecera = (event) => {
        const { name, value } = event.target;
        console.log(event.target)
        setCabeceraPago(prevState => ({
            ...prevState,
            [name]: value,
        }))
    };
    const duplicarFormulario = () => {
        setNumFormularios(numFormularios + 1);
        const nuevoFormulario = {
            ...{
                id: uuidv4(),
                cTab_Moneda: cTab_Moneda,
                cDt_Hito: "",
                nDt_Monto: 0,
                nDt_FlagFacturado: false,
                dDt_Fecha: dCb_Fecha,
                nDt_Estado: 1,
                seleccionado: false
            }
        };
        setValoresFormularios([...valoresFormularios, nuevoFormulario]);
    };
    const handleInputChange = (id, event) => {
        const { name, value } = event.target;
        const nuevosFormularios = valoresFormularios.map((formulario) =>
            formulario.id === id ? { ...formulario, [name]: value } : formulario
        );
        setValoresFormularios(nuevosFormularios);
    };
    const handleCheckboxChange = (id, event) => {
        const { name, checked } = event.target;

        const nuevosFormularios = valoresFormularios.map((formulario) =>
            formulario.id === id ? { ...formulario, [name]: checked } : formulario
        );
        setValoresFormularios(nuevosFormularios);
    };
    const handleCheckboxChangeSelect = (id, event) => {
        const { name, checked } = event.target;

        const nuevosFormularios = getDataDetallePago.map((formulario) =>
            formulario.nDt_Pago == id ? { ...formulario, [name]: checked } : formulario
        );
        setDataDetallePago(nuevosFormularios)

    };
    const eliminarFormulariosSeleccionados = () => {
        Swal.fire({
            title: "Mensaje",
            html:
                "Estas seguro de eliminar los elementos selecionados?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Aceptar",
            cancelButtonText: "Cancelar",
        }).then(result => {
            if (result.isConfirmed) {
                const nuevosFormularios = valoresFormularios.filter(
                    (formulario) => !formulario.seleccionado
                );
                setValoresFormularios(nuevosFormularios);
                setNumFormularios(nuevosFormularios.length);
                let array = getDataDetallePago.filter(row => row.seleccionado == true)
                if (array.length > 0) {
                    FuncionEliminarDetallePago(array, getCabeceraPago.nCb_Pago)
                }
            }
        })
    };
    const handleInputChangeDetallePago = (id, event) => {
        const { name, value } = event.target;
        const nuevosFormularios = getDataDetallePago.map((formulario) =>
            formulario.nDt_Pago === id ? { ...formulario, [name]: value, edit: true } : formulario
        );
        setDataDetallePago(nuevosFormularios);
    };
    const handleCheckboxChangeDetallePago = (id, event) => {
        const { name, checked } = event.target;

        const nuevosFormularios = getDataDetallePago.map((formulario) =>
            formulario.nDt_Pago == id ? { ...formulario, [name]: checked, edit: true } : formulario
        );
        setDataDetallePago(nuevosFormularios)

    };
    const renderFormularios = (type) => {
        return valoresFormularios.map((row, i) => (
            <Row style={{ marginBottom: 10 }} key={row.id}>
                <Col className="d-flex justify-content-center align-items-center col-content">
                    <input
                        className="checkbox"
                        type="checkbox"
                        style={{ marginTop: "5px", marginLeft: "0px" }}
                        name="seleccionado"
                        defaultChecked={row.seleccionado}
                        onClick={(e) => handleCheckboxChange(row.id, e)}
                    />
                </Col>
                <Col className="d-flex justify-content-center align-items-center col-content col-md-6">
                    <input
                        className="form-control"
                        type={type == 1 ? "text" : "date"}
                        placeholder="Ingresa un hito"
                        name={type == 1 ? "cDt_Hito" : "dDt_Fecha"}
                        value={type == 1 ? row.cDt_Hito : row.dDt_Fecha}
                        onChange={(e) => handleInputChange(row.id, e)}
                    />
                </Col>
                <Col className="d-flex justify-content-center align-items-center col-content">
                    <input
                        style={{ textAlign: 'right' }}
                        className="form-control"
                        type="text"
                        placeholder="Monto"
                        name="nDt_Monto"
                        value={row.nDt_Monto}
                        onChange={(e) => handleInputChange(row.id, e)}
                    />
                </Col>
                <Col className="d-flex justify-content-center align-items-center col-content">
                    <input
                        className="checkbox"
                        type="checkbox"
                        style={{ marginTop: "5px", marginLeft: "0px" }}
                        name="nDt_FlagFacturado"
                        defaultChecked={row.nDt_FlagFacturado}
                        onChange={(e) => handleCheckboxChange(row.id, e)}
                    />
                </Col>
            </Row>
        ));

    }
    const FuncionAplicarPlantillaPago = async () => {
        await instance
            .post("/Plantillapago/Cabecera/Pago/Insert", getCabeceraPago)
            .then(response => {
                setCabeceraPago(prevState => ({
                    ...prevState,
                    nCb_Pago: response.data[0].nCb_Pago,
                }))
                setDisableTabs(true)
                FuncionInsertarDetallePago(valoresFormularios, response.data[0].nCb_Pago)
            })
            .catch(error => {
                FuncionInsertarDetallePago(valoresFormularios, getCabeceraPago.nCb_Pago)
            })

    }

    const FuncionListarDetallePago = async (nCb_Pago) => {
        const postdata = {
            nCb_Pago: nCb_Pago
        }
        await instance
            .post("/Plantillapago/Detalle/Pago/Listar", postdata)
            .then(response => {
                let objeto = response.data
                const sumaTotal = objeto.reduce((total, objeto) => total + parseFloat(objeto.nDt_Monto), 0);
                setSubTotal(sumaTotal.toFixed(2));
                let n2 = sumaTotal
                let n1 = parseFloat(nCb_Monto)
                let Total = (n1 - n2)
                setTotal(Total.toFixed(2));
                setDataDetallePago(response.data)
            })
            .catch(error => {
                console.log(error)
            })
    }
    const FuncionInsertarDetallePago = async (ArrayDetallePago, nCb_Pago) => {
        let Validacion = valoresFormularios.filter(row => row.nDt_Monto == 0).length
        console.log(Validacion > 0)
        console.log(Validacion)
        if (Validacion > 0) {
            Swal.fire({
                title: "Mensaje",
                text: "Complete todos los campos",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
            return
        } else {
            let sumaTotal = valoresFormularios.reduce((total, objeto) => total + parseFloat(objeto.nDt_Monto), 0);
            let SubTotal = getDataDetallePago.reduce((total, objeto) => total + parseFloat(objeto.nDt_Monto), 0);
            sumaTotal = sumaTotal ? sumaTotal : 0
            sumaTotal = parseFloat(sumaTotal) + parseFloat(SubTotal)

            if (nCb_Monto < sumaTotal) {
                Swal.fire({
                    title: "Mensaje",
                    text: "Monto excedido",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                })
                return
            } else {
                if (getDataDetallePago.filter(row => row.edit == true).length > 0) {
                    FuncionEditarDetallePago(getDataDetallePago.filter(row => row.edit == true))
                }
                const postdata = {
                    ArrayDetallePago: ArrayDetallePago,
                    nCb_Pago: nCb_Pago,
                }
                await instance
                    .post("/Plantillapago/Detalle/Pago/Insert", postdata)
                    .then(response => {
                        setValoresFormularios([])
                        FuncionListarDetallePago(nCb_Pago)
                    })
                    .catch(error => {
                    })

            }
        }

        Swal.fire({
            title: "Mensaje",
            html:
                "Recuerda que solo se guardara el plan de pago",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Aceptar",
        }).then(result => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Mensaje",
                    text: "Se guardo exitosamente los elementos!",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })
            }
        })

    }
    const FuncionEditarDetallePago = async (ArrayDetallePago) => {
        const postdata = {
            ArrayDetallePago: ArrayDetallePago,
        }
        await instance
            .post("/Plantillapago/Detalle/Pago/Update", postdata)
            .then(response => {
                FuncionListarDetallePago(getCabeceraPago.nCb_Pago)
            })
            .catch(error => {
                console.log(error)
            })
    }
    const FuncionEliminarDetallePago = async (ArrayDetallePago, nCb_Pago) => {
        const postdata = {
            ArrayDetallePago: ArrayDetallePago,
        }
        await instance
            .post("/Plantillapago/Detalle/Pago/Delete", postdata)
            .then(response => {
                Swal.fire({
                    title: "Mensaje",
                    text: "Elemeto(s) eliminado(s) exitosamente!",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })
                FuncionListarDetallePago(nCb_Pago)
            })
            .catch(error => {
                console.log(error)
            })
    }
    const FuncionListarCliente = async () => {
        let RolId = getDecryptedItem("RolID")

        var postData = {
            bCli_Tipo: null,
            nUsu_ID: RolId == 6 || RolId == 1 ? null : getDecryptedItem("ID")
        }
        await instance
            .post("/Cliente/ListarCbo", postData)
            .then(response => {
                setDataCliente(response.data)
                console.log(response.data)
            })
            .catch(error => {
                console.log(error)
            })
    }
    const FuncionListarCasos = async (_idClient) => {
        var postData = {
            idClient: _idClient,
            nUsu_Id: getDecryptedItem("ID"),
        }
        console.log(postData)
        await instance
            .post("/Casos/ListarCbo", postData)
            .then(response => {
                setDataCaso(response.data)
                console.log(response.data)
            })
            .catch(error => {
                console.log(error)
            })
    }
    const handleChangeFiltroCaso = (e, name) => {
        if (name == "nCli_Id") {
            if (e.target.value == 0) {
                FuncionListarCasos(null)
            } else {
                FuncionListarCasos(e.target.value)
            }
        } else {
            if (e.target.value !== 0) {
                FuncionBuscarCabeceraPagoListar(e.target.value)
            }
        }
    }
    const FuncionBuscarCabeceraPagoListar = async (nCas_Id) => {
        const postdata = {
            nCas_Id: nCas_Id
        }
        await instance
            .post("/Plantillapago/Cabecera/Pago/Listar", postdata)
            .then(response => {
                let result = response.data
                if (result.length > 0) {
                    result = result[0]
                    FuncionCopyListarDetallePago(result.nCb_Pago)
                    setConditionDetallePago(false)
                } else {
                    Swal.fire({
                        title: "Mensaje",
                        text: "El caso no tiene un plan de pago",
                        icon: "error",
                        confirmButtonText: "Aceptar",
                    })
                    setConditionDetallePago(true)
                }
            })
            .catch(error => {
                console.log(error)
            })
    }
    const FuncionCopyListarDetallePago = async (nCb_Pago) => {
        const postdata = {
            nCb_Pago: nCb_Pago
        }
        await instance
            .post("/Plantillapago/Detalle/Pago/Listar", postdata)
            .then(response => {
                let objeto = response.data
                setdataCopyPlanDePago(objeto)

            })
            .catch(error => {
                console.log(error)
            })
    }
    const CopiarPlanDePago = async () => {
        let array = []
        dataCopyPlanDePago.map((row, i) => {
            setNumFormularios(numFormularios + 1);
            let nuevoFormulario = {
                id: uuidv4(),
                cTab_Moneda: cTab_Moneda,
                cDt_Hito: row.cDt_Hito,
                nDt_Monto: row.nDt_Monto,
                nDt_FlagFacturado: false,
                dDt_Fecha: dCb_Fecha,
                nDt_Estado: 1,
                seleccionado: false
            };
            array.push(nuevoFormulario);
        })
        setValoresFormularios(array);
    }

    useEffect(() => {
        FuncionListarCliente()
        EventoCabeceraTab(InfoCabecera.nCb_Tipo)
        setCabeceraPago(InfoCabecera)
        FuncionListarDetallePago(InfoCabecera.nCb_Pago)
    }, [])
    return (
        <div>
            <div
                className="modal-header ModalHeaderColor"
            >
                <h5
                    style={{ color: "#fff" }}
                    className="modal-title mt-0"
                    id="myModalLabel"
                >
                    Plan de Pagos
                </h5>
                <button
                    type="button"
                    onClick={() => onCloseClick()}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">

                <div className="container" style={{ marginBottom: 25 }}>
                    <Nav tabs className="nav-tabs-custom nav-justified">
                        <NavItem>
                            <NavLink
                                className={Active1}
                                style={{ cursor: "pointer" }}
                                onClick={() => EventoCabeceraTab("1")}
                                disabled={getDisableTabs}
                            >
                                Por Hitos{" "}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={Active2}
                                style={{ cursor: "pointer" }}
                                onClick={() => EventoCabeceraTab("2")}
                                disabled={getDisableTabs}
                            >
                                Por Fechas{" "}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={Active3}
                                style={{ cursor: "pointer" }}
                                onClick={() => EventoCabeceraTab("3")}
                                disabled={getDisableTabs}
                            >
                                Por Periodo{" "}
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div>
                <div className="container">
                    <Row>
                        <Col className="col-content col-md-8">
                            <p style={{ marginBottom: '5px' }}><b>Asunto:</b> {InfoCaso.cCas_Titulo1}</p>
                            <p style={{ marginBottom: '5px' }}><b>Titulo:</b> {InfoCaso.cCas_Titulo}</p>
                            <p style={{ marginBottom: '5px' }}><b>Codigo Externo:</b> {InfoCaso.cCas_Cod_Externo}</p>
                        </Col>
                        <Col className="col-content">
                            <Row>
                                <Col className="col-content col-md-12">
                                    <Row>
                                        <Col className="col-content"><b>Por pagar :</b> </Col>
                                        <Col className="col-content">
                                            <p style={{ marginBottom: '5px', float: 'right' }}>{arrayMoneda.find(e => e.cTabCodigo == cTab_Moneda).cTabSimbolo}  {nCb_Monto}</p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="col-content col-md-12">
                                    <Row>
                                        <Col className="col-content"><b>Montos :</b></Col>
                                        <Col className="col-content">
                                            <p style={{ marginBottom: '5px', float: 'right' }}>{getSubTotal}</p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="col-content col-md-12">
                                    <Row>
                                        <Col className="col-content"><b></b></Col>
                                        <Col className="col-content">
                                            <hr style={{ marginTop: '0px', marginBottom: '0px', borderTop: '1px solid #000', opacity: '1' }} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="col-content col-md-12">
                                    <Row>
                                        <Col className="col-content"><b>Por asignar :</b></Col>
                                        <Col className="col-content">
                                            <p style={{ marginBottom: '5px', float: 'right' }}>{getTotal}</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <TabContent activeTab={Tab} className="p-3 text-muted">
                    <TabPane tabId="1">
                        <div className="container" style={{ marginBottom: 25 }}>
                            <Row>
                                <Col className="col-content col-md-1">
                                    <Row>
                                        <input
                                            className="checkbox"
                                            type="checkbox"
                                            style={{ marginTop: "5px", marginLeft: "0px" }}
                                            onClick={(e) => setDisabled(!getDisabled)}
                                        />
                                    </Row>
                                </Col>
                                <Col className="col-content col-md-4">
                                    <Row>
                                        <Col className="d-flex justify-content-center align-items-center col-content col-md-4"><b>Cliente</b></Col>
                                        <Col className="col-content">
                                            <select disabled={getDisabled}
                                                className="form-select inputDown"
                                                name="nCli_Id" onChange={(e) => handleChangeFiltroCaso(e, "nCli_Id")}>
                                                <option value="0">Seleccionar...</option>
                                                {dataCliente.map((row, i) => (
                                                    <option key={i} value={row.nCli_Id}>
                                                        {row.cCli_NombreCompleto}
                                                    </option>
                                                ))}
                                            </select>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="col-content col-md-4">
                                    <Row>
                                        <Col className="d-flex justify-content-center align-items-center col-content col-md-4"><b>Caso</b></Col>
                                        <Col className="col-content">
                                            <select disabled={getDisabled}
                                                className="form-select inputDown"
                                                name="nCas_Id" onChange={(e) => handleChangeFiltroCaso(e, "nCas_Id")}>
                                                <option value="0">Seleccionar...</option>
                                                {dataCaso.map((row, i) => (
                                                    <option key={i} value={row.nCas_Id}>
                                                        {row.cCas_Titulo}
                                                    </option>
                                                ))}
                                            </select>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="d-flex justify-content-center align-items-center col-content">
                                    <Button disabled={getConditionDetallePago} onClick={() => CopiarPlanDePago()}>Copiar plan de pago</Button>
                                </Col>
                            </Row>
                        </div>
                        <div className="container" style={{ marginBottom: 25 }}>
                            <Row>
                                <Col className="d-flex justify-content-center align-items-center col-content">
                                    <label className="col-form-label labelDown" style={{ marginLeft: "0px" }}>Seleccionar</label>
                                </Col>
                                <Col className="d-flex justify-content-center align-items-center col-content col-md-6">
                                    <label className="col-form-label labelDown" style={{ marginLeft: "0px" }}>Hito</label>
                                </Col>
                                <Col className="d-flex justify-content-center align-items-center col-content">
                                    <label className="col-form-label labelDown" style={{ marginLeft: "0px" }}>{arrayMoneda.find(e => e.cTabCodigo == cTab_Moneda).cTabSimbolo} Monto</label>
                                </Col>
                                <Col className="d-flex justify-content-center align-items-center col-content">
                                    <label className="col-form-label labelDown" style={{ marginLeft: "0px" }}>Facturado</label>
                                </Col>
                            </Row>
                            <hr style={{ marginTop: '0px', marginBottom: '20px', borderTop: '1px solid #000', opacity: '1' }} />
                            {getDataDetallePago.map((row, i) => (
                                <Row style={{ marginBottom: 10 }} key={row.nDt_Pago}>
                                    <Col className="d-flex justify-content-center align-items-center col-content">
                                        <input
                                            className="checkbox"
                                            type="checkbox"
                                            style={{ marginTop: "5px", marginLeft: "0px" }}
                                            name="seleccionado"
                                            defaultChecked={row.seleccionado}
                                            onClick={(e) => handleCheckboxChangeSelect(row.nDt_Pago, e)}
                                        />
                                    </Col>
                                    <Col className="d-flex justify-content-center align-items-center col-content col-md-6">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Ingresa un hito"
                                            name="cDt_Hito"
                                            value={row.cDt_Hito}
                                            onChange={(e) => handleInputChangeDetallePago(row.nDt_Pago, e)}
                                        />
                                    </Col>
                                    <Col className="d-flex justify-content-center align-items-center col-content">
                                        <input
                                            style={{ textAlign: 'right' }}
                                            className="form-control"
                                            type="text"
                                            placeholder="Monto"
                                            name="nDt_Monto"
                                            value={row.nDt_Monto}
                                            onChange={(e) => handleInputChangeDetallePago(row.nDt_Pago, e)}
                                        />
                                    </Col>
                                    <Col className="d-flex justify-content-center align-items-center col-content">
                                        <input
                                            className="checkbox"
                                            type="checkbox"
                                            style={{ marginTop: "5px", marginLeft: "0px" }}
                                            name="nDt_FlagFacturado"
                                            defaultChecked={row.nDt_FlagFacturado}
                                            onChange={(e) => handleCheckboxChangeDetallePago(row.nDt_Pago, e)}
                                        />
                                    </Col>
                                </Row>
                            ))}
                            {renderFormularios(1)}

                        </div>
                    </TabPane>
                    <TabPane tabId="2">
                        <div className="container" style={{ marginBottom: 25 }}>
                            <Row>
                                <Col className="d-flex justify-content-center align-items-center col-content">
                                    <label className="col-form-label labelDown" style={{ marginLeft: "0px" }}>Seleccionar</label>
                                </Col>
                                <Col className="d-flex justify-content-center align-items-center col-content col-md-6">
                                    <label className="col-form-label labelDown" style={{ marginLeft: "0px" }}>Fecha</label>
                                </Col>
                                <Col className="d-flex justify-content-center align-items-center col-content">
                                    <label className="col-form-label labelDown" style={{ marginLeft: "0px" }}>{arrayMoneda.find(e => e.cTabCodigo == cTab_Moneda).cTabSimbolo} Monto</label>
                                </Col>
                                <Col className="d-flex justify-content-center align-items-center col-content">
                                    <label className="col-form-label labelDown" style={{ marginLeft: "0px" }}>Facturado</label>
                                </Col>
                            </Row>
                            <hr style={{ marginTop: '0px', marginBottom: '20px', borderTop: '1px solid #000', opacity: '1' }} />
                            {getDataDetallePago.map((row, i) => (
                                <Row style={{ marginBottom: 10 }} key={row.nDt_Pago}>
                                    <Col className="d-flex justify-content-center align-items-center col-content">
                                        <input
                                            className="checkbox"
                                            type="checkbox"
                                            style={{ marginTop: "5px", marginLeft: "0px" }}
                                            name="seleccionado"
                                            defaultChecked={row.seleccionado}
                                            onClick={(e) => handleCheckboxChangeSelect(row.nDt_Pago, e)}
                                        />
                                    </Col>
                                    <Col className="d-flex justify-content-center align-items-center col-content col-md-6">
                                        <input
                                            className="form-control"
                                            type="date"
                                            name="dDt_Fecha"
                                            value={row.dDt_Fecha}
                                            onChange={(e) => handleInputChangeDetallePago(row.nDt_Pago, e)}
                                        />
                                    </Col>
                                    <Col className="d-flex justify-content-center align-items-center col-content">
                                        <input
                                            style={{ textAlign: 'right' }}
                                            className="form-control"
                                            type="text"
                                            placeholder="Monto"
                                            name="nDt_Monto"
                                            value={row.nDt_Monto}
                                            onChange={(e) => handleInputChangeDetallePago(row.nDt_Pago, e)}
                                        />
                                    </Col>
                                    <Col className="d-flex justify-content-center align-items-center col-content">
                                        <input
                                            className="checkbox"
                                            type="checkbox"
                                            style={{ marginTop: "5px", marginLeft: "0px" }}
                                            name="nDt_FlagFacturado"
                                            defaultChecked={row.nDt_FlagFacturado}
                                            onChange={(e) => handleCheckboxChangeDetallePago(row.nDt_Pago, e)}
                                        />
                                    </Col>
                                </Row>
                            ))}
                            {renderFormularios(2)}
                        </div>
                    </TabPane>
                    <TabPane tabId="3">
                        <div className="container" style={{ marginBottom: 25 }}>
                            <Row>
                                <Col className="d-flex align-items-center col-md-2"><b>Periodo</b> </Col>
                                <Col className="d-flex align-items-center col-md-4">
                                    <select
                                        className="form-select inputDown"
                                        name="nCb_TEA"
                                        value={getCabeceraPago.nCb_TEA}
                                        onChange={(e) => handleInputChangeCabecera(e)}
                                    >
                                        <option value="0">
                                            Seleccionar...
                                        </option>
                                        <option value="1">
                                            Semanal
                                        </option>
                                        <option value="2">
                                            Quincenal
                                        </option>
                                        <option value="3">
                                            Mensual
                                        </option>
                                        <option value="3">
                                            Bimensual
                                        </option>
                                    </select>
                                </Col>
                            </Row>
                        </div>
                        <div className="container" style={{ marginBottom: 25 }}>
                            <Row>
                                <Col className="d-flex justify-content-center align-items-center col-content">
                                    <label className="col-form-label labelDown" style={{ marginLeft: "0px" }}>Seleccionar</label>
                                </Col>
                                <Col className="d-flex justify-content-center align-items-center col-content col-md-6">
                                    <label className="col-form-label labelDown" style={{ marginLeft: "0px" }}>Fecha</label>
                                </Col>
                                <Col className="d-flex justify-content-center align-items-center col-content">
                                    <label className="col-form-label labelDown" style={{ marginLeft: "0px" }}>{arrayMoneda.find(e => e.cTabCodigo == cTab_Moneda).cTabSimbolo} Monto</label>
                                </Col>
                                <Col className="d-flex justify-content-center align-items-center col-content">
                                    <label className="col-form-label labelDown" style={{ marginLeft: "0px" }}>Facturado</label>
                                </Col>
                            </Row>
                            <hr style={{ marginTop: '0px', marginBottom: '20px', borderTop: '1px solid #000', opacity: '1' }} />
                            {getDataDetallePago.map((row, i) => (
                                <Row style={{ marginBottom: 10 }} key={row.nDt_Pago}>
                                    <Col className="d-flex justify-content-center align-items-center col-content">
                                        <input
                                            className="checkbox"
                                            type="checkbox"
                                            style={{ marginTop: "5px", marginLeft: "0px" }}
                                            name="seleccionado"
                                            defaultChecked={row.seleccionado}
                                            onClick={(e) => handleCheckboxChangeSelect(row.nDt_Pago, e)}
                                        />
                                    </Col>
                                    <Col className="d-flex justify-content-center align-items-center col-content col-md-6">
                                        <input
                                            className="form-control"
                                            type="date"
                                            name="dDt_Fecha"
                                            value={row.dDt_Fecha}
                                            onChange={(e) => handleInputChangeDetallePago(row.nDt_Pago, e)}
                                        />
                                    </Col>
                                    <Col className="d-flex justify-content-center align-items-center col-content">
                                        <input
                                            style={{ textAlign: 'right' }}
                                            className="form-control"
                                            type="text"
                                            placeholder="Monto"
                                            name="nDt_Monto"
                                            value={row.nDt_Monto}
                                            onChange={(e) => handleInputChangeDetallePago(row.nDt_Pago, e)}
                                        />
                                    </Col>
                                    <Col className="d-flex justify-content-center align-items-center col-content">
                                        <input
                                            className="checkbox"
                                            type="checkbox"
                                            style={{ marginTop: "5px", marginLeft: "0px" }}
                                            name="nDt_FlagFacturado"
                                            defaultChecked={row.nDt_FlagFacturado}
                                            onChange={(e) => handleCheckboxChangeDetallePago(row.nDt_Pago, e)}
                                        />
                                    </Col>
                                </Row>
                            ))}
                            {renderFormularios(2)}
                        </div>
                    </TabPane>
                    <div>
                        <div className="container" style={{ marginTop: 35 }}>
                            <Row>
                                <Col className="d-flex justify-content-center align-items-center col-content">
                                </Col>
                                <Col className="d-flex justify-content-center align-items-center col-content">
                                    <Button className="btn-danger" onClick={() => duplicarFormulario()}> + Añadir</Button>
                                </Col>
                                <Col className="d-flex justify-content-center align-items-center col-content">
                                    <Button disabled={valoresFormularios.filter(item => item.seleccionado === true).concat(getDataDetallePago.filter(item => item.seleccionado === true)).length == 0 ? true : false} className="btn-danger" onClick={() => eliminarFormulariosSeleccionados()}> - Eliminar</Button>
                                </Col>
                                <Col className="d-flex justify-content-center align-items-center col-content">
                                    <Button onClick={() => FuncionAplicarPlantillaPago()}>Guardar</Button>
                                </Col>
                                <Col className="d-flex justify-content-center align-items-center col-content">
                                </Col>
                            </Row>
                        </div>
                    </div>
                </TabContent>

            </div>
        </div>
    )
}
export default PlanPago;