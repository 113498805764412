import React, { Component, useState, useEffect } from "react"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import {
  CardBody,
  Card,
  Col,
  Container,
  CardTitle,
  Button,
  Row,
  Modal,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap"
import { getDecryptedItem } from '../../util';
import MetaTags from "react-meta-tags"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/tablescss/FormatTable.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPlus,
  faSearch,
  faTimes,
  faChevronLeft,
  faChevronRight,
  faPaperPlane,
  faBuilding,
  faUser,
  faCalendar,
  faCircle,
  faClipboard,
  faMapMarkedAlt,
  faDownload,
  faEye,
  faTrash,
  faBell,
  faClock,
  faCoins,
} from "@fortawesome/free-solid-svg-icons"
import Swal from "sweetalert2"
import instance from '../../config'
import ModalHorasLaboradas from "pages/Componentes/ModalHorasLaboradas"
import { Link } from "react-router-dom"
import ReactTooltip from "react-tooltip"
const baseUrl = process.env.REACT_APP_API_URL
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
const Validacion = (form) => {
  let errors = {};
  let regexLetras = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
  let regexCorreo = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
  let regexNumero = /^[0-9]+$/;

  if (form.nCli_Id == 0) {
    errors.nCli_Id = "*"
  }
  if (form.nCas_Id == 0) {
    errors.nCas_Id = "*"
  }
  if (form.nCue_Id == 0) {
    errors.nCue_Id = "*"
  }
  if (form.cLab_Tab_Tipo == 0) {
    errors.cLab_Tab_Tipo = "*"
  }
  if (form.nUsu_Id == 0) {
    errors.nUsu_Id = "*"
  }
  if (form.cLab_Tab_Moneda == 0) {
    errors.cLab_Tab_Moneda = "*"
  }
  if (form.tLab_HoraFacturable.trim() == "") {
    errors.tLab_HoraFacturable = "*"
  }
  if (form.nLab_TasaXHora == "0") {
    errors.nLab_TasaXHora = "*"
  }

  return errors;
}
const HorasLaboradas = props => {
  const [modalInsertar, setModalInsertar] = useState(false)
  const [modalActualizar, setModalActualizar] = useState(false)
  const [modalDetalle, setModalDetalle] = useState(false)
  const [modalFiltro, setModalFiltro] = useState(false)
  const [data, setData] = useState([])
  const [dataComboMoneda, setDataComboMoneda] = useState([])
  const [dataCaso, setDataCaso] = useState([])
  const [dataComboUsuario, setDataComboUsuario] = useState([])
  const [filtroComboUsuario, setFiltroComboUsuario] = useState([])
  const [dataComboTipoHL, setDataComboTipoHL] = useState([])
  const [dataCliente, setDataCliente] = useState([])
  const [chekedFacturable, setchekedFacturable] = useState(true)
  const [chekedPreciFijo, setchekedPreciFijo] = useState(false)
  const [getCaso, setCaso] = useState("")
  const [HiddenFacturable, setHiddenFacturable] = useState()
  const [HiddenPreciFijo, setHiddenPreciFijo] = useState()
  const [validData, setValidData] = useState(false)

  const [dataCodInterno, setdataCodInterno] = useState("")
  const [dataCodExterno, setdataCodExterno] = useState("")
  const [getErrores, setErrores] = useState({});
  const [getHLaboradas, setHLaboradas] = useState({
    nLab_Id: "",
    nCli_Id: getDecryptedItem("CLIID"),
    nCas_Id: getDecryptedItem('nCas_Id'),
    cLab_Tab_Tipo: "",
    nLab_Hora: "00:00",
    nLab_Hora_H: "01",
    nLab_Hora_M: "00",
    dLab_Fecha: ano + "-" + mes + "-" + dia,
    cLab_Detalle: "",
    nUsu_Id: "",
    bLab_PrecioFijo: 0,
    tLab_HoraFacturable: "01:00",
    tLab_HoraFacturable_H: "01",
    tLab_HoraFacturable_M: "00",
    nLab_TasaXHora: "",
    cLab_Tab_Moneda: "",
    nLab_TasaXHoraFormat: 0,
    nLab_TasaXHoraOld: 0,
    nLab_Total: "",
    bLab_Estado: 1,
  })
  const [getFiltro, setFiltro] = useState({
    nCas_Id: null,
    FechaInicial: ano + "-" + "01" + "-" + "01",
    FechaFin: ano + "-" + mes + "-" + dia,
    nCli_Id: null,
    nUsu_Id: null
  })
  var Total;
  const [chekedValor1, setchekedValor1] = useState(false)
  const [chekedValor2, setchekedValor2] = useState(false)
  const [chekedValor3, setchekedValor3] = useState(false)
  const [hiddenCliente, setHiddenCliente] = useState(false)
  const [Valor1, setValor1] = useState(true)
  const [Valor2, setValor2] = useState(true)
  const [Valor3, setValor3] = useState(true)
  const [hiddenBtnEditar, setHiddenBtnEditar] = useState(true)
  const [hiddenBtnEliminar, setHiddenBtnEliminar] = useState(true)
  const handleChangeFiltro = e => {
    if (chekedValor1 == true || chekedValor2 == true) {


      setFiltro({
        ...getFiltro,
        [e.target.name]: e.target.value
      })
      if (e.target.name == 'nCli_Id') {
        if (e.target.value == 0) {
          FuncionListarCasos(null)
        } else {
          FuncionListarCasos(e.target.value)
        }
      }

    }
    else if (chekedValor3 == true || chekedValor3 == true) {


      setFiltro({
        ...getFiltro,
        [e.target.name]: e.target.value
      })

    }
    else {


      setFiltro({
        ...getFiltro,
        [e.target.name]: e.target.value
      })
      FuncionListarCasos(null)

      console.log(getFiltro);

    }
  }
  const SelectDetalle = async (nCas_Id, nCli_Id) => {
    setEncryptedItem('nCas_Id', nCas_Id);
    setEncryptedItem('CLIID', nCli_Id);
  }
  const handleChangeValor1 = e => {
    setchekedValor1(!chekedValor1)
    if (chekedValor1 == true) {
      setFiltro({
        ...getFiltro,
        nCli_Id: null
      })
      setValor1(true)
    } else {
      var Valor = getFiltro.nCli_Id

      setFiltro({
        ...getFiltro,
        nCli_Id: Valor
      })
      setValor1(false)
    }
  }
  const handleChangeValor2 = e => {
    setchekedValor2(!chekedValor2)
    if (chekedValor2 == true) {
      setFiltro({
        ...getFiltro,
        nCas_Id: null
      })
      setValor2(true)
    } else {
      var Valor = getFiltro.nCas_Id

      setFiltro({
        ...getFiltro,
        nCas_Id: Valor
      })
      setValor2(false)
    }
  }
  const handleChangeValor3 = e => {


    setchekedValor3(!chekedValor3)
    if (chekedValor3 == true) {
      setFiltro({
        ...getFiltro,
        nUsu_Id: null
      })
      setValor3(true)
    } else {
      var Valor = getFiltro.nUsu_Id

      setFiltro({
        ...getFiltro,
        nUsu_Id: Valor
      })
      setValor3(false)
    }
  }

  function Multiplicar(valor, HorasLaborables) {
    console.log(valor)
    console.log(HorasLaborables)
    var TasaXHora = valor
    console.log(TasaXHora)

    var Valor1 = HorasLaborables.split(":")
    var N1 = Valor1[0]
    var N2 = Valor1[1]
    var Multi = N1 * TasaXHora
    var NVALOR1 = TasaXHora / 60

    var NVALOR2 = NVALOR1 * N2

    var Total = Multi + NVALOR2

    setHLaboradas(prevState => ({
      ...prevState,
      nLab_Total: Total.toFixed(2),
    }))
  }

  const handleChange = e => {
    const { name, value } = e.target
    if (name == "nLab_TasaXHora") {
      setHLaboradas(prevState => ({
        ...prevState,
        nLab_TasaXHora: value,
        nLab_TasaXHoraOld: value,
        nLab_TasaXHoraFormat: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(value),
      }))
      if (chekedPreciFijo == false) {
        console.log(value)
        Multiplicar(value, getHLaboradas.tLab_HoraFacturable)
      }
      else {
        setHLaboradas(prevState => ({
          ...prevState,
          nLab_Total: value,
          nLab_TotalFormat: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(value),
          nLab_TotalFormat: value,
        }))
      }
    } else if (e.target.name == "nCas_Id") {
      setHLaboradas(prevState => ({
        ...prevState,
        [name]: value,
      }))
      setCaso(value);
      FuncionInformacionCaso(e.target.value);
      FuncionListarComboUsuario(e.target.value)
    } else if (name == "nUsu_Id") {
      setHLaboradas(prevState => ({
        ...prevState,
        [name]: value,
      }))
      if (getDecryptedItem("nCas_Id") == null) {
        FuncionGetMonedaMonto_UsuarioCaso(getCaso, value)
      }
      else {
        FuncionGetMonedaMonto_UsuarioCaso(getDecryptedItem("nCas_Id"), value)
      }
    } else if (name == "nLab_Hora_H") {
      setHLaboradas(prevState => ({
        ...prevState,
        nLab_Hora_H: value,
        nLab_Hora: value + ":" + getHLaboradas.nLab_Hora_M,
        tLab_HoraFacturable: value + ":" + getHLaboradas.nLab_Hora_M,
        tLab_HoraFacturable_H: value,
        tLab_HoraFacturable_M: getHLaboradas.nLab_Hora_M,
      }))
      Multiplicar(getHLaboradas.nLab_TasaXHora, value + ":" + getHLaboradas.nLab_Hora_M)
    } else if (name == "nLab_Hora_M") {
      setHLaboradas(prevState => ({
        ...prevState,
        nLab_Hora_M: value,
        nLab_Hora: getHLaboradas.nLab_Hora_H + ":" + value,
        tLab_HoraFacturable: getHLaboradas.nLab_Hora_H + ":" + value,
        tLab_HoraFacturable_M: value,
        tLab_HoraFacturable_H: getHLaboradas.nLab_Hora_H,
      }))
      Multiplicar(getHLaboradas.nLab_TasaXHora, getHLaboradas.nLab_Hora_H + ":" + value)
    } else if (name == "tLab_HoraFacturable_H") {
      if (getHLaboradas.nLab_Hora < value + ":" + getHLaboradas.tLab_HoraFacturable_M) {
        Swal.fire({
          title: "Mensaje",
          text: "El tiempo facturable no puede ser mayor a las horas laboradas ",
          icon: "error",
          confirmButtonText: "Aceptar",
        })
        return
      } else {
        setHLaboradas(prevState => ({
          ...prevState,
          tLab_HoraFacturable_H: value,
          tLab_HoraFacturable: value + ":" + getHLaboradas.tLab_HoraFacturable_M,
        }))

        console.log(getHLaboradas.nLab_TasaXHora)
        console.log(value + ":" + getHLaboradas.tLab_HoraFacturable_M)
        Multiplicar(getHLaboradas.nLab_TasaXHora, value + ":" + getHLaboradas.tLab_HoraFacturable_M)
      }
    } else if (name == "tLab_HoraFacturable_M") {

      console.log("else name == tLab_HoraFacturable_M")

      console.log("nLab_Hora : ", getHLaboradas.nLab_Hora)
      console.log("getHLaboradas.tLab_HoraFacturable_H : ", getHLaboradas.tLab_HoraFacturable_H)
      console.log("value : ", value)

      if (getHLaboradas.nLab_Hora < getHLaboradas.tLab_HoraFacturable_H + ":" + value) {
        Swal.fire({
          title: "Mensaje",
          text: "El tiempo facturable no puede ser mayor a las horas laboradas ",
          icon: "error",
          confirmButtonText: "Aceptar",
        })
        console.log(getHLaboradas.tLab_HoraFacturable_M)
        setHLaboradas(prevState => ({
          ...prevState,
          tLab_HoraFacturable_M: getHLaboradas.tLab_HoraFacturable_M,
        }))
        return
      } else {
        setHLaboradas(prevState => ({
          ...prevState,
          tLab_HoraFacturable_M: value,
          tLab_HoraFacturable: getHLaboradas.tLab_HoraFacturable_H + ":" + value,
        }))
        Multiplicar(getHLaboradas.nLab_TasaXHora, getHLaboradas.tLab_HoraFacturable_H + ":" + value)
      }

    }
    else {
      setHLaboradas(prevState => ({
        ...prevState,
        [name]: value,
      }))
    }

  }


  const handleChangeCheckFacturable = e => {
    setchekedFacturable(!chekedFacturable)
    if (chekedFacturable == true) {
      setHiddenFacturable("none")
    } else {
      setHiddenFacturable("")
    }
  }
  const handleChangeCheckPreciFijo = e => {
    setchekedPreciFijo(!chekedPreciFijo)
    if (chekedPreciFijo == false) {
      //setHiddenPreciFijo("none")



      setHiddenPreciFijo("")
      setHLaboradas(prevState => ({
        ...prevState,
        nLab_Total: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(getHLaboradas.nLab_TasaXHora * 1),
      }))

    } else {


      var HorasLaborables = document.getElementById("tLab_HoraFacturable").value
      var Valor1 = HorasLaborables.split(":")
      var N1 = Valor1[0]



      setHLaboradas(prevState => ({
        ...prevState,
        nLab_Total: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(getHLaboradas.nLab_TasaXHora * N1),
      }))
    }
  }

  const abrirCerrarModal = Modal => {
    if (Modal == 1) {
      if (!modalInsertar == false) {
        Swal.fire({
          html: "¿Seguro que desea salir sin guardar los datos?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        }).then(result => {
          if (result.isConfirmed) {
            setModalInsertar(!modalInsertar)
          }
        })
      } else {
        setModalInsertar(!modalInsertar)
      }
    } else if (Modal == 2) {
      FuncionListarCasos(null)
      setModalActualizar(!modalActualizar)
    } else if (Modal == 3) {
      FuncionListarCasos(null)
      setModalDetalle(!modalDetalle)
    }
    else if (Modal == 4) {
      setModalFiltro(!modalFiltro)
    }
  }
  const FuncionListarHorasLaboradas = async () => {
    let RolId = getDecryptedItem("RolID")
    var postData = {
      nUsu_ID: RolId == 6 || RolId == 1 ? null : getDecryptedItem("ID")
    }
    await instance
      .post("/Lab/Listar", postData)
      .then(response => {
        console.log(response)
        setData(response.data)

      })
      .catch(error => {

      })
  }
  const FuncionBuscarHorasLaboradas = async () => {
    var postData = {
      nCli_Id: getDecryptedItem("CLIID") || parseInt(getFiltro.nCli_Id),
      nCas_Id: parseInt(getFiltro.nCas_Id),
      FechaInicial: getFiltro.FechaInicial,
      FechaFin: getFiltro.FechaFin,
      nUsu_Id: parseInt(getFiltro.nUsu_Id)
    }

    console.log(postData);

    await instance
      .post("/Laborado/Buscar", postData)
      .then(response => {
        console.log(response.data);
        setData(response.data)
        setModalFiltro(!modalFiltro)

      })
      .catch(error => {

      })
  }
  const FuncionListarComboTipoHL = async () => {
    var postData = {
      Prefijo: "XA",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataComboTipoHL(response.data)

      })
      .catch(error => {

      })
  }
  const FuncionListarComboUsuario = async (nCas_Id) => {
    let postData = {
      _nCas_Id: nCas_Id
    }
    await instance
      .post("/Caso/Participantes_Caso_All", postData)
      .then(response => {
        setDataComboUsuario(response.data)

      })
      .catch(error => {

      })
  }
  const FuncionListarCliente = async () => {
    let RolId = getDecryptedItem("RolID")

    var postData = {
      bCli_Tipo: null,
      nUsu_ID: RolId == 6 || RolId == 1 ? null : getDecryptedItem("ID")
    }
    await instance
      .post("/Cliente/ListarCbo", postData)
      .then(response => {
        setDataCliente(response.data)

      })
      .catch(error => {

      })
  }
  const handleChangeFiltroCaso = e => {


    if (e.target.value == 0) {
      FuncionListarCasos(null)
      setHLaboradas(prevState => ({
        ...prevState,
        nCli_Id: e.target.value,

      }))
    } else {
      FuncionListarCasos(e.target.value)
      setHLaboradas(prevState => ({
        ...prevState,
        nCli_Id: e.target.value,

      }))
    }
  }
  const FuncionListarCasos = async (_idClient) => {
    var postData = {
      idClient: _idClient,
      nUsu_Id: getDecryptedItem("ID"),
    }
    await instance
      .post("/Casos/ListarCbo", postData)
      .then(response => {
        setDataCaso(response.data)

      })
      .catch(error => {

      })
  }
  const FuncionInformacionCaso = async (_nCas_Id) => {
    var postData = {
      nCas_Id: _nCas_Id,
    }
    await instance
      .post("/Casos/Buscar", postData)
      .then(response => {

        setdataCodInterno(response.data[0].cCas_Cod_Interno)
        setdataCodExterno(response.data[0].cCas_Cod_Externo)
      })
      .catch(error => {

      })
  }

  const FuncionListarComboMoneda = async () => {
    var postData = {
      Prefijo: "MO",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataComboMoneda(response.data)

      })
      .catch(error => {

      })
  }
  const FuncionAgregarHLaboradas = async () => {
    var postData = {
      nCas_Id: getHLaboradas.nCas_Id,
      cLab_Tab_Tipo: getHLaboradas.cLab_Tab_Tipo,
      nLab_Hora: getHLaboradas.nLab_Hora,
      dLab_Fecha: getHLaboradas.dLab_Fecha,
      cLab_Detalle: getHLaboradas.cLab_Detalle,
      nUsu_Id: getHLaboradas.nUsu_Id,
      bLab_PrecioFijo: getHLaboradas.bLab_PrecioFijo,
      tLab_HoraFacturable: getHLaboradas.tLab_HoraFacturable,
      cLab_Tab_Moneda: getHLaboradas.cLab_Tab_Moneda,
      nLab_Total: getHLaboradas.nLab_Total,
      bLab_Estado: getHLaboradas.bLab_Estado,
      nLab_TasaXHora: getHLaboradas.nLab_TasaXHora,
    }

    await instance
      .post("/Lab/Insertar", postData)
      .then(response => {
        FuncionListarHorasLaboradas()

        setModalInsertar(!modalInsertar)
        Swal.fire({
          title: "Mensaje",
          text: "Agregado exitosamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        })
      })
      .catch(error => {

      })
  }
  const FuncionActualizarHLaboradas = async () => {
    var postData = {
      nLab_Id: getHLaboradas.nLab_Id,
      nCas_Id: getHLaboradas.nCas_Id,
      cLab_Tab_Tipo: getHLaboradas.cLab_Tab_Tipo,
      nLab_Hora: getHLaboradas.nLab_Hora,
      dLab_Fecha: getHLaboradas.dLab_Fecha,
      cLab_Detalle: getHLaboradas.cLab_Detalle,
      nUsu_Id: getHLaboradas.nUsu_Id,
      bLab_PrecioFijo: getHLaboradas.bLab_PrecioFijo,
      tLab_HoraFacturable: getHLaboradas.tLab_HoraFacturable,
      cLab_Tab_Moneda: getHLaboradas.cLab_Tab_Moneda,
      nLab_Total: getHLaboradas.nLab_Total,
      bLab_Estado: getHLaboradas.bLab_Estado,
      nLab_TasaXHora: getHLaboradas.nLab_TasaXHora,
    }


    if (postData.bLab_PrecioFijo == "NO") {
      postData.bLab_PrecioFijo = 0
    }
    else {
      postData.bLab_PrecioFijo = 1
    }

    await instance
      .post("/Lab/Actualizar", postData)
      .then(response => {
        FuncionListarHorasLaboradas()

        setModalActualizar(!modalActualizar)
        Swal.fire({
          title: "Mensaje",
          text: "Actualizado exitosamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        })
      })
      .catch(error => {

      })

  }

  const FormatoMoneda = (moneda) => {
    return moneda = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(moneda)
  }
  const FuncionEliminarHLaboradas = async nLab_Id => {
    var postData = {
      nLab_Id: Number.parseInt(nLab_Id),
    }
    await instance
      .post("/Lab/Eliminar", postData)
      .then(response => {

        Swal.fire({
          title: "Mensaje",
          text: "Eliminado exitosamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        })
        FuncionListarHorasLaboradas()
      })
      .catch(error => {

      })
  }
  const UsuariosSelect = dataComboUsuario.map((row, i) => ({
    value: row.nUsu_ID,
    label: row.cUsu_Nombres,
    color: "#556ee6",
  }))
  const datas = {
    columns: [
      {
        label: "Fecha",
        field: "dLab_Fecha2",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "N° Caso",
        field: "cCas_Titulo",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Detalle",
        field: "cLab_Detalle",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Horas laboradas",
        field: "nLab_Hora",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Horas Facturables",
        field: "tLab_HoraFacturable",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Tasa por Hora",
        field: "nLab_TasaXHora",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Fijo",
        field: "bLab_PrecioFijo",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Total",
        field: "nLab_Total2",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
    ],
    rows: data,
  }


  const Acciones = (HLaboradas, caso) => {

    if (caso == "Editar") {
      console.log(HLaboradas)
      setCaso(HLaboradas.nCas_Id)
      setHLaboradas(HLaboradas)
      FuncionListarComboUsuario(HLaboradas.nCas_Id);
      //FuncionGetMonedaMonto_UsuarioCaso(HLaboradas.nCas_Id, HLaboradas.nUsu_Id);

      let tLab_HoraFacturable = HLaboradas.tLab_HoraFacturable;

      let [tLab_HoraFacturable_H, tLab_HoraFacturable_M] = tLab_HoraFacturable.split(":");
      setHLaboradas(prevState => ({
          ...prevState,
          tLab_HoraFacturable_H: tLab_HoraFacturable_H,
          tLab_HoraFacturable_M: tLab_HoraFacturable_M,
        })
      );

   
      setErrores(Validacion(HLaboradas));
      abrirCerrarModal(2)
    } else if (caso == "Detalle") {

      setHLaboradas(HLaboradas)
      console.log(HLaboradas);
      setCaso(HLaboradas.nCas_Id)
      FuncionListarComboUsuario(HLaboradas.nCas_Id);

      FuncionGetMonedaMonto_UsuarioCaso(HLaboradas.nCas_Id, HLaboradas.nUsu_Id);
      setHLaboradas(prevState => ({
        ...prevState,
        nUsu_Id: HLaboradas.nUsu_Id,
      }))
      abrirCerrarModal(3)
    }
  }

  const ReloadModalHLab = async () => {
    setModalInsertar(!modalInsertar)
    FuncionListarHorasLaboradas()
  }

  const FuncionGetMonedaMonto_UsuarioCaso = async (nCas_Id, nUsu_Id) => {
    var postData = {
      _nCas_Id: nCas_Id,
      _nUsu_Id: nUsu_Id,
    }

    await instance
      .post("/Casos/GetMonedaMonto_UsuarioCaso", postData)
      .then(response => {



        setHLaboradas(prevState => ({
          ...prevState,
          nLab_TasaXHora: response.data[0].nCas_Monto,
          cLab_Tab_Moneda: response.data[0].cCas_TabMoneda,
        }))

        var HorasLaborables = document.getElementById("tLab_HoraFacturable").value
        var TasaXHora = response.data[0].nCas_Monto
        var Valor1 = HorasLaborables.split(":")
        var N1 = Valor1[0]
        var N2 = Valor1[1]
        var Multi = N1 * TasaXHora
        var NVALOR1 = TasaXHora / 60

        var NVALOR2 = NVALOR1 * N2

        let Total = Multi + NVALOR2

        setHLaboradas(prevState => ({
          ...prevState,
          nLab_Total: Total.toFixed(2),
        }))
      })
      .catch(error => {

      })
  }

  const FuncionValidarFormulario = async () => {
    if (Object.keys(getErrores).length === 0) {
      FuncionActualizarHLaboradas()
    } else {
      setValidData(true);
      Swal.fire({
        title: "Mensaje",
        text: "Complete los campos requeridos",
        icon: "error",
        confirmButtonText: "Aceptar",
      })
    }
  }
  const handleBlur = e => {
    handleChange(e)
    setErrores(Validacion(getHLaboradas))

  }

  const BluerChange = e => {
    const { name, value } = e.target
    if (name == "nLab_TasaXHora") {
      setHLaboradas(prevState => ({
        ...prevState,
        [name]: getHLaboradas.nLab_TasaXHoraFormat,
      }))
    }
  }
  const FocusChange = e => {
    const { name, value } = e.target
    if (name == "nLab_TasaXHora") {
      setHLaboradas(prevState => ({
        ...prevState,
        [name]: getHLaboradas.nLab_TasaXHoraOld,
      }))
    }
  }
  const FuncionListarPermisos = async () => {
    var postData = {
      nUsu_ID: parseInt(getDecryptedItem("RolID"))
    }
    await instance
      .post("/Usuario/Acceso", postData)
      .then(response => {
        let arrayPermisos = response.data;
        const permiso = arrayPermisos.find(element => element.cUsu_Modulo == "ME10");

        if (permiso.bUsu_Editar == 1) {
          setHiddenBtnEditar(false);
        }
        else {
          setHiddenBtnEditar(true);
        }
        if (permiso.bUsu_Eliminar == 1) {
          setHiddenBtnEliminar(false);
        }
        else {
          setHiddenBtnEliminar(true);
        }
      })
      .catch(error => {

      })
  }

  const FuncionListarUsuarios = async () => {
    var postData = {
    }
    await instance
      .get("/Usuario/Listar", postData)
      .then(response => {

        setFiltroComboUsuario(response.data)
      })
      .catch(error => {

      })
  }

  useEffect(() => {

    if (getDecryptedItem("RolID") == 9) {
      setHiddenCliente(true);
      FuncionListarCasos(null)
    }

    FuncionListarUsuarios();
    FuncionListarPermisos();
    //FuncionListarComboUsuario()   //Registrado
    FuncionListarHorasLaboradas()

    FuncionListarComboTipoHL()    //Registrado
    FuncionListarComboMoneda()    //Registrado
    FuncionListarCliente()
    let rol = getDecryptedItem("RolID");
    if (rol !== '9') {
      localStorage.removeItem('CLIID');
      localStorage.removeItem('nCas_Id');
    }
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        {/* add meta title */}
        <MetaTags>
          <title>Horas Laboradas | GpsLegal</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="GpsLegal" breadcrumbItem="Horas Laboradas" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div>
                    <button
                      type="button"
                      className="btn btn-danger waves-effect waves-light"
                      data-toggle="modal"
                      data-target="#myModal"
                      onClick={() => abrirCerrarModal(1)}
                      hidden={hiddenBtnEditar}
                    >
                      <i style={{ fontSize: "15px" }} className="icon-nuevo"></i> Nueva hora(s)
                    </button>{" "}
                    <button
                      type="button"
                      className="btn btn-danger waves-effect waves-light"
                      data-toggle="modal"
                      data-target="#myModal"
                      onClick={() => abrirCerrarModal(4)}
                    >
                      <i style={{ fontSize: "15px" }} className="icon-filtro"></i> Filtros
                    </button>
                  </div>
                  {(() => {
                    if (modalInsertar == true) {
                      return <>
                        <ModalHorasLaboradas show={modalInsertar}
                          onCloseClick={() => abrirCerrarModal(1)}
                          ReloadLista={() => ReloadModalHLab()}
                          Titulo="Nueva Hora Laborada"
                        />
                      </>
                    }
                  })()}

                  <Modal size="lg" isOpen={modalActualizar}>
                    <div
                      className="modal-header"
                      style={{
                        background:
                          "linear-gradient( 180deg , #E5163A, #AF2240)",
                      }}
                    >
                      <h5
                        style={{ color: "#fff" }}
                        className="modal-title mt-0"
                        id="myModalLabel"
                      >
                        Editar horas laboradas
                      </h5>
                      <button
                        type="button"
                        onClick={() => abrirCerrarModal(2)}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="mb-3 row">
                        <div className="col-md-6">
                          <select disabled
                            className={getErrores.nCli_Id && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                            onChange={handleChangeFiltroCaso}
                            value={getHLaboradas && getHLaboradas.nCli_Id} >
                            <option value="00">Seleccionar...</option>
                            {dataCliente.map((row, i) => (
                              <option key={i} value={row.nCli_Id}>
                                {row.cCli_NombreCompleto}
                              </option>
                            ))}
                          </select>
                          <label className="col-form-label labelDown">
                            Seleccione Cliente
                          </label>
                        </div>
                        <div className="col-md-6">
                          <select
                            className="form-select inputDown"
                            name="nCas_Id"
                            onChange={handleChange}
                            value={getHLaboradas && getHLaboradas.nCas_Id}
                            disabled
                          >
                            <option value="0">Seleccionar...</option>
                            {dataCaso.map((row, i) => (
                              <option key={i} value={row.nCas_Id}>
                                {row.cCas_Titulo}
                              </option>
                            ))}
                          </select>
                          <label className="col-form-label labelDown">
                            Seleccione Caso
                          </label>
                        </div>
                      </div>
                      <div className="mb-1 row">
                        <div style={{ height: "20px;" }} className="col-md-5">
                          <label
                            htmlFor="example-text-input"
                            className="col-md-12 col-form-label"
                            style={{ marginLeft: "5px" }}
                          >
                            Cód Interno: {getHLaboradas.cCas_Cod_Interno}
                          </label>
                        </div>
                        <div className="col-md-7">
                          <label
                            htmlFor="example-text-input"
                            className="col-md-12 col-form-label"
                            style={{ marginLeft: "2px" }}
                          >
                            Cód Externo:{getHLaboradas.cCas_Cod_Externo}
                          </label>
                        </div>
                      </div>
                      <div className="mb-3 row">
                        <div className="col-md-4">
                          <div className="d-flex align-items-center">
                            <select
                              className="form-select inputDown"
                              name="nLab_Hora_H"
                              onChange={handleChange}
                              value={getHLaboradas && getHLaboradas.nLab_Hora?.split(":")[0]}
                            >
                              {Array.from({ length: 24 }, (_, i) => (
                                <option key={i} value={i.toString().padStart(2, '0')}>
                                  {i.toString().padStart(2, '0')}
                                </option>
                              ))}
                            </select>
                            <span className="mx-2">:</span>
                            <select
                              className="form-select inputDown"
                              name="nLab_Hora_M"
                              onChange={handleChange}
                              value={getHLaboradas && getHLaboradas.nLab_Hora?.split(":")[1]}
                            >
                              {Array.from({ length: 60 }, (_, i) => (
                                <option key={i} value={i.toString().padStart(2, '0')}>
                                  {i.toString().padStart(2, '0')}
                                </option>
                              ))}
                            </select>
                          </div>
                          {/* <input
                            className="form-control"
                            type="time"
                            placeholder="Horas laboradas"
                            id="nLab_Hora"
                            name="nLab_Hora"

                            onChange={handleChange}
                            value={getHLaboradas && getHLaboradas.nLab_Hora}
                        /> */}
                          <label className="col-form-label labelDown">
                            Horas laboradas (hh:mm)
                          </label>
                        </div>

                        <div className="col-md-4">
                          <input
                            className="form-control"
                            type="date"
                            name="dLab_Fecha"
                            onChange={handleChange}
                            value={getHLaboradas && getHLaboradas.dLab_Fecha}
                          />
                          <label className="col-form-label labelDown">
                            Fecha dd/mm/aaaa
                          </label>
                        </div>
                        <div className="col-md-4">
                          <select
                            className={getErrores.cLab_Tab_Tipo && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                            name="cLab_Tab_Tipo"
                            onChange={handleChange}
                            value={getHLaboradas && getHLaboradas.cLab_Tab_Tipo}
                            onBlur={handleBlur}
                          >
                            <option value="0">Seleccionar...</option>
                            {dataComboTipoHL.map((row, i) => (
                              <option key={i} value={row.cTabCodigo}>
                                {row.cTabNombre}
                              </option>
                            ))}
                          </select>
                          <label className="col-form-label labelDown"> Tipo de labor</label> {getErrores.cLab_Tab_Tipo && <label className="col-form-label labelDown labelRed">({getErrores.cLab_Tab_Tipo})</label>}
                        </div>
                      </div>
                      <div className="mb-3 row">
                        <div className="col-md-12">
                          <textarea
                            className="form-control"
                            type="text"
                            placeholder="Descripción"
                            name="cLab_Detalle"
                            onChange={handleChange}
                            value={getHLaboradas && getHLaboradas.cLab_Detalle}
                            maxLength="200"
                          ></textarea>
                          <label className="col-form-label labelDown">
                            Ingrese descripción
                          </label>
                        </div>
                      </div>
                      <div className="mb-3 row">
                        <div className="col-md-6">
                          <select
                            className={getErrores.nUsu_Id && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                            name="nUsu_Id"
                            onChange={handleChange}
                            value={getHLaboradas && getHLaboradas.nUsu_Id}
                            onBlur={handleBlur}
                          >
                            <option value="0">Seleccionar...</option>
                            {dataComboUsuario.map((row, i) => (
                              <option key={i} value={row.nUsu_Id}>
                                {row.cUsu_Nombres}
                              </option>
                            ))}
                          </select>
                          <label className="col-form-label labelDown">Persona que factura</label> {getErrores.nUsu_Id && <label className="col-form-label labelDown labelRed">({getErrores.nUsu_Id})</label>}
                        </div>
                        <div className="col-md-6">
                          <select
                            className={getErrores.cLab_Tab_Moneda && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                            name="cLab_Tab_Moneda"
                            onChange={handleChange}
                            value={getHLaboradas && getHLaboradas.cLab_Tab_Moneda}
                            onBlur={handleBlur}
                          >
                            <option value="0">Seleccionar</option>
                            {dataComboMoneda.map((row, i) => (
                              <option key={i} value={row.cTabCodigo}>
                                {row.cTabNombre}
                              </option>
                            ))}
                          </select>
                          <label className="col-form-label labelDown">Seleccione moneda</label> {getErrores.cLab_Tab_Moneda && <label className="col-form-label labelDown labelRed">({getErrores.cLab_Tab_Moneda})</label>}
                        </div>
                      </div>
                      <div className="mb-3 row">
                        <div className="mb-3 row">
                          <div
                            className="col-md-3"
                            style={{ paddingLeft: "50px" }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheckcolor1"
                              onChange={handleChangeCheckFacturable}
                              checked={chekedFacturable}
                              value={chekedFacturable}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customCheckcolor1"
                              style={{ paddingLeft: "5px" }}
                            >
                              Facturable
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row" style={{ display: HiddenFacturable }}>
                          <div
                            className="col-md-3"
                            style={{ paddingLeft: "50px" }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheckcolor1"
                              onChange={handleChangeCheckPreciFijo}
                              checked={chekedPreciFijo}
                              value={chekedPreciFijo}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customCheckcolor1"
                              style={{ paddingLeft: "5px" }}
                            >
                              Precio Fijo
                            </label>
                          </div>
                          <div className="col-md-3" style={{ display: HiddenPreciFijo }}>
                            <div className="d-flex align-items-center">
                              <select
                                className="form-select inputDown"
                                name="tLab_HoraFacturable_H"
                                onChange={handleChange}
                                value={getHLaboradas && getHLaboradas.tLab_HoraFacturable?.split(":")[0]}
                              >
                                {Array.from({ length: 24 }, (_, i) => (
                                  <option key={i} value={i.toString().padStart(2, '0')}>
                                    {i.toString().padStart(2, '0')}
                                  </option>
                                ))}
                              </select>
                              <span className="mx-2">:</span>
                              <select
                                value={getHLaboradas && getHLaboradas.tLab_HoraFacturable?.split(":")[1]}
                                className="form-select inputDown"
                                name="tLab_HoraFacturable_M"
                                onChange={handleChange}

                              >
                                {Array.from({ length: 60 }, (_, i) => (
                                  <option key={i} value={i.toString().padStart(2, '0')}>
                                    {i.toString().padStart(2, '0')}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {/* <input
                              className="form-control"
                              type="time"
                              placeholder="Tiempo facturable"
                              name="tLab_HoraFacturable"
                              id="tLab_HoraFacturable"
                              onChange={handleChange}
                              value={getHLaboradas && getHLaboradas.tLab_HoraFacturable}
                            /> */}
                            <label className="col-form-label labelDown">
                              Tiempo facturable
                            </label>
                          </div>
                          <div className="col-md-3" style={{ display: HiddenPreciFijo }}>
                            <input
                              className={getErrores.nLab_TasaXHora && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                              type="text"
                              placeholder="Tarifa por hora"
                              name="nLab_TasaXHora"
                              id="nLab_TasaXHora"
                              onChange={handleChange}
                              onBlur={BluerChange}
                              onFocus={FocusChange}
                              value={getHLaboradas && getHLaboradas.nLab_TasaXHora}
                              style={{ textAlign: 'right' }}
                            />
                            <label className="col-form-label labelDown">Tarifa por hora</label> {getErrores.nLab_TasaXHora && <label className="col-form-label labelDown labelRed">({getErrores.nLab_TasaXHora})</label>}

                          </div>
                          <div className="col-md-3">
                            <input
                              disabled
                              className="form-control"
                              type="text"
                              placeholder="Total"
                              name="nLab_Total"
                              onChange={handleChange}
                              value={getHLaboradas && FormatoMoneda(getHLaboradas.nLab_Total)}
                              style={{ textAlign: 'right' }}
                            />
                            <label className="col-form-label labelDown">
                              Monto Total
                            </label>
                          </div>
                          <div className="mb-3 row" style={{ marginTop: '20px', marginBottom: '0px' }}>
                            <div className="col-md-12">
                              <label className="col-form-label labelDown labelRed">(*) Campos obligatorios</label>
                              <label className="col-form-label labelDown"></label>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        onClick={() => abrirCerrarModal(2)}
                        className="btn btn-secondary waves-effect"
                        data-dismiss="modal"
                      >
                        Cerrar
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger waves-effect waves-light"
                        onClick={() => FuncionValidarFormulario()}
                      >
                        Guardar
                      </button>
                    </div>
                  </Modal>
                  <Modal size="lg" isOpen={modalDetalle}>
                    <div
                      className="modal-header"
                      style={{
                        background:
                          "linear-gradient( 180deg , #E5163A, #AF2240)",
                      }}
                    >
                      <h5
                        style={{ color: "#fff" }}
                        className="modal-title mt-0"
                        id="myModalLabel"
                      >
                        Detalle horas laboradas
                      </h5>
                      <button
                        type="button"
                        onClick={() => abrirCerrarModal(3)}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="mb-3 row">
                        <div className="col-md-6">
                          <select disabled className="form-select inputDown" onChange={handleChangeFiltroCaso} value={getHLaboradas && getHLaboradas.nCli_Id} >
                            <option value="00">Seleccionar...</option>
                            {dataCliente.map((row, i) => (
                              <option key={i} value={row.nCli_Id}>
                                {row.cCli_NombreCompleto}
                              </option>
                            ))}
                          </select>
                          <label className="col-form-label labelDown">
                            Seleccione Cliente
                          </label>
                        </div>
                        <div className="col-md-6">
                          <select
                            className="form-select inputDown"
                            name="nCas_Id"
                            onChange={handleChange}
                            value={getHLaboradas && getHLaboradas.nCas_Id}
                            disabled
                          >
                            <option value="0">Seleccionar...</option>
                            {dataCaso.map((row, i) => (
                              <option key={i} value={row.nCas_Id}>
                                {row.cCas_Titulo}
                              </option>
                            ))}
                          </select>
                          <label className="col-form-label labelDown">
                            Seleccione Caso
                          </label>
                        </div>
                      </div>
                      <div className="mb-1 row">
                        <div style={{ height: "20px;" }} className="col-md-5">
                          <label
                            htmlFor="example-text-input"
                            className="col-md-12 col-form-label"
                            style={{ marginLeft: "5px" }}
                          >
                            Cód Interno: {getHLaboradas.cCas_Cod_Interno}
                          </label>
                        </div>
                        <div className="col-md-7">
                          <label
                            htmlFor="example-text-input"
                            className="col-md-12 col-form-label"
                            style={{ marginLeft: "2px" }}
                          >
                            Cód Externo:{getHLaboradas.cCas_Cod_Externo}
                          </label>
                        </div>
                      </div>
                      <div className="mb-3 row">
                        <div className="col-md-4">
                          <input
                            disabled
                            className="form-control"
                            type="time"
                            placeholder="Horas laboradas"
                            name="nLab_Hora"
                            id="nLab_Hora"
                            onChange={handleChange}
                            value={getHLaboradas && getHLaboradas.nLab_Hora}
                          />
                          <label className="col-form-label labelDown">
                            Horas laboradas (hh:mm)
                          </label>
                        </div>
                        <div className="col-md-4">
                          <input
                            disabled
                            className="form-control"
                            type="date"
                            name="dLab_Fecha"
                            onChange={handleChange}
                            value={getHLaboradas && getHLaboradas.dLab_Fecha}
                          />
                          <label className="col-form-label labelDown">
                            Fecha dd/mm/aaaa
                          </label>
                        </div>
                        <div className="col-md-4">
                          <select
                            disabled
                            className="form-select"
                            name="cLab_Tab_Tipo"
                            onChange={handleChange}
                            value={getHLaboradas && getHLaboradas.cLab_Tab_Tipo}
                            onBlur={handleBlur}
                          >
                            <option value="0">Seleccionar...</option>
                            {dataComboTipoHL.map((row, i) => (
                              <option key={i} value={row.cTabCodigo}>
                                {row.cTabNombre}
                              </option>
                            ))}
                          </select>
                          <label className="col-form-label labelDown"> Tipo de labor</label> {getErrores.cLab_Tab_Tipo && <label className="col-form-label labelDown labelRed">({getErrores.cLab_Tab_Tipo})</label>}
                        </div>
                      </div>
                      <div className="mb-3 row">
                        <div className="col-md-12">
                          <textarea
                            disabled
                            className="form-control"
                            type="text"
                            placeholder="Descripción"
                            name="cLab_Detalle"
                            onChange={handleChange}
                            value={getHLaboradas && getHLaboradas.cLab_Detalle}
                            maxLength="200"
                          ></textarea>
                          <label className="col-form-label labelDown">
                            Ingrese descripción
                          </label>
                        </div>
                      </div>
                      <div className="mb-3 row">
                        <div className="col-md-6">
                          <select
                            disabled
                            className="form-select inputDown"
                            name="nUsu_Id"
                            onChange={handleChange}
                            value={getHLaboradas && getHLaboradas.nUsu_Id}
                            onBlur={handleBlur}
                          >
                            <option value="0">Seleccionar...</option>
                            {dataComboUsuario.map((row, i) => (
                              <option key={i} value={row.nUsu_Id}>
                                {row.cUsu_Nombres}
                              </option>
                            ))}
                          </select>
                          <label className="col-form-label labelDown">Persona que factura</label> {getErrores.nUsu_Id && <label className="col-form-label labelDown labelRed">({getErrores.nUsu_Id})</label>}
                        </div>
                        <div className="col-md-6">
                          <select
                            disabled
                            className="form-select inputDown"
                            name="cLab_Tab_Moneda"
                            onChange={handleChange}
                            value={getHLaboradas && getHLaboradas.cLab_Tab_Moneda}
                            onBlur={handleBlur}
                          >
                            <option value="0">Seleccionar</option>
                            {dataComboMoneda.map((row, i) => (
                              <option key={i} value={row.cTabCodigo}>
                                {row.cTabNombre}
                              </option>
                            ))}
                          </select>
                          <label className="col-form-label labelDown">Seleccione moneda</label> {getErrores.cLab_Tab_Moneda && <label className="col-form-label labelDown labelRed">({getErrores.cLab_Tab_Moneda})</label>}
                        </div>
                      </div>
                      <div className="mb-3 row">
                        <div className="mb-3 row">
                          <div
                            className="col-md-3"
                            style={{ paddingLeft: "50px" }}
                          >
                            <input
                              disabled
                              type="checkbox"
                              className="form-check-input"
                              id="customCheckcolor1"
                              onChange={handleChangeCheckFacturable}
                              checked={chekedFacturable}
                              value={chekedFacturable}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customCheckcolor1"
                              style={{ paddingLeft: "5px" }}
                            >
                              Facturable
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row" style={{ display: HiddenFacturable }}>
                          <div
                            className="col-md-3"
                            style={{ paddingLeft: "50px" }}
                          >
                            <input
                              disabled
                              type="checkbox"
                              className="form-check-input"
                              id="customCheckcolor1"
                              onChange={handleChangeCheckPreciFijo}
                              checked={chekedPreciFijo}
                              value={chekedPreciFijo}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customCheckcolor1"
                              style={{ paddingLeft: "5px" }}
                            >
                              Precio Fijo
                            </label>
                          </div>
                          <div className="col-md-3" style={{ display: HiddenPreciFijo }}>
                            <input
                              disabled
                              className="form-control"
                              type="time"
                              placeholder="Tiempo facturable"
                              name="tLab_HoraFacturable"
                              id="tLab_HoraFacturable"
                              onChange={handleChange}
                              value={getHLaboradas && getHLaboradas.tLab_HoraFacturable}
                            />
                            <label className="col-form-label labelDown">
                              Tiempo facturable
                            </label>
                          </div>
                          <div className="col-md-3" style={{ display: HiddenPreciFijo }}>
                            <input
                              disabled
                              className="form-control"
                              type="text"
                              placeholder="Tarifa por hora"
                              name="nLab_TasaXHora"
                              id="nLab_TasaXHora"
                              onChange={handleChange}
                              onBlur={BluerChange}
                              onFocus={FocusChange}
                              value={getHLaboradas && getHLaboradas.nLab_TasaXHora}
                              style={{ textAlign: 'right' }}
                            />
                            <label className="col-form-label labelDown">Tarifa por hora</label> {getErrores.nLab_TasaXHora && <label className="col-form-label labelDown labelRed">({getErrores.nLab_TasaXHora})</label>}

                          </div>
                          <div className="col-md-3">
                            <input
                              disabled
                              className="form-control"
                              type="text"
                              placeholder="Total"
                              name="nLab_Total"
                              onChange={handleChange}
                              value={getHLaboradas && FormatoMoneda(getHLaboradas.nLab_Total)}
                              style={{ textAlign: 'right' }}
                            />
                            <label className="col-form-label labelDown">
                              Monto Total
                            </label>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        onClick={() => abrirCerrarModal(3)}
                        className="btn btn-secondary waves-effect"
                        data-dismiss="modal"
                      >
                        Cerrar
                      </button>
                    </div>
                  </Modal>
                  <Modal size="lg" isOpen={modalFiltro}>
                    <div
                      className="modal-header"
                      style={{
                        background:
                          "linear-gradient( 180deg , #E5163A, #AF2240)",
                      }}
                    >
                      <h5
                        style={{ color: "#fff" }}
                        className="modal-title mt-0"
                        id="myModalLabel"
                      >
                        Seleccione criterio(s) de búsqueda
                      </h5>
                      <button
                        type="button"
                        onClick={() => abrirCerrarModal(4)}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <strong>Fechas (rango)</strong>
                      <hr style={{ color: "#000", marginTop: "0px" }} />
                      <div className="mb-3 row">
                        <div className="col-md-1" style={{ width: "20px" }}></div>
                        <div className="col-md-5">
                          <input
                            className="form-control inputDown"
                            type="date" name="FechaInicial" value={getFiltro && getFiltro.FechaInicial} onChange={handleChangeFiltro}
                          />
                          <label className="col-form-label labelDown">
                            Inicio*
                          </label>
                        </div>
                        <div className="col-md-1" style={{ width: "20px" }}></div>
                        <div className="col-md-5">
                          <input
                            className="form-control inputDown"
                            type="date" name="FechaFin" value={getFiltro && getFiltro.FechaFin} onChange={handleChangeFiltro}
                          />
                          <label className="col-form-label labelDown">
                            Fin*
                          </label>
                        </div>
                      </div>
                      <hr style={{ color: "#000", marginTop: "0px" }} />
                      <div className="mb-3 row">
                        <div className="col-md-1" hidden={hiddenCliente} style={{ width: "20px" }}>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckcolor1"
                            checked={chekedValor1}
                            value={chekedValor1}
                            onChange={handleChangeValor1}
                            style={{ transform: "scale(1.5)", marginTop: "10px" }}
                          />
                        </div>
                        <div className="col-md-5" hidden={hiddenCliente}>
                          <select className="form-select inputDown" disabled={Valor1} name="nCli_Id" id="nCli_Id" onChange={handleChangeFiltro}>
                            <option value="00">Seleccionar...</option>
                            {dataCliente.map((row, i) => (
                              <option key={i} value={row.nCli_Id}>
                                {row.cCli_NombreCompleto}
                              </option>
                            ))}
                          </select>
                          <label className="col-form-label labelDown">
                            Cliente
                          </label>
                        </div>
                        <div className="col-md-1" style={{ width: "20px" }}>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckcolor1"
                            checked={chekedValor2}
                            value={chekedValor2}
                            onChange={handleChangeValor2}
                            style={{ transform: "scale(1.5)", marginTop: "10px" }}
                          />
                        </div>
                        <div className="col-md-5">
                          <select className="form-select inputDown" id="nCas_Id" name="nCas_Id" disabled={Valor2} onChange={handleChangeFiltro}>
                            <option value="0">Seleccionar...</option>
                            {dataCaso.map((row, i) => (
                              <option key={i} value={row.nCas_Id}>
                                {row.cCas_Titulo}
                              </option>
                            ))}
                          </select>
                          <label className="col-form-label labelDown">
                            Caso
                          </label>
                        </div>

                      </div>
                      <div className="mb-3 row">
                        <div className="col-md-1" style={{ width: "20px" }}>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckcolor1"
                            checked={chekedValor3}
                            value={chekedValor3}
                            onChange={handleChangeValor3}
                            style={{ transform: "scale(1.5)", marginTop: "10px" }}
                          />
                        </div>
                        <div className="col-md-5">
                          <select className="form-select inputDown" id="nUsu_Id" name="nUsu_Id" disabled={Valor3} onChange={handleChangeFiltro}>
                            <option value="0">Seleccionar...</option>
                            {filtroComboUsuario.map((row, i) => (
                              <option key={i} value={row.nUsu_ID}>
                                {row.cUsu_Nombres}
                              </option>
                            ))}
                          </select>
                          <label className="col-form-label labelDown">
                            Persona que factura
                          </label>
                        </div>
                      </div>

                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        onClick={() => abrirCerrarModal(4)}
                        className="btn btn-secondary waves-effect"
                        data-dismiss="modal"
                      >
                        Cerrar
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger waves-effect waves-light"
                        onClick={() => FuncionBuscarHorasLaboradas()}
                      >
                        Filtrar
                      </button>
                    </div>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>


                  <CardTitle style={{ fontSize: "20px", color: "#aa1b0E", fontWeight: "normal" }}>
                    <span style={{ color: "#aa1b0E" }}> Listado de horas laboradas{" "}</span>
                  </CardTitle>

                  {data.map((row, i) => (
                    <div key={i} style={{ borderBottom: "1px solid #bbb" }}>
                      <div className="container-fluid" style={{ height: 'auto', padding: '0px' }}>
                        <div className="row col-md-12" style={{ padding: '0px', marginLeft: '2px' }}>
                          {/* <div className="col-md-1" style={{ width: '5%', height: '100px', padding: '0px' }}>
                            <input
                              type="checkbox"
                              className="checkbox"
                              name="dinamico"
                              style={{ paddingLeft: '13px', marginLeft: '23%' }}
                              id={"id" + row.nLab_Id}
                            />
                          </div> */}
                          <div className="col-md-10" style={{ width: '76%', paddingLeft: '6px' }}>
                            <div className="row col-md-12 titulo Texto-Limit" style={{ marginTop: '10px' }}>
                              <Link className="Texto-Limit col-md-1" data-tip data-for={"tooltip" + row.cCas_Titulo} style={{ width: 'auto', maxWidth: '40%' }} to="/dashboard-Casos" onClick={() => SelectDetalle(row.nCas_Id, row.nCli_Id)} >
                                <a className="" style={{ color: "#6B6B6B" }} >{row.cCas_Titulo}</a>
                                <ReactTooltip id={"tooltip" + row.cCas_Titulo}>
                                  <a style={{ fontSize: '13px', display: 'inline-block', fontWeight: 'normal' }}>Ir al caso</a>
                                </ReactTooltip>
                              </Link>
                              {" "}/{" "}
                              <Link className="Texto-Limit col-md-5" data-tip data-for={"tooltipEvento"} onClick={() => Acciones(row, "Detalle")} >
                                <a className="" style={{ color: "#032D5E" }} >{row.cLab_Detalle}</a>{"     "}
                                <ReactTooltip id={"tooltipEvento"}>
                                  <a style={{ fontSize: '13px', display: 'inline-block', fontWeight: 'normal' }}>Ver el detalle</a>
                                </ReactTooltip>
                              </Link>
                            </div>
                            <div className="titulo" style={{ marginTop: '2px', height: '15px', paddingTop: '4px' }}>
                              <label className="text-detalle">
                                < i style={{ fontSize: "15px" }} className="icon-horas-laboradas1"></i> H.Laboradas :
                                {row.nLab_Hora} {"  "}{" "}
                                <i style={{ fontSize: "15px", color: "#139c11" }} class="icon-horas-laboradas1"></i>{" "}
                                <a style={{ color: "#139c11", cursor: 'default' }}>
                                  {" "}
                                  H.Facturables :{row.tLab_HoraFacturable}{" "}
                                </a>
                              </label>
                            </div>
                            <div className="detalle Texto-Limit" style={{ backgroundColor: 'white', marginTop: '2px', paddingTop: '15px' }}>
                              <i style={{ fontSize: "15px" }} className="icon-Persona"></i>{" "}
                              <label className="text-detalle">
                                {row.cCli_NombreCompleto}{" "}
                              </label>{" "}
                              <i style={{ fontSize: "15px", color: "#2e33d9" }} className="icon-Egresos"></i>
                              {"  "}
                              <label
                                className="text-detalle"
                                style={{ color: "#2e33d9" }}
                              >
                                {row.nLab_Total2}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-2" style={{ width: '24%', padding: '0px' }}>
                            <div className="col-md-10 Texto-Limit" style={{ float: 'left', marginTop: '5px' }}>
                              <label className="" style={{ fontSize: '12px' }}>
                                CASO-{row.cCas_Cod_Externo}
                              </label>
                            </div>
                            <div className="" style={{ float: 'left', marginTop: '5px', }}>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  tag="a"
                                  className="dropdown-toggle"
                                >
                                  <i className="bx bx-dots-vertical-rounded" />
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem hidden={hiddenBtnEditar}
                                    onClick={() => Acciones(row, "Editar")}
                                  >
                                    Editar
                                  </DropdownItem>
                                  <DropdownItem hidden={hiddenBtnEliminar}
                                    onClick={() =>
                                      Swal.fire({
                                        title: "Mensaje",
                                        html:
                                          "Estas seguro de eliminar este registro",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonColor: "#3085d6",
                                        cancelButtonColor: "#d33",
                                        confirmButtonText: "Aceptar",
                                        cancelButtonText: "Cancelar",
                                      }).then(result => {
                                        if (result.isConfirmed) {
                                          FuncionEliminarHLaboradas(row.nLab_Id)
                                        }
                                      })
                                    }
                                  >
                                    Eliminar
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => Acciones(row, "Detalle")}
                                  >Detalle</DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <div className="col-md-12 titulo Texto-Limit" style={{ marginTop: '10px' }}>
                              <div className="row col-md-12 active" style={{ marginTop: '0px' }}>
                                <div className="col-md-2" style={{ width: '5%', paddingRight: '0px', marginRight: '0px' }}>
                                  <label className="text-detalle">
                                    {(() => {
                                      if (row.nLab_Total > 0) {
                                        return (
                                          <FontAwesomeIcon
                                            style={{ color: "#1BF26D" }}
                                            icon={faCircle}
                                          />
                                        )
                                      } {
                                        return (
                                          <FontAwesomeIcon
                                            style={{ color: "#F92828" }}
                                            icon={faCircle}
                                          />
                                        )
                                      }
                                    })()}{" "}
                                    {(() => {
                                      if (row.nLab_Total > 0) {
                                        return <a>Facturable</a>
                                      } else {
                                        return <a>No Facturable</a>
                                      }
                                    })()}{" "}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 titulo Texto-Limit" style={{ marginTop: '4px' }}>
                              <div className="row col-md-12 active" style={{ marginTop: '0px' }}>
                                <div className="col-md-2" style={{ width: '5%', paddingRight: '0px', marginRight: '0px' }}>
                                  <i style={{ fontSize: "15px" }} className="icon-calendario"></i>{" "}
                                  <label className="text-detalle" >
                                    {row.dLab_Fecha2}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default HorasLaboradas
