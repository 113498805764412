import PropTypes from 'prop-types'
import React, { Component, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import instance from '../../../config'
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"
import { getDecryptedItem, setEncryptedItem } from '../../../util';

const DocumentDropdown = props => {

    const [openClose, setopenClose] = useState(false);
    const [dataNov, setDataNov] = useState({})
    const [dataDoc, setDataDoc] = useState([])
    const baseUrl = process.env.REACT_APP_API_URL


    const abrirCerrarModal = Modal => {

        setopenClose(!openClose);
    }
    const FuncionListarCountDocument = async () => {
        var postData = {
            nUsu_ID: getDecryptedItem("ID") 
        }
        await instance
            .post("/Docs/CountV2", postData)
            .then(response => {
                setDataNov(response.data[0])
            })
            .catch(error => {

            })
    }
    const FuncionListarDocument = async () => {
        var postData = {
            nUsu_ID: getDecryptedItem("ID")
        }
        await instance
            .post("/Docs/ListV2", postData)
            .then(response => {
                setDataDoc(response.data)
            })
            .catch(error => {

            })
    }
    const saveLocalStorage = async (row) => {
        setEncryptedItem('CLIID', row.nCli_Id);
        setEncryptedItem('nCas_Id', row.nCas_Id);
    }

    useEffect(async () => {
        FuncionListarCountDocument();
        FuncionListarDocument();
    }, [])

    return (
        <React.Fragment>
            <Dropdown
                className="dropdown d-inline-block"
                tag="li"
                toggle={() => abrirCerrarModal()}
                isOpen={openClose}
            >
                <DropdownToggle
                    className="btn header-item noti-icon waves-effect"
                    tag="button"
                    id="page-header-notifications-dropdown"
                >
                    <i className="bx bx-dock-bottom" />
                    <span className="badge bg-danger rounded-pill" style={{ left: "18px", paddingLeft: "4px" }}>{dataNov.Total > 99 ? '99+' : dataNov.Total}</span>
                </DropdownToggle>

                <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
                    <div className="p-3">
                        <Row className="align-items-center">
                            <Col>
                                <b className="m-0"> {"Documentos por cargar"} {dataNov.Total} </b>
                            </Col>
                            <div className="col-auto">
                                <a href="#" className="small">
                                    {" "}
                                </a>
                            </div>
                        </Row>
                    </div>
                    <div data-simplebar="init" style={{ height: "350px" }}>
                        <div class="simplebar-wrapper" style={{ margin: 0 }}>
                            <div class="simplebar-height-auto-observer-wrapper">
                                <div class="simplebar-height-auto-observer"></div>
                            </div>
                            <div class="simplebar-mask">
                                <div class="simplebar-offset" style={{ right: 0, bottom: 0 }}>
                                    <div class="simplebar-content-wrapper" tabindex="0" role="region" aria-label="scrollable content" style={{ height: "70%" }}>
                                        <div class="simplebar-content" style={{ padding: 0 }}>
                                            {dataDoc.map((row, i2) => (
                                                <Link key={i2} to="/Documentos" className="text-reset notification-item">
                                                    <div className="media" onClick={() => {
                                                        saveLocalStorage(row)
                                                    }}>
                                                        <div className="avatar-xs me-3">
                                                            <span className="avatar-title bg-warning rounded-circle font-size-16">
                                                                <i className="bx bx-dock-bottom" />
                                                            </span>
                                                        </div>
                                                        <div className="media-body">
                                                            <h6 className="mt-0 mb-1">
                                                                Documento pendiente
                                                            </h6>
                                                            <div className="font-size-12 text-muted">
                                                                <p className="mb-1">
                                                                    {row.cTabNombre}
                                                                </p>
                                                                <p className="mb-1">
                                                                    {row.cCdo_Documento}
                                                                </p>
                                                                <p className="mb-1">
                                                                    {row.cCas_Titulo.length > 20 ? row.cCas_Titulo.substring(0, 20) + '...' : row.cCas_Titulo}/{row.cCli_NombreCompleto}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="simplebar-placeholder" style={{ width: "auto", height: "329px;" }}></div>
                        </div>
                        <div class="simplebar-track simplebar-horizontal" style={{ visibility: "hidden" }}>
                            <div class="simplebar-scrollbar" style={{ width: "0px", display: "none" }}></div>
                        </div>
                        <div class="simplebar-track simplebar-vertical" style={{ visibility: "hidden" }}>
                            <div class="simplebar-scrollbar" style={{ width: "0px", display: "none" }}></div>
                        </div>
                    </div>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    )




}
export default DocumentDropdown