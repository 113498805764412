import React, { Component, useState, useEffect, useContext } from "react"
import Select from "react-select"
import Swal from "sweetalert2"
import {
    Modal,
} from "reactstrap"
import instance from '../../config'
import ClienteContext from "pages/Menus/Context/Cliente/ClienteContext"
import ComboContext from "pages/Menus/Context/Combos/ComboContext"
import { getDecryptedItem } from "util"
const baseUrl = process.env.REACT_APP_API_URL
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
export const ModalRelacion = ({ show, onCloseClick, ReloadLista, relacion, Contition }) => {
    const comboContext = useContext(ComboContext);
    const [getRelacion, setRelacion] = useState({
        nRela_Id: "",
        nCas_Id: parseInt(getDecryptedItem('nCas_Id')), // encriptar (pendiente)
        nCli_Id: 0,    // encriptar (pendiente)
        cCas_Tab_Relacion: "ZA01",
    })
    const handleChange = e => {
        const { name, value } = e.target
        setRelacion(prevState => ({
            ...prevState,
            [name]: value,
        }))
    }
    const SaveRelacion = async () => {
        var postData = {
            nRela_Id: getRelacion.nRela_Id,
            nCas_Id: getRelacion.nCas_Id,
            nCli_Id: parseInt(getRelacion.nCli_Id),
            cCas_Tab_Relacion: getRelacion.cCas_Tab_Relacion,
        }

        let url = Contition == 1 ? "/relacion/RelacionUpdate" : "/relacion/RelacionInsertar"
        await instance
            .post(url, postData)
            .then(response => {
                Swal.fire({
                    title: "Mensaje",
                    text: "Agregado exitosamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })
                ReloadLista()
            }).catch(function (error) {
                Swal.fire({
                    title: "Mensaje",
                    text: "Intentelo mas tarde",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                })
            });
    }
    useEffect(() => {
        comboContext.FuncionListarGrupo()
        comboContext.FuncionListarCliente()
        if (Contition == 1) {
            setRelacion(prevState => ({
                ...prevState,
                nRela_Id: parseInt(relacion.nRela_Id),
                nCas_Id: parseInt(relacion.nCas_Id),
                nCli_Id: parseInt(relacion.nCli_Id),
                cCas_Tab_Relacion: relacion.cCas_Tab_Relacion,
            }))
        }
    }, [])
    return (
        <Modal isOpen={show}>
            <div
                className="modal-header ModalHeaderColor"
            >
                <h5
                    style={{ color: "#fff" }}
                    className="modal-title mt-0"
                    id="myModalLabel"
                >
                    {Contition == 0 ? "Añadir" : "Editar"} Relacion
                </h5>
                <button
                    type="button"
                    onClick={() => onCloseClick()}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="mb-3 row" style={{ marginTop: "15px" }}>
                    <div className="col-md-12">
                        <select
                            className="form-select"
                            name="nCli_Id"
                            onChange={handleChange}
                            defaultValue={getRelacion && getRelacion.nCli_Id}
                        >
                            <option value="0">Seleccionar...</option>
                            {comboContext.clientes.map((row, i) => (
                                <option key={i} value={row.nCli_Id}>
                                    {row.cCli_NombreCompleto}
                                </option>
                            ))}
                        </select>
                        <label className="col-form-label labelDown">Cliente</label>
                    </div>
                </div>
                <div className="mb-3 row" style={{ marginTop: "15px" }}>
                    <div className="col-md-12">
                        <select
                            className="form-select"
                            name="cCas_Tab_Relacion"
                            onChange={handleChange}
                            defaultValue={getRelacion && getRelacion.cCas_Tab_Relacion}
                        >
                            {comboContext.grupo.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                    {row.cTabNombre}
                                </option>
                            ))}
                        </select>
                        <label className="col-form-label labelDown">Relacion</label>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => onCloseClick()}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                >
                    Cerrar
                </button>
                <button
                    type="button"
                    className="btn btn-danger waves-effect waves-light"
                    onClick={() => SaveRelacion()}
                >
                    Guardar
                </button>
            </div>
        </Modal>
    )
}
export default ModalRelacion