import React, { useState, useEffect, useContext } from "react"
import MetaTags from "react-meta-tags"
import {
    Col,
    Row,
    Card,
    CardBody,
    CardTitle,
    DropdownItem,
    DropdownMenu,
    UncontrolledDropdown,
    DropdownToggle,
    Modal,
    Container,
} from "reactstrap"
import Checkbox from "../../Consultas/Checkbox";
import instance from '../../../config'
import Swal from "sweetalert2"
import { getDecryptedItem } from '../../../util';
import Breadcrumbs from "components/Common/Breadcrumb"
import ModalEgreso from "../../../../src/pages/Componentes/ModalEgreso";
import ModalIngreso from "../../../../src/pages/Componentes/ModalIngreso";
import ModalEvento from "pages/Menus/Evento/Componentes/ModalEvento"
import ModalTarea from "pages/Menus/Tarea/Componentes/ModalTarea"
import ModalCasoU from "pages/Menus/Casos/Componentes/ModalCasoU"
import ComboState from "../Context/Combos/ComboState";

var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var mesIni = Fecha.getMonth() - 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
if (mesIni < 10) mesIni = "0" + mesIni //agrega cero si el menor de 10
const baseUrl = process.env.REACT_APP_API_URL
const ListaAuditoria = props => {
    const [dataAuditoria, setDataAuditoria] = useState([]);
    const [dataComboUsuario, setDataComboUsuario] = useState([]);
    const [ArrayAuditoria, setArrayAuditoria] = useState([]);
    const [checked, setStateChecked] = useState(false);
    const [modalFiltro, setModalFiltro] = useState(false)
    const [chekedValor1, setchekedValor1] = useState(false)
    const [chekedValor2, setchekedValor2] = useState(false)
    const [chekedValor3, setchekedValor3] = useState(false)
    const [chekedValor4, setchekedValor4] = useState(false)
    const [hiddenBtnEditar, setHiddenBtnEditar] = useState(true)
    const [hiddenBtnEliminar, setHiddenBtnEliminar] = useState(true)
    const [Valor1, setValor1] = useState(true)
    const [Valor2, setValor2] = useState(true)
    const [Valor3, setValor3] = useState(true)
    const [Valor4, setValor4] = useState(true)
    const [getFiltro, setFiltro] = useState({
        nUsu_ID: null,
        cAud_nombre: null,
        FechaInicial: ano + "-" + "01" + "-" + "01",
        FechaFin: ano + "-" + mes + "-" + dia,
        nCas_Id: null,
        nCli_Id: null
    })
    const [selectAll, setSelectAll] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([])
    const [data, setData] = useState([])
    const [dataCaso, setDataCaso] = useState([])
    const [dataCliente, setDataCliente] = useState([])

    const [dataCasoInfo, setDataCasoInfo] = useState([])
    const [getDefaultCliente, setDefaultCliente] = useState({})
    const [getDefaultOrgano, setDefaultOrgano] = useState({})

    const [GetParticipantesA, setParticipantesA] = useState([])
    const [GetParticipantesAR, setParticipantesAR] = useState([])
    const [GetParticipantesP, setParticipantesP] = useState([])
    const [GetParticipantesUAD, setParticipantesUAD] = useState([])
    const [modalCasoU, setModalCasoU] = useState(false)
    const [modalEvento, setModalEvento] = useState(false)
    const [modalTarea, setModalTarea] = useState(false)
    const [modalEgreso, setModalEgreso] = useState(false)
    const [modalIngreso, setModalIngreso] = useState(false)
    const [GetParticipantes, setParticipantes] = useState([])
    function handleCheckAll(event) {
        setIsChecked(event.target.checked);
        const checkboxes = document.querySelectorAll("input[type=checkbox]");
        checkboxes.forEach((checkbox) => {
            checkbox.checked = event.target.checked;
        });
    }
    const SeleccionarTodo = (event) => {
        const isChecked = event.target.checked;
        const checkboxes = document.querySelectorAll('input[type="checkbox"]:not(#seleccionarMasivo)');
        checkboxes.forEach((checkbox) => {
            checkbox.checked = isChecked;
        });
        const checkedBoxes = Array.from(checkboxes).filter((checkbox) => checkbox.checked);
        const selectedObjects = checkedBoxes.map((checkbox) => {
            const pos = Array.from(checkbox.parentNode.parentNode.parentNode.children).indexOf(checkbox.parentNode.parentNode);
            return pos >= 0 ? dataAuditoria[pos] : undefined;
        });

        setArrayAuditoria(selectedObjects);


    };
    const desCheck = () => {
        const checkboxPrincipal = document.getElementById("selecionarMasivo");
        checkboxPrincipal.checked = false;

    }
    const handlePageChange = () => {

        desCheck();
        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
        checkboxes.forEach((checkbox) => {
            checkbox.checked = false;
        });

    };

    const handleChangeValor1 = e => {
        setchekedValor1(!chekedValor1)
        if (chekedValor1 == true) {
            setFiltro({
                ...getFiltro,
                nUsu_ID: null
            })
            setValor1(true)
        } else {
            var Valor = getFiltro.nUsu_ID

            setFiltro({
                ...getFiltro,
                nUsu_ID: Valor
            })
            setValor1(false)
        }
    }
    const handleChangeValor2 = e => {
        setchekedValor2(!chekedValor2)
        if (chekedValor2 == true) {
            setFiltro({
                ...getFiltro,
                cAud_nombre: null
            })
            setValor2(true)
        } else {
            var Valor = getFiltro.cAud_nombre

            setFiltro({
                ...getFiltro,
                cAud_nombre: Valor
            })
            setValor2(false)
        }
    }
    const handleChangeValor3 = e => {
        setchekedValor3(!chekedValor3)
        if (chekedValor3 == true) {
            setFiltro({
                ...getFiltro,
                nCli_Id: null
            })
            setValor3(true)
        } else {
            var Valor = getFiltro.nCli_Id

            setFiltro({
                ...getFiltro,
                nCli_Id: Valor
            })
            setValor3(false)
        }
    }
    const handleChangeValor4 = e => {
        setchekedValor4(!chekedValor4)
        if (chekedValor4 == true) {
            setFiltro({
                ...getFiltro,
                nCas_Id: null
            })
            setValor4(true)
        } else {
            var Valor = getFiltro.nCas_Id

            setFiltro({
                ...getFiltro,
                nCas_Id: Valor
            })
            setValor4(false)
        }
    }
    const handleChangeFiltro = e => {
        if (chekedValor1 == true || chekedValor2 == true || chekedValor3 == true || chekedValor4 == true) {


            setFiltro({
                ...getFiltro,
                [e.target.name]: e.target.value
            })

            if (e.target.name == "nCli_Id") {

                FuncionListarCasos(e.target.value)
            }
        }
        else {
            if (e.target.name == "nCli_Id") {

                FuncionListarCasos(e.target.value)
            }



            setFiltro({
                ...getFiltro,
                [e.target.name]: null
            })

        }
    }
    const handleChangeFiltroFechas = e => {


        setFiltro({
            ...getFiltro,
            [e.target.name]: e.target.value
        })

    }

    const UsuariosSelect = dataComboUsuario.map((row, i) => ({
        value: row.nUsu_ID,
        label: row.cUsu_Nombres,
        email: row.cUsu_email,
    }));
    const ListarAuditoria = async () => { // Guardar Lectura del caso

        await instance
            .post("/Auditoria/listar")
            .then(response => {

                setDataAuditoria(response.data)
            });
    }
    function tipoAccion(accion) {
        var res = "";
        if (accion == "I") {
            res = "Creó el registro";
        }
        else if (accion == "U") {
            res = "Editó el registro";
        }
        else if (accion == "D") {
            res = "Eliminó el registro";
        }
        else if (accion == "L") {
            res = "Revisó el registro";
        }
        return res
    }
    const BuscarAuditoria = async () => { // Guardar Lectura del caso
        var postData = {
            nUsu_ID: parseInt(getFiltro.nUsu_ID),
            cAud_nombre: getFiltro.cAud_nombre,
            FechaInicial: getFiltro.FechaInicial,
            FechaFin: getFiltro.FechaFin,
            nCli_Id: parseInt(getFiltro.nCli_Id),
            nCas_Id: parseInt(getFiltro.nCas_Id),
        }

        await instance
            .post("/Auditoria/Filtro", postData)
            .then(response => {

                setDataAuditoria(response.data)
                setModalFiltro(!modalFiltro)
            });
    }
    const Top = {
        columns: [
            {
                label: "",
                field: "cheacbox",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
                sort: false,
                searchable: false,
                width: 50,
                center: true,
                formatter: (value, row) => {
                    return (
                        <input
                            type="checkbox"
                            id="seleccionarMasivo"
                            checked={isChecked}
                            onChange={handleCheckAll}
                        />
                    );
                },
            },
        ],
        rows: [],
    };
    const datas = {
        columns: [
            ...Top.columns,
            {
                label: "Fecha",
                field: "dAud_Fecha",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "Usuario",
                field: "cUsu_Nombres",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "Módulo",
                field: "cAud_nombre",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "ID",
                field: "nAud_IdReg",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "Titulo",
                field: "cCas_Titulo",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },

        ],
        rows: [
            ...dataAuditoria.map((row, id) => ({
                ...row,
                cheacbox: (
                    <input
                        type="checkbox"
                        className="checkbox"
                        checked={isChecked}
                        onChange={() => { }}
                    />
                ),
            })),
        ],
    }
    const Acciones = {
        columns: [
            ...datas.columns,
            {
                label: "Acción",
                field: "acciones",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
        ],
        rows: [
            ...datas.rows.map((row, id) => ({
                ...row,
                cheacbox: (
                    <input
                        type="checkbox"
                        className=""
                        checked={isChecked}
                        onChange={() => { }}
                    />
                ),
            })),
        ],
    }
    const FuncionFiltroCliente = async () => {
        var postData = {
            _nUsu_ID: parseInt(getFiltro.nUsu_ID),
        }
        await instance
            .post("/Auditoria/listar/Usuario", postData)
            .then(response => {

                setDataAuditoria(response.data)
            })
            .catch(error => {

            })
    }
    const FuncionEliminarAuditoria = async () => {

        var postData = {
            array: isCheck
        }



        await instance
            .post("/Auditoria/AuditoriaEliminar", postData)
            .then(response => {

                Swal.fire({
                    title: "Mensaje",
                    text: `Registros eliminados exitosamente`,
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })
                setIsCheck([])
                ListarAuditoria();
            })
            .catch(error => {

            })
    }
    const abrirCerrarModal = Modal => {
        if (Modal == 3) {
            setModalFiltro(!modalFiltro)
        }
    }
    const FuncionListarComboUsuario = async () => {
        await instance
            .get("/Usuario/Listar")
            .then(response => {
                setDataComboUsuario(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionListarPermisos = async () => {
        var postData = {
            nUsu_ID: parseInt(getDecryptedItem("RolID"))
        }
        await instance
            .post("/Usuario/Acceso", postData)
            .then(response => {
                let arrayPermisos = response.data;
                const permiso = arrayPermisos.find(element => element.cUsu_Modulo == "ME28");

                if (permiso.bUsu_Editar == 1) {
                    setHiddenBtnEditar(false);
                }
                else {
                    setHiddenBtnEditar(true);
                }
                if (permiso.bUsu_Eliminar == 1) {
                    setHiddenBtnEliminar(false);
                }
                else {
                    setHiddenBtnEliminar(true);
                }
            })
            .catch(error => {

            })
    }

    const handleClick = (e) => {
        const { id, checked } = e.target;

        setIsCheck([...isCheck, id]);
        if (!checked) {
            setIsCheck(isCheck.filter((item) => item !== id));
        }
    };
    const handleSelectAll = (e) => {
        setIsCheckAll(!isCheckAll);
        setIsCheckAll(dataAuditoria.map((li) => li.id));
        if (isCheckAll) {
            setIsCheckAll([]);
        }
    };
    const FuncionBuscarEventosParticipantes = async (_nEve_Id) => {   // Aqui se carga GetdefaultParticipantes
        var postData = {
            nEve_Id: _nEve_Id,
        }
        await instance
            .post("/Evento/Buscar/Participantes", postData)
            .then(response => {

                setParticipantes(response.data);
                setTimeout(() => {
                    setModalEvento(true);
                }, 500);
            })
            .catch(error => {

            })
    }
    const FuncionBuscarTareasParticipantes = async (_nTar_Id) => {
        var postData = {
            nTar_Id: _nTar_Id,
        }
        await instance
            .post("/Tarea/Buscar/Participante", postData)
            .then(response => {
                setParticipantes(response.data);
                setModalTarea(true)
            })
            .catch(error => {

            })
    }
    const FuncionListarEquipoAbogadoResponsable = async (_nCas_Id) => {
        var postData = {
            nCas_Id: _nCas_Id,
            Tipo: "UC01",
        }
        await instance
            .post("/Casos/Equipo", postData)
            .then(response => {
                setParticipantesAR(response.data)
            })
            .catch(error => {

            })
    }
    const FuncionListarEquipoAbogado = async (_nCas_Id) => {
        var postData = {
            nCas_Id: _nCas_Id,
            Tipo: "UC02"
        }
        await instance
            .post("/Casos/Equipo", postData)
            .then(response => {

                setParticipantesA(response.data)
            })
            .catch(error => {

            })
    }
    const FuncionListarEquipoPracticante = async (_nCas_Id) => {
        var postData = {
            nCas_Id: _nCas_Id,
            Tipo: "UC03"
        }
        await instance
            .post("/Casos/Equipo", postData)
            .then(response => {
                setParticipantesP(response.data)
            })
            .catch(error => {

            })
    }
    const FuncionListarEquipoUsuarioAdd = async (_nCas_Id) => {
        var postData = {
            nCas_Id: _nCas_Id,
            Tipo: "UC04"
        }
        await instance
            .post("/Casos/Equipo", postData)
            .then(response => {
                setParticipantesUAD(response.data)
            })
            .catch(error => {

            })
    }
    const OpenModalCaso = (_nCas_Id, _dataCaso) => { /************************************************************ */
        Swal.fire({
            html:
                '<img id="imgr1" className="imgr" src="/static/media/logo-dark.7a9779c2.png" style="width:50px;height:auto;-webkit-animation: 3s rotate linear infinite;' +
                'animation: 3s rotate linear infinite;' +
                '-webkit-transform-origin: 50% 50%;' +
                'transform-origin: 50% 50%;" /> ' +
                '<br/><br/> Cargando...',
            showConfirmButton: false,
            allowOutsideClick: false
        });
        let nCas_Id = _nCas_Id;


        setDefaultCliente({ label: _dataCaso[0].cCli_NombreCompleto, value: _dataCaso[0].nCli_Id })
        setDefaultOrgano({ label: _dataCaso[0].cCas_Tab_Distrito_Jud2, value: _dataCaso[0].cCas_Tab_Distrito_Jud })
        FuncionListarEquipoAbogado(nCas_Id)
        FuncionListarEquipoAbogadoResponsable(nCas_Id)
        FuncionListarEquipoPracticante(nCas_Id)
        FuncionListarEquipoUsuarioAdd(nCas_Id)
        setTimeout(() => {
            setModalCasoU(true)
            Swal.close();
        }, 1000);
    }
    const openModal = async (auditoria) => {

        let modulo = auditoria.cAud_nombre;
        switch (modulo) {
            case "Eventos":
                var postData = {
                    _nEve_Id: parseInt(auditoria.nAud_IdReg)
                }
                await instance
                    .post("/Evento/ObtenerEvento", postData)
                    .then(response => {
                        setData(response.data[0])
                        FuncionBuscarEventosParticipantes(response.data[0].nEve_Id)
                    })
                    .catch(error => {

                    })
                break;
            case "Tareas":
                var postData = {
                    nCli_Id: null,
                    nCas_Id: null,
                    cTar_Tab_Tipo: null,
                    cTar_Tab_Categoria: null,
                    FechaInicial: '2020-01-01',
                    FechaFin: '2050-01-01',
                    nUsu_ID: null,
                    nTar_Id: parseInt(auditoria.nAud_IdReg)
                }
                await instance
                    .post("/Tarea/Buscar", postData)
                    .then(response => {

                        setData(response.data[0])
                        FuncionBuscarTareasParticipantes(parseInt(auditoria.nAud_IdReg))
                    })
                    .catch(error => {

                    })
                break;

            case "Ingresos":
                var postData = {
                    _nIng_Id: parseInt(auditoria.nAud_IdReg)
                }
                await instance
                    .post("/Ingreso/ObtenerIngreso", postData)
                    .then(response => {
                        setData(response.data[0]);
                        setTimeout(() => {
                            setModalIngreso(true);
                        }, 300);


                    })
                    .catch(error => {

                    })
                break;

            case "Egresos":
                var postData = {
                    _nEge_Id: parseInt(auditoria.nAud_IdReg)
                }
                await instance
                    .post("/Egreso/EgresoObtenerEgreso", postData)
                    .then(response => {
                        setData(response.data[0]);
                        setTimeout(() => {
                            setModalEgreso(true);
                        }, 300);


                    })
                    .catch(error => {

                    })
                break;

            case "Casos":
                var postData = {
                    nCas_Id: parseInt(auditoria.nAud_IdReg)
                }
                await instance
                    .post("/Casos/Buscar", postData)
                    .then(response => {

                        let _dataCaso = response.data;
                        setData(response.data[0])
                        setTimeout(() => {


                            OpenModalCaso(postData.nCas_Id, _dataCaso);
                        }, 1000);
                    })
                    .catch(error => {

                    })
                break;
        }
    }

    const FuncionListarCasos = async (_idClient) => {
        var postData = {
            idClient: parseInt(_idClient),
            nUsu_Id: parseInt(getDecryptedItem("ID")),
        }


        await instance
            .post("/Casos/ListarCbo", postData)
            .then(response => {

                setDataCaso(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionListarCliente = async () => {
        let RolId = getDecryptedItem("RolID")

        var postData = {
            bCli_Tipo: null,
            nUsu_ID: RolId == 6 || RolId == 1 ? null : getDecryptedItem("ID")
        }
        await instance
            .post("/Cliente/ListarCbo", postData)
            .then(response => {
                setDataCliente(response.data)

            })
            .catch(error => {

            })
    }

    useEffect(() => {
        FuncionListarCliente();

        FuncionListarPermisos();
        FuncionListarComboUsuario()
        ListarAuditoria();
    }, [])
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Historial del Log | GpsLegal</title>
                </MetaTags>
                <Container fluid>
                    { }

                    <Breadcrumbs
                        title="GpsLegal"
                        breadcrumbItem="Historial del Log"
                    />
                    <Container fluid>
                        <Col className="col-12">
                            <Card>
                                <div className="mb-3 row">

                                    <div className="col-md-7">
                                        <button
                                            type="button"
                                            className="btn btn-danger waves-effect waves-light"
                                            data-toggle="modal"
                                            data-target="#myModal"
                                            style={{ marginRight: '7px' }}
                                            hidden={hiddenBtnEliminar}
                                            onClick={() =>
                                                Swal.fire({
                                                    title: "Mensaje",
                                                    html:
                                                        `Esta seguro de eliminar estos ${isCheck.length} registros`,
                                                    icon: "warning",
                                                    showCancelButton: true,
                                                    confirmButtonColor: "#3085d6",
                                                    cancelButtonColor: "#d33",
                                                    confirmButtonText: "Aceptar",
                                                    cancelButtonText: "Cancelar",
                                                }).then(result => {
                                                    if (result.isConfirmed) {
                                                        FuncionEliminarAuditoria()
                                                    }
                                                })
                                            }
                                        >
                                            <i style={{ fontSize: "13px" }} className="icon-eliminar"> </i>Eliminar
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => abrirCerrarModal(3)}
                                            className="btn btn-danger waves-effect waves-light"
                                            data-toggle="modal"
                                            data-target="#myModal"
                                        >
                                            <i style={{ fontSize: "15px" }} className="icon-filtro"></i>&nbsp;Filtro
                                        </button>
                                    </div>
                                    <div className="col-md-2">
                                    </div>

                                    <div className="col-md-3" style={{ textAlign: 'right', float: 'right', paddingLeft: '0px' }}>

                                        <div>
                                            <label style={{ textAlign: 'right', paddingTop: '20px', paddingRight: '0px', marginRight: '0px' }}>Cantidad de registros : {dataAuditoria.length}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-wrapper-scroll-y-costos my-custom-scrollbar-costos" >
                                    <table id="datatableg" className="table table-bordered " >
                                        <thead >
                                            <tr className="">
                                                <th style={{ width: '5%', fontFamily: 'roboto', fontWeight: '200' }} className="alignCenter space-text"><input className="form-check-input" type="checkbox" onChange={handleSelectAll} /></th>
                                                <th style={{ width: '15%', fontFamily: 'roboto', fontWeight: '100' }} className="alignCenter space-text">Fecha</th>
                                                <th style={{ width: '10%', fontFamily: 'roboto', fontWeight: '100' }} className="alignCenter space-text">Usuario</th>
                                                <th style={{ width: '15%', fontFamily: 'roboto', fontWeight: '100' }} className="alignCenter space-text">Cliente</th>
                                                <th style={{ width: '20%', fontFamily: 'roboto', fontWeight: '100' }} className="alignCenter space-text">Caso</th>
                                                <th style={{ width: '10%', fontFamily: 'roboto', fontWeight: '100' }} className="alignCenter space-text">Módulo</th>
                                                <th style={{ width: '10%', fontFamily: 'roboto', fontWeight: '100' }} className="alignCenter space-text">ID</th>
                                                <th style={{ width: '20%', fontFamily: 'roboto', fontWeight: '100' }} className="alignCenter space-text">Titulo</th>
                                                <th style={{ width: '10%', fontFamily: 'roboto', fontWeight: '100' }} className="alignCenter space-text">Acción</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                dataAuditoria.map((e) =>
                                                    <tr>
                                                        <td style={{ fontFamily: 'roboto', textAlign: "center", paddingTop: "5px", paddingBottom: "5px" }}>
                                                            <Checkbox
                                                                key={e.cAud_Id}
                                                                type="checkbox"
                                                                name={"Hola"}
                                                                id={e.cAud_Id}
                                                                handleClick={handleClick}
                                                            />
                                                        </td>
                                                        <td style={{ fontFamily: 'roboto', textAlign: "center", paddingTop: "5px", paddingBottom: "5px" }}>{e.dAud_Fecha}</td>
                                                        <td style={{ fontFamily: 'roboto', textAlign: "left", paddingTop: "5px", paddingBottom: "5px" }}>{e.cUsu_Nombres}</td>
                                                        <td style={{ fontFamily: 'roboto', textAlign: "left", paddingTop: "5px", paddingBottom: "5px" }}>{e.cCli_NombreCompleto}</td>
                                                        <td style={{ fontFamily: 'roboto', textAlign: "left", paddingTop: "5px", paddingBottom: "5px" }}>{e.cCas_Cod_Externo}</td>
                                                        <td style={{ fontFamily: 'roboto', textAlign: "left", paddingTop: "5px", paddingBottom: "5px", }}>{e.cAud_nombre}</td>
                                                        <td style={{ fontFamily: 'roboto', textDecoration: 'underline', color: 'blue', textAlign: "center", paddingTop: "5px", paddingBottom: "5px", cursor: 'pointer' }} onClick={() => openModal(e)}>{e.nAud_IdReg}</td>
                                                        <td style={{ fontFamily: 'roboto', textAlign: "left", paddingTop: "5px", paddingBottom: "5px" }}>{e.titulo}</td>
                                                        <td style={{ fontFamily: 'roboto', textAlign: "left", paddingTop: "5px", paddingBottom: "5px" }}>{e.nAud_Accion}</td>

                                                    </tr>
                                                )}
                                        </tbody>
                                    </table>
                                </div>
                                {/* <MDBDataTable
                                    data={Acciones}
                                    responsiveXl
                                    fixed
                                    hover
                                    entriesLabel="Mostrar"
                                    entrylabel={"Mostrar entradas"}
                                    infoLabel={["Mostrando ", "a", "de", "registros"]}
                                    bordered
                                    order={["age", "desc"]}
                                    noRecordsFoundLabel={"No hay datos"}
                                    searchLabel={"Buscar"}
                                    noBottomColumns
                                    paginationLabel={["Anterior", "Siguiente"]}
                                    theadColor="HeadBlue"
                                    onPaginationClick={handlePageChange}
                                /> */}
                                <Modal size="lg" isOpen={modalFiltro}>
                                    <div
                                        className="modal-header ModalHeaderColor"
                                    >
                                        <h5
                                            style={{ color: "#fff" }}
                                            className="modal-title mt-0"
                                            id="myModalLabel"
                                        >
                                            Seleccione criterio(s) de búsqueda
                                        </h5>
                                        <button
                                            type="button"
                                            onClick={() => abrirCerrarModal(3)}
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <strong>Fechas (rango)</strong>
                                        <hr style={{ color: "#000", marginTop: "0px" }} />
                                        <div className="mb-3 row">
                                            <div className="col-md-1" style={{ width: "20px" }}></div>
                                            <div className="col-md-5">
                                                <input
                                                    className="form-control inputDown"
                                                    type="date" name="FechaInicial" value={getFiltro && getFiltro.FechaInicial} onChange={handleChangeFiltroFechas}
                                                />
                                                <label className="col-form-label labelDown">
                                                    Inicio*
                                                </label>
                                            </div>
                                            <div className="col-md-1" style={{ width: "20px" }}></div>
                                            <div className="col-md-5">
                                                <input
                                                    className="form-control inputDown"
                                                    type="date" name="FechaFin" value={getFiltro && getFiltro.FechaFin} onChange={handleChangeFiltroFechas}
                                                />
                                                <label className="col-form-label labelDown">
                                                    Fin*
                                                </label>
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-md-1" style={{ width: "20px" }}>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckcolor1"
                                                    checked={chekedValor1}
                                                    value={chekedValor1}
                                                    onChange={handleChangeValor1}
                                                    style={{ transform: "scale(1.5)", marginTop: "10px" }}
                                                />
                                            </div>
                                            <div className="col-md-5">
                                                <select className="form-select inputDown" name="nUsu_ID" onChange={handleChangeFiltro} disabled={Valor1}>
                                                    <option value="null">Seleccionar...</option>
                                                    {dataComboUsuario.map((row, i) => (
                                                        <option key={i} value={row.nUsu_ID}>
                                                            {row.cUsu_Nombres}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label className="col-form-label labelDown">
                                                    Usuario
                                                </label>
                                            </div>
                                            <div className="col-md-1" style={{ width: "20px" }}>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckcolor1"
                                                    checked={chekedValor2}
                                                    value={chekedValor2}
                                                    onChange={handleChangeValor2}
                                                    style={{ transform: "scale(1.5)", marginTop: "10px" }}
                                                />
                                            </div>
                                            <div className="col-md-5">
                                                <select className="form-select inputDown" name="cAud_nombre" onChange={handleChangeFiltro} disabled={Valor2}>
                                                    <option value="null">Seleccionar...</option>
                                                    <option value="Casos">Casos</option>
                                                    <option value="Eventos">Eventos</option>
                                                    <option value="Egresos">Egresos</option>
                                                    <option value="Ingresos">Ingresos</option>
                                                    <option value="Tareas">Tareas</option>
                                                </select>
                                                <label className="col-form-label labelDown">
                                                    Módulo
                                                </label>
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-md-1" style={{ width: "20px" }}>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckcolor1"
                                                    checked={chekedValor3}
                                                    value={chekedValor3}
                                                    onChange={handleChangeValor3}
                                                    style={{ transform: "scale(1.5)", marginTop: "10px" }}
                                                />
                                            </div>
                                            <div className="col-md-5">
                                                <select className="form-select inputDown" name="nCli_Id" onChange={handleChangeFiltro} disabled={Valor3}>
                                                    <option value="null">Seleccionar...</option>
                                                    {dataCliente.map((row, i) => (
                                                        <option key={i} value={row.nCli_Id}>
                                                            {row.cCli_NombreCompleto}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label className="col-form-label labelDown">
                                                    Cliente
                                                </label>
                                            </div>
                                            <div className="col-md-1" style={{ width: "20px" }}>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheckcolor1"
                                                    checked={chekedValor4}
                                                    value={chekedValor4}
                                                    onChange={handleChangeValor4}
                                                    style={{ transform: "scale(1.5)", marginTop: "10px" }}
                                                />
                                            </div>
                                            <div className="col-md-5">
                                                <select className="form-select inputDown" name="nCas_Id" onChange={handleChangeFiltro} disabled={Valor4}>
                                                    <option value="null">Seleccionar...</option>
                                                    {dataCaso.map((row, i) => (
                                                        <option key={i} value={row.nCas_Id}>
                                                            {row.cCas_Titulo}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label className="col-form-label labelDown">
                                                    Caso
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            onClick={() => abrirCerrarModal(3)}
                                            className="btn btn-secondary waves-effect"
                                            data-dismiss="modal"
                                        >
                                            Cerrar
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-danger waves-effect waves-light"
                                            onClick={() => BuscarAuditoria()}
                                        >
                                            Filtrar
                                        </button>
                                    </div>
                                </Modal>

                                {(() => {
                                    if (modalEvento == true) {
                                        return <>
                                            <ModalEvento
                                                show={modalEvento}
                                                onCloseClick={() => setModalEvento(false)}
                                                Condicion="2"
                                                Row={data}
                                                mode="Readonly"
                                                Id="1"
                                                onCloseModal={() => { setModalEvento(!modalEvento) }}
                                                arrayParticipantesEventos={GetParticipantes}
                                            />
                                        </>
                                    }
                                })()}
                                {(() => {
                                    if (modalTarea == true) {
                                        return <>
                                            <ModalTarea
                                                show={modalTarea}
                                                onCloseClick={() => setModalTarea(false)}
                                                Condicion="1"
                                                Row={data}
                                                mode="2"
                                                Id="1"
                                                onCloseModal={() => { setModalTarea(!modalTarea) }}
                                                arrayParticipantes={GetParticipantes}
                                            />
                                        </>
                                    }
                                })()}
                                <ComboState>
                                    {(() => {
                                        if (modalEgreso == true) {
                                            return <>
                                                <ModalEgreso
                                                    Titulo={"Ver egreso"}
                                                    show={modalEgreso}
                                                    onCloseClick={() => setModalEgreso(false)}
                                                    Condicion="1"
                                                    Row={data}
                                                    mode="Readonly"
                                                    modo="2"
                                                    Id="1"
                                                    egreso={data}
                                                    onCloseModal={() => { setModalEgreso(!modalEgreso) }}
                                                />
                                            </>
                                        }
                                    })()}
                                </ComboState>
                                {(() => {
                                    if (modalIngreso == true) {
                                        return <>
                                            <ModalIngreso
                                                Titulo={"Ver ingreso"}
                                                show={modalIngreso}
                                                ingreso={data}
                                                onCloseClick={() => setModalIngreso(false)}
                                                Condicion="1"
                                                Row={data}
                                                mode={"Readonly"}
                                                modo={2}
                                                Id="1"
                                                onCloseModal={() => { setModalIngreso(!modalIngreso) }}
                                            />
                                        </>
                                    }
                                })()}
                                <ComboState>
                                    {(() => {

                                        if (modalCasoU == true) {
                                            return <>
                                                <ModalCasoU
                                                    mode={"Readonly"}
                                                    show={modalCasoU}
                                                    Rows={data}
                                                    RowAR={GetParticipantesAR}
                                                    RowA={GetParticipantesA}
                                                    RowP={GetParticipantesP}
                                                    RowU={GetParticipantesUAD}
                                                    RowC={getDefaultCliente}
                                                    RowO={getDefaultOrgano}
                                                    onCloseClick={() => setModalCasoU(false)}
                                                    onCloseModal={() => { setModalCasoU(false) }} />
                                            </>
                                        }
                                    })()}
                                </ComboState>


                            </Card>
                        </Col>
                    </Container>
                </Container>
            </div>
        </React.Fragment >

    )
}
export default ListaAuditoria;