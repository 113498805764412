import React, { Component, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTags from "react-meta-tags"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import Swal from "sweetalert2"
import Checkbox from "./Checkbox";
import { getDecryptedItem } from '../../util';
import {
    CardBody,
    Card,
    Col,
    Container,
    CardTitle,
    Button,
    Row,
    Modal,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
} from "reactstrap"
import instance from '../../config'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'
const baseUrl = process.env.REACT_APP_API_URL
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
const ReporteProcesar = props => {
    const [dataCaso, setDataCaso] = useState([])
    const [isCheck, setIsCheck] = useState([])
    const [isCheckH, setIsCheckH] = useState([])
    const [InsertMontoTH, setInsertMontoTH] = useState([])
    const [InsertMontoTG, setInsertMontoTG] = useState([])
    const [hiddenBtnEditar, setHiddenBtnEditar] = useState(true)
    const [hiddenBtnEliminar, setHiddenBtnEliminar] = useState(true)
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [dataCliente, setDataCliente] = useState([])
    const [dataCasoInfo, setDataCasoInfo] = useState([])
    const [dataMoney, setDataMoney] = useState({ money: "S/" })
    const [dataEstadoCuentaCostos, setDataEstadoCuentaCostos] = useState([])
    const [dataTotalCosto, setDataTotalCosto] = useState([{ Total: 0.00 }])
    const [dataTotalHonorario, setDataTotalHonorario] = useState([{ Total: 0.00 }])
    const [dataCombo, setDataCombo] = useState([])
    const [dataEstadoCuentaHonorarios, setDataEstadoCuentaHonorarios] = useState([])
    const [dataConsulta, setDataConsulta] = useState({
        inicio: ano + "-" + mes + "-" + "01",
        fin: ano + "-" + mes + "-" + dia,
        mon: "MO01",
        cliente: null,
        caso: '',
    })
    const [checkAllG, setCheckAllG] = useState(false);
    const [checkAllH, setCheckAllH] = useState(false);
    const FuncionListarCliente = async () => {
        let RolId = getDecryptedItem("RolID")

        var postData = {
            bCli_Tipo: null,
            nUsu_ID: RolId == 6 || RolId == 1 ? null : getDecryptedItem("ID")
        }
        await instance
            .post("/Cliente/ListarCbo", postData)
            .then(response => {
                setDataCliente(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionListarCasos = async (_idClient) => {
        var postData = {
            idClient: _idClient,
            nUsu_Id: getDecryptedItem("ID"),
        }
        await instance
            .post("/Casos/ListarCbo", postData)
            .then(response => {
                setDataCaso(response.data)

            })
            .catch(error => {

            })
    }
    const fListarEstadoCuenta = async () => {
        var postData = {
            ini: dataConsulta.inicio,
            fin: dataConsulta.fin,
        }
        await instance
            .post("/Reporte/Ecuenta", postData)
            .then(response => {
                setDataEstadoCuenta(response.data)
                //
            })
            .catch(error => {

            })
    }
    const handleChangeBusqueda = e => {
        const { name, value } = e.target
        setDataConsulta(prevState => ({
            ...prevState,
            [name]: value,
        }))

        if (name == "cliente") {
            FuncionListarCasos(value)
        }
    }
    const fListarCostos = async () => {
        fListarTotalCosto()
        var postData = {
            ini: dataConsulta.inicio,
            fin: dataConsulta.fin,
            mon: dataConsulta.mon,
            nCas_Id: dataConsulta.caso
        }
        await instance
            .post("/Reporte/Costos/Proc/Liq", postData)
            .then(response => {
                console.log(response.data);
                setDataEstadoCuentaCostos(response.data)

            })
            .catch(error => {

            })
        fListarCostosHonorarios()
    }
    const fListarCostosHonorarios = async () => {
        fListarTotalHonorario()
        var postData = {
            ini: dataConsulta.inicio,
            fin: dataConsulta.fin,
            mon: dataConsulta.mon,
            nCas_Id: dataConsulta.caso
        }
        await instance
            .post("/Reporte/CostosHonorarios", postData)
            .then(response => {
                setDataEstadoCuentaHonorarios(response.data)
                if (dataConsulta.mon == "MO01") {
                    setDataMoney({ money: "S/" })
                } else {
                    setDataMoney({ money: "U$" })
                }
                //
            })
            .catch(error => {

            })
    }
    const fListarComboDocumento = async () => {
        var postData = {
            Prefijo: "MO",
        }
        await instance
            .post("/Maestros/Data", postData)
            .then(response => {
                setDataCombo(response.data)

            })
            .catch(error => {

            })
    }
    const fListarTotalCosto = async () => {
        var postData = {
            ini: dataConsulta.inicio,
            fin: dataConsulta.fin,
            mon: dataConsulta.mon,
            state: 1,
            cEgre_Liq_Numero: null,
            nCas_Id: dataConsulta.caso,
        }
        await instance
            .post("/Reporte/Costos/Total", postData)
            .then(response => {
                setDataTotalCosto(response.data)

            })
            .catch(error => {

            })
    }
    const fProcesar = async (xCodigo) => {
        let n = isCheck.length
        for (let i = 0; i <= n - 1; i++) {
            fProcesarData(isCheck[i], xCodigo)
        }
        let n2 = isCheckH.length
        for (let i = 0; i <= n2 - 1; i++) {
            fProcesarDataH(isCheckH[i], xCodigo)
        }
        Swal.fire({
            title: "Mensaje",
            text: "Datos fueron procesados",
            icon: "success",
            confirmButtonText: "Aceptar",
        }).then(result => {
            if (result.isConfirmed) {
                setIsCheck([])
                setIsCheckH([])
                setInsertMontoTH([])
                setInsertMontoTG([])
                fListarCostos()
                var elem1 = document.getElementById("checkBoxTbl").checked = 0;
                var elem2 = document.getElementById("checkBoxTblH").checked = 0;
            }
        })
    }

    const ActualizarMontosCaso = async (_nCas_Id, _nUsu_ID) => {

        const postData = {
            nCas_Id: _nCas_Id,
            nUsu_ID: _nUsu_ID
        }

        console.log(postData);

        await instance
        .post("/Liquidacion/ActualizarMontosCaso", postData)
        .then(response => {

            console.log(response.data);
            
        })
        .catch(error => {

        })

    }

    const GenerarCodigo = async () => {

        console.log(dataConsulta);

        if (InsertMontoTH.length > 0 || InsertMontoTG.length > 0) {
            var TotalH = 0
            var TotalG = 0

            for (var i = 0; i < InsertMontoTH.length; i++) {
                TotalH = parseFloat(TotalH) + parseFloat(InsertMontoTH[i])
            }

            for (var i = 0; i < InsertMontoTG.length; i++) {
                console.log(InsertMontoTG[i])
                TotalG = parseFloat(TotalG) + parseFloat(InsertMontoTG[i])
            }

            console.log(InsertMontoTH)
            console.log(InsertMontoTG)


            var postData = {
                nCas_Id: dataConsulta.caso,
                MontoH: TotalH,
                MontoG: TotalG,
                Moneda: dataConsulta.mon,
                FechaIniPeriodo: dataConsulta.inicio,
                FechaFinPeriodo: dataConsulta.fin
            }

            await instance
                .post("/GenerarCodigo/Liquidacion", postData)
                .then(response => {

                    fProcesar(response.data.Codigo)
                    ActualizarMontosCaso(dataConsulta.caso, dataConsulta.cliente)

                })
                .catch(error => {

                })
        } else {
            Swal.fire({
                title: "Mensaje",
                text: "Debes seleccionar al menos 1 item para poder procesar",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        }

    }
    const fProcesarData = async (idx, xCodigo) => {
        var postData = {
            id: parseInt(idx),
            Codigo: xCodigo
        }
        await instance
            .post("/Reporte/Costos/Procesar", postData)
            .then(response => {

            })
            .catch(error => {
            })
    }
    const fProcesarDataH = async (idx, xCodigo) => {
        var postData = {
            id: parseInt(idx),
            Codigo: xCodigo
        }
        await instance
            .post("/Reporte/Costos/ProcesarH", postData)
            .then(response => {

                // aqui llamar a la funcion

            })
            .catch(error => {
            })
    }
    const fListarTotalHonorario = async () => {
        var postData = {
            ini: dataConsulta.inicio,
            fin: dataConsulta.fin,
            mon: dataConsulta.mon,
            state: 1,
            cLab_Liq_Numero: null,
            nCas_Id: dataConsulta.caso,
        }
        await instance
            .post("/Reporte/Honorarios/Total", postData)
            .then(response => {
                setDataTotalHonorario(response.data)

            })
            .catch(error => {

            })
    }
    const handleSelectAll = () => {
        var elem = document.getElementById("checkBoxTbl");
        // Obtener la referencia a la tabla
        var tabla = document.getElementById("datatbody");
        var checkboxes = tabla.querySelectorAll("input[type='checkbox']");
        console.log(elem.checked)
        if (elem.checked) {
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
                let elemnt =
                {
                    target: {
                        id: checkbox.id, checked: checkbox.checked
                    }
                }
                handleClick(elemnt)

            });
        } else {
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
                let elemnt =
                {
                    target: {
                        id: checkbox.id, checked: checkbox.checked
                    }
                }
                handleClick(elemnt)
            });
        }

    };

    const handleSelectAllH = () => {
        var elem = document.getElementById("checkBoxTblH");
        // Obtener la referencia a la tabla
        var tabla = document.getElementById("datatbodyH");
        var checkboxes = tabla.querySelectorAll("input[type='checkbox']");
        console.log(elem.checked)
        if (elem.checked) {
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = true;
                let elemnt =
                {
                    target: {
                        id: checkbox.id, checked: checkbox.checked
                    }
                }
                handleClickH(elemnt)

            });
        } else {
            checkboxes.forEach(function (checkbox) {
                checkbox.checked = false;
                let elemnt =
                {
                    target: {
                        id: checkbox.id, checked: checkbox.checked
                    }
                }
                handleClickH(elemnt)
            });
        }

    };
    const handleClick = (e) => {
        const { id, checked } = e.target;
        let data = InsertMontoTG
        let dataCheck = isCheck
        if (checked == true) {
            dataCheck.push(id)
            data.push(dataEstadoCuentaCostos.find((dato) => dato.id == id).Monto2)
            setInsertMontoTG(data)
        } else {
            setInsertMontoTG(data.filter((item) => item !== dataEstadoCuentaCostos.find((dato) => dato.id == id).Monto2))
            setIsCheck(dataCheck.filter((item) => item !== id));
        }
    };

    const handleClickH = (e) => {
        const { id, checked } = e.target;
        let data = InsertMontoTH
        let dataCheck = isCheckH
        if (checked == true) {
            dataCheck.push(id)
            data.push(dataEstadoCuentaHonorarios.find((dato) => dato.id == id).Monto2)
            setInsertMontoTH(data)
        } else {
            setInsertMontoTH(data.filter((item) => item !== dataEstadoCuentaHonorarios.find((dato) => dato.id == id).Monto2))
            setIsCheckH(dataCheck.filter((item) => item !== id));
        }
    };
    const FuncionListarPermisos = async () => {
        var postData = {
            nUsu_ID: parseInt(getDecryptedItem("RolID"))
        }
        await instance
            .post("/Usuario/Acceso", postData)
            .then(response => {
                let arrayPermisos = response.data;
                const permiso = arrayPermisos.find(element => element.cUsu_Modulo == "ME14");

                if (permiso.bUsu_Editar == 1) {
                    setHiddenBtnEditar(false);
                }
                else {
                    setHiddenBtnEditar(true);
                }

                if (permiso.bUsu_Eliminar == 1) {
                    setHiddenBtnEliminar(false);
                }
                else {
                    setHiddenBtnEliminar(true);
                }
            })
            .catch(error => {

            })
    }

    useEffect(() => {
        FuncionListarPermisos()
        fListarComboDocumento()
        FuncionListarCliente()
        FuncionListarCasos(null)
    }, [])
    return (
        <React.Fragment>
            <div className="page-content">
                {/* add meta title */}
                <MetaTags>
                    <title>Procesar liquidación | GpsLegal</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="GpsLegal" breadcrumbItem="Procesar liquidación" />
                    <Row>
                        <div >
                            <div className="" style={{ marginTop: '0.5em' }}>
                                <div>
                                    <div className="grillado-caso" >
                                        <div className="">
                                            <div>
                                                <div className="combos-cliente">
                                                    <div>
                                                        <label className="texto-combex">Cliente</label>
                                                        <select style={{}}
                                                            className=" form-select inputDown "
                                                            name="cliente"
                                                            onChange={
                                                                handleChangeBusqueda}
                                                        >
                                                            <option value="00">Seleccionar</option>
                                                            {dataCliente.map((e) =>
                                                                <option value={e.nCli_Id}>
                                                                    {e.cCli_NombreCompleto}
                                                                </option>
                                                            )
                                                            }
                                                        </select>
                                                    </div>
                                                    <div>
                                                        <label className="texto-combex">Caso</label>
                                                        <select style={{}}
                                                            className="form-select inputDown texto-combex"
                                                            name="caso"
                                                            onChange={
                                                                handleChangeBusqueda}
                                                        >
                                                            <option className="texto-combex" value="00">Seleccionar</option>
                                                            {dataCaso.map((row, i) => (
                                                                <option className="texto-combex" value={row.nCas_Id}>
                                                                    {row.cCas_Titulo}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="options-ec" style={{ paddingTop: '5px' }}>
                                            <div >
                                                <label className="texto-combex">Fecha Inicial</label>
                                                <input style={{}} defaultValue={dataConsulta.inicio}
                                                    className="form-control texto-combex"
                                                    type="date"
                                                    name="inicio"
                                                    onChange={handleChangeBusqueda}
                                                />
                                            </div>
                                            <div>
                                                <label className="texto-combex">Fecha Final</label>
                                                <input
                                                    defaultValue={dataConsulta.fin}
                                                    className="form-control texto-combex"
                                                    type="date"
                                                    name="fin"
                                                    onChange={handleChangeBusqueda}
                                                />
                                            </div>
                                            <div>
                                                <label className="texto-combex">Moneda</label>
                                                <select
                                                    className="form-select inputDown "
                                                    name="mon"
                                                    onChange={handleChangeBusqueda}
                                                >
                                                    {dataCombo.slice(0).reverse().map((e) =>
                                                        <option value={e.cTabCodigo}>
                                                            {e.cTabNombre}
                                                        </option>
                                                    )
                                                    }
                                                </select>
                                            </div>
                                            <div className="botones-actions" style={{ marginTop: '2.1em' }}>
                                                <div>
                                                    <button type="button" className="btn btn-danger" onClick={() => fListarCostos()}>{" "}<i className="fas fa-search"></i> </button>
                                                </div>
                                            </div>
                                            <div className="boton-procesar">
                                                <button hidden={hiddenBtnEditar} type="button" className="btn btn-danger" onClick={() => GenerarCodigo()}><i className="fas fa-paper-plane" ></i>&nbsp;&nbsp;Procesar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br></br>
                            <div className="alert titulo border-abajo" style={{ backgroundColor: 'white', borderBottom: 'groove #032D5E', borderTop: 'groove #032D5E', padding: '5px' }} role="alert" >
                                GASTOS
                                <div style={{ textAlign: 'right', marginTop: '-18px', fontfamily: 'roboto' }}>
                                    Total: &nbsp;
                                    <b className="saldo-t" style={{ fontFamily: 'roboto' }}>  {dataTotalCosto.map((e) =>
                                        <b>{
                                            dataMoney.money + " " + e.Total
                                        }</b>
                                    )
                                    }</b>
                                </div>
                            </div>
                            <div style={{ height: '300px' }} className="table-wrapper-scroll-y-costos my-custom-scrollbar-costos">
                                <table id="datatableg" className="table table-bordered TablaHorasLaboradas">
                                    <thead >
                                        <tr className="">
                                            <th style={{ fontFamily: 'roboto', fontWeight: '100' }} className="alignCenter space-text"><input id="checkBoxTbl" className="form-check-input" type="checkbox" onChange={handleSelectAll} /></th>
                                            <th style={{ fontFamily: 'roboto', fontWeight: '100' }} className="alignCenter space-text">Fec./Operación</th>
                                            <th style={{ fontFamily: 'roboto', fontWeight: '100' }} className="alignCenter space-text">Motivo</th>
                                            <th style={{ fontFamily: 'roboto', fontWeight: '100' }} className="alignCenter space-text">Descripción</th>
                                            <th style={{ fontFamily: 'roboto', fontWeight: '100' }} className="alignCenter space-text">Monto</th>
                                            <th style={{ fontFamily: 'roboto', fontWeight: '100' }} className="alignCenter space-text">Estado</th>
                                        </tr>
                                    </thead>
                                    <tbody id="datatbody">
                                        {
                                            dataEstadoCuentaCostos.map((e) =>
                                                <tr>
                                                    <td style={{ fontFamily: 'roboto', textAlign: "center", paddingTop: "5px", paddingBottom: "5px" }}>
                                                        <Checkbox
                                                            key={e.id}
                                                            type="checkbox"
                                                            name={e.id}
                                                            id={e.id}
                                                            handleClick={handleClick}
                                                        />
                                                    </td>
                                                    <td style={{ fontFamily: 'roboto', textAlign: "center", paddingTop: "5px", paddingBottom: "5px" }}>{e.Fecha}</td>
                                                    <td style={{ fontFamily: 'roboto', textAlign: "left", paddingTop: "5px", paddingBottom: "5px" }}>{e.cEge_Tab_Tipo}</td>
                                                    <td style={{ fontFamily: 'roboto', textAlign: "left", paddingTop: "5px", paddingBottom: "5px" }}>{e.Detalle}</td>
                                                    <td style={{ fontFamily: 'roboto', textAlign: "right", paddingTop: "5px", paddingBottom: "5px" }}>{e.Monto}</td>
                                                    <td style={{ fontFamily: 'roboto', textAlign: "right", paddingTop: "5px", paddingBottom: "5px" }}>{e.state == 1 ? "Pendiente" : "Liquidado"}</td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                            <br></br>
                            <br></br>
                            <div className="alert titulo" style={{ backgroundColor: '#fff', color: '#454545', borderBottom: 'groove #032D5E', borderTop: 'groove #032D5E', padding: '5px' }} role="alert">
                                HONORARIOS
                                &nbsp;&nbsp;&nbsp;
                                {/* <i className="fas fa-file-excel excel"></i>
                                <ReactHTMLTableToExcel
                                    type="button"
                                    className="btn btn-success"
                                    table="tablehono"
                                    filename={"HonorarioExport"}
                                    sheet="Honorarios"
                                    buttonText="&nbsp;&nbsp;&nbsp;&nbsp;"
                                        />*/}
                                <div style={{ textAlign: 'right', marginTop: '-18px', fontFamily: 'roboto' }}>
                                    Total: &nbsp;
                                    <b className="saldo-t">  {dataTotalHonorario.map((e) =>
                                        <b>{
                                            dataMoney.money + " " + e.Total
                                        }</b>
                                    )
                                    }</b>
                                </div>
                            </div>
                            <div style={{ height: '300px' }} className="table-wrapper-scroll-y-costos my-custom-scrollbar-costos">
                                <table id="tablehono" className="table table-bordered TablaHorasLaboradas">
                                    <thead >
                                        <tr className="">
                                            <th style={{ fontFamily: 'roboto', fontWeight: '100' }} className="alignCenter space-text"><input id="checkBoxTblH" onChange={handleSelectAllH} className="form-check-input" type="checkbox" /></th>
                                            <th style={{ fontFamily: 'roboto' }} className="alignCenter">Fecha</th>
                                            <th style={{ fontFamily: 'roboto' }} className="alignCenter">Horas</th>
                                            <th style={{ fontFamily: 'roboto' }} className="alignCenter">Descripción</th>
                                            <th style={{ fontFamily: 'roboto' }} className="alignCenter">Monto</th>
                                        </tr>
                                    </thead>
                                    <tbody id="datatbodyH">
                                        {
                                            dataEstadoCuentaHonorarios.map((e) =>
                                                <tr>
                                                    <td style={{ fontFamily: 'roboto', textAlign: "center", paddingTop: "5px", paddingBottom: "5px" }}><Checkbox
                                                        key={e.id}
                                                        type="checkbox"
                                                        name={e.id}
                                                        id={e.id}
                                                        handleClick={handleClickH}
                                                    /></td>
                                                    <td style={{ textAlign: "center", paddingTop: "5px", paddingBottom: "5px", fontFamily: 'roboto' }}>{e.Fecha}</td>
                                                    <td style={{ textAlign: "left", paddingTop: "5px", paddingBottom: "5px", fontFamily: 'roboto' }}>{e.tLab_HoraFacturable}</td>
                                                    <td style={{ textAlign: "left", paddingTop: "5px", paddingBottom: "5px", fontFamily: 'roboto' }}>{e.Detalle}</td>
                                                    <td style={{ textAlign: "right", paddingTop: "5px", paddingBottom: "5px", fontFamily: 'roboto' }}>{e.Monto}</td>
                                                </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default ReporteProcesar