import React, { useRef, Component, useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import Swal from "sweetalert2"
import instance from '../../../../config'
import {
    CardBody,
    Card,
    Col,
    Container,
    Row,
} from "reactstrap"
import { getDecryptedItem } from '../../../../util';
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import ModalCliente from "../Componentes/ModalCliente"
import ClienteState from "pages/Menus/Context/Cliente/ClienteState"
import ComboState from "pages/Menus/Context/Combos/ComboState"
import ListarCliente from "../Componentes/Listar"
import ModalEvento from "../../Evento/Componentes/ModalEvento"
const Vista = props => {
    const [getmodalP, setModalP] = useState(false)
    const [getmodalE, setModalE] = useState(false)
    const [hiddenBtnEditar, setHiddenBtnEditar] = useState(true)
    const [hiddenBtnEliminar, setHiddenBtnEliminar] = useState(true)

    const abrirCerrarModal = Modal => {
        if (Modal == 1) {
            if (!getmodalP == false) {
                Swal.fire({
                    html: "¿Seguro que desea salir sin guardar los datos?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si",
                    cancelButtonText: "No",
                }).then(result => {
                    if (result.isConfirmed) {
                        setModalP(!getmodalP)
                    }
                })
            } else {
                setModalP(!getmodalP)
            }
        } else if (Modal == 2) {
            if (!getmodalE == false) {
                Swal.fire({
                    html: "¿Seguro que desea salir sin guardar los datos?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si",
                    cancelButtonText: "No",
                }).then(result => {
                    if (result.isConfirmed) {
                        setModalE(!getmodalE)
                    }
                })
            } else {
                setModalE(!getmodalE)
            }
        }
    }

    const FuncionListarPermisos = async () => {

        var postData = {
          nUsu_ID: parseInt(getDecryptedItem("RolID"))
        }

        await instance
        .post("/Usuario/Acceso", postData)
        .then(response => {
        let arrayPermisos = response.data;
        const permiso = arrayPermisos.find(element => element.cUsu_Modulo == "ME03");

        if (permiso.bUsu_Editar == 1) {
            setHiddenBtnEditar(false);
        }
        else {
            setHiddenBtnEditar(true);
        }
        if (permiso.bUsu_Eliminar == 1) {
            setHiddenBtnEliminar(false);
        }
        else {
            setHiddenBtnEliminar(true);
        }
        })
        .catch(error => {

        })
    }

    useEffect(async () => {
        FuncionListarPermisos();
    }, [])

    return (
        <React.Fragment>
            <ComboState>
                <ClienteState>
                    <div className="page-content">
                        <MetaTags>
                            <title>Contactos | GpsLegal</title>
                        </MetaTags>
                        <Container fluid>
                            <Breadcrumbs title="GpsLegal" breadcrumbItem="Contactos" />
                        </Container>
                        {/* Botones */}
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody    hidden={hiddenBtnEditar}>
                                        <button
                                            type="button"
                                            onClick={() => abrirCerrarModal(1)}
                                            className="btn btn-danger waves-effect waves-light"
                                        >
                                            <i style={{ fontSize: "15px" }} className="icon-nuevo"></i> Nueva Persona
                                        </button>{" "}
                                        <button
                                            type="button"
                                            onClick={() => abrirCerrarModal(2)}
                                            className="btn btn-danger waves-effect waves-light"
                                        >
                                            <i style={{ fontSize: "15px" }} className="icon-nuevo"></i> Nueva Empresa
                                        </button>{" "}
                                        {(() => {
                                            if (getmodalP == true) {
                                                return <>
                                                    <ModalCliente show={getmodalP}
                                                        onCloseClick={() => abrirCerrarModal(1)}
                                                        Condicion="2"
                                                        onCloseModal={() => setModalP(!getmodalP)}
                                                    />
                                                </>
                                            }
                                        })()}
                                       
                                        {(() => {
                                            if (getmodalE == true) {
                                                return <>
                                                    <ModalCliente show={getmodalE}
                                                        onCloseClick={() => abrirCerrarModal(2)}
                                                        Condicion="3"
                                                        onCloseModal={() => setModalE(!getmodalE)}
                                                        
                                                    />
                                                </>
                                            }
                                        })()}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        {/* Lista */}
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <ListarCliente />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </ClienteState>
            </ComboState>
        </React.Fragment>
    )
}
export default Vista