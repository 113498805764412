import React, { Component, useState, useEffect } from "react"
import { MDBDataTable } from "mdbreact"
import { Link } from "react-router-dom"
import {
  CardBody,
  Card,
  Col,
  Container,
  CardTitle,
  Button,
  Row,
  Modal,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap"
import MetaTags from "react-meta-tags"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
//Dependencia await instance para consumir apis
import instance from '../../config'
import { getDecryptedItem } from '../../util';
import Swal from "sweetalert2"
//Url Principal
const baseUrl = process.env.REACT_APP_API_URL
const Usuario = props => {
  // Variables de estado para modales
  const [modalInsertar, setModalInsertar] = useState(false)
  const [modalActualizar, setModalActualizar] = useState(false)
  const [modalEliminar, setModalEliminar] = useState(false)
  const [getMonto, setMonto] = useState({
    Monto: 0,
    MontoFormat: 0,
    MontoOld: 0,
  });
  const [getMoneda, setMoneda] = useState("");
  const [dataComboMoneda, setDataComboMoneda] = useState([])
  const [getErrores, setErrores] = useState({});
  // Variables para arreglos
  const [dataRol, setDataRol] = useState([])
  const [data, setData] = useState([])
  const [getRoles, setRoles] = useState([])
  const [dataModulos, setDataModulos] = useState([])
  const [getVisible1, setVisible1] = useState("")
  const [getVisible2, setVisible2] = useState("none")
  const [getDisabled, setDisabled] = useState(false)
  const [hiddenBtnEditar, setHiddenBtnEditar] = useState(true)
  const [hiddenBtnEliminar, setHiddenBtnEliminar] = useState(true)
  const [hiddenCboCliente, setHiddenCboCliente] = useState(true)
  const [validData, setValidData] = useState(false)
  const [dataCliente, setDataCliente] = useState([])
  // Variables para Json
  const [getUsuario, setUsuario] = useState({
    nUsu_ID: "",
    nRol_ID: 1,
    nRol_ID2: "",
    cUsu_Nombres: "",
    cUsu_email: "",
    cUsu_Fono: "",
    cUsu_Login: "",
    cUsu_Pass: "",
    bUsu_Activo: 1,
    nUsu_Cli_Id: "0"
  })
  const [getMyUsuario, setMyUsuario] = useState({})
  // Funcion para que cada ves que tu dijites en la caja de testo se mande el valor al setUsuario
  const handleChange = e => {
    const { name, value } = e.target
    setUsuario(prevState => ({
      ...prevState,
      [name]: value,
    }))
    if (name == "nUsu_Cli_Id") {
      if (value == "0") {
        setUsuario(prevState => ({
          ...prevState,
          cUsu_Nombres: "",
          cUsu_email: "",
          cUsu_Fono: "",
        }))
        setErrores(Validacion(getUsuario))
      }
      else {
        const found = dataCliente.find(element => element.nCli_Id == value);

        setUsuario(prevState => ({
          ...prevState,
          cUsu_email: found.cCli_Email1,
          cUsu_Fono: found.cCli_Fono1,
          cUsu_Nombres: found.cCli_NombreCompleto,
        }))
        setErrores(Validacion(getUsuario))
      }
    }
    if (name == "nRol_ID") {
      if (value == "9") {
        setHiddenCboCliente(false)
        setErrores(Validacion(getUsuario))
      }
      else {
        setHiddenCboCliente(true)
        // setUsuario(prevState => ({
        //   ...prevState,
        //   cUsu_Nombres: "",
        //   cUsu_email: "",
        //   cUsu_Fono: "",
        // }))
        setErrores(Validacion(getUsuario))
      }
    }

  }
  // Funcion para consumir apis rest GET
  const FuncionListarUsuario = async () => {
    await instance
      .get("/Usuario/ListarUsuarioAll")
      .then(response => {
        console.log(response.data)
        setData(response.data)

      })
      .catch(error => {

      })
  }
  const FuncionListarModulos = async () => {
    var postData = {
      Prefijo: "ME",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataModulos(response.data)

      })
      .catch(error => {

      })
  }
  const FuncionAgregarUsuarioAcceso = async (_nUsu_Id, _cUsu_Modulo, _cUsu_Opcion, _bUsu_Editar, _bUsu_Eliminar, _bUsu_Consultar) => {
    var postData = {
      nUsu_Id: Number.parseInt(_nUsu_Id),
      cUsu_Modulo: _cUsu_Modulo,
      cUsu_Opcion: _cUsu_Opcion,
      bUsu_Editar: _bUsu_Editar,
      bUsu_Eliminar: _bUsu_Eliminar,
      bUsu_Consultar: _bUsu_Consultar,
    }
    await instance
      .post("/Acceso/xDefecto", postData)
      .then(response => {
      })
      .catch(error => {

      })
  }
  const Validacion = (form) => {
    let errors = {};
    let regexLetras = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    let regexCorreo = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    let regexNumero = /^[0-9]+$/;
    if (form.cUsu_Nombres == null || form.cUsu_Nombres == undefined) {
      errors.cUsu_Nombres = "(*)"
    }
    else if (!form.cUsu_Nombres.trim()) {
      errors.cUsu_Nombres = "(*)"
    }
    else if (form.cUsu_Nombres.length > 50) {
      errors.cUsu_Nombres = "El campo solo puede tener 50 caracteres"
    }
    if (form.cUsu_email == "" || form.cUsu_email == null || form.cUsu_email == undefined || form.cUsu_email.trim() == "") {
      errors.cUsu_email = "(*)"
    }
    else if (!regexCorreo.test(form.cUsu_email.trim())) {
      errors.cUsu_email = "El correo no es valido"
    }
    else if (form.cUsu_email.length > 50) {
      errors.cUsu_email = "El correo solo puede tener 50 caracteres"
    }
    if (form.cUsu_Fono == undefined || form.cUsu_Fono == null) {
      errors.cUsu_Fono = "(*)"
    }
    else if (!form.cUsu_Fono.trim()) {
      errors.cUsu_Fono = "(*)"
    }
    else if (!regexNumero.test(form.cUsu_Fono.trim())) {
      errors.cUsu_Fono = "El campo solo acepta numeros"
    }
    else if (form.cUsu_Fono.length > 12) {
      errors.cUsu_Fono = "El campo solo puede tener 12 numeros"
    }
    if (form.cUsu_Login == undefined || form.cUsu_Login == null) {
      errors.cUsu_Login = "(*)"
    }
    else if (!form.cUsu_Login.trim()) {
      errors.cUsu_Login = "(*)"
    }
    else if (form.cUsu_Login.length > 50) {
      errors.cUsu_Login = "El campo solo puede tener 50 caracteres"
    }
    if (form.cUsu_Pass == undefined || form.cUsu_Pass == null) {
      errors.cUsu_Pass = "(*)"
    }
    else if (!form.cUsu_Pass.trim()) {
      errors.cUsu_Pass = "(*)"
    }
    else if (!form.cUsu_Pass.length > 15) {
      errors.cUsu_Pass = "El campo solo puede tener 15 caracteres"
    }
    if (hiddenCboCliente == false && form.nUsu_Cli_Id == "0" || hiddenCboCliente == false && form.nUsu_Cli_Id == 0) {
      errors.nUsu_Cli_Id = "(*)"
    }


    return errors;
  }

  // Funcion para consumir apis rest POST 
  const FuncionAgregarUsuario = async () => {



    if (hiddenCboCliente == false && getUsuario.nUsu_Cli_Id == "0" || hiddenCboCliente == false && getUsuario.nUsu_Cli_Id == null) {
      Swal.fire({
        title: "Mensaje",
        text: "Seleccione un cliente",
        icon: "error",
        confirmButtonText: "Aceptar",
      })
      setValidData(true);
    }
    else if (Object.keys(getErrores).length === 0) {

      let _nUsu_Cli_Id = null;
      if (hiddenCboCliente == true) {
        _nUsu_Cli_Id = null;
      }
      else {
        _nUsu_Cli_Id = parseInt(getUsuario.nUsu_Cli_Id);
      }
      var postData = {
        nRol_ID: parseInt(getUsuario.nRol_ID),
        cUsu_Nombres: getUsuario.cUsu_Nombres,
        cUsu_email: getUsuario.cUsu_email,
        cUsu_Fono: getUsuario.cUsu_Fono,
        cUsu_Login: getUsuario.cUsu_Login,
        cUsu_Pass: getUsuario.cUsu_Pass,
        bUsu_Activo: 1,
        nUsu_Cli_Id: _nUsu_Cli_Id,
        usuario: getMyUsuario.cUsu_Nombres
      }


      await instance
        .post("/Usuario/Insertar", postData)
        .then(async response => {

          if (response.data == "status") {
            Swal.fire({
              title: "Mensaje",
              text: "Ya existe un usuario con el mismo login",
              icon: "error",
              confirmButtonText: "Aceptar",
            })
          }
          else {
            Swal.fire({
              title: "Mensaje",
              text: "Agregado exitosamente",
              icon: "success",
              confirmButtonText: "Aceptar",
            })
            FuncionListarUsuario()
            setModalInsertar(!modalInsertar)

            const Modulos = dataModulos.map((row, i) => {
              FuncionAgregarUsuarioAcceso(response.data[0].nUsu_Id, row.cTabCodigo, 1, 1, 1, 1)
            })
          }
        })
        .catch(error => {

        })
    } else {
      setValidData(true);
      Swal.fire({
        title: "Mensaje",
        text: "Complete los campos requeridos",
        icon: "error",
        confirmButtonText: "Aceptar",
      })
    }
  }
  const FuncionValidarFormulario = async () => {
    FuncionAgregarUsuario()
  }
  const FuncionActualizarUsuario = async () => {

    if (Object.keys(getErrores).length === 0) {
      if (hiddenCboCliente == false && getUsuario.nUsu_Cli_Id == "0" || hiddenCboCliente == false && getUsuario.nUsu_Cli_Id == null) {
        Swal.fire({
          title: "Mensaje",
          text: "Seleccione un cliente",
          icon: "error",
          confirmButtonText: "Aceptar",
        })

      }
      else {

        var postData = {
          nUsu_ID: getUsuario.nUsu_ID,
          nRol_ID: parseInt(getUsuario.nRol_ID),
          cUsu_Nombres: getUsuario.cUsu_Nombres,
          cUsu_email: getUsuario.cUsu_email,
          cUsu_Fono: getUsuario.cUsu_Fono,
          cUsu_Login: getUsuario.cUsu_Login,
          cUsu_Pass: getUsuario.cUsu_Pass,
          bUsu_Activo: Number.parseInt(getUsuario.bUsu_Activo),
          nUsu_Cli_Id: parseInt(getUsuario.nUsu_Cli_Id)
        }
        let ID = parseInt(getDecryptedItem("ID")); //id del usuario logeado
        let rol = parseInt(getDecryptedItem("RolID")); //rol del usuario logeado
        let Rol = dataRol.find(element => element.nRol_ID == rol);


        if (ID == getUsuario.nUsu_ID) {
          if (rol != parseInt(getUsuario.nRol_ID) && rol == 1 || rol == 6) {
            Swal.fire({
              title: "Mensaje",
              text: `No puede cambiar su rol como ${Rol.cRol_Nombre}`,
              icon: "error",
              confirmButtonText: "Aceptar",
            });
          }
          else {
            await instance
              .post("/Usuario/Actualizar", postData)
              .then(async response => {

                FuncionListarUsuario()


                if (response.data == "existe") {
                  Swal.fire({
                    title: "Mensaje",
                    text: "Este login ya existe",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                  });
                }
                else {
                  Swal.fire({
                    title: "Mensaje",
                    text: "Agregado exitosamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                  });
                  abrirCerrarModal(2)
                }
              })
              .catch(error => {

              })
          }
        }
        else {
          await instance
            .post("/Usuario/Actualizar", postData)
            .then(async response => {

              FuncionListarUsuario()

              if (response.data == "existe") {
                Swal.fire({
                  title: "Mensaje",
                  text: "Este login ya existe",
                  icon: "error",
                  confirmButtonText: "Aceptar",
                });
              }
              else {
                Swal.fire({
                  title: "Mensaje",
                  text: "Actualizado exitosamente",
                  icon: "success",
                  confirmButtonText: "Aceptar",
                });
                abrirCerrarModal(2)
              }


            })
            .catch(error => {

            })
        }
      }
    }
    else {
      setValidData(true);
      Swal.fire({
        title: "Mensaje",
        text: "Complete los campos requeridos",
        icon: "error",
        confirmButtonText: "Aceptar",
      })
    }
  }
  const FuncionListarRoles = async () => {
    await instance
      .post("/Roles/Listar",)
      .then(response => {
        setDataRol(response.data)

      })
      .catch(error => {

      })
  }
  const FuncionEliminarUsuario = async () => {
    var postData = {
      nUsu_ID: getUsuario.nUsu_ID,
    }
    await instance
      .post("/Usuario/Eliminar", postData)
      .then(response => {
        FuncionListarUsuario()

        abrirCerrarModal(3)
      })
      .catch(error => {

      })
  }
  const datas = {
    columns: [
      {
        label: "Rol",
        field: "nRol_ID2",
        sort: "asc",
        width: 10,
      },
      {
        label: "Login",
        field: "cUsu_Login",
        sort: "asc",
        width: 10,
      },
      {
        label: "Usuario",
        field: "cUsu_Nombres",
        sort: "asc",
        width: 300,
      },
      {
        label: "Correo",
        field: "cUsu_email",
        sort: "asc",
        width: 10,
      },
      {
        label: "Nombre de cliente",
        field: "cCli_NombreCorto",
        sort: "asc",
        width: 10,
      },
    ],
    rows: data,
  }
  const Acciones = (Usuario, caso) => {
    setUsuario(Usuario)

    setHiddenCboCliente(true);
    setErrores({})
    if (caso == "Editar") {
      Validacion(Usuario)
      if (Usuario.nRol_ID2 == "Cliente") {
        setHiddenCboCliente(false);
      }

      abrirCerrarModal(2)


    } else {
      abrirCerrarModal(3)
    }
  }
  const badgesData = {
    columns: [
      ...datas.columns,
      {
        label: "Acciones",
        field: "acciones",
        width: 150,
      },
    ],
    rows: [
      ...datas.rows.map((row, id) => ({
        ...row,
        acciones: (
          <div>
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="dropdown-toggle">
                <i className="bx bx-dots-vertical-rounded" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem hidden={hiddenBtnEditar} onClick={() => Acciones(row, "Editar")}>
                  <Link>
                    Editar
                  </Link>
                </DropdownItem>
                <DropdownItem hidden={hiddenBtnEliminar}
                  onClick={() =>
                    Swal.fire({
                      title: "Mensaje",
                      html:
                        "¿Esta seguro de eliminar el usuario?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Aceptar",
                      cancelButtonText: "Cancelar",
                    }).then(result => {
                      if (result.isConfirmed) {
                        Acciones(row, "Eliminar")
                      }
                    })
                  }
                >
                  Eliminar
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        ),
      })),
    ],
  }
  const abrirCerrarModal = Modal => {
    setValidData(false);
    if (Modal == 1) {

      Validacion(getUsuario)
      setUsuario(prevState => ({
        ...prevState,
        nUsu_ID: "",
        nRol_ID: 1,
        nRol_ID2: "",
        cUsu_Nombres: "",
        cUsu_email: "",
        cUsu_Fono: "",
        cUsu_Login: "",
        cUsu_Pass: "",
        bUsu_Activo: 1,
        nUsu_Cli_Id: "0"
      }))
      // IF para el modal si esta abierto
      if (!modalInsertar == false) {
        Swal.fire({
          html: "¿Seguro que desea salir sin guardar los datos?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        }).then(result => {
          if (result.isConfirmed) {
            // Mandas valor inverso para el modal
            setModalInsertar(!modalInsertar)

          }
        })
      } else {
        setModalInsertar(!modalInsertar)
      }
    } else if (Modal == 2) {



      setModalActualizar(!modalActualizar)
    } else {
      setModalEliminar(!modalEliminar)
    }
  }
  const handleBlur = e => {
    handleChange(e)
    setErrores(Validacion(getUsuario))

  }
  const BluerChange = e => {
    const { name, value } = e.target
    setMonto(prevState => ({
      ...prevState,
      [name]: getMonto.MontoFormat,
    }))
  }
  const handleChangeMoneda = e => {


    setMoneda(e.target.value)
  }

  const FocusChange = e => {
    const { name, value } = e.target

    setMonto(prevState => ({
      ...prevState,
      [name]: getMonto.MontoFormat,
    }))

  }
  const FuncionListarComboMoneda = async () => {
    var postData = {
      Prefijo: "MO",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataComboMoneda(response.data)

      })
      .catch(error => {

      })
  }
  const changeVisibility = async () => {
    var tipo = document.getElementById("cUsu_Pass");
    if (tipo.type == "password") {
      tipo.type = "text";
      setVisible1("none")
      setVisible2("")
    } else {
      tipo.type = "password";
      setVisible1("")
      setVisible2("none")
    }
  }
  const FuncionListarPermisos = async () => {
    var postData = {
      nUsu_ID: parseInt(getDecryptedItem("RolID"))
    }
    await instance
      .post("/Usuario/Acceso", postData)
      .then(response => {
        let arrayPermisos = response.data;
        const permiso = arrayPermisos.find(element => element.cUsu_Modulo == "ME20");

        if (permiso.bUsu_Editar == 1) {
          setHiddenBtnEditar(false);
        }
        else {
          setHiddenBtnEditar(true);
        }
        if (permiso.bUsu_Eliminar == 1) {
          setHiddenBtnEliminar(false);
        }
        else {
          setHiddenBtnEliminar(true);
        }
      })
      .catch(error => {

      })
  }
  const FuncionListarCliente = async () => {
    let RolId = getDecryptedItem("RolID")

    var postData = {
      bCli_Tipo: null,
      nUsu_ID: RolId == 6 || RolId == 1 ? null : getDecryptedItem("ID")
    }
    await instance
      .post("/Cliente/ListarCbo", postData)
      .then(response => {
        setDataCliente(response.data)

      })
      .catch(error => {

      })
  }
  const BuscarUsuario = async (usuario) => {
    let postData = {
      nUsu_ID: parseInt(getDecryptedItem("ID")),
    }
    await instance
      .post("/Usuario/Perfil", postData)
      .then(response => {

        setMyUsuario(response.data[0])
      })
      .catch(error => {

      })
  }

  useEffect(() => {
    BuscarUsuario();
    FuncionListarCliente();
    FuncionListarPermisos();
    setErrores(Validacion(getErrores))
    FuncionListarComboMoneda()
    FuncionListarUsuario()
    FuncionListarRoles()
    FuncionListarModulos()
  }, [])
  return (
    <React.Fragment>

      <div className="page-content">
        { }
        <MetaTags>
          <title>Usuario | GpsLegal</title>
        </MetaTags>

        <Container fluid>
          { }
          <Breadcrumbs title="GpsLegal" breadcrumbItem="Tabla Usuario" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div>
                    <button
                      type="button"
                      onClick={() => abrirCerrarModal(1)}
                      className="btn btn-danger waves-effect waves-light"
                      data-toggle="modal"
                      data-target="#myModal"
                      hidden={hiddenBtnEditar}
                    >
                      Nuevo Usuario
                    </button>
                    <Modal size="lg" isOpen={modalInsertar}>
                      <div
                        className="modal-header ModalHeaderColor"
                      >
                        <h5
                          style={{ color: "#fff" }}
                          className="modal-title mt-0"
                          id="myModalLabel"
                        >
                          Nuevo Usuario
                        </h5>
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(1)}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="mb-3 row">
                          <div className="col-md-4">
                            <select

                              className={getErrores.nRol_ID && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                              name="nRol_ID"
                              onChange={handleChange}
                            >
                              {dataRol.map((row, i) => (
                                <option key={i} value={row.nRol_ID}>
                                  {row.cRol_Nombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">Seleccione rol</label>{getErrores.nRol_ID && <label className="col-form-label labelDown labelRed">{getErrores.nRol_ID}</label>}

                          </div>
                          <div className="col-md-4">
                            <input
                              name="cUsu_Login"

                              className={getErrores.Login && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                              type="text"
                              placeholder="Login"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              maxLength="50"
                              autoComplete="username"
                            />
                            <label className="col-form-label labelDown">Login</label>{getErrores.cUsu_Login && <label className="col-form-label labelDown labelRed">{getErrores.cUsu_Login}</label>}

                          </div>
                          <div className="col-md-3">
                            <input
                              id="cUsu_Pass"
                              name="cUsu_Pass"
                              className={getErrores.cUsu_Pass && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                              type="password"
                              placeholder="Contraseña"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              maxLength="15"
                              autocomplete="new-password"
                            />
                            <label className="col-form-label labelDown">Contraseña</label>{getErrores.cUsu_Pass && <label className="col-form-label labelDown labelRed">{getErrores.cUsu_Pass}</label>}
                          </div>
                          <div className="col-md-1"
                            style={{
                              borderRadius: '5px',
                              cursor: 'pointer',
                              height: '30px',
                              marginTop: '15px',
                              textAlign: 'left',
                              paddingLeft: '0px'
                            }}
                            onClick={() => changeVisibility()}
                          >
                            <i className="icon-ver" style={{ display: getVisible1, width: '5px' }}> </i>
                            <i className="icon-no-ver" style={{ display: getVisible2, width: '10px' }}> </i>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-4">
                            <input
                              name="cUsu_Nombres"
                              className={getErrores.cUsu_Nombres && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                              type="text"
                              placeholder="Nombre completo"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={getUsuario && getUsuario.cUsu_Nombres}
                              maxLength="50"
                            />
                            <label className="col-form-label labelDown">Nombre completo</label>{getErrores.cUsu_Nombres && <label className="col-form-label labelDown labelRed">{getErrores.cUsu_Nombres}</label>}
                          </div>
                          <div className="col-md-4">
                            <input
                              name="cUsu_email"
                              className={getErrores.cUsu_email && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                              type="text"
                              placeholder="Correo"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={getUsuario && getUsuario.cUsu_email}
                              maxLength="50"
                              autoComplete="username"
                            />
                            <label className="col-form-label labelDown">Correo</label>{getErrores.cUsu_email && <label className="col-form-label labelDown labelRed">{getErrores.cUsu_email}</label>}
                          </div>
                          <div className="col-md-4">
                            <input
                              name="cUsu_Fono"
                              className={getErrores.cUsu_Fono && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                              type="text"
                              placeholder="Teléfono"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={getUsuario && getUsuario.cUsu_Fono}
                              maxLength="12"

                            />
                            <label className="col-form-label labelDown">Teléfono</label>{getErrores.cUsu_Fono && <label className="col-form-label labelDown labelRed">{getErrores.cUsu_Fono}</label>}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-4">
                            <select
                              className="form-select"
                              name="bUsu_Activo"
                              onChange={handleChange}
                            >
                              <option selected value="1">Activo</option>
                              <option value="0">No activo</option>
                            </select>
                            <label className="col-form-label labelDown">
                              Estado
                            </label>
                          </div>
                          <div className="col-md-4" hidden={hiddenCboCliente}>
                            <select
                              className="form-select inputDown"
                              name="nUsu_Cli_Id"
                              onChange={handleChange}
                              value={getUsuario && getUsuario.nUsu_Cli_Id}
                            >
                              <option value="0">Seleccionar...</option>
                              {dataCliente.map((row, i) => (
                                <option key={i} value={row.nCli_Id}>
                                  {row.cCli_NombreCompleto}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">Cliente</label>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(1)}
                          className="btn btn-secondary waves-effect"
                          data-dismiss="modal"
                        >
                          Cerrar
                        </button>
                        <button
                          type="button"
                          onClick={() => FuncionValidarFormulario()}
                          className="btn btn-danger waves-effect waves-light"
                        >
                          Guardar
                        </button>
                      </div>
                    </Modal>
                    <Modal size="lg" isOpen={modalActualizar}>
                      <div
                        className="modal-header ModalHeaderColor"
                      >
                        <h5
                          style={{ color: "#fff" }}
                          className="modal-title mt-0"
                          id="myModalLabel"
                        >
                          Editar Usuario
                        </h5>
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(2)}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="mb-3 row">
                          <div className="col-md-4">
                            <select

                              className={getErrores.nRol_ID && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                              name="nRol_ID"
                              onChange={handleChange}
                              value={getUsuario && getUsuario.nRol_ID}
                            >
                              {dataRol.map((row, i) => (
                                <option key={i} value={row.nRol_ID}>
                                  {row.cRol_Nombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">Seleccione rol</label>{getErrores.nRol_ID && <label className="col-form-label labelDown labelRed">{getErrores.nRol_ID}</label>}

                          </div>
                          <div className="col-md-4">
                            <input
                              name="cUsu_Login"
                              value={getUsuario && getUsuario.cUsu_Login}
                              className={getErrores.Login && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                              type="text"
                              placeholder="Login"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              maxLength="50"
                              autoComplete="username"
                            />
                            <label className="col-form-label labelDown">Login</label>{getErrores.cUsu_Login && <label className="col-form-label labelDown labelRed">{getErrores.cUsu_Login}</label>}

                          </div>
                          <div className="col-md-3">
                            <input
                              id="cUsu_Pass"
                              name="cUsu_Pass"
                              className={getErrores.cUsu_Pass && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                              type="password"
                              value={getUsuario && getUsuario.cUsu_Pass}
                              placeholder="Contraseña"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              maxLength="15"
                              autocomplete="new-password"
                            />
                            <label className="col-form-label labelDown">Contraseña</label>{getErrores.cUsu_Pass && <label className="col-form-label labelDown labelRed">{getErrores.cUsu_Pass}</label>}
                          </div>
                          <div className="col-md-1"
                            style={{
                              borderRadius: '5px',
                              cursor: 'pointer',
                              height: '30px',
                              marginTop: '15px',
                              textAlign: 'left',
                              paddingLeft: '0px'
                            }}
                            onClick={() => changeVisibility()}
                          >
                            <i className="icon-ver" style={{ display: getVisible1, width: '5px' }}> </i>
                            <i className="icon-no-ver" style={{ display: getVisible2, width: '10px' }}> </i>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-4">
                            <input
                              name="cUsu_Nombres"
                              className={getErrores.cUsu_Nombres && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                              type="text"
                              placeholder="Nombre completo"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={getUsuario && getUsuario.cUsu_Nombres}
                              maxLength="50"
                            />
                            <label className="col-form-label labelDown">Nombre completo</label>{getErrores.cUsu_Nombres && <label className="col-form-label labelDown labelRed">{getErrores.cUsu_Nombres}</label>}
                          </div>
                          <div className="col-md-4">
                            <input
                              name="cUsu_email"
                              className={getErrores.cUsu_email && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                              type="text"
                              placeholder="Correo"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={getUsuario && getUsuario.cUsu_email}
                              maxLength="50"
                            />
                            <label className="col-form-label labelDown">Correo</label>{getErrores.cUsu_email && <label className="col-form-label labelDown labelRed">{getErrores.cUsu_email}</label>}
                          </div>
                          <div className="col-md-4">
                            <input
                              name="cUsu_Fono"
                              className={getErrores.cUsu_Fono && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                              type="text"
                              placeholder="Teléfono"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={getUsuario && getUsuario.cUsu_Fono}
                              maxLength="12"
                            />
                            <label className="col-form-label labelDown">Teléfono</label>{getErrores.cUsu_Fono && <label className="col-form-label labelDown labelRed">{getErrores.cUsu_Fono}</label>}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-4">
                            <select
                              className="form-select"
                              name="bUsu_Activo"
                              onChange={handleChange}
                            >
                              <option selected value="1">Activo</option>
                              <option value="0">No activo</option>
                            </select>
                            <label className="col-form-label labelDown">
                              Estado
                            </label>
                          </div>
                          <div className="col-md-4" hidden={hiddenCboCliente}>
                            <select
                              className={getErrores.nUsu_Cli_Id && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                              name="nUsu_Cli_Id"
                              onChange={handleChange}
                              value={getUsuario && getUsuario.nUsu_Cli_Id}
                            >
                              <option value="0">Seleccionar...</option>
                              {dataCliente.map((row, i) => (
                                <option key={i} value={row.nCli_Id}>
                                  {row.cCli_NombreCompleto}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">Cliente</label>{getErrores.nUsu_Cli_Id && <label className="col-form-label labelDown labelRed">{getErrores.nUsu_Cli_Id}</label>}
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(2)}
                          className="btn btn-secondary waves-effect"
                          data-dismiss="modal"
                        >
                          Cerrar
                        </button>
                        <button
                          type="button"
                          onClick={() => FuncionActualizarUsuario()}
                          className="btn btn-danger waves-effect waves-light"
                        >
                          Guardar
                        </button>
                      </div>
                    </Modal>
                    <Modal isOpen={modalEliminar}>
                      <div
                        className="modal-header ModalHeaderColor"
                      >
                        <h5
                          style={{ color: "#fff" }}
                          className="modal-title mt-0"
                          id="myModalLabel"
                        >
                          Eliminar Usuario
                        </h5>
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(3)}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        ¿Estás seguro que deseas eliminar al usuario{" "}<b>{getUsuario && getUsuario.nRol_ID2}{":"}{" "}
                          {getUsuario && getUsuario.cUsu_Nombres}</b>?
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(3)}
                          className="btn btn-secondary waves-effect"
                          data-dismiss="modal"
                        >
                          Cerrar
                        </button>
                        <button
                          type="button"
                          onClick={() => FuncionEliminarUsuario()}
                          className="btn btn-danger waves-effect waves-light"
                        >
                          Eliminar
                        </button>
                      </div>
                    </Modal>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col className="col-12">
                      <Card>
                        <CardBody>

                          <CardTitle style={{ fontSize: "20px", color: "#aa1b0E", fontWeight: "normal" }}>
                            <span style={{ color: "#aa1b0E" }}>Listado de Usuarios</span>{" "}
                          </CardTitle>

                          <MDBDataTable
                            data={badgesData}
                            className="TablaUsuarios"
                            responsive
                            hover
                            entrylabel={"Mostrar entradas"}
                            infoLabel={['Mostrando registros del', 'al', 'de un total de', 'registros']}
                            bordered
                            noRecordsFoundLabel={"No hay resultados"}
                            searchLabel={"Buscar"}
                            noBottomColumns
                            paginationLabel={['Anterior', 'Siguiente']}
                          />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Usuario
