import React, { Component, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link, useHistory } from "react-router-dom"
import { Alert, Breadcrumb, BreadcrumbItem, Col, Row } from "reactstrap"
import { getDecryptedItem } from '../../util';

import Swal from "sweetalert2"
import ModalTarea from "pages/Componentes/ModalTarea"
import ModalEvento from "pages/Componentes/ModalEvento"
import ModalIngreso from "pages/Componentes/ModalIngreso"
import ModalEgreso from "pages/Componentes/ModalEgreso"
import ModalHorasLaboradas from "pages/Componentes/ModalHorasLaboradas"
import ModalFlujo from "pages/Componentes/ModalFlujo"
import instance from '../../config'
const baseUrl = process.env.REACT_APP_API_URL
const VerificUrl = process.env.Verific_API_URL
import {
  useRouteMatch
} from "react-router-dom";

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { ModalNoticia } from "pages/Componentes/ModalNoticia"
import { useInterval } from 'react-use';

const Breadcrumbs = ({ breadcrumbItem, Condicion, title, ReloadTareas, ReloadEventos, ReloadHL, ReloadFlujo, ReloadEgreso, style }) => {

  const [modalInsertarTarea, setModalInsertarTarea] = useState(false)
  const [modalInsertarEvento, setModalInsertarEvento] = useState(false)
  const [modalInsertarIngreso, setModalInsertarIngreso] = useState(false)
  const [modalInsertarFlujo, setModalInsertarFlujo] = useState(false)
  const [modalInsertarEgreso, setModalInsertarEgreso] = useState(false)
  const [modalInsertarHLab, setModalInsertarHLab] = useState(false)

  const [hiddenBtnEditar_ing, setHiddenBtnEditar_ing] = useState(true)
  const [hiddenBtnEliminar_ing, setHiddenBtnEliminar_ing] = useState(true)

  const [hiddenBtnEditar_egr, setHiddenBtnEditar_egr] = useState(true)
  const [hiddenBtnEliminar_egr, setHiddenBtnEliminar_egr] = useState(true)

  const [hiddenBtnEditar_tar, setHiddenBtnEditar_tar] = useState(true)
  const [hiddenBtnEliminar_tar, setHiddenBtnEliminar_tar] = useState(true)

  const [hiddenBtnEditar_eve, setHiddenBtnEditar_eve] = useState(true)
  const [hiddenBtnEliminar_eve, setHiddenBtnEliminar_eve] = useState(true)

  const [hiddenBtnEditar_hla, setHiddenBtnEditar_hla] = useState(true)
  const [hiddenBtnEliminar_hla, setHiddenBtnEliminar_hla] = useState(true)

  const [hiddenBtnEditar_doc, setHiddenBtnEditar_doc] = useState(true)
  const [hiddenBtnEliminar_doc, setHiddenBtnEliminar_doc] = useState(true)

  const [hiddenBtnEditar_flu, setHiddenBtnEditar_flu] = useState(true)
  const [hiddenBtnEliminar_flu, setHiddenBtnEliminar_flu] = useState(true)

  

  const [getModalNoticia, setModalNoticia] = useState(false)
  const jsonwebtoken = require('jsonwebtoken');
  const SECRET_KEY_JWT = "secretkey5353";

  var Fecha = new Date() //Fecha actual
  var mes = Fecha.getMonth() + 1 //obteniendo mes
  var dia = Fecha.getDate() //obteniendo dia
  var ano = Fecha.getFullYear() //obteniendo año
  if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10 if (mes < 10) mes="0" + mes //agrega cero si el menor de 10

  const [getFiltroGrafico, setFiltroGrafico] = useState({
    M: parseInt(Fecha.getMonth() + 1),
    Y: parseInt(Fecha.getFullYear()),
  })
  const [dataHorasEstadisticas, setDataHorasEstadisticas] = useState([])
  const [HorasLabHL, setHorasHorasLabHL] = useState("")
  const [HorasLabHF, setHorasHorasLabHF] = useState("")
  const [HorasLabHNF, setHorasHorasLabHNF] = useState("")

  let { path, url } = useRouteMatch();
  let history = useHistory();


  const FuncionListarEventos = async () => {
    var postData = {
      nUsu_ID: getDecryptedItem('RolID')
    }
    toastr.options = {
      "closeButton": false,
      "debug": false,
      "newestOnTop": false,
      "progressBar": true,
      "positionClass": "toast-top-right",
      "preventDuplicates": false,
      "onclick": null,
      "showDuration": "1000",
      "hideDuration": "1000",
      "timeOut": "5000",
      "extendedTimeOut": "1000",
      "showEasing": "swing",
      "hideEasing": "linear",
      "showMethod": "fadeIn",
      "hideMethod": "fadeOut"
    }
    await instance
      .post("/Usuario/Acceso", postData)
      .then(response => {

        const UsuarioAcceso = response.data.map((row, i) => {
          if (row.cUsu_Modulo == "ME01") {
            if (url == "/dashboard-Abogado") {
              if (Boolean(parseInt(row.cUsu_Opcion)) == false) {
                history.push("/MiCuenta");
              }
            }
          } else if (row.cUsu_Modulo == "ME02") {
            if (url == "/Casos") {
              if (Boolean(parseInt(row.cUsu_Opcion)) == false) {
                toastr.error('Usted no tiene acceso al modulo de casos')
                history.push("/MiCuenta");
              }
            }
          } else if (row.cUsu_Modulo == "ME03") {
            if (url == "/Cliente") {
              if (Boolean(parseInt(row.cUsu_Opcion)) == false) {
                toastr.error('Usted no tiene acceso al modulo de cliente')
                history.push("/MiCuenta");
              }
            }
          } else if (row.cUsu_Modulo == "ME04") {
            if (url == "/Evento") {
              if (Boolean(parseInt(row.cUsu_Opcion)) == false) {
                toastr.error('Usted no tiene acceso al modulo de evento')
                history.push("/MiCuenta");
              }
            }
          } else if (row.cUsu_Modulo == "ME05") {
            if (url == "/Tareas") {
              if (Boolean(parseInt(row.cUsu_Opcion)) == false) {
                toastr.error('Usted no tiene acceso al modulo de tarea')
                history.push("/MiCuenta");
              }
            }
          } else if (row.cUsu_Modulo == "ME06") {
            if (url == "/Ingresos") {
              if (Boolean(parseInt(row.cUsu_Opcion)) == false) {
                toastr.error('Usted no tiene acceso al modulo de ingresos')
                history.push("/MiCuenta");
              }
            }
          } else if (row.cUsu_Modulo == "ME07") {
            if (url == "/Egresos") {
              if (Boolean(parseInt(row.cUsu_Opcion)) == false) {
                toastr.error('Usted no tiene acceso al modulo de egresos')
                history.push("/MiCuenta");
              }
            }
          } else if (row.cUsu_Modulo == "ME08") {
            if (url == "/calendar") {
              if (Boolean(parseInt(row.cUsu_Opcion)) == false) {
                toastr.error('Usted no tiene acceso al modulo de calendario')
                history.push("/MiCuenta");
              }
            }
          } else if (row.cUsu_Modulo == "ME09") {
            if (url == "/NuevoModulo") {
              if (Boolean(parseInt(row.cUsu_Opcion)) == false) {
                toastr.error('Usted no tiene acceso al modulo de documentos')
                history.push("/MiCuenta");
              }
            }
          } else if (row.cUsu_Modulo == "ME10") {
            if (url == "/HorasLaboradas") {
              if (Boolean(parseInt(row.cUsu_Opcion)) == false) {
                toastr.error('Usted no tiene acceso al modulo de horas laboradas')
                history.push("/MiCuenta");
              }
            }
          } else if (row.cUsu_Modulo == "ME11") {
            if (url == "/ReporteProcesar") {
              if (Boolean(parseInt(row.cUsu_Opcion)) == false) {
                toastr.error('Usted no tiene acceso al modulo de procesar liquidación')
                history.push("/MiCuenta");
              }
            }
          } else if (row.cUsu_Modulo == "ME13") {
            if (url == "/ReporteEstadoCuenta") {
              if (Boolean(parseInt(row.cUsu_Opcion)) == false) {
                toastr.error('Usted no tiene acceso al modulo de estado de cuenta')
                history.push("/MiCuenta");
              }
            }
          } else if (row.cUsu_Modulo == "ME14") {
            if (url == "/ReporteEstadoCuenta2") {
              if (Boolean(parseInt(row.cUsu_Opcion)) == false) {
                toastr.error('Usted no tiene acceso al modulo de reporte liquidación')
                history.push("/MiCuenta");
              }
            }
          } else if (row.cUsu_Modulo == "ME15") {
            if (url == "/ReporteAvanzado") {
              if (Boolean(parseInt(row.cUsu_Opcion)) == false) {
                toastr.error('Usted no tiene acceso al modulo de reporte analítico')
                history.push("/MiCuenta");
              }
            }
          } else if (row.cUsu_Modulo == "ME16") {
            if (url == "/Correo") {
              if (Boolean(parseInt(row.cUsu_Opcion)) == false) {
                toastr.error('Usted no tiene acceso al modulo de correos')
                history.push("/MiCuenta");
              }
            }
          } else if (row.cUsu_Modulo == "ME18") {
            if (url == "/MiCuenta") {
              if (Boolean(parseInt(row.cUsu_Opcion)) == false) {
                toastr.error('Usted no tiene acceso al modulo de mi cuenta')
                history.push("/MiCuenta");
              }
            }
          } else if (row.cUsu_Modulo == "ME19") {
            if (url == "/Roles") {
              if (Boolean(parseInt(row.cUsu_Opcion)) == false) {
                toastr.error('Usted no tiene acceso al modulo de roles')
                history.push("/MiCuenta");
              }
            }
          } else if (row.cUsu_Modulo == "ME20") {
            if (url == "/Usuario") {
              if (Boolean(parseInt(row.cUsu_Opcion)) == false) {
                toastr.error('Usted no tiene acceso al modulo de usuario')
                history.push("/MiCuenta");
              }
            }
          } else if (row.cUsu_Modulo == "ME21") {
            if (url == "/Notificaciones") {
              if (Boolean(parseInt(row.cUsu_Opcion)) == false) {
                toastr.error('Usted no tiene acceso al modulo de notificaciones')
                history.push("/MiCuenta");
              }
            }
          } else if (row.cUsu_Modulo == "ME22") {
            if (url == "/Maestros") {
              if (Boolean(parseInt(row.cUsu_Opcion)) == false) {
                toastr.error('Usted no tiene acceso al modulo de maestros')
                history.push("/MiCuenta");
              }
            }
          } else if (row.cUsu_Modulo == "ME23") {
            if (url == "/NuevoAmbiente") {
              if (Boolean(parseInt(row.cUsu_Opcion)) == false) {
                toastr.error('Usted no tiene acceso al modulo de WORKSPACE')
                history.push("/MiCuenta");
              }
            }
          } else if (row.cUsu_Modulo == "ME24") {
            if (url == "/Suscriptores") {
              if (Boolean(parseInt(row.cUsu_Opcion)) == false) {
                toastr.error('Usted no tiene acceso al modulo de suscriptores')
                history.push("/MiCuenta");
              }
            }
          } else if (row.cUsu_Modulo == "ME25") {

            if (url.includes("Flujo")) {
              if (Boolean(parseInt(row.cUsu_Opcion)) == false) {
                toastr.error('Usted no tiene acceso al modulo de Flujo')
                history.push("/MiCuenta");
              }
            }
          }
        })
      })
      .catch(error => {

      })
  }

  const ReloadModalListar = async () => {
    setModalInsertarTarea(!modalInsertarTarea)
  }

  const ReloadModalEvento = async () => {
    setModalInsertarEvento(!modalInsertarEvento)
    ReloadEventos();
  }

  const ReloadModalIngreso = async () => {
    setModalInsertarIngreso(!modalInsertarIngreso)
  }

  const ReloadModalEgreso = async () => {
    setModalInsertarEgreso(!modalInsertarEgreso)
  }


  const ReloadModalFlujo = async () => {
    setModalInsertarFlujo(!modalInsertarFlujo);
    ReloadFlujo();

  }

  const abrirCerrarModal = (modal) => {
    if (modal == 1) {
      if (!modalInsertarTarea == false) {
        Swal.fire({
          html: "¿Seguro que desea salir sin guardar los datos?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        }).then(result => {
          if (result.isConfirmed) {
            setModalInsertarTarea(!modalInsertarTarea)
          }
        })
      } else {
        setModalInsertarTarea(!modalInsertarTarea)
      }
    } else if (modal == 2) {
      if (!modalInsertarEvento == false) {
        Swal.fire({
          html: "¿Seguro que desea salir sin guardar los datos?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        }).then(result => {
          if (result.isConfirmed) {
            setModalInsertarEvento(!modalInsertarEvento)
          }
        })
      } else {
        setModalInsertarEvento(!modalInsertarEvento)
      }
    } else if (modal == 3) {
      if (!modalInsertarIngreso == false) {
        Swal.fire({
          html: "¿Seguro que desea salir sin guardar los datos?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        }).then(result => {
          if (result.isConfirmed) {
            setModalInsertarIngreso(!modalInsertarIngreso)
          }
        })
      } else {
        setModalInsertarIngreso(!modalInsertarIngreso)
      }
    } else if (modal == 4) {
      if (!modalInsertarEgreso == false) {
        Swal.fire({
          html: "¿Seguro que desea salir sin guardar los datos?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        }).then(result => {
          if (result.isConfirmed) {
            setModalInsertarEgreso(!modalInsertarEgreso)
          }
        })
      } else {
        setModalInsertarEgreso(!modalInsertarEgreso)
      }
    } else if (modal == 5) {
      if (!modalInsertarHLab == false) {
        Swal.fire({
          html: "¿Seguro que desea salir sin guardar los datos?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        }).then(result => {
          if (result.isConfirmed) {
            setModalInsertarHLab(!modalInsertarHLab)
          }
        })
      } else {
        setModalInsertarHLab(!modalInsertarHLab)
      }
    }
    else if (modal == 6) {
      if (!modalInsertarHLab == false) {
        Swal.fire({
          html: "¿Seguro que desea salir sin guardar los datos?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        }).then(result => {
          if (result.isConfirmed) {
            setModalInsertarFlujo(!modalInsertarFlujo)
          }
        })
      } else {
        setModalInsertarFlujo(!modalInsertarFlujo)
      }
    }

  }

  const FuncionListarPermisos = async () => {
    var postData = {
      nUsu_ID: getDecryptedItem('RolID')
    }
    await instance
      .post("/Usuario/Acceso", postData)
      .then(response => {
        let arrayPermisos = [];
        arrayPermisos = response.data;

        if (arrayPermisos.length == 0) {
          Swal.fire({
            title: "Mensaje",
            html:
              "Usted no cuenta con permisos habilitados",
            icon: "error",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Aceptar"
          }).then(result => {
            location.href = "/IniciarSesion";
          })
        }



        const permisoIng = arrayPermisos.find(element => element.cUsu_Modulo == "ME06");

        if (permisoIng.bUsu_Editar == 1) {
          setHiddenBtnEditar_ing(false);
        }
        else {
          setHiddenBtnEditar_ing(true);
        }

        if (permisoIng.bUsu_Eliminar == 1) {
          setHiddenBtnEliminar_ing(false);
        }
        else {
          setHiddenBtnEliminar_ing(true);
        }

        /************************************** */
        const permisoEgr = arrayPermisos.find(element => element.cUsu_Modulo == "ME07");

        if (permisoEgr.bUsu_Editar == 1) {
          setHiddenBtnEditar_egr(false);
        }
        else {
          setHiddenBtnEditar_egr(true);
        }

        if (permisoEgr.bUsu_Eliminar == 1) {
          setHiddenBtnEliminar_egr(false);
        }
        else {
          setHiddenBtnEliminar_egr(true);
        }
        /***************************************** */

        const permisoTar = arrayPermisos.find(element => element.cUsu_Modulo == "ME05");

        if (permisoTar.bUsu_Editar == 1) {
          setHiddenBtnEditar_tar(false);
        }
        else {
          setHiddenBtnEditar_tar(true);
        }

        if (permisoTar.bUsu_Eliminar == 1) {
          setHiddenBtnEliminar_tar(false);
        }
        else {
          setHiddenBtnEliminar_tar(true);
        }
        /***************************************** */

        const permisoEve = arrayPermisos.find(element => element.cUsu_Modulo == "ME04");

        if (permisoEve.bUsu_Editar == 1) {
          setHiddenBtnEditar_eve(false);
        }
        else {
          setHiddenBtnEditar_eve(true);
        }

        if (permisoEve.bUsu_Eliminar == 1) {
          setHiddenBtnEliminar_eve(false);
        }
        else {
          setHiddenBtnEliminar_eve(true);
        }
        /***************************************** */

        const permisoHla = arrayPermisos.find(element => element.cUsu_Modulo == "ME10");

        if (permisoHla.bUsu_Editar == 1) {
          setHiddenBtnEditar_hla(false);
        }
        else {
          setHiddenBtnEditar_hla(true);
        }

        if (permisoHla.bUsu_Eliminar == 1) {
          setHiddenBtnEliminar_hla(false);
        }
        else {
          setHiddenBtnEliminar_hla(true);
        }
        /***************************************** */

        const permisoDoc = arrayPermisos.find(element => element.cUsu_Modulo == "ME09");

        if (permisoDoc.bUsu_Editar == 1) {
          setHiddenBtnEditar_doc(false);
        }
        else {
          setHiddenBtnEditar_doc(true);
        }

        if (permisoDoc.bUsu_Eliminar == 1) {
          setHiddenBtnEliminar_doc(false);
        }
        else {
          setHiddenBtnEliminar_doc(true);
        }

        /***************************************** */

        const permisoFlu = arrayPermisos.find(element => element.cUsu_Modulo == "ME25");

        if (permisoFlu.bUsu_Editar == 1) {
          setHiddenBtnEditar_flu(false);
        }
        else {
          setHiddenBtnEditar_flu(true);
        }

        if (permisoFlu.bUsu_Eliminar == 1) {
          setHiddenBtnEliminar_flu(false);
        }
        else {
          setHiddenBtnEliminar_flu(true);
        }


      })
      .catch(error => {

      })
  }
  // useInterval(() => {
  //   try {
  //     const token = getDecryptedItem('token');
  //     const responseToken = jsonwebtoken.verify(token, SECRET_KEY_JWT);
  //     if (!responseToken) {
  //       throw new Error('Unauthorized Request');
  //     }
  //   } catch (error) {
  //     localStorage.clear();
  //     location.href = "/IniciarSesion";
  //   }
  // }, 1000);

  useEffect(() => {
    FuncionListarPermisos();
    FuncionListarEventos()
  }, [])

  return (
    <React.Fragment>
      {breadcrumbItem !== '' && title !== '' ? <Row style={{ height: style }}>
        <Col xs="12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <span className="colorTitulo">{breadcrumbItem}</span>
            {(() => {
              if (Condicion == "1") {
                return (
                  <>
                    <div className="col-md-1"></div>
                    <div className="col-md-1" hidden={hiddenBtnEditar_ing} style={{ textAlign: "center", paddingTop: "7px" }}>
                      <Link to="#" >
                        <i style={{
                          color: "#AA1B0E",
                          fontSize: "20px",
                          marginTop: "5px",
                          cursor: "pointer",
                        }} onClick={() => abrirCerrarModal(3)} className="icon-ingresos"></i>
                        {(() => {
                          if (modalInsertarIngreso == true) {
                            return <><ModalIngreso show={modalInsertarIngreso}
                              modo={0}
                              onCloseClick={() => abrirCerrarModal(3)}
                              ReloadLista={() => { ReloadModalIngreso(), ReloadEgreso() }}
                              Titulo="Nuevo Ingreso"
                            />
                            </>
                          }
                        })()}
                        <br />
                      </Link>
                      <label style={{ color: "#AA1B0E" }}>Ingresos</label>
                    </div>
                    <div className="col-md-1" hidden={hiddenBtnEditar_egr} style={{ textAlign: "center", paddingTop: "7px" }}>
                      <Link to="#" >
                        <i style={{
                          color: "#AA1B0E",
                          fontSize: "20px",
                          marginTop: "5px",
                          cursor: "pointer",
                        }}
                          onClick={() => abrirCerrarModal(4)}
                          className="icon-egresos"></i>
                      </Link>

                      {(() => {
                        if (modalInsertarEgreso == true) {
                          return <>
                            <ModalEgreso show={modalInsertarEgreso}
                              modo={0}
                              egreso={{}}
                              onCloseClick={() => setModalInsertarEgreso(false)}
                              ReloadLista={() => { ReloadModalEgreso(), ReloadEventos(), ReloadEgreso() }}
                              Titulo="Nuevo Egreso"
                            />
                          </>
                        }
                      })()}
                      <br />
                      <label style={{ color: "#AA1B0E" }}>Egresos</label>
                    </div>
                    <div className="col-md-1" hidden={hiddenBtnEditar_tar} style={{ textAlign: "center", paddingTop: "7px" }}>
                      <Link to="#" >
                        <i style={{
                          color: "#AA1B0E",
                          fontSize: "20px",
                          marginTop: "5px",
                          cursor: "pointer",
                        }} onClick={() => abrirCerrarModal(1)} className="icon-tareas"></i>
                        {(() => {
                          if (modalInsertarTarea == true) {
                            return <>
                              <ModalTarea show={modalInsertarTarea}
                                onCloseClick={() => abrirCerrarModal(1)}
                                ReloadLista={() => { ReloadModalListar(), ReloadTareas() }}
                                Titulo="Nueva Tarea"
                              />
                            </>
                          }
                        })()}
                      </Link>
                      <br />
                      <label style={{ color: "#AA1B0E" }}>Tareas</label>
                    </div>
                    <div className="col-md-1" hidden={hiddenBtnEditar_eve} style={{ textAlign: "center", paddingTop: "7px" }}>
                      <Link to="#" >
                        <i style={{
                          color: "#AA1B0E",
                          fontSize: "20px",
                          marginTop: "5px",
                          cursor: "pointer",
                        }} onClick={() => abrirCerrarModal(2)} className="icon-eventos"></i>
                        {(() => {
                          if (modalInsertarEvento == true) {
                            return <>
                              <ModalEvento
                                modo={0}
                                onCloseModal={() => ReloadModalEvento()}
                                show={modalInsertarEvento}
                                onCloseClick={() => abrirCerrarModal(2)}
                                ReloadLista={() => ReloadModalEvento()}
                                Titulo="Nueva Evento"
                              />
                            </>
                          }
                        })()}
                      </Link><br />
                      <label style={{ color: "#AA1B0E" }}>Eventos</label>
                    </div>
                    <div className="col-md-1" hidden={hiddenBtnEditar_hla} style={{ textAlign: "center", paddingTop: "7px" }}>
                      <Link to="#">
                        <i style={{
                          color: "#AA1B0E",
                          fontSize: "20px",
                          marginTop: "5px",
                          cursor: "pointer",
                        }} className="icon-horas-laboradas" onClick={() => abrirCerrarModal(5)}></i>
                        {(() => {
                          if (modalInsertarHLab == true) {
                            return <>
                              <ModalHorasLaboradas show={modalInsertarHLab}
                                onCloseClick={() => abrirCerrarModal(5)}
                                ReloadLista={() => { ReloadHL(), setModalInsertarHLab(!modalInsertarHLab) }}
                                Titulo="Nueva Hora Laborada"
                              />
                            </>
                          }
                        })()}
                      </Link><br />
                      <label style={{ color: "#AA1B0E" }}>H. Laboradas</label>
                    </div>
                    <div className="col-md-1" hidden={hiddenBtnEditar_doc} style={{ textAlign: "center", paddingTop: "7px" }}>
                      <Link to="/Documentos" >
                        <i style={{
                          color: "#AA1B0E",
                          fontSize: "20px",
                          marginTop: "5px",
                          cursor: "pointer",
                        }} className="icon-documentos"></i>

                      </Link><br />
                      <label style={{ color: "#AA1B0E" }}>Documentos</label>
                    </div>

                    <div className="col-md-1" hidden={hiddenBtnEditar_flu} style={{ textAlign: "center", paddingTop: "7px" }}>
                      <Link to="#" >
                        <i style={{
                          color: "#AA1B0E",
                          fontSize: "20px",
                          marginTop: "5px",
                          cursor: "pointer",
                        }} onClick={() => abrirCerrarModal(6)} //className="icon-ingresos"
                          className="icon-Flujo"> </i>
                        {(() => {
                          if (modalInsertarFlujo == true) {
                            return <><ModalFlujo show={modalInsertarFlujo}
                              onCloseClick={() => abrirCerrarModal(6)}
                              ReloadLista={() => ReloadModalFlujo()}
                              Titulo="Nuevo Flujo"
                            />
                            </>
                          }
                        })()}
                        <br />
                      </Link>
                      <label style={{ color: "#AA1B0E" }}>Flujo</label>
                    </div>

                    {/* 
                    <div className="col-md-1" style={{ textAlign: "center", paddingTop: "7px" }}>
                      <Link to="/FlujosNuevaTareacopy">
                        <i style={{
                          color: "#AA1B0E",
                          fontSize: "20px",
                          marginTop: "5px",
                          cursor: "pointer",
                        }} onClick={() => abrirCerrarModal(6)} //className="icon-ingresos"
                        > ❒</i>
                        {(() => {
                          if (modalInsertarFlujo == true) {
                            return <><ModalFlujo onCloseClick={() => abrirCerrarModal(6)} show={modalInsertarFlujo}
                            
                              Titulo="Nuevo Flujo"
                            />
                            </>
                          }
                        })()}
                        <br />
                      </Link>
                      <label style={{ color: "#AA1B0E" }}>S. Flujo</label>
                    </div> */}
                    <div className="col-md-1"></div>
                  </>
                )
              } else if (Condicion == null) {
                if (getModalNoticia == true) {
                  return <><ModalNoticia show={getModalNoticia}
                    onCloseClick={() => setModalNoticia(!getModalNoticia)}
                  />
                  </>
                }
              }
            })()}{" "}

            <div className="page-title-right">
              <Breadcrumb listClassName="m-0">
                <BreadcrumbItem style={{ color: 'red !important' }}>
                  <Link to="#" style={{ color: 'red !important' }}>{title}</Link>
                </BreadcrumbItem>

                <BreadcrumbItem active>
                  <Link to="#">{breadcrumbItem}</Link>
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </div>

          <pre style={{ padding: '0px' }}
            id="toastrOptions"
            className="toastr-options"
          ></pre>
        </Col>
      </Row> : false}

    </React.Fragment>
  )
}

export default Breadcrumbs