import React, { Component, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTags from "react-meta-tags"
import { MDBDataTable } from "mdbreact"
import moment from 'moment'
import Select from "react-select"
import Swal from "sweetalert2"
import {
  CardBody,
  Card,
  Col,
  Container,
  CardTitle,
  Button,
  Row,
  Modal,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap"
import instance from '../../config'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPlus,
  faSearch,
  faTimes,
  faChevronLeft,
  faChevronRight,
  faPaperPlane,
  faBuilding,
  faUser,
  faCalendar,
  faCircle,
  faClipboard,
  faBell,
} from "@fortawesome/free-solid-svg-icons"
import ModalTarea from "pages/Componentes/ModalTarea"
import { arrayPush } from "redux-form"
const baseUrl = process.env.REACT_APP_API_URL

const Correos = props => {
  function formatIni() {
    var d = new Date(),
      month = '' + 1,
      day = '' + 1,
      year = d.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  }
  function formatFin() {
    var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  }
  const [dataConsulta, setDataConsulta] = useState({
    inicio: formatIni(),
    fin: formatFin(),
    mon: 'MO01',
    caso: '',
    cliente: ''
  })
  const [modalVerCorreoEvento, setVerCorreoEvento] = useState(false)
  const [modalVerCorreoTarea, setVerCorreoTarea] = useState(false)
  const [modalVerCorreoIngresos, setVerCorreoIngresos] = useState(false)
  const [modalVerCorreoEgresos, setVerCorreoEgresos] = useState(false)
  const [modalVerCorreoCasos, setVerCorreoCasos] = useState(false)
  const [modalVerCorreoClientes, setVerCorreoClientes] = useState(false)
  const [GetParticipantes, setParticipantes] = useState([])
  const [dataCorreo, setDataCorreo] = useState([])
  const [dataUser, setDataUser] = useState([])
  const [dataCombo, setDataCombo] = useState([])
  const [data, setData] = useState([])
  const [htmlContent, setHtmlContent] = useState('')
  const [tituloModalVer, setTituloModalVer] = useState('')
  const [modalVerCorreo, setVerCorreo] = useState(false)    

  const [dataFilter, setDataFilter] = useState({
    user: null,
    type: null,
    fechaini: formatIni(),
    fechafin: formatFin()
  })

  const handleChange = e => {
    const { name, value } = e.target
    setDataFilter(prevState => ({
      ...prevState,
      [name]: e.target.type === "checkbox" ? e.target.checked : e.target.value
    }))

  }
  const FuncionListarFiltros = async () => {
    const postData = {
      user: dataFilter.user == '00' ? null : dataFilter.user,
      type: dataFilter.type == '00' ? null : dataFilter.type,
      fechaini: dataFilter.fechaini,
      fechafin: dataFilter.fechafin,
    }
    await instance
      .post("/Correo/List/Filter", postData)
      .then(response => {

        console.log(response.data)
        setData(response.data)

      })
      .catch(error => {

      })
  }
  const FuncionListarUsuario = async () => {
    await instance
      .get("/Usuario/Listar")
      .then(response => {
        setDataUser(response.data)

      })
      .catch(error => {

      })
  }
  const FuncionListarCorreos = async () => {
    await instance
      .post("/Correo/List")
      .then(response => {

        setData(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionEnviarCorreo = async (data) => {

    var body = {
      email: data.cUsu_email,

    }
    /*Swal.fire({
      title: "Mensaje",
      text: "Notificación enviada a " + data.cUsu_email,
      icon: "success",
      confirmButtonText: "Aceptar",
    })*/
    await instance
      .post("/mail", body)
      .then(response => {
        Swal.fire({
          title: "Mensaje",
          text: "Notificación enviada a " + data.cUsu_email,
          icon: "success",
          confirmButtonText: "Aceptar",
        })
      })
      .catch(error => {

      })
  }
  const FuncionListarComboTipo = async () => {
    var postData = {
      Prefijo: "CR",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataCombo(response.data)

      })
      .catch(error => {

      })
  }
  /** Eliminar correo */
  const FuncionEliminarCorreo = async (_nCor_Id) => {
    var postData = {
      nCor_Id: _nCor_Id
    }
    await instance
      .post("/Correo/CorreoEliminar", postData)
      .then(response => {
        Swal.fire({
          title: "Mensaje",
          text: "Eliminado exitosamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        })
        FuncionListarFiltros()
      })
      .catch(error => {

      })
  }


  useEffect(() => {
    //FuncionListarCorreos()
    //FuncionListarCorreos()
    FuncionListarFiltros()
    FuncionListarUsuario()
    FuncionListarComboTipo()  //Registrado
  }, [])

  const datas = {
    columns: [
      {
        label: "Tipo",
        field: "cTabNombre",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Nombre",
        field: "cUsu_Nombres",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Correo",
        field: "cUsu_email",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Enviado",
        field: "dCor_Enviado",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      }
    ],
    rows: data,
  }

  const badgesData = {
    columns: [
      {
        label: "Tipo",
        field: "TipoCorreo",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Usuario",
        field: "cUsu_Nombres",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Correo",
        field: "cUsu_email",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Enviado",
        field: "Enviado",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Asunto",
        field: "cCor_Asunto",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Acciones",
        field: "acciones",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
    ],
    rows: [
      ...datas.rows.map((row, id) => ({
        ...row,
        /*NombreCompleto: (
          <Link style={{color:"black"}} onClick={() =>
            setEncryptedItem('CLIID', row.nCli_Id)  
          } to="/dashboard-Cliente">
            {row.cCli_NombreCompleto}
            </Link>
        ),*/
        acciones: (
          <div>
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="dropdown-toggle">
                <i className="bx bx-dots-vertical-rounded" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    FuncionAbrirModal(row.nCas_Id, row.cCor_Tab_Tipo, row.cCor_htmlBody)
                  }
                  }>
                  Ver correo
                </DropdownItem>
                <DropdownItem onClick={() => FuncionReenviarCorreo(row.nCas_Id, row.cCor_Tab_Tipo, row.cCor_htmlBody)}>
                  Reenviar
                </DropdownItem>
                <DropdownItem onClick={() => Swal.fire({
                  title: "Mensaje",
                  html:
                    "Estas seguro de eliminar este registro",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Aceptar",
                  cancelButtonText: "Cancelar",
                }).then(result => {
                  if (result.isConfirmed) {

                    FuncionEliminarCorreo(row.nCor_Id)
                  }
                })}>
                  Eliminar
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        ),
      })),
    ],
  }
  const FuncionReenviarCorreo = async (codigo, cCor_Tab_Tipo, cCor_htmlBody) => {

    if (cCor_Tab_Tipo == "CR01") { //Casos
      let postData2 = {
        _nCas_Id: codigo
      }
      var _arrayParticipantes = [];
      await instance
        .post("/Caso/Participantes_Caso_All", postData2)
        .then(async response => {

          let array = response.data;


          const array2 = array.map(({ cUsu_Nombres, cUsu_email }) => ({
            label: cUsu_Nombres,
            value: 0,
            email: cUsu_email,
          }));


          /**reenvio de correo */
          var postData = {
            arrayParticipantes: array2,
            cCor_htmlBody: cCor_htmlBody
          }


          await instance
            .post("/mail/event/sendMailReenvioCorreo", postData)
            .then(async response => {

              Swal.fire({
                title: "Mensaje",
                text: "Se reenvío el correo",
                icon: "success",
                confirmButtonText: "Aceptar",
              })
            })
            .catch(error => {

            });
        })
        .catch(error => {

        });

    }
    else if (cCor_Tab_Tipo == "CR02") { //Clientes
    }
    else if (cCor_Tab_Tipo == "CR03") { //Tareas (Listo)
      let postData = {
        nTar_Id: codigo
      }
      var _arrayParticipantes = [];
      await instance
        .post("/Tarea/Buscar/Participante", postData)
        .then(async response => {

          /**reenvio de correo */
          var postData2 = {
            arrayParticipantes: response.data,
            cCor_htmlBody: cCor_htmlBody
          }

          await instance
            .post("/mail/event/sendMailReenvioCorreo", postData2)
            .then(async response => {
              Swal.fire({
                title: "Mensaje",
                text: "Se reenvío el correo",
                icon: "success",
                confirmButtonText: "Aceptar",
              })
            })
            .catch(error => {

            });

        })
        .catch(error => {

        });
    }
    else if (cCor_Tab_Tipo == "CR04") { //ingresos (Listo)
      var postData = {
        _nIng_Id: codigo
      }

      await instance
        .post("/Ingreso/ObtenerIngreso", postData)
        .then(async response => {

          var postData2 = {
            nCas_Id: response.data[0].nCas_Id
          }
          
          await instance
            .post("/Casos/AbogadoResposable", postData2)
            .then(async response => {


              let array = [];
              let arrayResponse = response.data;
              for (let i = 0; i < arrayResponse.length; i++) {
                if (arrayResponse[i].cCas_Tab_TipoEquipo == "Abogado Responsable") {
                  array.push(arrayResponse[i]);
                }
              }
              /**reenvio de correo */
              var postData3 = {
                arrayParticipantes: array,
                cCor_htmlBody: cCor_htmlBody
              }

              await instance
                .post("/mail/event/sendMailReenvioCorreo", postData3)
                .then(async response => {
                  Swal.fire({
                    title: "Mensaje",
                    text: "Se reenvío el correo",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                  })
                })
                .catch(error => {

                });


            })

        })
        .catch(error => {

        })

    }
    else if (cCor_Tab_Tipo == "CR05") { //Eventos (Listo)
      var postData2 = {
        nEve_Id: codigo
      }

      await instance
        .post("/Evento/Buscar/Participantes", postData2)
        .then(async response => {
          /**reenvio de correo */
          var postData = {
            arrayParticipantes: response.data,
            cCor_htmlBody: cCor_htmlBody
          }

          await instance
            .post("/mail/event/sendMailReenvioCorreo", postData)
            .then(async response => {
              Swal.fire({
                title: "Mensaje",
                text: "Se reenvío el correo",
                icon: "success",
                confirmButtonText: "Aceptar",
              })
            })
            .catch(error => {

            });

        })
        .catch(error => {

        });
    }
    else if (cCor_Tab_Tipo == "CR06") { //Egresos (Listo)

      var postData = {
        _nEge_Id: codigo
      }

      await instance
        .post("/Egreso/EgresoObtenerEgreso", postData)
        .then(async response => {

          var postData2 = {
            nCas_Id: response.data[0].nCas_Id
          }
          await instance
            .post("/Casos/AbogadoResposable", postData2)
            .then(async response => {


              let array = [];
              let arrayResponse = response.data;
              for (let i = 0; i < arrayResponse.length; i++) {
                if (arrayResponse[i].cCas_Tab_TipoEquipo == "Abogado Responsable") {
                  array.push(arrayResponse[i]);
                }
              }
              /**reenvio de correo */
              var postData3 = {
                arrayParticipantes: array,
                cCor_htmlBody: cCor_htmlBody
              }

              await instance
                .post("/mail/event/sendMailReenvioCorreo", postData3)
                .then(async response => {
                  Swal.fire({
                    title: "Mensaje",
                    text: "Se reenvío el correo",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                  })
                })
                .catch(error => {

                });


            })
            .catch(error => {

            });
        })
        .catch(error => {

        });
    }
  }

  //crear una funcion promesa
  async function getCorreoTarea( _nTar_Id) {
    return new Promise((resolve, reject) => {
      var postData = {
        _nTar_Id: _nTar_Id
      }
      instance
        .post("/Tarea/ObtenerTarea", postData)
        .then(response => {
          resolve(response.data[0]);
        })
        .catch(error => {
          reject(error);
        })
    });
  }

  async function getCorreoEvento( _nTar_Id) {
    return new Promise((resolve, reject) => {
      var postData = {
        _nEve_Id: _nTar_Id
      }
      instance
        .post("/Evento/ObtenerEvento", postData)
        .then(response => {
          resolve(response.data[0]);
        })
        .catch(error => {
          reject(error);
        })
    });
  }

  const FuncionAbrirModal = async (codigo, cCor_Tab_Tipo, cCor_htmlBody) => {

    console.log(codigo, cCor_Tab_Tipo)

    if (cCor_Tab_Tipo == "CR01") { //Casos
      setHtmlContent(cCor_htmlBody);
      setTituloModalVer("Correo enviado de caso");
      setVerCorreo(true)
    }
    else if (cCor_Tab_Tipo == "CR02") { //Clientes
    }
    else if (cCor_Tab_Tipo == "CR03") { //Tareas
    
      setHtmlContent(cCor_htmlBody);
      setTituloModalVer("Correo enviado de tarea");
      setVerCorreo(true)

    }
    else if (cCor_Tab_Tipo == "CR04") { //ingresos
      
      setHtmlContent(cCor_htmlBody);
      setTituloModalVer("Correo enviado de ingreso");
      setVerCorreo(true)

    }
    else if (cCor_Tab_Tipo == "CR05" && cCor_htmlBody) { //ingresos

      setHtmlContent(cCor_htmlBody);
      setTituloModalVer("Correo enviado de rechazo/aprobación de liquidación");
      setVerCorreo(true)

    }
    else if (cCor_Tab_Tipo == "CR06") { //Egresos

      setHtmlContent(cCor_htmlBody);
      setTituloModalVer("Correo enviado de egresos");
      setVerCorreo(true)
    }
  }


  return (
    <React.Fragment>
      <div className="page-content">
        { }
        <MetaTags>
          <title>Correo | GpsLegal</title>
        </MetaTags>
        <Container fluid>
          { }
          <Breadcrumbs title="GpsLegal" breadcrumbItem="Correos enviados" />
          <div className="grid-filter-email">
            <div>
              <label className="filter-title">Filtros</label>
            </div>
            <div style={{ width: '98%' }}>
              <label className="texto-combex" >Usuario</label>
              <select style={{}}
                className="form-select inputDown"
                name="user"
                onChange={handleChange}
              >
                <option className="texto-combex" value="00">Seleccionar</option>
                {dataUser.map((e) =>
                  <option className="texto-combex" value={e.nUsu_ID}>
                    {e.cUsu_Nombres}
                  </option>
                )
                }
              </select>
            </div>


            <div style={{ width: '98%' }}>
              <label className="texto-combex" >Tipo</label>
              <select style={{}}
                className="form-select inputDown"
                name="type"
                onChange={handleChange}
              >
                <option className="texto-combex" value="00">Seleccionar</option>
                {dataCombo.map((row, i) => (
                  <option key={i} value={row.cTabCodigo}>
                    {row.cTabNombre}
                  </option>
                ))}
              </select>
            </div>
            {/*fechas*/}



          </div>
          <div className="grid-filter-email" style={{ marginTop: "1em" }}>

            <div>
            </div>
            <div className="grid-fechas">
              <div style={{ width: '93%' }}>
                <label className="texto-combex">Fecha Inicial</label>
                <input style={{}}
                  className="form-control"
                  type="date" defaultValue={dataConsulta.inicio}
                  name="fechaini"
                  onChange={handleChange}
                />
              </div>
              <div style={{ width: '95%' }}>
                <label className="">Fecha Final</label>
                <input style={{}}
                  className="form-control"
                  type="date"
                  defaultValue={dataConsulta.fin}
                  name="fechafin"
                  onChange={handleChange}
                />
              </div>

            </div>
            <div className="button-filter">
              <button className="btn btn-danger" onClick={() => FuncionListarFiltros()}>
                <i className="fa fa-search">
                </i>&nbsp;&nbsp;Buscar</button>
            </div>
          </div>
          <Modal isOpen={modalVerCorreoEvento}>
            <div className="modal-header ModalHeaderColor" >
              <h5
                style={{ color: "#fff" }}
                className="modal-title mt-0"
                id="myModalLabel"
              >
                Correo enviado del evento
              </h5>
              <button
                type="button"
                onClick={() => {
                  setVerCorreoEvento(!modalVerCorreoEvento)
                }
                }
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div style={{ width: "100%", backgroundColor: "white", textAlign: "center", marginTop: "50px" }}>
                <img src='https://mcusercontent.com/ed85e4228211118429465984c/images/65f96943-ae91-35ae-af14-fa0c45aeb244.png' style={{ width: "300px", textAlign: "center" }}></img>
              </div>
              <div className="mb-3 row" style={{ marginTop: '15px' }}>

                <div className="col-md-12">
                  <label className="col-form-label labelDown">Tenemos para usted un nuevo evento. </label>
                </div>
                <hr></hr>
                <div className="col-md-12">
                  <label className="col-form-label labelDown">Se le ha incluido en el siguiente evento. </label>
                </div>
                <div className="col-md-12" style={{ backgroundColor: "#f6f7f8" }}>
                  <div className="mb-3 row" style={{ marginTop: '15px' }}>
                    <div className="col-md-12 row">
                      <div className="col-md-2" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown">Titulo</label>
                      </div>
                      <div className="col-md-1" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown"> : </label>
                      </div>
                      <div className="col-md-8 Texto-Limit" style={{ paddingLeft: '0px', textAlign: "left" }}>
                        <label className="col-form-label labelDown"
                          style={{ paddingLeft: '0px', textAlign: "left", marginLeft: "0px" }}>{dataCorreo.cEve_Titulo}</label>
                      </div>
                    </div>
                    <div className="col-md-12 row">
                      <div className="col-md-2" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown">Caso</label>
                      </div>
                      <div className="col-md-1" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown"> : </label>
                      </div>
                      <div className="col-md-8" style={{ paddingLeft: '0px', textAlign: "left" }}>
                        <label className="col-form-label labelDown"
                          style={{ paddingLeft: '0px', textAlign: "left", marginLeft: "0px" }}>{dataCorreo.cCas_Titulo}</label>
                      </div>
                    </div>
                    <div className="col-md-12 row">
                      <div className="col-md-2" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown">Cliente</label>
                      </div>
                      <div className="col-md-1" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown"> : </label>
                      </div>
                      <div className="col-md-8" style={{ paddingLeft: '0px', textAlign: "left" }}>
                        <label className="col-form-label labelDown"
                          style={{ paddingLeft: '0px', textAlign: "left", marginLeft: "0px" }}>{dataCorreo.cCli_NombreCompleto}</label>
                      </div>
                    </div>
                    <div className="col-md-12 row">
                      <div className="col-md-2" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown">Categoría</label>
                      </div>
                      <div className="col-md-1" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown"> : </label>
                      </div>
                      <div className="col-md-8" style={{ paddingLeft: '0px', textAlign: "left" }}>
                        <label className="col-form-label labelDown"
                          style={{ paddingLeft: '0px', textAlign: "left", marginLeft: "0px" }}>
                          {dataCorreo.cEve_Tab_Tipo2}</label>
                      </div>
                    </div>
                    <div className="col-md-12 row">
                      <div className="col-md-2" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown">Inicio</label>
                      </div>
                      <div className="col-md-1" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown"> : </label>
                      </div>
                      <div className="col-md-8" style={{ paddingLeft: '0px', textAlign: "left" }}>
                        <label className="col-form-label labelDown"
                          style={{ paddingLeft: '0px', textAlign: "left", marginLeft: "0px" }}>
                          {dataCorreo.dEve_FechaIni2}{" "}{dataCorreo.tEve_HoraIni2}</label>
                      </div>
                    </div>
                    <div className="col-md-12 row">
                      <div className="col-md-2" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown">Vencimiento </label>
                      </div>
                      <div className="col-md-1" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown"> : </label>
                      </div>
                      <div className="col-md-9" style={{ paddingLeft: '0px', textAlign: "left" }}>
                        <label className="col-form-label labelDown"
                          style={{ paddingLeft: '0px', textAlign: "left", marginLeft: "0px" }}>
                          {dataCorreo.dEve_FechaFin2}{" "}{dataCorreo.tEve_HoraFin2}</label>
                      </div>
                    </div>
                    <div className="col-md-12 row">
                      <div className="col-md-2" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown">Ubicación</label>
                      </div>
                      <div className="col-md-1" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown"> : </label>
                      </div>
                      <div className="col-md-9" style={{ paddingLeft: '0px', textAlign: "left" }}>
                        <label className="col-form-label labelDown"
                          style={{ paddingLeft: '0px', textAlign: "left", marginLeft: "0px" }}>

                          {dataCorreo.cEve_Ubicacion}</label>
                      </div>
                    </div>
                    <div className="col-md-12 row">
                      <div className="col-md-2" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown">Descripción</label>
                      </div>
                      <div className="col-md-1" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown"> : </label>
                      </div>
                      <div className="col-md-8" style={{ paddingLeft: '0px', textAlign: "left" }}>
                        <label className="col-form-label labelDown"
                          style={{ paddingLeft: '0px', textAlign: "left", marginLeft: "0px" }}>

                          {dataCorreo.cEve_Descripcion}</label>
                      </div>
                    </div>

                  </div>
                </div>

              </div>


            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setVerCorreoEvento(!modalVerCorreoEvento)
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Cerrar
              </button>
            </div>
          </Modal>
          <Modal isOpen={modalVerCorreoTarea}>
            <div className="modal-header ModalHeaderColor" >
              <h5
                style={{ color: "#fff" }}
                className="modal-title mt-0"
                id="myModalLabel"
              >
                Correo enviado de la tarea
              </h5>
              <button
                type="button"
                onClick={() => {
                  setVerCorreoTarea(!modalVerCorreoTarea)
                }
                }
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div style={{ width: "100%", backgroundColor: "white", textAlign: "center", marginTop: "50px" }}>
                <img src='https://mcusercontent.com/ed85e4228211118429465984c/images/65f96943-ae91-35ae-af14-fa0c45aeb244.png' style={{ width: "300px", textAlign: "center" }}></img>
              </div>
              <div className="mb-3 row" style={{ marginTop: '15px' }}>

                <div className="col-md-12">
                  <label className="col-form-label labelDown">Tenemos para usted una nueva tarea.</label>
                </div>
                <hr></hr>
                <div className="col-md-12">
                  <label className="col-form-label labelDown">Se le ha incluido en la siguiente tarea. </label>
                </div>
                <div className="col-md-12" style={{ backgroundColor: "#f6f7f8" }}>
                  <div className="mb-3 row" style={{ marginTop: '15px' }}>
                    <div className="col-md-12 row">
                      <div className="col-md-2" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown">Titulo</label>
                      </div>
                      <div className="col-md-1" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown"> : </label>
                      </div>
                      <div className="col-md-8 Texto-Limit" style={{ paddingLeft: '0px', textAlign: "left" }}>
                        <label className="col-form-label labelDown"
                          style={{ paddingLeft: '0px', textAlign: "left", marginLeft: "0px" }}>{dataCorreo.cTar_Titulo}</label>
                      </div>
                    </div>
                    <div className="col-md-12 row">
                      <div className="col-md-2" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown">Caso</label>
                      </div>
                      <div className="col-md-1" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown"> : </label>
                      </div>
                      <div className="col-md-8" style={{ paddingLeft: '0px', textAlign: "left" }}>
                        <label className="col-form-label labelDown"
                          style={{ paddingLeft: '0px', textAlign: "left", marginLeft: "0px" }}>{dataCorreo.cCas_Titulo}</label>
                      </div>
                    </div>
                    <div className="col-md-12 row">
                      <div className="col-md-2" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown">Cliente</label>
                      </div>
                      <div className="col-md-1" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown"> : </label>
                      </div>
                      <div className="col-md-8" style={{ paddingLeft: '0px', textAlign: "left" }}>
                        <label className="col-form-label labelDown"
                          style={{ paddingLeft: '0px', textAlign: "left", marginLeft: "0px" }}>{dataCorreo.cCli_NombreCompleto}</label>
                      </div>
                    </div>
                    <div className="col-md-12 row">
                      <div className="col-md-2" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown">Vencimiento </label>
                      </div>
                      <div className="col-md-1" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown"> : </label>
                      </div>
                      <div className="col-md-9" style={{ paddingLeft: '0px', textAlign: "left" }}>
                        <label className="col-form-label labelDown"
                          style={{ paddingLeft: '0px', textAlign: "left", marginLeft: "0px" }}>
                          {dataCorreo.dTar_Fec_Vence2}{" "}{dataCorreo.tTar_Hora_Vence2}</label>
                      </div>
                    </div>
                  </div>
                </div>

              </div>


            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setVerCorreoTarea(!modalVerCorreoTarea)
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Cerrar
              </button>
            </div>
          </Modal>
          <Modal isOpen={modalVerCorreoIngresos}>
            <div className="modal-header ModalHeaderColor" >
              <h5
                style={{ color: "#fff" }}
                className="modal-title mt-0"
                id="myModalLabel"
              >
                Correo enviado del ingreso
              </h5>
              <button
                type="button"
                onClick={() => {
                  setVerCorreoIngresos(!modalVerCorreoIngresos)
                }
                }
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div style={{ width: "100%", backgroundColor: "white", textAlign: "center", marginTop: "50px" }}>
                <img src='https://mcusercontent.com/ed85e4228211118429465984c/images/65f96943-ae91-35ae-af14-fa0c45aeb244.png' style={{ width: "300px", textAlign: "center" }}></img>
              </div>
              <div className="mb-3 row" style={{ marginTop: '15px' }}>

                <div className="col-md-12">
                  <label className="col-form-label labelDown">Se ha registrado un nuevo ingreso.</label>
                </div>
                <hr></hr>
                <div className="col-md-12" style={{ backgroundColor: "#f6f7f8" }}>
                  <div className="mb-3 row" style={{ marginTop: '15px' }}>
                    <div className="col-md-12 row">
                      <div className="col-md-2" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown">Titulo</label>
                      </div>
                      <div className="col-md-1" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown"> : </label>
                      </div>
                      <div className="col-md-8 Texto-Limit" style={{ paddingLeft: '0px', textAlign: "left" }}>
                        <label className="col-form-label labelDown"
                          style={{ paddingLeft: '0px', textAlign: "left", marginLeft: "0px" }}>{dataCorreo.cCas_Titulo}</label>
                      </div>
                    </div>
                    <div className="col-md-12 row">
                      <div className="col-md-2" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown">Cliente</label>
                      </div>
                      <div className="col-md-1" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown"> : </label>
                      </div>
                      <div className="col-md-8" style={{ paddingLeft: '0px', textAlign: "left" }}>
                        <label className="col-form-label labelDown"
                          style={{ paddingLeft: '0px', textAlign: "left", marginLeft: "0px" }}>{dataCorreo.Cliente}</label>
                      </div>
                    </div>
                    <div className="col-md-12 row">
                      <div className="col-md-2" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown">Caso</label>
                      </div>
                      <div className="col-md-1" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown"> : </label>
                      </div>
                      <div className="col-md-8" style={{ paddingLeft: '0px', textAlign: "left" }}>
                        <label className="col-form-label labelDown"
                          style={{ paddingLeft: '0px', textAlign: "left", marginLeft: "0px" }}>{dataCorreo.cCas_Titulo}</label>
                      </div>
                    </div>
                    <div className="col-md-12 row">
                      <div className="col-md-2" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown">Motivo </label>
                      </div>
                      <div className="col-md-1" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown"> : </label>
                      </div>
                      <div className="col-md-9" style={{ paddingLeft: '0px', textAlign: "left" }}>
                        <label className="col-form-label labelDown"
                          style={{ paddingLeft: '0px', textAlign: "left", marginLeft: "0px" }}>
                          {dataCorreo.dTar_Fec_Vence2}{" "}{dataCorreo.cIng_Tab_Tipo2}</label>
                      </div>
                    </div>
                    <div className="col-md-12 row">
                      <div className="col-md-2" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown">Fecha </label>
                      </div>
                      <div className="col-md-1" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown"> : </label>
                      </div>
                      <div className="col-md-9" style={{ paddingLeft: '0px', textAlign: "left" }}>
                        <label className="col-form-label labelDown"
                          style={{ paddingLeft: '0px', textAlign: "left", marginLeft: "0px" }}>
                          {dataCorreo.dTar_Fec_Vence2}{" "}{dataCorreo.dIng_Fecha2}</label>
                      </div>
                    </div>
                    <div className="col-md-12 row">
                      <div className="col-md-2" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown">Monto </label>
                      </div>
                      <div className="col-md-1" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown"> : </label>
                      </div>
                      <div className="col-md-9" style={{ paddingLeft: '0px', textAlign: "left" }}>
                        <label className="col-form-label labelDown"
                          style={{ paddingLeft: '0px', textAlign: "left", marginLeft: "0px" }}>
                          {dataCorreo.dTar_Fec_Vence2}{" "}{dataCorreo.nIng_Monto2}</label>
                      </div>
                    </div>
                    <div className="col-md-12 row">
                      <div className="col-md-2" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown">Descripción </label>
                      </div>
                      <div className="col-md-1" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown"> : </label>
                      </div>
                      <div className="col-md-9" style={{ paddingLeft: '0px', textAlign: "left" }}>
                        <label className="col-form-label labelDown"
                          style={{ paddingLeft: '0px', textAlign: "left", marginLeft: "0px" }}>
                          {dataCorreo.dTar_Fec_Vence2}{" "}{dataCorreo.cIng_Detalle}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setVerCorreoIngresos(!modalVerCorreoIngresos)
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Cerrar
              </button>
            </div>
          </Modal>
          <Modal isOpen={modalVerCorreoEgresos}>
            <div className="modal-header ModalHeaderColor" >
              <h5
                style={{ color: "#fff" }}
                className="modal-title mt-0"
                id="myModalLabel"
              >
                Correo enviado del egreso
              </h5>
              <button
                type="button"
                onClick={() => {
                  setVerCorreoEgresos(!modalVerCorreoEgresos)
                }
                }
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">


              <div style={{ width: "100%", backgroundColor: "white", textAlign: "center", marginTop: "50px" }}>
                <img src='https://mcusercontent.com/ed85e4228211118429465984c/images/65f96943-ae91-35ae-af14-fa0c45aeb244.png' style={{ width: "300px", textAlign: "center" }}></img>
              </div>
              <div className="mb-3 row" style={{ marginTop: '15px' }}>

                <div className="col-md-12">
                  <label className="col-form-label labelDown">Usted tiene un nuevo egreso registrado.</label>
                </div>
                <hr></hr>
                <div className="col-md-12" style={{ backgroundColor: "#f6f7f8" }}>
                  <div className="mb-3 row" style={{ marginTop: '15px' }}>
                    <div className="col-md-12 row">
                      <div className="col-md-2" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown">Titulo</label>
                      </div>
                      <div className="col-md-1" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown"> : </label>
                      </div>
                      <div className="col-md-8 Texto-Limit" style={{ paddingLeft: '0px', textAlign: "left" }}>
                        <label className="col-form-label labelDown"
                          style={{ paddingLeft: '0px', textAlign: "left", marginLeft: "0px" }}>{dataCorreo.cCas_Titulo}</label>
                      </div>
                    </div>
                    <div className="col-md-12 row">
                      <div className="col-md-2" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown">Cliente</label>
                      </div>
                      <div className="col-md-1" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown"> : </label>
                      </div>
                      <div className="col-md-8" style={{ paddingLeft: '0px', textAlign: "left" }}>
                        <label className="col-form-label labelDown"
                          style={{ paddingLeft: '0px', textAlign: "left", marginLeft: "0px" }}>{dataCorreo.Cliente}</label>
                      </div>
                    </div>
                    <div className="col-md-12 row">
                      <div className="col-md-2" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown">Motivo </label>
                      </div>
                      <div className="col-md-1" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown"> : </label>
                      </div>
                      <div className="col-md-9" style={{ paddingLeft: '0px', textAlign: "left" }}>
                        <label className="col-form-label labelDown"
                          style={{ paddingLeft: '0px', textAlign: "left", marginLeft: "0px" }}>
                          {dataCorreo.dTar_Fec_Vence2}{" "}{dataCorreo.cEge_Tab_Tipo2}</label>
                      </div>
                    </div>
                    <div className="col-md-12 row">
                      <div className="col-md-2" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown">Caso</label>
                      </div>
                      <div className="col-md-1" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown"> : </label>
                      </div>
                      <div className="col-md-8" style={{ paddingLeft: '0px', textAlign: "left" }}>
                        <label className="col-form-label labelDown"
                          style={{ paddingLeft: '0px', textAlign: "left", marginLeft: "0px" }}>{dataCorreo.cCas_Cod_Externo}</label>
                      </div>
                    </div>
                    <div className="col-md-12 row">
                      <div className="col-md-2" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown">Fecha </label>
                      </div>
                      <div className="col-md-1" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown"> : </label>
                      </div>
                      <div className="col-md-9" style={{ paddingLeft: '0px', textAlign: "left" }}>
                        <label className="col-form-label labelDown"
                          style={{ paddingLeft: '0px', textAlign: "left", marginLeft: "0px" }}>
                          {dataCorreo.dTar_Fec_Vence2}{" "}{dataCorreo.dEge_Fecha2}</label>
                      </div>
                    </div>
                    <div className="col-md-12 row">
                      <div className="col-md-2" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown">Monto </label>
                      </div>
                      <div className="col-md-1" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown"> : </label>
                      </div>
                      <div className="col-md-9" style={{ paddingLeft: '0px', textAlign: "left" }}>
                        <label className="col-form-label labelDown"
                          style={{ paddingLeft: '0px', textAlign: "left", marginLeft: "0px" }}>
                          {dataCorreo.dTar_Fec_Vence2}{" "}{dataCorreo.nEge_Monto2}</label>
                      </div>
                    </div>
                    <div className="col-md-12 row">
                      <div className="col-md-2" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown">Descripción </label>
                      </div>
                      <div className="col-md-1" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown"> : </label>
                      </div>
                      <div className="col-md-9" style={{ paddingLeft: '0px', textAlign: "left" }}>
                        <label className="col-form-label labelDown"
                          style={{ paddingLeft: '0px', textAlign: "left", marginLeft: "0px" }}>
                          {dataCorreo.dTar_Fec_Vence2}{" "}{dataCorreo.cEge_Detalle}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setVerCorreoEgresos(!modalVerCorreoEgresos)
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Cerrar
              </button>
            </div>
          </Modal>
          <Modal isOpen={modalVerCorreoCasos}>
            <div className="modal-header ModalHeaderColor" >
              <h5
                style={{ color: "#fff" }}
                className="modal-title mt-0"
                id="myModalLabel"
              >
                Correo enviado del caso
              </h5>
              <button
                type="button"
                onClick={() => {
                  setVerCorreoCasos(!modalVerCorreoCasos)
                }
                }
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div style={{ width: "100%", backgroundColor: "white", textAlign: "center", marginTop: "50px" }}>
                <img src='https://mcusercontent.com/ed85e4228211118429465984c/images/65f96943-ae91-35ae-af14-fa0c45aeb244.png' style={{ width: "300px", textAlign: "center" }}></img>
              </div>
              <div className="mb-3 row" style={{ marginTop: '15px' }}>

                <div className="col-md-12">
                  <label className="col-form-label labelDown">Usted tiene una invitación a participar en un nuevo caso</label>
                </div>
                <hr></hr>
                <div className="col-md-12" style={{ backgroundColor: "#f6f7f8" }}>
                  <div className="mb-3 row" style={{ marginTop: '15px' }}>
                    <div className="col-md-12 row">
                      <div className="col-md-2" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown">Titulo</label>
                      </div>
                      <div className="col-md-1" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown"> : </label>
                      </div>
                      <div className="col-md-8 Texto-Limit" style={{ paddingLeft: '0px', textAlign: "left" }}>
                        <label className="col-form-label labelDown"
                          style={{ paddingLeft: '0px', textAlign: "left", marginLeft: "0px" }}>{dataCorreo.cCas_Titulo}</label>
                      </div>
                    </div>
                    <div className="col-md-12 row">
                      <div className="col-md-2" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown">Cliente</label>
                      </div>
                      <div className="col-md-1" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown"> : </label>
                      </div>
                      <div className="col-md-8" style={{ paddingLeft: '0px', textAlign: "left" }}>
                        <label className="col-form-label labelDown"
                          style={{ paddingLeft: '0px', textAlign: "left", marginLeft: "0px" }}>{dataCorreo.cCli_NombreCompleto}</label>
                      </div>
                    </div>
                    <div className="col-md-12 row">
                      <div className="col-md-2" style={{ paddingRight: '0px' }}>
                        <label className="col-form-label labelDown">Cod externo </label>
                      </div>
                      <div className="col-md-1" style={{ paddingRight: '0px', marginLeft: '0px' }}>
                        <label className="col-form-label labelDown"> : </label>
                      </div>
                      <div className="col-md-9" style={{ paddingLeft: '0px', textAlign: "left" }}>
                        <label className="col-form-label labelDown"
                          style={{ paddingLeft: '0px', textAlign: "left", marginLeft: "0px" }}>
                          {dataCorreo.dTar_Fec_Vence2}{" "}{dataCorreo.cCas_Cod_Externo}</label>
                      </div>
                    </div>
                  </div>
                </div>

              </div>


            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setVerCorreoCasos(!modalVerCorreoCasos)
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Cerrar
              </button>
            </div>
          </Modal>

          <Modal size="lg" isOpen={modalVerCorreo} >
            <div className="modal-header ModalHeaderColor" >
              <h5
                style={{ color: "#fff" }}
                className="modal-title mt-0"
                id="myModalLabel"
              >
                {tituloModalVer}
              </h5>
              <button
                type="button"
                onClick={() => {
                  setVerCorreo(!modalVerCorreo)
                }
                }
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{ maxHeight: '670px', overflow: 'auto' }}>

              <div
                style={{height: '500px !important'}}
                className="col-md-12"
                dangerouslySetInnerHTML={{ __html: htmlContent }}
              ></div>
   
            </div>

            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setVerCorreo(!modalVerCorreo)
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Cerrar
              </button>
            </div>
          </Modal>

          <MDBDataTable
            data={badgesData}
            className="TablaCorreos"
            responsiveXl
            fixed
            hover
            entriesLabel="Mostrar"
            entrylabel={"Mostrar entradas"}
            infoLabel={["Mostrando ", "a", "de", "registros"]}
            bordered
            order={["age", "desc"]}
            noRecordsFoundLabel={"No hay datos"}
            searchLabel={"Buscar"}
            noBottomColumns
            paginationLabel={["Anterior", "Siguiente"]}
            theadColor="HeadBlue"
          />
        </Container>
      </div>
    </React.Fragment >
  )
}
export default Correos