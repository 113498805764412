import React, { Component, useState } from "react"
import { Alert, Row, Col, CardBody, Card, Container } from "reactstrap"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
// import images
import profile from "../../assets/images/imagen Recuperarcontra.png"
import logo from "../../assets/images/Logo.png"
import instance from '../../config'
import Swal from "sweetalert2"
const jsonwebtoken = require('jsonwebtoken');
const SECRET_KEY_JWT = 'secretkey5353';

const CambiarContraseña = props => {

  var FuncionEnvCorreo = async () => {
    let usuario = document.getElementById("email").value;
    const postData = {
      _usuario: usuario
    }
    const baseUrl = process.env.REACT_APP_API_URL

    await instance
      .post("/Usuario/recorypass", postData)
      .then(response => {

        console.log(response);

        let res = response["data"]["message"];
        if (res == "sended successfully") {
          Swal.fire({
            title: "Mensaje",
            text: "Se envió un mensaje a su correo",
            icon: "success",
            confirmButtonText: "Aceptar",
          })
        }
        else {
          Swal.fire({
            title: "Mensaje",
            text: "El correo no se encuentra registrado",
            icon: "error",
            confirmButtonText: "Aceptar",
          })
        }
      })
      .catch(error => {

      })
  }
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <MetaTags>
          <title>Recuperar contraseña | GpsLegal</title>
        </MetaTags>
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={3} lg={3} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <div style={{ paddingTop: "10px" }} className="mb-3 row">
                    <Link to="/" className="auth-logo-dark">
                      <img
                        src={profile}
                        alt=""
                        style={{
                          width: "100%",
                          height: "auto",
                          marginLeft: "auto",
                          marginRight: "auto",
                          display: "block",
                        }}
                      />
                    </Link>
                  </div>
                  <div className="p-2">
                    <AvForm className="mb-3">
                      <div className="form-group">
                        <AvField
                          name="email"
                          id="email"
                          label="Correo del usuario"
                          className="form-control"
                          placeholder="Ingrese su correo electronico"
                          type="text"
                        />
                      </div>
                      <div className="mt-3 d-grid">
                        <a
                          className="btn btn-block waves-effect waves-light"
                          style={{
                            backgroundColor: "#4b5d81",
                            color: "white",
                          }}
                          href="#"
                          onClick={() => FuncionEnvCorreo()}
                        >
                          Enviar Correo
                        </a>
                      </div>
                      <div className="mt-4 text-center">
                        <p>
                          Regresar a{" "}
                          <Link
                            to="IniciarSesion"
                            className="fw-medium text-primary"
                          >
                            Iniciar Sesión
                          </Link>{" "}
                        </p>
                        <p>© {new Date().getFullYear()} GPS Legal </p>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default CambiarContraseña
