import React, { useState, useEffect, useContext } from "react"
import {
    Dropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    Label,
} from "reactstrap"
const SelectSearch = ({ array, value, click, classstyle, placeholder, id, isAddItem, onKeyDown }) => {
    const [openClose, setopenClose] = useState(false);
    const [getDataArray, setDataArray] = useState([]);
    const [getSelectDefault, setSelectDefault] = useState({
        label: '',
        value: '',
    })
    const searchOrganoCompetente = valor => {
        let data = array;
        const e = valor.target;
        const searchTerms = e.value.trim().toLowerCase().split(' ');
        if (searchTerms.length > 0) {
            let result = data.filter((el) => {
                const name = el.label.toLowerCase();

                // Verificar si todas las palabras de búsqueda están presentes en el nombre
                return searchTerms.every((term) => name.includes(term));
            });

            setDataArray(result);
        } else {
            setDataArray(data);
        }
    };
    const handleChange = option => {
        click(option.value)
        setSelectDefault(prevState => ({
            ...prevState,
            label: option.label,
            value: option.value,
        }));
        setopenClose(!openClose)
    }
    const onKey = (e) => {
        if (isAddItem) {
            if (e.keyCode == 13) {
                onKeyDown(e)
                setopenClose(!openClose)
            }
        }
    }

    useEffect(() => {
        console.log(value)
        setSelectDefault(prevState => ({
            ...prevState,
            label: value.label,
            value: value.value,
        }))
        setDataArray(array)
    }, [value])
    return (
        <Dropdown
            id={id} tag="li" toggle={() => { setDataArray(array), setopenClose(!openClose) }}
            isOpen={openClose}>
            <DropdownToggle className={classstyle} id="dropdown-custom-components" style={{ width: '350px', backgroundColor: 'white', height: "36.5px" }}
            >
                <div className=" Texto-Limit" style={{ width: '300px' }}>
                    <p style={{ color: 'black' }}>{getSelectDefault.label}</p>
                </div>
            </DropdownToggle>
            <DropdownMenu modifiers={{
                setMaxHeight: {
                    enabled: true,
                    order: 890,
                    fn: (data) => {
                        return {
                            ...data,
                            styles: {
                                ...data.styles,
                                overflow: 'auto',
                                maxHeight: 300,
                                maxWidth: 500,
                                width: 350
                            },
                        };
                    },
                },
            }}>
                <div style={{ marginLeft: 10, marginRight: 10, marginTop: 5, marginBottom: 5 }}><input autoFocus
                    onKeyDown={(e) => { onKey(e) }}
                    type="text"
                    placeholder={placeholder}
                    onChange={searchOrganoCompetente}
                    className="form-control"
                /> </div>
                {getDataArray.map((option, i) => (
                    <div style={{ marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: 10, cursor: 'pointer' }} onClick={() => { handleChange(option) }}> {option.label}</div>
                ))}
            </DropdownMenu>
        </Dropdown>
    )
}
export default SelectSearch