import React, { Component, useState, useEffect } from "react"
import Select from "react-select"
import Swal from "sweetalert2"
import { getDecryptedItem } from '../../util'

import {
    Modal,
} from "reactstrap"
import instance from '../../config'
const baseUrl = process.env.REACT_APP_API_URL
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
export const ModalCliente = ({ show, onCloseClick, ReloadLista, Titulo, TipoCliente }) => {
    const [modalInsertar, setModalInsertar] = useState(false)
    const [getPersona, setPersona] = useState({
        nClid_Id: "",
        nUsu_Propietario: "",
        bCli_Tipo: "",
        cCli_Tab_TipoDoc: "NN00",
        cCli_Num_Doc: "",
        cCli_Num_DocOtro: "",
        cCli_NombreCorto: "",
        cCli_RazonSocial: "",
        cCli_Materno: "",
        nCli_Ubi: "",
        cCli_Dirección: "",
        cCli_DirecciónPostal: "",
        cCli_Email1: "",
        cCli_Email2: "",
        cCli_Fono1: "",
        cCli_Fono2: "",
        cCli_Web: "",
        cCli_ReferidoPor: "",
        cCli_ConNombre: "",
        cCli_ConApellidos: "",
        cCli_ConCargo: getDecryptedItem("NombreC"),
        cCli_ConFono1: "",
        cCli_ConFono2: "",
        cCli_ConEmail1: "",
        cCli_Tab_Prefijo: "",
        cCli_Logo: "",
        nCli_Estado: "",
        cCli_Profesion: "ZA01"
    })
    const handleChange = e => {
        const { name, value } = e.target
        if (TipoCliente == "1") {
            setPersona(prevState => ({
                ...prevState,
                cCli_RazonSocial: value,
            }))
        } else {
            setPersona(prevState => ({
                ...prevState,
                [name]: value,
            }))
        }

    }
    const FuncionAgregarClientePersona = async () => {
        var postData = {
            nUsu_Propietario: parseInt(getDecryptedItem("ID")),
            bCli_Tipo: parseInt(getPersona.bCli_Tipo),
            cCli_Tab_TipoDoc: getPersona.cCli_Tab_TipoDoc,
            cCli_Num_Doc: getPersona.cCli_Num_Doc,
            cCli_Num_DocOtro: "",
            cCli_NombreCorto: getPersona.cCli_NombreCorto,
            cCli_RazonSocial: getPersona.cCli_RazonSocial,
            cCli_Materno: getPersona.cCli_Materno,
            nCli_Ubi: "011505",
            cCli_Dirección: getPersona.cCli_Dirección,
            cCli_DirecciónPostal: getPersona.cCli_DirecciónPostal,
            cCli_Email1: getPersona.cCli_Email1,
            cCli_Email2: getPersona.cCli_Email2,
            cCli_Fono1: getPersona.cCli_Fono1,
            cCli_Fono2: getPersona.cCli_Fono2,
            cCli_Web: "",
            cCli_ReferidoPor: getPersona.cCli_ReferidoPor,
            cCli_ConNombre: "",
            cCli_ConApellidos: "",
            cCli_ConCargo: getDecryptedItem("NombreC"),
            cCli_ConFono1: "",
            cCli_ConFono2: "",
            cCli_ConEmail1: "",
            cCli_ConEmail2: "",
            cCli_Tab_Prefijo: "",
            cCli_Logo: "",
            nCli_Estado: 1,
            cCli_Profesion: "ZA01"
        }
        const postdata2 = {
            cCli_NombreCorto: parseInt(getPersona.bCli_Tipo) == 1 ? getPersona.cCli_RazonSocial : getPersona.cCli_NombreCorto + ' ' + getPersona.cCli_RazonSocial + ' ' + getPersona.cCli_Materno,
        }
        await instance
            .post("/Cliente/ClienteValidarClienteExist", postdata2)
            .then(async response => {
                let resultado = response.data.length;
                if (resultado > 0) {
                    Swal.fire({
                        title: "Mensaje",
                        text: "El cliente ya se encuentra registrado",
                        icon: "error",
                        confirmButtonText: "Aceptar",
                    })
                }
                else {
                    await instance
                        .post("/Cliente/Insertar", postData)
                        .then(response => {
                            Swal.fire({
                                title: "Mensaje",
                                text: "Agregado exitosamente",
                                icon: "success",
                                confirmButtonText: "Aceptar",
                            })
                            onCloseClick()
                            ReloadLista()
                        })
                        .catch(error => {

                        })
                }
            })
            .catch(error => {

            });
    }
    useEffect(() => {
        setPersona(prevState => ({
            ...prevState,
            bCli_Tipo: TipoCliente,
        }))
    }, [])
    return (
        <Modal isOpen={show}>
            <div
                className="modal-header ModalHeaderColor"
            >
                <h5
                    style={{ color: "#fff" }}
                    className="modal-title mt-0"
                    id="myModalLabel"
                >
                    {Titulo}
                </h5>
                <button
                    type="button"
                    onClick={() => onCloseClick()}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="col-mb-12 row">
                    {getPersona.bCli_Tipo == 0 ? (<>
                        <div className="col-md-4">
                            <input
                                className="form-control"
                                type="text"
                                name="cCli_NombreCorto"
                                onChange={handleChange}
                            />
                            <label className="col-form-label labelDown">
                                Nombre
                            </label>
                        </div>
                        <div className="col-md-4">
                            <input
                                className="form-control"
                                type="text"
                                name="cCli_RazonSocial"
                                onChange={handleChange}
                            />
                            <label className="col-form-label labelDown">
                                Apellido Paterno
                            </label>
                        </div>
                        <div className="col-md-4">
                            <input
                                className="form-control"
                                type="text"
                                name="cCli_Materno"
                                onChange={handleChange}
                            />
                            <label className="col-form-label labelDown">
                                Apellido Materno
                            </label>
                        </div>
                    </>) : (<>
                        <div className="col-md-12">
                            <input
                                className="form-control"
                                type="text"
                                name="cCli_RazonSocial"
                                onChange={handleChange}
                            />
                            <label className="col-form-label labelDown">
                                Razon Social
                            </label>
                        </div>
                    </>)}
                </div>
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => onCloseClick()}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                >
                    Cerrar
                </button>
                <button
                    type="button"
                    className="btn btn-danger waves-effect waves-light"
                    onClick={() => FuncionAgregarClientePersona()}
                >
                    Guardar
                </button>
            </div>
        </Modal>
    )
}
export default ModalCliente