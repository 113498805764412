import React, { Component, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTags from "react-meta-tags"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import Swal from "sweetalert2"
import { getDecryptedItem } from '../../util';
import {
  CardBody,
  Card,
  Col,
  Container,
  CardTitle,
  Button,
  Row,
  Modal,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap"
import instance from '../../config'
const baseUrl = process.env.REACT_APP_API_URL
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
const Tarifa = props => {
  const [modalInsertar, setModalInsertar] = useState(false)
  const [modalActualizar, setModalActualizar] = useState(false)
  const [modalFiltro, setModalFiltro] = useState(false)
  const [data, setData] = useState([])
  const [dataCabecera, setDataCabecera] = useState([])
  const [cheked, setcheked] = useState(false)
  const [Valorcheked, SetValorCheked] = useState(null)
  const [dataComboMoneda, setDataComboMoneda] = useState([])
  const [dataRoles, setDataRoles] = useState([])
  const [MonedaSelected, setMonedaSelected] = useState("")
  const [inputSoles, setInputSoles] = useState(false)
  const [inputDolares, setInputDolares] = useState(true)
  const [tituloTarifa, setTituloTarifa] = useState("")
  const [getErrores, setErrores] = useState({});

  const [hiddenBtnEditar, setHiddenBtnEditar] = useState(true)
  const [hiddenBtnEliminar, setHiddenBtnEliminar] = useState(true)
  const [validData, setValidData] = useState(false)
  const [getTarifa, setTarifa] = useState({
    cTar_Tab_Rol: "",
    cTar_Tab_Moneda: "",
    nTar_Monto: 0,
    nTar_MontoFormat: 0,
    nTar_MontoOld: 0,
    cTabNombre: "",
    cTar_Id: "",

  })
  const Validacion = (form) => {

    let errors = {};
    let regexLetras = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    let regexCorreo = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    let regexNumero = /^[0-9]+$/;
    if (form.cTar_Tab_Rol == "0" || form.cTar_Tab_Rol == "") {
      errors.cTar_Tab_Rol = "(*)"

    }
    if (form.cTar_Tab_Moneda == "0" || form.cTar_Tab_Moneda == "") {
      errors.cTar_Tab_Moneda = "(*)"
    }
    if (form.nTar_Monto == 0) {
      errors.nTar_Monto = "(*)"
    }
    return errors;
  }

  const handleChange = e => {
    const { name, value } = e.target
    setErrores(Validacion(getTarifa))

    setTarifa(prevState => ({
      ...prevState,
      [name]: value,
    }))

    if (name == "nTar_Monto") {
      setTarifa(prevState => ({
        ...prevState,
        nTar_Monto: value,
      }))
      setTarifa(prevState => ({
        ...prevState,
        nTar_MontoOld: value,
      }))
      setTarifa(prevState => ({
        ...prevState,
        nTar_MontoFormat: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(value),
      }))
    }
    else if (name == "cTar_Tab_Rol") {

      setTarifa(prevState => ({
        ...prevState,
        [name]: value,
      }))

    }


  }

  const FocusChange = e => {
    const { name, value } = e.target
    if (name == "nTar_Monto") {
      setTarifa(prevState => ({
        ...prevState,
        [name]: getTarifa.nTar_MontoOld,
      }))
    }
  }

  const handleBlur = e => {
    // handleChange(e)
    setErrores(Validacion(getTarifa))
    const { name, value } = e.target
    if (name == "nTar_Monto") {
      setTarifa(prevState => ({
        ...prevState,
        [name]: getTarifa.nTar_MontoFormat,
      }))
    }
  }

  const abrirCerrarModal = Modal => {
    if (Modal == 1) {

      setTarifa({
        cTar_Tab_Rol: "",
        cTar_Tab_Moneda: "",
        nTar_Monto: 0,
        nTar_MontoFormat: 0,
        nTar_MontoOld: 0,
        cTabNombre: "",
        cTar_Id: "",
      });
      if (!modalInsertar == false) {
        Swal.fire({
          html: "¿Seguro que desea salir sin guardar los datos?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        }).then(result => {
          if (result.isConfirmed) {

            Validacion(getTarifa);
            setModalInsertar(!modalInsertar)
          }
        })
      } else {
        setModalInsertar(!modalInsertar)
      }
    } else if (Modal == 2) {
      setModalActualizar(!modalActualizar)
    } else if (Modal == 3) {
      setModalFiltro(!modalFiltro)
    }
  }
  const Acciones = (Tarifa) => {
    setTarifa(Tarifa)

    setErrores({})
    abrirCerrarModal(2)
  }
  const FormatoMoneda = (moneda) => {

    return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(moneda);
  }
  const datas = {
    columns: [
      {
        label: "Rol",
        field: "rol_nom",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Moneda",
        field: "cTabNombre",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: `Tarifa hora`,
        field: "nTar_Monto2",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          width: "200px",
          className: "alignCenter",
        },
      },
    ],
    rows: data,
  }
  const badgesData = {
    columns: [
      ...datas.columns,
      {
        label: "Acciones",
        field: "acciones",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
    ],
    rows: [
      ...datas.rows.map((row, id) => ({
        ...row,
        acciones: (
          <div>
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="dropdown-toggle">
                <i className="bx bx-dots-vertical-rounded" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem hidden={hiddenBtnEditar} onClick={() => Acciones(row)}>Editar</DropdownItem>
                <DropdownItem hidden={hiddenBtnEliminar} onClick={() =>
                  Swal.fire({
                    title: "Mensaje",
                    html:
                      "Estas seguro de eliminar este registro",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Aceptar",
                    cancelButtonText: "Cancelar",
                  }).then(result => {
                    if (result.isConfirmed) {

                      FuncionEliminarTarifa(row.cTar_Id)
                    }
                  })
                }>Eliminar</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        ),
      })),
    ],
  }


  const FuncionValidarFormulario = async (tipo) => {
    if (Object.keys(getErrores).length === 0) {


      if (tipo == "upd") {
        FuncionActualizarTarifa();
      }
      else if ("ins") {
        FuncionInsertarTarifa();
      }

    } else {
      setValidData(true);
      Swal.fire({
        title: "Mensaje",
        text: "Complete los campos requeridos",
        icon: "error",
        confirmButtonText: "Aceptar",
      })
    }
  }

  const FuncionInsertarTarifa = async () => {


    if (getTarifa.cTar_Tab_Rol == 0 || getTarifa.cTar_Tab_Rol == undefined || getTarifa.cTar_Tab_Moneda == 0 || getTarifa.cTar_Tab_Moneda == undefined || getTarifa.nTar_Monto == 0 || getTarifa.nTar_Monto == undefined) {
      Swal.fire({
        title: "Mensaje",
        text: "Faltan completar datos",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
    else {
      var postData = {
        cTar_Tab_Rol: getTarifa.cTar_Tab_Rol,
        cTar_Tab_Moneda: getTarifa.cTar_Tab_Moneda,
        nTar_Monto: parseFloat(getTarifa.nTar_Monto.replace(/,/g, ''))
      }

      await instance
        .post("/Tarifa/Insertar", postData)
        .then(response => {

          let status = response.data;

          if (status == "ya existe") {
            Swal.fire({
              title: "Mensaje",
              text: "Ya existe un registro con este rol y moneda",
              icon: "error",
              confirmButtonText: "Aceptar",
            })
          }
          else {
            Swal.fire({
              title: "Mensaje",
              text: "Agregado exitosamente",
              icon: "success",
              confirmButtonText: "Aceptar",
            })
            setModalInsertar(!modalInsertar)
            FuncionListarTarifa()
          }
        })
        .catch(error => {

        })
    }

  }
  const FuncionActualizarTarifa = async () => {

    if (getTarifa.cTar_Tab_Rol == 0 || getTarifa.cTar_Tab_Moneda == 0 || getTarifa.nTar_Monto == 0) {
      Swal.fire({
        title: "Mensaje",
        text: "Faltan completar datos",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
    else {
      var postData = {
        cTar_Tab_Rol: getTarifa.cTar_Tab_Rol,
        cTar_Tab_Moneda: getTarifa.cTar_Tab_Moneda,
        nTar_Monto: parseFloat(getTarifa.nTar_Monto.replace(/,/g, '')),
        cTar_Id: getTarifa.cTar_Id,
      }




      await instance
        .post("/Tarifa/Actualizar", postData)
        .then(response => {



          let status = response.data;

          if (status == "ya existe") {
            Swal.fire({
              title: "Mensaje",
              text: "Ya existe un registro con este rol y moneda",
              icon: "error",
              confirmButtonText: "Aceptar",
            })
          }
          else {
            Swal.fire({
              title: "Mensaje",
              text: "Actualizado exitosamensste",
              icon: "success",
              confirmButtonText: "Aceptar",
            })
            setModalActualizar(!modalActualizar)
            FuncionListarTarifa()
          }
        })
        .catch(error => {

        })
    }

  }
  const FuncionFiltroTarifa = async e => {
    var postData = {
      cTar_Tab_Moneda: e.target.value,
    }
    if (postData.cTar_Tab_Moneda == 0) {
      FuncionListarTarifa()
    }
    else {
      await instance
        .post("/Tarifa/listar/Filtro", postData)
        .then(response => {

          setData(response.data)
        })
        .catch(error => {

        })
    }

  }
  const FuncionEliminarTarifa = async (_cTar_Id) => {
    var postData = {
      cTar_Id: _cTar_Id
    }
    await instance
      .post("/Tarifa/Eliminar", postData)
      .then(response => {
        Swal.fire({
          title: "Mensaje",
          text: "Eliminado exitosamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        })
        FuncionListarTarifa()
      })
      .catch(error => {

      })
  }
  const FuncionListarTarifa = async () => {

    var postData = {

    }
    await instance
      .post("/Tarifa/listar", postData)
      .then(response => {
        console.log(response.data)
        setData(response.data)


      })
      .catch(error => {

      })
  }
  const FuncionListarComboMoneda = async () => {
    var postData = {
      Prefijo: "MO",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataComboMoneda(response.data)


      })
      .catch(error => {

      })
  }
  const FuncionListarRoles = async () => {
    var postData = {

    }
    await instance
      .post("/Maestro/Listar/Roles", postData)
      .then(response => {
        setDataRoles(response.data)


      })
      .catch(error => {

      })
  }
  const FuncionListarPermisos = async () => {
    var postData = {
      nUsu_ID: parseInt(getDecryptedItem("RolID"))
    }
    await instance
      .post("/Usuario/Acceso", postData)
      .then(response => {
        let arrayPermisos = response.data;
        const permiso = arrayPermisos.find(element => element.cUsu_Modulo == "ME30");

        if (permiso.bUsu_Editar == 1) {
          setHiddenBtnEditar(false);
        }
        else {
          setHiddenBtnEditar(true);
        }
        if (permiso.bUsu_Eliminar == 1) {
          setHiddenBtnEliminar(false);
        }
        else {
          setHiddenBtnEliminar(true);
        }
      })
      .catch(error => {

      })
  }

  useEffect(() => {
    FuncionListarPermisos();
    FuncionListarTarifa()
    FuncionListarComboMoneda()
    FuncionListarRoles();
    setErrores(Validacion(getTarifa))
    //FuncionListarMaestrosCabecera()
  }, [])
  return (

    <React.Fragment>
      <div className="page-content">
        { }
        <MetaTags>
          <title>Configuración de tarifa | GpsLegal</title>
        </MetaTags>
        <Container fluid>
          { }
          <Breadcrumbs title="GpsLegal" breadcrumbItem="Configuración de tarifas" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div>
                    <div className="row col-md-12">
                      <div className="col-md-1"
                        style={{ width: "100px", paddingRight: "5px", paddingLeft: "0px", display: 'block' }}>
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(1)}
                          className="btn btn-danger waves-effect waves-light"
                          data-toggle="modal"
                          data-target="#myModal"
                          hidden={hiddenBtnEditar}
                        >
                          Nuevo Item
                        </button>{" "}
                      </div>
                      <div className="col-md-2"
                        style={{ width: "200px", paddingRight: "5px", paddingLeft: "0px", display: 'inline-block', float: 'left' }}>
                        <select
                          className="form-select inputDown"
                          name="cTab_Moneda"
                          value={dataComboMoneda.cTabCodigo}
                          onChange={FuncionFiltroTarifa}
                        >
                          <option value="0">Seleccionar</option>
                          {dataComboMoneda.map((row, i) => (
                            <option key={i} value={row.cTabCodigo}>
                              {row.cTabNombre}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>



                    <Modal isOpen={modalInsertar}>
                      <div
                        className="modal-header ModalHeaderColor"
                      >
                        <h5
                          style={{ color: "#fff" }}
                          className="modal-title mt-0"
                          id="myModalLabel"
                        >
                          Nueva tarifa
                        </h5>
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(1)}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">

                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <select
                              className={getErrores.cTar_Tab_Rol && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                              name="cTar_Tab_Rol"
                              value={getTarifa && getTarifa.cTar_Tab_Rol}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value="0">Seleccionar</option>
                              {dataRoles.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>

                            <label className="col-form-label labelDown">Rol</label>{getErrores.cTar_Tab_Rol && <label className="col-form-label labelDown labelRed">{getErrores.cTar_Tab_Rol}</label>}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <select
                              className={getErrores.cTar_Tab_Moneda && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                              name="cTar_Tab_Moneda"
                              value={getTarifa && getTarifa.cTar_Tab_Moneda}
                              onChange={handleChange}
                              onBlur={handleBlur}

                            >
                              <option value="0">Seleccionar</option>
                              {dataComboMoneda.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">Moneda</label>{getErrores.cTar_Tab_Moneda && <label className="col-form-label labelDown labelRed">{getErrores.cTar_Tab_Moneda}</label>}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <input
                              className={getErrores.nTar_Monto && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                              type="text"
                              placeholder="Ingrese un monto"
                              name="nTar_Monto"
                              value={getTarifa && getTarifa.nTar_Monto}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              onFocus={FocusChange}
                              style={{ textAlign: "right" }}
                              maxLength="10"
                            />
                            <label className="col-form-label labelDown">Tarifa hora </label>{getErrores.nTar_Monto && <label className="col-form-label labelDown labelRed">{getErrores.nTar_Monto}</label>}
                          </div>
                        </div>
                        <div className="mb-3 row" style={{ marginTop: '20px', marginBottom: '0px' }}>
                          <div className="col-md-12">
                            <label className="col-form-label labelDown labelRed">(*) Campos obligatorios</label>
                            <label className="col-form-label labelDown"></label>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(1)}
                          className="btn btn-secondary waves-effect"
                          data-dismiss="modal"
                        >
                          Cerrar
                        </button>
                        <button
                          type="button"
                          onClick={() => FuncionValidarFormulario("ins")}
                          className="btn btn-danger waves-effect waves-light"
                        >
                          Guardar
                        </button>
                      </div>
                    </Modal>

                    <Modal isOpen={modalActualizar}>
                      <div
                        className="modal-header ModalHeaderColor"
                      >
                        <h5
                          style={{ color: "#fff" }}
                          className="modal-title mt-0"
                          id="myModalLabel"
                        >
                          Editar Item
                        </h5>
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(2)}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">

                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <select
                              name="cTar_Tab_Rol"
                              value={getTarifa && getTarifa.cTar_Tab_Rol}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={getErrores.cTar_Tab_Rol && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                            >
                              <option value="0">Seleccionar</option>
                              {dataRoles.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>

                            <label className="col-form-label labelDown">Rol</label>{getErrores.cTar_Tab_Rol && <label className="col-form-label labelDown labelRed">{getErrores.cTar_Tab_Rol}</label>}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <select
                              className={getErrores.cTar_Tab_Moneda && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                              name="cTar_Tab_Moneda"
                              value={getTarifa && getTarifa.cTar_Tab_Moneda}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value="0">Seleccionar</option>
                              {dataComboMoneda.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Moneda
                            </label>
                            <label className="col-form-label labelDown labelRed">{getErrores.cTar_Tab_Moneda}</label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <input
                              className={getErrores.nTar_Monto && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                              type="text"
                              placeholder="Ingrese un monto"
                              name="nTar_Monto"
                              value={getTarifa && getTarifa.nTar_Monto}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              onFocus={FocusChange}
                              style={{ textAlign: "right" }}
                              maxLength="10"
                            />
                            <label className="col-form-label labelDown">
                              Tarifa hora
                            </label>
                            <label className="col-form-label labelDown labelRed">{getErrores.nTar_Monto}</label>
                          </div>
                        </div>
                        <div className="mb-3 row" style={{ marginTop: '20px', marginBottom: '0px' }}>
                          <div className="col-md-12">
                            <label className="col-form-label labelDown labelRed">(*) Campos obligatorios</label>
                            <label className="col-form-label labelDown"></label>
                          </div>
                        </div>


                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(2)}
                          className="btn btn-secondary waves-effect"
                          data-dismiss="modal"
                        >
                          Cerrar
                        </button>
                        <button
                          type="button"
                          onClick={() => FuncionValidarFormulario("upd")}
                          className="btn btn-danger waves-effect waves-light"
                        >
                          Guardar
                        </button>
                      </div>
                    </Modal>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>

                  <CardTitle style={{ fontSize: "20px", color: "#aa1b0E", fontWeight: "normal" }}>
                    <span style={{ color: "#aa1b0E" }}>Listado de tarifas por horas</span>{" "}
                  </CardTitle>
                  <MDBDataTable
                    data={badgesData}
                    responsiveXl
                    fixed
                    hover
                    entriesLabel="Mostrar"
                    entrylabel={"Mostrar entradas"}
                    infoLabel={["Mostrando ", "a", "de", "registros"]}
                    bordered
                    order={["age", "desc"]}
                    noRecordsFoundLabel={"No hay datos"}
                    searchLabel={"Buscar"}
                    noBottomColumns
                    className="TablaTarifa"
                    id="TablaTarifa"
                    paginationLabel={["Anterior", "Siguiente"]}
                  />

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Tarifa
