import React, { useState, useEffect, useContext, useRef } from "react"
import {
    Col,
    Row,
    Modal,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    CardText,
    Alert,
    Button,
} from "reactstrap"
import { getDecryptedItem, setEncryptedItem } from '../../../../util';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faTimes,
    faChevronLeft,
    faChevronRight,
    faPaperPlane,
    faGlobe,
    faCheck,
} from "@fortawesome/free-solid-svg-icons"
import logo from "../../../../assets/images/logo-dark.png"
import Select, { } from "react-select"
import moment from "moment"
import ComboContext from "pages/Menus/Context/Combos/ComboContext"
var meses = new Array("Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre");
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var mesIni = Fecha.getMonth() - 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
if (mesIni < 10) mesIni = "0" + mesIni //agrega cero si el menor de 10
import instance from '../../../../config'
import Swal from "sweetalert2"
import ModalCliente from "pages/Componentes/ModalCliente"
import CasoContext from "pages/Menus/Context/Casos/CasoContext"
import SelectSearch from "pages/Componentes/SelectSearch";
import { useHistory } from "react-router-dom"

const baseUrl = process.env.REACT_APP_API_URL
const Validacion = (form) => {
    let errors = {};
    let regexLetras = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    let regexCorreo = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    let regexNumero = /^[0-9]+$/;
    if (!form.cCas_Titulo.trim()) {
        errors.cCas_Titulo = "*"
    }
    if (form.cCas_Titulo1.length == 0) {
        errors.cCas_Titulo1 = "*"
    }
    if (!form.cCas_Cod_Externo.trim()) {
        errors.cCas_Cod_Externo = "*"
    }
    if (form.cCas_Tab_Materia == 'NN00') {
        errors.cCas_Tab_Materia = "*"
    }
    if (form.cCas_Tab_Tipo == 'CA01' || form.cCas_Tab_Tipo == 'c') {
        if (form.cCas_Tab_SubMateria == 'NN00') {
            errors.cCas_Tab_SubMateria = "*"
        }
    }

    if (form.nCli_Id == '') {
        errors.nCli_Id = "*"
    }
    if (form.cCas_Tab_Distrito_Jud == 'NN00') {
        errors.cCas_Tab_Distrito_Jud = "*"
    }
    return errors;
}
const ModalCaso = ({ show, onCloseClick, onCloseModal }) => {
    const [getErrores, setErrores] = useState({});

    let history = useHistory()
    const casoContext = useContext(CasoContext);
    const comboContext = useContext(ComboContext);
    const [modalInsertarCliente, setModalInsertarCliente] = useState(false)
    const [validData, setValidData] = useState(false)
    const [getMonedaAR, setMonedaAR] = useState("");
    const [getMonedaA, setMonedaA] = useState("");
    const [getMonedaP, setMonedaP] = useState("");
    const [getMonedaUAD, setMonedaUAD] = useState("");
    const [dataComboMoneda, setDataComboMoneda] = useState([])
    const [getAnioData, setAnioData] = useState([])
    const [getValueAsunto, setValueAsunto] = useState({
        label: 'Sin Asunto',
        value: 'Sin Asunto',
    })
    const SendAnios = () => {
        const anio = ano
        const anios = []
        for (var i = anio; i >= 1978; i--) {
            anios.push({ anio: i })
        }
        setAnioData(anios)
        console.table(anios)
    }
    const [getMontoAR, setMontoAR] = useState({
        Monto: 0,
        MontoFormat: 0,
        MontoOld: 0,
    });
    const [getMontoA, setMontoA] = useState({
        MontoA: 0,
        MontoFormatA: 0,
        MontoOldA: 0,
    });
    const [getMontoP, setMontoP] = useState({
        MontoP: 0,
        MontoFormatP: 0,
        MontoOldP: 0,
    });
    const [getMontoUAD, setMontoUAD] = useState({
        MontoUAD: 0,
        MontoFormatUAD: 0,
        MontoOldUAD: 0,
    });
    const [getCasos, setCasos] = useState({
        nCas_Id: "",
        nCli_Id: "",
        nEqu_ID: 1,
        cCas_Tab_Tipo: "CA01",
        cCas_Titulo1: "Sin Asunto",
        cCas_Titulo: "",
        cCas_Cod_Externo: "",
        cCas_Cod_Interno: "2021-0008",
        cCas_Recurso_Num: "0203",
        cCas_Detalle: "",
        cCas_Expediente: "",
        nCas_Exp_Year: ano,
        cMed_cau: "",
        cCas_Tab_Materia: "NN00",
        cCas_Tab_SubMateria: "NN00",
        nJuz_Id: "NN00",
        cCas_Tab_Distrito_Jud: "GA625",

        cCas_Tab_Jurisdiccion: "JU01",//Falta
        cCas_Tab_Sala: "SA01",//Falta
        cCas_Tab_Recurso_Tipo: "HA01",//Falta
        cCas_Tab_Comision: "CP01", //Falta
        cTab_Lugar_Tramite: "WE01",   //Falta
        cTab_Tipo_Acto: "0000", //Falta
        nPer_Id: 1,
        cCas_Referencia: "",
        cCas_Denominacion: "",
        cUbi_Org: "",
        dCas_Fec_Ini_Interno: ano + "-" + mes + "-" + dia,
        dCas_Fec_Ini_Procesal: ano + "-" + mes + "-" + dia,
        cCas_Tab_Estado_Externo: "",
        dCas_Fec_Estima_Cierre: moment(Fecha).format('yyyy-MM-DD'),
        cTab_Estado_Interno: "EP06",
        dFas_Fec_Cierre: "1970-01-01",
        nUsu_ID: getDecryptedItem("ID"),
        dUsu_Fecha: ano + "-" + mes + "-" + dia,
        bCas_Estado: 1,
        cTab_Moneda: "NN00",
        nCas_Monto: 0,
        nCas_MontoFormat: 0,
        nCas_MontoOld: 0,
        cTab_Tipo_Monto: 0,
        cName_Resolucion: "",
        nCas_HonorarioFijo: 0,
        nCas_HonorarioFijoFormat: 0,
        nCas_HonorarioFijoOld: 0,
        cTab_MonedaHF: "NN00",
        nCas_HonorarioExito: 0,
        nCas_HonorarioExitoFormat: 0,
        nCas_HonorarioExitoOld: 0,
        cTab_MonedaHE: "NN00",
        nCas_URPs: " ",
        nCas_URPsFormat: " ",
        nCas_URPsOld: " ",
        nCas_Origen: 0,
        TEXPEDIENTE: 0,
        LTRAMITE: 0,
        CodSalaSuprema: "",
        CodRecurso: "",
        CodNRecurso: "",
        CodAnoRecurso: "",
        CodNExpediente: "",
        CodAnoxpediente: "",
        CodDistritoOrigen: "",
        CodApePaterno: "",
        CodApeMaterno: "",
        CodNombre: "",
        nUsu_ID2: getDecryptedItem('NombreC'),
        selectComision: 0,
        selectOpcion1: 0,
        selectOpcion2: 0,
        RSocial: "",
        PApellidos: "",
        PNombres: "",
    })
    const [Loading, setLoading] = useState(true)
    const [cheked, setcheked] = useState(false)
    const [cheked2, setcheked2] = useState()
    const [disabled, setDisabled] = useState(true)
    const [checkedMC, setcheckedMC] = useState(false)
    const [getreadOnly, setreadOnly] = useState(false)
    /* Eventos Tabs */
    const [Tab, setTab] = useState("1")
    const [Active1, setActive1] = useState("active")
    const [Active2, setActive2] = useState("")
    const [Active3, setActive3] = useState("")
    const [ActiveN1, setActiveN1] = useState("badge bg-danger rounded-pill")
    const [ActiveN2, setActiveN2] = useState("badge bg-secondary rounded-pill")
    const [ActiveN3, setActiveN3] = useState("badge bg-secondary rounded-pill")
    const [HiddenFinalizar, setHiddenFinalizar] = useState("none")
    const [HiddenSiguiente3, setHiddenSiguiente3] = useState("none")
    const [HiddenSiguiente2, setHiddenSiguiente2] = useState("")
    const [HiddenAnterior2, setHiddenAnterior2] = useState("none")
    const [HiddenAnterior1, setHiddenAnterior1] = useState("none")
    const [HiddenEquipo, setHiddenEquipo] = useState()
    const [HiddenEquipoCbo, setHiddenEquipoCbo] = useState("none")
    const [HiddenCEJ, setHiddenCEJ] = useState('')
    const [HiddenCEJFiltro, setHiddenCEJFiltro] = useState('')
    const [HiddenSupremo, setHiddenSupremo] = useState('none')
    const [HiddenIndecopi, setHiddenIndecopi] = useState('none')
    const [HiddenIndecopiFiltro, setHiddenIndecopiFiltro] = useState('none')
    /*-------------------------------------------------------- */
    const [GetParticipantesA, setParticipantesA] = useState([])
    const [GetParticipantesAR, setParticipantesAR] = useState([])
    const [GetParticipantesP, setParticipantesP] = useState([])
    const [GetParticipantesUAD, setParticipantesUAD] = useState([])
    const [getdataCuadernos, setDataCuadernos] = useState([])
    const [getdataGeneral, setDataGeneral] = useState([])
    const [getdataMovimientos, setDataMovimientos] = useState([])
    const [getdataNotificacion, setDataNotificacion] = useState([])
    const [getdataArchivos, setDataArchivos] = useState([])
    const [getSeleccionarCuadernos, setSeleccionarCuadernos] = useState([])
    const [getdataCuadernosInsertar, setDataCuadernosInsertar] = useState([])
    const [getdataGeneralInsertar, setDataGeneralInsertar] = useState([])
    const [getdataMovimientosInsertar, setDataMovimientosInsertar] = useState([])
    const [getdataNotificacionInsertar, setDataNotificacionInsertar] = useState([])
    const [getdataArchivosInsertar, setDataArchivosInsertar] = useState([])
    /* Indecopi */
    const [getdenunciantes, setdenunciantes] = useState([])
    const [getdenunciados, setdenunciados] = useState([])
    const [getNotications, setNotications] = useState([])
    const [getCodigoIndecopi, setCodigoIndecopi] = useState([])
    const [getCodigoI, setCodigoI] = useState([])
    const [getCuantia, setCuantia] = useState(true)
    const [getTipoCliente, setTipoCliente] = useState(0)
    const [data, setData] = useState([])
    const [getCliente, setCliente] = useState([])
    const [getMaestroMonto, setMaestroMonto] = useState([]);
    const [getMaestro, setMaestro] = useState({
        cTabCodigo: "",
        cTabSimbolo: "",
        cTabNombre: ""
    })
    const [comision, setcomision] = useState({
        comision1: "",
        comision2: "none",
        comision3: "none",
        comision4: "none",
        persona1: "none",
        persona2: "none",
    })
    const [getHiddenIcon, setHiddenIcon] = useState(false)
    const [getAsunto, setAsunto] = useState({})
    const inputReference = useRef(null);
    const [getUsuario, setUsuario] = useState({
        bUsu_Activo: 1,
        cUsu_Fono: "",
        cUsu_Login: "",
        cUsu_Nombres: "",
        cUsu_Pass: "",
        cUsu_email: "",
        nRol_ID: 1,
        nRol_ID2: "",
        ImgBinary: "",
        nUsu_ID: ""
    })
    /* ----------------------------*/
    const FuncionListarComboMoneda = async () => {
        var postData = {
            Prefijo: "MO",
        }
        await instance
            .post("/Maestros/Data", postData)
            .then(response => {
                setDataComboMoneda(response.data)

            })
            .catch(error => {

            })
    }
    const BluerChange = e => {
        const { name, value } = e.target
        if (name == "Monto") {
            setMontoAR(prevState => ({
                ...prevState,
                [name]: getMontoAR.MontoFormat,
            }))
        }
        if (name == "MontoA") {
            setMontoA(prevState => ({
                ...prevState,
                [name]: getMontoA.MontoFormatA,
            }))
        }
        if (name == "MontoP") {
            setMontoP(prevState => ({
                ...prevState,
                [name]: getMontoP.MontoFormatP,
            }))
        }
        if (name == "MontoUAD") {
            setMontoUAD(prevState => ({
                ...prevState,
                [name]: getMontoUAD.MontoFormatUAD,
            }))
        }
        if (name == "nCas_Monto") {
            setCasos(prevState => ({
                ...prevState,
                [name]: getCasos.nCas_MontoFormat,
            }))
        }
        if (name == "nCas_HonorarioFijo") {
            setCasos(prevState => ({
                ...prevState,
                [name]: getCasos.nCas_HonorarioFijoFormat,
            }))
        }
        if (name == "nCas_HonorarioExito") {
            setCasos(prevState => ({
                ...prevState,
                [name]: getCasos.nCas_HonorarioExitoFormat,
            }))
        }
        if (name == "nCas_URPs") {
            setCasos(prevState => ({
                ...prevState,
                [name]: getCasos.nCas_URPsFormat,
            }))
        }
        setErrores(Validacion(getCasos))
    }
    const FocusChange = e => {
        const { name, value } = e.target
        if (name == "Monto") {
            setMontoAR(prevState => ({
                ...prevState,
                [name]: getMontoAR.MontoOld,
            }))
        }
        if (name == "MontoA") {
            setMontoA(prevState => ({
                ...prevState,
                [name]: getMontoA.MontoOldA,
            }))
        }
        if (name == "MontoP") {
            setMontoP(prevState => ({
                ...prevState,
                [name]: getMontoP.MontoOldP,
            }))
        }
        if (name == "MontoUAD") {
            setMontoUAD(prevState => ({
                ...prevState,
                [name]: getMontoUAD.MontoOldUAD,
            }))
        }
        if (name == "nCas_Monto") {
            setCasos(prevState => ({
                ...prevState,
                [name]: getCasos.nCas_MontoFormat,
            }))
        }

        if (name == "nCas_Monto") {
            setCasos(prevState => ({
                ...prevState,
                [name]: getCasos.nCas_MontoOld,
            }))
        }
        if (name == "nCas_HonorarioFijo") {
            setCasos(prevState => ({
                ...prevState,
                [name]: getCasos.nCas_HonorarioFijoOld,
            }))
        }
        if (name == "nCas_HonorarioExito") {
            setCasos(prevState => ({
                ...prevState,
                [name]: getCasos.nCas_HonorarioExitoOld,
            }))
        }


    }
    const handleChange = e => {
        const { name, value } = e.target
        setCasos(prevState => ({
            ...prevState,
            [name]: value,
        }))
        if (name == 'Monto') {
            setMontoAR(prevState => ({
                ...prevState,
                Monto: value,
                MontoOld: value,
                MontoFormat: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(value),
            }))
        }
        if (name == 'MontoA') {

            setMontoA(prevState => ({
                ...prevState,
                MontoA: value,
            }))
            setMontoA(prevState => ({
                ...prevState,
                MontoOldA: value
            }))
            setMontoA(prevState => ({
                ...prevState,
                MontoFormatA: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(value)
            }))

        }
        if (name == 'MontoP') {
            setMontoP(prevState => ({
                ...prevState,
                MontoP: value,
                MontoOldP: value,
                MontoFormatP: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(value),
            }))
        }
        if (name == 'MontoUAD') {
            setMontoUAD(prevState => ({
                ...prevState,
                MontoUAD: value,
                MontoOldUAD: value,
                MontoFormatUAD: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(value),
            }))
        }
        if (e.target.name == "nCas_Id") {
            FuncionInformacionCaso(e.target.value)
        }
    }
    const handleChangeCaso = e => {
        const { name, value } = e.target

        if (name == 'cCas_Tab_Tipo') {
            if (value == 'CA05') {
                setCasos(prevState => ({
                    ...prevState,
                    nCas_Origen: 1,
                    selectOpcion1: 1,
                    selectOpcion2: 0,
                }))
                setHiddenCEJ('none')
                setHiddenSupremo('none')
                setHiddenCEJFiltro('none')
                setHiddenIndecopi('')
                setHiddenIndecopiFiltro('none')
                setDataCuadernos([])
            } else if (value == 'CA04') {
                setCasos(prevState => ({
                    ...prevState,
                    nCas_Origen: 1,
                    selectOpcion1: 1,
                    selectOpcion2: 0,
                }))
                setHiddenCEJ('none')
                setHiddenSupremo('none')
                setHiddenCEJFiltro('none')
                setHiddenIndecopi('none')
                setHiddenIndecopiFiltro('')
                setDataCuadernos([])
            } else if (value == 'CA01') {
                setCasos(prevState => ({
                    ...prevState,
                    nCas_Origen: 0,
                }))
                setHiddenCEJ('')
                setHiddenCEJFiltro('none')
                setHiddenIndecopi('none')
                setHiddenIndecopiFiltro('none')
                setHiddenSupremo('none')
                setDataCuadernos([])
            } else if (value == 'CA02') {
                setCasos(prevState => ({
                    ...prevState,
                    nCas_Origen: 0,
                }))
                setHiddenCEJ('none')
                setHiddenCEJFiltro('')
                setHiddenIndecopi('none')
                setHiddenIndecopiFiltro('none')
                setHiddenSupremo('none')
                setDataCuadernos([])
            } else if (value == 'CA03') {
                setCasos(prevState => ({
                    ...prevState,
                    nCas_Origen: 3,
                }))
                setHiddenCEJ('none')
                setHiddenIndecopi('none')
                setHiddenIndecopiFiltro('none')
                setHiddenCEJFiltro('none')
                setHiddenSupremo('')
                setDataCuadernos([])
            } else if (value == 'CA06') {
                setCasos(prevState => ({
                    ...prevState,
                    nCas_Origen: 2,
                }))
                setHiddenCEJ('none')
                setHiddenSupremo('none')
                setHiddenCEJFiltro('none')
                setHiddenIndecopi('none')
                setHiddenIndecopiFiltro('none')
                setDataCuadernos([])
                setDisabled(false)
            }
        } else if (name == 'nCas_Monto') {
            setCasos(prevState => ({
                ...prevState,
                nCas_Monto: value,
                nCas_MontoOld: value,
                nCas_MontoFormat: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(value),
            }))
        } else if (name == 'nCas_HonorarioFijo') {
            setCasos(prevState => ({
                ...prevState,
                nCas_HonorarioFijo: value,
                nCas_HonorarioFijoOld: value,
                nCas_HonorarioFijoFormat: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(value),
            }))
        } else if (name == 'nCas_HonorarioExito') {
            setCasos(prevState => ({
                ...prevState,
                nCas_HonorarioExito: value,
                nCas_HonorarioExitoOld: value,
                nCas_HonorarioExitoFormat: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(value),
            }))
        } else if (name == 'nCas_URPs') {
            setCasos(prevState => ({
                ...prevState,
                nCas_URPs: value,
                nCas_URPsOld: value,
                nCas_URPsFormat: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(value),
            }))
        } else if (name == 'selectComision') {
            if (value == "1") {
                setcomision(prevState => ({
                    ...prevState,
                    comision1: "",
                    comision2: "none",
                    comision3: "none",
                    comision4: "none",
                }))
            } else if (value == "2") {
                setcomision(prevState => ({
                    ...prevState,
                    comision1: "none",
                    comision2: "",
                    comision3: "none",
                    comision4: "none",
                }))
            } else if (value == "3") {
                setcomision(prevState => ({
                    ...prevState,
                    comision1: "none",
                    comision2: "none",
                    comision3: "",
                    comision4: "none",
                }))
            } else if (value == "4") {
                setcomision(prevState => ({
                    ...prevState,
                    comision1: "none",
                    comision2: "none",
                    comision3: "none",
                    comision4: "",
                }))
            }
            setCasos(prevState => ({
                ...prevState,
                [name]: value,
            }))
        } else if (name == 'selectOpcion2') {
            if (value == "1") {
                setcomision(prevState => ({
                    ...prevState,
                    comision1: "none",
                    comision2: "none",
                    comision3: "none",
                    comision4: "none",
                    persona1: "",
                    persona2: "none",
                }))
            } else if (value == "2") {
                setcomision(prevState => ({
                    ...prevState,
                    comision1: "none",
                    comision2: "",
                    comision3: "none",
                    comision4: "none",
                    persona1: "none",
                    persona2: "",
                }))
            }
            setCasos(prevState => ({
                ...prevState,
                [name]: value,
                Cod: value,
            }))
        } else {
            setCasos(prevState => ({
                ...prevState,
                [name]: value,
            }))
            setErrores(Validacion(getCasos))
        }
    }
    const handleChangeCuantia = e => {

        setCuantia(!getCuantia)
    }
    const handleChangeCheck = e => {
        setcheked(!cheked)
        setcheked2(false)
        if (cheked == false) {
            setHiddenEquipo("none")
            setHiddenEquipoCbo("")
        } else {
            setHiddenEquipo("")
            setHiddenEquipoCbo("none")
            setcheked2(!cheked2)
        }
    }
    const handleChangeCheck2 = e => {
        setcheked2(!cheked2)
        setcheked(false)
        if (cheked2 == false) {
            setHiddenEquipo("")
            setHiddenEquipoCbo("none")
        } else {
            setHiddenEquipo("none")
            setHiddenEquipoCbo("")
            setcheked(!cheked)
        }
    }
    const handleChangeCliente = valor => {
        setCliente(valor)
        if (valor == null) {
        } else {
            setCasos(prevState => ({
                ...prevState,
                nCli_Id: valor.value,
            }))
            setErrores(Validacion(getCasos))
        }
        setValueAsunto(prevState => ({
            ...prevState,
            label: 'Sin Asunto',
            value: 'Sin Asunto',
        }))
        console.log("Sin Asunto")
        casoContext.FuncionAsuntos({ nCli_Id: valor.value })
    };
    const handleChangeTCliente = valor => {
        setTipoCliente(valor.target.value)
        comboContext.FuncionListarCliente(parseInt(valor.target.value))
    };
    const handleChangeOrganoCom = valor => {
        setCasos(prevState => ({
            ...prevState,
            cCas_Tab_Distrito_Jud: valor,
        }))
        setErrores(Validacion(getCasos))
    };
    const handleChangeParticipantesA = value => {
        if (value == null) {
        } else {
            setParticipantesA(value);
        }
    };
    const handleChangeParticipantesAR = value => {

        if (value == null) {
        } else {
            setParticipantesAR(value);
        }
    };
    const handleChangeParticipantesP = value => {
        if (value == null) {
        } else {
            setParticipantesP(value);
        }
    };
    const handleChangeParticipantesUAD = value => {
        if (value == null) {
        } else {
            setParticipantesUAD(value);
        }
    };
    const FuncionLimpiar = async () => {
        setCasos(prevState => ({
            ...prevState,
            nCas_Id: "",
            nCli_Id: "",
            nEqu_ID: 1,
            cCas_Tab_Tipo: "CA01",
            cCas_Titulo: "",
            cCas_Cod_Externo: "",
            cCas_Cod_Interno: "2021-0008",
            cCas_Recurso_Num: "0203",
            cCas_Detalle: "",
            cCas_Expediente: "",
            nCas_Exp_Year: ano,
            cCas_Tab_Materia: "NN00",
            cCas_Tab_SubMateria: "NN00",
            nJuz_Id: "NN00",
            cCas_Tab_Distrito_Jud: "GA625",

            cCas_Tab_Jurisdiccion: "JU01",//Falta
            cCas_Tab_Sala: "SA01",//Falta
            cCas_Tab_Recurso_Tipo: "HA01",//Falta
            cCas_Tab_Comision: "CP01", //Falta
            cTab_Lugar_Tramite: "WE01",   //Falta
            cTab_Tipo_Acto: "0000", //Falta
            nPer_Id: 1,
            cCas_Referencia: "",
            cCas_Denominacion: "",
            cUbi_Org: "",
            dCas_Fec_Ini_Interno: ano + "-" + mes + "-" + dia,
            dCas_Fec_Ini_Procesal: ano + "-" + mes + "-" + dia,
            cCas_Tab_Estado_Externo: "",
            dCas_Fec_Estima_Cierre: moment(Fecha).format('yyyy-MM-DD'),
            cTab_Estado_Interno: "EP06",
            dFas_Fec_Cierre: "1970-01-01",
            nUsu_ID: getDecryptedItem("ID"),
            dUsu_Fecha: ano + "-" + mes + "-" + dia,
            bCas_Estado: 1,
            cTab_Moneda: "NN00",
            nCas_Monto: 0,
            nCas_MontoFormat: 0,
            nCas_MontoOld: 0,
            cTab_Tipo_Monto: 0,
            cName_Resolucion: "",
            nCas_HonorarioFijo: 0,
            nCas_HonorarioExito: 0,
            nCas_Origen: 0,
            TEXPEDIENTE: 0,
            LTRAMITE: 0,
            CodSalaSuprema: "",
            CodRecurso: "",
            CodNRecurso: "",
            CodAnoRecurso: "",
            CodNExpediente: "",
            CodAnoxpediente: "",
            CodDistritoOrigen: "",
            CodApePaterno: "",
            CodApeMaterno: "",
            CodNombre: "",
            nUsu_ID2: getDecryptedItem('NombreC'),
            selectComision: 0,
        }))
    }
    const FuncionSeleccionarTCuadernos = async () => {
        setcheckedMC(!checkedMC)
        var i
        if (checkedMC == true) {
            for (i = 0; i < document.f2.elements.length; i++)
                if (document.f2.elements[i].type == "checkbox")
                    document.f2.elements[i].checked = 0
        } else {
            for (i = 0; i < document.f2.elements.length; i++)
                if (document.f2.elements[i].type == "checkbox")
                    document.f2.elements[i].checked = 1
        }
    }
    const onKeyDown = (e) => {
        setValueAsunto(prevState => ({
            ...prevState,
            label: e.target.value,
            value: e.target.value,
        }))
        setCasos(prevState => ({
            ...prevState,
            cCas_Titulo1: e.target.value
        }))
        inputReference.current.focus();
        setErrores(Validacion(getCasos))
    };
    const handleChangeCasoAsunto = (valor) => {
        setCasos(prevState => ({
            ...prevState,
            cCas_Titulo1: valor,
        }))
        setErrores(Validacion(getCasos))
    };
    const FuncionActivarCej = async (row, value) => {
        var Arreglo = []
        if (document.getElementById("checkbox" + row).checked == true) {
            setreadOnly(true)
            await instance
                .post("/Casos/IDCASO", {
                    cCas_Cod_Externo: value,
                })
                .then(response => {
                    if (response.data.length < 1) {
                        setDisabled(true)
                        document.getElementById("idLoading" + row).style.display = "";
                        document.getElementById("idCheck" + row).style.display = "none";
                        document.getElementById("idClose" + row).style.display = "none";
                        Arreglo = getSeleccionarCuadernos.concat(value)
                        if (getdataGeneral.length > 0) {
                            if (getdataGeneral.find(arreglo => arreglo.CodigoExterno === value) == undefined) {
                                FuncionSincronizarCEJDatos(value, row);
                            } else if ([getdataGeneral.find(arreglo => arreglo.CodigoExterno === value)].length > 0) {
                                setTimeout(() => {
                                    document.getElementById("idLoading" + row).style.display = "none";
                                    document.getElementById("idCheck" + row).style.display = "";
                                    document.getElementById("idClose" + row).style.display = "none";
                                    setreadOnly(false)
                                    setDisabled(false)
                                }, 2000);
                            }
                        } else {
                            FuncionSincronizarCEJDatos(value, row);
                        }
                    } else {
                        Swal.fire({
                            title: "Mensaje",
                            text: "El caso " + value + " ya se encuentra registrado en el sistema",
                            icon: "warning",
                            confirmButtonText: "Aceptar",
                        })
                        document.getElementById("idLoading" + row).style.display = "none";
                        document.getElementById("idCheck" + row).style.display = "";
                        document.getElementById("idClose" + row).style.display = "none";
                        document.getElementById("checkbox" + row).checked = false;
                        setreadOnly(false)
                        if (disabled == false) {
                            setDisabled(false)
                        } else {
                            setDisabled(true)
                        }
                    }
                })
                .catch(error => {
                    document.getElementById("idLoading" + row).style.display = "none";
                    document.getElementById("idCheck" + row).style.display = "none";
                    document.getElementById("idClose" + row).style.display = "";
                    document.getElementById("checkbox" + row).checked = false;
                    setreadOnly(false)
                    Swal.fire({
                        title: "Mensaje",
                        text: 'Algo salió mal. Por favor, inténtelo de nuevo',
                        icon: "warning",
                        confirmButtonText: "Aceptar",
                    })
                })
        } else {
            document.getElementById("idLoading" + row).style.display = "none";
            document.getElementById("idCheck" + row).style.display = "none";
            if (getSeleccionarCuadernos.includes(value)) {
                document.getElementById("idLoading" + row).style.display = "none";
                document.getElementById("idCheck" + row).style.display = "none";
                Arreglo = getSeleccionarCuadernos.filter(elemento => elemento !== value)
                if (Arreglo.length < 1) {
                    setDisabled(!disabled)
                    setreadOnly(false)
                }
            }
        }
        setSeleccionarCuadernos(Arreglo)
    }
    const FuncionSincronizarCEJ = async (_cCas_Cod_Externo) => {
        setreadOnly(false)
        var noValido = /\s/;
        if (_cCas_Cod_Externo.trim() == "") {
            Swal.fire({
                title: "Mensaje",
                text: "Ingrese el número del expediente",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        }
        else if (noValido.test(_cCas_Cod_Externo)) {
            Swal.fire({
                title: "Mensaje",
                text: "Por favor evite los espacios en blanco",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        }
        else {
            setLoading(false)
            setDisabled(true)
            setDataCuadernos([])
            var postData = {
                CodigoExterno: _cCas_Cod_Externo,
                cFecha: Fecha.getDate() + " de " + meses[Fecha.getMonth()] + " de " + Fecha.getFullYear(),
            }
            await instance
                .post("/Vincular/CEJ/Cuadernos", postData)
                .then(response => {
                    FuncionLimpiar()
                    try {
                        var elem = document.getElementsByName("cCas_Tab_Materia")[0];
                        elem.removeAttribute("disabled");
                        var elem2 = document.getElementsByName("cCas_Tab_SubMateria")[0];
                        elem2.removeAttribute("disabled");
                    } catch (error) {
                    }
                    setCasos(prevState => ({
                        ...prevState,
                        cCas_Expediente: _cCas_Cod_Externo,
                        nCas_Exp_Year: _cCas_Cod_Externo.split("-")[1],
                    }))
                    Swal.fire({
                        title: "Mensaje",
                        text: "Registro encontrado",
                        icon: "success",
                        confirmButtonText: "Aceptar",
                    })
                    setLoading(true)
                    setDataCuadernos(response.data.DataCuadernos)
                    /*setDataGeneral(response.data.DataGneral)
                    setDataMovimientos(response.data.DataMovimienntos)*/
                    return response.data;
                })
                .catch(error => {
                    Swal.fire({
                        title: "Mensaje",
                        text:'Algo salió mal. Por favor, inténtelo de nuevo',
                        icon: "error",
                        confirmButtonText: "Aceptar",
                    })
                    setLoading(true)
                })
        }

    }
    const FuncionSincronizarCEJFiltro = async () => {
        setLoading(false)
        setDisabled(true)
        setDataCuadernos([])
        var _cCas_Cod_Externo = getCEJFiltro.distritoJudicial.concat('-', getCEJFiltro.organoJurisdiccional).concat('-', getCEJFiltro.especialidad).concat('-', getCEJFiltro.anio).concat('-', getCEJFiltro.numeroExpediente)
        var postData = {
            CodigoExterno: _cCas_Cod_Externo,
            cFecha: Fecha.getDate() + " de " + meses[Fecha.getMonth()] + " de " + Fecha.getFullYear(),
        }
        await instance
            .post("/Vincular/CEJ/Cuadernos", postData)
            .then(response => {
                FuncionLimpiar()
                try {
                    var elem = document.getElementsByName("cCas_Tab_Materia")[0];
                    elem.removeAttribute("disabled");
                    var elem2 = document.getElementsByName("cCas_Tab_SubMateria")[0];
                    elem2.removeAttribute("disabled");
                } catch (error) {
                }
                setCasos(prevState => ({
                    ...prevState,
                    cCas_Expediente: response.data.DataCuadernos[0].CodigoC,
                    nCas_Exp_Year: getCEJFiltro.anio,
                }))
                Swal.fire({
                    title: "Mensaje",
                    text: "Registro encontradro",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })
                setLoading(true)
                setDataCuadernos(response.data.DataCuadernos)
                /*setDataGeneral(response.data.DataGneral)
                setDataMovimientos(response.data.DataMovimienntos)*/
                return response.data;
            })
            .catch(error => {
                setDataCuadernos([])
                setDataGeneral([])
                setDataMovimientos([])
                setDataCuadernos([])
                Swal.fire({
                    title: "Mensaje",
                    text: error,
                    icon: "error",
                    confirmButtonText: "Aceptar",
                })
                setLoading(true)
            })
    }
    const FuncionSincronizarCEJDatos = async (_cCas_Cod_Externo, row) => {
        var postData = {
            CodigoExterno: _cCas_Cod_Externo,
            cFecha: Fecha.getDate() + " de " + meses[Fecha.getMonth()] + " de " + Fecha.getFullYear(),
            nUsu_ID: parseInt(getDecryptedItem("ID")),
        }
        await instance
            .post("/Vincular/CEJ", postData)
            .then(response => {

                try {
                    var elem = document.getElementsByName("cCas_Tab_Materia")[0];
                    elem.removeAttribute("disabled");
                    var elem2 = document.getElementsByName("cCas_Tab_SubMateria")[0];
                    elem2.removeAttribute("disabled");
                } catch (error) {
                }
                comboContext.FuncionListarMateria()
                comboContext.FuncionListarSubMateria()
                comboContext.FuncionListarEstadoExterno()
                var jsonGeneral1 = getdataGeneral;
                var jsonGeneral2 = response.data.DataGneral;
                jsonGeneral1 = jsonGeneral1.concat(jsonGeneral2);
                setDataGeneral(jsonGeneral1)
                var jsonMov1 = getdataMovimientos;
                var jsonMov2 = response.data.DataMovimienntos;
                jsonMov1 = jsonMov1.concat(jsonMov2);
                setDataMovimientos(jsonMov1)
                var jsonNof1 = getdataNotificacion;
                var jsonNof2 = response.data.DataNotificacion;
                jsonNof1 = jsonNof1.concat(jsonNof2);
                setDataNotificacion(jsonNof1)
                var jsonArch1 = getdataArchivos;
                var jsonArch2 = response.data.DataResoluciones;
                jsonArch1 = jsonArch1.concat(jsonArch2);
                setDataArchivos(jsonArch1)

                comboContext.FuncionListarEstadoExterno();
                setTimeout(() => {
                    setDisabled(false)
                    setreadOnly(false)
                    document.getElementById("idLoading" + row).style.display = "none";
                    document.getElementById("idCheck" + row).style.display = "";
                    document.getElementById("idClose" + row).style.display = "none";
                }, 1000);
            })
            .catch(error => {
                setDisabled(true)
                document.getElementById("idLoading" + row).style.display = "none";
                document.getElementById("idCheck" + row).style.display = "none";
                document.getElementById("idClose" + row).style.display = "";
                document.getElementById("checkbox" + row).checked = false;
                setreadOnly(false)
                Swal.fire({
                    title: "Mensaje",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                })
            })
    }
    const FuncionSincronizarIndecopi = async () => {
        setLoading(false)
        setDisabled(true)
        var Cod = "";
        var AREA = getCasos.nJuz_Id;
        var TEXPEDIENTE = getCasos.TEXPEDIENTE;
        var LTRAMITE = getCasos.LTRAMITE;
        var NEXPEDIENTE = getCasos.cCas_Expediente;
        var AÑO = getCasos.nCas_Exp_Year;
        Cod = NEXPEDIENTE + "-" + AÑO + "-" + AREA + "-" + TEXPEDIENTE + "-" + LTRAMITE
        var postData4 = {
            cod: Cod,
            comision: parseInt(getCasos.selectComision),
            opcion: parseInt(getCasos.selectOpcion1),
            opcion2: parseInt(getCasos.selectOpcion2),
        }
        await instance
            .post("/Vincular/Indecopi", postData4)
            .then(response => {
                FuncionLimpiar()
                setDisabled(false)
                setdenunciantes(response.data.denunciantes)
                setdenunciados(response.data.denunciados)
                setNotications(response.data.Notications)
                setCodigoIndecopi(Cod)
                setCodigoI(response.data.NExpediente[0].NExpediente)
                setDataCuadernosInsertar(response.data.denunciantes)
                setDataGeneralInsertar(response.data.NExpediente)
                setDataMovimientosInsertar(response.data.denunciados)
                setDataNotificacionInsertar(response.data.Notications)
                setCasos(prevState => ({
                    ...prevState,
                    cCas_Tab_Tipo: "CA05",
                    nCas_Origen: 1,
                }))
                setCasos(prevState => ({
                    ...prevState,
                    cCas_Cod_Externo: Cod,
                }))
                setCasos(prevState => ({
                    ...prevState,
                    cCas_Titulo: response.data.NExpediente[0].NExpediente,
                }))

                var elem = document.getElementsByName("cCas_Tab_Materia")[0];
                elem.setAttribute("disabled", "true");
                var elem2 = document.getElementsByName("cCas_Tab_SubMateria")[0];
                elem2.setAttribute("disabled", "true");
                setCasos(prevState => ({
                    ...prevState,
                    cCas_Tab_Materia: "MA08",
                }))
                setCasos(prevState => ({
                    ...prevState,
                    cCas_Tab_SubMateria: "NN00",
                }))
                setLoading(true)
            })
            .catch(error => {

                setLoading(true)
            })
    }
    const FuncionSincronizarIndecopiFiltro = async () => {
        setLoading(false)
        setDisabled(true)
        var Cod = "";
        if (getCasos.selectOpcion2 == '2') {
            Cod = getCasos.PApellidos + "-" + getCasos.PNombres
        } else {
            Cod = getCasos.RSocial
        }
        var postData4 = {
            cod: Cod,
            comision: parseInt(getCasos.selectComision),
            opcion: parseInt(getCasos.selectOpcion1),
            opcion2: parseInt(getCasos.selectOpcion2),
        }
        await instance
            .post("/Vincular/Indecopi", postData4)
            .then(response => {
                setDataCuadernos(response.data.DataExpediente)
                setDisabled(false)
                setLoading(true)
            })
            .catch(error => {

                setLoading(true)
            })
    }
    const FuncionSincronizarIndecopiUrl = async (_url, _comision, _i, _Cod) => {
        setDisabled(true)
        setreadOnly(true)
        document.getElementById("idLoading" + _i).style.display = "";
        document.getElementById("idCheck" + _i).style.display = "none";
        var postData4 = {
            url: _url,
            comision: parseInt(_comision),
        }
        await instance
            .post("/Vincular/Indecopi/Scraping", postData4)
            .then(response => {

                setdenunciantes(response.data.denunciantes)
                setdenunciados(response.data.denunciados)
                setNotications(response.data.Notications)
                setCodigoIndecopi(_Cod)
                setCodigoI(response.data.NExpediente[0].NExpediente)
                setDataCuadernosInsertar(response.data.denunciantes)
                setDataGeneralInsertar(response.data.NExpediente)
                setDataMovimientosInsertar(response.data.denunciados)
                setDataNotificacionInsertar(response.data.Notications)

                setCasos(prevState => ({
                    ...prevState,
                    cCas_Tab_Tipo: "CA05",
                    nCas_Origen: 1,
                }))
                setCasos(prevState => ({
                    ...prevState,
                    cCas_Cod_Externo: _Cod,
                }))
                setCasos(prevState => ({
                    ...prevState,
                    cCas_Titulo: response.data.NExpediente[0].NExpediente,
                }))

                var elem = document.getElementsByName("cCas_Tab_Materia")[0];
                elem.setAttribute("disabled", "true");
                var elem2 = document.getElementsByName("cCas_Tab_SubMateria")[0];
                elem2.setAttribute("disabled", "true");
                setCasos(prevState => ({
                    ...prevState,
                    cCas_Tab_Materia: "MA08",
                }))
                setCasos(prevState => ({
                    ...prevState,
                    cCas_Tab_SubMateria: "NN00",
                }))
                document.getElementById("idLoading" + _i).style.display = "none";
                document.getElementById("idCheck" + _i).style.display = "";
                document.getElementById("checkbox" + _i).checked = true;
                setreadOnly(true)
                setDisabled(false)
            })
            .catch(error => {
                setLoading(true)
            })
    }
    const FuncionSincronizarSupremo = async () => {
        setLoading(false)
        setDisabled(true)
        var postData = {
            CodSalaSuprema: getCasos.CodSalaSuprema,
            CodRecurso: getCasos.CodRecurso,
            CodNRecurso: getCasos.CodNRecurso,
            CodAnoRecurso: getCasos.CodAnoRecurso,
            CodNExpediente: getCasos.CodNExpediente,
            CodAnoxpediente: getCasos.CodAnoxpediente,
            CodDistritoOrigen: getCasos.CodDistritoOrigen,
            CodApePaterno: getCasos.CodApePaterno,
            CodApeMaterno: getCasos.CodApeMaterno,
            CodNombre: getCasos.CodNombre
        }
        await instance
            .post("/Vincular/Suprema", postData)
            .then(response => {
                FuncionLimpiar()
                Swal.fire({
                    title: "Mensaje",
                    text: "Registro encontradro",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })
                setLoading(true)
                setDisabled(false)
                setDataCuadernosInsertar(response.data.DataInformacion)
                setDataGeneralInsertar(response.data.DataPartesProcesales)
                setDataMovimientosInsertar(response.data.DataSeguimientoExpediente)
                setDataNotificacionInsertar(response.data.DataVistaCausas)
                comboContext.FuncionListarJuzgado()
                comboContext.FuncionListarEstadoExterno()
                setCasos(prevState => ({
                    ...prevState,
                    nCas_Origen: 3,
                    cCas_Cod_Externo: response.data.DataInformacion[0].NroExpediente,
                    cCas_Titulo: response.data.DataInformacion[0].Materia,
                }))
            })
            .catch(error => {
                Swal.fire({
                    title: "Mensaje",
                    text: 'Se ha producido un error. Inténtelo de nuevo más tarde.',
                    icon: "error",
                    confirmButtonText: "Aceptar",
                })
                setLoading(true)
                setDisabled(true)
            })
    }
    const FuncionAgregarCaso = async () => {
        if (HiddenIndecopi == "none") {
            if (getdataGeneralInsertar.length > 0) {
                const Test = getdataGeneralInsertar.map((row, i) => {
                    (function (index) {
                        if (i == 0) {
                            setTimeout(function () { FuncionRegistrarCaso(getCasos.cCas_Titulo, getCasos.cCas_Cod_Externo); }, i * 100);
                        } else {
                            setTimeout(function () { FuncionRegistrarCaso(row.General[0].Sumilla, row.CodigoExterno); }, i * 100);
                        }
                    })(i);
                })
            } else {
                FuncionRegistrarCaso(getCasos.cCas_Titulo, getCasos.cCas_Cod_Externo);
            }
        } else {
            FuncionRegistrarCaso(getCasos.cCas_Titulo, getCasos.cCas_Cod_Externo);
        }

        onCloseModal();
    }
    const FuncionRegistrarCaso = async (_cCas_Titulo, _cCas_Cod_Externo) => {
        Swal.fire({
            html:
                '<img id="imgr1" className="imgr" src="/static/media/logo-dark.7a9779c2.png" style="width:50px;height:auto;-webkit-animation: 3s rotate linear infinite;' +
                'animation: 3s rotate linear infinite;' +
                '-webkit-transform-origin: 50% 50%;' +
                'transform-origin: 50% 50%;" /> ' +
                '<br/><br/> Cargando...',
            showConfirmButton: false,
            allowOutsideClick: false
        });
        var postData = {
            nCli_Id: getCasos.nCli_Id,
            nEqu_ID: getCasos.nEqu_ID,
            cCas_Tab_Tipo: getCasos.cCas_Tab_Tipo,
            cCas_Titulo: _cCas_Titulo,
            cCas_Cod_Externo: _cCas_Cod_Externo,
            cCas_Cod_Interno: getCasos.cCas_Cod_Interno,
            cCas_Tab_Materia: getCasos.cCas_Tab_Materia,
            cCas_Tab_SubMateria: getCasos.cCas_Tab_SubMateria,
            cCas_Detalle: getCasos.cCas_Detalle,
            cCas_Tab_Jurisdiccion: getCasos.cCas_Tab_Jurisdiccion,
            cCas_Expediente: getCasos.cCas_Expediente,
            nCas_Exp_Year: getCasos.nCas_Exp_Year,
            nJuz_Id: getCasos.nJuz_Id,
            cCas_Tab_Sala: getCasos.cCas_Tab_Sala,
            cCas_Tab_Recurso_Tipo: getCasos.cCas_Tab_Recurso_Tipo,
            cCas_Recurso_Num: getCasos.cCas_Recurso_Num,
            cCas_Tab_Distrito_Jud: getCasos.cCas_Tab_Distrito_Jud,
            cCas_Tab_Comision: getCasos.cCas_Tab_Comision,
            cTab_Lugar_Tramite: getCasos.cTab_Lugar_Tramite,
            nPer_Id: getCasos.nPer_Id,
            cCas_Referencia: getCasos.cCas_Referencia,
            cCas_Denominacion: getCasos.cCas_Denominacion,
            dCas_Fec_Ini_Interno: getCasos.dCas_Fec_Ini_Interno,
            dCas_Fec_Ini_Procesal: getCasos.dCas_Fec_Ini_Procesal,
            cCas_Tab_Estado_Externo: getCasos.cCas_Tab_Estado_Externo,
            dCas_Fec_Estima_Cierre: getCasos.dCas_Fec_Estima_Cierre,
            cTab_Estado_Interno: getCasos.cTab_Estado_Interno,
            dFas_Fec_Cierre: getCasos.dFas_Fec_Cierre,
            nUsu_ID: getDecryptedItem("ID"),
            dUsu_Fecha: getCasos.dUsu_Fecha,
            bCas_Estado: getCasos.bCas_Estado,
            cUbi_Org: getCasos.cUbi_Org,
            cTab_Tipo_Acto: getCasos.cTab_Tipo_Acto,
            cTab_Moneda: getCasos.cTab_Moneda,
            nCas_Monto: getCasos.nCas_MontoOld,
            cTab_Tipo_Monto: getCasos.cTab_Tipo_Monto,
            cName_Resolucion: getCasos.cName_Resolucion,
            nCas_Origen: getCasos.nCas_Origen,
            nCas_HonorarioFijo: getCasos.nCas_HonorarioFijoOld,
            nCas_HonorarioExito: getCasos.nCas_HonorarioFijoOld,
            cCas_Titulo1: getCasos.cCas_Titulo1,
            cTab_MonedaHF: getCasos.cTab_MonedaHF,
            cTab_MonedaHE: getCasos.cTab_MonedaHE,
            nCas_URPs: getCasos.nCas_URPsOld,
            anfitrion: getUsuario.cUsu_Nombres,
            cMed_cau: getCasos.cMed_cau
        }
        casoContext.FuncionRegistrarCaso(postData, getdataCuadernosInsertar, getdataGeneralInsertar, getdataMovimientosInsertar, getdataNotificacionInsertar, getdataArchivosInsertar, GetParticipantesA, GetParticipantesAR, GetParticipantesUAD, GetParticipantesP, getMonedaAR, getMonedaA, getMonedaP, getMonedaUAD, getMontoAR.MontoOld, getMontoA.MontoOldA, getMontoP.MontoOldP, getMontoUAD.MontoOldUAD, Swal)
    }
    const UsuariosSelect = comboContext.cusuario.map((row, i) => ({
        value: row.nUsu_ID,
        label: row.cUsu_Nombres,
        email: row.cUsu_email,
        color: "#556ee6",
    }))
    const ClientesSelect = comboContext.clientes.map((row, i) => ({
        value: row.nCli_Id,
        label: row.cCli_NombreCompleto,
        color: "#556ee6",
    }))
    const OrgCompetenteSelect = comboContext.juzgado.map((row, i) => ({
        value: row.cTabCodigo,
        label: row.cTabNombre,
        color: "#556ee6",
    }))
    const Asuntos = casoContext.asunto.map((row, i) => ({
        value: row.cCas_Titulo1,
        label: row.cCas_Titulo1,
        color: "#556ee6",
    }))
    const EventoCabeceraTab = async param1 => {
        if (param1 == "1") {
            comboContext.FuncionResetClienteUltimo()
            setTab(param1)
            setActive1("active")
            setActiveN1("badge bg-danger rounded-pill")
            setActive2("")
            setActiveN2("badge bg-secondary rounded-pill")
            setActive3("")
            setActiveN3("badge bg-secondary rounded-pill")
            setHiddenFinalizar("none")
            setHiddenAnterior2("none")
            setHiddenAnterior1("none")
            setHiddenSiguiente3("none")
            setHiddenSiguiente2("")
        } else if (param1 == "2") {
            setTab(param1)
            if (getCasos.nCas_Origen == 0) {
                var ArregloC = []
                var ArregloG = []
                var ArregloM = []
                var ArregloN = []
                for (var i = 0; i < getSeleccionarCuadernos.length; i++) {
                    if (i == 0) {
                        var Array = [getdataGeneral.find(arreglo => arreglo.CodigoExterno === getSeleccionarCuadernos[i])]
                        for (var g = 0; g < Array.length; g++) {
                            var CodigoExterno = Array[g].CodigoExterno
                            var Sumilla = Array[g].General[0].Sumilla
                            var OrganoJurisdiccional = Array[g].General[0].OrganoJurisdiccional

                            var cTabCodigo = comboContext.estadoexterno.find(p => p.cTabNombre.toUpperCase() === Array[g].General[0].Estado.toUpperCase())?.cTabCodigo
                            var cTabCodigo2 = comboContext.materia.find(p => p.cTabNombre.toUpperCase() === Array[g].General[0].Especialidad.toUpperCase())?.cTabCodigo
                            var cTabCodigo3 = comboContext.submateria.find(p => p.cTabNombre.toUpperCase() === Array[g].General[0].Materia.toUpperCase())?.cTabCodigo

                            var cTabCodigo4 = Array[g].General[0].Ubicación

                            var division = Array[g].General[0].FechadeInicio.split("/");
                            setCasos(prevState => ({
                                ...prevState,
                                cCas_Cod_Externo: CodigoExterno,
                                cUbi_Org: cTabCodigo4,
                            }))
                            setCasos(prevState => ({
                                ...prevState,
                                cCas_Titulo: Sumilla,
                            }))
                            setCasos(prevState => ({
                                ...prevState,
                                cCas_Tab_Materia: cTabCodigo2,
                            }))
                            setCasos(prevState => ({
                                ...prevState,
                                cCas_Tab_SubMateria: cTabCodigo3,
                            }))
                            setCasos(prevState => ({
                                ...prevState,
                                cCas_Tab_Estado_Externo: cTabCodigo,
                            }))

                            var f1 = new Date(getCasos.dCas_Fec_Ini_Interno);
                            var f2 = new Date(division[2] + "-" + division[1] + "-" + division[0]);
                            var f3 = "";
                            if (f1 > f2) {
                                f3 = parseInt(ano + parseInt(1)) + "-" + mes + "-" + dia;
                            } else {
                                f3 = parseInt(division[2]) + parseInt(1) + "-" + division[1] + "-" + division[0];
                            }
                            setCasos(prevState => ({
                                ...prevState,
                                dCas_Fec_Ini_Procesal: division[2] + "-" + division[1] + "-" + division[0],
                                dCas_Fec_Estima_Cierre: f3,
                            }))
                            setCasos(prevState => ({
                                ...prevState,
                                cCas_Denominacion: OrganoJurisdiccional,
                            }))
                        }
                    }
                    ArregloC.push(getdataCuadernos.find(arreglo => arreglo.CodigoExterno === getSeleccionarCuadernos[i]))
                    ArregloG.push(getdataGeneral.find(arreglo => arreglo.CodigoExterno === getSeleccionarCuadernos[i]))
                    const Test = getdataMovimientos.map((row, j) => {
                        if (row.CodigoExterno == getSeleccionarCuadernos[i]) {
                            ArregloM.push(row)
                        }
                    })
                }

                setDataCuadernosInsertar(ArregloC)
                setDataGeneralInsertar(ArregloG)
                setDataMovimientosInsertar(ArregloM)
                setDataNotificacionInsertar(getdataNotificacion)
                setDataArchivosInsertar(getdataArchivos)
            }
            setActive1("")
            setActiveN1("badge bg-secondary rounded-pill")
            setActive2("active")
            setActiveN2("badge bg-danger rounded-pill")
            setActive3("")
            setActiveN3("badge bg-secondary rounded-pill")
            setHiddenFinalizar("none")
            setHiddenAnterior2("none")
            setHiddenSiguiente2("none")
            setHiddenAnterior1("")
            setHiddenSiguiente3("")
        } else {
            if (Object.keys(getErrores).length === 0) {
                setTab(param1)
                setActive1("")
                setActiveN1("badge bg-secondary rounded-pill")
                setActive2("")
                setActiveN2("badge bg-secondary rounded-pill")
                setActive3("active")
                setActiveN3("badge bg-danger rounded-pill")
                setHiddenFinalizar("")
                setHiddenAnterior2("")
                setHiddenSiguiente2("none")
                setHiddenAnterior1("none")
                setHiddenSiguiente3("none")
            } else {
                setValidData(true);
                Swal.fire({
                    title: "Mensaje",
                    text: "Complete los campos requeridos",
                    icon: "warning",
                    confirmButtonText: "Aceptar",
                })

            }
        }
    }
    /** Funcion listar tarifas */
    const FuncionListarTarifa = async () => {

        var postData = {
        }
        await instance
            .post("/Tarifa/listar", postData)
            .then(response => {

                setData(response.data)



            })
            .catch(error => {

            })
    }
    const handleChangeMoneda = e => {
        if (e.target.name == "cIng_Tab_MonedaA") {
            setMonedaA(e.target.value)
            setMontoA(prevState => ({
                ...prevState,
                MontoA: 0,
                MontoOldA: 0,
                MontoFormatA: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(0),
            }))
            for (let i = 0; i < data.length; i++) {
                if (data[i].cTar_Tab_Moneda == e.target.value && data[i].rol_nom == "Abogado") {
                    setMontoA(prevState => ({
                        ...prevState,
                        MontoA: data[i].nTar_Monto,
                        MontoOldA: data[i].nTar_Monto,
                        MontoFormatA: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(data[i].nTar_Monto),
                    }))
                }
                if (data.length == i && getMontoA.MontoA == 0) {
                    setMontoA(prevState => ({
                        ...prevState,
                        MontoA: 0,
                        MontoOldA: 0,
                        MontoFormatA: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(0),
                    }))
                }
            }
        }
        if (e.target.name == "cIng_Tab_MonedaAR") {
            setMonedaAR(e.target.value)
            setMontoAR(prevState => ({
                ...prevState,
                Monto: 0,
                MontoOld: 0,
                MontoFormat: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(0),
            }))
            for (let i = 0; i < data.length; i++) {
                if (data[i].cTar_Tab_Moneda == e.target.value && data[i].rol_nom == "Abogado Responsable") {
                    setMontoAR(prevState => ({
                        ...prevState,
                        Monto: data[i].nTar_Monto,
                        MontoOld: data[i].nTar_Monto,
                        MontoFormat: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(data[i].nTar_Monto),
                    }))
                }
                if (data.length == i && getMonto.Monto == 0) {
                    setMontoAR(prevState => ({
                        ...prevState,
                        Monto: 0,
                        MontoOld: 0,
                        MontoFormat: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(0),
                    }))
                }
            }
        }
        if (e.target.name == "cIng_Tab_MonedaP") {
            setMonedaP(e.target.value)
            setMontoP(prevState => ({
                ...prevState,
                MontoP: 0,
                MontoOldP: 0,
                MontoFormatP: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(0),
            }))
            for (let i = 0; i < data.length; i++) {
                if (data[i].cTar_Tab_Moneda == e.target.value && data[i].rol_nom == "Practicante") {
                    setMontoP(prevState => ({
                        ...prevState,
                        MontoP: data[i].nTar_Monto,
                        MontoOldP: data[i].nTar_Monto,
                        MontoFormatP: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(data[i].nTar_Monto),
                    }))
                }
                if (data.length == i && getMontoP.MontoP == 0) {
                    setMontoP(prevState => ({
                        ...prevState,
                        MontoP: 0,
                        MontoOldP: 0,
                        MontoFormatP: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(0),
                    }))
                }
            }
        }
        if (e.target.name == "cIng_Tab_MonedaUAD") {
            setMonedaUAD(e.target.value)
            setMontoUAD(prevState => ({
                ...prevState,
                MontoUAD: 0,
                MontoOldUAD: 0,
                MontoFormatUAD: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(0),
            }))
            for (let i = 0; i < data.length; i++) {
                if (data[i].cTar_Tab_Moneda == e.target.value && data[i].rol_nom == "Adicional") {
                    setMontoUAD(prevState => ({
                        ...prevState,
                        MontoUAD: data[i].nTar_Monto,
                        MontoOldUAD: data[i].nTar_Monto,
                        MontoFormatUAD: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(data[i].nTar_Monto),
                    }))
                }
                if (data.length == i && getMontoUAD.MontoUAD == 0) {
                    setMontoUAD(prevState => ({
                        ...prevState,
                        MontoUAD: 0,
                        MontoOldUAD: 0,
                        MontoFormatUAD: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(0),
                    }))
                }
            }
        }

    }
    /************** */
    const [getCEJFiltro, setCEJFiltro] = useState({
        distritoJudicial: "",
        organoJurisdiccional: "",
        especialidad: "",
        anio: "2022",
        numeroExpediente: "",
    });
    const handleChangeCEJFiltro = e => {
        const { name, value } = e.target
        setCEJFiltro(prevState => ({
            ...prevState,
            [name]: value,
        }))

    }
    const FuncionListarUsuario = async (_nUsu_ID) => {
        var postData = {
            nUsu_ID: _nUsu_ID,
        }
        await instance
            .post("/Usuario/Perfil", postData)
            .then(response => {

                setUsuario(response.data[0])
            })
            .catch(error => {

            })
    }
    const FnPlanPago = async () => {
        if (getCasos.nCas_HonorarioFijoOld == 0 || getCasos.cTab_MonedaHF == 'NN00') {
            Swal.fire({
                title: "Mensaje",
                text: "Debes de seleccionar una moneda o un monto mayor a 0",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        } else {
            Swal.fire({
                html: `Para poder acceder a esta funcionalidad, se tiene que guardar el caso. ¿Desea guardar el caso?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Si",
                cancelButtonText: "No",
            }).then(result => {
                if (result.isConfirmed) {
                    setEncryptedItem('Active_PlanPago','1');
                    FuncionAgregarCaso()
                }
            })
        }
    }
    useEffect(() => {
        /* Funciones para listar los Combos */
        let nUsu_ID = getDecryptedItem("ID")
        FuncionListarUsuario(nUsu_ID);
        FuncionListarComboMoneda()
        setErrores(Validacion(getCasos));
        comboContext.FuncionListarCliente()
        comboContext.FuncionListarJurisdiccion()
        comboContext.FuncionListarJuzgado()
        comboContext.FuncionListarMateria()
        comboContext.FuncionListarSubMateria()
        comboContext.FuncionListarEstadoExterno()
        comboContext.FuncionListarEstadoInterno()
        comboContext.FuncionListarUsuario()
        comboContext.FuncionListarMoneda()
        comboContext.FuncionListarLugarTramite()
        comboContext.FuncionListarArea()
        comboContext.FuncionListarTipoExpediente()
        comboContext.FuncionListarEquipo()
        casoContext.FuncionLimpiarAsuntos()
        FuncionListarTarifa();
        SendAnios();
        setParticipantesAR([{ value: getDecryptedItem("ID"), label: getDecryptedItem('NombreC'), email: getDecryptedItem('Correo') }]);
        /* ---------------- */
        setHiddenIndecopi("none")
        setHiddenCEJFiltro('none')
        setHiddenCEJ("")
        localStorage.removeItem("nCas_Id")
        localStorage.removeItem("CLIID")
    }, [])

    return (
        <Modal size="lg" isOpen={show} >
            <div
                className="modal-header ModalHeaderColor"
            >
                <h5
                    style={{ color: "#fff" }}
                    className="modal-title mt-0"
                    id="myModalLabel"
                >
                    Nuevo Caso
                </h5>
                <button
                    type="button"
                    onClick={() => onCloseClick()}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <Nav tabs className="nav-tabs-custom nav-justified">
                    <NavItem>
                        <NavLink
                            className={Active1}
                            style={{ cursor: "pointer" }}
                        >
                            Vincular proceso <span className={ActiveN1}>1</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={Active2}
                            style={{ cursor: "pointer" }}
                        >
                            Datos del proceso{" "}
                            <span className={ActiveN2}>2</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={Active3}
                            style={{ cursor: "pointer" }}
                        >
                            Datos adicionales{" "}
                            <span className={ActiveN3}>3</span>
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={Tab} className="p-3 text-muted">
                    <TabPane tabId="1">
                        <Row>
                            <Col sm="12">
                                <div className="mb-3 row">
                                    <div className="modal-body">
                                        <div className="mb-3 row">
                                            <div className="col-md-3"></div>
                                            <div className="col-md-6">
                                                <select
                                                    className="form-select inputDown"
                                                    name="cCas_Tab_Tipo"
                                                    onChange={handleChangeCaso}
                                                >
                                                    <option value="CA01" selected>
                                                        Corte Superior - Búsqueda por código
                                                        de expediente CEJ
                                                    </option>
                                                    <option value="CA02">
                                                        Corte Superior - Búsqueda por filtros
                                                        CEJ
                                                    </option>
                                                    <option value="CA03" >
                                                        Corte Suprema - Consultas de
                                                        expediente de CEJ Supremo
                                                    </option>
                                                    <option value="CA04">
                                                        INDECOPI - Búsqueda por Denunciante/Denunciado
                                                    </option>
                                                    <option value="CA05">
                                                        INDECOPI - Búsqueda por expediente
                                                    </option>
                                                    <option value="CA06">
                                                        Otros
                                                    </option>
                                                </select>
                                                <label className="col-form-label labelDown">
                                                    Jurisdicción o notaria
                                                </label>
                                            </div>
                                            <div className="col-md-3"></div>
                                        </div>
                                        <div id="CEJ" style={{ display: HiddenCEJ }}>
                                            <div className="mb-3 row">
                                                <div className="col-md-3"></div>
                                                <div className="col-md-5">
                                                    <input
                                                        className="form-control"
                                                        name="cCas_Expediente"
                                                        onChange={handleChangeCaso}
                                                        placeholder="Nro. de expediente" />
                                                    <label className="col-form-label labelDown">
                                                        Ingrese Nro. de expediente
                                                    </label>
                                                </div>
                                                <div className="col-md-2">
                                                    <a
                                                        style={{
                                                            color: "black",
                                                            fontSize: "20px",
                                                        }}
                                                        onClick={() =>
                                                            FuncionSincronizarCEJ(getCasos.cCas_Expediente)
                                                        }
                                                    >
                                                        {" "}
                                                        <i className="icon-Mundo" style={{ cursor: 'pointer' }} hidden={!Loading}></i>
                                                        <img
                                                            id="imgr2"
                                                            className="imgr"
                                                            src={logo}
                                                            style={{ width: '40px', height: "auto" }}
                                                            hidden={Loading}
                                                        />
                                                    </a>
                                                </div>
                                                <div className="col-md-2"></div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-md-2"></div>
                                                <div className="col-md-5">
                                                    <img src="https://cej.pj.gob.pe/cej/img/ejexpediente.png" style={{ width: "500px", height: "auto" }} />
                                                </div>
                                                <div className="col-md-2"></div>
                                            </div>
                                            {/* <Alert color="danger">
                                                <p style={{ marginTop: 20,color:'black' }}>Estimado <b>Usuario</b>:<br /><br />
                                                    Queremos informarle que nuestro sistema sigue presentando intermitencias debido a los cambios en seguridad ocurridos en el sitio web del <b>Consejo Ejecutivo del Poder Judicial (CEJ)</b> durante las últimas semanas. Esto impide tanto la actualización como la creación de nuevos procesos.
                                                    Nuestro equipo está trabajando arduamente y mantiene contacto con el Poder Judicial del Perú para resolver esta situación lo más pronto posible.
                                                    Sentimos cualquier molestia que esto pueda causarle.
                                                    Si tiene alguna pregunta, no dude en contactarnos a través de <b>soporte@gpslegal.pe</b>.
                                                </p>
                                            </Alert> */}
                                            <div className="mb-3 row" hidden>
                                                <div className="col-md-3"></div>
                                                <div className="col-md-6">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Año"
                                                        value={getCasos.nCas_Exp_Year}
                                                        name="nCas_Exp_Year"
                                                        onChange={handleChangeCaso}
                                                    />
                                                    <label className="col-form-label labelDown">
                                                        Año
                                                    </label>
                                                </div>
                                                <div className="col-md-3"></div>
                                            </div>
                                            <div className="mb-3 row" hidden>
                                                <div className="col-md-3"></div>
                                                <div className="col-md-6">
                                                    <select
                                                        className="form-select inputDown"
                                                        name="nJuz_Id"
                                                        onChange={handleChangeCaso}
                                                    >
                                                        <option value="NN00">
                                                            Seleccionar...
                                                        </option>
                                                        {comboContext.juzgado.map((row, i) => (
                                                            <option
                                                                key={i}
                                                                value={row.cTabCodigo}
                                                            >
                                                                {row.cTabNombre}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <label className="col-form-label labelDown">
                                                        Seleccione Juzgado
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-md-3"></div>
                                                <div className="col-md-6">
                                                    <form name="f2" hidden={!Loading}>
                                                        {(() => {
                                                            if (getdataCuadernos.length > 0 && HiddenCEJ == "") {
                                                                return (
                                                                    <>
                                                                        {getdataCuadernos.map((row, i) => {
                                                                            return (
                                                                                <div key={i} className="divGLRE0  row" style={{
                                                                                    justifyContentt: "center",
                                                                                    alignItems: "center",
                                                                                }}>
                                                                                    <div className="col-md-2">
                                                                                        <input
                                                                                            id={"checkbox" + i}
                                                                                            type="checkbox"
                                                                                            className="checkbox checkboxCuadernos"
                                                                                            value={row.CodigoExterno}
                                                                                            onClick={() => FuncionActivarCej(i, row.CodigoExterno)}
                                                                                            disabled={getreadOnly}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-md-8 container">
                                                                                        <div style={{
                                                                                            borderBottom: "1px dashed #CCC",
                                                                                            paddingRight: "5px",
                                                                                            width: "100%;",
                                                                                        }}>
                                                                                            <div style={{ display: "inline-table" }}>
                                                                                                <b style={{ fontSize: "12px" }}>{row.CodigoExterno}</b>
                                                                                            </div>
                                                                                            <div style={{ paddingTop: "5px" }}>
                                                                                                <p style={{ fontSize: "10px" }}>{row.Detalle}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-1">
                                                                                        <div style={{
                                                                                            paddingRight: "5px",
                                                                                            width: "100%;",
                                                                                        }}>
                                                                                            <img
                                                                                                id={"idLoading" + i}
                                                                                                className="imgr"
                                                                                                src={logo}
                                                                                                style={{ width: '40px', height: "auto", display: "none" }}
                                                                                            />
                                                                                            <FontAwesomeIcon id={"idCheck" + i} icon={faCheck} style={{ color: "#00A94F", fontSize: "20px", display: "none" }} />
                                                                                            <FontAwesomeIcon id={"idClose" + i} icon={faTimes} style={{ color: "#AA1B0E", fontSize: "20px", display: "none" }} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </>
                                                                )
                                                            }
                                                        })()}
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="CEJFiltro" style={{ display: HiddenCEJFiltro }}>
                                            <div className="mb-3 row">
                                                <div className="col-md-3"></div>
                                                <div className="col-md-5">
                                                    <select id="distritoJudicial" name="distritoJudicial" onChange={handleChangeCEJFiltro} className="form-select">
                                                        <option value="" selected>Seleccionar</option>
                                                        <option value="AMAZONAS">AMAZONAS</option>
                                                        <option value="ANCASH">ANCASH</option>
                                                        <option value="APURIMAC">APURIMAC</option>
                                                        <option value="AREQUIPA">AREQUIPA</option>
                                                        <option value="AYACUCHO">AYACUCHO</option>
                                                        <option value="CAJAMARCA">CAJAMARCA</option>
                                                        <option value="CALLAO">CALLAO</option>
                                                        <option value="CAÑETE">CAÑETE</option>
                                                        <option value="CUSCO">CUSCO</option>
                                                        <option value="DEL SANTA">DEL SANTA</option>
                                                        <option value="HUANCAVELICA">HUANCAVELICA</option>
                                                        <option value="HUANUCO">HUANUCO</option>
                                                        <option value="HUAURA">HUAURA</option>
                                                        <option value="ICA">ICA</option>
                                                        <option value="JUNIN">JUNIN</option>
                                                        <option value="LA LIBERTAD">LA LIBERTAD</option>
                                                        <option value="LAMBAYEQUE">LAMBAYEQUE</option>
                                                        <option value="LIMA">LIMA</option>
                                                        <option value="LIMA ESTE">LIMA ESTE</option>
                                                        <option value="LIMA NORTE">LIMA NORTE</option>
                                                        <option value="LIMA SUR">LIMA SUR</option>
                                                        <option value="LORETO">LORETO</option>
                                                        <option value="MADRE DE DIOS">MADRE DE DIOS</option>
                                                        <option value="MOQUEGUA">MOQUEGUA</option>
                                                        <option value="PASCO">PASCO</option>
                                                        <option value="PIURA">PIURA</option>
                                                        <option value="PUNO">PUNO</option>
                                                        <option value="SAN MARTIN">SAN MARTIN</option>
                                                        <option value="SELVA CENTRAL">SELVA CENTRAL</option>
                                                        <option value="SULLANA">SULLANA</option>
                                                        <option value="TACNA">TACNA</option>
                                                        <option value="TUMBES">TUMBES</option>
                                                        <option value="UCAYALI">UCAYALI</option>
                                                        <option value="VENTANILLA - LIMA NOROESTE">VENTANILLA - LIMA NOROESTE</option>
                                                    </select>
                                                    <label className="col-form-label labelDown">
                                                        Distrito judicial
                                                    </label>
                                                </div>
                                                <div className="col-md-2">
                                                    <a
                                                        style={{
                                                            color: "black",
                                                            fontSize: "20px",
                                                        }}
                                                        onClick={() =>
                                                            FuncionSincronizarCEJFiltro()
                                                        }
                                                    >
                                                        {" "}
                                                        <FontAwesomeIcon icon={faGlobe} hidden={!Loading} />
                                                        <img
                                                            id="imgr2"
                                                            className="imgr"
                                                            src={logo}
                                                            style={{ width: '40px', height: "auto" }}
                                                            hidden={Loading}
                                                        />
                                                    </a>
                                                </div>
                                                <div className="col-md-2"></div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-md-3"></div>
                                                <div className="col-md-5">
                                                    <select id="organoJurisdiccional" name="organoJurisdiccional" onChange={handleChangeCEJFiltro} className="form-select" >
                                                        <option value="" >--Seleccionar</option>
                                                        <option value="JUZGADO DE PAZ LETRADO">JUZGADO DE PAZ LETRADO</option>
                                                        <option value="JUZGADO ESPECIALIZADO">JUZGADO ESPECIALIZADO</option>
                                                        <option value="JUZGADO MIXTO">JUZGADO MIXTO</option>
                                                        <option value="SALA SUPERIOR">SALA SUPERIOR</option>
                                                    </select>
                                                    <label className="col-form-label labelDown">
                                                        Instancia
                                                    </label>
                                                </div>
                                                <div className="col-md-4">
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-md-3"></div>
                                                <div className="col-md-5">
                                                    <select id="especialidad" name="especialidad" onChange={handleChangeCEJFiltro} className="form-select" >
                                                        <option value="" >--Seleccionar</option>
                                                        <option value="CIVIL">CIVIL</option>
                                                        <option value="COMERCIAL">COMERCIAL</option>
                                                        <option value="DERECHO CONSTITUCIONAL">DERECHO CONSTITUCIONAL</option>
                                                        <option value="FAMILIA CIVIL">FAMILIA CIVIL</option>
                                                        <option value="FAMILIA TUTELAR">FAMILIA TUTELAR</option>
                                                        <option value="LABORAL">LABORAL</option>
                                                    </select>
                                                    <label className="col-form-label labelDown">
                                                        Especialidad
                                                    </label>
                                                </div>
                                                <div className="col-md-4">
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-md-3"></div>
                                                <div className="col-md-5">
                                                    <select
                                                        className="form-select inputDown"
                                                        onChange={handleChangeCEJFiltro}
                                                        name="anio"
                                                    >
                                                        {getAnioData.map((row, i) => (
                                                            <option key={i} value={row.anio}>
                                                                {row.anio}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <label className="col-form-label labelDown">
                                                        Año
                                                    </label>
                                                </div>
                                                <div className="col-md-4">
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-md-3"></div>
                                                <div className="col-md-5">
                                                    <input
                                                        className="form-control"
                                                        name="numeroExpediente"
                                                        onChange={handleChangeCEJFiltro}
                                                        placeholder="Nro. de expediente" />
                                                    <label className="col-form-label labelDown">
                                                        Ingrese Nro. de expediente
                                                    </label>
                                                </div>
                                                <div className="col-md-4">
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-md-3"></div>
                                                <div className="col-md-6">
                                                    <form name="f2" hidden={!Loading}>
                                                        {(() => {
                                                            if (getdataCuadernos.length > 0 && HiddenCEJFiltro == "") {
                                                                return (
                                                                    <>
                                                                        {getdataCuadernos.map((row, i) => {
                                                                            return (
                                                                                <div key={i} className="divGLRE0  row" style={{
                                                                                    justifyContentt: "center",
                                                                                    alignItems: "center",
                                                                                }}>
                                                                                    <div className="col-md-2">
                                                                                        <input
                                                                                            id={"checkbox" + i}
                                                                                            type="checkbox"
                                                                                            className="checkbox checkboxCuadernos"
                                                                                            value={row.CodigoExterno}
                                                                                            onClick={() => { FuncionActivarCej(i, row.CodigoExterno) }}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-md-8 container">
                                                                                        <div style={{
                                                                                            borderBottom: "1px dashed #CCC",
                                                                                            paddingRight: "5px",
                                                                                            width: "100%;",
                                                                                        }}>
                                                                                            <div style={{ display: "inline-table" }}>
                                                                                                <b style={{ fontSize: "12px" }}>{row.CodigoExterno}</b>
                                                                                            </div>
                                                                                            <div style={{ paddingTop: "5px" }}>
                                                                                                <p style={{ fontSize: "10px" }}>{row.Detalle}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-1">
                                                                                        <div style={{
                                                                                            paddingRight: "5px",
                                                                                            width: "100%;",
                                                                                        }}>
                                                                                            <img
                                                                                                id={"idLoading" + i}
                                                                                                className="imgr"
                                                                                                src={logo}
                                                                                                style={{ width: '40px', height: "auto", display: "none" }}
                                                                                            />
                                                                                            <FontAwesomeIcon id={"idCheck" + i} icon={faCheck} style={{ color: "#00A94F", fontSize: "20px", display: "none" }} />
                                                                                            <FontAwesomeIcon id={"idClose" + i} icon={faTimes} style={{ color: "#00A94F", fontSize: "20px", display: "none" }} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </>
                                                                )
                                                            }
                                                        })()}
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="INDECOPI" style={{ display: HiddenIndecopi }}>
                                            <div className="mb-3 row">
                                                <div className="col-md-3"></div>
                                                <div className="col-md-6">
                                                    <select
                                                        className="form-select inputDown"
                                                        name="selectComision"
                                                        onChange={handleChangeCaso}
                                                    >
                                                        <option value="000">
                                                            Seleccione
                                                        </option>
                                                        <option value="1">
                                                            Comisión de Protección al Consumidor
                                                        </option>
                                                        <option value="2">
                                                            Procedimientos Sumarísimos
                                                        </option>
                                                        <option value="3">
                                                            Comsión de Represión de la Competencia Desleal
                                                        </option>
                                                        <option value="4">
                                                            Comisión de Eliminación de Barreras Burocráticas
                                                        </option>
                                                    </select>
                                                    <label className="col-form-label labelDown">
                                                        Comision
                                                    </label>
                                                </div>
                                                <div className="col-md-2">
                                                    <a
                                                        style={{
                                                            color: "black",
                                                            fontSize: "20px",
                                                        }}
                                                        onClick={() =>
                                                            FuncionSincronizarIndecopi()
                                                        }
                                                    >
                                                        {" "}
                                                        <FontAwesomeIcon icon={faGlobe} hidden={!Loading} />
                                                        <img
                                                            id="imgr2"
                                                            className="imgr"
                                                            src={logo}
                                                            style={{ width: '40px', height: "auto" }}
                                                            hidden={Loading}
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                            <div id="Comision1" style={{ display: comision.comision1 }}>
                                                <div className="mb-3 row">
                                                    <div className="col-md-3"></div>
                                                    <div className="col-md-6">
                                                        <select
                                                            className="form-select inputDown"
                                                            name="nJuz_Id"
                                                            onChange={handleChangeCaso}
                                                        >
                                                            <option value="000">
                                                                Seleccione
                                                            </option>
                                                            {comboContext.area.map((row, i) => (
                                                                <option
                                                                    key={i}
                                                                    value={row.cTabNombre}
                                                                >
                                                                    {row.cTabNombre}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <label className="col-form-label labelDown">
                                                            Area
                                                        </label>
                                                    </div>
                                                    <div className="col-md-2">
                                                    </div>
                                                </div>
                                                <div className="mb-3 row">
                                                    <div className="col-md-3"></div>
                                                    <div className="col-md-6">
                                                        <select
                                                            className="form-select inputDown"
                                                            name="TEXPEDIENTE"
                                                            onChange={handleChangeCaso}
                                                        >
                                                            <option value="000">
                                                                Seleccione
                                                            </option>
                                                            {comboContext.texpediente.map((row, i) => (
                                                                <option
                                                                    key={i}
                                                                    value={row.cTabSimbolo}
                                                                >
                                                                    {row.cTabNombre}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <label className="col-form-label labelDown">
                                                            Tipo expediente
                                                        </label>
                                                    </div>
                                                    <div className="col-md-2"></div>
                                                </div>
                                                <div className="mb-3 row">
                                                    <div className="col-md-3"></div>
                                                    <div className="col-md-6">
                                                        <select
                                                            className="form-select inputDown"
                                                            name="LTRAMITE"
                                                            onChange={handleChangeCaso}
                                                        >
                                                            <option value="000">
                                                                Seleccione
                                                            </option>
                                                            {comboContext.lugartramite.map((row, i) => (
                                                                <option
                                                                    key={i}
                                                                    value={row.cTabSimbolo}
                                                                >
                                                                    {row.cTabNombre}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <label className="col-form-label labelDown">
                                                            Lugar de tramite
                                                        </label>
                                                    </div>
                                                    <div className="col-md-2"></div>
                                                </div>
                                            </div>
                                            <div id="Comision2" style={{ display: comision.comision2 }}>
                                                <div className="mb-3 row">
                                                    <div className="col-md-3"></div>
                                                    <div className="col-md-6">
                                                        <select
                                                            className="form-select inputDown"
                                                            name="nJuz_Id"
                                                            onChange={handleChangeCaso}
                                                        >
                                                            <option value="000">
                                                                Seleccione
                                                            </option>
                                                            <option value="PS0">PS0</option>
                                                            <option value="PS1">PS1</option>
                                                            <option value="PS2">PS2</option>
                                                            <option value="PS3">PS3</option>
                                                        </select>
                                                        <label className="col-form-label labelDown">
                                                            Area
                                                        </label>
                                                    </div>
                                                    <div className="col-md-2">
                                                    </div>
                                                </div>
                                                <div className="mb-3 row">
                                                    <div className="col-md-3"></div>
                                                    <div className="col-md-6">
                                                        <select
                                                            className="form-select inputDown"
                                                            name="TEXPEDIENTE"
                                                            onChange={handleChangeCaso}
                                                        >
                                                            <option value="000">
                                                                Seleccione
                                                            </option>
                                                            <option value="1">DENUNCIA</option>
                                                            <option value="13">INCUM.LIQUID.DE COSTAS Y COSTOS</option>
                                                            <option value="3">INCUMPL. DE ACUERDO CONCILIAT.</option>
                                                            <option value="17">INCUMPL. DE ACUERDO CONCILIATORIO SAC</option>
                                                            <option value="4">INCUMPL. DE MEDIDAS CORRECTIVAS</option>
                                                            <option value="12">LIQUIDACION DE COSTAS Y COSTOS</option>
                                                            <option value="14">SANCIONADOR POR EL ART. 5</option>
                                                            <option value="25">SANCIONADOR POR EL ART. 7</option>
                                                            <option value="23">SANCIONADOR POR INCUMPLIMIENTO MEDIDA CAUTELAR</option>
                                                        </select>
                                                        <label className="col-form-label labelDown">
                                                            Tipo expediente
                                                        </label>
                                                    </div>
                                                    <div className="col-md-2"></div>
                                                </div>
                                                <div className="mb-3 row">
                                                    <div className="col-md-3"></div>
                                                    <div className="col-md-6">
                                                        <select
                                                            className="form-select inputDown"
                                                            name="LTRAMITE"
                                                            onChange={handleChangeCaso}
                                                        >
                                                            <option value="000">
                                                                Seleccione
                                                            </option>
                                                            {comboContext.lugartramite.map((row, i) => (
                                                                <option
                                                                    key={i}
                                                                    value={row.cTabSimbolo}
                                                                >
                                                                    {row.cTabNombre}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <label className="col-form-label labelDown">
                                                            Lugar de tramite
                                                        </label>
                                                    </div>
                                                    <div className="col-md-2"></div>
                                                </div>
                                            </div>
                                            <div id="Comision3" style={{ display: comision.comision3 }}>
                                                <div className="mb-3 row">
                                                    <div className="col-md-3"></div>
                                                    <div className="col-md-6">
                                                        <select
                                                            className="form-select inputDown"
                                                            name="LTRAMITE"
                                                            onChange={handleChangeCaso}
                                                        >
                                                            <option value="000">
                                                                Seleccione
                                                            </option>
                                                            {comboContext.lugartramite.map((row, i) => (
                                                                <option
                                                                    key={i}
                                                                    value={row.cTabSimbolo}
                                                                >
                                                                    {row.cTabNombre}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <label className="col-form-label labelDown">
                                                            Lugar de tramite
                                                        </label>
                                                    </div>
                                                    <div className="col-md-2"></div>
                                                </div>
                                            </div>
                                            <div id="Comision4" style={{ display: comision.comision4 }}>
                                                <div className="mb-3 row">
                                                    <div className="col-md-3"></div>
                                                    <div className="col-md-6">
                                                        <select
                                                            className="form-select inputDown"
                                                            name="TEXPEDIENTE"
                                                            onChange={handleChangeCaso}
                                                        >
                                                            <option value="000">
                                                                Seleccione
                                                            </option>
                                                            <option value="1">DENUNCIA</option>
                                                            <option value="21">PROCEDIMIENTO SANCIONADOR</option>
                                                            <option value="36">PROCEDIMIENTO DE LIQUIDACION DE COSTAS Y COSTOS</option>
                                                            <option value="39">INCUMPLIMIENTO DE MANDATO</option>
                                                        </select>
                                                        <label className="col-form-label labelDown">
                                                            Tipo expediente
                                                        </label>
                                                    </div>
                                                    <div className="col-md-2"></div>
                                                </div>
                                                <div className="mb-3 row">
                                                    <div className="col-md-3"></div>
                                                    <div className="col-md-6">
                                                        <select
                                                            className="form-select inputDown"
                                                            name="LTRAMITE"
                                                            onChange={handleChangeCaso}
                                                        >
                                                            <option value="000">
                                                                Seleccione
                                                            </option>
                                                            {comboContext.lugartramite.map((row, i) => (
                                                                <option
                                                                    key={i}
                                                                    value={row.cTabSimbolo}
                                                                >
                                                                    {row.cTabNombre}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <label className="col-form-label labelDown">
                                                            Lugar de tramite
                                                        </label>
                                                    </div>
                                                    <div className="col-md-2"></div>
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-md-3"></div>
                                                <div className="col-md-6">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Nro. de expediente"
                                                        name="cCas_Expediente"
                                                        onChange={handleChangeCaso}
                                                    />
                                                    <label className="col-form-label labelDown">
                                                        Ingrese Nro. de expediente
                                                    </label>
                                                </div>
                                                <div className="col-md-2"></div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-md-3"></div>
                                                <div className="col-md-6">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Año"
                                                        name="nCas_Exp_Year"
                                                        onChange={handleChangeCaso}
                                                    />
                                                    <label className="col-form-label labelDown">
                                                        Año
                                                    </label>
                                                </div>
                                                <div className="col-md-2"></div>
                                            </div>
                                        </div>
                                        <div id="INDECOPIFILTRO" style={{ display: HiddenIndecopiFiltro }}>
                                            <div className="mb-3 row">
                                                <div className="col-md-3"></div>
                                                <div className="col-md-6">
                                                    <select
                                                        className="form-select inputDown"
                                                        name="selectComision"
                                                        onChange={handleChangeCaso}
                                                    >
                                                        <option value="000">
                                                            Seleccione
                                                        </option>
                                                        <option value="1">
                                                            Comisión de Protección al Consumidor
                                                        </option>
                                                        <option value="2">
                                                            Procedimientos Sumarísimos
                                                        </option>
                                                        <option value="3">
                                                            Comsión de Represión de la Competencia Desleal
                                                        </option>
                                                        <option value="4">
                                                            Comisión de Eliminación de Barreras Burocráticas
                                                        </option>
                                                    </select>
                                                    <label className="col-form-label labelDown">
                                                        Comision
                                                    </label>
                                                </div>
                                                <div className="col-md-2">
                                                    <a
                                                        style={{
                                                            color: "black",
                                                            fontSize: "20px",
                                                        }}
                                                        onClick={() =>
                                                            FuncionSincronizarIndecopiFiltro()
                                                        }
                                                    >
                                                        {" "}
                                                        <FontAwesomeIcon icon={faGlobe} hidden={!Loading} />
                                                        <img
                                                            id="imgr2"
                                                            className="imgr"
                                                            src={logo}
                                                            style={{ width: '40px', height: "auto" }}
                                                            hidden={Loading}
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-md-3"></div>
                                                <div className="col-md-6">
                                                    <select
                                                        className="form-select inputDown"
                                                        name="selectOpcion1"
                                                        onChange={handleChangeCaso}
                                                    >
                                                        <option value="000">
                                                            Seleccione
                                                        </option>
                                                        <option value="2">
                                                            Denunciado
                                                        </option>
                                                        <option value="3">
                                                            Denunciante
                                                        </option>
                                                    </select>
                                                    <label className="col-form-label labelDown">
                                                        Opcion 1
                                                    </label>
                                                </div>
                                                <div className="col-md-2">
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-md-3"></div>
                                                <div className="col-md-6">
                                                    <select
                                                        className="form-select inputDown"
                                                        name="selectOpcion2"
                                                        onChange={handleChangeCaso}
                                                    >
                                                        <option value="000">
                                                            Seleccione
                                                        </option>
                                                        <option value="1">
                                                            Persona Juridica
                                                        </option>
                                                        <option value="2">
                                                            Persona Natural
                                                        </option>
                                                    </select>
                                                    <label className="col-form-label labelDown">
                                                        Opcion 2
                                                    </label>
                                                </div>
                                                <div className="col-md-2">
                                                </div>
                                            </div>

                                            <div className="mb-3 row" style={{ display: comision.persona1 }}>
                                                <div className="col-md-3"></div>
                                                <div className="col-md-6">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Razón Sociaal"
                                                        name="RSocial"
                                                        onChange={handleChangeCaso}
                                                    />
                                                    <label className="col-form-label labelDown">
                                                        Razón Social
                                                    </label>
                                                </div>
                                                <div className="col-md-2"></div>
                                            </div>
                                            <div className="mb-3 row" style={{ display: comision.persona2 }}>
                                                <div className="col-md-3"></div>
                                                <div className="col-md-3">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Apellidos"
                                                        name="PApellidos"
                                                        onChange={handleChangeCaso}
                                                    />
                                                    <label className="col-form-label labelDown">
                                                        Apellidos
                                                    </label>
                                                </div>
                                                <div className="col-md-3">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Nombres"
                                                        name="PNombres"
                                                        onChange={handleChangeCaso}
                                                    />
                                                    <label className="col-form-label labelDown">
                                                        Nombres
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-md-3"></div>
                                                <div className="col-md-6">
                                                    {getdataCuadernos.map((row, i) => {
                                                        return (
                                                            <div key={i} className="divGLRE0  row" style={{
                                                                justifyContentt: "center",
                                                                alignItems: "center",
                                                            }}>
                                                                <div className="col-md-2">
                                                                    <input
                                                                        id={"checkbox" + i}
                                                                        type="checkbox"
                                                                        className="checkbox checkboxCuadernos"
                                                                        value={row.Expediente}
                                                                        onClick={() => FuncionSincronizarIndecopiUrl(row.href, getCasos.selectComision, i, row.Expediente)}
                                                                        disabled={getreadOnly}
                                                                    />
                                                                </div>
                                                                <div className="col-md-8 container">
                                                                    <div style={{
                                                                        borderBottom: "1px dashed #CCC",
                                                                        paddingRight: "5px",
                                                                        width: "100%;",
                                                                    }}>
                                                                        <div style={{ display: "inline-table" }}>
                                                                            <b style={{ fontSize: "12px" }}>{row.Expediente}</b>
                                                                        </div>
                                                                        <div style={{ paddingTop: "5px" }}>
                                                                            <p style={{ fontSize: "10px" }}>{row.Denunciado} / {row.FechaPresentacion}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-1">
                                                                    <div style={{
                                                                        paddingRight: "5px",
                                                                        width: "100%;",
                                                                    }}>
                                                                        <img
                                                                            id={"idLoading" + i}
                                                                            className="imgr"
                                                                            src={logo}
                                                                            style={{ width: '40px', height: "auto", display: "none" }}
                                                                        />
                                                                        <FontAwesomeIcon id={"idCheck" + i} icon={faCheck} style={{ color: "#00A94F", fontSize: "20px", display: "none" }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>

                                        <div id="CEJSUPREMO" style={{ display: HiddenSupremo }}>
                                            <div className="mb-3 row">
                                                <div className="col-md-2"></div>
                                                <div className="col-md-8">
                                                    <select name="CodSalaSuprema" value={getCasos && getCasos.CodSalaSuprema} onChange={handleChangeCaso} className="form-select inputDown">
                                                        <option selected="selected" value="101">SALA SUPREMA PENAL PERMANENTE</option>
                                                        <option value="102">PRIMERA SALA SUPREMA PENAL TRANSITORIA</option>
                                                        <option value="107">SEGUNDA SALA SUPREMA PENAL TRANSITORIA</option>
                                                        <option value="201">SALA SUPREMA CIVIL PERMANENTE</option>
                                                        <option value="202">SALA SUPREMA CIVIL TRANSITORIA</option>
                                                        <option value="203">SALA SUPREMA CONSTITUCIONAL Y SOCIAL PERMANENTE</option>
                                                        <option value="204">PRIMERA SALA SUPREMA CONSTITUCIONAL Y SOCIAL TRANSITORIA</option>
                                                        <option value="206">SEGUNDA SALA SUPREMA CONSTITUCIONAL Y SOCIAL TRANSITORIA</option>
                                                        <option value="207">TERCERA SALA SUPREMA CONSTITUCIONAL Y SOCIAL TRANSITORIA</option>
                                                        <option value="208">CUARTA SALA SUPREMA CONSTITUCIONAL Y SOCIAL TRANSITORIA</option>
                                                        <option value="209">QUINTA SALA SUPREMA CONSTITUCIONAL Y SOCIAL TRANSITORIA</option>
                                                    </select>
                                                    <label className="col-form-label labelDown">
                                                        Sala Suprema
                                                    </label>
                                                </div>
                                                <div className="col-md-2">
                                                    <a
                                                        style={{
                                                            color: "black",
                                                            fontSize: "20px",
                                                        }}
                                                        onClick={() =>
                                                            FuncionSincronizarSupremo()
                                                        }
                                                    >
                                                        {" "}
                                                        <FontAwesomeIcon icon={faGlobe} hidden={!Loading} />
                                                        <img
                                                            id="imgr2"
                                                            className="imgr"
                                                            src={logo}
                                                            style={{ width: '40px', height: "auto" }}
                                                            hidden={Loading}
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-md-2"></div>
                                                <div className="col-md-8">
                                                    <select name="CodRecurso" value={getCasos && getCasos.CodRecurso} onChange={handleChangeCaso} className="form-select inputDown">
                                                        <option value="%">-Seleccione un Motivo Ingreso-</option>
                                                        <option value="123">APELACION</option>
                                                        <option value="780">CASACION</option>
                                                        <option value="709">COMPETENCIA</option>
                                                        <option value="055">CONSULTA</option>
                                                        <option value="799">CONSULTA DIVERSA</option>
                                                        <option value="625">EXTRADICION</option>
                                                        <option value="126">INHIBICION</option>
                                                        <option value="139">NULIDAD</option>
                                                        <option value="798">QUEJA</option>
                                                        <option value="794">QUEJA DIRECTA</option>
                                                        <option value="795">QUEJA EXCEPCIONAL</option>
                                                        <option value="796">QUEJA NCPP</option>
                                                        <option value="Z01">QUEJA ORDINARIA</option>
                                                        <option value="167">RECUSACION</option>
                                                        <option value="784">REVISION DE MEDIDA DISCIPLINARIA</option>
                                                        <option value="785">REVISION DE MEDIDA DISCIPLINARIA NCPP</option>
                                                        <option value="781">REVISION DE SENTENCIA</option>
                                                        <option value="782">REVISION DE SENTENCIA DEL NCPP</option>
                                                        <option value="783">TRANSFERENCIA DE COMPETENCIA</option>
                                                        <option value="018">APELACIÓN CON EFECTO SUSPENSIVO</option>
                                                        <option value="034">APELACIÓN SIN EFECTO SUSPENSIVO</option>
                                                        <option value="A33">QUEJA DE APELACIÓN</option>
                                                        <option value="A34">QUEJA DE CASACIÓN</option>
                                                        <option value="A36">QUEJA DE NULIDAD</option>
                                                        <option value="ZZU">QUEJA DE QUEJA</option>
                                                        <option value="A37">QUEJA DE REVISIÓN EXTRAORDINARIA</option>
                                                        <option value="A39">QUEJA DE REVISIÓN</option>
                                                        <option value="ZZS">QUEJA LABORAL</option>
                                                        <option value="A40">REVISIÓN</option>
                                                        <option value="Z27">REVISIÓN DE MEDIDA DISCIPLINARIA</option>
                                                        <option value="Z24">REVISIÓN DE MEDIDA DISCIPLINARIA-APELACIÓN</option>
                                                        <option value="Z25">REVISIÓN DE MEDIDA DISCIPLINARIA-RECONSIDERACIÓN</option>
                                                        <option value="Z26">REVISIÓN DE MEDIDA DISCIPLINARIA-REVISIÓN</option>
                                                        <option value="Z22">AGRAVIO CONSTITUCIONAL</option>
                                                        <option value="A26">INHIBITORIA</option>
                                                        <option value="A27">MD APELACIÓN</option>
                                                        <option value="A29">MD QUEJA</option>
                                                        <option value="A30">MD RECONSIDERACIÓN</option>
                                                        <option value="A31">MD REVISIÓN</option>
                                                        <option value="Z23">MEDIDA DISCIPLINARIA</option>
                                                    </select>
                                                    <label className="col-form-label labelDown">
                                                        Tipo Recurso
                                                    </label>
                                                </div>
                                                <div className="col-md-2"></div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-md-2"></div>
                                                <div className="col-md-4">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Número de Recurso"
                                                        name="CodNRecurso" value={getCasos && getCasos.CodNRecurso} onChange={handleChangeCaso}
                                                    />
                                                    <label className="col-form-label labelDown">
                                                        Número de Recurso
                                                    </label>
                                                </div>
                                                <div className="col-md-4">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Año"
                                                        name="CodAnoRecurso" value={getCasos && getCasos.CodAnoRecurso} onChange={handleChangeCaso}
                                                    />
                                                    <label className="col-form-label labelDown">
                                                        Año
                                                    </label>
                                                </div>
                                                <div className="col-md-2"></div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-md-2"></div>
                                                <div className="col-md-4">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Nro. Expediente Procedencia"
                                                        name="CodNExpediente" value={getCasos && getCasos.CodNExpediente} onChange={handleChangeCaso}
                                                    />
                                                    <label className="col-form-label labelDown">
                                                        Nro. Expediente Procedencia
                                                    </label>
                                                </div>
                                                <div className="col-md-2">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Año"
                                                        name="CodAnoxpediente" value={getCasos && getCasos.CodAnoxpediente} onChange={handleChangeCaso}
                                                    />
                                                    <label className="col-form-label labelDown">
                                                        Año
                                                    </label>
                                                </div>
                                                <div className="col-md-2">
                                                    <select name="CodDistritoOrigen" value={getCasos && getCasos.CodDistritoOrigen} onChange={handleChangeCaso} className="form-select inputDown" >
                                                        <option selected="selected" value="%">-Seleccione Distrito Origen-</option>
                                                        <option value="01">AMAZONAS</option>
                                                        <option value="02">ANCASH</option>s
                                                        <option value="03">APURIMAC</option>
                                                        <option value="04">AREQUIPA</option>
                                                        <option value="05">AYACUCHO</option>
                                                        <option value="06">CAJAMARCA</option>
                                                        <option value="07">CALLAO</option>
                                                        <option value="08">CAÑETE</option>
                                                        <option value="10">CUZCO</option>
                                                        <option value="25">DEL SANTA</option>
                                                        <option value="11">HUANCAVELICA</option>
                                                        <option value="12">HUANUCO</option>
                                                        <option value="13">HUAURA</option>
                                                        <option value="14">ICA</option>
                                                        <option value="15">JUNIN</option>
                                                        <option value="16">LA LIBERTAD</option>
                                                        <option value="17">LAMBAYEQUE</option>
                                                        <option value="18">LIMA</option>
                                                        <option value="32">LIMA ESTE</option>
                                                        <option value="09">LIMA NORTE</option>
                                                        <option value="30">LIMA SUR</option>
                                                        <option value="19">LORETO</option>
                                                        <option value="27">MADRE DE DIOS</option>
                                                        <option value="28">MOQUEGUA</option>
                                                        <option value="29">PASCO</option>
                                                        <option value="20">PIURA</option>
                                                        <option value="21">PUNO</option>
                                                        <option value="50">SALA PENAL NACIONAL</option>
                                                        <option value="22">SAN MARTIN</option>
                                                        <option value="34">SELVA CENTRAL</option>
                                                        <option value="31">SULLANA</option>
                                                        <option value="23">TACNA</option>
                                                        <option value="26">TUMBES</option>
                                                        <option value="24">UCAYALI</option>
                                                        <option value="33">VENTANILLA</option>
                                                    </select>
                                                    <label className="col-form-label labelDown">
                                                        Distrito Origen
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-md-2"></div>
                                                <div className="col-md-4">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Ap. Paterno ó Razón Social"
                                                        name="CodApePaterno" value={getCasos && getCasos.CodApePaterno} onChange={handleChangeCaso}
                                                    />
                                                    <label className="col-form-label labelDown">
                                                        Ap. Paterno ó Razón Social
                                                    </label>
                                                </div>
                                                <div className="col-md-4">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Apellido Materno"
                                                        name="CodApeMaterno" value={getCasos && getCasos.CodApeMaterno} onChange={handleChangeCaso}
                                                    />
                                                    <label className="col-form-label labelDown">
                                                        Apellido Materno
                                                    </label>
                                                </div>
                                                <div className="col-md-2">
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-md-2"></div>
                                                <div className="col-md-6">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Nombre (s)"
                                                        name="CodNombre" value={getCasos && getCasos.CodNombre} onChange={handleChangeCaso}
                                                    />
                                                    <label className="col-form-label labelDown">
                                                        Nombre (s)
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="2">
                        <Row>
                            <Col sm="12">
                                <div className="mb-3 row">
                                    <div className="modal-body">
                                        <div className="mb-3 row">
                                            <strong>Tipo de Caso</strong>
                                            <hr style={{ color: "#000" }} />
                                            <div className="col-md-7">
                                                <input
                                                    className={getErrores.cCas_Titulo && validData ? "invalid form-control" : "form-control"}
                                                    type="text"
                                                    placeholder="Titulo del caso"
                                                    name="cCas_Titulo"
                                                    value={getCasos && getCasos.cCas_Titulo}
                                                    onChange={handleChangeCaso}
                                                    onBlur={BluerChange}
                                                />
                                                <label className="col-form-label labelDown">Ingrese Titulo del caso</label>{getErrores.cCas_Titulo && <label className="col-form-label labelDown labelRed">({getErrores.cCas_Titulo})</label>}
                                            </div>
                                            <div className="col-md-5">
                                                <input
                                                    className={getErrores.cCas_Cod_Externo && validData ? "invalid form-control" : "form-control"}
                                                    type="text"
                                                    placeholder="Código externo"
                                                    name="cCas_Cod_Externo"
                                                    onChange={handleChangeCaso}
                                                    onBlur={BluerChange}
                                                    value={getCasos && getCasos.cCas_Cod_Externo}
                                                />
                                                <label className="col-form-label labelDown">Código externo</label>{getErrores.cCas_Cod_Externo && <label className="col-form-label labelDown labelRed">({getErrores.cCas_Cod_Externo})</label>}
                                            </div>
                                        </div>
                                        <div className="mb-3 row">

                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-md-6">
                                                <select
                                                    className={getErrores.cCas_Tab_Materia && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                                                    name="cCas_Tab_Materia"
                                                    onChange={handleChangeCaso}
                                                    onBlur={BluerChange}
                                                    value={getCasos && getCasos.cCas_Tab_Materia}
                                                >
                                                    <option value="NN00">
                                                        Seleccionar...
                                                    </option>
                                                    {comboContext.materia.map((row, i) => (
                                                        <option
                                                            key={i}
                                                            value={row.cTabCodigo}
                                                        >
                                                            {row.cTabNombre}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label className="col-form-label labelDown">Seleccione Especialidad</label>{getErrores.cCas_Tab_Materia && <label className="col-form-label labelDown labelRed">({getErrores.cCas_Tab_Materia})</label>}
                                            </div>
                                            <div className="col-md-6">
                                                <select
                                                    className={getErrores.cCas_Tab_SubMateria && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                                                    name="cCas_Tab_SubMateria"
                                                    onChange={handleChangeCaso}
                                                    onBlur={BluerChange}
                                                    value={getCasos && getCasos.cCas_Tab_SubMateria}
                                                >
                                                    <option value="NN00">
                                                        Seleccionar...
                                                    </option>
                                                    {comboContext.submateria.map((row, i) => (
                                                        <option
                                                            key={i}
                                                            value={row.cTabCodigo}
                                                        >
                                                            {row.cTabNombre}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label className="col-form-label labelDown">Seleccione Materia</label>{getErrores.cCas_Tab_SubMateria && <label className="col-form-label labelDown labelRed">({getErrores.cCas_Tab_SubMateria})</label>}
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-md-12">
                                                <textarea
                                                    className="form-control"
                                                    type="text"
                                                    maxLength="500"
                                                    name="cCas_Detalle"
                                                    onChange={handleChangeCaso}
                                                    value={getCasos && getCasos.cCas_Detalle}
                                                ></textarea>
                                                <label className="col-form-label labelDown">
                                                    Ingrese Detalle
                                                </label>
                                            </div>
                                        </div>
                                        <strong style={{ color: "#AA1B0E" }}>
                                            Cliente
                                        </strong>
                                        <hr
                                            style={{
                                                color: "#AA1B0E",
                                                marginTop: "0px",
                                            }}
                                        />
                                        <div className="mb-3 row">
                                            <div className="col-md-4">
                                                <select className="form-select" name="tipocliente" onChange={handleChangeTCliente} value={getTipoCliente}>
                                                    <option value="1">
                                                        Persona Jurídica
                                                    </option>
                                                    <option value="0" >
                                                        Persona Natural
                                                    </option>
                                                </select>
                                                <label className="col-form-label labelDown">
                                                    Seleccione tipo de cliente
                                                </label>
                                            </div>
                                            <div className="col-md-7">
                                                <Select
                                                    className={getErrores.nCli_Id && validData ? "invalid  " : ""}
                                                    onChange={handleChangeCliente}
                                                    onBlur={BluerChange}
                                                    options={ClientesSelect}
                                                    value={getCliente}
                                                    placeholder="Cliente..."
                                                />
                                                <label className="col-form-label labelDown">Seleccione Cliente</label>{getErrores.nCli_Id && <label className="col-form-label labelDown labelRed">({getErrores.nCli_Id})</label>}
                                            </div>
                                            <div className="col-md-1" style={{ top: "7px" }}>
                                                <i className="icon-nuevo" style={{ fontSize: "15px" }} onClick={() => setModalInsertarCliente(!modalInsertarCliente)}></i>
                                                {(() => {
                                                    if (modalInsertarCliente == true) {
                                                        return <><ModalCliente show={modalInsertarCliente}
                                                            onCloseClick={() => setModalInsertarCliente(!modalInsertarCliente)}
                                                            ReloadLista={() => {
                                                                comboContext.FuncionListarCliente2(null, setCliente, setCasos)
                                                            }}
                                                            TipoCliente={getTipoCliente}
                                                            Titulo="Nuevo Cliente"
                                                        />
                                                        </>
                                                    }
                                                })()}
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-md-7">
                                                <SelectSearch
                                                    id="cCas_Titulo1"
                                                    classstyle={getErrores.cCas_Titulo1 && validData ? "invalid  form-select" : "form-select"}
                                                    array={Asuntos}
                                                    value={getValueAsunto}
                                                    placeholder={"Buscar o Agregar Asunto"}
                                                    click={handleChangeCasoAsunto}
                                                    isAddItem={true}
                                                    onKeyDown={onKeyDown}
                                                />
                                                <label className="col-form-label labelDown">Ingrese Asunto del caso</label>{getErrores.cCas_Titulo1 && <label className="col-form-label labelDown labelRed">(*)</label>}
                                            </div>
                                            <div className="col-md-5">
                                                <input
                                                    ref={inputReference}
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="..."
                                                    name="cCas_Referencia"
                                                    onChange={handleChangeCaso}
                                                />
                                                <label className="col-form-label labelDown">
                                                    Referencia de contrato anterior
                                                </label>
                                            </div>
                                        </div>
                                        <strong>
                                            Información del organismo donde se tramitará
                                        </strong>
                                        <hr
                                            style={{ color: "#000", marginTop: "0px" }}
                                        />
                                        <div className="mb-3 row ">
                                            <div className="col-md-6">
                                                <SelectSearch
                                                    id="cCas_Tab_Distrito_Jud"
                                                    classstyle={getErrores.cCas_Tab_Distrito_Jud && validData ? "invalid  form-select" : "form-select"}
                                                    array={OrgCompetenteSelect}
                                                    value={{
                                                        label: 'Otros',
                                                        value: 'GA625',
                                                    }}
                                                    placeholder={"Buscar Órgano competente..."}
                                                    click={handleChangeOrganoCom}
                                                    isAddItem={false}
                                                />
                                                <label className="col-form-label labelDown">Órgano competente</label>{getErrores.cCas_Tab_Distrito_Jud && <label className="col-form-label labelDown labelRed">({getErrores.cCas_Tab_Distrito_Jud})</label>}
                                            </div>
                                            <div className="col-md-6">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="cCas_Denominacion"
                                                    onChange={handleChangeCaso}
                                                    defaultValue={getCasos && getCasos.cCas_Denominacion}
                                                />
                                                <label className="col-form-label labelDown">
                                                    Denominación
                                                </label>
                                            </div>
                                        </div>
                                        <div className="mb-3 row ">
                                            <div className="col-md-7">
                                                <input
                                                    name="cUbi_Org"
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Ubicación del expediente"
                                                    onChange={handleChangeCaso}
                                                    value={getCasos && getCasos.cUbi_Org}
                                                />
                                                <label className="col-form-label labelDown">
                                                    Ubicación del expediente
                                                </label>
                                            </div>
                                            <div className="col-md-7">
                                                <input
                                                    name="cMed_cau"
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Código cautelar"
                                                    onChange={handleChangeCaso}
                                                    value={getCasos && getCasos.cMed_cau}
                                                    maxLength={6}
                                                />
                                                <label className="col-form-label labelDown">
                                                    Código cautelar
                                                </label>
                                            </div>
                                        </div>
                                        <strong>Estado</strong>
                                        <hr
                                            style={{ color: "#000", marginTop: "0px" }}
                                        />
                                        <div className="mb-3 row ">
                                            <div className="col-md-6">
                                                <input
                                                    className="form-control"
                                                    type="date"
                                                    value={getCasos.dCas_Fec_Ini_Interno}
                                                    name="dCas_Fec_Ini_Interno"
                                                    onChange={handleChangeCaso}
                                                />
                                                <label className="col-form-label labelDown">
                                                    Fecha de inicio
                                                </label>
                                            </div>
                                            <div className="col-md-6">
                                                <input
                                                    className="form-control"
                                                    type="date"
                                                    value={getCasos.dCas_Fec_Ini_Procesal}
                                                    name="dCas_Fec_Ini_Procesal"
                                                    onChange={handleChangeCaso}
                                                />
                                                <label className="col-form-label labelDown">
                                                    Fecha de inicio procesal
                                                </label>
                                            </div>
                                        </div>
                                        <div className="mb-3 row ">
                                            <div className="col-md-6">
                                                <select
                                                    className="form-select inputDown"
                                                    name="cCas_Tab_Estado_Externo"
                                                    value={getCasos.cCas_Tab_Estado_Externo}
                                                    onChange={handleChangeCaso}
                                                >
                                                    <option value="NN00">
                                                        Seleccionar...
                                                    </option>
                                                    {comboContext.estadoexterno.map(
                                                        (row, i) => (
                                                            <option
                                                                key={i}
                                                                value={row.cTabCodigo}
                                                            >
                                                                {row.cTabNombre}
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                                <label className="col-form-label labelDown">
                                                    Estado externo
                                                </label>
                                            </div>
                                            <div className="col-md-6">
                                                <input
                                                    className="form-control"
                                                    type="date"
                                                    name="dCas_Fec_Estima_Cierre"
                                                    onChange={handleChangeCaso}
                                                    value={getCasos.dCas_Fec_Estima_Cierre}
                                                />
                                                <label className="col-form-label labelDown">
                                                    Fecha estimada de cierre
                                                </label>
                                            </div>
                                        </div>
                                        <div className="mb-3 row ">
                                            <div className="col-md-6">
                                                <select
                                                    className="form-select inputDown"
                                                    name="cTab_Estado_Interno"
                                                    onChange={handleChangeCaso}
                                                    value={getCasos.cTab_Estado_Interno}
                                                >
                                                    <option value="NN00">
                                                        Seleccionar...
                                                    </option>
                                                    {comboContext.estadointerno.map(
                                                        (row, i) => (
                                                            <option
                                                                key={i}
                                                                value={row.cTabCodigo}
                                                            >
                                                                {row.cTabNombre}
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                                <label className="col-form-label labelDown">
                                                    Estado Interno
                                                </label>
                                            </div>
                                            <div className="col-md-6">
                                                <input
                                                    className="form-control"
                                                    type="date"
                                                    name="dFas_Fec_Cierre"
                                                    onChange={handleChangeCaso}
                                                />
                                                <label className="col-form-label labelDown">
                                                    Fecha de cierre
                                                </label>
                                            </div>
                                        </div>
                                        <strong>Cuantía</strong>
                                        <hr
                                            style={{ color: "#000", marginTop: "0px" }}
                                        />
                                        <div className="mb-3 row ">
                                            <div className="col-md-3">
                                                <div className="col-md-3" >
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="customCheckcolor1"
                                                        onClick={() => handleChangeCuantia()}
                                                        style={{ transform: "scale(1.5)", marginTop: "10px" }}
                                                    />
                                                    <label className="col-form-label " style={{ position: "absolute", paddingLeft: "10px" }}>
                                                        Indeterminado
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <select
                                                    className="form-select inputDown" onChange={handleChangeCaso}
                                                    name="cTab_Moneda" disabled={!getCuantia}
                                                    value={getCasos.cTab_Moneda}
                                                >
                                                    <option value="NN00">Seleccionar</option>
                                                    {comboContext.moneda.map((row, i) => (
                                                        <option key={i} value={row.cTabCodigo}>
                                                            {row.cTabNombre}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label className="col-form-label labelDown">
                                                    Seleccione Moneda
                                                </label>
                                            </div>
                                            <div className="col-md-3">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    onChange={handleChangeCaso}
                                                    onBlur={BluerChange}
                                                    onFocus={FocusChange}
                                                    name="nCas_Monto"
                                                    placeholder="Monto"
                                                    value={getCasos.nCas_Monto}
                                                    style={{ textAlign: 'right' }}
                                                    disabled={!getCuantia}
                                                />
                                                <label className="col-form-label labelDown">
                                                    Ingrese Monto
                                                </label>
                                            </div>
                                            <div className="col-md-3">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    onChange={handleChangeCaso}
                                                    onBlur={BluerChange}
                                                    onFocus={FocusChange}
                                                    name="nCas_URPs"
                                                    placeholder="Monto"
                                                    value={getCasos.nCas_URPs}
                                                    style={{ textAlign: 'right' }}
                                                    disabled={!getCuantia}
                                                />
                                                <label className="col-form-label labelDown">
                                                    URPs
                                                </label>
                                            </div>
                                            <div className="col-md-3">
                                                <select
                                                    className="form-select inputDown"
                                                    onChange={handleChangeCaso}
                                                    name="cTab_Tipo_Monto"
                                                    value={getCasos.cTab_Tipo_Monto}
                                                >
                                                    <option value="0">Seleccionar</option>
                                                    <option value="1">A Favor</option>
                                                    <option value="2">En contra</option>
                                                </select>
                                                <label className="col-form-label labelDown">
                                                    Seleccione Tipo
                                                </label>
                                            </div>
                                        </div>
                                        <strong>Informacion del sistema</strong>
                                        <hr
                                            style={{ color: "#000", marginTop: "0px" }}
                                        />
                                        <div className="mb-3 row ">
                                            <div className="col-md-6">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Creado por"
                                                    name="nUsu_ID"
                                                    value={getCasos.nUsu_ID2}
                                                    disabled
                                                />
                                                <label className="col-form-label labelDown">
                                                    Creado por
                                                </label>
                                            </div>
                                            <div className="col-md-6">
                                                <input
                                                    className="form-control"
                                                    type="date"
                                                    value={getCasos.dUsu_Fecha}
                                                    name="dUsu_Fecha"
                                                    disabled
                                                />
                                                <label className="col-form-label labelDown">
                                                    Fecha de creacion
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="3">
                        <Row>
                            <Col sm="12">
                                <CardText className="mb-0">
                                    <div className="mb-3 row">
                                        <div className="modal-body">
                                            <div className="mb-3 row" >
                                                <strong>Equipo</strong>
                                                <hr style={{ color: "#000" }} />
                                                <div className="col-md-3" hidden>
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="customCheckcolor1"
                                                        onChange={handleChangeCheck}
                                                        checked={cheked}
                                                        value={cheked}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="customCheckcolor1"
                                                        style={{ paddingLeft: "5px" }}
                                                    >
                                                        Asignar Equipo
                                                    </label>
                                                </div>
                                                <div className="col-md-3" hidden>
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id="customCheckcolor1"
                                                        onChange={handleChangeCheck2}
                                                        checked={cheked2}
                                                        value={cheked2}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="customCheckcolor1"
                                                        style={{ paddingLeft: "5px" }}
                                                    >
                                                        Asignar personas
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-4 col-form-label"
                                                ></label>
                                                <div
                                                    className="col-md-6"
                                                    style={{ display: HiddenEquipoCbo }}
                                                >
                                                    <select
                                                        className="form-select inputDown"
                                                        name="nCas_Id"
                                                    >
                                                        <option value="0">
                                                            Seleccionar...
                                                        </option>
                                                        {comboContext.cequipo.map((row, i) => (
                                                            <option key={i} value={row.nEqu_ID}>
                                                                {row.cEqu_Nombre}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <label className="col-form-label labelDown">
                                                        Seleccione equipo
                                                    </label>
                                                </div>
                                            </div>
                                            <div style={{ display: HiddenEquipo }}>
                                                <div className="mb-3 row">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-3 col-form-label"
                                                        style={{ marginTop: '21px' }}
                                                    >
                                                        Abogado(s) responsable
                                                    </label>
                                                    <div className="col-md-4" style={{ marginTop: '21px' }}>
                                                        <Select
                                                            isMulti
                                                            onChange={handleChangeParticipantesAR}
                                                            name="colors"
                                                            options={UsuariosSelect}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            placeholder="Abogados responsables..."
                                                            defaultValue={GetParticipantesAR}
                                                        />
                                                        <label className="col-form-label labelDown">
                                                            Abogado(s) responsable
                                                        </label>
                                                    </div>
                                                    <div className="col-md-2" style={{ width: "140px", paddingRight: "5px", paddingLeft: "0px" }}>
                                                        <label className="col-form-label labelDown">
                                                            Moneda
                                                        </label>
                                                        <select
                                                            className="form-select inputDown"
                                                            name="cIng_Tab_MonedaAR"
                                                            value={getMonedaAR}
                                                            onChange={handleChangeMoneda}
                                                        >
                                                            <option value="0">Seleccionar</option>
                                                            {dataComboMoneda.map((row, i) => (
                                                                <option key={i} value={row.cTabCodigo}>
                                                                    {row.cTabNombre}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="col-md-2" style={{ width: "130px", paddingRight: "0px", paddingLeft: "0px" }}>
                                                        <label className="col-form-label labelDown">
                                                            Tarifa por hora
                                                        </label>
                                                        <input
                                                            name="Monto"
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Ingrese el monto"
                                                            onChange={handleChange}
                                                            onBlur={BluerChange}
                                                            onFocus={FocusChange}
                                                            value={getMontoAR && getMontoAR.Monto}
                                                            style={{ textAlign: 'right' }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mb-3 row">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-3 col-form-label"
                                                        style={{ marginTop: '21px' }}
                                                    >
                                                        Abogado(s)
                                                    </label>
                                                    <div className="col-md-4" style={{ marginTop: '21px' }}>
                                                        <Select
                                                            isMulti
                                                            onChange={handleChangeParticipantesA}
                                                            name="colors"
                                                            options={UsuariosSelect}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            placeholder="Abogados..."
                                                        />

                                                        <label className="col-form-label labelDown">
                                                            Abogado(s)
                                                        </label>
                                                    </div>
                                                    <div className="col-md-2" style={{ width: "140px", paddingRight: "5px", paddingLeft: "0px" }}>
                                                        <label className="col-form-label labelDown">
                                                            Moneda
                                                        </label>
                                                        <select
                                                            className="form-select inputDown"
                                                            name="cIng_Tab_MonedaA"
                                                            onChange={handleChangeMoneda}
                                                            value={getMonedaA}
                                                        >
                                                            <option value="0">Seleccionar</option>
                                                            {dataComboMoneda.map((row, i) => (
                                                                <option key={i} value={row.cTabCodigo}>
                                                                    {row.cTabNombre}
                                                                </option>
                                                            ))}
                                                        </select>

                                                    </div>
                                                    <div className="col-md-2" style={{ width: "130px", paddingRight: "0px", paddingLeft: "0px" }}>
                                                        <label className="col-form-label labelDown">
                                                            Tarifa por hora
                                                        </label>
                                                        <input
                                                            name="MontoA"
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Ingrese el monto"
                                                            onChange={handleChange}
                                                            onBlur={BluerChange}
                                                            onFocus={FocusChange}
                                                            value={getMontoA && getMontoA.MontoA}
                                                            style={{ textAlign: 'right' }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mb-3 row">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-3 col-form-label"
                                                        style={{ marginTop: '21px' }}
                                                    >
                                                        Practicante(s)
                                                    </label>
                                                    <div className="col-md-4" style={{ marginTop: '21px' }}>
                                                        <Select
                                                            isMulti
                                                            onChange={handleChangeParticipantesP}
                                                            name="colors"
                                                            options={UsuariosSelect}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            placeholder="Practicante..."
                                                        />
                                                        <label className="col-form-label labelDown">
                                                            Practicante(s)
                                                        </label>
                                                    </div>
                                                    <div className="col-md-2" style={{ width: "140px", paddingRight: "5px", paddingLeft: "0px" }}>
                                                        <label className="col-form-label labelDown">
                                                            Moneda
                                                        </label>
                                                        <select
                                                            className="form-select inputDown"
                                                            name="cIng_Tab_MonedaP"
                                                            value={getMonedaP}
                                                            onChange={handleChangeMoneda}
                                                        >
                                                            <option value="0">Seleccionar</option>
                                                            {dataComboMoneda.map((row, i) => (
                                                                <option key={i} value={row.cTabCodigo}>
                                                                    {row.cTabNombre}
                                                                </option>
                                                            ))}
                                                        </select>

                                                    </div>
                                                    <div className="col-md-2" style={{ width: "130px", paddingRight: "0px", paddingLeft: "0px" }}>
                                                        <label className="col-form-label labelDown">
                                                            Tarifa por hora
                                                        </label>
                                                        <input
                                                            name="MontoP"
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Ingrese el monto"
                                                            onChange={handleChange}
                                                            onBlur={BluerChange}
                                                            onFocus={FocusChange}
                                                            value={getMontoP && getMontoP.MontoP}
                                                            style={{ textAlign: 'right' }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mb-3 row">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-3 col-form-label"
                                                        style={{ marginTop: '21px' }}
                                                    >
                                                        Usuario Adicional
                                                    </label>
                                                    <div className="col-md-4" style={{ marginTop: '21px' }}>
                                                        <Select
                                                            isMulti
                                                            onChange={handleChangeParticipantesUAD}
                                                            name="colors"
                                                            options={UsuariosSelect}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            placeholder="Usuario Adicional..."
                                                        />
                                                        <label className="col-form-label labelDown">
                                                            Usuario Adicional
                                                        </label>
                                                    </div>
                                                    <div className="col-md-2" style={{ width: "140px", paddingRight: "5px", paddingLeft: "0px" }}>
                                                        <label className="col-form-label labelDown">
                                                            Moneda
                                                        </label>
                                                        <select
                                                            className="form-select inputDown"
                                                            name="cIng_Tab_MonedaUAD"
                                                            value={getMonedaUAD}
                                                            onChange={handleChangeMoneda}
                                                        >
                                                            <option value="0">Seleccionar</option>
                                                            {dataComboMoneda.map((row, i) => (
                                                                <option key={i} value={row.cTabCodigo}>
                                                                    {row.cTabNombre}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="col-md-2" style={{ width: "130px", paddingRight: "0px", paddingLeft: "0px" }}>
                                                        <label className="col-form-label labelDown">
                                                            Tarifa por hora
                                                        </label>
                                                        <input
                                                            onChange={handleChange}
                                                            onBlur={BluerChange}
                                                            onFocus={FocusChange}
                                                            name="MontoUAD"
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Ingrese el monto"
                                                            value={getMontoUAD && getMontoUAD.MontoUAD}
                                                            style={{ textAlign: 'right' }}
                                                        />
                                                    </div>
                                                </div>
                                                <hr
                                                    style={{ color: "#000", marginTop: "0px" }}
                                                />
                                                <div className="mb-3 row">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-3 col-form-label"
                                                    >
                                                        Honorario fijo
                                                    </label>
                                                    <div className="col-md-3">
                                                        <input
                                                            style={{ textAlign: "right" }}
                                                            name="nCas_HonorarioFijo"
                                                            value={getCasos.nCas_HonorarioFijo}
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Honorario fijo"
                                                            onChange={handleChangeCaso}
                                                            onBlur={BluerChange}
                                                            onFocus={FocusChange}
                                                        />
                                                        <label className="col-form-label labelDown">
                                                            Honorario fijo
                                                        </label>
                                                    </div>
                                                    <div className="col-md-2" style={{ width: "140px", paddingRight: "5px", paddingLeft: "0px" }}>
                                                        <select
                                                            className="form-select inputDown"
                                                            name="cTab_MonedaHF"
                                                            onChange={handleChangeCaso}
                                                        >
                                                            <option value="0">Seleccionar</option>
                                                            {dataComboMoneda.map((row, i) => (
                                                                <option key={i} value={row.cTabCodigo}>
                                                                    {row.cTabNombre}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <label className="col-form-label labelDown">
                                                            Moneda
                                                        </label>
                                                    </div>
                                                    <div className="col-md-2" style={{ width: "140px", paddingRight: "5px", paddingLeft: "0px" }}>
                                                        <Button onClick={() => FnPlanPago()}>Plan de Pagos</Button>
                                                    </div>
                                                </div>
                                                <div className="mb-3 row">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-3 col-form-label"
                                                    >
                                                        Honorario de éxito
                                                    </label>
                                                    <div className="col-md-3">
                                                        <input
                                                            style={{ textAlign: "right" }}
                                                            name="nCas_HonorarioExito"
                                                            value={getCasos.nCas_HonorarioExito}
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Honorario de éxito"
                                                            onChange={handleChangeCaso}
                                                            onBlur={BluerChange}
                                                            onFocus={FocusChange}
                                                        />
                                                        <label className="col-form-label labelDown">
                                                            Honorario de éxito
                                                        </label>
                                                    </div>
                                                    <div className="col-md-2" style={{ width: "140px", paddingRight: "5px", paddingLeft: "0px" }}>
                                                        <select
                                                            className="form-select inputDown"
                                                            name="cTab_MonedaHE"
                                                            onChange={handleChangeCaso}
                                                        >
                                                            <option value="0">Seleccionar</option>
                                                            {dataComboMoneda.map((row, i) => (
                                                                <option key={i} value={row.cTabCodigo}>
                                                                    {row.cTabNombre}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <label className="col-form-label labelDown">
                                                            Moneda
                                                        </label>
                                                    </div>
                                                </div>
                                                <strong>
                                                    Ubicación del file
                                                </strong>
                                                <hr
                                                    style={{ color: "#000", marginTop: "0px" }}
                                                />
                                                <div className="mb-3 row">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-3 col-form-label"
                                                    >
                                                        Ubicación del file
                                                    </label>
                                                    <div className="col-md-6">
                                                        <input
                                                            name="cName_Resolucion"
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Ubicación física del file"
                                                            onChange={handleChangeCaso}
                                                        />
                                                        <label className="col-form-label labelDown">
                                                            Ubicación del file
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="mb-12 row" style={{ display: HiddenEquipoCbo }}>
                                                    <label>
                                                        <b>
                                                            ** La asignación de un equipo reemplazara las personas asignadas.
                                                        </b>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardText>
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>
            </div>
            <div className="modal-footer">
                <div className="mb-3 row" style={{ marginTop: '20px', marginRight: "300px" }}>
                    <div className="col-md-12">
                        <label className="col-form-label labelDown labelRed">(*) Campos obligatorios</label>
                        <label className="col-form-label labelDown"></label>
                    </div>
                </div>
                <button
                    type="button"
                    onClick={() => { onCloseModal() }}
                    className="btn btn-secondary waves-effect waves-light"
                    data-dismiss="modal"
                >
                    Cerrar <FontAwesomeIcon icon={faTimes} />
                </button>
                <button
                    type="button"
                    onClick={() => EventoCabeceraTab("1")}
                    className="btn btn-primary waves-effect waves-light"
                    style={{ display: HiddenAnterior1 }}
                >
                    <FontAwesomeIcon icon={faChevronLeft} /> Anterior
                </button>
                <button
                    type="button"
                    onClick={() => EventoCabeceraTab("2")}
                    className="btn btn-primary waves-effect waves-light"
                    style={{ display: HiddenAnterior2 }}
                >
                    <FontAwesomeIcon icon={faChevronLeft} /> Anterior
                </button>
                <button
                    type="button"
                    onClick={() => EventoCabeceraTab("2")} name="btnSiguiente1"
                    className="btn btn-danger waves-effect waves-light " disabled={disabled}
                    style={{ display: HiddenSiguiente2 }}
                >
                    Siguiente <FontAwesomeIcon icon={faChevronRight} />
                </button>
                <button
                    type="button"
                    onClick={() => EventoCabeceraTab("3")}
                    className="btn btn-danger waves-effect waves-light"
                    style={{ display: HiddenSiguiente3 }}
                >
                    Siguiente <FontAwesomeIcon icon={faChevronRight} />
                </button>
                <button
                    type="button"
                    className="btn btn-danger waves-effect waves-light"
                    style={{ display: HiddenFinalizar }}
                    onClick={() => FuncionAgregarCaso()}
                >
                    Grabar <FontAwesomeIcon icon={faPaperPlane} />
                </button>
            </div>
        </Modal >
    )
}
export default ModalCaso