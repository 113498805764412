import React, { Component, useState, useEffect } from "react"
import Select from "react-select"
import Swal from "sweetalert2"
import InputMask from 'react-input-mask';
import { getDecryptedItem } from '../../util';
import {
    CardBody,
    Card,
    Col,
    Container,
    CardTitle,
    Button,
    Row,
    Modal,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
} from "reactstrap"
import instance from '../../config'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faPlus,
    faSearch,
    faTimes,
    faChevronLeft,
    faChevronRight,
    faPaperPlane,
    faBuilding,
    faUser,
    faCalendar,
    faCircle,
    faClipboard,
    faMapMarkedAlt,
    faDownload,
    faEye,
    faTrash,
} from "@fortawesome/free-solid-svg-icons"
const baseUrl = process.env.REACT_APP_API_URL
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
const Validacion = (form) => {
    let errors = {};
    let regexLetras = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    let regexCorreo = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    let regexNumero = /^[0-9]+$/;

    if (form.nCli_Id == 0 || form.nCli_Id == null) {
        errors.nCli_Id = "(*)"
    }
    if (form.nCas_Id == 0 || form.nCas_Id == null || form.nCas_Id == NaN) {
        errors.nCas_Id = "(*)"
    }
    if (form.nCue_Id == 0) {
        errors.nCue_Id = "(*)"
    }
    if (form.cLab_Tab_Tipo == 0) {
        errors.cLab_Tab_Tipo = "(*)"
    }
    if (form.nUsu_Id == 0) {
        errors.nUsu_Id = "(*)"
    }
    if (form.cLab_Tab_Moneda == 0) {
        errors.cLab_Tab_Moneda = "(*)"
    }
    if (form.nLab_TasaXHora == 0) {
        errors.nLab_TasaXHora = "(*)"
    }

    return errors;
}

export const ModalHorasLaboradas = ({ show, onCloseClick, ReloadLista, Titulo }) => {
    const [modalInsertar, setModalInsertar] = useState(false)
    const [disable, setDisable] = useState(false)
    const [data, setData] = useState([])
    const [dataComboMoneda, setDataComboMoneda] = useState([])
    const [dataCaso, setDataCaso] = useState([])
    const [dataComboUsuario, setDataComboUsuario] = useState([])
    const [dataComboTipoHL, setDataComboTipoHL] = useState([])
    const [dataCliente, setDataCliente] = useState([])
    const [chekedFacturable, setchekedFacturable] = useState(true)
    const [chekedPreciFijo, setchekedPreciFijo] = useState(false)
    const [getMoneda, setMoneda] = useState("");
    const [getMonto, setMonto] = useState(0);
    const [blockBtn, setBlockBtn] = useState(false)
    const [HiddenFacturable, setHiddenFacturable] = useState()
    const [HiddenPreciFijo, setHiddenPreciFijo] = useState()
    const [validData, setValidData] = useState(false)

    const [dataCodInterno, setdataCodInterno] = useState("")
    const [dataCodExterno, setdataCodExterno] = useState("")
    const [getCaso, setCaso] = useState("")
    const [getErrores, setErrores] = useState({});
    const [getHLaboradas, setHLaboradas] = useState({
        nLab_Id: "",
        nCli_Id: getDecryptedItem("CLIID"),
        nCas_Id: getDecryptedItem('nCas_Id'),
        cLab_Tab_Tipo: "",
        nLab_Hora: "01:00",
        nLab_Hora_H: "01",
        nLab_Hora_M: "00",
        dLab_Fecha: ano + "-" + mes + "-" + dia,
        cLab_Detalle: "",
        nUsu_Id: "",
        bLab_PrecioFijo: 0,
        tLab_HoraFacturable: "01:00",
        tLab_HoraFacturable_H: "01",
        tLab_HoraFacturable_M: "00",
        nLab_TasaXHora: "",
        cLab_Tab_Moneda: "",
        nLab_TasaXHoraFormat: 0,
        nLab_TasaXHoraOld: 0,
        nLab_Total: 0.00,
        nLab_TotalFormat: 0,
        nLab_TotalOld: 0,
        bLab_Estado: 1,
    })
    const [chekedValor1, setchekedValor1] = useState(false)
    const [chekedValor2, setchekedValor2] = useState(false)
    const [Valor1, setValor1] = useState(true)
    const [Valor2, setValor2] = useState(true)

    const handleChangeFiltroCaso = e => {




        if (e.target.value == 0) {
            FuncionListarCasos(null)
            setHLaboradas(prevState => ({
                ...prevState,
                nCli_Id: e.target.value,

            }))
        } else {
            FuncionListarCasos(e.target.value)
            setHLaboradas(prevState => ({
                ...prevState,
                nCli_Id: e.target.value,

            }))
        }
    }

    const BluerChange = e => {
        const { name, value } = e.target
        if (name == "nLab_TasaXHora") {
            setHLaboradas(prevState => ({
                ...prevState,
                [name]: getHLaboradas.nLab_TasaXHoraFormat,
            }))
        }
    }
    const FocusChange = e => {
        const { name, value } = e.target
        if (name == "nLab_TasaXHora") {
            setHLaboradas(prevState => ({
                ...prevState,
                [name]: getHLaboradas.nLab_TasaXHoraOld,
            }))
        }
    }

    function Multiplicar(valor, HorasLaborables) {
        console.log(HorasLaborables)
        var TasaXHora = valor
        console.log(TasaXHora)

        var Valor1 = HorasLaborables.split(":")
        var N1 = Valor1[0]
        var N2 = Valor1[1]
        var Multi = N1 * TasaXHora
        var NVALOR1 = TasaXHora / 60

        var NVALOR2 = NVALOR1 * N2

        var Total = Multi + NVALOR2

        setHLaboradas(prevState => ({
            ...prevState,
            nLab_Total: Total.toFixed(2),
        }))
    }

    const handleChange = e => {
        const { name, value } = e.target
        if (name == "nLab_TasaXHora") {
            setHLaboradas(prevState => ({
                ...prevState,
                nLab_TasaXHora: value,
                nLab_TasaXHoraOld: value,
                nLab_TasaXHoraFormat: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(value),
            }))
            if (chekedPreciFijo == false) {
                Multiplicar(value, getHLaboradas.tLab_HoraFacturable)
            }
            else {
                setHLaboradas(prevState => ({
                    ...prevState,
                    nLab_Total: value,
                    nLab_TotalFormat: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(value),
                    nLab_TotalFormat: value,
                }))
            }
        } else if (e.target.name == "nCas_Id") {
            setHLaboradas(prevState => ({
                ...prevState,
                [name]: value,
            }))
            setCaso(value);
            FuncionInformacionCaso(e.target.value);
            FuncionListarComboUsuario(e.target.value)
        } else if (name == "nUsu_Id") {

            setHLaboradas(prevState => ({
                ...prevState,
                [name]: value,
            }))

            if (getDecryptedItem("nCas_Id") == null) {
                FuncionGetMonedaMonto_UsuarioCaso(getCaso, value)
            }
            else {
                FuncionGetMonedaMonto_UsuarioCaso(getDecryptedItem("nCas_Id"), value)
            }

        } else if (name == "nLab_Hora_H") {
            setHLaboradas(prevState => ({
                ...prevState,
                nLab_Hora_H: value,
                nLab_Hora: value + ":" + getHLaboradas.nLab_Hora_M,
                tLab_HoraFacturable: value + ":" + getHLaboradas.nLab_Hora_M,
                tLab_HoraFacturable_H: value,
                tLab_HoraFacturable_M: getHLaboradas.nLab_Hora_M,
            }))
            Multiplicar(getHLaboradas.nLab_TasaXHora, value + ":" + getHLaboradas.nLab_Hora_M)
        } else if (name == "nLab_Hora_M") {
            setHLaboradas(prevState => ({
                ...prevState,
                nLab_Hora_M: value,
                nLab_Hora: getHLaboradas.nLab_Hora_H + ":" + value,
                tLab_HoraFacturable: getHLaboradas.nLab_Hora_H + ":" + value,
                tLab_HoraFacturable_M: value,
                tLab_HoraFacturable_H: getHLaboradas.nLab_Hora_H,
            }))
            Multiplicar(getHLaboradas.nLab_TasaXHora, getHLaboradas.nLab_Hora_H + ":" + value)
        } else if (name == "tLab_HoraFacturable_H") {
            if (getHLaboradas.nLab_Hora < value + ":" + getHLaboradas.tLab_HoraFacturable_M) {
                Swal.fire({
                    title: "Mensaje",
                    text: "El tiempo facturable no puede ser mayor a las horas laboradas ",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                })
                return
            } else {
                setHLaboradas(prevState => ({
                    ...prevState,
                    tLab_HoraFacturable_H: value,
                    tLab_HoraFacturable: value + ":" + getHLaboradas.tLab_HoraFacturable_M,
                }))
                Multiplicar(getHLaboradas.nLab_TasaXHora, value + ":" + getHLaboradas.tLab_HoraFacturable_M)
            }
        } else if (name == "tLab_HoraFacturable_M") {
            if (getHLaboradas.nLab_Hora < getHLaboradas.tLab_HoraFacturable_H + ":" + value) {
                Swal.fire({
                    title: "Mensaje",
                    text: "El tiempo facturable no puede ser mayor a las horas laboradas ",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                })
                console.log(getHLaboradas.tLab_HoraFacturable_M)
                setHLaboradas(prevState => ({
                    ...prevState,
                    tLab_HoraFacturable_M: getHLaboradas.tLab_HoraFacturable_M,
                }))
                return
            } else {
                setHLaboradas(prevState => ({
                    ...prevState,
                    tLab_HoraFacturable_M: value,
                    tLab_HoraFacturable: getHLaboradas.tLab_HoraFacturable_H + ":" + value,
                }))
                Multiplicar(getHLaboradas.nLab_TasaXHora, getHLaboradas.tLab_HoraFacturable_H + ":" + value)
            }

        }
        else {
            setHLaboradas(prevState => ({
                ...prevState,
                [name]: value,
            }))
        }

    }

    const handleChangeCheckFacturable = e => {
        const { cheked, name } = e.target
        setchekedFacturable(cheked)
        if (cheked == true) {
            setHiddenFacturable("none")
        } else {
            setHiddenFacturable("")
            setHLaboradas(prevState => ({
                ...prevState,
                tLab_HoraFacturable: "00:00",
            }))
        }
    }
    
    const handleChangeCheckPreciFijo = e => {
        setchekedPreciFijo(!chekedPreciFijo)
        if (chekedPreciFijo == false) {
            setHiddenPreciFijo("")
            setHLaboradas(prevState => ({
                ...prevState,
                nLab_Total: getHLaboradas.nLab_TasaXHora,
                nLab_TotalFormat: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(getHLaboradas.nLab_TasaXHora * 1),
                nLab_TotalOld: getHLaboradas.nLab_TasaXHora,
            }))

        } else {
            var HorasLaborables = document.getElementById("tLab_HoraFacturable").value
            var Valor1 = HorasLaborables.split(":")
            var N1 = Valor1[0]
            setHLaboradas(prevState => ({
                ...prevState,
                nLab_Total: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(getHLaboradas.nLab_TasaXHora * N1),
            }))
        }
    }
    const abrirCerrarModal = Modal => {
        if (Modal == 1) {
            if (!modalInsertar == false) {
                Swal.fire({
                    html: "¿Seguro que desea salir sin guardar los datos?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si",
                    cancelButtonText: "No",
                }).then(result => {
                    if (result.isConfirmed) {
                        setModalInsertar(!modalInsertar)
                    }
                })
            } else {
                setModalInsertar(!modalInsertar)
            }
        } else if (Modal == 2) {
            setModalActualizar(!modalActualizar)
        } else if (Modal == 4) {
            setModalFiltro(!modalFiltro)
        }
    }
    const FuncionListarComboTipoHL = async () => {
        var postData = {
            Prefijo: "XA",
        }
        await instance
            .post("/Maestros/Data", postData)
            .then(response => {
                setDataComboTipoHL(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionListarComboUsuario = async (nCas_Id) => {
        let postData = {
            _nCas_Id: parseInt(nCas_Id)
        }
        await instance
            .post("/Caso/Participantes_Caso_All", postData)
            .then(response => {
                setDataComboUsuario(response.data)

            })
            .catch(error => {

                console.log(error);

            })
    }
    const FuncionListarCliente = async () => {
        let RolId = getDecryptedItem("RolID")

        var postData = {
            bCli_Tipo: null,
            nUsu_ID: RolId == 6 || RolId == 1 ? null : getDecryptedItem("ID")
        }
        await instance
            .post("/Cliente/ListarCbo", postData)
            .then(response => {
                setDataCliente(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionListarCasos = async (_idClient) => {
        var postData = {
            idClient: _idClient,
            nUsu_Id: getDecryptedItem("ID"),
        }
        await instance
            .post("/Casos/ListarCbo", postData)
            .then(response => {
                setDataCaso(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionInformacionCaso = async (_nCas_Id) => {
        var postData = {
            nCas_Id: parseInt(_nCas_Id),
        }

        await instance
            .post("/Casos/Buscar", postData)
            .then(response => {

                setdataCodInterno(response.data[0].cCas_Cod_Interno)
                setdataCodExterno(response.data[0].cCas_Cod_Externo)
            })
            .catch(error => {

            })
    }
    const FuncionListarComboMoneda = async () => {
        var postData = {
            Prefijo: "MO",
        }
        await instance
            .post("/Maestros/Data", postData)
            .then(response => {
                setDataComboMoneda(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionAgregarHLaboradas = async () => {
        var postData = {
            nCas_Id: getHLaboradas.nCas_Id,
            cLab_Tab_Tipo: getHLaboradas.cLab_Tab_Tipo,
            nLab_Hora: getHLaboradas.nLab_Hora,
            dLab_Fecha: getHLaboradas.dLab_Fecha,
            cLab_Detalle: getHLaboradas.cLab_Detalle,
            nUsu_Id: parseInt(getDecryptedItem("ID")),
            bLab_PrecioFijo: getHLaboradas.bLab_PrecioFijo,
            tLab_HoraFacturable: getHLaboradas.tLab_HoraFacturable,
            cLab_Tab_Moneda: getHLaboradas.cLab_Tab_Moneda,
            nLab_Total: getHLaboradas.nLab_Total,
            bLab_Estado: getHLaboradas.bLab_Estado,
            nLab_TasaXHora: getHLaboradas.nLab_TasaXHoraOld,
            nCli_Id: getHLaboradas.nCli_Id
        }

        console.log(postData);

      

        await instance
            .post("/Lab/Insertar", postData)
            .then(response => {

                ReloadLista()
                setModalInsertar(!modalInsertar)
                Swal.fire({
                    title: "Mensaje",
                    text: "Agregado exitosamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })
            })
            .catch(error => {

            })
    }
    const FuncionValidarFormulario = async () => {
        console.log(getHLaboradas)
        if (!getHLaboradas.nCas_Id || !getHLaboradas.cLab_Tab_Tipo || !getHLaboradas.nUsu_Id || !getHLaboradas.cLab_Tab_Moneda || !getHLaboradas.nLab_TasaXHora || !getHLaboradas.nLab_Total) {
            setValidData(true);
            Swal.fire({
                title: "Mensaje",
                text: "Faltan completar datos",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        } else {
            setBlockBtn(true)
            FuncionAgregarHLaboradas()
        }
    }

    const FuncionGetMonedaMonto_UsuarioCaso = async (nCas_Id, nUsu_Id) => {
        if (parseInt(nCas_Id) > 0 || parseInt(nUsu_Id) > 0) {
            var postData = {
                _nCas_Id: nCas_Id,
                _nUsu_Id: nUsu_Id,
            }

            console.log(postData)

            await instance
                .post("/Casos/GetMonedaMonto_UsuarioCaso", postData)
                .then(response => {

                    console.log(response.data)
                    console.log(response.data.length)

                    let array = response.data;
                    console.log(array.length)
                    console.log(response.data.length)

                    if (response.data.length > 0) {

                        console.log("entro")
                        setHLaboradas(prevState => ({
                            ...prevState,
                            nLab_TasaXHora: response.data[0].nCas_Monto,
                            cLab_Tab_Moneda: response.data[0].cCas_TabMoneda,

                        }))
  
                        console.log(document.getElementById("tLab_HoraFacturable_H").value);
                        console.log(document.getElementById("tLab_HoraFacturable_M").value);

                        var HorasLaborables = document.getElementById("tLab_HoraFacturable_H").value;
                        var MinutosLaborables = document.getElementById("tLab_HoraFacturable_M").value

                        let tLab_HoraFacturable = HorasLaborables + ":" + MinutosLaborables
                        console.log(tLab_HoraFacturable);

                        var TasaXHora = response.data[0].nCas_Monto
                        var Valor1 = tLab_HoraFacturable.split(":")
                        var N1 = Valor1[0]
                        var N2 = Valor1[1]
                        var Multi = N1 * TasaXHora
                        var NVALOR1 = TasaXHora / 60

                        var NVALOR2 = NVALOR1 * N2

                        let Total = Multi + NVALOR2
                        setHLaboradas(prevState => ({
                            ...prevState,
                            nLab_Total: Total.toFixed(2),
                        }))
                    } else {
                        console.log("no entro")
                        setHLaboradas(prevState => ({
                            ...prevState,
                            nLab_TasaXHora: 0.00,
                            cLab_Tab_Moneda: 'MO01',
                            nLab_Total: 0.00,
                        }))
                    }


                })
                .catch(error => {
                    console.log(error)
                })
        }
    }
    const FormatoMoneda = (moneda) => {
        return moneda = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(moneda)
    }

    const handleBlur = e => {
        setErrores(Validacion(getHLaboradas))
    }

    const FuncionListarUsuarios = async () => {

        let postData = {
            _nCas_Id: getDecryptedItem("nCas_Id")
        }

        await instance
            .post("/Caso/Participantes_Caso_All", postData)
            .then(response => {

                setDataComboUsuario(response.data)
            })
            .catch(error => {

            })
    }

    useEffect(() => {

        setErrores(Validacion(getHLaboradas));
        FuncionListarComboUsuario()

        FuncionListarComboTipoHL()
        FuncionListarComboMoneda()
        FuncionListarCliente()
        if (getDecryptedItem("nCas_Id") === null) {
            setDisable(false)
        } else {
            FuncionListarCasos(null)
            setDisable(true)
            FuncionListarUsuarios()
            FuncionInformacionCaso(getDecryptedItem("nCas_Id"))
            setHLaboradas(prevState => ({
                ...prevState,
                nCli_Id: getDecryptedItem("CLIID") || getDecryptedItem('CASOIDCli')
            }))
        }
    }, [])
    return (
        <Modal size="lg" isOpen={show}>
            <div
                className="modal-header ModalHeaderColor"
            >
                <h5
                    style={{ color: "#fff" }}
                    className="modal-title mt-0"
                    id="myModalLabel"
                >
                    {Titulo}
                </h5>
                <button
                    type="button"
                    onClick={() => onCloseClick()}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="mb-3 row">
                    <div className="col-md-6">
                        <select className={getErrores.nCli_Id && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                            name="nCli_Id"
                            onChange={handleChangeFiltroCaso}
                            onBlur={handleBlur}
                            disabled={disable}
                            value={getHLaboradas && getHLaboradas.nCli_Id}>
                            <option value="0">Seleccionar...</option>
                            {dataCliente.map((row, i) => (
                                <option key={i} value={row.nCli_Id}>
                                    {row.cCli_NombreCompleto}
                                </option>
                            ))}
                        </select>
                        <label className="col-form-label labelDown">Cliente</label> {getErrores.nCli_Id && <label className="col-form-label labelDown labelRed">{getErrores.nCli_Id}</label>}
                    </div>
                    <div className="col-md-6">
                        <select

                            className={getErrores.nCas_Id && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                            name="nCas_Id"
                            disabled={disable}
                            onChange={handleChange}
                            value={getHLaboradas && getHLaboradas.nCas_Id}
                            onBlur={handleBlur}
                        >
                            <option value="0">Seleccionar...</option>
                            {dataCaso.map((row, i) => (
                                <option key={i} value={row.nCas_Id}>
                                    {row.cCas_Titulo}
                                </option>
                            ))}
                        </select>
                        <label className="col-form-label labelDown">Caso</label> {getErrores.nCas_Id && <label className="col-form-label labelDown labelRed">{getErrores.nCas_Id}</label>}
                    </div>
                </div>
                <div className="mb-1 row">
                    <div style={{ height: "20px;" }} className="col-md-5">
                        <label
                            htmlFor="example-text-input"
                            className="col-md-12 col-form-label"
                            style={{ marginLeft: "5px" }}
                        >
                            Cód Interno: {dataCodInterno}
                        </label>
                    </div>
                    <div className="col-md-7">
                        <label
                            htmlFor="example-text-input"
                            className="col-md-12 col-form-label"
                            style={{ marginLeft: "2px" }}
                        >
                            Cód Externo:{dataCodExterno}
                        </label>
                    </div>
                </div>
                <div className="mb-3 row">
                    <div className="col-md-4">
                        <div className="d-flex align-items-center">
                            <select
                                className="form-select inputDown"
                                name="nLab_Hora_H"
                                onChange={handleChange}
                                value={getHLaboradas && getHLaboradas.nLab_Hora?.split(":")[0]}
                            >
                                {Array.from({ length: 24 }, (_, i) => (
                                    <option key={i} value={i.toString().padStart(2, '0')}>
                                        {i.toString().padStart(2, '0')}
                                    </option>
                                ))}
                            </select>
                            <span className="mx-2">:</span>
                            <select
                                className="form-select inputDown"
                                name="nLab_Hora_M"
                                onChange={handleChange}
                                value={getHLaboradas && getHLaboradas.nLab_Hora?.split(":")[1]}
                            >
                                {Array.from({ length: 60 }, (_, i) => (
                                    <option key={i} value={i.toString().padStart(2, '0')}>
                                        {i.toString().padStart(2, '0')}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {/* <input
                            className="form-control"
                            type="time"
                            placeholder="Horas laboradas"
                            id="nLab_Hora"
                            name="nLab_Hora"

                            onChange={handleChange}
                            value={getHLaboradas && getHLaboradas.nLab_Hora}
                        /> */}
                        <label className="col-form-label labelDown">
                            Horas laboradas (hh:mm)
                        </label>
                    </div>
                    <div className="col-md-4">
                        <input
                            className="form-control"
                            type="date"
                            name="dLab_Fecha"
                            onChange={handleChange}
                            value={getHLaboradas && getHLaboradas.dLab_Fecha}
                        />
                        <label className="col-form-label labelDown">
                            Fecha dd/mm/aaaa
                        </label>
                    </div>
                    <div className="col-md-4">
                        <select
                            className={getErrores.cLab_Tab_Tipo && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                            name="cLab_Tab_Tipo"
                            onChange={handleChange}
                            value={getHLaboradas && getHLaboradas.cLab_Tab_Tipo}
                            onBlur={handleBlur}
                        >
                            <option value="0">Seleccionar...</option>
                            {dataComboTipoHL.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                    {row.cTabNombre}
                                </option>
                            ))}
                        </select>
                        <label className="col-form-label labelDown">Tipo de labor</label>{getErrores.cLab_Tab_Tipo && <label className="col-form-label labelDown labelRed">{getErrores.cLab_Tab_Tipo}</label>}
                    </div>
                </div>
                <div className="mb-3 row">
                    <div className="col-md-12">
                        <textarea
                            className="form-control"
                            type="text"
                            placeholder="Descripción"
                            name="cLab_Detalle"
                            onChange={handleChange}
                            maxLength="200"
                        ></textarea>
                        <label className="col-form-label labelDown">
                            Ingrese descripción
                        </label>
                    </div>
                </div>
                <div className="mb-3 row">
                    <div className="col-md-6">
                        <select
                            className={getErrores.nUsu_Id && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                            name="nUsu_Id"
                            onChange={handleChange}
                            value={getHLaboradas && getHLaboradas.nUsu_Id}
                            onBlur={handleBlur}
                        >
                            <option value="0">Seleccionar...</option>
                            {dataComboUsuario.map((row, i) => (
                                <option key={i} value={row.nUsu_Id}>
                                    {row.cUsu_Nombres}
                                </option>
                            ))}
                        </select>
                        <label className="col-form-label labelDown">Persona que factura</label>{getErrores.nUsu_Id && <label className="col-form-label labelDown labelRed">{getErrores.nUsu_Id}</label>}
                    </div>
                    <div className="col-md-6">
                        <select
                            className={getErrores.cLab_Tab_Moneda && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                            name="cLab_Tab_Moneda"
                            onChange={handleChange}
                            value={getHLaboradas && getHLaboradas.cLab_Tab_Moneda}
                            onBlur={handleBlur}
                        >
                            <option value="0">Seleccionar</option>
                            {dataComboMoneda.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                    {row.cTabNombre}
                                </option>
                            ))}
                        </select>
                        <label className="col-form-label labelDown">Seleccione moneda</label>{getErrores.cLab_Tab_Moneda && <label className="col-form-label labelDown labelRed">{getErrores.cLab_Tab_Moneda}</label>}
                    </div>
                </div>
                <div className="mb-3 row">
                    <div
                        className="col-md-3"
                        style={{ paddingLeft: "50px" }}
                    >
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckcolor1"
                            onChange={handleChangeCheckFacturable}
                            checked={chekedFacturable}
                            value={chekedFacturable}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="customCheckcolor1"
                            style={{ paddingLeft: "5px" }}
                        >
                            Facturable
                        </label>
                    </div>
                </div>
                <div className="mb-3 row" style={{ display: HiddenFacturable }}>
                    <div
                        className="col-md-3"
                        style={{ paddingLeft: "50px" }}
                    >
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckcolor1"
                            onChange={handleChangeCheckPreciFijo}
                            checked={chekedPreciFijo}
                            value={chekedPreciFijo}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="customCheckcolor1"
                            style={{ paddingLeft: "5px" }}
                        >
                            Precio Fijo
                        </label>
                    </div>
                    <div className="col-md-3" style={{ display: HiddenPreciFijo }}>
                        <div className="d-flex align-items-center">
                            <select
                                id="tLab_HoraFacturable_H"
                                className="form-select inputDown"
                                name="tLab_HoraFacturable_H"
                                onChange={handleChange}
                                value={getHLaboradas && getHLaboradas.tLab_HoraFacturable?.split(":")[0]}
                            >
                                {Array.from({ length: 24 }, (_, i) => (
                                    <option key={i} value={i.toString().padStart(2, '0')}>
                                        {i.toString().padStart(2, '0')}
                                    </option>
                                ))}
                            </select>
                            <span className="mx-2">:</span>
                            <select
                                id="tLab_HoraFacturable_M"
                                value={getHLaboradas && getHLaboradas.tLab_HoraFacturable?.split(":")[1]}
                                className="form-select inputDown"
                                name="tLab_HoraFacturable_M"
                                onChange={handleChange}
                            >
                                {Array.from({ length: 60 }, (_, i) => (
                                    <option key={i} value={i.toString().padStart(2, '0')}>
                                        {i.toString().padStart(2, '0')}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <label className="col-form-label labelDown">
                            Tiempo facturable
                        </label>
                    </div>

                    <div className="col-md-3" style={{ display: HiddenPreciFijo }}>
                        <input
                            className={getErrores.nLab_TasaXHora && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                            type="text"
                            placeholder="Tarifa por hora"
                            name="nLab_TasaXHora"
                            id="nLab_TasaXHora"
                            onChange={handleChange}
                            onBlur={BluerChange}
                            onFocus={FocusChange}
                            value={getHLaboradas && getHLaboradas.nLab_TasaXHora}
                            style={{ textAlign: 'right' }}
                        />
                        <label className="col-form-label labelDown">Tarifa por hora</label>{getErrores.nLab_TasaXHora && <label className="col-form-label labelDown labelRed">{getErrores.nLab_TasaXHora}</label>}

                    </div>
                    <div className="col-md-3">
                        <input
                            disabled
                            className="form-control"
                            type="text"
                            placeholder="Total"
                            name="nLab_Total"
                            onChange={handleChange}
                            value={getHLaboradas && FormatoMoneda(getHLaboradas.nLab_Total)}
                            style={{ textAlign: 'right' }}
                        />
                        <label className="col-form-label labelDown">
                            Monto Total
                        </label>
                    </div>
                    <div className="mb-3 row" style={{ marginTop: '20px', marginBottom: '0px' }}>
                        <div className="col-md-12">
                            <label className="col-form-label labelDown labelRed">(*) Campos obligatorios</label>
                            <label className="col-form-label labelDown"></label>
                        </div>
                    </div>

                </div>
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => onCloseClick()}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                >
                    Cerrar
                </button>
                <button
                    type="button"
                    className="btn btn-danger waves-effect waves-light"
                    disabled={blockBtn}
                    onClick={() => FuncionValidarFormulario()}
                >
                    Guardar
                </button>
            </div>
        </Modal>
    )
}
export default ModalHorasLaboradas