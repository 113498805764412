import React, { useState, useEffect, useContext } from "react"
import { getDecryptedItem } from '../../../../util';
import {
    Col,
    Row,
    Modal,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    CardText,
} from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faTimes,
    faChevronLeft,
    faChevronRight,
    faPaperPlane,
    faGlobe,
} from "@fortawesome/free-solid-svg-icons"
import logo from "../../../../assets/images/logo-dark.png"
import Select from "react-select"
import ComboContext from "pages/Menus/Context/Combos/ComboContext"
import CasoContext from "pages/Menus/Context/Casos/CasoContext"
var meses = new Array("Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre");
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var mesIni = Fecha.getMonth() - 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
if (mesIni < 10) mesIni = "0" + mesIni //agrega cero si el menor de 10
import Swal from "sweetalert2"
const ModalCasoF = ({ show, onCloseClick, onCloseModal, Rows, Condicion }) => {
    const comboContext = useContext(ComboContext);
    const casoContext = useContext(CasoContext);
    const [getHiddenEstadoInterno, setHiddenEstadoInterno] = useState("")
    const [chekedValor1, setchekedValor1] = useState(false)
    const [chekedValor2, setchekedValor2] = useState(false)
    const [chekedValor3, setchekedValor3] = useState(false)
    const [chekedValor4, setchekedValor4] = useState(false)
    const [chekedValor5, setchekedValor5] = useState(false)

    const [Valor1, setValor1] = useState(true)
    const [Valor2, setValor2] = useState(true)
    const [Valor3, setValor3] = useState(true)
    const [Valor4, setValor4] = useState(true)
    const [Valor5, setValor5] = useState(true)
    const [hiddenCliente, setHiddenCliente] = useState(false)
    const [condicion, setcondicion] = useState(false)
    const [getFiltro, setFiltro] = useState({
        nCli_Id: null,
        cCas_Tab_Materia: null,
        cCas_Tab_SubMateria: null,
        bCas_Estado: null,
        nUsu_ID: getDecryptedItem("ID"),
        FechaInicial: ano + "-" + "01" + "-" + "01",
        FechaFin: ano + "-" + mes + "-" + dia,
    })
    const handleChangeFiltro = e => {
        if (e.target.name == 'FechaInicial' || e.target.name == 'FechaFin') {
            setFiltro({
                ...getFiltro,
                [e.target.name]: e.target.value
            })
            return
        }
        if (chekedValor1 == true || chekedValor2 == true || chekedValor3 == true || chekedValor4 == true || chekedValor5 == true) {
            setFiltro({
                ...getFiltro,
                [e.target.name]: e.target.value
            })
        } else {
            setFiltro({
                ...getFiltro,
                [e.target.name]: null
            })

        }
    }
    const handleChangeValor1 = e => {
        setchekedValor1(!chekedValor1)
        if (chekedValor1 == true) {
            setFiltro({
                ...getFiltro,
                nCli_Id: null
            })
            setValor1(true)
        } else {
            var Valor = getFiltro.nCli_Id

            setFiltro({
                ...getFiltro,
                nCli_Id: Valor
            })
            setValor1(false)
        }
    }
    const handleChangeValor2 = e => {
        setchekedValor2(!chekedValor2)
        if (chekedValor2 == true) {
            setFiltro({
                ...getFiltro,
                cCas_Tab_Materia: null
            })
            setValor2(true)
        } else {
            var Valor = getFiltro.cCas_Tab_Materia

            setFiltro({
                ...getFiltro,
                cCas_Tab_Materia: Valor
            })
            setValor2(false)
        }
    }
    const handleChangeValor3 = e => {
        setchekedValor3(!chekedValor3)
        if (chekedValor3 == true) {
            setFiltro({
                ...getFiltro,
                cCas_Tab_SubMateria: null
            })
            setValor3(true)
        } else {
            var Valor = getFiltro.cCas_Tab_SubMateria

            setFiltro({
                ...getFiltro,
                cCas_Tab_SubMateria: Valor
            })
            setValor3(false)
        }
    }
    const handleChangeValor4 = e => {
        setchekedValor4(!chekedValor4)
        if (chekedValor4 == true) {
            setFiltro({
                ...getFiltro,
                bCas_Estado: null
            })
            setValor4(true)
        } else {
            var Valor = getFiltro.bCas_Estado

            setFiltro({
                ...getFiltro,
                bCas_Estado: Valor
            })
            setValor4(false)
        }
    }
    const handleChangeValor5 = e => {
        setchekedValor5(!chekedValor5)
        if (chekedValor5 == true) {
            setFiltro({
                ...getFiltro,
                nUsu_ID: null
            })
            setValor5(true)
        } else {
            var Valor = getFiltro.nUsu_ID

            setFiltro({
                ...getFiltro,
                nUsu_ID: Valor
            })
            setValor5(false)
        }
    }

    const handleChangeFiltroNExpediente = e => {
        setcondicion(true)
        if (Condicion == 1) {
            if (e.target.value.length > 0) {
                casoContext.FuncionFiltroCasosExpediente(casoContext.casos.filter(DatosAdd =>
                    DatosAdd.cCas_Cod_Externo.includes(e.target.value) || DatosAdd.cCli_NombreCompleto.toLowerCase().includes(e.target.value.toLowerCase())
                ))
            } else {
                casoContext.FuncionListarCasosDesactivar()
            }
        } else {
            if (e.target.value.length > 0) {
                casoContext.FuncionFiltroCasosExpediente(casoContext.casos.filter(DatosAdd =>
                    DatosAdd.cCas_Cod_Externo.includes(e.target.value) || DatosAdd.cCli_NombreCompleto.toLowerCase().includes(e.target.value.toLowerCase())
                ))
            } else {
                casoContext.FuncionFiltroCasos(getFiltro)
            }
        }
    }
    const FuncionFinalizar = () => {
        if (condicion == true) {
            onCloseModal()
        } else {
            console.log(getFiltro);
            casoContext.FuncionFiltroCasos(getFiltro), onCloseModal()
        }
    }
    useEffect(() => {

        if (getDecryptedItem("RolID") == 9) {
            setHiddenCliente(true);
        }

        setcondicion(false)
        /* Funciones para listar los Combos */
        comboContext.FuncionListarCliente()
        comboContext.FuncionListarMateria()
        comboContext.FuncionListarSubMateria()
        comboContext.FuncionListarEstadoExterno()
        comboContext.FuncionListarEstadoInterno()
        comboContext.FuncionListarUsuario()
        comboContext.FuncionListarMoneda()
        if (Condicion == 1) {
            setHiddenEstadoInterno("none")
            setFiltro({
                ...getFiltro,
                bCas_Estado: 'EP05'
            })
        }
    }, [])
    return (
        <Modal size="lg" isOpen={show} >
            <div
                className="modal-header ModalHeaderColor"
            >
                <h5
                    style={{ color: "#fff" }}
                    className="modal-title mt-0"
                    id="myModalLabel"
                >
                    Seleccione criterio(s) de búsqueda
                </h5>
                <button
                    type="button"
                    onClick={() => onCloseModal()}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <strong>Fechas de apertura (rango)</strong>
                <hr style={{ color: "#000", marginTop: "0px" }} />
                <div className="mb-3 row">
                    <div className="col-md-1" style={{ width: "20px" }}>
                    </div>
                    <div className="col-md-5">
                        <input
                            className="form-control inputDown"
                            type="date" name="FechaInicial" value={getFiltro && getFiltro.FechaInicial} onChange={handleChangeFiltro}
                        />
                        <label className="col-form-label labelDown">
                            Inicio*
                        </label>
                    </div>
                    <div className="col-md-1" style={{ width: "20px" }}>
                    </div>
                    <div className="col-md-5">
                        <input
                            className="form-control inputDown"
                            type="date" name="FechaFin" value={getFiltro && getFiltro.FechaFin} onChange={handleChangeFiltro}
                        />
                        <label className="col-form-label labelDown">
                            Fin*
                        </label>
                    </div>
                </div>
                {/* <div className="mb-3 row">
                    <div className="col-md-1" style={{ width: "20px" }}>
                    </div>
                    <div className="col-md-5">
                        <input
                            className="form-control inputDown"
                            type="text" placeholder="🔎" name="cCas_Expediente" onChange={handleChangeFiltroNExpediente}
                        />
                        <label className="col-form-label labelDown">
                            Numero de expediente o nombre del cliente
                        </label>
                    </div>
                </div> */}
                <div className="mb-3 row">
                    <div className="col-md-1" hidden={hiddenCliente} style={{ width: "20px" }}>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckcolor1"
                            checked={chekedValor1}
                            value={chekedValor1}
                            onChange={handleChangeValor1}
                            style={{ transform: "scale(1.5)", marginTop: "10px" }}
                        />
                    </div>
                    <div className="col-md-5" hidden={hiddenCliente}>
                        <select
                            className="form-select inputDown"
                            name="nCli_Id" onChange={handleChangeFiltro} disabled={Valor1}
                        >
                            <option value="00">Seleccionar...</option>
                            {comboContext.clientes.map((row, i) => (
                                <option key={i} value={row.nCli_Id}>
                                    {row.cCli_NombreCompleto}
                                </option>
                            ))}
                        </select>
                        <label className="col-form-label labelDown">
                            Seleccione cliente
                        </label>
                    </div>
                    <div className="col-md-1" style={{ width: "20px" }}>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckcolor1"
                            checked={chekedValor2}
                            value={chekedValor2}
                            onChange={handleChangeValor2}
                            style={{ transform: "scale(1.5)", marginTop: "10px" }}
                        />
                    </div>
                    <div className="col-md-5">
                        <select
                            className="form-select inputDown"
                            name="cCas_Tab_Materia" onChange={handleChangeFiltro} disabled={Valor2}
                        >
                            <option value="00">Seleccionar...</option>
                            {comboContext.materia.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                    {row.cTabNombre}
                                </option>
                            ))}
                        </select>
                        <label className="col-form-label labelDown">
                            Seleccione materia
                        </label>
                    </div>
                </div>
                <div className="mb-3 row">
                    <div className="col-md-1" style={{ width: "20px" }}>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckcolor1"
                            checked={chekedValor3}
                            value={chekedValor3}
                            onChange={handleChangeValor3}
                            style={{ transform: "scale(1.5)", marginTop: "10px" }}
                        />
                    </div>
                    <div className="col-md-5">
                        <select
                            className="form-select inputDown"
                            name="cCas_Tab_SubMateria" onChange={handleChangeFiltro} disabled={Valor3}
                        >
                            <option value="00">Seleccionar...</option>
                            {comboContext.submateria.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                    {row.cTabNombre}
                                </option>
                            ))}
                        </select>
                        <label className="col-form-label labelDown">
                            Seleccione submateria
                        </label>
                    </div>
                    <div className="col-md-1" style={{ width: "20px" }}>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckcolor1"
                            checked={chekedValor5}
                            value={chekedValor5}
                            onChange={handleChangeValor5}
                            style={{ transform: "scale(1.5)", marginTop: "10px" }}
                        />
                    </div>
                    <div className="col-md-5">
                        <select
                            className="form-select inputDown"
                            name="nUsu_ID" disabled={Valor5}
                        >
                            <option value="00">Seleccionar...</option>
                            {comboContext.cusuario.map((row, i) => (
                                <option key={i} value={row.nUsu_ID}>
                                    {row.cUsu_Nombres}
                                </option>
                            ))}
                        </select>
                        <label className="col-form-label labelDown">
                            Responsable
                        </label>
                    </div>
                </div>
                <div className="mb-3 row" style={{ display: getHiddenEstadoInterno }}>
                    <div className="col-md-1" style={{ width: "20px" }}>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckcolor1"
                            checked={chekedValor4}
                            value={chekedValor4}
                            onChange={handleChangeValor4}
                            style={{ transform: "scale(1.5)", marginTop: "10px" }}
                        />
                    </div>
                    <div className="col-md-5" >
                        <select
                            className="form-select inputDown"
                            name="bCas_Estado" onChange={handleChangeFiltro} disabled={Valor4}
                        >
                            <option value="00">Seleccionar...</option>
                            {comboContext.estadointerno.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                    {row.cTabNombre}
                                </option>
                            ))}
                        </select>
                        <label className="col-form-label labelDown">
                            Estado interno
                        </label>
                    </div>
                    <div className="col-md-1" style={{ width: "20px" }}>
                    </div>
                    <div className="col-md-5" hidden>
                        <select
                            className="form-select inputDown"
                            name="cCli_Tab_TipoDoc"
                        >
                            <option value="00">Seleccionar...</option>
                            {comboContext.cusuario.map((row, i) => (
                                <option key={i} value={row.nUsu_ID}>
                                    {row.cUsu_Nombres}
                                </option>
                            ))}
                        </select>
                        <label className="col-form-label labelDown">
                            Creado por
                        </label>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => onCloseModal()}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                >
                    Cerrar
                </button>
                <button
                    type="button"
                    className="btn btn-danger waves-effect waves-light"
                    onClick={() => { FuncionFinalizar() }}
                >
                    Filtrar
                </button>
            </div>
        </Modal>
    )
}
export default ModalCasoF