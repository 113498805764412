import React, { useState, useEffect } from "react"
import { useContext } from "react"
import {
  Modal,
} from "reactstrap"
import ComboContext from "../../Context/Combos/ComboContext";
import Select from "react-select"
import instance from '../../../../config';
import Swal from "sweetalert2"
import { getDecryptedItem } from '../../../../util';

var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
const baseUrl = process.env.REACT_APP_API_URL
const ModalEvento = ({ show, onCloseClick, onCloseModal, Condicion, Id, Row, arrayParticipantesEventos, mode }) => {
  const comboContext = useContext(ComboContext);
  const [modalActualizar, setModalActualizar] = useState(false)
  const [getDisableCombo, setDisableCombo] = useState(false)
  const [getDisableALL, setDisableALL] = useState(false)
  const [GetParticipantes, setParticipantes] = useState([])
  const [getErrores, setErrores] = useState({});
  const [dataCliente, setDataCliente] = useState([])
  const [dataCaso, setDataCaso] = useState([])
  const [hiddenBtnGuardar, setHiddenBtnGuardar] = useState(false)

  const [dataCategoriaEvento, setDataCategoriaEvento] = useState([])
  const [dataTipoEvento, setDataTipoEvento] = useState([])
  const [dataRecordarEvento, setDataRecordarEvento] = useState([])
  const [dataComboUsuario, setDataComboUsuario] = useState([])
  const [dataEstadoEvento, setDataEstadoEvento] = useState([])
  const [dataParticipantes, setDataParticipantes] = useState([])
  const [hiddenBtnEditar, setHiddenBtnEditar] = useState(true)
  const [Readonly, setReadonly] = useState(true)
  const [hiddenBtnEliminar, setHiddenBtnEliminar] = useState(true)
  const [tituloModal, setTituloModal] = useState("Editar evento")
  const [getEvento, setEvento] = useState({
    nEve_Id: "",
    nCas_Id: "",
    cEve_Tab_Tipo: "NN00",
    cEve_Tab_Categoria: "NN00",
    cEve_Titulo: "",
    cEve_Ubicacion: "",
    cEve_Descripcion: "",
    dEve_FechaIni: ano + "-" + mes + "-" + dia,
    dEve_FechaFin: ano + "-" + mes + "-" + dia,
    bEve_TodoDía: "",
    tEve_HoraIni: "",
    tEve_HoraFin: "",
    cEve_tab_Recordar: "NN00",
    cEve_Estado: "",
    bEve_Estado: 1,
    nCli_Id: "",
  })
  const Validacion = (form) => {
    let errors = {};
    let regexLetras = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    let regexCorreo = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    let regexNumero = /^[0-9]+$/;

    if (form.nCli_Id == 0) {
      errors.nCli_Id = "(*)"
    }

    if (form.nCas_Id == 0) {
      errors.nCas_Id = "(*)"
    }

    if (form.cEve_Tab_Categoria == 0) {
      errors.cEve_Tab_Categoria = "(*)"
    }

    if (!form.cEve_Titulo.trim()) {
      errors.cEve_Titulo = "(*)"
    }

    if (form.cEve_tab_Recordar == 0) {
      errors.cEve_tab_Recordar = "(*)"
    }

    if (form.bEve_TodoDía == 3) {
      errors.bEve_TodoDía = "(*)"
    }

    if (form.cEve_Tab_Tipo == 0) {
      errors.cEve_Tab_Tipo = "(*)"
    }

    if (form.cEve_Estado == 0) {
      errors.cEve_Estado = "(*)"
    }

    return errors;
  }
  const UsuariosSelect = dataComboUsuario.map((row, i) => ({
    value: row.nUsu_ID,
    label: row.cUsu_Nombres,
    email: row.cUsu_email,
  }));
  const handleBlur = e => {
    handleChange(e)
    setErrores(Validacion(getEvento))

  }
  const handleChange = e => {
    const { name, value } = e.target
    setEvento(prevState => ({
      ...prevState,
      [name]: value,
    }))
    if (name == "dEve_FechaIni") {
      setEvento(prevState => ({
        ...prevState,
        dEve_FechaFin: value,
      }))
    }


  }
  const handleChangeFiltroCaso = e => {

    if (e.target.value == 0) {
      comboContext.FuncionListarCaso(null)
    } else {
      comboContext.FuncionListarCaso(e.target.value)
    }
  }
  const FuncionListarComboCategoriaEvento = async () => {
    var postData = {
      Prefijo: "CE",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataCategoriaEvento(response.data)

      })
      .catch(error => {

      })
  }
  const handleChangeParticipantes = value => {
    if (value == null) {

    } else {

      setParticipantes(value);
    }

  };

  const FuncionActualizarParticipantes = async (_nEve_Id, _nUsu_ID) => {
    var postData = {
      nEve_Id: Number.parseInt(_nEve_Id),
      nUsu_ID: Number.parseInt(_nUsu_ID),
    }

    await instance
      .post("/Evento/Actualizar/Participante", postData)
      .then(response => {
      })
      .catch(error => {

      })
  }

  const FuncionEliminarParticipantes = async (_nEve_Id) => {
    var postData = {
      nEve_Id: Number.parseInt(_nEve_Id),
    }

    await instance
      .post("/Evento/Eliminar/Participante", postData)
      .then(response => {
      })
      .catch(error => {

      })
  }
  const FuncionValidarFormulario = async () => {
    if (Object.keys(getErrores).length === 0) {
      FuncionActualizarEvento()

    } else {
      Swal.fire({
        title: "Mensaje",
        text: "Complete los campos requeridos",
        icon: "error",
        confirmButtonText: "Aceptar",
      })
    }
  }
  const FuncionListarComboUsuario = async () => {
    await instance
      .get("/Usuario/Listar")
      .then(response => {
        setDataComboUsuario(response.data)

      })
      .catch(error => {

      })
  }


  const FuncionListarEventosParticipantes = async () => {
    await instance
      .post("/Eventos/Participante")
      .then(response => {

        setDataParticipantes(response.data)

      })
      .catch(error => {

      })
  }
  const FuncionListarEstadoEvento = async () => {
    var postData = {
      Prefijo: "TT",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataEstadoEvento(response.data)

      })
      .catch(error => {

      })
  }
  const FuncionListarComboRecordarEvento = async () => {
    var postData = {
      Prefijo: "PE",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataRecordarEvento(response.data)

      })
      .catch(error => {

      })
  }
  const FuncionListarComboTipoEvento = async () => {
    var postData = {
      Prefijo: "TE",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataTipoEvento(response.data)

      })
      .catch(error => {

      })
  }
  const abrirCerrarModal = Modal => {
    if (Modal == 2) {
      setModalActualizar(!show) /////////// AQUI SE LLAMA AL MODAL EDITAR EVENTO !

    }
  }
  const FuncionListarEventos = async () => {
    var postData = {
      nUsu_ID: getDecryptedItem("ID"),
    }
    await instance
      .post("/Eventos/DH", postData)
      .then(response => {

        setEvento(response.data)

      })
      .catch(error => {

      })
  }
  const FuncionActualizarEvento = async () => {
    var postData = {
      nEve_Id: getEvento.nEve_Id,
      nCas_Id: Number.parseInt(getEvento.nCas_Id),
      cEve_Tab_Tipo: getEvento.cEve_Tab_Tipo,
      cEve_Tab_Categoria: getEvento.cEve_Tab_Categoria,
      cEve_Titulo: getEvento.cEve_Titulo,
      cEve_Ubicacion: getEvento.cEve_Ubicacion,
      cEve_Descripcion: getEvento.cEve_Descripcion,
      dEve_FechaIni: getEvento.dEve_FechaIni,
      dEve_FechaFin: getEvento.dEve_FechaFin,
      bEve_TodoDía: Number.parseInt(getEvento.bEve_TodoDía),
      tEve_HoraIni: getEvento.tEve_HoraIni,
      tEve_HoraFin: getEvento.tEve_HoraFin,
      tEve_HoraIni2: getEvento.tEve_HoraIni2,
      tEve_HoraFin2: getEvento.tEve_HoraFin2,
      cEve_tab_Recordar: getEvento.cEve_tab_Recordar,
      bEve_Estado: Number.parseInt(getEvento.bEve_Estado),
      cEve_Estado: getEvento.cEve_Estado
    }

    await instance
      .post("/Evento/Actualizar", postData)
      .then(async response => {


        let postData2 = {
          cAud_nombre: "Eventos",
          nAud_IdReg: postData.nEve_Id,
          nUsu_ID: getDecryptedItem("ID"),
          dAud_Fecha: null,
          nAud_Accion: "U",
          cTabCodigo: 'ME04'
        }

        await instance
          .post("/Auditoria/Insertar", postData2)
          .then(response => {
          });

        abrirCerrarModal(2)



        FuncionEliminarParticipantes(getEvento.nEve_Id);
        setTimeout(() => {
          const Participantes = GetParticipantes.map((row, i) => {
            FuncionActualizarParticipantes(getEvento.nEve_Id, row.value)
          })

          Swal.fire({
            title: "Mensaje",
            text: "Actualizado exitosamente",
            icon: "success",
            confirmButtonText: "Aceptar",
          })
        }, 800);

      })
      .catch(error => {

      })


    var nomCat = undefined;
    for (let i = 0; i < dataCategoriaEvento.length; i++) {

      if (dataCategoriaEvento[i].cTabCodigo == getEvento.cEve_Tab_Categoria) {
        nomCat = dataCategoriaEvento[i].cTabNombre;
      }
    }

    /****** Enviar correos **** */
    let objMails = {
      categoria: nomCat,
      idCaso: getEvento.nCas_Id,
      cliente: getEvento.nCli_Id,
      parametro: {
        tpoTbl: 'evento',   // tipo de tabla
        tpoAct: 'actualizar'  // insertar o actualizar
      },
      dataEvent: postData,
      arrayParticipantes: GetParticipantes
    }


    await instance
      .post("/mail/event/evento", objMails)
      .then(res => {



      })
      .catch(error => {

      })
    /****** Enviar correos **** */
    onCloseModal();

  }
  const FuncionListarCliente = async () => {
    let RolId = getDecryptedItem("RolID")

    var postData = {
      bCli_Tipo: null,
      nUsu_ID: RolId == 6 || RolId == 1 ? null : getDecryptedItem("ID")
    }
    await instance
      .post("/Cliente/ListarCbo", postData)
      .then(response => {
        setDataCliente(response.data)

      })
      .catch(error => {

      })
  }

  const FuncionListarCaso = async (_idClient) => {
    var postData = {
      idClient: _idClient,
      nUsu_Id: getDecryptedItem("ID"),
    }
    await instance
      .post("/Casos/ListarCbo", postData)
      .then(response => {
        setDataCaso(response.data)

      })
      .catch(error => {

      })
  }
  const FuncionListarPermisos = async () => {
    var postData = {
      nUsu_ID: parseInt(getDecryptedItem("RolID"))
    }
    await instance
      .post("/Usuario/Acceso", postData)
      .then(response => {
        let arrayPermisos = response.data;
        const permiso = arrayPermisos.find(element => element.cUsu_Modulo == "ME04");

        if (permiso.bUsu_Editar == 1) {
          setHiddenBtnEditar(false);
        }
        else {
          setHiddenBtnEditar(true);
        }
        if (permiso.bUsu_Eliminar == 1) {
          setHiddenBtnEliminar(false);
        }
        else {
          setHiddenBtnEliminar(true);
        }
      })
      .catch(error => {

      })
  }

  useEffect(() => {
    FuncionListarPermisos();

    if (mode == "Readonly") {
      console.log("Readonly evento")
      setHiddenBtnGuardar(true);
      setTituloModal("Ver evento")
    }
    setEvento(Row)
    setParticipantes(arrayParticipantesEventos);
    FuncionListarComboTipoEvento()        //Registrado
    FuncionListarComboCategoriaEvento()   //Registrado
    FuncionListarComboRecordarEvento()    //Registrado
    FuncionListarComboUsuario()           //Registrado
    FuncionListarEstadoEvento()

    FuncionListarCliente()
    FuncionListarCaso(null)
    //comboContext.FuncionListarCatEvento()
    //comboContext.FuncionListarTipEvento()
    //comboContext.FuncionListarRecordar()
    //comboContext.FuncionListarEstado() 

  }, []);

  return (

    <Modal isOpen={show}>
      <div
        className="modal-header ModalHeaderColor"
      >
        <h5
          style={{ color: "#fff" }}
          className="modal-title mt-0"
          id="myModalLabel"
        >
          {tituloModal}
        </h5>
        <button
          type="button"
          onClick={() => onCloseModal()}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3 row">
          <div className="col-md-6">
            <select className="form-select inputDown" disabled="true"
              name="nCli_Id"
              value={getEvento && getEvento.nCli_Id}
            >
              <option value="0">Seleccionar...</option>
              {dataCliente.map((row, i) => (
                <option key={i} value={row.nCli_Id}>
                  {row.cCli_NombreCompleto}
                </option>
              ))}
            </select>
            <label className="col-form-label labelDown">
              Cliente
            </label>
          </div>
          <div className="col-md-6">
            <select
              disabled="true"
              className="form-select inputDown"
              name="nCas_Id"
              onChange={handleChange}
              value={getEvento && getEvento.nCas_Id}
            >
              <option value="0">Seleccionar...</option>
              {dataCaso.map((row, i) => (
                <option key={i} value={row.nCas_Id}>
                  {row.cCas_Titulo}
                </option>
              ))}
            </select>
            <label className="col-form-label labelDown">
              Caso
            </label>
          </div>
        </div>
        <div className="mb-1 row">
          <div className="col-md-5">
            <label
              htmlFor="example-text-input"
              className="col-md-12 col-form-label"
              style={{ marginLeft: "5px" }}
            >
              Cod Interno: {getEvento.cCas_Cod_Interno}
            </label>
          </div>
          <div className="col-md-7" >
            <label
              htmlFor="example-text-input"
              className="col-md-12 col-form-label"
              style={{ marginLeft: "2px" }}
            >
              Cod Externo: {getEvento.cCas_Cod_Externo}
            </label>
          </div>
        </div>

        <div className="mb-3 row" style={{ marginTop: '15px' }}>
          <div className="col-md-6">
            <select
              disabled={hiddenBtnGuardar}
              className="form-select"
              name="cEve_Tab_Categoria"
              onChange={handleChange}
              onBlur={handleBlur}
              value={getEvento && getEvento.cEve_Tab_Categoria}
            >
              <option value="0">Seleccionar...</option>
              {dataCategoriaEvento.map((row, i) => (
                <option key={i} value={row.cTabCodigo}>
                  {row.cTabNombre}
                </option>
              ))}
            </select>
            <label className="col-form-label labelDown">Categoría</label>{getErrores.cEve_Tab_Categoria && <label className="col-form-label labelDown labelRed">{getErrores.cEve_Tab_Categoria}</label>}
          </div>
          <div className="col-md-6">
            <input
              disabled={hiddenBtnGuardar}
              className="form-control"
              placeholder="Ingrese el título"
              name="cEve_Titulo"
              onChange={handleChange}
              onBlur={handleBlur}
              value={getEvento && getEvento.cEve_Titulo}
              maxLength="200"
            />
            <label className="col-form-label labelDown">Título</label>{getErrores.cEve_Titulo && <label className="col-form-label labelDown labelRed">{getErrores.cEve_Titulo}</label>}
          </div>
        </div>
        <div className="mb-3 row">
          <div className="col-md-12">
            <textarea
              disabled={hiddenBtnGuardar}
              className="form-control"
              placeholder="Ingrese la descripción"
              name="cEve_Descripcion"
              value={getEvento && getEvento.cEve_Descripcion}
              onChange={handleChange}
              maxLength="200"
            ></textarea>
            <label className="col-form-label labelDown">
              Descripción
            </label>
          </div>
        </div>
        <div className="mb-3 row">
          <div className="col-md-12">
            <Select
              isDisabled={hiddenBtnGuardar}
              isMulti
              isClearable
              onChange={handleChangeParticipantes}
              className="basic-multi-select"
              placeholder="Ingrese los participantes"
              options={UsuariosSelect}
              defaultValue={GetParticipantes}
            />
            <label className="col-form-label labelDown">
              Participantes
            </label>
          </div>
        </div>
        <div className="mb-3 row">
          <div className="col-md-12">
            <input
              disabled={hiddenBtnGuardar}
              className="form-control"
              placeholder="Ingrese la ubicación"
              name="cEve_Ubicacion"
              value={getEvento && getEvento.cEve_Ubicacion}
              onChange={handleChange}
              maxLength="200"
            />
            <label className="col-form-label labelDown">
              Ubicación
            </label>
          </div>
        </div>
        <div className="mb-3 row">
          <div className="col-md-6">
            <input
              disabled={hiddenBtnGuardar}
              className="form-control"
              type="date"
              name="dEve_FechaIni"
              value={getEvento && getEvento.dEve_FechaIni}
              onChange={handleChange}
            />
            <label className="col-form-label labelDown">
              Fecha de inicio
            </label>
          </div>
          <div className="col-md-6">
            <input
              disabled={hiddenBtnGuardar}
              className="form-control"
              type="time"
              name="tEve_HoraIni"
              value={getEvento && getEvento.tEve_HoraIni}
              onChange={handleChange}
            />
            <label className="col-form-label labelDown">
              Hora de inicio
            </label>
          </div>
        </div>
        <div className="mb-3 row">
          <div className="col-md-6">
            <input
              disabled={hiddenBtnGuardar}
              className="form-control"
              type="date"
              name="dEve_FechaFin"
              value={getEvento && getEvento.dEve_FechaFin}
              onChange={handleChange}
            />
            <label className="col-form-label labelDown">
              Fecha de fin
            </label>
          </div>
          <div className="col-md-6">
            <input
              disabled={hiddenBtnGuardar}
              className="form-control"
              type="time"
              name="tEve_HoraFin"
              value={getEvento && getEvento.tEve_HoraFin}
              onChange={handleChange}
            />
            <label className="col-form-label labelDown">
              Hora de fin
            </label>
          </div>
        </div>
        <div className="mb-3 row">
          <div className="col-md-6">
            <select
              disabled={hiddenBtnGuardar}
              className="form-select"
              name="cEve_tab_Recordar"
              value={getEvento && getEvento.cEve_tab_Recordar}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="0">Seleccionar...</option>
              {dataRecordarEvento.map((row, i) => (
                <option key={i} value={row.cTabCodigo}>
                  {row.cTabNombre}
                </option>
              ))}
            </select>
            <label className="col-form-label labelDown">Recordar</label>{getErrores.cEve_tab_Recordar && <label className="col-form-label labelDown labelRed">{getErrores.cEve_tab_Recordar}</label>}
          </div>
          <div className="col-md-6">
            <select
              disabled={hiddenBtnGuardar}
              className="form-select"
              name="bEve_TodoDía"
              value={getEvento && getEvento.bEve_TodoDía}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="00">Seleccionar...</option>
              <option value="1">Si</option>
              <option value="0">No</option>
            </select>
            <label className="col-form-label labelDown">Todo el día</label>{getErrores.bEve_TodoDía && <label className="col-form-label labelDown labelRed">{getErrores.bEve_TodoDía}</label>}
          </div>
        </div>
        <div className="mb-3 row">
          <div className="col-md-6">
            <select
              disabled={hiddenBtnGuardar}
              className="form-select"
              name="cEve_Tab_Tipo"
              onChange={handleChange}
              onBlur={handleBlur}
              value={getEvento && getEvento.cEve_Tab_Tipo}
            >
              <option value="00">Seleccionar...</option>
              {dataTipoEvento.map((row, i) => (
                <option key={i} value={row.cTabCodigo}>
                  {row.cTabNombre}
                </option>
              ))}
            </select>
            <label className="col-form-label labelDown">Tipo de Evento</label>{getErrores.cEve_Tab_Tipo && <label className="col-form-label labelDown labelRed">{getErrores.cEve_Tab_Tipo}</label>}
          </div>
          <div hidden className="col-md-6">
            <select
              disabled={hiddenBtnGuardar}
              className="form-select"
              name="bEve_Estado"
              value={getEvento && getEvento.bEve_Estado}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="00">Seleccionar...</option>
              <option value="1">Activo</option>
              <option value="0">No activo</option>
            </select>
            <label className="col-form-label labelDown">Estado</label>{getErrores.cEve_Estado && <label className="col-form-label labelDown labelRed">{getErrores.cEve_Estado}</label>}
          </div>
        </div>
        <div className="mb-3 row" style={{ marginTop: '20px', marginBottom: '0px' }}>
          <div className="col-md-12">
            <label className="col-form-label labelDown labelRed">(*) Campos obligatorios</label>
            <label className="col-form-label labelDown"></label>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => onCloseModal()}
          className="btn btn-secondary waves-effect"
          data-dismiss="modal"
        >
          Cerrar
        </button>
        <button
          hidden={hiddenBtnEditar || hiddenBtnGuardar}
          type="button"
          className="btn btn-danger waves-effect waves-light"
          onClick={() => FuncionValidarFormulario()}
        >
          Guardar
        </button>
      </div>
    </Modal>
  )
}
export default ModalEvento;