import React, { Component, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTags from "react-meta-tags"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import Swal from "sweetalert2"
import { getDecryptedItem } from '../../util';
import {
  CardBody,
  Card,
  Col,
  Container,
  CardTitle,
  Button,
  Row,
  Modal,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap"
import instance from '../../config'
const baseUrl = process.env.REACT_APP_API_URL
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
const Maestros = props => {
  const [modalInsertar, setModalInsertar] = useState(false)
  const [modalActualizar, setModalActualizar] = useState(false)
  const [modalFiltro, setModalFiltro] = useState(false)
  const [data, setData] = useState([])
  const [dataCabecera, setDataCabecera] = useState([])
  const [cheked, setcheked] = useState(false)
  const [Valorcheked, SetValorCheked] = useState(null)
  const [blockBtn, setBlockBtn] = useState(false)
  const [getHidden, setHidden] = useState(true)
  const [getItemSelected, setItemSelected] = useState("")
  const [hiddenBtnEditar, setHiddenBtnEditar] = useState(true)
  const [hiddenBtnEliminar, setHiddenBtnEliminar] = useState(true)
  const [validData, setValidData] = useState(false)
  const [getMaestro, setMaestro] = useState({
    cTabCodigo: "",
    cTabSimbolo: "",
    cTabNombre: ""
  })
  const [getFiltro, setFiltro] = useState({
    CodigoCbo: "",
  })
  const [getErrores, setErrores] = useState({});
  const Validacion = (form) => {
    let errors = {};
    let regexLetras = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    let regexCorreo = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    let regexNumero = /^[0-9]+$/;
    if (form.cTabCodigo == "") {
      errors.cTabCodigo = "(*)"

    }
    if (form.cTabNombre == "") {
      errors.cTabNombre = "(*)"
    }
    if (form.cTabCodigo.substring(0, 2) == "MO") {
      if (form.cTabSimbolo == "") {
        errors.cTabSimbolo = "(*)"
      }
    }
    return errors;
  }
  const handleBlur = e => {
    setErrores(Validacion(getMaestro))

  }
  const handleChangeCheck = e => {
    setcheked(!cheked)
    if (cheked == false) {
      SetValorCheked("__00")
    } else {
      SetValorCheked(null)
    }
  }
  const handleChangeSelect = e => {
    const { name, value } = e.target
    setFiltro(prevState => ({
      ...prevState,
      [name]: value,
    }))
    if (value == 'null') {
      FuncionListarMaestrosF(null)
    } else {
      var Varible = value.substring(0, 2) + "%";
      FuncionListarMaestrosF(Varible)
    }
  }
  const handleChange = e => {
    const { name, value } = e.target
    setMaestro(prevState => ({
      ...prevState,
      [name]: value,
    }))

  }
  const abrirCerrarModal = Modal => {
    setValidData(false);
    if (Modal == 1) {
      setErrores(Validacion(getMaestro))
      if (!modalInsertar == false) {
        Swal.fire({
          html: "¿Seguro que desea salir sin guardar los datos?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        }).then(result => {
          if (result.isConfirmed) {
            setModalInsertar(!modalInsertar)
          }
        })
      } else {
        var Varible = getFiltro.CodigoCbo.substring(0, 2);

        FuncionListarMaestrosCorrelativo(Varible)
        setModalInsertar(!modalInsertar)
      }
    } else if (Modal == 2) {
      setModalActualizar(!modalActualizar)
      Validacion(getMaestro);
    } else if (Modal == 3) {
      setModalFiltro(!modalFiltro)
    }
  }
  const Acciones = (Maestros) => {
    setMaestro(Maestros)
    abrirCerrarModal(2)
  }
  const datas = {
    columns: [

      {
        label: "Categoría",
        field: "Haeader",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "ID",
        field: "cTabCodigo",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Nombre",
        field: "cTabNombre",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Código",
        field: "cTabSimbolo",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
    ],
    rows: data,
  }
  const badgesData = {
    columns: [
      ...datas.columns,
      {
        label: "Acciones",
        field: "acciones",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
    ],
    rows: [
      ...datas.rows.map((row, id) => ({
        ...row,
        acciones: (
          <div>
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="dropdown-toggle">
                <i className="bx bx-dots-vertical-rounded" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem hidden={hiddenBtnEditar} onClick={() => Acciones(row)}>Editar</DropdownItem>
                <DropdownItem hidden={hiddenBtnEliminar} onClick={() =>
                  Swal.fire({
                    title: "Mensaje",
                    html:
                      "Estas seguro de eliminar este registro",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Aceptar",
                    cancelButtonText: "Cancelar",
                  }).then(result => {
                    if (result.isConfirmed) {

                      FuncionEliminarMaestros(row.cTabCodigo)
                    }
                  })
                }>Eliminar</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        ),
      })),
    ],
  }
  const FuncionListarMaestrosF = async (Variable) => {
    var postData = {
      Prefijo: Variable,
    }
    await instance
      .post("/Maestros/Listar", postData)
      .then(response => {
        setData(response.data)

      })
      .catch(error => {

      })
  }
  const FuncionListarMaestros = async () => {
    var postData = {
      Prefijo: Valorcheked,
    }
    await instance
      .post("/Maestros/Listar", postData)
      .then(response => {
        setData(response.data)

      })
      .catch(error => {

      })
  }
  const FuncionListarMaestrosCabecera = async () => {
    await instance
      .post("/Maestros/ListarCBO")
      .then(response => {
        setDataCabecera(response.data)
      })
      .catch(error => {

      })
  }
  var Correlativo;
  const FuncionListarMaestrosCorrelativo = async (Variable) => {
    var postData = {
      Prefijo: Variable,
    }
    await instance
      .post("/Maestros/Correlativo", postData)
      .then(response => {
        var JsonCorrelativo = response.data
        for (var i = 0; i < JsonCorrelativo.length; i++) {
          Correlativo = JsonCorrelativo[i].Correlativo;
        }
        setMaestro(prevState => ({
          ...prevState,
          cTabCodigo: Correlativo,
        }))
      })
      .catch(error => {

      })
  }
  const handleChangeSelectInsert = e => {
    const { name, value } = e.target
    setFiltro(prevState => ({
      ...prevState,
      [name]: value,
    }))
    var Varible = value.substring(0, 2);
    FuncionListarMaestrosCorrelativo(Varible)
    if (value == "MO00") {
      setHidden(false)
    }
    else {
      setHidden(true)
    }

  }

  const FuncionValidarFormulario = async () => {

    if (Object.keys(getErrores).length === 0) {

      setBlockBtn(true);
      FuncionInsertarMaestros()
    } else {
      setValidData(true);
      Swal.fire({
        title: "Mensaje",
        text: "Complete los campos requeridos",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
  }
  const FuncionValidarFormularioActualizar = async () => {


    if (Object.keys(getErrores).length === 0) {
      setBlockBtn(true);
      FuncionActualizarMaestros()

    } else {
      setValidData(true);
      Swal.fire({
        title: "Mensaje",
        text: "Complete los campos requeridos",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
  }
  const FuncionInsertarMaestros = async () => {
    var postData = {
      cTabCodigo: getMaestro.cTabCodigo,
      cTabSimbolo: getMaestro.cTabSimbolo,
      cTabNombre: getMaestro.cTabNombre,
    }
    await instance
      .post("/Maestros/Insertar", postData)
      .then(response => {
        Swal.fire({
          title: "Mensaje",
          text: "Agregado exitosamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        })

        setBlockBtn(false);
        setModalInsertar(!modalInsertar)
        var Varible = getFiltro.CodigoCbo.substring(0, 2) + "%";
        FuncionListarMaestrosF(Varible)
        setMaestro(prevState => ({
          ...prevState,
          cTabCodigo: "",
          cTabSimbolo: "",
          cTabNombre: ""
        }))
        setValidData(false);
      })
      .catch(error => {

      })
  }
  const FuncionActualizarMaestros = async () => {
    var postData = {
      cTabCodigo: getMaestro.cTabCodigo,
      cTabSimbolo: getMaestro.cTabSimbolo,
      cTabNombre: getMaestro.cTabNombre,
    }
    await instance
      .post("/Maestros/Actualizar", postData)
      .then(response => {
        Swal.fire({
          title: "Mensaje",
          text: "Actualizado exitosamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        })
        setBlockBtn(false);
        setModalActualizar(!modalActualizar)
        var Varible = getFiltro.CodigoCbo.substring(0, 2) + "%";
        FuncionListarMaestrosF(Varible)
        setMaestro(prevState => ({
          ...prevState,
          cTabCodigo: "",
          cTabSimbolo: "",
          cTabNombre: ""
        }));
        setValidData(false);
      })
      .catch(error => {

      })
  }
  const FuncionEliminarMaestros = async (_cTabCodigo) => {
    var postData = {
      cTabCodigo: _cTabCodigo
    }
    await instance
      .post("/Maestros/Eliminar", postData)
      .then(response => {
        Swal.fire({
          title: "Mensaje",
          text: "Eliminado exitosamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        })
        var Varible = getFiltro.CodigoCbo.substring(0, 2) + "%";
        FuncionListarMaestrosF(Varible)
      })
      .catch(error => {

      })
  }
  const FuncionListarPermisos = async () => {
    var postData = {
      nUsu_ID: parseInt(getDecryptedItem("RolID"))
    }
    await instance
      .post("/Usuario/Acceso", postData)
      .then(response => {
        let arrayPermisos = response.data;
        const permiso = arrayPermisos.find(element => element.cUsu_Modulo == "ME22");

        if (permiso.bUsu_Editar == 1) {
          setHiddenBtnEditar(false);
        }
        else {
          setHiddenBtnEditar(true);
        }
        if (permiso.bUsu_Eliminar == 1) {
          setHiddenBtnEliminar(false);
        }
        else {
          setHiddenBtnEliminar(true);
        }
      })
      .catch(error => {

      })
  }
  useEffect(() => {
    FuncionListarPermisos();
    setErrores(Validacion(getMaestro))
    FuncionListarMaestros()
    FuncionListarMaestrosCabecera()
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        { }
        <MetaTags>
          <title>Configuración de datos maestros | GpsLegal</title>
        </MetaTags>
        <Container fluid>
          { }
          <Breadcrumbs title="GpsLegal" breadcrumbItem="Configuración de datos maestros" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div>
                    <button
                      type="button"
                      onClick={() => abrirCerrarModal(1)}
                      className="btn btn-danger waves-effect waves-light"
                      data-toggle="modal"
                      data-target="#myModal"
                      hidden={hiddenBtnEditar}
                    >
                      Nuevo Item
                    </button>{" "}
                    <button hidden
                      type="button"
                      onClick={() => abrirCerrarModal(3)}
                      className="btn btn-danger waves-effect waves-light"
                      data-toggle="modal"
                      data-target="#myModal"
                    >
                      Filtro
                    </button>

                    <Modal isOpen={modalInsertar}>
                      <div
                        className="modal-header ModalHeaderColor"
                      >
                        <h5
                          style={{ color: "#fff" }}
                          className="modal-title mt-0"
                          id="myModalLabel"
                        >
                          Nuevo Item
                        </h5>
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(1)}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <select
                              onChange={handleChangeSelectInsert}
                              onBlur={handleBlur}
                              name="CodigoCbo"
                              defaultValue={getFiltro && getFiltro.CodigoCbo}
                              className="form-select inputDown">
                              {dataCabecera.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Categoría
                            </label>
                            {/* <label className="col-form-label labelDown labelRed">{getErrores.cTabNombre}</label> */}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <input
                              className="form-control inputDown"
                              type="text"
                              placeholder="Codigo"
                              name="cTabCodigo"
                              id="cTabCodigo"
                              readOnly
                              value={getMaestro && getMaestro.cTabCodigo}
                            />
                            <label className="col-form-label labelDown">
                              Codigo
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <input
                              className={getErrores.cTabNombre && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                              type="text"
                              placeholder="Nombre"
                              name="cTabNombre"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              maxLength="200"
                            />
                            <label className="col-form-label labelDown">
                              Ingrese Nombre
                            </label>
                            <label className="col-form-label labelDown labelRed">{getErrores.cTabNombre}</label>
                          </div>
                        </div>
                        <div className="mb-3 row" hidden={getHidden}>
                          <div className="col-md-12">
                            <input
                              className="form-control inputDown"
                              type="text"
                              placeholder="Simbolo"
                              name="cTabSimbolo"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              maxLength="5"
                            />
                            <label className="col-form-label labelDown">
                              Ingrese Simbolo
                            </label>
                            <label className="col-form-label labelDown labelRed">{getErrores.cTabSimbolo}</label>
                          </div>
                        </div>

                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(1)}
                          className="btn btn-secondary waves-effect"
                          data-dismiss="modal"
                        >
                          Cerrar
                        </button>
                        <button
                          type="button"
                          disabled={blockBtn}
                          onClick={() => {
                            FuncionValidarFormulario(),
                              setMaestro(prevState => ({
                                ...prevState,
                                cTabCodigo: "",
                                cTabSimbolo: "",
                                cTabNombre: ""
                              }))
                          }}
                          className="btn btn-danger waves-effect waves-light"
                        >
                          Guardar
                        </button>
                      </div>
                    </Modal>
                    <Modal isOpen={modalFiltro}>
                      <div
                        className="modal-header ModalHeaderColor"
                      >
                        <h5
                          style={{ color: "#fff" }}
                          className="modal-title mt-0"
                          id="myModalLabel"
                        >
                          Filtro Maestro
                        </h5>
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(3)}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheckcolor1"
                              onChange={handleChangeCheck}
                              checked={cheked}
                              value={cheked}
                            />
                            <label className="col-form-label labelDown">
                              Solo Cabecera
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(3)}
                          className="btn btn-secondary waves-effect"
                          data-dismiss="modal"
                        >
                          Cerrar
                        </button>
                        <button
                          type="button"
                          onClick={() => FuncionListarMaestros()}
                          className="btn btn-danger waves-effect waves-light"
                        >
                          Filtrar
                        </button>
                      </div>
                    </Modal>
                    <Modal isOpen={modalActualizar}>
                      <div
                        className="modal-header ModalHeaderColor"
                      >
                        <h5
                          style={{ color: "#fff" }}
                          className="modal-title mt-0"
                          id="myModalLabel"
                        >
                          Editar Item
                        </h5>
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(2)}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <input
                              className="form-control inputDown"
                              type="text"
                              placeholder="Codigo"
                              name="cTabCodigo"
                              id="cTabCodigo"
                              readOnly
                              value={getMaestro && getMaestro.cTabCodigo}
                            />
                            <label className="col-form-label labelDown">
                              Codigo
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <input
                              className={getErrores.cTabNombre && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                              type="text"
                              placeholder="Nombre"
                              name="cTabNombre"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={getMaestro && getMaestro.cTabNombre}
                              maxLength="200"
                            />
                            <label className="col-form-label labelDown">
                              Ingrese Nombre
                            </label>
                            <label className="col-form-label labelDown labelRed">{getErrores.cTabNombre}</label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <input
                              className={getErrores.cTabSimbolo && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                              type="text"
                              placeholder="Simbolo"
                              name="cTabSimbolo"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={getMaestro && getMaestro.cTabSimbolo}
                              maxLength="5"
                            />
                            <label className="col-form-label labelDown">
                              Ingrese Simbolo
                            </label>
                            <label className="col-form-label labelDown labelRed">{getErrores.cTabSimbolo}</label>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => {
                            abrirCerrarModal(2),
                              setMaestro(prevState => ({
                                ...prevState,
                                cTabCodigo: "",
                                cTabSimbolo: "",
                                cTabNombre: ""
                              }))
                          }}
                          className="btn btn-secondary waves-effect"
                          data-dismiss="modal"
                        >
                          Cerrar
                        </button>
                        <button
                          type="button"
                          onClick={() => FuncionValidarFormularioActualizar()}
                          className="btn btn-danger waves-effect waves-light"
                        >
                          Guardar
                        </button>
                      </div>
                    </Modal>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>

                  <CardTitle style={{ fontSize: "20px", color: "#aa1b0E", fontWeight: "normal" }}>
                    <span style={{ color: "#aa1b0E" }}>Listado de datos maestros</span>{" "}
                  </CardTitle>
                  <div className="mb-3 row">
                    <div className="col-md-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-6 col-form-label"
                        style={{ fontSize: "15px" }}
                      >
                        Categoría
                      </label>
                    </div>
                    <div className="col-md-3" style={{ float: 'right', display: 'inline-block' }}>
                      <select onChange={handleChangeSelect} name="CodigoCbo" defaultValue={getFiltro && getFiltro.CodigoCbo} className="form-select inputDown">
                        <option value="null">Seleccionar...</option>
                        {dataCabecera.map((row, i) => (
                          <option key={i} value={row.cTabCodigo}>
                            {row.cTabNombre}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <MDBDataTable
                    data={badgesData}
                    className="TablaMaestro"
                    responsiveXl
                    fixed
                    hover
                    entriesLabel="Mostrar"
                    entrylabel={"Mostrar entradas"}
                    infoLabel={["Mostrando ", "a", "de", "registros"]}
                    bordered
                    order={["age", "desc"]}
                    noRecordsFoundLabel={"No hay datos"}
                    searchLabel={"Buscar"}
                    noBottomColumns
                    paginationLabel={["Anterior", "Siguiente"]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Maestros
