import React from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"

//Import Images
import error from "../../assets/images/Logo.png"

const AccessDenied = () => {
    //meta title
    document.title = "500 Error Page | GPSLegal";

    return (
        <React.Fragment>
            <div className="account-pages my-5 pt-5">
                <Container>
                    <Row className="justify-content-center">

                        <Col lg="12">
                            <div className="text-center mb-5" style={{ marginTop: '55px' }}>
                                <h1 style={{ fontSize: '50px' }} className="text-uppercase">Acceso denegado</h1>
                            </div>
                            <div className="text-center mb-5" style={{ marginTop: '30px' }}>
                                <p>
                                    <p style={{ fontSize: '18px' }} > Estimado cliente:</p>
                                    <p style={{ fontSize: '18px' }} > Actualmente no cuenta con acceso a esta vista.</p>
                                    <p style={{ fontSize: '18px' }} > Para asistencia y recursos instructivos:   <a href="https://asistencia.gpslegal.pe">asistencia.gpslegal.pe</a></p>
                                    <p style={{ fontSize: '18px' }} > Contacte a nuestro equipo en:  <a >ayuda@gpslegal.pe</a></p>
                                    <p style={{ fontSize: '18px' }} > Envíenos un mensaje a nuestro WhatsApp: +51 998440020</p>
                                </p>
                            </div>
                        </Col>
                        <Col md="8" xl="12">
                            <div>
                                <img src={error} className="img-fluid" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default AccessDenied
