import React, { useReducer } from "react";
import instance from '../../../../config';
import ClienteContext from "./ClienteContext";
import ClienteReducer from "./ClienteReducer";
import { getDecryptedItem } from '../../../../util';
import Swal from "sweetalert2"
import { GET_CLIENTE, ADD_CLIENTE, UPD_CLIENTE, DLT_CLIENTE, GET_CLIENTEULT } from "../Type";
const baseUrl = process.env.REACT_APP_API_URL
const ClienteState = (props) => {
    const initialState = {
        clientes: [],
        clientesult: []
    };
    const [state, dispatch] = useReducer(ClienteReducer, initialState);
    const FuncionListarCliente = async () => {
        try {
            let RolId = getDecryptedItem("RolID")

            var postData = {
                nUsu_ID: RolId == 6 || RolId == 1 || RolId == 7 ? null : getDecryptedItem("ID")
            }

            await instance
                .post("/Cliente/Listar", postData)
                .then(response => {

                    dispatch({ type: GET_CLIENTE, payload: response.data });
                })
                .catch(error => {

                })
        } catch (error) {
            console.error(error);
        }
    };
    const FuncionListarClienteUltimo = async () => {
        try {
            await instance
                .get("/Cliente/UltimoRegistrado")
                .then(response => {

                    dispatch({ type: GET_CLIENTEULT, payload: response.data });
                })
                .catch(error => {

                })
        } catch (error) {
            console.error(error);
        }
    };
    const FuncionRegistrarCliente = async (cliente) => {
        try {
            console.log(cliente);
            const postdata2 = {
                cCli_NombreCorto: parseInt(cliente.bCli_Tipo) == 1 ? cliente.cCli_RazonSocial : cliente.cCli_NombreCorto + ' ' + cliente.cCli_RazonSocial + ' ' + cliente.cCli_Materno,
            }
            await instance
                .post("/Cliente/ClienteValidarClienteExist", postdata2)
                .then(async response => {

                    console.log(response);
                    let resultado = response.data.length;
                    if (resultado > 0) {
                        Swal.fire({
                            title: "Mensaje",
                            text: "El cliente ya se encuentra registrado",
                            icon: "error",
                            confirmButtonText: "Aceptar",
                        })
                    }
                    else {
                        await instance
                            .post("/Cliente/Insertar", cliente)
                            .then(response => {
                                dispatch({ type: ADD_CLIENTE, resultado: response.data });
                                Swal.fire({
                                    title: "Mensaje",
                                    text: "Agregado exitosamente",
                                    icon: "success",
                                    confirmButtonText: "Aceptar",
                                })
                                FuncionListarCliente()
                            })
                            .catch(error => {
                                Swal.fire({
                                    title: "Mensaje",
                                    text: error,
                                    icon: "error",
                                    confirmButtonText: "Aceptar",
                                })
                            })
                    }
                })
                .catch(error => {

                });
        } catch (error) {

        }
    };
    const FuncionActualizarCliente = async (cliente, onCloseModal) => {
        try {
            const postdata2 = {
                cCli_NombreCorto: parseInt(cliente.bCli_Tipo) == 1 ? cliente.cCli_RazonSocial : cliente.cCli_NombreCorto + ' ' + cliente.cCli_RazonSocial + ' ' + cliente.cCli_Materno,
            }
            await instance
                .post("/Cliente/ClienteValidarClienteExist", postdata2)
                .then(async response => {
                    let resultado = response.data.length;
                    let nCli_Id = resultado > 0 ? response.data[0].nCli_Id : 0;



                    if (resultado > 0 && cliente.nCli_Id !== nCli_Id) {
                        Swal.fire({
                            title: "Mensaje",
                            text: "El cliente ya se encuentra registrado",
                            icon: "error",
                            confirmButtonText: "Aceptar",
                        })
                    }
                    else {
                        await instance
                            .post("/Cliente/Actualizar", cliente)
                            .then(async response => {
                                dispatch({ type: UPD_CLIENTE, resultado: response.data });
                                onCloseModal();
                                Swal.fire({
                                    title: "Mensaje",
                                    text: "Actualizado exitosamente",
                                    icon: "success",
                                    confirmButtonText: "Aceptar",
                                })
                                FuncionListarCliente()
                            })
                            .catch(error => {
                                Swal.fire({
                                    title: "Mensaje",
                                    text: error,
                                    icon: "error",
                                    confirmButtonText: "Aceptar",
                                })
                            })
                    }
                })
                .catch(error => {

                });

        } catch (error) {

        }
    };
    const FuncionEliminarCliente = async (cCli_NombreCompleto, cliente) => {

        try {
            let postData = {
                _nCli_Id: cliente.nCli_Id
            }

            await instance
                .post("/Casos/NumeroCasos", postData)
                .then(response => {

                    if (response.data[0].contador == 0) {
                        Swal.fire({
                            title: "Mensaje",
                            html: "Estas seguro de eliminar al cliente : <b>" + cCli_NombreCompleto + "</b> ?",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Aceptar",
                            cancelButtonText: "Cancelar",
                        }).then(result => {
                            if (result.isConfirmed) {
                                instance
                                    .post("/Cliente/Eliminar", cliente)
                                    .then(response => {
                                        dispatch({ type: DLT_CLIENTE, resultado: response.data });
                                        FuncionListarCliente()
                                        Swal.fire({
                                            title: "Mensaje",
                                            text: "Eliminado exitosamente",
                                            icon: "success",
                                            confirmButtonText: "Aceptar",
                                        })
                                    })
                                    .catch(error => {
                                        Swal.fire({
                                            title: "Mensaje",
                                            text: error,
                                            icon: "error",
                                            confirmButtonText: "Aceptar",
                                        })
                                    })
                            }
                        })
                    }
                    else {
                        Swal.fire({
                            title: "Mensaje",
                            text: "El cliente no puede ser eliminado, tiene casos asignados",
                            icon: "error",
                            confirmButtonText: "Aceptar",
                        })
                    }

                })
                .catch(error => {

                })


        } catch (error) {

        }
    };
    return (
        <ClienteContext.Provider
            value={{
                clientes: state.clientes,
                clientesult: state.clientesult,
                FuncionListarCliente,
                FuncionListarClienteUltimo,
                FuncionRegistrarCliente,
                FuncionActualizarCliente,
                FuncionEliminarCliente,
            }}
        >
            {props.children}
        </ClienteContext.Provider>
    );
}
export default ClienteState;
