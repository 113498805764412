import React, { useState, useEffect } from "react"
import { useContext } from "react"
import { Link, useParams } from "react-router-dom"
import instance from '../../../../config'
import Swal from "sweetalert2"
import { MDBDataTable } from "mdbreact"
import { getDecryptedItem } from '../../../../util';
import {
  FormGroup,
  Label,
  Input,
  CardBody,
  Card,
  Col,
  Container,
  CardTitle,
  Button,
  Row,
  Modal,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import ComboContext from "../../Context/Combos/ComboContext";
import Select from "react-select"
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
var horaInicio = Fecha.getHours() + ':' + Fecha.getMinutes();
var horaFin = Fecha.getHours() + 1 + ':' + Fecha.getMinutes();
const baseUrl = process.env.REACT_APP_API_URL

const ModalTarea = ({ show, onCloseClick, onCloseModal, Condicion, Id, Row, arrayParticipantes, mode }) => {
  const [getDisableCombo, setDisableCombo] = useState(false)
  const [getDisableALL, setDisableALL] = useState(false)
  const [dataComboUsuario, setDataComboUsuario] = useState([])
  const [GetParticipantes, setParticipantes] = useState([])
  const [dataParticipantes, setDataParticipantes] = useState([])
  const [dataSelect, setDataSelect] = useState([])
  const [dataTipoTarea, setDataTipoTarea] = useState([])
  const [dataEstadoTarea, setDataEstadoTarea] = useState([])
  const [dataCategoriaTarea, setDataCategoriaTarea] = useState([])
  const [dataRecordarTarea, setDataRecordarTarea] = useState([])
  const [dataCliente, setDataCliente] = useState([])
  const [dataCaso, setDataCaso] = useState([])
  const [dataLista, setDatalista] = useState([])
  const [hiddenLblFlujo, setHiddenLblFlujo] = useState(true)
  const [hiddenBtnGuardar, setHiddenBtnGuardar] = useState(false)
  const [titulo, setTitulo] = useState("")
  const comboContext = useContext(ComboContext);
  const [getTareas, setTareas] = useState({
    nTar_Id: "",
    nCli_Id: "",
    nCas_Id: "",
    cTar_Tab_Tipo: "",
    cTar_Tab_Categoria: "",
    cTar_Titulo: "",
    nUsu_Asignado: 1,
    nUsu_Relacionado: 1,
    dTar_Fec_Vence: ano + "-" + mes + "-" + dia,
    tTar_Hora_Vence: horaFin,
    cTar_tab_recordar: "",
    bTar_Estado: "1",
    cEstadoTarea: "MT01",
    nTar_Duracion: '1',
    nTar_Prioridad: "",
    nTar_TipoDias: '1',
    cfFlu_Nombre: '',
    cfFas_Nombres: '',
    cfFlu_Nombre: '',
    cfFas_Nombre: ''
  })
  const [getErrores, setErrores] = useState({});
  const Validacion = (form) => {
    let errors = {};
    let regexLetras = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    let regexCorreo = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    let regexNumero = /^[0-9]+$/;
    if (form.nCas_Id == 0) {
      errors.nCas_Id = "(*)"
    }
    if (form.cTar_Tab_Categoria == 0) {
      errors.cTar_Tab_Categoria = "(*)"
    }
    if (!form.cTar_Titulo.trim()) {
      errors.cTar_Titulo = "(*)"
    }
    if (form.cTar_tab_recordar == 0) {
      errors.cTar_tab_recordar = "(*)"
    }
    if (form.cTar_Tab_Tipo == 0) {
      errors.cTar_Tab_Tipo = "(*)"
    }
    return errors;
  }
  const datas = {
    columns: [

      {
        label: "Listas ",
        field: "cLis_Nombre",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Estado ",
        field: "estado",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },


    ],
    rows: dataLista,
  }

  const badgesData = {
    columns: [

      ...datas.columns,
      {
        label: "Acciones",
        field: "acciones",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
    ],
    rows: [
      ...datas.rows.map((row, id) => ({
        ...row,
        acciones: (
          <div hidden={hiddenBtnGuardar}>
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="dropdown-toggle">
                <i className="bx bx-dots-vertical-rounded" />
              </DropdownToggle>
              <DropdownMenu>

                <DropdownItem onClick={() => FuncionEliminarLista(0, row.nLis_Id)}>
                  <Link >
                    Realizado
                  </Link>
                </DropdownItem>

                <DropdownItem
                  onClick={() => FuncionEliminarLista(1, row.nLis_Id)}
                >
                  Pendiente
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        ),
      })),
    ],
  }
  const FuncionEliminarLista = async (parametro, _nLis_Id) => {




    var postData = {
      nLis_Id: parseInt(_nLis_Id),
      estado: 0
    }



    if (parametro == 0) {
      postData.estado = 1 //Aprobado
    }
    else {
      postData.estado = 0 //Pendiente
    }



    await instance
      .post("/Tareas/TareasListaEliminar", postData)
      .then(response => {

        let _nfTar_Id = getTareas.nTar_Id;
        FuncionlistarLista(_nfTar_Id);

      })
      .catch(error => {

      })
  }

  const FuncionlistarLista = async (_nTar_Id) => {
    var postData = {
      nTar_Id: _nTar_Id,
    }



    await instance
      .post("/Tareas/TareaListaListar", postData)
      .then(response => {

        setDatalista(response.data);

      })
      .catch(error => {

      })

  }
  const handleBlur = e => {
    handleChange(e)
    setErrores(Validacion(getTareas))

  }
  const handleChangeParticipantes = value => {
    if (value == null) {
    } else {

      setParticipantes(value);
    }
  };
  const UsuariosSelect = dataComboUsuario.map((row, i) => ({
    value: row.nUsu_ID,
    label: row.cUsu_Nombres,
    email: row.cUsu_email,
  }))

  const FuncionValidarFormulario = async () => {
    if (!getTareas.nCas_Id || !getTareas.cTar_Tab_Categoria || !getTareas.cTar_Titulo || !getTareas.cTar_tab_recordar || !getTareas.cTar_Tab_Tipo) {
      Swal.fire({
        title: "Mensaje",
        text: "Faltan completar datos",
        icon: "error",
        confirmButtonText: "Aceptar",
      })
    } else {
      FuncionActualizarTarea()
    }
  }
  const FuncionActualizarTarea = async () => {
    var postData = {
      nTar_Id: getTareas.nTar_Id,
      nCas_Id: getTareas.nCas_Id,
      cTar_Tab_Tipo: getTareas.cTar_Tab_Tipo,
      cTar_Tab_Categoria: getTareas.cTar_Tab_Categoria,
      cTar_Titulo: getTareas.cTar_Titulo,
      nUsu_Asignado: getTareas.nUsu_Asignado,
      nUsu_Relacionado: getTareas.nUsu_Relacionado,
      dTar_Fec_Vence: getTareas.dTar_Fec_Vence,
      tTar_Hora_Vence: getTareas.tTar_Hora_Vence,
      tTar_Hora_Vence2: getTareas.tTar_Hora_Vence2,
      cTar_tab_recordar: getTareas.cTar_tab_recordar,
      bTar_Estado: Number.parseInt(getTareas.bTar_Estado),
      nTar_Prioridad: parseInt(getTareas.nTar_Prioridad),
      nTar_Duracion: parseInt(getTareas.nTar_Duracion),
      nTar_TipoDias: parseInt(getTareas.nTar_TipoDias),
      nfFas_Id: parseInt(getTareas.nfFas_Id),
    }

    let horaFin = postData.dTar_Fec_Vence + " " + postData.tTar_Hora_Vence;
    let fechaf = new Date(horaFin);

    let horaSistema = new Date();



    await instance
      .post("/Tarea/Actualizar", postData)
      .then(async response => {

        let postData2 = {
          cAud_nombre: "Tareas",
          nAud_IdReg: postData.nTar_Id,
          nUsu_ID: getDecryptedItem("ID"),
          dAud_Fecha: null,
          nAud_Accion: "U",
          cTabCodigo: 'ME05'
        }

        await instance
          .post("/Auditoria/Insertar", postData2)
          .then(response => {
          });


        FuncionEliminarParticipantes(getTareas.nTar_Id);

        setTimeout(() => {
          const Participantes = GetParticipantes.map((row, i) => {
            FuncionActualizarParticipantes(getTareas.nTar_Id, row.value)
          })

          Swal.fire({
            title: "Mensaje",
            text: "Actualizado exitosamente",
            icon: "success",
            confirmButtonText: "Aceptar",
          })
        }, 800);

      })
      .catch(error => {

      })


    /****** Enviar correos **** */
    const categoria = dataCategoriaTarea.find(element => element.cTabCodigo == postData.cTar_Tab_Categoria);

    let objMails = {
      categoria: categoria.cTabNombre,
      cliente: getTareas.nCli_Id,
      parametro: {
        tpoTbl: 'tarea',   // tipo de tabla
        tpoAct: 'actualizar'  // insertar o actualizar
      },
      dataTarea: postData,
      arrayParticipantes: GetParticipantes
    }



    await instance
      .post("/mail/event/tarea", objMails)
      .then(res => {
      })
      .catch(error => {

      })

    /****************************** */
    onCloseModal();


  }
  const FuncionActualizarParticipantes = async (_nTar_Id, _nUsu_ID) => {
    var postData = {
      nTar_Id: Number.parseInt(_nTar_Id),
      nUsu_ID: Number.parseInt(_nUsu_ID),
    }
    await instance
      .post("/Tarea/Insertar/Participante", postData)
      .then(response => {
        FuncionListarTareasParticipantes()
      })
      .catch(error => {

      })
  }
  const FuncionEliminarParticipantes = async (_nTar_Id) => {
    var postData = {
      nTar_Id: Number.parseInt(_nTar_Id),
    }
    await instance
      .post("/Tarea/Eliminar/Participante", postData)
      .then(response => {
      })
      .catch(error => {

      })
  }
  const handleChange = e => {
    const { name, value } = e.target
    setTareas(prevState => ({
      ...prevState,
      [name]: value,
    }))

  }

  const FuncionListarComboTipoTarea = async () => {
    var postData = {
      Prefijo: "TT",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataTipoTarea(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarComboCategoriaTarea = async () => {
    var postData = {
      Prefijo: "CT",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataCategoriaTarea(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarComboRecordarTarea = async () => {
    var postData = {
      Prefijo: "PE",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataRecordarTarea(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarCliente = async () => {
    let RolId = getDecryptedItem("RolID")

    var postData = {
      bCli_Tipo: null,
      nUsu_ID: RolId == 6 || RolId == 1 ? null : getDecryptedItem("ID")
    }
    await instance
      .post("/Cliente/ListarCbo", postData)
      .then(response => {
        setDataCliente(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarCasos = async (_idClient) => {
    var postData = {
      idClient: _idClient,
      nUsu_Id: getDecryptedItem("ID"),
    }
    await instance
      .post("/Casos/ListarCbo", postData)
      .then(response => {
        setDataCaso(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarComboUsuario = async () => {
    await instance
      .get("/Usuario/Listar")
      .then(response => {
        setDataComboUsuario(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarEstadoTarea = async () => {
    var postData = {
      Prefijo: "MT",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataEstadoTarea(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionFiltroTareas = async (_cTar_Tab_Tipo) => {
    var postData = {
      nCli_Id: parseInt(getFiltro.nCli_Id),
      nCas_Id: parseInt(getFiltro.nCas_Id),
      cTar_Tab_Tipo: _cTar_Tab_Tipo,
      cTar_Tab_Categoria: getFiltro.cTar_Tab_Categoria,
      FechaInicial: getFiltro.FechaInicial,
      FechaFin: getFiltro.FechaFin,
      nUsu_ID: getFiltro.nUsu_ID,
    }
    if (postData.cTar_Tab_Tipo == "Todos") {
      postData.cTar_Tab_Tipo = null;
    }

    await instance
      .post("/Tarea/Buscar", postData)
      .then(response => {

        setData(response.data)
      })
      .catch(error => {

      })
  }

  const FuncionBuscarTareasParticipantes = async (_nTar_Id) => {
    var postData = {
      nTar_Id: _nTar_Id,
    }
    await instance
      .post("/Tarea/Buscar/Participante", postData)
      .then(response => {

        setParticipantes(response.data);
      })
      .catch(error => {

      })
  }

  function sortJSON(data, key, orden) {
    return data.sort(function (a, b) {
      var x = a[key],
        y = b[key];

      if (orden === 'asc') {
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
      }

      if (orden === 'desc') {
        return ((x > y) ? -1 : ((x < y) ? 1 : 0));
      }
    });
  }
  const FuncionListarTareasParticipantes = async (_nTar_Id) => {
    await instance
      .post("/Tareas/Participantes")
      .then(response => {
        setDataParticipantes(response.data)
      })
      .catch(error => {

      })
  }
  useEffect(() => {
    if (mode == 2) {
      setTitulo("Ver tarea")
      setHiddenBtnGuardar(true);
    }
    else{
      setTitulo("Editar tarea")
      setHiddenBtnGuardar(false);
    }
    if (Row.nfFlu_Id == null) {
      setHiddenLblFlujo(true)
    }
    else {
      setHiddenLblFlujo(false)
    }
    FuncionlistarLista(Row.nTar_Id);
    setTareas(Row);
    setParticipantes(arrayParticipantes);

    FuncionListarComboTipoTarea()       //registrado
    FuncionListarComboCategoriaTarea()  //registrado
    FuncionListarComboRecordarTarea()   //registrado
    FuncionListarComboUsuario()         //registrado
    FuncionListarCliente()
    FuncionListarCasos(null)
    FuncionListarEstadoTarea()

  }, []);
  return (
    <Modal size="lg" isOpen={show}  >
      <div
        className="modal-header ModalHeaderColor"
      >
        <h5
          style={{ color: "#fff" }}
          className="modal-title mt-0"
          id="myModalLabel"
        >
          {titulo}
        </h5>
        <button
          type="button"
          onClick={() => onCloseModal()}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3 row">
          <div className="col-md-6">
            <select className="form-select inputDown" disabled="true"
              name="nCli_Id"
              value={getTareas && getTareas.nCli_Id}>
              <option value="0">Seleccionar...</option>
              {dataCliente.map((row, i) => (
                <option key={i} value={row.nCli_Id}>
                  {row.cCli_NombreCompleto}
                </option>
              ))}
            </select>
            <label className="col-form-label labelDown">
              Cliente
            </label>
          </div>
          <div className="col-md-6">
            <select
              className="form-select inputDown"
              name="nCas_Id" disabled="true"
              value={getTareas && getTareas.nCas_Id}
            >
              <option value="0">Seleccionar...</option>
              {dataCaso.map((row, i) => (
                <option key={i} value={row.nCas_Id}>
                  {row.cCas_Titulo}
                </option>
              ))}
            </select>
            <label className="col-form-label labelDown">
              Caso
            </label>
          </div>
        </div>
        <div className="mb-3 row">
          <div className="col-md-6">
            <label
              htmlFor="example-text-input"
              className="col-md-12 col-form-label"
              style={{ marginLeft: "5px" }}
            >
              Cod Interno: {getTareas.cCas_Cod_Interno}
            </label>
          </div>
          <div className="col-md-6" style={{ marginLeft: "0px", paddingLeft: '0px' }}>
            <label
              htmlFor="example-text-input"
              className="col-md-12 col-form-label"
              style={{ marginLeft: "0px", paddingLeft: '0px' }}
            >
              Cod Externo: {getTareas.cCas_Cod_Externo}
            </label>
          </div>
        </div>
        <div className="mb-3 row" style={{ marginTop: '-20px' }} hidden={hiddenLblFlujo}>
          <div className="col-md-6">
            <label
              htmlFor="example-text-input"
              className="col-md-12 col-form-label"
              style={{ marginLeft: "5px" }}
            >
              Flujo: {getTareas.cfFlu_Nombre}
            </label>
          </div>
          <div className="col-md-6" style={{ marginLeft: "0px", paddingLeft: '0px' }}>
            <label
              htmlFor="example-text-input"
              className="col-md-12 col-form-label"
              style={{ marginLeft: "0px", paddingLeft: '0px' }}
            >
              Fase: {getTareas.cfFas_Nombre}
            </label>
          </div>
        </div>

        <div className="mb-3 row" style={{ marginTop: '15px' }}>
          <div className="col-md-6">
            <select
              disabled={hiddenBtnGuardar}
              className="form-select"
              name="cTar_Tab_Categoria"
              onChange={handleChange}
              onBlur={handleBlur}
              value={getTareas && getTareas.cTar_Tab_Categoria}
            >
              <option value="0">Seleccionar...</option>
              {dataCategoriaTarea.map((row, i) => (
                <option key={i} value={row.cTabCodigo}>
                  {row.cTabNombre}
                </option>
              ))}
            </select>
            <label className="col-form-label labelDown">Categoria</label> {getErrores.cTar_Tab_Categoria && <label className="col-form-label labelDown labelRed">{getErrores.cTar_Tab_Categoria}</label>}
          </div>

          <div className="col-md-6">
            <textarea
              disabled={hiddenBtnGuardar}
              className="form-control"
              placeholder="Ingrese el título"
              name="cTar_Titulo"
              onChange={handleChange}
              onBlur={handleBlur}
              value={getTareas && getTareas.cTar_Titulo}
              maxLength="200p"
            ></textarea>
            <label className="col-form-label labelDown">Titulo</label>{getErrores.cTar_Titulo && <label className="col-form-label labelDown labelRed">{getErrores.cTar_Titulo}</label>}
          </div>

        </div>
        <div className="mb-3 row">
        </div>
        <div className="mb-3 row">
          <div className="col-md-6">
            <Select
              isDisabled={hiddenBtnGuardar}
              isMulti
              onChange={handleChangeParticipantes}
              name="colors"
              options={UsuariosSelect}
              defaultValue={GetParticipantes}
              className="basic-multi-select"
              placeholder="Ingrese los participantes"
            />
            <label className="col-form-label labelDown">
              Participantes
            </label>
          </div>
          <div className="col-md-2" style={{ width: '90px' }}>
            <input
              disabled={hiddenBtnGuardar}
              type="text"
              className="form-control"
              id="nTar_Duracion"
              name="nTar_Duracion"
              value={getTareas && getTareas.nTar_Duracion}
              onChange={handleChange}
              style={{ textAlign: 'right' }}
            />
            <label className="col-form-label labelDown">
              Duración
            </label>
          </div>
          <div className="col-md-2" style={{ width: '150px' }}>
            <select
              disabled={hiddenBtnGuardar}
              className="form-select"
              name="nTar_TipoDias"
              onChange={handleChange}
              value={getTareas && getTareas.nTar_TipoDias}
            >
              <option value="0">Seleccionar...</option>
              <option value="1">Dias calendario</option>
              <option value="2">Dias utiles</option>
            </select>
            <label className="col-form-label labelDown">
              Dias
            </label>
          </div>

          <div className="col-md-2" style={{ width: '150px' }}>
            <select
              disabled={hiddenBtnGuardar}
              className="form-select"
              name="nTar_Prioridad"
              onChange={handleChange}
              value={getTareas && getTareas.nTar_Prioridad}
            >
              <option value="0">Seleccionar...</option>
              <option value="1">Alta</option>
              <option value="2">Mediana</option>
              <option value="3">Baja</option>
              <option value="4">Sin Prioridad</option>
            </select>
            <label className="col-form-label labelDown">
              Prioridad
            </label>
          </div>
        </div>
        <div className="mb-3 row">
          <div className="col-md-3">
            <input
              disabled={hiddenBtnGuardar}
              className="form-control"
              type="date"
              name="dTar_Fec_Vence"
              onChange={handleChange}
              value={getTareas && getTareas.dTar_Fec_Vence}
            />
            <label className="col-form-label labelDown">
              Fecha de vencimiento
            </label>
          </div>
          <div className="col-md-3">
            <input
              disabled={hiddenBtnGuardar}
              className="form-control"
              type="time"
              name="tTar_Hora_Vence"
              onChange={handleChange}
              value={getTareas && getTareas.tTar_Hora_Vence}
            />
            <label className="col-form-label labelDown">
              Hora de vencimiento
            </label>
          </div>
          <div className="col-md-3">
            <select
              disabled={hiddenBtnGuardar}
              className="form-select"
              name="cTar_tab_recordar"
              onChange={handleChange}
              onBlur={handleBlur}
              value={getTareas && getTareas.cTar_tab_recordar}
            >
              <option value="0">Seleccionar...</option>
              {sortJSON(dataRecordarTarea, 'cTabCodigo', 'asc').map((row, i) => (
                <option key={i} value={row.cTabCodigo}>
                  {row.cTabNombre}
                </option>
              ))}
            </select>
            <label className="col-form-label labelDown">Recordar</label>{getErrores.cTar_tab_recordar && <label className="col-form-label labelDown labelRed">{getErrores.cTar_tab_recordar}</label>}
          </div>
          <div className="col-md-3">
            <select
              disabled={hiddenBtnGuardar}
              className="form-select"
              name="cTar_Tab_Tipo"
              onChange={handleChange}
              value={getTareas && getTareas.cTar_Tab_Tipo}
            >
              <option value="00">Seleccionar...</option>
              {dataTipoTarea.map((row, i) => (
                <option key={i} value={row.cTabCodigo}>
                  {row.cTabNombre}
                </option>
              ))}
            </select>
            <label className="col-form-label labelDown">Estado</label> {getErrores.cTar_Tab_Tipo && <label className="col-form-label labelDown labelRed">{getErrores.cTar_Tab_Tipo}</label>}
          </div>
        </div>
        <div className="mb-3 row">
        </div>
        <div className="mb-3 row">
          <div className="col-md-6" hidden>
            <select
              disabled={hiddenBtnGuardar}
              className="form-select"
              name="bTar_Estado"
              value={getTareas && getTareas.bTar_Estado}
              onChange={handleChange}
            >
              <option value="00">Seleccionar...</option>
              <option value="1">Activo</option>
              <option value="0">No activo</option>
            </select>
            <label className="col-form-label labelDown">
              Estado
            </label>
          </div>
        </div>
        <div className="mb-3 row">
          <div className="col-md-12">
            <CardBody style={{ paddingLeft: '0px', paddingBottom: '0px' }}>
              <Col className="col-12 row" style={{ paddingRight: '0px' }}>
                <FormGroup className="col-6" >
                  <Label htmlFor="cardnumberInput">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list-check" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z" />
                    </svg> Listas
                  </Label>
                </FormGroup>
                <FormGroup className="col-6 " style={{ textAlign: 'right', paddingRight: '0px' }} hidden={true}>
                  <button
                    disabled={hiddenBtnGuardar}
                    style={{ height: '30px', textAlign: 'right' }}
                    type="button"
                    onClick={() => abrirCerrarModal(8)}
                    className="btn btn-danger waves-effect waves-light"
                    data-toggle="modal"
                    data-target="#myModal"
                  >
                    Nueva
                  </button>
                </FormGroup>
              </Col>
              <MDBDataTable
                data={badgesData}
                className="TablaListas"
                responsiveXl
                fixed
                hover
                entriesLabel="Mostrar"
                entrylabel={"Mostrar entradas"}
                infoLabel={["Mostrando ", "a", "de", "registros"]}
                bordered
                order={["age", "desc"]}
                noRecordsFoundLabel={"No hay datos"}
                searchLabel={"Buscar"}
                noBottomColumns
                paginationLabel={["Anterior", "Siguiente"]}
                theadColor="HeadBlue"
                searching={false}
                paging={false}
                info={false}
              />
            </CardBody>
          </div>
        </div>

      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => onCloseModal()}
          className="btn btn-secondary waves-effect"
          data-dismiss="modal"
        >
          Cerrar
        </button>
        <button
          type="button"
          onClick={() => FuncionValidarFormulario()}
          className="btn btn-danger waves-effect waves-light"
          hidden={hiddenBtnGuardar}
        >
          Guardar
        </button>
      </div>
    </Modal>
  )
}
export default ModalTarea;