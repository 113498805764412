import React, { Component, useState, useEffect, Fragment } from "react"
import { Link } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import MetaTags from "react-meta-tags"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import Swal from "sweetalert2"
import { getDecryptedItem, setEncryptedItem } from '../../../util';
import {
    CardBody,
    Card,
    Col,
    Container,
    CardTitle,
    Button,
    Row,
    Modal,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Collapse,
    Media
} from "reactstrap"
import instance from '../../../config'
const baseUrl = process.env.REACT_APP_API_URL
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10

const MiCuenta = props => {
    const [getImagenes, setImagenes] = useState("/static/media/avatar-1.de73c074.jpg")
    const [getNameFile, setNameFile] = useState('Subir Logo');
    const [getLogo, setLogo] = useState(null);
    const [getCantidadCasos, SetCantidadCasos] = useState(0)
    const [getCantidadTE, SetCantidadTE] = useState(0)
    const [getCantidadE, SetCantidadE] = useState(0)
    const [hiddenBtnEditar, setHiddenBtnEditar] = useState(true)
    const [hiddenBtnEliminar, setHiddenBtnEliminar] = useState(true)
    const [getErrores, setErrores] = useState({});
    const [validData, setValidData] = useState(false)
    const [getUsuario, setUsuario] = useState({
        bUsu_Activo: 1,
        cUsu_Fono: "",
        cUsu_Login: "",
        cUsu_Nombres: "",
        cUsu_Pass: "",
        cUsu_PassConfirm: "",
        cUsu_email: "",
        nRol_ID: 1,
        nRol_ID2: "",
        ImgBinary: "",
        cUsu_Avatar: "",
    })
    const Validacion = (form) => {

        let errors = {};
        let regexLetras = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
        let regexCorreo = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
        let regexNumero = /^[0-9]+$/;

        if (!form.cUsu_Fono.trim()) {
            errors.cUsu_Fono = "(*)";
        }

        if (!form.cUsu_Login.trim()) {
            errors.cUsu_Login = "(*)";
        }

        if (!form.cUsu_Nombres.trim()) {
            errors.cUsu_Nombres = "(*)";
        }

        if (!form.cUsu_Pass.trim()) {
            errors.cUsu_Pass = "(*)";
        }
        if (!form.cUsu_PassConfirm.trim()) {
            errors.cUsu_PassConfirm = "(*)";
        }

        if (!form.cUsu_email.trim()) {
            errors.cUsu_email = "(*)";
        }

        return errors;
    }
    const handleChangeLogo = e => {
        const { files } = e.target;
        setLogo(files[0]);
        setNameFile(files[0].name);
    }
    const handleBlur = e => {
        handleChange(e)
        setErrores(Validacion(getUsuario))

    }
    const handleChange = e => {

        const { name, value, files } = e.target


        setUsuario(prevState => ({
            ...prevState,
            [name]: value,
        }))


        if (name == "ImgSubmit") {
            if (value == "") {
                setImagenes("/static/media/avatar-1.de73c074.jpg")
            } else {

                setImagenes(URL.createObjectURL(files[0]))
                setUsuario(prevState => ({
                    ...prevState,
                    ImgBinary: files[0],
                }))
            }
        }


    }
    const FuncionValidarFormulario = async () => {
        if (Object.keys(getErrores).length === 0) {


            if (!/\S+@\S+\.\S+/.test(getUsuario.cUsu_email.trim())) {

                Swal.fire({
                    title: "Mensaje",
                    text: "El correo no cumple con el formato",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                })
            } else if (getUsuario.cUsu_Pass !== getUsuario.cUsu_PassConfirm) {
                Swal.fire({
                    title: "Mensaje",
                    text: "Las contraseñas no son iguales",
                    icon: "error",
                    confirmButtonText: "Aceptar",
                })
            }
            else {
                FuncionActualizarLogo();
                FuncionActualizarUsuario();
            }

        } else {
            setValidData(true);
            Swal.fire({
                title: "Mensaje",
                text: "Complete los campos requeridos",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        }
    }
    const FuncionListarUsuario = async (_nUsu_ID) => {
        var postData = {
            nUsu_ID: _nUsu_ID,
        }
        await instance
            .post("/Usuario/Perfil", postData)
            .then(response => {
                console.log(response)
                setUsuario(response.data[0])
                FuncionCargarImg(response.data[0].cUsu_Avatar)
                setUsuario(prevState => ({
                    ...prevState,
                    cUsu_PassConfirm: response.data[0].cUsu_Pass
                }))

            })
            .catch(error => {

            })
    }
    const FuncionActualizarUsuario = async () => {

        var postData = {
            nUsu_ID: getUsuario.nUsu_ID,
            nRol_ID: parseInt(getUsuario.nRol_ID),
            cUsu_Nombres: getUsuario.cUsu_Nombres,
            cUsu_email: getUsuario.cUsu_email,
            cUsu_Fono: getUsuario.cUsu_Fono,
            cUsu_Login: getUsuario.cUsu_Login,
            cUsu_Pass: getUsuario.cUsu_Pass,
            bUsu_Activo: Number.parseInt(getUsuario.bUsu_Activo),
            nUsu_Cli_Id: null
        }



        await instance
            .post("/Usuario/Actualizar", postData)
            .then(async (response) => {
                console.log(getUsuario.ImgBinary)
                if (getUsuario.ImgBinary !== undefined) {
                    const formdata = new FormData()
                    formdata.append("image", getUsuario.ImgBinary)
                    formdata.append("nUsu_ID", getUsuario.nUsu_ID)
                    await instance
                        .post("/Usuario/UploadAvatar", formdata, { headers: { 'Content-Type': 'multipart/form-data' } })
                        .then(response => {
                            setEncryptedItem('NombreC', getUsuario.cUsu_Nombres);
                            FuncionListarUsuario(parseInt(getDecryptedItem("ID")))
                            Swal.fire({
                                title: "Mensaje",
                                text: "Actualizado exitosamente",
                                icon: "success",
                                confirmButtonText: "Aceptar",
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    window.location.reload();
                                }
                            })
                        })
                        .catch(error => {

                        })
                } else {
                    setEncryptedItem('NombreC', getUsuario.cUsu_Nombres);
                    FuncionListarUsuario(parseInt(getDecryptedItem("ID")))
                    Swal.fire({
                        title: "Mensaje",
                        text: "Actualizado exitosamente",
                        icon: "success",
                        confirmButtonText: "Aceptar",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload();
                        }
                    })
                }
            })
            .catch(error => {

            })
    }
    const FuncionListarCasos = async () => {
        try {
            let RolId = getDecryptedItem("RolID")
            var postData = {
                nUsu_ID: RolId == 6 || RolId == 1 ? null : getDecryptedItem("ID")
            }
            await instance
                .post("/Casos/Listar", postData)
                .then(response => {
                    SetCantidadCasos(response.data.length)
                })
        } catch (error) {

        }
    }
    const FuncionListarEventos = async () => {
        let RolId = getDecryptedItem("RolID")
        var postData = {
            nUsu_ID: RolId == 6 || RolId == 1 ? null : getDecryptedItem("ID")
        }
        await instance
            .post("/Evento/Listar", postData)
            .then(response => {

                FuncionListarTareas(response.data.length)
            })
            .catch(error => {

            })
    }
    const FuncionListarTareas = async (Cantidad) => {
        let RolId = getDecryptedItem("RolID")
        var postData = {
            nUsu_ID: RolId == 6 || RolId == 1 ? null : getDecryptedItem("ID")
        }
        await instance
            .post("/Tarea/Listar", postData)
            .then(response => {

                SetCantidadE(Cantidad)
                SetCantidadTE(response.data.length)
            })
            .catch(error => {

            })
    }
    function checkImage(src, good, bad) {
        var img = new Image();
        img.onload = good;
        img.onerror = bad;
        img.src = src;
    }
    const FuncionCargarImg = async (cUsu_Avatar) => {
        var postData = {
            FileName: cUsu_Avatar,
        }
        console.log(postData)
        await instance
            .post("/Usuario/DownloadFile", postData)
            .then(response => {
                const Url = response.data.data
                checkImage(Url, function () {
                    setImagenes(Url)
                }, function () { setImagenes('/static/media/avatar-1.de73c074.jpg') });
            })
            .catch(error => {
            })
    }
    const FuncionListarPermisos = async () => {
        var postData = {
            nUsu_ID: parseInt(getDecryptedItem("RolID"))
        }
        await instance
            .post("/Usuario/Acceso", postData)
            .then(response => {
                let arrayPermisos = response.data;
                const permiso = arrayPermisos.find(element => element.cUsu_Modulo == "ME18");

                if (permiso.bUsu_Editar == 1) {
                    setHiddenBtnEditar(false);
                }
                else {
                    setHiddenBtnEditar(true);
                }
                if (permiso.bUsu_Eliminar == 1) {
                    setHiddenBtnEliminar(false);
                }
                else {
                    setHiddenBtnEliminar(true);
                }
            })
            .catch(error => {

            })
    }
    const FuncionActualizarLogo = async () => {
        if (getLogo !== undefined) {
            const formdata = new FormData()
            formdata.append("image", getLogo)
            await instance
                .post("/Suscriptores/UploadLogo", formdata, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then(response => {

                })
                .catch(error => {

                })
        }
    }
    useEffect(() => {

        Validacion(getUsuario)
        FuncionListarPermisos();
        FuncionListarUsuario(parseInt(getDecryptedItem("ID")))

        FuncionListarCasos()
        FuncionListarEventos()
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                {/* add meta title */}
                <MetaTags>
                    <title>Mi cuenta | GpsLegal</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="GpsLegal" breadcrumbItem="Mi cuenta" />
                    <Row>
                        <Col className="col-3"></Col>
                        <Col className="col-6" >
                            <Card className="overflow-hidden">
                                <div className="ModalHeaderColor">
                                    <Row>
                                        <Col xs="7">
                                            <div className="text-primary p-3">
                                                <h5 style={{ color: "white" }}>Datos del usuario</h5>
                                                <p style={{ color: "white" }}>
                                                    Puedes ver tu perfil y editar tus datos</p>
                                            </div>
                                        </Col>
                                        <Col xs="5" className="align-self-end">
                                            <img src="" alt="" className="img-fluid" />
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0 cardBorder">
                                    <Row>
                                        <Col sm="4">
                                            <div className="avatar-md profile-user-widmb-4" style={{
                                                paddingLeft: "0px",
                                                marginLeft: "10",
                                                height: "60px",
                                                width: "100px"
                                            }}>
                                                <img
                                                    src={getImagenes}
                                                    alt=""
                                                    className="img-thumbnail rounded-circle"
                                                    style={{ width: '100px', height: '100px' }}
                                                />
                                            </div>
                                            <div className="avatar-md profile-user-widmb-4" style={{
                                                paddingTop: "40px",
                                                marginLeft: "0px",
                                                height: "auto",
                                                width: "100px",
                                                textAlign: 'center'
                                            }}>
                                                <label
                                                    hidden={hiddenBtnEditar}
                                                    htmlFor="fileInput"
                                                    style={{ border: '1px solid #ccc', cursor: 'pointer', padding: '6px', borderRadius: '5px' }}>
                                                    Cambiar
                                                </label>
                                                <input

                                                    hidden={true}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    id="fileInput"
                                                    name="ImgSubmit"
                                                    type="file"
                                                    accept="image/png,image/jpeg" />
                                            </div>
                                        </Col>
                                        <Col sm={8} style={{ margin: '0px', padding: '0px' }}>
                                            <div className="pt-4">
                                                <Col xs="12" className="mb-3">
                                                    <h5 className="font-size-15">
                                                        Participa en:
                                                    </h5>
                                                </Col>
                                                <Row style={{ margin: '0px !important', padding: '0px !important' }}>
                                                    <div className="mb-3 row" style={{ margin: '0px !important', padding: '0px !important' }}>
                                                        <div className="col-md-4" style={{ margin: '0px !important', padding: '0px !important' }}>
                                                            <h5 className="font-size-15" style={{ textAlign: "center" }}>
                                                                {getCantidadCasos}
                                                            </h5>
                                                            <p style={{ color: "black", textAlign: "center" }} className="mb-0">casos</p>
                                                        </div>
                                                        <div className="col-md-4" style={{ margin: '0px !important', padding: '0px !important' }}>
                                                            <h5 className="font-size-15" style={{ textAlign: "center" }}>
                                                                {getCantidadTE}
                                                            </h5>
                                                            <p style={{ color: "black", textAlign: "center" }} className=" mb-0">tareas</p>

                                                        </div>
                                                        <div className="col-md-4" style={{ margin: '0px !important', padding: '0px !important' }}>
                                                            <h5 className="font-size-15" style={{ textAlign: "center" }}>
                                                                {getCantidadE}
                                                            </h5>
                                                            <p style={{ color: "black", textAlign: "center" }} className=" mb-0">eventos</p>
                                                        </div>
                                                    </div>

                                                </Row>
                                            </div>
                                        </Col>
                                        <Col sm={12}>
                                            <div className="mb-5 row"></div>
                                            <div className="mb-3 row">
                                                <div className="col-md-6">
                                                    <input
                                                        type="text"
                                                        value={getUsuario && getUsuario.cUsu_Login}
                                                        disabled
                                                        className="form-control inputDown" />
                                                    <label className="col-form-label labelDown">
                                                        Login
                                                    </label>
                                                </div>
                                                <div className="col-md-6">
                                                    <input
                                                        disabled type="text"
                                                        value={getUsuario && getUsuario.nRol_ID2}
                                                        className="form-control inputDown" />
                                                    <label className="col-form-label labelDown">
                                                        Rol
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-md-6">
                                                    <input
                                                        type="text"
                                                        name="cUsu_Nombres"
                                                        onChange={handleChange}
                                                        value={getUsuario && getUsuario.cUsu_Nombres}
                                                        className="form-control inputDown"
                                                        placeholder="Ingrese sus nombres"
                                                        maxLength={50}
                                                    />
                                                    <label className="col-form-label labelDown">
                                                        Nombres
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-md-6">
                                                    <input
                                                        type="password"
                                                        name="cUsu_Pass"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        placeholder="Ingrese su contraseña"
                                                        value={getUsuario && getUsuario.cUsu_Pass}
                                                        className={getErrores.cUsu_Pass && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                                                        maxLength={15}
                                                        autocomplete="new-password"
                                                    />

                                                    <label className="col-form-label labelDown">Password</label>{getErrores.cUsu_Pass && <label className="col-form-label labelDown labelRed">{getErrores.cUsu_Pass}</label>}
                                                </div>
                                                <div className="col-md-6">
                                                    <input
                                                        type="password"
                                                        name="cUsu_PassConfirm"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={getUsuario && getUsuario.cUsu_PassConfirm}
                                                        placeholder="Confirme su contraseña"
                                                        className={getErrores.cUsu_PassConfirm && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                                                        maxLength={15}
                                                        autocomplete="new-password"
                                                    />
                                                    <label className="col-form-label labelDown">Confirmar password</label>{getErrores.cUsu_PassConfirm && <label className="col-form-label labelDown labelRed">{getErrores.cUsu_PassConfirm}</label>}

                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-md-6">
                                                    <input
                                                        type="text"
                                                        name="cUsu_email"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={getUsuario && getUsuario.cUsu_email}
                                                        className={getErrores.cUsu_email && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                                                        placeholder="Ingrese su correo"
                                                        autocomplete="off"
                                                        maxLength={50}
                                                    />

                                                    <label className="col-form-label labelDown">Correo</label>{getErrores.cUsu_email && <label className="col-form-label labelDown labelRed">{getErrores.cUsu_email}</label>}
                                                </div>
                                                <div className="col-md-6">
                                                    <input
                                                        type="text"
                                                        name="cUsu_Fono"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={getUsuario && getUsuario.cUsu_Fono}
                                                        className={getErrores.cUsu_Fono && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                                                        placeholder="Ingrese un telefono"
                                                        maxLength={12}
                                                    />

                                                    <label className="col-form-label labelDown">Teléfono</label>{getErrores.cUsu_Fono && <label className="col-form-label labelDown labelRed">{getErrores.cUsu_Fono}</label>}
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-md-6">
                                                    <input type="date" disabled value={getUsuario && getUsuario.dUsu_FechaCreacion} className="form-control inputDown" />
                                                    <label className="col-form-label labelDown">
                                                        Fecha de creación
                                                    </label>
                                                </div>
                                                <div className="col-md-6">
                                                    <input type="text" disabled defaultValue="Demo" className="form-control inputDown" />
                                                    <label className="col-form-label labelDown">
                                                        Plan
                                                    </label>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={8}>
                                            <Fragment>
                                                <label
                                                    hidden={hiddenBtnEditar}
                                                    htmlFor="fileLogo"
                                                    style={{ border: '1px solid #ccc', cursor: 'pointer', padding: '6px', borderRadius: '5px' }}>
                                                    {getNameFile}
                                                </label>
                                                <input
                                                    hidden={true}
                                                    onChange={handleChangeLogo}
                                                    className="form-control"
                                                    id="fileLogo"
                                                    name="ImgSubmit"
                                                    type="file"
                                                    accept="image/png,image/jpeg" />
                                            </Fragment>

                                        </Col>
                                        <Col sm={8}>
                                            <button hidden={hiddenBtnEditar} className="btn btn-danger" onClick={() => FuncionValidarFormulario()}>
                                                Guardar
                                            </button>
                                        </Col>
                                        <Col sm={4} style={{ textAlign: 'right', marginTop: '15px' }}>
                                            <label >
                                                ⓥ Versión. 231003
                                            </label>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default MiCuenta
