import React, { Component, useState, useEffect, useContext } from "react"
import Select from "react-select"
import Swal from "sweetalert2"
import {
    Modal,
} from "reactstrap"
import instance from '../../../../config'
import RolesContext from "pages/Menus/Context/Roles/RolesContext"
const baseUrl = process.env.REACT_APP_API_URL
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
export const ModalRoles = ({ show, onCloseClick, ReloadLista, Titulo, Condicion, Id, Row }) => {

    const rolesContext = useContext(RolesContext);

    const [getRoles, setRoles] = useState({
        nRol_ID: "",
        cRol_Nombre: "",
        bRol_Estado: "",
    })
    const handleChange = e => {
        const { name, value } = e.target
        setRoles(prevState => ({
            ...prevState,
            [name]: value,
        }))

    }
    const GuardarRoles = () => {
        if (Id > 0) {
            rolesContext.FuncionActualizarRoles(getRoles)
        } else {
            rolesContext.FuncionInsertarRoles(getRoles)
        }
        ReloadLista()
        Swal.fire({
            title: "Mensaje",
            text: "Guardado exitosamente",
            icon: "success",
            confirmButtonText: "Aceptar",
        })
    }
    useEffect(() => {
        if (Id > 0) {
            setRoles(Row)
        } else {
            setRoles({
                nRol_ID: "",
                cRol_Nombre: "",
                bRol_Estado: 1,
            })
        }

    }, []);
    return (
        <Modal isOpen={show}>
            <div
                className="modal-header ModalHeaderColor"
            >
                <h5
                    style={{ color: "#fff" }}
                    className="modal-title mt-0"
                    id="myModalLabel"
                >
                    {Titulo}
                </h5>
                <button
                    type="button"
                    onClick={() => onCloseClick()}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="mb-3 row">
                    <div className="col-md-12">
                        <input
                            className="form-control"
                            type="text"
                            name="cRol_Nombre"
                            onChange={handleChange}
                            value={getRoles && getRoles.cRol_Nombre}
                        />
                        <label className="col-form-label labelDown">
                            Nombre
                        </label>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => onCloseClick()}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                >
                    Cerrar
                </button>
                <button
                    type="button"
                    className="btn btn-danger waves-effect waves-light"
                    onClick={() => GuardarRoles()}
                >
                    Guardar
                </button>
            </div>
        </Modal>
    )
}
export default ModalRoles