import { GET_CLIENTE, ADD_CLIENTE, UPD_CLIENTE, DLT_CLIENTE,GET_CLIENTEULT } from "../Type";
export default (state, action) => {
  const { payload,response, type } = action;
  switch (type) {
    case GET_CLIENTE:
      return {
        ...state,
        clientes: payload,
      };
    case GET_CLIENTEULT:
        return {
          ...state,
          clientesult: payload,
        };
    case ADD_CLIENTE:
      return {
        ...state,
        resultado: response,
      };
    case DLT_CLIENTE:
      return {
        ...state,
        resultado: response,
      };
    default:
      return state;
  }
};
