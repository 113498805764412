import React, { useReducer, useEffect, useState } from "react";
import instance from '../../../../config';
import CasoContext from "./CasoContext";
import CasoReducer from "./CasoReducer";
import { getDecryptedItem, setEncryptedItem } from '../../../../util';
import { GET_CASOS, FLT_CASOS, FLT_CASOS_EXPEDIENTE, GET_ASUNTOS } from "../Type";
const baseUrl = process.env.REACT_APP_API_URL
import { useHistory } from "react-router-dom"
import Swal from "sweetalert2";
var meses = new Array("Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre");
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var mesIni = Fecha.getMonth() - 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
if (mesIni < 10) mesIni = "0" + mesIni //agrega cero si el menor de 10

const CasoState = (props) => {
    const initialState = {
        casos: [],
        bkcasos: [],
        asunto: []
    };
    const [getUsuario, setUsuario] = useState({
        bUsu_Activo: 1,
        cUsu_Fono: "",
        cUsu_Login: "",
        cUsu_Nombres: "",
        cUsu_Pass: "",
        cUsu_email: "",
        nRol_ID: 1,
        nRol_ID2: "",
        ImgBinary: "",
        nUsu_ID: ""
    })
    const FuncionListarUsuario = async (_nUsu_ID) => {
        var postData = {
            nUsu_ID: _nUsu_ID,
        }
        await instance
            .post("/Usuario/Perfil", postData)
            .then(response => {

                setUsuario(response.data[0])
            })
            .catch(error => {

            })
    }
    useEffect(() => {
        let nUsu_ID = getDecryptedItem("ID")
        FuncionListarUsuario(nUsu_ID);
    }, [])

    const [state, dispatch] = useReducer(CasoReducer, initialState);
    let history = useHistory()
    const FuncionListarCasos = async () => {
        try {
            let RolId = getDecryptedItem("RolID")

            var postData = {
                nUsu_ID: RolId == 6 || RolId == 1 ? null : getDecryptedItem("ID")


            }
            await instance
                .post("/Casos/Listar", postData)
                .then(response => {
                    dispatch({ type: GET_CASOS, payload: response.data });
                })
        } catch (error) {

        }
    }
    const FuncionListarCasosDesactivar = async () => {
        try {
            var postData = {
                nUsu_ID: getDecryptedItem("ID"),
            }
            await instance
                .post("/Casos/Listar/Desactivados", postData)
                .then(response => {
                    dispatch({ type: GET_CASOS, payload: response.data });
                })
        } catch (error) {

        }
    }
    const FuncionInsertarScraping = async (_nCas_Id, _cCav_Cuadernos, _cCav_General, _cCav_Movimientos, _cCav_Notificacion, _nCli_Id, _cCav_Archivo) => {
        var postData = {
            nCas_Id: _nCas_Id,
            cCav_Cuadernos: _cCav_Cuadernos,
            cCav_General: _cCav_General,
            cCav_Movimientos: _cCav_Movimientos,
            cCav_Notificacion: _cCav_Notificacion,
            cCav_Archivo: _cCav_Archivo,
            cCav_Notas: "",
        }
        await instance
            .post("/Scraping/Insertar", postData)
            .then(response => {
                if (history.location.pathname == "/Casos") {
                    Swal.close();
                    Swal.fire({
                        title: "Mensaje",
                        text: "Agregado exitosamente",
                        icon: "success",
                        confirmButtonText: "Aceptar",
                    })
                    if (getDecryptedItem("temp_nCas_Id") !== null) {
                        FuncionVincularCaso(parseInt(getDecryptedItem("temp_nCas_Id")), _nCas_Id)
                        SelectDetalle(parseInt(getDecryptedItem("temp_nCas_Id")), _nCli_Id)
                    } else {
                        SelectDetalle(_nCas_Id, _nCli_Id)
                    }
                    history.push("/dashboard-Casos")
                }
            })
            .catch(error => {
                Swal.close();
            })
    }
    const FuncionFiltroCasos = async (Row) => {
        try {
            await instance
                .post("/Casos/Filtro", Row)
                .then(response => {

                    dispatch({ type: FLT_CASOS, payload: response.data });
                })
        } catch (error) {

        }
    }
    const FuncionAsuntos = async (postdata) => {
        try {
            await instance
                .post("/Casos/Asunto", postdata)
                .then(response => {

                    dispatch({ type: GET_ASUNTOS, payload: response.data });
                })
        } catch (error) {

        }
    }
    const FuncionLimpiarAsuntos = async () => {
        dispatch({ type: GET_ASUNTOS, payload: [] });
    }
    const FuncionFiltroCasosExpediente = async (Row) => {
        dispatch({ type: FLT_CASOS_EXPEDIENTE, payload: Row });
    }

    const SelectDetalle = async (nCas_Id, nCli_Id) => {
        Swal.fire({
            html:
                '<img id="imgr1" className="imgr" src="/static/media/logo-dark.7a9779c2.png" style="width:50px;height:auto;-webkit-animation: 3s rotate linear infinite;' +
                'animation: 3s rotate linear infinite;' +
                '-webkit-transform-origin: 50% 50%;' +
                'transform-origin: 50% 50%;" /> ' +
                '<br/><br/> Cargando...',
            showConfirmButton: false,
            allowOutsideClick: false
        });
        setEncryptedItem('nCas_Id', nCas_Id);
        setEncryptedItem('CLIID', nCli_Id);
    }
    const FuncionRegistrarCaso = async (postData, _cCav_Cuadernos, _cCav_General, _cCav_Movimientos, _cCav_Notificacion, _cCav_Archivo, GetParticipantesA, GetParticipantesAR, GetParticipantesUAD, GetParticipantesP, getMonedaAR, getMonedaA, getMonedaP, getMonedaUAD, getMontoAR, getMontoA, getMontoP, getMontoUAD, Swal) => {
        let arrayParticipantes = [];
        try {
            await instance
                .post("/Casos/Insertar", postData)
                .then(async response => {
                    let Active_PlanPago = getDecryptedItem("Active_PlanPago");
                    if (Active_PlanPago == 1) {
                        setEncryptedItem('nCas_Id', response.data[0].nCas_Id);
                        setEncryptedItem('CLIID', postData.nCli_Id);
                        history.push("/Dashboard-Casos")
                    }
                    
                    if (GetParticipantesA.length > 0) {
                        const Participantes = GetParticipantesA.map((row, i) => {
                            FuncionAgregarCasoEquipo(response.data[0].nCas_Id, row.value, 'UC02', getMonedaA, getMontoA)
                        })
                        for (let i = 0; i < GetParticipantesA.length; i++) {
                            let obj = {
                                cCas_Tab_TipoEquipo2: "Abogado",
                                color: GetParticipantesA[i].color,
                                email: GetParticipantesA[i].email,
                                label: GetParticipantesA[i].label,
                                value: GetParticipantesA[i].value,
                            }
                            arrayParticipantes.push(obj);
                        }


                    }
                    if (GetParticipantesAR.length > 0) {
                        const Participantes = GetParticipantesAR.map((row, i) => {
                            FuncionAgregarCasoEquipo(response.data[0].nCas_Id, row.value, 'UC01', getMonedaAR, getMontoAR)
                        })
                        for (let i = 0; i < GetParticipantesAR.length; i++) {
                            let obj = {
                                cCas_Tab_TipoEquipo2: "Abogado Responsable",
                                color: GetParticipantesAR[i].color,
                                email: GetParticipantesAR[i].email,
                                label: GetParticipantesAR[i].label,
                                value: GetParticipantesAR[i].value,
                            }
                            arrayParticipantes.push(obj);
                        }
                    }
                    if (GetParticipantesUAD.length > 0) {
                        const Participantes = GetParticipantesUAD.map((row, i) => {
                            FuncionAgregarCasoEquipo(response.data[0].nCas_Id, row.value, 'UC04', getMonedaUAD, getMontoUAD)
                        })
                        for (let i = 0; i < GetParticipantesUAD.length; i++) {
                            let obj = {
                                cCas_Tab_TipoEquipo2: "Usuario Adicional",
                                color: GetParticipantesUAD[i].color,
                                email: GetParticipantesUAD[i].email,
                                label: GetParticipantesUAD[i].label,
                                value: GetParticipantesUAD[i].value,
                            }
                            arrayParticipantes.push(obj);
                        }
                    }
                    if (GetParticipantesP.length > 0) {
                        const Participantes = GetParticipantesP.map((row, i) => {
                            FuncionAgregarCasoEquipo(response.data[0].nCas_Id, row.value, 'UC03', getMonedaP, getMontoP)
                        })
                        for (let i = 0; i < GetParticipantesP.length; i++) {
                            let obj = {
                                cCas_Tab_TipoEquipo2: "Practicante",
                                color: GetParticipantesP[i].color,
                                email: GetParticipantesP[i].email,
                                label: GetParticipantesP[i].label,
                                value: GetParticipantesP[i].value,
                            }
                            arrayParticipantes.push(obj);
                        }
                    }
                    if (_cCav_Cuadernos.length > 0) {
                        FuncionInsertarScraping(response.data[0].nCas_Id, _cCav_Cuadernos, _cCav_General, _cCav_Movimientos, _cCav_Notificacion, postData.nCli_Id, _cCav_Archivo, Swal)
                    }

                    let postData2 = {
                        cAud_nombre: "Casos",
                        nAud_IdReg: response.data[0].nCas_Id,
                        nUsu_ID: getDecryptedItem("ID"),
                        dAud_Fecha: null,
                        nAud_Accion: "U",
                        cTabCodigo: 'ME02'
                    }
                    await instance
                        .post("/Auditoria/Insertar", postData2)
                        .then(response => {
                        });

                    try {
                        let hash = {};
                        arrayParticipantes = arrayParticipantes.filter(o => hash[o.value] ? false : hash[o.value] = true);

                        let nUsu_ID = getDecryptedItem("ID")

                        let objMails = {
                            anfitrion: getUsuario.cUsu_Nombres,
                            codExt: postData.cCas_Cod_Externo,
                            casNom: postData.cCas_Titulo,
                            idCaso: response.data[0].nCas_Id,
                            cliente: postData.nCli_Id,
                            parametro: {
                                tpoTbl: 'caso',   // tipo de tabla
                                tpoAct: 'insertar'  // insertar o actualizar
                            },
                            arrayParticipantes: arrayParticipantes
                        }
                        await instance
                            .post("/mail/event/casos", objMails)
                            .then(res => {

                            })
                    } catch (error) {
                        Swal.close();
                    }
                    FuncionListarCasos();
                })
                .catch(error => {
                    Swal.close();
                })
        } catch (error) {
            Swal.close();
        }
    }
    const FuncionCorreoEvento = async (_nCas_Id, _nUsu_Id, _cCor_Tab_Tipo, _cCor_Asunto) => {
        var postData = {
            nCas_Id: _nCas_Id,
            nUsu_Id: _nUsu_Id,
            cCor_Tab_Tipo: _cCor_Tab_Tipo,
            cCor_Asunto: _cCor_Asunto,
        }

        await instance
            .post("/Correo/Insertar", postData)
            .then(response => {
            })
            .catch(error => {

            })
    }
    const FuncionAgregarCasoEquipo = async (_nCas_Id, _nUsu_Id, _cCas_Tab_TipoEquipo, _cCas_TabMoneda, _nCas_Monto) => {
        var postData = {
            nCas_Id: _nCas_Id,
            nUsu_Id: _nUsu_Id,
            cCas_Tab_TipoEquipo: _cCas_Tab_TipoEquipo,
            cCas_TabMoneda: _cCas_TabMoneda,
            nCas_Monto: _nCas_Monto
        }
        await instance
            .post("/EqCasos/Insertar", postData)
            .then(response => {
                FuncionListarCasos();
                FuncionListarEquipo();
            })
            .catch(error => {

            })
    }
    const FuncionListarEquipo = async () => {
        var postData = {
            nCas_Id: null,
            Tipo: null,
        }
        await instance
            .post("/Casos/Equipo", postData)
            .then(response => {
            })
            .catch(error => {

            })
    }
    const FuncionVincularCaso = async (_nCas_IdOld, _nCas_IdNew) => {
        var postData = {
            nCas_IdOld: _nCas_IdOld,
            nCas_IdNew: _nCas_IdNew,
        }
        await instance
            .post("/Casos/VincularCaso", postData)
            .then(response => {
            })
            .catch(error => {

            })
    }

    return (
        <CasoContext.Provider
            value={{
                casos: state.casos,
                bkcasos: state.bkcasos,
                asunto: state.asunto,
                FuncionListarCasos,
                FuncionFiltroCasos,
                FuncionFiltroCasosExpediente,
                FuncionListarCasosDesactivar,
                FuncionRegistrarCaso,
                FuncionAsuntos,
                FuncionLimpiarAsuntos
            }}
        >
            {props.children}
        </CasoContext.Provider>
    );
}
export default CasoState;
