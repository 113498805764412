import React, { Component, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTags from "react-meta-tags"
import { MDBDataTable } from "mdbreact"
import "../../assets/scss/tablescss/FormatTable.scss"
import Swal from "sweetalert2"
import {
    CardHeader,
    CardText,
    CardBody,
    Card,
    Col,
    Container,
    CardTitle,
    Button,
    Row,
    Modal,
    Title,
    Text,
    Form,
    FormGroup,
    TabContent,
    TabPane,
    FormText,
    Label,
    Input,
    CustomInput,
    CheckBox,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap"
import instance from '../../config'
import { post } from "helpers/api_helper"
import { setEncryptedItem } from "util"
import { getDecryptedItem } from "util"

const baseUrl = process.env.REACT_APP_API_URL
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
const Suscriptores = props => {
    const [modalInsertar, setModalInsertar] = useState(false)
    const [modalActualizar, setModalActualizar] = useState(false)
    const [modalFiltro, setModalFiltro] = useState(false)
    const [data, setData] = useState([
        { 'NombreFlujo': 'Proceso de Conocimiento', 'Descripcion': 'Proceso mas completo de larga duración y complejidad', 'Fases': '8', 'Eliminar': 'x' },
        { 'NombreFlujo': 'Proceso Abreviado ', 'Descripcion': 'Proceso mas corto', 'Fases': '6', 'Eliminar': 'x' },
        { 'NombreFlujo': 'Proceso Sumarísimo ', 'Descripcion': 'Proceso mas rápido', 'Fases': '3', 'Eliminar': 'x' },
        { 'NombreFlujo': 'Proceso Cautelar  ', 'Descripcion': 'Proceso para asegurar la sentencia', 'Fases': '1', 'Eliminar': 'x' },
        { 'NombreFlujo': 'Proceso de Ejecución', 'Descripcion': 'Proceso una vez se tiene sentencia firme', 'Fases': '2', 'Eliminar': 'x' },
    ])
    const [dataPlan, setDataPlan] = useState([])
    const [validData, setValidData] = useState(false)
    const [getErrores, setErrores] = useState({});
    const [getSus, setSus] = useState({
        nSus_Id: "",
        nPla_ID: "",
        nPro_ID: "",
        cSus_Codigo: "",
        cSus_Categoria: "",
        cSus_Nombres: "",
        dSus_FechaVencimiento: "",
        dSus_FechaVencimiento2: "",
        dSus_FechaCreacion: "",
        dSus_FechaCreacion2: "",
        dSus_UltimoPago: "",
        dSus_UltimoPago2: "",
        cSus_Correo: "",
        cSus_Pass: "",
        cSus_Telefono: "",
        cSus_Empresa: "",
        cSus_CadenaConexion: "",
        cSus_Cuenta: "",
        bSus_Estado: 1,
        bSus_Visto: 1,
        cSus_Fac_Pais: "",
        cSus_Fac_PrimerNombre: "",
        cSus_Fac_Apellido: "",
        cSus_Fac_Correo: "",
        cSus_Fac_Compania: "",
        cSus_Fac_Telefono: "",
        cSus_Fac_DireccionL1: "",
        cSus_Fac_DireccionL2: "",
        cSus_Fac_DireccionL3: "",
        cSus_Fac_Ciudad: "",
        cSus_Fac_Departamento: "",
        cSus_Fac_CodigoPostal: "",
        nSus_Fac_Plan: "",
        cSus_Fac_Canal: "",
        cSus_Fac_tab_FormaPago: "",
        cSus_Fac_Ruc: "",
        dSus_Fac_ProxPago: "",
        dSus_Fac_ProxPago2: ""
    })
    const [getFiltro, setFiltro] = useState({
        FechaInicial: ano + "-" + "01" + "-" + "01",
        FechaFin: ano + "-" + mes + "-" + dia,
    })
    const [dataFlujos, setDataFlujo] = useState([])
    const [getFlujo, setFlujo] = useState({
        nfFlu_Id: "",
        cfFlu_Nombre: "",
        cfFlu_Descripcion: "",
        cfFlu_Descripcion2: ""
    })

    const Validacion = (form) => {
        let errors = {};
        let regexLetras = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
        let regexCorreo = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
        let regexNumero = /^[0-9]+$/;

        if (form.cfFlu_Nombre == null || form.cfFlu_Nombre == "" || form.cfFlu_Nombre.trim() == "") {
            errors.cfFlu_Nombre = "(*)"
        }

        if (form.cfFlu_Descripcion == null || form.cfFlu_Descripcion == "" || form.cfFlu_Descripcion.trim() == "") {
            errors.cfFlu_Descripcion = "(*)"
        }
        if (form.cfFlu_Descripcion2 == null || form.cfFlu_Descripcion2 == "" || form.cfFlu_Descripcion2.trim() == "") {
            errors.cfFlu_Descripcion2 = "(*)"
        }

        return errors;
    }
    const handleBlur = e => {
        handleChange(e)

        setErrores(Validacion(getFlujo))

    }
    const FuncionValidarFormulario = async () => {
        if (Object.keys(getErrores).length === 0) {
            FuncionAgregarFlujo()

        } else {
            setValidData(true);
            Swal.fire({
                title: "Mensaje",
                text: "Complete los campos requeridos",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        }
    }

    const FuncionAgregarFlujo = async () => {
        if (getFlujo.nfFlu_Id) {

            var postData = {
                nfFlu_Id: getFlujo.nfFlu_Id,
                cfFlu_Nombre: getFlujo.cfFlu_Nombre,
                cfFlu_Descripcion: getFlujo.cfFlu_Descripcion,
                cfFlu_Descripcion2: getFlujo.cfFlu_Descripcion2,

            }


            await instance
                .post("/Flujo/Actualizar", postData)
                .then(response => {

                    location.href = "Flujo";
                    Swal.fire({
                        title: "Mensaje",
                        text: "Agregado exitosamente",
                        icon: "success",
                        confirmButtonText: "Aceptar",
                    })
                });
        }
        else {
            var postData = {
                cfFlu_Nombre: getFlujo.cfFlu_Nombre,
                cfFlu_Descripcion: getFlujo.cfFlu_Descripcion,
                cfFlu_Descripcion2: getFlujo.cfFlu_Descripcion2,
            }


            await instance
                .post("/Flujo/Insertar", postData)
                .then(response => {

                    let nfFlu_Id = response.data[0].nfFlu_Id; // este es el id de la tarea creada
                    setEncryptedItem('nfFlu_Id', nfFlu_Id);
                    location.href = "FlujosDetalle";
                    Swal.fire({
                        title: "Mensaje",
                        text: "Agregado exitosamente",
                        icon: "success",
                        confirmButtonText: "Aceptar",
                    })
                });
        }

    }
    const FuncionListarSuscriptores = async () => {
        await instance
            .post("/Suscriptores/Listar")
            .then(response => {
                setData(response.data)

            })
            .catch(error => {

            })
    }

    const FuncionBuscarPlan_PorTipoPlan = async (nPro_ID) => {
        var postData = {
            nPro_ID: nPro_ID
        }
        await instance
            .post("/Plan/PlanesBuscar_porTipoPlan", postData)
            .then(response => {

                setDataPlan(response.data)

            })
            .catch(error => {

            })
    }

    const handleChange = e => {
        const { name, value } = e.target
        setFlujo(prevState => ({
            ...prevState,
            [name]: value,
        }))

    }
    const FuncionEliminarSuscriptores = async (_nSus_Id) => {
        var postData = {
            nSus_Id: Number.parseInt(_nSus_Id),
        }
        await instance
            .post("/Suscriptores/Eliminar", postData)
            .then(response => {
                FuncionListarSuscriptores()

                Swal.fire({
                    title: "Mensaje",
                    text: "Eliminado exitosamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })
            })
            .catch(error => {

            })
    }
    const Acciones = (Suscriptor) => {

        setEncryptedItem('nSus_Id', Suscriptor.nSus_Id);

    }
    const datas = {
        columns: [

            {
                label: "Nombre del Flujo de Trabajo",
                field: "NombreFlujo",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "Descripcion",
                field: "Descripcion",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "Fases",
                field: "Fases",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "Eliminar",
                field: "Eliminar",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },


        ],
        rows: data,
    }
    const AbrirNv = (Url) => {
        window.open(Url, '_blank')
    }
    const badgesData = {
        columns: [
            ...datas.columns,
            {
                label: "Acciones",
                field: "acciones",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
        ],
        rows: [
            ...datas.rows.map((row, id) => ({
                ...row,
                cuenta: (
                    <div>
                        <Link onClick={() => AbrirNv(row.cSus_Cuenta)}>
                            {row.cSus_Cuenta}
                        </Link>
                    </div>
                ),
                acciones: (
                    <div>
                        <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="dropdown-toggle">
                                <i className="bx bx-dots-vertical-rounded" />
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem>
                                    <Link onClick={() => Acciones(row)} to="/NuevoAmbiente">
                                        Editar
                                    </Link>
                                </DropdownItem>

                                <DropdownItem
                                    onClick={() =>
                                        Swal.fire({
                                            title: "Mensaje",
                                            html:
                                                "Estas seguro de eliminar al cliente : <b>" +
                                                row.cSus_Nombres +
                                                "</b> ?",
                                            icon: "warning",
                                            showCancelButton: true,
                                            confirmButtonColor: "#3085d6",
                                            cancelButtonColor: "#d33",
                                            confirmButtonText: "Aceptar",
                                            cancelButtonText: "Cancelar",
                                        }).then(result => {
                                            if (result.isConfirmed) {

                                                FuncionEliminarSuscriptores(row.nSus_Id)
                                            }
                                        })
                                    }
                                >
                                    Eliminar
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                ),
            })),
        ],
    }
    const FuncionBuscarFlujo = async (_nfFlu_Id) => {

        var postData = {
            nfFlu_Id: _nfFlu_Id
        }
        await instance
            .post("/Flujo/Obtener", postData)
            .then(response => {
                setFlujo(response.data[0])

            })
            .catch(error => {

            })
    }
    useEffect(() => {
        let _nfFlu_Id = getDecryptedItem("nfFlu_Id");
        if (_nfFlu_Id) {
            FuncionBuscarFlujo(_nfFlu_Id);
        }
        setErrores(Validacion(getFlujo))

    }, [])
    return (
        <React.Fragment>
            <div className="page-content">
                {/* add meta title */}
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="" breadcrumbItem="" />
                    <Row>
                        <Col className="col-12 row center">
                            <Col className="col-10">
                                <CardTitle className="h4" style={{ fontSize: "30px" }}>
                                    Flujos de Trabajo
                                </CardTitle>
                            </Col>
                            <Col className="col-2" >

                            </Col>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '40px' }}>
                        <Col className="col-12 row" >
                            <Card className="col-12">
                                <CardBody>

                                    <Row>
                                        <Col lg="6">
                                            <FormGroup className="mb-2">

                                                <Input
                                                    type="text"
                                                    className={getErrores.cfFlu_Nombre && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                                                    id="cfFlu_Nombre"
                                                    value={getFlujo && getFlujo.cfFlu_Nombre}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="cfFlu_Nombre"
                                                    placeholder="Nombre del flujo de trabajo"
                                                    maxLength="200"
                                                />
                                                <label className="col-form-label labelDown" style={{ marginLeft: '0px' }}>Flujo de trabajo</label>{getErrores.cfFlu_Nombre && <label className="col-form-label labelDown labelRed">{getErrores.cfFlu_Nombre}</label>}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6">
                                            <FormGroup className="mb-2">

                                                <Input
                                                    placeholder="Evento para calculo 1"
                                                    type="text"
                                                    className={getErrores.cfFlu_Descripcion && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                                                    id="cfFlu_Descripcion"
                                                    value={getFlujo && getFlujo.cfFlu_Descripcion}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="cfFlu_Descripcion"
                                                    maxLength="200"
                                                />
                                                <label className="col-form-label labelDown" style={{ marginLeft: '0px' }}>Evento para calculo</label>{getErrores.cfFlu_Descripcion && <label className="col-form-label labelDown labelRed">{getErrores.cfFlu_Descripcion}</label>}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6">
                                            <FormGroup className="mb-2">

                                                <Input
                                                    placeholder="Detalle del evento"
                                                    className={getErrores.cfFlu_Descripcion2 && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                                                    type="text"
                                                    id="cfFlu_Descripcion2"
                                                    value={getFlujo && getFlujo.cfFlu_Descripcion2}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="cfFlu_Descripcion2"
                                                    maxLength="200"
                                                />
                                                <label className="col-form-label labelDown" style={{ marginLeft: '0px' }}>Detalle</label>{getErrores.cfFlu_Descripcion2 && <label className="col-form-label labelDown labelRed">{getErrores.cfFlu_Descripcion2}</label>}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <CardBody style={{ paddingLeft: '0px' }}>
                                        <div style={{ paddingLeft: '0px' }}>
                                            <button
                                                type="button"
                                                onClick={() => FuncionValidarFormulario()}
                                                className="btn btn-danger waves-effect waves-light"
                                                data-toggle="modal"
                                                data-target="#myModal"
                                            >
                                                Guardar
                                            </button>

                                            {" "}
                                            <button
                                                type="button"
                                                onClick={() => location.href = "Flujo"}
                                                className="btn btn-danger waves-effect waves-light"
                                                data-toggle="modal"
                                                data-target="#myModal"
                                            >
                                                Cerrar
                                            </button>
                                        </div>
                                    </CardBody>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default Suscriptores