import React, { useState, useEffect } from "react"
import { useContext } from "react"
import { MDBDataTable } from "mdbreact"
import { Link } from "react-router-dom"
import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
} from "reactstrap"
import Swal from "sweetalert2"
import RolesContext from "../../Context/Roles/RolesContext";
import ModalRoles from "./Modal"
const Lista = () => {
    const rolesContext = useContext(RolesContext);
    const datas = {
        columns: [
            {
                label: "Nombre rol",
                field: "cRol_Nombre",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "Estado",
                field: "bRol_Estado",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
        ],
        rows: rolesContext.roles,
    }
    const Acciones = {
        columns: [
            ...datas.columns,
            {
                label: "Acciones",
                field: "acciones",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
        ],
        rows: [
            ...datas.rows.map((row, id) => ({
                ...row,
                acciones: (
                    <div>
                        <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="dropdown-toggle">
                                <i className="bx bx-dots-vertical-rounded" />
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem
                                    onClick={() =>
                                        EditarRoles(row.nRol_ID, row)
                                    }>
                                    Editar
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() =>
                                        console.table(row)
                                    }
                                >
                                    Eliminar
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                ),
            })),
        ],
    }
    const [getModal, setModal] = useState(false)
    const [getId, setId] = useState(0)
    const [getSelect, setSelect] = useState()
    const EditarRoles = (Id, Row) => {
        setId(Id);
        setSelect(Row)
        abrirCerrarModal()
    }
    const abrirCerrarModal = Modal => {
        if (!getModal == false) {
            Swal.fire({
                html: "¿Seguro que desea salir sin guardar los datos?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Si",
                cancelButtonText: "No",
            }).then(result => {
                if (result.isConfirmed) {
                    setModal(!getModal)
                }
            })
        } else {
            setModal(!getModal)
        }
    }
    useEffect(() => {
        rolesContext.FuncionListarRoles()
    }, []);
    return (
        <>
        {(() => {
            if (getModal == true) {
                return <>
                    <ModalRoles show={getModal}
                        onCloseClick={() => abrirCerrarModal(1)}
                        Titulo="Editar Rol"
                        Id={getId}
                        Row={getSelect}
                        onCloseModal={() => setModal(!getModal)}
                        ReloadLista={() => setModal(false)}
                    />
                </>
            }
        })()}
            <MDBDataTable
                data={Acciones}
                responsiveXl
                fixed
                hover
                entriesLabel="Mostrar"
                entrylabel={"Mostrar entradas"}
                infoLabel={["Mostrando ", "a", "de", "registros"]}
                bordered
                order={["age", "desc"]}
                noRecordsFoundLabel={"No hay datos"}
                searchLabel={"Buscar"}
                noBottomColumns
                paginationLabel={["Anterior", "Siguiente"]}
                theadColor="HeadBlue"
            />
        </>
    )
}
export default Lista