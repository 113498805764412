import React, { Component, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTags from "react-meta-tags"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import Swal from "sweetalert2"
import { getDecryptedItem } from '../../util';

import {
  CardBody,
  Card,
  Col,
  Container,
  CardTitle,
  Button,
  Row,
  Modal,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap"
import instance from '../../config'
const baseUrl = process.env.REACT_APP_API_URL
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
const Cuenta = props => {
  const [modalInsertar, setModalInsertar] = useState(false)
  const [modalActualizar, setModalActualizar] = useState(false)
  const [modalFiltro, setModalFiltro] = useState(false)
  const [data, setData] = useState([])
  const [blockBtn, setBlockBtn] = useState(false)
  const [dataComboBanco, setDataComboBanco] = useState([])
  const [dataComboMoneda, setDataComboMoneda] = useState([])
  const [hiddenBtnEditar, setHiddenBtnEditar] = useState(true)
  const [hiddenBtnEliminar, setHiddenBtnEliminar] = useState(true)
  const [validData, setValidData] = useState(false)
  const [getCuenta, setCuenta] = useState({
    cCue_Nombre: "",
    cCue_Numero: "",
    cCue_Tab_Banco: "",
    cCue_Tab_Moneda: "",
    bCue_Estado: 0,
    nCue_Id: 0,
  })
  const [getErrores, setErrores] = useState({});
  const Validacion = (form) => {
    let errors = {};
    let regexLetras = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    let regexCorreo = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    let regexNumero = /^[0-9]+$/;
    if (form.cCue_Tab_Banco == 0) {
      errors.cCue_Tab_Banco = "(*)"

    }
    if (form.cCue_Tab_Moneda == 0) {
      errors.cCue_Tab_Moneda = "(*)"
    }
    if (form.cCue_Nombre == "") {
      errors.cCue_Nombre = "(*)"
    }
    if (form.cCue_Numero == "") {
      errors.cCue_Numero = "(*)"
    }
    return errors;
  }
  const handleBlur = e => {
    handleChange(e)
    setErrores(Validacion(getCuenta))

  }

  const handleChange = e => {
    const { name, value } = e.target
    setErrores(Validacion(getCuenta))
    setCuenta(prevState => ({
      ...prevState,
      [name]: value,
    }))

  }
  const abrirCerrarModal = Modal => {
    if (Modal == 1) {
      if (!modalInsertar == false) {
        Swal.fire({
          html: "¿Seguro que desea salir sin guardar los datos?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        }).then(result => {
          if (result.isConfirmed) {
            setModalInsertar(!modalInsertar)
          }
        })
      } else {

        setModalInsertar(!modalInsertar)
      }
      let cuenta = {
        cCue_Nombre: "",
        cCue_Numero: "",
        cCue_Tab_Banco: "",
        cCue_Tab_Moneda: "",
        bCue_Estado: 0,
        nCue_Id: 0,
      }
      setCuenta(cuenta)
      setErrores(Validacion(cuenta))
    } else if (Modal == 2) {
      if (modalActualizar == true) {
        let cuenta = {
          cCue_Nombre: "",
          cCue_Numero: "",
          cCue_Tab_Banco: "",
          cCue_Tab_Moneda: "",
          bCue_Estado: 0,
          nCue_Id: 0,
        }

        setCuenta(cuenta)
        setErrores(Validacion(cuenta))
      }

      setModalActualizar(!modalActualizar)


    } else if (Modal == 3) {
      setModalFiltro(!modalFiltro)
    }
  }
  const Acciones = (cuenta) => {
    setCuenta(cuenta)

    abrirCerrarModal(2)
    setErrores(Validacion(cuenta))
  }
  const datas = {
    columns: [
      {
        label: "Codigo",
        field: "nCue_Id",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Banco",
        field: "cTabNombre",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Detalle",
        field: "cCue_Nombre",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Numero de cuenta",
        field: "cCue_Numero",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Moneda",
        field: "Moneda",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
    ],
    rows: data,
  }
  const badgesData = {
    columns: [
      ...datas.columns,
      {
        label: "Acciones",
        field: "acciones",
        fixedColumns: true,
        attributes: {
          className: "alignLeft",
        },
      },
    ],
    rows: [
      ...datas.rows.map((row, id) => ({
        ...row,
        acciones: (
          <div>
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="dropdown-toggle">
                <i className="bx bx-dots-vertical-rounded" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem hidden={hiddenBtnEditar} onClick={() => Acciones(row)}>Editar</DropdownItem>
                <DropdownItem hidden={hiddenBtnEliminar} onClick={() =>
                  Swal.fire({
                    title: "Mensaje",
                    html:
                      "Está seguro de eliminar este registro?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Aceptar",
                    cancelButtonText: "Cancelar",
                  }).then(result => {
                    if (result.isConfirmed) {
                      FuncionEliminar(row)
                    }
                  })
                }>Eliminar</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        ),
      })),
    ],
  }

  /** Cuentas */
  const FuncionListarCuentas = async () => {
    var postData = {
    }
    await instance
      .post("/Cuentas/Listar", postData)
      .then(response => {
        setData(response.data)

      })
      .catch(error => {

      })
  }
  const FuncionListarComboMoneda = async () => {
    var postData = {
      Prefijo: "MO",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataComboMoneda(response.data)


      })
      .catch(error => {

      })
  }
  const FuncionListarBancos = async () => {
    var postData = {
    }
    await instance
      .post("/Cuentas/Listar/Bancos", postData)
      .then(response => {
        setDataComboBanco(response.data)

      })
      .catch(error => {

      })
  }
  const FuncionValidarFormulario = async () => {
    if (Object.keys(getErrores).length === 0) {
      setBlockBtn(true);
      FuncionInsertar()
    } else {
      setValidData(true);
      Swal.fire({
        title: "Mensaje",
        text: "Complete los campos requeridos",
        icon: "error",
        confirmButtonText: "Aceptar",
      })
    }
  }
  const FuncionValidarFormularioActualizar = async () => {
    if (Object.keys(getErrores).length === 0) {
      setBlockBtn(true);
      FuncionActualizar()
    } else {
      setValidData(true);
      Swal.fire({
        title: "Mensaje",
        text: "Complete los campos requeridos",
        icon: "error",
        confirmButtonText: "Aceptar",
      })
    }
  }
  const FuncionInsertar = async () => {
    var postData = {
      cCue_Nombre: getCuenta.cCue_Nombre,
      cCue_Numero: getCuenta.cCue_Numero,
      cCue_Tab_Banco: getCuenta.cCue_Tab_Banco,
      cCue_Tab_Moneda: getCuenta.cCue_Tab_Moneda,
      bCue_Estado: 1,
    }
    await instance
      .post("/Cuentas/Insertar", postData)
      .then(response => {
        Swal.fire({
          title: "Mensaje",
          text: "Agregado exitosamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        })
        setBlockBtn(false);
        setModalInsertar(!modalInsertar)
        FuncionListarCuentas()
      })
      .catch(error => {

      })
  }
  const FuncionActualizar = async () => {
    var postData = {
      cCue_Nombre: getCuenta.cCue_Nombre,
      cCue_Numero: getCuenta.cCue_Numero,
      cCue_Tab_Banco: getCuenta.cCue_Tab_Banco,
      cCue_Tab_Moneda: getCuenta.cCue_Tab_Moneda,
      bCue_Estado: 1,
      nCue_Id: getCuenta.nCue_Id,
    }
    await instance
      .post("/Cuentas/Actualizar", postData)
      .then(response => {
        Swal.fire({
          title: "Mensaje",
          text: "Actualizado exitosamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        })
        setBlockBtn(false);
        setModalActualizar(!modalActualizar)
        FuncionListarCuentas()
      })
      .catch(error => {

      })
  }
  const FuncionEliminar = async (Cuenta) => {
    var postData = {
      cCue_Nombre: Cuenta.cCue_Nombre,
      cCue_Numero: Cuenta.cCue_Numero,
      cCue_Tab_Banco: Cuenta.cCue_Tab_Banco,
      cCue_Tab_Moneda: Cuenta.cCue_Tab_Moneda,
      bCue_Estado: 0,
      nCue_Id: Cuenta.nCue_Id,
    }
    await instance
      .post("/Cuentas/Actualizar", postData)
      .then(response => {


        Swal.fire({
          title: "Mensaje",
          text: "Eliminado exitosamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        })
        FuncionListarCuentas()
      })
      .catch(error => {

      })
  }
  const FuncionListarPermisos = async () => {
    var postData = {
      nUsu_ID: parseInt(getDecryptedItem("RolID"))
    }
    await instance
      .post("/Usuario/Acceso", postData)
      .then(response => {
        let arrayPermisos = response.data;
        const permiso = arrayPermisos.find(element => element.cUsu_Modulo == "ME31");

        if (permiso.bUsu_Editar == 1) {
          setHiddenBtnEditar(false);
        }
        else {
          setHiddenBtnEditar(true);
        }
        if (permiso.bUsu_Eliminar == 1) {
          setHiddenBtnEliminar(false);
        }
        else {
          setHiddenBtnEliminar(true);
        }
      })
      .catch(error => {

      })
  }
  useEffect(() => {
    FuncionListarPermisos();
    FuncionListarComboMoneda();
    FuncionListarCuentas();
    FuncionListarBancos();
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        {/* add meta title */}
        <MetaTags>
          <title>Configuración de cuentas bancarias | GpsLegal</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="GpsLegal" breadcrumbItem="Configuración de cuentas bancarias" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div>
                    <button
                      type="button"
                      onClick={() => abrirCerrarModal(1)}
                      className="btn btn-danger waves-effect waves-light"
                      data-toggle="modal"
                      data-target="#myModal"
                      hidden={hiddenBtnEditar}
                    >
                      Nueva Cuenta
                    </button>{" "}
                    <Modal isOpen={modalInsertar}>
                      <div
                        className="modal-header ModalHeaderColor"
                      >
                        <h5
                          style={{ color: "#fff" }}
                          className="modal-title mt-0"
                          id="myModalLabel"
                        >
                          Nueva cuenta bancaria
                        </h5>
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(1)}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <select
                              className={getErrores.cCue_Tab_Banco && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                              name="cCue_Tab_Banco"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value="0">Seleccionar</option>
                              {dataComboBanco.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>

                            <label className="col-form-label labelDown">Banco</label>{getErrores.cCue_Tab_Banco && <label className="col-form-label labelDown labelRed">{getErrores.cCue_Tab_Banco}</label>}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <input
                              className={getErrores.cCue_Nombre && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                              type="text"
                              placeholder="Nombre"
                              name="cCue_Nombre"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              maxLength="100"
                            />
                            <label className="col-form-label labelDown">Nombre de la cuenta</label>{getErrores.cCue_Nombre && <label className="col-form-label labelDown labelRed">{getErrores.cCue_Nombre}</label>}

                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <input
                              className={getErrores.cCue_Numero && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                              type="text"
                              placeholder="Numero"
                              name="cCue_Numero"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              maxLength="50"
                            />
                            <label className="col-form-label labelDown">Numero de cuenta</label>{getErrores.cCue_Numero && <label className="col-form-label labelDown labelRed">{getErrores.cCue_Numero}</label>}

                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <select
                              className={getErrores.cCue_Tab_Moneda && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                              name="cCue_Tab_Moneda"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value="0">Seleccionar</option>
                              {dataComboMoneda.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">Moneda</label>{getErrores.cCue_Tab_Moneda && <label className="col-form-label labelDown labelRed">{getErrores.cCue_Tab_Moneda}</label>}
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">

                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(1)}
                          className="btn btn-secondary waves-effect"
                          data-dismiss="modal"
                        >
                          Cerrar
                        </button>
                        <button
                          type="button"
                          disabled={blockBtn}
                          onClick={() => FuncionValidarFormulario()}
                          className="btn btn-danger waves-effect waves-light"
                        >
                          Guardar
                        </button>
                      </div>
                    </Modal>
                    <Modal isOpen={modalActualizar}>
                      <div
                        className="modal-header ModalHeaderColor"
                      >
                        <h5
                          style={{ color: "#fff" }}
                          className="modal-title mt-0"
                          id="myModalLabel"
                        >
                          Editar cuenta bancaria
                        </h5>
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(2)}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <select

                              className={getErrores.cCue_Tab_Banco && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                              name="cCue_Tab_Banco"
                              value={getCuenta && getCuenta.cCue_Tab_Banco}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value="0">Seleccionar</option>
                              {dataComboBanco.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">Banco</label>{getErrores.cCue_Tab_Banco && <label className="col-form-label labelDown labelRed">{getErrores.cCue_Tab_Banco}</label>}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <input
                              className={getErrores.cCue_Nombre && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                              type="text"
                              placeholder="Nombre"
                              name="cCue_Nombre"
                              value={getCuenta && getCuenta.cCue_Nombre}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              maxLength="100"
                            />
                            <label className="col-form-label labelDown">
                              Nombre de la cuenta
                            </label><label className="col-form-label labelDown labelRed">{getErrores.cCue_Nombre}</label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <input
                              className={getErrores.cCue_Numero && validData ? "invalid form-control inputDown" : "form-control inputDown"}
                              type="text"
                              placeholder="Numero"
                              name="cCue_Numero"
                              value={getCuenta && getCuenta.cCue_Numero}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              maxLength="50"
                            />
                            <label className="col-form-label labelDown">Numero de cuenta</label>{getErrores.cCue_Numero && <label className="col-form-label labelDown labelRed">{getErrores.cCue_Numero}</label>}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-12">
                            <select
                              className={getErrores.cCue_Tab_Moneda && validData ? "invalid form-select inputDown" : "form-select inputDown"}
                              name="cCue_Tab_Moneda"
                              value={getCuenta && getCuenta.cCue_Tab_Moneda}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value="0">Seleccionar</option>
                              {dataComboMoneda.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">Moneda</label>{getErrores.cCue_Tab_Moneda && <label className="col-form-label labelDown labelRed">{getErrores.cCue_Tab_Moneda}</label>}
                          </div>
                        </div>
                      </div>

                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(2)}
                          className="btn btn-secondary waves-effect"
                          data-dismiss="modal"
                        >
                          Cerrar
                        </button>
                        <button
                          type="button"
                          disabled={blockBtn}
                          onClick={() => FuncionValidarFormularioActualizar()}
                          className="btn btn-danger waves-effect waves-light"
                        >
                          Guardar
                        </button>
                      </div>
                    </Modal>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle style={{ fontSize: "20px", color: "#aa1b0E", fontWeight: "normal" }}>
                    <span style={{ color: "#aa1b0E" }}>Listado de cuentas bancarias</span>{" "}
                  </CardTitle>
                  <MDBDataTable
                    data={badgesData}
                    className="TablaCuentaBancaria"
                    responsiveXl
                    fixed
                    hover
                    entriesLabel="Mostrar"
                    entrylabel={"Mostrar entradas"}
                    infoLabel={["Mostrando ", "a", "de", "registros"]}
                    bordered
                    order={["age", "desc"]}
                    noRecordsFoundLabel={"No hay datos"}
                    searchLabel={"Buscar"}
                    noBottomColumns
                    paginationLabel={["Anterior", "Siguiente"]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Cuenta
