//Funciones de listado de combos
export const GET_COMBO_TDOCUMENTO = "GET_COMBO_TDOCUMENTO";
export const GET_COMBO_DEPARTAMENTO = "GET_COMBO_DEPARTAMENTO";
export const GET_COMBO_PROVINCIA = "GET_COMBO_PROVINCIA";
export const GET_COMBO_DISTRITO = "GET_COMBO_DISTRITO";
export const GET_COMBO_CLIENTE = "GET_COMBO_CLIENTE";
export const GET_COMBO_CASO = "GET_COMBO_CASO";
export const GET_COMBO_ESTADO = "GET_COMBO_ESTADO";
export const GET_COMBO_RECORDAR = "GET_COMBO_RECORDAR";
export const GET_COMBO_CATEVENTO = "GET_COMBO_CATEVENTO";
export const GET_COMBO_GRUPO = "GET_COMBO_GRUPO";
export const GET_COMBO_TIPEVENTO = "GET_COMBO_TIPEVENTO";
export const GET_COMBO_RECORDAREVENTO = "GET_COMBO_RECORDAREVENTO";
export const GET_COMBO_PARTICIPANTESEVENTO = "GET_COMBO_PARTICIPANTESEVENTO";
export const GET_COMBO_CATTAREA = "GET_COMBO_CATTAREA";
export const GET_COMBO_TIPOTAREA = "GET_COMBO_TIPOTAREA";
export const GET_COMBO_RECORDARTAREA = "GET_COMBO_RECORDARTAREA";
export const GET_COMBO_JURISDICCION = "GET_COMBO_JURISDICCION";
export const GET_COMBO_JUZGADO = "GET_COMBO_JUZGADO";
export const GET_COMBO_MATERIA = "GET_COMBO_MATERIA";
export const GET_COMBO_SUBMATERIA = "GET_COMBO_SUBMATERIA";
export const GET_COMBO_ESTADOINTERNO = "GET_COMBO_ESTADOINTERNO";
export const GET_COMBO_ESTADOEXTERNO = "GET_COMBO_ESTADOEXTERNO";
export const GET_COMBO_USUARIO = "GET_COMBO_USUARIO";
export const GET_COMBO_EQUIPO = "GET_COMBO_EQUIPO";
export const GET_COMBO_MONEDA = "GET_COMBO_MONEDA";
export const GET_COMBO_LUGARTRAMITE = "GET_COMBO_LUGARTRAMITE";
export const GET_COMBO_AREA = "GET_COMBO_AREA";
export const GET_COMBO_TEXPEDIENTE = "GET_COMBO_TEXPEDIENTE";

export const GET_COMBO_METODOPAGO = "GET_COMBO_METODOPAGO";
export const GET_COMBO_TINGRESO = "GET_COMBO_TINGRESO";
export const GET_COMBO_CUENTA = "GET_COMBO_CUENTA";
export const GET_COMBO_TEGRESO = "GET_COMBO_TEGRESO";
export const GET_COMBO_TCORREO = "GET_COMBO_TCORREO";
export const GET_COMBO_THORASLABORADAS = "GET_COMBO_THORASLABORADAS";
export const GET_COMBO_ABOGADORESPONSABLE = "GET_COMBO_ABOGADORESPONSABLE";
export const GET_COMBO_ABOGADO = "GET_COMBO_ABOGADO";
export const GET_COMBO_PRACTICANTE = "GET_COMBO_PRACTICANTE";
export const GET_COMBO_USUARIOADICIONAL = "GET_COMBO_USUARIOADICIONAL";

//Tipo de Funciones Casos
export const GET_CASOS = "GET_CASOS";
export const FLT_CASOS = "FLT_CASOS";
export const FLT_CASOS_EXPEDIENTE = "FLT_CASOS_EXPEDIENTE";

//Tipo de Funciones Cliente
export const GET_CLIENTE = "GET_CLIENTE";
export const GET_CLIENTEULT = "GET_CLIENTEULT";
export const ADD_CLIENTE = "ADD_CLIENTE";
export const UPD_CLIENTE = "UPD_CLIENTE";
export const DLT_CLIENTE = "DLT_CLIENTE";
//Tipo de Funciones Evento
export const GET_EVENTO = "GET_EVENTO";
export const ADD_EVENTO = "ADD_EVENTO";
export const UPD_EVENTO = "UPD_EVENTO";
export const DLT_EVENTO = "DLT_EVENTO";
//Tipo de Funciones Tarea
export const GET_TAREA = "GET_TAREA";
export const ADD_TAREA = "ADD_TAREA";
export const UPD_TAREA = "UPD_TAREA";
export const DLT_TAREA = "DLT_TAREA";

// Liquidacion Procesada
export const GET_REPORTE_LQP="GET_REPORTE_LQP";

// Roles
export const GET_ROLES="GET_ROLES";
export const ADD_ROLES="ADD_ROLES";
export const UPD_ROLES="UPD_ROLES";
export const GET_ASUNTOS="GET_ASUNTOS";



