import React from "react"
import { Redirect } from "react-router-dom"
//Modulos
import HorasLaboradas from "../pages/HorasLaboradas/HorasLaboradas"
import Casos2 from "../pages/Casos/Casos"
import Casos from "../pages/Menus/Casos/Caso"
import CasosDesactivados from "../pages/Menus/Casos/CasosDesactivados"
import Egresos from "../pages/Egresos/Egresos"
import AbogadoVirtual from "../pages/Consultas/AbogadoVT"
import NuevoAmbiente from "../pages/Consultas/NuevoAmbiente"
import Suscriptores from "../pages/Consultas/Suscriptores"
import Ingresos from "../pages/Ingresos/Ingresos"
import Tareas from "../pages/Consultas/Tareas"
import Evento from "../pages/Consultas/Evento"
import UploadDocument from "../pages/Consultas/UploadDocument"
import Cliente from "../pages/Menus/Cliente/Vista/Vista"
import Usuario from "../pages/Consultas/Usuarios"
import Notificaciones from "../pages/Notificaciones/Notificaciones"
import Correo from "../pages/Consultas/Correo"
import NuevoModulo from "../pages/Consultas/NuevoModulo"
import Calendario from "../pages/Calendar/index"
import Maestros from "../pages/Consultas/Maestro"
import HistorialNovedades from "../pages/Menus/HistorialNovedades/HistorialNovedades"
import Tarifa from "../pages/Consultas/Tarifa"
import Flujos from "../pages/Consultas/Flujos"
import FlujosNuevo from "../pages/Consultas/FlujosNuevo"
import FlujosDetalle from "../pages/Consultas/FlujosDetalle"
import FlujosTarea from "../pages/Consultas/FlujosTarea"
import FlujosNuevaTarea from "../pages/Consultas/FlujosNuevaTarea"
import FlujosNuevaTareacopy from "../pages/Consultas/FlujosNuevaTareacopy"
import FlujosRedactarDemanda from "../pages/Consultas/FlujosRedactarDemanda"

//Login
import IniciarSesion from "../pages/AuthenticationInner/IniciarSesion"
import RecuperarContraseña from "../pages/AuthenticationInner/RecuperarContraseña"
import RegistrarSuscriptor from "pages/AuthenticationInner/RegistrarSuscriptor"
import VerificarCorreo from "pages/AuthenticationInner/VerificarCorreo"
import CambiarContraseña from "pages/AuthenticationInner/cambiarPass"
import VerificarSuscriptor from "pages/AuthenticationInner/VerificarSuscriptor"
//Dashboard
import DashboardCasos from "../pages/Dashboards/Dashboard-Casos"
import DashboardCliente from "../pages/Dashboards/Dashboard-Cliente"
import DashboardAbogado from "../pages/Dashboards/Dashboard-Abogado"
import DashboardSuscriptor from "../pages/Dashboards/Dashboard-Suscriptor";
//Reportes
import ReporteEstadoCuenta2 from "../pages/Consultas/ReporteEstadoCuenta2"
import ReporteEstadoCuenta from "../pages/Consultas/ReporteEstadoCuenta"
import ReporteProcesar from "../pages/Consultas/ReporteProcesar"
import ReporteAvanzado from "../pages/Reportes/ReporteAvanzado"
import ExcelExport from "../pages/Consultas/ExcelExport"
//Incidencias
import ListaAuditoria from "../pages/Menus/Casos/ListaAuditoria"

//Roles
import MantenimientoRoles from "../pages/Menus/Roles/Roles"

// Menu
//Personalizar
import Roles from "../pages/Menus/Personalizar/Roles"
import MiCuenta from "../pages/Menus/Personalizar/MiCuenta"
import ReportesProcesados from "pages/Menus/Reportes/ReportesProcesados"
import Cuenta from "pages/Consultas/Cuenta"
import ErrorPath from "pages/Menus/404"
import ServiceDisabled from "pages/Menus/serviceDisable"
import AccessDenied from "pages/Menus/accessDenied"


const ModuloRouter = [
  { path: "/Incidencias", component: ListaAuditoria },
  { path: "/Dashboard-casos/:token", component: DashboardCasos },
  { path: "/Dashboard-casos", component: DashboardCasos },
  { path: "/Dashboard-cliente", component: DashboardCliente },
  { path: "/Dashboard-abogado", component: DashboardAbogado },
  { path: "/Dashboard-resumen", component: DashboardSuscriptor },
  { path: "/calendar", component: Calendario },
  { path: "/Tareas/:token", component: Tareas },
  { path: "/Tareas", component: Tareas },
  { path: "/Evento/:token", component: Evento },
  { path: "/Evento/", component: Evento },
  { path: "/Cliente", component: Cliente },
  { path: "/Usuario", component: Usuario },
  { path: "/Tarifa", component: Tarifa },
  { path: "/Maestros", component: Maestros },
  { path: "/Notificaciones", component: Notificaciones },
  { path: "/Correo", component: Correo },
  { path: "/Ingresos", component: Ingresos },
  { path: "/Egresos", component: Egresos },
  { path: "/Casos", component: Casos },
  { path: "/HorasLaboradas", component: HorasLaboradas },
  { path: "/NuevoAmbiente", component: NuevoAmbiente },
  { path: "/Suscriptores", component: Suscriptores },
  { path: "/AbogadoVirtual", component: AbogadoVirtual },
  { path: "/ReporteEstadoCuenta", component: ReporteEstadoCuenta },
  { path: "/ListadoReporteLiquidacion", component: ReportesProcesados },
  { path: "/ReporteEstadoCuenta2", component: ReporteEstadoCuenta2 },
  { path: "/ReporteProcesar", component: ReporteProcesar },
  { path: "/ReporteAvanzado", component: ReporteAvanzado },
  { path: "/ExcelExport", component: ExcelExport },
  { path: "/Roles", component: Roles },
  { path: "/MantenimientoRoles", component: MantenimientoRoles },
  { path: "/MiCuenta", component: MiCuenta },
  { path: "/HistorialNovedades", component: HistorialNovedades },
  { path: "/Documentos", component: NuevoModulo },
  { path: "/Cuenta", component: Cuenta },

  { path: "/CasosDesactivados", component: CasosDesactivados },
  { path: "/Casos2", component: Casos2 },
  { path: "/Flujo", component: Flujos },
  { path: "/FlujoNuevo", component: FlujosNuevo },
  { path: "/FlujosDetalle", component: FlujosDetalle },
  { path: "/FlujosTarea", component: FlujosTarea },
  { path: "/FlujosNuevaTarea", component: FlujosNuevaTarea },
  { path: "/FlujosRedactarDemanda", component: FlujosRedactarDemanda },
  { path: "/FlujoSeguimiento", component: FlujosNuevaTareacopy },
  { path: "/", component: () => <Redirect to="/IniciarSesion" /> },
  { path: "*", component: () => <Redirect to="/Error404" /> },
]
const LoginRouter = [
  { path: "/IniciarSesion", component: IniciarSesion },
  { path: "/RegistrarSuscriptor", component: RegistrarSuscriptor },
  { path: "/VerificarCorreo/:datos", component: VerificarCorreo },
  { path: "/RecuperarContraseña", component: RecuperarContraseña },
  { path: "/CambiarContraseña/:idCliente", component: CambiarContraseña },
  { path: "/CrearCuenta/:datos", component: VerificarSuscriptor },
  { path: "/Subirdocumento/:body", component: UploadDocument },
  { path: "/Error404", component: ErrorPath },
  { path: "/AccessDenied", component: AccessDenied },
  { path: "/ServiceDisabled", component: ServiceDisabled },
]
export { ModuloRouter, LoginRouter }
