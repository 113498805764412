import React, { Component, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import MetaTags from 'react-meta-tags';
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap"
// import images
import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import CarouselPage from "./CarouselPage"
import instance from '../../config'
const baseUrl = process.env.REACT_APP_API_URL
import Swal from "sweetalert2"
const RegistrarSuscriptor = props => {
    const [dataCategoria, setDataCategoria] = useState([])
    const [getSuscriptores, setSus] = useState({
        nSus_Id: "",
        cSus_Categoria: "ZD04",
        cSus_Nombres: "",
        dSus_FechaVencimiento: "",
        dSus_FechaVencimiento2: "",
        dSus_FechaCreacion: "",
        dSus_FechaCreacion2: "",
        dSus_UltimoPago: "",
        dSus_UltimoPago2: "",
        cSus_Correo: "",
        cSus_Pass: " ",
        cSus_Telefono: "",
        cSus_Empresa: "",
        cSus_CadenaConexion: "",
        cSus_Cuenta: "",
        bSus_Estado: 1,
    })
    const handleChange = e => {
        const { name, value } = e.target
        setSus(prevState => ({
            ...prevState,
            [name]: value,
        }))

    }
    const FuncionListarCategoria = async () => {
        var postData = {
            Prefijo: "ZD",
        }
        await instance
            .post("/Maestros/Data", postData)
            .then(response => {
                setDataCategoria(response.data)

            })
            .catch(error => {

            })
    }
    const CrearWorkSpace = async () => {
        await instance
            .post("/Correlativo/DB")
            .then(response => {

                ExportWorkSpace(response.data[0].Correlativo)
            })
            .catch(error => {

            })
    }
    const ExportWorkSpace = async (_Correlativo) => {
        FuncionInsertarSuscriptores(_Correlativo)
    }
    const ImportWorkSpace = async (_database) => {
        var postData = {
            database: _database,
        }
        await instance
            .post("http://localhost:9001/Import", postData)
            .then(response => {

                Swal.fire({
                    title: "Mensaje",
                    text: "Se ha registrado correctamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })
            })
            .catch(error => {

            })
    }
    const FuncionInsertarSuscriptores = async (_cSus_CadenaConexion) => {
        var postData = {
            cSus_Categoria: getSuscriptores.cSus_Categoria,
            cSus_Nombres: getSuscriptores.cSus_Nombres,
            cSus_Correo: getSuscriptores.cSus_Correo,
            cSus_Pass: getSuscriptores.cSus_Pass,
            cSus_Telefono: document.getElementById("cSus_Telefono").value,
            cSus_Empresa: getSuscriptores.cSus_Empresa,
            cSus_CadenaConexion: _cSus_CadenaConexion,
            cSus_Cuenta: 'http://' + document.getElementById("cSus_Cuenta").value.toLowerCase() + '.gpslegal.pe',
            bSus_Estado: Number.parseInt(1),
        }
        await instance
            .post("/Suscriptores/Registrar", postData)
            .then(response => {
                FuncionEnviarCorreo(_cSus_CadenaConexion)
                Swal.fire({
                    title: "Mensaje",
                    text: 'Se le envío un correo de confirmación',
                    icon: "success",
                    confirmButtonText: "Aceptar",
                }).then((result) => {
                    if (result.isConfirmed) {
                        setTimeout(() => {
                            location.replace("https://gpslegal.pe/")
                        }, 3000);
                    }
                })
            })
            .catch(error => {

            })
    }
    const FuncionEnviarCorreo = async (_cSus_CadenaConexion) => {
        var postData = {
            email: getSuscriptores.cSus_Correo,
            nombre: getSuscriptores.cSus_Nombres,
            empresa: getSuscriptores.cSus_Empresa,
            cuenta: document.getElementById("cSus_Cuenta").value.toLowerCase(),
            telefono: document.getElementById("cSus_Telefono").value,
            db: _cSus_CadenaConexion
        }
        await instance
            .post("/sendMail/Subscriptor", postData)
            .then(response => {

            })
            .catch(error => {

            })
    }
    const validarTextoEntrada = async (input, patron) => {
        var texto = input.value
        var letras = texto.split("")
        for (var x in letras) {
            var letra = letras[x]
            if (!(new RegExp(patron, "i")).test(letra)) {
                letras[x] = ""
            }
        }
        input.value = letras.join("")
    }
    const validarTexto = async () => {
        var cSus_Cuenta = document.getElementById("cSus_Cuenta")
        cSus_Cuenta.addEventListener("input", function (event) {
            validarTextoEntrada(this, "[a-z0-9]")
            cSus_Cuenta.addEventListener('input', function () {
                if (this.value.length > 30)
                    this.value = this.value.slice(0, 30);
            })
        })
    }
    const validarNumero = async () => {
        var cSus_Telefono = document.getElementById("cSus_Telefono")
        cSus_Telefono.addEventListener("input", function (event) {
            validarTextoEntrada(this, "[0-9]")
            cSus_Telefono.addEventListener('input', function () {
                if (this.value.length > 9)
                    this.value = this.value.slice(0, 9);
            })
        })
    }
    useEffect(() => {
        FuncionListarCategoria()
        validarNumero()
        validarTexto()
    }, [])
    return (
        <React.Fragment>
            <div>
                <MetaTags>
                    <title>Registrar| Gps Legal</title>
                </MetaTags>
                <Container fluid className="p-0">
                    <Row className="g-0">
                        <CarouselPage />
                        <Col xl={3} style={{ background: 'white', zIndex: 9999 }}>
                            <div className="p-md-5 p-4">
                                <div className="w-100">
                                    <div className="d-flex flex-column h-100">
                                        <div className="my-auto">
                                            <div>
                                                <h5 className="text-primary">Regístrate.</h5>
                                                <p className="text-muted">
                                                    Información.
                                                </p>
                                            </div>
                                            <div className="mt-4">
                                                <Form>
                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup className="mb-2">
                                                                <Label htmlFor="cardnumberInput">
                                                                    Nombre completo
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="cSus_Nombres"
                                                                    placeholder="Ingrese Nombre completo"
                                                                    name="cSus_Nombres" onChange={handleChange}
                                                                    value={getSuscriptores && getSuscriptores.cSus_Nombres}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="12">
                                                            <FormGroup className="mb-2">
                                                                <Label htmlFor="cardnumberInput">
                                                                    Correo electrónico
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="cSus_Correo"
                                                                    placeholder="Ingrese Correo electrónico"
                                                                    name="cSus_Correo" onChange={handleChange}
                                                                    value={getSuscriptores && getSuscriptores.cSus_Correo}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="cardnumberInput">
                                                                    Empresa
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="cSus_Empresa"
                                                                    placeholder="Ingrese Nombre de la Empresa"
                                                                    name="cSus_Empresa" onChange={handleChange}
                                                                    value={getSuscriptores && getSuscriptores.cSus_Empresa}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="12">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="cardnumberInput">
                                                                    Cuenta
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="cSus_Cuenta"
                                                                    placeholder="Ingrese Nombre de la cuenta"
                                                                    name="cSus_Cuenta" onChange={handleChange}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="12">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="cardnumberInput">
                                                                    Teléfono
                                                                </Label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="cSus_Telefono"
                                                                    placeholder="Ingrese Teléfono"
                                                                    name="cSus_Telefono" onChange={handleChange}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="12">
                                                            <FormGroup className="mb-3">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-success waves-effect waves-light"
                                                                    onClick={() => CrearWorkSpace()}
                                                                >
                                                                    Registrarse
                                                                </button>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                                <div className="mt-2 text-center">
                                                    <p>
                                                        ¿ Ya tienes una cuenta ?{" "}
                                                        <Link
                                                            to="IniciarSesion"
                                                            className="fw-medium text-primary"
                                                        >
                                                            {" "}
                                                            Login
                                                        </Link>{" "}
                                                    </p>
                                                    <p>
                                                        <Link
                                                            to="http://gpslegal.pe/"
                                                            className="fw-medium text-primary"
                                                        >
                                                            {" "}
                                                            Contáctenos
                                                        </Link>{" "}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default RegistrarSuscriptor
