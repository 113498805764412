import React, { useRef, Component, useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import moment from "moment"
import {
  CardBody,
  Card,
  Col,
  Container,
  CardTitle,
  Button,
  Row,
  Modal,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Spinner
} from "reactstrap"
import { Link } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import instance from '../../config'
import Select from "react-select"
import Swal from "sweetalert2"
import logo from "../../assets/images/logo-dark.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getDecryptedItem, setEncryptedItem } from '../../util';
import {
  faTimes,
  faChevronLeft,
  faChevronRight,
  faPaperPlane,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons"
import ModalCliente from "pages/Componentes/ModalCliente"
var meses = new Array("Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre");
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var mesIni = Fecha.getMonth() - 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
if (mesIni < 10) mesIni = "0" + mesIni //agrega cero si el menor de 10
const baseUrl = process.env.REACT_APP_API_URL
const Caso = props => {
  var cantidadCasos = 0;
  const [modalInsertar, setModalInsertar] = useState(false)
  const [modalActualizar, setModalActualizar] = useState(false)
  const [modalFiltro, setModalFiltro] = useState(false)
  const [modalVincularCEJ, setModalVincularCEJ] = useState(false)
  const [modalVincularIndecopi, setModalVincularIndecopi] = useState(false)
  const [modalInsertarCliente, setModalInsertarCliente] = useState(false)
  const [Tab, setTab] = useState()
  const [Active1, setActive1] = useState()
  const [Active2, setActive2] = useState()
  const [Active3, setActive3] = useState()
  const [ActiveN1, setActiveN1] = useState()
  const [ActiveN2, setActiveN2] = useState()
  const [ActiveN3, setActiveN3] = useState()
  const [HiddenFinalizar, setHiddenFinalizar] = useState()
  const [HiddenSiguiente3, setHiddenSiguiente3] = useState()
  const [HiddenSiguiente2, setHiddenSiguiente2] = useState()
  const [HiddenAnterior2, setHiddenAnterior2] = useState()
  const [HiddenAnterior1, setHiddenAnterior1] = useState()
  const [HiddenEquipo, setHiddenEquipo] = useState()
  const [HiddenEquipoCbo, setHiddenEquipoCbo] = useState()
  const [HiddenCEJ, setHiddenCEJ] = useState('')
  const [HiddenIndecopi, setHiddenIndecopi] = useState('none')
  const [cheked, setcheked] = useState(false)
  const [cheked2, setcheked2] = useState(false)
  const [data, setData] = useState([])
  const [dataEquipo, setDataEquipo] = useState([])
  const [dataCliente, setDataCliente] = useState([])
  const [dataComboJurisdiccion, setDataComboJurisdiccion] = useState([])
  const [GetParticipantesA, setParticipantesA] = useState([])
  const [GetParticipantesAR, setParticipantesAR] = useState([])
  const [GetParticipantesP, setParticipantesP] = useState([])
  const [GetParticipantesUAD, setParticipantesUAD] = useState([])
  const [dataComboArea, setDataComboArea] = useState([])
  const [dataComboTIPOEXPEDIENTE, setDataComboTIPOEXPEDIENTE] = useState([])
  const [dataComboLUGARDETRAMITE, setDataComboLUGARDETRAMITE] = useState([])
  const [dataComboJugados, setDataComboJugados] = useState([])
  const [dataComboMateria, setDataComboMateria] = useState([])
  const [dataComboSubMateria, setDataComboSubMateria] = useState([])
  const [dataComboEstadoExterno, setDataComboEstadoExterno] = useState([])
  const [dataComboEstadoInterno, setDataComboEstadoInterno] = useState([])
  const [dataComboMoneda, setDataComboMoneda] = useState([])
  const [dataComboUsuario, setDataComboUsuario] = useState([])
  const [getdataCuadernos, setDataCuadernos] = useState([])
  const [getdataGeneral, setDataGeneral] = useState([])
  const [getdataMovimientos, setDataMovimientos] = useState([])
  const [getdataCuadernosInsertar, setDataCuadernosInsertar] = useState([])
  const [getdataGeneralInsertar, setDataGeneralInsertar] = useState([])
  const [getdataMovimientosInsertar, setDataMovimientosInsertar] = useState([])
  const [Loading, setLoading] = useState(true)

  const [disabled, setDisabled] = useState(true)
  const [getSeleccionarCuadernos, setSeleccionarCuadernos] = useState([])
  const [chekedValor1, setchekedValor1] = useState(false)
  const [chekedValor2, setchekedValor2] = useState(false)
  const [chekedValor3, setchekedValor3] = useState(false)
  const [chekedValor4, setchekedValor4] = useState(false)
  const [chekedValor5, setchekedValor5] = useState(false)
  const [Valor1, setValor1] = useState(true)
  const [Valor2, setValor2] = useState(true)
  const [Valor3, setValor3] = useState(true)
  const [Valor4, setValor4] = useState(true)
  const [Valor5, setValor5] = useState(true)

  const [getFiltro, setFiltro] = useState({
    nCli_Id: null,
    cCas_Tab_Materia: null,
    cCas_Tab_SubMateria: null,
    bCas_Estado: null,
    nUsu_ID: null,
    FechaInicio: ano + "-" + "01" + "-" + "01",
    FechaFin: ano + "-" + mes + "-" + dia,
  })
  const handleChangeFiltro = e => {
    if (chekedValor1 == true || chekedValor2 == true || chekedValor3 == true || chekedValor4 == true || chekedValor5 == true) {


      setFiltro({
        ...getFiltro,
        [e.target.name]: e.target.value
      })

    } else {


      setFiltro({
        ...getFiltro,
        [e.target.name]: null
      })

    }
  }
  const handleChangeValor1 = e => {
    setchekedValor1(!chekedValor1)
    if (chekedValor1 == true) {
      setFiltro({
        ...getFiltro,
        nCli_Id: null
      })
      setValor1(true)
    } else {
      var Valor = getFiltro.nCli_Id

      setFiltro({
        ...getFiltro,
        nCli_Id: Valor
      })
      setValor1(false)
    }
  }
  const handleChangeValor2 = e => {
    setchekedValor2(!chekedValor2)
    if (chekedValor2 == true) {
      setFiltro({
        ...getFiltro,
        cCas_Tab_Materia: null
      })
      setValor2(true)
    } else {
      var Valor = getFiltro.cCas_Tab_Materia

      setFiltro({
        ...getFiltro,
        cCas_Tab_Materia: Valor
      })
      setValor2(false)
    }
  }
  const handleChangeValor3 = e => {
    setchekedValor3(!chekedValor3)
    if (chekedValor3 == true) {
      setFiltro({
        ...getFiltro,
        cCas_Tab_SubMateria: null
      })
      setValor3(true)
    } else {
      var Valor = getFiltro.cCas_Tab_SubMateria

      setFiltro({
        ...getFiltro,
        cCas_Tab_SubMateria: Valor
      })
      setValor3(false)
    }
  }
  const handleChangeValor4 = e => {
    setchekedValor4(!chekedValor4)
    if (chekedValor4 == true) {
      setFiltro({
        ...getFiltro,
        bCas_Estado: null
      })
      setValor4(true)
    } else {
      var Valor = getFiltro.bCas_Estado

      setFiltro({
        ...getFiltro,
        bCas_Estado: Valor
      })
      setValor4(false)
    }
  }
  const handleChangeValor5 = e => {
    setchekedValor5(!chekedValor5)
    if (chekedValor5 == true) {
      setFiltro({
        ...getFiltro,
        nUsu_ID: null
      })
      setValor5(true)
    } else {
      var Valor = getFiltro.nUsu_ID

      setFiltro({
        ...getFiltro,
        nUsu_ID: Valor
      })
      setValor5(false)
    }
  }
  const handleChangeParticipantesA = value => {
    if (value == null) {
    } else {
      setParticipantesA(value);
    }
  };
  const handleChangeParticipantesAR = value => {
    if (value == null) {
    } else {
      setParticipantesAR(value);
    }
  };
  const handleChangeParticipantesP = value => {
    if (value == null) {
    } else {
      setParticipantesP(value);
    }
  };
  const handleChangeParticipantesUAD = value => {
    if (value == null) {
    } else {
      setParticipantesUAD(value);
    }
  };

  //const [Exp, setExp]  = useState("");
  const [getCasos, setCasos] = useState({
    nCas_Id: "",
    nCli_Id: "",
    nEqu_ID: 1,
    cCas_Tab_Tipo: "CA01",
    cCas_Titulo: "",
    cCas_Cod_Externo: "",
    cCas_Cod_Interno: "2021-0008",
    cCas_Recurso_Num: "0203",
    cCas_Detalle: "",
    cCas_Expediente: "",
    nCas_Exp_Year: ano,
    cCas_Tab_Materia: "NN00",
    cCas_Tab_SubMateria: "NN00",
    nJuz_Id: "NN00",
    cCas_Tab_Distrito_Jud: "NN00",

    cCas_Tab_Jurisdiccion: "JU01",//Falta
    cCas_Tab_Sala: "SA01",//Falta
    cCas_Tab_Recurso_Tipo: "HA01",//Falta
    cCas_Tab_Comision: "CP01", //Falta
    cTab_Lugar_Tramite: "WE01",   //Falta
    cTab_Tipo_Acto: "0000", //Falta
    nPer_Id: 1,
    cCas_Referencia: "",
    cCas_Denominacion: "",
    cUbi_Org: "",
    dCas_Fec_Ini_Interno: ano + "-" + mes + "-" + dia,
    dCas_Fec_Ini_Procesal: ano + "-" + mes + "-" + dia,
    cCas_Tab_Estado_Externo: "",
    dCas_Fec_Estima_Cierre: moment(Fecha).format('yyyy-MM-DD'),
    cTab_Estado_Interno: "",
    dFas_Fec_Cierre: "",
    nUsu_ID: getDecryptedItem("ID"),
    dUsu_Fecha: ano + "-" + mes + "-" + dia,
    bCas_Estado: 1,
    cTab_Moneda: "",
    nCas_Monto: "",
    cTab_Tipo_Monto: "",
    nCas_Origen: 0,
    TEXPEDIENTE: 0,
    LTRAMITE: 0,
    nUsu_ID2: "",
  })
  const [getCasosEquipo, setCasosEquipo] = useState({
    nCas_Id: "",
    nUsu_Id: "",
    cCas_Tab_TipoEquipo: "",
  })
  const [getCodigoCaptcha, setCodigoCaptcha] = useState({
    cCodigoCaptcha: "",
  })

  const FuncionListarCliente = async () => {
    let RolId = getDecryptedItem("RolID")

    var postData = {
      bCli_Tipo: null,
      nUsu_ID: RolId == 6 || RolId == 1 ? null : getDecryptedItem("ID")
    }
    await instance
      .post("/Cliente/ListarCbo", postData)
      .then(response => {
        setDataCliente(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarComboJurisdiccion = async () => {
    var postData = {
      Prefijo: "JU",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataComboJurisdiccion(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarComboAREA = async () => {
    var postData = {
      Prefijo: "CC",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataComboArea(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarComboTIPOEXPEDIENTE = async () => {
    var postData = {
      Prefijo: "CX",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataComboTIPOEXPEDIENTE(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarComboLUGARDETRAMITE = async () => {
    var postData = {
      Prefijo: "SI",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataComboLUGARDETRAMITE(response.data)
      })
      .catch(error => {

      })
  }

  const FuncionListarComboJugados = async () => {
    var postData = {
      Prefijo: "GA",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataComboJugados(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarComboMateria = async () => {
    var postData = {
      Prefijo: "MA",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataComboMateria(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarComboSubMateria = async () => {
    var postData = {
      Prefijo: "MC",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataComboSubMateria(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarComboEstadoExterno = async () => {
    var postData = {
      Prefijo: "EG",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataComboEstadoExterno(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarComboEstadoInterno = async () => {
    var postData = {
      Prefijo: "EP",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataComboEstadoInterno(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarComboUsuario = async () => {
    await instance
      .get("/Usuario/Listar")
      .then(response => {
        setDataComboUsuario(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarComboMoneda = async () => {
    var postData = {
      Prefijo: "MO",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataComboMoneda(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionLimpiar = async () => {
    setCasos(prevState => ({
      ...prevState,
      nCas_Id: "",
      nCli_Id: "",
      nEqu_ID: 1,
      cCas_Tab_Tipo: "CA01",
      cCas_Titulo: "",
      cCas_Cod_Externo: "",
      cCas_Cod_Interno: "2021-0008",
      cCas_Tab_Materia: "",
      cCas_Tab_SubMateria: "",
      cCas_Detalle: "",
      cCas_Tab_Jurisdiccion: "JU01",
      cCas_Tab_Sala: "SA01",
      cCas_Tab_Recurso_Tipo: "HA01",
      cCas_Recurso_Num: "0203",
      cCas_Tab_Distrito_Jud: "",
      cCas_Tab_Comision: "CP01",
      cTab_Lugar_Tramite: "WE01",
      nPer_Id: 1,
      cCas_Referencia: "",
      cCas_Denominacion: "",
      cUbi_Org: "",
      dCas_Fec_Ini_Interno: ano + "-" + mes + "-" + dia,
      dCas_Fec_Ini_Procesal: ano + "-" + mes + "-" + dia,
      cCas_Tab_Estado_Externo: "",
      dCas_Fec_Estima_Cierre: moment(Fecha).format('yyyy-MM-DD'),
      cTab_Estado_Interno: "",
      dFas_Fec_Cierre: "",
      nUsu_ID: 1,
      dUsu_Fecha: ano + "-" + mes + "-" + dia,
      bCas_Estado: 1,
      cTab_Moneda: "",
      nCas_Monto: "",
      cTab_Tipo_Monto: "",
      nCas_Origen: 0,
    }))
  }
  const FuncionSincronizarCEJ = async (_cCas_Cod_Externo) => {
    setLoading(false)
    setDisabled(true)
    var postData = {
      CodigoExterno: _cCas_Cod_Externo,
      cFecha: Fecha.getDate() + " de " + meses[Fecha.getMonth()] + " de " + Fecha.getFullYear(),
    }
    await instance
      .post("/Vincular/CEJ", postData)
      .then(response => {
        FuncionLimpiar()
        try {
          var elem = document.getElementsByName("cCas_Tab_Materia")[0];
          elem.removeAttribute("disabled");
          var elem2 = document.getElementsByName("cCas_Tab_SubMateria")[0];
          elem2.removeAttribute("disabled");
        } catch (error) {
        }
        setCasos(prevState => ({
          ...prevState,
          nCas_Exp_Year: _cCas_Cod_Externo.split("-")[1],
        }))
        Swal.fire({
          title: "Mensaje",
          text: "Vinculado exitosamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        })
        setLoading(true)
        setDataCuadernos(response.data.DataCuadernos)
        setDataGeneral(response.data.DataGneral)
        setDataMovimientos(response.data.DataMovimienntos)
        return response.data;
      })
      .catch(error => {

        Swal.fire({
          title: "Mensaje",
          text: error,
          icon: "error",
          confirmButtonText: "Aceptar",
        })
        document.getElementById("preloader").style.cssText = "display: none";
        document.getElementById("status").style.cssText = "display: none";
      })
  }
  const FuncionActivarCej = async (e) => {
    var Arreglo = []
    var ArregloC = []
    var ArregloG = []
    var ArregloM = []

    if (getSeleccionarCuadernos.includes(e.target.value)) {
      Arreglo = getSeleccionarCuadernos.filter(elemento => elemento !== e.target.value)
    } else {
      Arreglo = getSeleccionarCuadernos.concat(e.target.value)
    }
    setSeleccionarCuadernos(Arreglo)

    for (var i = 0; i < Arreglo.length; i++) {
      if (i == 0) {
        var Array = [getdataGeneral.find(arreglo => arreglo.CodigoExterno === Arreglo[i])]
        for (var g = 0; g < Array.length; g++) {
          var CodigoExterno = Array[g].CodigoExterno
          var Sumilla = Array[g].General[0].Sumilla
          var cTabCodigo = dataComboEstadoExterno.find(p => p.cTabNombre === Array[g].General[0].Estado).cTabCodigo
          var cTabCodigo2 = dataComboMateria.find(p => p.cTabNombre === Array[g].General[0].Especialidad).cTabCodigo
          var cTabCodigo3 = dataComboSubMateria.find(p => p.cTabNombre === Array[g].General[0].Materia).cTabCodigo
          var division = Array[g].General[0].FechadeInicio.split("/");
          setCasos(prevState => ({
            ...prevState,
            cCas_Cod_Externo: CodigoExterno,
          }))
          setCasos(prevState => ({
            ...prevState,
            cCas_Titulo: Sumilla,
          }))
          setCasos(prevState => ({
            ...prevState,
            cCas_Tab_Estado_Externo: cTabCodigo,
          }))
          setCasos(prevState => ({
            ...prevState,
            cCas_Tab_Materia: cTabCodigo2,
          }))
          setCasos(prevState => ({
            ...prevState,
            cCas_Tab_SubMateria: cTabCodigo3,
          }))
          setCasos(prevState => ({
            ...prevState,
            dCas_Fec_Ini_Procesal: division[2] + "-" + division[1] + "-" + division[0],
          }))
        }
      }
      ArregloC.push(getdataCuadernos.find(arreglo => arreglo.CodigoExterno === Arreglo[i]))
      ArregloG.push(getdataGeneral.find(arreglo => arreglo.CodigoExterno === Arreglo[i]))
      const Test = getdataMovimientos.map((row, j) => {
        if (row.CodigoExterno == Arreglo[i]) {
          ArregloM.push(row)
        }
      })

    }

    setDataCuadernosInsertar(ArregloC)
    setDataGeneralInsertar(ArregloG)
    setDataMovimientosInsertar(ArregloM)
    if (Arreglo.length > 0) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }
  const [checkedMC, setcheckedMC] = useState(false)
  const FuncionSeleccionarTCuadernos = async () => {
    setcheckedMC(!checkedMC)
    var i
    if (checkedMC == true) {
      for (i = 0; i < document.f2.elements.length; i++)
        if (document.f2.elements[i].type == "checkbox")
          document.f2.elements[i].checked = 0
    } else {
      for (i = 0; i < document.f2.elements.length; i++)
        if (document.f2.elements[i].type == "checkbox")
          document.f2.elements[i].checked = 1
    }
  }
  const FuncionRefrescarCEJ = async (_Id, _cCas_Cod_Externo) => {
    Swal.fire({
      html:
        '<img id="imgr1" className="imgr" src="/static/media/logo-dark.7a9779c2.png" style="width:50px;height:auto;-webkit-animation: 3s rotate linear infinite;' +
        'animation: 3s rotate linear infinite;' +
        '-webkit-transform-origin: 50% 50%;' +
        'transform-origin: 50% 50%;" /> ' +
        '<br/><br/> Cargando...',
      showConfirmButton: false,
      allowOutsideClick: false
    });
    var postData = {
      CodigoExterno: _cCas_Cod_Externo,
      cFecha: Fecha.getDate() + " de " + meses[Fecha.getMonth()] + " de " + Fecha.getFullYear(),
    }
    await instance
      .post("/Vincular/CEJ/Update", postData)
      .then(response => {
        Swal.close();
        Swal.fire({
          title: "Mensaje",
          text: "Vinculado exitosamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        })
        FuncionInsertarScraping(_Id, response.data.DataCuadernos, response.data.DataGneral, response.data.DataMovimienntos)
      })
      .catch(error => {

        Swal.close();
        Swal.fire({
          title: "Mensaje",
          text: error,
          icon: "success",
          confirmButtonText: "Aceptar",
        })
      })
  }
  const FuncionInsertarScraping = async (_nCas_Id, _cCav_Cuadernos, _cCav_General, _cCav_Movimientos) => {
    var postData = {
      nCas_Id: _nCas_Id,
      cCav_Cuadernos: _cCav_Cuadernos,
      cCav_General: _cCav_General,
      cCav_Movimientos: _cCav_Movimientos,
      cCav_Archivo: "",
      cCav_Notas: "",
    }

    await instance
      .post("/Scraping/Insertar", postData)
      .then(response => {
      })
      .catch(error => {

      })
  }

  const abrirCerrarModal = (Modal, Row) => {
    if (Modal == 1) {
      if (!modalInsertar == false) {
        Swal.fire({
          html: "¿Seguro que desea salir sin guardar los datos?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        }).then(result => {
          if (result.isConfirmed) {
            setModalInsertar(!modalInsertar)
            setDataCuadernos([])
          }
        })
      } else {
        FuncionLimpiar()
        setModalInsertar(!modalInsertar)
      }
      setTab("1")
      setActive1("active")
      setActive2("")
      setActive3("")
      setHiddenFinalizar("none")
      setHiddenAnterior2("none")
      setHiddenAnterior1("none")
      setHiddenSiguiente3("none")
      setHiddenSiguiente2("")
      setSeleccionarCuadernos([])
    } else if (Modal == 2) {
      setTab("2")
      setActive1("")
      setActive2("active")
      setActive3("")
      setHiddenFinalizar("none")
      setHiddenAnterior2("none")
      setHiddenAnterior1("none")
      setHiddenSiguiente3("")
      setHiddenSiguiente2("none")
      setActiveN2("badge bg-danger rounded-pill")
      if (!modalActualizar == false) {
        setModalActualizar(!modalActualizar)
      } else {
        Swal.fire({
          html:
            '<img id="imgr1" className="imgr" src="/static/media/logo-dark.7a9779c2.png" style="width:50px;height:auto;-webkit-animation: 3s rotate linear infinite;' +
            'animation: 3s rotate linear infinite;' +
            '-webkit-transform-origin: 50% 50%;' +
            'transform-origin: 50% 50%;" /> ' +
            '<br/><br/> Cargando...',
          showConfirmButton: false,
          allowOutsideClick: false
        });
        setCasos(Row)
        FuncionListarEquipoAbogado(Row.nCas_Id)
        FuncionListarEquipoAbogadoResponsable(Row.nCas_Id)
        FuncionListarEquipoPracticante(Row.nCas_Id)
        FuncionListarEquipoUsuarioAdd(Row.nCas_Id)
        setTimeout(() => {
          setModalActualizar(!modalActualizar)
          Swal.close();
        }, 2000);
      }
    } else if (Modal == 4) {
      setModalFiltro(!modalFiltro)
    } else if (Modal == 5) {
      setModalInsertarCliente(!modalInsertarCliente)
      FuncionListarCliente()
    } else if (Modal == 6) {
      setModalVincularIndecopi(!modalVincularIndecopi)
    }
  }
  const FuncionListarCasos = async () => {
    var postData = {
      nUsu_ID: getDecryptedItem("ID"),
    }

    await instance
      .post("/Casos/Listar", postData)
      .then(response => {
        setData(response.data)


        /* const Test = response.data.map((row, i) => {
           (function (index) {
             if (row.cCas_Titulo == null) {
               
               //setTimeout(function () { FuncionSincronizarCEJUpdate(row.cCas_Cod_Externo); }, i * 20000);
             }
             //  setTimeout(function() { FuncionSincronizarCEJUpdate(row.cCas_Cod_Externo); }, i * 40000);
           })(i);
         })*/
      })
      .catch(error => {

      })
  }
  const FuncionFiltroCasos = async () => {
    var postData = {
      nCli_Id: getFiltro.nCli_Id,
      cCas_Tab_Materia: getFiltro.cCas_Tab_Materia,
      cCas_Tab_SubMateria: getFiltro.cCas_Tab_SubMateria,
      bCas_Estado: getFiltro.bCas_Estado,
      nUsu_ID: getFiltro.nUsu_ID,
      FechaInicial: getFiltro.FechaInicio,
      FechaFin: getFiltro.FechaFin,
    }

    await instance
      .post("/Casos/Filtro", postData)
      .then(response => {
        setData(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarEquipo = async () => {
    await instance
      .get("/Equipo/Listar")
      .then(response => {
        setDataEquipo(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarEquipoAbogadoResponsable = async (_nCas_Id) => {
    var postData = {
      nCas_Id: _nCas_Id,
      Tipo: "UC01",
    }
    await instance
      .post("/Casos/Equipo", postData)
      .then(response => {
        setParticipantesAR(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarEquipoAbogado = async (_nCas_Id) => {
    var postData = {
      nCas_Id: _nCas_Id,
      Tipo: "UC02"
    }
    await instance
      .post("/Casos/Equipo", postData)
      .then(response => {
        setParticipantesA(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarEquipoPracticante = async (_nCas_Id) => {
    var postData = {
      nCas_Id: _nCas_Id,
      Tipo: "UC03"
    }
    await instance
      .post("/Casos/Equipo", postData)
      .then(response => {
        setParticipantesP(response.data)
      })
      .catch(error => {

      })
  }
  const FuncionListarEquipoUsuarioAdd = async (_nCas_Id) => {
    var postData = {
      nCas_Id: _nCas_Id,
      Tipo: "UC04"
    }
    await instance
      .post("/Casos/Equipo", postData)
      .then(response => {
        setParticipantesUAD(response.data)
      })
      .catch(error => {

      })
  }

  const FuncionFuncionMasiva = async param1 => {
    var postData = {
      nCas_Id: param1,
    }
    await instance
      .post("/Casos/Masivo", postData)
      .then(response => {
        FuncionListarCasos()

      })
      .catch(error => {

      })
  }
  const FuncionAgregarCaso = async () => {
    const Test = getdataGeneralInsertar.map((row, i) => {
      (function (index) {
        if (i == 0) {
          setTimeout(function () { FuncionRegistrarCaso(getCasos.cCas_Titulo, getCasos.cCas_Cod_Externo); }, i * 100);
        } else {
          setTimeout(function () { FuncionRegistrarCaso(row.General[0].Sumilla, row.CodigoExterno); }, i * 100);
        }
      })(i);
    })
    Swal.fire({
      title: "Mensaje",
      text: "Agregado exitosamente",
      icon: "success",
      confirmButtonText: "Aceptar",
    })
  }
  const FuncionRegistrarCaso = async (_cCas_Titulo, _cCas_Cod_Externo) => {
    var postData = {
      nCli_Id: getCasos.nCli_Id,
      nEqu_ID: getCasos.nEqu_ID,
      cCas_Tab_Tipo: getCasos.cCas_Tab_Tipo,
      cCas_Titulo: _cCas_Titulo,
      cCas_Cod_Externo: _cCas_Cod_Externo,
      cCas_Cod_Interno: getCasos.cCas_Cod_Interno,
      cCas_Tab_Materia: getCasos.cCas_Tab_Materia,
      cCas_Tab_SubMateria: getCasos.cCas_Tab_SubMateria,
      cCas_Detalle: getCasos.cCas_Detalle,
      cCas_Tab_Jurisdiccion: getCasos.cCas_Tab_Jurisdiccion,
      cCas_Expediente: getCasos.cCas_Expediente,
      nCas_Exp_Year: getCasos.nCas_Exp_Year,
      nJuz_Id: getCasos.nJuz_Id,
      cCas_Tab_Sala: getCasos.cCas_Tab_Sala,
      cCas_Tab_Recurso_Tipo: getCasos.cCas_Tab_Recurso_Tipo,
      cCas_Recurso_Num: getCasos.cCas_Recurso_Num,
      cCas_Tab_Distrito_Jud: getCasos.cCas_Tab_Distrito_Jud,
      cCas_Tab_Comision: getCasos.cCas_Tab_Comision,
      cTab_Lugar_Tramite: getCasos.cTab_Lugar_Tramite,
      nPer_Id: getCasos.nPer_Id,
      cCas_Referencia: getCasos.cCas_Referencia,
      cCas_Denominacion: getCasos.cCas_Denominacion,
      dCas_Fec_Ini_Interno: getCasos.dCas_Fec_Ini_Interno,
      dCas_Fec_Ini_Procesal: getCasos.dCas_Fec_Ini_Procesal,
      cCas_Tab_Estado_Externo: getCasos.cCas_Tab_Estado_Externo,
      dCas_Fec_Estima_Cierre: getCasos.dCas_Fec_Estima_Cierre,
      cTab_Estado_Interno: getCasos.cTab_Estado_Interno,
      dFas_Fec_Cierre: getCasos.dFas_Fec_Cierre,
      nUsu_ID: getCasos.nUsu_ID,
      dUsu_Fecha: getCasos.dUsu_Fecha,
      bCas_Estado: getCasos.bCas_Estado,
      cUbi_Org: getCasos.cUbi_Org,
      cTab_Tipo_Acto: getCasos.cTab_Tipo_Acto,
      cTab_Moneda: getCasos.cTab_Moneda,
      nCas_Monto: getCasos.nCas_Monto,
      cTab_Tipo_Monto: getCasos.cTab_Tipo_Monto,
      cName_Resolucion: 'Auto',
      nCas_Origen: getCasos.nCas_Origen
    }
    try {
      await instance
        .post("/Casos/Insertar", postData)
        .then(response => {

          FuncionListarCasos()
          setModalInsertar(!modalInsertar)
          if (GetParticipantesA.length > 0) {
            const Participantes = GetParticipantesA.map((row, i) => {
              FuncionAgregarCasoEquipo(response.data[0].nCas_Id, row.value, 'UC02')
            })
          }
          if (GetParticipantesAR.length > 0) {
            const Participantes = GetParticipantesAR.map((row, i) => {
              FuncionAgregarCasoEquipo(response.data[0].nCas_Id, row.value, 'UC01')
            })
          }
          if (GetParticipantesUAD.length > 0) {
            const Participantes = GetParticipantesUAD.map((row, i) => {
              FuncionAgregarCasoEquipo(response.data[0].nCas_Id, row.value, 'UC04')
            })
          }
          if (GetParticipantesP.length > 0) {
            const Participantes = GetParticipantesP.map((row, i) => {
              FuncionAgregarCasoEquipo(response.data[0].nCas_Id, row.value, 'UC03')
            })
          }
          FuncionInsertarScraping(response.data[0].nCas_Id, getdataCuadernosInsertar, getdataGeneralInsertar, getdataMovimientosInsertar)
          setDataCuadernos([])
        })
        .catch(error => {

        })
    } catch (error) {
    }
  }
  const FuncionActualizarCaso = async (postData) => {
    FuncionEliminarCasoEquipo(getCasos.nCas_Id)
    await instance
      .post("/Casos/Actualizar", postData)
      .then(response => {
        if (GetParticipantesA.length > 0) {
          const Participantes = GetParticipantesA.map((row, i) => {
            FuncionAgregarCasoEquipo(getCasos.nCas_Id, row.value, 'UC02')
          })
        }
        if (GetParticipantesAR.length > 0) {
          const Participantes = GetParticipantesAR.map((row, i) => {
            FuncionAgregarCasoEquipo(getCasos.nCas_Id, row.value, 'UC01')
          })
        }
        if (GetParticipantesUAD.length > 0) {
          const Participantes = GetParticipantesUAD.map((row, i) => {
            FuncionAgregarCasoEquipo(getCasos.nCas_Id, row.value, 'UC04')
          })
        }
        if (GetParticipantesP.length > 0) {
          const Participantes = GetParticipantesP.map((row, i) => {
            FuncionAgregarCasoEquipo(getCasos.nCas_Id, row.value, 'UC03')
          })
        }
        setModalActualizar(!modalActualizar)
        FuncionListarCasos()
        Swal.fire({
          title: "Mensaje",
          text: "Actualizado exitosamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        })
      })
      .catch(error => {

      })
  }
  const FuncionEliminarCaso = async () => {
    var postData = {
      nCas_Id: getCasos.nCas_Id,
    }
    await instance
      .post("/Casos/Eliminar", postData)
      .then(response => {
        FuncionListarCasos()

        Swal.fire({
          title: "Mensaje",
          text: "Eliminado exitosamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        })
      })
      .catch(error => {

      })
  }
  const FuncionAgregarCasoEquipo = async (_nCas_Id, _nUsu_Id, _cCas_Tab_TipoEquipo) => {
    var postData = {
      nCas_Id: _nCas_Id,
      nUsu_Id: _nUsu_Id,
      cCas_Tab_TipoEquipo: _cCas_Tab_TipoEquipo,
    }
    await instance
      .post("/EqCasos/Insertar", postData)
      .then(response => {

      })
      .catch(error => {

      })
  }
  const FuncionEliminarCasoEquipo = async (_nCas_Id) => {
    var postData = {
      nCas_Id: _nCas_Id,
    }
    await instance
      .post("/EqCasos/Eliminar", postData)
      .then(response => {

      })
      .catch(error => {

      })
  }
  const handleChangeCaso = e => {
    const { name, value } = e.target
    setCasos(prevState => ({
      ...prevState,
      [name]: value,
    }))
    if (name == 'cCas_Tab_Tipo') {
      if (value == 'CA05') {
        setCasos(prevState => ({
          ...prevState,
          nCas_Origen: 1,
        }))
        setHiddenCEJ('none')
        setHiddenIndecopi('')
      } else {
        setCasos(prevState => ({
          ...prevState,
          nCas_Origen: 0,
        }))
        setHiddenCEJ('')
        setHiddenIndecopi('none')
      }
    }

  }

  const handleChangeCheck = e => {
    setcheked(!cheked)
    setcheked2(false)
    if (cheked == false) {
      setHiddenEquipo("none")
      setHiddenEquipoCbo("")
    } else {
      setHiddenEquipo("")
      setHiddenEquipoCbo("none")
      setcheked2(!cheked2)
    }
  }
  const handleChangeCheck2 = e => {
    setcheked2(!cheked2)
    setcheked(false)
    if (cheked2 == false) {
      setHiddenEquipo("")
      setHiddenEquipoCbo("none")
    } else {
      setHiddenEquipo("none")
      setHiddenEquipoCbo("")
      setcheked(!cheked)
    }
  }
  const UsuariosSelect = dataComboUsuario.map((row, i) => ({
    value: row.nUsu_ID,
    label: row.cUsu_Nombres,
    color: "#556ee6",
  }))
  const EventoCabeceraTab = async param1 => {
    setTab(param1)
    if (param1 == "1") {
      setActive1("active")
      setActiveN1("badge bg-danger rounded-pill")
      setActive2("")
      setActiveN2("badge bg-secondary rounded-pill")
      setActive3("")
      setActiveN3("badge bg-secondary rounded-pill")
      setHiddenFinalizar("none")
      setHiddenAnterior2("none")
      setHiddenAnterior1("none")
      setHiddenSiguiente3("none")
      setHiddenSiguiente2("")
    } else if (param1 == "2") {
      setActive1("")
      setActiveN1("badge bg-secondary rounded-pill")
      setActive2("active")
      setActiveN2("badge bg-danger rounded-pill")
      setActive3("")
      setActiveN3("badge bg-secondary rounded-pill")
      setHiddenFinalizar("none")
      setHiddenAnterior2("none")
      setHiddenSiguiente2("none")
      setHiddenAnterior1("")
      setHiddenSiguiente3("")
    } else {
      setActive1("")
      setActiveN1("badge bg-secondary rounded-pill")
      setActive2("")
      setActiveN2("badge bg-secondary rounded-pill")
      setActive3("active")
      setActiveN3("badge bg-danger rounded-pill")
      setHiddenFinalizar("")
      setHiddenAnterior2("")
      setHiddenSiguiente2("none")
      setHiddenAnterior1("none")
      setHiddenSiguiente3("none")
    }
  }
  let ArregloConsultaMasiva = []
  const ConsultasMasivas = async param1 => {
    let locationData = {
      nCas_Id: param1,
    }
    var elem = document.getElementById("id" + param1)
    if (elem.checked) {
      ArregloConsultaMasiva.push(locationData)

    } else {
      var indice = ArregloConsultaMasiva.findIndex(
        nCas_Id => nCas_Id.nCas_Id == param1
      )

      ArregloConsultaMasiva.splice(indice, 1)

    }
  }
  const FuncionActivarMasiva = async () => {
    ArregloConsultaMasiva.map((row, id) => FuncionFuncionMasiva(row.nCas_Id))
  }
  const SeleccionarTodo = async () => {
    var elem = document.getElementById("selecionarMasivo")
    var i
    if (elem.checked) {
      for (i = 0; i < document.f1.elements.length; i++)
        if (document.f1.elements[i].type == "checkbox")
          document.f1.elements[i].checked = 1
    } else {
      for (i = 0; i < document.f1.elements.length; i++)
        if (document.f1.elements[i].type == "checkbox")
          document.f1.elements[i].checked = 0
    }
  }


  const [getdenunciantes, setdenunciantes] = useState([])
  const [getdenunciados, setdenunciados] = useState([])
  const [getNotications, setNotications] = useState([])
  const [getCodigoIndecopi, setCodigoIndecopi] = useState([])
  const [getCodigoI, setCodigoI] = useState([])
  const FuncionSincronizarIndecopi = async () => {
    setLoading(false)
    var Cod = "";
    var AREA = "";
    var TEXPEDIENTE = "";
    var LTRAMITE = "";
    var LTRAMITE = "";
    var NEXPEDIENTE = "";
    var AÑO = "";
    var postData1 = {
      Prefijo: getCasos.nJuz_Id,
    }
    await instance
      .post("/Maestros/Listar", postData1)
      .then(response => {
        AREA = response.data[0].cTabSimbolo
      })
      .catch(error => {

      })
    var postData2 = {
      Prefijo: getCasos.TEXPEDIENTE,
    }
    await instance
      .post("/Maestros/Listar", postData2)
      .then(response => {
        TEXPEDIENTE = response.data[0].cTabSimbolo
      })
      .catch(error => {

      })
    var postData3 = {
      Prefijo: getCasos.LTRAMITE,
    }
    await instance
      .post("/Maestros/Listar", postData3)
      .then(response => {
        LTRAMITE = response.data[0].cTabSimbolo
      })
      .catch(error => {

      })
    AÑO = getCasos.nCas_Exp_Year;
    NEXPEDIENTE = getCasos.cCas_Expediente;
    Cod = NEXPEDIENTE + "-" + AÑO + "-" + AREA + "-" + TEXPEDIENTE + "-" + LTRAMITE


    var postData4 = {
      cod: Cod,
    }
    await instance
      .post("/Indecopi/Listar", postData4)
      .then(response => {
        setDisabled(false)
        setdenunciantes(response.data.denunciantes)
        setdenunciados(response.data.denunciados)
        setNotications(response.data.Notications)
        setCodigoIndecopi(Cod)
        setCodigoI(response.data.NExpediente[0].NExpediente)
        setCasos(prevState => ({
          ...prevState,
          nCas_Id: "",
          nCli_Id: "",
          nEqu_ID: 1,
          cCas_Tab_Tipo: "CA01",
          cCas_Titulo: "",
          cCas_Cod_Externo: "",
          cCas_Cod_Interno: "2021-0008",
          cCas_Tab_Materia: "",
          cCas_Tab_SubMateria: "",
          cCas_Detalle: "",
          cCas_Tab_Jurisdiccion: "JU01",
          cCas_Tab_Sala: "SA01",
          cCas_Tab_Recurso_Tipo: "HA01",
          cCas_Recurso_Num: "0203",
          cCas_Tab_Distrito_Jud: "",
          cCas_Tab_Comision: "CP01",
          cTab_Lugar_Tramite: "WE01",
          nPer_Id: 1,
          cCas_Referencia: "",
          cCas_Denominacion: "",
          cUbi_Org: "",
          dCas_Fec_Ini_Interno: ano + "-" + mes + "-" + dia,
          dCas_Fec_Ini_Procesal: ano + "-" + mes + "-" + dia,
          cCas_Tab_Estado_Externo: "",
          dCas_Fec_Estima_Cierre: moment(Fecha).format('yyyy-MM-DD'),
          cTab_Estado_Interno: "",
          dFas_Fec_Cierre: "",
          nUsu_ID: 1,
          dUsu_Fecha: ano + "-" + mes + "-" + dia,
          bCas_Estado: 1,
          cTab_Moneda: "",
          nCas_Monto: "",
          cTab_Tipo_Monto: "",
          nCas_Origen: 0,
        }))
        setCasos(prevState => ({
          ...prevState,
          cCas_Cod_Externo: Cod,
        }))
        setCasos(prevState => ({
          ...prevState,
          cCas_Titulo: response.data.NExpediente[0].NExpediente,
        }))

        var elem = document.getElementsByName("cCas_Tab_Materia")[0];
        elem.setAttribute("disabled", "true");
        var elem2 = document.getElementsByName("cCas_Tab_SubMateria")[0];
        elem2.setAttribute("disabled", "true");
        setCasos(prevState => ({
          ...prevState,
          cCas_Tab_Materia: "MA08",
        }))
        setCasos(prevState => ({
          ...prevState,
          cCas_Tab_SubMateria: "NN00",
        }))
        setLoading(true)
      })
      .catch(error => {

      })
  }
  const FuncionValidarFormulario = async () => {
    FuncionAgregarCaso()
  }
  useEffect(() => {
    FuncionListarCasos()
    FuncionListarCliente()
    FuncionListarComboJurisdiccion()
    FuncionListarComboJugados()
    FuncionListarComboMateria()
    FuncionListarComboSubMateria()
    FuncionListarComboEstadoExterno()
    FuncionListarComboEstadoInterno()
    FuncionListarComboUsuario()
    FuncionListarEquipo()
    setTab("1")
    setHiddenFinalizar("none")
    setHiddenAnterior2("none")
    setHiddenAnterior1("none")
    setHiddenSiguiente3("none")
    setHiddenSiguiente2("")
    setcheked2(true)
    setHiddenEquipo("")
    setHiddenEquipoCbo("none")
    setActive1("active")
    setActiveN1("badge bg-danger rounded-pill")
    setActive2("")
    setActiveN2("badge bg-secondary rounded-pill")
    setActive3("")
    setActiveN3("badge bg-secondary rounded-pill")
    FuncionListarComboMoneda()
    FuncionListarComboLUGARDETRAMITE()
    FuncionListarComboAREA()
    FuncionListarComboTIPOEXPEDIENTE()
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Casos | GpsLegal</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="GpsLegal" breadcrumbItem="Tabla Casos" />
        </Container>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <div>
                  <button
                    type="button"
                    onClick={() => abrirCerrarModal(1)}
                    className="btn btn-danger waves-effect waves-light"
                    data-toggle="modal"
                    data-target="#myModal"
                  >
                    <i style={{ fontSize: "15px" }} className="icon-nuevo"></i> Nuevo Caso
                  </button>{" "}
                  <button
                    type="button"
                    onClick={() => abrirCerrarModal(4)}
                    className="btn btn-danger waves-effect waves-light"
                    data-toggle="modal"
                    data-target="#myModal"
                  >
                    <i style={{ fontSize: "15px" }} className="icon-filtro"></i> Filtros
                  </button>{" "}
                  <button
                    type="button"
                    onClick={() => FuncionListarCasos()}
                    className="btn btn-primary waves-effect waves-light"
                    data-toggle="modal"
                    data-target="#myModal"
                  >
                    <i style={{ fontSize: "15px" }} className="icon-recuperar"></i> Refrescar
                  </button>
                  <Modal size="lg" isOpen={modalInsertar}>
                    <div
                      className="modal-header ModalHeaderColor"
                    >
                      <h5
                        style={{ color: "#fff" }}
                        className="modal-title mt-0"
                        id="myModalLabel"
                      >
                        Nuevo Caso
                      </h5>
                      <button
                        type="button"
                        onClick={() => abrirCerrarModal(1)}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <Nav tabs className="nav-tabs-custom nav-justified">
                        <NavItem>
                          <NavLink
                            className={Active1}
                            style={{ cursor: "pointer" }}
                          >
                            Vincular proceso <span className={ActiveN1}>1</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={Active2}
                            style={{ cursor: "pointer" }}
                          >
                            Datos del proceso{" "}
                            <span className={ActiveN2}>2</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={Active3}
                            style={{ cursor: "pointer" }}
                          >
                            Datos adicionales{" "}
                            <span className={ActiveN3}>3</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={Tab} className="p-3 text-muted">
                        <TabPane tabId="1">
                          <Row>
                            <Col sm="12">
                              <div className="mb-3 row">
                                <div className="modal-body">
                                  <div className="mb-3 row">
                                    <div className="col-md-3"></div>
                                    <div className="col-md-6">
                                      <select
                                        className="form-select inputDown"
                                        name="cCas_Tab_Tipo"
                                        onChange={handleChangeCaso}
                                      >
                                        <option value="CA01">
                                          Corte Superior - Búsqueda por código
                                          de expediente CEJ
                                        </option>
                                        <option value="CA02">
                                          Corte Superior - Búsqueda por filtros
                                          CEJ
                                        </option>
                                        <option value="CA03">
                                          Corte Suprema - Consultas de
                                          expediente de CEJ Supremo
                                        </option>
                                        <option value="CA04">
                                          INDECOPI - Búsqueda por Denunciante/Denunciado
                                        </option>
                                        <option value="CA05">
                                          INDECOPI - Búsqueda por expediente
                                        </option>
                                        <option value="CA06">Otros</option>
                                      </select>
                                      <label className="col-form-label labelDown">
                                        Jurisdicción o notaria
                                      </label>
                                    </div>
                                    <div className="col-md-3"></div>
                                  </div>
                                  <div id="CEJ" style={{ display: HiddenCEJ }}>
                                    <div className="mb-3 row">
                                      <div className="col-md-3"></div>
                                      <div className="col-md-5">
                                        <input
                                          className="form-control"
                                          type="text"
                                          placeholder="Nro. de expediente"
                                          name="cCas_Expediente"
                                          onChange={handleChangeCaso}
                                        />
                                        <label className="col-form-label labelDown">
                                          Ingrese Nro. de expediente
                                        </label>
                                      </div>
                                      <div className="col-md-2">
                                        <a
                                          style={{
                                            color: "black",
                                            fontSize: "20px",
                                          }}
                                          onClick={() =>
                                            FuncionSincronizarCEJ(getCasos.cCas_Expediente)
                                          }
                                        >
                                          {" "}
                                          <FontAwesomeIcon icon={faGlobe} hidden={!Loading} />
                                          <img
                                            id="imgr2"
                                            className="imgr"
                                            src={logo}
                                            style={{ width: '40px', height: "auto" }}
                                            hidden={Loading}
                                          />
                                        </a>
                                      </div>
                                      <div className="col-md-2"></div>
                                    </div>
                                    <div className="mb-3 row" hidden>
                                      <div className="col-md-3"></div>
                                      <div className="col-md-6">
                                        <input
                                          className="form-control"
                                          type="text"
                                          placeholder="Año"
                                          value={getCasos.nCas_Exp_Year}
                                          name="nCas_Exp_Year"
                                          onChange={handleChangeCaso}
                                        />
                                        <label className="col-form-label labelDown">
                                          Año
                                        </label>
                                      </div>
                                      <div className="col-md-3"></div>
                                    </div>
                                    <div className="mb-3 row" hidden>
                                      <div className="col-md-3"></div>
                                      <div className="col-md-6">
                                        <select
                                          className="form-select inputDown"
                                          name="nJuz_Id"
                                          onChange={handleChangeCaso}
                                        >
                                          <option value="NN00">
                                            Seleccionar...
                                          </option>
                                          {dataComboJugados.map((row, i) => (
                                            <option
                                              key={i}
                                              value={row.cTabCodigo}
                                            >
                                              {row.cTabNombre}
                                            </option>
                                          ))}
                                        </select>
                                        <label className="col-form-label labelDown">
                                          Seleccione Juzgado
                                        </label>
                                      </div>
                                    </div>
                                    <div className="mb-3 row">
                                      <div className="col-md-3"></div>
                                      <div className="col-md-6">
                                        <form name="f2" hidden={!Loading}>
                                          {(() => {
                                            if (getdataCuadernos.length > 0) {
                                              return (
                                                <>
                                                  <div className="divGLRE0 divResolImpar row" style={{
                                                    justifyContentt: "center",
                                                    alignItems: "center",
                                                  }}>
                                                    <div className="col-md-2">
                                                      <input
                                                        type="checkbox"
                                                        className="checkbox checkboxCuadernos"
                                                        name="SeleccionrTodoCuadernos"
                                                        checked={checkedMC}
                                                        onClick={() => FuncionSeleccionarTCuadernos()}
                                                      />
                                                    </div>
                                                    <div className="col-md-8 container">
                                                      <div style={{
                                                        borderBottom: "1px dashed #CCC",
                                                        paddingRight: "5px",
                                                        width: "100%;",
                                                      }}>
                                                        <div >
                                                          <p style={{
                                                            marginBottom: "0px",
                                                            marginTop: "16px"
                                                          }}>Seleccionar todo</p>
                                                        </div>
                                                        <br></br>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  {getdataCuadernos.map((row, i) => {
                                                    return (
                                                      <div className="divGLRE0 divResolImpar row" style={{
                                                        justifyContentt: "center",
                                                        alignItems: "center",
                                                      }}>
                                                        <div className="col-md-2">
                                                          <input
                                                            type="checkbox"
                                                            className="checkbox checkboxCuadernos"
                                                            name={"id" + row.CodigoC}
                                                            value={row.CodigoExterno}
                                                            onChange={FuncionActivarCej}
                                                          />
                                                        </div>
                                                        <div className="col-md-8 container">
                                                          <div style={{
                                                            borderBottom: "1px dashed #CCC",
                                                            paddingRight: "5px",
                                                            width: "100%;",
                                                          }}>
                                                            <div style={{ display: "inline-table" }}>
                                                              <b style={{ fontSize: "12px" }}>{row.CodigoExterno}</b>
                                                            </div>
                                                            <div style={{ paddingTop: "5px" }}>
                                                              <p style={{ fontSize: "10px" }}>{row.Detalle}</p>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    )
                                                  })}
                                                </>
                                              )
                                            }
                                          })()}
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                  <div id="INDECOPI" style={{ display: HiddenIndecopi }}>
                                    <div className="mb-3 row">
                                      <div className="col-md-3"></div>
                                      <div className="col-md-6">
                                        <select
                                          className="form-select inputDown"
                                          name="nJuz_Id"
                                          onChange={handleChangeCaso}
                                        >
                                          <option value="000">
                                            Seleccione
                                          </option>
                                          {dataComboArea.map((row, i) => (
                                            <option
                                              key={i}
                                              value={row.cTabCodigo}
                                            >
                                              {row.cTabNombre}
                                            </option>
                                          ))}
                                        </select>
                                        <label className="col-form-label labelDown">
                                          Area
                                        </label>
                                      </div>
                                      <div className="col-md-2">
                                        <a
                                          style={{
                                            color: "black",
                                            fontSize: "20px",
                                          }}
                                          onClick={() =>
                                            FuncionSincronizarIndecopi()
                                          }
                                        >
                                          {" "}
                                          <FontAwesomeIcon icon={faGlobe} />
                                        </a>
                                      </div>
                                    </div>
                                    <div className="mb-3 row">
                                      <div className="col-md-3"></div>
                                      <div className="col-md-6">
                                        <select
                                          className="form-select inputDown"
                                          name="TEXPEDIENTE"
                                          onChange={handleChangeCaso}
                                        >
                                          <option value="000">
                                            Seleccione
                                          </option>
                                          {dataComboTIPOEXPEDIENTE.map((row, i) => (
                                            <option
                                              key={i}
                                              value={row.cTabCodigo}
                                            >
                                              {row.cTabNombre}
                                            </option>
                                          ))}
                                        </select>
                                        <label className="col-form-label labelDown">
                                          Tipo expediente
                                        </label>
                                      </div>
                                      <div className="col-md-2"></div>
                                    </div>
                                    <div className="mb-3 row">
                                      <div className="col-md-3"></div>
                                      <div className="col-md-6">
                                        <select
                                          className="form-select inputDown"
                                          name="LTRAMITE"
                                          onChange={handleChangeCaso}
                                        >
                                          <option value="000">
                                            Seleccione
                                          </option>
                                          {dataComboLUGARDETRAMITE.map((row, i) => (
                                            <option
                                              key={i}
                                              value={row.cTabCodigo}
                                            >
                                              {row.cTabNombre}
                                            </option>
                                          ))}
                                        </select>
                                        <label className="col-form-label labelDown">
                                          Lugar de tramite
                                        </label>
                                      </div>
                                      <div className="col-md-2"></div>
                                    </div>
                                    <div className="mb-3 row">
                                      <div className="col-md-3"></div>
                                      <div className="col-md-6">
                                        <input
                                          className="form-control"
                                          type="text"
                                          placeholder="Nro. de expediente"
                                          name="cCas_Expediente"
                                          onChange={handleChangeCaso}
                                        />
                                        <label className="col-form-label labelDown">
                                          Ingrese Nro. de expediente
                                        </label>
                                      </div>
                                      <div className="col-md-2"></div>
                                    </div>
                                    <div className="mb-3 row">
                                      <div className="col-md-3"></div>
                                      <div className="col-md-6">
                                        <input
                                          className="form-control"
                                          type="text"
                                          placeholder="Año"
                                          name="nCas_Exp_Year"
                                          onChange={handleChangeCaso}
                                        />
                                        <label className="col-form-label labelDown">
                                          Año
                                        </label>
                                      </div>
                                      <div className="col-md-2"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="2">
                          <Row>
                            <Col sm="12">
                              <div className="mb-3 row">
                                <div className="modal-body">
                                  <div className="mb-3 row">
                                    <strong>Tipo de Caso</strong>
                                    <hr style={{ color: "#000" }} />
                                    <div className="col-md-7">
                                      <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Asunto del caso"
                                        name="cCas_Titulo"
                                        value={getCasos && getCasos.cCas_Titulo}
                                        onChange={handleChangeCaso}
                                      />
                                      <label className="col-form-label labelDown">
                                        Ingrese Asunto del caso
                                      </label>
                                    </div>
                                    <div className="col-md-5">
                                      <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Código externo"
                                        name="cCas_Cod_Externo"
                                        onChange={handleChangeCaso}
                                        value={getCasos && getCasos.cCas_Cod_Externo}
                                      />
                                      <label className="col-form-label labelDown">
                                        Código externo
                                      </label>
                                    </div>
                                  </div>
                                  <div className="mb-3 row">
                                    <div className="col-md-6">
                                      <select
                                        className="form-select inputDown"
                                        name="cCas_Tab_Materia"
                                        onChange={handleChangeCaso}
                                        value={getCasos && getCasos.cCas_Tab_Materia}
                                      >
                                        <option value="NN00">
                                          Seleccionar...
                                        </option>
                                        {dataComboMateria.map((row, i) => (
                                          <option
                                            key={i}
                                            value={row.cTabCodigo}
                                          >
                                            {row.cTabNombre}
                                          </option>
                                        ))}
                                      </select>
                                      <label className="col-form-label labelDown">
                                        Seleccione Especialidad
                                      </label>
                                    </div>
                                    <div className="col-md-6">
                                      <select
                                        className="form-select inputDown"
                                        name="cCas_Tab_SubMateria"
                                        onChange={handleChangeCaso}
                                        value={getCasos && getCasos.cCas_Tab_SubMateria}
                                      >
                                        <option value="NN00">
                                          Seleccionar...
                                        </option>
                                        {dataComboSubMateria.map((row, i) => (
                                          <option
                                            key={i}
                                            value={row.cTabCodigo}
                                          >
                                            {row.cTabNombre}
                                          </option>
                                        ))}
                                      </select>
                                      <label className="col-form-label labelDown">
                                        Seleccione Materia
                                      </label>
                                    </div>
                                  </div>
                                  <div className="mb-3 row">
                                    <div className="col-md-12">
                                      <textarea
                                        className="form-control"
                                        type="text"
                                        maxLength="500"
                                        name="cCas_Detalle"
                                        onChange={handleChangeCaso}
                                        value={getCasos && getCasos.cCas_Detalle}
                                      ></textarea>
                                      <label className="col-form-label labelDown">
                                        Ingrese Detalle
                                      </label>
                                    </div>
                                  </div>
                                  <strong style={{ color: "#AA1B0E" }}>
                                    Cliente
                                  </strong>
                                  <hr
                                    style={{
                                      color: "#AA1B0E",
                                      marginTop: "0px",
                                    }}
                                  />
                                  <div className="mb-3 row">
                                    <div className="col-md-4">
                                      <select className="form-select" >
                                        <option value="1">
                                          Persona Jurídica
                                        </option>
                                        <option value="0" selected>
                                          Persona Natural
                                        </option>
                                      </select>
                                      <label className="col-form-label labelDown">
                                        Seleccione tipo de cliente
                                      </label>
                                    </div>
                                    <div className="col-md-7">
                                      <select
                                        className="form-select"
                                        name="nCli_Id"
                                        onChange={handleChangeCaso}
                                      >
                                        <option value="NN00">
                                          Seleccionar...
                                        </option>
                                        {dataCliente.map((row, i) => (
                                          <option key={i} value={row.nCli_Id}>
                                            {row.cCli_NombreCompleto}
                                          </option>
                                        ))}
                                      </select>
                                      <label className="col-form-label labelDown">
                                        Cliente
                                      </label>
                                    </div>
                                    <div className="col-md-1" style={{ top: "7px" }}>
                                      <i className="icon-nuevo" style={{ fontSize: "15px" }} onClick={() => abrirCerrarModal(5)}></i>
                                      {(() => {
                                        if (modalInsertarCliente == true) {
                                          return <><ModalCliente show={modalInsertarCliente}
                                            onCloseClick={() => abrirCerrarModal(5)}
                                            Titulo="Nuevo Cliente"
                                          />
                                          </>
                                        }
                                      })()}
                                    </div>
                                  </div>
                                  <div className="mb-3 row">
                                    <div className="col-md-6">
                                      <input
                                        className="form-control"
                                        type="text"
                                        placeholder="..."
                                        name="cCas_Referencia"
                                        onChange={handleChangeCaso}
                                      />
                                      <label className="col-form-label labelDown">
                                        Referencia de contrato anterior
                                      </label>
                                    </div>
                                  </div>
                                  <strong>
                                    Información del organismo donde se tramitará
                                  </strong>
                                  <hr
                                    style={{ color: "#000", marginTop: "0px" }}
                                  />
                                  <div className="mb-3 row ">
                                    <div className="col-md-6">
                                      <select
                                        className="form-select inputDown"
                                        name="cCas_Tab_Distrito_Jud"
                                        onChange={handleChangeCaso}
                                      >
                                        <option value="NN00">
                                          Seleccionar...
                                        </option>
                                        {dataComboJugados.map((row, i) => (
                                          <option
                                            key={i}
                                            value={row.cTabCodigo}
                                          >
                                            {row.cTabNombre}
                                          </option>
                                        ))}
                                      </select>
                                      <label className="col-form-label labelDown">
                                        Órgano competente
                                      </label>
                                    </div>
                                    <div className="col-md-6">
                                      <input
                                        className="form-control"
                                        type="text"
                                        name="cCas_Denominacion"
                                        onChange={handleChangeCaso}
                                      />
                                      <label className="col-form-label labelDown">
                                        Denominación
                                      </label>
                                    </div>
                                  </div>
                                  <div className="mb-3 row ">
                                    <div className="col-md-12">
                                      <input
                                        name="cUbi_Org"
                                        className="form-control"
                                        type="text"
                                        placeholder="Ubicación del expediente"
                                        onChange={handleChangeCaso}
                                      />
                                      <label className="col-form-label labelDown">
                                        Ubicación del expediente
                                      </label>
                                    </div>
                                  </div>
                                  <strong>Estado</strong>
                                  <hr
                                    style={{ color: "#000", marginTop: "0px" }}
                                  />
                                  <div className="mb-3 row ">
                                    <div className="col-md-6">
                                      <input
                                        className="form-control"
                                        type="date"
                                        value={getCasos.dCas_Fec_Ini_Interno}
                                        name="dCas_Fec_Ini_Interno"
                                        onChange={handleChangeCaso}
                                      />
                                      <label className="col-form-label labelDown">
                                        Fecha de inicio
                                      </label>
                                    </div>
                                    <div className="col-md-6">
                                      <input

                                        className="form-control"
                                        type="date"
                                        value={getCasos.dCas_Fec_Ini_Procesal}
                                        name="dCas_Fec_Ini_Procesal"
                                        onChange={handleChangeCaso}
                                      />
                                      <label className="col-form-label labelDown">
                                        Fecha de inicio procesal
                                      </label>
                                    </div>
                                  </div>
                                  <div className="mb-3 row ">
                                    <div className="col-md-6">
                                      <select
                                        className="form-select inputDown"
                                        name="cCas_Tab_Estado_Externo"
                                        value={getCasos.cCas_Tab_Estado_Externo}
                                        onChange={handleChangeCaso}
                                      >
                                        <option value="NN00">
                                          Seleccionar...
                                        </option>
                                        {dataComboEstadoExterno.map(
                                          (row, i) => (
                                            <option
                                              key={i}
                                              value={row.cTabCodigo}
                                            >
                                              {row.cTabNombre}
                                            </option>
                                          )
                                        )}
                                      </select>
                                      <label className="col-form-label labelDown">
                                        Estado externo
                                      </label>
                                    </div>
                                    <div className="col-md-6">
                                      <input
                                        className="form-control"
                                        type="date"
                                        name="dCas_Fec_Estima_Cierre"
                                        onChange={handleChangeCaso}
                                      />
                                      <label className="col-form-label labelDown">
                                        Fecha estimada de cierre
                                      </label>
                                    </div>
                                  </div>
                                  <div className="mb-3 row ">
                                    <div className="col-md-6">
                                      <select
                                        className="form-select inputDown"
                                        name="cTab_Estado_Interno"
                                        onChange={handleChangeCaso}
                                      >
                                        <option value="NN00">
                                          Seleccionar...
                                        </option>
                                        {dataComboEstadoInterno.map(
                                          (row, i) => (
                                            <option
                                              key={i}
                                              value={row.cTabCodigo}
                                            >
                                              {row.cTabNombre}
                                            </option>
                                          )
                                        )}
                                      </select>
                                      <label className="col-form-label labelDown">
                                        Estado Interno
                                      </label>
                                    </div>
                                    <div className="col-md-6">
                                      <input
                                        className="form-control"
                                        type="date"
                                        name="dFas_Fec_Cierre"
                                        onChange={handleChangeCaso}
                                      />
                                      <label className="col-form-label labelDown">
                                        Fecha de cierre
                                      </label>
                                    </div>
                                  </div>
                                  <strong>Datos Económicos</strong>
                                  <hr
                                    style={{ color: "#000", marginTop: "0px" }}
                                  />
                                  <div className="mb-3 row ">
                                    <div className="col-md-4">
                                      <select
                                        className="form-select inputDown" onChange={handleChangeCaso}
                                        name="cTab_Moneda"
                                      >
                                        <option value="NN00">Seleccionar</option>
                                        {dataComboMoneda.map((row, i) => (
                                          <option key={i} value={row.cTabCodigo}>
                                            {row.cTabNombre}
                                          </option>
                                        ))}
                                      </select>
                                      <label className="col-form-label labelDown">
                                        Seleccione Moneda
                                      </label>
                                    </div>
                                    <div className="col-md-4">
                                      <input
                                        className="form-control"
                                        type="text"
                                        onChange={handleChangeCaso}
                                        name="nCas_Monto"
                                        placeholder="Monto"
                                      />
                                      <label className="col-form-label labelDown">
                                        Ingrese Monto
                                      </label>
                                    </div>
                                    <div className="col-md-4">
                                      <select
                                        className="form-select inputDown"
                                        onChange={handleChangeCaso}
                                        name="cTab_Tipo_Monto"
                                      >
                                        <option value="0">Seleccionar</option>
                                        <option value="1">A Favor</option>
                                        <option value="2">En contra</option>
                                      </select>
                                      <label className="col-form-label labelDown">
                                        Seleccione Tipo
                                      </label>
                                    </div>
                                  </div>
                                  <strong>Informacion del sistema</strong>
                                  <hr
                                    style={{ color: "#000", marginTop: "0px" }}
                                  />
                                  <div className="mb-3 row ">
                                    <div className="col-md-6">
                                      <input

                                        className="form-control"
                                        type="text"
                                        placeholder="Creado por"
                                        name="nUsu_ID"
                                        value={getCasos.nUsu_ID}
                                        onChange={handleChangeCaso}
                                        readOnly
                                      />
                                      <label className="col-form-label labelDown">
                                        Creado por
                                      </label>
                                    </div>
                                    <div className="col-md-6">
                                      <input

                                        className="form-control"
                                        type="date"
                                        value={getCasos.dUsu_Fecha}
                                        name="dUsu_Fecha"
                                        onChange={handleChangeCaso}
                                        readOnly
                                      />
                                      <label className="col-form-label labelDown">
                                        Fecha de creacion
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="3">
                          <Row>
                            <Col sm="12">
                              <CardText className="mb-0">
                                <div className="mb-3 row">
                                  <div className="modal-body">
                                    <div className="mb-3 row">
                                      <strong>Equipo</strong>
                                      <hr style={{ color: "#000" }} />
                                      <div className="col-md-3">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="customCheckcolor1"
                                          onChange={handleChangeCheck}
                                          checked={cheked}
                                          value={cheked}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="customCheckcolor1"
                                          style={{ paddingLeft: "5px" }}
                                        >
                                          Asignar Equipo
                                        </label>
                                      </div>
                                      <div className="col-md-3" hidden>
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="customCheckcolor1"
                                          onChange={handleChangeCheck2}
                                          checked={cheked2}
                                          value={cheked2}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="customCheckcolor1"
                                          style={{ paddingLeft: "5px" }}
                                        >
                                          Asignar personas
                                        </label>
                                      </div>
                                    </div>
                                    <div className="mb-3 row">
                                      <label
                                        htmlFor="example-text-input"
                                        className="col-md-4 col-form-label"
                                      ></label>
                                      <div
                                        className="col-md-6"
                                        style={{ display: HiddenEquipoCbo }}
                                      >
                                        <select
                                          className="form-select inputDown"
                                          name="nCas_Id"
                                        >
                                          <option value="0">
                                            Seleccionar...
                                          </option>
                                          {dataEquipo.map((row, i) => (
                                            <option key={i} value={row.nEqu_ID}>
                                              {row.cEqu_Nombre}
                                            </option>
                                          ))}
                                        </select>
                                        <label className="col-form-label labelDown">
                                          Seleccione equipo
                                        </label>
                                      </div>
                                    </div>
                                    <div style={{ display: HiddenEquipo }}>
                                      <div className="mb-3 row">
                                        <label
                                          htmlFor="example-text-input"
                                          className="col-md-4 col-form-label"
                                        >
                                          Abogado(s) responsable
                                        </label>
                                        <div className="col-md-6">
                                          <Select
                                            isMulti
                                            onChange={handleChangeParticipantesAR}
                                            name="colors"
                                            options={UsuariosSelect}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Abogados responsables..."
                                          />
                                          <label className="col-form-label labelDown">
                                            Abogado(s) responsable
                                          </label>
                                        </div>
                                      </div>
                                      <div className="mb-3 row">
                                        <label
                                          htmlFor="example-text-input"
                                          className="col-md-4 col-form-label"
                                        >
                                          Abogado(s)
                                        </label>
                                        <div className="col-md-6">
                                          <Select
                                            isMulti
                                            onChange={handleChangeParticipantesA}
                                            name="colors"
                                            options={UsuariosSelect}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Abogados..."
                                          />
                                          <label className="col-form-label labelDown">
                                            Abogado(s)
                                          </label>
                                        </div>
                                      </div>
                                      <div className="mb-3 row">
                                        <label
                                          htmlFor="example-text-input"
                                          className="col-md-4 col-form-label"
                                        >
                                          Practicante(s)
                                        </label>
                                        <div className="col-md-6">
                                          <Select
                                            isMulti
                                            onChange={handleChangeParticipantesP}
                                            name="colors"
                                            options={UsuariosSelect}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Practicante..."
                                          />
                                          <label className="col-form-label labelDown">
                                            Practicante(s)
                                          </label>
                                        </div>
                                      </div>
                                      <div className="mb-3 row">
                                        <label
                                          htmlFor="example-text-input"
                                          className="col-md-4 col-form-label"
                                        >
                                          Usuario Adicional
                                        </label>
                                        <div className="col-md-6">
                                          <Select
                                            isMulti
                                            onChange={handleChangeParticipantesUAD}
                                            name="colors"
                                            options={UsuariosSelect}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Usuario Adicional..."
                                          />
                                          <label className="col-form-label labelDown">
                                            Usuario Adicional
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </CardText>
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        onClick={() => abrirCerrarModal(1)}
                        className="btn btn-secondary waves-effect waves-light"
                        data-dismiss="modal"
                      >
                        Cerrar <FontAwesomeIcon icon={faTimes} />
                      </button>
                      <button
                        type="button"
                        onClick={() => EventoCabeceraTab("1")}
                        className="btn btn-primary waves-effect waves-light"
                        style={{ display: HiddenAnterior1 }}
                      >
                        <FontAwesomeIcon icon={faChevronLeft} /> Anterior
                      </button>
                      <button
                        type="button"
                        onClick={() => EventoCabeceraTab("2")}
                        className="btn btn-primary waves-effect waves-light"
                        style={{ display: HiddenAnterior2 }}
                      >
                        <FontAwesomeIcon icon={faChevronLeft} /> Anterior
                      </button>
                      <button
                        type="button"
                        onClick={() => EventoCabeceraTab("2")} name="btnSiguiente1"
                        className="btn btn-danger waves-effect waves-light " disabled={disabled}
                        style={{ display: HiddenSiguiente2 }}
                      >
                        Siguiente <FontAwesomeIcon icon={faChevronRight} />
                      </button>
                      <button
                        type="button"
                        onClick={() => EventoCabeceraTab("3")}
                        className="btn btn-danger waves-effect waves-light"
                        style={{ display: HiddenSiguiente3 }}
                      >
                        Siguiente <FontAwesomeIcon icon={faChevronRight} />
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger waves-effect waves-light"
                        style={{ display: HiddenFinalizar }}
                        onClick={() => FuncionValidarFormulario()}
                      >
                        Grabar <FontAwesomeIcon icon={faPaperPlane} />
                      </button>
                    </div>
                  </Modal>
                  <Modal size="lg" isOpen={modalActualizar}>
                    <div
                      className="modal-header ModalHeaderColor"
                    >
                      <h5
                        style={{ color: "#fff" }}
                        className="modal-title mt-0"
                        id="myModalLabel"
                      >
                        Editar Caso
                      </h5>
                      <button
                        type="button"
                        onClick={() => abrirCerrarModal(2)}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <Nav tabs className="nav-tabs-custom nav-justified">
                        <NavItem>
                          <NavLink
                            className={Active2}
                            style={{ cursor: "pointer" }}
                            onClick={() => EventoCabeceraTab("2")}
                          >
                            Datos del proceso{" "}
                            <span className={ActiveN2}>1</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={Active3}
                            style={{ cursor: "pointer" }}
                            onClick={() => EventoCabeceraTab("3")}
                          >
                            Datos adicionales{" "}
                            <span className={ActiveN3}>2</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={Tab} className="p-3 text-muted">
                        <TabPane tabId="2">
                          <Row>
                            <Col sm="12">
                              <div className="mb-3 row">
                                <div className="modal-body">
                                  <div className="mb-3 row">
                                    <strong>Tipo de Caso</strong>
                                    <hr style={{ color: "#000" }} />
                                    <div className="col-md-7">
                                      <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Asunto del caso"
                                        name="cCas_Titulo"
                                        value={getCasos && getCasos.cCas_Titulo}
                                        onChange={handleChangeCaso}
                                      />
                                      <label className="col-form-label labelDown">
                                        Ingrese Asunto del caso
                                      </label>
                                    </div>
                                    <div className="col-md-5">
                                      <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Código externo"
                                        name="cCas_Cod_Externo"
                                        onChange={handleChangeCaso}
                                        value={getCasos && getCasos.cCas_Cod_Externo}
                                      />
                                      <label className="col-form-label labelDown">
                                        Código externo
                                      </label>
                                    </div>
                                  </div>
                                  <div className="mb-3 row">
                                    <div className="col-md-6">
                                      <select
                                        className="form-select inputDown"
                                        name="cCas_Tab_Materia"
                                        onChange={handleChangeCaso}
                                        value={getCasos && getCasos.cCas_Tab_Materia}
                                      >
                                        <option value="NN00">
                                          Seleccionar...
                                        </option>
                                        {dataComboMateria.map((row, i) => (
                                          <option
                                            key={i}
                                            value={row.cTabCodigo}
                                          >
                                            {row.cTabNombre}
                                          </option>
                                        ))}
                                      </select>
                                      <label className="col-form-label labelDown">
                                        Seleccione materia
                                      </label>
                                    </div>
                                    <div className="col-md-6">
                                      <select
                                        className="form-select inputDown"
                                        name="cCas_Tab_SubMateria"
                                        onChange={handleChangeCaso}
                                        value={getCasos && getCasos.cCas_Tab_SubMateria}
                                      >
                                        <option value="NN00">
                                          Seleccionar...
                                        </option>
                                        {dataComboSubMateria.map((row, i) => (
                                          <option
                                            key={i}
                                            value={row.cTabCodigo}
                                          >
                                            {row.cTabNombre}
                                          </option>
                                        ))}
                                      </select>
                                      <label className="col-form-label labelDown">
                                        Seleccione submateria
                                      </label>
                                    </div>
                                  </div>
                                  <div className="mb-3 row">
                                    <div className="col-md-12">
                                      <textarea
                                        className="form-control"
                                        type="text"
                                        maxLength="500"
                                        name="cCas_Detalle"
                                        onChange={handleChangeCaso}
                                        value={getCasos && getCasos.cCas_Detalle}
                                      ></textarea>
                                      <label className="col-form-label labelDown">
                                        Ingrese Detalle
                                      </label>
                                    </div>
                                  </div>
                                  <strong style={{ color: "#AA1B0E" }}>
                                    Cliente
                                  </strong>
                                  <hr
                                    style={{
                                      color: "#AA1B0E",
                                      marginTop: "0px",
                                    }}
                                  />
                                  <div className="mb-3 row">
                                    <div className="col-md-4">
                                      <select className="form-select" >
                                        <option value="1">
                                          Persona Jurídica
                                        </option>
                                        <option value="0" selected>
                                          Persona Natural
                                        </option>
                                      </select>
                                      <label className="col-form-label labelDown">
                                        Seleccione tipo de cliente
                                      </label>
                                    </div>
                                    <div className="col-md-7">
                                      <select
                                        className="form-select"
                                        name="nCli_Id"
                                        onChange={handleChangeCaso}
                                        value={getCasos && getCasos.nCli_Id}
                                      >
                                        <option value="NN00">
                                          Seleccionar...
                                        </option>
                                        {dataCliente.map((row, i) => (
                                          <option key={i} value={row.nCli_Id}>
                                            {row.cCli_NombreCompleto}
                                          </option>
                                        ))}
                                      </select>
                                      <label className="col-form-label labelDown">
                                        Cliente
                                      </label>
                                    </div>
                                    <div className="col-md-1" style={{ top: "7px" }}>
                                      <i className="icon-nuevo" style={{ fontSize: "15px" }} onClick={() => abrirCerrarModal(5)}></i>
                                      {(() => {
                                        if (modalInsertarCliente == true) {
                                          return <><ModalCliente show={modalInsertarCliente}
                                            onCloseClick={() => abrirCerrarModal(5)}
                                            Titulo="Nuevo Cliente"
                                          />
                                          </>
                                        }
                                      })()}
                                    </div>
                                  </div>
                                  <div className="mb-3 row">
                                    <div className="col-md-6">
                                      <input
                                        className="form-control"
                                        type="text"
                                        placeholder="..."
                                        name="cCas_Referencia"
                                        onChange={handleChangeCaso}
                                        value={getCasos && getCasos.cCas_Referencia}
                                      />
                                      <label className="col-form-label labelDown">
                                        Referencia de contrato anterior
                                      </label>
                                    </div>
                                  </div>
                                  <strong>
                                    Información del organismo donde se tramitará
                                  </strong>
                                  <hr
                                    style={{ color: "#000", marginTop: "0px" }}
                                  />
                                  <div className="mb-3 row ">
                                    <div className="col-md-6">
                                      <select
                                        className="form-select inputDown"
                                        name="cCas_Tab_Distrito_Jud"
                                        onChange={handleChangeCaso}
                                        value={getCasos && getCasos.cCas_Tab_Distrito_Jud}
                                      >
                                        <option value="NN00">
                                          Seleccionar...
                                        </option>
                                        {dataComboJugados.map((row, i) => (
                                          <option
                                            key={i}
                                            value={row.cTabCodigo}
                                          >
                                            {row.cTabNombre}
                                          </option>
                                        ))}
                                      </select>
                                      <label className="col-form-label labelDown">
                                        Órgano competente
                                      </label>
                                    </div>
                                    <div className="col-md-6">
                                      <input
                                        className="form-control"
                                        type="text"
                                        name="cCas_Denominacion"
                                        onChange={handleChangeCaso}
                                        value={getCasos && getCasos.cCas_Denominacion}
                                      />
                                      <label className="col-form-label labelDown">
                                        Denominación
                                      </label>
                                    </div>
                                  </div>
                                  <div className="mb-3 row ">
                                    <div className="col-md-12">
                                      <input
                                        name="cUbi_Org"
                                        className="form-control"
                                        type="text"
                                        placeholder="Ubicación del organismo"
                                        onChange={handleChangeCaso}
                                        value={getCasos && getCasos.cUbi_Org}
                                      />
                                      <label className="col-form-label labelDown">
                                        Ubicación del organismo
                                      </label>
                                    </div>
                                  </div>
                                  <strong>Estado</strong>
                                  <hr
                                    style={{ color: "#000", marginTop: "0px" }}
                                  />
                                  <div className="mb-3 row ">
                                    <div className="col-md-6">
                                      <input
                                        className="form-control"
                                        type="date"
                                        name="dCas_Fec_Ini_Interno"
                                        onChange={handleChangeCaso}
                                        value={getCasos && getCasos.dCas_Fec_Ini_Interno}
                                      />
                                      <label className="col-form-label labelDown">
                                        Fecha de inicio
                                      </label>
                                    </div>
                                    <div className="col-md-6">
                                      <input

                                        className="form-control"
                                        type="date"
                                        value={getCasos.dCas_Fec_Ini_Procesal}
                                        name="dCas_Fec_Ini_Procesal"
                                        onChange={handleChangeCaso}
                                      />
                                      <label className="col-form-label labelDown">
                                        Fecha de inicio procesal
                                      </label>
                                    </div>
                                  </div>
                                  <div className="mb-3 row ">
                                    <div className="col-md-6">
                                      <select
                                        className="form-select inputDown"
                                        name="cCas_Tab_Estado_Externo"
                                        value={getCasos.cCas_Tab_Estado_Externo}
                                        onChange={handleChangeCaso}
                                      >
                                        <option value="NN00">
                                          Seleccionar...
                                        </option>
                                        {dataComboEstadoExterno.map(
                                          (row, i) => (
                                            <option
                                              key={i}
                                              value={row.cTabCodigo}
                                            >
                                              {row.cTabNombre}
                                            </option>
                                          )
                                        )}
                                      </select>
                                      <label className="col-form-label labelDown">
                                        Estado externo
                                      </label>
                                    </div>
                                    <div className="col-md-6">
                                      <input
                                        className="form-control"
                                        type="date"
                                        name="dCas_Fec_Estima_Cierre"
                                        onChange={handleChangeCaso}
                                        value={getCasos.dCas_Fec_Estima_Cierre}
                                      />
                                      <label className="col-form-label labelDown">
                                        Fecha estimada de cierre
                                      </label>
                                    </div>
                                  </div>
                                  <div className="mb-3 row ">
                                    <div className="col-md-6">
                                      <select
                                        className="form-select inputDown"
                                        name="cTab_Estado_Interno"
                                        onChange={handleChangeCaso}
                                        value={getCasos.cTab_Estado_Interno}
                                      >
                                        <option value="NN00">
                                          Seleccionar...
                                        </option>
                                        {dataComboEstadoInterno.map(
                                          (row, i) => (
                                            <option
                                              key={i}
                                              value={row.cTabCodigo}
                                            >
                                              {row.cTabNombre}
                                            </option>
                                          )
                                        )}
                                      </select>
                                      <label className="col-form-label labelDown">
                                        Estado Interno
                                      </label>
                                    </div>
                                    <div className="col-md-6">
                                      <input
                                        className="form-control"
                                        type="date"
                                        name="dFas_Fec_Cierre"
                                        onChange={handleChangeCaso}
                                        value={getCasos.dFas_Fec_Cierre}
                                      />
                                      <label className="col-form-label labelDown">
                                        Fecha de cierre
                                      </label>
                                    </div>
                                  </div>
                                  <strong>Datos Económicos</strong>
                                  <hr
                                    style={{ color: "#000", marginTop: "0px" }}
                                  />
                                  <div className="mb-3 row ">
                                    <div className="col-md-4">
                                      <select
                                        className="form-select inputDown" onChange={handleChangeCaso}
                                        name="cTab_Moneda"
                                        value={getCasos.cTab_Moneda}
                                      >
                                        <option value="NN00">Seleccionar</option>
                                        {dataComboMoneda.map((row, i) => (
                                          <option key={i} value={row.cTabCodigo}>
                                            {row.cTabNombre}
                                          </option>
                                        ))}
                                      </select>
                                      <label className="col-form-label labelDown">
                                        Seleccione Moneda
                                      </label>
                                    </div>
                                    <div className="col-md-4">
                                      <input
                                        className="form-control"
                                        type="text"
                                        onChange={handleChangeCaso}
                                        name="nCas_Monto"
                                        placeholder="Monto"
                                        value={getCasos.nCas_Monto}
                                      />
                                      <label className="col-form-label labelDown">
                                        Ingrese Monto
                                      </label>
                                    </div>
                                    <div className="col-md-4">
                                      <select
                                        className="form-select inputDown"
                                        onChange={handleChangeCaso}
                                        name="cTab_Tipo_Monto"
                                        value={getCasos.cTab_Tipo_Monto}
                                      >
                                        <option value="0">Seleccionar</option>
                                        <option value="1">A Favor</option>
                                        <option value="2">En contra</option>
                                      </select>
                                      <label className="col-form-label labelDown">
                                        Seleccione Tipo
                                      </label>
                                    </div>
                                  </div>
                                  <strong>Informacion del sistema</strong>
                                  <hr
                                    style={{ color: "#000", marginTop: "0px" }}
                                  />
                                  <div className="mb-3 row ">
                                    <div className="col-md-6">
                                      <input

                                        className="form-control"
                                        type="text"
                                        placeholder="Creado por"
                                        value={getCasos.nUsu_ID2}
                                        onChange={handleChangeCaso}
                                        readOnly
                                      />
                                      <label className="col-form-label labelDown">
                                        Creado por
                                      </label>
                                    </div>
                                    <div className="col-md-6">
                                      <input

                                        className="form-control"
                                        type="date"
                                        value={getCasos.dUsu_Fecha}
                                        name="dUsu_Fecha"
                                        onChange={handleChangeCaso}
                                        readOnly
                                      />
                                      <label className="col-form-label labelDown">
                                        Fecha de creacion
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="3">
                          <Row>
                            <Col sm="12">
                              <CardText className="mb-0">
                                <div className="mb-3 row">
                                  <div className="modal-body">
                                    <div className="mb-3 row">
                                      <strong>Equipo</strong>
                                      <hr style={{ color: "#000" }} />
                                      <div className="col-md-3">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="customCheckcolor1"
                                          onChange={handleChangeCheck}
                                          checked={cheked}
                                          value={cheked}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="customCheckcolor1"
                                          style={{ paddingLeft: "5px" }}
                                        >
                                          Asignar Equipo
                                        </label>
                                      </div>
                                      <div className="col-md-3" hidden>
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="customCheckcolor1"
                                          onChange={handleChangeCheck2}
                                          checked={cheked2}
                                          value={cheked2}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="customCheckcolor1"
                                          style={{ paddingLeft: "5px" }}
                                        >
                                          Asignar personas
                                        </label>
                                      </div>
                                    </div>
                                    <div className="mb-3 row">
                                      <label
                                        htmlFor="example-text-input"
                                        className="col-md-4 col-form-label"
                                      ></label>
                                      <div
                                        className="col-md-6"
                                        style={{ display: HiddenEquipoCbo }}
                                      >
                                        <select
                                          className="form-select inputDown"
                                          name="nCas_Id"
                                        >
                                          <option value="0">
                                            Seleccionar...
                                          </option>
                                          {dataEquipo.map((row, i) => (
                                            <option key={i} value={row.nEqu_ID}>
                                              {row.cEqu_Nombre}
                                            </option>
                                          ))}
                                        </select>
                                        <label className="col-form-label labelDown">
                                          Seleccione equipo
                                        </label>
                                      </div>
                                    </div>
                                    <div style={{ display: HiddenEquipo }}>
                                      <div className="mb-3 row">
                                        <label
                                          htmlFor="example-text-input"
                                          className="col-md-4 col-form-label"
                                        >
                                          Abogado(s) responsable
                                        </label>
                                        <div className="col-md-6">
                                          <Select
                                            isMulti
                                            onChange={handleChangeParticipantesAR}
                                            name="colors"
                                            options={UsuariosSelect}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Abogados responsables..."
                                            defaultValue={GetParticipantesAR}
                                          />
                                          <label className="col-form-label labelDown">
                                            Abogado(s) responsable
                                          </label>
                                        </div>
                                      </div>
                                      <div className="mb-3 row">
                                        <label
                                          htmlFor="example-text-input"
                                          className="col-md-4 col-form-label"
                                        >
                                          Abogado(s)
                                        </label>
                                        <div className="col-md-6">
                                          <Select
                                            isMulti
                                            onChange={handleChangeParticipantesA}
                                            name="colors"
                                            options={UsuariosSelect}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Abogados..."
                                            defaultValue={GetParticipantesA}
                                          />
                                          <label className="col-form-label labelDown">
                                            Abogado(s)
                                          </label>
                                        </div>
                                      </div>
                                      <div className="mb-3 row">
                                        <label
                                          htmlFor="example-text-input"
                                          className="col-md-4 col-form-label"
                                        >
                                          Practicante(s)
                                        </label>
                                        <div className="col-md-6">
                                          <Select
                                            isMulti
                                            onChange={handleChangeParticipantesP}
                                            name="colors"
                                            options={UsuariosSelect}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Practicante..."
                                            defaultValue={GetParticipantesP}
                                          />
                                          <label className="col-form-label labelDown">
                                            Practicante(s)
                                          </label>
                                        </div>
                                      </div>
                                      <div className="mb-3 row">
                                        <label
                                          htmlFor="example-text-input"
                                          className="col-md-4 col-form-label"
                                        >
                                          Usuario Adicional
                                        </label>
                                        <div className="col-md-6">
                                          <Select
                                            isMulti
                                            onChange={handleChangeParticipantesUAD}
                                            name="colors"
                                            options={UsuariosSelect}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Usuario Adicional..."
                                            defaultValue={GetParticipantesUAD}
                                          />
                                          <label className="col-form-label labelDown">
                                            Usuario Adicional
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </CardText>
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        onClick={() => abrirCerrarModal(2)}
                        className="btn btn-secondary waves-effect waves-light"
                        data-dismiss="modal"
                      >
                        Cerrar <FontAwesomeIcon icon={faTimes} />
                      </button>
                      <button
                        type="button"
                        onClick={() => EventoCabeceraTab("2")}
                        className="btn btn-primary waves-effect waves-light"
                        style={{ display: HiddenAnterior2 }}
                      >
                        <FontAwesomeIcon icon={faChevronLeft} /> Anterior
                      </button>
                      <button
                        type="button"
                        onClick={() => EventoCabeceraTab("2")} name="btnSiguiente1"
                        className="btn btn-danger waves-effect waves-light " disabled={disabled}
                        style={{ display: HiddenSiguiente2 }}
                      >
                        Siguiente <FontAwesomeIcon icon={faChevronRight} />
                      </button>
                      <button
                        type="button"
                        onClick={() => EventoCabeceraTab("3")}
                        className="btn btn-danger waves-effect waves-light"
                        style={{ display: HiddenSiguiente3 }}
                      >
                        Siguiente <FontAwesomeIcon icon={faChevronRight} />
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger waves-effect waves-light"
                        style={{ display: HiddenFinalizar }}
                        onClick={() => FuncionActualizarCaso(getCasos)}
                      >
                        Grabar <FontAwesomeIcon icon={faPaperPlane} />
                      </button>
                    </div>
                  </Modal>
                  <Modal size="lg" isOpen={modalFiltro}>
                    <div
                      className="modal-header ModalHeaderColor"
                    >
                      <h5
                        style={{ color: "#fff" }}
                        className="modal-title mt-0"
                        id="myModalLabel"
                      >
                        Seleccione criterio(s) de búsqueda
                      </h5>
                      <button
                        type="button"
                        onClick={() => abrirCerrarModal(4)}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <strong>Fechas de apertura (rango)</strong>
                      <hr style={{ color: "#000", marginTop: "0px" }} />
                      <div className="mb-3 row">
                        <div className="col-md-1" style={{ width: "20px" }}>
                        </div>
                        <div className="col-md-5">
                          <input
                            className="form-control inputDown"
                            type="date" name="FechaInicio" value={getFiltro && getFiltro.FechaInicio} onChange={handleChangeFiltro}
                          />
                          <label className="col-form-label labelDown">
                            Inicio*
                          </label>
                        </div>
                        <div className="col-md-1" style={{ width: "20px" }}>
                        </div>
                        <div className="col-md-5">
                          <input
                            className="form-control inputDown"
                            type="date" name="FechaFin" value={getFiltro && getFiltro.FechaFin} onChange={handleChangeFiltro}
                          />
                          <label className="col-form-label labelDown">
                            Fin*
                          </label>
                        </div>
                      </div>
                      <div className="mb-3 row">
                        <div className="col-md-1" style={{ width: "20px" }}>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckcolor1"
                            checked={chekedValor1}
                            value={chekedValor1}
                            onChange={handleChangeValor1}
                            style={{ transform: "scale(1.5)", marginTop: "10px" }}
                          />
                        </div>
                        <div className="col-md-5">
                          <select
                            className="form-select inputDown"
                            name="nCli_Id" onChange={handleChangeFiltro} disabled={Valor1}
                          >
                            <option value="00">Seleccionar...</option>
                            {dataCliente.map((row, i) => (
                              <option key={i} value={row.nCli_Id}>
                                {row.cCli_NombreCompleto}
                              </option>
                            ))}
                          </select>
                          <label className="col-form-label labelDown">
                            Seleccione cliente
                          </label>
                        </div>
                        <div className="col-md-1" style={{ width: "20px" }}>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckcolor1"
                            checked={chekedValor2}
                            value={chekedValor2}
                            onChange={handleChangeValor2}
                            style={{ transform: "scale(1.5)", marginTop: "10px" }}
                          />
                        </div>
                        <div className="col-md-5">
                          <select
                            className="form-select inputDown"
                            name="cCas_Tab_Materia" onChange={handleChangeFiltro} disabled={Valor2}
                          >
                            <option value="00">Seleccionar...</option>
                            {dataComboMateria.map((row, i) => (
                              <option key={i} value={row.cTabCodigo}>
                                {row.cTabNombre}
                              </option>
                            ))}
                          </select>
                          <label className="col-form-label labelDown">
                            Seleccione materia
                          </label>
                        </div>
                      </div>
                      <div className="mb-3 row">
                        <div className="col-md-1" style={{ width: "20px" }}>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckcolor1"
                            checked={chekedValor3}
                            value={chekedValor3}
                            onChange={handleChangeValor3}
                            style={{ transform: "scale(1.5)", marginTop: "10px" }}
                          />
                        </div>
                        <div className="col-md-5">
                          <select
                            className="form-select inputDown"
                            name="cCas_Tab_SubMateria" onChange={handleChangeFiltro} disabled={Valor3}
                          >
                            <option value="00">Seleccionar...</option>
                            {dataComboSubMateria.map((row, i) => (
                              <option key={i} value={row.cTabCodigo}>
                                {row.cTabNombre}
                              </option>
                            ))}
                          </select>
                          <label className="col-form-label labelDown">
                            Seleccione submateria
                          </label>
                        </div>
                        <div className="col-md-1" style={{ width: "20px" }}>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckcolor1"
                            checked={chekedValor5}
                            value={chekedValor5}
                            onChange={handleChangeValor5}
                            style={{ transform: "scale(1.5)", marginTop: "10px" }}
                          />
                        </div>
                        <div className="col-md-5">
                          <select
                            className="form-select inputDown"
                            name="_nUsu_ID" onChange={handleChangeFiltro} disabled={Valor5}
                          >
                            <option value="00">Seleccionar...</option>
                            {dataComboUsuario.map((row, i) => (
                              <option key={i} value={row.nUsu_ID}>
                                {row.cUsu_Nombres}
                              </option>
                            ))}
                          </select>
                          <label className="col-form-label labelDown">
                            Responsable
                          </label>
                        </div>
                      </div>
                      <div className="mb-3 row">
                        <div className="col-md-1" style={{ width: "20px" }}>
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckcolor1"
                            checked={chekedValor4}
                            value={chekedValor4}
                            onChange={handleChangeValor4}
                            style={{ transform: "scale(1.5)", marginTop: "10px" }}
                          />
                        </div>
                        <div className="col-md-5">
                          <select
                            className="form-select inputDown"
                            name="bCas_Estado" onChange={handleChangeFiltro} disabled={Valor4}
                          >
                            <option value="00">Seleccionar...</option>
                            {dataComboEstadoInterno.map((row, i) => (
                              <option key={i} value={row.cTabCodigo}>
                                {row.cTabNombre}
                              </option>
                            ))}
                          </select>
                          <label className="col-form-label labelDown">
                            Estado interno
                          </label>
                        </div>
                        <div className="col-md-1" style={{ width: "20px" }}>
                        </div>
                        <div className="col-md-5">
                          <select
                            className="form-select inputDown"
                            name="cCli_Tab_TipoDoc"
                          >
                            <option value="00">Seleccionar...</option>
                            {dataComboUsuario.map((row, i) => (
                              <option key={i} value={row.nUsu_ID}>
                                {row.cUsu_Nombres}
                              </option>
                            ))}
                          </select>
                          <label className="col-form-label labelDown">
                            Creado por
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        onClick={() => abrirCerrarModal(4)}
                        className="btn btn-secondary waves-effect"
                        data-dismiss="modal"
                      >
                        Cerrar
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger waves-effect waves-light"
                        onClick={() => FuncionFiltroCasos()}
                      >
                        Filtrar
                      </button>
                    </div>
                  </Modal>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle className="h4" style={{ fontSize: "30px" }}>
                  Listado de Casos{" "}
                </CardTitle>
              </CardBody>
              <div className="mb-3 row">
                <div className="col-md-2">
                  <input
                    type="checkbox"
                    className="checkbox"
                    id="selecionarMasivo"
                    style={{ marginTop: "8px" }}
                    onClick={() => SeleccionarTodo()}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="customCheckcolor1"
                    style={{ marginTop: "8px", paddingLeft: "67px" }}
                  >
                    Marcar todos
                  </label>
                </div>
                <div className="col-md-7">
                  <button
                    type="button"
                    onClick={() => FuncionActivarMasiva()}
                    className="btn btn-danger waves-effect waves-light"
                    data-toggle="modal"
                    data-target="#myModal"
                  >
                    <i style={{ fontSize: "13px" }} className="icon-eliminar"> </i>Desactivar
                  </button>

                </div>
              </div>
              <form name="f1">
                {data.map((row, i) => (
                  <div key={i} style={{ borderBottom: "1px solid #bbb" }}>
                    <input
                      type="checkbox"
                      className="checkbox"
                      name="dinamico"
                      id={"id" + row.nCas_Id}
                      onClick={() => ConsultasMasivas(row.nCas_Id)}
                    />
                    <div className="listado">
                      <div className="titulo Texto-Limit">
                        <Link to="/dashboard-Casos" onClick={() => {

                          setEncryptedItem('CASOIDCli', row.nCli_Id)
                        }} >
                          <a class style={{ color: "black" }} >{row.cCas_Titulo3}</a></Link>
                      </div>
                      <div className="descripcion">
                        <div className="case Texto-Limit">
                          <label className="text-truncate">
                            {" "}
                            {row.nCas_Origen}: {row.cCas_Cod_Interno}
                          </label>
                        </div>
                        <div class>
                          <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="dropdown-toggle">
                              <i className="bx bx-dots-vertical-rounded" />
                            </DropdownToggle>
                            <DropdownMenu right>
                              <DropdownItem onClick={() => {
                                setEncryptedItem('CASOID', row.nCas_Id)
                                setEncryptedItem('CASOIDCli', row.nCli_Id)
                              }
                              }>
                                <Link style={{ color: "black" }} to="/dashboard-Casos">Ver Detalle</Link>
                              </DropdownItem>
                              <DropdownItem>
                                <Link style={{ color: "black" }} to="#" onClick={() => abrirCerrarModal(2, row)}>Editar</Link>
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  Swal.fire({
                                    title: "Mensaje",
                                    html:
                                      "Estas seguro de eliminar el caso: <b>" +
                                      row.cCas_Titulo +
                                      "</b>",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Aceptar",
                                    cancelButtonText: "Cancelar",
                                  }).then(result => {
                                    if (result.isConfirmed) {
                                    }
                                  })
                                }
                              >
                                Eliminar
                              </DropdownItem>
                              <DropdownItem onClick={() => {
                                if (row.nCas_Origen == "Indecopi") {
                                  FuncionSincronizarIndecopi()
                                } else {
                                  FuncionRefrescarCEJ(row.nCas_Id, row.cCas_Cod_Externo)
                                }
                              }}>
                                {(() => {
                                  if (row.nCas_Origen == "Indecopi") {
                                    return (<>Refrescar INDECOPI</>)
                                  } else {
                                    return (<>Refrescar CEJ</>)
                                  }
                                })()}
                              </DropdownItem>
                              <DropdownItem onClick={() =>
                                setEncryptedItem('CASOID', row.nCas_Id)
                              }>
                                <Link style={{ color: "black" }} to="/NuevoModulo">Documentos</Link>
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                      </div>
                      <div className="detalle Texto-Limit">
                        <i style={{ fontSize: "16px" }} className="icon-balanza" />{" "}
                        <label htmlFor="" className="text-detalle">
                          Juzgado: {row.nJuz_Id2}
                        </label>{" "}
                        <i style={{ fontSize: "16px", color: "#c92c48" }} className="icon-Flor-de-Liz" />{" "}
                        <label className="text-detalle" style={{ color: "#c92c48" }} >
                          Cod Externo: {row.cCas_Cod_Externo}
                        </label>
                      </div>
                      <div className="users">
                        {(() => {
                          if (row.cTab_Estado_Interno == "EP03") {
                            return (
                              <i style={{ fontSize: "16px", color: "#AA1B0E" }} className="icon-esfera-2d" />
                            )
                          } else if (row.cTab_Estado_Interno == "EP02") {
                            return (
                              <i style={{ fontSize: "16px", color: "#1F60AB" }} className="icon-esfera-2d" />
                            )
                          } else if (row.cTab_Estado_Interno == "EP01") {
                            return (
                              <i style={{ fontSize: "16px", color: "#032D5E" }} className="icon-esfera-2d" />
                            )
                          } else if (row.cTab_Estado_Interno == "EP04") {
                            return (
                              <i style={{ fontSize: "16px", color: "#00A94F" }} className="icon-esfera-2d" />
                            )
                          }
                        })()}{" "}
                        <label className="text-case">
                          {row.cTab_Estado_Interno2}
                        </label>
                      </div>
                      <div className="detalle Texto-Limit">
                        {(() => {
                          if (row.bCli_Tipo == "1") {
                            return (
                              <i style={{ fontSize: "16px", color: "#1F60AB" }} className="icon-edificio" />
                            )
                          } else if (row.bCli_Tipo == "0") {
                            return (
                              <i style={{ fontSize: "16px", color: "#1F60AB" }} className="icon-Persona" />
                            )
                          }
                        })()}{" "}
                        {" "}
                        <label style={{ color: "#1F60AB" }} className="text-detalle">
                          {row.cCli_NombreCompleto}
                        </label>{" "}
                        <i style={{ fontSize: "16px" }} className="icon-Persona" />{" "}
                        <label className="text-detalle">{row.nUsu_ID2}</label>
                      </div>
                      <div className="active">
                        <i style={{ fontSize: "16px" }} className="icon-calendario" />
                        <label className="text-case">
                          Apertura {row.dCas_Fec_Ini_Interno2}
                        </label>
                      </div>
                    </div>
                  </div>
                ))}
              </form>
            </Card>
          </Col>
        </Row>
        <Row></Row>
      </div>
    </React.Fragment>
  )
}
export default Caso
