import React, { Component, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTags from "react-meta-tags"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import Swal from "sweetalert2"
import {
    CardHeader,
    CardText,
    CardBody,
    Card,
    Col,
    Container,
    CardTitle,
    Button,
    Row,
    Modal,
    Title,
    Text,
    Form,
    FormGroup,
    TabContent,
    TabPane,
    FormText,
    Label,
    Input,
    CustomInput,
    CheckBox,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap"
import instance from '../../config'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faPlus,
    faSearch,
    faTimes,
    faChevronLeft,
    faChevronRight,
    faPaperPlane,
    faBuilding,
    faUser,
    faCalendar,
    faCircle,
    faClipboard,
    faBell,
} from "@fortawesome/free-solid-svg-icons"
import { setSubmitFailed } from "redux-form"
const baseUrl = process.env.REACT_APP_API_URL
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
const Suscriptores = props => {
    const [modalInsertar, setModalInsertar] = useState(false)
    const [modalActualizar, setModalActualizar] = useState(false)
    const [modalFiltro, setModalFiltro] = useState(false)
    const [data, setData] = useState([])
    const [dataCategoria, setDataCategoria] = useState([])
    const [getSus, setSus] = useState({
        nSus_Id: "",
        cSus_Categoria: "",
        cSus_Nombres: "",
        dSus_FechaVencimiento: "",
        dSus_FechaVencimiento2: "",
        dSus_FechaCreacion: "",
        dSus_FechaCreacion2: "",
        dSus_UltimoPago: "",
        dSus_UltimoPago2: "",
        cSus_Correo: "",
        cSus_Pass: "",
        cSus_Telefono: "",
        cSus_Empresa: "",
        cSus_CadenaConexion: "",
        cSus_Cuenta: "",
        bSus_Estado: 1,
    })
    const [getFiltro, setFiltro] = useState({
        FechaInicial: ano + "-" + "01" + "-" + "01",
        FechaFin: ano + "-" + mes + "-" + dia,
    })
    const handleChange = e => {
        const { name, value } = e.target
        setSus(prevState => ({
            ...prevState,
            [name]: value,
        }))

    }
    const handleChangeFiltro = e => {
        const { name, value } = e.target
        setFiltro({
            ...getFiltro,
            [e.target.name]: e.target.value
        })

    }
    const abrirCerrarModal = Modal => {
        if (Modal == 1) {
            if (!modalInsertar == false) {
                Swal.fire({
                    html: "¿Seguro que desea salir sin guardar los datos?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si",
                    cancelButtonText: "No",
                }).then(result => {
                    if (result.isConfirmed) {
                        setModalInsertar(!modalInsertar)
                    }
                })
            } else {
                setModalInsertar(!modalInsertar)
            }
        } else if (Modal == 2) {
            setModalActualizar(!modalActualizar)
        } else if (Modal == 3) {
            setModalFiltro(!modalFiltro)
        }
    }
    const FuncionListarSuscriptores = async () => {
        await instance
            .post("/Suscriptores/Listar")
            .then(response => {
                setData(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionBuscarSuscriptores = async () => {
        var postData = {
            FechaInicial: getFiltro.FechaInicial,
            FechaFin: getFiltro.FechaFin,
        }
        await instance
            .post("/Suscriptores/Buscar", postData)
            .then(response => {
                setData(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionRegistrarSuscriptores = async () => {
        var postData = {
            cSus_Nombres: getSus.cSus_Nombres,
            cSus_Correo: getSus.cSus_Correo,
            cSus_Pass: getSus.cSus_Pass,
            cSus_Telefono: getSus.cSus_Telefono,
            cSus_Empresa: getSus.cSus_Empresa,
            cSus_CadenaConexion: getSus.cSus_CadenaConexion,
            cSus_Cuenta: getSus.cSus_Cuenta,
            cSus_CadenaConexion: getSus.cSus_CadenaConexion,
        }
        await instance
            .post("/Suscriptores/Registrar", postData)
            .then(response => {
                FuncionListarSuscriptores()
                abrirCerrarModal(1)

                Swal.fire({
                    title: "Mensaje",
                    text: "Agregado exitosamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })
            })
            .catch(error => {

            })
    }
    const FuncionActualizarSuscriptores = async () => {
        var postData = {
            nSus_Id: Number.parseInt(getSus.nSus_Id),
            cSus_Categoria: getSus.cSus_Categoria,
            cSus_Nombres: getSus.cSus_Nombres,
            dSus_FechaVencimiento: getSus.dSus_FechaVencimiento,
            dSus_FechaCreacion: getSus.dSus_FechaCreacion,
            dSus_UltimoPago: getSus.dSus_UltimoPago,
            cSus_Correo: getSus.cSus_Correo,
            cSus_Pass: getSus.cSus_Pass,
            cSus_Telefono: getSus.cSus_Telefono,
            cSus_Empresa: getSus.cSus_Empresa,
            cSus_CadenaConexion: getSus.cSus_CadenaConexion,
            cSus_Cuenta: getSus.cSus_Cuenta,
            cSus_CadenaConexion: getSus.cSus_CadenaConexion,
            bSus_Estado: Number.parseInt(getSus.bSus_Estado),
        }
        await instance
            .post("/Suscriptores/Actualizar", postData)
            .then(response => {
                FuncionListarSuscriptores()
                abrirCerrarModal(2)

                Swal.fire({
                    title: "Mensaje",
                    text: "Actualizado exitosamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })
            })
            .catch(error => {

            })
    }
    const FuncionEliminarSuscriptores = async (_nSus_Id) => {
        var postData = {
            nSus_Id: Number.parseInt(_nSus_Id),
        }
        await instance
            .post("/Suscriptores/Eliminar", postData)
            .then(response => {
                FuncionListarSuscriptores()

                Swal.fire({
                    title: "Mensaje",
                    text: "Eliminado exitosamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })
            })
            .catch(error => {

            })
    }
    const Acciones = (Suscriptor) => {
        setSus(Suscriptor)
        abrirCerrarModal(2)
    }
    const FuncionListarCategoria = async () => {
        var postData = {
            Prefijo: "ZD",
        }
        await instance
            .post("/Maestros/Data", postData)
            .then(response => {
                setDataCategoria(response.data)

            })
            .catch(error => {

            })
    }
    const datas = {
        columns: [
            {
                label: "Categoria",
                field: "cSus_Categoria2",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "Nombres",
                field: "cSus_Nombres",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "Creación",
                field: "dSus_FechaCreacion2",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "Vencimiento",
                field: "dSus_FechaVencimiento2",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "Correo",
                field: "cSus_Correo",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "Empresa",
                field: "cSus_Empresa",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "Base de datos",
                field: "cSus_CadenaConexion",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
        ],
        rows: data,
    }
    const AbrirNv = (Url) => {
        window.open("https://www." + Url, '_blank')
    }
    const badgesData = {
        columns: [
            ...datas.columns,
            {
                label: "Cuenta",
                field: "cuenta",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "Estado",
                field: "bSus_Estado2",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "Acciones",
                field: "acciones",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
        ],
        rows: [
            ...datas.rows.map((row, id) => ({
                ...row,
                cuenta: (
                    <div>
                        <Link onClick={() => AbrirNv(row.cSus_Cuenta)}>
                            {row.cSus_Cuenta}
                        </Link>
                    </div>
                ),
                acciones: (
                    <div>
                        <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="dropdown-toggle">
                                <i className="bx bx-dots-vertical-rounded" />
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem
                                    onClick={() =>
                                        Acciones(row)
                                    }>
                                    Editar
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() =>
                                        Swal.fire({
                                            title: "Mensaje",
                                            html:
                                                "Estas seguro de eliminar al cliente : <b>" +
                                                row.cSus_Nombres +
                                                "</b> ?",
                                            icon: "warning",
                                            showCancelButton: true,
                                            confirmButtonColor: "#3085d6",
                                            cancelButtonColor: "#d33",
                                            confirmButtonText: "Aceptar",
                                            cancelButtonText: "Cancelar",
                                        }).then(result => {
                                            if (result.isConfirmed) {

                                                FuncionEliminarSuscriptores(row.nSus_Id)
                                            }
                                        })
                                    }
                                >
                                    Eliminar
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                ),
            })),
        ],
    }
    useEffect(() => {
        FuncionListarSuscriptores()
        FuncionListarCategoria()
    }, [])
    return (
        <React.Fragment>
            <div className="page-content">
                {/* add meta title */}
                <MetaTags>
                    <title>Suscriptores | GpsLegal</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="GpsLegal" breadcrumbItem="Suscriptores" />
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div>
                                        <button
                                            type="button"
                                            onClick={() => abrirCerrarModal(3)}
                                            className="btn btn-danger waves-effect waves-light"
                                            data-toggle="modal"
                                            data-target="#myModal"
                                        >
                                            Filtro
                                        </button>{" "}
                                        <Modal isOpen={modalInsertar}>
                                            <div
                                                className="modal-header ModalHeaderColor"

                                            >
                                                <h5
                                                    style={{ color: "#fff" }}
                                                    className="modal-title mt-0"
                                                    id="myModalLabel"
                                                >
                                                    Registrar Suscriptores
                                                </h5>
                                                <button
                                                    type="button"
                                                    onClick={() => abrirCerrarModal(1)}
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                >
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <Form>
                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup className="mb-2">
                                                                <Label htmlFor="cardnumberInput">
                                                                    Nombre completo
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="cSus_Nombres"
                                                                    placeholder="Ingrese Nombre completo"
                                                                    name="cSus_Nombres" onChange={handleChange}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="12">
                                                            <FormGroup className="mb-2">
                                                                <Label htmlFor="cardnumberInput">
                                                                    Correo electronico
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="cSus_Correo"
                                                                    placeholder="Ingrese Correo electronico"
                                                                    name="cSus_Correo" onChange={handleChange}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="12">
                                                            <FormGroup className="mb-2">
                                                                <Label htmlFor="cardnumberInput">
                                                                    Contraseña
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="cSus_Pass"
                                                                    placeholder="Ingrese Contraseña"
                                                                    name="cSus_Pass" onChange={handleChange}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="cardnumberInput">
                                                                    Nombre de la Empresa
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="cSus_Empresa"
                                                                    placeholder="Ingrese Nombre de la Empresa"
                                                                    name="cSus_Empresa" onChange={handleChange}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="12">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="cardnumberInput">
                                                                    Nombre de la cuenta
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="cSus_Cuenta"
                                                                    placeholder="Ingrese Nombre de la cuenta"
                                                                    name="cSus_Cuenta" onChange={handleChange}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="12">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="cardnumberInput">
                                                                    Telefono
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="cSus_Telefono"
                                                                    placeholder="Ingrese Telefono"
                                                                    name="cSus_Telefono" onChange={handleChange}
                                                                    maxLength={20}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </div>
                                            <div className="modal-footer">
                                                <button
                                                    type="button"
                                                    onClick={() => abrirCerrarModal(1)}
                                                    className="btn btn-secondary waves-effect"
                                                    data-dismiss="modal"
                                                >
                                                    Cerrar
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => FuncionRegistrarSuscriptores()}
                                                    className="btn btn-danger waves-effect waves-light"
                                                >
                                                    Guardar
                                                </button>
                                            </div>
                                        </Modal>
                                        <Modal isOpen={modalActualizar}>
                                            <div
                                                className="modal-header ModalHeaderColor"

                                            >
                                                <h5
                                                    style={{ color: "#fff" }}
                                                    className="modal-title mt-0"
                                                    id="myModalLabel"
                                                >
                                                    Editar Suscriptores
                                                </h5>
                                                <button
                                                    type="button"
                                                    onClick={() => abrirCerrarModal(2)}
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                >
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <Form>
                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup className="mb-2">
                                                                <Label htmlFor="cardnumberInput">
                                                                    Categoria
                                                                </Label>
                                                                <select
                                                                    className="form-select"
                                                                    name="cSus_Categoria" onChange={handleChange}
                                                                    value={getSus && getSus.cSus_Categoria}
                                                                >
                                                                    <option value="0">Seleccionar...</option>
                                                                    {dataCategoria.map((row, i) => (
                                                                        <option key={i} value={row.cTabCodigo}>
                                                                            {row.cTabNombre}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="12">
                                                            <FormGroup className="mb-2">
                                                                <Label htmlFor="cardnumberInput">
                                                                    Nombre completo
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="cSus_Nombres"
                                                                    placeholder="Ingrese Nombre completo"
                                                                    name="cSus_Nombres" onChange={handleChange}
                                                                    value={getSus && getSus.cSus_Nombres}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="12">
                                                            <FormGroup className="mb-2">
                                                                <Label htmlFor="cardnumberInput">
                                                                    Correo electronico
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="cSus_Correo"
                                                                    placeholder="Ingrese Correo electronico"
                                                                    name="cSus_Correo" onChange={handleChange}
                                                                    value={getSus && getSus.cSus_Correo}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="12">
                                                            <FormGroup className="mb-2">
                                                                <Label htmlFor="cardnumberInput">
                                                                    Contraseña
                                                                </Label>
                                                                <Input
                                                                    type="password"
                                                                    className="form-control"
                                                                    id="cSus_Pass"
                                                                    placeholder="Ingrese Contraseña"
                                                                    name="cSus_Pass" onChange={handleChange}
                                                                    value={getSus && getSus.cSus_Pass}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="12">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="cardnumberInput">
                                                                    Nombre de la Empresa
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="cSus_Empresa"
                                                                    placeholder="Ingrese Nombre de la Empresa"
                                                                    name="cSus_Empresa" onChange={handleChange}
                                                                    value={getSus && getSus.cSus_Empresa}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="12">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="cardnumberInput">
                                                                    Nombre de la cuenta
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="cSus_Cuenta"
                                                                    placeholder="Ingrese Nombre de la cuenta"
                                                                    name="cSus_Cuenta" onChange={handleChange}
                                                                    value={getSus && getSus.cSus_Cuenta}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="12">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="cardnumberInput">
                                                                    Telefono
                                                                </Label>
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="cSus_Telefono"
                                                                    placeholder="Ingrese Telefono"
                                                                    name="cSus_Telefono" onChange={handleChange}
                                                                    value={getSus && getSus.cSus_Telefono}
                                                                    maxLength={20}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="12">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="cardnumberInput">
                                                                    Fecha Creación
                                                                </Label>
                                                                <input
                                                                    className="form-control"
                                                                    type="date"
                                                                    onChange={handleChange}
                                                                    name="dSus_FechaCreacion"
                                                                    value={getSus && getSus.dSus_FechaCreacion}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="12">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="cardnumberInput">
                                                                    Fecha Vencimiento
                                                                </Label>
                                                                <input
                                                                    className="form-control"
                                                                    type="date"
                                                                    onChange={handleChange}
                                                                    name="dSus_FechaVencimiento" 
                                                                    value={getSus && getSus.dSus_FechaVencimiento}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="12">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="cardnumberInput">
                                                                    Fecha Ultimo Pago
                                                                </Label>
                                                                <input
                                                                    className="form-control"
                                                                    type="date"
                                                                    onChange={handleChange}
                                                                    name="dSus_UltimoPago"
                                                                    value={getSus && getSus.dSus_UltimoPago}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="12">
                                                            <FormGroup className="mb-2">
                                                                <Label htmlFor="cardnumberInput">
                                                                    Estado
                                                                </Label>
                                                                <select
                                                                    className="form-select"
                                                                    name="bSus_Estado" onChange={handleChange}
                                                                    value={getSus && getSus.bSus_Estado}
                                                                >
                                                                    <option value="">Seleccionar...</option>
                                                                    <option value="1">Vigente</option>
                                                                    <option value="2">Suspendido </option>
                                                                    <option value="0">De baja</option>
                                                                </select>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </div>
                                            <div className="modal-footer">
                                                <button
                                                    type="button"
                                                    onClick={() => abrirCerrarModal(2)}
                                                    className="btn btn-secondary waves-effect"
                                                    data-dismiss="modal"
                                                >
                                                    Cerrar
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => FuncionActualizarSuscriptores()}
                                                    className="btn btn-danger waves-effect waves-light"
                                                >
                                                    Guardar
                                                </button>
                                            </div>
                                        </Modal>
                                        <Modal size="lg" isOpen={modalFiltro}>
                                            <div
                                                className="modal-header ModalHeaderColor"

                                            >
                                                <h5
                                                    style={{ color: "#fff" }}
                                                    className="modal-title mt-0"
                                                    id="myModalLabel"
                                                >
                                                    Seleccione criterio(s) de búsqueda
                                                </h5>
                                                <button
                                                    type="button"
                                                    onClick={() => abrirCerrarModal(3)}
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                >
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <strong>Fechas (rango)</strong>
                                                <hr style={{ color: "#000", marginTop: "0px" }} />
                                                <div className="mb-3 row">
                                                    <div className="col-md-1" style={{ width: "20px" }}></div>
                                                    <div className="col-md-5">
                                                        <input
                                                            className="form-control inputDown"
                                                            type="date" name="FechaInicial" value={getFiltro && getFiltro.FechaInicial} onChange={handleChangeFiltro}
                                                        />
                                                        <label className="col-form-label labelDown">
                                                            Inicio*
                                                        </label>
                                                    </div>
                                                    <div className="col-md-1" style={{ width: "20px" }}></div>
                                                    <div className="col-md-5">
                                                        <input
                                                            className="form-control inputDown"
                                                            type="date" name="FechaFin" value={getFiltro && getFiltro.FechaFin} onChange={handleChangeFiltro}
                                                        />
                                                        <label className="col-form-label labelDown">
                                                            Fin*
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button
                                                    type="button"
                                                    onClick={() => abrirCerrarModal(3)}
                                                    className="btn btn-secondary waves-effect"
                                                    data-dismiss="modal"
                                                >
                                                    Cerrar
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-danger waves-effect waves-light"
                                                    onClick={() => FuncionBuscarSuscriptores()}
                                                >
                                                    Filtrar
                                                </button>
                                            </div>
                                        </Modal>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <CardTitle className="h4" style={{ fontSize: "30px" }}>
                                        Listado de Suscriptores{" "}
                                    </CardTitle>
                                    <p className="card-title-desc">Descripción.</p>
                                    <MDBDataTable
                                        data={badgesData}
                                        responsiveXl
                                        fixed
                                        hover
                                        entriesLabel="Mostrar"
                                        entrylabel={"Mostrar entradas"}
                                        infoLabel={["Mostrando ", "a", "de", "registros"]}
                                        bordered
                                        order={["age", "desc"]}
                                        noRecordsFoundLabel={"No hay datos"}
                                        searchLabel={"Buscar"}
                                        noBottomColumns
                                        paginationLabel={["Anterior", "Siguiente"]}
                                        theadColor="HeadBlue"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default Suscriptores