import React, { Component, useState, useEffect } from "react"

import { MDBDataTable } from "mdbreact"

import {
  Col,
  Container,
  Row,
  Modal,
  Label,
} from "reactstrap"
import MetaTags from "react-meta-tags"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faEye,
  faTrash,
} from "@fortawesome/free-solid-svg-icons"
import { Link, useParams } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
//Dependencia await instance para consumir apis
import instance from '../../config'
import Swal from "sweetalert2"
import { get } from "lodash";
import './style-documento.css';
import { array } from "prop-types";
//Url Principal
const baseUrl = process.env.REACT_APP_API_URL;
const jsonwebtoken = require('jsonwebtoken');
//var param = useParams();

const subirDocumento = props => {
  // creo mis obj para que sean actualiazdos conforme se editen
  var param = useParams();
  var token = null;
  var FuncionGetToken = async () => {

    token = param.body;
    const SECRET_KEY_JWT = "secretkey5353";
    const payload = await jsonwebtoken.verify(token, SECRET_KEY_JWT);
    if (!payload) {
      return res.status(401).send('Unauhtorized Request');
    }
    else {

      setDocumentos(payload.payload.body.dataDocumento)
    }

  }
  const [body, setBody] = useState([])
  const [getDocumentos, setDocumentos] = useState({
    nCas_Id: 0,
    nCdo_Doc_Modo: 0,
    cCdo_Tab_Tipo_Doc: "",
    dCdo_Fecha_Solicito: "",
    cCdo_Archivo: "",
    dCdo_Fech_Entrega: "",
    nCdo_Pendiente: 1,
    cCdo_NotaPendiente: "",
    nCdo_PorAprobar: 0,
    cCdo_NotaPorAprobar: "",
    nCdo_Aprobado: 0,
    dCdo_Fecha_Aprobo: "",
    cCdo_Documento: ""
  })

  const [isSearch, setIsSearch] = useState({
    isClient: false,
    isCase: true
  })
  const [dataEliminar, setDataEliminar] = useState([])
  const [fecha, setFecha] = useState('2021-01-01')
  var today = new Date();
  var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

  const [fechaCarga, setFechaCarga] = useState(date)
  const [dataPendiente, setDataPendiente] = useState([])
  const [dataPorAprobar, setDataPorAprobar] = useState([])
  const [dataAprobado, setDataAprobado] = useState([])
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);

  /*
  
  ----------------------------
  */
  const [dataCases, setDataCases] = useState([])
  const [dataEliminado, setDataEliminado] = useState([])
  const [dataRechazados, setDataRechazados] = useState([])
  const [data, setData] = useState('2021-01-01')
  const [idDoc, setIdDoc] = useState({ id: 0 })
  const [dataCombo, setDataCombo] = useState([])
  const [fruites, setFruites] = useState([
    { id: 1, value: "banana", isChecked: false },
    { id: 2, value: "apple", isChecked: false },
    { id: 3, value: "mango", isChecked: false },
    { id: 4, value: "grap", isChecked: false }
  ])
  const [urlDoc, setUrlDoc] = useState('http://dotnetsa.org:88/api/gpslegal/api/files/')
  //Windows
  //  const[urlDoc,setUrlDoc] = useState('http://localhost/gpslegal/api/files/')
  const [urlImg, setUrlImg] = useState('')
  const [dataCliente, setDataCliente] = useState([])
  const [dataCaso, setDataCaso] = useState([])
  //modal
  const [dataCasoInfo, setDataCasoInfo] = useState([])
  const [modalEliminado, setModalEliminado] = useState(false)
  const [modalImg, setModalImg] = useState(false)
  const [modalInsertarBusqueda, setModalInsertarBusqueda] = useState(false)
  const [modalInsertarArchivo, setModalInsertarArchivo] = useState(true)
  const [Disable, setDisable] = useState(true)
  const [chkbox, setchkBox] = useState(true)
  const [dataSA, setDataSA] = useState({ status: 1, data: { nCdo_Id: 0 } });
  const [name, setName] = useState(false);
  const [xfile, xsetFile] = useState('');
  const send = event => {

  }
  const [getAdjunto, setAdjunto] = useState({
    cCdo_Archivo: "",
    nCdo_Id: 0
  });

  const postFile = async () => {


    if (xfile == '') {
      Swal.fire({
        title: "Mensaje",
        text: "Selecciona un archivo a cargar",
        icon: "info",
        confirmButtonText: "Aceptar",
      })

    } else {

      const formdata = new FormData()
      formdata.append("image", xfile)
      formdata.append("nCdo_Id", getDocumentos.nCdo_Id)


      await instance.post('/File/Upload', formdata)
        .then(response => {
          Swal.fire({
            title: "Mensaje",
            text: "Archivo cargado correctamente",
            icon: "success",
            confirmButtonText: "Aceptar",
          }).then((result) => {
            setModalInsertarArchivo(!modalInsertarArchivo)
            window.open("https://xyz.abc/", "_self", "");
            window.close()
          })
        })
        .catch(error => {

        })
    }
  }
  const LimpiarInputFile = async () => {

    if (xfile == '') {
      Swal.fire({
        title: "Mensaje",
        text: "Selecciona un archivo a cargar para limpiar la carga",
        icon: "info",
        confirmButtonText: "Aceptar",
      })
    } else {
      document.getElementById("file").value = ""
      xsetFile("")
    }
  }
  function encodeImageFileAsURL() {
    var filesSelected = document.getElementById("file").files
    if (filesSelected.length > 0) {
      var fileToLoad = filesSelected[0]
      var fileReader = new FileReader()
      fileReader.onload = function (fileLoadedEvent) {
        var srcData = fileLoadedEvent.target.result

        var newImage = document.createElement("iframe")
        newImage.src = srcData
        newImage.setAttribute("style", "width:100%;height:600px")
        // Create Base64 Object
        var array = srcData.split(",")

        var fileName = document.getElementById("file").value
        var idxDot = fileName.lastIndexOf(".") + 1
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase()
        if (extFile == "rar" || extFile == "zip") {
          const name = "<a>Este archivo no tiene vista previa</a>"
          document.getElementById("dase64_img").innerHTML = name
        } else {
          document.getElementById("dase64_img").innerHTML = newImage.outerHTML
        }
      }
      fileReader.readAsDataURL(fileToLoad)
    }
  }
  const validateFileType = async () => {
    var fileName = document.getElementById("file").value
    var idxDot = fileName.lastIndexOf(".") + 1
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase()
    if (
      extFile == "jpg" ||
      extFile == "jpeg" ||
      extFile == "png" ||
      extFile == "pdf" ||
      extFile == "rar" ||
      extFile == "zip"
    ) {
      var Valor = document.getElementById("cEge_Archivo").files[0].name
      setAdjunto(Valor);
    } else {

      setAdjunto("");
      Swal.fire({
        title: "Mensaje",
        text: "Formato no compatible",
        icon: "error",
        confirmButtonText: "Aceptar",
      })
    }
  }
  //modal
  const abrirCerrarModalArchivoInsertar = async (arr) => {
    //
    if (arr.status == 1) {

      setDataSA(getDocumentos)
      setModalInsertarArchivo(!modalInsertarArchivo)

    }
    /*else {
   setModalInsertarEmpresa(!modalInsertarEmpresa)
 }*/
  }
  const abrirCerrarModalImg = async (arr) => {
    //
    if (xfile == '') {
      Swal.fire({
        title: "Mensaje",
        text: "Selecciona un archivo a cargar",
        icon: "info",
        confirmButtonText: "Aceptar",
      })
    } else {
      if (arr.status == 1) {
        setModalImg(!modalImg)
        encodeImageFileAsURL()
        //


      }
    }
  }

  useEffect(async () => {
    await FuncionGetToken();
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        { }
        <MetaTags>
          <title> Documentos | GpsLegal</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Row>
            <Col className="col-12">

              <Modal isOpen={modalInsertarArchivo}>
                <div
                  className="modal-header ModalHeaderColor"
                >
                  <h5
                    style={{ color: "#fff" }}
                    className="modal-title mt-0"
                    id="myModalLabel"
                  >
                    Subir <span style={{ fontWeight: '600' }}> documento</span>
                  </h5>
                </div>
                <div className="modal-body">

                  <div className="">
                    <Label for="exampleFile">Adjuntar archivo</Label>
                    <br></br>
                    <input
                      className="form-control"
                      type="file"
                      placeholder="Usuario"
                      name="cEge_Archivo"
                      id="file"
                      onChange={(e) => {
                        const file = e.target.files[0]
                        //
                        xsetFile(file);
                        //postFile(file);
                        validateFileType
                      }}
                    />

                    <label className="col-form-label labelDown">
                      <b>Adjuntar máximo 5 MB (PDF, JPG, PNG)</b>
                    </label>
                  </div>
                  <br />
                  <div className="">
                    <button type="button" className="btn btn-info"
                      onClick={() => abrirCerrarModalImg({ status: 1, data: {} })}>
                      <FontAwesomeIcon
                        style={{
                          color: "white",
                          fontSize: "17px",
                          marginTop: "0",
                          cursor: "pointer",
                        }}
                        icon={faEye}
                        className=""
                      />
                      &nbsp;
                      Visualizar

                    </button>
                    &nbsp;
                    &nbsp;
                    <button type="button" className="btn btn-dark"
                      onClick={() => LimpiarInputFile()
                      }
                    >
                      <FontAwesomeIcon
                        style={{
                          color: "white",
                          fontSize: "17px",
                          marginTop: "0",
                          cursor: "pointer",
                        }}
                        className=""
                        icon={faTrash}
                      />&nbsp;
                      Eliminar

                    </button>


                  </div>

                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-danger waves-effect waves-light"
                    onClick={() => {
                      postFile()
                    }}
                  >
                    Guardar <FontAwesomeIcon icon={faPaperPlane} />
                  </button>
                </div>

              </Modal>
              <Modal size="lg" isOpen={modalImg}>
                <div
                  className="modal-header ModalHeaderColor"
                >
                  <h5
                    style={{ color: "#fff" }}
                    className="modal-title mt-0"
                    id="myModalLabel"
                  >
                    Vista Previa
                  </h5>
                  <button
                    type="button"
                    onClick={() => abrirCerrarModalImg({ status: 1, data: {} })}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div id="dase64_img"></div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={() => abrirCerrarModalImg({ status: 1, data: {} })}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                  >
                    Cerrar
                  </button>
                </div>
              </Modal>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}
export default subirDocumento


