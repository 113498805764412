import React, { Component, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTags from "react-meta-tags"
import { MDBDataTable } from "mdbreact"
import "../../assets/scss/tablescss/FormatTable.scss"
import Swal from "sweetalert2"
import { getDecryptedItem, setEncryptedItem } from '../../util';
import {
    CardHeader,
    CardText,
    CardBody,
    Card,
    Col,
    Container,
    CardTitle,
    Button,
    Row,
    Modal,
    Title,
    Text,
    Form,
    FormGroup,
    TabContent,
    TabPane,
    FormText,
    Label,
    Input,
    CustomInput,
    CheckBox,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap"
import instance from '../../config'
import { post } from "helpers/api_helper"

const baseUrl = process.env.REACT_APP_API_URL
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
const Suscriptores = props => {
    const [modalInsertar, setModalInsertar] = useState(false)
    const [modalActualizar, setModalActualizar] = useState(false)
    const [modalFiltro, setModalFiltro] = useState(false)
    const [data, setData] = useState([
        { 'NombreFlujo': 'Proceso de Conocimiento', 'Descripcion': 'Proceso mas completo de larga duración y complejidad', 'Fases': '8', 'Eliminar': 'x' },
        { 'NombreFlujo': 'Proceso Abreviado ', 'Descripcion': 'Proceso mas corto', 'Fases': '6', 'Eliminar': 'x' },
        { 'NombreFlujo': 'Proceso Sumarísimo ', 'Descripcion': 'Proceso mas rápido', 'Fases': '3', 'Eliminar': 'x' },
        { 'NombreFlujo': 'Proceso Cautelar  ', 'Descripcion': 'Proceso para asegurar la sentencia', 'Fases': '1', 'Eliminar': 'x' },
        { 'NombreFlujo': 'Proceso de Ejecución', 'Descripcion': 'Proceso una vez se tiene sentencia firme', 'Fases': '2', 'Eliminar': 'x' },
    ])
    const [dataPlan, setDataPlan] = useState([])
    const [dataCategoria, setDataCategoria] = useState([])
    const [dataFormaPago, setDataFormaPago] = useState([])
    const [dataCanal, setDataCanal] = useState([])
    const [dataTipoPlan, setTipoPlan] = useState([])
    const [subTitulo, setSubTitulo] = useState([])
    const [hiddenBtnEditar, setHiddenBtnEditar] = useState(true)
    const [hiddenBtnEliminar, setHiddenBtnEliminar] = useState(true)

    const [getSus, setSus] = useState({
        nSus_Id: "",
        nPla_ID: "",
        nPro_ID: "",
        cSus_Codigo: "",
        cSus_Categoria: "",
        cSus_Nombres: "",
        dSus_FechaVencimiento: "",
        dSus_FechaVencimiento2: "",
        dSus_FechaCreacion: "",
        dSus_FechaCreacion2: "",
        dSus_UltimoPago: "",
        dSus_UltimoPago2: "",
        cSus_Correo: "",
        cSus_Pass: "",
        cSus_Telefono: "",
        cSus_Empresa: "",
        cSus_CadenaConexion: "",
        cSus_Cuenta: "",
        bSus_Estado: 1,
        bSus_Visto: 1,
        cSus_Fac_Pais: "",
        cSus_Fac_PrimerNombre: "",
        cSus_Fac_Apellido: "",
        cSus_Fac_Correo: "",
        cSus_Fac_Compania: "",
        cSus_Fac_Telefono: "",
        cSus_Fac_DireccionL1: "",
        cSus_Fac_DireccionL2: "",
        cSus_Fac_DireccionL3: "",
        cSus_Fac_Ciudad: "",
        cSus_Fac_Departamento: "",
        cSus_Fac_CodigoPostal: "",
        nSus_Fac_Plan: "",
        cSus_Fac_Canal: "",
        cSus_Fac_tab_FormaPago: "",
        cSus_Fac_Ruc: "",
        dSus_Fac_ProxPago: "",
        dSus_Fac_ProxPago2: ""
    })
    const [dataFlujos, setDataFlujo] = useState([])
    const [getFiltro, setFiltro] = useState({
        FechaInicial: ano + "-" + "01" + "-" + "01",
        FechaFin: ano + "-" + mes + "-" + dia,
    })

    const handleChangeFiltro = e => {
        const { name, value } = e.target
        setFiltro({
            ...getFiltro,
            [e.target.name]: e.target.value
        })

    }
    const abrirCerrarModal = Modal => {
        if (Modal == 1) {
            if (!modalInsertar == false) {
                Swal.fire({
                    html: "¿Seguro que desea salir sin guardar los datos?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si",
                    cancelButtonText: "No",
                }).then(result => {
                    if (result.isConfirmed) {
                        setModalInsertar(!modalInsertar)
                    }
                })
            } else {
                setModalInsertar(!modalInsertar)
            }
        } else if (Modal == 2) {
            setModalActualizar(!modalActualizar)
        } else if (Modal == 3) {
            setModalFiltro(!modalFiltro)
        }
    }
    const FuncionListarSuscriptores = async () => {
        await instance
            .post("/Suscriptores/Listar")
            .then(response => {
                setData(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionBuscarSuscriptores = async () => {
        var postData = {
            FechaInicial: getFiltro.FechaInicial,
            FechaFin: getFiltro.FechaFin,
        }
        await instance
            .post("/Suscriptores/Buscar", postData)
            .then(response => {
                setData(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionRegistrarSuscriptores = async () => {
        var postData = {
            cSus_Nombres: getSus.cSus_Nombres,
            cSus_Correo: getSus.cSus_Correo,
            cSus_Pass: getSus.cSus_Pass,
            cSus_Telefono: getSus.cSus_Telefono,
            cSus_Empresa: getSus.cSus_Empresa,
            cSus_CadenaConexion: getSus.cSus_CadenaConexion,
            cSus_Cuenta: getSus.cSus_Cuenta,
            cSus_CadenaConexion: getSus.cSus_CadenaConexion,
        }

        await instance
            .post("/Suscriptores/Registrar", postData)
            .then(response => {
                FuncionListarSuscriptores()
                abrirCerrarModal(1)

                Swal.fire({
                    title: "Mensaje",
                    text: "Agregado exitosamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })
            })
            .catch(error => {

            })
    }
    const FuncionBuscarPlan_PorTipoPlan = async (nPro_ID) => {
        var postData = {
            nPro_ID: nPro_ID
        }
        await instance
            .post("/Plan/PlanesBuscar_porTipoPlan", postData)
            .then(response => {

                setDataPlan(response.data)

            })
            .catch(error => {

            })
    }

    const handleChange = e => {
        const { name, value } = e.target
        setSus(prevState => ({
            ...prevState,
            [name]: value,
        }))
        if (name == "nPro_ID") {

            FuncionBuscarPlan_PorTipoPlan(value);
        }

    }
    const FuncionActualizarSuscriptores = async () => {
        var postData = {
            nSus_Id: getSus.nSus_Id,
            nPla_ID: getSus.nPla_ID,
            nPro_ID: getSus.nPro_ID,
            cSus_Categoria: getSus.cSus_Categoria,
            cSus_Nombres: getSus.cSus_Nombres,
            cSus_Correo: getSus.cSus_Correo,
            cSus_Pass: getSus.cSus_Pass,
            cSus_Telefono: getSus.cSus_Telefono,
            cSus_Empresa: getSus.cSus_Empresa,
            cSus_CadenaConexion: getSus.cSus_CadenaConexion,
            cSus_Cuenta: getSus.cSus_Cuenta,
            bSus_Estado: Number.parseInt(getSus.bSus_Estado),
            bSus_Visto: getSus.bSus_Visto,
            dSus_FechaVencimiento: getSus.dSus_FechaVencimiento,
            dSus_FechaCreacion: getSus.dSus_FechaCreacion,
            dSus_UltimoPago: getSus.dSus_UltimoPago,
            cSus_Fac_Pais: getSus.cSus_Fac_Pais,
            cSus_Fac_PrimerNombre: getSus.cSus_Fac_PrimerNombre,
            cSus_Fac_Apellido: getSus.cSus_Fac_Apellido,
            cSus_Fac_Correo: getSus.cSus_Fac_Correo,
            cSus_Fac_Compania: getSus.cSus_Fac_Compania,
            cSus_Fac_Telefono: getSus.cSus_Fac_Telefono,
            cSus_Fac_DireccionL1: getSus.cSus_Fac_DireccionL1,
            cSus_Fac_DireccionL2: getSus.cSus_Fac_DireccionL2,
            cSus_Fac_DireccionL3: getSus.cSus_Fac_DireccionL3,
            cSus_Fac_Ciudad: getSus.cSus_Fac_Ciudad,
            cSus_Fac_Departamento: getSus.cSus_Fac_Departamento,
            cSus_Fac_CodigoPostal: getSus.cSus_Fac_CodigoPostal,
            nSus_Fac_Plan: getSus.nSus_Fac_Plan,
            cSus_Fac_Canal: getSus.cSus_Fac_Canal,
            cSus_Fac_tab_FormaPago: getSus.cSus_Fac_tab_FormaPago,
            cSus_Fac_Ruc: getSus.cSus_Fac_Ruc,
            dSus_Fac_ProxPago: getSus.dSus_Fac_ProxPago,
        }

        await instance
            .post("/Suscriptores/Actualizar", postData)
            .then(response => {
                FuncionListarSuscriptores()
                abrirCerrarModal(2)

                Swal.fire({
                    title: "Mensaje",
                    text: "Actualizado exitosamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })
            })
            .catch(error => {

            })
    }
    const FuncionEliminarSuscriptores = async (_nSus_Id) => {
        var postData = {
            nSus_Id: Number.parseInt(_nSus_Id),
        }
        await instance
            .post("/Suscriptores/Eliminar", postData)
            .then(response => {
                FuncionListarSuscriptores()

                Swal.fire({
                    title: "Mensaje",
                    text: "Eliminado exitosamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })
            })
            .catch(error => {

            })
    }
    const Acciones = (flujo, opc) => {

        if (opc == 1) {
            setEncryptedItem('nfFlu_Id', flujo.nfFlu_Id);
            location.href = "/FlujosDetalle";
        }
        else if (opc == 2) {
            setEncryptedItem('nfFlu_Id', flujo.nfFlu_Id);
            location.href = "/FlujoNuevo";
        }


    }
    const FuncionListarCategoria = async () => {
        var postData = {
            Prefijo: "ZD",
        }
        await instance
            .post("/Maestros/Data", postData)
            .then(response => {
                setDataCategoria(response.data)

            })
            .catch(error => {

            })
    }
    const datas = {
        columns: [

            {
                label: "Nombre del Flujo de Trabajo",
                field: "cfFlu_Nombre",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "Evento",
                field: "cfFlu_Descripcion",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },

            {
                label: "Descripción",
                field: "cfFlu_Descripcion2",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "Fases",
                field: "Cantidad",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
        ],
        rows: dataFlujos,
    }
    const AbrirNv = (Url) => {
        window.open(Url, '_blank')
    }
    const badgesData = {
        columns: [
            ...datas.columns,
            {
                label: "Acciones",
                field: "acciones",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
        ],
        rows: [
            ...datas.rows.map((row, id) => ({
                ...row,
                cuenta: (
                    <div>
                        <Link onClick={() => AbrirNv(row.cSus_Cuenta)}>
                            {row.cSus_Cuenta}
                        </Link>
                    </div>
                ),
                acciones: (
                    <div>
                        <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="dropdown-toggle">
                                <i className="bx bx-dots-vertical-rounded" />
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => Acciones(row, 1)}>
                                    <Link>
                                        Fases
                                    </Link>
                                </DropdownItem>
                                <DropdownItem onClick={() => Acciones(row, 2)} hidden={hiddenBtnEditar}>
                                    <Link>
                                        Editar
                                    </Link>
                                </DropdownItem>
                                <DropdownItem
                                    hidden={hiddenBtnEliminar}
                                    onClick={() =>
                                        Swal.fire({
                                            title: "Mensaje",
                                            html:
                                                "¿Esta seguro de eliminar el flujo?",
                                            icon: "warning",
                                            showCancelButton: true,
                                            confirmButtonColor: "#3085d6",
                                            cancelButtonColor: "#d33",
                                            confirmButtonText: "Aceptar",
                                            cancelButtonText: "Cancelar",
                                        }).then(result => {
                                            if (result.isConfirmed) {
                                                FuncionEliminarFlujo(row.nfFlu_Id)
                                            }
                                        })
                                    }
                                >
                                    Eliminar
                                </DropdownItem>

                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                ),
            })),
        ],
    }
    const FuncionFormaDePago = async () => {
        var postData = {
            Prefijo: "BD",
        }
        await instance
            .post("/Maestros/Data", postData)
            .then(response => {
                setDataFormaPago(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionListarCanal = async () => {
        var postData = {
            Prefijo: "AD",
        }
        await instance
            .post("/Maestros/Data", postData)
            .then(response => {
                setDataCanal(response.data)

            })
            .catch(error => {

            })
    }

    const FuncionListarFlujos = async () => {
        await instance
            .post("/Flujo/Listar")
            .then(response => {
                setDataFlujo(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionEliminarFlujo = async (_nfFlu_Id) => {
        var postData = {
            nfFlu_Id: parseInt(_nfFlu_Id)
        }

        await instance
            .post("/Flujo/FlujoEliminar", postData)
            .then(response => {

                FuncionListarFlujos();

            })
            .catch(error => {

            })
    }

    const FuncionListarPermisos = async () => {
        var postData = {
          nUsu_ID: parseInt(getDecryptedItem("RolID"))
        }
        await instance
          .post("/Usuario/Acceso", postData)
          .then(response => {
            let arrayPermisos = response.data;
            const permiso = arrayPermisos.find(element => element.cUsu_Modulo == "ME03");
    
            if (permiso.bUsu_Editar == 1) {
              setHiddenBtnEditar(false);
              setModo(1);
            }
            else {
              setHiddenBtnEditar(true);
              setModo(2);
            }
            if (permiso.bUsu_Eliminar == 1) {
              setHiddenBtnEliminar(false);
            }
            else {
              setHiddenBtnEliminar(true);
            }
          })
          .catch(error => {
    
          })
      }

    useEffect(() => {
        FuncionListarPermisos();
        FuncionListarFlujos();

        FuncionListarCanal();
        FuncionFormaDePago();
        //FuncionListarSuscriptores()
        FuncionListarCategoria()
    }, [])
    return (
        <React.Fragment>
            <div className="page-content">
                {/* add meta title */}
                <Breadcrumbs title="" breadcrumbItem="" />
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Row>
                        <Col className="col-12 row" style={{ paddingLeft: '32px' }}>
                            <Col className="col-10">

                                <CardTitle style={{ fontSize: "32px", color: "#aa1b0E", fontWeight: "normal" }}>
                                    <span style={{ color: "#aa1b0E" }}>Flujos de Trabajo</span>{" "}
                                </CardTitle>
                            </Col>
                            <Col className="col-2" >
                                <CardBody style={{ marginRight: '5px' }} hidden={hiddenBtnEditar}>
                                    <div style={{ textAlign: 'right' }}>
                                        <Link
                                            style={{ width: '150px', toptop: '-100px' }}
                                            className="btn btn-danger waves-effect waves-light"
                                            to="/FlujoNuevo" onClick={() => localStorage.removeItem('nfFlu_Id')}>
                                            Añadir flujo
                                        </Link>

                                    </div>
                                </CardBody>
                            </Col>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>

                                    <MDBDataTable
                                        data={badgesData}
                                        className="TablaFlujos"
                                        responsiveXl
                                        fixed
                                        hover
                                        entriesLabel="Mostrar"
                                        entrylabel={"Mostrar entradas"}
                                        infoLabel={["Mostrando ", "a", "de", "registros"]}
                                        bordered
                                        order={["age", "desc"]}
                                        noRecordsFoundLabel={"No hay datos"}
                                        searchLabel={"Buscar"}
                                        noBottomColumns
                                        paginationLabel={["Anterior", "Siguiente"]}
                                        theadColor="HeadBlue"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default Suscriptores