import React, { Component, useState, useEffect } from "react"
import { MDBDataTable } from "mdbreact"
import {
  CardBody,
  Card,
  Col,
  Container,
  CardTitle,
  Button,
  Row,
  Modal,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap"


import { getDecryptedItem } from '../../util';
import ReactHTMLTableToExcel from 'react-html-table-to-excel'
import MetaTags from "react-meta-tags"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/tablescss/FormatTable.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPlus,
  faSearch,
  faTimes,
  faChevronLeft,
  faChevronRight,
  faPaperPlane,
  faBuilding,
  faUser,
  faCalendar,
  faCircle,
  faClipboard,
  faMapMarkedAlt,
  faDownload,
  faEye,
  faTrash,
  faPaperclip,
} from "@fortawesome/free-solid-svg-icons"
import Swal from "sweetalert2"
import instance from '../../config'
import ModalEgreso from "pages/Componentes/ModalEgreso"
const baseUrl = process.env.REACT_APP_API_URL
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
const Egresos = props => {
  const [modalVer, setModalVer] = useState(false)
  const [modalInsertar, setModalInsertar] = useState(false)
  const [modalActualizar, setModalActualizar] = useState(false)
  const [modalEliminar, setModalEliminar] = useState(false)
  const [modalFiltro, setModalFiltro] = useState(false)
  const [modalImg, setModalImg] = useState(false)
  const [dataCasoInfo, setDataCasoInfo] = useState([])
  const [data, setData] = useState([])
  const [dataCliente, setDataCliente] = useState([])
  const [dataComboMoneda, setDataComboMoneda] = useState([])
  const [dataComboTEgreso, setDataComboTEgreso] = useState([])
  const [dataComboMPago, setDataComboMPago] = useState([])
  const [dataCaso, setDataCaso] = useState([])
  const [dataComboUsuario, setDataComboUsuario] = useState([])
  const [dataComboCuenta, setDataComboCuenta] = useState([])
  const [validData, setValidData] = useState(false)
  const [hiddenBtnEditar, setHiddenBtnEditar] = useState(true)
  const [hiddenBtnEliminar, setHiddenBtnEliminar] = useState(true)
  const [getImagenes, setImagenes] = useState("")
  const [disableTpoCambio, setDisableTpoCambio] = useState(true)
  const [getErrores, setErrores] = useState({});
  const [modo, setModo] = useState(0)
  const [getUrlFile, setUrlFile] = useState("")
  const [getEgreso, setEgreso] = useState({
    nEge_Id: "",
    nCli_Id: "",
    nCas_Id: "",
    nCue_Id: "",
    cCas_Cod_Externo: "",
    cCas_Cod_Interno: "",
    cEge_Tab_Tipo: "",
    cEge_Tab_FormaPago: "",
    cIng_Tab_Moneda: "MO01",
    dEge_TCambio: 0,
    dEge_TCambioFormat: 0,
    dEge_TCambioOld: 0,
    nEge_Monto: 0,
    nEge_MontoFormat: 0,
    nEge_MontoOld: 0,
    cEge_Detalle: "",
    nUsu_ID: "",
    cEge_Archivo: "",
    bEge_Estado: "1",
    dEge_Fecha: ano + "-" + mes + "-" + dia,
  })
  const [getFiltro, setFiltro] = useState({
    nCas_Id: null,
    cIng_Tab_Moneda: null,
    cEge_Tab_Tipo: null,
    FechaInicial: ano + "-" + "01" + "-" + "01",
    FechaFin: ano + "-" + mes + "-" + dia,
    nCli_Id: null,
  })
  const [chekedValor1, setchekedValor1] = useState(false)
  const [chekedValor2, setchekedValor2] = useState(false)
  const [chekedValor3, setchekedValor3] = useState(false)
  const [chekedValor4, setchekedValor4] = useState(false)
  const [Valor1, setValor1] = useState(true)
  const [Valor2, setValor2] = useState(true)
  const [Valor3, setValor3] = useState(true)
  const [Valor4, setValor4] = useState(true)
  const [hiddenCliente, setHiddenCliente] = useState(false)
  const Validacion = (form) => {

    let errors = {};
    let regexLetras = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    let regexCorreo = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    let regexNumero = /^[0-9]+$/;

    if (form.nCas_Id == 0) {
      errors.nCas_Id = "(*)"
    }
    if (form.nCue_Id == 0) {
      errors.nCue_Id = "(*)"
    }
    if (form.cEge_Tab_FormaPago == 0) {
      errors.cEge_Tab_FormaPago = "(*)"
    }
    if (form.cIng_Tab_Moneda == 0) {
      errors.cIng_Tab_Moneda = "(*)"
    }



    if (disableTpoCambio == false) {

      if (form.dEge_TCambio == 0) {
        errors.dEge_TCambio = "(*)"
      }
    }
    if (form.nEge_Monto == 0) {
      errors.nEge_Monto = "(*)"
    }
    if (form.cEge_Tab_Tipo == 0) {
      errors.cEge_Tab_Tipo = "(*)"
    }
    if (form.cTabCodigo == 0) {
      errors.cTabCodigo = "(*)"
    }
    if (form.nUsu_ID == 0 || form.nUsu_ID == "" || form.nUsu_ID == "0") {
      errors.nUsu_ID = "(*)"
    }

    return errors;
  }
  const handleChangeFiltro = e => {
    if (chekedValor1 == true || chekedValor2 == true || chekedValor3 == true || chekedValor4 == true) {


      setFiltro({
        ...getFiltro,
        [e.target.name]: e.target.value
      })

      if (e.target.name == "nCli_Id") {
        FuncionListarCasos(e.target.value)
      }

    } else {


      setFiltro({
        ...getFiltro,
        [e.target.name]: e.target.value
      })

    }
  }
  const handleChangeValor1 = e => {
    setchekedValor1(!chekedValor1)
    if (chekedValor1 == true) {
      setFiltro({
        ...getFiltro,
        nCli_Id: null
      })
      setValor1(true)
    } else {
      var Valor = getFiltro.nCli_Id

      setFiltro({
        ...getFiltro,
        nCli_Id: Valor
      })
      setValor1(false)
    }
  }
  const handleChangeValor2 = e => {
    setchekedValor2(!chekedValor2)
    if (chekedValor2 == true) {
      setFiltro({
        ...getFiltro,
        nCas_Id: null
      })
      setValor2(true)
    } else {
      var Valor = getFiltro.nCas_Id

      setFiltro({
        ...getFiltro,
        nCas_Id: Valor
      })
      setValor2(false)
    }
  }
  const handleChangeValor3 = e => {
    setchekedValor3(!chekedValor3)
    if (chekedValor3 == true) {
      setFiltro({
        ...getFiltro,
        cIng_Tab_Moneda: null
      })
      setValor3(true)
    } else {
      var Valor = getFiltro.cIng_Tab_Moneda

      setFiltro({
        ...getFiltro,
        cIng_Tab_Moneda: Valor
      })
      setValor3(false)
    }
  }
  const handleChangeValor4 = e => {
    setchekedValor4(!chekedValor4)
    if (chekedValor4 == true) {
      setFiltro({
        ...getFiltro,
        cEge_Tab_Tipo: null
      })
      setValor4(true)
    } else {
      var Valor = getFiltro.cEge_Tab_Tipo

      setFiltro({
        ...getFiltro,
        cEge_Tab_Tipo: Valor
      })
      setValor4(false)
    }
  }
  const handleBlur = e => {
    handleChange(e)
    setErrores(Validacion(getEgreso))

  }
  const BluerChange = e => {
    const { name, value } = e.target

    if (name == "nEge_Monto") {
      setEgreso(prevState => ({
        ...prevState,
        nEge_MontoFormat: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(value),
        nEge_Monto: value,
        nEge_MontoOld: value,
      }))
    } else if (name == "dEge_TCambio") {
      setEgreso(prevState => ({
        ...prevState,
        dEge_TCambioFormat: new Intl.NumberFormat("en-US", { minimumFractionDigits: 3, }).format(value),
        dEge_TCambio: value,
        dEge_TCambioOld: value,
      }))
    }

  }
  const FocusChange = e => {
    const { name, value } = e.target
    if (name == "nEge_Monto") {
      setEgreso(prevState => ({
        ...prevState,
        [name]: getEgreso.nEge_MontoOld,
      }));
    } else if (name == "dEge_TCambio") {
      setEgreso(prevState => ({
        ...prevState,
        [name]: getEgreso.dEge_TCambioOld,
      }))
    }
  }
  const handleChange = e => {
    const { name, value } = e.target

    if (name == "nEge_Monto") {
      setEgreso(prevState => ({
        ...prevState,
        nEge_Monto: value,
        nEge_MontoOld: value,
        nEge_MontoFormat: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(value),
      }))
    } else if (name == "dEge_TCambio") {
      setEgreso(prevState => ({
        ...prevState,
        dEge_TCambio: value,
        dEge_TCambioOld: value,
        dEge_TCambioFormat: new Intl.NumberFormat("en-US", { key: "en-US", value: "English (US)", minimumFractionDigits: 3, }).format(value),
      }))
      setErrores(Validacion(getEgreso))
    } else {
      setEgreso(prevState => ({
        ...prevState,
        [name]: value,
      }))
      if (name == "cIng_Tab_Moneda") {
        if (value !== "MO01" && value !== "MO07" && value !== "0") {
          setDisableTpoCambio(false);
        } else {
          setDisableTpoCambio(true);
          setEgreso(prevState => ({
            ...prevState,
            dEge_TCambio: 0.000,
            dEge_TCambioOld: 0.000,
            dEge_TCambioFormat: 0.000,
          }))
        }
      }
    }
    if (e.target.name == "nCas_Id") {
      FuncionInformacionCaso(e.target.value)
    }
  }
  const FuncionListarCliente = async () => {
    let RolId = getDecryptedItem("RolID")

    var postData = {
      bCli_Tipo: null,
      nUsu_ID: RolId == 6 || RolId == 1 ? null : getDecryptedItem("ID")
    }
    await instance
      .post("/Cliente/ListarCbo", postData)
      .then(response => {
        setDataCliente(response.data)

      })
      .catch(error => {

      })
  }
  const FuncionBuscarEgresos = async () => {
    var postData = {
      nCli_Id: getDecryptedItem("CLIID") || parseInt(getFiltro.nCli_Id),
      nCas_Id: parseInt(getFiltro.nCas_Id),
      cIng_Tab_Moneda: getFiltro.cIng_Tab_Moneda,
      cEge_Tab_Tipo: getFiltro.cEge_Tab_Tipo,
      FechaInicial: getFiltro.FechaInicial,
      FechaFin: getFiltro.FechaFin,
    }
    await instance
      .post("/Egreso/Buscar", postData)
      .then(response => {
        setData(response.data)
        setModalFiltro(!modalFiltro)

      })
      .catch(error => {

      })
  }

  const FuncionListarCasos = async (_idClient) => {
    var postData = {
      idClient: _idClient,
      nUsu_Id: getDecryptedItem("ID"),
    }
    await instance
      .post("/Casos/ListarCbo", postData)
      .then(response => {
        setDataCaso(response.data)
      })
      .catch(error => {

      })
  }

  const FuncionListarEgresos = async () => {
    let RolId = getDecryptedItem("RolID")
    var postData = {
      nUsu_ID: RolId == 6 || RolId == 1 ? null : getDecryptedItem("ID")
    }
    await instance
      .post("/Egresos/Listar", postData)
      .then(response => {
        console.log(response.data);
        setData(response.data)


      })
      .catch(error => {

      })
  }
  const FuncionListarComboMoneda = async () => {
    var postData = {
      Prefijo: "MO",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataComboMoneda(response.data)

      })
      .catch(error => {

      })
  }
  const FuncionListarComboTEgreso = async () => {
    var postData = {
      Prefijo: "NA",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataComboTEgreso(response.data)

      })
      .catch(error => {

      })
  }
  const FuncionListarComboMetodoPago = async () => {
    var postData = {
      Prefijo: "FP",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataComboMPago(response.data)

      })
      .catch(error => {

      })
  }
  const FuncionListarComboUsuario = async (nCas_Id) => {

    var postData = {
      nCas_Id: nCas_Id,
    }
    await instance
      .post("/Casos/FiltroUsuarioCaso", postData)
      .then(response => {

        setDataComboUsuario(response.data)
      })
      .catch(error => {

      })
  }

  const FuncionValidarFormulario = async () => {

    if (Object.keys(getErrores).length === 0) {

      FuncionActualizarEgreso()
    } else {
      setValidData(true);
      Swal.fire({
        title: "Mensaje",
        text: "Complete los campos requeridos",
        icon: "error",
        confirmButtonText: "Aceptar",
      })
    }
  }
  const FuncionActualizarEgreso = async () => {

    var postData = {
      nEge_Id: Number.parseInt(getEgreso.nEge_Id),
      nCas_Id: Number.parseInt(getEgreso.nCas_Id),
      nCue_Id: Number.parseInt(getEgreso.nCue_Id),
      cEge_Tab_Tipo: getEgreso.cEge_Tab_Tipo,
      cIng_Tab_Moneda: getEgreso.cIng_Tab_Moneda,
      dEge_Fecha: getEgreso.dEge_Fecha,
      cEge_Tab_FormaPago: getEgreso.cEge_Tab_FormaPago,
      dEge_TCambio: parseFloat(getEgreso.dEge_TCambioOld),
      nEge_Monto: parseFloat(getEgreso.nEge_MontoOld),
      cEge_Detalle: getEgreso.cEge_Detalle,
      nUsu_ID: getEgreso.nUsu_ID,
      cEge_Archivo: getEgreso.cEge_Archivo,
      cEge_Num_Ope: '0001',
      bEge_Estado: getEgreso.bEge_Estado,
    }


    // getEgreso.nEge_MontoOld === undefined ? postData.nEge_Monto = parseFloat(getEgreso.nEge_Monto) : null;
    // postData.cIng_Tab_Moneda == "MO01" ? postData.dEge_TCambio = 0 : null;

    await instance
      .post("/Egresos/Actualizar", postData)
      .then(async response => {
        let postData2 = {
          cAud_nombre: "Egresos",
          nAud_IdReg: postData.nEge_Id,
          nUsu_ID: getDecryptedItem("ID"),
          dAud_Fecha: null,
          nAud_Accion: "U",
          cTabCodigo: 'ME07'
        }
        await instance
          .post("/Auditoria/Insertar", postData2)
          .then(response => {
          });

        abrirCerrarModal(2)
        Swal.fire({
          title: "Mensaje",
          text: "Egreso actualizado exitosamente!",
          icon: "success",
          confirmButtonText: "Aceptar",
        })
        FuncionListarEgresos()
      })
      .catch(error => {

      })
  }

  const FuncionEliminarEgreso = async nEge_Id => {
    var postData = {
      nEge_Id: Number.parseInt(nEge_Id),
    }
    await instance
      .post("/Egresos/Eliminar", postData)
      .then(async response => {
        let postData2 = {
          cAud_nombre: "Egresos",
          nAud_IdReg: postData.nEge_Id,
          nUsu_ID: getDecryptedItem("ID"),
          dAud_Fecha: null,
          nAud_Accion: "D",
          cTabCodigo: 'ME07'
        }
        await instance
          .post("/Auditoria/Insertar", postData2)
          .then(response => {
          });

        FuncionListarEgresos()
        Swal.fire({
          title: "Egresos ",
          text: "Egreso eliminado exitosamente!",
          icon: "success",
          confirmButtonText: "Aceptar",
        })
      })
      .catch(error => {

      })
  }
  const FuncionListarComboCuenta = async () => {
    await instance
      .get("/CBanco/Listar/Combo")
      .then(response => {
        setDataComboCuenta(response.data)

      })
      .catch(error => {

      })
  }
  const datas = {
    columns: [
      {
        label: "Cliente",
        field: "cCli_NombreCompleto",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Caso",
        field: "cCas_Titulo",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Fecha",
        field: "dEge_Fecha2",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Motivo",
        field: "cEge_Tab_Tipo2",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Descripción",
        field: "cEge_Detalle",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
          width: "30%"
        },
      },
      {
        label: "Archivo",
        field: "archivo",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
          width: "1%"
        },
      },
      {
        label: "Monto",
        field: "nEge_Monto2",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
          width: "14%",
        },
      },
    ],
    rows: data,
  }
  const abrirCerrarModal = (Modal, row) => {
    if (Modal == 1) {
      if (!modalInsertar == false) {
        Swal.fire({
          html: "¿Seguro que desea salir sin guardar los datos?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si",
          cancelButtonText: "No",
        }).then(result => {
          if (result.isConfirmed) {
            setModalInsertar(!modalInsertar)
          }
        })
      } else {
        setModo(0);
        setModalInsertar(!modalInsertar)
      }
    } else if (Modal == 2) {
      console.log("actualizar");
      setModo(1);
      setModalInsertar(!modalInsertar)
    } else if (Modal == 3) {
      console.log("ver detalle");
      setModo(2);
      setModalInsertar(!modalInsertar) /////////// AQUI SE LLAMA AL MODAL VER TAREA !
    }
    else if (Modal == 4) {
      setModalFiltro(!modalFiltro)
    } else if (Modal == 5) {
      setModalImg(!modalImg)
      encodeImageFileAsURL()
      FuncionCargarImg(getEgreso.cEge_Archivo)
    } else if (Modal == 6) {
      FuncionCargarImg(row.cEge_Archivo)
    }
  }
  const FormatoMoneda = (moneda) => {
    return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 2 }).format(moneda);
  }
  const Acciones = (Egreso, caso) => {

    console.log(Egreso);
    setEgreso(prevState => ({
      ...prevState,
      nEge_Id: Egreso.nEge_Id,
      nCli_Id: Egreso.nCli_Id,
      nCas_Id: Egreso.nCas_Id,
      nCue_Id: Egreso.nCue_Id,
      cCas_Cod_Externo: Egreso.cCas_Cod_Externo,
      cCas_Cod_Interno: Egreso.cCas_Cod_Interno,
      cEge_Tab_Tipo: Egreso.cEge_Tab_Tipo,
      cEge_Tab_FormaPago: Egreso.cEge_Tab_FormaPago,
      cIng_Tab_Moneda: Egreso.cIng_Tab_Moneda,
      dEge_TCambio: Egreso.dEge_TCambio,
      dEge_TCambioFormat: Egreso.dEge_TCambio,
      dEge_TCambioOld: Egreso.dEge_TCambio,
      nEge_Monto: Egreso.nEge_Monto,
      nEge_MontoFormat: Egreso.nEge_Monto,
      nEge_MontoOld: Egreso.nEge_Monto,
      cEge_Detalle: Egreso.cEge_Detalle,
      nUsu_ID: Egreso.nUsu_ID,
      cEge_Archivo: Egreso.cEge_Archivo,
      bEge_Estado: Egreso.bEge_Estado,
      dEge_Fecha: Egreso.dEge_Fecha,
    }))
    //Mostrar la caja de texto cuando el egreso sea en dolares
    if (Egreso.cIng_Tab_Moneda !== "MO01" && Egreso.cIng_Tab_Moneda !== "MO07" && Egreso.cIng_Tab_Moneda !== "0") {
      setDisableTpoCambio(false);
    } else {
      setDisableTpoCambio(true);
      setEgreso(prevState => ({
        ...prevState,
        dEge_TCambio: 0.000,
        dEge_TCambioOld: 0.000,
        dEge_TCambioFormat: 0.000,
      }))
    }
    if (caso == "Editar") {
      FuncionListarComboUsuario(Egreso.nCas_Id)
      abrirCerrarModal(2)
    } else {
      abrirCerrarModal(3)
    }
  }
  const badgesData = {
    columns: [
      ...datas.columns,
      {
        label: "Acciones",
        field: "acciones",
        width: 150,
      },
    ],
    rows: [
      ...datas.rows.map((row, id) => ({
        ...row,
        archivo: (
          <div>
            {row.cEge_Archivo.length > 0 ? <FontAwesomeIcon onClick={() => abrirCerrarModal(6, row)} icon={faPaperclip} style={{ fontSize: 18 }} /> : ''}
          </div>
        ),
        acciones: (
          <div>
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="dropdown-toggle">
                <i className="bx bx-dots-vertical-rounded" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => Acciones(row, "Ver")}>
                  Ver detalle
                </DropdownItem>
                <DropdownItem hidden={hiddenBtnEditar} onClick={() => Acciones(row, "Editar")}>
                  Editar
                </DropdownItem>
                <DropdownItem hidden={hiddenBtnEliminar}
                  onClick={() =>
                    Swal.fire({
                      title: "Mensaje",
                      html: "Estas seguro de eliminar este registro",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Aceptar",
                      cancelButtonText: "Cancelar",
                    }).then(result => {
                      if (result.isConfirmed) {
                        FuncionEliminarEgreso(row.nEge_Id)
                      }
                    })
                  }
                >
                  Eliminar
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        ),
      })),
    ],
  }
  const validateFileType = async () => {
    var fileName = document.getElementById("cEge_Archivo").value
    var idxDot = fileName.lastIndexOf(".") + 1
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase()
    if (
      extFile == "jpg" ||
      extFile == "jpeg" ||
      extFile == "png" ||
      extFile == "pdf" ||
      extFile == "rar" ||
      extFile == "zip"
    ) {
      var Valor = document.getElementById("cEge_Archivo").files[0].name
      setEgreso(prevState => ({
        ...prevState,
        cEge_Archivo: Valor,
      }))
    } else {
      document.getElementById("cEge_Archivo").value = ""
      setEgreso(prevState => ({
        ...prevState,
        cEge_Archivo: "",
      }))
      Swal.fire({
        title: "Mensaje",
        text: "El archivo debe ser formado .pdf o .jpg",
        icon: "error",
        confirmButtonText: "Aceptar",
      })
    }
  }
  function encodeImageFileAsURL() {
    var filesSelected = document.getElementById("cEge_Archivo").files
    if (filesSelected.length > 0) {
      var fileToLoad = filesSelected[0]
      var fileReader = new FileReader()
      fileReader.onload = function (fileLoadedEvent) {
        var srcData = fileLoadedEvent.target.result

        var newImage = document.createElement("iframe")
        newImage.src = srcData
        newImage.setAttribute("style", "width:100%;height:600px")
        // Create Base64 Object
        var array = srcData.split(",")
        var fileName = document.getElementById("cEge_Archivo").value
        var idxDot = fileName.lastIndexOf(".") + 1
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase()
        if (extFile == "rar" || extFile == "zip") {
          const name = "<a>Este archivo no tiene vista previa</a>"
          document.getElementById("dase64_img").innerHTML = name
        } else {
          document.getElementById("dase64_img").innerHTML = newImage.outerHTML
        }
      }
      fileReader.readAsDataURL(fileToLoad)
    }
  }
  const LimpiarInputFile = async () => {
    document.getElementById("cEge_Archivo").value = ""
    setEgreso(prevState => ({
      ...prevState,
      cEge_Archivo: "",
    }))
  }
  const ReloadModalEgreso = async () => {
    setModalInsertar(!modalInsertar)
    FuncionListarEgresos()
  }
  const FuncionListarPermisos = async () => {
    var postData = {
      nUsu_ID: parseInt(getDecryptedItem("RolID"))
    }
    await instance
      .post("/Usuario/Acceso", postData)
      .then(response => {
        let arrayPermisos = response.data;
        const permiso = arrayPermisos.find(element => element.cUsu_Modulo == "ME07");

        if (permiso.bUsu_Editar == 1) {
          setHiddenBtnEditar(false);
        }
        else {
          setHiddenBtnEditar(true);
        }
        if (permiso.bUsu_Eliminar == 1) {
          setHiddenBtnEliminar(false);
        }
        else {
          setHiddenBtnEliminar(true);
        }
      })
      .catch(error => {

      })
  }

  const FuncionCargarImg = async (nameFile) => {
    var postData = {
      FileName: nameFile,
    }
    await instance
      .post("/Egreso/DownloadFile", postData)
      .then(response => {
        const Url = response.data.data
        setUrlFile(Url)
        setModalImg(!modalImg)
      })
      .catch(error => {
      })
  }

  const FnExportExcel = async () => {
    var postData = {
      type: "Egresos",
      data: data
    }
    await instance
      .post("/Generate/Excel", postData, { responseType: 'arraybuffer' })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = 'Egresos.xlsx';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {

      })
  }

  useEffect(() => {

    if (getDecryptedItem("RolID") == 9) {
      setHiddenCliente(true);
      FuncionListarCasos()
    }

    //setErrores(Validacion(getEgreso));
    FuncionListarPermisos();
    FuncionListarEgresos()
    FuncionListarCliente()

    FuncionListarComboMoneda()      //registrado
    FuncionListarComboTEgreso()     //registrado
    FuncionListarComboMetodoPago()  //registrado
    FuncionListarComboCuenta()      //registrado
    let rol = getDecryptedItem("RolID");

    if (rol !== '9') {
      localStorage.removeItem('CLIID');
      localStorage.removeItem('nCas_Id');
    }
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        { }
        <MetaTags>
          <title>Egresos | GpsLegal</title>
        </MetaTags>
        <Container fluid>
          { }
          <Breadcrumbs title="GpsLegal" breadcrumbItem="Egresos" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div>
                    <button
                      type="button"
                      onClick={() => abrirCerrarModal(1)}
                      className="btn btn-danger waves-effect waves-light"
                      data-toggle="modal"
                      data-target="#myModal"
                      hidden={hiddenBtnEditar}
                    >
                      <i style={{ fontSize: "15px" }} className="icon-nuevo"></i>&nbsp;
                      Nuevo
                    </button>{" "}
                    <button
                      type="button"
                      onClick={() => abrirCerrarModal(4)}
                      className="btn btn-danger waves-effect waves-light"
                      data-toggle="modal"
                      data-target="#myModal"
                    >
                      <i style={{ fontSize: "15px" }} className="icon-filtro"></i>&nbsp;
                      Filtros
                    </button>{" "}
                    <button
                      type="button"
                      onClick={() => FnExportExcel()}
                      className="btn btn-success waves-effect waves-light"
                      data-toggle="modal"
                      data-target="#myModal"
                    >
                      <i style={{ fontSize: "15px" }} className="icon-excel"></i>&nbsp;
                      Excel
                    </button>{" "}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div>

            <div className="">
              <div>
                <div>
                  <div>
                    {(() => {
                      if (modalInsertar == true) {
                        return <>
                          <ModalEgreso show={modalInsertar}
                            modo={modo}
                            egreso={getEgreso}
                            ReloadLista={() => ReloadModalEgreso()}
                            onCloseClick={() => { setModalInsertar(false), console.log("sssss") }}
                            Titulo="Nuevo Egreso"
                          />
                        </>
                      }
                    })()}
                    <Modal size="lg" isOpen={modalFiltro}>
                      <div
                        className="modal-header"
                        style={{
                          background:
                            "linear-gradient( 180deg , #E5163A, #AF2240)",
                        }}
                      >
                        <h5
                          style={{ color: "#fff" }}
                          className="modal-title mt-0"
                          id="myModalLabel"
                        >
                          Seleccione criterio(s) de búsqueda
                        </h5>
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(4)}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <strong>Fechas (rango)</strong>
                        <hr style={{ color: "#000", marginTop: "0px" }} />
                        <div className="mb-3 row">
                          <div className="col-md-1" style={{ width: "20px" }}></div>
                          <div className="col-md-5">
                            <input
                              className="form-control inputDown"
                              name="FechaInicial"
                              type="date" value={getFiltro && getFiltro.FechaInicial} onChange={handleChangeFiltro}
                            />
                            <label className="col-form-label labelDown">
                              Inicio*
                            </label>
                          </div>
                          <div className="col-md-1" style={{ width: "20px" }}></div>
                          <div className="col-md-5">
                            <input
                              className="form-control inputDown"
                              type="date" value={getFiltro && getFiltro.FechaFin} onChange={handleChangeFiltro}
                            />
                            <label className="col-form-label labelDown">
                              Fin*
                            </label>
                          </div>
                        </div>
                        <hr style={{ color: "#000", marginTop: "0px" }} />
                        <div className="mb-3 row">
                          <div className="col-md-1" hidden={hiddenCliente} style={{ width: "20px" }}>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheckcolor1"
                              checked={chekedValor1}
                              value={chekedValor1}
                              onChange={handleChangeValor1}
                              style={{ transform: "scale(1.5)", marginTop: "10px" }}
                            />
                          </div>
                          <div className="col-md-5" hidden={hiddenCliente}>
                            <select className="form-select inputDown" disabled={Valor1} name="nCli_Id" id="nCli_Id" onChange={handleChangeFiltro}>
                              <option value="00">Seleccionar...</option>
                              {dataCliente.map((row, i) => (
                                <option key={i} value={row.nCli_Id}>
                                  {row.cCli_NombreCompleto}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Cliente
                            </label>
                          </div>
                          <div className="col-md-1" style={{ width: "20px" }}>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheckcolor1"
                              checked={chekedValor2}
                              value={chekedValor2}
                              onChange={handleChangeValor2}
                              style={{ transform: "scale(1.5)", marginTop: "10px" }}
                            />
                          </div>
                          <div className="col-md-5">
                            <select
                              className="form-select inputDown"
                              name="nCas_Id" id="nCas_Id" disabled={Valor2} onChange={handleChangeFiltro}
                            >
                              <option value="0">Seleccionar...</option>
                              {dataCaso.map((row, i) => (
                                <option key={i} value={row.nCas_Id}>
                                  {row.cCas_Titulo}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Caso
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <div className="col-md-1" style={{ width: "20px" }}>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheckcolor1"
                              checked={chekedValor3}
                              value={chekedValor3}
                              onChange={handleChangeValor3}
                              style={{ transform: "scale(1.5)", marginTop: "10px" }}
                            />
                          </div>
                          <div className="col-md-5">
                            <select
                              className="form-select inputDown"
                              id="cIng_Tab_Moneda" name="cIng_Tab_Moneda" disabled={Valor3} onChange={handleChangeFiltro}
                            >
                              <option value="0">Seleccionar</option>
                              {dataComboMoneda.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Tipo de moneda
                            </label>
                          </div>
                          <div className="col-md-1" style={{ width: "20px" }}>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheckcolor1"
                              checked={chekedValor4}
                              value={chekedValor4}
                              onChange={handleChangeValor4}
                              style={{ transform: "scale(1.5)", marginTop: "10px" }}
                            />
                          </div>
                          <div className="col-md-5">
                            <select
                              className="form-select inputDown"
                              id="cEge_Tab_Tipo" name="cEge_Tab_Tipo" disabled={Valor4} onChange={handleChangeFiltro}
                            >
                              <option value="0">Seleccionar...</option>
                              {dataComboTEgreso.map((row, i) => (
                                <option key={i} value={row.cTabCodigo}>
                                  {row.cTabNombre}
                                </option>
                              ))}
                            </select>
                            <label className="col-form-label labelDown">
                              Tipo de egreso
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => abrirCerrarModal(4)}
                          className="btn btn-secondary waves-effect"
                          data-dismiss="modal"
                        >
                          Cerrar
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger waves-effect waves-light"
                          onClick={() => FuncionBuscarEgresos()}
                        >
                          Filtrar
                        </button>
                      </div>
                    </Modal>
                    <Modal size="lg" isOpen={modalImg}>
                      <div
                        className="modal-header"
                        style={{
                          background:
                            "linear-gradient( 180deg , #E5163A, #AF2240)",
                        }}
                      >
                        <h5
                          style={{ color: "#fff" }}
                          className="modal-title mt-0"
                          id="myModalLabel"
                        >
                          Vista Previa
                        </h5>
                        <button
                          type="button"
                          onClick={() => setModalImg(false)}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <iframe title="PDF Viewer" src={getUrlFile} width="100%" height="500px" />
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => setModalImg(false)}
                          className="btn btn-secondary waves-effect"
                          data-dismiss="modal"
                        >
                          Cerrar
                        </button>
                      </div>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Row>
            <Col className="col-12">
              <div>
                <div>
                  <div className="col-md-12 ">
                    <label className="col-md-12" style={{ textAlign: 'right', paddingTop: '20px', paddingRight: '56px' }}>Cantidad de egresos : {data.length}</label>
                  </div>
                  <MDBDataTable
                    data={badgesData}
                    responsiveXl
                    fixed
                    hover
                    id="tableEgreso"
                    entriesLabel="Mostrar"
                    entrylabel={"Mostrar entradas"}
                    infoLabel={["Mostrando ", "a", "de", "registros"]}
                    bordered
                    order={["age", "desc"]}
                    noRecordsFoundLabel={"No hay datos"}
                    searchLabel={"Buscar"}
                    noBottomColumns
                    paginationLabel={["Anterior", "Siguiente"]}
                    className="TablaEgreso"
                    theadColor="HeadBlue"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Egresos
