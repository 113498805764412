import { ADD_ROLES,UPD_ROLES,GET_ROLES } from "../Type";
export default (state, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_ROLES:
      return {
        ...state,
        roles: payload,
      };
    case ADD_ROLES:
      return {
        ...state,
        roles: payload,
      };
      case UPD_ROLES:
      return {
        ...state,
        roles: payload,
      };
    default:
      return state;
  }
};