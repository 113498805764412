import { GET_REPORTE_LQP } from "../Type";
export default (state, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_REPORTE_LQP:
      return {
        ...state,
        lqpArray: payload,
      };
    default:
      return state;
  }
};
