import {
    GET_COMBO_CLIENTE, GET_COMBO_CASO,
    GET_COMBO_TDOCUMENTO, GET_COMBO_DEPARTAMENTO,
    GET_COMBO_PROVINCIA, GET_COMBO_DISTRITO,
    GET_COMBO_ESTADO, GET_COMBO_CATEVENTO,
    GET_COMBO_TIPEVENTO, GET_COMBO_RECORDAR,
    GET_COMBO_CATTAREA,
    GET_COMBO_JURISDICCION,
    GET_COMBO_JUZGADO,
    GET_COMBO_MATERIA,
    GET_COMBO_SUBMATERIA,
    GET_COMBO_ESTADOINTERNO,
    GET_COMBO_ESTADOEXTERNO,
    GET_COMBO_USUARIO,
    GET_COMBO_EQUIPO,
    GET_COMBO_MONEDA,
    GET_COMBO_LUGARTRAMITE,
    GET_COMBO_AREA,
    GET_COMBO_TEXPEDIENTE,
    GET_COMBO_PARTICIPANTESEVENTO,
    GET_COMBO_METODOPAGO,
    //GET_COMBO_MONEDA, //--> Ya existe este nombre de metodo
    GET_COMBO_TINGRESO,
    GET_COMBO_CUENTA,
    GET_COMBO_TEGRESO,
    GET_COMBO_TIPOTAREA,
    GET_COMBO_RECORDARTAREA,
    GET_COMBO_RECORDAREVENTO,
    GET_COMBO_TCORREO,
    GET_COMBO_THORASLABORADAS,
    GET_COMBO_ABOGADORESPONSABLE,
    GET_COMBO_ABOGADO,
    GET_COMBO_PRACTICANTE,
    GET_COMBO_USUARIOADICIONAL,
    GET_CLIENTEULT,GET_COMBO_GRUPO
} from "../Type";
export default (state, action) => {
    const { payload, type, id } = action;
    switch (type) {
        case GET_COMBO_CLIENTE:
            return {
                ...state,
                clientes: payload,
            };
        case GET_CLIENTEULT:
            return {
                ...state,
                clientesult: payload,
                idClienteUlt: id,
            };
        case GET_COMBO_CASO:
            return {
                ...state,
                casos: payload,
            };
        case GET_COMBO_TDOCUMENTO:
            return {
                ...state,
                tdocumentos: payload,
            };
        case GET_COMBO_DEPARTAMENTO:
            return {
                ...state,
                departamentos: payload,
            };
        case GET_COMBO_PROVINCIA:
            return {
                ...state,
                provincias: payload,
            };
        case GET_COMBO_DISTRITO:
            return {
                ...state,
                distritos: payload,
            };
        case GET_COMBO_ESTADO:
            return {
                ...state,
                estados: payload,
            };
        case GET_COMBO_RECORDAR:
            return {
                ...state,
                recordar: payload,
            };
        case GET_COMBO_GRUPO:
            return {
                ...state,
                grupo: payload,
            };
        case GET_COMBO_CATEVENTO:
            return {
                ...state,
                categoriasEvento: payload,
            };
        case GET_COMBO_TIPEVENTO:
            return {
                ...state,
                tiposEvento: payload,
            };
        case GET_COMBO_CATTAREA:
            return {
                ...state,
                categoriasTarea: payload,
            };
        case GET_COMBO_JURISDICCION:
            return {
                ...state,
                jurisdiccion: payload,
            };
        case GET_COMBO_JUZGADO:
            return {
                ...state,
                juzgado: payload,
            };
        case GET_COMBO_MATERIA:
            return {
                ...state,
                materia: payload,
            };
        case GET_COMBO_SUBMATERIA:
            return {
                ...state,
                submateria: payload,
            };
        case GET_COMBO_ESTADOINTERNO:
            return {
                ...state,
                estadointerno: payload,
            };
        case GET_COMBO_ESTADOEXTERNO:
            return {
                ...state,
                estadoexterno: payload,
            };
        case GET_COMBO_USUARIO:
            return {
                ...state,
                cusuario: payload,
            };
        case GET_COMBO_EQUIPO:
            return {
                ...state,
                cequipo: payload,
            };
        case GET_COMBO_MONEDA:
            return {
                ...state,
                moneda: payload,
            };
        case GET_COMBO_LUGARTRAMITE:
            return {
                ...state,
                lugartramite: payload,
            };
        case GET_COMBO_AREA:
            return {
                ...state,
                area: payload,
            };
        case GET_COMBO_TEXPEDIENTE:
            return {
                ...state,
                texpediente: payload,
            };
        case GET_COMBO_PARTICIPANTESEVENTO:
            return {
                ...state,
                participantesevento: payload,
            };
        case GET_COMBO_METODOPAGO:
            return {
                ...state,
                metodopago: payload,
            };
        case GET_COMBO_TINGRESO:
            return {
                ...state,
                tingreso: payload,
            };
        case GET_COMBO_CUENTA:
            return {
                ...state,
                cuenta: payload,
            };
        case GET_COMBO_TEGRESO:
            return {
                ...state,
                tegreso: payload,
            };
        case GET_COMBO_TIPOTAREA:
            return {
                ...state,
                tipoTarea: payload,
            };
        case GET_COMBO_RECORDARTAREA:
            return {
                ...state,
                recordarTarea: payload,
            };
        case GET_COMBO_RECORDAREVENTO:
            return {
                ...state,
                recordarEvento: payload,
            };
        case GET_COMBO_TCORREO:
            return {
                ...state,
                tCorreo: payload,
            };
        case GET_COMBO_THORASLABORADAS:
            return {
                ...state,
                thLaboradas: payload,
            };
        case GET_COMBO_ABOGADORESPONSABLE:
            return {
                ...state,
                abogadoresponsable: payload,
            };
        case GET_COMBO_ABOGADO:
            return {
                ...state,
                abogado: payload,
            };
        case GET_COMBO_PRACTICANTE:
            return {
                ...state,
                practicante: payload,
            };
        case GET_COMBO_USUARIOADICIONAL:
            return {
                ...state,
                usuarioadicional: payload,
            };
        default:
            return state;
    }
};
