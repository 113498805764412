import React, { Component, useState, useEffect } from "react"
import { MDBDataTable } from "mdbreact"
import {
    CardBody,
    Card,
    Col,
    Container,
    CardTitle,
    Button,
    Row,
    Modal,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import { getDecryptedItem } from '../../util';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/tablescss/FormatTable.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faPlus,
    faSearch,
    faTimes,
    faChevronLeft,
    faChevronRight,
    faPaperPlane,
    faBuilding,
    faUser,
    faCalendar,
    faCircle,
    faClipboard,
    faMapMarkedAlt,
} from "@fortawesome/free-solid-svg-icons"
import Swal from "sweetalert2"
import instance from '../../config'
import ModalIngreso from "pages/Componentes/ModalIngreso"
import ReactHTMLTableToExcel from 'react-html-table-to-excel'
const baseUrl = process.env.REACT_APP_API_URL
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10

const Notificaciones = props => {

    const [data, setData] = useState([
    ])
    const [dataCasos, setDataCasos] = useState({
        altasBajas: 0,
        cambioEstado: 0,
        cambiDatos: 0,
        comentarios: 0,
        movimientos: 0,
    })
    const [hiddenBtnEditar, setHiddenBtnEditar] = useState(true)
    const [hiddenBtnEliminar, setHiddenBtnEliminar] = useState(true)

    const [dataClientes, setDataClientes] = useState({
        notas: 0,
        documentos: 0,
    })
    const [dataEtareas, setDataEtareas] = useState({
        eventos: 0,
        recordatorioEvento: 0,
        tareas: 0,
        recordatorioTarea: 0
    })
    const [dataIngresosEgresos, setDataIngresoEgreso] = useState({
        ingresos: 0,
        horasFacturables: 0,
        gastos: 0,

    })

    const [dataParametros, setDataParametros] = useState({
        horaPreferente: '8:00',
        diasInactividad: 30,
        diasResumen: 7
    })
    const [dataUserId, setDataUserId] = useState({
        iduser: 0
    })
    const [dataUser, setDataUser] = useState([])
    const handleChangeCliente = e => {
        const { name, value } = e.target
        setDataClientes({ ...dataClientes, [name]: parseInt(value) })

    }
    const handleChangeCaso = e => {
        const { name, value } = e.target
        /*    setDataCasos(prevState => ({
              ...prevState,
              [name]: parseInt(value)
  
          }))  */
        setDataCasos({ ...dataCasos, [name]: parseInt(value) })
        //setDataClientes({ ...dataCasos[0], [name] : value})

    }
    const handleChangeEventoTarea = e => {
        const { name, value } = e.target
        setDataEtareas(prevState => ({
            ...prevState,
            [name]: parseInt(value)
        }))

    }
    const handleChangeIngresoEgreso = e => {
        const { name, value } = e.target
        setDataIngresoEgreso(prevState => ({
            ...prevState,
            [name]: parseInt(value)
        }))

    }
    const handleChangeParametro = e => {
        const { name, value } = e.target
        setDataParametros(prevState => ({
            ...prevState,
            [name]: value
        }))

    }

    const ListConfig = async (xdata) => {
        var postData = {
            iduser: parseInt(xdata.id),

        }

        await instance
            .post("/config/notifications/list", postData)
            .then(response => {
                let arrayPermisos = response.data;
                if (arrayPermisos.length > 0) {
                    setDataCasos(arrayPermisos[0].nNot_Casos)
                    setDataClientes(arrayPermisos[0].nNot_Clientes)
                    setDataEtareas(arrayPermisos[0].nNot_EventosTareas)
                    setDataIngresoEgreso(arrayPermisos[0].nNot_IngresosEgresos)
                    setDataParametros(arrayPermisos[0].nNot_Parametros)
                } else {
                    setDataCasos({
                        altasBajas: 0,
                        cambioEstado: 0,
                        cambiDatos: 0,
                        comentarios: 0,
                        movimientos: 0,
                    })
                    setDataClientes({
                        notas: 0,
                        documentos: 0,
                    })
                    setDataEtareas({
                        eventos: 0,
                        recordatorioEvento: 0,
                        tareas: 0,
                        recordatorioTarea: 0
                    })
                    setDataIngresoEgreso({
                        ingresos: 0,
                        horasFacturables: 0,
                        gastos: 0,

                    })
                    setDataParametros({
                        horaPreferente: '8:00',
                        diasInactividad: 30,
                        diasResumen: 7
                    })
                }


            })
            .catch(error => {
                console.log(error)
                Swal.fire({
                    title: "Mensaje",
                    text: error.message,
                    icon: "error",
                    confirmButtonText: "Aceptar",
                })

            })

    }
    const handleChange = e => {
        const { name, value } = e.target
        setDataUserId(prevState => ({
            ...prevState,
            [name]: value
        }))

        ListConfig({ id: parseInt(value) })

        //
    }

    const fInsertarConfig = async () => {
        if (dataUserId.iduser == '00') {
            Swal.fire({
                title: "Mensaje",
                text: "Debe seleccionar al menos un usuario para configurar las notificaciones.",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        } else {
            var postData = {
                iduser: parseInt(dataUserId.iduser),
                case:
                {
                    altasBajas: parseInt(dataCasos.altasBajas),
                    cambioEstado: parseInt(dataCasos.cambioEstado),
                    cambiDatos: parseInt(dataCasos.cambiDatos),
                    comentarios: parseInt(dataCasos.comentarios),
                    movimientos: parseInt(dataCasos.movimientos)
                }
                ,
                client:
                {
                    notas: parseInt(dataClientes.notas),
                    documentos: parseInt(dataClientes.documentos),
                }
                ,
                eventotaks: {
                    eventos: parseInt(dataEtareas.eventos),
                    recordatorioEvento: parseInt(dataEtareas.recordatorioEvento),
                    tareas: parseInt(dataEtareas.tareas),
                    recordatorioTarea: parseInt(dataEtareas.recordatorioEvento)
                },
                ingresoegreso: {
                    ingresos: parseInt(dataIngresosEgresos.ingresos),
                    horasFacturables: parseInt(dataIngresosEgresos.horasFacturables),
                    gastos: parseInt(dataIngresosEgresos.gastos),
                },
                parametros: {
                    horaPreferente: parseInt(dataParametros.horaPreferente),
                    diasInactividad: parseInt(dataParametros.diasInactividad),
                    diasResumen: parseInt(dataParametros.diasResumen)
                },
                nNot_Movimientos: 0,
                nNot_Comentarios: 0,
                nNot_DiasCongelado: 0
            }

            await instance
                .post("/config/notifications/save", postData)
                .then(response => {
                    //setDataCombo(response.data)
                    Swal.fire({
                        title: "Mensaje",
                        text: "Transacción exitosa",
                        icon: "success",
                        confirmButtonText: "Aceptar",
                    })

                })
                .catch(error => {
                    Swal.fire({
                        title: "Mensaje",
                        text: error,
                        icon: "error",
                        confirmButtonText: "Aceptar",
                    })

                })
        }
    }
    const FuncionListarUsuario = async () => {
        await instance
            .get("/Usuario/Listar")
            .then(response => {
                setDataUser(response.data)

            })
            .catch(error => {

            })
    }
    const FuncionListarPermisos = async () => {
        var postData = {
            nUsu_ID: parseInt(getDecryptedItem("RolID"))
        }
        await instance
            .post("/Usuario/Acceso", postData)
            .then(response => {
                let arrayPermisos = response.data;
                const permiso = arrayPermisos.find(element => element.cUsu_Modulo == "ME21");

                if (permiso.bUsu_Editar == 1) {
                    setHiddenBtnEditar(false);
                }
                else {
                    setHiddenBtnEditar(true);
                }

                if (permiso.bUsu_Eliminar == 1) {
                    setHiddenBtnEliminar(false);
                }
                else {
                    setHiddenBtnEliminar(true);
                }
            })
            .catch(error => {

            })
    }
    useEffect(() => {
        FuncionListarPermisos();
        FuncionListarUsuario()
    }, [])
    return (
        <React.Fragment>
            <div className="page-content">
                {/* add meta title */}
                <MetaTags>
                    <title>Notificaciones | GpsLegal</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="GpsLegal" breadcrumbItem="Configuración de notificaciones" />
                    <div className="" >


                        <div className="form-usuario" style={{ paddingLeft: '20px', paddingTop: '10px' }}>
                            <div>
                                <label style={{ fontSize: "20px", color: "#aa1b0E", fontWeight: "normal", fontFamily: 'roboto', paddingTop: '9%' }}>Deseo ser notificado:</label>
                            </div>
                            <div>
                                <label className="texto-combex" >Usuario</label>
                                <select style={{ marginTop: '7px' }}
                                    className="form-select inputDown combodate texto-combex"
                                    name="iduser"
                                    onChange={handleChange}
                                >
                                    <option className="texto-combex" value="00">Seleccionar</option>
                                    {dataUser.map((e) =>
                                        <option className="texto-combex" value={e.nUsu_ID}>
                                            {e.cUsu_Nombres}
                                        </option>
                                    )
                                    }
                                </select>
                            </div>
                            <div className="form-boton-save">
                                <button hidden={hiddenBtnEditar} className="btn btn-danger texto-combex" onClick={() => fInsertarConfig()}><i className="fas fa-paper-plane" ></i>&nbsp;&nbsp;Guardar</button>
                            </div>
                        </div>

                        <div className="form-notificaciones">
                            {/*CABECERA<div className="col-notif">
                                <div className="cabecera-not title-notif">
                                    <b className="texto-combex">VALOR</b>
                                </div>
                                <div className="cabecera-not title-notif">
                                    <b className="texto-combex">NOMBRE</b>
                                </div>
                                <div className="cabecera-not title-notif">
                                    <b className="texto-combex">DESCRIPCIÓN</b>
                                </div>
                            </div>
                            
*/}
                            <div className="col-notif-start title-notif">
                                Casos
                            </div>

                            {/* fila 0*/}
                            <div className="col-notif">
                                <div>


                                    <select style={{ padding: '5px' }}
                                        className="form-select inputDown combodate texto-combex"
                                        name="altasBajas"
                                        onChange={handleChangeCaso}
                                        value={dataCasos.altasBajas}

                                    >
                                        <option className="texto-combex" value="0">Inmediato</option>
                                        <option className="texto-combex" value="1">Diario</option>
                                        <option className="texto-combex" value="2">Ambos</option>
                                        <option className="texto-combex" value="3">No notificar</option>
                                        {/*dataUser.map((e) =>
                                            <option className="texto-combex" value={e.nUsu_ID}>
                                                {e.cUsu_Nombres}
                                            </option>
                                        )
                                        */}
                                    </select>
                                </div>

                                <div>
                                    <label className="texto-notif">Altas y bajas</label>
                                </div>
                                <div>
                                    <label className="texto-notif">Al crearse o cerrarse un caso con mi participación</label>
                                </div>
                            </div>


                            {/* fila 1*/}
                            <div className="col-notif">
                                <div className="">

                                    <select style={{ padding: '5px' }}
                                        className="form-select inputDown combodate texto-combex"
                                        name="cambioEstado"
                                        onChange={handleChangeCaso}
                                        value={dataCasos.cambioEstado}
                                    >
                                        <option className="texto-combex" value="0">Inmediato</option>
                                        <option className="texto-combex" value="1">Diario</option>
                                        <option className="texto-combex" value="2">Ambos</option>
                                        <option className="texto-combex" value="3">No notificar</option>
                                        {/*dataUser.map((e) =>
                                            <option className="texto-combex" value={e.nUsu_ID}>
                                                {e.cUsu_Nombres}
                                            </option>
                                        )
                                        */}
                                    </select>
                                </div>

                                <div>
                                    <label className="texto-notif">Cambio de estado </label>
                                </div>
                                <div>
                                    <label className="texto-notif">Al haber un cambio de estado del proceso en el organismo donde se ventile.</label>
                                </div>
                            </div>





                            {/* fila 2*/}
                            <div className="col-notif">
                                <div>
                                    <select style={{ padding: '5px' }}
                                        className="form-select inputDown combodate texto-combex"
                                        name="cambiDatos"
                                        onChange={handleChangeCaso}
                                        value={dataCasos.cambiDatos}
                                    >
                                        <option className="texto-combex" value="0">Inmediato</option>
                                        <option className="texto-combex" value="1">Diario</option>
                                        <option className="texto-combex" value="2">Ambos</option>
                                        <option className="texto-combex" value="3">No notificar</option>
                                        {/*dataUser.map((e) =>
                                            <option className="texto-combex" value={e.nUsu_ID}>
                                                {e.cUsu_Nombres}
                                            </option>
                                        )
                                        */}
                                    </select>
                                </div>

                                <div>
                                    <label className="texto-notif">Cambios de datos </label>
                                </div>
                                <div>
                                    <label className="texto-notif">Al generarse un cambio en cualquier dato general del proceso (no incluye movimientos, horas ni gastos) </label>
                                </div>
                            </div>


                            {/* fila 3*/}
                            <div className="col-notif">
                                <div>

                                    <select style={{ padding: '5px' }}
                                        className="form-select inputDown combodate texto-combex"
                                        name="comentarios"
                                        onChange={handleChangeCaso}
                                        value={dataCasos.comentarios}
                                    >
                                        <option className="texto-combex" value="0">Inmediato</option>
                                        <option className="texto-combex" value="1">Diario</option>
                                        <option className="texto-combex" value="2">Ambos</option>
                                        <option className="texto-combex" value="3">No notificar</option>
                                        {/*dataUser.map((e) =>
                                            <option className="texto-combex" value={e.nUsu_ID}>
                                                {e.cUsu_Nombres}
                                            </option>
                                        )
                                        */}
                                    </select>
                                </div>
                                <div>
                                    <label className="texto-notif">Comentarios </label>
                                </div>
                                <div>
                                    <label className="texto-notif">Al agregar un comentario a un caso del que participo.</label>
                                </div>
                            </div>

                            {/* fila 4*/}
                            <div className="col-notif">
                                <div>

                                    <select style={{ padding: '5px' }}
                                        className="form-select inputDown combodate texto-combex"
                                        name="movimientos"
                                        onChange={handleChangeCaso}
                                        value={dataCasos.movimientos}
                                    >
                                        <option className="texto-combex" value="0">Inmediato</option>
                                        <option className="texto-combex" value="1">Diario</option>
                                        <option className="texto-combex" value="2">Ambos</option>
                                        <option className="texto-combex" value="3">No notificar</option>
                                        {/*dataUser.map((e) =>
                                            <option className="texto-combex" value={e.nUsu_ID}>
                                                {e.cUsu_Nombres}
                                            </option>
                                        )
                                        */}
                                    </select>
                                </div>

                                <div>
                                    <label className="texto-notif">Movimientos</label>
                                </div>
                                <div>
                                    <label className="texto-notif">Cualquier movimiento procesal, sea una resolución, notificación, notas, oficios o escritos de parte. </label>
                                </div>
                            </div>

                            {/*Cabecera 1*/}
                            <div className="col-notif-start title-notif">
                                Clientes
                            </div>


                            {/* fila 0 */}

                            <div className="col-notif">
                                <div>

                                    <select style={{ padding: '5px' }}
                                        className="form-select inputDown combodate texto-combex"
                                        name="notas"
                                        onChange={handleChangeCliente}
                                        value={dataClientes.notas == null ? 0 : dataClientes.notas}
                                    >
                                        <option className="texto-combex" value="0">Inmediato</option>
                                        <option className="texto-combex" value="1">Diario</option>
                                        <option className="texto-combex" value="2">Ambos</option>
                                        <option className="texto-combex" value="3">No notificar</option>
                                        {/*dataUser.map((e) =>
                                            <option className="texto-combex" value={e.nUsu_ID}>
                                                {e.cUsu_Nombres}
                                            </option>
                                        )
                                        */}
                                    </select>
                                </div>

                                <div>
                                    <label className="texto-notif" >Notas </label>
                                </div>
                                <div>
                                    <label className="texto-notif" >Al agregar una nota a un cliente con el que trabajo. </label>
                                </div>
                            </div>


                            {/* fila 1*/}
                            <div className="col-notif">
                                <div>
                                    <select style={{ padding: '5px' }}
                                        className="form-select inputDown combodate texto-combex"
                                        name="documentos"
                                        onChange={handleChangeCliente}
                                        value={dataClientes.documentos == null ? 0 : dataClientes.documentos}
                                    >
                                        <option className="texto-combex" value="0">Inmediato</option>
                                        <option className="texto-combex" value="1">Diario</option>
                                        <option className="texto-combex" value="2">Ambos</option>
                                        <option className="texto-combex" value="3">No notificar</option>
                                        {/*dataUser.map((e) =>
                                            <option className="texto-combex" value={e.nUsu_ID}>
                                                {e.cUsu_Nombres}
                                            </option>
                                        )
                                        */}
                                    </select>
                                </div>

                                <div>
                                    <label className="texto-notif" >Documentos </label>
                                </div>
                                <div>
                                    <label className="texto-notif" >Cuando el cliente suba documentos al sistema </label>
                                </div>
                            </div>

                            {/*Cabecera 2*/}
                            <div className="col-notif-start title-notif">
                                Eventos y Tareas
                            </div>


                            {/* fila 0*/}

                            <div className="col-notif">
                                <div>
                                    <select style={{ padding: '5px' }}
                                        className="form-select inputDown combodate texto-combex"
                                        name="eventos"
                                        onChange={handleChangeEventoTarea}
                                        value={dataEtareas.eventos}
                                    >
                                        <option className="texto-combex" value="0">Inmediato</option>
                                        <option className="texto-combex" value="1">Diario</option>
                                        <option className="texto-combex" value="2">Ambos</option>
                                        <option className="texto-combex" value="3">No notificar</option>
                                        {/*dataUser.map((e) =>
                                            <option className="texto-combex" value={e.nUsu_ID}>
                                                {e.cUsu_Nombres}
                                            </option>
                                        )
                                        */}
                                    </select>
                                </div>

                                <div>
                                    <label className="texto-notif" >Eventos  </label>
                                </div>
                                <div>
                                    <label className="texto-notif" >Al crearse un evento en el que participaré. </label>
                                </div>
                            </div>



                            {/* fila 1*/}
                            <div className="col-notif">
                                {/* <div>
                                    <select style={{ padding: '5px' }}
                                        className="form-select inputDown combodate texto-combex"
                                        name="recordatorioEvento"
                                        onChange={handleChangeEventoTarea}
                                        value={dataEtareas.recordatorioEvento}
                                    >
                                        <option className="texto-combex" value="0">Inmediato</option>
                                        <option className="texto-combex" value="1">Diario</option>
                                        <option className="texto-combex" value="2">Ambos</option>
                                        <option className="texto-combex" value="3">No notificar</option>
                                  
                                    </select>
                                </div>

                                <div>
                                    <label className="texto-notif" >Recordatorio de Evento</label>
                                </div>
                                <div>
                                    <label className="texto-notif" >Un día antes del evento</label>
                                </div> */}
                            </div>
                            {/* fila 2*/}
                            <div className="col-notif">
                                <div>
                                    <select style={{ padding: '5px' }}
                                        className="form-select inputDown combodate texto-combex"
                                        name="tareas"
                                        onChange={handleChangeEventoTarea}
                                        value={dataEtareas.tareas}
                                    >
                                        <option className="texto-combex" value="0">Inmediato</option>
                                        <option className="texto-combex" value="1">Diario</option>
                                        <option className="texto-combex" value="2">Ambos</option>
                                        <option className="texto-combex" value="3">No notificar</option>
                                        {/*dataUser.map((e) =>
                                            <option className="texto-combex" value={e.nUsu_ID}>
                                                {e.cUsu_Nombres}
                                            </option>
                                        )
                                        */}
                                    </select>
                                </div>

                                <div>
                                    <label className="texto-notif" >Tareas</label>
                                </div>
                                <div>
                                    <label className="texto-notif" >Al tener una tarea nueva asignada</label>
                                </div>
                            </div>

                            {/* fila 3*/}
                            {/* <div className="col-notif">
                                <div>
                                    <select style={{ padding: '5px' }}
                                        className="form-select inputDown combodate texto-combex"
                                        name="recordatorioTarea"
                                        onChange={handleChangeEventoTarea}
                                        value={dataEtareas.recordatorioTarea}
                                    >
                                        <option className="texto-combex" value="0">Inmediato</option>
                                        <option className="texto-combex" value="1">Diario</option>
                                        <option className="texto-combex" value="2">Ambos</option>
                                        <option className="texto-combex" value="3">No notificar</option>
                             
                                    </select>
                                </div>

                                <div>
                                    <label className="texto-notif" >Recordatorio de Tarea</label>
                                </div>
                                <div>
                                    <label className="texto-notif" >Un día antes del vencimiento de la tarea</label>
                                </div>
                            </div> */}

                            {/*Cabecera 3*/}
                            <div className="col-notif-start title-notif">
                                Ingresos y Egresos
                            </div>
                            {/* fila 0*/}
                            <div className="col-notif">
                                <div>

                                    <select style={{ padding: '5px' }}
                                        className="form-select inputDown combodate texto-combex"
                                        name="ingresos"
                                        onChange={handleChangeIngresoEgreso}
                                        value={dataIngresosEgresos.ingresos}
                                    >
                                        <option className="texto-combex" value="0">Inmediato</option>
                                        <option className="texto-combex" value="1">Diario</option>
                                        <option className="texto-combex" value="2">Ambos</option>
                                        <option className="texto-combex" value="3">No notificar</option>
                                        {/*dataUser.map((e) =>
                                            <option className="texto-combex" value={e.nUsu_ID}>
                                                {e.cUsu_Nombres}
                                            </option>
                                        )
                                        */}
                                    </select>
                                </div>

                                <div>
                                    <label className="texto-notif" >Ingresos</label>
                                </div>
                                <div>
                                    <label className="texto-notif" >Al recibir un abono del Cliente</label>
                                </div>
                            </div>

                            {/* fila 1*/}
                            <div className="col-notif">
                                <div>
                                    <select style={{ padding: '5px' }}
                                        className="form-select inputDown combodate texto-combex"
                                        name="horasFacturables"
                                        onChange={handleChangeIngresoEgreso}
                                        value={dataIngresosEgresos.horasFacturables}
                                    >
                                        <option className="texto-combex" value="0">Inmediato</option>
                                        <option className="texto-combex" value="1">Diario</option>
                                        <option className="texto-combex" value="2">Ambos</option>
                                        <option className="texto-combex" value="3">No notificar</option>
                                        {/*dataUser.map((e) =>
                                            <option className="texto-combex" value={e.nUsu_ID}>
                                                {e.cUsu_Nombres}
                                            </option>
                                        )
                                        */}
                                    </select>
                                </div>

                                <div>
                                    <label className="texto-notif" >Horas Facturables</label>
                                </div>
                                <div>
                                    <label className="texto-notif" >Al atribuirle horas de labor facturables a algún caso</label>
                                </div>
                            </div>

                            {/* fila 2*/}
                            <div className="col-notif">
                                <div>
                                    <select style={{ padding: '5px' }}
                                        className="form-select inputDown combodate texto-combex"
                                        name="gastos"
                                        onChange={handleChangeIngresoEgreso}
                                        vale={dataIngresosEgresos.gastos}
                                    >
                                        <option className="texto-combex" value="0">Inmediato</option>
                                        <option className="texto-combex" value="1">Diario</option>
                                        <option className="texto-combex" value="2">Ambos</option>
                                        <option className="texto-combex" value="3">No notificar</option>
                                        {/*dataUser.map((e) =>
                                            <option className="texto-combex" value={e.nUsu_ID}>
                                                {e.cUsu_Nombres}
                                            </option>
                                        )
                                        */}
                                    </select>
                                </div>

                                <div>
                                    <label className="texto-notif" >Gastos</label>
                                </div>
                                <div>
                                    <label className="texto-notif" >Al ingresar gastos a algún caso</label>
                                </div>
                            </div>

                            {/*Cabecera 3*/}
                            <div className="col-notif-start title-notif">
                                Parámetros
                            </div>




                            {/* fila 9*/}
                            <div className="col-notif">
                                <div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        maxLength="4"
                                        defaultValue="8:00"
                                        id=""
                                        name="horaPreferente"
                                        style={{ textAlign: 'left', width: '3em', padding: '2px' }}
                                        onChange={handleChangeParametro}
                                        value={dataParametros.horaPreferente}
                                    />
                                </div>

                                <div>
                                    <label className="texto-notif" value={dataParametros.horaPreferente}>Hora Preferente </label>
                                </div>
                                <div>
                                    <label className="texto-notif" >Hora preferente para el envío del correo del Resumen Diario </label>
                                </div>
                            </div>


                            {/* fila 10*/}
                            <div className="col-notif">
                                <div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id=""
                                        name="diasInactividad"
                                        maxLength="2"
                                        defaultValue={dataParametros.diasInactividad}
                                        style={{ textAlign: 'left', width: '3em', padding: '2px' }}
                                        onChange={handleChangeParametro}
                                        value={dataParametros.diasInactividad}
                                    />
                                </div>

                                <div>
                                    <label className="texto-notif" >Días de inactividad de procesos</label>
                                </div>
                                <div>
                                    <label className="texto-notif" >Cantidad de días de inactividad en un proceso con lo cual se genera una alerta</label>
                                </div>
                            </div>

                            <div className="col-notif">
                                <div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        maxLength="4"
                                        defaultValue={dataParametros.diasResumen}
                                        id=""
                                        onChange={handleChangeParametro}
                                        name="diasResumen"
                                        style={{ textAlign: 'left', width: '3em', padding: '2px' }}
                                        value={dataParametros.diasResumen}

                                    />

                                </div>

                                <div>
                                    <label className="texto-notif" >Días resumen de producción</label>
                                </div>
                                <div>
                                    <label className="texto-notif" >Cantidad de días para el envio de resumen de producción</label>
                                </div>
                            </div>

                            {/* fila 11
                            */}
                            <div className="col-notif-center">

                                <div style={{ display: 'grid', justifyContent: 'end' }}>
                                    <button hidden={hiddenBtnEditar} className="btn btn-danger texto-combex" onClick={() => fInsertarConfig()}><i className="fas fa-paper-plane" ></i>&nbsp;&nbsp;Guardar</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default Notificaciones
