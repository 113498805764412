import PropTypes from 'prop-types'
import React, { Component, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import instance from '../../../config'
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"

import { getDecryptedItem, setEncryptedItem } from '../../../util';

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg"
import avatar4 from "../../../assets/images/users/avatar-4.jpg"

//i18n
import { withTranslation } from "react-i18next"

const NotificationDropdown = props => {

  const [openClose, setopenClose] = useState(false);
  const [dataNov, setDataNov] = useState([])
  const [getCantidadNovedad, setCantidadNovedad] = useState(9999)
  const baseUrl = process.env.REACT_APP_API_URL


  const abrirCerrarModal = Modal => {

    setopenClose(!openClose);
  }

  const FuncionListarCasosNov = async () => {
    var postData = {
      nUsu_ID: getDecryptedItem("ID"),
      nNov_Estado: 1,
    }
    await instance
      .post("/Dashboard/Casos/Novedades/Listar", postData)
      .then(response => {
        setDataNov(response.data)
      })
      .catch(error => {

      })
  }

  const SelectDetalle = async (nCas_Id, nCli_Id, nNov_Id) => {
    setEncryptedItem('nCas_Id', nCas_Id);
    setEncryptedItem('CASOIDCli', nCli_Id);

    var postData = {
      nNov_Id: nNov_Id,
    }
    await instance
      .post("/Dashboard/Casos/Novedades/CambiarEstado", postData)
      .then(response => {

      })
      .catch(error => {

      })
  }

  useEffect(async () => {
    FuncionListarCasosNov();


    localStorage.removeItem('CASOIDCli');
    localStorage.removeItem('CASOID');
  }, [])

  return (
    <React.Fragment>
      <Dropdown
        className="dropdown d-inline-block"
        tag="li"
        toggle={() => abrirCerrarModal()}
        isOpen={openClose}
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada" />
          <span className="badge bg-danger rounded-pill" style={{ left: "18px", paddingLeft: "4px" }}>{dataNov.length > 99 ? '99+' : dataNov.length}</span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {"Notificaciones"} {dataNov.length} </h6>
              </Col>
              <div className="col-auto">
                <a href="#" className="small">
                  {" "}

                </a>
              </div>
            </Row>
          </div>
          <div data-simplebar="init" style={{ height: "350px" }}>
            <div class="simplebar-wrapper" style={{ margin: 0 }}>
              <div class="simplebar-height-auto-observer-wrapper">
                <div class="simplebar-height-auto-observer"></div>
              </div>
              <div class="simplebar-mask">
                <div class="simplebar-offset" style={{ right: 0, bottom: 0 }}>
                  <div class="simplebar-content-wrapper" tabindex="0" role="region" aria-label="scrollable content" style={{ height: "70%" }}>
                    <div class="simplebar-content" style={{ padding: 0 }}>
                      {dataNov.slice(0, getCantidadNovedad).map((row, i2) => (

                        <Link key={i2} to="/dashboard-Casos" onClick={() => SelectDetalle(row.nCas_Id, row.nCli_Id, row.nNov_Id)} className="text-reset notification-item">
                          <div className="media">

                            <div className="avatar-xs me-3">
                              <span className="avatar-title bg-primary rounded-circle font-size-16">
                                <i className="bx bx-cart" />
                              </span>
                            </div>
                            <div className="media-body">
                              <h6 className="mt-0 mb-1">
                                {row.cCas_Cod_Externo}
                              </h6>
                              <div className="font-size-12 text-muted">
                                <p className="mb-1">
                                  {row.nJuz_Id2}
                                </p>
                                <p className="mb-1">
                                  {row.cCli_NombreCompleto}
                                </p>
                                <p className="mb-0">
                                  <i className="mdi mdi-clock-outline" />{" "}
                                  {row.nNov_Fecha2}
                                </p>
                              </div>
                            </div>


                          </div>
                        </Link>

                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div class="simplebar-placeholder" style={{ width: "auto", height: "329px;" }}></div>
            </div>
            <div class="simplebar-track simplebar-horizontal" style={{ visibility: "hidden" }}>
              <div class="simplebar-scrollbar" style={{ width: "0px", display: "none" }}></div>
            </div>
            <div class="simplebar-track simplebar-vertical" style={{ visibility: "hidden" }}>
              <div class="simplebar-scrollbar" style={{ width: "0px", display: "none" }}></div>
            </div>
          </div>
          <div className="p-2 border-top d-grid">
            <Link to="/HistorialNovedades" className="btn btn-sm btn-link font-size-14 text-center" >
              <i className="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">Ver mas</span>
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )




}
export default NotificationDropdown