import React, { useState, useEffect } from "react"
import { useContext } from "react"
import {
    Modal,
} from "reactstrap"
import { getDecryptedItem } from '../../../../util';
import ClienteContext from "../../Context/Cliente/ClienteContext";
import ComboContext from "../../Context/Combos/ComboContext";
import instance from '../../../../config'
import Swal from "sweetalert2";
const baseUrl = process.env.REACT_APP_API_URL

const Validacion = (form) => {
    let errors = {};
    let regexLetras = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    let regexCorreo = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let regexNumero = /^[0-9]+$/;
    if (form.cCli_Tab_TipoDoc == 'NN00') {
        errors.cCli_Tab_TipoDoc = "Seleciones un tipo de documento(*)"
    }
    if (!form.cCli_Num_Doc.trim()) {
        errors.cCli_Num_Doc = "Ingrese el número de documento(*)"
    } else if (!regexNumero.test(form.cCli_Num_Doc.trim())) {
        errors.cCli_Num_Doc = "El campo solo acepta números"
    }
    if (!form.cCli_NombreCorto.trim()) {
        errors.cCli_NombreCorto = "Ingrese los nombres(*)"
    } else if (!regexLetras.test(form.cCli_NombreCorto.trim())) {
        errors.cCli_NombreCorto = "El campo solo acepta letras y espacios"
    }
    if (!form.cCli_RazonSocial.trim()) {
        errors.cCli_RazonSocial = "Ingrese apellido paterno(*)"
    } else if (!regexLetras.test(form.cCli_RazonSocial.trim())) {
        errors.cCli_RazonSocial = "El campo a solo acepta letras y espacios"
    }
    if (!form.cCli_Materno.trim()) {
        errors.cCli_Materno = "Ingrese apellido materno(*)"
    } else if (!regexLetras.test(form.cCli_Materno.trim())) {
        errors.cCli_Materno = "El campo  solo acepta letras y espacios"
    }
    if (!form.cCli_Fono1.trim()) {
        errors.cCli_Fono1 = "Ingrese teléfono 1(*)"
    }
    if (!form.cCli_Email1.trim()) {
        errors.cCli_Email1 = "Ingrese correo 1(*)"
    } else if (!regexCorreo.test(form.cCli_Email1.trim())) {
        errors.cCli_Email1 = "El formato del correo es incorrecto"
    }
    return errors;
}
const ValidacionE = (form) => {
    let errors = {};
    let regexLetras = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    let regexCorreo = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let regexNumero = /^[0-9]+$/;

    if (form.cCli_Tab_TipoDoc == 'NN00') {
        errors.cCli_Tab_TipoDoc = "Seleciones un tipo de documento"
    }

    if (!form.cCli_Num_Doc.trim()) {
        errors.cCli_Num_Doc = "(*)"
    } else if (!regexNumero.test(form.cCli_Num_Doc.trim())) {
        errors.cCli_Num_Doc = "El campo solo acepta números"
    }
    if (!form.cCli_RazonSocial.trim()) {
        errors.cCli_RazonSocial = "(*)"
    }

    if (!form.cCli_Fono1.trim()) {
        errors.cCli_Fono1 = "(*)"
    }
    return errors;
}
const ModalCliente = ({ show, onCloseClick, onCloseModal, Condicion, Id, Row }) => {
    const [getDepartamentos, setDepartamentos] = useState([]);
    const [getProvincias, setProvincias] = useState([]);
    const [getDistritos, setDistritos] = useState([]);
    const [getErrores, setErrores] = useState({});
    const [getErroresE, setErroresE] = useState({});
    const [dataComboMoneda, setDataComboMoneda] = useState([])
    const clienteContext = useContext(ClienteContext);
    const comboContext = useContext(ComboContext);
    const [getPersona, setPersona] = useState({
        nClid_Id: Id,
        nUsu_Propietario: parseInt(getDecryptedItem("ID")),
        bCli_Tipo: 0,
        cCli_Tab_TipoDoc: "DO01",
        cCli_Num_Doc: "",
        cCli_Num_DocOtro: "",
        cCli_NombreCorto: "",
        cCli_RazonSocial: "",
        cCli_Materno: "",
        nCli_Ubi: "NN00",
        cCli_Dirección: "",
        cCli_DirecciónPostal: "",
        cCli_Email1: "",
        cCli_Email2: "",
        cCli_Fono1: "",
        cCli_Fono2: "",
        cCli_Web: "",
        cCli_ReferidoPor: "",
        cCli_ConNombre: "",
        cCli_ConApellidos: "",
        cCli_ConCargo: getDecryptedItem("NombreC"),
        cCli_ConFono1: "",
        cCli_ConFono2: "",
        cCli_ConEmail1: "",
        cCli_Tab_Prefijo: "WC01",
        cCli_Logo: "",
        nCli_Estado: 1,
        cCli_Moneda: "MO01",
        cCli_Profesion: "ZA01"
    })
    const [getEmpresa, setEmpresa] = useState({
        nClid_Id: Id,
        nUsu_Propietario: parseInt(getDecryptedItem("ID")),
        bCli_Tipo: 1,
        cCli_Tab_TipoDoc: "DO02",
        cCli_Num_Doc: "",
        cCli_Num_DocOtro: "",
        cCli_NombreCorto: "",
        cCli_RazonSocial: "",
        cCli_Materno: "",
        nCli_Ubi: "NN00",
        cCli_Dirección: "",
        cCli_DirecciónPostal: "",
        cCli_Email1: "",
        cCli_Email2: "",
        cCli_Fono1: "",
        cCli_Fono2: "",
        cCli_Web: "",
        cCli_ReferidoPor: "",
        cCli_ConNombre: "",
        cCli_ConApellidos: "",
        cCli_ConCargo: getDecryptedItem("NombreC"),
        cCli_ConFono1: "",
        cCli_ConFono2: "",
        cCli_ConEmail1: "",
        cCli_ConEmail2: "",
        cCli_Tab_Prefijo: "WC01",
        cCli_Logo: "",
        nCli_Estado: 1,
        cCli_Moneda: "MO01",
        cCli_Profesion: "ZA01"
    })
    const [getUbigeo, setUbigeo] = useState({
        Departamento: "NN00",
        Provincia: "NN00",
    })
    const handleChangeP = e => {
        const { name, value } = e.target
        setPersona(prevState => ({
            ...prevState,
            [name]: value,
        }))

        console.log(getPersona);
    }
    const handleChangeE = e => {
        const { name, value } = e.target
        setEmpresa(prevState => ({
            ...prevState,
            [name]: value,
        }))

    }
    const handleBlur = e => {
        handleChangeP(e)
        setErrores(Validacion(getPersona))
    }
    const handleBlurE = e => {
        handleChangeE(e)
        setErroresE(ValidacionE(getEmpresa))

    }

    const handleChangeProvincia = async e => {
        const { name, value } = e.target
        setDistritos([])

        let _depa = e.target.value

        cargarProvincia(_depa.substr(0, 2))
        setUbigeo(prevState => ({
            ...prevState,
            [name]: value,
        }))
    }
    const handleChangeDistritos = async e => {
        const { name, value } = e.target
        let _prov = e.target.value

        cargarDistrito(_prov.substr(0, 4))
        setUbigeo(prevState => ({
            ...prevState,
            [name]: value,
        }))
    }
    const cargarDepartametos = async () => {
        await instance
            .get("/ubi/ListarDepartamentos")
            .then(response => {

                setDepartamentos(response.data)
            })
            .catch(error => {

            })
    }
    const cargarProvincia = async (_depa) => {
        let postData = {
            _depa: _depa
        }

        await instance
            .post("/ubi/ListarProvincias", postData)
            .then(response => {

                setProvincias(response.data)
            })
            .catch(error => {

            })
    }
    const cargarDistrito = async (_prov) => {
        let postData = {
            _prov: _prov
        }

        await instance
            .post("/ubi/ListarDistritos", postData)
            .then(response => {

                setDistritos(response.data)
            })
            .catch(error => {

            })
    }
    const FuncionValidarGuardarCliente = async () => {
        console.log(getErrores)
        console.log(getPersona)
        if (Id > 0) {
            if (Condicion == 2) {
                if (Object.keys(getErrores).length === 0) {
                    console.log("Actualizando");
                    clienteContext.FuncionActualizarCliente(getPersona, onCloseModal)

                } else {
                    Swal.fire({
                        title: "Mensaje",
                        text: "Complete los campos requeridos",
                        icon: "error",
                        confirmButtonText: "Aceptar",
                    })
                }
            } else {
                if (Object.keys(getErroresE).length === 0) {
                    clienteContext.FuncionActualizarCliente(getEmpresa, onCloseModal)

                } else {
                    Swal.fire({
                        title: "Mensaje",
                        text: "Complete los campos requeridos",
                        icon: "error",
                        confirmButtonText: "Aceptar",
                    })
                }
            }

        } else {
            if (Condicion == 2) {
                if (Object.keys(getErrores).length === 0) {
                    console.log("Registrar cliente");
                    console.log(getPersona);
                    clienteContext.FuncionRegistrarCliente(getPersona)
                    onCloseModal()
                } else {
                    Swal.fire({
                        title: "Mensaje",
                        text: "Complete los campos requeridos",
                        icon: "error",
                        confirmButtonText: "Aceptar",
                    })
                }
            } else {
                if (Object.keys(getErroresE).length === 0) {
                    clienteContext.FuncionRegistrarCliente(getEmpresa)
                    onCloseModal()
                } else {
                    Swal.fire({
                        title: "Mensaje",
                        text: "Complete los campos requeridos",
                        icon: "error",
                        confirmButtonText: "Aceptar",
                    })
                }
            }
        }
    }
    const FuncionListarComboMoneda = async () => {
        var postData = {
            Prefijo: "MO",
        }
        await instance
            .post("/Maestros/Data", postData)
            .then(response => {
                setDataComboMoneda(response.data)

            })
            .catch(error => {

            })
    }
    useEffect(() => {

        console.log("Id : ", Id);
        console.log("Row : ", Row);

        FuncionListarComboMoneda()
        cargarDepartametos()
        comboContext.FuncionListarTDocumento()
        comboContext.FuncionListarGrupo()
        if (Id > 0) {

            cargarProvincia(Row.nCli_Ubi.substr(0, 2))
            cargarDistrito(Row.nCli_Ubi.substr(0, 4))
            setUbigeo(prevState => ({
                ...prevState,
                Departamento: Row.nCli_Ubi.substr(0, 2) + "0000",
                Provincia: Row.nCli_Ubi.substr(0, 4) + "00",
            }))
            setPersona(Row)
            setPersona(prevState => ({
                ...prevState,
                bCli_Tipo: 0,
                cCli_Profesion: "ZA01",
            }))
            setEmpresa(Row)
            setEmpresa(prevState => ({
                ...prevState,
                bCli_Tipo: 1,
            }))
            if (Condicion == 2) {
                setErrores(Validacion(Row));
            } else {
                setErroresE(ValidacionE(Row));
            }
        } else {
            setPersona({
                nClid_Id: Id,
                nUsu_Propietario: parseInt(getDecryptedItem("ID")),
                bCli_Tipo: 0,
                cCli_Tab_TipoDoc: "DO01",
                cCli_Num_Doc: "",
                cCli_Num_DocOtro: "",
                cCli_NombreCorto: "",
                cCli_RazonSocial: "",
                cCli_Materno: "",
                nCli_Ubi: "011505",
                cCli_Dirección: "",
                cCli_DirecciónPostal: "",
                cCli_Email1: "",
                cCli_Email2: "",
                cCli_Fono1: "",
                cCli_Fono2: "",
                cCli_Web: "",
                cCli_ReferidoPor: "",
                cCli_ConNombre: "",
                cCli_ConApellidos: "",
                cCli_ConCargo: getDecryptedItem("NombreC"),
                cCli_ConFono1: "",
                cCli_ConFono2: "",
                cCli_ConEmail1: "",
                cCli_Tab_Prefijo: "WC01",
                cCli_Logo: "",
                nCli_Estado: 1,
                cCli_Profesion: "ZA01"
            })
            setEmpresa({
                nClid_Id: Id,
                nUsu_Propietario: parseInt(getDecryptedItem("ID")),
                bCli_Tipo: 1,
                cCli_Tab_TipoDoc: "DO02",
                cCli_Num_Doc: "",
                cCli_Num_DocOtro: "",
                cCli_NombreCorto: "",
                cCli_RazonSocial: "",
                cCli_Materno: "",
                nCli_Ubi: "011505",
                cCli_Dirección: "",
                cCli_DirecciónPostal: "",
                cCli_Email1: "",
                cCli_Email2: "",
                cCli_Fono1: "",
                cCli_Fono2: "",
                cCli_Web: "",
                cCli_ReferidoPor: "",
                cCli_ConNombre: "",
                cCli_ConApellidos: "",
                cCli_ConCargo: getDecryptedItem("NombreC"),
                cCli_ConFono1: "",
                cCli_ConFono2: "",
                cCli_ConEmail1: "",
                cCli_Tab_Prefijo: "WC01",
                cCli_Logo: "",
                nCli_Estado: 1,
                cCli_Profesion: "ZA01"
            })
            if (Condicion == 2) {
                setErrores(Validacion(getPersona));
            } else {
                setErroresE(ValidacionE(getEmpresa));
            }
        }
    }, []);
    return (
        <Modal size={(() => {
            if (Condicion == 1) {
                return ""
            } else {
                return "lg"
            }
        })()} isOpen={show}>
            <div
                className="modal-header ModalHeaderColor"
            >
                <h5
                    style={{ color: "#fff" }}
                    className="modal-title mt-0"
                    id="myModalLabel"
                >
                    {Id ? "Editar " : "Nueva "}
                    {(() => {
                        if (Condicion == 3) {
                            return "Empresa"
                        } else {
                            return "Persona"
                        }
                    })()}
                </h5>
                <button
                    type="button"
                    onClick={() => onCloseClick()}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                {(() => {
                    //Formulario Nuevo Persona Solo Nombres
                    if (Condicion == 1) {
                        return (
                            <>
                                <div className="mb-3 row">
                                    <div className="col-md-12">
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="cCli_NombreCorto"
                                            onChange={handleChangeP}
                                        />
                                        <label className="col-form-label labelDown">
                                            Nombre (Razón Social)
                                        </label>
                                    </div>
                                </div>
                            </>
                        )
                    }
                    //Formulario Nueva Persona
                    else if (Condicion == 2) {
                        return (
                            <>
                                <div className="mb-3 row">
                                    <div className="col-md-4">
                                        <select
                                            className="form-select inputDown"
                                            name="cCli_Tab_TipoDoc"
                                            onChange={handleChangeP}
                                            onBlur={handleBlur}
                                            value={getPersona && getPersona.cCli_Tab_TipoDoc}
                                        >
                                            <option value="NN00">Seleccionar...</option>
                                            {comboContext.tdocumentos.map((row, i) => (
                                                <option key={i} value={row.cTabCodigo}>
                                                    {row.cTabNombre}
                                                </option>
                                            ))}
                                        </select>
                                        {getErrores.cCli_Tab_TipoDoc && <label className="col-form-label labelDown labelRed">
                                            {getErrores.cCli_Tab_TipoDoc}</label> || <label className="col-form-label labelDown">
                                                Seleccione tipo de documento</label>}
                                    </div>
                                    <div className="col-md-4">
                                        <input
                                            className="form-control inputDown"
                                            type="text"
                                            placeholder="Número Documento"
                                            name="cCli_Num_Doc"
                                            onChange={handleChangeP}
                                            onBlur={handleBlur}
                                            value={getPersona && getPersona.cCli_Num_Doc}
                                            maxLength="20"
                                        />
                                        {getErrores.cCli_Num_Doc && <label className="col-form-label labelDown labelRed">
                                            {getErrores.cCli_Num_Doc}</label> || <label className="col-form-label labelDown">
                                                Ingrese el número de documento</label>}


                                    </div>
                                    <div className="col-md-4">
                                        <select
                                            className="form-select inputDown"
                                            name="cCli_Moneda"
                                            onChange={handleChangeP}
                                            defaultValue="MO01"
                                            value={getPersona && getPersona.cCli_Moneda}
                                        >
                                            <option value="NN00">Seleccionar...</option>
                                            {dataComboMoneda.map((row, i) => (
                                                <option key={i} value={row.cTabCodigo}>
                                                    {row.cTabNombre}
                                                </option>
                                            ))}
                                        </select>
                                        <label className="col-form-label labelDown">
                                            Moneda a usar para usuarios
                                        </label>
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-md-4">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Nombre"
                                            name="cCli_NombreCorto"
                                            onChange={handleChangeP}
                                            onBlur={handleBlur}
                                            value={getPersona && getPersona.cCli_NombreCorto}
                                            maxLength="50"
                                        />
                                        {getErrores.cCli_NombreCorto && <label className="col-form-label labelDown labelRed">
                                            {getErrores.cCli_NombreCorto}</label> || <label className="col-form-label labelDown">
                                                Ingrese los nombres</label>}

                                    </div>
                                    <div className="col-md-4">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Apellido Paterno"
                                            name="cCli_RazonSocial"
                                            onChange={handleChangeP}
                                            onBlur={handleBlur}
                                            value={getPersona && getPersona.cCli_RazonSocial}
                                            maxLength="50"
                                        />
                                        {getErrores.cCli_RazonSocial && <label className="col-form-label labelDown labelRed">
                                            {getErrores.cCli_RazonSocial}</label> || <label className="col-form-label labelDown">
                                                Ingrese apellido paterno</label>}
                                    </div>
                                    <div className="col-md-4">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Apellido Materno"
                                            name="cCli_Materno"
                                            onChange={handleChangeP}
                                            onBlur={handleBlur}
                                            value={getPersona && getPersona.cCli_Materno}
                                            maxLength="50"
                                        />
                                        {getErrores.cCli_Materno && <label className="col-form-label labelDown labelRed">
                                            {getErrores.cCli_Materno}</label> || <label className="col-form-label labelDown">
                                                Ingrese apellido materno</label>}
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-md-4">
                                        <select className="form-select inputDown"
                                            onChange={handleChangeProvincia}
                                            name="Departamento"
                                            value={getUbigeo.Departamento}>
                                            <option value="NN00">Departamento</option>
                                            {getDepartamentos.map((row, i) => (
                                                <option key={i} value={row.cUbi_Codigo}>
                                                    {row.cUbi_Detalle}
                                                </option>
                                            ))}
                                        </select>
                                        <label className="col-form-label labelDown">
                                            Seleccione departamento
                                        </label>
                                    </div>
                                    <div className="col-md-4">
                                        <select className="form-select inputDown"
                                            onChange={handleChangeDistritos}
                                            name="Provincia"
                                            value={getUbigeo.Provincia}>
                                            <option value="NN00">Provincia</option>
                                            {getProvincias.map((row, i) => (
                                                <option key={i} value={row.cUbi_Codigo}>
                                                    {row.cUbi_Detalle}
                                                </option>
                                            ))}
                                        </select>
                                        <label className="col-form-label labelDown">
                                            Seleccione provincia
                                        </label>
                                    </div>
                                    <div className="col-md-4">
                                        <select className="form-select inputDown"
                                            name="nCli_Ubi"
                                            value={getPersona && getPersona.nCli_Ubi}
                                            onChange={handleChangeP}>
                                            <option value="NN00">Distrito</option>
                                            {getDistritos.map((row, i) => (
                                                <option key={i} value={row.cUbi_Codigo}>
                                                    {row.cUbi_Detalle}
                                                </option>
                                            ))}
                                        </select>
                                        <label className="col-form-label labelDown">
                                            Seleccione distrito
                                        </label>
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-md-12">
                                        <textarea
                                            className="form-control"
                                            type="text"
                                            placeholder="Dirección"
                                            name="cCli_Dirección"
                                            onChange={handleChangeP}
                                            value={getPersona && getPersona.cCli_Dirección}
                                            maxLength="250"
                                        ></textarea>
                                        <label className="col-form-label labelDown">
                                            Ingrese dirección
                                        </label>
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-md-12">
                                        <textarea
                                            className="form-control"
                                            type="text"
                                            placeholder="Referencia para llegar a dirección"
                                            name="cCli_DirecciónPostal"
                                            onChange={handleChangeP}
                                            value={getPersona && getPersona.cCli_DirecciónPostal}
                                            maxLength="250"
                                        ></textarea>
                                        <label className="col-form-label labelDown">
                                            Ingrese referencia para llegar a dirección
                                        </label>
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-md-6">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Teléfono 1"
                                            name="cCli_Fono1"
                                            onChange={handleChangeP}
                                            onBlur={handleBlur}
                                            value={getPersona && getPersona.cCli_Fono1}
                                            maxLength={25}
                                        />
                                        {getErrores.cCli_Fono1 && <label className="col-form-label labelDown labelRed">
                                            {getErrores.cCli_Fono1}</label> || <label className="col-form-label labelDown">
                                                Ingrese teléfono 1</label>}
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Teléfono 2"
                                            name="cCli_Fono2"
                                            onChange={handleChangeP}
                                            value={getPersona && getPersona.cCli_Fono2}
                                            maxLength={25}
                                        />
                                        <label className="col-form-label labelDown">
                                            Ingrese teléfono 2
                                        </label>
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-md-6">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Correo 1"
                                            name="cCli_Email1"
                                            onBlur={handleBlur}
                                            onChange={handleChangeP}
                                            value={getPersona && getPersona.cCli_Email1}
                                            maxLength={50}
                                        />
                                        {getErrores.cCli_Email1 && <label className="col-form-label labelDown labelRed">
                                            {getErrores.cCli_Email1}</label> || <label className="col-form-label labelDown">
                                                Ingrese correo 1</label>}
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Correo 2"
                                            name="cCli_Email2"
                                            onChange={handleChangeP}
                                            value={getPersona && getPersona.cCli_Email2}
                                            maxLength={50}
                                        />
                                        <label className="col-form-label labelDown">
                                            Ingrese correo 2
                                        </label>
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-md-6">
                                        <select
                                            className="form-select inputDown"
                                            name="cCli_Profesion"
                                            onChange={handleChangeP}
                                            value={getPersona && getPersona.cCli_Profesion}
                                        >
                                            {comboContext.grupo.map((row, i) => (
                                                <option key={i} value={row.cTabCodigo}>
                                                    {row.cTabNombre}
                                                </option>
                                            ))}
                                        </select>
                                        <label className="col-form-label labelDown">
                                            Seleccione Grupo
                                        </label>
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            className="form-control"
                                            name="cCli_ConCargo"
                                            value={getPersona && getPersona.cCli_ConCargo}
                                            readOnly
                                        />
                                        <label className="col-form-label labelDown">
                                            Encargado
                                        </label>
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-md-6">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Referido por"
                                            name="cCli_ReferidoPor"
                                            onChange={handleChangeP}
                                            value={getPersona && getPersona.cCli_ReferidoPor}
                                            maxLength="100"
                                        />
                                        <label className="col-form-label labelDown">
                                            Ingrese Referido
                                        </label>
                                    </div>
                                </div>
                            </>
                        )
                    }
                    //Formulario Nueva Empresa
                    else if (Condicion == 3) {
                        return (
                            <>
                                <div className="mb-3 row">
                                    <strong>Datos de la Empresa.</strong>
                                    <hr style={{ color: "#000" }} />
                                    <div className="col-md-4">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="RUC"
                                            name="cCli_Num_Doc"
                                            onBlur={handleBlurE}
                                            onChange={handleChangeE}
                                            value={getEmpresa && getEmpresa.cCli_Num_Doc}
                                            maxLength="20"
                                        />
                                        <label className="col-form-label labelDown">Ingrese Ruc</label>{getErroresE.cCli_Num_Doc && <label className="col-form-label labelDown labelRed">{getErroresE.cCli_Num_Doc}</label>}
                                    </div>
                                    <div className="col-md-4">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Nombre Corto de la empresa"
                                            name="cCli_NombreCorto"
                                            onChange={handleChangeE}
                                            value={getEmpresa && getEmpresa.cCli_NombreCorto}
                                            maxLength="50"
                                        />
                                        <label className="col-form-label labelDown">
                                            Ingrese nombre corto de la empresa
                                        </label>
                                    </div>
                                    <div className="col-md-4">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Razón Social"
                                            name="cCli_RazonSocial"
                                            onChange={handleChangeE}
                                            onBlur={handleBlurE}
                                            value={getEmpresa && getEmpresa.cCli_RazonSocial}
                                            maxLength="50"
                                        />
                                        <label className="col-form-label labelDown">Ingrese Razon social</label>{getErroresE.cCli_RazonSocial && <label className="col-form-label labelDown labelRed">{getErroresE.cCli_RazonSocial}</label>}
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-md-4">
                                        <select className="form-select inputDown"
                                            onChange={handleChangeProvincia}
                                            name="Departamento"
                                            value={getUbigeo.Departamento}>
                                            <option value="NN00">Departamento</option>
                                            {getDepartamentos.map((row, i) => (
                                                <option key={i} value={row.cUbi_Codigo}>
                                                    {row.cUbi_Detalle}
                                                </option>
                                            ))}
                                        </select>
                                        <label className="col-form-label labelDown">
                                            Seleccione departamento
                                        </label>
                                    </div>
                                    <div className="col-md-4">
                                        <select className="form-select inputDown"
                                            onChange={handleChangeDistritos}
                                            name="Provincia"
                                            value={getUbigeo.Provincia}
                                        >
                                            <option value="NN00">Provincia</option>
                                            {getProvincias.map((row, i) => (
                                                <option key={i} value={row.cUbi_Codigo}>
                                                    {row.cUbi_Detalle}
                                                </option>
                                            ))}
                                        </select>
                                        <label className="col-form-label labelDown">
                                            Seleccione provincia
                                        </label>
                                    </div>
                                    <div className="col-md-4">
                                        <select className="form-select inputDown"
                                            name="nCli_Ubi"
                                            value={getEmpresa && getEmpresa.nCli_Ubi}
                                            onChange={handleChangeE}
                                        >
                                            <option value="NN00">Distrito</option>
                                            {getDistritos.map((row, i) => (
                                                <option key={i} value={row.cUbi_Codigo}>
                                                    {row.cUbi_Detalle}
                                                </option>
                                            ))}
                                        </select>
                                        <label className="col-form-label labelDown">
                                            Seleccione distrito
                                        </label>
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-md-12">
                                        <textarea
                                            name="cCli_Dirección"
                                            onChange={handleChangeE}
                                            className="form-control"
                                            type="text"
                                            placeholder="Dirección Fiscal"
                                            value={getEmpresa && getEmpresa.cCli_Dirección}
                                            maxLength="250"
                                        ></textarea>
                                        <label className="col-form-label labelDown">
                                            Ingrese dirección fiscal
                                        </label>
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-md-12">
                                        <textarea
                                            name="cCli_DirecciónPostal"
                                            onChange={handleChangeE}
                                            className="form-control"
                                            type="text"
                                            placeholder="Dirección Postal"
                                            value={getEmpresa && getEmpresa.cCli_DirecciónPostal}
                                            maxLength="250"
                                        ></textarea>
                                        <label className="col-form-label labelDown">
                                            Ingrese dirección postal
                                        </label>
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-md-6">
                                        <input
                                            name="cCli_Fono1"
                                            onChange={handleChangeE}
                                            onBlur={handleBlurE}
                                            className="form-control"
                                            type="text"
                                            placeholder="Teléfono 1"
                                            value={getEmpresa && getEmpresa.cCli_Fono1}
                                            maxLength={25}
                                        />
                                        <label className="col-form-label labelDown">Ingrese Telefono 1</label>{getErroresE.cCli_Fono1 && <label className="col-form-label labelDown labelRed">{getErroresE.cCli_Fono1}</label>}
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            name="cCli_Fono2"
                                            onChange={handleChangeE}
                                            className="form-control"
                                            type="text"
                                            placeholder="Teléfono 2"
                                            value={getEmpresa && getEmpresa.cCli_Fono2}
                                            maxLength={25}
                                        />
                                        <label className="col-form-label labelDown">
                                            Ingrese teléfono 2
                                        </label>
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-md-4">
                                        <input
                                            name="cCli_Web"
                                            onChange={handleChangeE}
                                            className="form-control"
                                            type="text"
                                            placeholder="Sitio web"
                                            value={getEmpresa && getEmpresa.cCli_Web}
                                            maxLength="50"
                                        />
                                        <label className="col-form-label labelDown">
                                            Ingrese sitio web
                                        </label>
                                    </div>
                                    <div className="col-md-4">
                                        <select
                                            className="form-select inputDown"
                                            name="cCli_Profesion"
                                            onChange={handleChangeE}
                                            value={getEmpresa && getEmpresa.cCli_Profesion}
                                        >
                                            {comboContext.grupo.map((row, i) => (
                                                <option key={i} value={row.cTabCodigo}>
                                                    {row.cTabNombre}
                                                </option>
                                            ))}
                                        </select>
                                        <label className="col-form-label labelDown">
                                            Seleccione Grupo
                                        </label>
                                    </div>
                                    <div className="col-md-4">
                                        <select
                                            className="form-select inputDown"
                                            name="cCli_Moneda"
                                            onChange={handleChangeE}
                                            value={getEmpresa && getEmpresa.cCli_Moneda}
                                        >
                                            <option value="NN00">Seleccionar...</option>
                                            {dataComboMoneda.map((row, i) => (
                                                <option key={i} value={row.cTabCodigo}>
                                                    {row.cTabNombre}
                                                </option>
                                            ))}
                                        </select>
                                        <label className="col-form-label labelDown">
                                            Moneda a usar para usuarios
                                        </label>
                                    </div>
                                </div>
                                <strong>Datos del contacto</strong>
                                <hr style={{ color: "#000", marginTop: "0px" }} />
                                <div className="mb-3 row">
                                    <div className="col-md-1" style={{ width: "64px" }}>
                                        <select
                                            className="form-select"
                                            name="cCli_Tab_Prefijo"
                                            onChange={handleChangeE}
                                            style={{ width: "62px" }}
                                            value={getEmpresa && getEmpresa.cCli_Tab_Prefijo}
                                        >
                                            <option value="WC01">Sr.</option>
                                            <option value="WC01">Sra.</option>
                                            <option value="WC03">Srta.</option>
                                        </select>
                                        <label className="col-form-label labelDown">
                                            Prefijo
                                        </label>
                                    </div>
                                    <div className="col-md-4">
                                        <input
                                            name="cCli_ConNombre"
                                            onChange={handleChangeE}
                                            className="form-control"
                                            type="text"
                                            placeholder="Nombre"
                                            value={getEmpresa && getEmpresa.cCli_ConNombre}
                                            maxLength="50"
                                        />
                                        <label className="col-form-label labelDown">
                                            Ingrese Nombre
                                        </label>
                                    </div>
                                    <div className="col-md-4">
                                        <input
                                            name="cCli_ConApellidos"
                                            onChange={handleChangeE}
                                            className="form-control"
                                            type="text"
                                            placeholder="Apellidos"
                                            value={getEmpresa && getEmpresa.cCli_ConApellidos}
                                            maxLength="50"
                                        />
                                        <label className="col-form-label labelDown">
                                            Ingrese Apellidos
                                        </label>
                                    </div>
                                    <div className="col-md-3">
                                        <input
                                            name="cCli_ConCargo"
                                            onChange={handleChangeE}
                                            className="form-control"
                                            type="text"
                                            placeholder="Cargo"
                                            value={getEmpresa && getEmpresa.cCli_ConCargo}
                                            maxLength="50"
                                        />
                                        <label className="col-form-label labelDown">
                                            Ingrese Cargo
                                        </label>
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-md-6">
                                        <input
                                            name="cCli_ConFono1"
                                            onChange={handleChangeE}
                                            className="form-control"
                                            type="text"
                                            placeholder="Teléfono 1"
                                            value={getEmpresa && getEmpresa.cCli_ConFono1}
                                            maxLength={25}
                                        />
                                        <label className="col-form-label labelDown">
                                            Ingrese teléfono 1
                                        </label>
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            name="cCli_ConFono2"
                                            onChange={handleChangeE}
                                            className="form-control"
                                            type="text"
                                            placeholder="Teléfono 2"
                                            value={getEmpresa && getEmpresa.cCli_ConFono2}
                                            maxLength={25}
                                        />
                                        <label className="col-form-label labelDown">
                                            Ingrese teléfono 2
                                        </label>
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-md-6">
                                        <input
                                            name="cCli_ConEmail1"
                                            onChange={handleChangeE}
                                            className="form-control"
                                            type="text"
                                            placeholder="Correo 1"
                                            value={getEmpresa && getEmpresa.cCli_ConEmail1}
                                            maxLength="50"
                                        />
                                        <label className="col-form-label labelDown">
                                            Ingrese correo 1
                                        </label>
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            name="cCli_ConEmail2"
                                            onChange={handleChangeE}
                                            className="form-control"
                                            type="text"
                                            placeholder="Correo 2"
                                            value={getEmpresa && getEmpresa.cCli_ConEmail2}
                                            maxLength="50"
                                        />
                                        <label className="col-form-label labelDown">
                                            Ingrese correo 2
                                        </label>
                                    </div>
                                </div>
                            </>
                        )
                    }
                })()}
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    onClick={() => onCloseClick()}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                >
                    Cerrar
                </button>
                <button
                    type="button"
                    className="btn btn-danger waves-effect waves-light"
                    onClick={() => { FuncionValidarGuardarCliente() }
                    }
                >
                    Guardar
                </button>
            </div>
        </Modal>
    )
}
export default ModalCliente