import CryptoJS from 'crypto-js';

const SECRET_KEY = 'j9X$m2P#qL7@vF5^kR3&wZ8!hN6*tB4';

// Función para encriptar
const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
};

// Función para desencriptar
const decryptData = (encryptedData) => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

// Función para guardar datos encriptados en localStorage
const setEncryptedItem = (key, value) => {
  const encryptedValue = encryptData(value);
  localStorage.setItem(key, encryptedValue);
};

// Función para obtener y desencriptar datos de localStorage
const getDecryptedItem = (key) => {
  const encryptedValue = localStorage.getItem(key);
  if (encryptedValue) {
    return decryptData(encryptedValue.toString());
  }
  return null;
};

export { setEncryptedItem, getDecryptedItem };