import React, { useState, useEffect, useContext } from "react"
import {
    Col,
    Row,
    Card,
    CardBody,
    DropdownItem,
    DropdownMenu,
    UncontrolledDropdown,
    CardTitle,
    DropdownToggle,
    Modal,
} from "reactstrap"
import instance from '../../../../config'
import { Link } from "react-router-dom";
import Swal from "sweetalert2"
import ReportesContext from "pages/Menus/Context/Reportes/ReportesContext";
import { MDBDataTable } from "mdbreact";
import "../../../../assets/scss/tablescss/FormatTable.scss"
import FileDownload from "js-file-download";
import { getDecryptedItem, setEncryptedItem } from '../../../../util';
import axios from "axios";
import { get, set } from "lodash";
var meses = new Array("Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre");
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var mesIni = Fecha.getMonth() - 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
if (mesIni < 10) mesIni = "0" + mesIni //agrega cero si el menor de 10
const baseUrl = process.env.REACT_APP_API_URL
const ListaReporteProcesado = props => {
    const reportesContext = useContext(ReportesContext);
    const [modalInsertar, setModalInsertar] = useState(false)
    const [modalAprobar, setModalAprobar] = useState(false)
    const [getDisableCliente, setDisableCliente] = useState(false)
    const [hiddenCliente, setHiddenCliente] = useState(false)
    const [getAbogadoResponsable, setAbogadoResponsable] = useState({})
    const [getCaso, setCaso] = useState({})
    const [getTitulo, setTitulo] = useState({
        nombre: "",
        estado: ""
    })
    const [getNumeroFactura, setNumeroFactura] = useState({
        Factura: "",
        Codigo: "",
        id: "",
        nCas_Id: "",
        monto: 0,   
        montoH:0, 
        estado: 0,
        codigoLiq:0
    })

    const [getAprobado, setAprobado] = useState("")


    const FuncionGenerarPDFLiQ = async (_CodigoLiq, _ArrayDatos, _Tipo) => {
        try {
            var postData = {
                CodigoLiq: _CodigoLiq,
                ArrayDatos: _ArrayDatos,
                TGastos: _ArrayDatos.MontoG,
                THonorarios: _ArrayDatos.MontoH,
                Tipo: _Tipo,
                FechaIniPeriodo: _ArrayDatos.FechaIniPeriodo,
                FechaFinPeriodo: _ArrayDatos.FechaFinPeriodo,
            }
            console.log(postData)
            if (_Tipo == "R") {
                await instance
                    .post("/Descargar/ReportePDF", postData, { responseType: 'blob' })
                    .then(response => {
                        FileDownload(response.data, _Tipo + "-" + _CodigoLiq + ".pdf")
                    })
            } else {
                await instance
                    .post("/Generar/ReportePDF", postData, { responseType: 'blob' })
                    .then(response => {
                        FileDownload(response.data, _Tipo + "-" + _CodigoLiq + ".pdf")
                    })
            }
        } catch (error) {

        }

    }

    const mostrarItem = () => {
        // Si ROL_ID es 9, no se muestra el elemento

        let rol = getDecryptedItem("RolID");

        console.log(rol);

        if (rol == 9) {
            console.log("rol : ", rol);
            return true;
        }
        // En cualquier otro caso, se muestra el elemento
        return false;
    };

    const mostrarItemRevertirLiq = (estado) => {

        let rol = getDecryptedItem("RolID");
  
        if (rol == 1) {
            console.log("rol : ", rol);

            if(estado == 1|| estado ==2){
                console.log("1");
                return false;
            }
            else{
                console.log("2");
                return true;
            }
           
        }
        else {
            console.log("3");
            return true;
        }
    };


    const datas = {
        columns: [
            {
                label: "Código",
                field: "Numero",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "Fecha",
                field: "Fecha",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "Cliente",
                field: "cCli_NombreCompleto",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            }
        ],
        rows: reportesContext.lqpArray,
    }

    const Acciones = {
        columns: [
            ...datas.columns,
            {
                label: "Caso",
                field: "Caso",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "Comprobante",
                field: "Factura",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "Total Gastos",
                field: "TGasto",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "Total Honorarios",
                field: "THonorarios",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "Estado",
                field: "estado",
                sort: "asc",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
            {
                label: "Acciones",
                field: "acciones",
                fixedColumns: true,
                attributes: {
                    className: "alignCenter",
                },
            },
        ],
        rows: [
            ...datas.rows.map((row, id) => ({
                ...row,
                Caso: (
                    <Link style={{ color: "black" }} onClick={() => {
                        setEncryptedItem('nCas_Id', row.nCas_Id)
                        setEncryptedItem('CASOIDCli', row.nCli_Id)
                    }
                    } to="/dashboard-Casos">
                        {row.cCas_Titulo} / {row.cCas_Cod_Externo}
                    </Link>
                ),
                TGasto: (
                    <p>{row.cTabSimbolo} {row.MontoG}</p>
                ),
                THonorarios: (
                    <p>{row.cTabSimbolo} {row.MontoH}</p>
                ),
                Caso: (
                    <Link style={{ color: "black" }} onClick={() => {
                        setEncryptedItem('nCas_Id', row.nCas_Id)
                        setEncryptedItem('CASOIDCli', row.nCli_Id)
                    }
                    } to="/dashboard-Casos">
                        {row.cCas_Titulo} / {row.cCas_Cod_Externo}
                    </Link>
                ),
                estado: (
                    <>
                        {(() => {
                            if (row.Estado2 == "0") {
                                return (
                                    <>
                                        <i style={{ fontSize: "16px", color: "#AA1B0E" }} className="icon-esfera-2d" /> {row.Estado} </>
                                )
                            } else if (row.Estado2 == "1") {
                                return (<>
                                    <i style={{ fontSize: "16px", color: "#1F60AB" }} className="icon-esfera-2d" />{row.Estado} </>
                                )
                            } else if (row.Estado2 == "2") {
                                return (<>
                                    <i style={{ fontSize: "16px", color: "#AA1B0E" }} className="icon-esfera-2d" />{row.Estado} </>
                                )
                            } else if (row.Estado2 == "3") {
                                return (<>
                                    <i style={{ fontSize: "16px", color: "#00A94F" }} className="icon-esfera-2d" />{row.Estado} </>
                                )
                            } else if (row.Estado2 == "4") {
                                return (<>
                                    <i style={{ fontSize: "16px", color: "#00A94F" }} className="icon-esfera-2d" />{row.Estado} </>
                                )
                            }
                        })()}{" "}
                    </>
                ),
                acciones: (
                    <div>
                        <UncontrolledDropdown style={{ float: "right" }}>
                            <DropdownToggle tag="a" className="dropdown-toggle">
                                <i className="bx bx-dots-vertical-rounded" />
                            </DropdownToggle>
                            <DropdownMenu right>

                                <DropdownItem onClick={() => {
                                    setEncryptedItem('nCas_Id', row.nCas_Id)
                                    setEncryptedItem('CASOIDCli', row.nCli_Id)
                                    }}>
                                    <Link style={{ color: "black" }} to="/dashboard-Casos">Ver Caso</Link>
                                </DropdownItem>

                                <DropdownItem hidden={mostrarItemRevertirLiq(row.Estado2)} onClick={() => {
                                    FuncionRevertirLiquidacion(row.Numero, row, row.Estado.substring(0, 1))
                                    }}>
                                    <Link to="#" style={{ color: "black" }} >Revertir liquidación</Link>
                                </DropdownItem>

                                <DropdownItem onClick={() => {
                                    FuncionGenerarPDFLiQ(row.Numero, row, row.Estado.substring(0, 1))
                                    }}>

                                    <Link to="#" style={{ color: "black" }} >Descargar PDF</Link>
                                </DropdownItem>

                                <DropdownItem hidden={mostrarItem()} onClick={() => {
                                        abrirCerrarModal(1)
                                        setNumeroFactura(prevState => ({
                                            ...prevState,
                                            Codigo: row.Numero,
                                            id: row.id
                                        }))
                                    }}>

                                    <Link to="#" style={{ color: "black" }} >Agregar numero de comprobante</Link>
                                </DropdownItem>

                                <DropdownItem onClick={() => {
                                        abrirCerrarModal(2, row, 1), 
                                        setNumeroFactura(prevState => ({
                                            ...prevState,
                                            Codigo: row.Numero,
                                            id: row.id,
                                            nCas_Id: row.nCas_Id,
                                            monto: row.MontoG,
                                            estado: row.Estado2,
                                            montoH: row.MontoH,
                                            codigoLiq:row.Numero
                                        })) }}>
                                    <Link to="#" style={{ color: "black" }}> Aprobado</Link>
                                </DropdownItem>

                                <DropdownItem onClick={() => {
                                        abrirCerrarModal(2, row, 2), 
                                        setNumeroFactura(prevState => ({
                                            ...prevState,
                                            Codigo: row.Numero,
                                            id: row.id,
                                            nCas_Id: row.nCas_Id,
                                            monto: row.MontoG,
                                            estado: row.Estado2
                                        })) }}>
                                    <Link to="#" style={{ color: "black" }}> Rechazado</Link>
                                </DropdownItem>

                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                ),
            })),
        ],
    }


    const LiquidacionCambiarEstado = async (_id, _estado) => {

        let postData = {
            id: _id,
            estado: _estado,
            fecha:ano + "-" + mes + "-" + dia,
            descripcion: getAprobado
        }

        await instance
            .post("/Liquidacion/LiquidacionActualizarEstado", postData)
            .then(res => {
                FuncionBuscarCasos();
                setAprobado("");
            })
            .catch(err => {

                console.log(err)

            })

    }


    const abrirCerrarModal = async (Modal, row, opcion) => {
        if (Modal == 1) {
            setNumeroFactura(prevState => ({
                ...prevState,
                Codigo: "",
                Factura: "",
            }))
            if (!modalInsertar == false) {
                Swal.fire({
                    html: "¿Seguro que desea salir sin guardar los datos?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si",
                    cancelButtonText: "No",
                }).then(result => {
                    if (result.isConfirmed) {
                        setModalInsertar(!modalInsertar)
                        reportesContext.FuncionListarLiquidacionProcesada()
                    }
                })
            } else {
                setModalInsertar(!modalInsertar);
            }
        }
        else if (Modal == 2){

            setTitulo({});

            if(opcion ==1){
                setTitulo({
                    nombre: "Aprobar liquidación",
                    estado: "1"
                })
            }
            else if(opcion ==2){
                setTitulo({
                    nombre: "Rechazar liquidación",
                    estado: "2"
                });
            }

            setModalAprobar(!modalAprobar);
            console.log("modalAprobar", modalAprobar)
            console.log("getNumeroFactura", getNumeroFactura)
            let AbogadoResposable = await FuncionObtenerAbogadoResponsable(row.nCas_Id);
            setAbogadoResponsable(AbogadoResposable[0]);

            let Caso = await FuncionObtenerCaso(row.nCas_Id);
            setCaso(Caso[0]);

            console.log("AbogadoResposable", AbogadoResposable);
            console.log("Caso", Caso);
          
        }
    }
    const handleChange = e => {
        const { name, value } = e.target
        setNumeroFactura(prevState => ({
            ...prevState,
            Factura: value,
        }))

        if(name == "Descripcion"){
            setAprobado(value)
        }
       

    }

    const GuardarAprobado = async () => {

        console.log(getNumeroFactura.estado);

        if(getNumeroFactura.estado==3 && getTitulo.estado==1){
            Swal.fire({
                title: "Mensaje",
                text: "La liquidación ya fue aprobada",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
            return;
        }
        else if(getNumeroFactura.estado==3 && getTitulo.estado==2){
            Swal.fire({
                title: "Mensaje",
                text: "La liquidación ya fue aprobada, no se puede rechazar",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
            return;
        }

        console.log("correcto")

 
        if(getAprobado.trim() == ""){

            Swal.fire({
                title: "Mensaje",
                text: "Debe ingresar una descripción",
                icon: "error",
                confirmButtonText: "Aceptar",
            })

            return;
        }

        let postData = {
            nCas_Id: getCaso.nCas_Id,
            cCas_Cod_Externo: getCaso.cCas_Cod_Externo,
            fechaAprobacion: dia  + "-" + mes + "-" + ano,
            monto: getNumeroFactura.monto,
            descripcion: getAprobado,
            cCas_Titulo: getCaso.cCas_Titulo,
            cCli_NombreCorto: getCaso.cCli_NombreCompleto,
            destinatario: getAbogadoResponsable.nUsu_Id,
            correo: getAbogadoResponsable.email, //'hernanriosvalencia92@gmail.com',
            nUsu_Id: getAbogadoResponsable.nUsu_Id_Cod,
            rol: getAbogadoResponsable.cCas_Tab_TipoEquipo2,
            descripcionLiq:getNumeroFactura.codigoLiq,
            montoH:getNumeroFactura.montoH,
            fechaRechazo: dia  + "-" + mes + "-" + ano,
        }

        console.log("postData", postData)
        console.log("getTitulo.estado", getTitulo.estado)


        if(getTitulo.estado == "1"){ // aprobado
            await instance
            .post("/sendMail/sendMailAprobacionLiquidacion", postData)
            .then(res => {
                console.log(res)
                Swal.fire({
                    title: "Mensaje",
                    text: "Aprobado exitosamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })

                console.log("getNumeroFactura", getNumeroFactura);
                LiquidacionCambiarEstado(getNumeroFactura.id, 3);
                setModalAprobar(false);

            })
            .catch(err => {
                console.log(err)
            })
        }
        else if(getTitulo.estado == "2"){
            console.log("rechazado")
            await instance
            .post("/sendMail/sendMailRechazoLiquidacion", postData)
            .then(res => {
                console.log(res)
                Swal.fire({
                    title: "Mensaje",
                    text: "Se rechazo la liquidación",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })

                console.log("getNumeroFactura", getNumeroFactura);
                LiquidacionCambiarEstado(getNumeroFactura.id, 2);
                setModalAprobar(false);

            })
            .catch(err => {
                console.log(err)
            })
        }
    }

    const FuncionGuardarFactura = async () => {
        var postData = {
            Factura: getNumeroFactura.Factura,
            Codigo: getNumeroFactura.Codigo,
            Estado: 4,
            NumeroLiq: getNumeroFactura.Codigo,
            Estado2: 4
        }
        await instance
            .post("/Liquidacion/ActualizarFactura", postData)
            .then(response => {

                setModalInsertar(!modalInsertar)

                reportesContext.FuncionListarLiquidacionProcesada()
                LiquidacionCambiarEstado(getNumeroFactura.id, 4)


                Swal.fire({
                    title: "Mensaje",
                    text: "Agregado exitosamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })
            })
            .catch(error => {

            })
    }
    const FuncionRevertirLiquidacion = async (_Codigo, _Row) => {
        FuncionGenerarPDFLiQ(_Codigo, _Row, "RP")
        var postData = {
            Factura: "",
            Codigo: _Codigo,
            Estado: 1,
            NumeroLiq: "",
            Estado2: 2,
        }
        await instance
            .post("/Liquidacion/ActualizarFactura", postData)
            .then(response => {
                Swal.fire({
                    title: "Mensaje",
                    text: "Se ha descargado un documento con el contenido de la liquidación rechazada",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })
                reportesContext.FuncionListarLiquidacionProcesada()
            })
            .catch(error => {

            })
    }

    const [dataCasoInfo, setDataCasoInfo] = useState([])
    const [dataCases, setDataCases] = useState([])
    const [dataCliente, setDataCliente] = useState([])
    const [dataConsulta, setDataConsulta] = useState({
        Case: null,
        Client: null
    })
    const handleChangeBusqueda = async (e) => {
        if (e.target.value == 0) {
            setDataConsulta({ ...dataConsulta, Case: 0, Client: 0 })

        } else {
            listCasesByClient(e.target.value)

        }
    }
    const listCasesByClient = async (id) => {
        setDataConsulta({ ...dataConsulta, Client: id })
        var postData = {
            idClient: id,
        }
        await instance
            .post("/Casos/findClient", postData)
            .then(res => {
                setDataCases(res.data)

            })
            .catch(err => {

            })
    }
    const handleChangeBusquedaCaso = async (e) => {
        setDataConsulta({ ...dataConsulta, Case: e.target.value })
    }
    const FuncionListarCliente = async () => {
        let RolId = getDecryptedItem("RolID")

        var postData = {
            bCli_Tipo: null,
            nUsu_ID: RolId == 6 || RolId == 1 ? null : getDecryptedItem("ID")
        }
        await instance
            .post("/Cliente/ListarCbo", postData)
            .then(response => {
                setDataCliente(response.data)

            })
            .catch(error => {

            })
    }

    const FuncionBuscarCasos = async () => {
        reportesContext.FuncionListarLiquidacionProcesada(dataConsulta.Client, dataConsulta.Case)
        var postData = {
            nCas_Id: dataConsulta.Case,
        }
        await instance
            .post("/Casos/Buscar", postData)
            .then(response => {
                console.log(response.data);
                setDataCasoInfo(response.data)
            })
            .catch(error => {

            })
    }

    const FuncionBloquearCombo = async () => {
        let rol = parseInt(getDecryptedItem("RolID"))
        if (rol == 9) {
            setDataConsulta({ ...dataConsulta, Client: parseInt(getDecryptedItem("CLIID")) })
            setDisableCliente(true)
            listCasesByClient(parseInt(getDecryptedItem("CLIID")))
        }
    }

    
    const FuncionObtenerAbogadoResponsable = (_nCas_Id) => {
        return new Promise((resolve, reject) => {
            var postData = {
                nCas_Id: _nCas_Id,
            };
            instance
                .post("/Casos/AbogadoResposable", postData)
                .then(response => {
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    };

    const FuncionObtenerCaso = (_nCas_Id) => {
        return new Promise((resolve, reject) => {
            var postData = {
                nCas_Id: _nCas_Id,
            };
            instance
                .post("/Casos/Buscar", postData)
                .then(response => {
                    console.log(response.data);
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    };

    useEffect(() => {
        if (getDecryptedItem("RolID") == 9) {
            setHiddenCliente(true);
            let nomCli = getDecryptedItem("NombreC");
            setDataCasoInfo([{ cCli_NombreCompleto: nomCli }]);
            console.log(nomCli);

        }
        FuncionBloquearCombo()
        FuncionListarCliente()
    }, [])

    return (

        <Col className="col-12">
            <Modal isOpen={modalInsertar}>
                <div
                    className="modal-header ModalHeaderColor"
                >
                    <h5
                        style={{ color: "#fff" }}
                        className="modal-title mt-0"
                        id="myModalLabel"
                    >
                        Ingrese número de comprobante
                    </h5>
                    <button
                        type="button"
                        onClick={() => abrirCerrarModal(1)}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="mb-3 row">
                        <div className="col-md-12">
                            <input
                                type="text"
                                className="form-control inputDown"
                                placeholder="Comprobante"
                                name="Comprobante"
                                value={getNumeroFactura && getNumeroFactura.Factura}
                                onChange={handleChange}
                            />
                            <label className="col-form-label labelDown">
                                Número de comprobante
                            </label>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => abrirCerrarModal(1)}
                        className="btn btn-secondary waves-effect"
                        data-dismiss="modal"
                    >
                        Cerrar
                    </button>
                    <button
                        type="button"
                        className="btn btn-danger waves-effect waves-light"
                        onClick={() => FuncionGuardarFactura()}
                    >
                        Guardar
                    </button>
                </div>
            </Modal>

            <Modal isOpen={modalAprobar}>
                <div
                    className="modal-header ModalHeaderColor"
                >
                    <h5
                        style={{ color: "#fff" }}
                        className="modal-title mt-0"
                        id="myModalLabel"
                    >
                        {getTitulo.nombre}
                    </h5>
                    <button
                        type="button"
                        onClick={() => abrirCerrarModal(2)}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="mb-3 row">
                        <div className="col-md-12">
                            <input
                                type="text"
                                className="form-control inputDown"
                                placeholder="Motivo"
                                name="Descripcion"
                                value={getAprobado && getAprobado}
                                onChange={handleChange}
                            />
                            <label className="col-form-label labelDown">
                                Motivo
                            </label>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => abrirCerrarModal(2)}
                        className="btn btn-secondary waves-effect"
                        data-dismiss="modal"
                    >
                        Cerrar
                    </button>
                    <button
                        type="button"
                        className="btn btn-danger waves-effect waves-light"
                        onClick={() => GuardarAprobado()}
                    >
                        Guardar
                    </button>
                </div>
            </Modal>

            <Card className="cardBorder">
                <CardBody>
                    <div className="row" >
                        <div className="col-7"><i style={{ fontSize: "40px", float: "left" }} className="fas fa-briefcase">&nbsp;</i>
                            Caso<br />
                            <b style={{ fontSize: "13px" }}>{dataCasoInfo.map((row, i) => (
                                row.cCas_Titulo
                            ))}</b>
                        </div>
                        <label style={{ fontSize: "12px" }}>Código: <b>{dataCasoInfo.map((row, i) => (
                            row.cCas_Cod_Externo
                        ))}</b>{" // "}<b style={{ color: "#1F60AB" }}>
                                {dataCasoInfo.map((row, i) => (
                                    row.cCas_Cod_Interno
                                ))}</b>
                            &nbsp;&nbsp;&nbsp;Cliente: <b style={{ color: "#AA1B0E" }}>{dataCasoInfo.map((row, i) => (
                                row.cCli_NombreCompleto
                            ))}</b></label>
                    </div>
                    <div className="combos-doc">
                        <div hidden={hiddenCliente}>
                            <label className="texto-combex">Cliente</label>
                            <select style={{ textAlignLast: "left" }} disabled={getDisableCliente}
                                className="form-select inputDown"
                                name="Client"
                                value={dataConsulta && dataConsulta.Client}
                                onChange={handleChangeBusqueda}
                            >
                                <option value="null">Seleccionar</option>
                                {dataCliente.map((e) =>
                                    <option value={e.nCli_Id}>
                                        {e.cCli_NombreCompleto}
                                    </option>
                                )}
                            </select>
                        </div>
                        <div>
                            <label className="texto-combex">Caso</label>
                            <select style={{}}
                                className="form-select inputDown"
                                name="Case"
                                disabled={dataConsulta.Client == '0' ? true : null}
                                value={dataConsulta && dataConsulta.Case}
                                onChange={handleChangeBusquedaCaso}>
                                <option value="null">Seleccionar</option>
                                {dataCases.map((row, i) => (
                                    <option value={row.nCas_Id}>
                                        {row.cCas_Titulo}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <button style={{ marginTop: '26px' }} type="button"
                                className="btn btn-danger" onClick={() => FuncionBuscarCasos()}>
                                <i className="fa fa-search"></i>
                            </button>
                        </div>
                    </div>
                </CardBody>
            </Card>

            <Card>
                <MDBDataTable
                    data={Acciones}
                    responsiveXl
                    fixed
                    hover
                    entriesLabel="Mostrar"
                    entrylabel={"Mostrar entradas"}
                    infoLabel={["Mostrando ", "a", "de", "registros"]}
                    bordered
                    order={["age", "desc"]}
                    noRecordsFoundLabel={"No hay datos"}
                    searchLabel={"Buscar"}
                    noBottomColumns
                    paginationLabel={["Anterior", "Siguiente"]}
                    theadColor="HeadBlue"
                    className="TablaProcesados"
                    id="TablaProcesados"
                />
            </Card>
        </Col>
    )
}
export default ListaReporteProcesado