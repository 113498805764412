import React, { useState, useEffect, useContext } from "react"
import {
    Col,
    Row,
    Modal,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    CardText,
    Button,
} from "reactstrap"
import moment from "moment"
import { getDecryptedItem } from '../../../../util';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faTimes,
    faChevronLeft,
    faChevronRight,
    faPaperPlane,
} from "@fortawesome/free-solid-svg-icons"
import Select from "react-select"
import ComboContext from "pages/Menus/Context/Combos/ComboContext"
var meses = new Array("Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre");
var Fecha = new Date() //Fecha actual
var mes = Fecha.getMonth() + 1 //obteniendo mes
var mesIni = Fecha.getMonth() - 1 //obteniendo mes
var dia = Fecha.getDate() //obteniendo dia
var ano = Fecha.getFullYear() //obteniendo año
if (dia < 10) dia = "0" + dia //agrega cero si el menor de 10
if (mes < 10) mes = "0" + mes //agrega cero si el menor de 10
if (mesIni < 10) mesIni = "0" + mesIni //agrega cero si el menor de 10

import instance from '../../../../config'
import Swal from "sweetalert2"
import ModalCliente from "pages/Componentes/ModalCliente"
import CasoContext from "pages/Menus/Context/Casos/CasoContext"
import SelectSearch from "pages/Componentes/SelectSearch"
import PlanPago from "./PlanPago"
const MemoPlanPago = React.memo(PlanPago);
const baseUrl = process.env.REACT_APP_API_URL

const Validacion = (form) => {
    let errors = {};
    if (!form.cCas_Titulo.trim()) {
        errors.cCas_Titulo = "*"
    }
    if (!form.cCas_Cod_Externo.trim()) {
        errors.cCas_Cod_Externo = "*"
    }
    if (form.cCas_Tab_Materia == 'NN00') {
        errors.cCas_Tab_Materia = "*"
    }
    if (form.cCas_Tab_Tipo == 'CA01' || form.cCas_Tab_Tipo == 'CA02') {
        if (form.cCas_Tab_SubMateria == 'NN00') {
            errors.cCas_Tab_SubMateria = "*"
        }
    }

    if (form.nCli_Id == '') {
        errors.nCli_Id = "*"
    }
    if (form.cCas_Tab_Distrito_Jud == 'NN00') {
        errors.cCas_Tab_Distrito_Jud = "*"
    }
    return errors;
}

const ModalCasoU = ({ show, onCloseClick, onCloseModal, Rows, RowAR, RowA, RowP, RowU, RowC, RowO, mode }) => {
    const [getErrores, setErrores] = useState({});
    const casoContext = useContext(CasoContext);
    const comboContext = useContext(ComboContext);
    const [modalInsertarCliente, setModalInsertarCliente] = useState(false)
    const [btnGrabar, setBtnGrabar] = useState(false)
    const [GetParticipantesA, setParticipantesA] = useState([])
    const [GetParticipantesAR, setParticipantesAR] = useState([])
    const [GetParticipantesP, setParticipantesP] = useState([])
    const [GetParticipantesUAD, setParticipantesUAD] = useState([])
    const [tituloModal, setTituloModal] = useState("Actualizar Caso");
    const [getMonedaAR, setMonedaAR] = useState("");
    const [getMonedaA, setMonedaA] = useState("");
    const [getMonedaP, setMonedaP] = useState("");
    const [getMonedaUAD, setMonedaUAD] = useState("");
    const [hiddenBtnGuardar, setHiddenBtnGuardar] = useState(false)
    const [getHiddenPago, setHiddenPago] = useState(false)
    const [HiddenDiv, setHiddenDiv] = useState("")
    const [data, setData] = useState([])
    const [getMontoAR, setMontoAR] = useState({
        Monto: 0,
        MontoFormat: 0,
        MontoOld: 0,
    });
    const [getMontoA, setMontoA] = useState({
        MontoA: 0,
        MontoFormatA: 0,
        MontoOldA: 0,
    });
    const [getMontoP, setMontoP] = useState({
        MontoP: 0,
        MontoFormatP: 0,
        MontoOldP: 0,
    });
    const [getMontoUAD, setMontoUAD] = useState({
        MontoUAD: 0,
        MontoFormatUAD: 0,
        MontoOldUAD: 0,
    });
    const [getMaestroMonto, setMaestroMonto] = useState([]);
    const [dataComboMoneda, setDataComboMoneda] = useState([])
    const [idEQParticipantes, SetidEQParticipantes] = useState(0)
    const [UsuariosEquipo, setUsuariosEquipo] = useState([])
    const [getCasos, setCasos] = useState({
        nCas_Id: "",
        nCli_Id: "",
        nEqu_ID: 1,
        cCas_Tab_Tipo: "CA01",
        cCas_Titulo1: "",
        cCas_Titulo: "",
        cCas_Cod_Externo: "",
        cCas_Cod_Interno: "2021-0008",
        cCas_Recurso_Num: "0203",
        cCas_Detalle: "",
        cCas_Expediente: "",
        nCas_Exp_Year: ano,
        cMed_cau: "",
        cCas_Tab_Materia: "NN00",
        cCas_Tab_SubMateria: "NN00",
        nJuz_Id: "NN00",
        cCas_Tab_Distrito_Jud: "NN00",

        cCas_Tab_Jurisdiccion: "JU01",//Falta
        cCas_Tab_Sala: "SA01",//Falta
        cCas_Tab_Recurso_Tipo: "HA01",//Falta
        cCas_Tab_Comision: "CP01", //Falta
        cTab_Lugar_Tramite: "WE01",   //Falta
        cTab_Tipo_Acto: "0000", //Falta
        nPer_Id: 1,
        cCas_Referencia: "",
        cCas_Denominacion: "",
        cUbi_Org: "",
        dCas_Fec_Ini_Interno: ano + "-" + mes + "-" + dia,
        dCas_Fec_Ini_Procesal: ano + "-" + mes + "-" + dia,
        cCas_Tab_Estado_Externo: "",
        dCas_Fec_Estima_Cierre: moment(Fecha).format('yyyy-MM-DD'),
        cTab_Estado_Interno: "EP06",
        dFas_Fec_Cierre: "1970-01-01",
        nUsu_ID: getDecryptedItem("ID"),
        dUsu_Fecha: ano + "-" + mes + "-" + dia,
        bCas_Estado: 1,
        cTab_Moneda: "NN00",
        nCas_Monto: 0,
        nCas_MontoFormat: 0,
        nCas_MontoOld: 0,
        cTab_Tipo_Monto: 0,
        cName_Resolucion: "",
        nCas_HonorarioFijo: 0,
        nCas_HonorarioFijoFormat: 0,
        nCas_HonorarioFijoOld: 0,
        cTab_MonedaHF: "NN00",
        nCas_HonorarioExito: 0,
        nCas_HonorarioExitoFormat: 0,
        nCas_HonorarioExitoOld: 0,
        cTab_MonedaHE: "NN00",
        nCas_URPs: " ",
        nCas_URPsFormat: " ",
        nCas_URPsOld: " ",
        nCas_Origen: 0,
        TEXPEDIENTE: 0,
        LTRAMITE: 0,
        CodSalaSuprema: "",
        CodRecurso: "",
        CodNRecurso: "",
        CodAnoRecurso: "",
        CodNExpediente: "",
        CodAnoxpediente: "",
        CodDistritoOrigen: "",
        CodApePaterno: "",
        CodApeMaterno: "",
        CodNombre: "",
        nUsu_ID2: getDecryptedItem('NombreC'),
        selectComision: 0,
        selectOpcion1: 0,
        selectOpcion2: 0,
        RSocial: "",
        PApellidos: "",
        PNombres: "",
    })
    const [cheked, setcheked] = useState(false)
    const [cheked2, setcheked2] = useState()
    const [disabled, setDisabled] = useState(false)
    const [checkedMC, setcheckedMC] = useState(false)
    /* Eventos Tabs */
    const [Tab, setTab] = useState("2")
    const [Active1, setActive1] = useState("")
    const [Active2, setActive2] = useState("active")
    const [Active3, setActive3] = useState("")
    const [ActiveN1, setActiveN1] = useState("badge bg-secondary rounded-pill")
    const [ActiveN2, setActiveN2] = useState("badge bg-danger rounded-pill")
    const [ActiveN3, setActiveN3] = useState("badge bg-secondary rounded-pill")
    const [HiddenFinalizar, setHiddenFinalizar] = useState("none")
    const [HiddenSiguiente3, setHiddenSiguiente3] = useState("")
    const [HiddenSiguiente2, setHiddenSiguiente2] = useState("none")
    const [HiddenAnterior2, setHiddenAnterior2] = useState("none")
    const [HiddenAnterior1, setHiddenAnterior1] = useState("none")
    const [HiddenEquipo, setHiddenEquipo] = useState()
    const [HiddenEquipoCbo, setHiddenEquipoCbo] = useState("none")
    const [HiddenCEJ, setHiddenCEJ] = useState('')
    const [HiddenIndecopi, setHiddenIndecopi] = useState('none')
    const [getTipoCliente, setTipoCliente] = useState(0)
    const [getCuantia, setCuantia] = useState(true)
    const [getDisableTabsPago, setDisableTabsPago] = useState(true)
    const [getCabeceraPago, setCabeceraPago] = useState({})
    const [getUsuario, setUsuario] = useState({
        bUsu_Activo: 1,
        cUsu_Fono: "",
        cUsu_Login: "",
        cUsu_Nombres: "",
        cUsu_Pass: "",
        cUsu_email: "",
        nRol_ID: 1,
        nRol_ID2: "",
        ImgBinary: "",
        nUsu_ID: ""
    })
    /*-------------------------------------------------------- */
    const EventoCabeceraTab = async param1 => {
        if (RowAR.length > 0) {
            setMonedaAR(RowAR[0].cCas_TabMoneda);
            setMontoAR(prevState => ({
                ...prevState,
                Monto: RowAR[0].nCas_Monto,
                MontoOld: RowAR[0].nCas_Monto,
                MontoFormat: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(RowAR[0].nCas_Monto),
            }))
        }
        else {
            setMonedaAR('MO00');
            setMontoAR(0.00);
        }
        if (RowA.length > 0) {
            setMonedaA(RowA[0].cCas_TabMoneda);
            setMontoA(prevState => ({
                ...prevState,
                MontoA: RowA[0].nCas_Monto,
                MontoOldA: RowA[0].nCas_Monto,
                MontoFormatA: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(RowA[0].nCas_Monto),
            }))
        }
        else {
            setMonedaA('MO00');
            setMontoA(0.00);
        }
        if (RowP.length > 0) {
            setMonedaP(RowP[0].cCas_TabMoneda);
            setMontoP(prevState => ({
                ...prevState,
                MontoP: RowP[0].nCas_Monto,
                MontoOldP: RowP[0].nCas_Monto,
                MontoFormatP: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(RowP[0].nCas_Monto),
            }))
        }
        else {
            setMonedaP('MO00');
            setMontoP(0.00);
        }
        if (RowU.length > 0) {
            setMonedaUAD(RowU[0].cCas_TabMoneda);
            setMontoUAD(prevState => ({
                ...prevState,
                MontoUAD: RowU[0].nCas_Monto,
                MontoOldUAD: RowU[0].nCas_Monto,
                MontoFormatUAD: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(RowU[0].nCas_Monto),
            }))
        }
        else {
            setMonedaUAD('MO00');
            setMontoUAD(0.00);
        }

        setTab(param1)
        if (param1 == "1") {
            setActive1("active")
            setActiveN1("badge bg-danger rounded-pill")
            setActive2("")
            setActiveN2("badge bg-secondary rounded-pill")
            setActive3("")
            setActiveN3("badge bg-secondary rounded-pill")
            setHiddenFinalizar("none")
            setHiddenAnterior2("none")
            setHiddenAnterior1("none")
            setHiddenSiguiente3("none")
            setHiddenSiguiente2("")
        } else if (param1 == "2") {
            setActive1("")
            setActiveN1("badge bg-secondary rounded-pill")
            setActive2("active")
            setActiveN2("badge bg-danger rounded-pill")
            setActive3("")
            setActiveN3("badge bg-secondary rounded-pill")
            setHiddenFinalizar("none")
            setHiddenAnterior2("none")
            setHiddenSiguiente2("none")
            setHiddenAnterior1("")
            setHiddenSiguiente3("")
        } else {
            setActive1("")
            setActiveN1("badge bg-secondary rounded-pill")
            setActive2("")
            setActiveN2("badge bg-secondary rounded-pill")
            setActive3("active")
            setActiveN3("badge bg-danger rounded-pill")
            setHiddenFinalizar("")
            setHiddenAnterior2("")
            setHiddenSiguiente2("none")
            setHiddenAnterior1("none")
            setHiddenSiguiente3("none")
        }
    }
    const handleChangeCaso = e => {
        const { name, value } = e.target

        if (name == 'cCas_Tab_Tipo') {
            if (value == 'CA05') {
                setCasos(prevState => ({
                    ...prevState,
                    nCas_Origen: 1,
                    selectOpcion1: 1,
                    selectOpcion2: 0,
                }))
                setHiddenCEJ('none')
                setHiddenSupremo('none')
                setHiddenCEJFiltro('none')
                setHiddenIndecopi('')
                setHiddenIndecopiFiltro('none')
                setDataCuadernos([])
            } else if (value == 'CA04') {
                setCasos(prevState => ({
                    ...prevState,
                    nCas_Origen: 1,
                    selectOpcion1: 1,
                    selectOpcion2: 0,
                }))
                setHiddenCEJ('none')
                setHiddenSupremo('none')
                setHiddenCEJFiltro('none')
                setHiddenIndecopi('none')
                setHiddenIndecopiFiltro('')
                setDataCuadernos([])
            } else if (value == 'CA01') {
                setCasos(prevState => ({
                    ...prevState,
                    nCas_Origen: 0,
                }))
                setHiddenCEJ('')
                setHiddenCEJFiltro('none')
                setHiddenIndecopi('none')
                setHiddenIndecopiFiltro('none')
                setHiddenSupremo('none')
                setDataCuadernos([])
            } else if (value == 'CA02') {
                setCasos(prevState => ({
                    ...prevState,
                    nCas_Origen: 0,
                }))
                setHiddenCEJ('none')
                setHiddenCEJFiltro('')
                setHiddenIndecopi('none')
                setHiddenIndecopiFiltro('none')
                setHiddenSupremo('none')
                setDataCuadernos([])
            } else if (value == 'CA03') {
                setCasos(prevState => ({
                    ...prevState,
                    nCas_Origen: 2,
                }))
                setHiddenCEJ('none')
                setHiddenIndecopi('none')
                setHiddenIndecopiFiltro('none')
                setHiddenCEJFiltro('none')
                setHiddenSupremo('')
                setDataCuadernos([])
            }
        } else if (name == 'nCas_Monto') {
            setCasos(prevState => ({
                ...prevState,
                nCas_Monto: value,
                nCas_MontoOld: value,
                nCas_MontoFormat: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(value),
            }))
        } else if (name == 'nCas_HonorarioFijo') {
            setCasos(prevState => ({
                ...prevState,
                nCas_HonorarioFijo: value,
                nCas_HonorarioFijoOld: value,
                nCas_HonorarioFijoFormat: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(value),
            }))
        } else if (name == 'nCas_HonorarioExito') {
            setCasos(prevState => ({
                ...prevState,
                nCas_HonorarioExito: value,
                nCas_HonorarioExitoOld: value,
                nCas_HonorarioExitoFormat: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(value),
            }))
        } else if (name == 'nCas_URPs') {
            setCasos(prevState => ({
                ...prevState,
                nCas_URPs: value,
                nCas_URPsOld: value,
                nCas_URPsFormat: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(value),
            }))
        } else if (name == 'selectComision') {
            if (value == "1") {
                setcomision(prevState => ({
                    ...prevState,
                    comision1: "",
                    comision2: "none",
                    comision3: "none",
                    comision4: "none",
                }))
            } else if (value == "2") {
                setcomision(prevState => ({
                    ...prevState,
                    comision1: "none",
                    comision2: "",
                    comision3: "none",
                    comision4: "none",
                }))
            } else if (value == "3") {
                setcomision(prevState => ({
                    ...prevState,
                    comision1: "none",
                    comision2: "none",
                    comision3: "",
                    comision4: "none",
                }))
            } else if (value == "4") {
                setcomision(prevState => ({
                    ...prevState,
                    comision1: "none",
                    comision2: "none",
                    comision3: "none",
                    comision4: "",
                }))
            }
            setCasos(prevState => ({
                ...prevState,
                [name]: value,
            }))
        } else if (name == 'selectOpcion2') {
            if (value == "1") {
                setcomision(prevState => ({
                    ...prevState,
                    comision1: "none",
                    comision2: "none",
                    comision3: "none",
                    comision4: "none",
                    persona1: "",
                    persona2: "none",
                }))
            } else if (value == "2") {
                setcomision(prevState => ({
                    ...prevState,
                    comision1: "none",
                    comision2: "",
                    comision3: "none",
                    comision4: "none",
                    persona1: "none",
                    persona2: "",
                }))
            }
            setCasos(prevState => ({
                ...prevState,
                [name]: value,
                Cod: value,
            }))
        } else {
            setCasos(prevState => ({
                ...prevState,
                [name]: value,
            }))
            setErrores(Validacion(getCasos))
        }

    }
    const handleChangeTCliente = valor => {
        setTipoCliente(valor.target.value)
        comboContext.FuncionListarCliente(parseInt(valor.target.value))
    };
    const handleChangeCliente = valor => {
        if (valor == null) {
        } else {
            setCasos(prevState => ({
                ...prevState,
                nCli_Id: valor.value,
            }))
            setErrores(Validacion(getCasos))
        }
    };
    const handleChangeCheck = e => {
        setcheked(!cheked)
        setcheked2(false)
        if (cheked == false) {
            setHiddenEquipo("none")
            setHiddenEquipoCbo("")
        } else {
            setHiddenEquipo("")
            setHiddenEquipoCbo("none")
            setcheked2(!cheked2)
        }
    }
    const handleChangeCheck2 = e => {
        setcheked2(!cheked2)
        setcheked(false)
        if (cheked2 == false) {
            setHiddenEquipo("")
            setHiddenEquipoCbo("none")
        } else {
            setHiddenEquipo("none")
            setHiddenEquipoCbo("")
            setcheked(!cheked)
        }
    }
    const handleChangeParticipantesA = value => {
        if (value == null) {
            setParticipantesA([]);
        } else {
            setParticipantesA(value);
        }
    };
    const handleChangeParticipantesAR = value => {
        if (value == null) {
            setParticipantesAR([]);
        } else {
            setParticipantesAR(value);

        }
    };
    const handleChangeParticipantesP = value => {
        if (value == null) {
            setParticipantesP([]);
        } else {
            setParticipantesP(value);
        }
    };
    const handleChangeParticipantesUAD = value => {
        if (value == null) {
            setParticipantesUAD([]);
        } else {
            setParticipantesUAD(value);
        }
    };
    const UsuariosSelect = comboContext.cusuario.map((row, i) => ({
        value: row.nUsu_ID,
        label: row.cUsu_Nombres,
        email: row.cUsu_email,
        color: "#556ee6",
        cCas_Tab_TipoEquipo2: row.cCas_Tab_TipoEquipo2
    }))
    const FuncionEliminarCasoEquipo = async (_nCas_Id) => {
        var postData = {
            nCas_Id: _nCas_Id,
        }

        await instance
            .post("/EqCasos/Eliminar", postData)
            .then(response => {

            })
            .catch(error => {

            })
    }
    const FuncionAgregarCasoEquipo = async (_nCas_Id, _nUsu_Id, _cCas_Tab_TipoEquipo, _cCas_TabMoneda, _nCas_Monto) => {
        var postData = {
            nCas_Id: _nCas_Id,
            nUsu_Id: _nUsu_Id,
            cCas_Tab_TipoEquipo: _cCas_Tab_TipoEquipo,
            cCas_TabMoneda: _cCas_TabMoneda,
            nCas_Monto: _nCas_Monto
        }
        await instance
            .post("/EqCasos/Insertar", postData)
            .then(response => {

            })
            .catch(error => {

            })
    }
    const FuncionActualizarCaso = async () => {

        setBtnGrabar(true);
        await FuncionEliminarCasoEquipo(getCasos.nCas_Id)
        var postData = {
            nCas_Id: getCasos.nCas_Id,
            nCli_Id: getCasos.nCli_Id,
            nEqu_ID: getCasos.nEqu_ID,
            cCas_Tab_Tipo: getCasos.cCas_Tab_Tipo,
            cCas_Titulo: getCasos.cCas_Titulo,
            cCas_Cod_Externo: getCasos.cCas_Cod_Externo,
            cCas_Cod_Interno: getCasos.cCas_Cod_Interno,
            cCas_Tab_Materia: getCasos.cCas_Tab_Materia,
            cCas_Tab_SubMateria: getCasos.cCas_Tab_SubMateria,
            cCas_Detalle: getCasos.cCas_Detalle,
            cCas_Tab_Jurisdiccion: getCasos.cCas_Tab_Jurisdiccion,
            cCas_Expediente: getCasos.cCas_Expediente,
            nCas_Exp_Year: getCasos.nCas_Exp_Year,
            nJuz_Id: getCasos.nJuz_Id,
            cCas_Tab_Sala: getCasos.cCas_Tab_Sala,
            cCas_Tab_Recurso_Tipo: getCasos.cCas_Tab_Recurso_Tipo,
            cCas_Recurso_Num: getCasos.cCas_Recurso_Num,
            cCas_Tab_Distrito_Jud: getCasos.cCas_Tab_Distrito_Jud,
            cCas_Tab_Comision: getCasos.cCas_Tab_Comision,
            cTab_Lugar_Tramite: getCasos.cTab_Lugar_Tramite,
            nPer_Id: getCasos.nPer_Id,
            cCas_Referencia: getCasos.cCas_Referencia,
            cCas_Denominacion: getCasos.cCas_Denominacion,
            dCas_Fec_Ini_Interno: getCasos.dCas_Fec_Ini_Interno,
            dCas_Fec_Ini_Procesal: getCasos.dCas_Fec_Ini_Procesal,
            cCas_Tab_Estado_Externo: getCasos.cCas_Tab_Estado_Externo,
            dCas_Fec_Estima_Cierre: getCasos.dCas_Fec_Estima_Cierre,
            cTab_Estado_Interno: getCasos.cTab_Estado_Interno,
            dFas_Fec_Cierre: getCasos.dFas_Fec_Cierre,
            nUsu_ID: getDecryptedItem("ID"),
            dUsu_Fecha: getCasos.dUsu_Fecha,
            bCas_Estado: getCasos.bCas_Estado,
            cUbi_Org: getCasos.cUbi_Org,
            cTab_Tipo_Acto: getCasos.cTab_Tipo_Acto,
            cTab_Moneda: getCasos.cTab_Moneda,
            nCas_Monto: getCasos.nCas_MontoOld,
            cTab_Tipo_Monto: getCasos.cTab_Tipo_Monto,
            cName_Resolucion: getCasos.cName_Resolucion,
            nCas_Origen: parseInt(getCasos.nCas_Origen),
            nCas_HonorarioFijo: getCasos.nCas_HonorarioFijoOld,
            nCas_HonorarioExito: getCasos.nCas_HonorarioExitoOld,
            cCas_Titulo1: getCasos.cCas_Titulo1,
            cTab_MonedaHF: getCasos.cTab_MonedaHF,
            cTab_MonedaHE: getCasos.cTab_MonedaHE,
            nCas_URPs: getCasos.nCas_URPsOld,
            cMed_cau: getCasos.cMed_cau
        }



        await instance
            .post("/Casos/Actualizar", postData)
            .then(async response => {

                Swal.fire({
                    title: "Mensaje",
                    text: "Actualizado exitosamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })

                if (cheked) {

                    FuncionInsParticipantesFromEC(UsuariosEquipo.nEqu_ID, getCasos.nCas_Id);
                }
                else {
                    var arrayParticipantes = [];
                    if (GetParticipantesA.length > 0) {
                        const Participantes = GetParticipantesA.map((row, i) => {
                            FuncionAgregarCasoEquipo(getCasos.nCas_Id, row.value, 'UC02', getMonedaA, getMontoA.MontoOldA)
                        })
                        for (let i = 0; i < GetParticipantesA.length; i++) {
                            let obj = {
                                cCas_Tab_TipoEquipo2: "Abogado",
                                color: GetParticipantesA[i].color,
                                email: GetParticipantesA[i].email,
                                label: GetParticipantesA[i].label,
                                value: GetParticipantesA[i].value,
                            }
                            arrayParticipantes.push(obj);
                        }
                    }
                    if (GetParticipantesAR.length > 0) {
                        const Participantes = GetParticipantesAR.map((row, i) => {
                            FuncionAgregarCasoEquipo(getCasos.nCas_Id, row.value, 'UC01', getMonedaAR, getMontoAR.MontoOld)
                        })
                        for (let i = 0; i < GetParticipantesAR.length; i++) {
                            let obj = {
                                cCas_Tab_TipoEquipo2: "Abogado Responsable",
                                color: GetParticipantesAR[i].color,
                                email: GetParticipantesAR[i].email,
                                label: GetParticipantesAR[i].label,
                                value: GetParticipantesAR[i].value,
                            }
                            arrayParticipantes.push(obj);
                        }
                    }
                    if (GetParticipantesUAD.length > 0) {
                        const Participantes = GetParticipantesUAD.map((row, i) => {
                            FuncionAgregarCasoEquipo(getCasos.nCas_Id, row.value, 'UC04', getMonedaUAD, getMontoUAD.MontoOldUAD)
                        })
                        for (let i = 0; i < GetParticipantesUAD.length; i++) {
                            let obj = {
                                cCas_Tab_TipoEquipo2: "Usuario Adicional",
                                color: GetParticipantesUAD[i].color,
                                email: GetParticipantesUAD[i].email,
                                label: GetParticipantesUAD[i].label,
                                value: GetParticipantesUAD[i].value,
                            }
                            arrayParticipantes.push(obj);
                        }
                    }
                    if (GetParticipantesP.length > 0) {
                        const Participantes = GetParticipantesP.map((row, i) => {
                            FuncionAgregarCasoEquipo(getCasos.nCas_Id, row.value, 'UC03', getMonedaP, getMontoP.MontoOldP)
                        })
                        for (let i = 0; i < GetParticipantesP.length; i++) {
                            let obj = {
                                cCas_Tab_TipoEquipo2: "Practicante",
                                color: GetParticipantesP[i].color,
                                email: GetParticipantesP[i].email,
                                label: GetParticipantesP[i].label,
                                value: GetParticipantesP[i].value,
                            }
                            arrayParticipantes.push(obj);
                        }
                    }
                }
                onCloseModal()
                let hash = {};
                arrayParticipantes = arrayParticipantes.filter(o => hash[o.value] ? false : hash[o.value] = true);

                let nUsu_ID = getDecryptedItem("ID")
                let objMails = {
                    nUsu_ID: nUsu_ID,
                    anfitrion: getUsuario.cUsu_Nombres,
                    codExt: getCasos.cCas_Cod_Externo,
                    casNom: getCasos.cCas_Titulo,
                    idCaso: getCasos.nCas_Id,
                    cliente: getCasos.nCli_Id,
                    parametro: {
                        tpoTbl: 'caso',   // tipo de tabla
                        tpoAct: 'actualizar'  // insertar o actualizar
                    },
                    arrayParticipantes: arrayParticipantes
                }

                await instance
                    .post("/mail/event/casos", objMails)
                    .then(res => {

                    })
                    .catch(error => {

                    })
                let postData2 = {
                    cAud_nombre: "Casos",
                    nAud_IdReg: getCasos.nCas_Id,
                    nUsu_ID: getDecryptedItem("ID"),
                    dAud_Fecha: null,
                    nAud_Accion: "U",
                    cTabCodigo: 'ME02'
                }
                await instance
                    .post("/Auditoria/Insertar", postData2)
                    .then(response => {
                    });

            })
            .catch(error => {

            })

    }
    const FuncionCorreoEvento = async (_nCas_Id, _nUsu_Id, _cCor_Tab_Tipo, _cCor_Asunto) => {
        var postData = {
            nCas_Id: _nCas_Id,
            nUsu_Id: _nUsu_Id,
            cCor_Tab_Tipo: _cCor_Tab_Tipo,
            cCor_Asunto: _cCor_Asunto,
        }
        await instance
            .post("/Correo/Insertar", postData)
            .then(response => {
            })
            .catch(error => {

            })
    }
    const handleChangeEQParticipantes = e => {

        SetidEQParticipantes(e.target.value)

        FuncionBuscarUsuXEquipo(e.target.value);
    }
    /** Funcion para buscar usuarios del equipo */
    const FuncionBuscarUsuXEquipo = async (_nEqu_ID) => {
        var postData = {
            nEqu_ID: _nEqu_ID
        }
        await instance
            .post("/EQU/BuscarUsuarios", postData)
            .then(response => {
                setUsuariosEquipo(response.data[0])

            });
    }
    /** Funcion para Eliminar participantes del caso y reemplazar por el equipo */
    const FuncionInsParticipantesFromEC = async (_nEqu_ID, _nCas_Id) => {
        var postData = {
            nEqu_ID: _nEqu_ID,
            nCas_Id: _nCas_Id
        }

        await instance
            .post("/EQU/InsParticipantesFromEC", postData)
            .then(response => {
            });
    }
    const FuncionListarComboMoneda = async () => {
        var postData = {
            Prefijo: "MO",
        }
        await instance
            .post("/Maestros/Data", postData)
            .then(response => {
                setDataComboMoneda(response.data)

            })
            .catch(error => {

            })
    }
    const BluerChange = e => {
        const { name, value } = e.target
        if (name == "Monto") {

            setMontoAR(prevState => ({
                ...prevState,
                [name]: getMontoAR.MontoFormat,
            }))
        }
        if (name == "MontoA") {
            setMontoA(prevState => ({
                ...prevState,
                [name]: getMontoA.MontoFormatA,
            }))
        }
        if (name == "MontoP") {
            setMontoP(prevState => ({
                ...prevState,
                [name]: getMontoP.MontoFormatP,
            }))
        }
        if (name == "MontoUAD") {
            setMontoUAD(prevState => ({
                ...prevState,
                [name]: getMontoUAD.MontoFormatUAD,
            }))
        }
        if (name == "nCas_Monto") {
            setCasos(prevState => ({
                ...prevState,
                [name]: getCasos.nCas_MontoFormat,
            }))
        }
        if (name == "nCas_HonorarioFijo") {
            setCasos(prevState => ({
                ...prevState,
                [name]: getCasos.nCas_HonorarioFijoFormat,
            }))
        }
        if (name == "nCas_HonorarioExito") {
            setCasos(prevState => ({
                ...prevState,
                [name]: getCasos.nCas_HonorarioExitoFormat,
            }))
        }
        if (name == "nCas_URPs") {
            setCasos(prevState => ({
                ...prevState,
                [name]: getCasos.nCas_URPsFormat,
            }))
        }

        setErrores(Validacion(getCasos))

    }
    const FocusChange = e => {
        const { name, value } = e.target
        if (name == "Monto") {
            setMontoAR(prevState => ({
                ...prevState,
                [name]: getMontoAR.MontoOld,
            }))
        }
        if (name == "MontoA") {
            setMontoA(prevState => ({
                ...prevState,
                [name]: getMontoA.MontoOldA,
            }))
        }
        if (name == "MontoP") {
            setMontoP(prevState => ({
                ...prevState,
                [name]: getMontoP.MontoOldP,
            }))
        }
        if (name == "MontoUAD") {
            setMontoUAD(prevState => ({
                ...prevState,
                [name]: getMontoUAD.MontoOldUAD,
            }))
        }
        if (name == "nCas_Monto") {
            setCasos(prevState => ({
                ...prevState,
                [name]: getCasos.nCas_MontoFormat,
            }))
        }

        if (name == "nCas_Monto") {
            setCasos(prevState => ({
                ...prevState,
                [name]: getCasos.nCas_MontoOld,
            }))
        }
        if (name == "nCas_URPs") {
            setCasos(prevState => ({
                ...prevState,
                [name]: getCasos.nCas_URPsOld,
            }))
        }
        if (name == "nCas_HonorarioFijo") {
            setCasos(prevState => ({
                ...prevState,
                [name]: getCasos.nCas_HonorarioFijoOld,
            }))
        }
        if (name == "nCas_HonorarioExito") {
            setCasos(prevState => ({
                ...prevState,
                [name]: getCasos.nCas_HonorarioExitoOld,
            }))
        }


    }
    const handleChange = e => {
        const { name, value } = e.target
        setCasos(prevState => ({
            ...prevState,
            [name]: value,
        }))


        if (name == 'Monto') {
            setMontoAR(prevState => ({
                ...prevState,
                Monto: value,
                MontoOld: value,
                MontoFormat: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(value),
            }))
        }
        if (name == 'MontoA') {

            setMontoA(prevState => ({
                ...prevState,
                MontoA: value,
            }))
            setMontoA(prevState => ({
                ...prevState,
                MontoOldA: value
            }))
            setMontoA(prevState => ({
                ...prevState,
                MontoFormatA: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(value)
            }))

        }
        if (name == 'MontoP') {
            setMontoP(prevState => ({
                ...prevState,
                MontoP: value,
                MontoOldP: value,
                MontoFormatP: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(value),
            }))
        }
        if (name == 'MontoUAD') {
            setMontoUAD(prevState => ({
                ...prevState,
                MontoUAD: value,
                MontoOldUAD: value,
                MontoFormatUAD: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(value),
            }))
        }
        if (e.target.name == "nCas_Id") {
            FuncionInformacionCaso(e.target.value)
        }
    }
    const handleChangeCuantia = e => {
        setCuantia(!getCuantia)
    }
    const FuncionListarMaestros = async () => {
        var postData = {
        }
        await instance
            .post("/Maestros/ls", postData)
            .then(response => {
                setData(response.data)

                setMaestroMonto(response.data)
            })
            .catch(error => {

            })
    }
    /** Funcion listar tarifas */
    const FuncionListarTarifa = async () => {

        var postData = {
        }
        await instance
            .post("/Tarifa/listar", postData)
            .then(response => {

                setData(response.data)



            })
            .catch(error => {

            })
    }
    const handleChangeMoneda = e => {
        if (e.target.name == "cIng_Tab_MonedaA") {
            setMonedaA(e.target.value)
            setMontoA(prevState => ({
                ...prevState,
                MontoA: 0,
                MontoOldA: 0,
                MontoFormatA: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(0),
            }))
            for (let i = 0; i < data.length; i++) {
                if (data[i].cTar_Tab_Moneda == e.target.value && data[i].rol_nom == "Abogado") {
                    setMontoA(prevState => ({
                        ...prevState,
                        MontoA: data[i].nTar_Monto,
                        MontoOldA: data[i].nTar_Monto,
                        MontoFormatA: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(data[i].nTar_Monto),
                    }))
                }
                if (data.length == i && getMontoA.MontoA == 0) {
                    setMontoA(prevState => ({
                        ...prevState,
                        MontoA: 0,
                        MontoOldA: 0,
                        MontoFormatA: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(0),
                    }))
                }
            }
        }
        if (e.target.name == "cIng_Tab_MonedaAR") {
            setMonedaAR(e.target.value)
            setMontoAR(prevState => ({
                ...prevState,
                Monto: 0,
                MontoOld: 0,
                MontoFormat: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(0),
            }))
            for (let i = 0; i < data.length; i++) {
                if (data[i].cTar_Tab_Moneda == e.target.value && data[i].rol_nom == "Abogado Responsable") {
                    setMontoAR(prevState => ({
                        ...prevState,
                        Monto: data[i].nTar_Monto,
                        MontoOld: data[i].nTar_Monto,
                        MontoFormat: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(data[i].nTar_Monto),
                    }))
                }
                if (data.length == i && getMonto.Monto == 0) {
                    setMontoAR(prevState => ({
                        ...prevState,
                        Monto: 0,
                        MontoOld: 0,
                        MontoFormat: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(0),
                    }))
                }
            }
        }
        if (e.target.name == "cIng_Tab_MonedaP") {
            setMonedaP(e.target.value)
            setMontoP(prevState => ({
                ...prevState,
                MontoP: 0,
                MontoOldP: 0,
                MontoFormatP: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(0),
            }))
            for (let i = 0; i < data.length; i++) {
                if (data[i].cTar_Tab_Moneda == e.target.value && data[i].rol_nom == "Practicante") {
                    setMontoP(prevState => ({
                        ...prevState,
                        MontoP: data[i].nTar_Monto,
                        MontoOldP: data[i].nTar_Monto,
                        MontoFormatP: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(data[i].nTar_Monto),
                    }))
                }
                if (data.length == i && getMontoP.MontoP == 0) {
                    setMontoP(prevState => ({
                        ...prevState,
                        MontoP: 0,
                        MontoOldP: 0,
                        MontoFormatP: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(0),
                    }))
                }
            }
        }
        if (e.target.name == "cIng_Tab_MonedaUAD") {
            setMonedaUAD(e.target.value)
            setMontoUAD(prevState => ({
                ...prevState,
                MontoUAD: 0,
                MontoOldUAD: 0,
                MontoFormatUAD: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(0),
            }))
            for (let i = 0; i < data.length; i++) {
                if (data[i].cTar_Tab_Moneda == e.target.value && data[i].rol_nom == "Adicional") {
                    setMontoUAD(prevState => ({
                        ...prevState,
                        MontoUAD: data[i].nTar_Monto,
                        MontoOldUAD: data[i].nTar_Monto,
                        MontoFormatUAD: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(data[i].nTar_Monto),
                    }))
                }
                if (data.length == i && getMontoUAD.MontoUAD == 0) {
                    setMontoUAD(prevState => ({
                        ...prevState,
                        MontoUAD: 0,
                        MontoOldUAD: 0,
                        MontoFormatUAD: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(0),
                    }))
                }
            }
        }

    }
    const handleChangeOrganoCom = valor => {
        setCasos(prevState => ({
            ...prevState,
            cCas_Tab_Distrito_Jud: valor,
        }))
        setErrores(Validacion(getCasos))
    };
    const ClientesSelect = comboContext.clientes.map((row, i) => ({
        value: row.nCli_Id,
        label: row.cCli_NombreCompleto,
        color: "#556ee6",
    }))
    const OrgCompetenteSelect = comboContext.juzgado.map((row, i) => ({
        value: row.cTabCodigo,
        label: row.cTabNombre,
        color: "#556ee6",
    }))
    const FuncionListarUsuario = async (_nUsu_ID) => {
        var postData = {
            nUsu_ID: _nUsu_ID,
        }
        await instance
            .post("/Usuario/Perfil", postData)
            .then(response => {

                setUsuario(response.data[0])
            })
            .catch(error => {

            })
    }
    const FuncionAbrirPlantillaPago = async (value) => {

        if (getCasos.nCas_HonorarioFijoOld == 0 || getCasos.cTab_MonedaHF == 'NN00') {
            Swal.fire({
                title: "Mensaje",
                text: "Debes de seleccionar una moneda o un monto mayor a 0",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        } else {
            FuncionActualizarHonorario()
            FuncionAplicarPlantillaPago(getCasos.nCas_Id, value)
        }
    }
    const FuncionActualizarHonorario = async () => {
        var postData = {
            nCas_Id: getCasos.nCas_Id,
            nCli_Id: getCasos.nCli_Id,
            nEqu_ID: getCasos.nEqu_ID,
            cCas_Tab_Tipo: getCasos.cCas_Tab_Tipo,
            cCas_Titulo: getCasos.cCas_Titulo,
            cCas_Cod_Externo: getCasos.cCas_Cod_Externo,
            cCas_Cod_Interno: getCasos.cCas_Cod_Interno,
            cCas_Tab_Materia: getCasos.cCas_Tab_Materia,
            cCas_Tab_SubMateria: getCasos.cCas_Tab_SubMateria,
            cCas_Detalle: getCasos.cCas_Detalle,
            cCas_Tab_Jurisdiccion: getCasos.cCas_Tab_Jurisdiccion,
            cCas_Expediente: getCasos.cCas_Expediente,
            nCas_Exp_Year: getCasos.nCas_Exp_Year,
            nJuz_Id: getCasos.nJuz_Id,
            cCas_Tab_Sala: getCasos.cCas_Tab_Sala,
            cCas_Tab_Recurso_Tipo: getCasos.cCas_Tab_Recurso_Tipo,
            cCas_Recurso_Num: getCasos.cCas_Recurso_Num,
            cCas_Tab_Distrito_Jud: getCasos.cCas_Tab_Distrito_Jud,
            cCas_Tab_Comision: getCasos.cCas_Tab_Comision,
            cTab_Lugar_Tramite: getCasos.cTab_Lugar_Tramite,
            nPer_Id: getCasos.nPer_Id,
            cCas_Referencia: getCasos.cCas_Referencia,
            cCas_Denominacion: getCasos.cCas_Denominacion,
            dCas_Fec_Ini_Interno: getCasos.dCas_Fec_Ini_Interno,
            dCas_Fec_Ini_Procesal: getCasos.dCas_Fec_Ini_Procesal,
            cCas_Tab_Estado_Externo: getCasos.cCas_Tab_Estado_Externo,
            dCas_Fec_Estima_Cierre: getCasos.dCas_Fec_Estima_Cierre,
            cTab_Estado_Interno: getCasos.cTab_Estado_Interno,
            dFas_Fec_Cierre: getCasos.dFas_Fec_Cierre,
            nUsu_ID: getDecryptedItem("ID"),
            dUsu_Fecha: getCasos.dUsu_Fecha,
            bCas_Estado: getCasos.bCas_Estado,
            cUbi_Org: getCasos.cUbi_Org,
            cTab_Tipo_Acto: getCasos.cTab_Tipo_Acto,
            cTab_Moneda: getCasos.cTab_Moneda,
            nCas_Monto: getCasos.nCas_MontoOld,
            cTab_Tipo_Monto: getCasos.cTab_Tipo_Monto,
            cName_Resolucion: getCasos.cName_Resolucion,
            nCas_Origen: parseInt(getCasos.nCas_Origen),
            nCas_HonorarioFijo: getCasos.nCas_HonorarioFijoOld,
            nCas_HonorarioExito: getCasos.nCas_HonorarioExitoOld,
            cCas_Titulo1: getCasos.cCas_Titulo1,
            cTab_MonedaHF: getCasos.cTab_MonedaHF,
            cTab_MonedaHE: getCasos.cTab_MonedaHE,
            nCas_URPs: getCasos.nCas_URPsOld,
            cMed_cau: getCasos.cMed_cau
        }



        await instance
            .post("/Casos/Actualizar", postData)
            .then(async response => {

            }).catch(error => {
            })

    }
    const FuncionAplicarPlantillaPago = async (nCas_Id, value) => {
        const postdata = {
            nCas_Id: nCas_Id
        }
        await instance
            .post("/Plantillapago/Cabecera/Pago/Listar", postdata)
            .then(response => {
                let result = response.data
                if (result.length == 0) {
                    result = {
                        nCas_Id: nCas_Id,
                        nCb_Tipo: 1,
                        dCb_Fecha: ano + "-" + mes + "-" + dia,
                        nCb_Cuotas: 0,
                        nCb_TEA: 0,
                        nCb_Monto: getCasos.nCas_HonorarioFijoOld,
                        nCb_Estado: 1
                    }
                } else {
                    result = result[0]
                }
                setDisableTabsPago(response.data.length == 0 ? false : true)
                setCabeceraPago(result)
                setHiddenPago(value)
                if (value == true) {
                    setHiddenDiv('none')
                } else {
                    setHiddenDiv('')
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    useEffect(() => {
        /* Funciones para listar los Combos */
        console.log("mode : ", mode);
        if (mode == "Readonly") {
            setBtnGrabar(true);
            setTituloModal("Ver Caso");

        }
        let nUsu_ID = getDecryptedItem("ID")
        FuncionListarUsuario(nUsu_ID);
        FuncionListarComboMoneda()
        comboContext.FuncionListarCliente()
        comboContext.FuncionListarJurisdiccion()
        comboContext.FuncionListarJuzgado()
        comboContext.FuncionListarMateria()
        comboContext.FuncionListarSubMateria()
        comboContext.FuncionListarEstadoExterno()
        comboContext.FuncionListarEstadoInterno()
        comboContext.FuncionListarUsuario()
        comboContext.FuncionListarMoneda()
        comboContext.FuncionListarEquipo()
        setCasos(Rows)
        setCasos(prevState => ({
            ...prevState,
            nCas_Monto: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(Rows.nCas_Monto),
            nCas_MontoOld: Rows.nCas_Monto,
            nCas_MontoFormat: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(Rows.nCas_Monto),
            nCas_HonorarioFijo: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(Rows.nCas_HonorarioFijo),
            nCas_HonorarioFijoOld: Rows.nCas_HonorarioFijo,
            nCas_HonorarioFijoFormat: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(Rows.nCas_HonorarioFijo),
            nCas_HonorarioExito: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(Rows.nCas_HonorarioExito),
            nCas_HonorarioExitoOld: Rows.nCas_HonorarioExito,
            nCas_HonorarioExitoFormat: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(Rows.nCas_HonorarioExito),
            nCas_URPs: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(Rows.nCas_URPs),
            nCas_URPsOld: Rows.nCas_URPs,
            nCas_URPsFormat: new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, }).format(Rows.nCas_URPs),
        }))
        setParticipantesA(RowA)
        setParticipantesAR(RowAR)
        setParticipantesP(RowP)
        setParticipantesUAD(RowU)
        //FuncionListarMaestros();
        FuncionListarTarifa();
    }, [])
    return (
        <Modal size="lg" isOpen={show}>
            <div style={{ display: HiddenDiv }}>
                <div
                    className="modal-header ModalHeaderColor"
                >
                    <h5
                        style={{ color: "#fff" }}
                        className="modal-title mt-0"
                        id="myModalLabel"
                    >
                        {tituloModal}
                    </h5>
                    <button
                        type="button"
                        onClick={() => onCloseClick()}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Nav tabs className="nav-tabs-custom nav-justified">
                        <NavItem>
                            <NavLink
                                className={Active2}
                                style={{ cursor: "pointer" }}
                                onClick={() => EventoCabeceraTab("2")}
                            >
                                Datos del proceso{" "}
                                <span className={ActiveN2}>1</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={Active3}
                                style={{ cursor: "pointer" }}
                                onClick={() => EventoCabeceraTab("3")}
                            >
                                Datos adicionales{" "}
                                <span className={ActiveN3}>2</span>
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={Tab} className="p-3 text-muted">
                        <TabPane tabId="2">
                            <Row>
                                <Col sm="12">
                                    <div className="mb-3 row">
                                        <div className="modal-body">
                                            <div className="mb-3 row">
                                                <strong>Tipo de Caso</strong>
                                                <hr style={{ color: "#000" }} />
                                                <div className="col-md-7">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Titulo del caso"
                                                        name="cCas_Titulo"
                                                        value={getCasos && getCasos.cCas_Titulo}
                                                        onChange={handleChangeCaso}
                                                        onBlur={BluerChange}
                                                    />
                                                    <label className="col-form-label labelDown">Ingrese Titulo del caso</label>
                                                </div>
                                                <div className="col-md-5">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Código externo"
                                                        name="cCas_Cod_Externo"
                                                        onChange={handleChangeCaso}
                                                        onBlur={BluerChange}
                                                        value={getCasos && getCasos.cCas_Cod_Externo}
                                                    />
                                                    <label className="col-form-label labelDown">Código externo</label>{getErrores.cCas_Cod_Externo && <label className="col-form-label labelDown labelRed">({getErrores.cCas_Cod_Externo})</label>}
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-md-6">
                                                    <select
                                                        className="form-select inputDown"
                                                        name="cCas_Tab_Materia"
                                                        onChange={handleChangeCaso}
                                                        onBlur={BluerChange}
                                                        value={getCasos && getCasos.cCas_Tab_Materia}
                                                    >
                                                        <option value="NN00">
                                                            Seleccionar...
                                                        </option>
                                                        {comboContext.materia.map((row, i) => (
                                                            <option
                                                                key={i}
                                                                value={row.cTabCodigo}
                                                            >
                                                                {row.cTabNombre}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <label className="col-form-label labelDown">Seleccione Especialidad</label>{getErrores.cCas_Tab_Materia && <label className="col-form-label labelDown labelRed">({getErrores.cCas_Tab_Materia})</label>}
                                                </div>
                                                <div className="col-md-6">
                                                    <select
                                                        className="form-select inputDown"
                                                        name="cCas_Tab_SubMateria"
                                                        onChange={handleChangeCaso}
                                                        onBlur={BluerChange}
                                                        value={getCasos && getCasos.cCas_Tab_SubMateria}
                                                    >
                                                        <option value="NN00">
                                                            Seleccionar...
                                                        </option>
                                                        {comboContext.submateria.map((row, i) => (
                                                            <option
                                                                key={i}
                                                                value={row.cTabCodigo}
                                                            >
                                                                {row.cTabNombre}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <label className="col-form-label labelDown">Seleccione Materia</label>{getErrores.cCas_Tab_SubMateria && <label className="col-form-label labelDown labelRed">({getErrores.cCas_Tab_SubMateria})</label>}
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-md-12">
                                                    <textarea
                                                        className="form-control"
                                                        type="text"
                                                        maxLength="500"
                                                        name="cCas_Detalle"
                                                        onChange={handleChangeCaso}
                                                        value={getCasos && getCasos.cCas_Detalle}
                                                    ></textarea>
                                                    <label className="col-form-label labelDown">
                                                        Ingrese Detalle
                                                    </label>
                                                </div>
                                            </div>
                                            <strong style={{ color: "#AA1B0E" }}>
                                                Cliente
                                            </strong>
                                            <hr
                                                style={{
                                                    color: "#AA1B0E",
                                                    marginTop: "0px",
                                                }}
                                            />
                                            <div className="mb-3 row">
                                                <div className="col-md-4">
                                                    <select className="form-select" name="tipocliente" onChange={handleChangeTCliente} value={getTipoCliente}>
                                                        <option value="1">
                                                            Persona Jurídica
                                                        </option>
                                                        <option value="0" >
                                                            Persona Natural
                                                        </option>
                                                    </select>
                                                    <label className="col-form-label labelDown">
                                                        Seleccione tipo de cliente
                                                    </label>
                                                </div>
                                                <div className="col-md-7">
                                                    <Select
                                                        className={getErrores.nCli_Id && "invalid"}
                                                        onChange={handleChangeCliente}
                                                        onBlur={BluerChange}
                                                        options={ClientesSelect}
                                                        defaultValue={RowC}
                                                        placeholder="Cliente..."
                                                    />
                                                    <label className="col-form-label labelDown">Seleccione Cliente</label>{getErrores.nCli_Id && <label className="col-form-label labelDown labelRed">({getErrores.nCli_Id})</label>}
                                                </div>
                                                <div className="col-md-1" style={{ top: "7px" }}>
                                                    <i className="icon-nuevo" style={{ fontSize: "15px" }} onClick={() => setModalInsertarCliente(!modalInsertarCliente)}></i>
                                                    {(() => {
                                                        if (modalInsertarCliente == true) {
                                                            return <><ModalCliente show={modalInsertarCliente}
                                                                onCloseClick={() => setModalInsertarCliente(!modalInsertarCliente)}
                                                                ReloadLista={() => { comboContext.FuncionListarCliente() }}
                                                                TipoCliente={getTipoCliente}
                                                                Titulo="Nuevo Cliente"
                                                            />
                                                            </>
                                                        }
                                                    })()}
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-md-7">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Asunto del caso"
                                                        name="cCas_Titulo1"
                                                        value={getCasos && getCasos.cCas_Titulo1}
                                                        onChange={handleChangeCaso}
                                                        onBlur={BluerChange}
                                                    />
                                                    <label className="col-form-label labelDown">Ingrese Asunto del caso</label>{getErrores.cCas_Titulo && <label className="col-form-label labelDown labelRed">({getErrores.cCas_Titulo})</label>}
                                                </div>
                                                <div className="col-md-5">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="..."
                                                        name="cCas_Referencia"
                                                        onChange={handleChangeCaso}
                                                        value={getCasos && getCasos.cCas_Referencia}
                                                    />
                                                    <label className="col-form-label labelDown">
                                                        Referencia de contrato anterior
                                                    </label>
                                                </div>
                                            </div>
                                            <strong>
                                                Información del organismo donde se tramitará
                                            </strong>
                                            <hr
                                                style={{ color: "#000", marginTop: "0px" }}
                                            />
                                            <div className="mb-3 row ">
                                                <div className="col-md-6">
                                                    <SelectSearch array={OrgCompetenteSelect} value={RowO} click={handleChangeOrganoCom} />
                                                    <label className="col-form-label labelDown">Órgano competente</label>{getErrores.cCas_Tab_Distrito_Jud && <label className="col-form-label labelDown labelRed">({getErrores.cCas_Tab_Distrito_Jud})</label>}
                                                </div>
                                                <div className="col-md-6">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="cCas_Denominacion"
                                                        onChange={handleChangeCaso}
                                                        value={getCasos && getCasos.cCas_Denominacion}
                                                    />
                                                    <label className="col-form-label labelDown">
                                                        Denominación
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="mb-3 row ">
                                                <div className="col-md-7">
                                                    <input
                                                        name="cUbi_Org"
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Ubicación del expediente"
                                                        onChange={handleChangeCaso}
                                                        value={getCasos && getCasos.cUbi_Org}
                                                    />
                                                    <label className="col-form-label labelDown">
                                                        Ubicación del expediente
                                                    </label>
                                                </div>
                                                <div className="col-md-5">
                                                    <input
                                                        name="cMed_cau"
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Codigo cautelar"
                                                        onChange={handleChangeCaso}
                                                        value={getCasos && getCasos.cMed_cau}
                                                        maxLength={6}
                                                    />
                                                    <label className="col-form-label labelDown">
                                                        Codigo cautelar
                                                    </label>
                                                </div>
                                            </div>
                                            <strong>Estado</strong>
                                            <hr
                                                style={{ color: "#000", marginTop: "0px" }}
                                            />
                                            <div className="mb-3 row ">
                                                <div className="col-md-6">
                                                    <input
                                                        className="form-control"
                                                        type="date"
                                                        value={getCasos.dCas_Fec_Ini_Interno}
                                                        name="dCas_Fec_Ini_Interno"
                                                        onChange={handleChangeCaso}
                                                    />
                                                    <label className="col-form-label labelDown">
                                                        Fecha de inicio
                                                    </label>
                                                </div>
                                                <div className="col-md-6">
                                                    <input
                                                        className="form-control"
                                                        type="date"
                                                        value={getCasos.dCas_Fec_Ini_Procesal}
                                                        name="dCas_Fec_Ini_Procesal"
                                                        onChange={handleChangeCaso}
                                                    />
                                                    <label className="col-form-label labelDown">
                                                        Fecha de inicio procesal
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="mb-3 row ">
                                                <div className="col-md-6">
                                                    <select
                                                        className="form-select inputDown"
                                                        name="cCas_Tab_Estado_Externo"
                                                        value={getCasos.cCas_Tab_Estado_Externo}
                                                        onChange={handleChangeCaso}
                                                    >
                                                        <option value="NN00">
                                                            Seleccionar...
                                                        </option>
                                                        {comboContext.estadoexterno.map(
                                                            (row, i) => (
                                                                <option
                                                                    key={i}
                                                                    value={row.cTabCodigo}
                                                                >
                                                                    {row.cTabNombre}
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                    <label className="col-form-label labelDown">
                                                        Estado externo
                                                    </label>
                                                </div>
                                                <div className="col-md-6">
                                                    <input
                                                        className="form-control"
                                                        type="date"
                                                        name="dCas_Fec_Estima_Cierre"
                                                        onChange={handleChangeCaso}
                                                        value={getCasos.dCas_Fec_Estima_Cierre}
                                                    />
                                                    <label className="col-form-label labelDown">
                                                        Fecha estimada de cierre
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="mb-3 row ">
                                                <div className="col-md-6">
                                                    <select
                                                        className="form-select inputDown"
                                                        name="cTab_Estado_Interno"
                                                        onChange={handleChangeCaso}
                                                        value={getCasos.cTab_Estado_Interno}
                                                    >
                                                        <option value="NN00">
                                                            Seleccionar...
                                                        </option>
                                                        {comboContext.estadointerno.map(
                                                            (row, i) => (
                                                                <option
                                                                    key={i}
                                                                    value={row.cTabCodigo}
                                                                >
                                                                    {row.cTabNombre}
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                    <label className="col-form-label labelDown">
                                                        Estado Interno
                                                    </label>
                                                </div>
                                                <div className="col-md-6">
                                                    <input
                                                        className="form-control"
                                                        type="date"
                                                        name="dFas_Fec_Cierre"
                                                        onChange={handleChangeCaso}
                                                        value={getCasos.dFas_Fec_Cierre}
                                                    />
                                                    <label className="col-form-label labelDown">
                                                        Fecha de cierre
                                                    </label>
                                                </div>
                                            </div>
                                            <strong>Cuantía</strong>
                                            <hr
                                                style={{ color: "#000", marginTop: "0px" }}
                                            />
                                            <div className="mb-3 row ">
                                                <div className="col-md-3">
                                                    <div className="col-md-3" >
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="customCheckcolor1"
                                                            onClick={() => handleChangeCuantia()}
                                                            style={{ transform: "scale(1.5)", marginTop: "10px" }}
                                                        />
                                                        <label className="col-form-label " style={{ position: "absolute", paddingLeft: "10px" }}>
                                                            Indeterminado
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <select
                                                        className="form-select inputDown" onChange={handleChangeCaso}
                                                        name="cTab_Moneda" disabled={!getCuantia}
                                                        value={getCasos.cTab_Moneda}
                                                    >
                                                        <option value="NN00">Seleccionar</option>
                                                        {comboContext.moneda.map((row, i) => (
                                                            <option key={i} value={row.cTabCodigo}>
                                                                {row.cTabNombre}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <label className="col-form-label labelDown">
                                                        Seleccione Moneda
                                                    </label>
                                                </div>
                                                <div className="col-md-3">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        onChange={handleChangeCaso}
                                                        onBlur={BluerChange}
                                                        onFocus={FocusChange}
                                                        name="nCas_Monto"
                                                        placeholder="Monto"
                                                        value={getCasos.nCas_Monto}
                                                        style={{ textAlign: 'right' }}
                                                        disabled={!getCuantia}
                                                    />
                                                    <label className="col-form-label labelDown">
                                                        Ingrese Monto
                                                    </label>
                                                </div>
                                                <div className="col-md-3">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        onChange={handleChangeCaso}
                                                        onBlur={BluerChange}
                                                        onFocus={FocusChange}
                                                        name="nCas_URPs"
                                                        placeholder="Monto"
                                                        value={getCasos.nCas_URPs}
                                                        style={{ textAlign: 'right' }}
                                                        disabled={!getCuantia}
                                                    />
                                                    <label className="col-form-label labelDown">
                                                        URPs
                                                    </label>
                                                </div>
                                                <div className="col-md-3">
                                                    <select
                                                        className="form-select inputDown"
                                                        onChange={handleChangeCaso}
                                                        name="cTab_Tipo_Monto"
                                                        value={getCasos.cTab_Tipo_Monto}
                                                    >
                                                        <option value="0">Seleccionar</option>
                                                        <option value="1">A Favor</option>
                                                        <option value="2">En contra</option>
                                                    </select>
                                                    <label className="col-form-label labelDown">
                                                        Seleccione Tipo
                                                    </label>
                                                </div>
                                            </div>
                                            <strong>Informacion del sistema</strong>
                                            <hr
                                                style={{ color: "#000", marginTop: "0px" }}
                                            />
                                            <div className="mb-3 row ">
                                                <div className="col-md-6">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Creado por"
                                                        name="nUsu_ID"
                                                        value={getCasos.nUsu_ID2}
                                                        onChange={handleChangeCaso}
                                                        readOnly
                                                    />
                                                    <label className="col-form-label labelDown">
                                                        Creado por
                                                    </label>
                                                </div>
                                                <div className="col-md-6">
                                                    <input
                                                        className="form-control"
                                                        type="date"
                                                        value={getCasos.dUsu_Fecha}
                                                        name="dUsu_Fecha"
                                                        onChange={handleChangeCaso}
                                                        readOnly
                                                    />
                                                    <label className="col-form-label labelDown">
                                                        Fecha de creacion
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="3">
                            <Row>
                                <Col sm="12">
                                    <CardText className="mb-0">
                                        <div className="mb-3 row">
                                            <div className="modal-body">
                                                <div className="mb-3 row">
                                                    <strong>Equipo</strong>
                                                    <hr style={{ color: "#000" }} />
                                                    <div className="col-md-3" hidden>
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="customCheckcolor1"
                                                            onChange={handleChangeCheck}
                                                            checked={cheked}
                                                            value={cheked}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="customCheckcolor1"
                                                            style={{ paddingLeft: "5px" }}
                                                        >
                                                            Asignar Equipo
                                                        </label>
                                                    </div>
                                                    <div className="col-md-3" hidden>
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="customCheckcolor1"
                                                            onChange={handleChangeCheck2}
                                                            checked={cheked2}
                                                            value={cheked2}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="customCheckcolor1"
                                                            style={{ paddingLeft: "5px" }}
                                                        >
                                                            Asignar personas
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="mb-3 row">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-3 col-form-label"
                                                    ></label>
                                                    <div
                                                        className="col-md-6"
                                                        style={{ display: HiddenEquipoCbo }}
                                                    >
                                                        <select
                                                            className="form-select inputDown"
                                                            name="nCas_Id"
                                                            onChange={handleChangeEQParticipantes}
                                                        >
                                                            <option value="0">
                                                                Seleccionar...
                                                            </option>
                                                            {comboContext.cequipo.map((row, i) => (
                                                                <option key={i} value={row.nEqu_ID}>
                                                                    {row.cEqu_Nombre}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <label className="col-form-label labelDown">
                                                            equipo
                                                        </label>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="mb-3 row" style={{ display: HiddenEquipo }}>
                                                        <label
                                                            htmlFor="example-text-input"
                                                            className="col-md-3 col-form-label"
                                                            style={{ marginTop: '21px' }}
                                                        >
                                                            Abogado(s) responsable
                                                        </label>
                                                        <div className="col-md-4" style={{ width: "290px", marginTop: '21px' }}>
                                                            <Select
                                                                isMulti
                                                                onChange={handleChangeParticipantesAR}
                                                                name="colors"
                                                                options={UsuariosSelect}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                placeholder="Abogados responsables..."
                                                                defaultValue={GetParticipantesAR}
                                                            />
                                                            <label className="col-form-label labelDown">
                                                                Abogado(s) responsable
                                                            </label>
                                                        </div>
                                                        <div className="col-md-2" style={{ width: "140px", paddingRight: "5px", paddingLeft: "0px" }}>
                                                            <label className="col-form-label labelDown">
                                                                Moneda
                                                            </label>
                                                            <select
                                                                className="form-select inputDown"
                                                                name="cIng_Tab_MonedaAR"
                                                                value={getMonedaAR}
                                                                onChange={handleChangeMoneda}
                                                            >
                                                                <option value="0">Seleccionar</option>
                                                                {dataComboMoneda.map((row, i) => (
                                                                    <option key={i} value={row.cTabCodigo}>
                                                                        {row.cTabNombre}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="col-md-2" style={{ width: "130px", paddingRight: "0px", paddingLeft: "0px" }}>
                                                            <label className="col-form-label labelDown">
                                                                Tarifa por hora
                                                            </label>
                                                            <input
                                                                name="Monto"
                                                                className="form-control"
                                                                type="text"
                                                                placeholder=" el monto"
                                                                onChange={handleChange}
                                                                onBlur={BluerChange}
                                                                onFocus={FocusChange}
                                                                value={getMontoAR && getMontoAR.Monto}
                                                                style={{ textAlign: 'right' }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mb-3 row" style={{ display: HiddenEquipo }}>
                                                        <label
                                                            htmlFor="example-text-input"
                                                            className="col-md-3 col-form-label"
                                                            style={{ marginTop: '21px' }}
                                                        >
                                                            Abogado(s)
                                                        </label>
                                                        <div className="col-md-4" style={{ width: "290px", marginTop: '21px' }}>
                                                            <Select
                                                                isMulti
                                                                onChange={handleChangeParticipantesA}
                                                                name="colors"
                                                                options={UsuariosSelect}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                placeholder="Abogados..."
                                                                defaultValue={GetParticipantesA}
                                                            />
                                                            <label className="col-form-label labelDown">
                                                                Abogado(s)
                                                            </label>
                                                        </div>
                                                        <div className="col-md-2" style={{ width: "140px", paddingRight: "5px", paddingLeft: "0px" }}>
                                                            <label className="col-form-label labelDown">
                                                                Moneda
                                                            </label>
                                                            <select
                                                                className="form-select inputDown"
                                                                name="cIng_Tab_MonedaA"
                                                                onChange={handleChangeMoneda}
                                                                value={getMonedaA}
                                                            >
                                                                <option value="0">Seleccionar</option>
                                                                {dataComboMoneda.map((row, i) => (
                                                                    <option key={i} value={row.cTabCodigo}>
                                                                        {row.cTabNombre}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                        </div>
                                                        <div className="col-md-2" style={{ width: "130px", paddingRight: "0px", paddingLeft: "0px" }}>
                                                            <label className="col-form-label labelDown">
                                                                Tarifa por hora
                                                            </label>
                                                            <input
                                                                name="MontoA"
                                                                className="form-control"
                                                                type="text"
                                                                placeholder=" el monto"
                                                                onChange={handleChange}
                                                                onBlur={BluerChange}
                                                                onFocus={FocusChange}
                                                                value={getMontoA && getMontoA.MontoA}
                                                                style={{ textAlign: 'right' }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mb-3 row" style={{ display: HiddenEquipo }}>
                                                        <label
                                                            htmlFor="example-text-input"
                                                            className="col-md-3 col-form-label"
                                                            style={{ marginTop: '21px' }}
                                                        >
                                                            Practicante(s)
                                                        </label>
                                                        <div className="col-md-4" style={{ width: "290px", marginTop: '21px' }}>
                                                            <Select
                                                                isMulti
                                                                onChange={handleChangeParticipantesP}
                                                                name="colors"
                                                                options={UsuariosSelect}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                placeholder="Practicante..."
                                                                value={GetParticipantesP}
                                                            />
                                                            <label className="col-form-label labelDown">
                                                                Practicante(s)
                                                            </label>
                                                        </div>
                                                        <div className="col-md-2" style={{ width: "140px", paddingRight: "5px", paddingLeft: "0px" }}>
                                                            <label className="col-form-label labelDown">
                                                                Moneda
                                                            </label>
                                                            <select
                                                                className="form-select inputDown"
                                                                name="cIng_Tab_MonedaP"
                                                                value={getMonedaP}
                                                                onChange={handleChangeMoneda}
                                                            >
                                                                <option value="0">Seleccionar</option>
                                                                {dataComboMoneda.map((row, i) => (
                                                                    <option key={i} value={row.cTabCodigo}>
                                                                        {row.cTabNombre}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                        </div>
                                                        <div className="col-md-2" style={{ width: "130px", paddingRight: "0px", paddingLeft: "0px" }}>
                                                            <label className="col-form-label labelDown">
                                                                Tarifa por hora
                                                            </label>
                                                            <input
                                                                name="MontoP"
                                                                className="form-control"
                                                                type="text"
                                                                placeholder=" el monto"
                                                                onChange={handleChange}
                                                                onBlur={BluerChange}
                                                                onFocus={FocusChange}
                                                                value={getMontoP && getMontoP.MontoP}
                                                                style={{ textAlign: 'right' }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mb-3 row" >
                                                        <label
                                                            htmlFor="example-text-input"
                                                            className="col-md-3 col-form-label"
                                                            style={{ marginTop: '21px' }}
                                                        >
                                                            Usuario Adicional
                                                        </label>
                                                        <div className="col-md-4" style={{ width: "290px", marginTop: '21px' }}>
                                                            <Select
                                                                isMulti
                                                                onChange={handleChangeParticipantesUAD}
                                                                name="colors"
                                                                options={UsuariosSelect}
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                placeholder="Usuario Adicional..."
                                                                value={GetParticipantesUAD}
                                                            />
                                                            <label className="col-form-label labelDown">
                                                                Usuario Adicional
                                                            </label>
                                                        </div>
                                                        <div className="col-md-2" style={{ width: "140px", paddingRight: "5px", paddingLeft: "0px" }}>
                                                            <label className="col-form-label labelDown">
                                                                Moneda
                                                            </label>
                                                            <select
                                                                className="form-select inputDown"
                                                                name="cIng_Tab_MonedaUAD"
                                                                value={getMonedaUAD}
                                                                onChange={handleChangeMoneda}
                                                            >
                                                                <option value="0">Seleccionar</option>
                                                                {dataComboMoneda.map((row, i) => (
                                                                    <option key={i} value={row.cTabCodigo}>
                                                                        {row.cTabNombre}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="col-md-2" style={{ width: "130px", paddingRight: "0px", paddingLeft: "0px" }}>
                                                            <label className="col-form-label labelDown">
                                                                Tarifa por hora
                                                            </label>
                                                            <input
                                                                onChange={handleChange}
                                                                onBlur={BluerChange}
                                                                onFocus={FocusChange}
                                                                name="MontoUAD"
                                                                className="form-control"
                                                                type="text"
                                                                placeholder=" el monto"
                                                                value={getMontoUAD && getMontoUAD.MontoUAD}
                                                                style={{ textAlign: 'right' }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <hr
                                                        style={{ color: "#000", marginTop: "0px" }}
                                                    />
                                                    <div className="mb-3 row">
                                                        <label
                                                            htmlFor="example-text-input"
                                                            className="col-md-3 col-form-label"
                                                        >
                                                            Honorario fijo
                                                        </label>
                                                        <div className="col-md-3">
                                                            <input
                                                                style={{ textAlign: "right" }}
                                                                name="nCas_HonorarioFijo"
                                                                value={getCasos.nCas_HonorarioFijo}
                                                                className="form-control"
                                                                type="text"
                                                                placeholder="Honorario fijo"
                                                                onChange={handleChangeCaso}
                                                                onBlur={BluerChange}
                                                                onFocus={FocusChange}
                                                            />
                                                            <label className="col-form-label labelDown">
                                                                Honorario fijo
                                                            </label>
                                                        </div>
                                                        <div className="col-md-2" style={{ width: "140px", paddingRight: "5px", paddingLeft: "0px" }}>
                                                            <select
                                                                className="form-select inputDown"
                                                                name="cTab_MonedaHF"
                                                                onChange={handleChangeCaso}
                                                                value={getCasos.cTab_MonedaHF}
                                                            >
                                                                <option value="0">Seleccionar</option>
                                                                {dataComboMoneda.map((row, i) => (
                                                                    <option key={i} value={row.cTabCodigo}>
                                                                        {row.cTabNombre}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            <label className="col-form-label labelDown">
                                                                Moneda
                                                            </label>
                                                        </div>
                                                        <div className="col-md-2" style={{ width: "140px", paddingRight: "5px", paddingLeft: "0px" }}>
                                                            <Button onClick={() => FuncionAbrirPlantillaPago(true)}>Plan de Pagos</Button>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3 row">
                                                        <label
                                                            htmlFor="example-text-input"
                                                            className="col-md-3 col-form-label"
                                                        >
                                                            Honorario de éxito
                                                        </label>
                                                        <div className="col-md-3">
                                                            <input
                                                                style={{ textAlign: "right" }}
                                                                name="nCas_HonorarioExito"
                                                                value={getCasos.nCas_HonorarioExito}
                                                                className="form-control"
                                                                type="text"
                                                                placeholder="Honorario de éxito"
                                                                onChange={handleChangeCaso}
                                                                onBlur={BluerChange}
                                                                onFocus={FocusChange}
                                                            />
                                                            <label className="col-form-label labelDown">
                                                                Honorario de éxito
                                                            </label>
                                                        </div>
                                                        <div className="col-md-2" style={{ width: "140px", paddingRight: "5px", paddingLeft: "0px" }}>
                                                            <select
                                                                className="form-select inputDown"
                                                                name="cTab_MonedaHE"
                                                                onChange={handleChangeCaso}
                                                                value={getCasos.cTab_MonedaHE}
                                                            >
                                                                <option value="0">Seleccionar</option>
                                                                {dataComboMoneda.map((row, i) => (
                                                                    <option key={i} value={row.cTabCodigo}>
                                                                        {row.cTabNombre}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            <label className="col-form-label labelDown">
                                                                Moneda
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <strong>
                                                        Ubicación del file
                                                    </strong>
                                                    <hr
                                                        style={{ color: "#000", marginTop: "0px" }}
                                                    />
                                                    <div className="mb-3 row">
                                                        <label
                                                            htmlFor="example-text-input"
                                                            className="col-md-3 col-form-label"
                                                        >
                                                            Ubicación del file
                                                        </label>
                                                        <div className="col-md-6">
                                                            <input
                                                                name="cName_Resolucion"
                                                                value={getCasos && getCasos.cName_Resolucion}
                                                                className="form-control"
                                                                type="text"
                                                                placeholder="Ubicación física del file"
                                                                onChange={handleChangeCaso}
                                                            />
                                                            <label className="col-form-label labelDown">
                                                                Ubicación del file
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="mb-12 row" style={{ display: HiddenEquipoCbo }}>
                                                        <label>
                                                            <b>
                                                                ** La asignación de un equipo reemplazara las personas asignadas.
                                                            </b>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CardText>
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                </div>
                <div className="modal-footer">

                    <button
                        type="button"
                        onClick={() => onCloseModal()}
                        className="btn btn-secondary waves-effect waves-light"
                        data-dismiss="modal"
                    >
                        Cerrar <FontAwesomeIcon icon={faTimes} />
                    </button>
                    <button
                        type="button"
                        onClick={() => EventoCabeceraTab("2")}
                        className="btn btn-primary waves-effect waves-light"
                        style={{ display: HiddenAnterior2 }}
                    >
                        <FontAwesomeIcon icon={faChevronLeft} /> Anterior
                    </button>
                    <button
                        type="button"
                        onClick={() => EventoCabeceraTab("2")} name="btnSiguiente1"
                        className="btn btn-danger waves-effect waves-light " disabled={disabled}
                        style={{ display: HiddenSiguiente2 }}
                    >
                        Siguiente <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                    <button
                        type="button"
                        onClick={() => EventoCabeceraTab("3")}
                        className="btn btn-danger waves-effect waves-light"
                        style={{ display: HiddenSiguiente3 }}
                    >
                        Siguiente <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                    <button
                        disabled={btnGrabar}
                        hidden={btnGrabar}
                        type="button"
                        className="btn btn-danger waves-effect waves-light"
                        style={{ display: HiddenFinalizar }}
                        onClick={() => FuncionActualizarCaso(getCasos)}
                    >
                        Grabar<FontAwesomeIcon icon={faPaperPlane} />
                    </button>
                </div>
            </div>
            {(() => {
                if (getHiddenPago) {
                    return (
                        <MemoPlanPago DisableTabs={getDisableTabsPago} InfoCabecera={getCabeceraPago} nCasId={getCasos.nCas_Id} InfoCaso={getCasos} nCb_Monto={getCasos.nCas_HonorarioFijoOld} dCb_Fecha={ano + "-" + mes + "-" + dia} cTab_Moneda={getCasos.cTab_MonedaHF} arrayMoneda={dataComboMoneda} onCloseClick={() => FuncionAbrirPlantillaPago(false)} />
                    )
                }
            })()}
        </Modal>
    )
}
export default ModalCasoU