import React, { Component, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTags from "react-meta-tags"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import Swal from "sweetalert2"
import * as XLSX from "xlsx";
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

import {
  CardBody,
  Card,
  Col,
  Container,
  CardTitle,
  Button,
  Row,
  Modal,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap"
import instance from '../../config'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPlus,
  faSearch,
  faTimes,
  faChevronLeft,
  faChevronRight,
  faPaperPlane,
  faBuilding,
  faUser,
  faCalendar,
  faCircle,
  faClipboard,
  faBell,
} from "@fortawesome/free-solid-svg-icons"
import ModalTarea from "pages/Componentes/ModalTarea"
const baseUrl = process.env.REACT_APP_API_URL

const Correos = props => {
  const [items, setItems] = useState([]);

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
    promise.then((d) => {
      setItems(d);

    });
  };

  function formatIni() {
    var d = new Date(),
      month = '' + 1,
      day = '' + 1,
      year = d.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  }
  function formatFin() {
    var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  }
  const [dataConsulta, setDataConsulta] = useState({
    inicio: formatIni(),
    fin: formatFin(),
    mon: 'MO01',
    caso: '',
    cliente: ''
  })


  const [dataUser, setDataUser] = useState([])
  const [dataCombo, setDataCombo] = useState([])
  const [data, setData] = useState([])
  const [dataFilter, setDataFilter] = useState({
    nUsu_Id: 0,
  })
  const FuncionListarUsuario = async () => {
    await instance
      .get("/Usuario/Listar")
      .then(response => {
        setDataUser(response.data)

      })
      .catch(error => {

      })
  }
  const [dataCasesExcel, setDataCasesExel] = useState([])
  const dataCasesExport = async () => {
    var postData = {
    }
    await instance
      .post("/Report/Cases/Excel")
      .then(response => {
        setDataCasesExel(response.data)

      })
      .catch(error => {

      })
  }

  const FuncionListarCorreos = async () => {

    await instance
      .post("/Correo/List")
      .then(response => {

        setData(response.data)
      })
      .catch(error => {

      })
  }


  const FuncionEnviarCorreo = async (data) => {

    var body = {
      email: data.cUsu_email,

    }
    /*Swal.fire({
      title: "Mensaje",
      text: "Notificación enviada a " + data.cUsu_email,
      icon: "success",
      confirmButtonText: "Aceptar",
    })*/
    await instance
      .post("/mail", body)
      .then(response => {
        Swal.fire({
          title: "Mensaje",
          text: "Notificación enviada a " + data.cUsu_email,
          icon: "success",
          confirmButtonText: "Aceptar",
        })

      })
      .catch(error => {

      })
  }


  const handleChange = e => {
    const { name, value } = e.target
    setDataFilter(prevState => ({
      ...prevState,
      [name]: e.target.type === "checkbox" ? e.target.checked : e.target.value
    }))

  }
  const FuncionListarComboTipo = async () => {
    var postData = {
      Prefijo: "CR",
    }
    await instance
      .post("/Maestros/Data", postData)
      .then(response => {
        setDataCombo(response.data)

      })
      .catch(error => {

      })
  }

  useEffect(() => {
    dataCasesExport()
    FuncionListarCorreos()
    FuncionListarUsuario()
    FuncionListarComboTipo()
  }, [])

  const datas = {
    columns: [
      {
        label: "N°",
        field: "N°",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Código",
        field: "Código",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Cliente",
        field: "Cliente",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Asunto",
        field: "Asunto",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "ÓrganoJurisdiccional",
        field: "ÓrganoJurisdiccional",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "DistritoJudicial",
        field: "DistritoJudicial",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Juez",
        field: "Juez",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "EspecialistaLegal",
        field: "EspecialistaLegal",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Materia",
        field: "Materia",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "SubMateria",
        field: "SubMateria",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Ubicación",
        field: "Ubicación",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "EtapaProcesal",
        field: "EtapaProcesal",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      }
      ,
      {
        label: "FechadeInicio",
        field: "FechadeInicio",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Estado",
        field: "EstadoCEJ",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      }
    ],
    rows: items,
  }

  const badgesData = {
    columns: [
      {
        label: "N°",
        field: "N°",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Código",
        field: "Código",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Cliente",
        field: "Cliente",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Asunto",
        field: "Asunto",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "ÓrganoJurisdiccional",
        field: "ÓrganoJurisdiccional",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "DistritoJudicial",
        field: "DistritoJudicial",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Juez",
        field: "Juez",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "EspecialistaLegal",
        field: "EspecialistaLegal",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Materia",
        field: "Materia",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "SubMateria",
        field: "SubMateria",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Ubicación",
        field: "Ubicación",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "EtapaProcesal",
        field: "EtapaProcesal",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      }
      ,
      {
        label: "FechadeInicio",
        field: "FechadeInicio",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      },
      {
        label: "Estado",
        field: "EstadoCEJ",
        sort: "asc",
        fixedColumns: true,
        attributes: {
          className: "alignCenter",
        },
      }

    ],
    rows: [
      ...datas.rows.map((row, id) => ({
        ...row,
        /*NombreCompleto: (
          <Link style={{color:"black"}} onClick={() =>
            setEncryptedItem('CLIID', row.nCli_Id)  
          } to="/dashboard-Cliente">
            {row.cCli_NombreCompleto}
            </Link>
        ),*/
        acciones: (
          <div>
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="dropdown-toggle">
                <i className="bx bx-dots-vertical-rounded" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={""
                  }>
                  Ver correo
                </DropdownItem>
                <DropdownItem onClick={() => FuncionEnviarCorreo(row)}>
                  Reenviar
                </DropdownItem>
                <DropdownItem onClick={() => FuncionEnviarCorreo(row)}>
                  Eliminar
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        ),
      })),
    ],
  }


  return (
    <React.Fragment>
      <div className="page-content">
        {/* add meta title */}
        <MetaTags>
          <title>Correo | GpsLegal</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="GpsLegal" breadcrumbItem="EXPORTAR / IMPORTAR INFORMACIÓN" />

          <div className="button-filter">
            {<ExcelFile element={<button style={{ fontWeight: '600' }} className="btn btn-success">X</button>} filename={"Export_Data_Case", formatFin()}>
              <ExcelSheet data={dataCasesExcel} name="Data Casos" >
                <ExcelColumn label="N°" value="N°" />
                <ExcelColumn label="Código" value="Código" />
                <ExcelColumn label="Cliente" value="Cliente" />
                <ExcelColumn label="Asunto" value="Asunto" />
                <ExcelColumn label="ÓrganoJurisdiccional" value="ÓrganoJurisdiccional" />
                <ExcelColumn label="DistritoJudicial" value="DistritoJudicial" />
                <ExcelColumn label="Juez" value="Juez" />
                <ExcelColumn label="EspecialistaLegal" value="EspecialistaLegal" />
                <ExcelColumn label="Materia" value="Materia" />
                <ExcelColumn label="SubMateria" value="SubMateria" />
                <ExcelColumn label="Ubicación" value="Ubicación" />
                <ExcelColumn label="EtapaProcesal" value="EtapaProcesal" />
                <ExcelColumn label="FechadeInicio" value="FechadeInicio" />
                <ExcelColumn label="EstadoCEJ" value="EstadoCEJ" />
              </ExcelSheet>
            </ExcelFile>}
            <input
              type="file"
              onChange={(e) => {
                const file = e.target.files[0];
                readExcel(file);

              }}
            />
          </div>


          <MDBDataTable
            data={badgesData}
            responsiveXl
            fixed
            hover
            entriesLabel="Mostrar"
            entrylabel={"Mostrar entradas"}
            infoLabel={["Mostrando ", "a", "de", "registros"]}
            bordered
            order={["age", "desc"]}
            noRecordsFoundLabel={"No hay datos"}
            searchLabel={"Buscar"}
            noBottomColumns
            paginationLabel={["Anterior", "Siguiente"]}
            theadColor="HeadBlue"
          />
        </Container>
      </div>
    </React.Fragment >
  )
}
export default Correos