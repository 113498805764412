import React from "react";
const Checkbox = ({ id, type, name, handleClick, isChecked }) => {
  return (
    <input
    
      id={id}
      name={name}
      type={type}
      className="form-check-input"
      onChange={handleClick}
      /*checked={isCheck.filt)er((item) => item == id}*/
      />
  );
};
export default Checkbox;
