import React, { Component, useState, useEffect } from "react"
import ReactDOM from "react-dom";
import { MDBDataTable } from "mdbreact"

import {
    CardHeader,
    CardText,
    CardBody,
    Card,
    Col,
    Container,
    CardTitle,
    Button,
    Row,
    Modal,
    Title,
    Text,
    Form,
    FormGroup,
    TabContent,
    TabPane,
    FormText,
    Label,
    Input,
    CustomInput,
    CheckBox,
    Nav,
    NavItem,
    NavLink
} from "reactstrap"
import MetaTags from "react-meta-tags"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPlus,
    faSearch,
    faTimes,
    faChevronLeft,
    faChevronRight,
    faPaperPlane,
    faBuilding,
    faUser,
    faCalendar,
    faCircle,
    faSync,
    faChessPawn,
    faBalanceScale,
    faGlobe,
    faDownload,
    faEye,
    faTrash,
} from "@fortawesome/free-solid-svg-icons"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
//Dependencia await instance para consumir apis
import instance from '../../config'
import Swal from "sweetalert2"
import { get } from "lodash";
import './style-documento.css';
import { array } from "prop-types";
//Url Principal
const baseUrl = process.env.REACT_APP_API_URL;

const CrearNuevoAmbiente = props => {
    const [data, setData] = useState([])
    const [getSuscriptores, setSuscriptores] = useState({
        cSus_Categoria: "",
        cSus_Nombres: "",
        dSus_FechaVencimiento: "",
        dSus_FechaCreacion: "",
        cSus_Correo: "",
        cSus_Pass: "",
        cSus_Telefono: "",
        cSus_Empresa: "",
        cSus_CadenaConexion: "",
        cSus_Cuenta: "",
        bSus_Estado: 1,
    })
    const [NavLink1, setNavLink1] = useState('active nav-link')
    const [NavLink2, setNavLink2] = useState('')
    const [NavLink3, setNavLink3] = useState('')
    const [Style1, setStyle1] = useState('white')
    const [Style2, setStyle2] = useState('')
    const [Style3, setStyle3] = useState('')
    const [Tab, setTab] = useState('1')
    const [dataCategoria, setDataCategoria] = useState([])
    const FuncionTab = (tab) => {
        if (tab == 1) {
            setNavLink1('active nav-link')
            setStyle1('white')
            setTab('1')
            setNavLink2('')
            setStyle2('')
            setNavLink3('')
            setStyle3('')
        } else if (tab == 2) {
            setNavLink1('')
            setStyle1('')
            setNavLink2('active nav-link')
            setStyle2('white')
            setTab('2')
            setNavLink3('')
            setStyle3('')
        } else {
            setNavLink1('')
            setStyle1('')
            setNavLink2('')
            setStyle2('')
            setNavLink3('active nav-link')
            setStyle3('white')
            setTab('3')
        }
    }
    const handleChange = e => {
        const { name, value } = e.target
        setSuscriptores(prevState => ({
            ...prevState,
            [name]: value,
        }))

    }

    const CrearWorkSpace = async () => {
        await instance
            .post("http://localhost:9001/WorkSpace/Crear")
            .then(response => {

                ExportWorkSpace()
                setTimeout(() => {
                    ImportWorkSpace(response.data[0].Correlativo)
                    FuncionInsertarSuscriptores(response.data[0].Correlativo)
                }, 3000);
            })
            .catch(error => {

            })
    }
    const ExportWorkSpace = async () => {
        await instance
            .post("http://localhost:9001/Export")
            .then(response => {

            })
            .catch(error => {

            })
    }
    const ImportWorkSpace = async (_database) => {
        var postData = {
            database: _database,
        }
        await instance
            .post("http://localhost:9001/Import", postData)
            .then(response => {

                Swal.fire({
                    title: "Mensaje",
                    text: "Se ha creado el WORKSPACE correctamente",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                })
            })
            .catch(error => {

            })
    }
    const FuncionListarCategoria = async () => {
        var postData = {
            Prefijo: "ZD",
        }
        await instance
            .post("/Maestros/Data", postData)
            .then(response => {
                setDataCategoria(response.data)

            })
            .catch(error => {

            })
    }
    const expresiones = {
        usuario: /^[a-zA-Z0-9\_\-]{4,16}$/, // Letras, numeros, guion y guion_bajo
        nombre: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras y espacios, pueden llevar acentos.
        password: /^.{4,12}$/, // 4 a 12 digitos.
        correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
        telefono: /^\d{7,14}$/ // 7 a 14 numeros.
    }
    const validarTextoEntrada = async (input, patron) => {
        var texto = input.value
        var letras = texto.split("")
        for (var x in letras) {
            var letra = letras[x]
            if (!(new RegExp(patron, "i")).test(letra)) {
                letras[x] = ""
            }
        }
        input.value = letras.join("")
    }
    const validarTexto = async () => {
        var cSus_Cuenta = document.getElementById("cSus_Cuenta")
        cSus_Cuenta.addEventListener("input", function (event) {
            validarTextoEntrada(this, "[a-z0-9]")
            cSus_Cuenta.addEventListener('input', function () {
                if (this.value.length > 30)
                    this.value = this.value.slice(0, 30);
            })
        })
    }
    const validarNumero = async () => {
        var cSus_Telefono = document.getElementById("cSus_Telefono")
        cSus_Telefono.addEventListener("input", function (event) {
            validarTextoEntrada(this, "[0-9]")
            cSus_Telefono.addEventListener('input', function () {
                if (this.value.length > 9)
                    this.value = this.value.slice(0, 9);
            })
        })
    }
    const FuncionInsertarSuscriptores = async (_cSus_CadenaConexion) => {
        var postData = {
            cSus_Categoria: getSuscriptores.cSus_Categoria,
            cSus_Nombres: getSuscriptores.cSus_Nombres,
            cSus_Correo: getSuscriptores.cSus_Correo,
            cSus_Pass: getSuscriptores.cSus_Pass,
            cSus_Telefono: document.getElementById("cSus_Telefono").value,
            cSus_Empresa: getSuscriptores.cSus_Empresa,
            cSus_CadenaConexion: _cSus_CadenaConexion,
            cSus_Cuenta: document.getElementById("cSus_Cuenta").value,
            bSus_Estado: Number.parseInt(getSuscriptores.bSus_Estado),
        }
        await instance
            .post("/Suscriptores/Registrar", postData)
            .then(response => {

            })
            .catch(error => {

            })
    }

    const FuncionValidarFormulario = async () => {
        if (!getSuscriptores.cSus_Categoria || !getSuscriptores.cSus_Nombres || !getSuscriptores.cSus_Correo || !getSuscriptores.cSus_Pass || document.getElementById("cSus_Telefono").value.length > 9 || document.getElementById("cSus_Telefono").value.length < 9) {
            Swal.fire({
                title: "Mensaje",
                text: "Faltan completar datos",
                icon: "error",
                confirmButtonText: "Aceptar",
            })
        } else {
            CrearWorkSpace()
        }
    }
    useEffect(() => {
        validarTexto()
        validarNumero()
        FuncionListarCategoria()
    }, [])
    return (
        <React.Fragment>
            <div className="page-content">
                {/* add meta title */}
                <MetaTags>
                    <title>Crear nuevo WorkSpace | GpsLegal</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="GpsLegal" breadcrumbItem="Crear nuevo WorkSpace" />
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div className="checkout-tabs">
                                        <Row>
                                            <Col lg="2" sm="3">
                                                <Nav className="flex-column" pills>
                                                    <NavItem>
                                                        <NavLink
                                                            className={NavLink1}
                                                            onClick={() => FuncionTab(1)}
                                                        >
                                                            <i style={{ color: Style1 }} className="bx bx-money d-block check-nav-icon mt-4 mb-2" />
                                                            <p className="font-weight-bold mb-4">Datos del Suscriptor</p>
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className={NavLink2}
                                                            onClick={() => FuncionTab(2)}
                                                        >
                                                            <i style={{ color: Style2 }} className="bx bx-badge-check d-block check-nav-icon mt-4 mb-2" />
                                                            <p className="font-weight-bold mb-4">Información</p>
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                            </Col>
                                            <Col lg="10" sm="9">
                                                <Card>
                                                    <CardBody>
                                                        <TabContent activeTab={Tab}>
                                                            <TabPane
                                                                tabId="1"
                                                                id="v-pills-payment"
                                                                role="tabpanel"
                                                                aria-labelledby="v-pills-payment-tab"
                                                            >
                                                                <div>
                                                                    <CardTitle className="h4">Datos del Suscriptor</CardTitle>
                                                                    <p className="">
                                                                        Crear un nuevo Suscriptor para el WorkSpace
                                                                    </p>
                                                                    <div className="p-4 border">
                                                                        <Form>
                                                                            <Row>
                                                                                <Col lg="3">
                                                                                    <FormGroup className="mb-2">
                                                                                        <Label htmlFor="cardnumberInput">
                                                                                            Categoria
                                                                                        </Label>
                                                                                        <select
                                                                                            className="form-select"
                                                                                            name="cSus_Categoria" onChange={handleChange}
                                                                                        >
                                                                                            <option value="0">Seleccionar...</option>
                                                                                            {dataCategoria.map((row, i) => (
                                                                                                <option key={i} value={row.cTabCodigo}>
                                                                                                    {row.cTabNombre}
                                                                                                </option>
                                                                                            ))}
                                                                                        </select>
                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col lg="3">
                                                                                    <FormGroup className="mb-2">
                                                                                        <Label htmlFor="cardnumberInput">
                                                                                            Nombre completo
                                                                                        </Label>
                                                                                        <Input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            id="cSus_Nombres"
                                                                                            placeholder="Ingrese Nombre completo"
                                                                                            name="cSus_Nombres" onChange={handleChange}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col lg="3">
                                                                                    <FormGroup className="mb-2">
                                                                                        <Label htmlFor="cardnumberInput">
                                                                                            Correo electrónico
                                                                                        </Label>
                                                                                        <Input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            id="cSus_Correo"
                                                                                            placeholder="Ingrese Correo electrónico"
                                                                                            name="cSus_Correo" onChange={handleChange}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col lg="3">
                                                                                    <FormGroup className="mb-2">
                                                                                        <Label htmlFor="cardnumberInput">
                                                                                            Contraseña
                                                                                        </Label>
                                                                                        <Input
                                                                                            type="password"
                                                                                            className="form-control"
                                                                                            id="cSus_Pass"
                                                                                            placeholder="Ingrese Contraseña"
                                                                                            name="cSus_Pass" onChange={handleChange}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col lg="3">
                                                                                    <FormGroup className="mb-3">
                                                                                        <Label htmlFor="cardnumberInput">
                                                                                            Empresa
                                                                                        </Label>
                                                                                        <Input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            id="cSus_Empresa"
                                                                                            placeholder="Ingrese la Empresa"
                                                                                            name="cSus_Empresa" onChange={handleChange}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col lg="3">
                                                                                    <FormGroup className="mb-3">
                                                                                        <Label htmlFor="cardnumberInput">
                                                                                            Cuenta
                                                                                        </Label>
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            id="cSus_Cuenta"
                                                                                            placeholder="Ingrese Nombre de la cuenta"
                                                                                            name="cSus_Cuenta" onChange={handleChange}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col lg="3">
                                                                                    <FormGroup className="mb-3">
                                                                                        <Label htmlFor="cardnumberInput">
                                                                                            Teléfono
                                                                                        </Label>
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            id="cSus_Telefono"
                                                                                            placeholder="Ingrese Teléfono"
                                                                                            name="cSus_Telefono" onChange={handleChange}
                                                                                        />
                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col lg="3">
                                                                                    <FormGroup className="mb-2">
                                                                                        <Label htmlFor="cardnumberInput">
                                                                                            Estado
                                                                                        </Label>
                                                                                        <select
                                                                                            className="form-select"
                                                                                            name="bSus_Estado"
                                                                                            onChange={handleChange}
                                                                                        >
                                                                                            <option value="">Seleccionar...</option>
                                                                                            <option value="1">Vigente</option>
                                                                                            <option value="2">Suspendido </option>
                                                                                            <option value="0">De baja</option>
                                                                                        </select>
                                                                                    </FormGroup>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col lg="12" style={{ display: "flex", justifyContent: "flex-end" }}>
                                                                                    <FormGroup className="mb-3">
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-success waves-effect waves-light"
                                                                                            onClick={() => FuncionValidarFormulario()}
                                                                                        >
                                                                                            Finalizar <FontAwesomeIcon icon={faPaperPlane} />
                                                                                        </button>
                                                                                    </FormGroup>
                                                                                </Col>
                                                                            </Row>
                                                                        </Form>
                                                                    </div>
                                                                </div>
                                                            </TabPane>
                                                            <TabPane tabId="2" id="v-pills-confir" role="tabpanel">
                                                                <Card className="shadow-none border mb-0">
                                                                    <CardBody>
                                                                        <CardTitle className="mb-4">
                                                                            Información
                                                                        </CardTitle>
                                                                        <div className="table-responsive">
                                                                            <CardTitle className="h4">La base de datos ha sido creada exitosamente</CardTitle>
                                                                            <p className="">
                                                                                Nombre de DB: db_Prueba
                                                                            </p>
                                                                            <p className="">
                                                                                Cadena de conexion: db_Prueba
                                                                            </p>
                                                                            <p className="">
                                                                                Usuario : Admin
                                                                            </p>
                                                                            <p className="">
                                                                                Contraseña : Admin
                                                                            </p>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </TabPane>
                                                        </TabContent>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default CrearNuevoAmbiente

